/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const TheredbuttonSi = ({
  ptLabel = "계약 중도 취소",
  style = "S",
  onClick = "D",
  varState,
  className,
  overlapGroupClassName,
}) => {
  return (
    <div className={`theredbutton-SI var-state-${varState} ${className}`} style={typeof style === "object" ? style : null}>
      <div className="content-2">
        <div className={`overlap-group-9 ${overlapGroupClassName}`}>
          <div className="frame-32">
            <div className="pt-label-2">{ptLabel}</div>
          </div>
          <div className="text-wrapper-5"></div>
          <div className="text-wrapper-5" onClick={() => typeof onClick === "function" ? onClick() : null} onMouseOut={(e) => typeof onMouseOut === "function" ? onMouseOut(e) : null} onMouseOver={(e) => typeof onMouseOver === "function" ? onMouseOver(e) : null} onMouseMove={(e) => typeof onMouseMove === "function" ? onMouseMove(e) : null} style={{zIndex: 20}}>D</div>
        </div>
      </div>
    </div>
  );
};

TheredbuttonSi.propTypes = {
  ptLabel: PropTypes.string,
  style: PropTypes.any,
  onClick: PropTypes.any,
  varState: PropTypes.oneOf(["enabled", "disabled"]),
};
