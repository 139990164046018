// downloaded data!
export const data = [
  {
    "type": "global",
    "key": "mod_global_1_boom",
    "name": "loc_data_string_modifiers_name_mod_global_1_boom",
    "desc": "loc_data_longtext_modifiers_desc_mod_global_1_boom",
    "overwrite": "mod_global_2_recession"
  },
  {
    "type": "global",
    "key": "mod_global_2_recession",
    "name": "loc_data_string_modifiers_name_mod_global_2_recession",
    "desc": "loc_data_longtext_modifiers_desc_mod_global_2_recession",
    "overwrite": "mod_global_1_boom"
  },
  {
    "type": "global",
    "key": "mod_global_3_promising",
    "name": "loc_data_string_modifiers_name_mod_global_3_promising",
    "desc": "loc_data_longtext_modifiers_desc_mod_global_3_promising",
    "overwrite": "mod_global_4_youtuber"
  },
  {
    "type": "global",
    "key": "mod_global_4_youtuber",
    "name": "loc_data_string_modifiers_name_mod_global_4_youtuber",
    "desc": "loc_data_longtext_modifiers_desc_mod_global_4_youtuber",
    "overwrite": "mod_global_3_promising"
  },
  {
    "type": "global",
    "key": "mod_global_5_excess_firearm",
    "name": "loc_data_string_modifiers_name_mod_global_5_excess_firearm",
    "desc": "loc_data_longtext_modifiers_desc_mod_global_5_excess_firearm",
    "overwrite": "mod_global_6_excess_equipment, mod_global_7_excess_throwable"
  },
  {
    "type": "global",
    "key": "mod_global_6_excess_equipment",
    "name": "loc_data_string_modifiers_name_mod_global_6_excess_equipment",
    "desc": "loc_data_longtext_modifiers_desc_mod_global_6_excess_equipment",
    "overwrite": "mod_global_5_excess_firearm, mod_global_7_excess_throwable"
  },
  {
    "type": "global",
    "key": "mod_global_7_excess_throwable",
    "name": "loc_data_string_modifiers_name_mod_global_7_excess_throwable",
    "desc": "loc_data_longtext_modifiers_desc_mod_global_7_excess_throwable",
    "overwrite": "mod_global_5_excess_firearm, mod_global_6_excess_equipment"
  },
  {
    "type": "global",
    "key": "mod_global_8_contract_faithful",
    "name": "loc_data_string_modifiers_name_mod_global_8_contract_faithful",
    "desc": "loc_data_longtext_modifiers_desc_mod_global_8_contract_faithful",
    "overwrite": "mod_global_9_contract_decieve"
  },
  {
    "type": "global",
    "key": "mod_global_9_contract_decieve",
    "name": "loc_data_string_modifiers_name_mod_global_9_contract_decieve",
    "desc": "loc_data_longtext_modifiers_desc_mod_global_9_contract_decieve",
    "overwrite": "mod_global_8_contract_faithful"
  },
  {
    "type": "global",
    "key": "mod_global_10_high_manpower",
    "name": "loc_data_string_modifiers_name_mod_global_10_high_manpower",
    "desc": "loc_data_longtext_modifiers_desc_mod_global_10_high_manpower",
    "overwrite": "mod_global_11_platform_labor"
  },
  {
    "type": "global",
    "key": "mod_global_11_platform_labor",
    "name": "loc_data_string_modifiers_name_mod_global_11_platform_labor",
    "desc": "loc_data_longtext_modifiers_desc_mod_global_11_platform_labor",
    "overwrite": "mod_global_10_high_manpower"
  },
  {
    "type": "global",
    "key": "mod_global_12_shop_firearm",
    "name": "loc_data_string_modifiers_name_mod_global_12_shop_firearm",
    "desc": "loc_data_longtext_modifiers_desc_mod_global_12_shop_firearm"
  },
  {
    "type": "global",
    "key": "mod_global_13_shop_equipment",
    "name": "loc_data_string_modifiers_name_mod_global_13_shop_equipment",
    "desc": "loc_data_longtext_modifiers_desc_mod_global_13_shop_equipment"
  },
  {
    "type": "global",
    "key": "mod_global_14_shop_throwable",
    "name": "loc_data_string_modifiers_name_mod_global_14_shop_throwable",
    "desc": "loc_data_longtext_modifiers_desc_mod_global_14_shop_throwable"
  },
  {
    "type": "global",
    "key": "mod_global_25_mission_supply",
    "name": "loc_data_string_modifiers_name_mod_global_25_mission_supply",
    "desc": "loc_data_longtext_modifiers_desc_mod_global_25_mission_supply"
  },
  {
    "type": "global",
    "key": "mod_global_26_mission_resent",
    "name": "loc_data_string_modifiers_name_mod_global_26_mission_resent",
    "desc": "loc_data_longtext_modifiers_desc_mod_global_26_mission_resent"
  },
  {
    "type": "mission",
    "key": "mod_mission_1_firearm",
    "name": "loc_data_string_modifiers_name_mod_mission_1_firearm",
    "desc": "loc_data_longtext_modifiers_desc_mod_mission_1_firearm"
  },
  {
    "type": "mission",
    "key": "mod_mission_2_equipment",
    "name": "loc_data_string_modifiers_name_mod_mission_2_equipment",
    "desc": "loc_data_longtext_modifiers_desc_mod_mission_2_equipment"
  },
  {
    "type": "mission",
    "key": "mod_mission_3_throwable",
    "name": "loc_data_string_modifiers_name_mod_mission_3_throwable",
    "desc": "loc_data_longtext_modifiers_desc_mod_mission_3_throwable"
  },
  {
    "type": "mission",
    "key": "mod_mission_4_hyena",
    "name": "loc_data_string_modifiers_name_mod_mission_4_hyena",
    "desc": "loc_data_longtext_modifiers_desc_mod_mission_4_hyena"
  },
  {
    "type": "mission",
    "key": "mod_mission_5_elite",
    "name": "loc_data_string_modifiers_name_mod_mission_5_elite",
    "desc": "loc_data_longtext_modifiers_desc_mod_mission_5_elite"
  },
  {
    "type": "mission",
    "key": "mod_mission_6_supply",
    "name": "loc_data_string_modifiers_name_mod_mission_6_supply",
    "desc": "loc_data_longtext_modifiers_desc_mod_mission_6_supply"
  },
  {
    "type": "mission",
    "key": "mod_mission_7_resent",
    "name": "loc_data_string_modifiers_name_mod_mission_7_resent",
    "desc": "loc_data_longtext_modifiers_desc_mod_mission_7_resent",
    "overwrite": "mod_mission_8_weaken"
  },
  {
    "type": "mission",
    "key": "mod_mission_8_weaken",
    "name": "loc_data_string_modifiers_name_mod_mission_8_weaken",
    "desc": "loc_data_longtext_modifiers_desc_mod_mission_8_weaken",
    "overwrite": "mod_mission_7_resent"
  },
  {
    "type": "mission",
    "key": "mod_mission_9_militia",
    "name": "loc_data_string_modifiers_name_mod_mission_9_militia",
    "desc": "loc_data_longtext_modifiers_desc_mod_mission_9_militia"
  },
  {
    "type": "agent",
    "key": "mod_agent_5_disable_prompt",
    "name": "loc_data_string_modifiers_name_mod_agent_5_disable_prompt",
    "desc": "loc_data_longtext_modifiers_desc_mod_agent_5_disable_prompt"
  },
  {
    "type": "agent",
    "key": "mod_agent_6_morale_high",
    "name": "loc_data_string_modifiers_name_mod_agent_6_morale_high",
    "desc": "loc_data_longtext_modifiers_desc_mod_agent_6_morale_high",
    "overwrite": "mod_agent_7_morale_low"
  },
  {
    "type": "agent",
    "key": "mod_agent_7_morale_low",
    "name": "loc_data_string_modifiers_name_mod_agent_7_morale_low",
    "desc": "loc_data_longtext_modifiers_desc_mod_agent_7_morale_low",
    "overwrite": "mod_agent_6_morale_high"
  },
  {
    "type": "agent",
    "key": "mod_agent_8_growth_power",
    "name": "loc_data_string_modifiers_name_mod_agent_8_growth_power",
    "desc": "loc_data_longtext_modifiers_desc_mod_agent_8_growth_power",
    "overwrite": "mod_agent_9_growth_perk"
  },
  {
    "type": "agent",
    "key": "mod_agent_9_growth_perk",
    "name": "loc_data_string_modifiers_name_mod_agent_9_growth_perk",
    "desc": "loc_data_longtext_modifiers_desc_mod_agent_9_growth_perk",
    "overwrite": "mod_agent_8_growth_power"
  },
  {
    "type": "agent",
    "key": "mod_agent_10_drug",
    "name": "loc_data_string_modifiers_name_mod_agent_10_drug",
    "desc": "loc_data_longtext_modifiers_desc_mod_agent_10_drug"
  },
  {
    "type": "agent",
    "key": "mod_agent_13_agenda_strong",
    "name": "loc_data_string_modifiers_name_mod_agent_13_agenda_strong",
    "desc": "loc_data_longtext_modifiers_desc_mod_agent_13_agenda_strong"
  },
  {
    "type": "agent",
    "key": "mod_agent_14_agenda_weak",
    "name": "loc_data_string_modifiers_name_mod_agent_14_agenda_weak",
    "desc": "loc_data_longtext_modifiers_desc_mod_agent_14_agenda_weak"
  },
  {
    "type": "agent",
    "key": "mod_agent_15_agenda_expensive",
    "name": "loc_data_string_modifiers_name_mod_agent_15_agenda_expensive",
    "desc": "loc_data_longtext_modifiers_desc_mod_agent_15_agenda_expensive"
  },
  {
    "type": "agent",
    "key": "mod_agent_16_agenda_frequent",
    "name": "loc_data_string_modifiers_name_mod_agent_16_agenda_frequent",
    "desc": "loc_data_longtext_modifiers_desc_mod_agent_16_agenda_frequent"
  },
  {
    "type": "agent",
    "key": "mod_agent_17_agenda_safety",
    "name": "loc_data_string_modifiers_name_mod_agent_17_agenda_safety",
    "desc": "loc_data_longtext_modifiers_desc_mod_agent_17_agenda_safety"
  },
  {
    "type": "agent",
    "key": "mod_agent_18_agenda_wellbeing",
    "name": "loc_data_string_modifiers_name_mod_agent_18_agenda_wellbeing",
    "desc": "loc_data_longtext_modifiers_desc_mod_agent_18_agenda_wellbeing"
  },
  {
    "type": "agent",
    "key": "mod_agent_19_agenda_complicated",
    "name": "loc_data_string_modifiers_name_mod_agent_19_agenda_complicated",
    "desc": "loc_data_longtext_modifiers_desc_mod_agent_19_agenda_complicated"
  },
  {
    "type": "contract",
    "key": "mod_contract_1_frequency_high",
    "name": "loc_data_string_modifiers_name_mod_contract_1_frequency_high",
    "desc": "loc_data_longtext_modifiers_desc_mod_contract_1_frequency_high"
  },
  {
    "type": "contract",
    "key": "mod_contract_2_frequency_low",
    "name": "loc_data_string_modifiers_name_mod_contract_2_frequency_low",
    "desc": "loc_data_longtext_modifiers_desc_mod_contract_2_frequency_low"
  },
  {
    "type": "contract",
    "key": "mod_contract_3_difficulty_high",
    "name": "loc_data_string_modifiers_name_mod_contract_3_difficulty_high",
    "desc": "loc_data_longtext_modifiers_desc_mod_contract_3_difficulty_high"
  },
  {
    "type": "contract",
    "key": "mod_contract_4_difficulty_low",
    "name": "loc_data_string_modifiers_name_mod_contract_4_difficulty_low",
    "desc": "loc_data_longtext_modifiers_desc_mod_contract_4_difficulty_low"
  },
  {
    "type": "contract",
    "key": "mod_contract_5_reward_high",
    "name": "loc_data_string_modifiers_name_mod_contract_5_reward_high",
    "desc": "loc_data_longtext_modifiers_desc_mod_contract_5_reward_high"
  },
  {
    "type": "contract",
    "key": "mod_contract_6_reward_low",
    "name": "loc_data_string_modifiers_name_mod_contract_6_reward_low",
    "desc": "loc_data_longtext_modifiers_desc_mod_contract_6_reward_low"
  },
  {
    "type": "recruit",
    "key": "mod_recruit_1_apply_high",
    "name": "loc_data_string_modifiers_name_mod_recruit_1_apply_high",
    "desc": "loc_data_longtext_modifiers_desc_mod_recruit_1_apply_high"
  },
  {
    "type": "recruit",
    "key": "mod_recruit_2_apply_low",
    "name": "loc_data_string_modifiers_name_mod_recruit_2_apply_low",
    "desc": "loc_data_longtext_modifiers_desc_mod_recruit_2_apply_low"
  },
  {
    "type": "recruit",
    "key": "mod_recruit_3_wage_high",
    "name": "loc_data_string_modifiers_name_mod_recruit_3_wage_high",
    "desc": "loc_data_longtext_modifiers_desc_mod_recruit_3_wage_high"
  },
  {
    "type": "recruit",
    "key": "mod_recruit_4_wage_low",
    "name": "loc_data_string_modifiers_name_mod_recruit_4_wage_low",
    "desc": "loc_data_longtext_modifiers_desc_mod_recruit_4_wage_low"
  },
  {
    "type": "recruit",
    "key": "mod_recruit_5_quality_high",
    "name": "loc_data_string_modifiers_name_mod_recruit_5_quality_high",
    "desc": "loc_data_longtext_modifiers_desc_mod_recruit_5_quality_high"
  },
  {
    "type": "recruit",
    "key": "mod_recruit_6_quality_low",
    "name": "loc_data_string_modifiers_name_mod_recruit_6_quality_low",
    "desc": "loc_data_longtext_modifiers_desc_mod_recruit_6_quality_low"
  }
];