import { ENTITY_CONFIG_TMPL } from './opts.mjs';
import { names3 } from './names.mjs';

const lerp = function (a, b, t) {
  return a + (b - a) * t;
}

// level: [1, 20]
const project = function (min, max, lvl) {
  return lerp(min, max, (lvl - 1) / 19);
}

const project2 = function (min, max, lvl) {
  return lerp(min, max, lvl / 2);
}

export const STAT_DESCR = {
  stat_movespeed: '이동속도',
  stat_dashdist: '재배치 성향',
  stat_aimvar_hold: '지향 조준',
  stat_aimvar_decay_per_tick: '조준 속도',
  stat_aimvar_hold_max: '정조준',
  stat_aimvar_incr_per_shoot: '반동 제어',
  stat_aimvar_incr_per_hit: '압박하 사격',
  stat_aim_fire_thres: '방아쇠 성향',
  stat_aim_reload: '재장전 성향',
};

export const STAT_RANGE = {
  stat_movespeed: [18, 22],
  stat_dashdist: [50, 150],
  stat_aimvar_hold: [1, 20],
  stat_aimvar_decay_per_tick: [1, 20],
  stat_aimvar_hold_max: [1, 20],
  stat_aimvar_incr_per_shoot: [1, 20],
  stat_aimvar_incr_per_hit: [1, 20],
  stat_aim_fire_thres: [0, 2],
  stat_aim_reload: [0, 2],
};


export const STATS_TMPL = {
  name: null,

  // [1, 3]
  stat_size: 2,

  // [1, 20]
  // sr, dmr, ar, smg, sg
  stat_firearm_level: [11, 11, 11, 11, 11],

  // [18, 22]
  stat_movespeed: 20,
  // [50, 150]
  stat_dashdist: 100,
  // [1, 20]
  stat_aimvar_hold: 11,
  stat_aimvar_decay_per_tick: 11,
  stat_aimvar_hold_max: 11,
  stat_aimvar_incr_per_shoot: 11,
  stat_aimvar_incr_per_hit: 11,
  // [0, 2]
  stat_aim_fire_thres: 1,
  stat_aim_reload: 1,
};

export function stats_const(val, firearmVal) {
  return {
    stat_size: 2,
    stat_firearm_level: STATS_TMPL.stat_firearm_level.map((_v) => firearmVal ?? val),

    stat_movespeed: 20,
    stat_dashdist: 100,
    stat_aimvar_hold: val,
    stat_aimvar_decay_per_tick: val,
    stat_aimvar_hold_max: val,
    stat_aimvar_incr_per_shoot: val,
    stat_aimvar_incr_per_hit: val,
    stat_aim_fire_thres: 1,
    stat_aim_reload: 1,

    _stat: val,
  };
}

export function stats_rand(rng, name) {
  if (!name) {
    name = rng.choice(names3);
  }
  return {
    name,
    stat_size: rng.integer(1, 3),
    stat_firearm_level: STATS_TMPL.stat_firearm_level.map((_v) => rng.integer(1, 20)),

    stat_movespeed: rng.integer(18, 22),
    stat_dashdist: rng.integer(50, 150),
    stat_aimvar_hold: rng.integer(1, 20),
    stat_aimvar_decay_per_tick: rng.integer(1, 20),
    stat_aimvar_hold_max: rng.integer(1, 20),
    stat_aimvar_incr_per_shoot: rng.integer(1, 20),
    stat_aimvar_incr_per_hit: rng.integer(1, 20),
    stat_aim_fire_thres: rng.integer(0, 2),
    stat_aim_reload: rng.integer(0, 2),
  };
}

export function stats_avg(stats) {
  let sum = 0;
  let count = 0;

  for (const key in stats) {
    if (key.indexOf('stat_aimvar') !== 0) {
      continue;
    }
    if (isNaN(stats[key])) {
      continue;
    }
    sum += stats[key];
    count += 1;
  }
  return (sum / count).toFixed(1);
}

export function stats_mul(stats, m) {
  let s = {};
  m = m ?? 1;
  for (const [key, value] of Object.entries(stats)) {
    if (key === 'stat_firearm_level') {
      let levels = [];
      for (const l of value) {
        levels.push(l * m);
      }
      s[key] = levels;
    } else if (key.indexOf('stat_aimvar') !== 0) {
      s[key] = value;
    }
    else if (isNaN(value)) {
      s[key] = value;
    } else {
      s[key] = value * m;
    }
  }
  return s;
}

export const STATS_TMPL_MAX = {
  ...STATS_TMPL,

  stat_size: 1,
  stat_firearm_level: [20, 20, 20, 20, 20],

  stat_aimvar_hold: 20,
  stat_aimvar_decay_per_tick: 20,
  stat_aimvar_hold_max: 20,
  stat_aimvar_incr_per_shoot: 20,
  stat_aimvar_incr_per_hit: 20,
};


export const team0_STATS_TMPL = {
  ...STATS_TMPL,

  stat_firearm_level: [11, 11, 11, 11, 20],
  // [18, 22]
  //stat_movespeed: 20,
  // [50, 150]
  //stat_dashdist: 100,
  // [1, 20]
  //stat_aimvar_hold: 1,
  //stat_aimvar_decay_per_tick: 1,
  //stat_aimvar_hold_max: 1,
  //stat_aimvar_incr_per_shoot: 1,
  // [0, 2]
  //stat_aim_fire_thres: 1,
  //stat_aim_reload: 1,
  // [1, 20]
  //stat_aimvar_incr_per_hit: 1,
};

export const team1_STATS_TMPL = {
  ...STATS_TMPL,

  //stat_firearm_level: [11, 11, 11, 11, 11],
  // [18, 22]
  //stat_movespeed: 20,
  // [50, 150]
  //stat_dashdist: 100,
  // [1, 20]
  //stat_aimvar_hold: 20,
  //stat_aimvar_decay_per_tick: 20,
  //stat_aimvar_hold_max: 20,
  //stat_aimvar_incr_per_shoot: 20,
  // [0, 2]
  //stat_aim_fire_thres: 1,
  //stat_aim_reload: 1,
  // [1, 20]
  //stat_aimvar_incr_per_hit: 20,
};

const firearm_stat_proj = {
  // TODO:
  sr: {
    range: [-20, 20],
    // ref: 1/32, 1/280
    hold: [0.5, -0.5],
    hold_max: [0.5, -0.5],
  },
  dmr: {
    range: [-10, 10],
    // ref: 1/32, 1/280
    hold: [0.4, -0.4],
    hold_max: [0.4, -0.4],
  },
  ar: {
    range: [-5, 5],
    // ref: 1/32, 1/256
    hold: [0.4, -0.4],
    hold_max: [0.4, -0.4],
  },
  smg: {
    range: [-5, 5],
    // ref: 1/32, 1/256
    hold: [0.3, -0.3],
    hold_max: [0.3, -0.3],
  },
  sg: {
    range: [-5, 5],
    // ref: 1/32, 1/280
    hold: [0.2, -0.2],
    hold_max: [0.2, -0.2],
  },
};
export const FIREARM_CATS = Object.keys(firearm_stat_proj);

function entityApplyFirearmLevel(stat, entity, cat) {
  const idx = FIREARM_CATS.indexOf(cat);

  const proj = firearm_stat_proj[cat];
  const level = stat.stat_firearm_level[idx];

  entity.firearm_range += project(proj.range[0], proj.range[1], level);
  entity.aimvar_hold *= Math.exp(project(proj.hold[0], proj.hold[1], level));
  entity.aimvar_hold_max *= Math.exp(project(proj.hold_max[0], proj.hold_max[1], level));
}

export function updateEntityStat(entity, stat, firearm_stat) {
  if (stat.stat_size <= 0 || stat.stat_size > 3) {
    throw new Error(`invalid stat_size: ${stat.stat_size}`);
  }
  entity.size = [2.25, 2.5, 2.75][stat.stat_size - 1];


  if (firearm_stat) {
    // 총기 template 복사해서 넣기
    for (const key in firearm_stat) {
      entity[key] = firearm_stat[key];
    }

    // 화기 숙련도별 능력치 적용하기
    entityApplyFirearmLevel(stat, entity, firearm_stat.firearm_ty);
  }

  // 기타 능력치 적용하기
  entity.speed = stat.stat_movespeed;
  entity.waypoint_dash_dist = stat.stat_dashdist;
  entity.aimvar_hold *= Math.exp(project(0.2, -0.6, stat.stat_aimvar_hold));
  entity.aimvar_hold_max *= Math.exp(project(-0.2, -0.8, stat.stat_aimvar_hold_max));
  entity.aimvar_decay_per_tick = project(0.9, 0.87, stat.stat_aimvar_decay_per_tick);
  entity.aimvar_incr_per_hit = project(0.2, 0.1, stat.stat_aimvar_incr_per_hit);
  entity.firearm_reload_idle_visibility_duration = project2(2, 3, stat.stat_aim_reload);
  entity.firearm_reload_idle_duration = project2(7, 13, stat.stat_aim_reload);

  // TODO: multiplier
  entity.aimvar_incr_per_shoot *= project(1.4, 0.6, stat.stat_aimvar_incr_per_shoot);
  entity.aim_samples_fire_thres += project2(-0.05, 0.05, stat.stat_aim_fire_thres);

  if (stat.name) {
    entity.name = stat.name;
  }
  entity._stat = stat;
  entity._firearm_stat = firearm_stat;

  return entity;
}

export function entityFromStat(stat, firearm_stat) {
  const base = { ...ENTITY_CONFIG_TMPL };
  return updateEntityStat(base, stat, firearm_stat);
}
