import '../style.css';

import { L } from '../../../../localization.mjs';

export function FH1Tabs(props) {
  const { items, selected, onChange } = props;

  return <div className={`fh1-tabs ${props.className ?? ''}`} style={props.style}>
    {items.map((item) => {
      const { key, locale, badge_new } = item;
      let cls = 'fh1-tabs fh1-tab';
      const badge = badge_new ? <div className="fh1-badge-wrapper"><div className="fh1-badge-new">N</div></div> : null;
      if (key === selected) {
        cls += ' fh1-selected';
      }
      let label = item.label ?? L(locale);
      return <div key={key} className={cls} onClick={() => onChange(key)}>
        {label}
        {badge}
      </div>;
    })}
  </div>;
}
