import React from 'react';

import './WorldView2.css';

import { v2 } from './v2';
import { GAMEFACE } from './gameface';
import { oddrFromQr, qrNeighbors, qrFromOddr, qrEq, qrCmp, qrFromCoord } from './worldmap';
import { WorldState2, centerState } from './worldmap';
import { CELL_CENTER } from './worldmap';
import { COLORS } from './colors';

import * as data_facilities from './data/google/processor/data_facilities.mjs';
import { TICK_PER_DAY, tickToDateStr } from './tick.mjs';
import { L } from './localization.mjs';

const colors = [COLORS.grid0[1], COLORS.highlight0[0], 'green', COLORS.grid0[3], 'gray', 'purple', 'black'];
export const TYNAMES = ['평야', '도시', '숲', '바다', '황야', '???2', '산'];

const colors2 = [
  '#ffffff', 'gray', '#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080', '#000000',
];

/*
function axial_to_oddr(hex):
    var col = hex.q + (hex.r - (hex.r&1)) / 2
    var row = hex.r
    return OffsetCoord(col, row)

function oddr_to_axial(hex):
    var q = hex.col - (hex.row - (hex.row&1)) / 2
    var r = hex.row
    return Hex(q, r)
*/

function hexagonPoint(pos, size, i) {
  const angle = (i * 60) * Math.PI / 180;
  return pos.add(new v2(Math.sin(angle), -Math.cos(angle)).mul(size));
}

function hexagonPointMid(pos, size, i) {
  size = size * Math.sqrt(3) / 2;
  const angle = (30 + i * 60) * Math.PI / 180;
  return pos.add(new v2(Math.sin(angle), -Math.cos(angle)).mul(size));
}

function pathHexagonBorder(ctx, pos, size, i, cont) {
  const p0 = hexagonPoint(pos, size, i);
  const p1 = hexagonPoint(pos, size, i + 1);
  if (cont) {
    ctx.lineTo(p0.x, p0.y);
  } else {
    ctx.moveTo(p0.x, p0.y);
  }
  ctx.lineTo(p1.x, p1.y);
}

function pathHexagon(ctx, pos, size) {
  let moved = false;
  for (let i = 0; i < 7; i++) {
    // hexagon
    const { x, y } = hexagonPoint(pos, size, i);
    if (!moved) {
      ctx.moveTo(x, y);
      moved = true;
    } else {
      ctx.lineTo(x, y);
    }
  }
}

function renderHexagon(ctx, pos, size) {
  ctx.beginPath();
  pathHexagon(ctx, pos, size);
  ctx.fill();
}

const VIEWS = ['game', 'block', 'conflict', 'geography'];

export function WorldViewButtons(props) {
  const { view, onView } = props;
  return <div className="box">
    views
    {VIEWS.map((v) => {
      const selected = v === view;
      let cls = '';
      if (selected) {
        cls += ' btn-selected';
      }
      return <button key={v} className={cls} onClick={() => onView(v)}>{v}</button>;
    })}
  </div>;
}

const SCALES = [4, 3, 2];

export class WorldCanvas extends React.Component {
  constructor(props) {
    super(props);

    const loadImage = (src) => {
      const img = document.createElement('img');
      img.ready = false;
      img.onload = () => {
        img.ready = true;
        this.renderCanvas();
      };
      img.src = src;
      return img;
    };

    this.img_map = loadImage('/img/world_test_4096_8bit.png');
    this.img_map_gray = loadImage('/img/world_test_4096_8bit_gray.png');
    this.img_cloud = loadImage('/img/cloud.png');

    this.keyDown = this.onKeyDown.bind(this);
    this.keyUp = this.onKeyUp.bind(this);
    this.resize = this.onResize.bind(this);
    this.contextMenu = (ev) => {
      ev.preventDefault();
    };
    this.canvasRef = React.createRef();

    this.size_cell = props.size_cell ?? 10;
    this.size_cell_h = this.size_cell * 3 / 2;
    this.size_cell_w = Math.sqrt(3) * this.size_cell;

    this.offset = this.size_cell * 2;

    const { world } = props;

    const size = new v2(window.innerWidth, window.innerHeight);
    const scale = 4;

    let canvasOffset = new v2(0, 0);
    const start = world.centers.find((c) => world.storage[c.idx].centerstate.office);
    if (start) {
      const start_p = world.qridx(start.idx);
      const pos = this.cellcenter(start_p);
      canvasOffset = this.offsetToProject(pos, size.mul(0.5), scale);
    }

    this.state = {
      size,

      scale,
      canvasOffset,

      selectedColor: 0,
      mouseOver: null,
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.keyDown);
    document.addEventListener('keyup', this.keyUp);
    document.addEventListener('contextmenu', this.contextMenu);
    window.addEventListener('resize', this.resize);

    this.renderCanvas();
  }

  componentDidUpdate() {
    this.renderCanvas();
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyDown);
    document.removeEventListener('keyup', this.keyUp);
    document.removeEventListener('contextmenu', this.contextMenu);
    window.removeEventListener('resize', this.resize);
  }

  static canvasSize(world) {
    const { width, height } = world;
    const { size_cell_w, size_cell_h, offset } = this;
    const canvas_width = Math.floor((width - 0.5) * size_cell_w + offset * 2);
    const canvas_height = Math.floor((height - 1) * size_cell_h + offset * 2);
    return { width: canvas_width, height: canvas_height };
  }

  onResize() {
    this.setState({ size: new v2(window.innerWidth, window.innerHeight) });
  }

  onKeyDownEdit(ev) {
    if (!this.props.edit) {
      return;
    }

    if (ev.key >= '0' && ev.key < colors.length.toString()) {
      this.setState({ selectedColor: ev.key - '0' });
    }

    if (ev.key === 'Shift') {
      ev.preventDefault();
      this.setState({ paint: true });
    }
  }

  screenToCanvas(p, canvasOffset, scale) {
    const { offset } = this;
    // canvas = screen / scale + (canvasOffset - offset);
    return p.mul(1 / scale).add(canvasOffset.sub(v2.unit(offset)));
  }

  canvasToScreen(p, canvasOffset, scale) {
    // screen = (canvas - (canvasOffset)) * scale;
    return p.sub(canvasOffset).mul(scale);
  }

  // get new offset to locate `canvas` to `screen`
  offsetToProject(canvas, screen, scale) {
    const { offset } = this;
    return canvas.sub(screen.mul(1 / scale)).add(v2.unit(offset));
  }

  onKeyDown(ev) {
    const { scale, canvasOffset, size } = this.state;

    if (ev.key === 'z') {
      ev.preventDefault();
      const idx = SCALES.indexOf(scale);
      const scale_next = SCALES[(idx + 1) % SCALES.length];

      // center = (size * 0.5 / scale) + (canvasoffset - offset);
      const canvas_prev = this.screenToCanvas(size.mul(0.5), canvasOffset, scale);
      // canvasoffset_next = (center - size * 0.5 / scale) + offset;
      const offset_next = this.offsetToProject(canvas_prev, size.mul(0.5), scale_next);

      this.setState({
        scale: scale_next,
        canvasOffset: offset_next,
      });
    }

    this.onKeyDownEdit(ev);
  }

  onKeyUp(ev) {
    const { edit, world } = this.props;
    if (!edit) {
      return;
    }

    if (ev.key === 'Shift') {
      ev.preventDefault();
      world.recalculate();
      this.setState({ paint: false });
    }
  }

  cellcenter(p) {
    const { size_cell_w, size_cell_h, offset } = this;
    const { i, j } = oddrFromQr(p);

    const x = (i + (j & 1) / 2) * size_cell_w + offset;
    const y = j * size_cell_h + offset;
    return new v2(x, y);
  }

  get missionOver() {
    return this.props.missionOver ?? this.props.missionOver ?? null;
  }

  renderCanvas() {
    if (!this.canvasRef.current) {
      return;
    }
    if (!this.img_map.ready || !this.img_map_gray.ready || !this.img_cloud.ready) {
      return;
    }

    const { size_cell } = this;
    const { mouseOver, size: cs, scale, canvasOffset } = this.state;
    const { world, centerOver, view, days } = this.props;
    const { selected_base_idx, selected_mission_idx } = this.props;
    const { storage, width, height, centers, missions, blockconns } = world;

    const missionOver = this.missionOver;
    const ctx = this.canvasRef.current.getContext('2d');

    ctx.globalAlpha = 1;

    const renderScale = window.devicePixelRatio;
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.scale(renderScale, renderScale);

    ctx.fillStyle = 'black';
    ctx.fillRect(0, 0, cs.x, cs.y);

    ctx.font = '500 4px "The Jamsil OTF"';
    const lineheight = 5;

    const fontStyle0 = COLORS.highlight0[1];
    const fontStyle1 = COLORS.grid0[0];

    function writeLine0(x, y, offset, str) {
      ctx.fillStyle = fontStyle1;
      ctx.fillText(str, x + 10 + 0.5, y + offset + 0.5);
      ctx.fillStyle = fontStyle0;
      ctx.fillText(str, x + 10, y + offset);
    }

    const renderPath = (ctx, pFrom, pTo, arrow, size) => {
      const { x: xFrom, y: yFrom } = this.cellcenter(pFrom);
      let { x: xTo, y: yTo } = this.cellcenter(pTo);

      const xDiff = xTo - xFrom;
      const yDiff = yTo - yFrom;

      const diffMagnitude = Math.sqrt(xDiff ** 2 + yDiff ** 2);
      const angle = -Math.atan2(yDiff, xDiff);
      const angleRectVertexOne = angle - Math.PI / 2;
      const angleRectVertexTwo = angle + Math.PI / 2;

      xTo += xDiff / diffMagnitude;
      yTo += yDiff / diffMagnitude;

      ctx.beginPath();
      ctx.moveTo(xFrom + size * Math.cos(angleRectVertexOne), yFrom - size * Math.sin(angleRectVertexOne));
      ctx.lineTo(xTo + size * Math.cos(angleRectVertexOne), yTo - size * Math.sin(angleRectVertexOne));
      ctx.lineTo(xTo + size * Math.cos(angleRectVertexTwo), yTo - size * Math.sin(angleRectVertexTwo));
      ctx.lineTo(xFrom + size * Math.cos(angleRectVertexTwo), yFrom - size * Math.sin(angleRectVertexTwo));
      ctx.lineTo(xFrom + size * Math.cos(angleRectVertexOne), yFrom - size * Math.sin(angleRectVertexOne));
      ctx.fill();

      // 목표지점이면 화살표 그리기
      if (arrow) {
        xTo -= xDiff / diffMagnitude;
        yTo -= yDiff / diffMagnitude;
        const triangelSize = size * 5;

        ctx.beginPath();
        ctx.moveTo(xTo + triangelSize * Math.cos(angle), yTo - triangelSize * Math.sin(angle));
        for (let i = 0; i < 3; i++) {
          const triAngle = angle + i * Math.PI * 2 / 3
          ctx.lineTo(xTo + triangelSize * Math.cos(triAngle), yTo - triangelSize * Math.sin(triAngle));
        }
        ctx.fill();
      }
    }

    const renderCircle = (ctx, pStart, size) => {
      const { x, y } = this.cellcenter(pStart);
      ctx.beginPath();
      ctx.arc(x, y, size * 2, 0, 2 * Math.PI);
      ctx.fill();
    }

    const renderSquare = (ctx, pStart, size) => {
      const { x, y } = this.cellcenter(pStart);
      ctx.beginPath();
      ctx.moveTo(x - size, y - size);
      ctx.lineTo(x + size, y - size);
      ctx.lineTo(x + size, y + size);
      ctx.lineTo(x - size, y + size);
      ctx.lineTo(x - size, y - size);
      ctx.fill();
    };

    ctx.globalAlpha = 1;
    ctx.scale(scale, scale);
    ctx.translate(-canvasOffset.x, -canvasOffset.y);

    const renderBackground = (img) => {
      const scale = 0.5;
      const offset = new v2(150, 1100).mul(1 / scale);
      // ctx.filter = 'grayscale(1)';

      const { x: sx0, y: sy0 } = this.state.size;
      const { x: sx, y: sy } = this.state.size.mul(1 / scale);
      ctx.drawImage(img, offset.x, offset.y, sx, sy, 0, 0, sx0, sy0);
    };

    const screen = (p) => {
      const buffer = 100;
      const { x: sx, y: sy } = this.state.size;

      const center = this.cellcenter(p);
      const coord = center.sub(canvasOffset);
      // console.log(canvasOffset, center);
      if (coord.x < -buffer || coord.y < -buffer || coord.x > sx + buffer || coord.y > sy + buffer) {
        return false;
      }
      return true;
    };

    const renderCells = (cb) => {
      for (let j = 0; j < height; j++) {
        for (let i = 0; i < width; i++) {
          const p = qrFromOddr({ i, j });

          if (!screen(p)) {
            continue;
          }

          cb(p);
        }
      }
    };

    const renderCell = (p, size, color) => {
      const pos = this.cellcenter(p);

      ctx.fillStyle = color;
      renderHexagon(ctx, pos, size);
    }

    let mouseOverIdx = -1;
    let mouseOverCenterIdx = -1;
    if (mouseOver) {
      mouseOverIdx = world.idxqr(mouseOver);
      if (mouseOverIdx !== -1 && !missions.find((m) => m.p && qrEq(m.p, mouseOver))) {
        const { nearests: n0 } = storage[mouseOverIdx];
        if (n0.length > 0) {
          mouseOverCenterIdx = n0[0]?.idx ?? -1;
        }
        // console.log('mouseover', mouseOverIdx);
      }
    }

    /*
    const gamecursor = (p) => {
      if (!mouseOver) {
        return false;
      }
      const idx = world.idxqr(p);
      if (idx === -1) {
        return false;
      }
      const { nearests } = storage[idx];
      if (nearests.length > 0) {
        return nearests[0]?.idx === mouseOverCenterIdx;
      }
    }
    */

    const gameoffice = (p) => {
      const idx = world.idxqr(p);
      if (idx === -1) {
        return false;
      }
      const { centerstate, nearests } = storage[idx];
      if (centerstate?.safehouse) {
        return true;
      }
      if (nearests.length === 0) {
        return false;
      }
      if (nearests.length > 0) {
        return storage[nearests[0].idx].centerstate.safehouse;
      }
      return false;
    }

    const gamenear = (p) => {
      for (const p2 of qrNeighbors(p)) {
        const idx = world.idxqr(p2);
        if (idx < 0 || idx >= storage.length) {
          continue;
        }

        let { nearests } = storage[idx];
        if (nearests.length === 0) {
          continue;
        }

        if (!storage[nearests[0].idx]?.centerstate?.locked) {
          return true;
        }
      }
      return false;
    }

    // render background, stencil
    const intensity = 0.6;
    /*
    const intensity = (() => {
      return 0.6;
      if (isNaN(days)) {
        return 0.6;
      }
      const hours = (days - Math.floor(days)) * 24;
      let min = 0.2;
      let max = 0.6;
      if (simtps > 20) {
        min = 0.4;
        max = 0.5;
      }
      if (simtps > 50) {
        min = 0.45;
        max = 0.5;
      }
      const delta = max - min;

      if (hours < 6 || hours > 20) {
        return min;
      } else if (hours <= 8) {
        return ((hours - 6) / 2) * delta + min;
      } else if (hours > 18) {
        return ((20 - hours) / 2) * delta + min;
      } else {
        return max;
      }
    })();
    */

    if (!GAMEFACE) {
      ctx.globalAlpha = intensity ?? 0.6;

      ctx.save();
      ctx.beginPath();
      renderCells((p) => {
        if (!gamenear(p) || !gameoffice(p)) {
          return;
        }
        pathHexagon(ctx, this.cellcenter(p), size_cell - 0.2);
      });
      ctx.clip();
      renderBackground(this.img_map);
      ctx.restore();

      ctx.save();
      ctx.beginPath();
      renderCells((p) => {
        if (!gamenear(p) || gameoffice(p)) {
          return;
        }
        pathHexagon(ctx, this.cellcenter(p), size_cell - 0.2);
      });
      ctx.clip();
      renderBackground(this.img_map_gray);
      ctx.restore();
    }

    // cloud
    ctx.globalAlpha = 0.3;
    {
      const size = 2048;
      const scale = 2;
      const size1 = size / scale;

      const offset = (days * 100) % size1;
      for (let x = -1; x <= 1; x++) {
        for (let y = -1; y <= 1; y++) {
          let xx = x * size1 + offset;
          let yy = y * size1 - offset;
          ctx.drawImage(this.img_cloud, 0, 0, size, size, xx, yy, size1, size1);
        }
      }
    }

    ctx.globalAlpha = 1;

    const renderBorders = (borders) => {
      const {p: p0, i: i0} = borders[0];
      const pos0 = hexagonPoint(this.cellcenter(p0), size_cell, i0);
      ctx.moveTo(pos0.x, pos0.y);

      for (const { p, i } of borders) {
        const pos = hexagonPoint(this.cellcenter(p), size_cell, i + 1);
        ctx.lineTo(pos.x, pos.y);
      }
    };

    const renderBordersMid = (borders) => {
      const {p: p0, i: i0} = borders[0];
      const pos0 = hexagonPointMid(this.cellcenter(p0), size_cell, i0);
      ctx.moveTo(pos0.x, pos0.y);

      for (const { p, i } of borders) {
        const pos = hexagonPointMid(this.cellcenter(p), size_cell, i);
        ctx.lineTo(pos.x, pos.y);
      }
      ctx.lineTo(pos0.x, pos0.y);
    };

    // render borders
    {
      ctx.strokeStyle = COLORS.title0[0];
      ctx.lineWidth = 1;

      ctx.beginPath();
      for (const { borders } of world.centers) {
        renderBorders(borders);
        // renderBordersMid(borders);
      }
      ctx.stroke();
    }

    // render path
    ctx.fillStyle = 'black';
    for (const { s, t } of world.road) {
      renderPath(ctx, s, t, false, size_cell / 10);
    }

    // render facilities
    for (const { idx: center_idx, partitions } of centers) {
      const s = storage[center_idx].centerstate;
      for (const f of s.facilities) {
        const { idx, key } = f;
        if (!idx) {
          continue;
        }

        const p = world.qridx(idx);
        const { x, y } = this.cellcenter(p);

        const data = data_facilities.facilityByKey(key);

        ctx.fillStyle = (s.office && f.enabled) ? 'orange' : 'gray';
        renderSquare(ctx, p, size_cell / 4);
        ctx.fillText(L(data.name), x + 10, y);
      }
    }

    const renderSubdivMouseOver = true;
    const renderSubdivAll = false;
    const renderSubdivUnlocks = false;
    const renderSubdivUnlockBorder = true;

    // render subdivisions
    for (const { idx: center_idx, partitions } of centers) {
      const { visited } = partitions;
      const { unlocks } = storage[center_idx].centerstate;

      if (renderSubdivMouseOver) {
        renderCells((p) => {
          const idx = world.idxqr(p);
          if (storage[idx].nearests[0]?.idx !== center_idx) {
            return;
          }
          if (center_idx !== mouseOverCenterIdx) {
            return;
          }

          const mouseOverSubDiv = visited[mouseOverIdx];
          if (mouseOverSubDiv != visited[idx]) {
            return;
          }

          const subdiv = visited[idx];
          ctx.fillStyle = colors2[subdiv];
          renderSquare(ctx, p, size_cell / 6);
        });
      }

      if (renderSubdivUnlocks || renderSubdivAll) {
        renderCells((p) => {
          const idx = world.idxqr(p);
          if (storage[idx].nearests[0]?.idx !== center_idx) {
            return;
          }
          const subdiv = visited[idx];
          if (renderSubdivUnlocks && subdiv >= unlocks) {
            return;
          }

          ctx.fillStyle = colors2[subdiv];
          renderSquare(ctx, p, size_cell / 6);

          if (false) {
            const { x, y } = this.cellcenter(p);
            ctx.fillText(`${subdiv}`, x + 5, y);
          }
        });
      }

      if (unlocks > 0 && renderSubdivUnlockBorder) {
        const p = world.qridx(center_idx);
        const borders = world.borders(p, (idx) => {
          if (idx === center_idx) {
            return true;
          }
          if (storage[idx].nearests[0]?.idx !== center_idx) {
            return false;
          }
          const subdiv = visited[idx];
          if (subdiv >= unlocks) {
            return false;
          }
          return true;
        });

        ctx.strokeStyle = COLORS.highlight0[1];
        ctx.lineWidth = 1;
        ctx.beginPath();
        // renderBorders(borders);
        renderBordersMid(borders);
        ctx.stroke();
      }
    }

    // render missions
    let missionOverIdx = -1;
    for (const mission of missions) {
      if (this.props.hidemissions) {
        break;
      }
      if (!mission.p) {
        continue;
      }
      const { p } = mission;

      if (!screen(p)) {
        continue;
      }

      const { x, y } = this.cellcenter(p);
      const idx = world.idxqr(p);

      if (mission === missionOver) {
        missionOverIdx = idx;
        ctx.globalAlpha = 0.5;

        ctx.fillStyle = 'black';
        renderCell(p, size_cell * 2, 'black');
      }

      let color = 'blue';
      if (mission.milestone) {
        color = 'red';
      }
      if (mission.pending) {
        color = 'purple';
      }

      let avail = mission.avail;
      if (avail) {
        ctx.globalAlpha = 1;
      } else {
        ctx.globalAlpha = 0.3;
      }

      ctx.fillStyle = color;

      renderCircle(ctx, p, size_cell / 4);
      if (!avail) {
        continue;
      }

      let offset = p.r % 2 === 0 ? 0 : size_cell / 2;
      function writeLine(str) {
        writeLine0(x, y, offset, str);
        offset += lineheight;
      }

      ctx.globalAlpha = 1;
      let title = `#${idx}`;
      if (mission.pending) {
        title += L('loc_ui_string_worldmap_mission_ongoing');
      }
      if (mission === missionOver && mission.title) {
        title = `${title} ${mission.title}`;
      }
      writeLine(title);
      if (mission.milestone) {
        let msg = L('loc_ui_string_common_mission_milestone');
        writeLine(msg);
      } else if (mission.ty) {
        const has_reward = Object.values(mission.afterReward).find((v) => v > 0);
        let msg = `${mission.area.num + 1}${has_reward ? '*' : ''} / ${mission.client} / $${mission.resource}`;
        writeLine(msg);
      }
    }

    // render centers
    ctx.globalAlpha = 1;
    for (const { idx, borders } of world.centers) {
      const p = world.qridx(idx);
      if (!screen(p)) {
        continue;
      }

      const { x, y } = this.cellcenter(p);
      const { ty, centerstate } = storage[idx];

      if (idx === centerOver) {
        ctx.globalAlpha = 0.3;
        ctx.fillStyle = COLORS.title0[0];
        renderCell(p, size_cell * 2, 'black');
        ctx.globalAlpha = 1;
      }

      if (idx === mouseOverCenterIdx) {
        ctx.strokeStyle = COLORS.highlight0[0];
        ctx.lineWidth = 1;
        ctx.beginPath();
        renderBorders(borders);
        ctx.stroke();
      }

      // 게임 모드일 때 center 이름 덜 보여주기
      if (view === 'game') {
        const adjacent = blockconns.find((b) => b.from === idx && !storage[b.to].centerstate.locked);
        if (centerstate?.locked && !adjacent) {
          continue;
        }
      }

      // 임시로 색 바꾸기: 사무실 없는 센터는 회색으로
      let color = colors[ty];
      if (ty === CELL_CENTER) {
        if (!centerstate.office) {
          color = 'white';
        }
      }

      renderCell(p, size_cell - 2, color);

      let offset = p.r % 2 === 0 ? 0 : size_cell / 2;
      function writeLine(str) {
        writeLine0(x, y, offset, str);
        offset += lineheight;
      }

      ctx.fillStyle = fontStyle0;
      writeLine(L('loc_ui_string_worldmap_cursor_base') + centerstate.name);
    }

    let nodes = null;

    function renderPathTo(idx) {
      if (!selected_base_idx) {
        return;
      }
      if (!nodes) {
        nodes = world.distances0(world.qridx(selected_base_idx)).nodes;
      }

      let node = nodes[idx];
      while (node && node.parent.idx !== -1) {
        const s = node.p;
        const t = node.parent.p;
        renderPath(ctx, s, t, false, size_cell / 10);
        node = node.parent;
      }
    }

    if (mouseOver) {
      const idx = world.idxqr(mouseOver);
      const mission = missions.find((m) => m.idx === idx);
      if (mission) {
        ctx.fillStyle = 'gray';
        renderPathTo(idx);

        const color = COLORS.highlight0[1];
        renderCell(mouseOver, size_cell - 4, color);
      }
      const center = centers.find((c) => c.idx === idx);
      if (center) {
        const color = COLORS.highlight0[1];
        renderCell(mouseOver, size_cell - 4, color);
      }
    }

    if (selected_mission_idx) {
      ctx.fillStyle = 'white';
      renderPathTo(selected_mission_idx);
    }

    if (this.props.onRender) {
      this.props.onRender();
    }
  }

  // qr coordinate to browser screen coordinate
  screenPos(p) {
    const pos = this.cellcenter(p);
    const { canvasOffset, scale } = this.state;
    return this.canvasToScreen(pos, canvasOffset, scale);
  }

  canvasCursor(type, e) {
    const canvas = this.canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    if (type === "touch") {
      e = e.touches[0];
    }

    let x = e.clientX - rect.left;
    let y = e.clientY - rect.top;

    const viewWidth = canvas.width;
    const viewHeight = canvas.height;

    if (x < 0 || y < 0 || x > viewWidth || y > viewHeight) {
      return null;
    }

    return new v2(x, y);
  }

  clampCanvasOffset(offset) {
    const clamp = function(x, min, max) {
      return Math.min(Math.max(x, min), max);
    }

    const { sim, scale } = this.state;

    const { width, height } = sim.world;
    const { viewWidth, viewHeight } = this.viewSize();

    const maxX = width - viewWidth / scale;
    const maxY = height - viewHeight / scale;
    return new v2(
      clamp(offset.x, 0, maxX),
      clamp(offset.y, 0, maxY),
    );
  }

  // right-drag to scroll
  onMouseDown(type, e) {
    if (e.button !== 2) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();

    const cursor = this.canvasCursor(type, e).mul(1 / this.state.scale);
    if (!cursor) {
      return;
    }
    e.preventDefault();
    const { canvasOffset } = this.state;
    this.setState({
      click: cursor.add(canvasOffset),
    });
  }

  onMouseUp(_e) {
    this.setState({
      click: null
    })
  }

  onMouseMove(type, e) {
    const { click } = this.state;
    if (!click) {
      return
    }

    const cursor = this.canvasCursor(type, e).mul(1 / this.state.scale);
    if (!cursor) {
      return;
    }

    const nextOffset = click.sub(cursor);
    this.setState({
      // canvasOffset: this.clampCanvasOffset(nextOffset),
      canvasOffset: nextOffset,
    })
  }

  onMouseMoveCanvas(ev) {
    this.onMouseMove('mouse', ev);

    const { size_cell } = this;
    const { paint, mouseOver, canvasOffset, scale } = this.state;
    const coord = this.screenToCanvas(this.canvasCursor("mouse", ev), canvasOffset, scale);
    if (!coord) {
      this.setState({ mouseOver: null });
      return;
    }
    const p = qrFromCoord(coord, size_cell);

    if (mouseOver !== null && qrEq(p, mouseOver)) {
      /*
        if (paint) {
          this.onClickEdit(p);
        }
      */

      if (this.props.onMouseMove) {
        this.props.onMouseMove(p);
      }
    }

    if (mouseOver && p && paint && !qrEq(p, mouseOver)) {
      const { world } = this.props;
      let s = mouseOver;
      let t = p;
      if (qrCmp(s, t) < 0) {
        [s, t] = [t, s];
      }
      let found = world.road.findIndex((r) => qrEq(r.s, s) && qrEq(r.t, t));
      if (found !== -1) {
        world.road.splice(found, 1);
      } else {
        world.road.push({ s, t });
      }

      /* eslint-disable */
      this.state.mouseOver = p;
      /* eslint-enable */
    }

    this.setState({ mouseOver: p });
  }

  onMouseOutCanvas() {
    if (this.props.onMouseMove) {
      this.props.onMouseMove(null);
    }

    this.setState({ click: null, mouseOver: null });
  }

  onClickCanvas(ev) {
    const { size_cell } = this;
    const { canvasOffset, scale } = this.state;
    const { world, edit } = this.props;

    const coord = this.screenToCanvas(this.canvasCursor("mouse", ev), canvasOffset, scale);
    if (!coord) {
      return;
    }

    const p = qrFromCoord(coord, size_cell);
    if (world.idxqr(p) === -1) {
      return;
    }

    if (edit && this.onClickEdit(p)) {
      const { world } = this.props;
      world.recalculate();
    }

    if (this.props.onSelect) {
      this.props.onSelect(p);
    }
  }

  onClickEdit(p) {
    const { world } = this.props;
    const { storage } = world;
    const { selectedColor } = this.state;
    const idx = world.idxqr(p);
    if (idx === -1) {
      return false;
    }

    storage[idx].ty = selectedColor;
    if (selectedColor === 1) {
      storage[idx].centerstate = centerState();
    } else {
      delete (storage[idx].centerstate);
    }

    this.setState({ storage });
    return true;
  }

  render() {
    const { size: cs } = this.state;

    const renderScale = window.devicePixelRatio;

    return <canvas ref={this.canvasRef} className="world2-canvas"
      width={cs.x * renderScale}
      height={cs.y * renderScale}
      style={{ width: cs.x, height: cs.y }}
      onMouseDown={(ev) => this.onMouseDown('mouse', ev)}
      onMouseMove={(ev) => this.onMouseMoveCanvas(ev)}
      onMouseUp={(ev) => this.onMouseUp(ev)}
      onMouseOut={(ev) => this.onMouseOutCanvas(ev)}
      onClick={(ev) => this.onClickCanvas(ev)}
    />
    // <p>transition={JSON.stringify(this.props.world.transition)}</p>
  }
}

export class WorldView2 extends React.Component {
  constructor(props) {
    super(props);

    this.textareaRef = React.createRef();
    this.keyDown = this.onKeyDown.bind(this);

    this.timer = null;
    this.last_ms = Date.now();
    this.onTimer = () => {
      let { last_ms } = this;
      let { simtps, world } = this.state;
      const now = Date.now();

      let idx = 0;
      while (last_ms < now && idx < 10) {
        last_ms += 1000 / simtps;
        world.onTick();
        idx += 1;
      }

      this.startTimer();
      this.last_ms = last_ms;
      this.setState({ world });
    };

    const world = new WorldState2();
    const edit = true;

    this.state = {
      world,

      // block, conflict, neutral
      view: edit ? 'geography' : 'game',
      simtps: TICK_PER_DAY * 2,
      paused: true,

      centerOver: -1,
      missionOver: null,

      edit,

      paint: false,
    };
  }

  componentDidMount() {
    if (!this.state.paused) {
      this.startTimer();
    }

    document.addEventListener('keydown', this.keyDown);
  }

  componentWillUnmount() {
    this.stopTimer();

    document.removeEventListener('keydown', this.keyDown);
  }

  startTimer() {
    const { simtps } = this.state;
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.last_ms = Date.now();
    this.timer = setTimeout(this.onTimer, 1000 / simtps);
  }

  stopTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  onKeyDown(ev) {
    if (ev.key === ' ') {
      const { paused } = this.state;
      ev.preventDefault();
      if (paused) {
        this.startTimer();
      } else {
        this.stopTimer();
      }
      this.setState({ paused: !paused });
    }
  }

  onSave() {
    const { world } = this.state;
    this.textareaRef.current.value = JSON.stringify(world.serialize());
  }

  onLoad() {
    const obj = JSON.parse(this.textareaRef.current.value);
    const { world } = this.state;
    world.deserialize(obj);

    this.setState({ world });
  }

  renderBlock(center, center_idx) {
    const { world, centerOver } = this.state;
    const { storage, blockconns } = world;
    const { idx } = center;

    let s = storage[idx];

    const { centerstate: { name, strength, unlocks } } = s;
    const updateStr = (str) => {
      s.centerstate.strength = str;
      world.recalculate();
    };

    const updateUnlocks = (v) => {
      s.centerstate.unlocks = v;
      this.setState({ world });
    };

    const neighbors = blockconns.filter((b) => b.from === idx).map((b) => storage[b.to].centerstate.name).join(', ');

    const onCenterOver = (center) => {
      this.setState({ centerOver: center });
    };

    let cls = 'box';
    if (idx === centerOver) {
      cls += ' over';
    }

    return <div key={center_idx} className={cls}
      onMouseOver={() => onCenterOver(idx)}
      onMouseLeave={() => onCenterOver(-1)}
    >
      <input value={name} onChange={(ev) => {
        storage[idx].centerstate.name = ev.target.value;
        this.setState({ world });
      }} />
      center #{center_idx}({idx})

      <br />
      strength={strength}
      <button onClick={() => updateStr(strength + 1)}>+</button>
      <button onClick={() => updateStr(strength - 1)}>-</button>

      unlocks={unlocks}
      <button onClick={() => updateUnlocks(unlocks + 1)}>+</button>
      <button onClick={() => updateUnlocks(unlocks - 1)}>-</button>
    </div>;

    // <p>neighbors={neighbors}</p>
  }

  renderMission(s) {
    const { tick, world } = this.state;
    const { mission, distance, expire_at, conflict_ty, nearests } = s;
    const { ty } = mission;

    const missionOver = this.missionOver;

    const days = ((expire_at - tick) / TICK_PER_DAY).toFixed(0);
    const idx = world.idxqr(s.p);

    const onMissionOver = (s) => {
      this.setState({ missionOver: s });
    };

    let cls = (s === missionOver) ? 'entity-over' : '';
    cls += " box";

    return <div key={idx} className={cls}
      onMouseOver={() => onMissionOver(s)}
      onMouseOut={() => onMissionOver(null)}>
      mission #{idx}, expire={days} days, belongs={nearests.map(({ idx }) => `#${idx}`).join(",")} <br />
      ty={ty}/{conflict_ty}, distance={distance}
    </div>;
  }

  onView(view) {
    this.setState({ view });
  }

  render() {
    const { world, centerOver, edit, view, mouseOver } = this.state;
    const { tick, centers, missions } = world;

    return <>
      <div className="world2-canvas-bg">
        <WorldCanvas world={world} view={view}
          centerOver={centerOver}
          edit={edit}
          onMouseMove={mouseOver => this.setState({ mouseOver })}
        />
      </div>

      <div className="world2-info">
        <WorldViewButtons view={view} onView={this.onView.bind(this)} />
        date={tickToDateStr(tick)}
        <p>mouseOver={JSON.stringify(mouseOver)}</p>
        <input type="checkbox" value={edit} onChange={(ev) => this.setState({ edit: ev.target.value })} />edit

        <div className="box">
          missions
          {missions.map((mission) => this.renderMission(mission))}
        </div>
        <div className="box">
          centers
          {centers.map((center, idx) => this.renderBlock(center, idx))}
        </div>
        <div>
          <textarea ref={this.textareaRef} />
          <button onClick={this.onSave.bind(this)}>save</button>
          <button onClick={this.onLoad.bind(this)}>load</button>
        </div>
      </div>
    </>;

    // <p>transition={JSON.stringify(transition)}</p>
  }
}

export class FacilityTableView extends React.Component {
  render() {
    const { data } = this.props;

    return <table>
      <tbody>
        {Object.entries(data).map(([key, arr]) => {
          return <tr key={key}>
            <td>{key}</td>
            {arr.map((v, i) => {
              return <td key={i}>{v}</td>;
            })}
          </tr>;
        })}
      </tbody>
    </table>;
  }
}
