import { useState } from 'react';
import dayjs from 'dayjs';

import { Checklist } from './FacilityView';
import { L } from './localization.mjs';

import {
  FH1ButtonTiny as Button,
  FH1ButtonFoldable as ButtonFoldable,
} from './component/figma/fh1';

export function questObjective(game) {
  const quest = game.questTracker.onProgress.find((q) => q.expires_at > 0);
  if (!quest) {
    return null;
  }

  const conds = quest.objectives.map((o, i) => {
    return { done: o.completed, label: L(quest.instruction).replace(/\r/g, '').split('\n')[i] };
  });

  return <div className="report-quest">
    <div className="report-quest-header">
      {L('loc_ui_string_quest_ongoing') + ": " + L(quest.title)}
      {questExpire(game.turn, quest)}
    </div>
    <div className="report-quote">
      {L(quest.description)}
    </div>
    <Checklist conditions={conds} />
  </div>;
}

export function questClaimable(quest) {
  for (const objective of quest.objectives) {
    if (!objective.completed) {
      return false;
    }
  }
  return true;
}

export function questRewardString(quest) {
  if (quest.rewards.length === 0) {
    return null;
  }
  return quest.rewards.map((r) => {
    switch (r.key) {
      case 'resource':
        return L('loc_dynamic_string_quest_reward_resource', { value: r.value });
      case 'firearm':
        return L('loc_dynamic_string_common_item_tier') + r.value[1] + ' ' + L(firearm_ty_name[r.value[0]]);
      default:
        break;
    }
    return '';
  }).join(', ');
}

export function questExpire(turn, quest) {
  if (quest.expires_at > 0) {
    return <div className='overlay-quest-expire'>
      다음 시간 안에 완료해야 함: {dayjs.duration(quest.expires_at - turn, 'hour').humanize()}
    </div>;
  }
  return null;
}

export function questTitle(quest) {
  let title = L(quest.title);
  if (quest.type === 'tutorial') {
    title = `튜토리얼: ${title}`;
  }
  return title;
}

export function QuestElement(props) {
  const { tick, quest, onClaim } = props;
  const claimable = !!onClaim;

  let itemClassName = claimable ? 'flexright-questitem-claimable' : 'flexright-questitem';
  if (quest.type === 'milestone') {
    itemClassName += ' special';
  }
  const [showQuestDetail, setQuestDetail] = useState(false);

  const rewardString = questRewardString(quest);
  const conds = quest.objectives.map((o, i) => ({ done: o.completed, label: L(quest.instruction).replace(/\r/g, '').split('\n')[i] }));

  let description;
  if (showQuestDetail) {
    description = <div className='box desc'>{L(quest.description)}</div>
  }

  let btn = null;
  if (rewardString) {
    L('loc_dynamic_string_quest_reward', { rewardString });
  }
  if (claimable) {
    if (rewardString) {
      btn = <Button onClick={() => onClaim(quest)}>{L('loc_dynamic_button_quest_claim_reward', { rewardString })}</Button>;
    } else {
      btn = <Button onClick={() => onClaim(quest)}>{(quest.type === 'tutorial' ? L('loc_ui_title_modal_tutorial_finish') : L('loc_ui_title_modal_quest_finish'))}</Button>;
    }
  }


  return <div className={itemClassName}>
    <div className='flexright-questitem-title'>
      <ButtonFoldable label={questTitle(quest)} folded={!showQuestDetail} onClick={() => {
        setQuestDetail(!showQuestDetail)
      }} />
    </div>
    {description}
    {questExpire(tick, quest)}
    <Checklist conditions={conds} />
    <div>
      {btn}
    </div>
  </div>;
}

