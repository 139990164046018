import _ from 'lodash';

import { facilities as data_facilities } from '../../data/google/processor/data_facilities.mjs';

import { TICK_PER_DAY } from '../../tick.mjs';
import { CONTRACT_OPTIONS, contractDetails0, contractRenew } from '../../contract.mjs';
import { TEMPORAL_DEPARTMENT_KEY } from '../../department.mjs';
import { departmentName, getPercentageText, getSuccessRateText } from '../../DepartmentView';

import { convertAgent } from '../../figAdapter';

import { Bulletitem } from '../anima/src/components/Bulletitem';
import { PanelContractPlan } from '../anima/src/components/PanelContractPlan';
import { PanelSummary } from '../anima/src/components/PanelSummary';
import { ThebluebuttonSi as GoodButton } from '../anima/src/components/ThebluebuttonSi';
import { TheredbuttonSi as BadButton } from '../anima/src/components/TheredbuttonSi';


function calculate_final_fee(game, work_key, department, pending, fee) {
  const props = game.getDepartmentPendingSuccessProps(work_key, department, pending);
  const { discount } = props;
  return fee * (1.0 - discount);
}

export const AgentSummaryContractWrapper = (props) => {
  const { game, agent, negotiated, avail, onDone, onContract, tooltipHandlers,
    option: option0, onChangeOption,
    depart_idx = 0, onChangeDepartIdx,
  } = props;
  const { world, turn, global_modifier, departmentRoot } = game;

  const {
    onTooltipShow = () => { },
    onTooltipHide = () => { },
    onTooltipMove = () => { },
    lookup = () => { },
  } = tooltipHandlers ?? {};

  const options_readonly = !negotiated;

  const contract = agent?.contract;
  const agenda = contract?.agenda;
  const option1 = option0 ?? contract?.option ?? {};

  const disabled = {};
  const option = {};
  for (const opt of CONTRACT_OPTIONS) {
    disabled[opt.key] = false;
    option[opt.key] = option1[opt.key] ?? false;
  }

  if (agenda === 'mod_agent_16_agenda_frequent') {
    disabled['long'] = true;
    option['long'] = false;
  }
  if (agenda === 'mod_agent_18_agenda_wellbeing') {
    disabled['advanced'] = true;
    option['advanced'] = true;
  }
  if (agenda === 'mod_agent_17_agenda_safety') {
    disabled['danger'] = true;
    option['danger'] = true;
  }

  if (!_.isEqual(option, option0)) {
    onChangeOption && onChangeOption(option);
  }

  if (!agent) {
    return null;
  }

  // DepartmentView에서 적당히 훔쳐옴
  // TODO: 적당히 합치기
  const work_key = 'recruit_agent';
  const departments = departmentRoot.departments
    .filter((d) => data_facilities.find((f) => f.key === d.key)?.tasks.find((t) => t.work_key === work_key))
    .filter((d) => !(d.head_id < 0));
  if (departments.length === 0) {
    const temporary_department = departmentRoot.departments.find((d) => d.key === TEMPORAL_DEPARTMENT_KEY);
    if (temporary_department) {
      departments.push(temporary_department);
    }
  }

  const set_depart_idx_next = () => {
    const incr = depart_idx + 1;
    const next = (incr >= departments.length) ? 0 : incr;
    onChangeDepartIdx(next);
  };

  const department = departments[depart_idx];
  const department_working = departmentRoot.departments.find((d) => d.pendings.find((p) => p.work_key === work_key && p.var1.idx === agent.idx));
  const pending_plan = departmentRoot.createPending(work_key, department, agent, null);
  const pending_working_idx = department_working?.pendings?.findIndex((p) => p.var1?.idx === agent.idx);
  const pending_working = department_working?.pendings[pending_working_idx];
  const remain_days = Math.ceil((pending_working?.ticks ?? 0) / TICK_PER_DAY);
  const success_rate_text = getSuccessRateText(work_key);
  const percentage_text = getPercentageText(pending_plan);
  const onAddPending = () => departmentRoot.addPending(department, pending_plan, game);
  const onRemovePending = negotiated ?
    null :
    () => {
      departmentRoot.removePending(department, pending_working_idx, game);
      onDone && onDone();
    };

  const { start, term } = contract;
  const contracted = term > 0;
  const renewal_needed = contracted && (start + term <= turn);
  const allow_change_depart = !negotiated && avail && !pending_working && !contracted;

  const a = convertAgent({ agent, turn });
  const preview = contractRenew(turn, agent, option, global_modifier);
  const details = contractDetails0(preview, turn);
  const prev_details = contractDetails0(agent.contract, turn);

  const dangerpay = option.danger ? 250 : 0;
  // TODO: 상수
  const injury = option.advanced ? 10 : 5;
  // TODO: 상수

  const final_fee = calculate_final_fee(game, work_key, department, pending_plan, contract.turnCost);

  const style_container = {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    top: 106,
    left: 14,
  };
  const style_panel_1 = {};
  const style_panel_2 = {
    position: 'relative',
    top: 6,
    left: 4,
  };

  return (
    <>
      <div style={style_container}>
        <div style={style_panel_1}>
          <PanelSummary
            override1={a.portrait}
            override={<>
              {a.traits.map((i) =>
                <Bulletitem
                  key={i.key}
                  ptBulletItemContent={i.name}
                  onMouseOver={() => onTooltipShow(lookup('trait', { key: i.key }))}
                  onMouseOut={onTooltipHide}
                  onMouseMove={onTooltipMove}
                />
              )}
            </>}
            contentArea={<>
              {a.modifier.map((i) =>
                <Bulletitem
                  key={i.key}
                  ptBulletItemContent={i.name}
                  onMouseOver={() => onTooltipShow(lookup('modifier', { key: i.key }))}
                  onMouseOut={onTooltipHide}
                  onMouseMove={onTooltipMove}
                />
              )}
            </>}
            itemBoxPtValue={`${a.age}`}
            itemBoxPtValue1={a.nationality.nation}
            itemBoxPtValue2={a.language}
            itemBoxPtValue3={a.background.name}

            itemBoxOnClick={() => { }}
            itemBoxOnMouseOver={() => onTooltipShow(lookup('background', { key: a.background.key }))}
            itemBoxOnMouseOut={onTooltipHide}
            itemBoxOnMouseMove={onTooltipMove}

            ptGrade={a.grade /* TODO */}
            ptMaxHp={`${a.life_max}`}
            ptOverall={`${a.overall_cap.toFixed(1)}`}
            ptPhysical={`${a.physical_cap.toFixed(1)}`}
            statGradePtGrade={a.growth_grade}
            statGradePtGrade1={a.grade /* TODO */}
            statGradePtGrade2={a.physical_grade}
            ptFirearmRate={`${a.firearm?.firearm_rate}`}
            ptFirearmTy={a.firearm?.firearm_ty.toUpperCase()}
            ptFirearmName={a.firearm?.firearm_name}
            firearmShapeTy={`ty${a.firearm?.firearm_ty?.toLowerCase()}-1`}
            ptVestName={a.equipment?.vest_name}
          />
        </div>
        <div style={style_panel_2}>
          <PanelContractPlan
            ptType={details.ty}
            ptPeriod={details.remaining}
            ptHireFee={renewal_needed ? `${prev_details.turn_cost} → ${details.turn_cost}` : `${details.turn_cost}`}
            ptDeployCost={renewal_needed ? `${prev_details.mission_cost} → ${details.mission_cost}` : `${details.mission_cost}`}
            ptProfitShareRatio={renewal_needed ? `${prev_details.profit_share_rate} → ${details.profit_share_rate}` : `${details.profit_share_rate}`}
            ptInjuryExpense={`$${injury.toLocaleString('en-US')} /day`}
            ptHazardPay={`$${dangerpay.toLocaleString('en-US')}`}
            ptDepartmentName={departmentName(department, world)}
            ptNegoProbLabel={success_rate_text}
            ptNegoProb={percentage_text}
            ptCostVar={`$${final_fee.toFixed(1)} /month`}

            pbShowDepartment={avail && !negotiated && !contracted}
            pbShowDepartmentChangeButton={allow_change_depart}
            simpleChangeButtonOnClick={set_depart_idx_next}

            pbShowOptions={!options_readonly}
            contractPlanOptionOnClick={() => options_readonly || disabled['long'] || onChangeOption({ ...option, long: !option['long'] })}
            contractPlanOptionOnClick1={() => options_readonly || disabled['advanced'] || onChangeOption({ ...option, advanced: !option['advanced'] })}
            contractPlanOptionOnClick2={() => options_readonly || disabled['danger'] || onChangeOption({ ...option, danger: !option['danger'] })}
            contractPlanOptionVarState2={`${option['long'] ? 'checked' : 'unchecked'}-${(options_readonly || disabled['long']) ? 'disabled' : 'enabled'}`}
            contractPlanOptionVarState={`${option['advanced'] ? 'checked' : 'unchecked'}-${(options_readonly || disabled['advanced']) ? 'disabled' : 'enabled'}`}
            contractPlanOptionVarState1={`${option['danger'] ? 'checked' : 'unchecked'}-${(options_readonly || disabled['danger']) ? 'disabled' : 'enabled'}`}

            override={
              <div style={{ position: 'absolute', top: 340, left: 2 }}>
                <GoodButton
                  onClick={
                    negotiated ?
                      avail ?
                        renewal_needed ?
                          () => {
                            onContract && onContract(agent.contract.ty, agent, option);
                            onDone && onDone();
                          } :
                          () => {
                            onContract && onContract(agent.contract.ty, agent, option);
                            onDone && onDone();
                          } :
                        null :
                      department_working ?
                        null :
                        () => {
                          onAddPending();
                          onDone && onDone();
                        }
                  }
                  ptLabel={
                    negotiated ?
                      avail ?
                        renewal_needed ?
                          '계약 갱신하기' :
                          '고용 계약하기' :
                        '자리없음' :
                      department_working ?
                        `고용 협상 진행중: ${remain_days}일 남음` :
                        '고용 협상 시작하기'
                  }
                  style={{}}
                  varState={
                    negotiated ?
                      avail ?
                        'enabled' :
                        'disabled' :
                      department_working ?
                        'disabled' :
                        'enabled'
                  }
                />
                {
                  (!negotiated && !department_working) ?
                    null
                    : <BadButton
                      onClick={
                        negotiated ?
                          avail ?
                            renewal_needed ?
                              () => {
                                !agent.state && onContract && onContract(null, agent, option);
                                onDone && onDone();
                              } :
                              () => {
                                onContract && onContract(null, agent, option);
                                onDone && onDone();
                              } :
                            () => {
                              onContract && onContract(null, agent, option);
                              onDone && onDone();
                            } :
                          onRemovePending
                      }
                      ptLabel={
                        negotiated ?
                          avail ?
                            renewal_needed ?
                              agent.state ?
                                `갱신안하기불가능(${agent.state})` :
                                '계약 갱신하지 않기' :
                              '계약하지 않기' :
                            contracted ?
                              '갱신불가(자리없음)' :
                              '신규불가(자리없음)' :
                          '협상 취소'
                      }
                      style={{}}
                      varState={agent.state ? 'disabled' : 'enabled'}
                    />
                }
              </div>
            }
          />
        </div>
      </div>
    </>
  );
};
