import React from 'react';
import { mat4 } from 'gl-matrix';

const angle = 10 / 180 * Math.PI;
const perspective = 400;
const dist = 400;

function transform(sx, sy) {
  const py = dist + Math.cos(angle) * sy;
  const pz = -Math.sin(angle) * sy;

  let m = mat4.create();
  m[11] = -1 / perspective;
  m[14] = 1 - pz / perspective;

  return mat4.translate(mat4.create(), m, [sx, py, -pz]);
}

function transform_tile(sx, sy) {
  return mat4.multiply(mat4.create(), transform(sx, sy), mat4.fromXRotation(mat4.create(), angle));
}

export class WorldView3 extends React.Component {
  render() {

    const st = (sx, sy) => {
      return {
        transform: `matrix3d(${transform_tile(sx, sy).join(',')})`,
      };
    }

    const tile_size = 256;
    const stt = (x, y, color) => {
      return {
        ...st(x * tile_size, y * tile_size),
        width: tile_size,
        height: tile_size,
        backgroundColor: color,
        position: 'absolute',
        top: `calc(50vh - ${tile_size / 2}px)`,
        left: `calc(50vw - ${tile_size / 2}px)`,
      };
    }

    const sb = (sx, sy) => {
      return {
        transform: `matrix3d(${transform(sx, sy).join(',')})`,
      };
    }

    const stb = (x, y, color) => {
      return {
        ...sb(x * tile_size, y * tile_size - 50),
        width: 100,
        height: 100,
        backgroundColor: color,
        position: 'absolute',
        bottom: `calc(50vh - ${100 / 2}px)`,
        left: `calc(50vw - ${100 / 2}px)`,
      };
    };

    let size = 8;
    let tiles = [];
    for (let x = -size; x <= size; x++) {
      for (let y = -size; y <= size; y++) {
        const color = (x + y) % 2 === 0 ? 'red' : 'blue';
        tiles.push(<div key={`${x}_${y}`} style={stt(x, y, color)} />);
        tiles.push(<div key={`bb_${x}_${y}`} style={stb(x, y, 'white')} />);
      }
    }

    return <div style={{
      width: '100vw',
      height: '100vh',
      position: 'relative',
      overflow: 'hidden',
      background: 'black',
    }}>
      {tiles}
    </div>;
  }
}


