/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { PgbTooltipContent } from "../PgbTooltipContent";
import "./style.css";

export const PgbtooltipSi = ({
  style = "D",
  override = (
    <PgbTooltipContent className="PGB-tooltip-content-instance" ptDesc="TOOLTIP_DESC" ptTitle="TOOLTIP_TITLE" />
  ),
}) => {
  return (
    <div className="pgbtooltip-SI" style={typeof style === "object" ? style : null}>
      <div className="content-3">
        <div className="div-3">
          <img className="subtract-2" alt="Subtract" src="/anima/img/subtract-3.svg" />
        </div>
        <div className="div-3">
          <div className="overlap-group-11">
            <img className="rectangle-3" alt="Rectangle" src="/anima/img/rectangle-362-1.svg" />
            <img className="rectangle-4" alt="Rectangle" src="/anima/img/rectangle-367-1.svg" />
          </div>
        </div>
        <div className="div-3">
          <img className="subtract-3" alt="Subtract" src="/anima/img/subtract-5.svg" />
        </div>
        <div className="style-dummy"></div>
        <div className="contents-holder">{override}</div>
      </div>
    </div>
  );
};

PgbtooltipSi.propTypes = {
  style: PropTypes.any,
};
