
const lerp = function(a, b, t) {
  return a + (b - a) * t;
}

export function dirnorm(d) {
  while (d > Math.PI * 2) {
    d -= Math.PI * 2;
  }
  while (d < 0) {
    d += Math.PI * 2;
  }
  if (d === Math.PI * 2) {
    d = 0;
  }
  return d;
}

export function dirnorm0(d) {
  while (d > Math.PI) {
    d -= Math.PI * 2;
  }
  while (d < -Math.PI) {
    d += Math.PI * 2;
  }
  return d;
}

export function dircontains(dir, mindir, maxdir) {
  while (dir < mindir) {
    dir += Math.PI * 2;
  }
  return dir < maxdir;
}

export class v2 {
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }

  static fromdir(dir) {
    const x = Math.sin(dir);
    const y = -1 * Math.cos(dir);
    return new v2(x, y);
  }

  static unit(x) {
    return new v2(x, x);
  }

  static zero() {
    return new v2(0, 0);
  }

  static from({ x, y }) {
    return new v2(x, y);
  }

  clone() {
    return new v2(this.x, this.y);
  }

  eq(other) {
    return this.x === other.x && this.y === other.y;
  }
  add(other) {
    return new v2(this.x + other.x, this.y + other.y);
  }
  sub(other) {
    return new v2(this.x - other.x, this.y - other.y);
  }
  mul(v) {
    return new v2(this.x * v, this.y * v);
  }
  len() {
    return Math.sqrt(this.x * this.x + this.y * this.y);
  }
  norm() {
    const len = this.len();
    return new v2(this.x / len, this.y / len);
  }
  dist(other) {
    return this.sub(other).len();
  }
  rot(o, heading) {
    const x = this.x - o.x;
    const y = this.y - o.y;
    const dx = Math.cos(heading) * x + Math.sin(heading) * y;
    const dy = -1 * Math.sin(heading) * x + Math.cos(heading) * y;
    return o.add(new v2(dx, dy));
  }
  dir() {
    return Math.atan2(this.y, this.x) + Math.PI / 2;
  }
  p(f) {
    return new v2(f(this.x), f(this.y));
  }
  round() {
    return this.p(Math.round);
  }
  cmp(other) {
    if (this.x !== other.x) { return this.x - other.x }
    if (this.y !== other.y) { return this.y - other.y }
    return 0;
  }
  hash() {
    return `${this.x.toFixed(2)},${this.y.toFixed(2)}`;
  }
  hash2(n) {
    return `${Math.floor(this.x / n)},${Math.floor(this.y / n)}`;
  }
  min(other) {
    return new v2(Math.min(this.x, other.x), Math.min(this.y, other.y));
  }
  max(other) {
    return new v2(Math.max(this.x, other.x), Math.max(this.y, other.y));
  }
  inner(other) {
    return this.x * other.x + this.y * other.y;
  }

  static lerp(a, b, t) {
    return new v2(lerp(a.x, b.x, t), lerp(a.y, b.y, t));
  }
  static ccw(a, b, c) {
    return (b.x - a.x) * (c.y - a.y) - (b.y - a.y) * (c.x - a.x);
  }
  static intersect(a, b, c, d) {
    return v2.ccw(a, b, c) * v2.ccw(a, b, d) < 0 && v2.ccw(c, d, b) * v2.ccw(c, d, a) < 0;
  }
  static intersect_t(a, b, c, d) {
    const nominator = (a.x - c.x) * (c.y - d.y) - (a.y - c.y) * (c.x - d.x);
    const denominator = (a.x - b.x) * (c.y - d.y) - (a.y - b.y) * (c.x - d.x);
    return nominator / denominator;
  }

  static polygonAABB(points) {
    let tl = points[0];
    let br = points[0];
    for (const p of points) {
      tl = tl.min(p);
      br = br.max(p);
    }
    return { tl, br };
  }

  static centroid(points) {
    let sumX = 0;
    let sumY = 0;
    for (const p of points) {
      sumX += p.x;
      sumY += p.y;
    }
    return new v2(sumX / points.length, sumY / points.length);
  }
}
