import _ from 'lodash';

import { opts } from './opts.mjs';
import { dirnorm0, v2 } from './v2.mjs';

const AIM_ARC = false;

export const COLORS = {
  BACKGROUND: 'rgb(40, 40, 40)',
  GOAL: 'purple',
  FULL: 'lightgray',
  DOOR: 'darkcyan',

  BORDER: 'lightgray',

  FOG: [0, 0, 0],

  LABEL_OBJ: 'gray',
  LABEL_THROWABLE: 'red',
  LABEL_AIMTARGET: 'yellow',
  LABEL_OBJECT: 'lightgray',

  // 효과 영역 라벨 텍스트
  LABEL_EFFECT: 'yellow',

  THROWABLE: 'red',
  THROWABLE_TRAJECTORY: 'blue',

  // 명중한 trail
  TRAIL_HIT: 'white',
  // 빗맞은 trail
  TRAIL_MISS: 'gray',

  // 왼쪽 상단 디버그 정보
  LABEL_INFO: 'white',

  TEAM_COLORS: [
    'lightgreen',
    'red',
    'cyan',
  ],

  DEAD: 'gray',

  SPAWNAREA: 'yellow',

  // bubbles
  BUBBLE_FILL: 'gray',
  BUBBLE_TEXT: 'white',
  BUBBLE_BORDER: 'black',

  DEBUG_MST: 'cyan',
  DEBUG_RISK: 'white',
  DEBUG_RISK_MAX: 'red',
  DEBUG_SCORE: 'green',
  DEBUG_DIST_REACHABLE: 'green',
  DEBUG_DIST_UNREACHABLE: 'red',
  // mouseover했을 때 나오는 debug aim
  DEBUG_AIM: 'blue',
  DEBUG_LABEL_GRID: 'white',
};

export const COLORS_ALT = {
  ...COLORS,

  BACKGROUND: 'rgb(43, 43, 50)',
  FULL: 'rgb(137, 114, 86)',
  BORDER: 'orange',
  DOOR: 'rgb(255, 120, 0)',

  SPAWNAREA: 'rgb(255, 120, 0)',

  TRAIL_HIT: 'rgb(66, 255, 0',
  TRAIL_MISS: 'gray',

  FOG: [20, 20, 24],

  LABEL: 'rgb(241, 229, 143)',

  LABEL_INFO: 'orange',

  TEAM_COLORS: [
    'rgb(45, 240, 130)',
    'rgb(255, 0, 75)',
    'cyan',
  ],

  DEBUG_AIM: 'rgb(127, 255, 117)',
  DEBUG_LABEL_GRID: 'white',

};


function renderGrid(ctx, world, grid, styleFn) {
  if (styleFn === undefined) {
    styleFn = (val) => `rgba(${Math.floor(val * 255)}, 0, 0, 0.3)`;
  }
  let idx = 0;
  for (let y = 0; y < world.grid_count_y; y++) {
    for (let x = 0; x < world.grid_count_x; x++) {
      const posX = x * opts.GRID_SIZE - world.width / 2;
      const posY = y * opts.GRID_SIZE - world.height / 2;

      const style = styleFn(grid[idx]);
      if (style) {
        ctx.fillStyle = style;
        ctx.fillRect(posX, posY, opts.GRID_SIZE, opts.GRID_SIZE);
      }

      idx += 1;
    }
  }
  // reset alpha
  ctx.fillStyle = 'rgba(0,0,0,1)';
}

const clamp = function(x, min, max) {
  return Math.min(Math.max(x, min), max);
}

function formatCoord(coord) {
  return `[${coord.x.toFixed(0)},${coord.y.toFixed(0)}]`;
}

const CAMERATEST = false;

function camera(sim) {
  const entities = sim.entities.filter(e => e.team === 0);

  const center = entities.map(e => e.pos).reduce((a, b) => a.add(b), new v2(0, 0)).mul(1 / entities.length);
  const heading0 = entities.map(e => e.dir).reduce((a, b) => a + b, 0) / entities.length;

  // sim에서 방향에 도움이 되는 정보
  //  - entity.dir
  //  - entity.aimdir
  //  - entity.waypoint (console.log(JSON.stringify(entity.waypoint)))
  //  - entity.aimtarget: 조준 대상
  //  - entity.state ("dead", "idle"), entity.team
  //  - entity.leader

  let heading_last = sim._heading ?? 0;
  const relheading = dirnorm0(heading0 - heading_last);
  const heading = heading_last + relheading * 0.01;
  sim._heading = heading;

  return {
    center,
    heading,
  };
}

export function renderCanvas(ctx, state, props, resources) {
  const { sim, entityOver, mousepos, renderScale } = state;
  const { canvasScale } = props;

  if (!sim) {
    return;
  }
  const {
    debugOptObstacles,
    debugOptStructs,
    debugOptPoints,
    debugOptNet,
    debugOptRisk,
    debugOptRiskDry,
    debugOptVisArc,
    debugOptWasmVis,
    debugOptWasmNav,
    debugOptWasmThreat,
    debugOptWaypoints,
    debugOptPerception,
    debugOptKnowledge,
    debugOptThrow,
    debugOptEdges,
    debugOptFog,
    debugMST,
    debugOptBubble,
  } = state;
  const { world, entities, throwables, spawnareas, routes, tick, obstacles, trails, blastareas, goals } = sim;

  const colors = state.colors ?? COLORS_ALT;
  const canvasOffset = state.canvasOffset ?? new v2(0, 0);
  const scale = state.scale ?? 1;

  ctx.resetTransform();
  ctx.scale(renderScale, renderScale);

  const desiredFontSize = 12;
  const fontSize = desiredFontSize / canvasScale;
  ctx.font = `bold ${fontSize}px monospace`;

  // render floor
  if (world.tile_floor) {
    const tile_src_size = 16;
    const tile_dst_size = 16;
    const tile_floor = world.tile_floor;
    for (let x = 0; x < world.width; x += tile_dst_size) {
      for (let y = 0; y < world.height; y += tile_dst_size) {
        ctx.drawImage(this.img_tile,
          tile_floor.x * 16, tile_floor.y * 16, tile_src_size, tile_src_size,
          x, y, tile_dst_size, tile_dst_size);
      }
    }
  } else {
    ctx.fillStyle = colors.BACKGROUND;
    ctx.fillRect(0, 0, world.width, world.height);
  }

  const renderProgressBar = function(pos, size, ratio, color_fg, color_bg) {
    pos = pos.round();
    const b = 1;

    ctx.fillStyle = color_bg ?? 'white';
    ctx.fillRect(pos.x, pos.y, size.x, size.y);
    ctx.fillStyle = color_fg ?? 'red';
    ctx.fillRect(pos.x + b, pos.y + b, (size.x - b * 2) * ratio, size.y - b * 2);
  }

  ctx.scale(scale, scale);

  // camera
  if (CAMERATEST) {
    const { viewWidth, viewHeight } = props;
    const { center, heading } = camera(sim);

    ctx.translate(viewWidth / 2, viewHeight / 2);
    ctx.rotate(-heading);
    ctx.translate(- center.x, - center.y);
  } else {
    ctx.translate(world.width / 2 - canvasOffset.x, world.height / 2 - canvasOffset.y);
  }

  const renderSymbolHexagon = function(canvasX, canvasY, size) {
    ctx.beginPath();
    let moved = false;
    for (let i = 0; i < 7; i++) {
      // hexagon
      const angle = (30 + i * 60) * Math.PI / 180;
      let x = canvasX + Math.sin(angle) * size;
      let y = canvasY + Math.cos(angle) * size;
      if (!moved) {
        ctx.moveTo(x, y);
        moved = true;
      } else {
        ctx.lineTo(x, y);
      }
    }
    ctx.stroke();
  }

  function renderBubble(pos, msg) {
    let { x, y } = pos;

    const margin = 4;

    ctx.font = 'bold 12px monospace';

    const m = ctx.measureText(msg);

    const width = margin * 2 + m.width;
    const height = m.fontBoundingBoxAscent + m.fontBoundingBoxDescent + (margin * 2);

    // anchored at bottom left
    y -= height;

    ctx.fillStyle = COLORS.BUBBLE_FILL;
    ctx.beginPath();
    ctx.roundRect(x, y, width, height, 4);
    ctx.fill();

    ctx.strokeStyle = COLORS.BUBBLE_BORDER;
    ctx.lineWidth = 1;
    ctx.beginPath();
    ctx.roundRect(x, y, width, height, 4);
    ctx.stroke();

    ctx.fillStyle = COLORS.BUBBLE_TEXT;
    ctx.fillText(msg, x + margin, y + margin + m.fontBoundingBoxAscent);
  }

  function renderSymbolCross(pos, size) {
    ctx.beginPath();
    ctx.moveTo(pos.x - size, pos.y - size);
    ctx.lineTo(pos.x + size, pos.y + size);
    ctx.moveTo(pos.x - size, pos.y + size);
    ctx.lineTo(pos.x + size, pos.y - size);
    ctx.stroke();
  }

  function renderSymbolCircle(pos, size) {
    ctx.beginPath();
    ctx.arc(pos.x, pos.y, size, 0, Math.PI * 2);
    ctx.stroke();
  }

  function renderPoly(polygon) {
    const start = polygon[polygon.length - 1];
    ctx.beginPath();
    ctx.moveTo(start.x, start.y);
    for (const p of polygon) {
      ctx.lineTo(p.x, p.y);
    }
  }

  function renderArc(pos, dir, len, angle) {
    ctx.beginPath();
    ctx.moveTo(pos.x, pos.y);

    ctx.arc(pos.x, pos.y, len, dir - angle - Math.PI / 2, dir + angle - Math.PI / 2);
    ctx.lineTo(pos.x, pos.y);
    ctx.stroke();
  }

  function renderSpawnarea(obj) {
    // obstacle outline
    ctx.strokeStyle = colors.SPAWNAREA;
    renderPoly(obj.polygon);
    ctx.stroke();

    ctx.fillStyle = colors.SPAWNAREA;
    ctx.fillText(obj.name, obj.pos.x, obj.pos.y);

    if (debugOptStructs && obj.areastate.structures) {
      for (const structure of obj.areastate.structures) {
        for (const room of structure.structure.squarifyTreemap) {
          renderPoly(room.shape.polygon);
          ctx.stroke();
        }
      }
    }
  }

  if (debugOptNet) {
    ctx.strokeStyle = 'rgb(0, 0, 255)';
    ctx.beginPath();
    for (const edge of routes.edges) {
      ctx.moveTo(edge.from.x, edge.from.y);
      ctx.lineTo(edge.to.x, edge.to.y);
    }
    ctx.stroke();
  }

  if (debugOptPoints) {
    for (const node of routes.nodes) {
      ctx.strokeStyle = node.is_coverpoint ? 'gray' : 'blue';
      renderSymbolCircle(node.pos, 1);

      if (node.is_coverpoint) {
        let start = node.pos;
        let end = node.pos.add(v2.fromdir(node.coverdir).mul(5));

        ctx.beginPath();
        ctx.moveTo(start.x, start.y);
        ctx.lineTo(end.x, end.y);
        ctx.stroke();
      }
    }
  }

  if (debugOptObstacles) {
    for (const obj of obstacles) {
      // obstacle outline
      if (obj.goalstate) {
        ctx.fillStyle = colors.GOAL;
      } else if (obj.ty === 'full') {
        ctx.fillStyle = colors.FULL;
      } else if (obj.ty === 'half') {
        ctx.strokeStyle = colors.BORDER;
      } else if (obj.ty === 'door') {
        ctx.strokeStyle = colors.DOOR;
        ctx.fillStyle = colors.DOOR;
      } else {
        console.log(`unexpected ty=${obj.ty}`, obj);
      }

      renderPoly(obj.polygon);
      if (obj.ty === 'full' || obj.goalstate || (obj.ty === 'door' && !obj.doorstate.open)) {
        ctx.fill();
      } else {
        ctx.stroke();
      }

      if (obj.name) {
        ctx.fillStyle = colors.LABEL_OBJ;
        ctx.fillText(obj.name, obj.pos.x, obj.pos.y);
      }
    }
  }

  if (debugMST) {
    ctx.strokeStyle = colors.DEBUG_MST;
    ctx.beginPath();
    for (const edge of debugMST) {
      ctx.moveTo(edge.from.x, edge.from.y);
      ctx.lineTo(edge.to.x, edge.to.y);
    }
    ctx.stroke();

    /*
    ctx.strokeStyle = 'cyan';
    ctx.beginPath();
    for (const node of debugMST) {
      const { edge } = node;
      ctx.moveTo(edge.from.x, edge.from.y);
      ctx.lineTo(edge.to.x, edge.to.y);
    }
    ctx.stroke();

    ctx.strokeStyle = 'white';
    ctx.beginPath();
    for (const node of debugMST) {
      const { edge, leaf } = node;
      if (!leaf) {
        continue;
      }
      ctx.moveTo(edge.from.x, edge.from.y);
      ctx.lineTo(edge.to.x, edge.to.y);
    }
    ctx.stroke();
    */
  }

  // perception grid
  if (debugOptPerception && entityOver && entities.includes(entityOver)) {
    renderGrid(ctx, world, entityOver.grid_vis);
  }
  if (debugOptKnowledge && entityOver && entities.includes(entityOver)) {
    renderGrid(ctx, world, entityOver.grid_explore);
  }

  let fogbuf = null;
  if (debugOptFog) {
    fogbuf = sim.teamVisibility(0);
  }

  if (debugOptFog) {
    const [r, g, b] = colors.FOG;
    renderGrid(ctx, world, fogbuf, (v) => {
      v = (1 - v).toFixed(3);
      return `rgba(${r}, ${g}, ${b}, ${v})`;
    });
  }

  for (const throwable of throwables) {
    const { pos, start_pos, target_pos } = throwable;


    if (throwable.blast_timer.expired(tick)) {
      continue;
    }

    ctx.strokeStyle = colors.THROWABLE;
    renderSymbolCircle(pos, 3);
    ctx.strokeStyle = colors.THROWABLE_TRAJECTORY;
    renderSymbolCircle(start_pos, 2);
    renderSymbolCircle(target_pos, 2);

    if (throwable.move_timer.expired(tick)) {
      ctx.fillStyle = colors.LABEL_THROWABLE;
      const remain = throwable.blast_timer.remain(tick);
      ctx.fillText(`${(remain / opts.tps).toFixed(1)}s`, pos.x, pos.y);
    }
  }

  for (const object of sim.objects) {
    const { pos, name, owned } = object;
    if (owned) {
      continue;
    }

    ctx.fillStyle = colors.LABEL_OBJECT;
    ctx.fillText(`${name}`, pos.x, pos.y);

    ctx.strokeStyle = colors.LABEL_OBJECT;
    renderSymbolCross(pos, 3);
  }

  for (const entity of entities) {
    const { pos } = entity;

    if (debugOptFog && entity.team !== 0) {
      if (fogbuf[world.idx(world.worldToGrid(entity.pos))] < 0.6) {
        continue;
      }
    }

    const dead = entity.state === 'dead';
    const teamcolor = entity.vis_color ?? colors.TEAM_COLORS[entity.team];
    ctx.strokeStyle = dead ? colors.DEAD : teamcolor;

    renderSymbolCircle(pos, entity.size);

    // dir
    if (!dead) {
      ctx.beginPath();
      ctx.moveTo(pos.x, pos.y);

      const dirX = Math.sin(entity.dir) * 10;
      const dirY = -1 * Math.cos(entity.dir) * 10;
      ctx.lineTo(pos.x + dirX, pos.y + dirY);
      ctx.stroke();
    }

    // aimdir
    if (!dead) {
      // debugaimdir
      if (entity === entityOver) {
        const dirvec = v2.fromdir(entity.debugaimdir).mul(100);
        const end = pos.add(dirvec);

        const oldwidth = ctx.lineWidth;
        ctx.lineWidth = 10;
        ctx.strokeStyle = colors.DEBUG_AIM;
        ctx.beginPath();
        ctx.moveTo(pos.x, pos.y);
        ctx.lineTo(end.x, end.y);
        ctx.stroke();

        ctx.lineWidth = oldwidth;
      }

      /*
      // aimdir indicator
      const dirvec = v2.fromdir(aimdir).mul(20);
      const end = pos.add(dirvec);

      ctx.beginPath();
      ctx.moveTo(pos.x, pos.y);
      ctx.lineTo(end.x, end.y);
      ctx.stroke();
      */

      // aimarc
      if (AIM_ARC) {
        ctx.beginPath();
        ctx.moveTo(pos.x, pos.y);

        ctx.strokeStyle = entity.team === 0 ? 'gray' : 'rgba(255, 255, 255, 0.15)';
        const aimvar = entity.aimvar * sim.entityAimvarMult(entity);
        renderArc(entity.pos, entity.aimdir, entity.firearm_range, aimvar);
        // reset alpha
        ctx.strokeStyle = 'rgba(0,0,0,1)';
      } else {
        let fillStyle = null;
        if (entity.team === 0) {
          if (entity.aimtarget) {
            fillStyle = 'rgba(255, 0, 0, 0.5)';
          } else {
            fillStyle = 'rgba(255, 255, 255, 0.5)';
          }
        } else {
          if (entity.aimtarget) {
            fillStyle = 'rgba(255, 0, 0, 0.15)';
          } else {
            fillStyle = 'rgba(255, 255, 255, 0.15)';
          }
        }
        ctx.fillStyle = fillStyle;

        const vis = sim.rv.triangulated.visibility(pos.x, pos.y, false);
        vis.limit(entity.firearm_range);

        const aimvar = entity.aimvar * sim.entityAimvarMult(entity);
        let p0 = entity.pos.add(v2.fromdir(entity.aimdir - aimvar));
        let p1 = entity.pos.add(v2.fromdir(entity.aimdir + aimvar));
        vis.clip([p0.x, p0.y, p1.x, p1.y]);

        const vispoints = decodePoints(vis.serialize());

        vis.free();

        ctx.beginPath();
        ctx.moveTo(pos.x, pos.y);
        for (const p of vispoints) {
          ctx.lineTo(p.x, p.y);
        }
        ctx.lineTo(pos.x, pos.y);
        ctx.fill();

        // reset alpha
        ctx.fillStyle = 'rgba(0,0,0,1)';
      }



      // render vis
      if (entity === entityOver || debugOptVisArc) {
        ctx.strokeStyle = entity.team === 0 ? 'blue' : 'rgba(255, 255, 255, 0.15)';
        const vis_range = sim.entityEffectParam(entity, 'vis_range');
        const vis_var = sim.entityEffectParam(entity, 'vis_var');
        renderArc(entity.pos, entity.aimdir, vis_range, vis_var);
      }

      if (debugOptThrow && entity === entityOver) {
        const doordir = sim.entityWaypointDoorDir(entity);
        if (doordir) {
          ctx.strokeStyle = 'cyan';

          const { pos, dir, ray } = doordir;

          const dist = Math.min(ray.len() * 0.8, 400);

          const p0 = pos;
          const p1 = pos.add(dir.mul(dist));
          renderSymbolCircle(p0, 5);
          renderSymbolCircle(p1, 5);

          renderVis(sim.rv, p1, 100);
        }
      }
    }

    // route
    if (debugOptWaypoints) {
      // render route
      if (entity === entityOver) {
        ctx.strokeStyle = 'rgba(255, 0, 0, 1)';
      } else {
        ctx.strokeStyle = 'rgba(255, 0, 0, 0.25)';
      }
      const oldwidth = ctx.lineWidth;
      ctx.lineWidth = 2;

      if (entity.waypoint?.path) {
        ctx.beginPath();
        const path = entity.waypoint.path;
        for (let i = 0; i < path.length; i++) {
          const p = path[i].pos;
          if (i === 0) {
            ctx.moveTo(p.x, p.y);
          } else {
            ctx.lineTo(p.x, p.y);
          }

          if (entity === entityOver) {
            renderSymbolCircle(p, 3);
          }

        }
        ctx.stroke();
      }

      if (entity === entityOver) {
        ctx.strokeStyle = 'rgba(0, 255, 0, 1)';
      } else {
        ctx.strokeStyle = 'rgba(0, 255, 0, 0.25)';
      }

      if (entity.waypoint?.cp?.edge) {
        ctx.beginPath();
        const { p_from, p_to } = entity.waypoint.cp.edge;

        ctx.moveTo(p_from.pos.x, p_from.pos.y);
        ctx.lineTo(p_to.pos.x, p_to.pos.y);
        ctx.stroke();

      }

      ctx.strokeStyle = 'rgba(255, 0, 0, 1)';
      ctx.lineWidth = oldwidth;
    }

    ctx.globalAlpha = 0.1;
    if (debugOptEdges) {
      ctx.strokeStyle = 'rgb(255, 0, 0)';
      ctx.beginPath();
      for (const edge of sim.routes.edges_shoot) {
        const { from_idx: idx0, to_idx: idx1 } = edge;
        const { pos: p0 } = sim.routes.nodes[idx0];
        const { pos: p1 } = sim.routes.nodes[idx1];

        ctx.moveTo(p0.x, p0.y);
        ctx.lineTo(p1.x, p1.y);
      }
      ctx.stroke();
    }
    ctx.globalAlpha = 1;

    // highlight: aimtarget info
    if (entity === entityOver && entity.aimtarget) {
      const p = entity.aimtarget.pos;
      renderSymbolHexagon(p.x, p.y, 5);

      const aimdist = entity.aimtarget.pos.dist(pos) / 10;
      ctx.fillStyle = colors.LABEL_AIMTARGET;
      ctx.fillText(`${aimdist.toFixed(0)}m`, p.x, p.y + 20);
    }

    function showRiskDir(res) {
      const { samples, samples_ray, sample_count, selected_idx, selected_val, pos } = res;

      function renderRiskLine(i, label) {
        const lineend = samples_ray[i];
        if (!lineend) {
          return;
        }

        ctx.beginPath();
        ctx.moveTo(pos.x, pos.y);
        ctx.lineTo(lineend.x, lineend.y);
        ctx.stroke();

        const margin = 50;
        lineend.x = clamp(lineend.x, -world.width / 2 + margin, world.width / 2 - margin);
        lineend.y = clamp(lineend.y, -world.height / 2 + margin, world.height / 2 - margin);

        ctx.fillText(label, lineend.x, lineend.y);
      }

      ctx.strokeStyle = colors.DEBUG_RISK;
      ctx.fillStyle = colors.DEBUG_RISK;

      for (let i = 0; i < sample_count; i++) {
        renderRiskLine(i, samples[i].toFixed(2));
      }

      ctx.fillStyle = colors.DEBUG_RISK_MAX;
      renderRiskLine(selected_idx, selected_val.toFixed(2));
    }

    // highlight: riskdir
    if (debugOptRisk && entity === entityOver) {
      showRiskDir(sim.riskdir(entity));
    }
    if (debugOptRiskDry && entity === entityOver) {
      showRiskDir(sim.riskdirDry(entity));
    }

    let name = entity.name;
    if (name.indexOf('"') >= 0) {
      name = name.split('"')[1];
    }

    ctx.fillStyle = dead ? colors.DEAD : teamcolor;

    let info_life = `${Math.round(entity.life)}`;
    if (entity.armor >= 1) {
      info_life += `(${Math.floor(entity.armor)})`;
    }

    ctx.fillText(`${name} ${info_life}`, pos.x, pos.y - 10);
    ctx.fillText(`${entity.firearm_ty} ${entity.ammo}`, pos.x, pos.y - 1);

    /*
    if (!entity.reloadTick.expired(tick)) {
      renderProgressBar(pos.add(new v2(0, 4)), new v2(50, 6), entity.reloadTick.progress(tick));
    }
    */

    if (!entity.healTick.expired(tick)) {
      renderProgressBar(pos.add(new v2(0, 4)), new v2(50, 6), entity.healTick.progress(tick), 'blue');
    }

    if (!entity.collectTick.expired(tick)) {
      renderProgressBar(pos.add(new v2(0, 4)), new v2(50, 6), entity.collectTick.progress(tick), 'purple');
    }

    // render icons
    if (resources.icons) {
      let offset = 0;
      const size = 16;

      function renderIconAt(name, pos) {
        const { x, y } = pos;
        if (!resources.icons.sheet[name]) {
          return;
        }
        const [x0, y0] = resources.icons.sheet[name];
        ctx.drawImage(resources.icons, x0 * size, y0 * size, size, size, x, y, size, size);
      }

      function renderIcon(name) {
        renderIconAt(name, pos.add(new v2(offset, 0)));
        offset += size;
      }

      for (const icon of entity.icons) {
        if (icon === 'skull') {
          renderIconAt('skull', pos.add(new v2(-size, -size)));
        }
        renderIcon(icon);
      }
    }
  }

  if (props.debug) {
    for (const area of spawnareas) {
      renderSpawnarea(area);
    }
  }

  for (const obj of goals) {
    ctx.strokeStyle = colors.GOAL;
    ctx.fillStyle = colors.GOAL;

    renderSymbolCircle(obj.pos, opts.GOAL_RADIUS);

    const { owner, occupying_team, occupy_tick } = obj.goalstate;
    let txt = obj.name;
    if (owner >= 0) {
      txt += ` own ${owner}`;
    } else if (occupying_team >= 0) {
      const ticks = sim.ticksFromSec(opts.GOAL_OCCUPY_DURATION);
      txt += ` hold ${occupying_team} ${tick - occupy_tick}/${ticks}`;
    }
    ctx.fillText(txt, obj.pos.x + 10, obj.pos.y);
  }

  function renderBlood(pos, angle, idx) {
    const asx = 512 * (idx % 4);
    const asy = 512 * Math.floor(idx / 4);

    const size = 64;
    ctx.translate(pos.x, pos.y);
    ctx.rotate(angle);
    // TODO
    if (resources.blood) {
      ctx.drawImage(resources.blood, asx, asy, 512, 512, -size / 2, -size / 2, size, size);
    }
    ctx.rotate(-angle);
    ctx.translate(-pos.x, -pos.y);
  }

  for (const ea of blastareas) {
    const tick_elapsed = tick - ea.tick;

    for (const entity of ea.entities) {
      const idx = Math.min(15, tick_elapsed);
      const dir = entity.pos.sub(ea.pos).dir();
      const angle = dir + Math.PI / 2;

      renderBlood(entity.pos, angle, idx);
    }

    if (tick >= ea.expire_at) {
      continue;
    }

    renderVis0(ea.vis, (val) => {
      if (val === 0) {
        return null;
      }
      val = Math.floor(val * 255);
      if (ea.effect_ty) {
        return `rgba(${val}, ${val}, ${val}, 0.3)`;
      } else {
        return `rgba(0, ${val}, 0, 0.3)`;
      }
    });

    if (ea.effect_ty) {
      ctx.fillStyle = colors.LABEL_EFFECT;
      ctx.fillText(`(${ea.effect_ty})`, ea.pos.x, ea.pos.y);
    }
  }

  for (const trail of trails) {
    const { hit, pos, dir, len } = trail;
    const duration = hit ? opts.TRAIL_HIT_DURATION : opts.TRAIL_DURATION;

    const tick_elapsed = tick - trail.tick;

    if (trail.hit) {
      const idx = Math.min(15, tick_elapsed);
      const angle = trail.dir + Math.PI / 2;
      renderBlood(trail.target_pos, angle, idx);
    }

    if (tick_elapsed > sim.ticksFromSec(duration)) {
      continue;
    }

    ctx.strokeStyle = hit ? colors.TRAIL_HIT : COLORS.TRAIL_MISS;
    ctx.beginPath();
    ctx.moveTo(pos.x, pos.y);

    const dirX = Math.sin(dir) * len;
    const dirY = -1 * Math.cos(dir) * len;

    ctx.lineTo(pos.x + dirX, pos.y + dirY);
    ctx.stroke();
  }

  // render bubbles
  if (debugOptBubble) {
    const rendered = {};
    for (const bubble of sim.bubbles) {
      if (bubble.timer.expired(tick)) {
        continue;
      }
      const { entity, msg } = bubble;
      if (rendered[entity.name]) {
        continue;
      }
      rendered[entity.name] = true;
      renderBubble(entity.pos.add(new v2(0, -20)), msg);
    }
  }

  const { debugScore, debugScoreProp } = state;

  if (debugScore) {
    ctx.fillStyle = colors.DEBUG_SCORE;
    for (const item of debugScore) {
      const { query, node } = item;
      const { pos } = node;
      if (!query || query[debugScoreProp] === undefined) {
        continue;
      }

      let v = query[debugScoreProp];
      if (!isNaN(v)) {
        v = v.toFixed(2);
      }
      if (v instanceof Object) {
        v = JSON.stringify(v);
      }
      ctx.fillText(v, pos.x, pos.y);
    }
  }

  const { debugDist } = state;
  if (debugDist) {
    for (let i = 0; i < debugDist.length; i++) {
      let dist = debugDist[i];
      let { pos } = sim.routes.nodes[i];

      if (dist === -1) {
        ctx.fillStyle = colors.DEBUG_DIST_UNREACHABLE;
        ctx.font = '9px monospace';
        ctx.fillText(dist, pos.x, pos.y);
      } else {
        ctx.fillStyle = colors.DEBUG_DIST_REACHABLE;
        ctx.font = '5px monospace';
        ctx.fillText(dist.toFixed(0), pos.x, pos.y);
      }

    }
  }

  const { debugGrid } = state;
  if (debugGrid) {
    ctx.fillStyle = colors.DEBUG_LABEL_GRID;
    ctx.font = 'bold 8px monospace';

    const { risks } = debugGrid;

    for (let y = 0; y < world.grid_count_y; y++) {
      for (let x = 0; x < world.grid_count_x; x++) {
        const idx = world.idx(new v2(x, y));
        const cost = risks[idx];
        if (cost < 2) {
          continue;
        }
        const pos = state.world.gridToWorld(new v2(x, y));

        ctx.fillText(`${cost}`, pos.x, pos.y);
        /*
        ctx.fillStyle = `rgba(${Math.floor(cost)}, 0, 0, 0.3)`;
        ctx.fillRect(pos.x, pos.y, opts.GRID_SIZE, opts.GRID_SIZE);
        */
      }
    }
  }

  function decodePoints(res) {
    const points = [];
    for (let i = 0; i < res.length / 2; i++) {
      const mult = 1;
      const x = res[i * 2 + 0] * mult;
      const y = res[i * 2 + 1] * mult;
      points.push(new v2(x, y));
    }

    return points;
  }

  /*
  if (debugOptWasmVis || debugOptWasmNav || debugOptWasmThreat) {
    const simplices = sim.sx.simplices();
    ctx.strokeStyle = 'purple';
    ctx.beginPath();
    const simpoints = decodePoints(simplices);
    for (let i = 0; i < simpoints.length / 2; i++) {
      let p0 = simpoints[i * 2 + 0];
      let p1 = simpoints[i * 2 + 1];
      ctx.moveTo(p0.x, p0.y);
      ctx.lineTo(p1.x, p1.y);
    }
    ctx.stroke();
  }
  */

  function renderVis0(vis, styleFn) {
    const vispoints = decodePoints(vis.serialize());

    if (vispoints.length > 0) {
      ctx.strokeStyle = 'white';
      ctx.beginPath();
      ctx.moveTo(vispoints[vispoints.length - 1].x, vispoints[vispoints.length - 1].y);
      for (let i = 0; i < vispoints.length; i++) {
        ctx.lineTo(vispoints[i].x, vispoints[i].y);
      }
      ctx.stroke();
    }

    const buf = new Float32Array(world.grid_count);
    vis.fill([-world.width / 2, -world.height / 2, world.width, world.height, opts.GRID_SIZE], buf);

    renderGrid(ctx, world, buf, styleFn);
  }

  function renderVis(t, pos, limit) {
    if (!mousepos) {
      return;
    }
    if (!pos) {
      pos = mousepos.sub(new v2(world.width / 2, world.height / 2)).mul(1 / scale);
    }
    limit = limit ?? 400;
    const vis = t.triangulated.visibility(pos.x, pos.y, true);
    vis.limit(limit);

    renderVis0(vis, (val) => `rgba(0, ${Math.floor(val * 255)}, 0, 0.3)`);
    vis.free();
  }

  if (debugOptWasmVis) {
    renderVis(sim.rv);
  }
  if (debugOptWasmNav) {
    renderVis(sim.rn);
  }
  if (debugOptWasmThreat) {
    renderVis(sim.rt);
  }

  // TODO: renderCanvasInfo 문제 확인해서 고치기
  renderCanvasInfo(ctx, state);

  if (sim.paused()) {
    ctx.resetTransform();
    ctx.scale(renderScale, renderScale);

    ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
    ctx.fillRect(0, 0, world.width, world.height);

    ctx.fillStyle = colors ? colors.LABEL_INFO : 'orange';
    ctx.font = 'bold 48px monospace';
    ctx.fillText('PAUSED '.repeat(20), -world.width / 2, world.height / 2);
  }
}


function renderCanvasInfo(ctx, state) {
  const { sim, mousepos, scale, entityOver, colors, renderScale } = state;
  if (!sim) {
    return;
  }
  const { world, seed, routes, tick } = sim;

  let offset = 12;

  function renderLine(msg) {
    ctx.fillText(msg, 5, offset);
    offset += 12;
  }

  ctx.resetTransform();
  ctx.scale(renderScale, renderScale);

  ctx.fillStyle = colors ? colors.LABEL_INFO : 'orange';
  ctx.font = 'bold 12px monospace';
  const mapWidth = Math.floor(world.width / 10 / scale);
  const mapHeight = Math.floor(world.height / 10 / scale);
  let msg = `preset=${world.preset}, seed=${seed}, tick=${tick}, scale=${scale}/[${mapWidth}m x ${mapHeight}m], network=${routes.nodes.length}/${routes.edges.length}`;

  renderLine(msg);

  const perfbuf = sim.perfbuf;
  const perfsum = _.sumBy(perfbuf, (a) => a.dt);
  const tick_ms = perfsum / perfbuf.length;

  let tick_tps = sim.tps;
  if (perfbuf.length > 2) {
    const now = _.maxBy(perfbuf, (a) => a.start);
    const start = _.minBy(perfbuf, (a) => a.start);
    tick_tps = 1000 * perfbuf.length / (now.start + now.dt - start.start);
  }

  renderLine(`tps=${sim.tps}, ttps=${state.simtps}, rtps=${tick_tps.toFixed(1)}, ${tick_ms.toFixed(1)}ms`);

  if (mousepos) {
    const worldpos = mousepos.sub(new v2(world.width / 2, world.height / 2)).mul(1 / scale);
    msg = `mousepos=${formatCoord(mousepos)}/${formatCoord(worldpos)}`;
    const vis_team0 = sim.teamVisibilityAt(0, worldpos);
    const vis_team1 = sim.teamVisibilityAt(1, worldpos);
    msg += ` teamvis=${vis_team0.toFixed(2)}/${vis_team1.toFixed(2)}`;
    if (entityOver !== null) {
      msg += `/${sim.visibilityAt(entityOver, worldpos).toFixed(2)}`;
    }

    renderLine(msg);
  }
}
