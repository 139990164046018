import { data as source } from '../downloaded/maps.mjs';
import { data as sourceMissionTitles } from '../downloaded/missionTitles.mjs';
import { data as sourceMissionMilestoneTitles } from '../downloaded/missionMilestoneTitles.mjs';

export const maps = [];
for (const row of source) {
  let { preset, threats_min, threats_max, resource_multiplier, conflict_tys, weight } = row;
  maps.push({
    preset,
    threats_min: 0 | threats_min,
    threats_max: 0 | threats_max,
    resource_multiplier: +resource_multiplier,
    conflict_tys: conflict_tys.split(','),
    weight: +weight,
  });
}

export function sampleMapTy(rng, threats) {
  const entries = maps.filter((m) => threats >= m.threats_min && threats <= m.threats_max);

  let cur = 0;
  const cdf = [];
  for (const entry of entries) {
    cur += entry.weight;
    cdf.push(cur);
  }

  let sample = rng.range(0, cur);
  for (let i = 0; i < entries.length; i++) {
    if (sample < cdf[i]) {
      return entries[i];
    }
  }

  // unreachable
  throw new Error('unreachable');
}

// TODO: conflict ty to preset mapping

export const titles = sourceMissionTitles.map((row) => row.title);
export const milestone_titles = sourceMissionMilestoneTitles.map((row) => row.title);
export const milestone_descs = sourceMissionMilestoneTitles.map((row) => row.desc);
