import { data as source } from '../downloaded/stats.mjs';
import { data as sourceStatsDescr } from '../downloaded/statsDescr.mjs';


export const stats_descr = sourceStatsDescr;
export const stats = [];
for (const row of source) {
  const {
    stat_key,
    value,
    perk,
    name,
    descr,
  } = row;

  stats.push({
    stat_key,
    value: +value,
    perk,
    name,
    descr,
  });
}
