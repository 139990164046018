// downloaded data!
export const data = [
  {
    "stat_key": "decision",
    "value": "4",
    "perk": "perk_aimvar_max_5",
    "name": "목표 중심",
    "descr": "더 정밀하게 조준하여 최소 조준각이 5% 더 좁혀집니다."
  },
  {
    "stat_key": "decision",
    "value": "8",
    "perk": "perk_aimvar_5",
    "name": "결단",
    "descr": "조준을 시작했을 때의 최초 조준각이 5% 더 좁혀집니다."
  },
  {
    "stat_key": "decision",
    "value": "12",
    "perk": "perk_aimvar_max_10",
    "name": "몰입",
    "descr": "더 정밀하게 조준하여 최소 조준각이 10% 더 좁혀집니다."
  },
  {
    "stat_key": "decision",
    "value": "16",
    "perk": "perk_aimvar_10",
    "name": "마음의 준비",
    "descr": "조준을 시작했을 때의 최초 조준각이 10% 더 좁혀집니다."
  },
  {
    "stat_key": "decision",
    "value": "20",
    "perk": "perk_predict_aim",
    "name": "직감",
    "descr": "적이 등장할 위치를 예상할 수 있습니다. 20% 더 정밀하게 조준할 수 있게 됩니다."
  },
  {
    "stat_key": "bravery",
    "value": "4",
    "perk": "perk_aimvar_incr_m5",
    "name": "인내",
    "descr": "피격 당했을 때의 조준이 흐트러지는 패널티가 5% 줄어듭니다."
  },
  {
    "stat_key": "bravery",
    "value": "8",
    "perk": "perk_suppress_m5",
    "name": "공포 극복",
    "descr": "사격 받을 때의 조준이 흐트러지는 패널티가 5% 줄어듭니다."
  },
  {
    "stat_key": "bravery",
    "value": "12",
    "perk": "perk_aimvar_incr_m10",
    "name": "정신력",
    "descr": "피격 당했을 때의 조준이 흐트러지는 패널티가 10% 줄어듭니다."
  },
  {
    "stat_key": "bravery",
    "value": "16",
    "perk": "perk_suppress_m10",
    "name": "영웅심",
    "descr": "사격 받을 때의 조준이 흐트러지는 패널티가 10% 줄어듭니다."
  },
  {
    "stat_key": "bravery",
    "value": "20",
    "perk": "perk_counterattack",
    "name": "반격",
    "descr": "적에게 먼저 사격을 받으면, 1초 동안 회피율이 20% 증가하고, 사격한 적에 대한 명중률이 20% 증가합니다."
  },
  {
    "stat_key": "focus",
    "value": "4",
    "perk": "perk_moving_reload",
    "name": "사전 준비",
    "descr": "이동 중에 재장전하는 경우, 다음 재장전 속도가 10% 증가합니다."
  },
  {
    "stat_key": "focus",
    "value": "8",
    "perk": "perk_stationary_aimspeed",
    "name": "자연체",
    "descr": "정지 상태에서 사격할 때의 조준 속도가 10% 빨라집니다."
  },
  {
    "stat_key": "focus",
    "value": "12",
    "perk": "perk_aim_together",
    "name": "견제 활용",
    "descr": "다른 용병이 공격중인 적을 조준하는 속도가 50% 빨라집니다."
  },
  {
    "stat_key": "focus",
    "value": "16",
    "perk": "perk_cover_steady",
    "name": "반동 제어",
    "descr": "엄폐 중 사격 시, 반동으로 인한 조준 흔들림이 20% 감소합니다."
  },
  {
    "stat_key": "focus",
    "value": "20",
    "perk": "perk_aim_execute",
    "name": "마무리",
    "descr": "이미 피해를 입은 적을 조준하는 속도가 50% 빨라집니다."
  },
  {
    "stat_key": "reaction",
    "value": "4",
    "perk": "perk_cover_dash",
    "name": "신속 엄폐",
    "descr": "적에게 공격 받을 때 엄폐물까지 10% 빠르게 달려갈 수 있습니다."
  },
  {
    "stat_key": "reaction",
    "value": "8",
    "perk": "perk_reflex_evade",
    "name": "빠른 반응",
    "descr": "적의 등장에 반사적으로 피탄 면적을 줄여 피격율이 10% 감소합니다."
  },
  {
    "stat_key": "reaction",
    "value": "12",
    "perk": "perk_move_evade",
    "name": "착시 유발",
    "descr": "이동 중인 상태일 때의 피격율이 10% 감소합니다."
  },
  {
    "stat_key": "reaction",
    "value": "16",
    "perk": "perk_move_cover",
    "name": "현란한 이동",
    "descr": "이동 중 공격받을 때 엄폐 효과를 받습니다."
  },
  {
    "stat_key": "reaction",
    "value": "20",
    "perk": "perk_engage_dash",
    "name": "돌진본능",
    "descr": "교전 중 50% 빠르게 움직입니다. 엎드리지 않습니다."
  },
  {
    "stat_key": "toughness",
    "value": "4",
    "perk": "perk_healed_amount_5",
    "name": "건강체질",
    "descr": "치료 받을 때의 회복 효율 보너스가 5% 증가 합니다."
  },
  {
    "stat_key": "toughness",
    "value": "8",
    "perk": "perk_glancing_blow",
    "name": "비껴맞기",
    "descr": "피격 당해도 급소를 피하여 치명상을 입을 확률이 20% 감소합니다."
  },
  {
    "stat_key": "toughness",
    "value": "12",
    "perk": "perk_healed_amount_15",
    "name": "타고난 몸",
    "descr": "치료 받을 때의 회복 효율 보너스가 15% 증가 합니다."
  },
  {
    "stat_key": "toughness",
    "value": "16",
    "perk": "perk_recover_life",
    "name": "숨 고르기",
    "descr": "마지막 피해로부터 5초간 피해가 없으면 해당 피해의 10%를 회복합니다."
  },
  {
    "stat_key": "toughness",
    "value": "20",
    "perk": "perk_reduce_penalty",
    "name": "투혼",
    "descr": "부상에 의한 전투력 패널티가 30% 감소합니다."
  },
  {
    "stat_key": "precision",
    "value": "4",
    "perk": "perk_critical_add",
    "name": "약점 파악",
    "descr": "치명상을 입힐 확률이 2% 증가합니다."
  },
  {
    "stat_key": "precision",
    "value": "8",
    "perk": "perk_damage_standing",
    "name": "쉬운 목표",
    "descr": "엄폐 없이 서있는 상태의 적에게 주는 피해량이 15% 증가합니다."
  },
  {
    "stat_key": "precision",
    "value": "12",
    "perk": "perk_pierce_moving_enemy",
    "name": "움직임 포착",
    "descr": "이동중인 적을 사격할 때 장애물을 무시합니다."
  },
  {
    "stat_key": "precision",
    "value": "16",
    "perk": "perk_damage_move_crawl",
    "name": "움직이는 목표",
    "descr": "이동 중이거나 포복중인 상태의 적에게 주는 피해량이 15% 증가합니다."
  },
  {
    "stat_key": "precision",
    "value": "20",
    "perk": "perk_lastshoot.",
    "name": "마지막 한 발",
    "descr": "재장전 전 마지막 탄환이 엄폐, 장애물을 무시하여 명중하고, 15% 증가한 피해를 줍니다."
  }
];