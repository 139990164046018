/* global engine */

import { pm } from 'postmessage-polyfill';

if (typeof engine !== 'undefined') {
  window.gameface = engine;

  window.postMessage = function (message) {
    pm({
      type: message.type,
      // TODO:
      origin: 'http://127.0.0.1:3000',
      target: window,
      data: message,
    });
  };

  const _setInterval = window.setInterval;
  window.setInterval = function (callback, delay = 0) {
    return _setInterval(callback, delay);
  }

  // force retina display check
  if (window.innerWidth >= 1920 * 2 && window.innerHeight >= 1080 * 2) {
    document.body.classList.add('force-retina');
  }
}

export const GAMEFACE = !!window.gameface;
