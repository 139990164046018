// downloaded data!
export const data = [
  {
    "level": "1",
    "life": "34"
  },
  {
    "level": "2",
    "life": "37"
  },
  {
    "level": "3",
    "life": "41"
  },
  {
    "level": "4",
    "life": "44"
  },
  {
    "level": "5",
    "life": "48"
  },
  {
    "level": "6",
    "life": "51"
  },
  {
    "level": "7",
    "life": "55"
  },
  {
    "level": "8",
    "life": "58"
  },
  {
    "level": "9",
    "life": "62"
  },
  {
    "level": "10",
    "life": "65"
  },
  {
    "level": "11",
    "life": "69"
  },
  {
    "level": "12",
    "life": "72"
  },
  {
    "level": "13",
    "life": "76"
  },
  {
    "level": "14",
    "life": "79"
  },
  {
    "level": "15",
    "life": "83"
  },
  {
    "level": "16",
    "life": "86"
  },
  {
    "level": "17",
    "life": "90"
  },
  {
    "level": "18",
    "life": "93"
  },
  {
    "level": "19",
    "life": "97"
  },
  {
    "level": "20",
    "life": "100"
  }
];