// downloaded data!
export const data = [
  {
    "level": "1",
    "exp": "100"
  },
  {
    "level": "2",
    "exp": "150"
  },
  {
    "level": "3",
    "exp": "230"
  },
  {
    "level": "4",
    "exp": "340"
  },
  {
    "level": "5",
    "exp": "480"
  },
  {
    "level": "6",
    "exp": "650"
  },
  {
    "level": "7",
    "exp": "850"
  },
  {
    "level": "8",
    "exp": "1080"
  },
  {
    "level": "9",
    "exp": "1340"
  },
  {
    "level": "10"
  }
];