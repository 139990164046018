import _ from 'lodash';
import { useState } from "react";

import { STATS2_DESCR } from "../../stats2.mjs";
import { data as data_perktree } from "../../data/google/downloaded/perktree";
import { perks2 as data_perks, perks2ByKey as perk_by_key } from "../../data/google/processor/data_perks2.mjs";

import { AgentPerk } from "../anima/src/components/AgentPerk";
import { PerkLevel } from "../anima/src/components/PerkLevel";
import { PerkTreeColumn } from "../anima/src/components/PerkTreeColumn";
import { PgbConA } from "../anima/src/components/PgbConA";
import { PgbConB } from "../anima/src/components/PgbConB";
import { PgbConC } from "../anima/src/components/PgbConC";
import { PerkSlot } from "../anima/src/components/PerkSlot";
import { TreeBranch } from "../anima/src/components/TreeBranch";
import { PerktooltipSi } from '../anima/src/components/PerktooltipSi';
import { PgbtooltipSi } from '../anima/src/components/PgbtooltipSi';
import { PgbTooltipContent } from '../anima/src/components/PgbTooltipContent';


// UI
const OFFSET_TOOLTIP = { x: 16, y: 16 };

// kinds
const [COMMON, ROLE, PERSONAL] = ['common', 'role', 'personal'];
const COMMON_COLS = 2;

const PERK_GROUPS_WITH_ANY_PERKS = _.uniq(data_perks.flatMap((p) => p.groups));

const PERK_GROUPS_DEFAULT = _.uniq(data_perktree
  .filter((d) => d.source === 'default')
  .map((d) => d.perk_group));
const PERK_GROUPS_TRAIT = _.uniq(data_perktree
  .filter((d) => d.source === 'trait')
  .filter((d) => PERK_GROUPS_WITH_ANY_PERKS.includes(d.perk_group))
  .map((d) => d.perk_group));
const PERK_GROUPS_BACKGROUND = _.uniq(data_perktree
  .filter((d) => d.source === 'background')
  .filter((d) => PERK_GROUPS_WITH_ANY_PERKS.includes(d.perk_group))
  .map((d) => d.perk_group));

const PERK_GROUPS_FOR_ROLE = PERK_GROUPS_BACKGROUND
  .filter((g) => !PERK_GROUPS_DEFAULT.includes(g))
  .filter((g) => !PERK_GROUPS_TRAIT.includes(g));

// TODO: 다국어 할때 날리기
const lookupDevStatLabel = (key) => {
  const match = /%%(\w+)%%/.exec(`${key}`);
  const k = match && match[1];
  return STATS2_DESCR[k] || key;
};

function isAvailableToLearn(key, learned_keys, point_avail, point_used) {
  if (point_avail < 1) {
    return false;
  }
  if (learned_keys.includes(key)) {
    return false;
  }

  const perk = perk_by_key(key);

  if (perk.level > point_used + 1) {
    return false;
  }

  const is_role_perk = _.some(PERK_GROUPS_FOR_ROLE, (group) => perk.groups.includes(group));
  if (is_role_perk) {
    if (perk.dep && perk.dep.length > 0 && !learned_keys.includes(perk.dep)) {
      return false;
    }
  }

  return true;
}

function createPerktrees(groups, point_avail, point_used, learned_keys, all_perks, onAcquirePerk, draws_branch,
  set_show_tt, set_coord_tt, set_title_tt, set_desc_tt) {
  const perks = all_perks.filter((p) => _.some(groups, (g) => p.groups.includes(g)));
  const highest_level = Math.max(0, ...perks.map((p) => p.level));

  const trees = [];
  for (let column = 0; column < groups.length; column++) {
    const group = groups[column];

    const slots = [];
    for (let level = 1; level <= highest_level; level++) {
      if (level > 1) {
        slots.push(
          draws_branch ?
            <TreeBranch key={level + '_branch'} property1='branch-c' /> :
            <TreeBranch key={level + '_branch'} property1='branch-x' />
        );
      }

      const perk_candidates = perks
        .filter((p) => p.level === level)
        .filter((p) => p.groups.includes(group))
        .sort((p) => p.key);
      let perk = null;
      if (perk_candidates.length === 0) {
        perk = null;
      } else if (perk_candidates.length === 1) {
        perk = perk_candidates[0];
      } else if (perk_candidates.length === COMMON_COLS && group === COMMON) {
        perk = perk_candidates[column];
      } else {
        throw new Error(`too many perks at level ${level}: ${perk_candidates.map((p) => p.key).join(', ')}`);
      }

      const handlers_tt_perk = {
        onMouseOut: (e) => set_show_tt(false),
        onMouseOver: (e) => set_show_tt(true) || set_title_tt(perk.name) || set_desc_tt(perk.descr),
        onMouseMove: (e) => set_coord_tt({ x: e.clientX + OFFSET_TOOLTIP.x, y: e.clientY + OFFSET_TOOLTIP.y }),
      };

      const learned = perk && learned_keys.includes(perk.key);
      const avail = perk && isAvailableToLearn(perk.key, learned_keys, point_avail, point_used);
      const slot =
        perk ?
          learned ?
            <PerkSlot key={level} state='completed' className='' label={perk.name} {...handlers_tt_perk} /> :
            avail ?
              <PerkSlot key={level} state='unlocked' className='' label={perk.name}
                onClick={() => onAcquirePerk && onAcquirePerk(perk.key)}
                {...handlers_tt_perk}
              /> :
              <PerkSlot key={level} state='locked' className='' label={perk.name} {...handlers_tt_perk} /> :
          draws_branch ?
            <PerkSlot key={level} state='branch-b' className='' /> :
            <PerkSlot key={level} state='branch-x' className='' />;
      slots.push(slot);
    }
    trees.push(<PerkTreeColumn key={column} override={<>{slots}</>} />);
  }

  return {
    perktrees: <>{trees}</>,
    highest_level,
  };
}

export const AgentPerkWrapper = (props) => {
  const { agent, onAgentAcquirePerk } = props;

  const kinds = [COMMON];
  const [selected_kind, set_selected_kind] = useState(kinds[0]);

  const [show_tt_perk, set_show_tt_perk] = useState(false);
  const [coord_tt_perk, set_coord_tt_perk] = useState({ x: 0, y: 0 });
  const [title_tt_perk, set_title_tt_perk] = useState('');
  const [desc_tt_perk, set_desc_tt_perk] = useState('');

  const [show_tt_btn, set_show_tt_btn] = useState(false);
  const [coord_tt_btn, set_coord_tt_btn] = useState({ x: 0, y: 0 });
  const [contents_tt_btn, set_contents_tt_btn] = useState([]);

  if (!agent) {
    return null;
  }

  const onAcquirePerk = (perk) => onAgentAcquirePerk && onAgentAcquirePerk(agent, perk);

  const style_tt_perk = {
    display: show_tt_perk ? 'flex' : 'none',
    position: 'fixed',
    top: coord_tt_perk.y, left: coord_tt_perk.x,
  };
  const style_tt_btn = {
    display: show_tt_btn ? 'flex' : 'none',
    position: 'fixed',
    top: coord_tt_btn.y, left: coord_tt_btn.x,
  };

  const background_perk_group = agent.background_perk_group.perk_group;

  const group_role0 = background_perk_group;
  const group_role = (PERK_GROUPS_FOR_ROLE.includes(group_role0)) ? group_role0 : null;
  const group_role_perktree = data_perktree.find((p) => p.perk_group === group_role);
  const group_role_name0 = group_role_perktree?.perk_group_name;
  const group_role_name = lookupDevStatLabel(group_role_name0) ?? group_role;
  const group_role_desc = group_role_perktree?.description;
  if (group_role) {
    kinds.push(ROLE);
  }

  const groups_from_background = PERK_GROUPS_TRAIT.filter((g) => g === background_perk_group);
  const groups_from_traits = agent.traits.map((t) => t.trait_perk_group).filter((g) => PERK_GROUPS_TRAIT.includes(g));
  const groups_personal = _.uniq([...groups_from_background, ...groups_from_traits].sort());
  // TODO: 개별퍽 그룹 표시 순서, 일단 알파벳순 정렬 하는데, 나중에 게임내 다른 정렬 순서에 따르기

  const groups_personal_perktree = groups_personal.map((g) => data_perktree.find((p) => p.perk_group === g && p.source === 'trait'));
  const groups_personal_descs = groups_personal_perktree.map((p) => p.description);
  const groups_personal_names = groups_personal_perktree.map((p) => lookupDevStatLabel(p.perk_group_name));
  const groups_personal_name = groups_personal_names.join(', ');
  if (groups_personal.length > 0) {
    kinds.push(PERSONAL);
  }


  const { perks: { list: keys_learned, point, point_total } } = agent;
  const point_used = point_total - point;

  const groups =
    (selected_kind === COMMON) ? Array(COMMON_COLS).fill(COMMON) :
      (selected_kind === ROLE) ? [group_role] :
        (selected_kind === PERSONAL) ? groups_personal :
          [];
  const draws_branch = (selected_kind === ROLE);
  const { perktrees, highest_level } =
    createPerktrees(groups, point, point_used, keys_learned, data_perks, onAcquirePerk, draws_branch,
      set_show_tt_perk, set_coord_tt_perk, set_title_tt_perk, set_desc_tt_perk);

  const group_button_handlers = kinds.map((group) => {
    if (group === COMMON) {
      return () => set_selected_kind(COMMON);
    } else if (group === ROLE) {
      return () => set_selected_kind(ROLE);
    } else if (group === PERSONAL) {
      return () => set_selected_kind(PERSONAL);
    } else {
      throw new Error('unknown group');
    }
  });

  const group_button_handlers_over = kinds.map((group) => {
    if (group === COMMON) {
      return () => set_show_tt_btn(false);
    } else if (group === ROLE) {
      return () => {
        set_contents_tt_btn([[group_role_name, group_role_desc]]);
        set_show_tt_btn(true);
      };
    } else if (group === PERSONAL) {
      return () => {
        set_contents_tt_btn(
          groups_personal_names.map((name, i) => [name, groups_personal_descs[i]])
        );
        set_show_tt_btn(true);
      };
    } else {
      throw new Error('unknown group');
    }
  });
  const group_button_handlers_out = kinds.map((group) => {
    return () => set_show_tt_btn(false);
  });
  const group_button_handlers_move = kinds.map((group) => {
    return (e) => set_coord_tt_btn({ x: e.clientX + OFFSET_TOOLTIP.x, y: e.clientY + OFFSET_TOOLTIP.y });
  });

  const group_button_states = kinds.map((group) => {
    if (group === COMMON) {
      return (selected_kind === COMMON) ? 'on' : 'off';
    } else if (group === ROLE) {
      return (selected_kind === ROLE) ? 'on' : 'off';
    } else if (group === PERSONAL) {
      return (selected_kind === PERSONAL) ? 'on' : 'off';
    } else {
      throw new Error('unknown group');
    }
  });

  const group_button_labels = kinds.map((group) => {
    if (group === COMMON) {
      return 'Common';
    } else if (group === ROLE) {
      return group_role_name;
    } else if (group === PERSONAL) {
      return groups_personal_name;
    } else {
      throw new Error('unknown group');
    }
  });

  const group_button_sub_labels = kinds.map((group) => {
    if (group === COMMON) {
      return '';
    } else if (group === ROLE) {
      return 'Specialized';
    } else if (group === PERSONAL) {
      return 'Personal';
    } else {
      throw new Error('unknown group');
    }
  });

  const groups_count = kinds.length;

  let buttons_group = null;

  switch (groups_count) {
    case 1:
      buttons_group =
        <PgbConA
          PGBButtonOnClick={group_button_handlers[0]}
          PGBButtonOnMouseMove={group_button_handlers_move[0]}
          PGBButtonOnMouseOut={group_button_handlers_out[0]}
          PGBButtonOnMouseOver={group_button_handlers_over[0]}
          PGBButtonPtLabel={group_button_labels[0]}
          PGBButtonVarState={group_button_states[0]}
        />;
      break;
    case 2:
      buttons_group =
        <PgbConB
          PGBButtonOnClick={group_button_handlers[0]}
          PGBButtonOnClick1={group_button_handlers[1]}
          PGBButtonOnMouseMove={group_button_handlers_move[0]}
          PGBButtonOnMouseMove1={group_button_handlers_move[1]}
          PGBButtonOnMouseOut={group_button_handlers_out[0]}
          PGBButtonOnMouseOut1={group_button_handlers_out[1]}
          PGBButtonOnMouseOver={group_button_handlers_over[0]}
          PGBButtonOnMouseOver1={group_button_handlers_over[1]}
          PGBButtonPtLabel={group_button_labels[0]}
          PGBButtonPtLabel1={group_button_labels[1]}
          PGBButtonPtSubLabel={group_button_sub_labels[1]}
          PGBButtonVarState={group_button_states[0]}
          PGBButtonVarState1={group_button_states[1]}
        />
      break;
    default:
      buttons_group =
        <PgbConC
          PGBButtonOnClick={group_button_handlers[0]}
          PGBButtonOnClick1={group_button_handlers[1]}
          PGBButtonOnClick2={group_button_handlers[2]}
          PGBButtonOnMouseMove={group_button_handlers_move[0]}
          PGBButtonOnMouseMove1={group_button_handlers_move[1]}
          PGBButtonOnMouseMove2={group_button_handlers_move[2]}
          PGBButtonOnMouseOut={group_button_handlers_out[0]}
          PGBButtonOnMouseOut1={group_button_handlers_out[1]}
          PGBButtonOnMouseOut2={group_button_handlers_out[2]}
          PGBButtonOnMouseOver={group_button_handlers_over[0]}
          PGBButtonOnMouseOver1={group_button_handlers_over[1]}
          PGBButtonOnMouseOver2={group_button_handlers_over[2]}
          PGBButtonPtLabel={group_button_labels[0]}
          PGBButtonPtLabel1={group_button_labels[1]}
          PGBButtonPtLabel2={group_button_labels[2]}
          PGBButtonPtSubLabel={group_button_sub_labels[1]}
          PGBButtonPtSubLabel1={group_button_sub_labels[2]}
          PGBButtonVarState={group_button_states[0]}
          PGBButtonVarState1={group_button_states[1]}
          PGBButtonVarState2={group_button_states[2]}
        />
  }

  return (
    <>
      <AgentPerk
        ptAvailablePerkPoint={point.toString()}
        ptTotalPerkPoint={(point_total - point).toString()}
        override={buttons_group}
        perkLevels={<>
          {Array.from(Array(highest_level).keys()).map((i) => <PerkLevel key={i} PTPerklevel={`${1 + i}`} />)}
        </>}
        perkTreesContainer={perktrees}
      />
      <PerktooltipSi
        style={style_tt_perk}
        ptTitle={title_tt_perk}
        ptDesc={desc_tt_perk}
      />
      <PgbtooltipSi
        style={style_tt_btn}
        override={<>{contents_tt_btn.map((pair) =>
          <PgbTooltipContent
            key={pair[0]}
            ptTitle={pair[0]}
            ptDesc={pair[1]}
          />
        )}</>}
      />
    </>
  );
};
