// downloaded data!
export const data = [
  {
    "key": "ambition_1_honor_1",
    "name": "명예로운 경력",
    "condition": "성공시킨 임무의 명성 합계 150 이상"
  },
  {
    "key": "ambition_1_honor_2",
    "name": "명예로운 경력",
    "condition": "성공시킨 임무의 명성 합계 600 이상"
  },
  {
    "key": "ambition_1_honor_3",
    "name": "명예로운 경력",
    "condition": "성공시킨 임무의 명성 합계 2400 이상"
  },
  {
    "key": "ambition_1_honor_4",
    "name": "명예로운 경력",
    "condition": "성공시킨 임무의 명성 합계 9600 이상"
  },
  {
    "key": "ambition_2_deposit_1",
    "name": "목돈 마련",
    "condition": "월급, 인센티브, 위험수당 등 모든 수입의 합 3000 이상"
  },
  {
    "key": "ambition_2_deposit_2",
    "name": "목돈 마련",
    "condition": "월급, 인센티브, 위험수당 등 모든 수입의 합 5000 이상"
  },
  {
    "key": "ambition_2_deposit_3",
    "name": "목돈 마련",
    "condition": "월급, 인센티브, 위험수당 등 모든 수입의 합 8000 이상"
  },
  {
    "key": "ambition_2_deposit_4",
    "name": "목돈 마련",
    "condition": "월급, 인센티브, 위험수당 등 모든 수입의 합 12000 이상"
  },
  {
    "key": "ambition_3_success",
    "name": "성공적인 경력",
    "condition": "성공시킨 임무의 수 30회 이상"
  },
  {
    "key": "ambition_4_expert",
    "name": "군사 전문가",
    "condition": "무기 적성 모두 획득"
  },
  {
    "key": "ambition_5_onboard",
    "name": "업계 적응",
    "condition": "아무 퍽 트리 최종 퍽 1개 보유"
  },
  {
    "key": "ambition_6_legend",
    "name": "업계의 전설",
    "condition": "아무 퍽 트리 최종 퍽 2개 보유"
  },
  {
    "key": "ambition_7_hobby",
    "name": "성공한 취미",
    "condition": "창고에 *3 무기 모두 보유"
  },
  {
    "key": "ambition_8_commed",
    "name": "의료 전문가",
    "condition": "perk_commed_buff, perk_commed_amount 중 1개 보유"
  },
  {
    "key": "ambition_9_doctor",
    "name": "의료 권위자",
    "condition": "perk_commed_buff, perk_commed_amount 모두 보유"
  },
  {
    "key": "ambition_10_grenadier",
    "name": "척탄 전문가",
    "condition": "perk_granadier_shorter_blast, perk_granadier_special_2 중 1개 보유"
  },
  {
    "key": "ambition_11_bomber",
    "name": "척탄 권위자",
    "condition": "perk_granadier_shorter_blast, perk_granadier_special_2 모두 보유"
  },
  {
    "key": "ambition_12_desmar",
    "name": "지정사수 전문가",
    "condition": "perk_desmar_distraction_range, perk_desmar_aimspeed 중 1개 보유"
  },
  {
    "key": "ambition_13_reaper",
    "name": "지정사수 권위자",
    "condition": "perk_desmar_distraction_range, perk_desmar_aimspeed 모두 보유"
  }
];