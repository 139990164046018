import _ from 'lodash';

import { Component } from 'react';
import { FH1ProgressBar as ProgressBar } from './component/figma/fh1';

import { firearm_tys, firearm_ty_full } from './presets_firearm';
import { L } from './localization.mjs';

export class FirearmView extends Component {
  render() {
    const { firearm: f, prev_firearm: prev_f, hideDetail } = this.props;

    const progressprops = {
      width: 200,
      fgcolor: 'black',
      capcolor: 'black',
      bgcolor: 'white',
      textcolor: 'black',
    };

    const getrpm = (f) => {
      let fire_interval = f.firearm_shoot_pattern_interval_sec;
      if (f.firearm_shoot_pattern?.length > 0) {
        fire_interval = f.firearm_shoot_pattern[0];
      }
      return 60 / fire_interval;
    }

    const getRecoilControl = (f) => {
      return Math.min(1, 1 - f.aimvar_incr_per_shoot / f.aimvar_incr_per_shoot_mult);
    }

    const getAccuracy = (f) => {
      return Math.round(Math.PI / f.aimvar_hold_max);
    }

    let recoil = 'loc_ui_string_common_compare_firearm_recoil_verystrong';
    let recoil_mult = f.aimvar_incr_per_shoot_mult;
    if (recoil_mult < 0.5) {
      recoil = 'loc_ui_string_common_compare_firearm_recoil_weak';
    } else if (recoil_mult < 1) {
      recoil = 'loc_ui_string_common_compare_firearm_recoil_medium';
    } else if (recoil_mult < 3) {
      recoil = 'loc_ui_string_common_compare_firearm_recoil_strong';
    }

    const getCompareText = (diff) => {
      if (diff > 0) {
        return <>(<font className='item-arrow-higher'>{L('loc_dynamic_string_common_item_compare_higher')}</font>)</>;
      }
      else if (diff < 0) {
        return <>(<font className='item-arrow-lower'>{L('loc_dynamic_string_common_item_compare_lower')}</font>)</>
      }
      else {
        return <>({L('loc_dynamic_string_common_item_compare_equal')})</>;
      }
    }

    const getProgressBar = (cur, max, digits, prev) => {
      const text = hideDetail ? '' : `${cur.toFixed(digits)}/${max.toFixed(digits)}`;
      if (!prev) {
        return <ProgressBar cur={cur} max={max} {...progressprops} digits={digits} text={text} />
      }
      else {
        const diff = (cur - prev).toFixed(digits);
        if (diff > 0) {
          return <ProgressBar cur={prev} max={max} growth={diff} classname={'better'} {...progressprops} digits={digits} text={text} />
        }
        else if (diff < 0) {
          return <ProgressBar cur={cur} max={max} growth={-diff} classname={'worse'} {...progressprops} digits={digits} text={text} />
        }
        else {
          return <ProgressBar cur={cur} max={max} {...progressprops} digits={digits} text={text} />
        }
      }
    }

    let ammo_diff = null;
    let rpm_diff_progressBar = null;
    let damage_diff_progressBar = null;
    let recoil_diff = null;
    let recoil_control_diff_progressBar = null;
    let range_diff = null;
    let range_diff_progressBar = null;
    let accuracy_diff = null;
    let accuracy_diff_progressBar = null;

    if (prev_f) {
      ammo_diff = getCompareText(f.firearm_ammo_max - prev_f.firearm_ammo_max);
      rpm_diff_progressBar = getProgressBar(getrpm(f), Math.max(f.firearm_ty_max_rpm, prev_f.firearm_ty_max_rpm), 1, getrpm(prev_f));
      damage_diff_progressBar = getProgressBar(f.firearm_projectile_damage, Math.max(f.firearm_ty_max_damage, prev_f.firearm_ty_max_damage), 0, prev_f.firearm_projectile_damage);
      recoil_diff = getCompareText((prev_f.aimvar_incr_per_shoot_mult - f.aimvar_incr_per_shoot_mult).toFixed(1));
      recoil_control_diff_progressBar = getProgressBar(getRecoilControl(f), 1, 2, getRecoilControl(prev_f));
      range_diff = getCompareText(f.firearm_range - prev_f.firearm_range);
      accuracy_diff = getCompareText(prev_f.aimvar_hold_max - f.aimvar_hold_max);
      range_diff_progressBar = getProgressBar(f.firearm_range, 1200, 0, prev_f.firearm_range);
      accuracy_diff_progressBar = getProgressBar(getAccuracy(f), 1200, 0, getAccuracy(prev_f));
    }
    else {
      rpm_diff_progressBar = getProgressBar(getrpm(f), f.firearm_ty_max_rpm, 2);
      damage_diff_progressBar = getProgressBar(f.firearm_projectile_damage, f.firearm_ty_max_damage, 0);
      recoil_control_diff_progressBar = getProgressBar(getRecoilControl(f), 1, 2);
      range_diff_progressBar = getProgressBar(f.firearm_range, 1200, 0);
      accuracy_diff_progressBar = getProgressBar(getAccuracy(f), 1200, 0);
    }

    return <table>
      <tbody>
        <tr>
          <td>{L('loc_ui_string_common_compare_name')}</td>
          <td>{L(f.firearm_name)}</td>
        </tr>

        <tr>
          <td>{L('loc_ui_string_common_compare_firearm_class_and_tier')}</td>
          <td>{L('loc_ui_string_common_firearm_' + firearm_ty_full[f.firearm_ty] + '_short')} | {L('loc_dynamic_string_common_item_tier')}{f.firearm_rate}</td>
        </tr>

        <tr>
          <td>{L('loc_ui_string_common_compare_firearm_ammo')}</td>
          <td>{f.firearm_ammo_max}{ammo_diff}</td>
        </tr>

        <tr>
          <td>{L('loc_ui_string_common_compare_firearm_rpm')}</td>
          <td>{rpm_diff_progressBar}</td>
        </tr>

        <tr>
          <td>{L('loc_ui_string_common_compare_firearm_damage')}</td>
          <td>{damage_diff_progressBar}</td>
        </tr>

        <tr>
          <td>{L('loc_ui_string_common_compare_firearm_recoil')}</td>
          <td>{L(recoil)} {hideDetail ? '' : `(${recoil_mult})`} {recoil_diff}</td>
        </tr>

        <tr>
          <td>{L('loc_ui_string_common_compare_firearm_recoil_control')}</td>
          <td>{recoil_control_diff_progressBar}</td>
        </tr>

        <tr>
          <td>{L('loc_ui_string_common_compare_firearm_range')}</td>
          <td>{range_diff_progressBar}</td>
        </tr>

        <tr>
          <td>{L('loc_ui_string_common_compare_firearm_accuracy')}</td>
          <td>{accuracy_diff_progressBar}</td>
        </tr>
      </tbody>
    </table>;
  }
}

export class FirearmsView extends Component {
  render() {
    let count = _.max(Object.values(firearm_tys).map((t) => t.length));
    const tys = Object.keys(firearm_tys);

    let rows = [];

    for (let i = 0; i < count; i++) {
      rows.push(<tr key={i}>
        {tys.map((ty) => {
          let firearm = firearm_tys[ty][i];
          if (!firearm) {
            return <td key={ty}>N/A</td>;
          }
          return <td key={ty}>
            <FirearmView firearm={firearm_tys[ty][i]} prev_firearm={firearm_tys[ty][i]} />
          </td>;
        })}
      </tr>);
    }

    return <table>
      <tbody>
        {rows}
      </tbody>
    </table>;
  }
}
