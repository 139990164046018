// downloaded data!
export const data = [
  {
    "area": "0",
    "label": "E",
    "ty": "potential",
    "weight": "50"
  },
  {
    "area": "0",
    "label": "D",
    "ty": "potential",
    "weight": "34"
  },
  {
    "area": "0",
    "label": "C",
    "ty": "potential",
    "weight": "10"
  },
  {
    "area": "0",
    "label": "B",
    "ty": "potential",
    "weight": "5"
  },
  {
    "area": "0",
    "label": "A",
    "ty": "potential",
    "weight": "0.9"
  },
  {
    "area": "0",
    "label": "S",
    "ty": "potential",
    "weight": "0.1"
  },
  {
    "area": "1",
    "label": "E",
    "ty": "potential",
    "weight": "0"
  },
  {
    "area": "1",
    "label": "D",
    "ty": "potential",
    "weight": "50"
  },
  {
    "area": "1",
    "label": "C",
    "ty": "potential",
    "weight": "34"
  },
  {
    "area": "1",
    "label": "B",
    "ty": "potential",
    "weight": "10"
  },
  {
    "area": "1",
    "label": "A",
    "ty": "potential",
    "weight": "5"
  },
  {
    "area": "1",
    "label": "S",
    "ty": "potential",
    "weight": "1"
  },
  {
    "area": "2",
    "label": "E",
    "ty": "potential",
    "weight": "0"
  },
  {
    "area": "2",
    "label": "D",
    "ty": "potential",
    "weight": "0"
  },
  {
    "area": "2",
    "label": "C",
    "ty": "potential",
    "weight": "50"
  },
  {
    "area": "2",
    "label": "B",
    "ty": "potential",
    "weight": "30"
  },
  {
    "area": "2",
    "label": "A",
    "ty": "potential",
    "weight": "15"
  },
  {
    "area": "2",
    "label": "S",
    "ty": "potential",
    "weight": "5"
  },
  {
    "area": "3",
    "label": "E",
    "ty": "potential",
    "weight": "0"
  },
  {
    "area": "3",
    "label": "D",
    "ty": "potential",
    "weight": "0"
  },
  {
    "area": "3",
    "label": "C",
    "ty": "potential",
    "weight": "0"
  },
  {
    "area": "3",
    "label": "B",
    "ty": "potential",
    "weight": "50"
  },
  {
    "area": "3",
    "label": "A",
    "ty": "potential",
    "weight": "30"
  },
  {
    "area": "3",
    "label": "S",
    "ty": "potential",
    "weight": "20"
  },
  {
    "area": "0",
    "label": "E",
    "ty": "growthcap",
    "weight": "50"
  },
  {
    "area": "0",
    "label": "D",
    "ty": "growthcap",
    "weight": "34"
  },
  {
    "area": "0",
    "label": "C",
    "ty": "growthcap",
    "weight": "10"
  },
  {
    "area": "0",
    "label": "B",
    "ty": "growthcap",
    "weight": "5"
  },
  {
    "area": "0",
    "label": "A",
    "ty": "growthcap",
    "weight": "0.9"
  },
  {
    "area": "0",
    "label": "S",
    "ty": "growthcap",
    "weight": "0.1"
  },
  {
    "area": "1",
    "label": "E",
    "ty": "growthcap",
    "weight": "0"
  },
  {
    "area": "1",
    "label": "D",
    "ty": "growthcap",
    "weight": "50"
  },
  {
    "area": "1",
    "label": "C",
    "ty": "growthcap",
    "weight": "34"
  },
  {
    "area": "1",
    "label": "B",
    "ty": "growthcap",
    "weight": "10"
  },
  {
    "area": "1",
    "label": "A",
    "ty": "growthcap",
    "weight": "5"
  },
  {
    "area": "1",
    "label": "S",
    "ty": "growthcap",
    "weight": "1"
  },
  {
    "area": "2",
    "label": "E",
    "ty": "growthcap",
    "weight": "0"
  },
  {
    "area": "2",
    "label": "D",
    "ty": "growthcap",
    "weight": "0"
  },
  {
    "area": "2",
    "label": "C",
    "ty": "growthcap",
    "weight": "50"
  },
  {
    "area": "2",
    "label": "B",
    "ty": "growthcap",
    "weight": "30"
  },
  {
    "area": "2",
    "label": "A",
    "ty": "growthcap",
    "weight": "15"
  },
  {
    "area": "2",
    "label": "S",
    "ty": "growthcap",
    "weight": "5"
  },
  {
    "area": "3",
    "label": "E",
    "ty": "growthcap",
    "weight": "0"
  },
  {
    "area": "3",
    "label": "D",
    "ty": "growthcap",
    "weight": "0"
  },
  {
    "area": "3",
    "label": "C",
    "ty": "growthcap",
    "weight": "0"
  },
  {
    "area": "3",
    "label": "B",
    "ty": "growthcap",
    "weight": "50"
  },
  {
    "area": "3",
    "label": "A",
    "ty": "growthcap",
    "weight": "30"
  },
  {
    "area": "3",
    "label": "S",
    "ty": "growthcap",
    "weight": "20"
  },
  {
    "area": "0",
    "label": "E",
    "ty": "perkpointcap",
    "weight": "50"
  },
  {
    "area": "0",
    "label": "D",
    "ty": "perkpointcap",
    "weight": "34"
  },
  {
    "area": "0",
    "label": "C",
    "ty": "perkpointcap",
    "weight": "10"
  },
  {
    "area": "0",
    "label": "B",
    "ty": "perkpointcap",
    "weight": "5"
  },
  {
    "area": "0",
    "label": "A",
    "ty": "perkpointcap",
    "weight": "0.9"
  },
  {
    "area": "0",
    "label": "S",
    "ty": "perkpointcap",
    "weight": "0.1"
  },
  {
    "area": "1",
    "label": "E",
    "ty": "perkpointcap",
    "weight": "0"
  },
  {
    "area": "1",
    "label": "D",
    "ty": "perkpointcap",
    "weight": "50"
  },
  {
    "area": "1",
    "label": "C",
    "ty": "perkpointcap",
    "weight": "34"
  },
  {
    "area": "1",
    "label": "B",
    "ty": "perkpointcap",
    "weight": "10"
  },
  {
    "area": "1",
    "label": "A",
    "ty": "perkpointcap",
    "weight": "5"
  },
  {
    "area": "1",
    "label": "S",
    "ty": "perkpointcap",
    "weight": "1"
  },
  {
    "area": "2",
    "label": "E",
    "ty": "perkpointcap",
    "weight": "0"
  },
  {
    "area": "2",
    "label": "D",
    "ty": "perkpointcap",
    "weight": "0"
  },
  {
    "area": "2",
    "label": "C",
    "ty": "perkpointcap",
    "weight": "50"
  },
  {
    "area": "2",
    "label": "B",
    "ty": "perkpointcap",
    "weight": "30"
  },
  {
    "area": "2",
    "label": "A",
    "ty": "perkpointcap",
    "weight": "15"
  },
  {
    "area": "2",
    "label": "S",
    "ty": "perkpointcap",
    "weight": "5"
  },
  {
    "area": "3",
    "label": "E",
    "ty": "perkpointcap",
    "weight": "0"
  },
  {
    "area": "3",
    "label": "D",
    "ty": "perkpointcap",
    "weight": "0"
  },
  {
    "area": "3",
    "label": "C",
    "ty": "perkpointcap",
    "weight": "0"
  },
  {
    "area": "3",
    "label": "B",
    "ty": "perkpointcap",
    "weight": "50"
  },
  {
    "area": "3",
    "label": "A",
    "ty": "perkpointcap",
    "weight": "30"
  },
  {
    "area": "3",
    "label": "S",
    "ty": "perkpointcap",
    "weight": "20"
  },
  {
    "area": "0",
    "label": "E",
    "ty": "physicalcap",
    "weight": "50"
  },
  {
    "area": "0",
    "label": "D",
    "ty": "physicalcap",
    "weight": "34"
  },
  {
    "area": "0",
    "label": "C",
    "ty": "physicalcap",
    "weight": "10"
  },
  {
    "area": "0",
    "label": "B",
    "ty": "physicalcap",
    "weight": "5"
  },
  {
    "area": "0",
    "label": "A",
    "ty": "physicalcap",
    "weight": "0.9"
  },
  {
    "area": "0",
    "label": "S",
    "ty": "physicalcap",
    "weight": "0.1"
  },
  {
    "area": "1",
    "label": "E",
    "ty": "physicalcap",
    "weight": "0"
  },
  {
    "area": "1",
    "label": "D",
    "ty": "physicalcap",
    "weight": "50"
  },
  {
    "area": "1",
    "label": "C",
    "ty": "physicalcap",
    "weight": "34"
  },
  {
    "area": "1",
    "label": "B",
    "ty": "physicalcap",
    "weight": "10"
  },
  {
    "area": "1",
    "label": "A",
    "ty": "physicalcap",
    "weight": "5"
  },
  {
    "area": "1",
    "label": "S",
    "ty": "physicalcap",
    "weight": "1"
  },
  {
    "area": "2",
    "label": "E",
    "ty": "physicalcap",
    "weight": "0"
  },
  {
    "area": "2",
    "label": "D",
    "ty": "physicalcap",
    "weight": "0"
  },
  {
    "area": "2",
    "label": "C",
    "ty": "physicalcap",
    "weight": "50"
  },
  {
    "area": "2",
    "label": "B",
    "ty": "physicalcap",
    "weight": "30"
  },
  {
    "area": "2",
    "label": "A",
    "ty": "physicalcap",
    "weight": "15"
  },
  {
    "area": "2",
    "label": "S",
    "ty": "physicalcap",
    "weight": "5"
  },
  {
    "area": "3",
    "label": "E",
    "ty": "physicalcap",
    "weight": "0"
  },
  {
    "area": "3",
    "label": "D",
    "ty": "physicalcap",
    "weight": "0"
  },
  {
    "area": "3",
    "label": "C",
    "ty": "physicalcap",
    "weight": "0"
  },
  {
    "area": "3",
    "label": "B",
    "ty": "physicalcap",
    "weight": "50"
  },
  {
    "area": "3",
    "label": "A",
    "ty": "physicalcap",
    "weight": "30"
  },
  {
    "area": "3",
    "label": "S",
    "ty": "physicalcap",
    "weight": "20"
  }
];