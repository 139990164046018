import '../style.css';

export function FH1Check(props) {
  const { onChange } = props;
  const checked = !!props.checked;
  const disabled = !!props.disabled;
  const enabled = !disabled;
  return <div {...props} className={`fh1-check ${props.className ?? ''}`} onClick={() => {
    if (disabled) return;
    const value = !checked;
    const ev = { target: { checked: value } }
    onChange && onChange(ev);
  }}>
    <div className={`fh1-checkshape ${checked ? 'checked' : 'unchecked'} ${enabled ? 'enabled' : 'disabled'}`}>
      <svg viewBox='-3 -4 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M0 6.5L7 13L18 0' strokeWidth='6' />
      </svg>
    </div>
    <div className='fh1-label'>{props.label ?? props.children}</div>
  </div>;
}
