import { data as source } from '../downloaded/items.mjs';

export const items = [];
for (const row of source) {
  const {
    key,
    buy_cost,
    sell_cost,
    durability,
  } = row;

  items.push({
    key,
    buy_cost: parseInt(buy_cost),
    sell_cost: parseInt(sell_cost),
    durability: parseInt(durability),
  });
}
