// downloaded data!
export const data = [
  {
    "unlocks": "0",
    "renown": "0"
  },
  {
    "unlocks": "1",
    "renown": "17.5"
  },
  {
    "unlocks": "2",
    "renown": "40"
  },
  {
    "unlocks": "3",
    "renown": "70"
  },
  {
    "unlocks": "4",
    "renown": "110"
  },
  {
    "unlocks": "5",
    "renown": "162.5"
  },
  {
    "unlocks": "6",
    "renown": "230"
  },
  {
    "unlocks": "7",
    "renown": "315"
  },
  {
    "unlocks": "8",
    "renown": "420"
  },
  {
    "unlocks": "9",
    "renown": "547.5"
  },
  {
    "unlocks": "10",
    "renown": "700"
  }
];