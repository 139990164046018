import React from 'react';
import _ from 'lodash';
import { Chart, registerables } from 'chart.js';
import dayjs from 'dayjs';
import * as cytoscape from 'cytoscape';
import spread from 'cytoscape-spread';

import { tickToDate, tickToDateStr } from './tick';

import { Rng } from './rand.mjs';

cytoscape.use(spread);

Chart.register(...registerables);

const opts = {
  UPGRADE_MULT: {
    default: 0.95,
    min: 0.5,
    max: 0.99,
  },
  EVENT_PROB: {
    default: 0.015,
    min: 0,
    max: 1,
  },
  ACTION_COUNT_DECAY_PER_MONTH: {
    default: 2,
    min: 0,
    max: 10,
  },
  PER: {
    default: 0.1,
    min: 0.01,
    max: 10,
  },

  COST_ACTION_BASE: {
    default: 100,
    min: 10,
    max: 10000,
  },
  COST_ACTION_EXPONENT: {
    default: 2.1,
    min: 1.0,
    max: 3.0,
  },

  DISTANCE_PANALTY: {
    default: 1.2,
    min: 1.0,
    max: 10.0,
  },

  OWNERSHIP_WEIGHT_DECAY: {
    default: 0.99933,
    min: 0.1,
    max: 1.0,
  },
};

const SPEEDS = [1, 3, 5, 100];

const COLORS = [
  '#eee',
  '#FD8A8A',
  '#F1F7B5',
  '#A8D1D1',
  '#9EA1D4',
];


function softmax(arr) {
  return arr.map((value, index) =>
    Math.exp(value) / arr.map((y) => Math.exp(y)).reduce((a, b) => a + b)
  )
}

const production_levels = [];
for (let i = 1; i < 100; i++) {
  production_levels.push({
    level: i - 1,
    upgrade_cost: 300 * i,
    amount: 100 * (i - 1),
  });
}

const OWNWERSHIP_GRACE_TICKS = 90;

const OWNERSHIP_CAP_OWNER = 30;
const OWNERSHIP_CAP_OTHER = 35;

class Ownership {
  constructor(tick, player_count) {
    this.weights = [1];
    for (let i = 0; i < player_count; i++) {
      // default: -1
      this.weights.push(-1);
    }
    this._update_softmax();

    this.state = {
      owner: this.dominant(),
      tick: tick + OWNWERSHIP_GRACE_TICKS,
    };
  }

  set_weight(tick, player_idx, amount) {
    const other = new Ownership(tick, this.weights.length - 1);
    other.weights = this.weights.slice();
    other.weights[player_idx] = amount;

    other._update_softmax();

    const owner = other.dominant();
    if (owner !== this.state.owner) {
      other.state = { owner, tick: tick + OWNWERSHIP_GRACE_TICKS };
    } else {
      other.state = this.state;
    }

    return other;
  }

  allowed(tick, player_idx) {
    if (this.state.owner === player_idx) {
      return this.weights[player_idx] < OWNERSHIP_CAP_OWNER;
    }
    if (this.state.tick < tick) {
      return this.weights[player_idx] < OWNERSHIP_CAP_OTHER;
    }
    return false;
  }

  add_weight(tick, player_idx, amount) {
    return this.set_weight(tick, player_idx, this.weights[player_idx] + amount);
  }

  _update_softmax() {
    this.softmax = softmax(this.weights.map((w) => {
      if (w <= -1) {
        return -100;
      } else {
        return w;
      }
    }));
  }

  share(player_idx) {
    return this.softmax[player_idx];
  }

  dominant() {
    return this.softmax.findIndex((w) => w >= 0.5);
  }

  decay(decay) {
    for (let i = 0; i < this.weights.length; i++) {
      if (this.weights[i] > 0) {
        this.weights[i] *= decay;
      }
    }
    this._update_softmax();
  }
}

const VIS_SIZE_PROD = 40;
const VIS_DISCOUNT_W = 5;
const VIS_SIZE_PROFIT = 30;

const VIS_SIZE_TENSION = 20;

const TMPL = {
  discount: 0,
  margin: 1,
  closed: false,

  vis_team: 0,
  vis_color_team: COLORS[0],
  vis_color_src: COLORS[0],

  vis_discount: VIS_DISCOUNT_W,
  vis_size_prod: VIS_SIZE_PROD,
  vis_label_profit: ``,

  vis_size_tension: VIS_SIZE_TENSION,
  vis_label_tension: '',

  modifiers: {
    discount_offset: 0,
    margin_offset: 0,
  },
};

function stepSrc(id, next) {
  return {
    ...TMPL,
    id,
    next,
    production_lv: 1,
  };
}

function isSrc(step) {
  return !isNaN(step.production_lv);
}

function histItem(key) {
  return {
    key,
    income: 0,
    spending: 0,
    valuation: 0,
    action_count: 0,
  }
};

function histInit(tick) {
  const histories = [];
  const end = dayjs(tickToDate(tick));
  let date = dayjs(end).subtract(1, 'year').add(1, 'month');
  while (date.unix() <= end.unix()) {
    histories.push(histItem(date.format('YYYY-MM')));
    date = date.add(1, 'month');
  }
  return histories;
}

class WorldState {
  constructor() {
    const tick = 0;
    this.tick = tick;

    const player_count = 4;
    this.player_count = player_count;

    const stepStart = (id, next, owner) => {
      return {
        ...TMPL,
        id,
        next,
        discount: 0.5,
        margin: 0.5,
        ownerships: new Ownership(tick, player_count).add_weight(tick, owner, 4),
        start_player_idx: owner,
      };
    }

    const stepOwn = (s, player_idx) => {
      if (!s.ownerships) {
        s.ownerships = new Ownership(tick, this.player_count);
      }
      s.ownerships = s.ownerships.add_weight(tick, player_idx, 5);
      return s;
    };

    this.steps = [
      // player #4
      stepOwn(stepSrc('src-6', 'r3-0'), 4),
      stepStart('r3-0', 'r0-1', 4),

      // player #1
      stepOwn(stepSrc('src-0', 'r0-0'), 1),
      stepOwn(stepSrc('src-1', 'r0-0'), 1),
      stepStart('r0-0', 'r0-1', 1),
      { ...TMPL, id: 'r0-1', next: 'r-2', discount: 0.95, margin: 0.05 },
      // { ...TMPL, id: 'r0-1', next: 'r0-2', discount: 0.95, margin: 0.5 },
      // { ...TMPL, id: 'r0-2', next: 'r-3', discount: 0.95, margin: 0.5 },

      // player #2
      stepOwn(stepSrc('src-2', 'r1-0'), 2),
      stepOwn(stepSrc('src-3', 'r1-0'), 2),
      stepStart('r1-0', 'r-2', 2),
      // { ...TMPL, id: 'r1-1', next: 'r-2', discount: 0.95, margin: 0.5 },
      // { ...TMPL, id: 'r1-1', next: 'r1-2', discount: 0.95, margin: 0.5 },
      // { ...TMPL, id: 'r1-2', next: 'r-3', discount: 0.95, margin: 0.5 },

      // player #3
      stepOwn(stepSrc('src-4', 'r2-0'), 3),
      stepOwn(stepSrc('src-5', 'r2-0'), 3),
      stepStart('r2-0', 'r2-1', 3),
      { ...TMPL, id: 'r2-1', next: 'r-2', discount: 0.95, margin: 0.5 },
      // { ...TMPL, id: 'r2-1', next: 'r2-2', discount: 0.95, margin: 0.5 },
      // { ...TMPL, id: 'r2-2', next: 'r-3', discount: 0.95, margin: 0.5 },

      { ...TMPL, id: 'r-2', next: 'r-3', discount: 0.5, margin: 0.05 },
      { ...TMPL, id: 'r-3', next: 'sink', discount: 0.5, margin: 0.05 },

      { ...TMPL, id: 'sink', next: null, discount: 0, margin: 0 },
    ];

    for (const step of this.steps) {
      if (!step.ownerships) {
        step.ownerships = new Ownership(tick, this.player_count);
      }
    }

    this.opts = {};
    for (const optkey of Object.keys(opts)) {
      this.opts[optkey] = opts[optkey].default;
    }
    this.price_retail = 1000;

    this.players = [null];
    for (let i = 0; i < this.player_count; i++) {
      this.players.push({
        action_count: 1,
        balance: 1000,
        profit: 0,
        histories: histInit(this.tick),
      });
    }

    this.dryrun = false;

    this.calculate();
  }

  dist0(src, dst) {
    const { steps } = this;
    if (src === dst) {
      return 0;
    }
    let dist = 1;
    let cur = steps.find((s) => s.id === src);
    while (cur && cur.next !== null) {
      const id = cur.next;
      if (id === dst) {
        return dist;
      }
      cur = steps.find((s) => s.id === id);
      dist += 1;
    }
    return -1;
  }

  dist(src, dst) {
    return Math.max(this.dist0(src, dst), this.dist0(dst, src));
  }

  distToSink(id) {
    const { steps } = this;
    let dist = 0;
    let cur = steps.find((s) => s.id === id);
    while (cur.next !== null) {
      const id = cur.next;
      cur = steps.find((s) => s.id === id);
      dist += 1;
    }
    return dist;
  }

  calculate() {
    let { steps, price_retail } = this;
    const deps = {};
    for (const step of steps) {
      deps[step.id] = 0;
    }

    /*
    for (const step of steps) {
      if (step.next === null) {
        continue;
      }
      const next = steps.find((s) => s.id === step.next);
      deps[next.id] += 1;
    }
    const orders = [];
    while (orders.length < steps.length) {
      const next = steps.find((s) => deps[s.id] === 0);
      deps[next.id] -= 1;
      deps[next.next] -= 1;
      orders.push({ ...next });
    }
    steps = orders;
    */

    // backward: calculate unit price
    for (let i = steps.length - 1; i >= 0; i--) {
      const step = steps[i];
      if (step.next === null) {
        step.unit_amount = 1;
        step.price_sell = price_retail;
        step.price_buy = price_retail;
        step.amount = 0;
        continue;
      }
      const next = steps.find((s) => s.id === step.next);

      let unit_amount = next.unit_amount;
      let price = next.price_buy;

      const discount = Math.min(1.0, Math.max(0.0, step.discount + step.modifiers.discount_offset));
      const margin = Math.min(1.0, Math.max(0.0, step.margin + step.modifiers.margin_offset));
      const discount_mult = (1 - discount);
      unit_amount = unit_amount / discount_mult;
      step.unit_amount = unit_amount;
      price = price * discount_mult;
      step.price_sell = price;
      price = price * (1 - margin);
      step.price_buy = price;


      if (isSrc(step) && !step.closed) {
        step.amount = production_levels[step.production_lv].amount;
      } else {
        step.amount = 0;
      }
    }

    // forward: calculate amount
    for (let i = 0; i < steps.length; i++) {
      const step = steps[i];
      const { price_sell, price_buy } = step;

      const next = steps.find((s) => s.id === step.next);

      const amount = step.amount;
      const discount = Math.min(1.0, Math.max(0.0, step.discount + step.modifiers.discount_offset));
      const amount_next = Math.round(amount * (1 - discount));
      if (next) {
        next.amount += amount_next;
      }

      const loss = amount - amount_next;
      const revenue = price_sell * amount;
      const profit = Math.round((price_sell - price_buy) * amount);

      // visualization
      steps[i] = { ...step, revenue, profit, loss };
    }

    // visualization properties
    const amount_max = _.max(steps.map((s) => s.amount));
    // const profit_max = _.max(steps.map((s) => s.profit));
    const tension_max = _.max(steps.map((s) => _.sum(s.ownerships.weights) + this.players.length));

    const profit_scale = _.max(steps.map((s) => Math.sqrt(s.profit)));

    for (const step of steps) {
      const is_src = isSrc(step);
      step.vis_team = step.ownerships.dominant();
      step.vis_color_team = COLORS[step.vis_team];
      step.vis_color_src = COLORS[0 | is_src];

      step.vis_discount = 1 + VIS_DISCOUNT_W * (1 - step.discount);
      step.vis_size_prod = VIS_SIZE_PROD * step.amount / amount_max;
      step.vis_label_profit = `$${Math.round(step.profit)}`;

      const profit = Math.max(1, step.profit);
      // const profit_r = Math.log(profit) / Math.log(profit_max);
      step.vis_size_profit = VIS_SIZE_PROFIT * Math.sqrt(profit) / profit_scale;

      const tension = _.sum(step.ownerships.weights) + this.players.length;

      step.vis_label_tension = tension.toFixed(1).toString();

      step.vis_size_tension = VIS_SIZE_TENSION * tension / tension_max;
    }

    this.steps = steps;

    if (!this.dryrun) {
      for (let player_idx = 1; player_idx <= this.player_count; player_idx++) {
        let profit = 0;
        for (const step of this.steps) {
          const share = step.ownerships.share(player_idx);
          profit += step.profit * share;
        }
        this.players[player_idx].profit = Math.round(profit);
      }
    }
  }

  actions2(s) {
    const { margin_offset, discount_offset } = s.modifiers;
    return [
      {
        label: `m+`, action: () => {
          s.modifiers = { ...s.modifiers, margin_offset: margin_offset + 0.05 };
        },
      },
      {
        label: `m-`, action: () => {
          s.modifiers = { ...s.modifiers, margin_offset: margin_offset - 0.05 };
        }
      },
      {
        label: `d+`, action: () => {
          s.modifiers = { ...s.modifiers, discount_offset: discount_offset + 0.05 };
        }
      },
      {
        label: `d-`, action: () => {
          s.modifiers = { ...s.modifiers, discount_offset: discount_offset - 0.05 };
        }
      },
    ];
  }

  actions(s, player_idx) {
    const { tick, steps, players, opts } = this;
    const { action_count } = players[player_idx];

    const reachable = (step) => {
      if (step.ownerships.share(player_idx) >= 0.5) {
        return true;
      }
      const next = steps.find((s) => s.id === step.next && s.ownerships.share(player_idx) >= 0.5);
      if (next) {
        return true;
      }
      const prev = steps.find((s) => s.next === step.id && s.ownerships.share(player_idx) >= 0.5);
      if (prev) {
        return true;
      }
      return false;
    };

    if (!reachable(s)) {
      return [];
    }

    let cost_action = opts.COST_ACTION_BASE * Math.pow(action_count, opts.COST_ACTION_EXPONENT);
    {
      const base = steps.find((s) => s.start_player_idx === player_idx);
      const d = this.dist(base.id, s.id);
      if (d > 0) {
        cost_action *= Math.pow(opts.DISTANCE_PANALTY, d);
      }
    }
    cost_action = Math.floor(cost_action);

    const cost_src = Math.pow(10, steps.filter((s) => isSrc(s)).length + 2)

    const share_amount = 0.1;
    const share_price = Math.floor(s.profit * 365 * opts.PER * share_amount);
    const actions = [];
    if (s.ownerships.share(player_idx) < 0.8 && s.ownerships.allowed(tick, player_idx) && share_price > 0) {
      actions.push({
        key: 'ownership',
        label: `share $${share_price}`,
        price: share_price,
        action: () => this.buyShareFixed(player_idx, share_price, s.id, share_amount),
      });
    }

    if (false && s.ownerships.share(player_idx) > 0.5) {
      actions.push({
        key: 'margin-plus',
        label: `m+ $${cost_action}`,
        price: cost_action,
        action: () => this.buyMargin(player_idx, cost_action, s.id, true),
      });

      actions.push({
        key: 'margin-minus',
        label: `m- $${cost_action}`,
        price: cost_action,
        action: () => this.buyMargin(player_idx, cost_action, s.id, false),
      });
    }

    if (s.next !== null && !isSrc(s)) {
      actions.push({
        key: 'addsrc',
        label: `add src $${cost_src}`,
        price: cost_src,
        action: () => this.addSource(player_idx, cost_src, s.id),
      });
    }

    if (isSrc(s) && s.production_lv < production_levels.length - 1) {
      actions.push({
        key: 'production',
        label: `production $${cost_action}`,
        price: cost_action,
        action: () => this.buyProduction(player_idx, cost_action, s.id),
      });
    }

    if (s.discount > 0) {
      actions.push({
        key: 'discount',
        label: `discount $${cost_action}`,
        price: cost_action,
        action: () => this.buyDiscount(player_idx, cost_action, s.id),
      });
    }
    return actions;
  }

  buyWith(player_idx, cost, f) {
    let { tick, players } = this;
    const { balance } = players[player_idx];

    if (!this.dryrun) {
      if (balance < cost) {
        return;
      }
    }

    f(this);

    this.calculate();

    if (!this.dryrun) {
      const player = this.players[player_idx];

      player.balance -= cost;
      player.action_count += 1;

      const item = this.historyItem(player_idx, tick);
      item.spending += cost;
      item.action_count = player.action_count;
    }
  }

  buyProduction(player_idx, cost, id) {
    return this.buyWith(player_idx, cost, (world) => {
      world.steps = world.steps.map((s) => {
        if (s.id === id) {
          return { ...s, production_lv: s.production_lv + 1 };
        }
        return s;
      });
    });
  }

  buyDiscount(player_idx, cost, id) {
    return this.buyWith(player_idx, cost, (world) => {
      world.steps = world.steps.map((s) => {
        if (s.id === id) {
          return { ...s, discount: s.discount * world.opts.UPGRADE_MULT, };
        }
        return s;
      });
    });
  }

  buyMargin(player_idx, cost, id, incr) {
    return this.buyWith(player_idx, cost, (world) => {
      world.steps = world.steps.map((s) => {
        if (s.id === id) {
          if (incr) {
            let margin = 1 - (1 - s.margin) * world.opts.UPGRADE_MULT;
            return { ...s, margin };
          } else {
            let margin = s.margin * world.opts.UPGRADE_MULT;
            return { ...s, margin };
          }
        }
        return s;
      });
    });
  }

  buyShareFixed(player_idx, cost, id, amount) {
    let { tick, players } = this;
    let { balance } = players[player_idx];

    if (!this.dryrun) {
      if (balance < cost) {
        return;
      }
    }

    this.steps = this.steps.map((s) => {
      if (s.id === id) {
        return { ...s, ownerships: s.ownerships.add_weight(tick, player_idx, amount) };
      }
      return s;
    });
    this.calculate();

    if (!this.dryrun) {
      const player = this.players[player_idx];
      player.balance -= cost;

      const item = this.historyItem(player_idx, tick);
      item.spending += cost;
    }
  }

  addSource(player_idx, cost, parent_id) {
    const { tick, players } = this;
    let { balance } = players[player_idx];

    if (balance < cost) {
      return;
    }
    players[player_idx].balance -= cost;

    let steps = this.steps.slice();
    const idx = steps.filter((s) => isSrc(s)).length;
    const step = stepSrc(`src-${idx}`, parent_id);
    step.ownerships = new Ownership(tick, this.player_count);

    const parent_idx = this.steps.findIndex((s) => s.id === parent_id);
    const steps_tail = steps.splice(parent_idx);

    this.steps = [
      ...steps,
      step,
      ...steps_tail,
    ];
    this.calculate();
  }

  historyItem(player_idx, tick) {
    const player = this.players[player_idx];
    const tick_key = dayjs(tickToDate(tick)).format('YYYY-MM');
    let history_item = player.histories.find((h) => h.key === tick_key);
    if (!history_item) {
      history_item = {
        key: tick_key,
        income: 0,
        spending: 0,
      };
      player.histories.push(history_item);
    }
    return history_item;
  }

  onTick() {
    // decay
    for (const step of this.steps) {
      step.ownerships.decay(this.opts.OWNERSHIP_WEIGHT_DECAY);
    }
    this.calculate();

    for (let player_idx = 1; player_idx <= this.player_count; player_idx++) {
      const player = this.players[player_idx];
      player.balance += player.profit;

      const item = this.historyItem(player_idx, this.tick);
      item.income += player.profit;
      item.valuation = player.profit * 365 * this.opts.PER;
    }

    this.tick += 1;
  }
}

const GRAPH_VIEWS = ['name', 'prod', 'earn', 'tension'];
const STY_NODE = {
  'width': 12,
  'height': 12,
  'font-size': 10,
  'text-wrap': 'wrap',
};
const STY_EDGE = {
  'font-size': 8,
  'width': 1,
  'line-color': '#ccc',
  'curve-style': 'bezier',
  'target-arrow-color': '#ccc',
  'target-arrow-shape': 'triangle',
};

export class GraphView extends React.Component {
  constructor(props) {
    super(props);

    this.graphRef = React.createRef();
    this.state = {
      cy: null,
      view: 'tension',
    };
  }

  componentDidMount() {
    const { world } = this.props;
    const { steps } = world;

    const elements = [
      ...steps.map((step) => {
        const { id } = step;

        return {
          data: {
            id,
            step,
          },
          grabbable: false,
        };
      }),
      ...steps.map((step) => {
        if (!step.next) {
          return null;
        }
        const id = `${step.id}-${step.next}`;
        return {
          data: {
            id,
            source: step.id,
            target: step.next,
            src: step,
            dst: steps.find((s) => s.id === step.next),
          }
        };
      }).filter((v) => v !== null),
    ];

    const container = this.graphRef.current;
    const cy = cytoscape({
      container,

      userZoomingEnabled: false,
      boxSelectionEnabled: false,
      autounselectify: true,

      layout: {
        name: 'cose',
        animate: false,
      },

      style: [],

      elements,
    });

    this.onView(this.state.view, cy);
    this.setState({ cy });
  }

  componentWillUnmount() {
    const { cy } = this.state;
    if (cy) {
      cy.destroy();
    }
  }

  componentDidUpdate() {
    const { world } = this.props;
    const { cy } = this.state;
    for (const n0 of cy.nodes()) {
      const step = world.steps.find((step) => step.id === n0.data('id'));
      const team = step.ownerships.dominant();
      n0.data('team', team);
      n0.data('color', COLORS[team]);

      n0.data('step', step);
    }
  }

  onView(key, cy) {
    cy = cy ?? this.state.cy;
    if (key === 'name') {
      cy.style([{
        selector: 'node',
        style: {
          ...STY_NODE,
          'content': 'data(id)',
          'background-color': 'data(step.vis_color_team)',
        }
      },
      {
        selector: 'edge',
        style: {
          ...STY_EDGE,
          'content': 'data(id)',
        }
      },
      ]);
    } else if (key === 'prod') {
      cy.style([{
        selector: 'node',
        style: {
          ...STY_NODE,
          'width': 'data(step.vis_size_prod)',
          'height': 'data(step.vis_size_prod)',
          'content': 'data(step.amount)',
          'background-color': 'data(step.vis_color_src)',
        }
      },
      {
        selector: 'edge',
        style: {
          ...STY_EDGE,
          'width': 'data(src.vis_discount)',
          'content': 'data(src.discount)',
        }
      },
      ]);
    } else if (key === 'earn') {
      cy.style([{
        selector: 'node',
        style: {
          ...STY_NODE,
          'width': 'data(step.vis_size_profit)',
          'height': 'data(step.vis_size_profit)',
          'content': 'data(step.vis_label_profit)',
          'background-color': 'data(step.vis_color_team)',
        }
      },
      {
        selector: 'edge',
        style: {
          ...STY_EDGE,
          'content': '',
        }
      },
      ]);
    } else if (key === 'tension') {
      cy.style([{
        selector: 'node',
        style: {
          ...STY_NODE,
          'width': 'data(step.vis_size_tension)',
          'height': 'data(step.vis_size_tension)',
          'content': 'data(step.vis_label_tension)',
          'background-color': 'data(step.vis_color_team)',
        }
      },
      {
        selector: 'edge',
        style: {
          ...STY_EDGE,
          'content': '',
        }
      },
      ]);
    }

    this.setState({ view: key });
  }

  render() {
    const { view } = this.state;
    const sty = { width: 800, height: 600 };
    const buttons = GRAPH_VIEWS.map((key) => {
      const cls = (view === key) ? 'selected' : '';
      return <button key={key} className={cls} onClick={() => this.onView(key)}>{key}</button>;
    });
    return <div style={{ display: 'inline-block' }}>
      {buttons}
      <br />
      <div style={sty} ref={this.graphRef}></div>
    </div>;
  }
}

export class TableView extends React.Component {
  constructor(props) {
    super(props);

    this.keyDown = this.onKeyDown.bind(this);

    this.timer = null;
    this.last_ms = Date.now();
    this.onTimer = () => {
      let { last_ms } = this;
      const { simtps } = this.state;
      const now = Date.now();

      while (last_ms < now) {
        last_ms += 1000 / simtps;
        this.onTick();
      }

      this.startTimer();
      this.last_ms = last_ms;
    };

    this.chartPlayerRef = React.createRef();
    this.chartSummaryRef = React.createRef();
    this.state = this.initialState();
  }

  initialState() {
    const world = new WorldState();

    return {

      rng: new Rng(),
      simtps: SPEEDS[1],
      paused: false,
      auto: false,

      cur_player_idx: 1,
      world,

      events: [],
    };
  }

  initCharts() {
    this.chartPlayer = new Chart(this.chartPlayerRef.current, {
      type: 'bar',
      data: { labels: [], datasets: [] },
      options: {
        animation: false,
        aspectRatio: 5,
        scales: {
          y: {
            title: { display: true, text: 'cash flow' },
            beginAtZero: true,
            position: 'left',
            ticks: { callback: (value, index, ticks) => '$' + value, }
          },
          y1: {
            title: { display: true, text: 'valuation' },
            position: 'right',
            grid: {
              drawOnChartArea: false, // only want the grid lines for one axis to show up
            },
            ticks: { callback: (value, index, ticks) => '$' + value, }
          },
        }
      }
    });

    this.chartSummary = new Chart(this.chartSummaryRef.current, {
      type: 'line',
      data: { labels: [], datasets: [] },
      options: {
        animation: false,
        aspectRatio: 5,
        scales: {
          y: {
            title: { display: true, text: 'cash flow' },
            beginAtZero: true,
            position: 'left',
            ticks: { callback: (value, index, ticks) => '$' + value, }
          },
        }
      }
    });
  }

  componentDidMount() {
    document.addEventListener('keydown', this.keyDown);

    if (!this.state.paused) {
      this.startTimer();
    }

    this.initCharts();
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyDown);
    this.stopTimer();
    this.chartPlayer.destroy();
    this.chartSummary.destroy();
  }

  onKeyDown(ev) {
    for (let i = 0; i < SPEEDS.length; i++) {
      if (ev.key === (i + 1).toString()) {
        this.setState({ simtps: SPEEDS[i] });
        return;
      }
    }

    if (ev.key === 's') {
      this.onTick();
      return;
    }
    if (ev.key === 'r') {
      this.setState(this.initialState());
      return;
    }

    if (ev.key === 'a') {
      ev.preventDefault();
      this.setState({ auto: !this.state.auto });
    }

    if (ev.key === ' ') {
      ev.preventDefault();
      const { paused } = this.state;
      if (paused) {
        this.startTimer();
      } else {
        this.stopTimer();
      }
      this.setState({ paused: !paused });
    }
  }

  startTimer() {
    const { simtps } = this.state;
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.last_ms = Date.now();
    this.timer = setTimeout(this.onTimer, 1000 / simtps);
  }

  stopTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  onTickAutoPlayer(world, tick, player_idx) {
    const results = [];
    const { steps, players } = world;
    const { balance } = players[player_idx];
    world.dryrun = true;

    const income_base = _.sum(world.steps.map((s) => s.profit * s.ownerships.share(player_idx)));

    for (const step of steps) {
      const actions = world.actions(step, player_idx);

      for (const a of actions) {
        const { key, price, action } = a;
        const available = (price <= balance / 3);
        action();

        const income = _.sum(world.steps.map((s) => s.profit * s.ownerships.share(player_idx)));
        let income_diff = (income - income_base) / price;
        if (key === 'ownership') {
          income_diff *= 10;
        }

        world.steps = steps;
        results.push({ income_diff, step, available, action: a });
      }
    }

    world.dryrun = false;

    results.sort(({ income_diff: a }, { income_diff: b }) => b - a);

    const income_diff_max = _.max(results.map(({ income_diff }) => income_diff));
    const candidates = results.filter(({ available, income_diff, action }) => {
      const afford = action.price < income_base / 20 || action.price < balance / 10;
      return afford && available && income_diff > income_diff_max / 2;
    });

    let events = [];
    if (candidates.length > 0) {
      const { step: { id }, action: { label, price, action }, income_diff } = _.shuffle(candidates)[0];
      action();

      events = [
        { key: `auto-${player_idx}-${tick}`, tick, text: `${player_idx}-${id}-${label}, 비용=${price} diff=$${income_diff.toFixed(0)}` },
      ];
    }

    return {
      events,
    };
  }

  onTickRandomEvent() {
    const { rng, world } = this.state;
    let { steps, opts } = world;
    const tick = world.tick + 1;

    // events
    if (rng.next() > opts.EVENT_PROB) {
      return null;
    }

    const types = [
      { weight: 2, ty: 'production' },
      { weight: 3, ty: 'discount' },
      { weight: 1, ty: 'ownership' },
    ];

    const player_idx = rng.integer(1, world.players.length - 1);

    const probs = types.map((e) => e.weight);
    const ty = types[rng.weighted(probs)].ty;
    let text = '아무 일도 일어나지 않았습니다.';

    if (ty === 'production') {
      const candidates = steps.filter((s) => isSrc(s) && !s.closed);
      if (candidates.length > 0) {
        const probs = candidates.map((s) => Math.pow(2, -world.distToSink(s.id)));
        const target = candidates[rng.weighted(probs)];
        if (target.production_lv > 0) {
          const production_lv = Math.floor(target.production_lv / 2);
          text = `${target.id}가 습격당했습니다: 생산랑이 ${production_levels[target.production_lv].amount}에서 ${production_levels[production_lv].amount}으로 크게 감소합니다.`;
          target.production_lv = production_lv;
        } else {
          text = `${target.id}가 습격당했습니다: 문을 닫습니다`;
          target.closed = true;
        }
      }
    } else if (ty === 'discount') {
      const candidates = steps.filter((s) => s.discount > 0 && s.ownerships.share(player_idx) >= 0.5 && !s.closed);
      if (candidates.length > 0) {
        const target = rng.choice(candidates);
        const discount = 1 - (1 - target.discount) * 0.5;
        text = `${target.id}의 경계가 강화됩니다: discount가 ${target.discount.toFixed(2)}에서 ${discount.toFixed(2)}로 크게 증가합니다`;
        target.discount = discount;
      }
    } else if (ty === 'ownership') {
      const candidates = steps.filter((s) => s.ownerships[player_idx] > -1);
      if (candidates.length > 0) {
        const target = rng.choice(candidates);
        const ownerships = target.ownerships.add_weight(tick, player_idx, -0.5);

        const share_before = target.ownerships.share(player_idx);
        const share_after = ownerships.share(player_idx);
        text = `${target.id}에 분쟁이 발생했습니다: ownership이 ${(share_before * 100).toFixed(1)}% 에서 ${(share_after * 100).toFixed(1)}% 로 감소합니다`;
        target.ownerships = ownerships;
      }
    }

    return { key: `ev-${tick}`, tick, text };
  }

  onTick() {
    const { world } = this.state;
    let { events } = this.state;
    const tick = world.tick + 1;

    // events
    const event_tick = this.onTickRandomEvent();
    if (event_tick) {
      events = [
        event_tick,
        ...events.slice(0, 9),
      ];
    }

    world.onTick();

    // auto action
    for (let player_idx = 1; player_idx <= world.player_count; player_idx++) {
      const { events: events1 } = this.onTickAutoPlayer(world, tick, player_idx);
      events = [...events1, ...events];
      events = events.slice(0, 10);
    }

    if (tickToDate(tick).getDate() === 1) {
      for (const player of world.players.filter((p) => p !== null)) {
        player.action_count = Math.max(1, player.action_count - world.opts.ACTION_COUNT_DECAY_PER_MONTH);
      }
      world.calculate();

      events = [
        { key: `month-${tick}`, tick, text: '새로운 달이 시작됩니다, 행동 비용이 감소합니다.' },
        ...events.slice(0, 9),
      ];
    }

    this.setState({ world, events });
  }

  componentDidUpdate() {
    const { world, cur_player_idx } = this.state;
    const { chartPlayer, chartSummary } = this;

    const { histories } = world.players[cur_player_idx];
    const histories1 = histories.slice(Math.max(0, histories.length - 12));

    chartPlayer.data.labels = histories1.map((h) => h.key);
    chartPlayer.data.datasets = [
      {
        label: 'income',
        data: histories1.map((h) => h.income),
        borderWidth: 1,
        yAxisID: 'y',
      },
      {
        label: 'spending',
        data: histories1.map((h) => h.spending),
        borderWidth: 1,
        yAxisID: 'y',
      },
      {
        label: 'valuation',
        data: histories1.map((h) => h.valuation),
        borderWidth: 1,
        type: 'line',
        yAxisID: 'y1',
      }
    ];
    chartPlayer.update();

    chartSummary.data.labels = histories.map((h) => h.key);
    chartSummary.data.datasets = world.players.map((p, player_idx) => {
      if (p === null) {
        return null;
      }
      const { histories } = p;

      return {
        label: `player #${player_idx}`,
        data: histories.map((h) => h.income),
        backgroundColor: COLORS[player_idx],
        borderColor: COLORS[player_idx],
        borderWidth: 1,
        yAxisID: 'y',
      };
    }).filter((p) => p !== null);
    chartSummary.update();
  }

  renderTableOwnership() {
    const { world } = this.state;
    const { tick, steps, players, player_count } = world;

    const profit_total = _.sum(steps.map((s) => s.profit));

    const teams = ['none'];
    for (let i = 1; i <= player_count; i++) {
      teams.push(`player ${i}`);
    }

    return <table>
      <thead>
        <tr>
          <th></th>
          {teams.map((team, idx) => <th key={team}>{team}</th>)}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>balance</td>
          {players.map((player, player_idx) => {
            if (player_idx === 0) {
              return <td key={player_idx}></td>;
            }
            return <td key={player_idx}>${player.balance.toFixed(0)}</td>;
          })}
        </tr>

        <tr>
          <td>profit</td>
          {players.map((player, player_idx) => {
            if (player_idx === 0) {
              return <td key={player_idx}></td>;
            }
            return <td key={player_idx}>${player.profit.toFixed(0)}</td>;
          })}
        </tr>

        <tr>
          <td>share</td>
          {players.map((player, player_idx) => {
            if (player_idx === 0) {
              return <td key={player_idx}></td>;
            }

            let share = player.profit / profit_total;
            if (profit_total === 0) {
              share = 0;
            }

            return <td key={player_idx}>${(share * 100).toFixed(1)}%</td>;
          })}
        </tr>

        <tr>
          <td>actions</td>
          {players.map((player, player_idx) => {
            if (player_idx === 0) {
              return <td key={player_idx}></td>;
            }
            return <td key={player_idx}>{player.action_count}</td>;
          })}
        </tr>

        <tr>
          <td>mod</td>
          {players.map((player, player_idx) => {
            if (player_idx === 0) {
              return <td key={player_idx}></td>;
            }
            const eliminated = world.steps.find((s) => s.ownerships.share(player_idx) >= 0.5) === undefined;
            let btn = null;
            if (eliminated) {
              btn = <button onClick={() => {
                const base = world.steps.find((s) => s.start_player_idx === player_idx);
                const targets = world.steps.filter((s) => world.dist0(s.id, base.id) >= 0);
                for (const step of targets) {
                  const max = _.max(step.ownerships.weights);
                  step.ownerships = step.ownerships.set_weight(tick, player_idx, max + 5);
                }
                player.action_count = 1;
                world.calculate();
              }}>revive</button>;
            }
            return <td key={player_idx}>{btn}</td>;
          })}
        </tr>

        {
          steps.map((s) => {
            return <tr key={s.id}>
              <td>{s.id}</td>
              {teams.map((team, idx) => {
                const share = s.ownerships.share(idx);
                const w = s.ownerships.weights[idx].toFixed(1);
                let cls = '';
                if (share >= 0.5) {
                  cls = 'selected';
                }
                return <td className={cls} key={team}>{(share * 100).toFixed(1)}% ({w})</td>;
              })}
            </tr>;
          })
        }
      </tbody>
    </table>
  }

  render() {
    const {
      paused,
      events,
      world,
      cur_player_idx,
    } = this.state;
    const {
      tick,
      steps,
    } = world;

    const production = _.sum(steps.map((s) => {
      if (isSrc(s)) {
        return production_levels[s.production_lv].amount;
      }
      return 0;
    }));

    const profit_total = _.sum(steps.map((s) => s.profit));
    const { balance, profit } = world.players[cur_player_idx];

    const rows = steps.map((s) => {
      let cls = '';
      if (s.closed) {
        cls = 'over';
      }

      const actions = world.actions(s, cur_player_idx);
      const btns = actions.map(({ key, label, price, action }) =>
        <button key={key} disabled={balance < price}
          onClick={() => {
            action();
            this.setState({ steps });
          }}>{label}</button>
      );

      const btns2 = world.actions2(s).map(({ label, action }) => {
        return <button key={label}
          onClick={() => { action(); this.setState({ steps }); }}>
          {label}</button>;
      });

      let share = s.profit / profit_total * 100;
      if (profit_total === 0) {
        share = 0;
      }
      const dividend = s.profit * s.ownerships.share(cur_player_idx);

      return <tr key={s.id} className={cls}>
        <td>{s.id}</td>
        <td>{s.next}</td>
        <td>{s.discount.toFixed(2)}</td>
        <td>{s.amount.toFixed(1)}</td>
        <td>{s.loss.toFixed(0)}</td>
        <td>{s.margin.toFixed(2)}</td>
        <td>${s.price_buy.toFixed(2)}</td>
        <td>${s.price_sell.toFixed(2)}</td>
        <td>${s.revenue.toFixed(0)}</td>
        <td>${s.profit.toFixed(0)}</td>
        <td>${share.toFixed(1)}%</td>
        <td>{(s.ownerships.share(cur_player_idx) * 100).toFixed(1)}%</td>
        <td>${dividend.toFixed(0)}</td>
        <td>{(dividend / profit * 100).toFixed(1)}%</td>
        <td>
          {btns}
        </td>
        <td>
          {Object.keys(s.modifiers).map((key) =>
            <span>{key}={s.modifiers[key].toFixed(2)} </span>
          )}
          {btns2}
        </td>
      </tr>;
    });

    // const tick_amount = 0.05;
    return <div>
      <div className="box">
        <p>speed
          {SPEEDS.map((s) => {
            let cls = (s === this.state.simtps) ? 'selected' : null;
            return <button className={cls} key={s} onClick={() => this.setState({ simtps: s })}>x{s}</button>;
          })}
        </p>
        <div>
          knobs
          {Object.keys(opts).map((optkey) => {
            return <div>
              <input type="number"
                min={optkey.min}
                max={optkey.max}
                defaultValue={world.opts[optkey]}
                onChange={(ev) => {
                  world.opts[optkey] = +ev.target.value;
                }}
                key="optkey" />
              {optkey}
            </div>;
          })}
        </div>
      </div>
      <div className="box">
        date={tickToDateStr(tick)} tick={tick} paused={paused}<br />
        production={production}<br />
        market cap=${profit_total.toFixed(0)}

        {world.players.map((player, player_idx) => {
          if (player === null) {
            return null;
          }
          const cls = (cur_player_idx === player_idx) ? 'selected' : '';
          return <button key={player_idx} className={cls} onClick={() => {
            this.setState({ cur_player_idx: player_idx });
          }}>player #{player_idx}</button>;
        })}
      </div>

      <table>
        <thead>
          <tr>
            <th>id</th>
            <th>next</th>
            <th>discount</th>
            <th>amount</th>
            <th>loss</th>
            <th>margin</th>
            <th>price(buy)</th>
            <th>price(sell)</th>
            <th>revenue</th>
            <th>profit</th>
            <th>share</th>
            <th>ownership</th>
            <th>dividend</th>
            <th>contrib</th>
            <th>actions</th>
            <th>modifiers</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>

      <div>
        <div style={{ display: 'inline-block' }}>
          {this.renderTableOwnership()}
        </div>
        <GraphView world={world} />
      </div>

      <div className="box">
        <canvas ref={this.chartPlayerRef} />
        <canvas ref={this.chartSummaryRef} />
      </div>

      <div className="box">
        <h4>이벤트</h4>
        {events.map((e) => {
          const dt = tick - e.tick;
          return <p key={e.key}>
            {tickToDateStr(e.tick)} ({dt}일 전): {e.text}
          </p>;
        })}
      </div>

      <div className="box">
        <h3>도움말</h3>
        <h4>단축키</h4>
        <ul>
          <li><code>1</code>, <code>2</code>, <code>3</code>: 게임 속도를 조절합니다.</li>
          <li><code>space</code>: 게임을 일시 정지하고 다시 시작합니다.</li>
          <li><code>r</code>: 게임을 다시 시작합니다.</li>
          <li><code>a</code>: 봇이 게임을 진행합니다.</li>
        </ul>
        <h4>사업체</h4>
        <ul>
          <li>테이블의 한 열은 하나의 사업체를 표현합니다.</li>
          <li>각 사업체는 상위 사업체로부터 상품을 조달해서, <code>next</code> 행에 표기된 하위 사업체로 판매합니다. </li>
          <li>각 사업체는 이윤을 붙여 상품을 유통합니다. 이윤율은 <code>margin</code> 값에 따라 정해집니다: 높을수록 구매가보다 더 비싼 가격에 판매합니다.</li>
          <li>한 사업체에서 다른 사업체로 상품이 전달될 때, 상품이 손실됩니다. 손실율을 <code>discount</code> 값에 따라 정해집니다: 높을수록 유통 과정에서 더 많은 상품이 손실됩니다.</li>
          <li><code>src-</code> 이름을 가진 사업체는 상품을 생산합니다.</li>
          <li><code>sink</code> 이름을 가진 사업체는 상품을 소비합니다.</li>
          <li>각 사업체는 매 틱 마다 생산 혹은 유통을 통해 가치를 창출합니다. 사업체가 창출하는 가치의 양은 <code>profit</code> 열에서 확인할 수 있습니다</li>
          <li>각 사업체는 매 틱마다의 순이익을 배당합니다. 플레이어는 각 사업체를 소유할 수 있고, 플레이어의 지분율은 <code>ownership</code> 열에서 확인할 수 있습니다. 플레이어가 각 사업체로부터 배당을 통해 거두는 수익은 <code>dividend</code> 열에서 확인할 수 있습니다.</li>
        </ul>
        <h4>수익화: 플레이어는 다양한 방법을 통해 수익을 극대화할 수 있습니다.</h4>
        <ul>
          <li>시장 개척: 플레이어는 시장의 크기를 늘려 더 많은 수익을 거들 수 있습니다. 우리의 상품은 수요가 무궁무진하기 때문에, 더 많은 상품을 수요처에 공급하는 것이 중요합니다. 상품의 생산량을 늘리세요: <code>production</code> 업그레이드를 통해 상품의 생산량을 늘릴 수 있습니다. 상품의 손실을 최소화하세요: <code>discount</code> 업그레이드를 통해, 각 단계에서 유실되는 상품의 양을 최소화할 수 있습니다.</li>
          <li>시장 점유율 확대: 플레이어는 시장 점유율을 늘려, 수익을 올릴 수 있습니다. 플레이어는 각 사업체의 지분율을 올려 수익성 있는 사업체로부터 수익을 나눠받을 수 있습니다: <code>share</code> 업그레이드를 활용하세요. 혹은, 플레이어는 각 사업체의 이윤율을 높이거나 줄여, 플레이어가 소유한 사업체로 수익을 옮길 수 있습니다: <code>margin</code> 업그레이드를 활용하세요.</li>
        </ul>

      </div>
    </div>
  }
}


/*
  discount
  <button onClick={() => this.modifyDiscount(s.id, -tick_amount)}>-</button>
  <button onClick={() => this.modifyDiscount(s.id, tick_amount)}>+</button>
  margin
  <button onClick={() => this.modifyMargin(s.id, -tick_amount)}>-</button>
  <button onClick={() => this.modifyMargin(s.id, tick_amount)}>+</button>
  */

