// downloaded data!
export const data = [
  {
    "staff_ability_key": "search",
    "staff_ability_name": "loc_data_string_staffability_name_search",
    "staff_ability_desc": "loc_data_longtext_staffability_desc_search"
  },
  {
    "staff_ability_key": "negotiate",
    "staff_ability_name": "loc_data_string_staffability_name_negotiate",
    "staff_ability_desc": "loc_data_longtext_staffability_desc_negotiate"
  },
  {
    "staff_ability_key": "bargain",
    "staff_ability_name": "loc_data_string_staffability_name_bargain",
    "staff_ability_desc": "loc_data_longtext_staffability_desc_bargain"
  },
  {
    "staff_ability_key": "train",
    "staff_ability_name": "loc_data_string_staffability_name_train",
    "staff_ability_desc": "loc_data_longtext_staffability_desc_train"
  },
  {
    "staff_ability_key": "manage",
    "staff_ability_name": "loc_data_string_staffability_name_manage",
    "staff_ability_desc": "loc_data_longtext_staffability_desc_manage"
  }
];