import { data } from './data/google/downloaded/localization.mjs';

import i18next from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import resourcesToBackend from 'i18next-resources-to-backend';

export const localeKeys = Object.keys(data[0]).filter(key => key !== 'key' && !key.startsWith('_'));

export const bundledResources = {};
for (const locale of localeKeys) {
  bundledResources[locale] = {
    translation: data.reduce((acc, o) => {
      acc[o.key] = o[locale];
      return acc;
    } , {}),
  };
}

i18next
  .use(ChainedBackend)
  .init({
    fallbackLng: 'ko-KR',
    // ... your i18next config
    backend: {
      backends: [
        resourcesToBackend(bundledResources)
      ],
    interpolation: {
      skipOnVariables: false,
    }
    }
  });

export function L(lkey, ctx) {
  return i18next.t(lkey, ctx);
}

export function localeSet(locale, cb) {
  i18next.changeLanguage(locale);
}
