// downloaded data!
export const data = [
  {
    "idx": "1",
    "ty0": "base_main",
    "ty1": "base",
    "name": "loc_data_string_facilities_name_base1",
    "tier": "1",
    "key": "base1",
    "prerequisities": "",
    "cost": "",
    "maintenance": "0",
    "duration": "",
    "favor": "0",
    "token": "1",
    "descr": "loc_data_longtext_facilities_descr_base1",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "base_main_1"
  },
  {
    "idx": "2",
    "ty0": "base_main",
    "ty1": "base",
    "name": "loc_data_string_facilities_name_base2",
    "tier": "2",
    "key": "base2",
    "prerequisities": "base1",
    "cost": "5000",
    "maintenance": "250",
    "duration": "7",
    "favor": "0",
    "token": "2",
    "descr": "loc_data_longtext_facilities_descr_base2",
    "staff_heads": "1",
    "staffs": "2",
    "tasks": "search_staff\nrecruit_staff, [*recruit_staffs]",
    "staff_abilities": "search, negotiate",
    "facilityresult": "base_main_2"
  },
  {
    "idx": "3",
    "ty0": "base_main",
    "ty1": "base",
    "name": "loc_data_string_facilities_name_base3",
    "tier": "3",
    "key": "base3",
    "prerequisities": "base2",
    "cost": "10000",
    "maintenance": "500",
    "duration": "14",
    "favor": "0",
    "token": "3",
    "descr": "loc_data_longtext_facilities_descr_base3",
    "staff_heads": "1",
    "staffs": "3",
    "tasks": "search_staff\nrecruit_staff, [*recruit_staffs]",
    "staff_abilities": "search, negotiate",
    "facilityresult": "base_main_3"
  },
  {
    "idx": "4",
    "ty0": "base_main",
    "ty1": "base",
    "name": "loc_data_string_facilities_name_base4",
    "tier": "4",
    "key": "base4",
    "prerequisities": "base3",
    "cost": "15000",
    "maintenance": "750",
    "duration": "21",
    "favor": "0",
    "token": "4",
    "descr": "loc_data_longtext_facilities_descr_base4",
    "staff_heads": "1",
    "staffs": "4",
    "tasks": "search_staff\nrecruit_staff, [*recruit_staffs]",
    "staff_abilities": "search, negotiate",
    "facilityresult": "base_main_4"
  },
  {
    "idx": "5",
    "ty0": "base_sub",
    "ty1": "empty",
    "name": "loc_data_string_facilities_name_base_empty",
    "tier": "0",
    "key": "base_empty",
    "prerequisities": "",
    "cost": "",
    "maintenance": "0",
    "duration": "",
    "favor": "",
    "token": "",
    "descr": "loc_data_longtext_facilities_descr_base_empty"
  },
  {
    "idx": "18",
    "ty0": "base_sub",
    "ty1": "command",
    "name": "loc_data_string_facilities_name_command1",
    "tier": "1",
    "key": "command1",
    "prerequisities": "base_empty",
    "cost": "3000",
    "maintenance": "200",
    "duration": "7",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_command1",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "command_1"
  },
  {
    "idx": "19",
    "ty0": "base_sub",
    "ty1": "command",
    "name": "loc_data_string_facilities_name_command2",
    "tier": "2",
    "key": "command2",
    "prerequisities": "command1",
    "cost": "6000",
    "maintenance": "400",
    "duration": "14",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_command2",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "command_2"
  },
  {
    "idx": "20",
    "ty0": "base_sub",
    "ty1": "command",
    "name": "loc_data_string_facilities_name_command3",
    "tier": "3",
    "key": "command3",
    "prerequisities": "command2",
    "cost": "9000",
    "maintenance": "600",
    "duration": "21",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_command3",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "command_3"
  },
  {
    "idx": "21",
    "ty0": "base_sub",
    "ty1": "command",
    "name": "loc_data_string_facilities_name_command4",
    "tier": "4",
    "key": "command4",
    "prerequisities": "command3",
    "cost": "15000",
    "maintenance": "800",
    "duration": "28",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_command4",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "command_4"
  },
  {
    "idx": "6",
    "ty0": "base_sub",
    "ty1": "market",
    "name": "loc_data_string_facilities_name_market0_1",
    "tier": "1",
    "key": "market0_1",
    "prerequisities": "base_empty",
    "cost": "3000",
    "maintenance": "200",
    "duration": "7",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_market0_1",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "market_1"
  },
  {
    "idx": "7",
    "ty0": "base_sub",
    "ty1": "market",
    "name": "loc_data_string_facilities_name_market0_2",
    "tier": "2",
    "key": "market0_2",
    "prerequisities": "market0_1",
    "cost": "6000",
    "maintenance": "400",
    "duration": "14",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_market0_2",
    "staff_heads": "1",
    "staffs": "2",
    "tasks": "source_gun_tier, [1, 2]\nsource_bulletproof_tier, [1, 2]\nsource_granade_tier, [1, 2]\nbuy_item, [*market_items]\nsell_item, [*warehouse_items]",
    "staff_abilities": "search, bargain",
    "facilityresult": "market_2"
  },
  {
    "idx": "8",
    "ty0": "base_sub",
    "ty1": "market",
    "name": "loc_data_string_facilities_name_market0_3",
    "tier": "3",
    "key": "market0_3",
    "prerequisities": "market0_2",
    "cost": "9000",
    "maintenance": "600",
    "duration": "21",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_market0_3",
    "staff_heads": "1",
    "staffs": "3",
    "tasks": "source_gun_tier, [1, 2, 3]\nsource_bulletproof_tier, [1, 2, 3]\nsource_granade_tier, [1, 2, 3]\nbuy_item, [*market_items]\nsell_item, [*warehouse_items]\nsource_gun_ty_tier, [sg, smg, ar], [1, 2]",
    "staff_abilities": "search, bargain",
    "facilityresult": "market_3"
  },
  {
    "idx": "9",
    "ty0": "base_sub",
    "ty1": "market",
    "name": "loc_data_string_facilities_name_market0_4",
    "tier": "4",
    "key": "market0_4",
    "prerequisities": "market0_3",
    "cost": "15000",
    "maintenance": "800",
    "duration": "28",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_market0_4",
    "staff_heads": "1",
    "staffs": "4",
    "tasks": "source_gun_tier, [1, 2, 3, 4]\nsource_bulletproof_tier, [1, 2, 3, 4]\nsource_granade_tier, [1, 2, 3]\nbuy_item, [*market_items]\nsell_item, [*warehouse_items]\nsource_gun_ty_tier, [sg, smg, ar], [1, 2, 3]",
    "staff_abilities": "search, bargain",
    "facilityresult": "market_4"
  },
  {
    "idx": "10",
    "ty0": "base_sub",
    "ty1": "recruit",
    "name": "loc_data_string_facilities_name_recruit0_1",
    "tier": "1",
    "key": "recruit0_1",
    "prerequisities": "base_empty",
    "cost": "3000",
    "maintenance": "200",
    "duration": "7",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_recruit0_1",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "recruit_1"
  },
  {
    "idx": "11",
    "ty0": "base_sub",
    "ty1": "recruit",
    "name": "loc_data_string_facilities_name_recruit0_2",
    "tier": "2",
    "key": "recruit0_2",
    "prerequisities": "recruit0_1",
    "cost": "6000",
    "maintenance": "400",
    "duration": "14",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_recruit0_2",
    "staff_heads": "1",
    "staffs": "2",
    "tasks": "search_agent_tier, [1, 2]\nrecruit_agent, [*recruit_agents]",
    "staff_abilities": "search, negotiate",
    "facilityresult": "recruit_2"
  },
  {
    "idx": "12",
    "ty0": "base_sub",
    "ty1": "recruit",
    "name": "loc_data_string_facilities_name_recruit0_3",
    "tier": "3",
    "key": "recruit0_3",
    "prerequisities": "recruit0_2",
    "cost": "9000",
    "maintenance": "600",
    "duration": "21",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_recruit0_3",
    "staff_heads": "1",
    "staffs": "3",
    "tasks": "search_agent_tier, [1, 2, 3]\nrecruit_agent, [*recruit_agents]",
    "staff_abilities": "search, negotiate",
    "facilityresult": "recruit_3"
  },
  {
    "idx": "13",
    "ty0": "base_sub",
    "ty1": "recruit",
    "name": "loc_data_string_facilities_name_recruit0_4",
    "tier": "4",
    "key": "recruit0_4",
    "prerequisities": "recruit0_3",
    "cost": "15000",
    "maintenance": "800",
    "duration": "28",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_recruit0_4",
    "staff_heads": "1",
    "staffs": "4",
    "tasks": "search_agent_tier, [1, 2, 3, 4]\nrecruit_agent, [*recruit_agents]",
    "staff_abilities": "search, negotiate",
    "facilityresult": "recruit_4"
  },
  {
    "idx": "14",
    "ty0": "base_sub",
    "ty1": "training",
    "name": "loc_data_string_facilities_name_training_general1",
    "tier": "1",
    "key": "training_general1",
    "prerequisities": "base_empty",
    "cost": "3000",
    "maintenance": "200",
    "duration": "7",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_training_general1",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "train_1"
  },
  {
    "idx": "15",
    "ty0": "base_sub",
    "ty1": "training",
    "name": "loc_data_string_facilities_name_training_general2",
    "tier": "2",
    "key": "training_general2",
    "prerequisities": "training_general1",
    "cost": "6000",
    "maintenance": "400",
    "duration": "14",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_training_general2",
    "staff_heads": "1",
    "staffs": "1",
    "tasks": "maintain_training",
    "staff_abilities": "train",
    "facilityresult": "train_2"
  },
  {
    "idx": "16",
    "ty0": "base_sub",
    "ty1": "training",
    "name": "loc_data_string_facilities_name_training_general3",
    "tier": "3",
    "key": "training_general3",
    "prerequisities": "training_general2",
    "cost": "9000",
    "maintenance": "600",
    "duration": "21",
    "favor": "1",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_training_general3",
    "staff_heads": "1",
    "staffs": "2",
    "tasks": "maintain_training",
    "staff_abilities": "train",
    "facilityresult": "train_3"
  },
  {
    "idx": "17",
    "ty0": "base_sub",
    "ty1": "training",
    "name": "loc_data_string_facilities_name_training_general4",
    "tier": "4",
    "key": "training_general4",
    "prerequisities": "training_general3",
    "cost": "15000",
    "maintenance": "800",
    "duration": "28",
    "favor": "3",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_training_general4",
    "staff_heads": "1",
    "staffs": "3",
    "tasks": "maintain_training",
    "staff_abilities": "train",
    "facilityresult": "train_4"
  },
  {
    "idx": "22",
    "ty0": "base_sub",
    "ty1": "trauma",
    "name": "loc_data_string_facilities_name_trauma1",
    "tier": "1",
    "key": "trauma1",
    "prerequisities": "base_empty",
    "cost": "3000",
    "maintenance": "200",
    "duration": "7",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_trauma1",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "trauma_1"
  },
  {
    "idx": "23",
    "ty0": "base_sub",
    "ty1": "trauma",
    "name": "loc_data_string_facilities_name_trauma2",
    "tier": "2",
    "key": "trauma2",
    "prerequisities": "trauma1",
    "cost": "6000",
    "maintenance": "400",
    "duration": "14",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_trauma2",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "trauma_2"
  },
  {
    "idx": "24",
    "ty0": "base_sub",
    "ty1": "trauma",
    "name": "loc_data_string_facilities_name_trauma3",
    "tier": "3",
    "key": "trauma3",
    "prerequisities": "trauma2",
    "cost": "9000",
    "maintenance": "600",
    "duration": "21",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_trauma3",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "trauma_3"
  },
  {
    "idx": "25",
    "ty0": "base_sub",
    "ty1": "trauma",
    "name": "loc_data_string_facilities_name_trauma4",
    "tier": "4",
    "key": "trauma4",
    "prerequisities": "trauma3",
    "cost": "15000",
    "maintenance": "800",
    "duration": "28",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_trauma4",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "trauma_4"
  },
  {
    "idx": "26",
    "ty0": "base_sub",
    "ty1": "manage",
    "name": "loc_data_string_facilities_name_manage1",
    "tier": "1",
    "key": "manage1",
    "prerequisities": "base_empty",
    "cost": "3000",
    "maintenance": "200",
    "duration": "7",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_manage1",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "manage_1"
  },
  {
    "idx": "27",
    "ty0": "base_sub",
    "ty1": "manage",
    "name": "loc_data_string_facilities_name_manage2",
    "tier": "2",
    "key": "manage2",
    "prerequisities": "manage1",
    "cost": "6000",
    "maintenance": "400",
    "duration": "14",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_manage2",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "manage_2"
  },
  {
    "idx": "28",
    "ty0": "base_sub",
    "ty1": "manage",
    "name": "loc_data_string_facilities_name_manage3",
    "tier": "3",
    "key": "manage3",
    "prerequisities": "manage2",
    "cost": "9000",
    "maintenance": "600",
    "duration": "21",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_manage3",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "manage_3"
  },
  {
    "idx": "29",
    "ty0": "base_sub",
    "ty1": "manage",
    "name": "loc_data_string_facilities_name_manage4",
    "tier": "4",
    "key": "manage4",
    "prerequisities": "manage3",
    "cost": "15000",
    "maintenance": "800",
    "duration": "28",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_manage4",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "manage_4"
  },
  {
    "idx": "30",
    "ty0": "base_sub",
    "ty1": "mechanic",
    "name": "loc_data_string_facilities_name_mechanic0",
    "tier": "1",
    "key": "mechanic1",
    "prerequisities": "base_empty",
    "cost": "4500",
    "maintenance": "200",
    "duration": "7",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_mechanic0",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "mechanic_1"
  },
  {
    "idx": "31",
    "ty0": "base_sub",
    "ty1": "mechanic",
    "name": "loc_data_string_facilities_name_mechanic1",
    "tier": "2",
    "key": "mechanic2",
    "prerequisities": "mechanic1",
    "cost": "9000",
    "maintenance": "400",
    "duration": "14",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_mechanic1",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "mechanic_2"
  },
  {
    "idx": "32",
    "ty0": "base_sub",
    "ty1": "mechanic",
    "name": "loc_data_string_facilities_name_mechanic2",
    "tier": "3",
    "key": "mechanic3",
    "prerequisities": "mechanic2",
    "cost": "13500",
    "maintenance": "600",
    "duration": "21",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_mechanic2",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "mechanic_3"
  },
  {
    "idx": "33",
    "ty0": "base_sub",
    "ty1": "mechanic",
    "name": "loc_data_string_facilities_name_mechanic3",
    "tier": "4",
    "key": "mechanic4",
    "prerequisities": "mechanic3",
    "cost": "22500",
    "maintenance": "800",
    "duration": "28",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_mechanic3",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "mechanic_4"
  },
  {
    "idx": "34",
    "ty0": "base_sub",
    "ty1": "remedy",
    "name": "loc_data_string_facilities_name_remedy0",
    "tier": "1",
    "key": "remedy1",
    "prerequisities": "base_empty",
    "cost": "4500",
    "maintenance": "200",
    "duration": "7",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_remedy0",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "remedy_1"
  },
  {
    "idx": "35",
    "ty0": "base_sub",
    "ty1": "remedy",
    "name": "loc_data_string_facilities_name_remedy1",
    "tier": "2",
    "key": "remedy2",
    "prerequisities": "remedy1",
    "cost": "9000",
    "maintenance": "400",
    "duration": "14",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_remedy1",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "remedy_2"
  },
  {
    "idx": "36",
    "ty0": "base_sub",
    "ty1": "remedy",
    "name": "loc_data_string_facilities_name_remedy2",
    "tier": "3",
    "key": "remedy3",
    "prerequisities": "remedy2",
    "cost": "13500",
    "maintenance": "600",
    "duration": "21",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_remedy2",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "remedy_3"
  },
  {
    "idx": "37",
    "ty0": "base_sub",
    "ty1": "remedy",
    "name": "loc_data_string_facilities_name_remedy3",
    "tier": "4",
    "key": "remedy4",
    "prerequisities": "remedy3",
    "cost": "22500",
    "maintenance": "800",
    "duration": "28",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_remedy3",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "remedy_4"
  },
  {
    "idx": "38",
    "ty0": "base_sub",
    "ty1": "hideout",
    "name": "loc_data_string_facilities_name_hideout0",
    "tier": "1",
    "key": "hideout1",
    "prerequisities": "base_empty",
    "cost": "4500",
    "maintenance": "200",
    "duration": "7",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_hideout0",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "hideout_1"
  },
  {
    "idx": "39",
    "ty0": "base_sub",
    "ty1": "hideout",
    "name": "loc_data_string_facilities_name_hideout1",
    "tier": "2",
    "key": "hideout2",
    "prerequisities": "hideout1",
    "cost": "9000",
    "maintenance": "400",
    "duration": "14",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_hideout1",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "hideout_2"
  },
  {
    "idx": "40",
    "ty0": "base_sub",
    "ty1": "hideout",
    "name": "loc_data_string_facilities_name_hideout2",
    "tier": "3",
    "key": "hideout3",
    "prerequisities": "hideout2",
    "cost": "13500",
    "maintenance": "600",
    "duration": "21",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_hideout2",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "hideout_3"
  },
  {
    "idx": "41",
    "ty0": "base_sub",
    "ty1": "hideout",
    "name": "loc_data_string_facilities_name_hideout3",
    "tier": "4",
    "key": "hideout4",
    "prerequisities": "hideout3",
    "cost": "22500",
    "maintenance": "800",
    "duration": "28",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_hideout3",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "hideout_4"
  },
  {
    "idx": "42",
    "ty0": "base_hq",
    "ty1": "hq",
    "name": "loc_data_string_facilities_name_hq1",
    "tier": "1",
    "key": "hq1",
    "prerequisities": "",
    "cost": "",
    "maintenance": "0",
    "duration": "",
    "favor": "",
    "token": "",
    "descr": "loc_data_longtext_facilities_descr_hq1",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "hq_stat_bonus"
  },
  {
    "idx": "43",
    "ty0": "safehouse",
    "ty1": "safehouse_main",
    "name": "loc_data_string_facilities_name_safehouse1",
    "tier": "1",
    "key": "safehouse1",
    "prerequisities": "",
    "cost": "",
    "maintenance": "0",
    "duration": "",
    "favor": "",
    "token": "",
    "descr": "loc_data_longtext_facilities_descr_safehouse1",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "safehouse_1"
  },
  {
    "idx": "44",
    "ty0": "safehouse",
    "ty1": "safehouse_main",
    "name": "loc_data_string_facilities_name_safehouse2",
    "tier": "2",
    "key": "safehouse2",
    "prerequisities": "safehouse1",
    "cost": "1000",
    "maintenance": "250",
    "duration": "7",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_safehouse2",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "safehouse_2"
  },
  {
    "idx": "45",
    "ty0": "safehouse",
    "ty1": "safehouse_main",
    "name": "loc_data_string_facilities_name_safehouse3",
    "tier": "3",
    "key": "safehouse3",
    "prerequisities": "safehouse2",
    "cost": "2000",
    "maintenance": "500",
    "duration": "14",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_safehouse3",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "safehouse_3"
  },
  {
    "idx": "46",
    "ty0": "safehouse",
    "ty1": "safehouse_main",
    "name": "loc_data_string_facilities_name_safehouse4",
    "tier": "4",
    "key": "safehouse4",
    "prerequisities": "safehouse3",
    "cost": "4000",
    "maintenance": "750",
    "duration": "21",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_safehouse4",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "safehouse_4"
  },
  {
    "idx": "47",
    "ty0": "support",
    "ty1": "pharmacy",
    "name": "loc_data_string_facilities_name_pharmacy0",
    "tier": "0",
    "key": "pharmacy0",
    "prerequisities": "",
    "cost": "",
    "maintenance": "0",
    "duration": "",
    "favor": "",
    "token": "",
    "descr": "loc_data_longtext_facilities_descr_pharmacy0"
  },
  {
    "idx": "48",
    "ty0": "support",
    "ty1": "pharmacy",
    "name": "loc_data_string_facilities_name_pharmacy1",
    "tier": "1",
    "key": "pharmacy1",
    "prerequisities": "pharmacy0",
    "cost": "2000",
    "maintenance": "100",
    "duration": "0",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_pharmacy1",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "pharmacy"
  },
  {
    "idx": "49",
    "ty0": "support",
    "ty1": "bar",
    "name": "loc_data_string_facilities_name_bar0",
    "tier": "0",
    "key": "bar0",
    "prerequisities": "",
    "cost": "",
    "maintenance": "0",
    "duration": "",
    "favor": "",
    "token": "",
    "descr": "loc_data_longtext_facilities_descr_bar0"
  },
  {
    "idx": "50",
    "ty0": "support",
    "ty1": "bar",
    "name": "loc_data_string_facilities_name_bar1",
    "tier": "1",
    "key": "bar1",
    "prerequisities": "bar0",
    "cost": "2000",
    "maintenance": "100",
    "duration": "0",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_bar1",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "bar"
  },
  {
    "idx": "53",
    "ty0": "support",
    "ty1": "architect",
    "name": "loc_data_string_facilities_name_accountant0",
    "tier": "0",
    "key": "accountant0",
    "prerequisities": "",
    "cost": "",
    "maintenance": "0",
    "duration": "",
    "favor": "",
    "token": "",
    "descr": "loc_data_longtext_facilities_descr_accountant0"
  },
  {
    "idx": "54",
    "ty0": "support",
    "ty1": "architect",
    "name": "loc_data_string_facilities_name_accountant1",
    "tier": "1",
    "key": "accountant1",
    "prerequisities": "accountant0",
    "cost": "2000",
    "maintenance": "100",
    "duration": "0",
    "favor": "0",
    "token": "0",
    "descr": "loc_data_longtext_facilities_descr_accountant1",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "architect"
  },
  {
    "idx": "55",
    "ty0": "local",
    "ty1": "pier",
    "name": "loc_data_string_facilities_name_pier",
    "tier": "2",
    "key": "pier",
    "prerequisities": "",
    "cost": "",
    "maintenance": "0",
    "duration": "",
    "favor": "",
    "token": "",
    "descr": "loc_data_longtext_facilities_descr_pier",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "pier"
  },
  {
    "idx": "56",
    "ty0": "local",
    "ty1": "gym",
    "name": "loc_data_string_facilities_name_gym",
    "tier": "1",
    "key": "gym",
    "prerequisities": "",
    "cost": "",
    "maintenance": "0",
    "duration": "",
    "favor": "",
    "token": "",
    "descr": "loc_data_longtext_facilities_descr_gym",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "gym"
  },
  {
    "idx": "57",
    "ty0": "local",
    "ty1": "college",
    "name": "loc_data_string_facilities_name_college",
    "tier": "3",
    "key": "college",
    "prerequisities": "",
    "cost": "",
    "maintenance": "0",
    "duration": "",
    "favor": "",
    "token": "",
    "descr": "loc_data_longtext_facilities_descr_college",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "college"
  },
  {
    "idx": "58",
    "ty0": "local",
    "ty1": "redevelop",
    "name": "loc_data_string_facilities_name_redevelop",
    "tier": "1",
    "key": "redevelop",
    "prerequisities": "",
    "cost": "",
    "maintenance": "0",
    "duration": "",
    "favor": "",
    "token": "",
    "descr": "loc_data_longtext_facilities_descr_redevelop",
    "staff_heads": "",
    "staffs": "",
    "tasks": "",
    "staff_abilities": "",
    "facilityresult": "redevelop"
  }
];