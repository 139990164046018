// downloaded data!
export const data = [
  {
    "num": "1",
    "choice": "1_1",
    "ty": "global",
    "cost": "",
    "amount": "",
    "length": "4",
    "modifier": "mod_global_1_boom",
    "action": "업계에 돈이 많이 풀렸으면 좋겠다.",
    "title": "4개월간 [호경기] 획득"
  },
  {
    "num": "1",
    "choice": "1_2",
    "ty": "global",
    "cost": "",
    "amount": "",
    "length": "4",
    "modifier": "mod_global_2_recession",
    "action": "이 업계는 시련이 필요하다.",
    "title": "4개월간 [불경기] 획득"
  },
  {
    "num": "1",
    "choice": "1_3",
    "ty": "resource",
    "cost": "",
    "amount": "500",
    "length": "",
    "modifier": "",
    "action": "현금은 항상 필요하다.",
    "title": "$500 획득"
  },
  {
    "num": "2",
    "choice": "2_1",
    "ty": "global",
    "cost": "",
    "amount": "",
    "length": "4",
    "modifier": "mod_global_3_promising",
    "action": "흘러가는 대로 두자.",
    "title": "4개월간 [유망한 업계] 획득"
  },
  {
    "num": "2",
    "choice": "2_2",
    "ty": "new_agent",
    "cost": "500",
    "amount": "",
    "length": "",
    "modifier": "mod_agent_8_growth_power",
    "action": "유망한 친구 하나 정도 건질 수 있지 않을까?",
    "title": "$500을 지불하고 8개월간 [성장기]를 지닌 용병 1명과 계약(6개월 계약)"
  },
  {
    "num": "2",
    "choice": "2_3",
    "ty": "new_agent",
    "cost": "500",
    "amount": "",
    "length": "",
    "modifier": "mod_agent_19_agenda_complicated",
    "action": "말뚝박을 친구 하나 정도 건질 수 있지 않을까? 집에 빚이 많다든지.",
    "title": "$500을 지불하고 [복잡한 사정] 아젠다를 지닌 용병 1명과 계약(6개월 계약)"
  },
  {
    "num": "3",
    "choice": "3_1",
    "ty": "global",
    "cost": "",
    "amount": "",
    "length": "4",
    "modifier": "mod_global_3_promising",
    "action": "입사 삼 년만에 부모님께 목장을 하나 사드렸다는 얘기를 하자.",
    "title": "4개월간 [유망한 업계] 획득"
  },
  {
    "num": "3",
    "choice": "3_2",
    "ty": "global",
    "cost": "",
    "amount": "",
    "length": "4",
    "modifier": "mod_global_4_youtuber",
    "action": "솔직히 낭만은 여기보단 아프리카지. 여긴 돈 때문에 하는 거야.",
    "title": "4개월간 [바다 건너의 기회] 획득"
  },
  {
    "num": "3",
    "choice": "3_3",
    "ty": "resource",
    "cost": "",
    "amount": "100",
    "length": "",
    "modifier": "",
    "action": "제가 1964년 사이공에 있었을 때 일입니다. 크리스라는 친구가 있었는데...",
    "title": "$100 획득"
  },
  {
    "num": "4",
    "choice": "4_1",
    "ty": "next_mission",
    "cost": "",
    "amount": "",
    "length": "",
    "modifier": "mod_mission_1_firearm",
    "action": "화력보충할 시간이다! 무기고 위치 찍어줘.",
    "title": "해당 지역에 [무기고] 임무가 하나 생성"
  },
  {
    "num": "4",
    "choice": "4_2",
    "ty": "next_mission",
    "cost": "",
    "amount": "",
    "length": "",
    "modifier": "mod_mission_2_equipment",
    "action": "방탄장비 어디에 쌓아놓는지 알아내자.",
    "title": "해당 지역에 [방탄장비 보관소] 임무가 하나 생성"
  },
  {
    "num": "4",
    "choice": "4_3",
    "ty": "next_mission",
    "cost": "",
    "amount": "",
    "length": "",
    "modifier": "mod_mission_3_throwable",
    "action": "투척물은 어디에 있지?",
    "title": "해당 지역에 [투척물 창고] 임무가 하나 생성"
  },
  {
    "num": "5",
    "choice": "5_1",
    "ty": "next_mission",
    "cost": "",
    "amount": "",
    "length": "",
    "modifier": "mod_mission_4_hyena",
    "action": "안전하게 가는 게 좋지. 가장 쉬운 임무로 부탁해.",
    "title": "해당 지역에 [후속 타격 의뢰] 임무가 하나 생성"
  },
  {
    "num": "5",
    "choice": "5_2",
    "ty": "next_mission",
    "cost": "",
    "amount": "",
    "length": "",
    "modifier": "mod_mission_5_elite",
    "action": "재능 있는 신참의 소문을 들었는데, 지금 어딨지?",
    "title": "해당 지역에 [정예 의뢰] 임무가 하나 생성"
  },
  {
    "num": "6",
    "choice": "6_1",
    "ty": "global",
    "cost": "",
    "amount": "",
    "length": "4",
    "modifier": "mod_global_8_contract_faithful",
    "action": "평균근속연수를 좀 높이고 싶다.",
    "title": "4개월간 [고용안정성 증가] 획득"
  },
  {
    "num": "6",
    "choice": "6_2",
    "ty": "global",
    "cost": "",
    "amount": "",
    "length": "4",
    "modifier": "mod_global_9_contract_decieve",
    "action": "어리버리한 놈들에게 세상의 쓴맛을 보여주자.",
    "title": "4개월간 [부당 계약] 획득"
  },
  {
    "num": "6",
    "choice": "6_3",
    "ty": "agents",
    "cost": "",
    "amount": "3",
    "length": "4",
    "modifier": "mod_agent_14_agenda_weak, mod_agent_7_morale_low",
    "action": "당분간 일 제대로 못 할 것 같은데, 재계약 때 좀 깎아야겠어.",
    "title": "계약중인 용병 중 3명이 각각 4개월간 [낮은 모럴]과 [협상 약점] 아젠다를 획득"
  },
  {
    "num": "7",
    "choice": "7_1",
    "ty": "global",
    "cost": "",
    "amount": "",
    "length": "4",
    "modifier": "mod_global_5_excess_firearm",
    "action": "무기가 중요하다고 하면 무기만 잔뜩 얻을 것 같고…",
    "title": "4개월간 [무기 보상 강화] 획득"
  },
  {
    "num": "7",
    "choice": "7_2",
    "ty": "global",
    "cost": "",
    "amount": "",
    "length": "4",
    "modifier": "mod_global_6_excess_equipment",
    "action": "방탄장비가 중요하다고 하면 방탄장비 말고는 구경도 못 하겠네.",
    "title": "4개월간 [장비 보상 강화] 획득"
  },
  {
    "num": "7",
    "choice": "7_3",
    "ty": "global",
    "cost": "",
    "amount": "",
    "length": "4",
    "modifier": "mod_global_7_excess_throwable",
    "action": "그냥 수류탄이 최고라고 말해버릴까….",
    "title": "4개월간 [투척물 보상 강화] 획득"
  },
  {
    "num": "8",
    "choice": "8_1",
    "ty": "next_mission",
    "cost": "",
    "amount": "",
    "length": "",
    "modifier": "mod_mission_6_supply",
    "action": "소문이 쫙 퍼졌다. 다들 우리의 습격에 대비하고 있을 거다.",
    "title": "해당 지역에 [보급 어려움] 임무가 하나 생성"
  },
  {
    "num": "8",
    "choice": "8_2",
    "ty": "next_mission",
    "cost": "",
    "amount": "",
    "length": "",
    "modifier": "mod_mission_7_resent",
    "action": "놈들이 우리를 무서워 해야지. 쫄릴 거 없다.",
    "title": "해당 지역에 [원한 쌓임] 임무가 하나 생성"
  },
  {
    "num": "9",
    "choice": "9_1",
    "ty": "global",
    "cost": "",
    "amount": "",
    "length": "4",
    "modifier": "mod_global_10_high_manpower",
    "action": "장교진에 인맥 있는 사람?",
    "title": "4개월간 [고급 인력] 획득"
  },
  {
    "num": "9",
    "choice": "9_2",
    "ty": "global",
    "cost": "",
    "amount": "",
    "length": "4",
    "modifier": "mod_global_11_platform_labor",
    "action": "병사 출신은 짧은 사이클로 굴리기 좋지.",
    "title": "4개월간 [사병 출신 인력] 획득"
  },
  {
    "num": "10",
    "choice": "10_1",
    "ty": "next_mission",
    "cost": "",
    "amount": "",
    "length": "",
    "modifier": "mod_mission_8_weaken",
    "action": "양동을 요청한다.",
    "title": "해당 지역에 [분산된 적] 임무가 하나 생성"
  },
  {
    "num": "10",
    "choice": "10_2",
    "ty": "next_mission",
    "cost": "",
    "amount": "",
    "length": "",
    "modifier": "mod_mission_9_militia",
    "action": "동행을 요청한다.",
    "title": "해당 지역에 [현지 증원] 임무가 하나 생성"
  },
  {
    "num": "11",
    "choice": "11_1",
    "ty": "mission_agents",
    "cost": "",
    "amount": "1",
    "length": "",
    "modifier": "mod_agent_13_agenda_strong",
    "action": "잘 한 건 잘 했다고 해 줘야겠지.",
    "title": "임무에 참여한 용병 중 1명이 [협상 강점] 아젠다 획득"
  },
  {
    "num": "11",
    "choice": "11_2",
    "ty": "mission_agents",
    "cost": "",
    "amount": "2",
    "length": "4",
    "modifier": "mod_agent_7_morale_low",
    "action": "분명 또 재계약때 뭐라고 할텐데.",
    "title": "임무에 참여한 용병 중 2명이 4개월간 [낮은 모럴] 획득"
  },
  {
    "num": "12",
    "choice": "12_1",
    "ty": "global",
    "cost": "",
    "amount": "",
    "length": "2",
    "modifier": "mod_global_5_excess_firearm",
    "action": "무기를 잔뜩 구할 기회로군.",
    "title": "2개월간 [무기 보상 강화] 획득"
  },
  {
    "num": "12",
    "choice": "12_2",
    "ty": "next_mission",
    "cost": "",
    "amount": "",
    "length": "1",
    "modifier": "mod_mission_1_firearm",
    "action": "무기를 잔뜩 쥔 호구들을 털 기회로군.",
    "title": "해당 지역에 [무기고] 임무가 하나 생성"
  },
  {
    "num": "13",
    "choice": "13_1",
    "ty": "global",
    "cost": "",
    "amount": "",
    "length": "2",
    "modifier": "mod_global_6_excess_equipment",
    "action": "방탄복을 베고 자게 생겼네.",
    "title": "2개월간 [장비 보상 강화] 획득"
  },
  {
    "num": "13",
    "choice": "13_2",
    "ty": "next_mission",
    "cost": "",
    "amount": "",
    "length": "",
    "modifier": "mod_mission_2_equipment",
    "action": "적들이 방탄복을 많이 입고 있겠군…",
    "title": "[방탄장비 보관소] 임무가 하나 생성"
  },
  {
    "num": "14",
    "choice": "14_1",
    "ty": "global",
    "cost": "",
    "amount": "",
    "length": "2",
    "modifier": "mod_global_7_excess_throwable",
    "action": "현장에 투척물 있으면 다 들고 와.",
    "title": "2개월간 [투척물 보상 강화] 획득"
  },
  {
    "num": "14",
    "choice": "14_2",
    "ty": "next_mission",
    "cost": "",
    "amount": "",
    "length": "",
    "modifier": "mod_mission_3_throwable",
    "action": "어딘가 가득 쌓여 있을 거야. 위치를 알아보자.",
    "title": "[투척물 창고] 임무가 하나 생성"
  },
  {
    "num": "15",
    "choice": "15_1",
    "ty": "mission_agents",
    "cost": "",
    "amount": "1",
    "length": "",
    "modifier": "mod_agent_19_agenda_complicated",
    "action": "다음 계약은 좀 유리하게 할 수 있겠군.",
    "title": "임무에 참여한 용병 중 1명이 [복잡한 사정] 아젠다 획득"
  },
  {
    "num": "15",
    "choice": "15_2",
    "ty": "mission_agents",
    "cost": "",
    "amount": "2",
    "length": "4",
    "modifier": "mod_agent_6_morale_high",
    "action": "거 실수좀 할 수 있지! 이걸로 술이나 사멕여.",
    "title": "임무에 참여한 용병 중 2명이 4개월간 [사기충천] 획득"
  },
  {
    "num": "16",
    "choice": "16_1",
    "ty": "resource",
    "cost": "",
    "amount": "500",
    "length": "",
    "modifier": "",
    "action": "놓고 간 사람 잘못이지.",
    "title": "$500 획득"
  },
  {
    "num": "17",
    "choice": "17_1",
    "ty": "agents",
    "cost": "",
    "amount": "3",
    "length": "",
    "modifier": "mod_agent_17_agenda_safety",
    "action": "시정하겠습니다.",
    "title": "계약중인 용병 중 3명이 [안전 욕구] 아젠다를 획득"
  },
  {
    "num": "17",
    "choice": "17_2",
    "ty": "resource",
    "cost": "500",
    "amount": "",
    "length": "",
    "modifier": "",
    "action": "아이고 노고가 많으십니다. 일단 앉아서 얘기하시지요.",
    "title": "$500 지불"
  },
  {
    "num": "18",
    "choice": "18_1",
    "ty": "remove_global",
    "cost": "",
    "amount": "1",
    "length": "",
    "modifier": "",
    "action": "설마 이게 끝날 줄이야….",
    "title": "[] 삭제"
  },
  {
    "num": "18",
    "choice": "18_2",
    "ty": "remove_global",
    "cost": "",
    "amount": "1",
    "length": "",
    "modifier": "",
    "action": "이건 끝날 것 같기는 했다.",
    "title": "[] 삭제"
  },
  {
    "num": "18",
    "choice": "18_3",
    "ty": "remove_global",
    "cost": "",
    "amount": "1",
    "length": "",
    "modifier": "",
    "action": "이것도 끝나나 보다.",
    "title": "[] 삭제"
  },
  {
    "num": "19",
    "choice": "19_1",
    "ty": "mission_agents",
    "cost": "",
    "amount": "2",
    "length": "4",
    "modifier": "mod_agent_5_disable_prompt",
    "action": "하고 싶은 대로 하게 해 줘.",
    "title": "임무에 참여한 용병 중 2명이 4개월간 [현장 판단 우선] 획득"
  },
  {
    "num": "19",
    "choice": "19_2",
    "ty": "mission_agents",
    "cost": "",
    "amount": "2",
    "length": "4",
    "modifier": "mod_agent_7_morale_low",
    "action": "명령 듣게 만들어.",
    "title": "임무에 참여한 용병 중 2명이 4개월간 [낮은 모럴] 획득"
  },
  {
    "num": "20",
    "choice": "20_1",
    "ty": "mission_agents",
    "cost": "",
    "amount": "1",
    "length": "4",
    "modifier": "mod_agent_8_growth_power",
    "action": "운동 시켜볼까? 요즘 뉴욕에서 인기 있다는 그, 필라테스라는 거 말이야.",
    "title": "임무에 참여한 용병 중 1명이 4개월간 [성장기] 획득"
  },
  {
    "num": "20",
    "choice": "20_2",
    "ty": "mission_agents",
    "cost": "",
    "amount": "1",
    "length": "4",
    "modifier": "mod_agent_9_growth_perk",
    "action": "전술훈련 부트캠프 보내볼까?",
    "title": "임무에 참여한 용병 중 1명이 4개월간 [사춘기] 획득"
  },
  {
    "num": "20",
    "choice": "20_3",
    "ty": "mission_agents",
    "cost": "",
    "amount": "1",
    "length": "4",
    "modifier": "mod_agent_6_morale_high",
    "action": "임무에 전념했으면 좋겠네.",
    "title": "임무에 참여한 용병 중 1명이 4개월간 [사기충천] 획득"
  },
  {
    "num": "21",
    "choice": "21_1",
    "ty": "next_milestone_mission",
    "cost": "",
    "amount": "",
    "length": "",
    "modifier": "mod_mission_5_elite",
    "action": "무시무시한 놈이 있다던데.",
    "title": "다음 마일스톤 미션이 [정예 의뢰] 획득"
  },
  {
    "num": "21",
    "choice": "21_2",
    "ty": "next_milestone_mission",
    "cost": "",
    "amount": "",
    "length": "",
    "modifier": "mod_mission_7_resent",
    "action": "숫자가 좀 많다던데.",
    "title": "다음 마일스톤 미션이 [원한 쌓임] 획득"
  },
  {
    "num": "22",
    "choice": "22_1",
    "ty": "new_agent",
    "cost": "250",
    "amount": "",
    "length": "8",
    "modifier": "mod_agent_8_growth_power",
    "action": "유망한 친구로!",
    "title": "$500을 지불하고 8개월간 [성장기]를 지닌 용병 1명과 계약, (6개월 계약)"
  },
  {
    "num": "22",
    "choice": "22_2",
    "ty": "new_agent",
    "cost": "250",
    "amount": "",
    "length": "4",
    "modifier": "mod_agent_6_morale_high",
    "action": "즉시 전력인 친구로!",
    "title": "$500을 지불하고 4개월간 [사기충천]을 지닌 용병 1명과 계약, (6개월 계약)"
  },
  {
    "num": "22",
    "choice": "22_3",
    "ty": "resource",
    "cost": "",
    "amount": "500",
    "length": "",
    "modifier": "",
    "action": "굳이? 다른 곳에 알선해 주자.",
    "title": "$500 획득"
  },
  {
    "num": "23",
    "choice": "23_1",
    "ty": "heal",
    "cost": "1000",
    "amount": "",
    "length": "",
    "modifier": "",
    "action": "멀쩡한 치료를 요청한다",
    "title": "$1000을 지불하고 전원의 체력을 모두 회복"
  },
  {
    "num": "23",
    "choice": "23_2",
    "ty": "agents",
    "cost": "500",
    "amount": "1",
    "length": "4",
    "modifier": "mod_agent_10_drug",
    "action": "수단과 방법을 가리지 않고 강해지는 시술을 요청한다",
    "title": "$500을 지불하고 계약중인 용병 중 1명이 4개월간 [약물 투여]를 획득"
  },
  {
    "num": "23",
    "choice": "23_3",
    "ty": "resource",
    "cost": "",
    "amount": "500",
    "length": "",
    "modifier": "",
    "action": "경찰서에 넘긴다",
    "title": "$500 획득"
  },
  {
    "num": "24",
    "choice": "24_1",
    "ty": "resource",
    "cost": "",
    "amount": "-300",
    "length": "",
    "modifier": "",
    "action": "어쩔 수 없지.",
    "title": "$300 상실(상실의 결과 잔고가 $0 아래로 떨어지지는 않음)"
  },
  {
    "num": "25",
    "choice": "25_1",
    "ty": "agents",
    "cost": "",
    "amount": "",
    "length": "3",
    "modifier": "mod_agent_17_agenda_safety",
    "action": "부상을 입은 용병들이 더 잘 회복할 수 있도록….",
    "title": "계약중인 용병 중 3명이 [안전 욕구] 아젠다를 획득"
  },
  {
    "num": "25",
    "choice": "25_2",
    "ty": "agents",
    "cost": "",
    "amount": "",
    "length": "3",
    "modifier": "mod_agent_18_agenda_wellbeing",
    "action": "위험한 임무에 향하는 용병들이 보답받도록….",
    "title": "계약중인 용병 중 3명이 [웰빙 욕구] 아젠다를 획득"
  },
  {
    "num": "25",
    "choice": "25_3",
    "ty": "resource",
    "cost": "1000",
    "amount": "",
    "length": "",
    "modifier": "",
    "action": "항상 노고가 많습니다. 일단 앉아서 얘기하지요.",
    "title": "$1000 지불"
  },
  {
    "num": "26",
    "choice": "26_1",
    "ty": "remove_agent",
    "cost": "",
    "amount": "1",
    "length": "",
    "modifier": "",
    "action": "이 친구도 고생 했지.",
    "title": "[] 용병의 [] 모디파이어 혹은 아젠다 삭제"
  },
  {
    "num": "26",
    "choice": "26_2",
    "ty": "remove_agent",
    "cost": "",
    "amount": "1",
    "length": "",
    "modifier": "",
    "action": "사람 일 모르는 거네.",
    "title": "[] 용병의 [] 모디파이어 혹은 아젠다 삭제"
  },
  {
    "num": "26",
    "choice": "26_3",
    "ty": "remove_agent",
    "cost": "",
    "amount": "1",
    "length": "",
    "modifier": "",
    "action": "나중에 술이나 같이 할까.",
    "title": "[] 용병의 [] 모디파이어 혹은 아젠다 삭제"
  },
  {
    "num": "27",
    "choice": "27_1",
    "ty": "global",
    "cost": "",
    "amount": "",
    "length": "1",
    "modifier": "mod_global_12_shop_firearm",
    "action": "총기 상인과 거래를 트자.",
    "title": "1개월간 [무기 상점 강화] 획득"
  },
  {
    "num": "27",
    "choice": "27_2",
    "ty": "global",
    "cost": "",
    "amount": "",
    "length": "1",
    "modifier": "mod_global_13_shop_equipment",
    "action": "방탄장비 업체와 거래를 트자.",
    "title": "1개월간 [장비 상점 강화] 획득"
  },
  {
    "num": "27",
    "choice": "27_3",
    "ty": "global",
    "cost": "",
    "amount": "",
    "length": "1",
    "modifier": "mod_global_14_shop_throwable",
    "action": "투척물 다루는 친구와 거래를 트자.",
    "title": "1개월간 [투척물 상점 강화] 획득"
  },
  {
    "num": "28",
    "choice": "28_1",
    "ty": "resource",
    "cost": "",
    "amount": "",
    "length": "0",
    "modifier": "",
    "action": "내일도 오늘의 평온이 이어지길.",
    "title": "아무 일도 일어나지 않음"
  },
  {
    "num": "29",
    "choice": "29_1",
    "ty": "resource_per_agent",
    "cost": "",
    "amount": "200",
    "length": "",
    "modifier": "",
    "action": "울상 짓지 말고 내놔.",
    "title": "고용중인 용병 1명당 $200 획득"
  },
  {
    "num": "30",
    "choice": "30_1",
    "ty": "resource_per_agent",
    "cost": "",
    "amount": "-200",
    "length": "",
    "modifier": "",
    "action": "이만한 회사 없다.",
    "title": "고용중인 용병 1명당 $200 상실(상실의 결과 잔고가 $0 아래로 떨어지지는 않음)"
  },
  {
    "num": "31",
    "choice": "31_1",
    "ty": "global",
    "cost": "",
    "amount": "",
    "length": "12",
    "modifier": "mod_global_1_boom",
    "action": "이게 어떤 영향을 미칠지.",
    "title": "12개월간 [호경기] 획득"
  },
  {
    "num": "32",
    "choice": "32_1",
    "ty": "global",
    "cost": "",
    "amount": "",
    "length": "12",
    "modifier": "mod_global_2_recession",
    "action": "이게 어떤 영향을 미칠지.",
    "title": "12개월간 [불경기] 획득"
  },
  {
    "num": "33",
    "choice": "33_1",
    "ty": "global",
    "cost": "",
    "amount": "",
    "length": "8",
    "modifier": "mod_global_3_promising",
    "action": "여기가 콜롬비아의 등용문이다. 우린 집안도, 학력도, 인성도 안 봐.",
    "title": "8개월간 [유망한 업계] 획득"
  },
  {
    "num": "34",
    "choice": "34_1",
    "ty": "global",
    "cost": "",
    "amount": "",
    "length": "8",
    "modifier": "mod_global_4_youtuber",
    "action": "우리도 아프리카로 넘어갈까. 에이, 거긴 말도 안 통해.",
    "title": "8개월간 [바다 건너의 기회] 획득"
  },
  {
    "num": "35",
    "choice": "35_1",
    "ty": "global",
    "cost": "",
    "amount": "",
    "length": "12",
    "modifier": "mod_global_8_contract_faithful",
    "action": "이게 어떤 영향을 미칠지.",
    "title": "12개월간 [신뢰의 계약] 획득"
  },
  {
    "num": "36",
    "choice": "36_1",
    "ty": "global",
    "cost": "",
    "amount": "",
    "length": "12",
    "modifier": "mod_global_9_contract_decieve",
    "action": "이게 어떤 영향을 미칠지.",
    "title": "12개월간 [부당 계약] 획득"
  },
  {
    "num": "37",
    "choice": "37_1",
    "ty": "global",
    "cost": "",
    "amount": "",
    "length": "4",
    "modifier": "mod_global_10_high_manpower",
    "action": "장교진에 인맥 있는 사람?",
    "title": "4개월간 [고급 인력] 획득"
  },
  {
    "num": "38",
    "choice": "38_1",
    "ty": "global",
    "cost": "",
    "amount": "",
    "length": "4",
    "modifier": "mod_global_11_platform_labor",
    "action": "병사 출신은 짧은 사이클로 굴리기 좋지.",
    "title": "4개월간 [사병 출신 인력] 획득"
  },
  {
    "num": "39",
    "choice": "39_1",
    "ty": "global",
    "cost": "",
    "amount": "",
    "length": "1",
    "modifier": "mod_global_12_shop_firearm",
    "action": "곧 고품질 무기가 시장에 풀리겠군.",
    "title": "1개월간 [무기 상점 강화] 획득"
  },
  {
    "num": "40",
    "choice": "40_1",
    "ty": "global",
    "cost": "",
    "amount": "",
    "length": "1",
    "modifier": "mod_global_13_shop_equipment",
    "action": "곧 고품질 방탄복이 많이 남겠군.",
    "title": "1개월간 [장비 상점 강화] 획득"
  },
  {
    "num": "41",
    "choice": "41_1",
    "ty": "global",
    "cost": "",
    "amount": "",
    "length": "1",
    "modifier": "mod_global_14_shop_throwable",
    "action": "무기상도 투척물을 더 많이 들여놓기 시작했다.",
    "title": "1개월간 [투척물 상점 강화] 획득"
  },
  {
    "num": "42",
    "choice": "42_1",
    "ty": "renown",
    "cost": "",
    "amount": "",
    "length": "",
    "modifier": "",
    "action": "이 정도는 시작일 뿐이다.",
    "title": "명성 수치 최대치 500으로 상승"
  },
  {
    "num": "43",
    "choice": "43_1",
    "ty": "renown",
    "cost": "",
    "amount": "",
    "length": "",
    "modifier": "",
    "action": "다음 기회에는 꼭 참가하도록 하지.",
    "title": "명성 수치 최대치 2000으로 상승"
  },
  {
    "num": "44",
    "choice": "44_1",
    "ty": "renown",
    "cost": "",
    "amount": "",
    "length": "",
    "modifier": "",
    "action": "이건 좀 위험한데.",
    "title": "명성 수치 최대치 8000으로 상승"
  },
  {
    "num": "45",
    "choice": "45_1",
    "ty": "renown",
    "cost": "",
    "amount": "",
    "length": "",
    "modifier": "",
    "action": "이게 카르텔의 숨통을 끊을 것이다.",
    "title": "명성 수치 최대치 15000으로 상승"
  },
  {
    "num": "46",
    "choice": "46_1",
    "ty": "renown",
    "cost": "",
    "amount": "",
    "length": "",
    "modifier": "",
    "action": "나는 수호자가 아니다. 나는 파괴자다.",
    "title": "GAME CLEAR"
  },
  {
    "num": "49",
    "choice": "49_1",
    "ty": "none",
    "cost": "",
    "amount": "",
    "length": "",
    "modifier": "",
    "action": "뛰어난 인재를 뽑아 훈련시키고 좋은 장비로 무장시키는 한편 임무를 수행해 우리의 이름을 알려야 한다.",
    "title": "계속 성장해야 한다."
  }
];