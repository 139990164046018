import React, { useState } from 'react';
import { Rng } from './rand.mjs';
import { stats_const, stats_rand, FIREARM_CATS, STAT_DESCR, stats_avg, STAT_RANGE, updateEntityStat } from './stat.mjs';
import { presets } from './presets_mission.mjs';
import {
  tmpl_firearm_ar_low,
  tmpl_firearm_smg_low,
  tmpl_firearm_hostage,
} from './presets_firearm.mjs';
import { entityFromStat } from './stat';
import { SimView } from './SimView';
import { names, names3 } from './names';
import _ from 'lodash';
import { StatList } from './component/StatList';
import { firearms, firearms_tier1 } from './presets_firearm';
import { gears_vest_bulletproof } from './presets_gear';
import { throwables } from './presets_throwables';
import { perks, perk_groups, perk_apply_stats, perk_apply_entity } from './perks';
import { sampleMission } from './data/google/processor/data_missions';
import { v2 } from './v2';
import { gear_vest_bulletproof_low } from './presets_gear.mjs';

// LD: 재화를 소모해서 새 임무를 생성할 때, 필요한 재화의 양.
const COST_MISSION_NEW_INTEL = 300;

const COST_MARKET_NEW_INTEL = 500;
const COST_MARKET_ADD_LISTING_TY = 500;

const INITIAL_MISSIONS = 5;
// LD: 최대 임무 수. 최대 임무 수가 넘어서면 오래된 미션부터 사라집니다.
const MAX_MISSIONS = 7;

// // LD: 임무에 참여하지 않는 agent는 마다 아래만큼의 체력을 회복합니다.
const LIFE_RECOVER_PER_HOUR = 1.2;

// LD: 아래 일 간격마다 한 번 씩 새 임무가 생성됩니다.
const NEW_INTEL_INTERVAL = 5;

const NEW_MARKET_INTERVAL = 3;

// LD: 시작 에이전트 수.
const INITIAL_AGENTS = 4;

const LIFE_MAX = 100;

const PERK_MIN_HINTS = 5;

// LD: 시작 재화 량.
const INITIAL_RESOURCES = {
  // 자원
  resource: 1000,
  iron: 5,
};

const FACTIONS = {
  'A': {
    resentment: 0, favor: 0,
  },
  'B': {
    resentment: 0, favor: 0,
  },
  'C': {
    resentment: 0, favor: 0,
  },
};
const RESENTMENT_TO_THREATS = 0.15;
const FAVOR_INCREASE = 1;
const RESENTMENT_INCREASE = 1;
const MAX_FACTIONS_IN_MISSION = 2;
//(의뢰,적대)=[(x,x),(x.o),(o,x),(o,o)]
const PERCENT_FACIONS_ON_MISSION = [0.1, 0.3, 0.3, 0.3];

function missionAgentCostSelect(selected) {
  return Math.min(100 * Math.pow(2, selected.length), 800);
}
function missionAgentCostAcc(selected) {
  if (selected.length === 0) {
    return 0;
  }
  if (selected.length > 4) {
    return selected.length * 800 - 900;
  }
  return 100 * (Math.pow(2, selected.length) - 1);
}

function itemPrice(item) {
  if (item.equipment) {
    return item.equipment.vest_cost;
  }
  if (item.firearm) {
    return item.firearm.firearm_cost;
  }
  if (item.throwable) {
    return item.throwable.throwable_cost;
  }
  throw new Error('unknown item');
}

function itemSellPrice(item) {
  const cost = itemPrice(item);
  return Math.floor(cost / 2);
}

function itemRate(item) {
  if (item.equipment) {
    return item.equipment.vest_rate;
  }
  if (item.firearm) {
    return item.firearm.firearm_rate;
  }
  if (item.throwable) {
    return item.throwable.throwable_rate;
  }
  throw new Error('unknown item');
}

function createMilestoneMissions() {
  const missions = [
    {
      ty: 'indoor2',
      expires_at: 30,
      cost: { time: 5 },
      reward: { base: 5000, extra: 100 },
      intel: { threats: [6, 8], training: [3, 6], rescues: [0, 0] },
      milestone: true,
      enemies: ['A'],
      clients: []
    },

    {
      ty: 'ship',
      expires_at: 60,
      cost: { time: 8 },
      reward: { base: 7500, extra: 150 },
      intel: { threats: [10, 15], training: [4, 8], rescues: [0, 0] },
      milestone: true,
      enemies: ['B'],
      clients: ['A']
    },

    {
      ty: 'bazaar',
      expires_at: 75,
      cost: { time: 8 },
      reward: { base: 10000, extra: 150 },
      intel: { threats: [10, 15], training: [4, 8], rescues: [0, 0] },
      milestone: true,
      enemies: ['A'],
      clients: ['C']
    },

    {
      ty: 'embassy',
      expires_at: 90,
      cost: { time: 10 },
      reward: { base: 15000, extra: 200 },
      intel: { threats: [10, 15], training: [4, 8], rescues: [0, 1] },
      milestone: true,
      enemies: ['C', 'B'],
      clients: ['A']
    },

    {
      ty: 'metro',
      expires_at: 120,
      cost: { time: 15 },
      reward: { base: 20000, extra: 250 },
      intel: { threats: [20, 50], training: [5, 10], rescues: [0, 0] },
      milestone: true,
      enemies: ['C'],
      clients: []
    },
  ];

  return missions.map((mission) => {
    const preset = presets[mission.ty]();
    const enemy_count = preset.entities.filter((e) => e.team === 1).length;
    mission.intel.threats = [Math.floor(enemy_count * 2 / 3), Math.floor(enemy_count * 4 / 3)];
    return mission;
  });
}

const MARKET_LISTING_TYPES = ['agent', 'firearm', 'equipment', 'util'];
const MARKET_LEVEL_SIZES = [
  { cost: 0, size: 5, progress: 0, message: '' },
  { cost: 100, size: 6, progress: 1, message: '상점 크기 1 증가(총 상점 크기 6)' },
  { cost: 200, size: 7, progress: 1, message: '상점 크기 1 증가(총 상점 크기 7)' },
  { cost: 300, size: 8, progress: 1, message: '상점 크기 1 증가(총 상점 크기 8)' },
  { cost: 500, size: 10, progress: 3, message: '상점 크기 2 증가(총 상점 크기 10)' },
];

const MARKET_LEVEL_DISCOUNT = [
  { cost: 0, mult: 1, progress: 0, message: '' },
  { cost: 200, mult: 0.75, progress: 1, message: '새 항목 추가비용 25% 감소' },
  { cost: 500, mult: 0.50, progress: 4, message: '새 항목 추가비용 50% 감소' },
];

const MARKET_LEVEL_TY = [
  { cost: 0, progress: 0, message: '' },
  { cost: 400, progress: 2, message: '을/를 골라뽑을 수 있습니다.' },
  { cost: 800, progress: 3, message: '이/가 상점 리스팅으로 추가될 때, 더 높은 등급/좋은 스펙의 아이템이 추가될 확률이 증가합니다.' },
];

// serializable했으면 좋겠음.
function marketState() {
  const level_ty = {};
  for (const ty of MARKET_LISTING_TYPES) {
    level_ty[ty] = 0;
  }

  return {
    // 특정 타입 구매 버튼의 업그레이드 레벨
    // 타입 업그레이드를 하면, 1) 해당 타입 리스팅 추가하기, 2) 해당 타입 리스팅의 퀄리티가 올라감
    level_ty,

    // 상점 크기 레벨. MARKET_LEVEL_SIZES와 조합해서 크기를 결정합니다.
    level_market_size: 0,
    // 상점 비용 감소 업그레이드 레벨
    level_market_discount: 0,
  };
}

function marketSize({ level_market_size }) {
  return MARKET_LEVEL_SIZES[level_market_size].size;
}

function marketDiscountPurchase(progress) {
  return Math.max(0.1, 1 - (progress - 1) * 0.1);
}

function marketDiscountListing({ level_market_discount }) {
  return Math.max(0.1, MARKET_LEVEL_DISCOUNT[level_market_discount].mult);
}

function MarketView(props) {
  const { market_state, market_listings, progress, resource } = props;
  // TODO: 나중에 고쳐야 함
  const { onMarketNewListing, onMarketListingPurchase, onMarketListingDiscard, onMarketStateUpdate } = props;

  const { level_market_size, level_market_discount } = market_state;

  const size = marketSize(market_state);
  const discount_listing = marketDiscountListing(market_state);
  const discount_purchase = marketDiscountPurchase(progress);

  const cost_listing = Math.floor(COST_MARKET_NEW_INTEL * discount_listing);
  const cost_listing_ty = Math.floor(COST_MARKET_ADD_LISTING_TY * discount_listing);

  function buildUpgradeButton(tab, key, label) {
    // TODO: 사실 안좋기는 한데..
    let val = market_state[key];
    if (val === tab.length - 1) {
      return null;
    }

    const data = tab[val + 1];
    const cost = data.cost;

    let disabled = false;
    let title = '';

    if (data.progress > progress) {
      disabled = true;
      title += '마일스톤' + data.progress + '을 클리어 해야합니다. ';
    }
    if (resource < cost) {
      disabled = true;
      title += 'resource가 부족합니다.';
    }
    if (!disabled) {
      title = data.message;
    }

    return <button onClick={() => {
      const next_state = {
        ...market_state,
      };
      next_state[key] += 1;

      onMarketStateUpdate(next_state, cost);
    }} disabled={disabled} title={title}>{label} ${cost}</button>;
  }

  let button_upgrade_size = buildUpgradeButton(MARKET_LEVEL_SIZES, 'level_market_size', 'size');
  let button_upgrade_discount = buildUpgradeButton(MARKET_LEVEL_DISCOUNT, 'level_market_discount', 'discount');

  // 상점에서 타입 구매 버튼
  function buildTySpawnbutton(ty) {
    const level = market_state.level_ty[ty];
    // TODO: logic
    if (level === 0) {
      return null;
    }
    const cost = cost_listing_ty * 2;

    return <button onClick={() => onMarketNewListing(ty, cost)}>
      new {ty} ${cost}</button>;
  }

  // 상점에서 타입 업그레이드 버튼
  function buildTyUpgradeButton(ty) {
    const level = market_state.level_ty[ty];
    if (level === MARKET_LEVEL_TY.length - 1) {
      return;
    }

    const data = MARKET_LEVEL_TY[level + 1];
    const cost = data.cost;
    let disabled = false;
    let title = '';

    if (data.progress > progress) {
      disabled = true;
      title += '마일스톤' + data.progress + '를 클리어 해야합니다. ';
    }
    if (resource < cost) {
      disabled = true;
      title += 'resource가 부족합니다.';
    }
    if (!disabled) {
      title = ty + data.message;
    }

    return <button onClick={() => {
      const next_level_ty = { ...market_state.level_ty };
      next_level_ty[ty] += 1;
      const next_state = {
        ...market_state,
        level_ty: next_level_ty,
      };

      onMarketStateUpdate(next_state, cost);
    }} disabled={disabled} title={title}>{ty} ${cost}</button>;
  }

  function buildNewListingButton() {
    const disabled = resource < cost_listing;
    let title;
    if (disabled) {
      title = 'resource가 부족합니다.';
    }
    return <button disabled={disabled} title={title} onClick={() => onMarketNewListing(null, cost_listing)}>new listing (${cost_listing})</button>;
  }

  return <>
    <p>market listings {market_listings.length}/{size}
      {buildNewListingButton()}
      {MARKET_LISTING_TYPES.map(ty => <span key={ty}>{buildTySpawnbutton(ty)}</span>)}
    </p>

    <p>upgrades size={level_market_size} {button_upgrade_size} discount={level_market_discount} {button_upgrade_discount}
      {MARKET_LISTING_TYPES.map(ty => <span key={ty}>{buildTyUpgradeButton(ty)}</span>)}
    </p>

    {market_listings.map((m, i) => <MarketListingView key={i} item={m}
      onMarketListingPurchase={onMarketListingPurchase}
      onMarketListingDiscard={onMarketListingDiscard}
      discount={discount_purchase} resource={resource}
    />)}
  </>;
}

function createMarketItemTy(rng, market_state, names, ty) {
  switch (ty) {
    case 'agent':
      const name = names.pop();
      let stats = stats_rand(rng, name);
      if (market_state.level_ty.agent === 1) {
        // 상점에 새 용병이 추가될 때 50% 확률로 발동되어, 역량이 최소 11인 용병이 리스팅됨
        if (rng.range(0, 1) > 0.5) {
          while (stats_avg(stats) < 11) {
            stats = stats_rand(rng, name);
          }
        }
      } else if (market_state.level_ty.agent === 2) {
        // 상점에 새 용병이 추가될 때 50% 확률로 발동되어, 역량이 최소 13인 용병이 리스팅됨
        if (rng.range(0, 1) > 0.5) {
          while (stats_avg(stats) < 13) {
            stats = stats_rand(rng, name);
          }
        }
      }

      return {
        ty: 'agent',
        cost: rng.integer(1000, 3000),
        stats,
      };

    case 'firearm':
      let firearm = rng.choice(firearms);
      if (market_state.level_ty.firearm === 1) {
        // 상점에 새 무기가 추가될 때 50% 확률로 발동되어, 2티어 이상의 무기가 리스팅됨
        if (rng.range(0, 1) > 0.5) {
          firearm = rng.choice(firearms.filter((f) => f.firearm_rate > 1));
        }
      } else if (market_state.level_ty.firearm === 2) {
        firearm = rng.choice(firearms.filter((f) => f.firearm_rate > 1));
      }

      return {
        ty: 'firearm',
        cost: firearm.firearm_cost ? firearm.firearm_cost : 100,
        firearm,
      };

    case 'equipment':
      let equipment = rng.choice(gears_vest_bulletproof.slice(1));

      if (market_state.level_ty.firearm === 1) {
        // 상점에 새 방탄장비가 추가될 때 50% 확률로 발동되어, 2티어 이상의 방탄장비가 리스팅됨
        if (rng.range(0, 1) > 0.5) {
          equipment = rng.choice(gears_vest_bulletproof.slice(1).filter((e) => e.vest_rate > 1));
        }
      } else if (market_state.level_ty.firearm === 2) {
        equipment = rng.choice(gears_vest_bulletproof.slice(1).filter((e) => e.vest_rate > 1));
      }

      return {
        ty: 'equipment',
        cost: equipment.vest_cost,
        equipment,
      };

    case 'util':
      let priceMult = 1;
      const t = rng.choice(throwables.slice(1));
      const lvl = market_state.level_ty.util;
      switch (lvl) {
        case 1:
          priceMult = 0.75;
          break;
        case 2:
          priceMult = 0.5;
          break;
        default:
          throw new Error(`unknown market_state.level_ty.util=${lvl}`);
      }

      return {
        ty: 'util',
        cost: Math.floor(t.throwable_cost * priceMult),
        throwable: t,
      };
    default:
      throw new Error('not implemented');
  }
}

function createMarketItem(rng, market_state, names) {
  const ty = rng.choice(MARKET_LISTING_TYPES);
  return createMarketItemTy(rng, market_state, names, ty);
}

function createMission(rng, progress, tick, factions) {
  const config = sampleMission(rng, progress);

  const { reward_mult_min, reward_mult_max } = config;
  const threats_min = rng.integer(config.threats_min, config.threats_max);
  const threats_max = Math.ceil(threats_min * 1.5);

  let duration = threats_min * 3 + rng.integer(2, 10);
  let expires_at = tick + duration;
  if (expires_at < 5) {
    expires_at = 5;
  }

  const reward_base = rng.integer(reward_mult_min, reward_mult_max) * (threats_min + 1);
  const rescues_max = rng.range(0, 1) < Math.max(threats_min / 20, 0.5) ? 1 : 0;

  const ty = rng.choice(['indoor', 'outdoor']);

  let factionKeys = Object.keys(factions);

  const random = rng.range(0, 1);
  let clients = [];
  let enemies = [];

  if (random > 1 - PERCENT_FACIONS_ON_MISSION[3]) {
    const client = factionKeys[rng.integer(0, factionKeys.length - 1)];
    clients.push(client);
    factionKeys = factionKeys.filter((key) => key !== client)
    const enemy = factionKeys[rng.integer(0, factionKeys.length - 1)];
    enemies.push(enemy);
  } else if (random > PERCENT_FACIONS_ON_MISSION[0] + PERCENT_FACIONS_ON_MISSION[1]) {
    for (let i = 0; i < MAX_FACTIONS_IN_MISSION; i++) {
      const client = factionKeys[rng.integer(0, factionKeys.length - 1)];
      if (clients.includes(client)) {
        break;
      }
      clients.push(client);
    }
  } else if (random > PERCENT_FACIONS_ON_MISSION[0]) {
    for (let i = 0; i < MAX_FACTIONS_IN_MISSION; i++) {
      const enemy = factionKeys[rng.integer(0, factionKeys.length - 1)];
      if (enemies.includes(enemy)) {
        break;
      }
      enemies.push(enemy);
    }
  }

  const perkgroup = rng.choice(perk_groups);

  return {
    clients,
    enemies,

    ty,
    perkgroup,
    expires_at,

    reward: {
      base: reward_base,
      extra: Math.floor(reward_base * rng.range(0.5, 1.0) / duration),
    },

    cost: {
      time: 2 + Math.floor(threats_max / 3),
    },

    intel: {
      threats: [threats_min, threats_max],
      training: [1, 3],
      rescues: [0, rescues_max],
    },
  };
}

// https://coolors.co/03071e-370617-6a040f-9d0208-d00000-dc2f02-e85d04-f48c06-faa307-ffba08
const THREAT_STATS = [1, 4, 7];
const THREAT_COLORS = ['#D00000', '#E85D04', '#FFBA08'];

function sampleThreats(rng, count, target, cats) {
  // 제일 높은 cats로 채웁니다.
  const levels = new Array(count).fill(cats.length - 1);

  while (_.meanBy(levels, (lv) => cats[lv]) > target) {
    let idx = rng.integer(0, count - 1);
    if (levels[idx] === 0) {
      continue;
    }
    levels[idx] -= 1;
  }
  return levels;
}

function instantiateMission(rng, mission, names, tick, factions) {
  const { intel } = mission;

  const rescues_len = rng.integer(...intel.rescues);
  const rescues = [];
  while (rescues.length < rescues_len) {
    const stats = stats_rand(rng);
    stats.name = names.pop();
    rescues.push(stats);
  }

  let threats = 0;
  const training = rng.integer(...intel.training);
  const additionalThreats = resentment_to_plusthreats(mission, factions);

  if (!mission.milestone) {
    threats = rng.integer(...intel.threats) + additionalThreats;
  } else {
    // 먼저 instantiate해서 숫자를 확인해야 함.
    const preset = presets[mission.ty]();
    threats = preset.entities.filter((e) => e.team === 1).length + additionalThreats;
  }

  const threat_levels = sampleThreats(rng, threats, training, THREAT_STATS);


  let ty = mission.ty;

  // 반복 가능한 임무에 대해, 위협 수에 따라 preset을 조절
  if (ty === 'indoor' && threats > 7) {
    ty = 'indoor2';
  }
  if (!mission.milestone && rescues.length > 0) {
    ty += '_rescue';
  }

  const tick_delta = mission.expires_at - tick;

  return {
    ...mission,

    resource: mission.reward.base + mission.reward.extra * tick_delta,

    ty,
    threats,
    threat_levels,
    training,
    rescues,
  };
}

function PerkGroupView(props) {
  if (!props || !props.group) {
    return null;
  }

  const { name, keys } = props.group;
  let text = '';
  for (const key of keys) {
    text += perks[key].name + '=' + perks[key].descr + '\n';
  }

  return <span title={text}>경험={name}</span>;
}

function resentment_to_plusthreats(mission, factions) {
  let plusThreats = 0;
  for (const enemy of mission.enemies) {
    if (factions[enemy]) {
      plusThreats += factions[enemy].resentment * RESENTMENT_TO_THREATS;
    }
    plusThreats = plusThreats / mission.enemies.length;
  }
  plusThreats = Math.round(plusThreats);
  return plusThreats;
}

function MissionItem(props) {
  const { readonly, mission, mission_selected, onMissionSelect, tick, factions } = props;

  let buttons = null;
  if (!readonly) {
    const selectmsg = mission === mission_selected ? 'unselect' : 'select';
    buttons = <>
      <button onClick={() => onMissionSelect(mission)}>{selectmsg}</button>
    </>;
  }

  let rescuetext = null;
  if (mission.intel.rescues.find((v) => v > 0)) {
    rescuetext = `구조=${mission.intel.rescues.join("-")}`;
  }

  const tick_delta = mission.expires_at - tick;
  const reward = mission.reward.base + mission.reward.extra * tick_delta;

  const plusThreats = resentment_to_plusthreats(mission, factions)

  return <div className="box">
    <p>의뢰 = {mission.clients.toString() === '' ? '개인' : mission.clients.toString()} 적대 = {mission.enemies.toString() === '' ? '없음' : mission.enemies.toString()}</p>
    <p>임무 환경={mission.ty}, 만료={mission.expires_at - tick} days, <PerkGroupView group={mission.perkgroup} /></p>
    <p>보상=${reward} (완료=${mission.reward.base}, 조기 완료시 일당=${mission.reward.extra}), 기간={mission.cost.time} days</p>
    <p>위협={mission.intel.threats.join("-")} {plusThreats ? `(+${plusThreats})` : ``}, 훈련={mission.intel.training.join("-")} {rescuetext}</p>
    {buttons}
  </div >;
}

export function NameView(props) {
  const { name } = props;
  const url = `https://namu.wiki/w/${name}`;

  return <a href={url} target="_blank" rel="noreferrer">[{name}]</a>;
}

// 성장 요소
//  - stat / perk
//    - 미션 이후 미션 결과에 따라 변동될 수 있음
//    - 혹은, 훈련을 통해 획득할 수 있음
//  - equipments (firearm, equipment): 구매. 교전에 따라 소모됨.
export function AgentDetail(props) {
  const { agent } = props;

  return <div className="box">
    <p>name=<NameView name={agent.name} /> life={agent.life}/{LIFE_MAX}</p>
    <StatList stats={agent.stats} />
  </div>;


  //  계약 만료까지={agent.expires_at - tick}일
}

function AgentStat(props) {
  const { stats } = props;

  const max_firearm_level = _.max(stats.stat_firearm_level);
  const max_firearm_idx = stats.stat_firearm_level.indexOf(max_firearm_level);
  const max_firearm_cat = FIREARM_CATS[max_firearm_idx];

  const firearm_title = stats.stat_firearm_level.map((level, i) => {
    const label = FIREARM_CATS[i];
    return `${label}=${level}`;
  }).join("\n");

  const stats_title = Object.keys(STAT_DESCR).map((name) => {
    return `${STAT_DESCR[name]}=${stats[name]}`;
  }).join("\n");

  return <>
    <span title={firearm_title}>특기={max_firearm_level}({max_firearm_cat})</span>
    <> </>
    <span title={stats_title}>역량={stats_avg(stats)}</span>
  </>;
}

function PerkView(props) {
  const { perk_key } = props;
  const p = perks[perk_key];
  return <span title={p.descr}>{p.name}, </span>;
}

function AgentPerkHintView(props) {
  const { agent, readonly, onAgentAcquirePerk, onAgentEtcStart, resource } = props;
  const { hints } = agent;

  const title = hints.map((key) => {
    return perks[key].name + ': ' + perks[key].descr;
  }).join('\n');

  let btn = null;
  if (!readonly && hints.length >= PERK_MIN_HINTS) {
    btn = <button style={{ color: 'red' }} onClick={() => onAgentAcquirePerk(agent)}>특기획득</button>;
  }
  let trainBtn = null;
  let healBtn = null;
  if (!readonly) {
    const trainArgs = { agent, cost: { resource: 100, time: 3 }, ty: 'training' }
    let disabled = resource < trainArgs.cost.resource;
    let title;
    if (disabled) {
      title = 'resource가 부족합니다.';
    }
    trainBtn = <button disabled={disabled} title={title} onClick={() => onAgentEtcStart(trainArgs)}>훈련 ${trainArgs.cost.resource}</button>;

    const healArgs = { agent, cost: { resource: 1000, time: 1 }, ty: 'heal' }
    disabled = resource < healArgs.cost.resource;
    if (disabled) {
      title = 'resource가 부족합니다.';
    } else {
      title = 'life 10을 회복합니다.'
    }
    healBtn = <button disabled={disabled} title={title} onClick={() => onAgentEtcStart(healArgs)}>치료 ${healArgs.cost.resource}</button>;
  }

  let perklistview = null;
  if (agent.perks.length > 0) {
    const perklist = agent.perks.map((p) => <PerkView key={p} perk_key={p} />);
    perklistview = <>, 특기={perklist}</>;
  }
  return <span><span title={title}>경험={hints.length}개{perklistview}</span> {btn} {trainBtn} {healBtn}</span>;
}

function AgentItem(props) {
  const { agent, agents_selected, readonly, resource } = props;
  const { onAgentToggle, onAgentDetail, inventories, onAgentEquip, onAgentAcquirePerk, onAgentEtcStart, onSpawnChange } = props;

  const [showfirearms, setShowfirearms] = useState(false);
  const [showequipments, setShowequipments] = useState(false);
  const [showutils, setShowtuils] = useState(false);

  const buttons = [];
  if (onAgentToggle) {
    const cost_select = missionAgentCostSelect(agents_selected);
    const selected = agents_selected.includes(agent);
    let msg = '';
    if (!selected) {
      msg = `select ($${cost_select})`;
    } else {
      msg = 'unselect';
    }
    buttons.push(<button key='select' onClick={() => onAgentToggle(agent)}>{msg}</button>);
  }
  if (onSpawnChange) {
    buttons.push(<button key='spawn' onClick={() => onSpawnChange(agent)}>area #{agent.spawnarea}</button>)
  }

  if (onAgentDetail) {
    buttons.push(<button key='detail' onClick={() => onAgentDetail(agent)}>detail</button>);
  }

  let firearmbutton = null;
  let firearmlist = null;

  let equipmentbutton = null;
  let equipmentlist = null;

  let utilbutton = null;
  let utillist = null;

  if (!readonly) {
    firearmbutton = <button onClick={() => setShowfirearms(!showfirearms)}>change</button>;

    if (showfirearms) {
      const firearms = _.uniqBy(inventories.filter((e) => e.ty === 'firearm'), (e) => e.firearm.firearm_name);;
      firearmlist = <span>firearms
        {firearms
          .map((e, i) => <button key={i} onClick={() => {
            setShowfirearms(false);
            onAgentEquip(agent, e);
          }}><FirearmLabel firearm={e.firearm} /></button>)}
      </span>;
    }

    equipmentbutton = <button onClick={() => setShowequipments(!showequipments)}>change</button>;
    if (showequipments) {
      const equipments = _.uniqBy(inventories.filter((e) => e.ty === 'equipment'), (e) => e.equipment.vest_name);
      equipmentlist = <span>equipments
        {equipments
          .map((e, i) => <button key={i} onClick={() => {
            setShowequipments(false);
            onAgentEquip(agent, e);
          }}><GearLabel equipment={e.equipment} /></button>)}
      </span>;
    }

    utilbutton = <button onClick={() => setShowtuils(!showutils)}>change</button>;
    if (showutils) {
      utillist = <span>util
        {inventories
          .filter((e) => e.ty === 'util')
          .map((e, i) => <button key={i} onClick={() => {
            setShowtuils(false);
            onAgentEquip(agent, e);
          }}><UtilLabel item={e} /></button>)}
      </span>;
    }
  }

  const { idx, name, life, stats, firearm, equipment, util, mission_stats } = agent;

  let stat_title = `idx=${idx}\n사살=${mission_stats.kills}\n임무=${mission_stats.count}\n명중=${mission_stats.hits_done}\n피격=${mission_stats.hits_taken}`;

  return <div className="box">
    <p>name=<NameView name={name} /> life={life.toFixed(1)}/{LIFE_MAX} <AgentStat stats={stats} />
      <span>{" "}</span>
      <span title={stat_title}>사살={mission_stats.kills}, 임무={mission_stats.count}</span> {buttons}
      <span>{" "}</span>
    </p>
    <p>
      firearm <FirearmLabel firearm={firearm} /> {firearmbutton}
      <span> | </span>
      equipment <GearLabel equipment={equipment} /> {equipmentbutton}
      <span> | </span>
      util <UtilLabel item={util} /> {utilbutton}
      <span> | </span>
      <AgentPerkHintView
        agent={agent}
        readonly={readonly}
        onAgentEtcStart={onAgentEtcStart}
        onAgentAcquirePerk={onAgentAcquirePerk}
        resource={resource} />
    </p>
    {firearmlist}
    {equipmentlist}
    {utillist}
  </div>;
  // , 계약 만료까지={expires_at - tick}일
}


function PlanView(props) {
  const { mission, agents, onMissionQueue, tick, onMissionDryrun, onReset, onSpawnChange, onAgentToggle, factions } = props;

  const resource = missionAgentCostAcc(agents);

  let missionview = <p>임무를 선택해주세요</p>;
  if (mission) {
    missionview = <MissionItem mission={mission} tick={tick} readonly factions={factions} />;
  }

  let agentsview = <p>인원을 선택해주세요</p>;
  if (agents.length > 0) {
    agentsview = agents.map(agent => <AgentItem key={agent.name} agent={agent} tick={tick} agents_selected={agents}
      onSpawnChange={onSpawnChange} onAgentToggle={onAgentToggle} readonly />);
  }

  const time = mission ? mission.cost.time : 0;

  const canstart = mission && agents.length > 0;
  const args = { mission, agents, cost: { resource, time } };

  let simbutton = null;
  if (onMissionDryrun) {
    simbutton = <button onClick={() => onMissionDryrun(args)} disabled={!canstart}>
      simulate
    </button>;
  }

  return <div className="box">
    <p>임무 계획 <button onClick={onReset}>reset</button></p>
    {missionview}
    {agentsview}
    <p>소요일: {time} days</p>
    <p>임무 수당: ${resource}</p>
    <button onClick={() => onMissionQueue(args)} disabled={!canstart}>임무 출발</button>
    {simbutton}
  </div>;
}

function FirearmLabel(props) {
  const { firearm } = props;
  const { firearm_ty, firearm_name, firearm_rate } = firearm;
  return <span>ty={firearm_ty}, name={firearm_name}(★{firearm_rate})</span>;
}

function GearLabel(props) {
  const { equipment } = props;
  const { vest_name, vest_armor, vest_rate } = equipment;
  return <span>name={vest_name}, armor={vest_armor}(★{vest_rate})</span>;
}

function UtilLabel(props) {
  const { item } = props;
  const { throwable } = item;
  const { throwable_name, throwable_rate } = throwable;
  return <span>name={throwable_name} (★{throwable_rate})</span>;
}

function MarketListingView(props) {
  const { item, onMarketListingPurchase, onMarketListingDiscard, onInventorySell, discount, resource } = props;
  const { ty } = item;

  const cost = Math.floor(item.cost * discount);

  let detail = null;
  let tyDetail = null;
  switch (ty) {
    case 'agent':
      const { stats } = item;
      detail = <span><NameView name={stats.name} /> <AgentStat stats={stats} /></span>;
      break;
    case 'equipment':
      const { equipment } = item;
      detail = <GearLabel equipment={equipment} />;
      break;
    case 'firearm':
      const { firearm } = item;
      detail = <FirearmLabel firearm={firearm} />;
      break;
    case 'util':
      detail = <UtilLabel item={item} />
      tyDetail = <span title="기타">{ty}</span>;
      break;
    default:
      break;
  }

  let buttons = [];

  if (onMarketListingPurchase) {
    const disabled = resource < cost;
    let title;
    if (disabled) {
      title = 'resource가 부족합니다.';
    }
    buttons.push(<button key="purchase" disabled={disabled} title={title} onClick={() => onMarketListingPurchase(item, cost)}>purchase ${cost}</button>);
  }
  if (onMarketListingDiscard) {
    buttons.push(<button key="discard" onClick={() => onMarketListingDiscard(item)}>discard</button>);
  }
  if (onInventorySell) {
    buttons.push(<button key="sell" onClick={() => onInventorySell(item)}>sell ${itemSellPrice(item)}</button>);
  }

  return <div className="box">
    {tyDetail ?? ty} {detail} {buttons}
  </div>;
}

function createAgent(rng, name, stats, idx) {
  stats.name = name;
  const max_firearm_level = _.max(stats.stat_firearm_level);
  const max_firearm_idx = stats.stat_firearm_level.indexOf(max_firearm_level);

  return {
    idx,
    name,
    life: LIFE_MAX,
    stats,

    firearm: firearms_tier1[max_firearm_idx],
    equipment: gears_vest_bulletproof[0],
    spawnarea: 0,
    util: { ty: 'util', cost: 0, throwable: throwables[0] },

    expires_at: 1000, // TODO

    mission_stats: {
      count: 0,
      kills: 0,

      hits_done: 0,
      hits_taken: 0,
    },

    perks: [],
    hints: [
      rng.choice(Object.keys(perks)),
    ],
  };
};

function shuffle(array) {
  let currentIndex = array.length, randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

export class LoopView extends React.Component {
  static initialState() {
    const names = names3.slice();
    shuffle(names);

    let agent_idx = 0;

    const rng = new Rng(Rng.randomseed());
    const agents = [];

    function constraintViolated(agents) {
      // 특정 화기 숙련 에이전트가 정도 이상으로 나오지 않도록
      const constraints = [
        [['sr', 'dmr'], 2],
        [['smg', 'sg'], 2],
      ];

      for (const [types, count] of constraints) {
        let count2 = 0;
        for (const agent of agents) {
          const max_firearm_level = _.max(agent.stats.stat_firearm_level);
          const max_firearm_idx = agent.stats.stat_firearm_level.indexOf(max_firearm_level);
          const max_firearm_cat = FIREARM_CATS[max_firearm_idx];

          if (types.includes(max_firearm_cat)) {
            count2++;
          }
        }
        if (count2 >= count) {
          return true;
        }
      }
      return false;
    }

    while (agents.length < INITIAL_AGENTS) {
      const name = names.pop();
      const stats = stats_rand(rng, name);

      const agent = createAgent(rng, name, stats, agent_idx++);
      agents.push(agent);

      if (constraintViolated(agents)) {
        agents.pop();
        names.push(name);
      }
    }

    const progress = 1;
    const market_listings = [];
    const market_state = marketState();

    market_listings.push(createMarketItemTy(rng, market_state, names, 'firearm'));
    market_listings.push(createMarketItemTy(rng, market_state, names, 'equipment'));
    market_listings.push(createMarketItemTy(rng, market_state, names, 'equipment'));

    const tick = 0;

    const factions = { ...FACTIONS };
    return {
      rng,
      tick,
      resources: { ...INITIAL_RESOURCES },

      journals: [],

      pendings: [{
        ty: 'market',
        tick: NEW_MARKET_INTERVAL,
      }, {
        ty: 'intel',
        tick: NEW_INTEL_INTERVAL,
      }],

      agents,
      agent_idx,
      dead: [],

      missions: new Array(INITIAL_MISSIONS).fill(0).map(() => createMission(rng, progress, tick, factions)),

      milestone_missions: createMilestoneMissions(),

      inventories: [
        { ty: 'equipment', cost: 100, equipment: gear_vest_bulletproof_low },
        { ty: 'equipment', cost: 100, equipment: gear_vest_bulletproof_low },
        { ty: 'equipment', cost: 100, equipment: gear_vest_bulletproof_low },
        { ty: 'equipment', cost: 100, equipment: gear_vest_bulletproof_low },
      ],

      market_state,
      market_listings,

      mission_selected: null,
      agents_selected: [],

      mission_state: null,
      training_state: [],
      heal_state: [],

      progress,

      names,

      factions,
    };
  }

  constructor(props) {
    super(props);

    this.simRef = React.createRef();
    this.state = LoopView.initialState();
  }

  pushJournal(msg, tick, journals) {
    journals = journals ?? this.state.journals;
    tick = tick ?? this.state.tick;
    const j = journals.slice();
    j.push({ tick, msg });
    return j;
  }

  static stateDescr(state) {
    const { tick, agents, progress } = state;
    return `day=${tick}, agents=${agents.length}, progress=${progress}`;
  }

  // save-load
  serializeState(idx) {
    const { state } = this;
    window.localStorage.setItem(`proto_loop_save_${idx}_descr`, LoopView.stateDescr(state));
    window.localStorage.setItem(`proto_loop_save_${idx}_data`, JSON.stringify(state));

    this.setState({});
  }

  discardState(idx) {
    window.localStorage.removeItem(`proto_loop_save_${idx}_descr`);
    window.localStorage.removeItem(`proto_loop_save_${idx}_data`);

    this.setState({});
  }

  deserializeState(data) {
    function fixObject(o) {
      if (Array.isArray(o)) {
        return o.map(fixObject);
      }
      if (!isNaN(o)) {
        return o;
      }
      if (typeof o === 'string') {
        return o;
      }

      const keys = Object.keys(o);
      if (keys.length === 2 && keys[0] === 'x' && keys[1] === 'y') {
        return new v2(o.x, o.y);
      }

      for (const key of keys) {
        o[key] = fixObject(o[key]);
      }
      return o;
    }

    const state = JSON.parse(data);
    fixObject(state);
    state.agents_selected = state.agents_selected.map((a0) => state.agents.find((a1) => a1.idx === a0.idx));
    if (state.mission_selected && state.mission_selected.milestone) {
      state.mission_selected = state.milestone_missions.find((m) => m.ty === state.mission_selected.ty);
    } else if (state.mission_selected && !state.mission_selected.milestone) {
      state.mission_selected = state.missions.find((m) => _.isEqual(m, state.mission_selected));
    }
    state.rng = new Rng(Rng.randomseed());

    this.setState(state);
  }

  renderSystem() {
    const cansave = this.canProgress();

    let slots = [];
    for (let i = 0; i < 3; i++) {
      let idx = i;
      const descr = window.localStorage.getItem(`proto_loop_save_${idx}_descr`);
      const loadButton = <button onClick={() => {
        const data = window.localStorage.getItem(`proto_loop_save_${idx}_data`);
        this.deserializeState(data);
      }}>load</button>;
      let saveButton = null;
      if (i > 0) {
        saveButton = <button onClick={() => this.serializeState(idx)} disabled={!cansave}>save</button>;
      }
      const discardButton = <button onClick={() => this.discardState(idx)}>discard</button>;

      if (!descr) {
        slots.push(<div key={idx}>slot {idx}: empty {saveButton}</div>);
        continue;
      }
      slots.push(<div key={idx}>slot {idx}: {descr} {loadButton} {saveButton} {discardButton}</div>);
    }

    return <div className="box">
      <p>system</p>
      {slots}
    </div>;
  }

  onTick(days) {
    const tick = this.state.tick + days;

    const agents = this.state.agents.slice();
    const resources = { ...this.state.resources };

    this.serializeState(0);

    let journals = this.pushJournal(`${days} 일이 경과했습니다.`, tick);

    for (const agent of agents) {
      const life_next = Math.min(100, agent.life * Math.pow(LIFE_RECOVER_PER_HOUR, days));
      if (agent.life !== life_next) {
        journals = this.pushJournal(`${agent.name}의 체력이 ${(agent.life).toFixed(1)} => ${(life_next).toFixed(1)}로 회복되었습니다.`, tick, journals);
        agent.life = life_next;
      }
    }

    let missions = this.state.missions.filter((m) => {
      if (m.expires_at <= tick) {
        this.setState({
          journals: this.pushJournal('임무가 만료되었습니다', tick, journals)
        })
        return false;
      }
      return true;
    });

    let mission_state = this.state.mission_state;
    const pendings = this.state.pendings.slice();
    while (pendings.length > 0 && pendings[0].tick <= tick) {
      const ev = pendings.shift();

      if (ev.ty === 'intel') {
        journals = this.pushJournal('새 임무 정보가 도착했습니다.', tick, journals);

        ev.tick = tick + NEW_INTEL_INTERVAL;
        pendings.push(ev);
        pendings.sort((a, b) => a.tick - b.tick);

        missions = this.appendNewMission(missions);
      } else if (ev.ty === 'market') {
        journals = this.pushJournal('새 상품이 업데이트되었습니다.', tick, journals);

        ev.tick = tick + NEW_MARKET_INTERVAL;
        pendings.push(ev);
        pendings.sort((a, b) => a.tick - b.tick);

        this.appendNewMarket();
      } else if (ev.ty === 'mission') {
        journals = this.pushJournal('임무를 시작합니다', tick, journals);
        mission_state = ev.mission_state;
      } else if (ev.ty === 'training') {
        this.onTrainFinish(ev.agent);
      } else if (ev.ty === 'heal') {
        this.onHealFinish(ev.agent);
      }
    }

    this.setState({
      tick: tick,
      agents,
      resources,

      missions,
      mission_state,

      pendings,
      journals
    });
  }

  onMissionNewIntel() {
    const resources = { ...this.state.resources };
    if (resources.resource < COST_MISSION_NEW_INTEL) {
      return;
    }
    resources.resource -= COST_MISSION_NEW_INTEL;

    const missions = this.appendNewMission(this.state.missions);
    this.setState({ resources, missions });

  }

  appendNewMission(missions) {
    const { rng, progress, tick, factions } = this.state;
    missions.push(createMission(rng, progress, tick, factions));
    while (missions.length > MAX_MISSIONS) {
      missions.shift();
    }
    return missions;
  }

  appendNewMarket(ty) {
    const { rng, market_state, names } = this.state;
    const market_listings = this.state.market_listings.slice();

    if (ty) {
      market_listings.push(createMarketItemTy(rng, market_state, names, ty));
    } else {
      market_listings.push(createMarketItem(rng, market_state, names));
    }

    const market_size = marketSize(market_state);
    while (market_listings.length > market_size) {
      market_listings.shift();
    }

    this.setState({ market_listings, names });
  }

  onMarketNewListing(ty, cost) {
    // TODO: handle ty
    const resources = { ...this.state.resources };
    if (resources.resource < cost) {
      return;
    }
    resources.resource -= cost;

    this.appendNewMarket(ty);
    this.setState({ resources });
  }

  onMissionSelect(mission) {
    const { mission_selected, agents_selected } = this.state;
    for (const agent of agents_selected) {
      agent.spawnarea = 0;
    }
    if (mission === mission_selected) {
      this.setState({ mission_selected: null });
    } else {
      this.setState({ mission_selected: mission });
    }
  }

  buildMissionState(props) {
    const { rng, tick, factions } = this.state;
    const { mission, agents } = props;

    const names = this.state.names.slice();

    const instantiated = instantiateMission(rng, mission, names, tick, factions);
    // TODO
    this.setState({ names });
    const simstate = presets[instantiated.ty]();

    // 우리팀
    const entities = agents.map((agent) => {
      const { firearm, equipment, util } = agent;

      const stats = perk_apply_stats(agent.stats, agent.perks);

      const entity = entityFromStat(stats, firearm);
      perk_apply_entity(entity, agent.perks);

      entity.life = agent.life;
      entity.life_max = 100;
      entity.allow_fire_control = true;
      entity.allow_cover_edge = true;

      entity.armor = equipment.vest_armor;
      entity.armor_max = equipment.vest_armor;
      entity.armor_hit_prob = equipment.vest_hit_prob;
      entity.idx = agent.idx;

      entity.spawnarea = agent.spawnarea;

      if (util.throwable) {
        entity.throwables = [util.throwable];
      }

      if (instantiated.rescues.length > 0) {
        entity.default_rule = 'mission';
      } else {
        entity.default_rule = 'explore';
      }
      return entity;
    });

    // TODO: 반복 미션인 경우에만
    if (!mission.milestone) {
      // 적 에이전트 생성
      simstate.entities = entities.slice();

      let i = 1;
      for (const level of instantiated.threat_levels) {
        const stats = stats_const(THREAT_STATS[level]);

        let firearm = tmpl_firearm_smg_low;
        if (level > 0) {
          firearm = tmpl_firearm_ar_low;
        }

        stats.name = rng.choice(names);
        const entity = entityFromStat(stats, firearm);
        if (mission.ty === 'maze') {
          entity.spawnarea = i;
          i++;
          if (i === simstate.spawnareas.length) {
            i = 1;
          }
        } else {
          entity.spawnarea = 1;
        }
        entity.team = 1;
        entity.default_rule = { ty: 'idle', alert: false };
        entity.allow_crawl = false;

        entity.vis_color = THREAT_COLORS[level];

        simstate.entities.push(entity);
      }
    } else {
      const enemy_entities = simstate.entities.filter((e) => e.team > 0);
      if (enemy_entities.length > instantiated.threat_levels.length) {
        throw new Error("enemy_entities.length > instantiated.threat_levels.length");
      }

      simstate.entities = entities.slice();

      for (let i = 0; i < instantiated.threat_levels.length; i++) {
        const level = instantiated.threat_levels[i];
        const stats = stats_const(THREAT_STATS[level]);

        let entity = null;
        if (i < enemy_entities.length) {
          // 기본 entity. 위협으로 증가되지 않은 친구들입니다.
          entity = { ...enemy_entities[i] };
        } else {
          // 원한 값에 따라 새로 추가되는 entity입니다.
          entity = { ...(rng.choice(enemy_entities)) };
        }

        updateEntityStat(entity, stats);
        entity.vis_color = THREAT_COLORS[level];

        simstate.entities.push(entity);
      }
      // TODO: 추가적인 위협량에 따라, entity를 무작위로 선택해 복사해서 넣을 것
    }

    if (!mission.milestone) {
      for (const stats of instantiated.rescues) {
        const entity = entityFromStat(stats, tmpl_firearm_hostage);
        entity.spawnarea = 1;
        entity.team = 2;
        entity.default_rule = 'idle';
        entity.ty = 'vip';

        simstate.entities.push(entity);
      }
    }

    return {
      instantiated,
      mission,
      agents,
      simstate,
    };
  }

  onAgentEtcStart(props) {
    const { agent, cost, ty } = props;
    const resources = { ...this.state.resources };
    resources.resource -= cost.resource;
    if (resources.resource < 0) return;
    const journals = this.pushJournal(`${agent.name}(이)가 ${ty}을 시작합니다. $${cost.resource}를 지급합니다. ${cost.time}일 후  ${ty}이 끝납니다.`);

    let tick = this.state.tick + cost.time;

    const agents_selected = this.state.agents_selected.filter((a) => agent !== a);
    agent.spawnarea = 0;

    const pendings = this.state.pendings.slice();
    pendings.push({
      ty,
      tick,
      agent,
    });

    pendings.sort((a, b) => a.tick - b.tick);

    this.setState({
      resources,
      pendings,
      agents: this.state.agents.filter((a) => {
        return agent !== a;
      }),
      journals,
      agents_selected
    });
  }

  onMissionQueue(props) {
    const { mission, agents, cost } = props;

    const mission_state = this.buildMissionState(props);

    // use resources
    const resources = { ...this.state.resources };
    resources.resource -= cost.resource;

    const journals = this.pushJournal(`${agents.length}명의 인원이 임무를 출발합니다. 수당 $${cost.resource}를 지급합니다. ${cost.time}일 후 임무를 시작합니다.`);

    let tick = this.state.tick + cost.time;
    // eslint-disable-next-line
    while (this.state.pendings.find((p) => p.ty === 'mission' && p.tick === tick)) {
      tick += 1;
    }

    const pendings = this.state.pendings.slice();
    pendings.push({
      ty: 'mission',
      tick,
      mission_state,
    });

    pendings.sort((a, b) => a.tick - b.tick);

    this.resetMissionConfig();
    this.setState({
      resources,
      pendings,

      missions: this.state.missions.filter((m) => m !== mission),
      milestone_missions: this.state.milestone_missions.filter((m) => m !== mission),
      agents: this.state.agents.filter((a) => {
        return !agents.includes(a);
      }),
      journals
    });
  }

  resetMissionConfig() {
    this.setState({
      mission_selected: null,
      agents_selected: [],
    });
  }

  onMissionDryrun(props) {
    const mission_state = this.buildMissionState(props);
    this.setState({
      mission_state: {
        ...mission_state,
        dryrun: true,
      },
    });
  }

  onMissionComplete(obj) {
    const { rng } = this.state;
    const { mission_state } = obj;
    const { sim, mission, instantiated } = mission_state;
    const { trails } = sim;

    let agent_idx = this.state.agent_idx;
    const resources = { ...this.state.resources };
    const market_listings = this.state.market_listings.slice();

    const killsAll = trails.filter((t) => t.source.team === 0 && t.kill);

    const agents = this.state.agents.slice();
    const dead = this.state.dead.slice();
    let journals = this.state.journals.slice();
    for (const agent of mission_state.agents) {
      const { idx, name, mission_stats } = agent;

      const entity = sim.entities.find((e) => e.idx === idx);
      if (!entity) {
        agents.push(agent);
        continue;
      }
      agent.life = entity.life;
      if (agent.life === 0) {
        journals = this.pushJournal(`${name}이(가) 사망했습니다`, this.state.tick, journals);
        dead.push(agent);
        continue;
      }

      mission_stats.count += 1;
      mission_stats.kills += killsAll.filter((t) => t.source === entity).length;

      mission_stats.hits_done += trails.filter((t) => t.source === entity && t.hit).length;
      mission_stats.hits_taken += trails.filter((t) => t.target === entity && t.hit).length;

      agents.push(agent);
    }

    for (const stats of instantiated.rescues) {
      // TODO: idx
      const entity = sim.entities.find((e) => e.name === stats.name);
      // TODO: milestone mission의 경우, entity에 해당하는 agents가 없을 수 있습니다.
      if (!entity || entity.team !== 0 || entity.life <= 0) {
        continue;
      }

      this.pushJournal(`${entity.name}이(가) 합류합니다`);
      const agent = createAgent(rng, stats.name, stats, agent_idx++);
      agent.life = entity.life;

      market_listings.push({
        ty: 'agent',
        cost: rng.integer(500, 1500),
        stats,
      });
    }

    if (mission_state.res === 0) {
      resources.resource += instantiated.resource;
    }

    let progress = this.state.progress;
    if (mission.milestone) {
      progress += 1;
      journals = this.pushJournal(`마일스톤 미션을 클리어합니다`, this.state.tick, journals);
    }

    this.setState({
      agent_idx,
      agents: _.sortBy(agents, (a) => a.idx).filter((a) => a.life > 0),
      resources,
      mission_state: null,
      mission_selected: null,
      agents_selected: [],
      market_listings,
      progress,
      journals,
      dead,
    });
  }

  missionSpawnAreas() {
    const mission = this.state.mission_selected;
    if (!mission) {
      return [];
    }
    const areas = presets[mission.ty]().spawnareas;
    //앞에서 부터 spawnarea가 된다는 가정하...
    return areas.filter((area) => area.spawn);
  }

  onSpawnChange(agent) {
    const areas = this.missionSpawnAreas();

    agent.spawnarea = (agent.spawnarea + 1) % areas.length;
    this.setState({});
  }

  onAgentToggle(agent) {
    let agents_selected = this.state.agents_selected.slice();
    if (agents_selected.includes(agent)) {
      agents_selected = agents_selected.filter((a) => a !== agent);
    } else {
      agents_selected.push(agent);
    }
    this.setState({ agents_selected });
  }

  onAgentEquip(agent, item) {
    const agents = this.state.agents.slice();
    const inventories = this.state.inventories.slice().filter((e) => e !== item);

    const { ty, cost } = item;
    if (ty === 'firearm') {
      // convert current firearm into inventory item
      inventories.push({ ty: 'firearm', cost, firearm: agent.firearm });
      agent.firearm = item.firearm;
      this.setState({ agents, inventories });
    } else if (ty === 'equipment') {
      // convert current firearm into inventory item
      inventories.push({ ty: 'equipment', cost, equipment: agent.equipment });
      agent.equipment = item.equipment;
      this.setState({ agents, inventories });
    } else if (ty === 'util') {
      inventories.push(agent.util);
      agent.util = item;
      this.setState({ agents, inventories });
    } else {
      throw new Error(`unimplmented ty=${ty}`);
    }
  }

  onAgentAcquirePerk(agent) {
    const { rng } = this.state;

    const hints = agent.hints;
    const perk = rng.choice(hints);
    agent.perks.push(perk);
    agent.hints = [];

    this.setState({ agents: this.state.agents.slice() });
  }

  onTrainFinish(agent) {
    const { training_state, rng } = this.state;

    const ran = [0, 0, 0, 0, 0, 1, 1, 1, 1, 2];

    const perkgroup0 = 'perk_stat_firearm_level_0 perk_stat_firearm_level_1 perk_stat_firearm_level_2 perk_stat_firearm_level_3 perk_stat_firearm_level_4';
    const perkgroup1 = 'perk_firstshoot_hit perk_firstshoot_amp perk_lastshoot_hit perk_lastshoot_amp perk_unidir_sense perk_cover_dash perk_aimtarget_incr_aimvar perk_hit_incr_aimvar perk_damp_aimvar_incr';
    const perkgroup2 = 'perk_instant_reload perk_kill_recover perk_shoot_ignore_obstructed perk_reload_one_more';
    const perkgroups = [perkgroup0.split(' '), perkgroup1.split(' '), perkgroup2.split(' ')];

    let hint_key = null;
    while (true) {
      const perkgroup = rng.choice(ran);
      hint_key = rng.choice(perkgroups[perkgroup]);
      if (!agent.perks.includes(hint_key)) {
        break;
      }
    }

    agent.hints.push(hint_key);

    training_state.push({ agent, hint_key });

    this.setState({
      training_state,
      agents: this.state.agents.slice()
    });
  }

  onHealFinish(agent) {
    const { heal_state } = this.state;

    agent.life += 10;
    agent.life = Math.min(100, agent.life);

    heal_state.push(agent);

    this.setState({
      heal_state,
      agents: this.state.agents.slice()
    });
  }

  onEtcComplete() {
    const agents = this.state.agents.slice();
    const { training_state, heal_state } = this.state;
    let journals = this.state.journals;
    for (const { agent } of training_state) {
      journals = this.pushJournal(`${agent.name} 훈련이 끝났습니다.`, this.state.tick, journals);
      agents.push(agent);
    }
    for (const agent of heal_state) {
      journals = this.pushJournal(`${agent.name} 치료가 끝났습니다.`, this.state.tick, journals);
      agents.push(agent);
    }

    this.setState({
      agents: _.sortBy(agents, (a) => a.idx),
      training_state: [],
      heal_state: [],
      journals
    });
  }

  onFinish(res) {
    const { mission_state, rng, factions } = this.state;
    const sim = this.simRef.current.state.sim;

    if (mission_state.dryrun) {
      this.setState({ mission_state: null });
      return;
    }

    const life_changes = [];
    for (const agent of mission_state.agents) {
      const entity = sim.entities.find((e) => e.idx === agent.idx);
      if (!entity) {
        continue;
      }

      life_changes.push({
        agent,
        life_before: agent.life,
        life_after: entity.life,
      });

    }

    const stat_changes = [];
    const { agents } = mission_state;

    // 성장. 매 사살마다 stat을 획득합니다.
    const killsAll = sim.trails.filter((t) => t.source.team === 0 && t.kill);
    for (const trail of killsAll) {
      const entity = trail.source;
      const agent = agents.find((e) => e.idx === entity.idx);

      let stat_key = null;
      let count = 0;
      while (stat_key === null) {
        // max tries
        if (count > 100) {
          break;
        }
        count += 1;

        stat_key = rng.choice(Object.keys(STAT_DESCR));
        if (stat_key.indexOf('stat_aimvar') !== 0) {
          stat_key = null;
          continue;
        }

        const range = STAT_RANGE[stat_key];

        if (agent.stats[stat_key] >= range[1]) {
          stat_key = null;
          continue;
        }

        const stat_before = agent.stats[stat_key];
        const stat_after = stat_before + 1;
        agent.stats[stat_key] = stat_after;

        stat_changes.push({
          agent,
          stat_key,
          stat_before,
          stat_after,
        });
      }
    }

    // 화기 숙련
    for (const agent of agents) {
      const entity = sim.entities.find((e) => e.idx === agent.idx);
      if (!entity) {
        continue;
      }

      const firearm_ty = entity.firearm_ty;
      const ty_idx = FIREARM_CATS.indexOf(firearm_ty);

      const level = agent.stats.stat_firearm_level[ty_idx];
      if (level >= 20) {
        continue;
      }

      let prob = 1.0;
      if (level > 10) {
        prob = (level - 10) * 0.066;
      }
      const firearm_level_up = rng.range(0, 1) < prob;

      if (firearm_level_up) {
        stat_changes.push({
          agent,
          stat_key: 'stat_firearm_level',
          firearm_ty: firearm_ty,
          stat_before: level,
          stat_after: level + 1,
        });
        agent.stats.stat_firearm_level[ty_idx] += 1;
      }
    }

    // 퍽 힌트
    const hint_changes_before_trim = [];
    let hint_changes = [];
    if (mission_state.mission.perkgroup) {
      for (const agent of agents) {
        function sampleHint() {
          const group = mission_state.mission.perkgroup;
          const hint_key = rng.choice(group.keys);

          if (!agent.perks.includes(hint_key)) {
            return hint_key;
          }

          const all_keys = Object.keys(perks);
          while (true) {
            const hint_key = rng.choice(all_keys);
            if (!agent.perks.includes(hint_key)) {
              return hint_key;
            }
          }
        }

        function addHint(hint_key, cause) {
          hint_changes_before_trim.push({
            cause,
            agent,
            hint_key,
          });
        }

        addHint(sampleHint(), '임무 성공');

        if (killsAll.find((t) => t.source.idx === agent.idx)) {
          addHint(sampleHint(), '임무 기여');
        }
        if (!life_changes.find((e) => e.agent === agent)) {
          addHint(sampleHint(), '무사 귀환');
        }

      }
      // 퍽 힌트 획득 개수를 미션 내 킬 발생수로 제한합니다.
      hint_changes = _.shuffle(hint_changes_before_trim).slice(0, killsAll.length);
      for (const { agent, hint_key } of hint_changes) {
        agent.hints.push(hint_key);
      }
    }

    //원한, 호의
    let favor_changes = []
    let resentment_changes = [];
    for (const client of mission_state.mission.clients) {
      const favor_before = factions[client].favor;
      const favor_after = Math.min(favor_before + mission_state.instantiated.threats * FAVOR_INCREASE, 100);
      favor_changes.push({
        name: client,
        favor_before,
        favor_after,
      });
      factions[client].favor = favor_after;
    }
    for (const enemy of mission_state.mission.enemies) {
      const resentment_before = factions[enemy].resentment;
      const resentment_after = Math.min(resentment_before + mission_state.instantiated.threats * RESENTMENT_INCREASE, 100);
      resentment_changes.push({
        name: enemy,
        resentment_before,
        resentment_after,
      });
      factions[enemy].resentment = resentment_after;
    }

    this.setState({
      mission_state: {
        ...mission_state, sim, res,
        life_changes,
        stat_changes,
        hint_changes,
        favor_changes,
        resentment_changes,
      },
      factions,
    });
  }

  onInventorySell(item) {
    const resources = { ...this.state.resources };
    const inventories = this.state.inventories.filter((i) => i !== item);

    resources.resource += itemSellPrice(item);

    this.setState({
      resources,
      inventories
    });
  }

  onMarketListingPurchase(item, cost) {
    const resources = { ...this.state.resources };
    const inventories = this.state.inventories.slice();
    const market_listings = this.state.market_listings.filter((i) => i !== item);

    let { rng, agents, agent_idx } = this.state;

    if (resources.resource < cost) {
      return;
    }
    resources.resource -= cost;
    this.setState({ resources, market_listings });

    switch (item.ty) {
      case 'agent':
        const { stats } = item;
        agents = agents.slice();

        const agent = createAgent(rng, stats.name, stats, agent_idx++);
        agents.push(agent);
        this.setState({ agents, agent_idx });
        break;
      default:
        inventories.push(item);
        this.setState({ inventories });
        break;
    }
  }

  onMarketListingDiscard(item) {
    const market_listings = this.state.market_listings.filter((i) => i !== item);
    this.setState({ market_listings });
  }

  // cost만큼의 자원이 있는 경우, 자원을 차감하고 market_size를 업데이트합니다.
  onMarketStateUpdate(market_state, cost) {
    const resources = { ...this.state.resources };
    if (resources.resource < cost) {
      return;
    }
    resources.resource -= cost;

    this.setState({ resources, market_state });
  }

  canProgress() {
    const { mission_state, training_state } = this.state;
    if (training_state.length > 0) {
      return false;
    }
    if (mission_state !== null) {
      return false;
    }

    return true;
  }

  renderETC() {
    const { training_state, heal_state } = this.state;

    if (training_state.length === 0 && heal_state.length === 0) {
      return null;
    }

    return <div className="box">
      기타
      <div className="box">
        {training_state.map(({ agent, hint_key }, i) => {
          return <p key={i}>{agent.name}: 경험 {"<"}{perks[hint_key].name}{">"}를 획득합니다.</p>;
        })
        }
        {heal_state.map((agent, i) => {
          return <p key={i}>{agent.name}: 체력 10을 회복합니다.</p>;
        })
        }
      </div>

      <button onClick={() => this.onEtcComplete()}>complete</button>
    </div>;
  }

  renderMission() {
    const {
      mission_selected,
      agents_selected,
      mission_state,
      resources,
      tick,
      factions
    } = this.state;


    if (!mission_state) {
      let onSpawnChange = null;
      if (this.missionSpawnAreas().length > 1) {
        onSpawnChange = this.onSpawnChange.bind(this);
      }
      return <PlanView mission={mission_selected} agents={agents_selected} resources={resources} tick={tick}
        onMissionQueue={this.onMissionQueue.bind(this)}
        onMissionDryrun={this.onMissionDryrun.bind(this)}
        onReset={() => this.resetMissionConfig()}
        onSpawnChange={onSpawnChange}
        factions={factions}
      />;
    }

    if (mission_state.res === undefined) {
      return <SimView ref={this.simRef}
        onFinish={(res) => this.onFinish(res)}
        {...mission_state.simstate}
      />;
    }

    const { life_changes, stat_changes, hint_changes, favor_changes, resentment_changes } = mission_state;
    return <div className="box">
      res={mission_state.res === 0 ? 'win' : 'lose'}
      <br />
      reward=${mission_state.res === 0 ? mission_state.instantiated.resource : 0}
      <br />
      {mission_state.res === 0 ?
        <div className="box">
          팩션 변화
          {favor_changes.map(({ name, favor_before, favor_after }) => {
            return <p key={name}>호의: {name} - {favor_before} {"=>"} {favor_after}</p>;
          })}
          {resentment_changes.map(({ name, resentment_before, resentment_after }) => {
            return <p key={name}>원한: {name} - {resentment_before} {"=>"} {resentment_after}</p>;
          })}
        </div>

        : ``}
      <div className="box">
        체력 변화
        {life_changes
          .filter(({ life_before, life_after }) => life_before !== life_after)
          .map(({ agent, life_before, life_after }, i) => {
            return <p key={i}>{agent.name} {(life_before).toFixed(1)} {"=>"} {(life_after).toFixed(1)} {life_after === 0 ? "(사망)" : ""}</p>;
          })
        }
      </div>
      <div className="box">
        성장
        {stat_changes
          .filter(({ agent }) => agent.life > 0)
          .map((change, i) => {
            const { agent, stat_key, stat_before, stat_after } = change;
            if (stat_key === 'stat_firearm_level') {
              return <p key={i}>{agent.name}: {change.firearm_ty} 화기 숙련: {stat_before} {"=>"} {stat_after}</p>;
            } else {
              return <p key={i}>{agent.name}: {STAT_DESCR[stat_key]} {stat_before} {"=>"} {stat_after}</p>;
            }
          })
        }
        <br />
        {hint_changes
          .filter(({ agent }) => agent.life > 0)
          .map(({ agent, cause, hint_key }, i) => {
            const perk = perks[hint_key];
            return <p key={i}>{agent.name}: {cause}(으)로 {perk.name} 힌트를 획득합니다.</p>;
          })
        }
      </div>

      <button onClick={() => this.onMissionComplete({ mission_state })}>complete</button>
    </div>;
  }

  render() {
    const {
      mission_selected,
      agents,
      agents_selected,
      tick,
      resources,

      missions,
      milestone_missions,

      inventories,

      market_state,
      market_listings,

      journals,
      pendings,

      progress,
      dead,

      factions
    } = this.state;

    const action_disabled = !this.canProgress();

    const j = journals.slice();
    j.reverse();

    const max_wait = pendings[0].tick - tick;

    const new_intel_disable = resources.resource < COST_MISSION_NEW_INTEL;
    let new_intel_title;
    if (new_intel_disable) {
      new_intel_title = 'resource가 부족합니다.'
    }
    const new_intel_btn = <button disabled={new_intel_disable} title={new_intel_title} onClick={() => this.onMissionNewIntel()}>new intel (${COST_MISSION_NEW_INTEL})</button>;

    return <>
      {this.renderSystem()}

      <div className="box">
        <div>
          진행도={progress} elapsed={tick} days
          <br />

          <button disabled={action_disabled} onClick={() => this.onTick(1)}>wait 1 day</button>
          <button disabled={action_disabled} onClick={() => this.onTick(max_wait)}>wait {max_wait} days</button>
        </div>

        <p>resources</p>
        {Object.entries(resources).map(([k, v]) => <p key={k}>{k}: {v}</p>)}
      </div>

      <div className='box'>
        faction
        {Object.entries(factions).map(([name, { resentment, favor }]) => {
          return <p key={name}>{name} - 원한: {resentment}, 호의:{favor} </p>
        })}
      </div>

      <div className="box">
        upcomings
        {pendings.map((p, i) => {
          let title = '';
          if (p.ty === 'mission') {
            for (const agent of p.mission_state.agents) {
              title += agent.name + ', ';
            }
          } else if (p.ty === 'training' || p.ty === 'heal') {
            title += p.agent.name;
          }
          return <p title={title} key={i}>{p.tick - tick}일 후: {p.ty}</p>;
        })}
      </div>

      {this.renderMission()}
      {this.renderETC()}

      <div className="box">
        <p>agents</p>
        {agents.map((a, i) => <AgentItem key={i} agent={a}
          agents_selected={agents_selected} tick={tick}
          onAgentToggle={(a) => this.onAgentToggle(a)}
          onAgentEquip={(agent, item) => this.onAgentEquip(agent, item)}
          onAgentAcquirePerk={this.onAgentAcquirePerk.bind(this)}
          onAgentEtcStart={this.onAgentEtcStart.bind(this)}
          inventories={inventories}
          resource={resources.resource}
        />
        )}
      </div>

      <div className="box">
        <p>inventory </p>

        {inventories
          .filter((m) => itemRate(m) > 0)
          .map((m, i) => <MarketListingView key={i} item={m}
            onInventorySell={this.onInventorySell.bind(this)}
          />)}

        <MarketView
          progress={progress}
          resource={resources.resource}
          market_state={market_state}
          market_listings={market_listings}
          onMarketNewListing={this.onMarketNewListing.bind(this)}
          onMarketListingPurchase={this.onMarketListingPurchase.bind(this)}
          onMarketListingDiscard={this.onMarketListingDiscard.bind(this)}
          onMarketStateUpdate={this.onMarketStateUpdate.bind(this)}
        />
      </div>

      <div className="box">
        <p>missions {missions.length}/{MAX_MISSIONS}</p>
        {missions.map((m, i) =>
          <MissionItem mission={m} mission_selected={mission_selected} key={i} tick={tick}
            onMissionSelect={this.onMissionSelect.bind(this)} factions={factions} />)}
        {new_intel_btn}
        <br />
      </div>

      <div className="box">
        <p>milestone_missions: 만료 전에 완료해야 합니다.</p>
        {milestone_missions.slice(0, 1).map((m, i) =>
          <MissionItem mission={m} mission_selected={mission_selected} key={i} tick={tick}
            onMissionSelect={this.onMissionSelect.bind(this)} factions={factions} />)}
      </div>

      <div className="box">
        {/* 묘비명 써서 넣고 싶다 */}
        <p>죽은 자들의 무덤</p>
        {dead.map((agent, i) => {
          return <AgentItem key={i} agent={agent} tick={tick} readonly />
        })}
      </div>

      <div className="box">
        <p>journal</p>
        {j.map(({ tick: t, msg }, i) => <p key={i}>{tick - t}일 전: {msg}</p>)}
      </div>

    </>;
  }
}
