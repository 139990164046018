
import * as React from 'react';
import { titles } from './data/google/processor/data_maps.mjs';
import { Rng } from './rand.mjs';

import {
  negotiate_mission_objectives,
  negotiate_mission_locations,
  negotiate_mission_sizes,
  negotiate_mission_levels,
  negotiate_client_infos,
  negotiate_options,
  negotiate_texts,
} from './data_negotiate';

export const CLIENT_PREF_KEYS = ['importance', 'prospect', 'early', 'perfect', 'time', 'few'];



function HorizontalInfoView(props) {
  const { pairs } = props;
  let className = props.className ?? '';
  className += ' flex-horizontal-info';

  return <div className={className}>
    {
      pairs.map(({ key, value }, i) => {
        return <div key={i}>
          <div className="flex-horizontal-info-key">{key}</div>
          <div className="flex-horizontal-info-value">{value}</div>
        </div>;
      })
    }
  </div >;
}

function SuggestionOptionsView(props) {
  const { pairs } = props;
  return <div className={'flex-suggestion-options-list'}>
    {
      pairs.map(({ name, description }, i) => {
        return <div key={i} className='flex-suggestion-options-list-element'>{name} - {description}</div>
      })
    }
  </div>
}

function SuggestionOptionsViewWithButton(props) {
  const { pairs, onClick } = props;
  return <div className={'flex-suggestion-options-list'}>
    {
      pairs.map(({ name, description }, i) => {
        return <div key={i} className='flex-suggestion-options-list-element'>
          {name} - {description}
          <button onClick={() => { onClick(i) }}>-</button>
        </div>
      })
    }
  </div>
}

function SuggestionOptionsDropdown(props) {
  const { pairs, dropdown_id } = props;

  return <select name='options' id={dropdown_id}>
    {
      pairs.map(({ key, name, description }, i) => {
        return <option key={i} className="flex-suggest-options-dropdown-element" value={key}>{name} - {description}</option>
      })
    }
  </select>;
}

function GetEvaluationTexts(props) {
  const { missionInfo, clientInfo, suggestInfo_prev, suggestInfo_curr } = props;

  const seed = Rng.randomseed();
  const rng = new Rng(seed);

  const pref_importance = clientInfo.pref.importance.value;
  const pref_early = clientInfo.pref.early.value;

  const client_relation = clientInfo.relation.value;
  const client_personality = clientInfo.personality.value;
  let client_attitude = clientInfo.attitude.value;

  const score_condition_prev = scoreCondition({ missionInfo, clientInfo, suggestInfo: suggestInfo_prev });
  const score_condition_curr = scoreCondition({ missionInfo, clientInfo, suggestInfo: suggestInfo_curr });
  const score_submit = score_condition_curr - score_condition_prev;

  const texts = [];

  //수정 제안 점수와 점수 상승 제한값과 비교
  const max_limit_score = 17 * ((pref_importance + pref_early) * 0.2 + 1);
  const min_limit_score = 13 * ((pref_importance + pref_early) * 0.2 + 1);
  if (score_submit < 0) {
    texts.push(randomText(rng, 'eval_submit_bad'));
  }
  else if (min_limit_score > score_submit) {
    texts.push(randomText(rng, 'eval_submit_normal'));
  }
  else if ((max_limit_score + min_limit_score) / 2 > score_submit) {
    texts.push(randomText(rng, 'eval_submit_good'));
  }
  else {
    texts.push(randomText(rng, 'eval_submit_verygood'));
  }

  const score_reaction = 100 * (1 - score_submit / min_limit_score) + pref_importance * 20 + pref_early * 20 + client_relation * 20 + client_personality * 40 + client_attitude * 10;
  if (score_reaction >= 0) {
    texts.push(randomText(rng, 'eval_reaction_good'));
  }
  else if (score_reaction >= -100) {
    texts.push(randomText(rng, 'eval_reaction_bad'));
  }
  else {
    texts.push(randomText(rng, 'eval_reaction_verybad'));
  }

  return texts;
}

function CalculateSuggestResult(props) {
  const { missionInfo, clientInfo, suggestInfo_prev, suggestInfo_curr } = props;

  const seed = Rng.randomseed();
  const rng = new Rng(seed);

  const base_reward = missionInfo.base_reward;
  const base_deadline = missionInfo.base_deadline;
  const curr_reward = suggestInfo_curr.reward;
  const curr_deadline = suggestInfo_curr.deadline;

  const is_option_perfect = suggestInfo_curr.options.find(element => element.key === 'option_perfect') ? 1 : 0;
  const is_option_time = suggestInfo_curr.options.find(element => element.key === 'option_time') ? 1 : 0;
  const is_option_few = suggestInfo_curr.options.find(element => element.key === 'option_few') ? 1 : 0;

  const pref_importance = clientInfo.pref.importance.value;
  const pref_early = clientInfo.pref.early.value;

  const client_relation = clientInfo.relation.value;
  const client_personality = clientInfo.personality.value;
  let client_attitude = clientInfo.attitude.value;
  let client_patience = clientInfo.patience.value;

  const score_condition_prev = scoreCondition({ missionInfo, clientInfo, suggestInfo: suggestInfo_prev });
  const score_condition_curr = scoreCondition({ missionInfo, clientInfo, suggestInfo: suggestInfo_curr });
  const score_submit = score_condition_curr - score_condition_prev;

  const texts = [];
  let accepted = true;

  //보상 상한 체크
  const constraint_reward = base_reward * (1.75 + 0.5 * pref_importance);
  const max_reward = curr_reward * (1 + 0.25 * is_option_perfect + 0.25 * is_option_time + 0.25 * is_option_few);
  if (max_reward > constraint_reward) {
    //fail
    texts.push(randomText(rng, 'client_reward'));
    accepted = false;
  }
  //성공기한 체크
  const constraint_deadline = base_deadline * (2 - pref_early);
  if (curr_deadline > constraint_deadline) {
    //fail
    texts.push(randomText(rng, 'client_deadline'));
    accepted = false;
  }

  //수정 제안 점수와 점수 상승 제한값과 비교
  const max_limit_score = 17 * ((pref_importance + pref_early) * 0.2 + 1);
  const min_limit_score = 13 * ((pref_importance + pref_early) * 0.2 + 1);
  const limit_score = rng.range(min_limit_score, max_limit_score);
  if (score_submit > limit_score) {
    //fail
    accepted = false;
  }

  if (accepted) {
    texts.push(randomText(rng, 'client_accept'));
    client_attitude = Math.min(client_attitude + 1, 2);
    client_patience = Math.max(client_patience - 1, 0);
  }
  else {
    client_attitude = Math.max(client_attitude - 1, -2);
    client_patience = Math.max(client_patience - 1, 0);
    let score_reaction = 100 * (1 - score_submit / min_limit_score) + pref_importance * 20 + pref_early * 20 + client_relation * 20 + client_personality * 40 + client_attitude * 10;
    if (score_reaction >= 0) {
      texts.push(randomText(rng, 'client_refusal_close'));
    }
    else if (score_reaction >= -100) {
      texts.push(randomText(rng, 'client_refusal_toomuch'));
      if (rng.range(0, 100) < -score_reaction) {
        client_patience = Math.max(client_patience - 1, 0);
      }
    }
    else {
      texts.push(randomText(rng, 'client_refusal_mad'));
      while (score_reaction < -100) {
        score_reaction += 100;
        client_patience = Math.max(client_patience - 1, 0);
      }
      if (rng.range(0, 100) < -score_reaction) {
        client_patience = Math.max(client_patience - 1, 0);
      }
    }
  }
  //수용시 태도 +1, 인내심 - 1
  //거절시 태도 -1, 인내심 - 1
  //이후 반응 점수를 계산하여 반응 결정
  const random_hint_key = rng.choice(CLIENT_PREF_KEYS);
  const pref_hint = clientInfo.pref[random_hint_key].value;
  const random_log_key = `client_hint_${random_hint_key}_${pref_hint}`;
  texts.push(randomText(rng, random_log_key));

  const suggestLog = new SuggestLog({ suggestInfo: suggestInfo_curr, logs: texts, accepted: accepted })
  return { log: suggestLog, new_attitude: client_attitude, new_patience: client_patience, hint: random_hint_key };
}

function scoreCondition(props) {
  const { missionInfo, clientInfo, suggestInfo } = props;

  const base_reward = missionInfo.base_reward;
  const base_deadline = missionInfo.base_deadline;
  const curr_reward = suggestInfo.reward;
  const curr_deadline = suggestInfo.deadline;

  const is_option_advance = suggestInfo.options.find(element => element.key === 'option_advance') ? 1 : 0;
  const is_option_penalty = suggestInfo.options.find(element => element.key === 'option_penalty') ? 1 : 0;
  const is_option_perfect = suggestInfo.options.find(element => element.key === 'option_perfect') ? 1 : 0;
  const is_option_time = suggestInfo.options.find(element => element.key === 'option_time') ? 1 : 0;
  const is_option_few = suggestInfo.options.find(element => element.key === 'option_few') ? 1 : 0;

  const pref_importance = clientInfo.pref.importance.value;
  const pref_prospect = clientInfo.pref.prospect.value;
  const pref_early = clientInfo.pref.early.value;
  const pref_perfect = clientInfo.pref.perfect.value;
  const pref_time = clientInfo.pref.time.value;
  const pref_few = clientInfo.pref.few.value;

  return curr_reward / base_reward * 100 * (is_option_advance * (0.25 + (pref_importance - pref_prospect) * 0.1) + 1) * (is_option_penalty * (-0.2 + (pref_prospect - pref_importance) * 0.1) + 1) * (is_option_perfect * (0.15 - pref_perfect * 0.1) + 1) * (is_option_time * (0.15 - pref_time * 0.1) + 1) * (is_option_few * (0.15 - pref_few * 0.1) + 1) + (curr_deadline / base_deadline - 1) * 70 * (pref_early * 0.5 + 1);
}

function randomText(rng, key) {
  const texts = negotiate_texts.filter((e) => e.key.includes(key));
  return rng.choice(texts).text;
}

export class MissionInfo {
  constructor() {
    const seed = Rng.randomseed();
    const rng = new Rng(seed);

    const objective = rng.weighted_key(negotiate_mission_objectives, 'weight');

    //TODO: 실제 규칙에 따라 랜덤 맵 선정하기
    const map = rng.choice(['indoor', 'embassy_short', 'outdoor']);
    const locations = negotiate_mission_locations.filter(({ maps }) => maps.includes(map));
    const location = rng.choice(locations);

    //TODO: 실제 규칙에 따라 적 규모 선정하기
    const enemy_count = rng.integer(1, 50);
    const sizes = negotiate_mission_sizes.filter(({ min, max }) => enemy_count >= min && enemy_count <= max);
    const size = rng.choice(sizes);

    //TODO: 실제 규칙에 따라 적 수준 선정하기
    const enemy_level = rng.integer(1, 6);
    const levels = negotiate_mission_levels.filter(({ min, max }) => enemy_level >= min && enemy_level <= max);
    const level = rng.choice(levels);

    const title = rng.choice(titles);

    this.title = title;
    this.objective = objective;
    this.location = location;
    this.size = size;
    this.level = level;
    this.base_reward = 1000;
    this.base_deadline = 21;
  }
}

export class ClientInfo {
  constructor() {
    const seed = Rng.randomseed();
    const rng = new Rng(seed);

    const patience = rng.weighted_key(negotiate_client_infos['patience'], 'weight');
    const relation = rng.weighted_key(negotiate_client_infos['relation'], 'weight');
    const personality = rng.weighted_key(negotiate_client_infos['personality'], 'weight');
    const attitude = rng.weighted_key(negotiate_client_infos['attitude'], 'weight');

    const pref = {};
    for (const key of CLIENT_PREF_KEYS) {
      pref[key] = rng.weighted_key(negotiate_client_infos[key], 'weight');
    }

    //TODO: 용병 작명 시스템처럼 무작위로 이름 생성해서 넣어주기
    this.name = 'Big Boss';
    this.patience = patience;
    this.relation = relation;
    this.personality = personality;
    this.attitude = attitude;

    this.pref = pref;
    this.pref_known = [];
  }
}

export class SuggestLog {
  constructor(props) {
    const { suggestInfo, logs, accepted } = props;
    this.suggestInfo = suggestInfo;
    this.logs = logs;
    this.accepted = accepted;
  }
}

export class SuggestInfo {
  constructor() {

    this.reward = 1000;
    this.deadline = 21;
    this.options = [];
  }
}

function Mission(props) {
  const { missionInfo, clientInfo } = props;

  const pairs_missionInfo = [
    { key: <span title={`TODO: 핵심 목표`}>Main Goal</span>, value: missionInfo.objective.name },
    { key: <span title={`TODO: 위치`}>Location</span>, value: missionInfo.location.name },
    { key: <span title={`TODO: 규모`}>Enemy Size</span>, value: missionInfo.size.name },
    { key: <span title={`TODO: 수준`}>Enemy Level</span>, value: missionInfo.level.name },
  ];

  const pairs_clientInfo = [
    { key: <span title={`TODO: 이름`}>Name</span>, value: clientInfo.name },
    { key: <span title={`TODO: 관계`}>Relationship</span>, value: clientInfo.relation.description },
    { key: <span title={`TODO: 성격`}>Personality</span>, value: clientInfo.personality.description },
    { key: <span title={`TODO: 태도`}>Attitude</span>, value: clientInfo.attitude.description },
  ];

  return <div className='flex-negotiation'>
    <p className='flex-title'>Negotiation</p>
    <div className='flex-mission'>
      <div className='flex-mission-title'>Mission Information</div>
      <div className='flex-missionitem'>
        <div className='flex-missionitem-title'>{missionInfo.title}</div>
        <HorizontalInfoView pairs={pairs_missionInfo} className='flex-missionitem-info' />
        <div className="flex-missionitem-footer">
          <div className="flex-missionitem-renown">
            <div className="flex-missionitem-footer-key">Reputation</div>
            <div className="flex-missionitem-footer-value">-23</div>
          </div>
          <div className="flex-missionitem-reward">
            <div className="flex-missionitem-footer-key">Reward</div>
            <div className="flex-missionitem-footer-value">+${missionInfo.base_reward}</div>
          </div>
        </div>
      </div>
    </div>
    <div className='flex-client'>
      <div className='flex-client-title'>Client Information</div>
      <div className='flex-client-info'>
        <HorizontalInfoView pairs={pairs_clientInfo} className='flex-missionitem-Info' />
        <div className='flex-client-patience-key'>
          <span title={`TODO: 인내심`}>Patience</span>
        </div>
        <div className='flex-client-patience-value'>{clientInfo.patience.description}</div>
        <div className='flex-client-pref-key'>
          <span title={`TODO: 성향`}>Pref</span>
        </div>
        <HorizontalInfoView pairs={clientInfo.pref_known} className='flex-missionitem-Info' />
      </div>
    </div>
  </div>
}

export class Negotiation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options_add: [],
      options_remove: [],
      reward_diff: 0,
      deadline_diff: 0,
      evaluations: [],
    };
  }

  render() {
    const { missionInfo, clientInfo, suggestInfo, onSuggestChanged } = this.props;
    const { options_add, options_remove, reward_diff, deadline_diff, evaluations } = this.state;

    return <div className='flex-negotiation'>
      <div className='flex-suggestion'>
        <p className='flex-title'>3rd Round</p>
        <div className="flex-suggestion-reward"> {`Reward: +$${suggestInfo.reward}`}</div>
        <div className='flex-suggestion-deadline'>{`Deadline: ${suggestInfo.deadline} days`}</div>
        <div className='flex-suggestion-options'>
          <div className='flex-suggestion-options-title'>Options</div>
          <SuggestionOptionsView pairs={suggestInfo.options} />
          <button onClick={() => {

          }}>협상 포기</button>
          <button onClick={() => {

          }}>조건 수락</button>
        </div>
        <div className='flex-suggestion-modify'>
          <div className='flex-suggestion-modify-title'>조건 수정 제안</div>
          <div className='flex-suggestion-modify-reward'>
            보상 변경: {reward_diff >= 0 ? `+$${reward_diff}` : `-$${-reward_diff}`}
            <button onClick={() => {
              const diff = reward_diff + suggestInfo.reward / 10;
              this.updateState({ options_add, options_remove, reward_diff: diff, deadline_diff });
            }}>+</button>
            <button onClick={() => {
              const diff = reward_diff - suggestInfo.reward / 10;
              this.updateState({ options_add, options_remove, reward_diff: diff, deadline_diff });
            }}>-</button>
          </div>
          <div className='flex-suggestion-modify-deadline'>
            성공기한 변경: {`${deadline_diff} days`}
            <button onClick={() => {
              const diff = deadline_diff + 3;
              this.updateState({ options_add, options_remove, reward_diff, deadline_diff: diff });
            }}>+</button>
            <button onClick={() => {
              const diff = deadline_diff - 3;
              this.updateState({ options_add, options_remove, reward_diff, deadline_diff: diff });
            }}>-</button>
          </div>
          <div className='flex-suggestion-modify-add-option'>
            옵션 추가
            <SuggestionOptionsDropdown pairs={negotiate_options.filter(
              ({ key }) =>
                !suggestInfo.options.find(element => element.key === key)
                && !options_add.find(element => element.key === key)
            )} dropdown_id='opt-add' />
            <button onClick={() => {
              const opt = document.getElementById('opt-add');
              const option = negotiate_options.find(element => element.key === opt.value);
              if (option) {
                options_add.push(option);
              }
              this.updateState({ options_add, options_remove, reward_diff, deadline_diff });
            }}>+</button>
          </div>
          <SuggestionOptionsViewWithButton pairs={options_add} onClick={(index) => {
            options_add.splice(index, 1);
            this.updateState({ options_add, options_remove, reward_diff, deadline_diff });
          }} />
          <div className='flex-suggestion-modify-remove-option'>
            옵션 삭제
            <SuggestionOptionsDropdown pairs={suggestInfo.options.filter(
              ({ key }) => !options_remove.find(element => element.key === key)
            )} dropdown_id='opt-remove' />
            <button onClick={() => {
              const opt = document.getElementById('opt-remove');
              const option = negotiate_options.find(element => element.key === opt.value);
              if (option) {
                options_remove.push(option);
              }
              this.updateState({ options_add, options_remove, reward_diff, deadline_diff });
            }}>+</button>
            <SuggestionOptionsViewWithButton pairs={options_remove} onClick={(index) => {
              options_remove.splice(index, 1);
              this.updateState({ options_add, options_remove, reward_diff, deadline_diff });
            }} />
          </div>
          <button onClick={() => {
            const suggestInfo_curr = this.getSuggestInfo_Curr({ options_add, options_remove, reward_diff, deadline_diff });
            const result = CalculateSuggestResult({ missionInfo, clientInfo, suggestInfo_prev: suggestInfo, suggestInfo_curr });
            onSuggestChanged(result);
            this.setState({ options_add: [], options_remove: [], reward_diff: 0, deadline_diff: 0, evaluations: [] });
          }}>조건 수정</button>
          <div className="flex-suggestion-evaluation">
            <div className={'flex-suggestion-evaluation-list'}>
              {
                evaluations.map((text, i) => {
                  return <div key={i} className='flex-suggestion-evaluation-list-element'>- {text}</div>
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  }

  getSuggestInfo_Curr(props) {
    const { suggestInfo } = this.props;
    const { options_add, options_remove, reward_diff, deadline_diff } = props;

    const suggestInfo_curr = new SuggestInfo();
    suggestInfo_curr.reward = suggestInfo.reward + reward_diff;
    suggestInfo_curr.deadline = suggestInfo.deadline + deadline_diff;
    suggestInfo_curr.options = suggestInfo.options;
    const newOptions = suggestInfo_curr.options.concat(options_add);
    for (const obj of options_remove) {
      const index = newOptions.findIndex((e) => e.key === obj.key);
      if (index >= 0) {
        newOptions.splice(index, 1);
      }
    }
    suggestInfo_curr.options = newOptions;

    return suggestInfo_curr;
  }

  updateState(props) {
    const { missionInfo, clientInfo, suggestInfo } = this.props;
    const { options_add, options_remove, reward_diff, deadline_diff } = props;

    const suggestInfo_curr = this.getSuggestInfo_Curr(props);
    const evaluations = GetEvaluationTexts({ missionInfo, clientInfo, suggestInfo_prev: suggestInfo, suggestInfo_curr });
    this.setState({
      options_add: options_add,
      options_remove: options_remove,
      reward_diff: reward_diff,
      deadline_diff: deadline_diff,
      evaluations: evaluations,
    });
  }
}

export class SuggestLogView extends React.Component {
  render() {
    const { suggestLogs } = this.props;

    return <div className='flex-suggestLogs'>
      <div className='flex-suggestion'>
        <p className='flex-title'>Log</p>
        <div className='flex-suggestion-options-list'>
          {
            suggestLogs.map(({ suggestInfo, logs, accepted }, i) => {
              return <SuggestLogElement key={i} index={i} suggestInfo={suggestInfo} logs={logs} accepted={accepted} />
            })
          }
        </div>
      </div>
    </div>
  }
}

function SuggestLogElement(props) {
  const { index, suggestInfo, logs, accepted } = props;
  let result_log = null;
  if (accepted) {
    result_log = <>
      <div className="flex-suggestion-reward"> Reward: +${suggestInfo.reward}</div>
      <div className='flex-suggestion-deadline'> Deadline: {suggestInfo.deadline} days</div>
      <div className='flex-suggestion-options'>
        <div className='flex-suggestion-options-title'>Options</div>
        <SuggestionOptionsView pairs={suggestInfo.options} />
      </div></>
  }

  return <div key={index} className='flex-suggestion-options-list-element'>
    suggest {index + 1}: {accepted ? 'success' : 'fail'}
    <div className='flex-log-element'>
      {
        logs.map((text, i) => {
          return <div key={i}>
            - {text}
          </div>
        })
      }
    </div>
    <div className='flex-suggestion-log'>
      {result_log}
    </div>
    ----------------------------------------------
  </div>
}

export class NegotiateView extends React.Component {
  constructor() {
    super();

    const missionInfo = new MissionInfo();
    const clientInfo = new ClientInfo();
    const suggestInfo = new SuggestInfo();
    const suggestLogs = [];

    this.state = { missionInfo, clientInfo, suggestInfo, suggestLogs };
  }

  onSuggestChanged(result) {
    const { clientInfo, suggestLogs } = this.state;
    const { log, new_attitude, new_patience, hint } = result;
    suggestLogs.push(log)

    clientInfo.attitude = negotiate_client_infos.attitude.find(e => e.value === new_attitude);
    clientInfo.patience = negotiate_client_infos.patience.find(e => e.value === new_patience);
    if (!clientInfo.pref_known.find(e => e.key === hint)) {
      clientInfo.pref_known.push({ key: hint, value: clientInfo.pref[hint].description })
    }
    if (log.accepted) {
      this.setState({ clientInfo, suggestInfo: log.suggestInfo, suggestLogs });
    }
    else {
      this.setState({ clientInfo, suggestLogs });
    }
  }

  render() {
    const { missionInfo, clientInfo, suggestInfo, suggestLogs } = this.state;
    return <>
      <Mission missionInfo={missionInfo} clientInfo={clientInfo} />
      <Negotiation missionInfo={missionInfo} clientInfo={clientInfo} suggestInfo={suggestInfo} onSuggestChanged={this.onSuggestChanged.bind(this)} />
      <SuggestLogView suggestLogs={suggestLogs} />
    </>
  }
}
