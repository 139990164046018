import { Component, createRef } from 'react';
import { Rng } from './rand.mjs';

import './PortraitsView.css';

export const PORTRAIT_PRESET_ZOOM = 'translate(0,+10%) scale(1.5) rotate(0deg)';

const mandatories = [
  '100_body',
  '350_head',
];

const files = [
  /*
"/portrait/001_bgi/bgi_gray.png",
"/portrait/001_bgi/bgi_orange.png",
  */
  "/portrait/050_back/back_acc_buttstock_001.png",
  "/portrait/050_back/blank_000.png",
  "/portrait/050_back/blank_001.png",
  "/portrait/050_back/blank_002.png",
  "/portrait/050_back/blank_003.png",
  "/portrait/050_back/blank_004.png",
  "/portrait/100_body/body_001.png",
  "/portrait/100_body/body_002.png",
  "/portrait/100_body/body_003.png",
  "/portrait/100_body/body_004.png",
  "/portrait/100_body/DarkCirclesFaceBody.png",
  "/portrait/100_body/body-Brad-Pitt.png",
  "/portrait/150_top/top_shirt_001.png",
  "/portrait/150_top/top_shirt_002.png",
  "/portrait/150_top/top_shirt_003.png",
  "/portrait/150_top/top_shirt_004.png",
  "/portrait/150_top/top_tanktop_001.png",
  "/portrait/150_top/BlueTankTop.png",
  "/portrait/150_top/White-collar-top.png",
  "/portrait/200_coat/coat_001.png",
  "/portrait/200_coat/coat_002.png",
  "/portrait/200_coat/coat_003.png",
  "/portrait/200_coat/brown-stripes-coat.png",
  "/portrait/250_vest/vest_001.png",
  "/portrait/250_vest/vest_002.png",
  "/portrait/250_vest/vest_003.png",
  "/portrait/250_vest/green-pocket-vest.png",
  "/portrait/300_belt/belt_001.png",
  "/portrait/300_belt/belt_002.png",
  "/portrait/300_belt/blank_000.png",
  "/portrait/300_belt/blank_001.png",
  "/portrait/300_belt/blank_002.png",
  "/portrait/300_belt/blank_003.png",
  "/portrait/300_belt/blank_004.png",
  "/portrait/300_belt/BlackBagStrap.png",
  "/portrait/300_belt/GreenBeltVest.png",
  "/portrait/330_scarf/blank_000.png",
  "/portrait/330_scarf/blank_001.png",
  "/portrait/330_scarf/blank_002.png",
  "/portrait/330_scarf/blank_003.png",
  "/portrait/330_scarf/blank_004.png",
  "/portrait/330_scarf/scarf_001.png",
  "/portrait/330_scarf/scarf_002.png",
  "/portrait/330_scarf/DogTag.png",
  "/portrait/330_scarf/green-military-scarf.png",
  "/portrait/350_head/head_001.png",
  "/portrait/350_head/head_002.png",
  "/portrait/350_head/head_003.png",
  "/portrait/350_head/head_004.png",
  "/portrait/350_head/DarkCirclesFace.png",
  "/portrait/350_head/head-Brad-Pitt.png",
  "/portrait/400_scar/blank_000.png",
  "/portrait/400_scar/blank_001.png",
  "/portrait/400_scar/blank_002.png",
  "/portrait/400_scar/blank_003.png",
  "/portrait/400_scar/blank_004.png",
  "/portrait/400_scar/face_scar_001.png",
  "/portrait/400_scar/face_scar_002.png",
  "/portrait/400_scar/under-the-eyes-scar.png",
  "/portrait/450_beard/beard_001.png",
  "/portrait/450_beard/beard_002.png",
  "/portrait/450_beard/beard_003.png",
  "/portrait/450_beard/beard_004.png",
  "/portrait/450_beard/blank_000.png",
  "/portrait/450_beard/blank_001.png",
  "/portrait/450_beard/blank_002.png",
  "/portrait/450_beard/blank_003.png",
  "/portrait/450_beard/blank_004.png",
  "/portrait/450_beard/just-beard.png",
  "/portrait/450_beard/Roundbeard.png",
  "/portrait/500_mustache/blank_000.png",
  "/portrait/500_mustache/blank_001.png",
  "/portrait/500_mustache/blank_002.png",
  "/portrait/500_mustache/blank_003.png",
  "/portrait/500_mustache/blank_004.png",
  "/portrait/500_mustache/mustache_001.png",
  "/portrait/550_eyebrow/blank_000.png",
  "/portrait/550_eyebrow/blank_001.png",
  "/portrait/550_eyebrow/blank_002.png",
  "/portrait/550_eyebrow/blank_003.png",
  "/portrait/550_eyebrow/blank_004.png",
  "/portrait/550_eyebrow/eyebrow_001.png",
  "/portrait/600_glasses/blank_000.png",
  "/portrait/600_glasses/blank_001.png",
  "/portrait/600_glasses/blank_002.png",
  "/portrait/600_glasses/blank_003.png",
  "/portrait/600_glasses/blank_004.png",
  "/portrait/600_glasses/eye_patch_001.png",
  "/portrait/600_glasses/glasses_001.png",
  "/portrait/600_glasses/glasses_002.png",
  "/portrait/600_glasses/glasses_003.png",
  "/portrait/600_glasses/glasses_004.png",
  "/portrait/600_glasses/metal-bold-glasses.png",
  "/portrait/650_bandana/bandana_001.png",
  "/portrait/650_bandana/bandana_002.png",
  "/portrait/650_bandana/blank_000.png",
  "/portrait/650_bandana/blank_001.png",
  "/portrait/650_bandana/blank_002.png",
  "/portrait/650_bandana/blank_003.png",
  "/portrait/650_bandana/blank_004.png",
  "/portrait/700_hair_cap/blank_000.png",
  "/portrait/700_hair_cap/blank_001.png",
  "/portrait/700_hair_cap/cap_001.png",
  "/portrait/700_hair_cap/hair_001.png",
  "/portrait/700_hair_cap/hair_002.png",
  "/portrait/700_hair_cap/hair_003.png",
  "/portrait/700_hair_cap/hair_004.png",
  "/portrait/700_hair_cap/CurlyLongHair.png",
  "/portrait/700_hair_cap/gray-messy-hair.png",
  "/portrait/700_hair_cap/red-ball-cap.png",
  "/portrait/750_mask/blank_000.png",
  "/portrait/750_mask/blank_001.png",
  "/portrait/750_mask/blank_002.png",
  "/portrait/750_mask/blank_003.png",
  "/portrait/750_mask/blank_004.png",
  "/portrait/750_mask/gas_mask_001.png",
  "/portrait/750_mask/GreenMask.png",
  "/portrait/750_mask/cigarette.png",
];

const CATS = {};
for (const file of files) {
  const [, , cat, item] = file.split('/');
  if (!CATS[cat]) {
    CATS[cat] = [];
  }
  CATS[cat].push(item);
}


function generatePortrait(rng, cats) {
  const cur = {};
  for (const cat of Object.keys(cats)) {
    const candidates = cats[cat].slice();
    if (!mandatories.includes(cat)) {
      candidates.push(null);
    }
    cur[cat] = rng.choice(candidates);
  }
  return cur;
}


export function PortraitView(props) {
  const { preset } = props;

  let cur = props.cur;
  if (!cur) {
    const rng = new Rng(props.idx);
    cur = generatePortrait(rng, CATS);
  }

  const layers = [];
  for (const [cat, item] of Object.entries(cur)) {
    if (item === null) {
      continue;
    }
    layers.push(<img alt="" className='portrait-part' key={cat} src={`/portrait/${cat}/${item}`} style={{ transform: preset }} />);
  }

  let cls = 'portrait-frame';
  if (props.className) {
    cls += ' ' + props.className;
  }

  return <div className={cls}>
    {layers}
  </div>;
}

export class PortraitsView extends Component {
  constructor(props) {
    super(props);

    const rng = new Rng();
    const cur = generatePortrait(rng, CATS);

    this.inputRef = createRef();

    this.state = {
      cats: CATS,
      rng,
      cur,

      preset: 'translate(0,+10%) scale(1.5) rotate(90deg)',
    };
  }

  componentDidMount() {
    this.onSave(this.state.cur);
  }

  onSave(cur) {
    this.inputRef.current.value = JSON.stringify(cur);
  }

  onLoad() {
    const cur = JSON.parse(this.inputRef.current.value);
    this.setState({ cur });
  }

  render() {
    const { cur, rng, cats } = this.state;

    function catButtons(cat) {
      const candidates = cats[cat].slice();
      if (!mandatories.includes(cat)) {
        candidates.push(null);
      }
      return <div>
        {cat}=
        {candidates.map((item) => {
          const name = item === null ? '(none)' : item.split('.')[0];

          const cls = (cur[cat] === item) ? 'over' : '';

          return <button className={cls} key={item} onClick={() => {
            const next = { ...cur };
            next[cat] = item;
            this.setState({ cur: next });
          }}>{name}</button>;
        })}
      </div>;
    }

    return <div className="portraitsview">
      <div>
        <span>프리셋: </span>
        <input className="portraitsview-preset-input" value={this.state.preset} onChange={(e) => this.setState({ preset: e.target.value })} />
      </div>
      <PortraitView cur={cur} preset={this.state.preset} />
      <PortraitView cur={cur} className='portrait-frame-small0' preset={this.state.preset} />
      <PortraitView cur={cur} className='portrait-frame-small1' preset={this.state.preset} />
      <div>
        <input type="text" ref={this.inputRef} />
        <button onClick={() => this.onSave()}>save</button>
        <button onClick={() => this.onLoad()}>load</button>
      </div>
      <button onClick={() => {
        const cur = generatePortrait(rng, cats);
        this.setState({ cur });
        this.onSave(cur);
      }}>Random</button>
      {Object.keys(cats).map(catButtons.bind(this))}
    </div>;
  }
}
