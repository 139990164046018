import dayjs from 'dayjs';
import { contractoverall } from './data/google/processor/data_contractoverall.mjs';

import { TICK_PER_MONTH } from './tick.mjs';
import { L } from './localization.mjs';

export const CONTRACT_TY = {
  'Standard': 'loc_ui_string_agent_contract_type_standard',
  'Incentive': 'loc_ui_string_agent_contract_type_incentive',
};

export const CONTRACT_OPTIONS = [
  { key: 'long', name: 'loc_ui_string_agent_contract_option_long_term', desc: 'loc_ui_longtext_agent_contract_option_long_term' },
  { key: 'advanced', name: 'loc_ui_string_agent_contract_option_medical_priority', desc: `loc_ui_longtext_agent_contract_option_medical_priority` },
  { key: 'danger', name: 'loc_ui_string_agent_contract_option_hazard_pay', desc: 'loc_ui_longtext_agent_contract_option_hazard_pay' },
];



// aiden-2: 임금 체계: PS
const SHARE_RATIO_DEFAULT = 0.05;
// aiden-28: 엘리트 PS
const SHARE_RATIO_ELITE = 0.075;

// aiden-3: 인센티브 계약 기본급이 표준 계약 기본급의 몇 배인지
const TURN_COST_RATIO_INCENTIVE = 0.4;
// aiden-3: 파견 수당이 표준 계약 기본급의 몇 배인지
const MISSION_COST_RATIO = 0.4;

/// CONTRACT
export function contractNew(rng, turn, term) {
  const option = {};
  for (const opt of CONTRACT_OPTIONS) {
    option[opt.key] = false;
  }

  const ty = rng.choice(Object.keys(CONTRACT_TY));

  return {
    ty,
    cost: 0,
    turnCost: 0,

    missionCost: 0,
    shareRatio: SHARE_RATIO_DEFAULT,

    agenda: null,
    option,
    cnt: 0,
    start: turn,
    contractAt: turn,

    term: 0,
    term_next: term,

    // 1회성 계약 금액 증감
    renew_mult: 1,

    // 계약 기간동안의 손익. cashbook과 겹치나??
    balance: 0,

    mission: 0,
    dispatch: 0,
  };
}

export function contractDetails0(contract, turn) {
  return {
    ty: contract.ty,
    cost: contract.cost,
    turn_cost: contract.turnCost,
    mission_cost: contract.missionCost,
    profit_share_rate: `${(contract.shareRatio * 100).toFixed(1)}%`,
    remaining: L('loc_dynamic_string_agent_contract_expiration', { count: Math.ceil((contract.start + contract.term - turn) / 24) }),
    options: Object.entries(contract.option).map(([key, value]) => {
      const option = CONTRACT_OPTIONS.find((o) => o.key === key) ?? {};
      return { key, value, ...option };
    }),
  };
}

export function contractDetails(contract, turn, prev_contract) {
  const details = contractDetails0(contract, turn);
  let prev_details = null;
  if (prev_contract) {
    prev_details = contractDetails0(prev_contract, turn);
  }

  let base_pay = L('loc_dynamic_string_agent_contract_monthly_pay', { value: details.turn_cost });
  let deployment_pay = `$${details.mission_cost}`;
  let ps_rate = details.profit_share_rate;
  if (prev_contract) {
    base_pay = `${L('loc_dynamic_string_agent_contract_monthly_pay', { value: prev_details.turn_cost })} → ${L('loc_dynamic_string_agent_contract_monthly_pay', { value: details.turn_cost })}`;
    deployment_pay = `$${prev_details.mission_cost} → $${details.mission_cost}`;
    ps_rate = `${prev_details.profit_share_rate} → ${details.profit_share_rate}`;
  }

  return [
    [L('loc_ui_string_agent_contract_type'), L(CONTRACT_TY[details.ty])],
    // [`Market Value`, details.cost],
    [L('loc_ui_string_agent_contract_base_pay'), base_pay],
    [L('loc_ui_string_agent_contract_deployment_pay'), deployment_pay],
    [L('loc_ui_string_agent_contract_profit_sharing_rate'), ps_rate],
    [L('loc_ui_string_agent_contract_option'), `${details.options.filter((o) => o.value).map((o) => L(o.name))}`],
    [L('loc_ui_string_agent_contract_remaining'), `${details.remaining}`]
  ];
}

export function terminations(contract, turn) {
  const contractRemainMonth = Math.floor((contract.term + contract.start - turn + TICK_PER_MONTH - 1) / TICK_PER_MONTH);
  const terminationCost = contractRemainMonth * contract.turnCost / 2;
  const terminationLabel = `비용=$${terminationCost.toFixed(1)}
    잔여 계약 기간=${contractRemainMonth}
    월비용=$${contract.turnCost}`;
  return { terminationCost, terminationLabel };
}

// 기본 계약기간 28일로 조정
export const AGENT_CONTRACT_TICKS = 6 * TICK_PER_MONTH;

export const AGENT_CONTRACT_COST = (agent) => {
  const { power, cost_mult } = agent;
  const perks_len = agent.perks.list.length;

  const is_incentive = agent.contract?.ty === 'Incentive';

  const base0 = power * 160;

  let base = base0 + (perks_len * 200) + 720;
  if (is_incentive) {
    // aiden-79
    base = base0 + (perks_len * 200) + 180;
  }

  if (agent.contract && agent.contract.cnt > 0) {
    // aiden-79
    if (is_incentive) {
      base += agent.contract.balance / (agent.contract.term / TICK_PER_MONTH) * 2;
    }

    base = base * cost_mult * agent.contract.renew_mult;
  }

  return Math.round(base);
}

export function agentContractCost(agent, option, global_modifier, modifiers) {
  const { agenda, cnt } = agent.contract;
  global_modifier = global_modifier || [];

  let newCost = AGENT_CONTRACT_COST(agent);
  let installment = Math.round(newCost);
  let turn0 = AGENT_CONTRACT_TICKS;

  if (option.long) {
    if (global_modifier.find((m) => m.key === 'mod_global_8_contract_faithful')) {
      installment = Math.round(installment * 1.1);
    } else {
      installment = Math.round(installment * 1.5);
    }
    turn0 = AGENT_CONTRACT_TICKS * 1.5;
  }

  if (agenda === 'mod_agent_13_agenda_strong' && !option.advanced) {
    installment = Math.round(installment * 1.4);
  }
  if (agenda === 'mod_agent_14_agenda_weak') {
    installment = Math.round(installment * 0.83);
  }
  if (agenda === 'mod_agent_19_agenda_complicated' && option.long) {
    installment = Math.round(installment * 0.66);
  }

  if (cnt === 0 && global_modifier.find((m) => m.key === 'mod_global_3_promising')) {
    installment *= 0.5;
  }
  if (global_modifier.find((m) => m.key === 'mod_global_1_boom')) {
    installment *= 1.5;
  }
  if (global_modifier.find((m) => m.key === 'mod_global_2_recession')) {
    installment *= 0.5;
  }
  if (global_modifier.find((m) => m.key === 'mod_global_8_contract_faithful')) {
    installment *= 1.2;
  }
  if (cnt === 0 && global_modifier.find((m) => m.key === 'mod_global_9_contract_decieve')) {
    installment *= 0.5;
  }
  //chae-새로운 용병에게만 적용해야하는데...... 일단 이렇게 넣기
  if (cnt === 0 && global_modifier.find((m) => m.key === 'mod_global_10_high_manpower')) {
    installment *= 1.2;
  }
  if (cnt === 0 && global_modifier.find((m) => m.key === 'mod_global_11_platform_labor')) {
    installment *= 0.8;
    turn0 = Math.round(turn0 * 2 / 3);
  }

  if (modifiers) {
    for (const [key, value] of Object.entries(modifiers)) {
      if (key === 'discount') {
        installment *= (1 - value);
      }
    }
  }
  installment *= agent.contract_mult_overall;

  installment = Math.round(installment / (turn0 / TICK_PER_MONTH));
  return installment;
}


export function contractToString(contract, turn) {
  const details = contractDetails(contract, turn);
  return details.map(([key, val]) => `${key}: ${val}`).join('\n');
}

export function contractCalculateCost(contract, agent, option, global_modifier) {
  const cost = agentContractCost(agent, option, global_modifier);

  // base cost
  contract.cost = cost;
  if (contract.ty === 'Standard') {
    contract.turnCost = cost;
    contract.missionCost = 0;
    contract.shareRatio = 0;
  } else if (contract.ty === 'Incentive') {
    contract.turnCost = Math.round(cost * TURN_COST_RATIO_INCENTIVE);
    contract.missionCost = Math.round(cost * MISSION_COST_RATIO);
    contract.shareRatio = SHARE_RATIO_DEFAULT;
    if (agent.power >= 17) {
      contract.shareRatio = SHARE_RATIO_ELITE;
    }
    contract.shareRatio = Math.round(contract.shareRatio * agent.contract_mult_overall * 1000) / 1000;
  }
  //1회성 계약 금액 증감 초기화
  contract.renew_mult = 1;

  if (option.long) {
    const term = contract.term * 1.5;
    contract.remainTurn = term;
    contract.term = term;
  }

  if (contract.cnt === 0 && global_modifier.find((m) => m.key === 'mod_global_9_contract_decieve')) {
    contract.agenda = 'mod_agent_13_agenda_strong';
  }

  if (contract.cnt === 0 && global_modifier.find((m) => m.key === 'mod_global_11_platform_labor')) {
    const term = (contract.term === 0 ? AGENT_CONTRACT_TICKS : contract.term) * 2 / 3;
    contract.term_next = Math.round(term);
  }
}

export function contractRenew(turn, agent, option, global_modifier) {
  const contract = {
    ...agent.contract,
    option,
    cnt: agent.contract.cnt + 1,
    start: turn,
    term: agent.contract.term_next ?? AGENT_CONTRACT_TICKS,
    mission: 0,
    balance: 0,
    dispatch: 0,
    agenda: null,
  }
  delete contract.term_next;

  contractCalculateCost(contract, agent, option, global_modifier);
  return contract;
}

export function contractOverallMult(overall, rng) {
  for (let i = 0; i < contractoverall.length; i++) {
    const { overall: overall0, mult_min, mult_max } = contractoverall[i];
    if (overall <= overall0) {
      return rng.range(mult_min, mult_max);
    }
  }
  const last = contractoverall[contractoverall.length - 1];
  return rng.range(last.mult_min, last.mult_max);
}
