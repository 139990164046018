import React from "react";

import './vitedist/comp1/style.css';
import { App as FigComponents } from './vitedist/comp1/vite-react-ts.mjs';

import { Rng } from './rand.mjs';
import { createAgent } from './character.mjs';
import { names3 } from './names';
import { perks2 } from "./data/google/processor/data_perks2.mjs";

import { convertAgents } from './figAdapter';
import { AgentPerkWrapper } from "./component/animawrapper/AgentPerkWrapper";
import { AgentContractWrapper } from "./component/animawrapper/AgentContractWrapper";
import { ContractInProgress } from "./component/anima/src/components/ContractInProgress";
import { CipoCell } from "./component/anima/src/components/CipoCell";
import { TestCompWrap } from "./component/anima/src/components/TestCompWrap/TestCompWrap";
import { SwapDummy } from "./component/anima/src/components/SwapDummy";
import { SwapDummer } from "./component/anima/src/components/SwapDummer";

const SEED = undefined;


function createAgents() {
  const rng = new Rng(SEED);
  const len = 15;
  const agents = [];

  const names = names3.slice();
  rng.shuffle(names);

  if (names.length < len) {
    throw new Error('need more names');
  }

  for (let i = 0; i < len; i++) {
    const turn = i * 47;
    const agent = createAgent(rng, names[i], i, { global_modifier: [], turn });
    agent.contract.option['long'] = rng.choice([true, false]);
    agent.contract.option['advanced'] = rng.choice([true, false]);
    agent.contract.option['danger'] = rng.choice([true, false]);
    agent.level.cur += i * 2;
    agents.push(agent);
  }
  return agents;
};


export function FigComponentsView(props) {
  const agents = props.agents ?? createAgents();

  const agent_a = agents[0];
  const agent_b = agents[2];
  {
    const rng = new Rng(SEED);
    const super_perks = perks2.filter((p) => rng.next() > 0.5).map((p) => p.key);
    agent_b.perks.list = super_perks;
    agent_b.perks.point = 123;
    agent_b.perks.point_total = 456;
  }

  const agent_empty_perks = agents[1];
  agent_empty_perks.perks.list = [];
  agent_empty_perks.traits = [];
  agent_empty_perks.background_perk_group.perk_group = 'common';

  const agents_selected = [agents[1], agents[2], agents[5], agents[6]];

  const agents0 = convertAgents(agents, agents_selected);

  return (<div>
    <h1>ContractInProgressOverlay Cell</h1>
    <CipoCell
      ptAgentHpCap="/333"
      ptAgentHpVal="222"
      ptAgentStaminaVal="222"
      ptAgentStaminaCap="/333"
      ptAgentStaminaRegen="11.11"
      ptAgentStaminaIdx="3"
      ptAgentName="FOO"
      ptFirearmTy="SG"
      override={<div style={{ width: 59, height: 64, background: 'magenta' }}>portrait</div>}
    />
    <h1>ContractInProgressOverlay</h1>
    <div style={{ border: '1px solid red', height: 600 }}>
      <div style={{ border: '1px solid blue', position: 'relative', top: 200, left: 20 }}>
        <ContractInProgress
          ptCustomer="고객님"
          ptEnemyCount="22"
          ptMapName="맵이름"
          ptMoney="666"
          ptRemainNumber="33"
          ptRemainUnit="secs"
          ptRenown="333"
          ptThreatLevel="2"
          override={
            <>
              <CipoCell />
              <CipoCell />
              <CipoCell />
              <CipoCell />
              <CipoCell />
              <CipoCell />
              <CipoCell />
              <CipoCell />
              <CipoCell />
              <CipoCell />
              <CipoCell />
              <CipoCell />
            </>

          }
        />
      </div>
    </div>
    <h1>agent A</h1>
    <AgentContractWrapper agent={agent_a} turn={123} />
    <hr />
    <h1>agent A</h1>
    <AgentPerkWrapper agent={agent_a} />
    <hr />
    <h1>agent B</h1>
    <AgentPerkWrapper agent={agent_b} />
    <hr />
    <h1>agent 깡통</h1>
    <AgentPerkWrapper agent={agent_empty_perks} />
    <hr />
    <FigComponents agents={agents0} />
    <hr />
    <h1>TestCompWrap</h1>
    <style>
      {`
        /* 마지막 열의 margin-right 없애기; */
        /* 6n의 6을 손으로 구해야 합니다 */
        .test-comp-wrap .inner>*:nth-child(6n) {
          margin-right: 0;
        }
        /* 마지막 행의 margin-bottom 없애기는 답이 없다 */
      `}
    </style>
    <TestCompWrap
      override={<>
        <SwapDummy />
        <SwapDummer />
        <SwapDummy />
        <SwapDummer />
        <SwapDummy />
        <SwapDummer />
        <SwapDummy />
        <SwapDummer />
        <SwapDummy />
        <SwapDummer />
        <SwapDummy />
        <SwapDummer />
        <SwapDummy />
        <SwapDummer />
        <SwapDummy />
        <SwapDummer />
        <SwapDummy />
        <SwapDummer />
        <SwapDummy />
        <SwapDummer />
        <SwapDummy />
        <SwapDummer />
        <SwapDummy />
        <SwapDummer />
        <SwapDummy />
        <SwapDummer />
      </>}
    />
  </div>);
}
