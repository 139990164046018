import React from 'react';

import { Rng } from './rand.mjs';
import { instantiateMissionThreats, threatsSummary } from './mission.mjs';

export class ThreatsView extends React.Component {
  constructor(props) {
    super(props);

    const rng = new Rng(0);

    this.state = {
      rng,

      training: 1,
      count: 5,
      samples: 10,
    };
  }

  render() {
    const { rng, training, count, samples } = this.state;

    const list = [];
    for (let i = 0; i < samples; i++) {
      list.push(instantiateMissionThreats(rng, { threats: count, training }));
    }

    return <>
      <h1>missions</h1>
      training: <input type="number" value={training} onChange={(e) => {
        this.setState({ training: parseInt(e.target.value) });
      }}></input>
      <br />
      count: <input type="number" value={count} onChange={(e) => {
        this.setState({ count: parseInt(e.target.value) });
      }}></input>
      <br />
      samples: <input type="number" value={samples} onChange={(e) => {
        this.setState({ samples: parseInt(e.target.value) });
      }}></input>

      <>
        {list.map((threats, i) => {
          return <p key={i}>{threatsSummary(threats)}</p>;
        })}
      </>
    </>;
  }
}
