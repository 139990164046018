import './WorldView.css';
import _ from 'lodash';
import React from 'react';
import * as cytoscape from 'cytoscape';
import spread from 'cytoscape-spread';
import popper from 'cytoscape-popper';
import { Rng } from './rand.mjs';
import { v2 } from './v2.mjs';

cytoscape.use(spread);
cytoscape.use(popper);

function nodeData(idx, position) {
  return {
    id: `node${idx}`,
    position,
    level: 1,
    money: 0,
    production: 10,
    capacity: 100,
  };
}

function nodeLabel(n) {
  const ignored = ['position', 'team'];
  let s = '';
  for (const key of Object.keys(n)) {
    if (ignored.includes(key)) {
      continue;
    }
    s += `${key}=${n[key]}\n`;
  }
  return s;
}

export class WorldView extends React.Component {
  constructor(props) {
    super(props);

    this.keyDown = this.onKeyDown.bind(this);

    this.graphRef = React.createRef();
    this.popperRef = React.createRef();

    const WORLD_SIZE = 200;
    const SEP = WORLD_SIZE / 3;
    const COUNT = 20;

    const rng = new Rng(0);
    const nodes = [];
    const coords = [];
    while (nodes.length < COUNT) {
      const i = nodes.length;
      const x = rng.integer(-WORLD_SIZE, WORLD_SIZE);
      const y = rng.integer(-WORLD_SIZE, WORLD_SIZE);
      const p = new v2(x, y);
      if (nodes.find((n) => n.position.dist(p) < SEP)) {
        continue;
      }

      coords.push(x);
      coords.push(y);
      nodes.push(nodeData(i, p));
    }

    const d = props.m.Delaunay.from(coords);
    const conns = d.neighbors();
    d.free();

    const edges = [];
    for (let i = 0; i < conns.length / 2; i++) {
      const n0 = conns[i * 2];
      const n1 = conns[i * 2 + 1];

      edges.push({ source: `node${n0}`, target: `node${n1}` });
    }

    const teams_count = 4;
    const teams = [];
    for (let i = 0; i < teams_count; i++) {
      teams.push({
        id: i,
        resource: 0,
      });
    }
    const pending_nodes = nodes.slice();

    // random team assignment
    let team_idx = 0;
    while (pending_nodes.length > 0) {
      const node = pending_nodes.pop();
      node.team = 0;

      team_idx = (team_idx + 1) % teams_count;
    }

    const graph = { nodes, edges };

    this.state = {
      tick: 0,
      cy: null,
      teams,
      graph,

      nodeOver: null,
    };
  }

  initGraph() {
    const { nodes, edges } = this.state.graph;

    const container = this.graphRef.current;

    const colors = [
      '#eee',
      '#FD8A8A',
      '#F1F7B5',
      '#A8D1D1',
      '#9EA1D4',
    ];

    const elements = [
      ...nodes.map((n) => {
        return {
          data: {
            id: n.id,
            label: nodeLabel(n),
            team: n.team,
            color: colors[n.team],
          },
          position: n.position,
          locked: true,
          grabbable: false,
        };
      }),
      ...edges.map(({ source, target }) => {
        const id = `${source}-${target}`;
        return {
          data: {
            id,
            label: id,
            source,
            target,
            flow: 0,
          }
        };
      }),
    ];

    const cy = cytoscape({
      container,

      userZoomingEnabled: false,
      boxSelectionEnabled: false,
      autounselectify: true,

      style: [
        {
          selector: 'node',
          style: {
            'content': 'data(label)',
            'width': 12,
            'height': 12,
            'font-size': 6,
            'text-wrap': 'wrap',
            'background-color': 'data(color)'
          }
        },

        {
          selector: 'edge',
          style: {
            'content': 'data(label)',
            'font-size': 4,
            'width': 1,
            'line-color': '#ccc',
            'curve-style': 'bezier',
            'target-arrow-color': '#ccc',
            'target-arrow-shape': 'triangle',
          }
        },
      ],

      elements,
    });

    this.setState({ cy });
  }

  onPopup(node) {
    this.setState({ nodeOver: node });
    if (node === null) {
      return;
    }

    this.popup = node.popper({
      content: () => {
        return this.popperRef.current;
      },
      popper: {} // my popper options here
    });
  }

  componentDidMount() {
    document.addEventListener('keydown', this.keyDown);
    this.initGraph();
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyDown);
    const { cy } = this.state;
    if (cy) {
      cy.destroy();
    }
  }

  onKeyDown(ev) {
    if (ev.key === 's') {
      this.onTick();
    }
  }

  onTick() {
    const { cy, graph } = this.state;
    const tick = this.state.tick + 1;
    for (const n0 of cy.nodes()) {
      const n = graph.nodes.find((n) => n.id === n0.data('id'));
      n0.data('label', nodeLabel(n));
    }

    this.setState({ tick });
  }

  income(team) {
    const { cy } = this.state;
    if (!cy) {
      return 0;
    }

    return _.sum(
      cy.nodes()
        .filter((n) => n.data('team') === team)
        .map((n) => n.data('income')));
  }

  render() {
    const { tick, nodeOver } = this.state;
    let text = '';
    let textCls = '';
    if (nodeOver) {
      const data = nodeOver.data();
      text = JSON.stringify(data);
      textCls = 'popper-div';
    }

    const size = 1200;

    return <>
      tick={tick}
      <div ref={this.graphRef} style={{ width: size, height: size }}></div>
      <div ref={this.popperRef} className={textCls}>{text}</div>
    </>;
  }
}
