import _ from 'lodash';

import * as agent_training from './data/google/processor/data_training.mjs';

import { agentAvail, agentChangeApply, agentChangeCummulate, applyAgentStats2GaugeDelta, tickToAge, agentStats2TrainingInfo } from './character.mjs';
import { perks2 } from './data/google/processor/data_perks2.mjs';
import { firearm_tys } from './presets_firearm.mjs';
import { training2Bykey } from './data/google/processor/data_training2.mjs';
import { STATS2_DESCR } from './stats2.mjs';

export const AVAIL_RATIO = 1.0;
const TICK_PER_DAY = 24;
const TICK_PER_WEEK = TICK_PER_DAY * 7;
const TICK_PER_MONTH = TICK_PER_WEEK * 4;

export const DEBUG_Instructor_Duplicate_Allow = true;

export const perk2_keys = perks2.map(({ key }) => key);
export const firearm_ty_keys = Object.keys(firearm_tys);

export const SessionSlot_Length = 1;
export const TrainingSessionsTemplate = Array.from({ length: SessionSlot_Length }).map((_) => '');

export const AvailabilityDecay = -1;
export const AvailabilityOnTraining = -2;
export const InitialAvailability = 100;

export function cummulate(a, b, excludes = []) {
  a ??= {};
  const ret = { ...a };

  for (const key of Object.keys(b)) {
    if (excludes.includes(key) || b[key] === null) {
      continue;
    }

    const ty = typeof b[key];
    if (ty === 'number') {
      const val = a[key] ?? 0;
      ret[key] = val + b[key];
    } else if (ty === 'object') {
      const valA = a[key] ?? {};
      const valB = b[key];
      ret[key] = cummulate(valA, valB);
    } else if (ty === 'string') {
      if (a[key] === undefined) {
        ret[key] = b[key];
      } else {
        ret[key] = a[key] + ', ' + b[key];
      }
    } else {
      console.error(`unknown agent change key type: ${key}, ${typeof key}`);
    }
  }
  return ret;
}

export function generateSummary(trainingResults) {
  const summary = Object.values(_.groupBy(trainingResults, ({ agent }) => agent.idx))
    .map((results) => results.reduce((acc, { agent, result }) => {
      const cum = agentChangeCummulate(acc.result.cum, result.delta);
      const newResult = {
        ...acc.result,
        count: { ...acc.result.count },
        delta: cum,
        cum: cum,
      };
      switch (result.ty) {
        case 'stat':
          newResult.count.stat += 1;
          break;
        case 'perk':
          newResult.count.perk += 1;
          break;
        case 'aptitude':
          newResult.count.aptitude += 1;
          break;
        default:
          console.error(`unknown training result type: ${result.ty}`);
      }
      return {
        agent,
        result: newResult,
      };
    }, { agent: results[0].agent, result: { count: { stat: 0, perk: 0, aptitude: 0 }, delta: {}, cum: {} } }));
  return summary;
}

export function filterEffect(effect, ty, option) {
  return {
    all: effect?.all,
    [ty]: {
      all: effect?.[ty]?.all,
      [option]: effect?.[ty]?.[option],
    }
  };
}

export function trainInfo(agent, instructor, ty, option, level, effects) {
  const info = agentTrainAvail(agent, instructor, ty, option, level, 0);

  const effect = effects.reduce((acc, cur) => cummulate(acc, cur), {});
  info.data.train_mult += (
    (effect?.efficiency?.all) ?? 0.0 +
    (effect?.efficiency?.[ty]?.["all"] ?? 0.0) +
    (effect?.efficiency?.[ty]?.[option] ?? 0.0)
  );

  const duration = TICK_PER_WEEK * 2;

  info.duration = Math.floor(
    duration * (
      1 +
      (effects?.duration?.all ?? 0.0) +
      (effects?.duration?.[ty]?.["all"] ?? 0.0) +
      (effects?.duration?.[ty]?.[option] ?? 0.0)
    )
  );

  return info;
}

function agentTrainAvail(agent, instructor, ty, option, level, life_ratio) {
  const data = { ...agent_training.list.find(({ areaNum: n }) => n === level - 1) };

  if (agent === null) {
    return {
      avail: false,
      reason: 'agent-not-assigned',
      data,
    };
  }
  if (instructor === null) {
    return {
      avail: false,
      reason: 'instructor-not-assigned',
      data,
    };
  }

  if (agent.life / agent.life_max < life_ratio) {
    return {
      avail: false,
      reason: 'insufficient life',
      data,
    };
  }

  switch (ty) {
    case 'stat': {
      const reason = option.find((key) => !key) !== undefined ? 'session-not-assigned' : 'ok';
      return {
        cur: 0,
        cap: 0,
        avail: reason === 'ok',
        reason: reason,
        data,
      };
      // switch (option) {
      //   case 'overall': {
      //     const reason = agent.power < thres ?
      //       (agent.power < power_cap ? 'ok' : 'power-cap') : 'power-thres';
      //     return {
      //       cur: agent.power,
      //       cap: thres,
      //       avail: agent.power < thres && agent.power < power_cap,
      //       reason,
      //       data,
      //     };
      //   }
      //   case 'physical':
      //     const reason = agent.physical < thres ?
      //       (agent.physical < physical_cap ? 'ok' : 'physical-cap') : 'physical-thres';
      //     return {
      //       cur: agent.physical,
      //       cap: thres,
      //       avail: agent.physical < thres && agent.physical < physical_cap,
      //       reason: reason,
      //       data,
      //     };
      //   default: {
      //     const reason = agent.stats2[option] < thres ?
      //       (
      //         agent.power < power_cap ?
      //           'ok' :
      //           `power-cap`
      //       ) :
      //       `stat2-thres`;
      //     return {
      //       cur: agent.stats2[option],
      //       cap: thres,
      //       avail: agent.stats2[option] < thres && agent.power < power_cap,
      //       reason,
      //       data,
      //     };
      //   }
      // }
    }
    // ToDo: 어디에서도 쓰지 않은디...
    case 'mission':
      return {
        cur: 0,
        cap: 0,
        avail: true,
        reason: 'ok',
      };
    case 'perk': {
      const reason = agent.perks.point === 0 ? "perk-point" :
        option === null ? "perk-not-assigned" :
          // !canAgentAcquirePerk(agent, option) ? "agent-perk-not-avail" :
          agent.perks.list.includes(option) ? "agent-perk-already" :
            !instructor.perks.includes(option) ? "instructor-perk-not-avail" : "ok";
      return {
        cur: 0,
        cap: 0,
        avail: reason === "ok",
        reason,
        data,
      };
    }
    case 'aptitude': {
      const reason = agent.perks.point === 0 ? "perk-point" :
        agent.vocation.includes(option) ? "agent-aptitude-already" :
          !instructor.aptitudes.includes(option) ? "instructor-aptitude-not-avail" : "ok";
      return {
        cur: 0,
        cap: 0,
        avail: reason === "ok",
        reason,
        data,
      };
    }
    default:
      console.error(`unknown training type: ${ty}`);
  }
}

export function trainPending(agent) {
  let pending = "";
  if (agent.state === 'mission') {
    pending = "(Performing a request)";
  } else if (!agentAvail(agent, AVAIL_RATIO)) {
    pending = "(Recovering)";
  }
  return pending;
}

export function isAgentTrainable(agent) {
  if (agent.state !== null) {
    return false;
  } else if (!agentAvail(agent, AVAIL_RATIO)) {
    return false;
  }
  return true;
}

export function createSlot(idx, ty, level) {
  level = level ?? 0;
  const slot = { idx, level, ty, effects: [], training: null, automation: null, availability: InitialAvailability, availabilityPendings: [], lastInfo: { delta: 0, availabilities: [] } };
  return slot;
}

export function createInstructor(idx, name, perks, aptitudes) {
  const instructor = { idx, name, perks, aptitudes };
  return instructor;
}

export function slotTypeUpdate(slot, facilityType) {
  if (facilityType === "") return;

  if (facilityType.startsWith('training_advanced')) {
    slot.ty = "perk";
  } else if (facilityType.startsWith("training_firearm")) {
    slot.ty = "aptitude";
  } else {
    slot.ty = 'stat';
    slot.level = 0 | (facilityType.slice('training'.length) - 1);
  }
}

export function trainStart(tick, level, agent, instructor, ty, option, effects) {
  const info = trainInfo(agent, instructor, ty, option, level, effects);
  const { avail, reason } = info;

  if (!avail) {
    return { training: null, reason };
  }

  const training = {
    trainInfo: info,
    ty,
    option,
    expires_at: tick + info.duration,
    agentIdx: agent.idx,
    instructorIdx: instructor.idx,

    result: {
      count: {
        stat: 0,
        perk: 0,
        firearm: 0,
      }, ty, delta: null, cum: null
    },
  };

  agent.state = 'training';

  return { training, reason: null };
}

export function tickSlots(slots, tick, agents, instructors) {
  const events = [];
  for (const slot of slots) {
    const ev = tickSlot(slot, tick, agents, instructors);
    if (ev) {
      events.push(ev);
    }
    if (tick % TICK_PER_DAY === 0) {
      const agent = agents.find((a) => a.idx === slot.training?.agentIdx);
      const availAbilityDecay = getDecayAvailability(slot, agent);
      applyAvailability(slot, [availAbilityDecay]);
    }
    // 훈련이 종료되어 다음 훈련이 없을 때
    if (slot.training === null) {
      if (slot.automation !== null) {
        const instructor = instructors.find((a) => a.idx === slot.automation.instructorIdx) ?? null;
        refreshSlotAutomation(slot, tick, agents, instructor, slots, instructors);
      }
    }
  }
  return events;
}

export function agentStats2GaugeTrainInfo(training, level, availability, effects = []) {
  let facilityEfficiency = agent_training.list.find(({ areaNum: n }) => n === level - 1).train_mult;
  let effectEfficiency = 0;
  for (const effect of effects) {
    if (effect.efficiency) {
      effectEfficiency += effect.efficiency;
    }
  }
  let train_mult = facilityEfficiency + effectEfficiency;

  function createTemplate_detail() {
    return ({
      original: 0,
      sum: 0,
      beforeAvailability: 0,
      delta: 0,
      facility: 0,
      modifier: 0,
      modifiers: [],
    });
  }

  const mult = {
    availability,
    all: train_mult,
    facility: facilityEfficiency,
    effects,
  }

  const isValid = !!training && (training.ty === 'stat') && (training.option instanceof Array);

  const template = {
    summary: Object.fromEntries(Object.entries(STATS2_DESCR).map(([key,]) => ([key, 0]))),
    detail: Object.fromEntries(Object.entries(STATS2_DESCR).map(([key,]) => ([key, createTemplate_detail()]))),
    // TODO: nakwon ? ?? 지우기
    sessions: (isValid ? training.option : Array.from({ length: SessionSlot_Length }))
      .map(() => Object.fromEntries(Object.entries(STATS2_DESCR).map(([key,]) => ([key, createTemplate_detail()])))),
    mult
  };

  if (!isValid) {
    return template;
  }

  // TODO: nakwon 생성한 템플릿에 데이터 채워넣는걸로 ?? 같은거 다 지워버리기
  const sessions = training.option.map((key) => {
    const training2 = training2Bykey(key);
    if (!training2) {
      return null;
    }

    const mul_delta = {};
    for (const key of Object.keys(STATS2_DESCR)) {
      const original = training2[key];
      const sum = training2[key] * train_mult * availability / 100;

      const value = {
        original,
        sum,
        beforeAvailability: training2[key] * train_mult,
        delta: sum - original,
        facility: original * (facilityEfficiency - 1) * availability / 100,
        modifier: original * effectEfficiency * availability / 100,
        modifiers: effects.map((effect) => ({
          key: effect.key,
          efficiency: effect.efficiency,
          amount: original * effect.efficiency * availability / 100,
        })),
      };
      mul_delta[key] = value;
    }

    return mul_delta;
  });

  const detail = {};
  const summary = {};
  for (const key of Object.keys(STATS2_DESCR)) {
    const sessions0 = sessions.filter((s) => s !== null).map((s) => s[key]);
    const sum = _.sum(sessions0.map((m) => m.sum)) ?? 0;

    const modifiers = [];
    for (const session of sessions0) {
      for (const modifier of session.modifiers) {
        let found = modifiers.find(({ key }) => key === modifier.key)
        if (!found) {
          found = { key: modifier.key, efficiency: modifier.efficiency, amount: 0 };
          modifiers.push(found);
        }
        found.amount += modifier.amount;
      }
    }

    detail[key] = {
      original: _.sum(sessions0.map((m) => m.original)),
      sum,
      beforeAvailability: _.sum(sessions0.map((m) => m.beforeAvailability)),
      delta: _.sum(sessions0.map((m) => m.delta)),
      facility: _.sum(sessions0.map((m) => m.facility)),
      modifier: _.sum(sessions0.map((m) => m.modifier)),
      modifiers,
    };
    summary[key] = sum;
  }

  return {
    detail,
    summary,
    sessions,
    mult: {
      all: train_mult,
      facility: facilityEfficiency,
      effects,
      availability,
    }
  };
}

export function tickSlot(slot, tick, agents, instructors) {
  const { training } = slot;

  // TODO: nakwon: 하위호환, 관리도가 없으면 채워줍니다.
  if (slot.availability === undefined) {
    slot.availability = InitialAvailability;
  }

  if (training === null) {
    return null;
  }

  const agent = agents.find((a) => a.idx === training.agentIdx);

  // TODO: nakwon: 훈련은 스텟을 바로 바꾸지 않고, 게이지를 바꿉니다
  if (
    training.ty === 'stat' &&
    tick % TICK_PER_DAY === 0
  ) {
    const { summary: stats2GaugeTrainInfo } = agentStats2GaugeTrainInfo(training, slot.level, slot.availability, [...slot.effects]);
    applyAgentStats2GaugeDelta(agent, stats2GaugeTrainInfo, tickToAge(tick, agent.born_at));
  }

  if (training.expires_at === tick) {
    // slot.training = null;
    const { training: training_next, reason, result } = trainComplete(tick, slot, agents, instructors);
    slot.training = training_next;

    // 훈련이 종료된 경우
    return {
      slot,
      // training.result에 결과가 담겨 있습니다.
      training,
      reason,
      result,
    };
  }
  return null;
}

function agentAcquirePerk(agent, perk) {
  if (!perk) {
    return;
  }
  agent.perks.point -= 1;
  agent.perks.list.push(perk);
}

// training.result를 적절하게 설정합니다.
export function trainComplete(tick, slot, agents, instructors) {
  const { training } = slot;
  const agent = agents.find((a) => a.idx === training.agentIdx);
  const instructor = instructors.find((a) => a.idx === training.instructorIdx);
  agent.contract.dispatch++;
  agent.mission_stats.dispatches++;

  const { ty, option } = training;

  let args = {};

  let trainResult = null;

  // ToDo: agentChangeApply에 vocation과 perk을 추가하고, acquirePerk 제거하면?
  switch (ty) {
    case 'stat':
      {
        args = { agent };
        const result = { ...training.result };
        result.count.stat++;
        result.delta = args;
        result.cum = args;
        training.result = result;
        // args = agentGrowth(rng, agent, option, data.train_mult, cap);
        // agentChangeApply(agent, args, tick);
        // // ToDo: gentChangeCummulate에 vocation과 perk을 추가하기 전까지는 구조 분리
        // const result = { ...training.result };
        // result.delta = args;
        // result.cum = agentChangeCummulate(result.cum, args);
        // result.count.stat++;
        // training.result = result;
        // break;

        const stats2_deltaInfo = agentStats2TrainingInfo(agent, training);
        const stats2_delta = {};
        for (const key in stats2_deltaInfo.stats2) {
          stats2_delta[key] = stats2_deltaInfo.stats2[key].delta.sum;
        }
        const power_delta = stats2_deltaInfo.overall.delta.sum;
        trainResult = { agent, power: power_delta, stats2: stats2_delta };
      }
      break;
    case 'perk':
      {
        agentAcquirePerk(agent, option);
        args = { agent, perk: option };
        const result = { ...training.result };
        result.count.perk++;
        result.delta = args;
        result.cum = args;
        training.result = result;
        break;
      }
    case 'aptitude':
      {
        args = { agent, vocation: option };
        agentChangeApply(agent, args, tick);
        agent.perks.point -= 1;
        const result = { ...training.result };
        result.count.aptitude++;
        result.delta = args;
        result.cum = args;
        training.result = result;
        break;
      }
    default:
      console.error(`unknown training type: ${ty}`);
  }

  if (training.recurr && !training.recurrCancelled) {
    if (slot.automation !== null && (slot.automation.start_at + slot.automation.duration <= tick || slot.automation.cancelled)) {
      return { training: null, reason: 'automation end' };
    }

    // TODO
    const { training: next, reason } = trainStart(tick, slot.level, agent, instructor, ty, option, slot.effects);
    if (reason) {
      return { training: null, reason };
    }

    // set recurr data
    next.recurr = true;
    next.result = { ...training.result };
    next.recurrCancelled = false;

    return { training: next, reason: null, result: trainResult };
  } else {
    return { training: null, reason: 'oneshot', result: trainResult };
  }
}

export function slotCancel(slot) {
  const { training } = slot;
  slot.training = null;
  return {
    slot,
    training,
    reason: 'cancelled',
  };
}

export function refreshSlotAutomation(slot, tick, agents, instructor, slots, instructors) {
  if (slot.automation?.cancelled === true) {
    slot.automation = null;
  } else {
    setSlotAutomation(slot, tick, agents, instructor, slots, instructors, slot.automation.option);
  }
}

export function slotAutomationUpdate(slot, tick, agents, instructor, slots, instructors, isEnable, option) {
  if (isEnable) {
    setSlotAutomation(slot, tick, agents, instructor, slots, instructors, option);
  }
  else {
    if (slot.automation !== null) {
      slot.automation.cancelled = true;
    }
  }
}

export function setSlotAutomation(slot, tick, agents, instructor, slots, instructors, option) {
  const { training } = slot;

  if (slot.automation?.cancelled === true) {
    slot.automation.cancelled = false;
    slot.automation.option = option;
  } else {
    slot.automation = {
      start_at: tick,
      duration: TICK_PER_MONTH * 3,
      cancelled: false,
      instructorIdx: instructor?.idx,
      option,
    };
  }

  if (training === null) {

    if (instructor === null || slots.find((slot) => slot.training?.instructorIdx === instructor.idx) !== undefined) {
      instructor = instructors.find((a) => DEBUG_Instructor_Duplicate_Allow || slots.find((slot) => slot.training?.instructorIdx === a.idx) === undefined) ?? null;
    }
    if (instructor !== null) {
      slot.automation.instructorIdx = instructor.idx;
    } else {
      return;
    }

    const candidate = automationPriority(agents, instructor, slots, option);
    if (candidate) {
      const { training, reason } = trainStart(tick, slot.level, candidate, instructor, "stat", option, slot.effects);
      if (!reason) {
        training.recurr = true;
        training.recurrCancelled = false;
        slot.training = training;
      }
    }
  }
}

function automationPriority(agents, instructor, slots, option) {
  const filteredAgents = agents
    .filter((agent) => slots.find((slot) => slot.training?.agentIdx === agent.idx) === undefined)
    .filter((agent) => {
      const info = trainInfo(agent, instructor, "stat", option, 1, []);
      return info.avail;
    });

  const ordered = filteredAgents.sort((a, b) => {
    const a_overall = Math.floor(a.power * 10) / 10;
    const b_overall = Math.floor(b.power * 10) / 10;
    if (a_overall !== b_overall) {
      return a_overall - b_overall;
    }
    const a_potential = Math.floor(a.potential * 10) / 10;
    const b_potential = Math.floor(b.potential * 10) / 10;
    return a_potential - b_potential;
  });

  const [first] = ordered;

  return first;
}

export const AvailabilityMax = 100;
export const AvailabilityMin = 0;

export function getDecayAvailability(slot, agent) {
  const onTraining = agent && slot.training && !trainPending(agent);
  if (onTraining) {
    return { availability: AvailabilityOnTraining, desc_key: 'decay_training' };
  }
  else {
    return { availability: AvailabilityDecay, desc_key: 'decay_normal' };
  }
}

export function mergeAvailability(slot, availabilities) {
  const delta = _.sum(availabilities.map(({ availability }) => availability));
  let excess = 0;

  if (delta > 0) {
    excess = delta - Math.min(AvailabilityMax - slot.availability, delta);
  } else if (delta < 0) {
    excess = delta - Math.max(AvailabilityMin - slot.availability, delta);
  }

  return {
    delta: delta - excess,
    excess,
  }
}

export function getAvailabilityInfo(slot, agent) {
  const decay = getDecayAvailability(slot, agent);
  const availabilities = slot.availabilityPendings ? [decay].concat(slot.availabilityPendings) : [decay];
  const { delta, excess } = mergeAvailability(slot, availabilities);
  return {
    availabilities,
    delta,
    excess,
  };
}

export function applyAvailability(slot, availability) {
  let availabilities = (availability instanceof Array) ? availability : [availability];
  const pendings = slot.availabilityPendings ?? [];
  availabilities = availabilities.concat(pendings);

  const { delta, excess } = mergeAvailability(slot, availabilities);

  slot.lastInfo = { delta, availabilities: availabilities.slice() };
  slot.availabilityPendings = [];
  return excess;
}

export function addAvailabilityPending(slot, availability, desc_key) {
  if (!slot.availabilityPendings) {
    slot.availabilityPendings = [];
  }
  slot.availabilityPendings.push({ availability, desc_key });
}
