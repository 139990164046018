/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const FirearmShape = ({ pbShowBg = true, ty, className }) => {
  return (
    <div className={`firearm-shape ${className}`}>
      <div className="overlap-7">
        {pbShowBg && (
          <div className="bg">
            <div className="overlap-group-4">
              <div className="inner-rect" />
              <img className="union" alt="Union" src="/anima/img/union-10.svg" />
              <img className="corner-inside" alt="Corner inside" src="/anima/img/cornerinside-8.svg" />
              <img className="corner-outside" alt="Corner outside" src="/anima/img/corneroutside-8.svg" />
            </div>
          </div>
        )}

        <img
          className={`image-firearm ${ty}`}
          alt="Image firearm"
          src={
            ty === "tydmr-1"
              ? "/anima/img/image-firearm-2.svg"
              : ty === "tysg-1"
              ? "/anima/img/image-firearm-3.svg"
              : ty === "tysmg-1"
              ? "/anima/img/image-firearm-4.svg"
              : ty === "tysr-1"
              ? "/anima/img/image-firearm-5.svg"
              : ty === "tyhg-1"
              ? "/anima/img/image-firearm-hg.png"
              : ty === "typlaceholder-1"
              ? "/anima/img/image-firearm-6.svg"
              : ty === "typlaceholder-2"
              ? "/anima/img/image-firearm-7.svg"
              : "/anima/img/image-firearm-1.svg"
          }
        />
      </div>
    </div>
  );
};

FirearmShape.propTypes = {
  pbShowBg: PropTypes.bool,
  ty: PropTypes.oneOf(["tysmg-1", "tyar-1", "typlaceholder-1", "tydmr-1", "tysg-1", "typlaceholder-2", "tysr-1", "tyhg-1"]),
};
