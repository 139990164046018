import { data as source } from '../downloaded/ordersDescr.mjs';

export const ordersDescr = [];
for (const row of source) {
  const { key, title, descr } = row;
  ordersDescr.push({
    key,
    title,
    descr,
  });
}
