// downloaded data!
export const data = [
  {
    "paragraph": "loc_data_longtext_cutscenes_paragraph_0"
  },
  {
    "paragraph": "loc_data_longtext_cutscenes_paragraph_1"
  },
  {
    "paragraph": "loc_data_longtext_cutscenes_paragraph_2"
  },
  {
    "paragraph": "loc_data_longtext_cutscenes_paragraph_3"
  },
  {
    "paragraph": "loc_data_longtext_cutscenes_paragraph_4"
  },
  {
    "paragraph": "loc_data_longtext_cutscenes_paragraph_5"
  }
];