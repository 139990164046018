import _ from 'lodash';

import './PerkView.css';

import { data as data_perktree } from './data/google/downloaded/perktree';
import { perks2 as data_perks, perks2ByKey as perk_by_key } from './data/google/processor/data_perks2.mjs';
import { L } from './localization.mjs';

// PerkView has many Trees
// Tree has many TreeColumns
// TreeColumn has many Slots

// kinds
const [COMMON, ROLE, PERSONAL] = ['common', 'role', 'personal'];
const COMMON_COLS = 2;

const PERK_GROUPS_WITH_ANY_PERKS = _.uniq(data_perks.flatMap((p) => p.groups));

const PERK_GROUPS_DEFAULT = _.uniq(data_perktree
  .filter((d) => d.source === 'default')
  .map((d) => d.perk_group));
const PERK_GROUPS_TRAIT = _.uniq(data_perktree
  .filter((d) => d.source === 'trait')
  .filter((d) => PERK_GROUPS_WITH_ANY_PERKS.includes(d.perk_group))
  .map((d) => d.perk_group));
const PERK_GROUPS_BACKGROUND = _.uniq(data_perktree
  .filter((d) => d.source === 'background')
  .filter((d) => PERK_GROUPS_WITH_ANY_PERKS.includes(d.perk_group))
  .map((d) => d.perk_group));

const PERK_GROUPS_FOR_ROLE = PERK_GROUPS_BACKGROUND
  .filter((g) => !PERK_GROUPS_DEFAULT.includes(g))
  .filter((g) => !PERK_GROUPS_TRAIT.includes(g));

function isAvailableToLearn(key, learned_keys, point_avail, point_used) {
  if (point_avail < 1) {
    return false;
  }
  if (learned_keys.includes(key)) {
    return false;
  }

  const perk = perk_by_key(key);

  if (perk.level > point_used + 1) {
    return false;
  }

  const is_role_perk = _.some(PERK_GROUPS_FOR_ROLE, (group) => perk.groups.includes(group));
  if (is_role_perk) {
    if (perk.dep && perk.dep.length > 0 && !learned_keys.includes(perk.dep)) {
      return false;
    }
  }

  return true;
}

function createPerktrees(groups, point_avail, point_used, learned_keys, all_perks, onAcquirePerk) {
  const perks = all_perks.filter((p) => _.some(groups, (g) => p.groups.includes(g)));
  const highest_level = Math.max(0, ...perks.map((p) => p.level));

  const trees = [];
  for (let column = 0; column < groups.length; column++) {
    const group = groups[column];

    const slots = [];
    for (let level = 1; level <= highest_level; level++) {
      const perk_candidates = perks
        .filter((p) => p.level === level)
        .filter((p) => p.groups.includes(group))
        .sort((p) => p.key);
      let perk = null;
      if (perk_candidates.length === 0) {
        perk = null;
      } else if (perk_candidates.length === 1) {
        perk = perk_candidates[0];
      } else if (perk_candidates.length === COMMON_COLS && group === COMMON) {
        perk = perk_candidates[column];
      } else {
        throw new Error(`too many perks at level ${level}: ${perk_candidates.map((p) => p.key).join(', ')}`);
      }

      const learned = perk && learned_keys.includes(perk.key);
      const avail = perk && isAvailableToLearn(perk.key, learned_keys, point_avail, point_used);
      const slot =
        perk ?
          learned ?
            <PerkSlot key={level} state='completed' label={L(perk.name)} descr={L(perk.descr)} /> :
            avail ?
              <PerkSlot key={level} state='unlocked' label={L(perk.name)} descr={L(perk.descr)}
                onClick={() => onAcquirePerk && onAcquirePerk(perk.key)}

              /> :
              <PerkSlot key={level} state='locked' label={L(perk.name)} descr={L(perk.descr)} /> :
          <PerkSlot key={level} state='empty' />;
      slots.push(slot);
    }
    trees.push(<>
      <div className='perkgroup'>GROUP: {group ? L('loc_data_string_perktree_perk_group_name_' + group) : 'X'}</div>
      <PerkTreeColumn key={column} slots={slots} />
    </>);
  }

  return {
    perktrees: trees,
    highest_level,
  };
}

function PerkSlot(props) {
  const { state, label, onClick, descr } = props;
  return <div onClick={onClick} className={state} title={descr}>
    state:{state}
    <br />
    {label}
  </div>;
}

function PerkTreeColumn(props) {
  const { slots } = props;
  return <>{slots}</>;
}

export const PerkView = (props) => {
  const { agent, onAgentAcquirePerk } = props;

  const kinds = [COMMON, ROLE, PERSONAL];

  if (!agent) {
    return null;
  }

  const onAcquirePerk = (perk) => onAgentAcquirePerk && onAgentAcquirePerk(agent, perk);

  const background_perk_group = agent.background_perk_group.perk_group;

  const group_role0 = background_perk_group;
  const group_role = (PERK_GROUPS_FOR_ROLE.includes(group_role0)) ? group_role0 : null;
  if (group_role) {
    kinds.push(ROLE);
  }

  const groups_from_background = PERK_GROUPS_TRAIT.filter((g) => g === background_perk_group);
  const groups_from_traits = agent.traits.map((t) => t.trait_perk_group).filter((g) => PERK_GROUPS_TRAIT.includes(g));
  const groups_personal = _.uniq([...groups_from_background, ...groups_from_traits].sort());
  // TODO: 개별퍽 그룹 표시 순서, 일단 알파벳순 정렬 하는데, 나중에 게임내 다른 정렬 순서에 따르기

  if (groups_personal.length > 0) {
    kinds.push(PERSONAL);
  }


  const { perks: { list: keys_learned, point, point_total } } = agent;
  const point_used = point_total - point;

  const groups = [COMMON, COMMON, group_role, ...groups_personal].filter(Boolean);
  const { perktrees, highest_level } =
    createPerktrees(groups, point, point_used, keys_learned, data_perks, onAcquirePerk);

  const rows = 1 + highest_level;

  return (<>
    <div>{`${L('loc_ui_string_agent_perk_point_available')}=${point.toString()}`}</div>
    <div>{`${L('loc_ui_string_agent_perk_point_used')}=${(point_total - point).toString()}`}</div>
    <div className='perk-grid' style={{ display: 'grid', gridTemplateRows: `repeat(${rows}, minmax(0, 1fr))`, gridAutoFlow: 'column' }}>
      <div>LEVEL:</div>
      {Array.from(Array(highest_level).keys()).map((i) => <div key={i}>level={`${1 + i}`}</div>)}
      {perktrees}
    </div>
  </>);
};
