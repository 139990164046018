import { items } from './data/google/processor/data_items.mjs'

// fragment granade
export const throwable_fragment = {
  key: 'throwable_fragment',

  throwable_name: 'fragment',
  throwable_cost: 3000,
  throwable_rate: 3,

  // maximum throwable range
  throw_range: 300,
  // 투척으로부터 폭발까지의 지연 시간
  throw_delay: 1.5,
  blast_delay: 4.0,

  // 아래 있는 효과가 모두 적용됩니다.
  blasts: [
    {
      // 효과 종류: damage. projectile로 인한 피해와 마찬가지로, armor 적용
      // 규칙 등이 동일하게 적용됩니다.
      blast_ty: 'damage',
      // full exposure: line-of-sight에 장애물, 문, 벽 등이 아무것도 없는 경우에만 적용됩니다.
      blast_expose_ty: 'full',
      // blast radius
      blast_radius: 100,
      blast_damage: 100,
    },
    {
      blast_ty: 'damage',
      // half exposure: line-of-sight에 벽이 있는 경우를 제외하고 적용됩니다.
      blast_expose_ty: 'half',
      blast_radius: 100,
      blast_damage: 60,

      // instant
      blast_effect_ty: 'stun_gr',
      blast_effect_duration: 2,
    },
  ],
};


// standard frag
// 표준적인 지연신관 세열 수류탄
export const throwable_frag_mk1 = {
  key: 'throwable_frag_mk1',

  throwable_name: 'loc_ui_string_common_grenade_fragment_tier_1',
  throwable_cost: 1000,
  throwable_rate: 1,

  // maximum throwable range
  throw_range: 300,
  // 투척으로부터 폭발까지의 지연 시간
  throw_delay: 1.5,
  blast_delay: 4.0,

  // 아래 있는 효과가 모두 적용됩니다.
  blasts: [
    {
      // 효과 종류: damage. projectile로 인한 피해와 마찬가지로, armor 적용
      // 규칙 등이 동일하게 적용됩니다.
      blast_ty: 'damage',
      // full exposure: line-of-sight에 장애물, 문, 벽 등이 아무것도 없는 경우에만 적용됩니다.
      blast_expose_ty: 'full',
      // blast radius
      blast_radius: 90,
      blast_damage: 100,
    },
    {
      blast_ty: 'damage',
      // half exposure: line-of-sight에 벽이 있는 경우를 제외하고 적용됩니다.
      blast_expose_ty: 'half',
      blast_radius: 90,
      blast_damage: 33,
    },
    {
      blast_ty: 'effect',
      blast_expose_ty: 'half',
      blast_radius: 90,

      blast_effect_ty: 'stun_gr',
      blast_effect_duration: 1.5,
    },
  ],
};

// standard frag
export const throwable_frag_mk2 = {
  key: 'throwable_frag_mk2',

  throwable_name: 'loc_ui_string_common_grenade_fragment_tier_2',
  throwable_cost: 1500,
  throwable_rate: 2,

  throw_range: 300,
  throw_delay: 1.5,
  blast_delay: 4.0,

  blasts: [
    {
      blast_ty: 'damage',
      blast_expose_ty: 'full',
      blast_radius: 90,
      blast_damage: 133,
    },
    {
      blast_ty: 'damage',
      blast_expose_ty: 'half',
      blast_radius: 90,
      blast_damage: 40,
    },
    {
      blast_ty: 'effect',
      blast_expose_ty: 'half',
      blast_radius: 90,

      blast_effect_ty: 'stun_gr',
      blast_effect_duration: 1.5,
    },
  ],
};

// standard frag
export const throwable_frag_mk3 = {
  key: 'throwable_frag_mk3',

  throwable_name: 'loc_ui_string_common_grenade_fragment_tier_3',
  throwable_cost: 2500,
  throwable_rate: 3,

  throw_range: 300,
  throw_delay: 1.5,
  blast_delay: 3.75,

  blasts: [
    {
      blast_ty: 'damage',
      blast_expose_ty: 'full',
      blast_radius: 100,
      blast_damage: 166,
    },
    {
      blast_ty: 'damage',
      blast_expose_ty: 'half',
      blast_radius: 100,
      blast_damage: 40,
    },
    {
      blast_ty: 'effect',
      blast_expose_ty: 'half',
      blast_radius: 100,

      blast_effect_ty: 'stun_gr',
      blast_effect_duration: 1.5,
    },
  ],
};

export const throwable_frag_demo = {
  key: 'throwable_frag_demo',

  throwable_name: 'RGO-ST',
  throwable_cost: 2500,
  throwable_rate: 3,

  throw_range: 300,
  throw_delay: 1.5,
  blast_delay: 3.75,

  blasts: [
    {
      blast_ty: 'damage',
      blast_expose_ty: 'full',
      blast_radius: 1,
      blast_damage: 1,
    }
  ],
};


// impact frag
// 충격신관 세열 수류탄, blast_delay가 전반적으로 짧고 비거리가 긴 대신 살상범위와 화력이 감소함
export const throwable_impact_frag_mk1 = {
  key: 'throwable_impact_frag_mk1',

  throwable_name: 'loc_ui_string_common_grenade_fragment_impact_tier_1',
  throwable_cost: 1800,
  throwable_rate: 1,

  throw_range: 450,
  throw_delay: 0.7,
  blast_delay: 1.3,

  blasts: [
    {
      blast_ty: 'damage',
      blast_expose_ty: 'full',

      blast_radius: 35,
      blast_damage: 90,
    },
    {
      blast_ty: 'damage',
      blast_expose_ty: 'half',

      blast_radius: 100,
      blast_damage: 34,
    },
    {
      blast_ty: 'effect',
      blast_expose_ty: 'full',
      blast_radius: 35,

      blast_effect_ty: 'stun_gr',
      blast_effect_duration: 0.8,
    },
  ],
};

export const throwable_impact_frag_mk2 = {
  key: 'throwable_impact_frag_mk2',

  throwable_name: 'loc_ui_string_common_grenade_fragment_impact_tier_2',
  throwable_cost: 3000,
  throwable_rate: 2,

  throw_range: 450,
  throw_delay: 0.7,
  blast_delay: 1,

  blasts: [
    {
      blast_ty: 'damage',
      blast_expose_ty: 'full',
      blast_radius: 35,
      blast_damage: 100,

      blast_effect_ty: 'stun_gr',
      blast_effect_duration: 1,
    },
    {
      blast_ty: 'damage',
      blast_expose_ty: 'half',
      blast_radius: 100,
      blast_damage: 34,
    },
    {
      blast_ty: 'effect',
      blast_expose_ty: 'full',
      blast_radius: 35,

      blast_effect_ty: 'stun_gr',
      blast_effect_duration: 0.8,
    },
  ],
};

// HE grenades
// 고폭 수류탄. 살상반경은 좁은편이나 폭압으로 적을 제압하기 때문에 엄폐물을 무시한다
export const throwable_he_mk1 = {
  key: 'throwable_he_mk1',

  throwable_name: 'loc_ui_string_common_grenade_high_explosive_tier_1',
  throwable_cost: 800,
  throwable_rate: 1,

  throw_range: 200,
  throw_delay: 1.5,
  blast_delay: 3,

  blasts: [
    {
      blast_ty: 'damage',
      blast_expose_ty: 'full',
      blast_radius: 10,
      blast_damage: 100,
    },
    {
      blast_ty: 'damage',
      blast_expose_ty: 'half',
      blast_radius: 60,
      blast_damage: 70,
    },
    {
      blast_ty: 'effect',
      blast_expose_ty: 'half',
      blast_radius: 60,

      blast_effect_ty: 'stun_gr',
      blast_effect_duration: 2,
    },
  ],
};

export const throwable_he_mk2 = {
  key: 'throwable_he_mk2',

  throwable_name: 'loc_ui_string_common_grenade_high_explosive_tier_2',
  throwable_cost: 1200,
  throwable_rate: 2,

  throw_range: 200,
  throw_delay: 1.5,
  blast_delay: 3,

  blasts: [
    {
      blast_ty: 'damage',
      blast_expose_ty: 'full',
      blast_radius: 10,
      blast_damage: 100,
    },
    {
      blast_ty: 'damage',
      blast_expose_ty: 'half',
      blast_radius: 70,
      blast_damage: 70,
    },
    {
      blast_ty: 'effect',
      blast_expose_ty: 'half',
      blast_radius: 70,

      blast_effect_ty: 'stun_gr',
      blast_effect_duration: 2,
    },
  ],
};

// stun granade
export const throwable_stun = {
  key: 'throwable_stun',

  throwable_name: 'stun',
  throwable_cost: 2000,
  throwable_rate: 2,

  // maximum throwable range
  throw_range: 200,
  // 투척으로부터 폭발까지의 지연 시간
  throw_delay: 1.5,
  blast_delay: 4.0,

  // 아래 있는 효과가 모두 적용됩니다.
  blasts: [
    // 예시: stun granade
    {
      // 효과 종류: effect: 일정 시간 동안 effect를 적용합니다.
      blast_ty: 'effect',
      blast_expose_ty: 'half',
      blast_radius: 150,

      // instant
      blast_effect_ty: 'stun_gr',
      blast_effect_duration: 5,
    },
  ],
};

// stun granade
// 섬광탄. 살상력이 거의 없지만 반경 내의 적을 무력화 시킬 수 있다. 엄폐물 뒤에 있거나 좀 먼 거리에 있었다면 효과가 다소 감소한다.
export const throwable_stun_mk1 = {
  key: 'throwable_stun_mk1',

  throwable_name: 'loc_ui_string_common_grenade_stun_tier_1',
  throwable_cost: 2000,
  throwable_rate: 1,

  throw_range: 200,
  throw_delay: 1.5,
  blast_delay: 2,

  blasts: [
    {
      blast_ty: 'effect',
      blast_expose_ty: 'full',
      blast_radius: 60,
      blast_effect_ty: 'stun_gr',
      blast_effect_duration: 5,
    },
    {
      blast_ty: 'effect',
      blast_expose_ty: 'half',
      blast_radius: 120,

      blast_effect_ty: 'stun_gr',
      blast_effect_duration: 3.5,
    },
  ],
};

export const throwable_stun_mk2 = {
  key: 'throwable_stun_mk2',

  throwable_name: 'loc_ui_string_common_grenade_stun_tier_2',
  throwable_cost: 2000,
  throwable_rate: 2,

  throw_range: 200,
  throw_delay: 1.5,
  blast_delay: 2,

  blasts: [
    {
      blast_ty: 'effect',
      blast_expose_ty: 'full',
      blast_radius: 70,

      blast_effect_ty: 'stun_gr',
      blast_effect_duration: 5,
    },
    {
      blast_ty: 'effect',
      blast_expose_ty: 'half',
      blast_radius: 140,

      blast_effect_ty: 'stun_gr',
      blast_effect_duration: 3.5,
    },
  ],
};

export const throwable_stun_mk3 = {
  key: 'throwable_stun_mk3',

  throwable_name: 'loc_ui_string_common_grenade_stun_tier_3',
  throwable_cost: 3500,
  throwable_rate: 3,

  throw_range: 200,
  throw_delay: 1.5,
  blast_delay: 2,

  blasts: [
    {
      blast_ty: 'effect',
      blast_expose_ty: 'full',
      blast_radius: 90,

      blast_effect_ty: 'stun_gr2',
      blast_effect_duration: 5,
    },
    {
      blast_ty: 'effect',
      blast_expose_ty: 'half',
      blast_radius: 150,

      blast_effect_ty: 'stun_gr2',
      blast_effect_duration: 3.5,
    },
  ],
};


export const throwable_visibility = {
  key: 'throwable_visibility',

  throwable_name: 'visibility',
  throwable_cost: 1000,
  throwable_rate: 1,

  // maximum throwable range
  throw_range: 200,
  // 투척으로부터 폭발까지의 지연 시간
  throw_delay: 1.5,
  blast_delay: 4.0,

  blasts: [
    {
      // 효과 종류: effect: 일정 시간 동안 effect를 적용합니다.
      blast_ty: 'visibility',
      blast_expose_ty: 'half',
      blast_radius: 150,
    }
  ],
};

export const throwable_smoke = {
  key: 'throwable_smoke',

  throwable_name: 'smoke',
  throwable_cost: 1000,
  throwable_rate: 1,

  // maximum throwable range
  throw_range: 200,
  // 투척으로부터 폭발까지의 지연 시간
  throw_delay: 1.5,
  blast_delay: 1.5,

  blasts: [
    {
      // 효과 종류: effect: 일정 시간 동안 effect를 적용합니다.
      blast_ty: 'smoke',
      blast_expose_ty: 'half',
      blast_radius: 150,
      effect_ty: 'smoke',
      blast_effect_duration: 120,
    }
  ],
};

export const throwable_none = {
  key: 'throwable_none',

  throwable_name: 'loc_ui_string_common_granade_tier_0',
  throwable_cost: 0,
  throwable_rate: 0,
};

export const throwables = [
  throwable_none,
  throwable_fragment,
  throwable_frag_mk1,
  throwable_frag_mk2,
  throwable_frag_mk3,
  throwable_impact_frag_mk1,
  throwable_impact_frag_mk2,
  throwable_he_mk1,
  throwable_he_mk2,
  throwable_stun,
  throwable_stun_mk1,
  throwable_stun_mk2,
  throwable_stun_mk3,
  throwable_visibility,
  throwable_smoke,
];

for (const throwable of throwables) {
  const item = items.find(i => i.key === throwable.key);
  if (item) {
    throwable.buy_cost = item.buy_cost;
    throwable.sell_cost = item.sell_cost;
    throwable.durability = item.durability;
  }
}