export const COLORS = {
  title0: [
    '#e9e1c0',
    '#ffb400',
    '#ff6000',
    '#ef5011',
  ],
  title1: [
    '#f2b75f',
    '#e98b3f',
  ],

  grid0: [
    '#1c1b18',
    '#282421',
    '#3b3a29',
    '#35414b',
    '#22524a',
  ],

  popup0: [
    '#e1995e',
    '#f0edd2',
  ],

  highlight0: [
    '#fc4c07',
    '#2fe9bf',
    '#f600ff',
    '#2def84',
  ],
};


