import { data as source } from '../downloaded/exp.mjs';

export const exps = [];
for (const row of source) {
  const { level, exp } = row;
  exps.push({
    level: 0 | level,
    exp: 0 | exp,
  });
}
