import _ from 'lodash';
import Heap from 'heap';

import { Rng } from './rand.mjs';
import * as presets from './presets_world.mjs';
import { TickTimer } from './ticktimer.mjs';
import { EFFECTS_DEFAULT } from './effects.mjs';
import * as data_facilities from './data/google/processor/data_facilities.mjs';
import bounds from 'binary-search-bounds';

import { TICK_PER_DAY } from './tick.mjs';

export const CELL_CENTER = 1;

const MISSION_PROB = 1 / (14 * TICK_PER_DAY);

const TICK_TRANSITION_IDLE = TICK_PER_DAY * 7;
const TICK_TRANSITION_SPLIT = TICK_PER_DAY * 14;
const TICK_TRANSITION_EXPAND = TICK_PER_DAY * 14;

const PRESET = presets.PRESET_ALT3;

export const SUBDIV_COUNT = 10;

// eslint-disable-next-line
export function qrDist(a, b) {
  const as = -a.q - a.r;
  const bs = -b.q - b.r;
  return (Math.abs(a.q - b.q) + Math.abs(a.r - b.r) + Math.abs(as - bs)) / 2;
}

export function qrSub(a, b) {
  return { q: a.q - b.q, r: a.r - b.r };
}

export function qrNeighbors({ q, r }) {
  return [
    { q: q + 1, r: r - 1 },
    { q: q + 1, r },
    { q, r: r + 1 },
    { q: q - 1, r: r + 1 },
    { q: q - 1, r },
    { q, r: r - 1 },
  ];
}

export function qrCmp(p0, p1) {
  if (p0.q < p1.q) {
    return -1;
  } else if (p0.q > p1.q) {
    return 1;
  } else if (p0.r < p1.r) {
    return -1;
  } else if (p0.r > p1.r) {
    return 1;
  } else {
    return 0;
  }
}

export function qrEq(p0, p1) {
  if (!p0 || !p1) {
    return false;
  }
  return qrCmp(p0, p1) === 0;
}

export function qrRound(frac) {
  const round = Math.round;
  const abs = Math.abs;

  const frac_s = -frac.q - frac.r;

  var q = round(frac.q)
  var r = round(frac.r)
  var s = round(frac_s)

  var q_diff = abs(q - frac.q)
  var r_diff = abs(r - frac.r)
  var s_diff = abs(s - frac_s)

  if (q_diff > r_diff && q_diff > s_diff) {
    q = -r - s
  } else if (r_diff > s_diff) {
    r = -q - s
  } else {
    s = -q - r
  }

  return { q, r }
}

export function qrFromCoord(point, size_cell) {
  const q = (Math.sqrt(3) / 3 * point.x - 1. / 3 * point.y) / size_cell;
  const r = (2. / 3 * point.y) / size_cell;
  return qrRound({ q, r });
}

export function qrFromOddr({ i, j }) {
  var q = i - (j - (j & 1)) / 2;
  var r = j;
  return { q, r };
}

export function oddrFromQr({ q, r }) {
  var i = q + (r - (r & 1)) / 2
  var j = r
  return { i, j };
}

export function centerState() {
  return {
    name: 'test',
    facilities: [],
    locked: true,

    strength: 0,

    renown: 0,
    unlocks: 0,

    safehouse: false,
    office: false,

    effects: { ...EFFECTS_DEFAULT },

    tier: 1,
    forces: [{ tier: 1, name: '자경단', weight: 100 }],
  };
}

const mergeMaps = function(a, b) {
  const keys = new Set();
  for (const key of Object.keys(a)) {
    keys.add(key);
  }
  for (const key of Object.keys(b)) {
    keys.add(key);
  }
  const out = {};
  for (const k of keys.keys()) {
    const va = a[k] ?? [0, 0, 0, 0];
    const vb = b[k] ?? [0, 0, 0, 0];
    out[k] = _.zipWith(va, vb, (a, b) => a + b);
  }
  return out;
};

const roadCmp = (a, b) => {
  let cmp = qrCmp(a.s, b.s);
  if (cmp !== 0) {
    return cmp;
  }
  return qrCmp(a.t, b.t);
};

const CRIMINALS = ["갱단", "잡범"];

export class WorldState2 {
  constructor(data) {
    if (data) {
      Object.assign(this, data);
    } else {
      this.initialize();
    }

    this.rng = new Rng();

    this.recalculate();
  }

  initialize() {
    const tick = 0;

    const width = 50;
    const height = 60;

    const storage_offset = Math.floor((height - 1) / 2);
    const storage_width = width + storage_offset;
    const storage = new Array(storage_width * height);
    for (let i = 0; i < storage.length; i++) {
      storage[i] = {
        ty: 0,
        nearests: [],
      };
    }

    this.tick = tick;
    this.rng = new Rng();

    this.height = height;
    this.width = width;
    this.storage_width = storage_width;
    this.storage_offset = storage_offset;
    this.storage = storage;

    this.road = [];

    this.transition = { ty: 'idle', timer: new TickTimer(tick, TICK_TRANSITION_IDLE) };
    this.centers = [];
    this.blockconns = [];
    this.missions = [];

    if (PRESET) {
      const obj = JSON.parse(PRESET);
      this.storage = obj.storage;
      this.road = obj.road;

      this.road.sort(roadCmp);
      this.deserialize({});
    }
  }

  serialize() {
    const { tick, height, width, storage_width, storage_offset, storage, missions, road, transition } = this;

    return {
      tick,
      height,
      width,

      storage_width,
      storage_offset,

      missions,
      road,
      transition,

      storage: storage.map((s) => {
        const s1 = { ...s };
        delete s1.nearests;
        delete s1.subdivisions;
        delete s1.paratitions;
        return s1;
      }),
    };
  }

  deserialize(obj) {
    Object.assign(this, obj);

    this.rng = new Rng();
    for (const s of this.storage) {
      if (s.centerstate) {
        s.centerstate = {
          ...centerState(),
          ...s.centerstate,
        };
      }
    }

    const { start, duration } = this.transition.timer;
    this.transition.timer = new TickTimer(start, duration);

    this.recalculate();
    return this;
  }

  cells(ty) {
    const { storage, width, height } = this;

    const res = [];
    for (let j = 0; j < height; j++) {
      for (let i = 0; i < width; i++) {
        const p = qrFromOddr({ i, j });
        const idx = this.idxqr(p);
        if (storage[idx].ty === ty) {
          res.push({ p, idx });
        }
      }
    }
    return res;
  }

  borders(p, pred) {
    const { storage } = this;
    const heap = new Heap((a, b) => {
      return a.cost - b.cost;
    });
    heap.push({ cost: 0, p, idx: this.idxqr(p), parent: { idx: -1 } });

    const visited = new Uint32Array(storage.length);
    const borders = [];

    while (!heap.empty()) {
      const elem = heap.pop();
      const { idx } = elem;

      if (!pred(idx)) {
        borders.push({ ...elem.parent, i: elem.i });
        continue;
      }
      if (visited[idx]) {
        continue;
      }

      visited[idx] = elem.parent.idx;

      const neighbors = qrNeighbors(elem.p);
      for (let i = 0; i < neighbors.length; i++) {
        const neighbor = neighbors[i];
        const idx = this.idxqr(neighbor);
        if (idx === -1) {
          continue;
        }

        heap.push({
          cost: elem.cost + 1,
          p: neighbor,
          idx,
          parent: elem,
          i,
        });
      }
    }

    const duel = (b) => {
      const p = qrNeighbors(b.p)[b.i];
      const idx = this.idxqr(p);
      const i = (b.i + 3) % 6;
      return { ...b, idx, p, i };
    }

    const connected0 = (b0, b1) => {
      const { idx: idxb0, i: ib0 } = b0;
      const { idx: idxb1, i: ib1 } = b1;

      if (idxb0 !== idxb1) {
        return null;
      }
      if ((ib0 + 1) % 6 === ib1) {
        return 1;
      }
      if ((ib0 + 5) % 6 === ib1) {
        return -1;
      }
      return null;
    }

    const connected = (b0, b1) => {
      let v = connected0(b0, b1);
      if (v) {
        return v;
      }
      v = connected0(b0, duel(b1));
      if (v) {
        return v;
      }
      v = connected0(duel(b0), b1);
      if (v) {
        return -v;
      }
      v = connected0(duel(b0), duel(b1));
      if (v) {
        return -v;
      }
      return null;
    }

    // stitch
    let rev = false;
    const borders0 = borders.slice();
    const borders1 = borders0.splice(0, 1);
    while (borders0.length > 0) {
      const next = borders0.findIndex((b) => {
        return connected(borders1[borders1.length - 1], b);
      });
      if (next === -1) {
        if (rev) {
          throw new Error('borders');
        }
        rev = true;
        borders1.reverse();
        continue;
      }
      borders1.push(borders0.splice(next, 1)[0]);
    }

    if (connected(borders1[0], borders1[1]) < 0) {
      borders1.reverse();
    }

    return borders1;
  }

  partitions(center_idx, pl) {
    const { storage } = this;
    const heap = new Heap((a, b) => {
      return a.cost - b.cost;
    });

    for (let i = 0; i < pl.length; i++) {
      const p = pl[i];
      const idx = this.idxqr(p);
      heap.push({ cost: 0, p, idx, partition: i, parent: { idx: -1 } });
    }

    const visited = new Int32Array(storage.length);
    visited.fill(-1);
    const dists = new Uint8Array(storage.length);
    const nodes = new Array(storage.length);

    const connectivity = [];

    const addconn0 = (from, to) => {
      let l = connectivity[from] ?? [];
      if (!l.includes(to)) {
        l.push(to);
      }
      connectivity[from] = l;
    };

    const addconn = (from, to) => {
      if (from === to) {
        return;
      }
      addconn0(from, to);
      addconn0(to, from);
    };

    while (!heap.empty()) {
      const elem = heap.pop();
      const { idx } = elem;

      if (idx !== center_idx && storage[idx].nearests[0]?.idx !== center_idx) {
        continue;
      }

      if (visited[idx] >= 0) {
        addconn(visited[idx], elem.partition);
        if (dists[idx] === elem.cost && visited[idx] > elem.partition) {
          visited[idx] = elem.partition;
        }
        continue;
      }
      if (storage[idx].ty > 2) {
        continue;
      }

      nodes[elem.idx] = elem;
      visited[idx] = elem.partition;
      dists[idx] = elem.cost;

      const neighbors = qrNeighbors(elem.p);
      for (const neighbor of neighbors) {
        const idx = this.idxqr(neighbor);
        if (idx === -1) {
          continue;
        }
        let cost = 1;
        heap.push({
          cost: elem.cost + cost,
          parent: elem,
          p: neighbor,
          idx,
          partition: elem.partition,
        });
      }
    }

    return { nodes, dists, visited, connectivity };
  }

  distances0(p) {
    const { storage } = this;
    const heap = new Heap((a, b) => {
      return a.cost - b.cost;
    });

    const idx = this.idxqr(p);
    heap.push({ cost: 0, p, idx, parent: { idx: -1 } });

    const visited = new Uint32Array(storage.length);
    const dists = new Uint8Array(storage.length);
    const nodes = new Array(storage.length);

    while (!heap.empty()) {
      const elem = heap.pop();
      const { idx } = elem;

      if (visited[idx]) {
        continue;
      }
      if (storage[idx].ty > 2) {
        continue;
      }

      nodes[elem.idx] = elem;
      visited[idx] = elem.parent.idx;
      dists[idx] = elem.cost;

      const neighbors = qrNeighbors(elem.p);
      for (const neighbor of neighbors) {
        const idx = this.idxqr(neighbor);
        if (idx === -1) {
          continue;
        }
        let s = elem.p;
        let t = neighbor;
        if (qrCmp(s, t) < 0) {
          [s, t] = [t, s];
        }

        const node_idx = bounds.eq(this.road, { s, t }, roadCmp);
        let cost = 2;
        if (node_idx >= 0) {
          cost = 1;
        }

        heap.push({
          cost: elem.cost + cost,
          parent: elem,
          p: neighbor,
          idx,
        });
      }
    }

    return { nodes, dists, visited };
  }

  distances(p) {
    const { dists } = this.distances0(p);
    return dists;
  }

  findNearest(p) {
    const { storage } = this;
    const heap = new Heap((a, b) => {
      return a.cost - b.cost;
    });

    const idx = this.idxqr(p);
    heap.push({ cost: 0, p, idx: idx });
    const visited = new Uint8Array(storage.length);
    let maxcost = 10000;
    let results = [];

    while (!heap.empty()) {
      const elem = heap.pop();
      if (elem.cost > maxcost) {
        break;
      }

      if (elem.terminal) {
        maxcost = elem.cost;
        results.push(elem);
        continue;
      }

      if (visited[elem.idx]) {
        continue;
      }
      visited[elem.idx] = 1;

      const s = storage[elem.idx];
      if (s.ty > 1) {
        // ignore
        continue;
      }

      if (s.ty === 1) {
        const { centerstate: { strength } } = s;
        heap.push({
          cost: elem.cost + strength,
          p: elem.p,
          idx: elem.idx,
          terminal: true,
        });
        continue;
      }

      const neighbors = qrNeighbors(elem.p);
      for (const neighbor of neighbors) {
        const idx = this.idxqr(neighbor);
        if (idx === -1) {
          continue;
        }

        heap.push({
          cost: elem.cost + 1,
          p: neighbor,
          idx,
        });
      }
    }

    return results;
  }

  recalculateNearests(centers) {
    const { storage, width, height } = this;

    const distances = centers.map((center) => this.distances(center.p));
    const blockconns = [];

    for (let j = 0; j < height; j++) {
      for (let i = 0; i < width; i++) {
        const p = qrFromOddr({ i, j });
        const idx = this.idxqr(p);

        const thres = _.min(distances.map((arr, i) => {
          const { centerstate: { strength } } = storage[centers[i].idx];
          return arr[idx] + strength;
        })) + 1;

        const s = storage[idx];

        if (s.ty !== 0) {
          s.nearests = [];
          continue;
        }

        let nearests = centers.map((center, i) => {
          const dist = distances[i][idx];
          if (dist === 0) {
            return null;
          }

          const { centerstate: { strength } } = storage[centers[i].idx];
          return {
            dist,
            cost: dist + strength,
            idx: center.idx,
            p: center.p,
          };
        }).filter((elem) => elem !== null && elem.cost <= thres);

        nearests = nearests.sort((a, b) => {
          if (a.cost !== b.cost) {
            return a.cost - b.cost;
          }
          return a.idx - b.idx;
        });
        nearests = nearests.map((elem) => {
          return {
            ...elem,
            center_idx: centers.findIndex((s) => s.idx === elem.idx),
          };
        });
        s.nearests = nearests;

        {
          // update connectivity
          const blocks = nearests.map((n) => n.idx).sort((a, b) => a.idx - b.idx);
          for (const from of blocks) {
            for (const to of blocks) {
              if (from === to) {
                continue;
              }

              if (blockconns.findIndex((conn) => conn.from === from && conn.to === to) === -1) {
                blockconns.push({ from, to });
              }
            }
          }
        }
      }
    }

    return blockconns;
  }

  recalculate() {
    const { storage } = this;

    this.centers = this.cells(CELL_CENTER);
    this.blockconns = this.recalculateNearests(this.centers);

    for (const c of this.centers) {
      const rng = new Rng(2);
      const { idx } = c;
      const p = this.qridx(idx);
      c.borders = this.borders(p, (idx0) => {
        if (idx0 === idx) {
          return true;
        }
        return storage[idx0].nearests[0]?.idx === idx;
      });

      // subdivisions
      const candidates = rng.shuffle(this.centerCells(c, () => false)).slice(0, SUBDIV_COUNT - 1);
      candidates.sort((a, b) => {
        return a.dist - b.dist;
      });
      c.subdivisions = [
        c,
        ...candidates,
      ];
      c.partitions = this.partitions(idx, c.subdivisions.map(({ p }) => p));
    }
  }

  chooseExpand() {
    const { rng, blockconns, storage } = this;

    for (let i = 0; i < 100; i++) {
      const { from, to } = rng.choice(blockconns);
      const { centerstate: { faction: faction_from } } = storage[from];
      const { centerstate: { faction: faction_to } } = storage[to];

      if (faction_from === faction_to) {
        continue;
      }
      return { from, to };
    }
    return null;
  }

  transitionSplit() {
    const { rng, storage } = this;

    // 분리될 block을 선택합니다.
    const cells = this.cells(CELL_CENTER);
    const factions = _.countBy(cells.map((c) => {
      const { centerstate: { faction } } = storage[c.idx];
      return faction;
    }));

    let faction = 0;
    while (faction < 100) {
      if (isNaN(factions[faction])) {
        break;
      }
      faction += 1;
    }

    for (let i = 0; i < 100; i++) {
      const selected = rng.choice(cells);
      const { centerstate: { faction: faction_selected } } = storage[selected.idx];
      if (factions[faction_selected] < 2) {
        continue;
      }
      return {
        target: selected,
        faction,
      };
    }
    return null;
  }

  onTickTransition(tick) {
    const { rng, transition, storage } = this;

    if (!transition.timer.expired(tick)) {
      return transition;
    }
    if (transition.ty === 'idle') {
      // select next transition

      const transitions = [];
      const expand = this.chooseExpand();
      if (expand) {
        transitions.push('expand');
      }
      const split = this.transitionSplit();
      if (split) {
        transitions.push('split');
      }

      const ty = rng.choice(transitions);
      if (ty === 'split') {
        const target = split;
        const transition = {
          ty,
          ...target,
          timer: new TickTimer(tick, TICK_TRANSITION_SPLIT),
        };
        return transition;
      } else if (ty === 'expand') {
        const { from, to } = expand;
        const transition = {
          ty,
          from,
          to,
          timer: new TickTimer(tick, TICK_TRANSITION_EXPAND),
        };
        return transition;
      }
    } else {
      if (transition.ty === 'split') {
        const { target, faction } = transition;
        storage[target.idx].centerstate.faction = faction;
        this.recalculate();
      } else if (transition.ty === 'expand') {
        const { from, to } = transition;
        storage[to].centerstate.faction = storage[from].centerstate.faction;
        this.recalculate();
      } else {
        throw new Error(`unknown transition ty=${transition.ty}`);
      }

      return {
        ty: 'idle',
        timer: new TickTimer(tick, TICK_TRANSITION_IDLE),
      };
    }
    throw new Error('onTickTransition');
  }

  facilitySamples(center) {
    const { storage } = this;

    const candidates = [];
    for (let idx = 0; idx < storage.length; idx++) {
      const s = storage[idx];
      const p = this.qridx(idx);
      if (!s.nearests) {
        continue;
      }
      if (s.nearests.length !== 1 || s.nearests[0].idx !== center.idx) {
        continue;
      }
      if (this.occupied(idx)) {
        continue;
      }
      candidates.push({ ...s, idx, p, center });
    }

    return candidates;
  }

  onNewMission(center, subdiv) {
    center = this.centers.find((c) => c.idx === center.idx);

    const candidates = this.centerCells(center).filter((s) => {
      const delta = qrSub(s.p, center.p);
      const sum = delta.q + delta.r;
      // 임시: 오른쪽 아래에는 안 나오도록
      if (sum >= 1 && sum <= 3) {
        return false;
      }

      if (subdiv === -1) {
        return true;
      }
      return center.partitions.visited[s.idx] === subdiv;
    });

    return this.rng.choice(candidates);
  }

  qridx(idx) {
    const { storage_width, storage_offset } = this;

    const r = Math.floor(idx / storage_width);
    const q = (idx % storage_width) - storage_offset;
    return { q, r };
  }

  idxqr({ q, r }) {
    const { storage_width, storage_offset, width, height } = this;
    if (r < 0 || r >= height) {
      return -1;
    }
    const idxrow = q + storage_offset;
    const rowoff = Math.floor(r / 2);
    if (idxrow < storage_offset - rowoff || idxrow >= storage_offset - rowoff + width) {
      return -1;
    }
    const idx = r * storage_width + idxrow;
    return idx;
  }

  occupied(idx) {
    const { centers, missions } = this;
    if (centers.find((c) => {
      if (c.idx === idx) {
        return true;
      }
      const { centerstate } = this.storage[c.idx];
      if (centerstate.facilities.find((f) => f.idx === idx)) {
        return true;
      }
      return false;
    })) {
      return true;
    }

    if (missions.find((m) => m.idx === idx)) {
      return true;
    }
    return false;
  }

  centerCells(center, occupied) {
    const { storage } = this;
    if (!occupied) {
      occupied = this.occupied.bind(this);
    }

    const candidates = [];
    for (let idx = 0; idx < storage.length; idx++) {
      const s = storage[idx];
      const p = this.qridx(idx);
      if (!s.nearests) {
        continue;
      }
      if (idx === center.idx) {
        continue;
      }

      if (s.nearests[0]?.idx !== center.idx) {
        continue;
      }
      if (occupied(idx)) {
        continue;
      }
      const dist = qrDist(center.p, p);
      candidates.push({ ...s, idx, p, center, dist });
    }
    return candidates;
  }

  facilitiesByKey(key) {
    const facilities = this.facilities();
    return facilities[key] ?? [0, 0, 0, 0];
  }

  facilityEffectsByKey(idx, effect_key) {
    const { storage } = this;
    const { centerstate: { facilities } } = storage[idx];

    const effects = [];
    for (const { enabled, key } of facilities) {
      if (!enabled) {
        continue;
      }

      const { result } = data_facilities.facilityByKey(key);
      if (!result) {
        continue;
      }

      for (const effect of result.effects) {
        if (effect.effect === effect_key) {
          effects.push(effect);
        }
      }
    }
    return effects;
  }

  facilitiesByCenterKey(idx, key) {
    const facilities = this.facilitiesByCenter(idx);
    return facilities[key] ?? [0, 0, 0, 0];
  }

  facilityMaxByCenterKey(idx, key) {
    const f = this.facilitiesByCenterKey(idx, key);
    for (let i = 3; i >= 0; i--) {
      if (f[i] > 0) {
        return i + 1;
      }
    }
    return 0;
  }

  facilityBonusesByKey(idx, key) {
    const facilities = this.facilityBonuses(idx);
    return facilities[key] ?? [0, 0, 0, 0];
  }

  facilityMaxBonusByKey(idx, key) {
    const f = this.facilityBonusesByKey(idx, key);
    for (let i = 3; i >= 0; i--) {
      if (f[i] > 0) {
        return i + 1;
      }
    }
    return 0;
  }

  facilityBonuses(idx) {
    let bonuses = this.facilitiesByCenter(idx);
    const neighbors = this.blockconns.filter((b) => b.from === idx);
    for (const { to } of neighbors) {
      let types = this.facilitiesByCenter(to);
      bonuses = mergeMaps(bonuses, types);
    }
    for (const key of Object.keys(bonuses)) {
      if (key.indexOf('_adjacent') === -1) {
        delete (bonuses[key]);
      }
    }
    return bonuses;
  }

  facilitiesByCenter(idx) {
    const { storage } = this;
    const facilities = storage[idx].centerstate?.facilities ?? [];

    const types = {};


    for (const { key } of facilities) {
      const matches = key.match(/^(:?[a-z_]+)(:?[0-9])$/);
      if (!matches) {
        continue;
      }
      const [, ty, idxstr] = matches;
      const idx = (0 | idxstr) - 1;
      if (!(ty in types)) {
        types[ty] = [0, 0, 0, 0];
      }
      types[ty][idx] += 1;
    }
    return types;
  }

  facilities() {
    const { centers } = this;
    let types = {};

    for (const { idx } of centers) {
      const m = this.facilitiesByCenter(idx);
      types = mergeMaps(types, m);
    }

    return types;
  }

  centerIdx(idx) {
    const s = this.storage[idx];
    if (s.centerstate) {
      return idx;
    }
    if (!s.nearests || s.nearests.length === 0) {
      return -1;
    }

    const nearest = s.nearests[0];
    return this.centers[nearest.center_idx].idx;
  }

  centerEnabled(idx) {
    const { locked, office } = this.storage[idx].centerstate;
    // TODO
    // const mode = this.centerMode(idx);
    return !locked && office;
  }

  // "세력 파워가 50 이상인 세력이 존재하면 그 세력이 지배 세력으로 등극. 해당 구역은 지배 세력의 지배 구역 리스트에 들어감.
  // 세력 파워가 50 미만으로 떨어지면 취소됨."
  centerOwner(idx) {
    const { storage } = this;
    const { centerstate: { forces } } = storage[idx];

    return forces.find(({ name, weight }) => {
      return !CRIMINALS.includes(name) && weight >= 50;
    });
  }

  // 2 전장	"세력 파워가 0보다 큰 지역세력/대형세력/주요세력이 2개 이상 존재할 때. 매달 평균 2개의 의뢰가 발생함."
  // 1 무질서	"세력 파워가 0보다 크고 범죄자가 아닌 세력이 1개 존재하며 지배 세력이 없을 때(=범죄자가 우세할 때). 매달 평균 2개의 의뢰가 발생함."
  // 0 안정	"세력 파워가 0보다 크고 범죄자가 아닌 세력이 1개 존재하며 지배 세력이 있을 때. 매달 평균 1개의 의뢰가 발생함."
  centerMode(idx) {
    const { storage } = this;
    const { centerstate: { forces } } = storage[idx];

    if (forces.filter(({ tier, weight }) => tier > 1 && weight > 0).length > 1) {
      return 2;
    }

    if (forces.find(({ name, weight }) => {
      if (CRIMINALS.includes(name)) {
        return false;
      }
      return weight > 0;
    }) && !this.centerOwner(idx)) {
      return 1;
    }

    return 0;
  }

  centerSampleMission(rng, idx) {
    const { storage } = this;
    const { centerstate: { forces } } = storage[idx];

    const client_candidates = forces.filter(({ name, weight }) => {
      if (CRIMINALS.includes(name)) {
        return false;
      }
      if (weight <= 0) {
        return false;
      }
      return true;
    });

    const client = rng.choice(client_candidates);
    const target = rng.choice(forces.filter((f) => f.name !== client.name && f.weight > 0));

    return { client, target };
  }

  centerUpdateWeights(rng, idx, client, target) {
    const { storage } = this;
    const { centerstate: { forces } } = storage[idx];

    client = forces.find((f) => f.name === client);
    target = forces.find((f) => f.name === target);

    const amount = Math.min(5, Math.max(0, target.weight));

    client.weight += amount;
    target.weight -= amount;

    const criminal = forces.find((f) => CRIMINALS.includes(f.name));

    // restore criminal weight
    while (criminal.weight < 10) {
      const candidates = forces.filter((f) => f.weight !== 0);
      if (candidates.length === 0) {
        // TODO
        break;
      }
      rng.choice(candidates).weight -= 1;
      criminal.weight += 1;
    }
  }
}
