import React, { useState } from 'react';
import { RoguelikeView } from './RoguelikeView';

import './TitleView.css';

function Btn(props) {
  const { name, onClick } = props;

  const [over, onOver] = useState(false);
  const color = over ? 'Red' : 'White';
  const src = `/img/title/${name.toUpperCase()}_${color}.svg`;

  return <img className={`title-button-${name}`} src={src}
    onMouseDown={() => { onClick?.(); }}
    onMouseOver={() => { onOver(true); }}
    onMouseOut={() => { onOver(false); }}
  />;
}

export class TitleView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      started: false,
    };
  }

  render() {
    const { m } = this.props;
    if (this.state.started) {
      return <RoguelikeView m={m} />;
    }

    return <div className="title-root">
      <div className="title-button">
        <Btn name="start" onClick={() => {
          this.setState({ started: true });
        }} />

        <div style={{ height: '2px' }} />
        <Btn name="options" />
        <div style={{ height: '120px' }} />
        <Btn name="exit" onClick={() => {
          window.ue?.connection?.onquit?.();
        }} />
      </div>
    </div>;
  }
}
