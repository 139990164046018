// downloaded data!
export const data = [
  {
    "areaNum": "0",
    "min": "9",
    "max": "15"
  },
  {
    "areaNum": "1",
    "min": "22",
    "max": "38"
  },
  {
    "areaNum": "2",
    "min": "75",
    "max": "125"
  },
  {
    "areaNum": "3",
    "min": "300",
    "max": "500"
  }
];