import './SkirmishView.css';
import React from 'react';
import { HireView } from './component/HireView';
import { SimView } from './SimView';
import { presets } from './presets_mission';
import { entityFromStat } from './stat';
import { opts } from './opts';
import { StatView } from './StatView';
import { BuildReport } from './component/BuildReport';
import { perk_apply_stats, perk_apply_entity } from './perks';

export class SkirmishView extends React.Component {
  constructor(props) {
    super(props);

    this.simRef = React.createRef();
    this.hireRef = React.createRef();

    this.keyDownBind = this.keyDown.bind(this);
    this.keyUpBind = this.keyUp.bind(this);

    this.state = {
      phase: 'prepare',
      simstate: null,

      sim: null,
      showStats: false,

      resource: 12000,
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.keyDownBind);
    document.addEventListener('keyup', this.keyUpBind);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyDownBind);
    document.removeEventListener('keyup', this.keyUpBind);
  }

  keyDown(ev) {
    if (ev.key === 'q') {
      if (this.state.phase === 'sim') {
        this.onResult();
      }
    } else if (ev.key === 'Tab') {
      ev.preventDefault();
      this.setState({ showStats: true });
    }
  }

  keyUp(ev) {
    if (ev.key === 'Tab') {
      ev.preventDefault();
      this.setState({ showStats: false });
    }
  }

  onStart() {
    const hire = this.hireRef.current;

    const simstate = presets['metro']();

    const selected = hire.state.characters.filter((ch) => ch.hired);
    const entities = simstate.entities.filter((a) => a.team !== 0);

    simstate.entities = [
      ...selected.map((ch, i) => {
        const perk_keys = ch.character.perks.map((p) => p.key);
        const stats = perk_apply_stats(ch.character.stats, perk_keys);

        const entity = entityFromStat(stats, ch.weapon);
        // TODO
        entity.armor = ch.equipment.vest_armor;
        entity.armor_max = ch.equipment.vest_armor;
        entity.armor_hit_prob = ch.equipment.vest_hit_prob;

        perk_apply_entity(entity, perk_keys);

        // TODO
        entity.spawnarea = i % 2;
        entity.allow_fire_control = true;
        return entity;
      }),
      ...entities,
    ];

    this.setState({
      phase: 'sim',
      resource_remain: hire.state.resource,
      characters: hire.state.characters,
      selected,
      simstate,
    });
  }

  onFinish() {
    this.onResult();
    // this.setState({ finished: true });
  }

  onResult() {
    const sim = this.simRef.current.state.sim;
    this.setState({ phase: 'result', sim });
  }

  onReset() {
    this.setState({
      phase: 'prepare',
      simstate: null,
      sim: null,
      // finished: false,
    });
  }

  renderResult() {
    const { sim, resource, resource_remain, selected } = this.state;

    const result = sim.onTick();

    return <>
      <h1>결과: {result === 0 ? '성공' : '실패'}</h1>
      <div>
        소요 시간: {Math.floor(sim.tick / opts.tps)}초
        <br />
        미사용 자원: {resource_remain}/{resource}
      </div>
      <StatView sim={sim} selected={selected} />
    </>
  }

  renderEvaluation() {
    const { sim, resource, resource_remain } = this.state;
    const result = sim.onTick();
    const { entities } = sim;

    function calculateEvaluation() {
      if (result !== 0) {
        return { mission_fail: 0 };
      } else {
        let evals = { mission_success: 40 };
        const vip = entities.find((e) => e.ty === 'vip');
        if (vip && vip.life < 100) {
          evals.hurt_vip = -10;
        }
        evals.mission_hype = 60;
        const deadAllies = entities.filter((e) => e.team === 0 && e.ty !== 'vip' && e.life <= 0);
        const hurtSevereAllies = entities.filter((e) => e.team === 0 && e.ty !== 'vip' && e.life > 0 && e.life < 10);
        const hurtHeavyAllies = entities.filter((e) => e.team === 0 && e.ty !== 'vip' && e.life >= 10 && e.life < 50);
        const hurtLightAllies = entities.filter((e) => e.team === 0 && e.ty !== 'vip' && e.life >= 50 && e.life < 100);
        if (deadAllies.length > 0) {
          evals.dead_allies = -15 * deadAllies.length;
        }
        if (hurtSevereAllies.length > 0) {
          evals.hurt_severe_allies = -10 * hurtSevereAllies.length;
        }
        if (hurtHeavyAllies.length > 0) {
          evals.hurt_heavy_allies = -5 * hurtHeavyAllies.length;
        }
        if (hurtLightAllies.length > 0) {
          evals.hurt_light_allies = -2 * hurtLightAllies.length;
        }
        evals.resource_saved = Math.floor(Math.min(20, 40 * (resource_remain / resource)));

        return evals;
      }
    }

    function getEvalInfo(key) {
      switch (key) {
        case 'mission_fail':
          return ['임무 실패', 0];
        case 'mission_success':
          return ['임무 성공', 0];
        case 'hurt_vip':
          return ['VIP 부상', 1];
        case 'mission_hype':
          return ['승리의 흥분', 0];
        case 'dead_allies':
          return ['아군 사망', 1];
        case 'hurt_severe_allies':
          return ['아군 중태', 1];
        case 'hurt_heavy_allies':
          return ['아군 중상', 1];
        case 'hurt_light_allies':
          return ['아군 경상', 1];
        case 'resource_saved':
          return ['자원 절약', 0];
        default:
          return ['오류', 0];
      }
    }

    function getAlphabetScore(evals) {
      const score = Object.values(evals).reduce((a, b) => a + b, 0);
      const hurts = Object.keys(evals).filter((k) => k.startsWith('hurt')).length;

      if (evals.mission_fail) {
        return ['F', score];
      } else if (score >= 100 || (score >= 90 && hurts === 0)) {
        return ['S', score];
      } else if (score >= 90) {
        return ['A', score];
      } else if (score >= 75) {
        return ['B', score];
      } else if (score >= 60) {
        return ['C', score];
      } else {
        return ['D', score];
      }
    }

    function buildReport(evals) {
      const rows = [];
      const [alphabetScore, score] = getAlphabetScore(evals);
      for (const key in evals) {
        const value = evals[key];
        const [text, depth] = getEvalInfo(key);
        rows.push([text, depth, value])
      }
      return <>
        <BuildReport rows={rows} alphabetScore={alphabetScore} score={score} />
      </>
    }

    return buildReport(calculateEvaluation());

  }

  componentDidUpdate() {
    const sim = this.simRef.current?.state?.sim;
    if (sim && this.state.sim !== sim) {
      this.setState({ sim });
    }
  }

  render() {
    const { phase, resource, resource_remain, characters, sim, selected, showStats } = this.state;

    if (phase === 'prepare') {
      return <>
        <button onClick={() => this.onStart()}>Start</button>
        <HireView ref={this.hireRef} resource={resource} initialState={{ characters, resource: resource_remain }} />
      </>;
    } else if (phase === 'sim') {
      let stats = null;
      if (showStats) {
        stats = <div className='stat-overlay'>
          <StatView sim={sim} selected={selected} />;
        </div>;
      }

      return <>
        <SimView ref={this.simRef}
          onFinish={(res) => this.onFinish(res)}
          {...this.state.simstate}
        />
        {stats}
      </>;
    } else {
      return <div>
        <button onClick={this.onReset.bind(this)}>Restart</button>
        {this.renderResult()}
        {this.renderEvaluation()}
      </div>;
    }
  }
};

