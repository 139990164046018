import { data as source } from '../downloaded/backgrounds.mjs';
import { data as perktree0 } from '../downloaded/perktree.mjs';

const perktree = perktree0.map((p) => {
  p.weight = 0 | p.weight;
  return p;
});

export const backgrounds = [];
for (const row of source) {
  let {
    key,
    name,
    decision,
    bravery,
    focus,
    reaction,
    toughness,
    precision,
    vocations,
    modifiers,
    contract,
    nationalities,
    area,
    traits,
    trait_rule,
    ambition,
    ambition_modifiers,
    ambition_traits_gain,
    ambition_traits_lose,
    background_perk_group,
    descr,
  } = row;

  const perk_groups = perktree.filter((p) => {
    return p.source === 'background' && p.source_group === background_perk_group;
  });

  backgrounds.push({
    key,
    name,

    stats2: {
      decision: +decision,
      bravery: +bravery,
      focus: +focus,
      reaction: +reaction,
      toughness: +toughness,
      precision: +precision,
    },

    vocations: vocations.split(',').map((v) => v.trim().toLowerCase()),
    modifiers: modifiers.split(',').map((v) => v.trim()).filter((t) => t.length > 0),
    contract,
    nationalities: nationalities.split(',').map((v) => v.trim()).filter((v) => v.length > 0),
    area: parseInt(area),
    traits: traits.split(',').map((v) => v.trim()),
    trait_rule,
    ambition,
    ambition_modifiers: ambition_modifiers.split(',').map((v) => v.trim()).filter((t) => t.length > 0),
    ambition_traits_gain: ambition_traits_gain.split(',').map((v) => v.trim()).filter((t) => t.length > 0),
    ambition_traits_lose: ambition_traits_lose.split(',').map((v) => v.trim()).filter((t) => t.length > 0),
    background_perk_group,
    perk_groups,
    descr,
  });
}

