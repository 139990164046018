import * as React from 'react';
import _ from 'lodash';
import './ResearchView.css';

import { researches, research_keys } from './data/google/processor/data_research';

export const COMPLETE_CONDS = [
  { key: 'monthly_income', 'name': '월 수입', unit: 1000, def: 0 },
  { key: 'renown', 'name': '명성', unit: 10, def: 0 },
  { key: 'milestone_clear', 'name': '마일스톤 임무 달성', unit: 1, def: 0 },
  { key: 'branch_expand', 'name': '지부 설립', unit: 1, def: 1 },
];

export function ResearchEffectLabel(props) {
  const { effect: { key, value } } = props;

  const data = research_keys.find((keys) => keys.key === key);
  if (!data) {
    return `${key}: ${value}`;
  }

  return data.descr_tmpl.replace("{}", value);
}

export class ResearchProgress {
  constructor(props) {
    if (props) {
      Object.assign(this, props);
    } else {
      this.initialize();
    }
  }

  initialize() {
    this.unlocks = [];
    this.inprogress = null;
    this.tick = 0;

    this.effects_once = [];
    this.effects_sets = {};
    this.effects_acc = {};

    this.conditions = {};
    for (const { key, def } of COMPLETE_CONDS) {
      this.conditions[key] = def;
    }
  }

  static prereq0(research, unlocks) {
    if (research.prerequisities.length === 0) {
      return false;
    }

    for (const pr of research.prerequisities) {
      if (unlocks.find((u) => u.key === pr)) {
        return true;
      }
    }
    return false;
  }


  static prereq(research, unlocks) {
    if (research.prerequisities.length === 0) {
      return true
    }

    if (research.prerequisite_cond === 'and') {
      for (const pr of research.prerequisities) {
        if (!unlocks.find((u) => u.key === pr)) {
          return false;
        }
      }
      return true;
    } else {
      for (const pr of research.prerequisities) {
        if (unlocks.find((u) => u.key === pr)) {
          return true;
        }
      }
      return false;
    }
  }

  // research0을 기준으로, research1이 무엇인가
  static rel(research0, research1) {
    if (research0 === null) {
      return 'none';
    }

    if (research0.key === research1.key) {
      return 'self';
    }
    if (ResearchProgress.prereq0(research0, [research1])) {
      return 'child';
    }
    if (ResearchProgress.prereq0(research1, [research0])) {
      return 'parent';
    }
    if (research0.exclusives.includes(research1.key)) {
      return 'exclusive';
    }
    return 'none';
  }

  state(research) {
    // locked, active, available, inprogress, invalid
    if (this.unlocks.find((u) => u.key === research.key)) {
      return 'active';
    }

    // check invalid
    if (this.unlocks.find((u) => u.exclusives.includes(research.key))) {
      return 'invalid';
    }

    if (this.inprogress?.key === research.key) {
      return 'inprogress';
    }

    if (ResearchProgress.prereq(research, this.unlocks)) {
      return 'available';
    }

    return 'locked';
  }

  onStateTransit(research) {
    const state = this.state(research);

    if (['active', 'invalid', 'locked'].includes(state)) {
      return;
    }

    if (research.duration === 0 || state === 'inprogress') {
      return;
    }

    if (state === 'available') {
      // available -> inprogress
      this.inprogress = {
        key: research.key,
        duration: research.duration,
        start_at: this.tick,
        end_at: this.tick + research.duration,
      };
      return;
    }

    throw new Error('invalid state: ' + state);
  }

  conditionMax(key, value) {
    const unlocks_len = this.unlocks.length;

    this.conditions[key] = Math.max(this.conditions[key], value);
    this.onUpdatePassiveResearches();

    return this.unlocks.slice(unlocks_len);
  }

  onResearchFinish(research) {
    for (const effect of research.effects) {
      const eff_data = research_keys.find((keys) => keys.key === effect.key);
      const ty = eff_data?.ty ?? 'acc';

      switch (ty) {
        case 'once':
          this.effects_once.push(effect);
          break;
        case 'set':
          const cur = this.effects_sets[effect.key] ?? [];
          cur.push(effect.value);
          this.effects_sets[effect.key] = cur;
          break;
        case 'acc':
          this.effects_acc[effect.key] = (this.effects_acc[effect.key] ?? 0) + effect.value;
          break;
        default:
          throw new Error('invalid effect type: ' + ty);
      }
    }

    this.unlocks.push(research);
  }

  onEffectClaim(effect) {
    this.effects_once = this.effects_once.filter((e) => e !== effect);
  }

  onTick(ticks) {
    const unlocks_len = this.unlocks.length;

    this.tick += ticks;
    if (this.inprogress?.end_at <= this.tick) {
      const research = researches.find((f) => f.key === this.inprogress.key);
      this.onResearchFinish(research);
      this.inprogress = null;
    }

    this.onUpdatePassiveResearches();

    return this.unlocks.slice(unlocks_len);
  }

  onUpdatePassiveResearches() {
    for (const research of researches) {
      if (research.duration > 0) {
        continue;
      }
      if (this.unlocks.find((u) => u.key === research.key)) {
        continue;
      }

      const state = this.state(research);
      if (state !== 'available') {
        continue;
      }

      const cur = this.conditions[research.complete_cond];
      if (cur < research.complete_value) {
        continue;
      }

      this.onResearchFinish(research);
    }
  }
}

export function ResearchEffects(props) {
  const { progress, onEffectClaim } = props;
  return <>
    <div className="research-effect-title">once</div>
    {progress.effects_once.map((effect) => {
      let claimbtn = null;
      if (onEffectClaim) {
        claimbtn = <button onClick={() => onEffectClaim(effect)}>claim</button>;
      }
      return <div key={effect.key}>{effect.key}: {effect.value} {claimbtn}</div>;
    })}
    <div className="research-effect-title">set</div>
    {Object.entries(progress.effects_sets).map(([key, values]) => {
      return <div key={key}>{key}: {values.join(', ')}</div>;
    })}
    <div className="research-effect-title">acc</div>
    {Object.entries(progress.effects_acc).map(([key, value]) => {
      return <div key={key}>{key}: {value}</div>;
    })}
  </>;
}

export class ResearchTable extends React.Component {
}

export class ResearchView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      researchOver: null,
    };
  }

  renderTable() {
    if (!this.props.table) {
      return null;
    }

    let mainline = researches.filter((r) => r.narrative);
    for (let i = 0; i < mainline.length; i++) {
      mainline[i].row = i;
    }
    let cols = Array.from(new Set(researches.map((r) => r.col)));
    cols.sort((a, b) => a - b);

    let columns = [];
    for (const col of cols) {
      if (col > 0 && col % 10 === 0) {
        columns.push(<tr key={col - 1}>.</tr>);
      }
      let rows = researches.filter((r) => r.col === col);
      let tds = [];
      for (const r of rows) {
        let prereqs = r.prerequisities.map((p) => researches.find((r) => r.key === p));
        let prereq_idx = _.max(prereqs.map((r) => r.row));

        while (prereq_idx >= tds.length) {
          tds.push(<td key={tds.length} className="research-table-empty"></td>);
        }

        if (isNaN(r.row)) {
          r.row = prereq_idx + 1;
        }

        const { relCls, name, s } = this.researchState(r);
        tds.push(<td key={tds.length} className={relCls}
          onMouseOver={() => this.setState({ researchOver: r })}
          onMouseOut={() => this.setState({ researchOver: null })}
        >{s}
          <br />
          <b>{name}</b>
          <br />
          <i>보상: {r.effects.map((eff) => {
            return <ResearchEffectLabel key={eff.key} effect={eff} />;
          })}
          </i>
        </td>);
      }

      columns.push(<tr key={col}>
        {tds}
      </tr>);
    }

    return <div>
      <table>
        <tbody>
          {columns}
        </tbody>
      </table>
    </div>;
  }

  researchState(research) {
    const { progress, onStateTransit } = this.props;
    const { researchOver } = this.state;

    const state = progress.state(research);
    const rel = ResearchProgress.rel(researchOver, research);

    const relCls = `research-rel-${rel}`;
    const stateCls = `research-color research-color-${state}`;
    // <span className="research-reward">{research.effect_text}</span><br />
    let { name } = research;
    let body = <>
      {research.descr}<br />
      {research.effects.length === 0 ? null : <span className="research-reward">
        보상: {research.effects.map((eff) => {
          return <ResearchEffectLabel key={eff.key} effect={eff} />;
        })}
      </span>}
    </>;
    if (research.narrative && state === 'locked') {
      name = '???';
      body = '???';
    }

    let extra = null;
    if (state === 'inprogress') {
      extra = <span className="research-progress">
        ({progress.tick - progress.inprogress.start_at}/{progress.inprogress.duration} days)
      </span>;
    } else if (['available', 'locked'].includes(state)) {
      extra = <span className="research-progress">
        ({research.duration} days)
      </span>;
    }

    let s = <span className={stateCls}
      onClick={() => onStateTransit(research)}
    >{state}{extra}</span>;

    if (state !== 'active' && research.complete_cond) {
      const condItem = COMPLETE_CONDS.find((c) => c.key === research.complete_cond);
      s = <span className="research-cond">
        {condItem.name}: {progress.conditions[condItem.key]}/{research.complete_value}
      </span>;
    }
    return { relCls, name, body, s };
  }

  renderList() {
    return <div className='research-root'>
      <table>
        <thead>
          <tr>
            <th>제목</th>
            <th>설명</th>
            <th>진행</th>
          </tr>
        </thead>
        <tbody>
          {researches.map((research) => {
            const { relCls, name, body, s } = this.researchState(research);

            return <tr key={research.key} className={relCls}
              onMouseOver={() => this.setState({ researchOver: research })}
              onMouseOut={() => this.setState({ researchOver: null })}
            >
              <td>{name}</td>
              <td>{body}</td>
              <td>{s}</td>
            </tr>
          })}
        </tbody>
      </table>
    </div>;
  }

  render() {
    return <div className='research-root'>
      {this.props.table ? this.renderTable() : this.renderList()}
    </div>;
  }
}

export class ResearchRoot extends React.Component {
  constructor(props) {
    super(props);

    let progress = new ResearchProgress();

    this.state = {
      progress,
    };
  }

  onStateTransit(research) {
    const { progress } = this.state;
    progress.onStateTransit(research);
    this.setState({ progress });
  }

  render() {
    const { progress } = this.state;

    return <>
      <div>
        tick={progress.tick}
        <button onClick={() => {
          progress.onTick(7);
          this.setState({ progress });
        }}>tick</button>

        conditions
        {COMPLETE_CONDS.map((cond) => {
          return <div key={cond.key}>
            {cond.name}: {progress.conditions[cond.key]}
            <button onClick={() => {
              progress.conditions[cond.key] += cond.unit;
              progress.onUpdatePassiveResearches();
              this.setState({ progress });
            }}>+</button>
          </div>;
        })}
      </div>
      <ResearchEffects progress={progress} onEffectClaim={this.props.onEffectClaim} />
      <ResearchView progress={progress} onStateTransit={this.onStateTransit.bind(this)} table={true} />
    </>;
  }
}
