import { data as source } from '../downloaded/fatigue.mjs';


const list = [];
for (const row of source) {
  let { idx, min_stamina, max_stamina, name } = row;
  list.push({
    idx,
    min_stamina,
    max_stamina,
    name,
  });
}

export function getFatigueData(stamina) {
  return list.find((e) => e.min_stamina <= stamina && e.max_stamina > stamina);
}
