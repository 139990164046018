import { data as source } from '../downloaded/enemies.mjs';

export const data = [];
for (const row of source) {
  let { threat_level, life_min, life_max, weight, power, firearm_rate, gear_vest_rate, color, firearm_types } = row;
  data.push({
    threat_level: 0 | threat_level,
    life_min: 0 | life_min,
    life_max: 0 | life_max,
    weight: +weight,
    power: +power,
    firearm_rate: 0 | firearm_rate,
    gear_vest_rate: 0 | gear_vest_rate,
    color,
    firearm_types: firearm_types.split(',').map(ty => ty.trim()),
  });
}
