export function HorizontalInfoView(props) {
  const { pairs } = props;
  let className = props.className ?? '';
  className += ' flex-horizontal-info';

  return <div className={className}>
    {
      pairs.map(({ key, value }, i) => {
        return <div key={i}>
          <div className="flex-horizontal-info-key">{key}</div>
          <div className="flex-horizontal-info-value">{value}</div>
        </div>;
      })
    }
  </div >;
}
