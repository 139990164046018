import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './gameface';
import Main from './Main';
import { BrowserRouter } from "react-router-dom";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";


// HMR
module.hot?.accept();

if (process.env.NODE_ENV === "production" && !window.gameface) {
  Sentry.init({
    dsn: "https://0c76e3ef7ac540fa8880c33cf610c6d3@sentry.citrus.sh/42",
    release: process.env.REACT_APP_SENTRY_VERSION,
    integrations: [new BrowserTracing()],

    tracesSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Main />
  </BrowserRouter>
);
