/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const PerkLevel = ({ PTPerklevel = "1", className }) => {
  return (
    <div className={`perk-level ${className}`}>
      <div className="overlap-group-15">
        <div className="outer-bg">
          <div className="inner-line" />
        </div>
        <div className="PT-perklevel">{PTPerklevel}</div>
      </div>
    </div>
  );
};

PerkLevel.propTypes = {
  PTPerklevel: PropTypes.string,
};
