// downloaded data!
export const data = [
  {
    "idx": "0",
    "min_stamina": "0",
    "max_stamina": "1",
    "name": "loc_data_string_fatigue_0"
  },
  {
    "idx": "1",
    "min_stamina": "1",
    "max_stamina": "2",
    "name": "loc_data_string_fatigue_1"
  },
  {
    "idx": "2",
    "min_stamina": "2",
    "max_stamina": "3",
    "name": "loc_data_string_fatigue_2"
  },
  {
    "idx": "3",
    "min_stamina": "3",
    "max_stamina": "4",
    "name": "loc_data_string_fatigue_3"
  },
  {
    "idx": "4",
    "min_stamina": "4",
    "max_stamina": "5",
    "name": "loc_data_string_fatigue_4"
  }
];