// downloaded data!
export const data = [
  {
    "num_subdiv": "0",
    "0_missions": "1",
    "1_missions": "0",
    "2_missions": "0",
    "3_missions": "0",
    "4_missions": "0",
    "5_missions": "0",
    "6_missions": "0",
    "7_missions": "0",
    "8_missions": "0",
    "9_missions": "0",
    "10_missions": "0"
  },
  {
    "num_subdiv": "1",
    "0_missions": "0.5",
    "1_missions": "0.5",
    "2_missions": "0",
    "3_missions": "0",
    "4_missions": "0",
    "5_missions": "0",
    "6_missions": "0",
    "7_missions": "0",
    "8_missions": "0",
    "9_missions": "0",
    "10_missions": "0"
  },
  {
    "num_subdiv": "2",
    "0_missions": "0.28",
    "1_missions": "0.44",
    "2_missions": "0.24",
    "3_missions": "0.04",
    "4_missions": "0",
    "5_missions": "0",
    "6_missions": "0",
    "7_missions": "0",
    "8_missions": "0",
    "9_missions": "0",
    "10_missions": "0"
  },
  {
    "num_subdiv": "3",
    "0_missions": "0.13",
    "1_missions": "0.38",
    "2_missions": "0.38",
    "3_missions": "0.11",
    "4_missions": "0",
    "5_missions": "0",
    "6_missions": "0",
    "7_missions": "0",
    "8_missions": "0",
    "9_missions": "0",
    "10_missions": "0"
  },
  {
    "num_subdiv": "4",
    "0_missions": "0.04",
    "1_missions": "0.24",
    "2_missions": "0.44",
    "3_missions": "0.24",
    "4_missions": "0.04",
    "5_missions": "0",
    "6_missions": "0",
    "7_missions": "0",
    "8_missions": "0",
    "9_missions": "0",
    "10_missions": "0"
  },
  {
    "num_subdiv": "5",
    "0_missions": "0.02",
    "1_missions": "0.11",
    "2_missions": "0.38",
    "3_missions": "0.38",
    "4_missions": "0.11",
    "5_missions": "0",
    "6_missions": "0",
    "7_missions": "0",
    "8_missions": "0",
    "9_missions": "0",
    "10_missions": "0"
  },
  {
    "num_subdiv": "6",
    "0_missions": "0",
    "1_missions": "0.04",
    "2_missions": "0.24",
    "3_missions": "0.44",
    "4_missions": "0.24",
    "5_missions": "0.04",
    "6_missions": "0",
    "7_missions": "0",
    "8_missions": "0",
    "9_missions": "0",
    "10_missions": "0"
  },
  {
    "num_subdiv": "7",
    "0_missions": "0.02",
    "1_missions": "0",
    "2_missions": "0.11",
    "3_missions": "0.38",
    "4_missions": "0.38",
    "5_missions": "0.11",
    "6_missions": "0",
    "7_missions": "0",
    "8_missions": "0",
    "9_missions": "0",
    "10_missions": "0"
  },
  {
    "num_subdiv": "8",
    "0_missions": "0",
    "1_missions": "0",
    "2_missions": "0.04",
    "3_missions": "0.24",
    "4_missions": "0.44",
    "5_missions": "0.24",
    "6_missions": "0.04",
    "7_missions": "0",
    "8_missions": "0",
    "9_missions": "0",
    "10_missions": "0"
  },
  {
    "num_subdiv": "9",
    "0_missions": "0.02",
    "1_missions": "0",
    "2_missions": "0",
    "3_missions": "0.11",
    "4_missions": "0.38",
    "5_missions": "0.38",
    "6_missions": "0.11",
    "7_missions": "0",
    "8_missions": "0",
    "9_missions": "0",
    "10_missions": "0"
  },
  {
    "num_subdiv": "10",
    "0_missions": "0",
    "1_missions": "0",
    "2_missions": "0",
    "3_missions": "0.04",
    "4_missions": "0.24",
    "5_missions": "0.44",
    "6_missions": "0.24",
    "7_missions": "0.04",
    "8_missions": "0",
    "9_missions": "0",
    "10_missions": "0"
  }
];