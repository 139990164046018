// downloaded data!
export const data = [
  {
    "label": "E",
    "weight": "50",
    "power_cap": "6",
    "spawn_power_min": "4",
    "spawn_power_max": "5",
    "stat_deviation": "1"
  },
  {
    "label": "D",
    "weight": "34",
    "power_cap": "9",
    "spawn_power_min": "5",
    "spawn_power_max": "6",
    "stat_deviation": "1"
  },
  {
    "label": "C",
    "weight": "10",
    "power_cap": "12",
    "spawn_power_min": "8",
    "spawn_power_max": "9",
    "stat_deviation": "1"
  },
  {
    "label": "B",
    "weight": "5",
    "power_cap": "15",
    "spawn_power_min": "10",
    "spawn_power_max": "11",
    "stat_deviation": "2"
  },
  {
    "label": "A",
    "weight": "0.9",
    "power_cap": "18",
    "spawn_power_min": "12",
    "spawn_power_max": "13",
    "stat_deviation": "2"
  },
  {
    "label": "S",
    "weight": "0.1",
    "power_cap": "20",
    "spawn_power_min": "14",
    "spawn_power_max": "15",
    "stat_deviation": "2"
  }
];