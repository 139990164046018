import { data as source } from '../downloaded/ambitions.mjs';

export const ambitions = [];
for (const row of source) {
  let { key, name, condition } = row;

  ambitions.push({
    key,
    name,
    condition,
  });
}

