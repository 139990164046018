import _ from 'lodash';

import { Rng } from './rand.mjs';
import { names, names3 } from './names.mjs';
import { opts, PARAMS } from './opts.mjs';
import { v2, dirnorm, dirnorm0, dircontains } from './v2.mjs';
import { createStructures, placeStructures } from './room.mjs';
import { World } from './world.mjs';
import { TickTimer } from './ticktimer.mjs';
import {
  Route,
  UnionPoly,
  bisectEdge,
  checkcover,
  coverEdges,
  createObstacle,
  findReachables,
  geomContains,
  geomSamplePoint,
  geomSamplePointWithinPolygon,
  obstructed,
  obstructed_t,
  onReachableGridWasm,
  onReachableGridWasmApply,
  overlapped,
  raycastVisibilityWasm,
  raycastWasm,
  routeNodeFromPos,
  routePathfind,
  routePathfindAll,
  shrinkRect,
} from './geom.mjs';
import { stats_const, updateEntityStat } from './stat.mjs';
import { presets } from './presets_obstacles.mjs';

const lerp = function (a, b, t) {
  return a + (b - a) * t;
}
const clamp = function (x, min, max) {
  return Math.min(Math.max(x, min), max);
}

function lineToPointDist(p, dir, target) {
  return Math.abs(Math.cos(dir) * (p.x - target.x) + Math.sin(dir) * (p.y - target.y));
}

function projectile_vertvar(variance) {
  return Math.min(Math.PI / 128, variance / 3);
}

// eslint-disable-next-line
function projectile_dice2d(rng, pos, targetpos, dir, variance) {
  const aimdirmin = dir - variance;
  const aimdirmax = dir + variance;

  const firedir = rng.range(aimdirmin, aimdirmax);
  let dist = lineToPointDist(pos, firedir, targetpos);

  const vertvar = projectile_vertvar(variance);
  const firedir_v = rng.range(dir - vertvar, dir + vertvar);
  let dist_v = lineToPointDist(pos, firedir_v, targetpos);

  if (dist > dist_v) {
    return firedir;
  } else {
    return firedir_v;
  }
}

// n번 주사위를 굴려서 제일 안 좋은 방향을 샘플링합니다.
function projectile_dice(rng, pos, targetpos, dir, variance, runcount) {
  const aimdirmin = dir - variance;
  const aimdirmax = dir + variance;

  let maxdist = 0;
  for (let i = 0; i < runcount; i++) {
    const firedir = rng.range(aimdirmin, aimdirmax);
    const dist = lineToPointDist(pos, firedir, targetpos);
    if (dist > maxdist) {
      dir = firedir;
      maxdist = dist;
    }
  }
  return dir;
}

function obstacleFilterVisible(o) {
  if (o.ty === 'half') {
    return false;
  }
  if (o.ty === 'door' && o.doorstate.open) {
    return false;
  }
  return true;
}

function obstacleFilterReachable(o) {
  if (o.ty === 'half') {
    return true;
  }
  if (o.ty === 'door' && o.doorstate.open) {
    return false;
  }
  return true;
}

// 벽과 닫힌 문
function obstacleFilterBlock(o) {
  if (o.ty === 'door' && o.doorstate.open) {
    return false;
  }
  return true;
}

class Queue {
  constructor(capacity) {
    this.arr = [];
    this.capacity = capacity;
  }

  push(item) {
    this.arr.push(item);
    while (this.arr.length > this.capacity) {
      this.arr.splice(0, 1);
    }
  }

  includes(item) {
    return this.arr.includes(item);
  }
}

export const AREA_CONFIG_TMPL = {
  pos: new v2(60, 300),
  size: new v2(40, 200),
  heading: 0,
};

function controlDefaults(tick) {
  return {
    holdpos: false,
    throwable: true,
    cover: true,
    search: false,
    heal: opts.HEAL_THRES_DEFAULT,
    mobility: opts.MOBILITY_LEVEL_DEFAULT,

    reorg: false,

    state: 'explore',

    reorgTimer: new TickTimer(tick, 0),

    ticks_reorg: 0,
  };
}

export class Entity {
  constructor(rng, tick, extra) {
    // other props
    this.name = rng.choice(names);
    if (extra.team === 0) {
      this.name = rng.choice(names3);
    }

    // === life & armor ===
    this.life = extra.life;
    this.life_max = extra.life_max ?? this.life;

    if (extra.vest_armor) {
      extra.armor = extra.vest_armor;
    }
    if (extra.vest_hit_prob) {
      extra.armor_hit_prob = extra.vest_hit_prob;
    }
    this.armor = extra.armor ?? 0;
    this.armor_max = this.armor;


    // === ammo ===
    // TODO: explore demo
    this.ammo_total = extra.ammo_total ?? 100000;

    this.firearm_ammo_max = extra.firearm_ammo_max;
    this.ammo = Math.min(this.ammo_total, this.firearm_ammo_max);

    // === aim & movement ==
    this.pos = new v2(0, 0);
    this.gridpos = new v2(0, 0);

    // 이동 방향 reference. entityNavigate 등에서 정해짐
    this.dir = Math.PI / 2;
    // 현재 바라보는 방향
    this.aimdir = Math.PI / 2;
    this.debugaimdir = this.aimdir;
    this.aimvar = extra.aimvar_hold;
    this.aimmult = 1;
    this.aimtarget = null;
    this.aimtargetshoots = 0;

    // === states ===
    this.state = 'stand';
    this.moving = false;
    this.waypoint = null;
    this.waypoint_policy = extra.waypoint_policy ?? 'none';
    this.movestate = 'walk';
    this.movespeed = 0;
    this.recent_visits = new Queue(opts.RECENT_VISITS_COUNT);

    const tick0 = tick - 1;

    // crawl하면 일정 시간 일어날 수 없습니다.
    this.crawlTick = new TickTimer(tick0, 0);

    // 사격 패턴 중 다음 사격 타이머
    this.shootPatternTick = new TickTimer(tick0, 0);
    this.shootPatternIdx = 0;

    this.reloadTick = new TickTimer(tick0, 0);
    this.reloadShootIdleTick = new TickTimer(tick0, 0);

    this.lastRouteTick = new TickTimer(tick0, 0);
    this.lastRiskTick = new TickTimer(tick0, 0);

    this.checkThrowableTick = new TickTimer(tick0, 0);
    this.idleAlertTick = new TickTimer(tick0, 0);

    this.retargetTick = new TickTimer(tick0, 0);

    this.unaimTick = new TickTimer(tick0, 0);

    // healTick: 회복 끝나는 순간
    this.healTick = new TickTimer(tick0, 0);
    this.collectTick = new TickTimer(tick0, 0);

    // 테스트: unaim pause
    this.unaimPauseTick = new TickTimer(tick0, 0);

    this.deadTick = 0;

    if (extra) {
      for (const key in extra) {
        this[key] = extra[key];
      }
    }
    this.rules = [];
    if (typeof this.default_rule === 'string') {
      this.rules.push({ ty: this.default_rule, tick });
    } else {
      const rule = { ...this.default_rule, tick };
      this.rules.push(rule);
    }

    this.waypoints = extra.waypoints ?? null;

    // temperal effects
    this.effects = [];

    // awareness
    this.awareness = [];

    // posessions
    this.objects = [];

    // in-game icons
    this.icons = [];
  }

  get waypoint_rule() {
    return this.rules[this.rules.length - 1];
  }

  has_rule(ty) {
    return this.rules.find((r) => r.ty === ty) !== undefined;
  }

  // ty
  // initiator: 교전 등이 발생했을 때의 상대
  // expires_at: expires_at 뒤에 없어짐
  // area: explore 할 때 explore할 영역
  // follow_target: 추적 대상. 구출한 뒤 인질에게 적용
  // rescue_target: 구출 대상
  // gather: 모이기
  // goal: cover-goal, capture-goal
  // interact: interact, interact-object
  //
  // args
  // leader: gather 등에서 사용
  // transient: control로 외부에서 들어온 명령
  push_rule(tick, rule) {
    this.mark_rule(false);
    this.rules.push({
      ...rule,
      tick,
    });
  }

  alter_rule(rule_next, rule_expected) {
    let last = this.waypoint_rule;
    if (last.ty === rule_expected) {
      last.ty = rule_next;
      this.mark_rule(false);
      return true;
    }
    return false;
  }

  pop_rule_chain(fn) {
    let found = this.rules.findIndex(fn);
    if (found > 0) {
      this.rules.splice(found);
    }
  }

  pop_rule() {
    if (this.rules.length === 1) {
      console.error("could not pop last rule");
      return null;
    }
    this.mark_rule(false);
    return this.rules.pop();
  }

  mark_rule(marked) {
    this._rule_marked = marked;
  }

  get rule_marked() {
    return this._rule_marked;
  }

  get rule_awaken() {
    if (this.rules.length < 2) {
      return false;
    }
    const base_ty = this.rules[0].ty;
    return base_ty === 'idle' && base_ty !== this.waypoint_rule.ty;
  }
}

function opponentTeam(team) {
  if (team === 0) {
    return [1];
  } else {
    return [0];
  }
}

const MESSAGES = [
  '오늘 저녁은 뭐지',
  '무사히 돌아가야 할텐데',
  '신이시여',
  '덥고 끈적끈적해',
  '집에 가고 싶어',
  '언제까지 이렇게 살아야 하지',
  '무서워',
];

export class Simulator {
  static create(props) {
    const sim = new Simulator(props);

    sim.routes = new Route(props.m, sim);
    const { world, entities } = sim;

    if (props.seed_placement) {
      sim.rng = new Rng(props.seed_placement);
    }

    // spawn entities
    for (const config of props.entities) {
      const entity = sim.spawnEntity(config);
      if (!entity) {
        console.error('failed to spawn entity');
      }
      if (entity) {
        sim.entitySetMobilityLevel(entity, opts.MOBILITY_LEVEL_DEFAULT);
        if (entity.team === 0) {
          entity.allow_cover_edge = true;
          // entity.perk_cover_dash = true;
        }
      }
    }
    for (let i = 0; i < props.entities.length; i++) {
      const pe = props.entities[i];
      if (!isNaN(pe.leader)) {
        sim.entities[i].leader = sim.entities[pe.leader];
      }
    }

    // spawn objects
    const objects = props.objects ?? [];
    for (let i = 0; i < objects.length; i++) {
      const object = objects[i];
      const pos = sim.spawnPos(object);
      sim.objects.push({ ...object, pos, owned: false, seq: i });
    }

    // 같은 팀끼리 grid를 공유합니다.
    for (const entity of entities) {
      let sharefn = null;
      if (!isNaN(entity.vis_group)) {
        sharefn = (e) => e.vis_group === entity.vis_group;
      }
      if (sharefn === null) {
        continue;
      }
      // 인지 정보를 공유합니다: 탐색, awareness
      const src = entities.find(sharefn);
      entity.grid_explore = src.grid_explore;
      entity.awareness = src.awareness;
    }

    for (const entity of entities) {
      if (!entity.use_visibility) {
        continue;
      }

      sim.entityUpdateGridOmniDir(entity, entity.pos, true);
    }

    if (world.exp_prepopulate_grid) {
      const old = opts.GRID_VIS_PER_TICK;
      opts.GRID_VIS_PER_TICK = 1;

      for (const entity of entities) {
        if (!entity.use_visibility) {
          continue;
        }

        if (entity.team !== 0) {
          continue;
        }

        for (let i = 0; i < 4; i++) {
          let x = world.width * i / 3 - world.width / 2;
          let y = world.height * i / 3 - world.height / 2;

          sim.entityUpdateGridOmniDir(entity, new v2(-world.width / 2 + 1, y));
          sim.entityUpdateGridOmniDir(entity, new v2(world.width / 2 - 1, y));

          sim.entityUpdateGridOmniDir(entity, new v2(x, -world.height / 2 + 1));
          sim.entityUpdateGridOmniDir(entity, new v2(x, world.height / 2 - 1));
        }
      }

      opts.GRID_VIS_PER_TICK = old;
    }

    return sim;
  }

  constructor(props) {
    const { obstacle_specs } = props;
    const world = new World(props.world);
    let { seed } = props;
    let obstacles = [];
    const rng = new Rng(seed);

    const areas = props.spawnareas.map((area, i) => {
      const obs = createObstacle(area.pos, area.extent, area.heading, null, true, null);
      obs.name = `area #${i}`;
      if (area.effect_ty) {
        obs.name += ` (${area.effect_ty})`;
      }
      obs.areastate = {
        area,
        vacate: area.vacate ?? false,
        triggers: area.triggers ? area.triggers.slice() : [],
      };
      return obs;
    });

    function sampleObstacle(width, height, extent_margin, ty, area) {
      for (let i = 0; i < 100; i++) {
        const pos = geomSamplePoint(rng, area);
        const rot = rng.angle();

        const obs = createObstacle(pos, new v2(width, height), rot, ty, false, extent_margin);
        if (obstacles.find((o) => overlapped(o.routepoints, obs.routepoints))) {
          continue;
        }

        return obs;
      }
      console.error('failed to sample obstacle');
      return null;
    }


    for (const area of areas) {
      if (area.areastate.area.structureopts === undefined) {
        continue;
      }
      const { count, obstacle_count, enterance, heading } = area.areastate.area.structureopts;

      // TODO: heading
      const pos = area.areastate.area.pos;
      const extent = area.areastate.area.extent;

      const placements = placeStructures(rng, { pos, extent, count, heading });
      area.areastate.placements = placements.map((p) => createObstacle(p.pos, p.extent, p.dir, null, true, null));

      const structures = createStructures(rng, placements, { enterance });
      area.areastate.structures = structures;

      for (const s of structures) {
        const { structure, extent, offset, dir } = s;
        const pos_s = structure.pos.add(offset);

        s.shape = createObstacle(pos_s, extent, dir, 'structure', true, null);

        for (const wall of structure.walls) {
          const { start, end } = wall;

          const pos = v2.lerp(start, end, 0.5).add(offset);
          const extent = end.sub(start).mul(0.5);

          obstacles.push(createObstacle(pos.rot(pos_s, dir), extent, dir, 'full', false, v2.unit(12)));
        }

        for (const room of structure.squarifyTreemap) {
          const tl = new v2(room.x0, room.y0);
          const br = new v2(room.x1, room.y1);
          const pos = v2.lerp(tl, br, 0.5).add(offset);
          const extent = br.sub(tl).mul(0.5);

          room.shape = createObstacle(pos.rot(pos_s, dir), extent, dir, 'room', false, null);
        }

        for (const wall of structure.doors) {
          const { start, end } = wall;
          const pos = v2.lerp(start, end, 0.5).add(offset);
          const extent = end.sub(start).mul(0.5);

          let extent_margin = new v2(1, 4);
          if (extent.y > extent.x) {
            extent_margin = new v2(4, 1);
          }

          const o = createObstacle(pos.rot(pos_s, dir), extent, dir, 'door', false, extent_margin);

          obstacles.push(o);
        }

        if (!obstacle_count) {
          continue;
        }

        const target_count = obstacles.length + obstacle_count;
        while (obstacles.length < target_count) {

          const width = rng.range(2, 4);
          const height = rng.range(5, 9);
          const obs = sampleObstacle(width, height, new v2(5, 5), 'half', s.shape);
          if (obs === null) {
            break;
          }
          obstacles.push(obs);
        }
      }
    }

    // obstacles
    for (const obstacle_spec of obstacle_specs) {
      if (obstacle_spec.random === undefined) {
        // TODO: 개선해야 함
        const obs = createObstacle(v2.from(obstacle_spec.pos),
          v2.from(obstacle_spec.extent),
          obstacle_spec.heading,
          obstacle_spec.ty,
          obstacle_spec.no_coverpoint ?? false,
          obstacle_spec.extent_margin
        );
        if (obstacle_spec.ty === 'door') {
          obs.doorstate.group = obstacle_spec.doorgroup ?? null;
          obs.doorstate.name = obstacle_spec.doorname ?? null;
        }

        if (obstacle_spec.name) {
          obs.name = obstacle_spec.name;
        }
        if (obstacle_spec.imported) {
          obs.imported = obstacle_spec.imported;
        }
        if (obstacle_spec.wip) {
          obs.wip = obstacle_spec.wip;
        }
        obstacles.push(obs);
        continue;
      }

      const target_count = obstacles.length + obstacle_spec.random.count;
      const area_heading = obstacle_spec.heading ?? 0;
      const obstacle_area = createObstacle(
        obstacle_spec.pos,
        obstacle_spec.extent,
        area_heading,
        '',
        false,
        null);

      while (obstacles.length < target_count) {
        let ty = obstacle_spec.random.ty ?? 'full';
        if (ty === 'mixed') {
          ty = rng.choice(['full', 'half']);
        }

        let obs = null;
        if (obstacle_spec.random.presets) {
          let candidates = presets.filter((p) => {
            return ((ty === 'half') !== (p.extent[2] < 70));
          });
          let preset = rng.choice(candidates);
          const width = preset.extent[0] / 10;
          const height = preset.extent[1] / 10;

          obs = sampleObstacle(width, height, new v2(10, 10), ty, obstacle_area);
          if (obs) {
            obs.name = preset.name;
            obs.fullname = preset.fullname;
          }
        } else {
          const width = obstacle_spec.random.extent?.x ?? rng.range(4, 8);
          const height = obstacle_spec.random.extent?.y ?? rng.range(15, 50);

          obs = sampleObstacle(width, height, new v2(10, 10), ty, obstacle_area);
        }

        if (obs === null) {
          break;
        }
        if (areas.find((o) => o.areastate.vacate && overlapped(o.routepoints, obs.routepoints))) {
          continue;
        }

        obstacles.push(obs);
      }
    }

    const { GOAL_SIZE } = opts;
    const goals = props.goals.map((g) => {
      const area = areas[g.area];

      let goal = null;
      for (let i = 0; i < 100; i++) {
        const pos = geomSamplePoint(rng, area);
        const rot = rng.angle();

        // TDO:
        const obs = createObstacle(pos, new v2(GOAL_SIZE, GOAL_SIZE), rot, 'half', false, null);
        obs.ty = 'full';
        if (!obstacles.find((o) => overlapped(o.routepoints, obs.routepoints))) {
          goal = obs;
          break;
        }
      }
      goal.name = g.name;
      goal.waypoint = !!g.waypoint;
      goal.goalstate = { ...opts.GOALSTATE_TMPL };

      return goal;
    });

    for (const goal of goals) {
      obstacles.push(goal);
    }

    // spawn entities
    const tick = 0;
    this.placement_rooms = [];

    // hydration
    function hydrate_prompt_options(options) {
      return options.map((p) => {
        return {
          ...p, actions: p.actions.map((a) => {
            a = { ...a };
            if (a.actionrules) {
              a.actionrules = a.actionrules.map(dydrate_rule);
            }
            return a;
          })
        };
      });
    }
    function dydrate_rule(r) {
      r = { ...r };
      if (!isNaN(r.area)) {
        r.area = areas[r.area];
      }
      if (!isNaN(r.goal)) {
        r.goal = goals[r.goal];
      }
      if (r.prompt_options) {
        r.prompt_options = hydrate_prompt_options(r.prompt_options);
      }
      return r;
    };
    function hydrate_trigger(t) {
      t = { ...t };
      if (t.actionrules) {
        t.mission_rules = t.actionrules.map(dydrate_rule);
      }
      if (t.actionprompts) {
        t.actionprompts = {
          ...t.actionprompts,
          prompt_options: hydrate_prompt_options(t.actionprompts.prompt_options)
        };
      }
      if (t.actionentities) {
        t.actionentities = t.actionentities.map((e) => {
          return {
            ...e,
            default_rule: dydrate_rule(e.default_rule),
          };
        });
      }
      return t;
    }
    this.mission_rules = props.mission_rules.map((r, i) => ({ mission_idx: i, ...dydrate_rule(r) }));

    for (const area of areas) {
      area.areastate.triggers = area.areastate.triggers.map(hydrate_trigger);
    }

    this.world = world;
    this.seed = seed;
    this.rng = rng;
    this.tick = tick;
    this.tps = opts.tps;

    this.entities = [];
    this.throwables = [];
    this.objects = [];

    this.trails = [];
    this.blastareas = [];
    this.spawnareas = areas;
    this.obstacles = obstacles;
    this.goals = goals;
    this.routes = null;

    this.journal = [];
    this.journal.push = function (...args) {
      function lastDuplicateJournalIndex(list, item) {
        const arr = list.map((e, i) => ({ ...e, index: i })).filter((e) => e.ty === item.ty);
        if (item.ty !== 'perk') {
          return -1;
        }

        const subarr = arr.filter((e) => e.perk === item.perk);
        if (subarr.length === 0 || arr[arr.length - 1] !== subarr[subarr.length - 1]) {
          return -1;
        }
        const last = subarr[subarr.length - 1];
        const lastIndex = last.index;

        if (_.isEqual(last.targets, item.targets)) {
          return -1;
        }
        return lastIndex;
      }

      if (!this || this.length === 0) {
        Array.prototype.push.apply(this, args);
      } else {
        const i = lastDuplicateJournalIndex(this, args[0]);
        if (i === -1) {
          Array.prototype.push.apply(this, args);
        } else if (this[i].reps) {
          this[i].reps++;
        } else {
          this[i].reps = 2;
        }
      }
    };
    this.perfbuf = [];

    this.rebuildVisibility();
    this.rv = new UnionPoly(props.m, rng, world, obstacles.filter(obstacleFilterVisible));
    this.rr = new UnionPoly(props.m, rng, world, obstacles.filter(obstacleFilterReachable));
    this.rb = new UnionPoly(props.m, rng, world, obstacles.filter(obstacleFilterBlock));

    // buildRoute
    this.rn = new UnionPoly(props.m, rng, world, obstacles.filter((o) => o.ty !== 'door'));
    // riskdir
    this.rt = new UnionPoly(props.m, rng, world, obstacles.filter((o) => o.ty === 'full'));

    this.m = props.m;
    this.pending_actions = [];

    // user-provided controls
    this.controls_list = [];
    this.pending_prompts = [];

    // TODO: per-team?
    this.withdraw = false;

    // TODO: DEMO
    this.door_policy = [];

    this.blastareas = (props.blastareas ?? []).map((b) => {
      const { pos, blast_radius, blast_expose_ty, effect_ty } = b;
      let vismodel = null;
      switch (blast_expose_ty) {
        case 'full':
          vismodel = this.rb;
          break;
        case 'half':
          vismodel = this.rv;
          break;
        default:
          throw new Error(`unknown blast_expose_ty=${blast_expose_ty}`);
      }

      const vis = vismodel.triangulated.visibility(pos.x, pos.y, false);
      vis.limit(blast_radius);

      let ba = {
        pos,
        tick,
        expire_at: tick + this.ticksFromSec(3600),
        vis,
        entities: [],
        effect_ty,
      };
      return ba;
    });

    this.heals = [];
    this.bubbles = [];

    this.stalkers = props.stalkers ?? [];

    // TODO
    this.playerstats = {
      morale: opts.PS_MORALE_DEFAULT,
      uncover: opts.PS_UNCOVER_DEFAULT,

      loot: {
        resource: 0,
        inventory: [],
      },
    };
  }

  free() {
    const t = this;
    function free0(key) {
      if (t[key]) {
        t[key].free();
        t[key] = null;
      }
    }
    free0('rv');
    free0('rr');
    free0('rb');
    free0('rn');
    free0('rt');

    for (const { vis } of this.blastareas) {
      vis.free();
    }

    for (const { vis } of this.entities) {
      vis?.free();
    }
    this.routes?.free();
  }

  // choose onobstructed position with given config
  spawnPos(config) {
    const { rng, obstacles, goals, placement_rooms } = this;
    const { GOAL_RADIUS } = goals;

    let pos = config.pos;
    if (pos) {
      return pos;
    }

    const area = this.spawnareas[config.spawnarea];
    const { structures } = area.areastate;

    while (!pos) {
      if (structures) {
        // sample random placement
        const sample = rng.integer(0, structures.length - 1);
        const { structure } = structures[sample];
        const maps = structure.squarifyTreemap;

        let room = (0 | placement_rooms[sample]);
        placement_rooms[sample] = (room + 1) % maps.length;

        const geom = maps[room].shape;
        pos = geomSamplePoint(rng, geom);
        if (pos === null) {
          return null;
        }
      } else {
        const inset = config.size;
        const safePolygon = shrinkRect(area.polygon, inset);
        pos = geomSamplePointWithinPolygon(rng, safePolygon);
      }

      // TODO: goal에서 spawn 안 되도록
      const pos0 = pos;
      if (obstacles.find((o) => geomContains(pos0, o.routepoints))) {
        pos = null;
        continue;
      }
      if (findReachables(this.routes, pos0).length === 0) {
        pos = null;
        continue;
      }
      if (goals.find((g) => g.pos.dist(pos0) < GOAL_RADIUS)) {
        pos = null;
        continue;
      }
    }

    return pos;
  }

  spawnEntity(config) {
    const { rng, tick, world } = this;

    let pos = this.spawnPos(config);
    const area = this.spawnareas[config.spawnarea];

    config = { ...config };
    if (typeof config.default_rule === 'object') {
      const r = config.default_rule;
      if (!isNaN(r.goal)) {
        r.goal = this.goals[r.goal];
      }
      config.default_rule = r;
    }

    const entity = new Entity(rng, tick, config);
    entity.seq = this.entities.length;
    entity.pos = pos;
    entity.gridpos = world.worldToGrid(pos);

    // heading pos
    entity.dir = config.dir ?? area?.areastate?.area?.spawnheading ?? rng.angle();
    entity.aimdir = entity.dir;

    // visibility grid. 이 안에 있는 적을 탐색할 수 있습니다.
    entity.grid_vis = new Float32Array(world.grid_count);
    entity.grid_vis.fill(0);

    // explore grid. navigation에 사용합니다.
    entity.grid_explore = new Uint8Array(world.grid_count);
    entity.grid_explore.fill(0);

    this.entities.push(entity);
    return entity;
  }

  rebuildVisibility() {
    const { rng, world, obstacles, m } = this;

    // visibility
    if (this.rv) {
      this.rv.free();
      this.rv = new UnionPoly(m, rng, world, obstacles.filter(obstacleFilterVisible));
    }

    // navigation reachability
    if (this.rr) {
      this.rr.free();
      this.rr = new UnionPoly(m, rng, world, obstacles.filter(obstacleFilterReachable));
    }

    // throwables
    if (this.rb) {
      this.rb.free();
      this.rb = new UnionPoly(m, rng, world, obstacles.filter(obstacleFilterBlock));
    }
  }

  debugchoosepoint(_pos, itemFunc, _cmpFunc, includes_routepints) {
    const { routes } = this;
    const items = [];

    for (let i = 0; i < routes.nodes.length; i++) {
      const node = routes.nodes[i];
      if (!includes_routepints && !node.is_coverpoint) {
        continue;
      }

      const query = itemFunc(node.pos, node.obstacle, i);
      items.push({ node, query });
    }

    return items;
  }

  choosepoints(pos, itemFunc, cmpFunc, includes_routepints) {
    const { routes } = this;
    const items = [];

    const tryAddItem = (item) => {
      for (let i = 0; i < items.length; i++) {
        const item0 = items[i];
        const cmp = cmpFunc(item0, item);
        if (cmp > 0) {
          items[i] = item;
          return;
        }
        if (cmp < 0) {
          return;
        }
      }
      items.push(item);
    };

    const dists = routePathfindAll(routes, pos, true);

    for (let i = 0; i < routes.nodes.length; i++) {
      // prune unreachable points
      if (dists[i] === -1) {
        continue;
      }

      const node = routes.nodes[i];
      if (!includes_routepints && !node.is_coverpoint) {
        continue;
      }

      const item = itemFunc(node.pos, node.obstacle, i);
      if (!item) {
        continue;
      }

      tryAddItem(item);
    }

    return items;
  }

  choosepoint(pos, itemFunc, cmpFunc, includes_routepints) {
    const { routes } = this;
    let item = null;

    const dists = routePathfindAll(routes, pos, true);

    for (let i = 0; i < routes.nodes.length; i++) {
      // prune unreachable points
      if (dists[i] === -1) {
        continue;
      }

      const node = routes.nodes[i];
      if (!includes_routepints && !node.is_coverpoint) {
        continue;
      }

      const item2 = itemFunc(node.pos, node.obstacle, i);
      if (!item2) {
        continue;
      }

      // return true if second argument is better than the first one
      if (!item || cmpFunc(item, item2) > 0) {
        item = item2;
      }
    }

    return item;
  }


  choosefirepoint(e1, f) {
    const { routes } = this;

    return (f ?? this.choosepoint.bind(this))(e1.pos, (pos, obstacle) => {
      // TODO: dist: dijkstra
      let dist = pos.dist(e1.pos);
      if (dist < opts.eps) {
        return null;
      }

      const cover = checkcover(pos, e1.pos, routes);
      if (cover === 3) {
        // blocked, cannot fire
        return null;
      }

      return {
        cover,
        dist,
        pos,
        obstacle,
      };
    }, (a, b) => {
      if (b.cover < a.cover || (b.cover === a.cover && b.dist < a.dist)) {
        return 1;
      }
      return 0;
    }, true);
  }

  // 적이 보이지 않은 상태에서, 방향을 정해서 경계
  chooseguardpoint(entity, _f) {
    const { routes } = this;

    const res = this.riskdirDry(entity);
    const { samples_ray, sample_count, selected_idx } = res;

    const ray = samples_ray[selected_idx];
    const dist = entity.pos.dist(ray);
    const dir = selected_idx * Math.PI * 2 / sample_count;

    const dists = routePathfindAll(routes, entity.pos, true);
    const target_pos = entity.pos.add(v2.fromdir(dir).mul(Math.max(10, dist - 10)));

    return this.choosepoint(entity.pos, (pos, obstacle, i) => {
      let dist = dists[i];
      // 상대방을 사격할 수 있는 위치 중 하나를 고릅니다.
      const cover_offence = checkcover(pos, target_pos, routes);
      if (cover_offence === 3) {
        // skip blocked
        return null;
      }

      let cover = checkcover(target_pos, pos, routes);

      return {
        cover,
        dist,
        pos,
        obstacle,
      };
    }, (a, b) => {
      // return true if choose b over a
      // 높을수록 좋음
      if (b.cover > a.cover) { return 1; }
      if (b.cover < a.cover) { return -1; }

      // 현재 위치에서 가까울수록 좋음
      if (b.dist < a.dist) { return 1; }
      if (b.dist > a.dist) { return -1; }
      return 0;
    });
  }

  // 적이 보이지 않은 상태에서, 방향을 정해서 경계
  choosereorgpoint(entity, _f) {
    const { routes, entities } = this;
    const dists = routePathfindAll(routes, entity.pos, true);

    const allies = entities.filter((e) => e !== entity && e.team === entity.team && e.state !== 'dead');
    const allies_points = allies.map((e) => e.waypoint?.cp?.pos).filter((e) => e);

    return this.choosepoint(entity.pos, (pos, obstacle, i) => {
      // 같은 편과 같은 자리에서 지키지 않습니다.
      if (allies_points.find((p) => pos.eq(p))) {
        return null;
      }

      let cover = 1;
      if (obstacle.ty === 'half') {
        cover = 2;
      }
      let dist = dists[i];
      if (entity.leader && entity.leader.state !== 'dead') {
        dist += entity.leader.pos.dist(pos);
      }
      // 낮을수록 좋음
      let score = (3 - cover) * 10 + dist;

      return {
        cover,
        dist,
        score,
        pos,
        obstacle,
      };
    }, (a, b) => {
      // return true if choose b over a
      // 현재 위치에서 가까울수록 좋음
      if (b.score < a.score) { return 1; }
      if (b.score > a.score) { return -1; }
      return 0;
    });
  }

  // shooter on e1, target on e0, find coverpoint of *e0*
  // find coverpoint, accounting all enemies, able to fire to aimtarget
  choosecoverpoint(entity, max_dist, _f) {
    const { routes } = this;

    const e1 = entity.aimtarget;
    const e1_pos = e1.pos;

    // 공격받고 있을 때 엄폐를 풀 수 있는지 여부를 통제
    const offenced = this.entityOffenced(entity);
    if (offenced) {
      const route_node = routeNodeFromPos(routes, entity.pos);
      if (route_node && route_node.is_coverpoint && !entity.allow_uncover_on_fire) {
        return null;
      }
    }

    const dists = routePathfindAll(routes, entity.pos, true);

    let cp = this.choosecoverpoint0(entity, max_dist, e1_pos, dists);

    let cp_edge = null;
    if (entity.allow_cover_edge) {
      const edges = coverEdges(routes, entity.pos, e1.pos).filter((e) => {
        const { p_from, p_to } = e;
        const dist = Math.max(e1.pos.dist(p_from.pos), e1.pos.dist(p_to.pos));
        return dist < max_dist;
      });

      edges.sort((e0, e1) => {
        return entity.pos.dist(e0.p_mid) - entity.pos.dist(e1.p_mid);
      });
      cp_edge = edges[0] ?? null;

      if (edges.length > 0) {
        const edge = edges[0];
        const { idx_from, idx_to, p_from, p_to, ub } = edge;
        const dist = Math.max(dists[idx_from], dists[idx_to]);
        cp_edge = {
          cover: 2,
          edge,
          dist,
          pos: v2.lerp(p_from.pos, p_to.pos, ub),
        };
      }
    }

    if (cp && cp_edge) {
      // compare two candidates
      function score(cp) {
        return cp.cover - cp.dist / 100;
      }

      if (score(cp_edge) > score(cp)) {
        cp = cp_edge;
      }
    }

    return cp;
  }

  entitySetMobilityLevel(entity, level) {
    /*
    // mobility level
    //  - 0: do not move, in any case
    //  - 1: do not move from coverpoint, under fire, riskdir on roaming
    //  - 2: as-is
    //  - 3: do not wait door breaching
    this.mobility_level = 2;
    */

    entity.mobility_level = level;

    entity.allow_crawl = level < 3;
    entity.allow_door_wait = level < 3;
    entity.use_riskdir = level < 3;
    entity.riskdir_use_visibility_grid = level < 2;
    entity.allow_uncover_on_fire = level > 2;

    entity.allow_follow_leader = level < 3;
    entity.allow_wait_follower = level < 3;

    entity.allow_fire_control = level < 2;

    switch (level) {
      case 1:
        entity.movestate = 'low';
        entity.aim_samples_fire_thres = 0.5;
        break;
      case 2:
        entity.movestate = 'walk';
        entity.aim_samples_fire_thres = 0.2;
        break;
      case 3:
        entity.movestate = 'run';
        entity.aim_samples_fire_thres = 0.0;
        break;
      default:
        throw new Error(`unknown entitySetMobilityLevel: ${level}`);
    }
  }

  static risk_cmp(r0, r1) {
    // return positive number if r0 is riskier than r1
    for (let i = 0; i < r0.length; i++) {
      if (r0[i] !== r1[i]) {
        return r0[i] - r1[i];
      }
    }
    return 0;
  }

  entityRiskEnemies(entity) {
    const { entities, world } = this;

    // calculate current risk
    const ot = opponentTeam(entity.team);
    return entities.filter((e) => {
      if (e === entity || !ot.includes(e.team) || e.state === 'dead') {
        return false;
      }
      if (entity.aimtarget === e) {
        return true;
      }
      if (entity.use_visibility) {
        const visible = entity.grid_vis[world.idx(e.gridpos)] > entity.vis_thres;
        if (!visible) {
          return false;
        }
      }
      return true;
    });
  }

  entityRisk(entity, pos) {
    const { routes } = this;
    if (!pos) {
      pos = entity.pos;
    }

    // calculate current risk
    const enemies = this.entityRiskEnemies(entity);
    const covers = [0, 0, 0, 0];
    for (const enemy of enemies) {
      let cover = checkcover(enemy.pos, pos, routes);
      covers[cover] += 1;
    }
    return covers;
  }

  choosecoverpoint0(entity, max_dist, e1_pos, dists) {
    const { entities, routes } = this;

    const enemies = this.entityRiskEnemies(entity);
    const allies = entities.filter((e) => e !== entity && e.team === entity.team && e.state !== 'dead');
    const allies_points = allies.map((e) => e.waypoint?.cp?.pos).filter((e) => e);

    // least risk position
    let ally_risks = [];
    if (!isNaN(entity.risk_rank)) {
      ally_risks = allies.map((e) => this.entityRisk(e));
    }

    const itemFn = (pos, obstacle, i) => {
      // 같은 편과 같은 자리에서 지키지 않습니다.
      if (allies_points.find((p) => pos.eq(p))) {
        return null;
      }

      let dist0 = pos.dist(e1_pos);
      if (dist0 > max_dist) {
        return null;
      }

      if (entity.recent_visits.includes(i)) {
        return null;
      }

      let dist = dists[i];
      // 상대방을 사격할 수 있는 위치 중 하나를 고릅니다.
      const cover_offence = checkcover(pos, e1_pos, routes);
      if (cover_offence === 3) {
        // skip blocked
        return null;
      }

      let cover = 3;
      for (const enemy of enemies) {
        let cover_enemy = checkcover(enemy.pos, pos, routes);
        cover = Math.min(cover, cover_enemy);
      }

      // 위험도. 높을수록 안전함
      let risk_rank_delta = 0;
      if (!isNaN(entity.risk_rank)) {
        const risk = this.entityRisk(entity, pos);

        let risk_rank = 0;
        for (const ally_risk of ally_risks) {
          if (Simulator.risk_cmp(ally_risk, risk) > 0) {
            risk_rank += 1;
          }
        }
        risk_rank_delta = Math.abs(risk_rank - entity.risk_rank);
      }

      return {
        risk_rank_delta,
        cover,
        dist,
        pos,
        obstacle,
      };
    };

    const cmpFn = (a, b) => {
      // return true if choose b over a
      if (a === null) {
        return 1;
      }
      // 높을수록 좋음
      if (b.cover > a.cover) { return 1; }
      if (b.cover < a.cover) { return -1; }

      // risk_rank_delta: 낮을수록 좋음
      if (b.risk_rank_delta < a.risk_rank_delta) { return 1; }
      if (b.risk_rank_delta > a.risk_rank_delta) { return -1; }

      // 현재 위치에서 가까울수록 좋음
      if (b.dist < a.dist) { return 1; }
      if (b.dist > a.dist) { return -1; }
      return 0;
    };

    /*
    const candidates = this.choosepoints(entity.pos, itemFn, (a, b) => {
      // 작을수록 좋음
      const c0 = a.risk_rank_delta - b.risk_rank_delta;
      // 클수록 좋음
      const c1 = b.cover - a.cover;
      // 작을수록 좋음
      const c2 = a.dist - b.dist;

      if (c0 >= 0 && c1 >= 0 && c2 >= 0) {
        return 1;
      }
      else if (c0 <= 0 && c1 <= 0 && c2 <= 0) {
        return -1;
      }
      return 0;
    });
    candidates.sort(cmpFn);
    */

    return this.choosepoint(entity.pos, itemFn, cmpFn);
  }

  choosehidepoint(entity, f) {
    const { entities, routes } = this;

    const ot = opponentTeam(entity.team);
    const allies = entities.filter((e) => e !== entity && !ot.includes(e.team) && e.state !== 'dead');
    const enemies = entities.filter((e) => e !== entity && ot.includes(e.team) && e.state !== 'dead');

    const allies_points = allies.map((e) => e.waypoint?.cp?.pos).filter((e) => e);

    return (f ?? this.choosepoint.bind(this))(entity.pos, (pos, obstacle) => {
      // 같은 편과 같은 자리에서 지키지 않습니다.
      if (allies_points.find((p) => pos.eq(p))) {
        return null;
      }

      const dist = pos.dist(entity.pos);
      let cover = 3;
      for (const enemy of enemies) {
        // 상대방의 firearm_range 밖에 있는 경우 무시
        const dist = enemy.pos.dist(pos);
        if (dist > enemy.firearm_range) {
          continue;
        }

        let cover_enemy = checkcover(enemy.pos, pos, routes);
        cover = Math.min(cover, cover_enemy);
      }

      return {
        cover,
        dist,
        pos,
        obstacle,
      };
    }, (a, b) => {
      // return true if choose b over a
      // 높을수록 좋음
      if (b.cover > a.cover) { return 1; }
      if (b.cover < a.cover) { return -1; }

      // 현재 위치에서 가까울수록 좋음
      if (b.dist < a.dist) {
        return 1;
      }
      return -1;
    });
  }

  choosenearestpoint(entity, f) {
    const { routes } = this;
    const { pos } = entity;

    const dists = routePathfindAll(routes, pos, true);

    return (f ?? this.choosepoint.bind(this))(entity.pos, (pos, obstacle, i) => {
      let dist = dists[i];
      if (dist < 0) {
        return null;
      }

      return {
        dist,
        pos,
        obstacle,
      };
    }, (a, b) => {
      // return true if choose b over a
      // 현재 위치에서 가까울수록 좋음
      if (b.dist < a.dist) {
        return 1;
      }
      return -1;
    });
  }

  chooserescuepoint(entity, target, radius, f) {
    const dists = routePathfindAll(this.routes, entity.pos, false);

    const p = (f ?? this.choosepoint.bind(this))(entity.pos, (pos, obstacle, i) => {
      if (target.pos.dist(pos) >= radius) {
        return null;
      }
      if (obstructed(target.pos, pos, this.obstacles)) {
        return null;
      }

      const p = {
        score: dists[i],
        pos,
        obstacle,
      };
      return p;
    }, (a, b) => {
      // return true if choose b over a
      if (b.score > a.score) {
        return 1;
      }
      return -1;
    });
    return p;
  }

  explorepointScoreFn(entity) {
    const { world, routes } = this;

    const area = entity.waypoint_rule.area;
    const initiator = entity.waypoint_rule.initiator;

    let dists = null;
    if (initiator) {
      dists = routePathfindAll(routes, initiator.pos, true);
    } else {
      dists = routePathfindAll(routes, entity.pos, true);
    }

    const range = this.entityVisRange(entity);

    return (pos, obstacle, i) => {
      // area가 지정된 경우 해당 area만 탐색
      if (area && !geomContains(pos, area.polygon)) {
        return null;
      }

      if (isNaN(i)) {
        const node = routeNodeFromPos(routes, pos);
        if (isNaN(node?.idx)) {
          return null;
        }
        i = node.idx;
      }

      const dist = dists[i];
      if (dist < 0) {
        // unreachable
        return null;
      }

      let cover = 1;
      onReachableGridWasm(this.world, this.rv, pos, range, (idx) => {
        // area가 있는 경우, 해당 area의 탐색 여부만 확인합니다.
        if (area) {
          const world_pos = this.world.gridIdxToWorld(idx);
          if (!geomContains(world_pos, area.polygon)) {
            return false;
          }
        }
        cover += 1 - entity.grid_explore[idx];
        return true;
      });

      const normcover = cover / (world.width * world.height / Math.pow(opts.GRID_SIZE, 2));
      const normdist = Math.max(30, dist) / Math.sqrt(world.width * world.height);

      let score = 0;
      if (normdist > 0) {
        score = normcover / normdist;
      }

      if (entity.recent_visits.includes(i)) {
        score = -1000;
      }

      const p = {
        cover,
        dist,
        normcover,
        normdist: score,
        score,
        pos,
        obstacle,
      };
      return p;
    };
  }

  chooseexplorepoint(entity, scoreFn, f) {
    const { routes, entities } = this;

    const { leader } = entity;
    let dists_leader = null;
    if (entity.allow_follow_leader && leader && leader.vis && leader.state !== 'dead') {
      dists_leader = routePathfindAll(routes, leader.waypoint?.pos ?? leader.pos, true);

      if (this.entityWaypointDoor(leader)) {
        return leader.waypoint.cp;
      }
    }

    const allies = entities.filter((e) => e !== entity && e.team === entity.team && e.state !== 'dead');
    const allies_points = allies.map((e) => e.waypoint?.cp?.pos).filter((e) => e);

    let maxcover = null;
    const p = (f ?? this.choosepoint.bind(this))(entity.pos, (pos, obstacle, i) => {
      // 같은 편과 같은 자리에서 지키지 않습니다.
      if (!leader && allies_points.find((p) => pos.eq(p))) {
        return null;
      }

      const p = scoreFn(pos, obstacle, i);
      if (!p) {
        return null;
      }
      if (!maxcover || maxcover.cover < p.cover) {
        maxcover = p;
      }

      // leader로부터 line of sight가 확보되는 곳으로 이동해야 합니다.
      if (dists_leader) {
        if (dists_leader?.[i] > opts.FOLLOWER_ALLOWED_DIST) {
          p.score = -1000;
        }
      }

      return p;
    }, (a, b) => {
      // return true if choose b over a
      if (b.score > a.score) {
        return 1;
      }
      return -1;
    });

    if (p === null) {
      console.error('no explore point', entity.name);
      return null;
    }

    // HACK
    p.maxcover = maxcover;
    return p;
  }

  choosecapturepoint(entity, goal, f) {
    const { entities, goals } = this;
    const allies = entities.filter((e) => e !== entity && e.team === entity.team && e.state !== 'dead');
    const allies_points = allies.map((e) => e.waypoint?.cp?.pos).filter((e) => e);

    return (f ?? this.choosepoint.bind(this))(entity.pos, (pos, obstacle) => {
      // 같은 편과 같은 자리에서 지키지 않습니다.
      if (allies_points.find((p) => pos.eq(p))) {
        return null;
      }
      if (!goals.includes(obstacle)) {
        return null;
      }
      if (obstacle.goalstate.owner >= 0) {
        return null;
      }
      if (goal && obstacle && goal !== obstacle) {
        return null;
      }

      const dist = pos.dist(entity.pos);

      return {
        dist,
        score: -dist,
        pos,
        obstacle,
      };
    }, (a, b) => {
      // return true if choose b over a
      if (b.score > a.score) {
        return 1;
      }
      return -1;
    });
  }

  // goal을 지키는 위치를 찾습니다.
  choosecovergoalpoint(entity, goal, f) {
    const { entities, goals, routes } = this;

    const ot = opponentTeam(entity.team);
    const allies = entities.filter((e) => e !== entity && !ot.includes(e.team) && e.state !== 'dead');
    const enemies = entities.filter((e) => e !== entity && ot.includes(e.team) && e.state !== 'dead');

    const allies_points = allies.map((e) => e.waypoint?.cp?.pos).filter((e) => e);
    // const goals_in_danger = enemies.map((e) => e.waypoint.obstacle);

    // p에서 goal을 얼마나 지킬 수 있는지.
    function goalscore(p, goal) {
      // TODO: CHEAT: 현재 적이 점령하려고 시도하는 goal만 지킵니다.
      /*
      if (!goals_in_danger.includes(goal)) {
        return 0;
      }
      */

      // 점수가 낮을수록 안 좋음. 점령된 goal을 지키지 않습니다.
      if (goal.goalstate.owner >= 0) {
        return 0;
      }

      const scores = goal.coverpoints.map(({ pos: gp }) => {
        const dist = gp.dist(p);
        if (dist >= entity.firearm_range) {
          // 거리가 멀어서 사격할 수 없는 경우
          return 0;
        }
        return 1;
      });

      return scores.reduce((a, b) => a + b, 0);
    }

    return (f ?? this.choosepoint.bind(this))(entity.pos, (pos, obstacle) => {
      // goal에서 지키지 않습니다
      if (goals.includes(obstacle)) {
        return null;
      }
      // 같은 편과 같은 자리에서 지키지 않습니다.
      if (allies_points.find((p) => pos.eq(p))) {
        return null;
      }

      // score가 높을수록 coverage가 작음
      let score_goal = 0;
      if (goal) {
        score_goal = goalscore(pos, goal);
      } else {
        score_goal = goals.map((goal) => goalscore(pos, goal)).reduce((a, b) => a + b, 0);
      }
      score_goal = Math.min(20, score_goal);

      // 현재 위치에서 가까운 곳을 선호합니다.
      const dist = pos.dist(entity.pos);
      const score_dist = -dist / 200;

      // 현재 적의 위치에서 노출되지 않는 곳을 선호합니다.
      const score_enemy = enemies.map((e) => {
        return checkcover(e.pos, pos, routes) > 1 ? 1 : 0;
      }).reduce((a, b) => a + b, 0) * 4;

      const score = score_goal + score_dist + score_enemy;

      return {
        score_goal,
        score_dist,
        score_enemy,
        score,
        pos,
        obstacle,
      };
    }, (a, b) => {
      // return true if choose b over a
      // score가 큰 위치를 찾습니다.
      if (b.score > a.score) {
        return 1;
      }

      return -1;
    });
  }

  entityWaypointDoorDir(entity) {
    const { routes } = this;
    const path = entity.waypoint?.path;
    if (!path) {
      return null;
    }

    for (let i = 0; i < path.length - 1; i++) {
      const idx = path[i].idx;
      const idx1 = path[i + 1].idx;
      if (idx === -1 || idx1 === -1) {
        continue;
      }

      const node = routes.nodes[idx];
      const node1 = routes.nodes[idx1];
      if (node.is_coverpoint && node.obstacle.ty === 'door' && !node.obstacle.doorstate.open) {
        const { pos, obstacle } = node;
        const dir = node1.pos.sub(node.pos).norm();
        const ray = raycastWasm(this.rv, node1.pos, [dir])[0].sub(pos);
        return {
          obstacle,
          pos,
          dir,
          ray,
        };
      }
    }
    return null;
  }

  entityWaypointDoor(entity) {
    const path = entity.waypoint?.path;
    if (!path) {
      return null;
    }

    for (const s of path) {
      const { idx } = s;
      if (idx === -1) {
        continue;
      }
      if (this.doorPathNode(idx)) {
        return idx;
      }
    }
    return null;
  }

  doorPathNode(idx) {
    const { routes } = this;
    if (!(idx >= 0)) {
      return false;
    }
    const node = routes.nodes[idx];
    if (!node) {
      return false;
    }
    return !node.is_coverpoint && node.obstacle.ty === 'door' && !node.obstacle.doorstate.open;
  }

  doorStopNode(entity, idx) {
    const { routes } = this;
    if (!(idx >= 0)) {
      return false;
    }
    const node = routes.nodes[idx];
    if (!node) {
      return false;
    }
    if (node.obstacle.ty !== 'door' || node.obstacle.doorstate.open) {
      return false;
    }

    return !node.is_coverpoint;

    /*
    // TODO: 트레일러 데모
    // 아무도 coverpoint로 가고 있지 않은 경우, coverpoint까지 갑니다.
    // 누군가 coverpoint로 가고 있는 경우, routepoint까지 갑니다.
    const node0 = node;
    const waitings = this.entities.map((e) => {
      if (e === entity) {
        return null;
      }
      let path = e.waypoint?.path;
      if (!path) {
        return null;
      }
      if (path.length < 1) {
        return null;
      }

      const { idx } = path[1];
      if (!(idx >= 0)) {
        return null;
      }
      const node = routes.nodes[idx];
      if (node?.obstacle !== node0.obstacle) {
        return null;
      }
      if (node.obstacle.ty !== 'door' || node.obstacle.doorstate.open) {
        return null;
      }
      return node;
    }).filter((n) => n !== null);

    const occupied = waitings.find((n) => n.is_coverpoint);

    if (occupied) {
      return !node.is_coverpoint;
    } else {
      return node.is_coverpoint;
    }
    */
  }

  nodeShouldStop(entity, node_idx) {
    if (this.doorShouldStop(entity, node_idx, true)) {
      return true;
    }
    if (this.leaderShouldStop(entity, node_idx)) {
      return true;
    }
    return false;
  }

  leaderShouldStop(entity, _node_idx) {
    if (!entity.allow_wait_follower) {
      return false;
    }

    const { entities } = this;
    const followers = entities.filter((e) => e.leader === entity && e.state !== 'dead');
    for (let follower of followers) {
      if (follower.movespeed === 0) {
        continue;
      }
      if (follower.pos.dist(entity.pos) > opts.LEADER_WAIT_DIST) {
        return true;
      }
    }
    return false;
  }

  doorShouldStop(entity, node_idx, visited) {
    const { routes, tick } = this;

    const node = routes.nodes[node_idx];
    const o = node.obstacle;

    // policy가 끝났는지 확인
    let policy = this.door_policy.find((p) => p.door === o);
    if (policy) {
      if (policy.throwable_ty && !policy.throwable) {
        // TODO: HACK: 나중에 고쳐야 함
        if (visited) {
          this.navigateOnVisitNode(entity, node_idx);
        }
        return true;
      } else if (!o.doorstate.opener) {
        o.doorstate.opener = entity;
      }

      if (this.throwables.find((t) => !t.blast_timer.expired(tick) && t.throwable === policy.throwable)) {
        // 투척물을 던졌으면 폭발까지 기다렸다가 진입함
        return true;
      }
      return false;
    }

    if (!this.doorStopNode(entity, node_idx)) {
      return false;
    }

    if (o.doorstate.open) {
      return false;
    }

    // 빠른 진입
    if (!entity.allow_door_wait) {
      return false;
    }

    // 같은 문에서 진입을 기다리고 있을 때
    const waitings = this.doorWaitings(entity, o);
    for (const { entity: waiting, node } of waitings) {
      if (entity === waiting) {
        continue;
      }
      // 도착할 때 까지 기다림
      if (!waiting.pos.eq(node.pos)) {
        return true;
      }
    }

    // check if there's a pending prompt with given door
    const p = this.pending_prompts.find((p) => p.door === o);
    if (p) {
      return true;
    }

    if (!visited) {
      return true;
    }

    const throwables = _.countBy(_.flatten(waitings.map((item) => item.entity.throwables.map((t) => t.throwable_name))));

    // 던질 물건이 없는 경우, 바로 진입합니다.
    if (Object.keys(throwables).length === 0) {
      return false;
    }

    const prompt_duration = 5;
    const prompt_options = [
      {
        title: 'proceed', actions: [
          { action: 'open_door', actiondoorpolicy: { door: o } }
        ]
      }
    ];

    for (const throwable_ty of Object.keys(throwables)) {
      const count = throwables[throwable_ty];
      prompt_options.push(
        {
          title: `with ${throwable_ty} (${count})`, actions:
            [{ action: 'open_door', actiondoorpolicy: { door: o, throwable_ty } }]
        }
      );
    }

    let default_idx = 0;
    if (prompt_options.length > 1 && entity.demo_trailer_throw) {
      default_idx = 1;
    }
    prompt_options[default_idx].default = true;

    this.pending_prompts.push({
      area: entity.spawnarea,
      expire_at: this.ticksFromSec(prompt_duration) + this.tick,
      queue_at: this.tick,
      prompt_options,
      door: o,
    });

    return true;
  }

  doorWaitings(entity, o) {
    const { entities, routes } = this;

    return entities.map((e) => {
      if (e.team !== entity.team || e.state === 'dead' || !e.waypoint?.path) {
        return null;
      }
      for (const s of e.waypoint.path) {
        const { idx } = s;
        if (idx === -1) {
          continue;
        }
        const node = routes.nodes[idx];
        if (o.doorstate.group !== node?.obstacle?.doorstate?.group && o !== node?.obstacle) {
          continue;
        }

        if (this.doorStopNode(e, idx)) {
          return { entity: e, node };
        }
      }
      return null;
    }).filter((item) => item !== null);
  }

  maybePopGroupRule(entity, cond) {
    entity.mark_rule(cond);
    if (!cond) {
      return;
    }

    const { ty } = entity.waypoint_rule;
    const groupEntities = this.entities.filter((e) => e.team === entity.team && e.state !== 'dead' && e.ty !== 'vip');
    if (groupEntities.find((e) => e.waypoint_rule.ty !== ty || !e.rule_marked)) {
      return;
    }

    this.popGroupRule(entity);
  }

  popGroupRule(entity) {
    const { ty, prompt_duration, prompt_options, mission_idx } = entity.waypoint_rule;
    const groupEntities = this.entities.filter((e) => e.team === entity.team && e.state !== 'dead' && e.ty !== 'vip');
    if (groupEntities.find((e) => e.waypoint_rule.ty !== ty)) {
      return;
    }

    for (const e of groupEntities) {
      e.pop_rule();
    }

    if (prompt_options) {
      this.pending_prompts.push({
        area: entity.spawnarea,
        expire_at: this.ticksFromSec(prompt_duration) + this.tick,
        queue_at: this.tick,
        prompt_options,
      });
    }

    // rule에 연관된 prompt가 있었던 경우 삭제합니다.
    if (isNaN(mission_idx)) {
      return;
    }
    this.pending_prompts = this.pending_prompts.filter((p) => p.mission_idx !== mission_idx);
  }

  maybeRescueVIP(entity) {
    const { journal, tick } = this;
    // TODO: check if VIP is discovered
    if (entity.waypoint_rule.ty !== 'explore') {
      return;
    }

    // TODO: 못 찾거나 이미 구출됨
    const target = this.entityFoundVIP(entity);
    if (!target || target.team === 0) {
      return;
    }

    // 이미 prompt가 나옴
    if (this.pending_prompts.find((p) => p.rescue_target === target)) {
      return;
    }
    if (this.entities.find((e) => e.waypoint_rule.rescue_target === target)) {
      return;
    }

    journal.push({ tick, ty: 'discover', entity, target });
    this.pending_prompts.push({
      area: entity.spawnarea,
      expire_at: this.ticksFromSec(3600) + tick,
      queue_at: tick,
      rescue_target: target,
      prompt_options: [
        {
          title: 'rescue', default: true, actions: [
            { action: 'rescue', rescue_target: target }
          ]
        }
      ],
    });
  }

  entityThrow(entity, throwable, target_pos) {
    const { tick } = this;
    const moveDuration = this.ticksFromSec(throwable.throw_delay);
    if (entity.perk_grenadier_shorter_blast) {
      this.journal.push({ tick, ty: 'perk', perk: 'perk_grenadier_shorter_blast', entity, })
    }
    const blast_delay = entity.perk_grenadier_shorter_blast ? throwable.blast_delay - 1.0 : throwable.blast_delay;
    const blastDuration = this.ticksFromSec(blast_delay);

    this.throwables.push({
      throwable,
      move_timer: new TickTimer(tick, moveDuration),
      blast_timer: new TickTimer(tick, blastDuration),
      entity,
      pos: entity.pos,
      start_pos: entity.pos,
      target_pos,
      blastareas: [],
    });
  }

  throwableUpdate(throwable) {
    const { tick } = this

    if (!throwable.move_timer.expired(tick)) {
      const progress = throwable.move_timer.progress(tick);
      throwable.pos = v2.lerp(throwable.start_pos, throwable.target_pos, progress);
    }

    if (throwable.blast_timer.expired_exact(tick)) {
      this.entityHandleThrowable(throwable);
    }
  }

  entityHandleThrowable(throwable0) {
    const { world, tick } = this;
    const { entity, throwable, pos } = throwable0;

    for (const blast of throwable.blasts) {
      let { blast_radius } = blast;
      const { blast_ty, blast_expose_ty } = blast;
      let vismodel = null;
      switch (blast_expose_ty) {
        case 'full':
          vismodel = this.rb;
          break;
        case 'half':
          vismodel = this.rv;
          break;
        default:
          throw new Error(`unknown blast_ty=${blast_expose_ty}`);
      }

      if (entity.perk_grenadier_effect_range) {
        blast_radius *= 1.5;
      }

      const entities = [];
      const vis = vismodel.triangulated.visibility(pos.x, pos.y, false);
      vis.limit(blast_radius);

      const ba = {
        pos,
        tick,
        expire_at: tick + this.ticksFromSec(3),
        vis,
        entities,
      };

      if (blast_ty === 'visibility') {
        // agent가 아니라 grid에 영향을 줌.

        onReachableGridWasmApply(world, vis, (idx) => {
          entity.grid_vis[idx] = 1;
          entity.grid_explore[idx] = 1;
        });
      } else {
        const entities_reachable = this.reachableEntities(vis);
        const { blast_effect_ty, blast_effect_duration } = blast;

        let effect_multiplier = 1;
        if (entity.perk_grenadier_effect_amount) {
          this.journal.push({ tick, ty: 'perk', perk: 'perk_grenadier_effect_amount', entity, })
          effect_multiplier = 1.25;
        }

        for (const target of entities_reachable) {

          switch (blast_ty) {
            case 'damage':
              // 투척물 피해에서는 항상 armor를 차감함.
              const { kill } = this.entityOnDamage(entity, target, blast.blast_damage * effect_multiplier, 1, true);
              entities.push(target);
              if (kill) {
                this.journal.push({ tick, ty: 'throw_kill', entity, throwable, target });
              }
              break;
            case 'effect': {
              const expire_at = tick + this.ticksFromSec(blast_effect_duration * effect_multiplier);
              const found = target.effects.find((e) => e.effect_ty === blast_effect_ty);
              if (found) {
                found.expire_at = Math.max(found.expire_at, expire_at);
              } else {
                target.effects.push({
                  effect_ty: blast_effect_ty,
                  expire_at,
                });
                entities.push(target);
              }
              this.journal.push({ tick, ty: 'throw_effect', entity, throwable, target, effect_ty: blast_effect_ty })
              break;
            }
            case 'smoke':
              break;
            default:
              throw new Error(`unknown blast_ty=${blast_ty}`);
          }
        }

        if (blast_ty === 'smoke') {
          ba.expire_at = tick + this.ticksFromSec(blast_effect_duration);
          ba.effect_ty = 'smoke';
        }
      }

      throwable0.blastareas.push(ba);
      this.blastareas.push(ba);
    }
  }

  navigateOnVisitNode(entity, node_idx) {
    if (!(node_idx >= 0)) {
      return false;
    }

    const { routes, rng, tick } = this;
    const node = routes.nodes[node_idx];
    const o = node.obstacle;

    if (this.doorStopNode(entity, node_idx)) {
      const policy = this.door_policy.find((p) => p.door === o);

      // TODO: 문 열때 동작
      const doordir = this.entityWaypointDoorDir(entity);
      if (policy?.throwable_ty && doordir && doordir.obstacle === node.obstacle) {
        const { pos, dir, ray } = doordir;
        const throwdirmargin = rng.integer(-5, 5) * Math.PI / 180;
        const throwdir = new v2(dir.x * Math.cos(throwdirmargin) - dir.y * Math.sin(throwdirmargin), dir.x * Math.sin(throwdirmargin) + dir.y * Math.cos(throwdirmargin));
        const { throwable_ty } = policy;
        const selected = entity.throwables.find((t) => t.throwable_name === throwable_ty);
        if (!selected) {
          // TODO: policy에 던지라고 했는데 나는 없음. 누군가 가지고 있기를 기도하자
          return false;
        }
        entity.throwables = entity.throwables.filter((t) => t !== selected);

        const dist = Math.min(ray.len() * 0.8, selected.throw_range / 2);
        const p1 = pos.add(throwdir.mul(dist));

        policy.throwable = selected;
        this.journal.push({ tick, ty: 'throw_door', entity: entity, throwable: selected, });
        this.entityThrow(entity, selected, p1);
        // this.entityHandleThrowable(entity, selected, p1);
      }

      o.doorstate.open = true;
      this.rebuildVisibility();
    }
  }

  entityRoutable(entity) {
    const { tick } = this;

    if (entity.waypoint_rule.ty === 'reorg') {
      return false;
    }

    // reroute 주기를 초과한 경우
    return entity.lastRouteTick.expired(tick);
  }

  entityNextWaypointFollow(entity) {
    if (!entity.waypoint) {
      return;
    }

    // 이전 route가 있을 때, 이 route를 일단 계속 따라갑니다.
    const wp = entity.waypoint;
    if (wp.pos.eq(entity.pos) && wp.path) {
      let path = wp.path.slice();
      while (path.length > 0 && !entity.pos.eq(path[0].pos)) {
        path = path.slice(1);
      }

      // path[0]은 현재 위치, 혹은 현재 위치까지의 path입니다.
      // path[1]은 다음 위치, 현재 위치에서 다음 위치까지의 경로입니다.

      if (path.length > 0) {
        // 현재 도착점에 대해
        const cur = path[0];
        const cur_idx = cur?.idx;
        // TODO: 문열기 정리하기
        if (cur_idx >= 0 && this.nodeShouldStop(entity, cur_idx)) {
          return;
        }

        this.navigateOnVisitNode(entity, cur_idx);
      }

      if (path.length > 1) {
        // 다음 목적지가 있는 경우 마저 이동합니다.
        const dest = path[1];
        entity.waypoint = {
          pos: dest.pos,
          cp: wp.cp,
          obstacle: wp.obstacle,
          path,
        };
      }
      // waypoint의 끝에 도착했을 때는 항상 reroute?
    }
  }

  entityNextWaypoint0(entity) {
    const { entities, routes } = this;
    const rule = entity.waypoint_rule;
    const allies = entities.filter((e) => e.team === entity.team && e !== entity && e.state !== 'dead');

    if (this.controlGet(entity.spawnarea, 'holdpos')) {
      return;
    }

    // TODO: milestone 3: 현재 적이 보이지 않지만 자리를 지키는게 우위를 지키는 경우
    // 현재 이전 waypoint에 도착한 경우
    if (opts.EXP_HOLD_POSITION && entity.waypoint && entity.waypoint.pos.eq(entity.pos)) {
      if (['cover', 'cover-fire'].indexOf(rule.ty) !== -1) {
        const pulls = entities.filter((e) => e.team !== entity.team && e.state !== 'dead' && e.waypoint_rule.ty?.indexOf('fire') > 0 && e.aimtarget === entity && entity.firearm_range > e.firearm_range);
        if (pulls.length > 0) {
          return;
        }
      }
    }

    let cp = null;
    if (rule.ty === 'patrol') {
      let waypoint = entity.waypoints[0];
      if (entity.pos.eq(waypoint)) {
        entity.waypoints = entity.waypoints.slice(1);
        entity.waypoints.push(waypoint);
        waypoint = entity.waypoints[0];
      }

      cp = {
        pos: waypoint,
      };
    }

    if (rule.ty === 'rescue') {
      cp = this.chooserescuepoint(entity, entity.waypoint_rule.rescue_target, opts.RESCUE_RADIUS);
    }
    if (rule.ty === 'follow') {
      cp = this.chooserescuepoint(entity, entity.waypoint_rule.follow_target, opts.RESCUE_RADIUS);
    }
    if (rule.ty === 'heal') {
      cp = { pos: rule.heal_target.pos };
    }
    if (rule.ty === 'capture-goal') {
      cp = this.choosecapturepoint(entity, rule.goal);
    }
    if (rule.ty === 'cover-goal') {
      cp = this.choosecovergoalpoint(entity, rule.goal);
    }
    if (rule.ty === 'reload') {
      cp = this.choosehidepoint(entity);
    }
    if (rule.ty === 'gather') {
      cp = this.choosenearestpoint(rule.leader);
    }
    if (rule.ty === 'hide') {
      cp = this.choosehidepoint(entity);
    }
    if (rule.ty === 'interact-object') {
      cp = { pos: rule.object.pos };
    }

    if (entity.waypoint?.pos?.eq(entity.pos)) {
      // TODO: 문 진입 데모
      // explore 상태에서 목적지에 도착하고 re-route하는 경우, 진행할 수 없는 상황인지 검사해야 합니다.
      const cur = entity.waypoint.path.find((p) => entity.pos.eq(p.pos));
      const dest_idx = cur?.idx;

      if (dest_idx >= 0) {
        entity.recent_visits.push(dest_idx);

        if (this.nodeShouldStop(entity, dest_idx)) {
          return;
        }
      }
    }

    // explore + area, explore + initiator
    if (rule.ty === 'explore') {
      const scoreFn = this.explorepointScoreFn(entity);
      cp = this.chooseexplorepoint(entity, scoreFn);
      // TODO: room hack
      if (cp?.maxcover) {
        this.maybePopGroupRule(entity, cp.maxcover.cover < opts.EXPLORED_THRES);
      }
    }

    if (!cp) {
      if (entity.aimtarget) {
        // rule 1. 가장 가까운 엄호물
        // rule 2. 오랫동안 상대방을 사격할 수 없는 경우, 상대방이 닿는 곳 (이건 지금 loop에서 처리)
        // rule 3. 내 앞에서 내 상대를 제압하고 있는 아군이 있는 경우, 그 아군보다 더 가까운 곳으로 이동

        if (rule.ty === 'fire') {
          cp = this.choosefirepoint(entity.aimtarget);
        } else {
          const covering_entity = entities.find((e) => e.team === entity.team
            && e !== entity
            && e.state === 'covered'
            && e.aimtarget === entity.aimtarget
            && e.aimtargetshoots > 0);

          let max_dist = 100000;
          if (rule.ty === 'cover-fire') {
            max_dist = Math.min(max_dist, entity.firearm_range);
          }

          if (covering_entity) {
            // TODO
            // max_dist = Math.min(max_dist, covering_entity.pos.dist(entity.aimtarget.pos));
          }
          cp = this.choosecoverpoint(entity, max_dist);
        }
      } else {
        // cp도 없고 aimtarget도 없음.
        // cp = this.chooseguardpoint(entity);
        cp = this.choosereorgpoint(entity);
      }
    }

    if (cp && !cp.pos.eq(entity.pos)) {
      // TODO: XXX: FIXME: 2인 방 진입 데모용
      const ally_doornodes = [];
      for (const e of allies) {
        const door = this.entityWaypointDoor(e);
        if (door) {
          ally_doornodes.push(door);
        }
      }

      const path = routePathfind(routes, entity.pos, cp.pos, (edge) => {
        const cost = edge.len;
        let weight = 1;

        if (ally_doornodes.includes(edge.from_idx)) {
          // TODO: 트레일러 데모
          return 1000000;
        }

        if (opts.ROUTE_WITH_SAMPLING) {
          // check if edge is covered
          const samples = 3;
          let covers = 0;
          for (let i = 0; i < samples; i++) {
            // should be deterministic
            let samplepos = v2.lerp(edge.from, edge.to, (i + 1) / (samples + 1));
            covers += checkcover(entity.aimtarget.pos, samplepos, routes);
          }
          weight = (samples - covers / 2) / (samples);
        }

        return cost * weight;
      }, [], entity.allow_cover_edge);

      if (path) {
        const idx = path[0]?.idx;
        // 현재 entity가 node 위에 있는 경우, 현재 위치에 대해 처리
        if (idx >= 0) {
          this.navigateOnVisitNode(entity, idx);
        }

        const dest = path.find((p) => p.len > 0);
        if (dest) {
          entity.waypoint = {
            pos: dest.pos,
            cp,
            rule_ty: rule.ty,
            obstacle: cp.obstacle,
            path,
          };
        } else {
          console.error('failed to find route', entity.name);
          // routePathfind(routes, entity.pos, cp.pos, null, true);
          entity.waypoint = cp;
        }
      }

      if (['covered', 'hide'].includes(entity.state)) {
        entity.state = 'stand';
      }
    }
  }

  entityNextWaypoint(entity) {
    const { rng, tick } = this;

    if (entity.waypoint_rule.ty === 'idle') {
      return null;
    }

    // reroute 주기를 초과한 경우
    if (!this.entityRoutable(entity)) {
      this.entityNextWaypointFollow(entity);
      return;
    }

    let route_interval = rng.range(...opts.REROUTE_INTERVAL_RANGE);
    entity.lastRouteTick = new TickTimer(tick, this.ticksFromSec(route_interval));

    this.entityNextWaypoint0(entity);
  }

  ticksFromSec(seconds) {
    return Math.floor(this.tps * seconds);
  }

  entityEffect(entity, effect_ty) {
    const effects = this.entityEffects(entity);
    for (const effect of effects) {
      if (effect.effect_ty === effect_ty) {
        return effect;
      }
    }
    return null;
  }

  entityEffects(entity) {
    const { blastareas, spawnareas, tick } = this;

    const effects = [];
    effects.push({ effect_ty: `movestate_${entity.movestate}` });

    for (const area of spawnareas) {
      let effect_ty = area.areastate.area.effect_ty;
      if (area.areastate.area.structureopts) {
        effect_ty = 'indoor';
      }

      if (!effect_ty || !geomContains(entity.pos, area.polygon)) {
        continue;
      }

      effects.push({ ...area.areastate.area, effect_ty });
    }
    for (const { effect_ty, expire_at } of entity.effects) {
      if (expire_at <= tick) {
        continue;
      }
      effects.push({ effect_ty });
    }
    for (const ba of blastareas) {
      if (ba.expire_at <= tick) {
        continue;
      }
      if (typeof ba.effect_ty !== 'string') {
        continue;
      }

      const entities_reachable = this.reachableEntities(ba.vis);
      if (entities_reachable.includes(entity)) {
        effects.push(ba);
      }
    }

    return effects;
  }

  entityEffectParam(entity, key) {
    let val = entity[key];
    const effects = this.entityEffects(entity);
    for (const { effect_ty } of effects) {
      const param_key = `${effect_ty}_params`;
      const setval = PARAMS[param_key]?.values?.[key];
      if (setval !== undefined) {
        val = setval;
      }

      const mult = PARAMS[param_key]?.multipliers?.[key];
      if (!isNaN(mult)) {
        val *= mult;
      }
    }

    return val;
  }

  // effect area 구현 관련으로 추상화
  entityVisRange(entity) {
    return this.entityEffectParam(entity, 'vis_range');
  }

  // effect area 구현 관련으로 추상화
  entityAimvarHoldMax(entity) {
    return this.entityEffectParam(entity, 'aimvar_hold_max');
  }

  entitySpeed(entity) {
    const { entities, spawnareas } = this;
    const speed_base = this.entityEffectParam(entity, 'speed') / this.tps;
    const movespeed_min = opts.MOVESPEED_MIN / this.tps;

    let speed_mult = 1.0;
    if (entity.perk_smg_fast_move) {
      speed_mult += 0.1;
    }

    switch (entity.state) {
      case 'dash':
        speed_mult += 0.25;
      case 'stand':
      case 'covered':
        break;
      case 'crawl':
      case 'hide':
      case 'dead':
        speed_mult = 0;
        break;
      default:
        throw new Error(`speed: invalid state: ${entity.state}`);
    }

    if (!entity.unaimPauseTick.expired(this.tick)) {
      speed_mult = 0;
    }

    const speed = speed_base * speed_mult;

    // area filter: 실내일 때 느리게 움직이도록
    // TODO: 좀 더 일반적인 방법 필요: 넓은 공간을 탐색할 때 빠르게 움직이도록 하기?
    let indoor_mult = 1.0;
    for (const area of spawnareas) {
      if (!area.areastate.area.structureopts) {
        continue;
      }
      if (geomContains(entity.pos, area.polygon)) {
        // 50 -> 20
        indoor_mult = 0.8;
        break;
      }
    }

    if (entity.perk_engage_dash) {
      const offenced = this.entityOffenced(entity);
      if (offenced) {
        return speed * 1.5;
      }
    }

    // dir/aimdir을 조합해서 이동 속도를 정합니다.
    // abs(reldir), speedmult가 (0, 1), (Math.PI/2, 0)
    const reldir = dirnorm0(entity.dir - entity.aimdir);
    let multiplier = 1;
    // TODO: milestone 3: 실외에서는 movespeed_rules 적용 안 함
    if (!(opts.EXP_OUTDOOR_IGNORE_MOVESPEED_RULES && indoor_mult === 1.0)) {
      for (const item of entity.movespeed_rules) {
        if (reldir < item.reldir) {
          multiplier = item.multiplier;
        }
      }
    }

    const followers = entities.filter((e) => {
      return e.leader === entity && e.state !== 'dead'
    });
    // leader인 경우
    if (entity.allow_wait_follower && followers.length > 0) {
      const centerofmass = followers.reduce((pos_acc, { pos }) => pos.add(pos_acc), v2.zero())
        .mul(1 / followers.length);

      // center of mass가 목적지보다 멀면 느리게 가야 함
      if (entity.waypoint) {
        const waypoint_pos = entity.waypoint.pos;
        // 양수면 entity가 앞, 음수면 뒤
        const delta_dist = centerofmass.dist(waypoint_pos) - entity.pos.dist(waypoint_pos);

        // const delta = centerofmass.sub(entity.pos);
        if (delta_dist > opts.LEADER_SLOW_DIST) {
          multiplier = multiplier * 0.5;
        }
      }
    }

    const movespeed = speed * multiplier * indoor_mult;
    if (movespeed === 0) {
      return movespeed;
    }
    return Math.max(movespeed, movespeed_min);
  }

  entityFoundVIP(entity) {
    let target = this.entities
      .filter(e => e.team === 2 && e.state !== 'dead')
      .find((e) => entity.grid_explore[this.world.idx(e.gridpos)] > 0.1);

    if (!target) {
      return null;
    }
    return target;
  }

  entityUpdateAimtarget(entity) {
    const { rng, entities } = this;
    if (rng.range(0, 1) < entity.retarget_accurate_prob) {
      return this.entityUpdateAimtarget0(entity);
    }

    // improper judgement
    const ot = opponentTeam(entity.team);
    const evalFn = this.entityTargetEvalFn(entity);
    const targets = entities
      .filter(e => ot.includes(e.team) && e.state !== 'dead')
      .map((target) => evalFn(entity, target))
      .filter((e) => e.visible);


    if (targets.length > 0) {
      const target = rng.choice(targets);
      this.entitySetAimtarget(entity, target.entity);
      return true;
    } else {
      this.entitySetAimtarget(entity, null);
      return false;
    }
  }

  entityTargetEvalFn(entity) {
    const { world, routes, journal, tick } = this;

    const offenced = this.entityOffenced(entity);
    const obstacles = this.obstacles.filter((o) => o.ty === 'full' || (o.ty === 'door' && !o.doorstate.open));
    let perk_unidir_used = false;

    const range = this.entityVisRange(entity);
    const vis_var = this.entityEffectParam(entity, 'vis_var');

    const initiator = entity.waypoint_rule.initiator;

    return (entity, other) => {
      const cover = checkcover(entity.pos, other.pos, routes);
      const dist = entity.pos.dist(other.pos);
      const reachable = dist < entity.firearm_range;
      let reason = null;
      let visible = true;

      if (entity.use_visibility) {
        visible = this.entityAware(entity, other);

        if (visible && opts.EXP_AIMTARGET_VIS) {
          // angle
          const dirvec = other.pos.sub(entity.pos);
          const dir = dirvec.dir();
          const reldir = dirnorm0(entity.aimdir - dir);

          if (Math.abs(reldir) > vis_var) {
            reason = 'invis';
            visible = false;
          }
        }
      }

      if (!world.exp_search) {
        if (other === initiator) {
          // 실내 데모용: 교전 시작한 상대는 항상 어디있는지 알아야 함
          reason = 'initiator';
          visible = true;
        }
        if (offenced && offenced.source === other) {
          // 나를 공격한 상대는 어디 있는지 알아야 함
          reason = 'offenced';
          visible = true;
        }
      }

      if (!visible && dist < range
        && entity.perk_unidir_sense && !obstructed(entity.pos, other.pos, obstacles)) {
        if (!perk_unidir_used) {
          journal.push({ tick, ty: 'perk', entity, perk: 'perk_unidir_sense' });
        }
        perk_unidir_used = true;
        reason = 'perk_unidir_sense';
        visible = true;
      }

      return {
        entity: other,
        cover,
        dist,
        reachable,
        reason,
        visible,
      };
    };
  }

  entityUpdateAimtarget0(entity) {
    // TODO: FIXME
    if (entity.ty === 'vip') {
      return false;
    }
    const pattern_ended = entity.shootPatternIdx === 0;
    if (!pattern_ended) {
      return false;
    }

    // rule에서 target을 명시한 경우
    const rule_target = entity.waypoint_rule.target;
    if (rule_target && rule_target.state !== 'dead') {
      entity.aimtarget = rule_target;
      entity.aimtargetshoots = 0;
      return false;
    }

    const { entities } = this;

    function basecmp(a, b) {
      const goala = a.entity.waypoint_rule.goal?.goalstate?.owner ?? 0;
      const goalb = a.entity.waypoint_rule.goal?.goalstate?.owner ?? 0;

      // 현재 닿는 상대를 aim
      if (a.reachable !== b.reachable) {
        return b.reachable - a.reachable;
      }

      // goal이 낮은 상대를 aim
      if (goala !== goalb) {
        return goala - goalb;
      }

      // firearm_range가 높은 상대를 aim
      if (entity.perk_desmar_priority_defensive) {
        if (a.entity.firearm_range !== b.entity.firearm_range) {
          return b.entity.firearm_range - a.entity.firearm_range;
        }
      }

      // 체력 소모가 적은 상대를 aim
      if (entity.perk_desmar_priority_offensive) {
        const da = a.entity.life_max - a.entity.life;
        const db = b.entity.life_max - b.entity.life;

        if (da !== db) {
          return da - db;
        }
      }

      // 다음 조건일 때 cover가 낮은 상대를 aim
      if (a.cover !== b.cover) {
        // 둘 다 범위 안에 있는 경우
        if (a.reachable && b.reachable) {
          return a.cover - b.cover;
        }
      }
      return 0;
    }

    function evalTargetSortFn(a, b) {
      const cmp = basecmp(a, b);
      if (cmp) {
        return cmp;
      }

      // 거리가 가까운 상태를 aim
      return a.dist - b.dist;
    };

    const ot = opponentTeam(entity.team);
    const evalFn = this.entityTargetEvalFn(entity);
    const targets = entities
      .filter(e => ot.includes(e.team) && e.state !== 'dead')
      .map((target) => {
        // 같은 상대를 조준하고 있는 아군 목록
        let allies = [];
        if (entity.allow_coordinated_fire) {
          allies = entities
            .filter((e) => entity !== e && !ot.includes(e.team) && e.state !== 'dead' && e.aimtarget === target)
            .map((e) => this.entityTargetEvalFn(e)(e, target));
          allies.sort(evalTargetSortFn);
        }

        const res = evalFn(entity, target);
        res.ally_result = allies[0];
        return res;
      })
      .filter((e) => e.visible);

    function cmpAllyResult(a, b) {
      if (a === undefined && b !== undefined) {
        return -1;
      }
      if (a !== undefined && b === undefined) {
        return 1;
      }
      if (a === undefined && b === undefined) {
        return 0;
      }
      // ally로부터의 우선순위가 낮은 상대를 선호
      return evalTargetSortFn(b, a);
    }

    targets.sort((a, b) => {
      if (a.perk_targetpref_high !== b.perk_targetpref_high) {
        return a.perk_targetpref_high - b.perk_targetpref_high;
      }
      if (a.perk_targetpref_low !== b.perk_targetpref_low) {
        return b.perk_targetpref_low - b.perk_targetpref_low;
      }

      const cmp0 = basecmp(a, b);
      if (cmp0) {
        return cmp0;
      }

      const cmp = cmpAllyResult(a.ally_result, b.ally_result);
      if (cmp) {
        return cmp;
      }

      // 거리가 가까운 상태를 aim
      return a.dist - b.dist;
    });

    const target = targets[0] ? targets[0].entity : null;
    this.entitySetAimtarget(entity, target);
    return target !== null;
  }

  entitySetAimtarget(entity, target) {
    const { tick, journal } = this;

    const pattern_ended = entity.shootPatternIdx === 0;

    if (target && target !== entity.aimtarget) {
      journal.push({ tick, ty: 'aim', entity, target });
      entity.aimtarget = target;
      entity.aimtargetshoots = 0;

      entity.unaimTick = new TickTimer(tick, this.ticksFromSec(opts.UNAIM_DURATION));
      entity.unaimPauseTick = new TickTimer(tick, 0)
    } else if (pattern_ended && entity.aimtarget && entity.aimtarget.state === 'dead') {
      this.entityUnaim(entity);
      entity.unaimPauseTick = new TickTimer(tick, this.ticksFromSec(opts.UNAIM_PAUSE_DURATION));
    }
  }

  entityUnaim(entity) {
    const { tick, journal } = this;

    if (entity.aimtarget === null) {
      return;
    }

    // TODO: waypoint_rule을 보고, 최상위에 initiator가 현재 aimtarget이면 잊음
    if (entity.waypoint_rule.initiator === entity.aimtarget) {
      entity.pop_rule();
    }

    entity.aimtarget = null;
    entity.aimtargetshoots = 0;
    journal.push({ tick, ty: 'unaim', entity });
  }

  entityNavigate(entity) {
    const { world, routes, tick, entities } = this;

    if (!entity.waypoint) {
      return;
    }

    if (!entity.crawlTick.expired(tick) || !entity.healTick.expired(tick) || !entity.collectTick.expired(tick)) {
      return;
    }

    // TODO: edge에 머물러야 할 때, 사격 가능한 위치 찾기
    // TODO: waypoint.path.length 확인 나중에 고쳐야 함
    if (entity.waypoint.path && entity.waypoint.path.length < 3 && entity.waypoint?.cp?.edge && entity.aimtarget) {
      const { p_from, p_to } = entity.waypoint.cp.edge;
      // lb: covering point, ub: shooting point
      const { ub } = bisectEdge(routes, p_from.pos, p_to.pos, entity.aimtarget.pos);

      const p = v2.lerp(p_from.pos, p_to.pos, ub);
      entity.waypoint.pos = p;
    }

    const d = entity.waypoint.pos.sub(entity.pos);
    const dist = d.len();

    const effect = this.entityEffect(entity, 'slope')

    function gettargetpos(speed) {
      if (dist === 0 || dist < speed) {
        return entity.waypoint.pos;
      }

      if (effect) {
        const dir = effect.effect_slope_vec.norm();

        const inner = dir.inner(d.norm()) * 0.5;
        speed = (1 + inner) * speed;
      }

      let delta = d.mul(speed / dist);
      return entity.pos.add(delta);
    }

    // 이동 방향을 정합니다
    if (dist > 0) {
      entity.dir = dirnorm(d.dir());
    }

    let speed = this.entitySpeed(entity);
    // heal 하러 오는 친구 있으면 멈춥니다.
    if (entities.find((e) => e.state !== 'dead' && e.waypoint_rule.heal_target === entity)) {
      speed = 0;
    }

    let targetpos = gettargetpos(speed);
    let dash = false;

    if (entity.perk_cover_dash) {
      const { cp } = entity.waypoint;

      if (!cp.edge && !isNaN(cp.cover) && cp.cover >= 2 && !cp.pos.eq(entity.pos)) {
        const offenced = this.entityOffenced(entity);
        if (offenced || entity.aimtarget) {
          speed = 2.0 * entity.speed / this.tps;
          targetpos = gettargetpos(speed);
          if (!cp._perk_cover_dash) {
            this.journal.push({ tick, ty: 'perk', entity, perk: 'perk_cover_dash' });
            this.bubblePush(entity, 'covering!');
          }
          cp._perk_cover_dash = true;
          dash = true;
        }
      }
    }

    // check collision
    if (opts.EXP_SOFT_COLLISION) {
      for (const other of this.entities) {
        if (other === entity) {
          continue;
        }
        const entitydist = targetpos.dist(other.pos);
        if (entitydist < entity.size + other.size && entity.name.localeCompare(other.name) > 0) {
          speed = speed / 2;
          targetpos = gettargetpos(speed);
        }
      }
    }

    const movedist = entity.pos.dist(targetpos);
    entity.pos = targetpos;
    entity.gridpos = world.worldToGrid(entity.pos);

    if (movedist > 0) {
      entity.state = dash ? 'dash' : 'stand';
      entity.moving = true;
      entity.aimtargetshoots = 0;
      entity.movespeed = speed;
    } else {
      if (entity.state !== 'covered') {
        entity.state = 'stand';
      }
      entity.moving = false;
      entity.movespeed = 0;
    }

    if (entity.pos === entity.waypoint.pos) {
      let stopped = false;
      // 재장전중에는 waypoint에서 멈춥니다
      if (!entity.reloadTick.expired(tick)) {
        stopped = true;
      }

      if (!stopped) {
        this.entityNextWaypoint(entity);
      }
    }

    const aimvar_mult = this.entityEffectParam(entity, 'aimvar_mult');
    // firearm_aimvar_incr_move_cap는 tps=30 기준으로 만들어져 있습니다.
    const firearm_aimvar_incr_move_cap = entity.firearm_aimvar_incr_move_cap * 30 / opts.tps;
    entity.aimmult += Math.min(firearm_aimvar_incr_move_cap,
      entity.movespeed * opts.AIMVAR_INCR_MOVE_MULTIPLER * aimvar_mult);
  }

  entityUpdateAim(entity, targetdir) {
    entity.debugaimdir = targetdir;

    if (opts.EXP_ROTATE_INSTANT) {
      entity.aimdir = targetdir;
      return;
    }

    const aimdelta0 = dirnorm0(targetdir - entity.aimdir);

    const rule = entity.aim_rot_rules.find((a) => a.aimvar > Math.abs(aimdelta0));

    // angular speed?
    // aimspeed는 tps=30 기준으로 만들어져 있습니다.
    let aimspeed = (rule.aimspeed * 30) / opts.tps;
    if (entity.perk_smg_fast_move) {
      aimspeed *= 1.1;
    }
    const aimdelta = clamp(aimdelta0, -aimspeed, aimspeed);
    entity.aimdir = dirnorm(entity.aimdir + aimdelta);

    const aimvar_mult = this.entityEffectParam(entity, 'aimvar_mult');

    // aimdelta만큼 aim이 흐트러짐
    // firearm_aimvar_incr_rot_cap는 tps=30 기준으로 만들어져 있습니다.
    const firearm_aimvar_incr_rot_cap = entity.firearm_aimvar_incr_rot_cap * 30 / opts.tps;
    entity.aimmult += Math.min(firearm_aimvar_incr_rot_cap,
      Math.abs(aimdelta) * opts.AIMVAR_INCR_ROT_MULTIPLER * aimvar_mult);
  }

  entityHandleFireSim(entity, samples, rng) {
    const target = entity.aimtarget;
    const variance = this.entityAimvar(entity);

    let projectile_aimvar = entity.firearm_projectile_aimvar;
    let projectile_per_shoot = entity.firearm_projectile_per_shoot;

    if (entity.perk_sg_projectile) {
      projectile_aimvar *= 1.5;
      projectile_per_shoot *= 2;
    }

    let samples_hits = 0;
    for (let j = 0; j < samples; j++) {
      const firedir = projectile_dice(rng, entity.pos, target.pos, entity.aimdir, variance, opts.AIM_ITER_FIRE);
      let hits = 0;

      for (let i = 0; i < projectile_per_shoot; i++) {
        const projectile_dir = projectile_dice(rng,
          entity.pos, target.pos,
          firedir, projectile_aimvar, opts.AIM_ITER_PROJECTILE);

        const dist = lineToPointDist(entity.pos, projectile_dir, target.pos);
        if (dist < target.size) {
          hits += 1;
        }
      }
      if (hits > 0) {
        samples_hits += 1;
      }
    }

    return samples_hits / samples;
  }

  entityExposed(entity, pos) {
    const { entities } = this;

    const ot = opponentTeam(entity.team);
    for (const e of entities) {
      if (!ot.includes(e.team) || e.state === 'dead') {
        continue;
      }

      const { world } = this;
      const gridpos = world.worldToGrid(pos);
      const grid = e.grid_vis[world.idx(gridpos)];
      if (grid > entity.vis_thres) {
        return true;
      }
    }
    return false;
  }

  // fire control이 의미가 있는지 확인합니다
  entityFireControl(entity) {
    const { entities } = this;

    // 조준 상대가 없는 경우 무시합니다.
    /*
    if (!entity.aimtarget) {
      return false;
    }
    */
    // 이미 교전중인 경우
    /*
    if (!entity.reloadShootIdleTick.expired(tick)) {
      return false;
    }
    */

    // 조준당한 경우 응사해야 합니다.
    if (entities.find((e) => e.aimtarget === entity && e.state !== 'dead')) {
      return false;
    }

    // 현재 위치/이동 목적지가 적의 시야에 노출된 경우 fire control이 의미 없습니다.
    if (this.entityExposed(entity, entity.pos)) {
      return false;
    }
    if (entity.waypoint && this.entityExposed(entity, entity.waypoint.pos)) {
      return false;
    }

    return true;
  }

  // target 등을 고려한 aimvar
  entityAimvar(entity) {
    return entity.aimvar & this.entityAimvarMult(entity);
  }

  entityAimvarMult(entity) {
    let mult = 1;
    let { firearm_aimvar_mult, aimtarget } = entity;

    mult *= firearm_aimvar_mult;
    if (aimtarget && this.entityEffect(aimtarget, 'smoke')) {
      mult *= opts.SMOKE_AIMVAR_MULT;
    }
    return mult;

  }

  // fire control 상태에서 준비되었는지
  entityFireControlReady(entity, rng) {
    if (!entity.aimtarget) {
      return false;
    }

    // waypoint에 도착했는지
    if (entity.waypoint?.cp) {
      const { cp } = entity.waypoint;
      if (!cp.edge && cp.cover >= 2 && !entity.pos.eq(cp.pos)) {
        return false;
      }
    }

    // aim이 충분히 정교한지
    const simres = this.entityHandleFireSim(entity, opts.AIM_SAMPLES_FIRE, rng);
    if (entity.aimmult > 0.01 && simres < entity.aim_samples_fire_thres) {
      return false;
    }

    return true;
  }

  // true: 사격 불가
  entityFireControlled(entity, rng) {
    // coordinated fire control
    // 시작: 아군이 entityFireControl=true 인 상태로 시작.
    // 중간: entityFireControl=true, entityFireControlReady=true 일 때까지 기다림
    // 사격 시작, 아군 중 한 명이 entityFireControl=false가 됨, fire control이 유효하지 않은 상태가 되어 사격 시작
    const { entities } = this;

    const ot = opponentTeam(entity.team);
    const allies = entities.filter((e) => !ot.includes(e.team) && e.state !== 'dead' && e.ty !== 'vip');

    // 아군 중 한명이라도 fire control이 의미 없는 상황이라면 사격합니다.
    if (allies.find((e) => !this.entityFireControl(e))) {
      return false;
    }

    // 모두 fire control이 의미 있지만, 사격 준비되지 않은 아군이 있습니다.
    if (allies.find((e) => !this.entityFireControlReady(e, rng))) {
      return true;
    }

    // 사격합니다.
    return false;
  }

  pushJournal(item) {
    const { journal } = this;
    if (journal.length === 0) {
      journal.push(item);
      return;
    }
    const last = journal[journal.length - 1];
    if (last.perk === item.perk && journal.entity === item.entity) {
      // dedup
      return;
    }
    journal.push(item);
  }

  entityOnDamage(entity, target, damage, stable_mult, hit_armor) {
    let hit = false;
    let kill = false;
    if (target.invulnerable) {
      return { kill, hit, damage: 0 };
    }

    const { journal, tick } = this;

    if (target.perk_armor_first && !hit_armor && target.armor > 0) {
      hit_armor = true;
      journal.push({ tick, ty: 'perk', entity: target, perk: 'perk_armor_first' });
    }

    if (entity.perk_egress_damage_damper > 0) {
      damage = Math.floor(damage / 2);
      entity.perk_egress_damage_damper -= 1;
    } else if (target.perk_ingress_damage_damper > 0) {
      damage = Math.floor(damage / 2);
      target.perk_ingress_damage_damper -= 1;
    }

    // 내부 피해량은 연속적이지만, 이산적인 것 처럼 속여서 보여줍니다.
    if (opts.STABLE_DAMAGE) {
      if (opts.STABLE_DAMAGE_FAKE) {
        if (target._damage_acc === undefined) {
          target._damage_acc = 0;
        }
        target._damage_acc += damage * stable_mult;
        if (target._damage_acc < damage && target._damage_acc < target.armor + target.life) {
          return { kill, hit, damage: 0 };
        }
        target._damage_acc -= damage;
      } else {
        damage *= stable_mult;
      }
    }

    const damage0 = damage;

    let damage_armor = 0;
    let damage_life = 0
    hit = true;
    if (hit_armor) {
      damage_armor = Math.min(damage, target.armor);
      damage -= damage_armor;

      if (target.perk_armor_effect) {
        this.journal.push({ tick, ty: 'perk', entity: target, perk: 'perk_armor_effect' });
        damage_armor = Math.floor(damage_armor / 2);
      }
      target.armor -= damage_armor;
    }

    let stop = damage_armor > 0 && entity.firearm_armor_stop;
    if (!stop) {
      damage_life = Math.min(target.life, damage);
      damage -= damage_life;
      target.life = target.life - damage_life;

      if (entity.perk_hit_antiarmor) {
        this.journal.push({ tick, ty: 'perk', entity, perk: 'perk_hit_antiarmor', targets: [target] });
        target.armor = Math.max(0, target.armor - damage_life);
      }
    }

    if (target.life === 0) {
      if (target.state !== 'dead') {
        target.state = 'dead';
        target.deadTick = tick;
      }
      kill = true;
    }
    return {
      kill,
      hit,
      damage: damage0 - damage,
      damage_armor,
      damage_life,
      stop,
    };
  }

  entityHandleFire(entity) {
    const { rng, tick, routes, journal, trails } = this;
    const obstacles = this.obstacles.filter((o) => o.ty === 'full');

    const target = entity.aimtarget;
    const d = target.pos.sub(entity.pos);
    const dist = d.len();
    const targetdir = dirnorm(d.dir());

    if (entity.state === 'dash') {
      this.entityUpdateAim(entity, entity.dir);
      // dash 상태에서는 사격 불가
      return { aimvalid: false };
    } else {
      this.entityUpdateAim(entity, targetdir);
    }

    const effect = this.entityEffect(target, 'smoke');

    const aimvar_mult = this.entityAimvarMult(entity);
    const variance = entity.aimvar * aimvar_mult;
    const aimdirmin = entity.aimdir - variance;
    const aimdirmax = entity.aimdir + variance;

    // 사격 통제 상태.
    if (entity.waypoint_rule.ty === 'reorg') {
      return { aimvalid: false };
    }
    if (!entity.healTick.expired(tick) || !entity.collectTick.expired(tick)) {
      return { aimvalid: false };
    }
    if (entity.shootPatternIdx === 0 && entity.allow_fire_control && this.entityFireControlled(entity, rng)) {
      return { aimvalid: false };
    }

    const cover = checkcover(entity.pos, target.pos, routes);

    let aimvalid = dircontains(targetdir, aimdirmin, aimdirmax) && dist < entity.firearm_range && cover !== 3;

    let entity_stopped = entity.state === 'crawl';
    if (!entity_stopped && entity.waypoint) {
      entity_stopped = entity.movespeed === 0;
    }

    // TODO
    if (entity.reloadTick.expired(tick) // cannot shoot during reload
      && entity.shootPatternTick.expired(tick)
      && entity.ammo > 0
      && entity.ammo_total > 0
      && aimvalid
      && entity.state !== 'hide'
      && (entity.firearm_ty !== 'sr' || entity_stopped)) {

      // shoot
      entity.aimtargetshoots += 1;

      let { aimvar_incr_per_shoot } = entity;
      if (entity.perk_cover_steady && entity.state === 'cover') {
        journal.push({ tick, ty: 'perk', entity, perk: 'perk_cover_steady' });
        aimvar_incr_per_shoot *= 0.8;
      }
      entity.aimmult += aimvar_incr_per_shoot;

      entity.ammo -= 1;
      entity.ammo_total -= 1;

      const firedir = projectile_dice(rng, entity.pos, target.pos, entity.aimdir, variance, opts.AIM_ITER_FIRE);
      const target_dist = entity.pos.dist(target.pos);

      let perk_suppress_logged = false;

      entity.reloadShootIdleTick = new TickTimer(tick, this.ticksFromSec(entity.firearm_reload_idle_duration));

      let projectile_aimvar = entity.firearm_projectile_aimvar;
      let projectile_per_shoot = entity.firearm_projectile_per_shoot;

      if (entity.perk_sg_projectile) {
        projectile_aimvar *= 1.5;
        projectile_per_shoot *= 2;
      }

      // multiple projectiles per shoot
      for (let i = 0; i < projectile_per_shoot; i++) {
        const projectile_dir = projectile_dice(rng,
          entity.pos, target.pos,
          firedir, projectile_aimvar, opts.AIM_ITER_PROJECTILE);

        const aim_arc_len = (variance + projectile_aimvar) * target_dist;
        let stable_mult = 1;
        if (opts.STABLE_DAMAGE) {
          stable_mult = Math.min(Math.pow(target.size / aim_arc_len, 2), 1);
        }

        const variance_min = entity.aimvar_hold_max * aimvar_mult;
        const aim_arc_len_min = (variance_min + projectile_aimvar) * target_dist;
        const stable_mult_max = Math.min(Math.pow(target.size / aim_arc_len_min, 2), 1);

        const projectile_end = entity.pos.add(v2.fromdir(projectile_dir).mul(entity.firearm_range));
        const t = obstructed_t(entity.pos, projectile_end, obstacles);
        const ray_len = t * entity.firearm_range;

        let valid = false;
        let hit = false;
        let kill = false;

        let prob = target.hit_prob_stand;
        if (target.perk_move_evade && target.movespeed > 0) {
          prob *= 0.9;
        }

        let { aimvar_incr_per_hit } = entity;
        if (entity.perk_hit_incr_aimvar) {
          aimvar_incr_per_hit *= 2;
        }

        if (target.perk_damp_aimvar_incr && target.firearm_ty !== 'sr') {
          aimvar_incr_per_hit *= 0.1;
        }

        // TODO: HACK: dmr/sr의 경우 명중과 무관하게 supression이 발생합니다.
        if (!['sr', 'dmr'].includes(entity.firearm_ty)) {
          aimvar_incr_per_hit *= stable_mult;
        }

        let aimvar_incr_per_hit_mult = 1.0;
        if (target.perk_aimvar_incr_m5) {
          aimvar_incr_per_hit_mult -= 0.15;
        }
        if (target.perk_aimvar_incr_m10) {
          aimvar_incr_per_hit_mult -= 0.15;
        }

        let aimvar_suppress_mult = 1.0;
        if (target.perk_suppress_m5) {
          aimvar_suppress_mult -= 0.05;
        }
        if (target.perk_suppress_incr_m10) {
          aimvar_suppress_mult -= 0.1;
        }

        let hit0 = ray_len > target_dist && target.life > 0;
        if (!opts.STABLE_DAMAGE) {
          const dist = lineToPointDist(entity.pos, projectile_dir, target.pos);
          hit0 = hit0 && dist < target.size;
        }

        let damage = 0;
        let damage_life = 0;
        let damage_armor = 0;
        let crit = false;
        let stop = false;

        let check_cover_prob = true;
        if (entity.perk_piercing_bullet) {
          if (!entity.perk_lastshoot || entity.ammo > 0) {
            journal.push({ tick, ty: 'perk', entity, perk: 'perk_piercing_bullet', targets: [target] });
          }
          check_cover_prob = false;
        }
        if (entity.perk_lastshoot && entity.ammo === 0) {
          check_cover_prob = false;
        }

        if (check_cover_prob) {
          let effcover = cover;
          if (target.perk_move_cover && target.movespeed > 0) {
            journal.push({ tick, ty: 'perk', entity: target, perk: 'perk_move_cover' });
            effcover = Math.max(effcover, 2);
          }

          if (target.perk_standing_evade && effcover === 0) {
            journal.push({ tick, ty: 'perk', entity: target, perk: 'perk_standing_evade' });
            prob *= 0.5;
          }

          if (effcover === 1) {
            // obstructed
            if (entity.perk_shoot_ignore_obstructed) {
              this.pushJournal({ tick, ty: 'perk', entity, perk: 'perk_shoot_ignore_obstructed' });
            } else if (entity.perk_pierce_moving_enemy && target.movespeed > 0) {
              // TODO: 가려짐 효과 무시
            } else {
              prob = target.hit_prob_obstructed;
            }
          } else if (effcover === 2) {
            if (target.state === 'hide') {
              prob = opts.HIT_PROB_HIDE;
            } else {
              prob = target.hit_prob_covered;
              if (entity.perk_reduce_cover_effect) {
                this.journal.push({ tick, ty: 'perk', entity, perk: 'perk_reduce_cover_effect', targets: [target] });
                prob *= 1.5;
              }
            }
          } else if (effcover === 3) {
            // blocked
            prob = 0;
          }
          if (target.state === 'crawl') {
            prob = opts.HIT_PROB_CRAWL;
          }

          if (target.perk_cover_effect) {
            prob *= 0.5;
            this.journal.push({ tick, ty: 'perk', entity: target, perk: 'perk_cover_effect', targets: [entity] });
          }
        }

        // 총기 종류에 따른 명중 확률 보정 적용
        prob += entity.firearm_additional_hit_prob;

        if (effect) {
          prob -= opts.SMOKE_HIT_PROB_PANALTY;
        }

        if (target.perk_cover_reload_evade && !target.reloadTick.expired(tick)) {
          prob = 0;
          journal.push({ tick, ty: 'perk', entity: target, perk: 'perk_cover_reload_evade' });
        } else {
          if (entity.perk_firstshoot_hit && entity.ammo === entity.firearm_ammo_max - 1) {
            prob = 1;
            journal.push({ tick, ty: 'perk', entity, perk: 'perk_firstshoot_hit' });
          }
          if ((entity.perk_lastshoot || entity.perk_lastshoot_hit) && entity.ammo === 0) {
            prob = 1;
            journal.push({ tick, ty: 'perk', entity, perk: 'perk_lastshoot_hit' });
          }
        }

        prob = clamp(prob, 0, 1);

        if (hit0) {
          valid = true;
          hit = rng.range(0, 1) < prob;

          if (hit) {
            damage = entity.firearm_projectile_damage;

            let crit_prob = entity.crit_prob;
            if (entity.perk_critical_add) {
              crit_prob += 0.02;
            }
            if (entity.team !== 0) {
              crit_prob = 0;
            }

            if (rng.range(0, 1) < crit_prob * stable_mult) {
              crit = true;
            }

            if (target.perk_glancing_blow && rng.range(0, 1) < 0.2) {
              crit = false;
            }

            if (crit) {
              damage *= 1000;
            }

            let damage_mult = 1;
            if (entity.perk_damage_standing && cover === 0 && target.movespeed === 0) {
              journal.push({ tick, ty: 'perk', entity, perk: 'perk_damage_standing', targets: [target] });
              damage_mult += 0.15;
            }

            if (entity.perk_damage_move_crawl && (target.movespeed > 0 || target.state === 'crawl')) {
              journal.push({ tick, ty: 'perk', entity, perk: 'perk_damage_move_crawl', targets: [target] });
              damage_mult += 0.15;
            }

            if (entity.perk_lastshoot && entity.ammo === 0) {
              journal.push({ tick, ty: 'perk', entity, perk: 'perk_lastshoot', targets: [target] });
              damage_mult += 0.50;
            }
            damage = Math.floor(damage * damage_mult);

            // 먼저, 확률적 피해량 모델을 적용합니다.
            if (entity.firearm_projectile_damage_prob) {
              let damage_prob = rng.weighted_key(entity.firearm_projectile_damage_prob, 'weight');
              damage = Math.floor(damage * damage_prob.multiplier);
            }

            if (entity.perk_desmar_damage && entity.firearm_ty === 'dmr') {
              damage = Math.floor(damage * 1.2);
              journal.push({ tick, ty: 'perk', entity, perk: 'perk_desmar_damage' });
            }

            if (entity.perk_firstshoot_amp && entity.ammo === entity.firearm_ammo_max - 1) {
              damage += damage;
              journal.push({ tick, ty: 'perk', entity, perk: 'perk_firstshoot_amp' });
            }
            if (entity.perk_lastshoot_amp && entity.ammo === 0) {
              damage += damage;
              journal.push({ tick, ty: 'perk', entity, perk: 'perk_lastshoot_amp' });
            }

            if (entity.perk_sr_critical && rng.range(0, 1) < 0.2) {
              damage += damage;
              journal.push({ tick, ty: 'perk', entity, perk: 'perk_sr_critical', targets: [target] });
            }

            // 방호복 처리
            let hit_armor = rng.range(0, 1) < target.armor_hit_prob;
            const res = this.entityOnDamage(entity, target, damage, stable_mult, hit_armor);
            ({ hit, kill, damage, damage_life, damage_armor, stop } = res);
          } else {
            damage = entity.firearm_projectile_damage;
          }

          journal.push({ tick, ty: 'fire', entity, target, hit, prob, kill });
          if (entity.perk_kill_recover && kill) {
            const life_next = Math.min(entity.life + 11, entity.life_max);
            if (life_next !== entity.life) {
              entity.life = life_next;
              journal.push({ tick, ty: 'perk', entity, perk: 'perk_kill_recover' });
            }
          }

          target.aimmult += aimvar_incr_per_hit * aimvar_incr_per_hit_mult;
        } else if (entity.perk_suppress) {
          target.aimmult += aimvar_incr_per_hit * aimvar_suppress_mult;

          if (!perk_suppress_logged) {
            this.pushJournal({ tick, ty: 'perk', entity, perk: 'perk_suppress' });
          }
          perk_suppress_logged = true;
        }

        const DISTRACTION_CRAWL_DURATION = opts.DISTRACTION_CRAWL_DURATION;
        if (entity.perk_desmar_distraction) {
          if (!entity.perk_desmar_distraction_range) {
            journal.push({ tick, ty: 'perk', entity, perk: 'perk_desmar_distraction', targets: [target] });
          }
          this.entityEnterCrawl(target, DISTRACTION_CRAWL_DURATION);
        }

        if (entity.perk_desmar_distraction_range) {
          const reachable_entities = this.reachableEntities(target.vis).filter((e) => e.team === target.team && e.pos.dist(target.pos) < 100);
          journal.push({ tick, ty: 'perk', entity, perk: 'perk_desmar_distraction_range', targets: [target, ...reachable_entities] });
          for (const dist_target of reachable_entities) {
            this.entityEnterCrawl(dist_target, DISTRACTION_CRAWL_DURATION);
          }
        }

        const threat_max = prob * stable_mult_max;
        const trail = {
          tick,
          pos: entity.pos,
          target_pos: target.pos,
          dir: projectile_dir,
          len: ray_len,
          source: entity,
          target,
          hit,
          threat_max,
          valid,
          kill,
          damage,
          crit,
        };
        trails.push(trail);

        // playerstats
        {
          const { playerstats } = this;
          let { morale } = playerstats;

          const team_entities = this.entities.filter((e) => e.team === 0);

          // 피격시
          if (hit) {
            if (entity.team === 0) {
              // 플레이어가 피격한 경우
            } else {
              // 플레이어가 피격당한 경우
              morale -= damage * opts.PS_MORALE_DAMAGE_MULT / team_entities.length;
            }
          }

          if (crit) {
            morale += opts.PS_MORALE_CRIT;
          } else if (kill) {
            if (entity.team === 0) {
              // 플레이어가 사살한 경우
              morale += 2;//(target._stat?._stat ?? 5) * opts.PS_MORALE_MULT_KILL;
            } else {
              // 플레이어가 사살당한 경우

              const team_overall = team_entities.reduce((acc, e) => acc + (e._stat?._stat ?? 5), 0);
              const team_level = team_entities.reduce((acc, e) => acc + (e.level ?? 1), 0);
              const team_mentality = (team_overall + team_level * 2) / team_entities.length;

              let value = ((entity._stat?._stat ?? 5) + (entity.level ?? 1) * 2) / team_mentality * opts.PS_MORALE_FALLEN_COEF / Math.sqrt(team_entities.length);
              morale -= value;
            }
          }

          playerstats.morale = clamp(morale, 0, 100);
        }

        if (!kill && hit && rng.range(0, 1) < 0.5) {
          this.bubblePush(target, 'ouch');
        }

        if (kill) {
          if (crit) {
            this.bubblePush(entity, 'target down, headshot');
          } else {
            this.bubblePush(entity, 'target down');
          }
        }

        entity.unaimTick = new TickTimer(tick, this.ticksFromSec(opts.UNAIM_DURATION));

        if (stop) {
          break;
        }
      }

      const pattern = entity.firearm_shoot_pattern;
      const shoot_tick = this.ticksFromSec(entity.firearm_shoot_pattern_interval_sec);
      if (entity.shootPatternIdx < pattern.length) {
        const shoot_pattern_interval = this.ticksFromSec(pattern[entity.shootPatternIdx]);

        entity.shootPatternIdx += 1;
        entity.shootPatternTick = new TickTimer(tick, shoot_pattern_interval);
      } else {
        entity.shootPatternIdx = 0;
        entity.shootPatternTick = new TickTimer(tick, shoot_tick);
      }
    }

    return { aimvalid };
  }

  riskdirDry(entity) {
    const { tick, world } = this;
    const { grid_vis } = entity;

    const grid = new Float32Array(world.grid_count);
    grid.set(grid_vis);

    const vismodel = this.rt;
    let res = this.riskdirWithSampler(entity, entity.pos, vismodel, grid, this.riskdirSample.bind(this));

    if (res.selected_val < 1) {
      res = this.riskdirWithSampler(entity, entity.pos, vismodel, grid, (entity, pos, samples_count, samples_weighted) => {
        this.riskdirSample(entity, pos.add(new v2(-10, -10)), samples_count, samples_weighted, vismodel, grid);
        this.riskdirSample(entity, pos.add(new v2(10, -10)), samples_count, samples_weighted, vismodel, grid);
        this.riskdirSample(entity, pos.add(new v2(-10, 10)), samples_count, samples_weighted, vismodel, grid);
        this.riskdirSample(entity, pos.add(new v2(10, 10)), samples_count, samples_weighted, vismodel, grid);
      });
    }

    return res;
  }

  riskdirSample(entity, pos, samples_count, samples_weighted, vismodel, grid) {
    vismodel = vismodel ?? this.rv;

    let range = this.entityVisRange(entity);
    let advance = 0;

    if (!pos) {
      const { dir, movespeed } = entity;
      advance = movespeed * this.ticksFromSec(1);
      pos = entity.pos.add(v2.fromdir(dir).mul(advance));
      range = range - advance * 4;
    }

    const sample_count = samples_count.length;
    // (-PI, PI) -> (0, 2PI) -> [0, sample_count)
    const sample_size = Math.PI * 2 / sample_count;

    onReachableGridWasm(this.world, vismodel, pos, range, (idx) => {
      const val = grid[idx];
      if (val === 1) {
        return true;
      }

      // angle
      const world_pos = this.world.gridIdxToWorld(idx);
      const dirvec = world_pos.sub(pos);
      const dir = dirnorm(dirvec.dir());
      const diridx = Math.floor(dir / (Math.PI * 2) * sample_count + sample_size / 2);

      const longitudal_mult = lerp(1, 0, dirvec.len() / range);
      samples_count[diridx] += 1;
      // 총 위협량보다는 각위협량이 더 중요한 듯? 원뿔 부피는 거리 제곱에 비래하니까 n^2로
      samples_weighted[diridx] += (1 - val) * longitudal_mult * longitudal_mult;
      return true;
    });
  }

  riskdir(entity, pos) {
    if (!pos) {
      const { dir, movespeed } = entity;
      const advance = movespeed * this.ticksFromSec(1);
      pos = entity.pos.add(v2.fromdir(dir).mul(advance));
    }

    const { grid_vis, grid_explore, riskdir_use_visibility_grid } = entity;
    const grid = riskdir_use_visibility_grid ? grid_vis : grid_explore;

    return this.riskdirWithSampler(entity, pos, this.rv, grid, this.riskdirSample.bind(this));
  }

  riskdirWithSampler(entity, pos, vismodel, grid, sampler_fn) {
    const sample_count = 32;
    // (-PI, PI) -> (0, 2PI) -> [0, sample_count)
    const samples_count = new Float32Array(sample_count);
    const samples_weighted = new Float32Array(sample_count);

    const rays = [];
    for (let i = 0; i < sample_count; i++) {
      const dir = (i / sample_count) * Math.PI * 2;
      const vec = v2.fromdir(dir);
      rays.push(vec);
    }
    const samples_ray = raycastWasm(vismodel, pos, rays);

    sampler_fn(entity, pos, samples_count, samples_weighted, vismodel, grid);

    const samples = samples_weighted;

    let maxidx = 0;
    let maxval = samples[0];

    for (let i = 0; i < sample_count; i++) {
      if (samples[i] > maxval) {
        maxval = samples[i];
        maxidx = i;
      }
    }

    return {
      pos,
      sample_count,
      samples,
      samples_ray,

      selected_idx: maxidx,
      selected_val: maxval,
      selected_dir: (maxidx / sample_count) * Math.PI * 2,
    };
  }

  entityUpdateGridOmniDir(entity, pos, update_vis, vismodel) {
    const { grid_vis, grid_explore } = entity;
    const range = this.entityVisRange(entity);

    vismodel = vismodel ?? this.rv;

    onReachableGridWasm(this.world, vismodel, pos, range, (idx) => {
      // TODO: opts.GRID_VIS_PER_TICK?
      if (update_vis) {
        grid_vis[idx] = 1;
      }
      grid_explore[idx] = 1;
      return true;
    });
  }

  entityUpdateGrid(entity, pos) {
    if (!pos) {
      pos = entity.pos;
    }

    const { grid_vis, grid_explore } = entity;
    const vis_var = this.entityEffectParam(entity, 'vis_var');
    const range = this.entityVisRange(entity);

    let out_to_in = false;
    if (entity.team === 0 && opts.FOG_OUT_TO_IN) {
      out_to_in = true;
    }
    const vis = this.rv.triangulated.visibility(pos.x, pos.y, out_to_in);
    vis.limit(range);
    onReachableGridWasmApply(this.world, vis, (idx) => {
      if (grid_vis[idx] === 1.0) {
        return true;
      }

      // angle
      const world_pos = this.world.gridIdxToWorld(idx);
      const dirvec = world_pos.sub(pos);
      const dir = dirvec.dir();
      const reldir = dirnorm0(entity.aimdir - dir);

      if (Math.abs(reldir) < vis_var) {
        if (opts.EXP_VISIBILITY_SOFT) {
          const angular_mult = lerp(1, 0.5, Math.abs(reldir) / vis_var);
          const longitudal_mult = lerp(1, 0.1, dirvec.len() / range);
          const incr = angular_mult * longitudal_mult * longitudal_mult * opts.GRID_VIS_PER_TICK;
          grid_vis[idx] = Math.min(1.0, grid_vis[idx] + incr);
        } else {
          grid_vis[idx] = 1.0;
        }
        grid_explore[idx] = 1;
      } else {
        grid_vis[idx] = Math.min(1.0, grid_vis[idx] + opts.GRID_VIS_OMNIDIR_PER_TICK);
        grid_explore[idx] = Math.min(1.0, grid_explore[idx] + opts.GRID_VIS_OMNIDIR_PER_TICK);
      }

      return true;
    });

    // cache latest visibility
    if (entity.vis) {
      entity.vis.free();
      entity.vis = null
    }
    entity.vis = vis;

    // update awareness
    for (const e of this.entities) {
      if (entity.seq === e.seq || entity.team === e.team) {
        continue;
      }
      const vis = entity.grid_vis[this.world.idx(e.gridpos)];
      let awareness = entity.awareness[e.seq];
      if (isNaN(awareness)) {
        awareness = 0;
      }
      entity.awareness[e.seq] = (awareness + vis * entity.aware_mult) * entity.aware_decay;
    }
  }

  entityVisible(entity, pos) {
    const { world } = this;
    const gridpos = world.worldToGrid(pos);
    return entity.grid_vis[world.idx(gridpos)] > 0.1;
  }

  entityAware(entity, e) {
    const { world } = this;
    if (opts.USE_AWARE) {
      return entity.awareness[e.seq] > e.vis_thres;
    } else {
      return entity.grid_vis[world.idx(e.gridpos)] > e.vis_thres;
    }
  }

  entityVisibleAllies(entity) {
    return this.entities.filter((e) => {
      if (e === entity || e.team !== entity.team) {
        return false;
      }

      if (entity.use_visibility) {
        if (entity.grid_explore[this.world.idx(e.gridpos)] < e.vis_thres) {
          return false;
        }
      }
      return true;
    });
  }

  entityVisibleOpponents(entity) {
    const ot = opponentTeam(entity.team);
    return this.entities.filter((e) => {
      if (e === entity || !ot.includes(e.team) || e.state === 'dead') {
        return false;
      }

      if (entity.use_visibility) {
        if (!this.entityAware(entity, e)) {
          return false;
        }
      }
      return true;
    });
  }

  entityReload(entity) {
    const { journal } = this;
    if (!entity.reloadTick.expired(this.tick)) {
      // 이미 재장전중. 무시합니다.
      return;
    }

    const { tick } = this;
    if (entity.perk_instant_reload) {
      entity.reloadTick = new TickTimer(tick, 1);
      journal.push({ tick, ty: 'perk', entity, perk: 'perk_instant_reload' });
    } else {
      let { firearm_reload_duration } = entity;
      if (entity.perk_crawl_reload && entity.state === 'cover') {
        firearm_reload_duration *= 0.5;
      }
      entity.reloadTick = new TickTimer(tick, this.ticksFromSec(firearm_reload_duration));
    }

    // TODO: 실내 데모
    // entity.push_rule(tick, { ty: 'reload' });
  }

  entityOffenced(entity, filterFn) {
    const { tick, trails } = this;

    const response_tick = this.ticksFromSec(entity.response_time);

    const last_attacked = trails.reverse().find((t) => {
      // 반응하지 못한 사격
      if (t.tick > tick - response_tick) {
        return false;
      }
      if (t.target !== entity) {
        return false;
      }
      if (filterFn && !filterFn(t)) {
        return false;
      }
      return true;
    });
    const last_attacked_tick = last_attacked ? last_attacked.tick : -1000;

    if (tick - last_attacked_tick < this.ticksFromSec(opts.SHOT_IDLE_DURATION)) {
      return last_attacked;
    }
    return null;
  }

  entityTransferKnoledge(entity) {
    const { entities } = this;
    const allies = entities.filter((e) => e !== entity && e.team === entity.team && e.state !== 'dead');

    for (const e of allies) {
      if (entity.grid_explore === e.grid_explore) {
        // already sharing
        continue;
      }

      const len = entity.grid_explore.length;
      for (let i = 0; i < len; i++) {
        const v = Math.max(e.grid_explore[i], entity.grid_explore[i]);
        e.grid_explore[i] = v;
        entity.grid_explore[i] = v;
      }
    }
  }

  entityCoverPoilcy(entity) {
    if (entity.firearm_ty === 'sr') {
      return 'cover';
    }
    if (entity.team !== 0) {
      return 'cover-fire';
    }
    return this.controlGet(entity.spawnarea, 'cover') ? 'cover' : 'cover-fire';
  }

  visibleNodes(vis, nodes) {
    const coords = [];
    for (const { pos: { x, y } } of nodes) {
      coords.push(x);
      coords.push(y);
    }

    return vis.visible(coords);
  }

  reachableEntities(vis) {
    const { entities } = this;
    const coords = [];
    for (const { pos: { x, y } } of entities) {
      coords.push(x);
      coords.push(y);
    }

    const visibles = vis.visible(coords);
    return entities.filter((_e, i) => visibles[i] > 0);
  }

  entityTryThrowables(entity) {
    const { throwables } = entity;

    const throwable_damage = throwables.find((t) => {
      return t.blasts.find((b) => b.blast_ty === 'damage');
    });
    if (throwable_damage) {
      if (this.entityTryThrowable(entity, throwable_damage)) {
        return;
      }
    }

    const smoke = throwables.find((t) => {
      return t.blasts.find((b) => b.blast_ty === 'damage') === undefined;
    });
    if (smoke) {
      this.entityTryThrowableSmoke(entity, smoke);
    }
  }

  entityTryThrowableSmoke(entity, throwable) {
    // smoke는 다음 상황에서 던집니다
    //  - aimtarget이 사거리 밖에 있고
    //  - aimtarget의 사거리가 나의 사거리보다 길고 현재 위협받고 있음

    let max_blast_radius = _.max(throwable.blasts.map((b) => b.blast_radius));
    // TODO:
    /*
    if (entity.perk_grenadier_effect_range) {
      max_blast_radius *= 1.5;
    }
    */

    // sample direction
    const { aimdir, pos, vis, aimtarget } = entity;

    // 현재 교전 가능
    if (aimtarget) {
      if (aimtarget.pos.dist(entity.pos) < entity.firearm_range) {
        return;
      }
      // 사거리 차이가 크게 나지 않음
      if (aimtarget.firearm_range - entity.firearm_range < 50) {
        return;
      }
    }

    // 공격받지 않으면 무시
    const offenced = this.entityOffenced(entity);
    if (!offenced) {
      return;
    }

    const dir = v2.fromdir(aimdir);

    const ray = raycastVisibilityWasm(vis, [dir])[0].sub(pos);

    let { throwable_max_dist } = entity;
    if (entity.perk_grenadier_throw_range) {
      throwable_max_dist *= 1.2;
    }

    // arbitrary value
    const dist_min = 50;
    const dist_max = Math.min(ray.len() - 20, throwable_max_dist);

    for (let dist = dist_max; dist >= dist_min; dist -= 50) {
      const p1 = pos.add(dir.mul(dist));

      const vis_throwable = this.rv.triangulated.visibility(p1.x, p1.y, false);
      vis_throwable.limit(max_blast_radius);
      const vis_entities = this.reachableEntities(vis_throwable);

      // const ally_count = vis_entities.filter((e) => e.team === entity.team && e.state !== 'dead').length;
      const enemy_affected = vis_entities.filter((e) => e.team !== entity.team && e.state !== 'dead')
      const enemy_count = enemy_affected.length;

      vis_throwable.free();

      if (enemy_count > 0) {
        return;
      }

      entity.throwables = entity.throwables.filter((t) => t !== throwable);
      this.journal.push({ tick: this.tick, ty: 'throw_general', entity, throwable, targets: enemy_affected, });
      this.entityThrow(entity, throwable, p1);
      // this.entityHandleThrowable(entity, throwable, p1);
      break;
    }
  }

  entityTryThrowable(entity, throwable) {
    let max_blast_radius = _.max(throwable.blasts.map((b) => b.blast_radius));
    if (entity.perk_grenadier_effect_range) {
      max_blast_radius *= 1.5;
    }

    // sample direction
    const { aimdir, pos, vis } = entity;

    const dir = v2.fromdir(aimdir);

    const ray = raycastVisibilityWasm(vis, [dir])[0].sub(pos);
    if (ray.len() < max_blast_radius) {
      return false;
    }

    let { throwable_max_dist } = entity;
    if (entity.perk_grenadier_throw_range) {
      throwable_max_dist *= 1.2;
    }

    const dist_min = max_blast_radius * 1.5;
    const dist_max = Math.min(ray.len() - 20, throwable_max_dist);

    // const dist = Math.min(ray.len() * 0.9, rng.range(max_blast_radius * 1.5, entity.throwable_max_dist));

    for (let dist = dist_max; dist >= dist_min; dist -= 50) {
      const p1 = pos.add(dir.mul(dist));

      const vis_throwable = this.rv.triangulated.visibility(p1.x, p1.y, false);
      const block_throwable = this.rb.triangulated.visibility(p1.x, p1.y, false);

      vis_throwable.limit(max_blast_radius);
      block_throwable.limit(max_blast_radius);

      const ally_count = this.reachableEntities(vis_throwable).filter((e) => e.team === entity.team && e.state !== 'dead').length;
      const enemy_direct = this.reachableEntities(block_throwable).filter((e) => e.team !== entity.team && e.state !== 'dead');
      const enemy_direct_count = enemy_direct.length;
      const enemy_indirect = this.reachableEntities(vis_throwable).filter((e) => e.team !== entity.team && e.state !== 'dead');
      const enemy_indirect_count = enemy_indirect.length;
      const enemy_affected = _.uniq([...enemy_direct, ...enemy_indirect]);
      vis_throwable.free();
      block_throwable.free();

      // 양심이 있으면 프래깅은 하지 맙시다
      if (ally_count > 0) {
        continue;
      }

      if (2 * enemy_direct_count + enemy_indirect_count > 2) {
        entity.throwables = entity.throwables.filter((t) => t !== throwable);
        this.journal.push({ tick: this.tick, ty: 'throw_general', entity, throwable, targets: enemy_affected, });
        if (entity.perk_grenadier_throw_range && dist * 1.2 > throwable_max_dist) {
          this.journal.push({ tick: this.tick, ty: 'perk', perk: 'perk_grenadier_throw_range', entity, });
        }
        this.entityThrow(entity, throwable, p1);
        // this.entityHandleThrowable(entity, throwable, p1);
        return true;
      }
    }
    return false;
  }

  teamVisibility(team) {
    const { world, entities } = this;

    const fogbuf = new Float32Array(world.grid_count);
    for (const entity of entities) {
      if (entity.team !== team || entity.state === 'dead') {
        continue;
      }
      const { grid_vis, grid_explore } = entity;
      for (let i = 0; i < world.grid_count; i++) {
        fogbuf[i] = Math.max(Math.max(fogbuf[i], grid_explore[i] * 0.5), grid_vis[i]);
      }
    }
    return fogbuf;
  }

  visibilityAt(entity, pos) {
    const { world } = this;
    const gridpos = world.worldToGrid(pos);
    return entity.grid_vis[world.idx(gridpos)] ?? NaN;
  }

  teamVisibilityAt(team, pos) {
    const { world, entities } = this;

    let val = 0.0;
    for (const entity of entities) {
      if (entity.team !== team || entity.state === 'dead') {
        continue;
      }
      const gridpos = world.worldToGrid(pos);
      val = Math.max(val, entity.grid_vis[world.idx(gridpos)]);
    }
    return val;
  }

  onTickHeal(entity) {
    const { tick } = this;
    const { ty, heal_target } = entity.waypoint_rule;
    if (ty !== 'heal') {
      return;
    }

    const [h] = entity.heals;

    if (entity.heals.length === 0 || heal_target.state === 'dead') {
      entity.pop_rule();
      return;
    }

    // 체력이 부족해진 경우 취소
    if (entity.perk_commed_risk && heal_target.life <= h.heal_amount / 2) {
      entity.pop_rule();
      return;
    }

    if (entity.pos.dist(heal_target.pos) > opts.HEAL_RADIUS) {
      return;
    }

    if (entity.healTick.expired_exact(tick)) {
      let heal_multiplier = 1.0;
      if (entity.perk_commed_amount) {
        this.journal.push({ tick, ty: 'perk', perk: 'perk_commed_amount', entity, heal_target, });
        heal_multiplier += 0.2;
      }
      if (entity.perk_commed_risk) {
        heal_multiplier += 1.0;
      }

      if (heal_target.perk_healed_amount_5) {
        heal_multiplier += 0.05;
      }
      if (heal_target.perk_healed_amount_15) {
        heal_multiplier += 0.15;
      }

      // 한 번만 스스로 치료할 수 있어야 합니다.
      if (entity === heal_target) {
        entity._perk_commed_heal_self = true;
      }

      if (entity.perk_commed_buff) {
        if (heal_target._stat._stat && heal_target._firearm_stat) {
          const stat = heal_target._stat._stat;
          const stats_next = stats_const(stat + 2);

          this.journal.push({ tick: this.tick, ty: 'perk', perk: 'perk_commed_buff', entity, heal_target, });
          updateEntityStat(heal_target, stats_next, heal_target._firearm_stat);
        }
      }

      // 끝
      const prevLife = heal_target.life;
      heal_target.life = Math.min(heal_target.life_max, heal_target.life + h.heal_amount * heal_multiplier);
      this.heals.push({ source: entity, heal_target, heal_amount: heal_target.life - prevLife });
      entity.heals.splice(0, 1);
      entity.pop_rule();
    } else if (entity.healTick.expired(tick)) {
      let duration_multiplier = 1.0;
      if (entity.perk_commed_workspeed) {
        this.journal.push({ tick: this.tick, ty: 'perk', perk: 'perk_commed_workspeed', entity, heal_target, });
        duration_multiplier = 0.8;
      }

      const duration = this.ticksFromSec(h.heal_duration * duration_multiplier);
      // 처음 시작
      entity.healTick = new TickTimer(tick, duration);

      if (entity.perk_commed_risk) {
        this.journal.push({ tick: this.tick, ty: 'heal_risk', entity, heal_target, });
        heal_target.life -= Math.floor(h.heal_amount / 2);
      }
    } else {
    }
  }

  maybeHeal(entity) {
    if (entity.heals.length === 0) {
      return;
    }
    // 교전중
    if (entity.aimtarget !== null) {
      return;
    }

    const { tick, entities } = this;

    if (entity.waypoint_rule.ty !== 'explore') {
      return;
    }
    const [h] = entity.heals;
    const heal_thres = this.controlGet(entity.spawnarea, 'heal');

    const candidates = entities.filter((e) => {
      if (e.team !== entity.team || e.life === e.life_max) {
        return false;
      }
      if (e === entity && !entity.perk_commed_target_self && !entity._perk_commed_heal_self) {
        return false;
      }
      // 체력이 충분하지 않은 경우
      if (entity.perk_commed_risk && e.life <= h.heal_amount / 2) {
        return false;
      }
      if (e.life >= e.life_max * heal_thres) {
        return false;
      }
      return true;
    });
    if (candidates.length === 0) {
      return;
    }

    candidates.sort((a, b) => {
      return entity.pos.dist(a.pos) < entity.pos.dist(b.pos);
    });

    const [target] = candidates;

    if (this.entities.find((e) => e.waypoint_rule.heal_target === target)) {
      return;
    }

    // TODO
    if (entity.name === target.name) {
      this.journal.push({ tick: this.tick, ty: 'heal_self', entity, target });
    } else {
      this.journal.push({ tick: this.tick, ty: 'heal_start', entity, target });
    }
    entity.push_rule(tick, { ty: 'heal', heal_target: target });
  }

  entityUpdateAimvar(entity) {
    const { entities } = this;

    // aimvar_decay_per_tick은 tps=30을 기준으로 만들어져 있음
    let decay_mult = 1.0;
    if (entity.aimtarget && entity.perk_desmar_aimspeed && entity.aimtarget.life === entity.aimtarget.life_max) {
      decay_mult += 1.0;
      this.journal.push({ tick: this.tick, ty: 'perk', entity, perk: 'perk_desmar_aimspeed' });
    }
    if (entity.perk_stationary_aimspeed && entity.movespeed === 0) {
      decay_mult += 0.1;
    }

    const { aimtarget } = entity;
    if (aimtarget) {
      if (entity.perk_aim_together) {
        let allies = entities.filter((e) => e !== entity && e.team === entity.team && e.state === 'covered' && e.aimtarget === aimtarget);
        if (allies.length > 0) {
          this.journal.push({ tick: this.tick, ty: 'perk', entity, perk: 'perk_aim_together', targets: [aimtarget] });
          decay_mult += 0.5;
        }
      }

      if (entity.perk_aim_execute && aimtarget.life < aimtarget.life_max) {
        this.journal.push({ tick: this.tick, ty: 'perk', entity, perk: 'perk_aim_execute', targets: [aimtarget] });
        decay_mult += 0.5;
      }
    }

    let aimvar_decay = Math.pow(entity.aimvar_decay_per_tick, 30 / opts.tps);
    aimvar_decay = 1 - (1 - aimvar_decay) * decay_mult;
    entity.aimmult = entity.aimmult * aimvar_decay;

    let aimvar_hold_mult = 1;
    let aimvar_hold_max_mult = 1;
    if (entity.perk_aimvar_5) {
      aimvar_hold_mult -= 0.05;
    }
    if (entity.perk_aimvar_10) {
      aimvar_hold_mult -= 0.1;
    }
    if (entity.perk_aimvar_max_5) {
      aimvar_hold_max_mult -= 0.05;
    }
    if (entity.perk_aimvar_max_10) {
      aimvar_hold_max_mult -= 0.1;
    }

    let aimvar_hold = entity.aimvar_hold * aimvar_hold_mult;
    let aimvar_hold_max = this.entityEffectParam(entity, 'aimvar_hold_max') * aimvar_hold_max_mult;


    const aims = entities.filter((e) => e.perk_aimtarget_incr_aimvar && !['sr', 'dmr'].includes(e.firearm_ty) && e.aimtarget === entity && e.state !== 'dead');
    if (aims.length > 0) {
      aimvar_hold_max *= 1.25;
    }
    entity.aimvar = lerp(aimvar_hold_max, aimvar_hold, entity.aimmult);
  }

  entityEnterCrawl(entity, seconds) {
    seconds = seconds ?? opts.CRAWL_MIN_DURATION;
    if (entity.perk_engage_dash) {
      return;
    }
    if (entity.state === 'dead') {
      return;
    }

    const { tick } = this;
    entity.state = 'crawl';
    entity.moving = false;

    entity.crawlTick = new TickTimer(tick, this.ticksFromSec(seconds));
  }

  entityUpdateThrowable(entity) {
    const { tick } = this;

    if (!this.controlGet(entity.spawnarea, 'throwable')) {
      return;
    }

    if (!entity.checkThrowableTick.expired(tick)) {
      return;
    }
    entity.checkThrowableTick = new TickTimer(tick, this.ticksFromSec(...opts.CHECK_THROWABLE_INTERVAL_RANGE));

    const team_throwables = this.throwables.filter((t) => t.entity.team === entity.team);
    if (team_throwables.length > 0) {
      const tick_last = team_throwables[team_throwables.length - 1].tick;
      if (tick - tick_last < this.ticksFromSec(opts.THROWABLE_INTERVAL)) {
        return;
      }
    }

    // TODO: throwable
    this.entityTryThrowables(entity);
  }

  entityUpdate(entity) {
    if (entity.state === 'dead') {
      return;
    }
    if (entity.waypoint_rule.ty === 'dummy') {
      // preset_aimvar 테스트용
      this.entityUpdateAimvar(entity);
      return;
    }

    const { tick, rng, routes, goals, entities, world } = this;

    const route_interval = rng.range(...opts.REROUTE_INTERVAL_RANGE);
    const reroute_ticks = this.ticksFromSec(route_interval);
    let reroute = entity.waypoint_rule.ty !== 'explore' && tick % reroute_ticks === 0;

    let reload = entity.ammo === 0;

    if (entity.use_visibility) {
      this.entityUpdateGrid(entity);
    }

    while ((entity.waypoint_rule.expires_at ?? tick) < tick) {
      console.log('rule expired', entity.waypoint_rule);
      entity.pop_rule();
    }

    // aim한 상대와 주어진 시간동안 교전하지 못한 경우, aim을 해제합니다.
    if (entity.unaimTick.expired_exact(tick)) {
      this.entityUnaim(entity);
    }

    // specify target
    const update_aimtarget = entity.retargetTick.expired(tick)
      || entity.aimtarget === null
      || entity.aimtarget.state === 'dead';
    if (update_aimtarget) {
      if (this.entityUpdateAimtarget(entity)) {
        // reset route. allow_cover_edge용
        entity.lastRouteTick = new TickTimer(tick, 0);
      }
      entity.retargetTick = new TickTimer(tick,
        this.ticksFromSec(rng.range(...entity.retarget_interval_range)));

      this.maybeRescueVIP(entity);
      this.maybeHeal(entity);
    }

    this.onTickHeal(entity);
    this.entityUpdateThrowable(entity);

    // const rescue_target_remain = entities.find((e) => e.team !== 0 && e.ty === 'vip');
    if (entity.waypoint_rule.ty === 'rescue' && entity.waypoint_rule.rescue_target.team === entity.team) {
      // TODO
      entity.pop_rule();
    }

    let waypoint_near = false;
    if (entity.waypoint) {
      // waypoint가 있는 경우
      const wp_dist = entity.waypoint.pos.dist(entity.pos);
      waypoint_near = wp_dist < entity.waypoint_dash_dist;
    }

    // 최근에 공격받은 적 있는지
    const offenced = this.entityOffenced(entity);

    // 시야 밖에서 공격받은 적 있는지
    const offenced_invis = this.entityOffenced(entity, (t) => {
      return !this.entityAware(entity, t.source);
    });

    // 위협적인 사격을 받은 적 있는지
    const offenced_crawl = this.entityOffenced(entity, (t) => {
      return t.threat_max >= opts.THREAT_THRES_CRAWL;
    });

    const allow_crawl = this.entityEffectParam(entity, 'allow_crawl');
    if ((offenced_invis || allow_crawl) && ['crawl', 'stand'].includes(entity.state)) {
      if (offenced_crawl && !waypoint_near) {
        this.entityEnterCrawl(entity);
      }
    }

    // 오랜 시간 공격받지 않았을 경우, 다시 일어나서 움직입니다.
    if (entity.state === 'crawl' && entity.crawlTick.expired(tick)) {
      entity.state = 'stand';
    }

    if (entity.waypoint_rule.ty === 'cover' && entity.aimtarget) {
      const dist = entity.pos.dist(entity.aimtarget.pos);
      const exposed = entity.firearm_range < dist && entity.aimtarget.firearm_range > dist;
      let transit = false;

      // 반격할 수 없는 상황에 오래 노출된 경우, 정책을 바꿉니다.
      if (entity.movespeed === 0 && exposed) {
        transit = true;
      }
      // 교전을 시작할 수 없는 경우
      if (tick - entity.waypoint_rule.tick > this.ticksFromSec(opts.COVER_FIRE_DURATION)) {
        transit = true;
      }
      const progress = entities.find((e) => e.state !== 'dead' && e.movespeed > 0);
      if (!progress) {
        transit = true;
      }

      if (transit) {
        entity.push_rule(tick, { ty: 'cover-fire', initiator: entity.aimtarget });
        reroute = true;
      }
    }

    if (entity.waypoint?.cp && entity.waypoint.pos.eq(entity.pos)) {
      if (entity.waypoint.cp.obstacle?.ty === 'half' && !['covered', 'hide'].includes(entity.state)) {
        entity.state = 'covered';
      }
    }

    const opponents = this.entityVisibleOpponents(entity);
    if (entity.waypoint_rule.ty === 'idle' && entity.ty !== 'vip') {
      if (opponents.length > 0) {
        entity.push_rule(tick, { ty: 'cover-fire', initiator: opponents[0] });
        reroute = true;
        this.bubblePush(entity, '웬 놈들이냐!');
      }
    }

    // TODO: 하드코딩 고치기
    if (entity.waypoint_rule.ty === 'idle' && !entity.waypoint_rule.alert && this.playerstats.uncover >= 25) {
      entity.push_rule(tick, { ty: 'idle', alert: true });
    }

    if (entity.waypoint_rule.ty === 'idle') {
      // idle awake
      const allies = [...this.entityVisibleAllies(entity), entity];
      // TODO: handle multiple offencer?
      const offenced = allies.map((e) => this.entityOffenced(e)).find((e) => e !== null);
      const initiated = allies.find((e) => e.waypoint_rule.initiator);
      if (offenced) {
        // check visibility
        const visible = this.entityAware(entity, offenced.source);

        if (!world.exp_search || visible) {
          entity.push_rule(tick, { ty: 'cover-fire', initiator: offenced.source });
          reroute = true;
          this.bubblePush(entity, '습격인가?');
        } else {
          // TODO
          const initiator = offenced.source;
          entity.push_rule(tick, { ty: 'explore', initiator });
          entity.push_rule(tick, { ty: 'hide', initiator, expires_at: tick + this.ticksFromSec(10) });
          reroute = true;
        }
      }
      if (initiated && !reroute) {
        entity.push_rule(tick, { ty: 'explore', initiator: initiated.waypoint_rule.initiator });
        reroute = true;
      }
    }

    const cover_ty = this.entityCoverPoilcy(entity);
    if (offenced && ['explore', 'hide'].includes(entity.waypoint_rule.ty)) {
      const visible = this.entityAware(entity, offenced.source);
      if (!world.exp_search || visible) {
        entity.push_rule(tick, { ty: cover_ty, initiator: offenced.source });
        reroute = true;
      }
    }

    if (offenced && entity.waypoint_rule.ty === 'capture-goal' && entity.ty !== 'vip') {
      entity.push_rule(tick, { ty: 'cover-fire', initiator: offenced.source });
      reroute = true;
    }

    let last_rule = entity.waypoint_rule.ty;

    if (entity.waypoint_rule.initiator && entity.waypoint_rule.initiator.state === 'dead') {
      if (entity.aimtarget) {
        // 교전이 아직 끝나지 않은 경우
        entity.waypoint_rule.initiator = entity.aimtarget;
      } else {
        entity.pop_rule();
      }
    }
    if (entity.aimtarget && entity.aimtarget.state !== 'dead' && ['explore', 'hide'].includes(entity.waypoint_rule.ty)) {
      entity.push_rule(tick, { ty: cover_ty, initiator: entity.aimtarget });
    }

    if (last_rule !== entity.waypoint_rule.ty) {
      if (entity.waypoint_rule.ty === 'explore') {
        this.bubblePush(entity, '위협 제거, 탐색을 계속한다');
      } else {
        this.bubblePush(entity, '교전 시작');
      }
    }

    function entityInGoal(entity, target_goal) {
      const goal = entity.waypoint_rule.goal;
      if (!goal || target_goal !== goal) {
        return false;
      }
      return goal && goal.pos.dist(entity.pos) < opts.GOAL_RADIUS;
    }

    // TODO: 미션 시나리오 만들기
    if (entity.waypoint_rule.ty === 'mission') {
      for (const rule of this.mission_rules) {
        entity.push_rule(tick, { ...rule });
      }
    }

    // TODO
    if (entity.waypoint_rule.ty === 'interact') {
      const object = this.objects[entity.waypoint_rule.object];
      const chaser = this.entities.find((e) => {
        const r = e.waypoint_rule;
        return e.state !== 'dead' && r.ty === 'interact-object' && r.object === object;
      });
      if (!chaser) {
        if (this.entityVisible(entity, object.pos)) {
          entity.push_rule(tick, { ty: 'interact-object', object });
        } else {
          entity.push_rule(tick, { ty: 'explore', area: this.spawnareas[object.spawnarea] });
        }
      } else {
        entity.push_rule(tick, { ty: 'cover' });
      }
    }

    // TODO: execution order
    if (entity.waypoint_rule.ty === 'interact-object' && entity.waypoint_rule.object.pos.dist(entity.pos) < opts.INTERACT_RADIUS) {
      // 수집 완료
      if (entity.collectTick.expired_exact(tick)) {

        const { playerstats } = this;
        const { loot } = playerstats;
        const object = entity.waypoint_rule.object;
        object.owned = true;
        entity.objects.push(object);

        // TODO: 상자 열기
        const engaged = this.entities.filter((e) => e.team !== 0 && e.state === 'dead' && !e._engaged);
        for (const entity of engaged) {
          entity._engaged = true;
        }
        for (const entity of engaged) {
          if (loot.inventory.length === 0 && !isNaN(entity.firearm_rate)) {
            if (rng.range(0, 1) < opts.PS_LOOT_FIREARM_PROB) {
              loot.inventory.push({ ty: 'firearm', firearm: entity });
              break;
            }
          }
        }

        loot.resource += rng.integer(...opts.PS_OBJECT_RESOURCE_AMOUNT_RANGE);

        const entities = this.entities.filter((e) => e.state !== 'dead' && e.team === entity.team);
        for (const e of entities) {
          e.pop_rule_chain((r) => r.ty === 'interact' && r.object === object.seq);
        }
      } else if (!entity.collectTick.expired(tick)) {
        // 수집중
      } else {
        // 수집 시작
        entity.collectTick = new TickTimer(tick, this.ticksFromSec(opts.INTERACT_DURATION));
      }
    }

    // TODO: explore: 건물 있는 경우 건물을 순차적으로 탐색하기
    /*
    if (entity.waypoint_rule.ty === 'explore'
      && entity.waypoint_rule.area?.areastate?.structures) {
      const { area } = entity.waypoint_rule;
      entity.pop_rule();

      for (const s of area.areastate.structures) {
        // 개별 방 수색
        entity.push_rule(tick, { ty: 'explore', area: s.shape });
      }

      this.entityNextWaypoint(entity);
    }
    */

    if (entity.waypoint_rule.ty === 'capture') {
      // capture: choose goal
      const p = this.choosecapturepoint(entity, null);
      if (p && p.obstacle) {
        entity.push_rule(tick, { ty: 'capture-goal', goal: p.obstacle });
      } else {
        console.error('unable to choose goal, covering');
        entity.push_rule(tick, { ty: cover_ty });
      }
    }
    if (entity.waypoint_rule.ty === 'capture-goal') {
      const { goal } = entity.waypoint_rule;

      let captured = false;
      if (goal.waypoint) {
        // waypoint인 경우, 모든 구성원이 goal에 도착할 때 까지 기다립니다
        captured = entityInGoal(entity, goal);
      } else {
        captured = goal.goalstate.owner >= 0;
      }

      this.maybePopGroupRule(entity, captured);

      // goal에 나보다 먼저 들어가 있는 상대가 있는 경우
      const opponent = entities.find((e) => e.state !== 'dead' && e.team !== entity.team && entityInGoal(e, goal));
      if (opponent) {
        entity.push_rule(tick, { ty: 'cover-fire', target: opponent });
      }
    }

    if (entity.waypoint_rule.ty === 'gather') {
      this.maybePopGroupRule(entity, entity.waypoint_rule.leader.pos.dist(entity.pos) < 20);
    }

    if (entity.waypoint_rule.ty === 'cover-capture') {
      const nearest = goals.slice();
      nearest.sort((a, b) => entity.pos.dist(a.pos) - entity.pos.dist(b.pos));
      if (nearest.length > 0) {
        entity.push_rule(tick, { ty: 'cover-goal', goal: nearest[0] });
      } else {
        console.error('unable to choose goal, covering');
        entity.push_rule(tick, { ty: cover_ty });
      }
    }

    // reload
    if (entity.reloadTick.expired_exact(tick)) {
      entity.ammo = Math.min(entity.firearm_ammo_max, entity.ammo_total);
      if (entity.perk_reload_one_more) {
        entity.ammo += 1;
        this.journal.push({ tick, ty: 'perk', entity, perk: 'perk_reload_one_more' });
      }

      if (entity.perk_armor_recover && entity.armor === 0 && !entity._perk_armor_recovered) {
        entity.armor = entity.armor_max;
        entity._perk_armor_recovered = true;

        this.journal.push({ tick, ty: 'perk', entity, perk: 'perk_armor_recover' });
      }
      // TODO: 실내 데모
      // console.assert(entity.waypoint_rule.ty === 'reload');
      // entity.pop_rule();
    }

    if (entity.ammo < entity.firearm_ammo_max) {
      if (entity.reloadTick.expired(tick) && entity.reloadShootIdleTick.expired(tick)) {
        // 유효한 조준을 유지하는 동안 재장전하지 않습니다. aim_samples_fire_thres가 너무 높으면 발생
        if (!(entity.aimtarget && checkcover(entity.pos, entity.aimtarget.pos, routes) !== 3)) {
          reload = true;
        }
      }

      if (!entity.aimtarget && entity.lastRiskTick.expired(tick) && entity.waypoint) {
        const r = this.riskdir(entity, entity.waypoint.pos);
        if (r.selected_val === 0) {
          reload = true;

          if (entity.team === 0 && world.exp_transfer_knowledge) {
            this.entityTransferKnoledge(entity);
          }
        }
      }
    }

    if (reload && entity.ammo < entity.firearm_ammo_max) {
      this.entityReload(entity);
      // TODO: 실내 데모
      // reroute = true;
    }

    if (!entity.waypoint || reroute) {
      /*
      if (this.entityUpdateAimtarget(entity)) {
        // reset route. allow_cover_edge용
        entity.lastRouteTick = new TickTimer(tick, 0);
      }
      */
      this.entityNextWaypoint(entity);
    }

    let navigate = true;

    this.entityUpdateAimvar(entity);

    // TODO: rule === 'fire'에서 재장전이 필요한 경우 대기합니다.
    if (entity.ammo === 0 && entity.waypoint_rule.ty === 'fire') {
      navigate = false;
    }

    // 기본적으로 진행 방향을 봅니다.
    if (!entity.aimtarget) {
      let dir = entity.dir;
      if (entity.use_riskdir) {
        let res = null;

        if (entity.movespeed > 0) {
          res = this.riskdir(entity);
        } else if (entity.waypoint_rule.alert ?? false) {
          // 프롬프트 데모: 총성이 들리면 잠재적 위협 방향을 바라봅니다.

          if (!entity._alert_res || entity.idleAlertTick.expired(tick)) {
            entity._alert_res = this.riskdirDry(entity);
            entity.idleAlertTick = new TickTimer(tick, this.ticksFromSec(...opts.IDLE_ALERT_INTERVAL_RANGE));
          }
          res = entity._alert_res;
        }

        let selected_val = res?.selected_val ?? 0;

        if (selected_val > opts.RISKDIR_THRES) {
          dir = res.selected_dir;
          entity.lastRiskTick = new TickTimer(tick, this.ticksFromSec(opts.RISK_DIR_PERSISTENT_DURATION));
        } else {
          // 자유 공간을 탐험중

          // 실내 데모용: 문 진입시 동작
          if (entity.waypoint?.path) {
            for (const p of entity.waypoint.path) {
              if (p.idx === -1) {
                continue;
              }
              const node = routes.nodes[p.idx];
              if (node.obstacle?.ty === 'door' && this.doorShouldStop(entity, p.idx, false)) {
                // 경로에 열어야 하는 문이 있는 경우
                let d = node.pos.sub(entity.pos);
                if (d.len() < 30) {
                  dir = node.obstacle.pos.sub(entity.pos).dir();
                }
                break;
              }
            }
          }
        }
      }
      this.entityUpdateAim(entity, dir);
    }

    // aim
    // 시체에 대고도 가끔 쏩니다.
    if (entity.aimtarget) {
      const { aimvalid } = this.entityHandleFire(entity);
      // TODO: rule === 'fire'에서 상대를 사격할 수 있는 상태인 경우 정지합니다.
      if (entity.waypoint_rule.ty === 'fire' && aimvalid) {
        navigate = false;
      }
    } else {
      entity.shootPatternIdx = 0;
    }

    if (navigate) {
      this.entityNavigate(entity);
    }
  }

  onTickAreas() {
    const { entities } = this;
    let areas = [];
    for (let i = 0; i < entities.length; i++) {
      const { team, spawnarea } = entities[i];
      if (team !== 0 || spawnarea < 0) {
        continue;
      }
      if (!areas.includes(spawnarea)) {
        areas.push(spawnarea);
      }
    }

    for (const area of areas) {
      this.onTickArea(area);
    }
  }

  onTickArea(area) {
    const { tick } = this;

    const entities = this.entities.filter((e) => e.state !== 'dead' && e.spawnarea === area);
    const controls = this.controls(area);

    const last_state = controls.state;
    controls.state = this.areaState(area);

    let engage_ended = false;
    if (last_state === 'engage' && controls.state === 'explore') {
      engage_ended = true;
    }

    if (engage_ended) {
      const engaged = this.entities.filter((e) => e.team !== 0 && e.state === 'dead' && !e._engaged);
      for (const entity of engaged) {
        entity._engaged = true;
      }

      if (opts.PS_ENGAGE_PAUSE) {
        this.pending_prompts.push({
          area,
          expire_at: tick,
          queue_at: tick,
          pause: true,
          prompt_options: [
            {
              title: `약탈`, actions: [
                { action: 'loot', area, engaged },
              ],
            },
            {
              title: `응급처치`, actions: [
                { action: 'firstaid', area },
              ],
            },
            {
              title: `임무진행`, default: true, actions: [
                { action: 'reorg', area },
              ],
            },
            {
              title: `임무포기`, actions: [
                { action: 'withdraw', area },
              ],
            },

          ],
        });
      }
    }


    let tick_mult = opts.PS_UNCOVER_DURATION_IDLE_MULT;
    if (controls.state === 'engage') {
      let player_count = entities.length;
      let enemy_count = this.entities.filter((e) => e.team !== 0).length;
      tick_mult = opts.PS_UNCOVER_DURATION_ENGAGE_MULT * player_count / enemy_count;
    } else if (entities.find((e) => e.waypoint_rule.ty.startsWith('interact'))) {
      tick_mult = opts.PS_UNCOVER_DURATION_SEARCH_MULT;
    }

    let { playerstats } = this;
    playerstats.uncover = Math.min(playerstats.uncover + (1 / 30 * tick_mult), 100);

    if (controls.state === 'reorg' && controls.reorgTimer?.expired(tick)) {
      controls.reorg = false;
    }

    if (opts.AREA_GOVERNER_COVER_REORG) {
      // 교전 후 reorg
      if (engage_ended) {
        controls.reorg = true;
      }

      if (last_state !== 'reorg' && controls.state === 'reorg') {
        controls.reorgTimer = new TickTimer(tick, this.ticksFromSec(opts.AREA_GOVERNER_COVER_REORG_DURATION));
      }
    }

    if (controls.state.includes('reorg')) {
      controls.ticks_reorg += 1;
    }

    // TODO:
    for (const stalker of this.stalkers.filter((s) => s.tick === controls.ticks_reorg)) {
      const entity = this.spawnEntity(stalker.entity);
      entity.spawnarea = -1;
      entity.push_rule(tick, { ty: 'explore' });
    }

    // reorg 관련 처리
    if (controls.reorg) {
      for (const entity of entities) {
        if (!entity.has_rule('reorg')) {
          entity.push_rule(tick, { ty: 'reorg' });
          this.entityNextWaypoint0(entity, false);
        }
      }

      // reorg 중 교전이 발생한 경우
      const initiator = this.entities.find((e) => e.state !== 'dead' && entities.includes(e.aimtarget));
      if (initiator) {
        for (const entity of entities) {
          if (entity.waypoint_rule.ty === 'reorg') {
            const rule_next = this.entityCoverPoilcy(entity);
            entity.push_rule(tick, { ty: rule_next, initiator, transient: true });
          }
        }
      }
    } else {
      for (const entity of entities) {
        entity.pop_rule_chain((r) => r.ty === 'reorg');
      }
    }
  }

  areaState(spawnarea) {
    const entities = this.entities.filter((e) => e.spawnarea === spawnarea);

    let engaged = this.entities.find((e) => e.state !== 'dead' && entities.includes(e.aimtarget)) !== undefined;
    if (!engaged && entities.find((e) => e.aimtargetshoots > 0)) {
      engaged = true;
    }
    let covering = this.controls(spawnarea).reorg;
    let covered = this.controlReorgWait(spawnarea);

    let state = 'explore';
    if (engaged) {
      state = 'engage';
    } else if (covering) {
      state = 'reorg0';
      if (covered) {
        state = 'reorg';
      }
    }

    return state;
  }

  entityUpdateIcon(entity) {
    const { tick, trails } = this;

    const icons = [];

    if (entity.objects.length > 0) {
      icons.push('briefcase');
    }

    const dead = entity.state === 'dead';

    if (dead) {
      const trail = trails.find((trail) => trail.target === entity && trail.kill);
      if (trail && trail.crit) {
        icons.push('skull')
      }
    }

    if (!dead) {
      const rule_ty = entity.waypoint_rule.ty;

      if (entity.heals.length > 0) {
        icons.push('heal');
      }
      if (entity.throwables.length > 0) {
        icons.push('granade');
      }
      if (entity.aimtarget?.perk_targetpref_high) {
        icons.push('aim');
      }

      if (entity.movestate === 'low') {
        icons.push('alert');
      }

      if (rule_ty === 'explore') {
        icons.push('explore');
      } else if (rule_ty === 'idle') {
        icons.push('idle');
      } else if (rule_ty === 'reorg') {
        icons.push('gather');
      } else if (entity.moving) {
        if (entity.state === 'stand') {
          icons.push('walk');
        } else if (entity.state === 'dash') {
          icons.push('run');
        }
      }

      if (entity.state === 'covered') {
        icons.push('obstruct');
      }
      if (entity.state === 'crawl') {
        icons.push('crawl');
      }

      if (!entity.reloadTick.expired(tick)) {
        icons.push('reload');
      }

      if (this.entityEffect(entity, 'limvis')) {
        icons.push('blind');
      }
    }
    entity.icons = icons;
  }

  triggerAction(action) {
    const { rng, tick } = this;
    switch (action.action) {
      case 'push_rule':
        const { actiontarget, actionrules } = action;
        const entities = this.entities.filter((e) => {
          if (e.state === 'dead') {
            return false;
          }
          if (actiontarget.team !== undefined && actiontarget.team === e.team) {
            return true;
          }
          if (actiontarget.group !== undefined && actiontarget.group === e.group) {
            return true;
          }
          return false;
        });

        const rules = actionrules.map((r) => {
          r = { ...r };
          if (!isNaN(r.area)) {
            r.area = this.spawnareas[r.area];
          }
          return r;
        });

        for (const entity of entities) {
          for (const r of rules) {
            entity.push_rule(tick, { ...r });
          }

          this.entityNextWaypoint(entity);
        }
        break;

      case 'spawn_entity':
        for (const config of action.actionentities) {
          this.spawnEntity(config);
        }
        break;

      case 'spawn_object': {
        // const spec = { spawnarea: action.actionarea, size: 10, name: 'hidden object' };
        // const pos = this.spawnPos(spec);
        // const seq = this.objects.length;
        // const object = { ...spec, pos, owned: false, seq };
        // this.objects.push(object);

        // // TODO
        // const area = this.entities.find((e) => e.team === 0 && e.state !== 'dead').spawnarea;
        // const search = this.controls(area).search;

        // const prompt_options = [
        //   { title: 'skip', default: !search, actions: [] },
        //   {
        //     title: 'investigate',
        //     default: search,
        //     actions: [
        //       {
        //         action: 'push_rule',
        //         actiontarget: { ty: 'entity', team: 0 },
        //         actionrules: [{ ty: 'interact', object: seq }],
        //       }
        //     ],
        //   },
        // ];

        // this.pending_prompts.push({
        //   area: area,
        //   expire_at: this.ticksFromSec(5) + this.tick,
        //   queue_at: this.tick,
        //   prompt_options,
        // });
      }
        break;


      case 'push_prompt':
        const prompts = action.actionprompts;
        const { prompt_duration, prompt_options } = prompts;

        this.pending_prompts.push({
          // TODO
          area: 0,
          expire_at: this.ticksFromSec(prompt_duration) + this.tick,
          queue_at: this.tick,
          prompt_options,
        });
        break;

      case 'open_door':
        const { actiondoorpolicy } = action;
        this.door_policy.push(actiondoorpolicy);
        break;

      case 'rescue': {
        const { rescue_target } = action;
        // 아무나 찍어서
        const entity = this.entities.find((e) => e.team === 0 && e.state !== 'dead');

        // 이미 다른 entity가 구출중임
        if (entity) {
          entity.push_rule(tick, { ty: 'rescue', rescue_target });
        }
        break;
      }

      // TODO: ingameaction: 일시정지 후 선택 데모
      case 'loot': {
        const { playerstats } = this;
        const { loot } = playerstats;

        const { engaged } = action;
        for (const entity of engaged) {
          if (loot.inventory.length === 0 && !isNaN(entity.firearm_rate)) {
            if (rng.range(0, 1) < opts.PS_LOOT_FIREARM_PROB) {
              loot.inventory.push({ ty: 'firearm', firearm: entity });
              continue;
            }
          }

          loot.resource += rng.integer(...opts.PS_LOOT_RESOURCE_AMOUNT_RANGE);
          playerstats.uncover = Math.min(100, playerstats.uncover + opts.PS_LOOT_UNCOCOVER_INCR);
        }
        break;
      }

      case 'firstaid': {
        const { area } = action;
        const entities = this.entities.filter((e) => e.spawnarea === area && e.state !== 'dead');
        let uncover_incr = 0;
        for (const entity of entities) {
          entity.life = Math.min(entity.life + opts.PS_FIRSTAID_HEAL, entity.life_max);
          uncover_incr += opts.PS_FIRSTAID_UNCOVER_INCR;
        }
        const { playerstats } = this;
        playerstats.uncover = Math.min(playerstats.uncover + uncover_incr, 100);
        break;
      }

      case 'reorg': {
        const controls = this.controls(action.area);
        controls.reorg = true;
        controls.reorgTimer = new TickTimer(tick, this.ticksFromSec(opts.AREA_GOVERNER_COVER_REORG_DURATION));
        break;
      }

      case 'withdraw': {
        this.withdraw = true;
        break;
      }

      case 'withdraw_rule':
        this.popGroupRule(action.entity);
        break;

      default:
        throw new Error(`unknown action: ${action.action}`);
    }
  }

  get prompts() {
    return this.pending_prompts;
  }

  get actions() {
    return this.pending_actions;
  }

  controlSelectPrompt(prompts, i) {
    this.pending_prompts = this.pending_prompts.filter((p) => p !== prompts);

    const selected = prompts.prompt_options[i];
    for (const action of selected.actions) {
      this.triggerAction(action);
    }
  }

  controlRule(spawnarea, ty) {
    for (const entity of this.entities) {
      if (entity.spawnarea !== spawnarea) {
        continue;
      }
      entity.push_rule({ ty, transient: true });
    }
  }

  controls(spawnarea) {
    const { tick } = this;
    if (this.controls_list[spawnarea] === undefined) {
      this.controls_list[spawnarea] = controlDefaults(tick);
    }
    return this.controls_list[spawnarea];
  }

  controlSet(spawnarea, key, value) {
    const controls = this.controls(spawnarea);
    controls[key] = value;

    /*
    if (key === 'cover') {
      if (value) {
        this.controlSetCoverPolicy(spawnarea, 'cover');
      } else {
        this.controlSetCoverPolicy(spawnarea, 'cover-fire');
      }
    }
    */
    // mobility level
    if (key === 'mobility') {
      this.controlMobilityLevel(spawnarea, 0 | value);
    }

    if (key === 'withdraw') {
      this.withdraw = 1;
    }
  }

  controlGet(spawnarea, key) {
    const controls = this.controls(spawnarea);
    return controls[key];
  }

  controlSetCoverPolicy(spawnarea, value) {
    const { tick } = this;

    const rule_prev = this.control_cover_policy
    if (rule_prev === value) {
      return;
    }

    this.control_cover_policy = value;

    for (const entity of this.entities) {
      if (entity.spawnarea !== spawnarea) {
        continue;
      }

      const rule_next = this.entityCoverPoilcy(entity);

      if (!entity.alter_rule(rule_next, rule_prev)) {
        entity.push_rule(tick, { ty: rule_next, transient: true });
        entity.lastRouteTick = new TickTimer(this.tick, 0);
        this.entityNextWaypoint(entity);
      }
    }
  }

  controlGather(spawnarea) {
    const { tick } = this;

    const entities = this.entities.filter((e) => e.spawnarea === spawnarea);
    const leader = entities[0];
    for (const entity of entities) {
      if (entity.waypoint_rule !== 'gather') {
        entity.push_rule(tick, { ty: 'gather', leader, transient: true });
      }
    }
    this.controlSet(spawnarea, 'gather', true);
  }

  controlClear(spawnarea) {
    for (const entity of this.entities) {
      if (entity.spawnarea !== spawnarea) {
        continue;
      }

      // remove all transient rules
      while (entity.waypoint_rule.transient) {
        entity.pop_rule();
      }
    }
    this.controlSet(spawnarea, 'cover', false);
  }

  controlMobilityLevel(spawnarea, level) {
    for (const entity of this.entities) {
      if (entity.spawnarea === spawnarea) {
        this.entitySetMobilityLevel(entity, level);
      }
    }
  }

  controlReorgWait(spawnarea) {
    const controls = this.controls(spawnarea);
    if (!controls.reorg) {
      return false;
    }
    return _.every(this.entities.filter((e) => e.state !== 'dead' && e.spawnarea === spawnarea), (e) => e.movespeed === 0);
  }

  onTick() {
    const idx = this.tick % opts.PERF_BUF_SIZE;
    const start = Date.now();
    const res = this.onTick0();
    const dt = Date.now() - start;

    this.perfbuf[idx] = { start, dt };
    return res;
  }

  simover() {
    const { world, goals, entities, withdraw } = this;

    if (withdraw) {
      return 1;
    }

    // 패배 조건: 사기, 발각
    if (this.playerstats.morale <= 0 || this.playerstats.uncover >= 100) {
      return 1;
    }

    // simover check: occupy
    const occupymajor = Math.floor((goals.length + 1) / 2);
    const t0win = goals.filter((e) => e.goalstate.owner === 0).length >= occupymajor;
    const t1win = goals.filter((e) => e.goalstate.owner === 1).length >= occupymajor;

    if (goals.length > 0 && world.simover_rule === 'goal') {
      if (t0win || t1win) {
        return t0win ? 0 : 1;
      }
    }

    // simover check: eliminated
    const t0dead = entities.filter((e) => e.team === 0 && e.state !== 'dead').length === 0;
    const t1dead = entities.filter((e) => e.spawnarea >= 0 && e.team === 1 && e.state !== 'dead').length === 0;

    if (t0dead) {
      return 1;
    }

    if (world.simover_rule === 'eliminate' && t1dead) {
      return 0;
    }

    if (world.simover_rule === 'mission') {
      if (t0win && t1dead) {
        return 0;
      }
      if (t1win || t0dead) {
        return 1;
      }
    }
    return -1;
  }

  onTickUpdateVis() {
    const { tick, entities } = this;

    const interval = this.ticksFromSec(opts.VIS_DECAY_INTERVAL);
    const decay = opts.VIS_DECAY_PER_SEC * opts.VIS_DECAY_INTERVAL;

    for (const entity of entities) {
      let grid = entity.grid_vis;
      let tick_decayed = grid.tick_decayed ?? 0;

      if (tick - tick_decayed < interval) {
        continue;
      }

      // decay
      grid.tick_decayed = tick;
      for (let i = 0; i < grid.length; i++) {
        grid[i] = Math.max(0, grid[i] - decay);
      }

      /*
      onReachableGridWasmApply(world, entity.vis, (idx) => {
        grid[idx] = Math.min(grid[idx] + decay, 1.0);
        return true;
      });
      */
    }
  }

  onTickUpdateVIP() {
    const { tick, entities } = this;

    const vips = entities.filter((e) => e.ty === 'vip');
    for (const vip of vips) {
      if (vip.waypoint_rule.ty === 'idle') {
        const nearby = entities.find((e) => e.state !== 'dead'
          && e.team === 0
          && e.pos.dist(vip.pos) < opts.RESCUE_RADIUS
          && !obstructed(vip.pos, e.pos, this.obstacles));

        if (nearby) {
          vip.team = nearby.team;
          vip.push_rule(tick, { ty: 'follow', follow_target: nearby });

          this.pending_prompts = this.pending_prompts.filter((p) => p.rescue_target !== vip);
        }
      }
    }
  }

  onTickUpdatePrompts() {
    const { tick } = this;

    this.pending_prompts.sort((a, b) => a.expire_at - b.expire_at);

    if (this.pending_prompts.find((p) => p.pause)) {
      return true;
    }

    while (this.pending_prompts.length > 0 && this.pending_prompts[0].expire_at <= tick) {
      const prompts = this.pending_prompts[0];
      const { prompt_options } = prompts;

      const def = prompt_options.find((p) => p.default);
      this.controlSelectPrompt(prompts, prompt_options.indexOf(def));
    }
    return false;
  }

  onTickUpdateTriggers() {
    const { tick, entities, trails, blastareas } = this;

    const { pending_actions } = this;
    // update triggers
    for (const area of this.spawnareas) {
      area.areastate.triggers = area.areastate.triggers.filter((t) => {
        const execute_at = tick + (t.actiondelay ?? 0);
        switch (t.condition) {
          case 'enter':
            if (entities.find((e) => geomContains(e.pos, area.polygon) && e.team === t.conditiontarget.team)) {
              pending_actions.push({ queue_at: tick, execute_at, trigger: t });
              return false;
            }
            return true;

          case 'enter1': {
            // 구역에 진입했으면서, 교전 중이 아닌 경우
            const found = entities.find((e) => geomContains(e.pos, area.polygon) && e.team === t.conditiontarget.team);
            if (found && this.controls(found.spawnarea).state !== 'engage') {
              pending_actions.push({ queue_at: tick, execute_at, trigger: t });
              return false;
            }
            return true;
          }

          case 'leave':
            const found = entities.find((e) => geomContains(e.pos, area.polygon) && e.team === t.conditiontarget.team);
            // TODO: 멀쩡하게 만들기
            if (!t._entered && found) {
              t._entered = true;
            }
            if (t._entered && !found) {
              pending_actions.push({ queue_at: tick, execute_at, trigger: t });
              return false;
            }

            return true;

          case 'fire':
            // TODO: make it efficient
            if (trails.find((t) => geomContains(t.pos, area.polygon))) {
              pending_actions.push({ queue_at: tick, execute_at, trigger: t });
              return false;
            }
            return true;

          case 'blast':
            if (blastareas.find((e) => geomContains(e.pos, area.polygon))) {
              pending_actions.push({ queue_at: tick, execute_at, trigger: t });
              return false;
            }
            return true;

          default:
            throw new Error("unknown trigger condition: " + t.condition);
        }
      });
    }

    pending_actions.sort((a, b) => a.execute_at - b.execute_at);

    while (pending_actions.length > 0 && pending_actions[0].execute_at <= tick) {
      const { trigger } = pending_actions.shift();
      this.triggerAction(trigger);
    }
  }

  onTickUpdateGoals() {
    const { tick, goals, entities } = this;

    for (let i = 0; i < goals.length; i++) {
      const goal = goals[i];
      if (goal.waypoint) {
        continue;
      }

      const state = goal.goalstate;
      const candidates = entities.filter((e) => e.state !== 'dead' && goal.pos.dist(e.pos) < opts.GOAL_RADIUS);
      state.count_team0 = candidates.filter((e) => e.team === 0).length;
      state.count_team1 = candidates.filter((e) => e.team === 1).length;

      if (state.owner >= 0) {
        continue;
      }

      let occupying_team = -1;
      if (state.count_team0 > state.count_team1) {
        occupying_team = 0;
      } else if (state.count_team0 < state.count_team1) {
        occupying_team = 1;
      }

      if (occupying_team !== state.occupying_team) {
        state.occupying_team = occupying_team;
        if (occupying_team >= 0) {
          state.occupy_tick = tick;
        }
      }

      if (occupying_team >= 0 && tick - state.occupy_tick > this.ticksFromSec(opts.GOAL_OCCUPY_DURATION)) {
        state.owner = state.occupying_team;
      }
    }
  }

  onTickPrompt() {
    // mission rule에 따른 prompt를 추가합니다.
    this.maybePushMissionRulePrompt();
  }

  maybePushMissionRulePrompt() {
    const { entities, tick, pending_prompts } = this;
    const entity = entities.find((e) => e.team === 0 && e.state !== 'dead');
    if (!entity) {
      return;
    }
    const { ty, mission_idx } = entity.waypoint_rule;

    if (!(mission_idx > 0)) {
      return;
    }

    if (pending_prompts.find((p) => p.mission_idx === mission_idx)) {
      return;
    }

    pending_prompts.push({
      area: entity.spawnarea,
      expire_at: this.ticksFromSec(3600) + tick,
      queue_at: tick,
      mission_idx,
      prompt_options: [
        {
          title: `withdraw ${ty}`, default: true, actions: [
            { action: 'withdraw_rule', mission_idx, entity }
          ]
        }
      ],
    });
  }

  convertEntity(entity, team) {
    const { entities } = this;
    const tmpl = entities.find((e) => e.team === team && e.state !== 'dead');

    entity.team = team;
    entity.rules = tmpl.rules.map((r) => ({ ...r }));
  }

  bubblePush(entity, msg) {
    const { tick, bubbles } = this;
    bubbles.push({
      msg,
      entity,
      timer: new TickTimer(tick, this.ticksFromSec(2.0)),
    });
  }

  bubbleUpdate() {
    const { rng, tick, bubbles, entities } = this;
    if (bubbles.length > 0 && !bubbles[bubbles.length - 1].timer.expired(tick)) {
      return;
    }

    if (rng.next(0, 1) > 0.01) {
      return;
    }

    const msg = rng.choice(MESSAGES);
    const candidates = entities.filter((a) => {
      return a.team === 0 && a.state !== 'dead' && a.waypoint_rule.ty === 'explore';
    })
    if (candidates.length === 0) {
      return;
    }
    const entity = rng.choice(candidates);
    this.bubblePush(entity, msg);
  }

  paused() {
    if (this.onTickUpdatePrompts()) {
      return true;
    }
    return false;
  }

  onTick0() {
    const { entities, throwables } = this;

    // simover check
    let res = this.simover();
    if (res >= 0) {
      return res;
    }

    if (this.paused()) {
      return -1;
    }

    // update entities
    for (const entity of entities) {
      this.entityUpdate(entity);
    }
    // update teams
    this.onTickAreas();

    for (const entity of entities) {
      this.entityUpdateIcon(entity);
    }
    this.bubbleUpdate();

    // update objects
    for (const t of throwables) {
      this.throwableUpdate(t);
    }

    // handle mission rules
    this.onTickPrompt();

    // decay grid_vis
    this.onTickUpdateVis();

    // update vips
    this.onTickUpdateVIP();

    // update triggers
    this.onTickUpdateTriggers();

    // update goals
    this.onTickUpdateGoals();

    this.tick += 1;

    return -1;
  }
}
