import '../style.css';

function evh(props) {
  return {
    onMouseDown: (e) => {
      if (props.onClick) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    onClick: (e) => {
      if (props.onClick) {
        e.preventDefault();
        e.stopPropagation();
        props.onClick(e);
      }
    },
  };
}

export function FH1ButtonTiny(props) {
  return <button
    {...props}
    {...evh(props)}
    className={`fh1-button fh1-button-tiny ${props.className ?? ''}`}
  >
    {props.children ?? props.label}
  </button>;
}

export function FH1ButtonInline(props) {
  return <button
    {...props}
    {...evh(props)}
    className={`fh1-button fh1-button-inline ${props.className ?? ''}`}
  >
    {props.children ?? props.label}
  </button>;
}

export function FH1Button(props) {
  return <button
    {...props}
    {...evh(props)}
    className={`fh1-button ${props.className ?? ''}`}
  >
    {props.children ?? props.label}
  </button>;
}

export function FH1ButtonFoldable(props) {
  const props0 = { ...props };
  delete props0.folded;
  return <button
    {...props0}
    {...evh(props)}
    className={`fh1-button fh1-button-inline fh1-button-foldable ${props.folded ? 'fh1-folded' : 'fh1-unfolded'} ${props.className ?? ''}`}
  >
    <div className='fh1-label'>
      <svg preserveAspectRatio='none' viewBox='0 0 21 16' xmlns='http://www.w3.org/2000/svg'>
        <path d='M20 0L10 10L0 0L0 6L10 16L20 6L20 0Z' fill='white' />
      </svg>
      {props.children ?? props.label}
    </div>
  </button>;
}
