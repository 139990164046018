import { STAT_DESCR, FIREARM_CATS } from '../stat';

export function StatList(props) {
  const { stats, style } = props;

  const numToColor = (x) => {
    if (x <= 5) {
      return '#81c784';
    } else if (x <= 10) {
      return '#4caf50';
    } else if (x <= 15) {
      return '#388e3c';
    } else {
      return '#1b5e20';
    }
  }

  function wrapValue(name, value) {
    let strValue;
    switch (name) {
      case '재배치 성향':
        if (value <= 90) {
          strValue = '신중';
        } else if (value <= 110) {
          strValue = '균형';
        } else {
          strValue = '과감';
        }
        return (
          <div style={{
            gridRow: 1,
            gridColumn: 2,
          }}>
            {strValue}
          </div>
        )
      case '방아쇠 성향':
      case '재장전 성향':
        if (value === 0) {
          strValue = '일찍';
        } else if (value === 1) {
          strValue = '균형';
        } else {
          strValue = '늦게';
        }
        return (
          <div style={{
            gridRow: 1,
            gridColumn: 2,
          }}>
            {strValue}
          </div>
        )
      case '이동속도':
        return (
          <div style={{
            gridRow: 1,
            gridColumn: 2,
          }}>
            {value}
          </div>
        );
      default:

    }
    return (
      <div style={{
        gridRow: 1,
        gridColumn: 2,
        color: numToColor(value),
      }}>
        {value}
      </div>
    );
  }

  function renderFirearm() {
    return (
      <div style={{ width: '100%', overflow: 'hidden' }}>
        <table>
          <tbody>
            {FIREARM_CATS.map((cat, i) => {
              return (
                <tr key={i}>
                  <th>
                    {cat}
                  </th>
                  <td align="left">{wrapValue(cat, stats.stat_firearm_level[i])}</td>
                </tr>);
            })}
          </tbody>
        </table>
      </div >
    );
  }

  function renderGeneral() {
    return (
      <div style={{ width: '100%', overflow: 'hidden' }}>
        <table>
          <tbody>
            {Object.keys(STAT_DESCR).map((key, i) => {
              const name = STAT_DESCR[key];
              return (
                <tr key={i}>
                  <th>
                    {name}
                  </th>
                  <td align="left">{wrapValue(name, stats[key])}</td>
                </tr>);
            })}
          </tbody>
        </table>
      </div >
    );
  }

  return (
    <>
      <div style={style}>
        <div align='center'>화기 숙련도</div>
        {renderFirearm()}
      </div>
      <div style={style}>
        <div align='center'>일반</div>
        {renderGeneral()}
      </div>
    </>
  )
}

