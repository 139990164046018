// downloaded data!
export const data = [
  {
    "label": "E",
    "physical_cap": "8"
  },
  {
    "label": "D",
    "physical_cap": "10"
  },
  {
    "label": "C",
    "physical_cap": "12"
  },
  {
    "label": "B",
    "physical_cap": "14"
  },
  {
    "label": "A",
    "physical_cap": "16"
  },
  {
    "label": "S",
    "physical_cap": "18"
  }
];