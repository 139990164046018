/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const ContractOption = ({
  ptDesc = "OPTION DESCRIPTION BLAH BLAH FOO BAR OPTION DESCRIPTION BLAH BLAH FOO BAR OPTION DESCRIPTION BLAH BLAH FOO BAR 123",
  ptName = "OPTION_NAME",
}) => {
  return (
    <div className="contract-option">
      <div className="frame-33">
        <div className="frame-34">
          <div className="title-19">{ptName}</div>
        </div>
        <div className="frame-35">
          <p className="p">{ptDesc}</p>
        </div>
      </div>
      <img className="vector-5" alt="Vector" src="/anima/img/vector-200-1.svg" />
    </div>
  );
};

ContractOption.propTypes = {
  ptDesc: PropTypes.string,
  ptName: PropTypes.string,
};
