import _ from 'lodash';

import { firearms_tier1 } from './presets_firearm.mjs';
import { gears_vest_bulletproof } from './presets_gear.mjs';
import { throwables } from './presets_throwables.mjs';
import { stats_const } from './stat.mjs';
import { nationalities } from './data/google/processor/data_nationalities.mjs';
import { backgrounds } from './data/google/processor/data_backgrounds.mjs';
import * as data_traits from './data/google/processor/data_traits.mjs';
import { ambitions } from './data/google/processor/data_ambitions.mjs';
import { modifiers, agendakeys } from './data/google/processor/data_modifiers.mjs';
import * as pot from './data/google/processor/data_potential.mjs';
import * as gcap from './data/google/processor/data_growthcap.mjs';
import * as pscap from './data/google/processor/data_physicalcap.mjs';
import * as agent_physical from './data/google/processor/data_physical.mjs';
import * as data_stats from './data/google/processor/data_stats.mjs';
import * as exps from './data/google/processor/data_exp.mjs';
import { perks2 } from './data/google/processor/data_perks2.mjs';
import { data as perktree } from './data/google/downloaded/perktree.mjs';

import { AGENT_CONTRACT_TICKS, contractOverallMult } from './contract.mjs';
import { contractNew, contractCalculateCost } from './contract.mjs';
import { STATS2_DESCR } from './stats2.mjs';
import { TrainingSessionsTemplate } from './training.mjs';

import { TICK_PER_MONTH, TICK_PER_WEEK } from './tick.mjs';
import { getGrowthRate } from './data/google/processor/data_growthrate.mjs';
import { L } from './localization.mjs';

import { training2Bykey } from './data/google/processor/data_training2.mjs';

const TRAITS_MAX = 3;
const AGENT_INITIAL_VOCATIONS = ['ar', 'smg', 'sg', 'dmr'];
export const AGENT_TRAINING_CATS = [
  'overall', 'physical', // 'mission',
  ...data_stats.stats_descr.map(({ key }) => key)
];

export const DEFAULT_FIREARM = firearms_tier1.find((f) => f.firearm_ty === 'hg');
export const DEFAULT_EQUIPMENT = gears_vest_bulletproof[0];
export const DEFAULT_THROWABLE = throwables[0];

const PERK_GROUPS_TRAIT = _.uniq(perktree
  .filter((d) => d.source === 'trait')
  .map((d) => d.perk_group));

export function tickToAge(turn, born_at = 0) {
  const age = Math.floor((turn - born_at) / (365 * 24));
  return age;
}

export function sampleAgentStats(rng, mean, count, delta, max) {
  const stats = new Array(count).fill(mean);
  const stepamount = 0.1;

  for (let i = 0; i < 100; i++) {
    let i0 = rng.integer(0, count - 1);
    let i1 = (i0 + rng.integer(0, count - 2)) % count;

    if (Math.abs(stats[i0] - mean) >= delta || Math.abs(stats[i1] - mean) >= delta) {
      continue;
    }

    if (stats[i0] > stats[i1]) {
      [i0, i1] = [i1, i0];
    }

    // move stepamount from i0 to i1
    if (stats[i0] < stepamount || stats[i1] + stepamount > max) {
      break;
    }

    stats[i0] -= stepamount;
    stats[i1] += stepamount;
  }
  return stats;
}

export function agentBackgroundTitle(background) {
  const { ambition_traits_gain, ambition_traits_lose, ambition_modifiers } = background;

  let ambition_key = background.ambition;
  if (ambition_key.startsWith("ambition_1_") || ambition_key.startsWith("ambition_2_")) {
    ambition_key += "_1";
  }

  const ambition = ambitions.find((a) => a.key === ambition_key);

  return `달성 조건: ${ambition.condition}
 - trait 얻음: ${ambition_traits_gain.map(data_traits.find).map((t) => t.name).join(', ')}
 - trait 잃음: ${ambition_traits_lose.map(data_traits.find).map((t) => t.name).join(', ')}
 - modifier 얻음: ${ambition_modifiers.map((key) => modifiers[key].name).join(', ')}
  `;
}

function avg(l) {
  return l.reduce((a, b) => a + b, 0) / l.length;
}

function dist_points(rng, l, n) {
  let a = new Array(l).fill(0);
  for (let i = 0; i < n; i++) {
    a[rng.integer(0, l - 1)] += 1;
  }
  return a;
}

function missionstats() {
  return {
    count: 0,
    wins: 0,
    loses: 0,

    kills: 0,

    // 계약 틱 수
    ticks: 0,

    // 훈련 수
    dispatches: 0,

    // 기여 수익
    contributions: 0,

    // 기여한 명예
    renowns: 0,

    // 같은 팀에서 전투불능 수
    teamdowns: 0,

    // 회복한 총 체력
    recovers: 0,
    recover_days: 0,
    recover_cost: 0,

    damage_done: [],
    damage_taken: [],
  };
}

export function createAgent(rng, name, idx, opts) {
  let { power, growthcap, physicalcap, potential, vocation, stats2, areaNum, turn, global_modifier, label_offset, label_offsets, power_offset } = opts;
  areaNum = areaNum ?? 0;
  label_offset = label_offset ?? 0;
  label_offsets = label_offsets ?? [0, 0, 0];
  power_offset = power_offset ?? 0;

  const offsets = dist_points(rng, 3, label_offset);
  for (let i = 0; i < 3; i++) {
    offsets[i] += label_offsets[i];
  }
  const [dp, dg, dps] = offsets;

  potential = potential ?? pot.sample(rng, areaNum, dp);
  const gcapData = gcap.sample(rng, areaNum, dg);
  const physicalData = pscap.sample(rng, areaNum, dps);

  const physical = rng.integer(6, physicalData.physical_cap);

  const { spawn_power_min, spawn_power_max } = gcapData;
  power = (isNaN(power) ? rng.range(spawn_power_min, spawn_power_max) : power) + power_offset;

  gcapData.power_cap = Math.max(power, gcapData.power_cap);

  const [decision, bravery, focus, reaction, toughness, precision] = sampleAgentStats(rng, power, 6, gcapData.stat_deviation, 20);
  // TODO: power와 맞아야 함
  stats2 = stats2 ?? { decision, bravery, focus, reaction, toughness, precision };

  // stats2를 기준으로 power를 다시 계산하기
  power = avg(Object.values(stats2));

  const { power_cap } = growthcap ? gcap.find(growthcap) : gcapData;
  // 스탯2는 개별 cap을 가짐
  const stats2_cap = {};
  for (const key of Object.keys(stats2)) {
    stats2_cap[key] = stats2[key] + (power_cap - power);
  }

  const stats2_gauge = {};
  for (const key of Object.keys(stats2)) {
    // TODO
    // stats2_gauge[key] = rng.choice([50, 150, 250]);
    stats2_gauge[key] = AgentInitialStats2Gauge;
  }

  const stats = stats_const(power, 10);
  stats.name = name;

  growthcap ??= gcapData.label;
  physicalcap ??= physicalData.label;

  const life_max = agent_physical.lifeMax(physical);

  const stamina_max = 4;
  const stamina_regen_per_day = 1.2 * rng.integer(3, 4) / 56;

  // nationality
  const nationality = rng.weighted_key(nationalities, 'weight');
  const language = rng.choice(nationality.languages);

  const background_candidates = backgrounds.filter((b) => {
    if (b.area > areaNum) {
      return false;
    }
    if (b.nationalities.length === 0) {
      // 제한이 없는 경우
      return true;
    }
    return b.nationalities.includes(nationality.key);
  });
  const background = rng.choice(background_candidates);

  let { trait_rule } = background;
  let traits0 = [];
  if (trait_rule === 'one') {
    traits0 = [rng.choice(background.traits)].map(data_traits.find);
  } else {
    traits0 = background.traits.map((key) => data_traits.find(key));
  }

  while (traits0.length < TRAITS_MAX - 1) {
    const trait_candidates = data_traits.traits.filter((t) => {
      if (traits0.includes(t)) {
        return false;
      }
      if (t.conflicts.find((conflict) => traits0.includes(conflict))) {
        return false;
      }
      return true;
    });

    traits0.push(rng.choice(trait_candidates));
  }

  // 개별 퍽 그룹은 3개까지 가질 수 있는데,
  // trait에 의해 3개 주어질 수 있고
  // background에 의해서도 1개 주어질 수 있어서
  // 최대 4개가 배정될 수 있는 문제를 우회
  if (traits0.length >= TRAITS_MAX) {
    background.perk_groups = background.perk_groups.map((g) => {
      const weight = PERK_GROUPS_TRAIT.includes(g.perk_group) ? 0 : g.weight;
      return { ...g, weight };
    });
  }
  const background_perk_group = rng.weighted_key(background.perk_groups, 'weight');

  let ambition_key = background.ambition;
  if (ambition_key.startsWith("ambition_1_") || ambition_key.startsWith("ambition_2_")) {
    ambition_key += "_1";
  }

  const term = AGENT_CONTRACT_TICKS;
  const contract = contractNew(rng, turn, term);

  const ambition = ambitions.find((a) => a.key === ambition_key);

  vocation = vocation ?? [rng.choice(AGENT_INITIAL_VOCATIONS)];
  const firearm = firearms_tier1.find((f) => f.firearm_ty === 'hg');

  const age = rng.range(20, 40);
  const born_at = -age * 365 * 24;

  const prevTrainingSessions = [...TrainingSessionsTemplate];

  const agent = {
    idx,
    name,
    stats,
    vocation,
    contract,

    stats2,

    // TODO: nakwon: 스텟은 초기 스텟 아래로 감소하지 않습니다.
    stats2_base: { ...stats2 },
    // TODO: stats2는 개별 cap을 가짐
    stats2_cap,
    stats2_gauge,

    life: opts.life ?? life_max,
    life_max,
    stamina: stamina_max,
    stamina_max,
    stamina_regen_per_day,
    power,
    initial_power: power, // 당장은 안쓰고 디버깅용
    physical,

    potential,
    growthcap,
    physicalcap,

    level: {
      cur: 1,
      exp: 0,
    },

    cost_mult: rng.range(0.8, 1.4),
    contract_mult_overall: contractOverallMult(power, rng),
    modifier: background.modifiers.map((key) => ({ key, start: turn, term })),
    state: null,

    firearm,
    equipment: gears_vest_bulletproof[0],
    throwables: [throwables[0], throwables[0]],
    spawnarea: 0,

    mission_stats_last: missionstats(),
    mission_stats: missionstats(),

    perks: { point: 0, point_total: 0, list: [] },

    nationality,
    language,
    background,
    background_perk_group,
    traits: traits0,
    ambition,
    ambition_completed: false,

    born_at,

    prevTrainingSessions,
  };

  contractCalculateCost(agent.contract, agent, {}, global_modifier ?? []);

  return agent;
}

function amountstr(amount) {
  if (amount > 0) {
    return `+${amount.toFixed(1)}`;
  } else {
    return `${amount.toFixed(1)}`;
  }
}

export function agentPowerModifiers(agent, turn) {
  const { power, life, life_max, modifier, contract, firearm } = agent;
  const { option } = contract;

  let bonuses = [];
  if (modifier.find((m) => m.key === 'mod_agent_5_disable_prompt')) {
    bonuses.push({ amount: 2, label: L('loc_data_string_modifiers_name_mod_agent_5_disable_prompt') });
  }
  if (modifier.find((m) => m.key === 'mod_agent_6_morale_high')) {
    bonuses.push({ amount: 2, label: L('loc_data_string_modifiers_name_mod_agent_6_morale_high') });
  }
  if (modifier.find((m) => m.key === 'mod_agent_7_morale_low')) {
    bonuses.push({ amount: -2, label: L('loc_data_string_modifiers_name_mod_agent_7_morale_low') });
  }
  if (option.danger) {
    bonuses.push({ amount: 2, label: L('loc_ui_string_agent_contract_option') + "(" + L('loc_ui_string_agent_contract_option_hazard_pay') + ")" });
  }

  if (life !== life_max) {
    const amount = -1 * (life_max - life) / life_max * power / 2;
    bonuses.push({ amount, label: L('loc_ui_string_training_cannot_select_agent_recovery') });
  }

  const firearm_perk_key = `perk_${firearm.firearm_ty}_base`;
  const found = agent.perks.list.find((p) => p === firearm_perk_key);
  if (found) {
    bonuses.push({ amount: 1, label: perks2.find(x => x.key === found).name });
  }

  const amount = _.sum((bonuses.map(({ amount }) => amount)));
  const label = bonuses.map(({ amount, label }) => {
    return `${label}: ${amountstr(amount)}`;
  }).join('\n');

  return {
    bonuses,
    amount,
    label,
  };
}

export function agentMissionExp(agent, opts) {
  const { kills, damage_done, win, mission_exp, agents_count } = opts;
  const potData = pot.findByPotential(agent.potential);

  if (win) {
    return (mission_exp / agents_count + kills * 2 + damage_done / 50) * potData.exp_mult;
  } else {
    return (kills * 2 + damage_done / 50) * 0.5 * potData.exp_mult;
  }
}

// TODO: nakwon: 훈련 종류에 따라 게이지 변화량이 바뀝니다.
export function agentGrowth(rng, agent, src, mult, cap) {
  if (isNaN(mult)) {
    mult = 1.0;
  }

  // aiden-43
  const gcapData = gcap.find(agent.growthcap);
  let power_cap = gcapData.power_cap;
  if (cap) {
    power_cap = Math.min(cap, power_cap);
  }

  const pscapData = pscap.find(agent.physicalcap);

  const potData = pot.findByPotential(agent.potential);
  const { stat_min, stat_max } = potData;

  const stats2 = Object.assign({}, agent.stats2);
  const stats2_keys = Object.keys(stats2);

  // TODO: nakwon: 훈련은 스텟을 바로 바꾸는 대신 스텟 게이지를 바꿉니다.
  const stats2_incr = {};
  for (const key of stats2_keys) {
    stats2_incr[key] = 0;
  }

  let physical = 0;

  if (agent.modifier.find((m) => m.key === 'mod_agent_8_growth_power')) {
    mult += 1;
  }

  if (src === 'overall') {
    const { overall_min, overall_max } = potData;
    let overall_incr = Math.max(0, rng.range(overall_min, overall_max) * mult);
    overall_incr = Math.min((power_cap - agent.power), overall_incr);

    // 전체 능력치를 적당히 올립니다.
    let step_size = 0.01;
    let steps = Math.floor(overall_incr * stats2_keys.length / step_size) || 1;
    for (let i = 0; i < steps; i++) {
      const stat_key = rng.choice(stats2_keys.filter((key) => stats2[key] < 20));
      stats2[stat_key] += step_size;
      stats2_incr[stat_key] += step_size;
    }
  }

  if (src === 'physical') {
    const { physical_min, physical_max } = potData;
    let physical_incr = Math.max(0, Math.min(pscapData.physical_cap - physical, rng.range(physical_min, physical_max) * mult));
    physical = physical_incr;
  }

  if (stats2_keys.includes(src)) {
    const amount = Math.min(cap - stats2[src], rng.range(stat_min, stat_max) * mult);
    // 능력치 하나를 올립니다.
    stats2[src] += amount;
    stats2_incr[src] += amount;
  }

  let stats_values = Object.values(stats2);
  const power = _.sum(stats_values) / stats_values.length;

  return {
    agent,
    power: power - agent.power,
    stats2: stats2_incr,
    physical,
  };
}

export function agentChangeCummulate(a, b) {
  a ??= {};
  const excludeKeys = ['agent'];
  const ret = { agent: b['agent'] };

  for (const key of Object.keys(b)) {
    if (excludeKeys.includes(key) || b[key] === null) {
      continue;
    }

    const ty = typeof b[key];
    if (ty === 'number') {
      const val = a[key] ?? 0;
      ret[key] = val + b[key];
    } else if (ty === 'object') {
      const valA = a[key] ?? {};
      const valB = b[key];
      ret[key] = agentChangeCummulate(valA, valB);
    } else if (ty === 'string') {
      // ToDo: vocation, perk 추가(아마 배열로 추가하고 +, -로 구분할 듯)
      if (a[key] === undefined) {
        ret[key] = b[key];
      } else {
        ret[key] = a[key] + ', ' + b[key];
      }
    } else {
      console.error(`unknown agent change key type: ${key}, ${typeof key}`);
    }
  }
  return ret;
}

export function agentChangeApply(agent, args, turn) {
  const { power, stats2, point, vocation, modifier, agenda, life, exp, stamina } = args;
  const a = agent;

  if (vocation) {
    a.vocation.push(vocation);
  }
  if (modifier) {
    for (const mod of modifier) {
      a.modifier = a.modifier.filter((m) => m.key !== mod && !modifiers[mod].overwrite.includes(m.key));
      if (agendakeys.includes(mod)) {
        a.contract.agenda = mod;
      } else {
        a.modifier.push({ key: mod, start: turn, term: args.term })
      }
    }
  }

  if (stats2) {
    for (const [key, value] of Object.entries(stats2)) {
      a.stats2[key] += value;
    }
  }

  if (!isNaN(life)) {
    a.life = Math.max(1, Math.min(a.life_max, life));
  }

  if (!isNaN(stamina)) {
    a.stamina = Math.max(0, Math.min(a.stamina_max, stamina));
  }

  if (!isNaN(exp)) {
    const { level, perks } = agent;
    let exp_remain = exp;

    while (exp_remain > 0) {
      const expData = exps.exps.find((e) => e.level === level.cur);
      if (expData.exp === 0) {
        break;
      }

      const used = Math.min(exp_remain, expData.exp - level.exp);

      level.exp += used;
      exp_remain -= used;
      if (level.exp === expData.exp) {
        level.cur += 1;
        level.exp = 0;

        perks.point_total += 1;
        perks.point += 1;
      }
    }
  }

  a.power = a.power + (power ?? 0);
  a.contract = { ...a.contract, agenda: agenda ?? a.contract.agenda };

  if (stats2?.toughness) {
    const { life, life_max } = life_max_change(a, stats2.toughness);
    a.life = life;
    a.life_max = life_max;
  }

  let point_total = a.perks.point_total + (point ?? 0);
  let point_add = point_total - a.perks.point_total;

  a.perks.point_total = point_total;
  a.perks.point += point_add;
}

export function life_max_change(prev, delta) {
  const res = {
    life: prev.life,
    life_max: prev.life_max,
    stat: prev.stats2.toughness,
  };
  const life_prev = prev.life_max;
  const life_next = Math.round(res.stat * 6 + 16);

  if (res.life > 0) {
    res.life_max = life_next;
    res.life = Math.min(res.life_max, res.life + life_next - life_prev);
  }
  return res;
}

export function agentChangeDescr(args) {
  const { agent, power, stats2, physical, point, vocation, modifier, agenda, life, exp, perk } = args;

  let descr_stats2 = '';
  if (stats2) {
    for (const [key, value] of Object.entries(stats2)) {
      if (value > 0) {
        descr_stats2 += `${key}(+${value.toFixed(2)}) `;
      }
    }
  }

  const descrs = [
    `${agent.name} ${life ? `생명력: ${life.toFixed(0)}` : ''}`,
    `${power ? `전투력: +${power.toFixed(2)}(${descr_stats2})` : ''}`,
    `${physical ? `Physical(+${physical})` : ``}`,
    `${exp ? `경험치: +${exp}` : ``}`,
    `${point ? `퍽 포인트: +${point}` : ``}`,
    `${vocation ? `무기 훈련 인증: +${vocation}` : ``}`,
    `${modifier ? `상태 변화: ${modifier.map((m) => modifiers[m].name + ', ')} 획득` : ``}`,
    `${agenda ? `협상 의견: ${modifiers[agenda].name} 획득` : ``}`,
    `${perk ? `퍽: ${perks2.find((x) => x.key === perk).name} 획득` : ``}`,
  ];
  return descrs.filter((l) => l.length !== 0).join(', ');
}

function statPanelty(agent, stat_key) {
  if (stat_key === 'toughness') {
    return 0;
  }

  let stat_mult = 0.5;
  if (agent.perk_reduce_penalty) {
    stat_mult = 0.35;
  }

  stat_mult *= 1.84;

  return (agent.life_max - agent.life) / agent.life_max * (1 - agent.stats2.toughness * 0.01) * stat_mult;
}

function StatValue(agent, stat_key) {
  const base = agent.stats2[stat_key];
  const mod = _.sum(agent.traits.map((t) => t.stats2[stat_key]))

  const panelty = base * statPanelty(agent, stat_key);
  return base + mod - panelty;
}

export function agentEffectiveStat(agent) {
  const stats2 = {};
  for (const key of Object.keys(agent.stats2)) {
    stats2[key] = StatValue(agent, key);
  }
  return stats2;
}

export function agentAvail(agent, ratio) {
  const { life, life_max } = agent;
  return life >= Math.floor(life_max * ratio);
}

export function lifeRecoverMultiplier(agent, mult) {
  mult = mult ?? 1;

  let stat = StatValue(agent, 'toughness');

  let stat_gain = 0.015 * stat;

  if (stat >= 4) {
    // 강인함 4레벨 퍽
    stat_gain += 0.05;
  }
  if (stat > 12) {
    // 강인함 12레벨 퍽
    stat_gain += 0.15;
  }

  //1.1 ^ 0.2 = 1.019245
  return 1 + 0.05 * (1 + stat_gain) * mult;
}

export function agentHasThrowableSlot1(agent) {
  return !!agent.perks.list.find((p) => p === 'perk_grenadier_base');
}

export function agentHasThrowableSlot2(agent) {
  return !!agent.perks.list.find((p) => p === 'perk_grenadier_amount');
}

// ToDo: 지우기
export function agentTrainInfo(agent, instructor, ty, option, thres, life_ratio) {
  let duration = TICK_PER_WEEK * 4;
  if (['perk', 'aptitude'].includes(ty)) {
    duration = TICK_PER_WEEK * 12;
  } else if (['stat'].includes(ty) && !['overall', 'physical'].includes(option)) {
    duration = TICK_PER_WEEK;
  }
  return {
    duration,
    ...agentTrainAvail(agent, instructor, ty, option, thres, life_ratio),
  };
}

// ToDo: 지우기
function agentTrainAvail(agent, instructor, ty, option, thres, life_ratio) {
  const gcapData = gcap.find(agent.growthcap);
  const power_cap = gcapData.power_cap;

  if (agent.life / agent.life_max < life_ratio) {
    return {
      avail: false,
      reason: 'insufficient life',
    };
  }

  switch (ty) {
    case 'stat': {
      switch (option) {
        case 'overall': {
          const reason = agent.power < thres ?
            (agent.power < power_cap ? 'ok' : 'power-cap') : 'power-thres';
          return {
            cur: agent.power,
            cap: thres,
            avail: agent.power < thres && agent.power < power_cap,
            reason,
          };
        }
        case 'physical':
          return {
            cur: agent.physical,
            cap: thres,
            avail: agent.physical < thres,
            reason: 'physical-thres',
          };
        default: {
          const reason = agent.stats2[option] < thres ?
            (
              agent.power < power_cap ?
                'ok' :
                `power-cap (${agent.power.toFixed(2)} < ${power_cap})?`
            ) :
            `${option}-thres (${agent.stats2[option].toFixed(2)} < ${thres})?`;
          return {
            cur: agent.stats2[option],
            cap: thres,
            avail: agent.stats2[option] < thres && agent.power < power_cap,
            reason,
          };
        }
      }
    }
    // ToDo: 어디에서도 쓰지 않은디...
    case 'mission':
      return {
        cur: 0,
        cap: 0,
        avail: true,
        reason: 'ok',
      };
    case 'perk': {
      const reason = agent.perks.point === 0 ? "perk-point" :
        option === null ? "perk-not-assigned" :
          !canAgentAcquirePerk(agent, option) ? "agent-perk-not-avail" :
            agent.perks.list.includes(option) ? "agent-perk-already" :
              !instructor.perks.includes(option) ? "instructor-perk-not-avail" : "ok";
      return {
        cur: 0,
        cap: 0,
        avail: reason === "ok",
        reason
      };
    }
    case 'aptitude': {
      const reason = agent.perks.point === 0 ? "perk-point" :
        agent.vocation.includes(option) ? "agent-aptitude-already" :
          !instructor.aptitudes.includes(option) ? "instructor-aptitude-not-avail" : "ok";
      return {
        cur: 0,
        cap: 0,
        avail: reason === "ok",
        reason
      };
    }
    default: {
      throw new Error(`unknown train type: ${ty}`);
    }
  }
}

export function canAgentAcquirePerk(agent, perk) {
  if (agent.perks.list.includes(perk)) return false;
  return true;
}

export const Stats2GaugeAgeCapStep = 5;
export function agentStats2GaugeAgeCap(age) {
  return Stats2GaugeMax - Math.max(age - Stats2GaugeDecayAgeingAge, 0) * Stats2GaugeAgeCapStep;
}

export function applyAgentStats2GaugeDelta(agent, stats2_gauge_delta, age) {
  const new_stat2_gauge = { ...agent.stats2_gauge };
  for (const [key, value] of Object.entries(stats2_gauge_delta)) {
    new_stat2_gauge[key] += value;
    new_stat2_gauge[key] = Math.min(new_stat2_gauge[key], agentStats2GaugeAgeCap(age));
    new_stat2_gauge[key] = Math.max(new_stat2_gauge[key], Stats2GaugeMin);
  }
  agent.stats2_gauge = new_stat2_gauge;
  return new_stat2_gauge;
}

export const Stats2GaugeDecayPerGauge = -0.003;
export const Stats2GaugeDecayAgeingAge = 30;
export function agentStats2GaugeDecayInfo(agent, age) {
  const { stats2_gauge, state } = agent;
  const stats2_gauge_delta = Object.fromEntries(
    Object.entries(stats2_gauge).map(([key, value]) => [key, state === 'mission' ? 0 : Stats2GaugeDecayPerGauge * value])
  );
  const stats2_gauge_delta_ageing = Object.fromEntries(
    Object.entries(stats2_gauge).map(([key, value]) => [key, Stats2GaugeDecayPerGauge * value * Math.max(0, (age - Stats2GaugeDecayAgeingAge) / Stats2GaugeDecayAgeingAge)])
  );

  return {
    default: stats2_gauge_delta,
    ageing: stats2_gauge_delta_ageing,
  };
}

export const Stats2GaugeMin = 0;
export const Stats2GaugeDecrease = 100;
export const Stats2GaugeIncrease = 200;
export const Stats2GaugeMax = 300;
const AgentInitialStats2Gauge = Stats2GaugeIncrease;

export function mergeStats2GaugeDelta(agent, age, stats2_gauge_deltas) {
  const stats2_gauge_delta = Object.fromEntries(
    Object.entries(STATS2_DESCR).map(([key, _]) => [key, 0])
  );
  const stats2_gauge_excess = Object.fromEntries(
    Object.entries(STATS2_DESCR).map(([key, _]) => [key, 0])
  );

  for (const stats2_gauge of stats2_gauge_deltas) {
    for (const key in stats2_gauge_delta) {
      if (stats2_gauge[key]) {
        stats2_gauge_delta[key] += stats2_gauge[key];
      }
    }
  }

  if (agent) {
    for (const key in stats2_gauge_delta) {
      stats2_gauge_excess[key] += stats2_gauge_delta[key] - Math.min(agentStats2GaugeAgeCap(age) - agent.stats2_gauge[key], stats2_gauge_delta[key]);
      stats2_gauge_excess[key] += stats2_gauge_delta[key] - Math.max(Stats2GaugeMin - agent.stats2_gauge[key], stats2_gauge_delta[key]);
      stats2_gauge_delta[key] = stats2_gauge_delta[key] - stats2_gauge_excess[key];
    }
  }
  return {
    delta: stats2_gauge_delta,
    excess: stats2_gauge_excess,
  };
}

const Stats2ChangeStep = 0.0001;
export const Stats2LowCap = 0.8;
export function agentStats2ChangeInfo(agent, stats2_gauge) {
  if (!stats2_gauge) {
    stats2_gauge = agent.stats2_gauge;
  }

  const stats2 = {};
  for (const key in stats2_gauge) {
    const delta = {
      sum: 0.0,
      potential: 0.0,
      growthrate: 0.0,
      maxcap: 0.0,
      mincap: 0.0,
    };
    const mult = {
      potential: agent.potential,
      growthrate: getGrowthRate(agent.stats2[key])
    };
    const result = {
      mult,
      delta,
    };
    if (stats2_gauge[key] >= Stats2GaugeIncrease) {
      delta.potential = (stats2_gauge[key] - Stats2GaugeIncrease) * Stats2ChangeStep * mult.potential;
      mult.growthrate = mult.growthrate.growth_bonus_rate;
      delta.growthrate = (stats2_gauge[key] - Stats2GaugeIncrease) * Stats2ChangeStep * mult.growthrate;
    }
    else if (stats2_gauge[key] < Stats2GaugeDecrease) {
      delta.potential = -1 * (Stats2GaugeDecrease - stats2_gauge[key]) * Stats2ChangeStep * (1 - mult.potential);
      mult.growthrate = mult.growthrate.growth_reduction_rate;
      delta.growthrate = -1 * (Stats2GaugeDecrease - stats2_gauge[key]) * Stats2ChangeStep * (1 - mult.growthrate);
    }
    delta.sum = delta.potential + delta.growthrate;


    // 스텟은 stats2 개별 cap보다 더 높게 증가하지 않습니다.
    delta.maxcap = Math.min(agent.stats2_cap[key] - agent.stats2[key], delta.sum) - delta.sum;
    // 스텟은 초기 스텟의 80% 아래로 감소하지 않습니다.
    delta.mincap = Math.max(agent.stats2_base[key] * Stats2LowCap - agent.stats2[key], delta.sum) - delta.sum;

    delta.sum += delta.maxcap + delta.mincap;

    stats2[key] = result;
  }

  const results = {
    overall: {
      delta: {
        sum: _.sum(Object.values(stats2).map((r) => r.delta.sum)) / Object.values(STATS2_DESCR).length,
        potential: 0,
        growthrate: 0,
        maxcap: 0,
        mincap: 0,
      },
      mult: {
        potential: 0,
        growthrate: 0,
      },
    },
    stats2,
  }

  return results;
}

export function agentStats2TrainingInfo(agent, training) {

  const sessions = training.option.map((key) => {
    const training2 = training2Bykey(key);
    if (!training2) {
      return null;
    }
    return training2;
  });

  const stats2 = {};
  for (const key of Object.keys(STATS2_DESCR)) {
    const delta = {
      sum: 0.0,
      potential: 0.0,
      growthrate: 0.0,
      maxcap: 0.0,
      mincap: 0.0,
    };
    const mult = {
      potential: agent.potential,
      growthrate: getGrowthRate(agent.stats2[key])
    };
    const result = {
      mult,
      delta,
    };

    const sessions0 = sessions.filter((s) => s !== null).map((s) => s[key]);
    delta.sum = _.sum(sessions0) ?? 0;

    stats2[key] = result;
  }

  const results = {
    overall: {
      delta: {
        sum: _.sum(Object.values(stats2).map((r) => r.delta.sum)) / Object.values(STATS2_DESCR).length,
        potential: 0,
        growthrate: 0,
        maxcap: 0,
        mincap: 0,
      },
      mult: {
        potential: 0,
        growthrate: 0,
      },
    },
    stats2,
  }

  return results;
}
