// downloaded data!
export const data = [
  {
    "name": "마약산업",
    "ty": "drug",
    "effect": "생성되는 임무의 보상 +20%"
  },
  {
    "name": "광산",
    "ty": "mine",
    "effect": "생성되는 용병의 무기 적성이 SG, SMG 중 하나"
  },
  {
    "name": "슬럼",
    "ty": "slum",
    "effect": "생성되는 용병이 30% 확률로 growth 등급 +1, 생성되는 화기의 종류가 SG, SMG 중 하나"
  },
  {
    "name": "대도시",
    "ty": "metro",
    "effect": "생성되는 임무의 맵이 50% 확률로 실내 맵(indoor, indoor2, alley1_short), 훈련 효율 +20%"
  },
  {
    "name": "국립공원",
    "ty": "park",
    "effect": "생성되는 용병의 무기 적성이 AR, DMR, SR 중 하나, 훈련 효율 +20%"
  },
  {
    "name": "군사기지",
    "ty": "military",
    "effect": "생성되는 용병이 30% 확률로 growth 등급 +1, 생성되는 화기의 종류가 AR, DMR, SR 중 하나"
  },
  {
    "name": "농장",
    "ty": "farm",
    "effect": "생성되는 임무의 맵이 50% 확률로 야전 맵(outdoor, border_checkpoint)"
  }
];