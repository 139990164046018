/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { PerkSlot } from "../PerkSlot";
import { PerkTreeColumn } from "../PerkTreeColumn";
import "./style.css";

export const PerkTreesContainer = ({
  className,
  override = (
    <PerkTreeColumn
      override={
        <PerkSlot
          className="perk-slot-2"
          frameEc="/anima/img/frame8ec93630-2.svg"
          img="/anima/img/perkimg-sample-2.png"
          label="PERK"
          onClick="D"
          onMouseMove="D"
          onMouseOut="D"
          onMouseOver="D"
          state="completed"
          v="/anima/img/v1fb2a71d-1.svg"
          v1="/anima/img/v90633387-2.svg"
          vE="/anima/img/v48146e38-2.svg"
          vFceEc="/anima/img/v5fce79ec-2.svg"
          vf="/anima/img/vf6ee8613-2.svg"
        />
      }
    />
  ),
}) => {
  return <div className={`perk-trees-container ${className}`}>{override}</div>;
};
