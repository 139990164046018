// downloaded data!
export const data = [
  {
    "stat_min": "0",
    "stat_max": "2",
    "growth_bonus_rate": "1",
    "growth_reduction_rate": "0.1"
  },
  {
    "stat_min": "2",
    "stat_max": "4",
    "growth_bonus_rate": "0.9",
    "growth_reduction_rate": "0.2"
  },
  {
    "stat_min": "4",
    "stat_max": "6",
    "growth_bonus_rate": "0.8",
    "growth_reduction_rate": "0.3"
  },
  {
    "stat_min": "6",
    "stat_max": "8",
    "growth_bonus_rate": "0.7",
    "growth_reduction_rate": "0.4"
  },
  {
    "stat_min": "8",
    "stat_max": "10",
    "growth_bonus_rate": "0.6",
    "growth_reduction_rate": "0.5"
  },
  {
    "stat_min": "10",
    "stat_max": "12",
    "growth_bonus_rate": "0.5",
    "growth_reduction_rate": "0.6"
  },
  {
    "stat_min": "12",
    "stat_max": "14",
    "growth_bonus_rate": "0.4",
    "growth_reduction_rate": "0.7"
  },
  {
    "stat_min": "14",
    "stat_max": "16",
    "growth_bonus_rate": "0.3",
    "growth_reduction_rate": "0.8"
  },
  {
    "stat_min": "16",
    "stat_max": "18",
    "growth_bonus_rate": "0.2",
    "growth_reduction_rate": "0.9"
  },
  {
    "stat_min": "18",
    "stat_max": "20",
    "growth_bonus_rate": "0.1",
    "growth_reduction_rate": "1"
  }
];