import { items } from './data/google/processor/data_items.mjs'

export const gear_vest_bulletproof_nothing = {
  key: 'vest_bulletproof_nothing',

  vest_name: 'loc_ui_string_common_protection_tier_0',
  vest_rate: 0,
  vest_armor: 0,
  vest_hit_prob: 0,
  vest_cost: 0
};

// 낮은 스펙의 적 방탄복. 33의 피해를 입히는 5.56 탄을 1회 더 버티게 해줍니다.
export const gear_vest_bulletproof_low = {
  key: 'vest_bulletproof_low',

  vest_name: 'loc_ui_string_common_protection_tier_1',
  vest_rate: 1,
  vest_armor: 33,
  vest_hit_prob: 0.8,
  vest_cost: 100
};

// 중간 스펙의 적 방탄복. 33의 피해를 입히는 5.56 탄을 1회 더 버티게 해줍니다. 버틸 확률이 조금 더 높습니다.
export const gear_vest_bulletproof_mid = {
  key: 'vest_bulletproof_mid',

  vest_name: 'loc_ui_string_common_protection_tier_2',
  vest_rate: 2,
  vest_armor: 33,
  vest_hit_prob: 0.9,
  vest_cost: 250
};

// 높은 스펙의 방탄복. 33의 피해를 입히는 5.56 탄을 2회 더 버티게 해줍니다.
export const gear_vest_bulletproof_high = {
  key: 'vest_bulletproof_high',

  vest_name: 'loc_ui_string_common_protection_tier_3',
  vest_rate: 3,
  vest_armor: 66,
  vest_hit_prob: 0.8,
  vest_cost: 1000
};

// 매우 높은 스펙의 방탄복. 33의 피해를 입히는 5.56 탄을 2회 더 버티게 해줍니다. 버틸 확률이 높습니다.
export const gear_vest_bulletproof_highend = {
  key: 'vest_bulletproof_highend',

  vest_name: 'loc_ui_string_common_protection_tier_4',
  vest_rate: 4,
  vest_armor: 66,
  vest_hit_prob: 0.9,
  vest_cost: 2000
};

export const gears_vest_bulletproof = [
  gear_vest_bulletproof_nothing,
  gear_vest_bulletproof_low,
  gear_vest_bulletproof_mid,
  gear_vest_bulletproof_high,
  gear_vest_bulletproof_highend,
]

for (const vest of gears_vest_bulletproof) {
  const item = items.find(i => i.key === vest.key);
  if (item) {
    vest.buy_cost = item.buy_cost;
    vest.sell_cost = item.sell_cost;
    vest.durability = item.durability;
  }
}

export const gear_nvd_t0 = {
  nvd_rate: 0,
  nvd_cost: 0,
  nvd_name: '없음',
}

// PVS-7C, FOV 45
export const gear_nvd_t1 = {
  limvis_params: {
    multipliers: {
      vis_range: 0.5,
      aimvar_hold_max: 1.6,
      vis_var: 0.33,
      speed: 0.6,
    },
  },
  nvd_rate: 1,
  nvd_cost: 1000,
  nvd_name: 'PVS-7C',
}

// RPNVG, FOV 70
export const gear_nvd_t2 = {
  limvis_params: {
    multipliers: {
      vis_range: 0.6,
      aimvar_hold_max: 1.5,
      vis_var: 0.58,
      speed: 0.6,
    },
  },
  nvd_rate: 2,
  nvd_cost: 2000,
  nvd_name: 'RPNVG'
}

// WFOV F-NVG, FOV 80
export const gear_nvd_t3 = {
  limvis_params: {
    multipliers: {
      vis_range: 0.7,
      aimvar_hold_max: 1.35,
      vis_var: 0.66,
      speed: 0.6,
    },
  },
  nvd_rate: 3,
  nvd_cost: 3000,
  nvd_name: 'F-NVG'
}

// GPNVG-18, FOV 96
export const gear_nvd_t4 = {
  limvis_params: {
    multipliers: {
      vis_range: 0.85,
      aimvar_hold_max: 1.1,
      vis_var: 0.8,
      speed: 0.6,
    },
  },
  nvd_rate: 4,
  nvd_cost: 4000,
  nvd_name: 'GPNVG-18'
}

export const gears_nvd = [
  gear_nvd_t0,
  gear_nvd_t1,
  gear_nvd_t2,
  gear_nvd_t3,
  gear_nvd_t4
]
