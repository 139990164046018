/// converts external obstacle format (from UE) to internal representation
import _ from 'lodash';

import { v2 } from './v2.mjs';
import { opts } from './opts.mjs';
import { Rng } from './rand.mjs';

const min = [100, 100];
const max = [-100, -100];

const scale = 0.1;

function conv_rot(r) {
  return -r * Math.PI / 180;
}

export function convert_world(extobjs, simstate) {
  const obstacle_specs = convert(extobjs);

  function roundup(x, unit) {
    return Math.ceil(x / unit) * unit;
  }
  simstate.obstacle_specs = [];

  const world = obstacle_specs.find((s) => s.tags.includes('world'));
  let offset = new v2(0, 0);
  if (world) {
    simstate.world.width = roundup(world.extent.x * 2, opts.GRID_SIZE);
    simstate.world.height = roundup(world.extent.y * 2, opts.GRID_SIZE);
    simstate.world.offset = world.pos;
    offset = world.pos;
  }


  // setup
  for (const spec of obstacle_specs) {
    let { pos, extent } = spec;
    if (spec.tags.includes('world')) {
      continue;
    }
    pos = spec.pos.sub(offset);
    spec.pos = pos;

    const spawntag = spec.tags.find((t) => t.startsWith('spawn'));
    if (spawntag) {
      const idx = parseInt(spawntag.split('spawn')[1], 10);
      if (isNaN(idx) || idx >= simstate.spawnareas.length) {
        console.log('invalid spawn tag', spawntag);
        continue;
      }

      simstate.spawnareas[idx].pos = pos;
      simstate.spawnareas[idx].extent = extent;
      continue;
    }

    let s = null;
    if (spec.tags.includes('full')) {
      s = { ...spec, ty: 'full' };
    }
    if (spec.tags.includes('half')) {
      s = { ...spec, ty: 'half' };
    }
    if (s && s.tags.includes('nocover')) {
      s.no_coverpoint = true;
    }

    if (s) {
      simstate.obstacle_specs.push(s);
    }
  }

  return simstate;
}

export function convert(objects) {
  const obstacle_specs = [];

  for (const obj of objects) {
    for (let i = 0; i < 3; i++) {
      obj.min[i] *= obj.scale[i];
      obj.max[i] *= obj.scale[i];
    }

    let [minx, miny, minz] = obj.min;
    let [maxx, maxy, maxz] = obj.max;
    let [x, y] = obj.center;

    /*
    let ignore = false;
    for (let i = 0; i < 3; i++) {
      if (obj.max[i] - obj.min[i] < size[i]) {
        ignore = true;
        break;
      }
    }
    if (ignore) {
      continue;
    }
    if (maxx - minx < 50 && maxy - miny < 50) {
      continue;
    }
    */
    if (obj.name.toLowerCase().includes('tree')) {
      continue;
    }

    let heading = conv_rot(obj.rotation[2]);

    let x0 = (minx + maxx) / 2;
    let y0 = (miny + maxy) / 2;

    let centerx = Math.cos(heading) * x0 + Math.sin(heading) * y0;
    let centery = -1 * Math.sin(heading) * x0 + Math.cos(heading) * y0;

    centerx = x + centerx;
    centery = y + centery;
    let extentx = (maxx - minx) / 2;
    let extenty = (maxy - miny) / 2;

    /*
    if (extentx > 1000 || extenty > 1000) {
      continue;
    }
    */

    let ty = 'half';
    if ((maxz - minz) > 150) {
      ty = 'full';
    }

    min[0] = Math.min(x + minx);
    min[1] = Math.min(y + miny);

    max[0] = Math.max(x + maxx);
    max[1] = Math.max(y + maxy);
    obstacle_specs.push({
      ty,
      pos: new v2(centerx, centery).mul(scale),
      extent: new v2(extentx, extenty).mul(scale),
      heading,
      name: obj.name,
      tags: obj.tags,
      imported: true,
    });
  }

  return obstacle_specs;
}

export const SAMPLE = [{ "name": "StaticMeshActor_1", "min": [-50, -50, -50], "max": [50, 50, 50], "center": [-1020, -100, 0], "scale": [3.75, 1, 2], "rotation": [-0, 0, 0], "tags": ["sim", "half"] }, { "name": "StaticMeshActor_2", "min": [-50, -50, -50], "max": [50, 50, 50], "center": [680, -110, 0], "scale": [3.75, 1, 2], "rotation": [-0, 0, 0], "tags": ["sim", "half"] }, { "name": "StaticMeshActor_3", "min": [-50, -50, -50], "max": [50, 50, 50], "center": [-170, -90, 0], "scale": [12, 0.25, 4], "rotation": [-0, 0, 0], "tags": ["sim", "full"] }, { "name": "StaticMeshActor_4", "min": [-50, -50, -50], "max": [50, 50, 50], "center": [100, 0, 0], "scale": [32.25, 51.25, 1], "rotation": [-0, 0, 0], "tags": ["sim", "world"] }, { "name": "StaticMeshActor_5", "min": [-50, -50, -50], "max": [50, 50, 50], "center": [-370, -2070, 0], "scale": [4, 4, 1], "rotation": [-0, 0, 0], "tags": ["sim", "spawn0"] }, { "name": "StaticMeshActor_6", "min": [-50, -50, -50], "max": [50, 50, 50], "center": [0, 520, 0], "scale": [4, 4, 1], "rotation": [-0, 0, 0], "tags": ["sim", "spawn1"] }, { "name": "StaticMeshActor_7", "min": [-50, -50, -50], "max": [50, 50, 50], "center": [-850, -1700, 0], "scale": [23.75, 0.25, 4], "rotation": [-0, 0, 0], "tags": ["sim", "full"] }, { "name": "StaticMeshActor_8", "min": [-50, -50, -50], "max": [50, 50, 50], "center": [910, -1280, 0], "scale": [23.75, 0.25, 4], "rotation": [-0, 0, 0], "tags": ["sim", "full"] }];


// serializes states for UE
const PARTS_ALL = [
  'body_01',
  'body_06',
  'body_07',
  'body_08',
  'body_09',
  // 'acc_gasmask_3ds',
  // 'acc_ammobelt',
  'acc_dogtag',
  // 'acc_avoncbrn',
  'acc_bowmanheadset',
  'acc_gasmask',
  'acc_goggles',
  'acc_headset',
  'acc_lashheadset',
  // 'acc_pvs7',
  // 'acc_headmount',
  'acc_sunglasses',
  'acc_kneepads',
  'acc_elbowpads',
  'armor_3marmor',
  'armor_3marmor_02',
  'armor_6b518',
  'armor_6b518_02',
  'armor_6b518_03',
  // 'armor_bodyarmormk2',
  // 'armor_bodyarmormk2_02',
  // 'armor_defender2',
  // 'armor_defender2_02',
  'armor_pasgt',
  'armor_pasgt_02',
  // 'armor_tgfaust',
  // 'armor_tgfaust_02',
  'backpack_alicebackpack', // vest
  // 'backpack_alicebackpack_02',
  // 'backpack_alicebackpack_acc',
  // 'backpack_bergen',
  // 'backpack_patrolpack',
  // 'backpack_rd54',
  'gloves_gloves_01',
  'gloves_gloves_02',
  'gloves_gloves_03',
  'headgear_altyn',
  'headgear_balaclava_01',
  'headgear_balaclava_02',
  'headgear_balaclava_03',
  'headgear_bandana_01',
  'headgear_beret',
  'headgear_booniehat_01',
  'headgear_baseballcap_01',
  'headgear_cowboyhat_01',
  'headgear_cowboyhat_02',
  'headgear_fieldcap_01',
  'headgear_ghilliebalaclava',
  'headgear_ghilliebalaclava_01',
  'headgear_headband_01',
  'headgear_keffiyeh_01',
  'headgear_m1helmet', // vest
  // 'headgear_mk6helmet_01',
  // 'headgear_mk6helmet_02',
  // 'headgear_mk6helmet_03',
  // 'headgear_pasgt',
  // 'headgear_protecthelmet',
  // 'headgear_slouchhat',
  'headgear_ssh68',
  // 'lowerbody_bdupants_01',
  // 'lowerbody_bdupants_02',
  // 'lowerbody_trousers_01',
  // 'lowerbody_florapants',
  // 'lowerbody_ghilliepants',
  // 'lowerbody_ghilliepants_01',
  // 'lowerbody_gorkapants',
  'lowerbody_male_underwear_01',
  // 'lowerbody_pants',
  // 'lowerbody_trousers',
  // 'lowerbody_wz2010',
  // 'lowerbody_wz2010_01',
  'upperbody_bdujacketfolded',
  'upperbody_bdujacketlong',
  'upperbody_bdujacket_npc',
  'upperbody_bdujacketshort',
  'upperbody_cs95_jacket',
  // 'upperbody_flora',
  // 'upperbody_ghilliejacket',
  // 'upperbody_ghilliejacket_01',
  // 'upperbody_gorka',
  'upperbody_jacket',
  'upperbody_male_sleeves',
  'upperbody_tshirt',
  'upperbody_tshirt_npc',
  'vests_6sh92', // vest
  'vests_alicevest', // vest
  // 'vests_assaultvest',
  // 'vests_bhi',
  'vests_gvest_01',
  // 'vests_gvest_02',
  // 'vests_kenguru3',
  // 'vests_lbv_vest',
  // 'vests_plce',
  'vests_strap',
  // 'body_malehostageoffice_04',
];

const PARTS_CONSMATICS0 = [
  'headgear_beret',
  'headgear_booniehat_01',
  'headgear_baseballcap_01',
  'headgear_cowboyhat_01',
  'headgear_cowboyhat_02',
  'headgear_fieldcap_01',
  // 'headgear_slouchhat',
];

const vests = [
  // none
  [],
  // 1
  ['vests_6sh92'],
  // 2
  ['vests_alicevest', 'headgear_m1helmet'],
  // 3
  ['armor_6b518', 'vests_alicevest', 'headgear_m1helmet'],
  // 4
  ['armor_6b518', 'vests_alicevest', 'headgear_m1helmet', 'backpack_alicebackpack'],
];

function extSerializeEntity(sim, entity, tfp) {
  let { name, pos, movespeed, firearm_ty, state, waypoint, aimtarget } = entity;
  const { life, life_max, armor, armor_max, ammo, firearm_ammo_max, aimdir, team } = entity;
  const { firearm_range } = entity;
  const { threat_level, vis_color, icons } = entity;
  waypoint = waypoint?.path?.map(({ pos }) => tfp(pos)) ?? [];

  let name_split = name.split('"');
  if (name_split.length === 3) {
    name = name_split[1];
  }

  let ads = true;
  if (movespeed > 0.7) {
    ads = false;
  }
  if (aimtarget) {
    aimtarget = tfp(aimtarget.pos);
    ads = true;
  }
  if (state === 'dash') {
    ads = false;
  }

  let parts = [];

  const rng = new Rng(name);
  const kills = sim.trails.filter((t) => t.source === entity && t.kill).length;

  // body
  if (team === 0) {
    const body = rng.choice(PARTS_ALL.filter(p => p.startsWith('body_0')));
    const cosmatics = rng.choice(PARTS_CONSMATICS0);
    parts = [
      body,
      'upperbody_tshirt_npc',
      'lowerbody_gorkapants',
      ...vests[entity.vest_rate ?? 0],
      cosmatics,
    ];
  } else {
    const body = rng.choice(PARTS_ALL.filter(p => p.startsWith('body_0')));
    parts = [
      body,
      'upperbody_tshirt_npc',
      'lowerbody_gorkapants',
    ];
  }

  const aimvar = entity.aimvar * sim.entityAimvarMult(entity);
  return {
    name,
    pos: tfp(pos),
    team,
    // movespeed 단위 (10cm/tick)
    // 편의상 mm/s로 넘김
    movespeed: movespeed * 10 * sim.tps,
    life,
    life_max,
    armor,
    armor_max,
    state,
    waypoint,
    aimtarget,

    ads,

    firearm_ty,
    aimdir,
    aimvar,
    firearm_range,
    firearm_ammo_max,

    ammo,

    // UI
    kills,

    crouch: ['covered', 'crawl', 'dash'].includes(state),

    // cosmatics/UI
    parts,
    threat_level,
    vis_color,
    icons,
  };
}

export function serializeState(sim, first, res, entityOver) {
  const fogbuf = sim.teamVisibility(0);
  // encode fogbuf
  let vis = '';
  for (let i = 0; i < fogbuf.length; i++) {
    const code = Math.floor(Math.min(fogbuf[i], 0.99) * 10);
    vis += code;
  }

  const tfp = (pos) => {
    return pos.add(sim.world.offset).mul(10).round();
  }

  const { tick } = sim;
  const entities = sim.entities.map((entity, idx) => {
    const { pos, reloadTick } = entity;
    const visible = fogbuf[sim.world.idx(sim.world.worldToGrid(pos))] > 0.6;

    let initiated = false;
    const rule = entity.waypoint_rule;
    if (entity.team !== 0 && rule.ty !== 'idle' && rule.tick === tick - 1) {
      initiated = true;
    }

    return {
      ...extSerializeEntity(sim, entity, tfp),

      idx,
      visible,
      reload_triggered: reloadTick.triggered(tick),
      reload_remain: reloadTick.remain(tick),
      entity_over: entity === entityOver,
      initiated,
    };
  });

  const trails = sim.trails.filter(({ tick }) => {
    return tick === sim.tick - 1;
  }).map(({ source, target, dir, len, hit, kill }) => {
    return {
      source_idx: sim.entities.indexOf(source),
      target_idx: sim.entities.indexOf(target),
      dir,
      len: len * 10,
      hit: !!hit,
      kill: !!kill,
    };
  });

  const bubbles = sim.bubbles.filter(({ timer }) => {
    return !timer.expired(tick);
  }).map(({ msg, entity, timer }) => {
    return {
      msg: msg,
      entity_idx: sim.entities.indexOf(entity),
      remain: timer.remain(tick),
    };
  });

  const throwables = sim.throwables.map((throwable) => {
    const {
      move_timer,
      blast_timer,
      entity,
      pos,
      start_pos,
      target_pos,
      blastareas,
    } = throwable;

    const targets = _.flatten(blastareas.map(({ entities }) => entities));

    const has_blast_ty = (ty) => {
      return throwable.throwable.blasts.find(({ blast_ty }) => blast_ty === ty);
    };

    let ty = 'base';
    if (has_blast_ty('damage')) {
      ty = 'frag';
    } else if (has_blast_ty('effect')) {
      ty = 'stun';
    }

    return {
      ty,
      name: throwable.throwable.throwable_name,

      throw_at: move_timer.start,
      land_at: move_timer.end,
      blast_at: blast_timer.end,

      pos: tfp(pos),
      start_pos: tfp(start_pos),
      target_pos: tfp(target_pos),

      source_idx: sim.entities.indexOf(entity),
      target_indices: targets.map((t) => sim.entities.indexOf(t)),
    };
  });

  let level_name_base = 'L_ShooterGym0';
  let level_names = ['L_ShooterGym_base'];
  if (sim.world.level_name_base) {
    level_name_base = sim.world.level_name_base;
  }
  if (sim.world.level_names) {
    level_names = sim.world.level_names;
  }

  const world = {
    level_name_base,
    level_names,
    width: sim.world.width,
    height: sim.world.height,
    grid_size: opts.GRID_SIZE * 10,
    grid_count_x: sim.world.grid_count_x,
    grid_count_y: sim.world.grid_count_y,
    offset: sim.world.offset.mul(10).round(),
    vis,
  };

  const obstacles = sim.obstacles.map((obs) => {
    const { ty, pos, extent, heading, doorstate, wip, imported } = obs;
    let dooropen = false;
    let doorname = doorstate?.name ?? null;
    let dooropener = -1;
    if (ty === 'door' && doorstate.open) {
      dooropen = true;
      dooropener = sim.entities.indexOf(doorstate.opener);
    }
    return {
      ty,
      pos: tfp(pos),
      extent: extent.mul(10).round(),
      heading,
      dooropen,
      doorname,
      dooropener,
      wip: !!wip,
      imported: !!imported,
      fullname: obs.fullname,
    };
  });

  const resp = {
    res,
    first,
    tick,

    world,
    obstacles,
    entities,
    bubbles,
    trails,
    throwables,
  };

  if (first) {
    resp.res = -2;
  }

  return resp;
}
