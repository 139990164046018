// downloaded data!
export const data = [
  {
    "key": "quest_first_dispatch",
    "type": "tutorial",
    "title": "loc_data_longtext_quest_title_quest_first_dispatch",
    "instruction": "loc_data_longtext_quest_instruction_quest_first_dispatch",
    "objective": "dispatch_mission",
    "reward": "",
    "duration": "-1",
    "description": "loc_data_longtext_quest_description_quest_first_dispatch",
    "prerequisite": "",
    "on_start": ""
  },
  {
    "key": "quest_progress_time",
    "type": "tutorial",
    "title": "loc_data_longtext_quest_title_quest_progress_time",
    "instruction": "loc_data_longtext_quest_instruction_quest_progress_time",
    "objective": "progress_time\nchange_gamespeed\nfinish_mission",
    "reward": "",
    "duration": "-1",
    "description": "loc_data_longtext_quest_description_quest_progress_time",
    "prerequisite": "quest_finish, quest_first_dispatch"
  },
  {
    "key": "quest_first_firearm",
    "type": "tutorial",
    "title": "loc_data_longtext_quest_title_quest_first_firearm",
    "instruction": "loc_data_longtext_quest_instruction_quest_first_firearm",
    "objective": "replace_firearm",
    "reward": "",
    "duration": "-1",
    "description": "loc_data_longtext_quest_description_quest_first_firearm",
    "prerequisite": "quest_finish, quest_progress_time\nfirearm_acquire, 1",
    "on_start": ""
  },
  {
    "key": "quest_first_recruit",
    "type": "tutorial",
    "title": "loc_data_longtext_quest_title_quest_first_recruit",
    "instruction": "loc_data_longtext_quest_instruction_quest_first_recruit",
    "objective": "department_work, recruit_agent\nfinish_department_work, recruit_agent",
    "reward": "",
    "duration": "-1",
    "description": "loc_data_longtext_quest_description_quest_first_recruit",
    "prerequisite": "mission_clear, 4",
    "on_start": "recruit_apply_tier, 0"
  },
  {
    "key": "quest_first_purchase",
    "type": "tutorial",
    "title": "loc_data_longtext_quest_title_quest_first_purchase",
    "instruction": "loc_data_longtext_quest_instruction_quest_first_purchase",
    "objective": "buy_item\nfinish_buy_item",
    "reward": "",
    "duration": "-1",
    "description": "loc_data_longtext_quest_description_quest_first_purchase",
    "prerequisite": "market_item\nmission_clear, 3"
  },
  {
    "key": "quest_first_armor",
    "type": "tutorial",
    "title": "loc_data_longtext_quest_title_quest_first_armor",
    "instruction": "loc_data_longtext_quest_instruction_quest_first_armor",
    "objective": "replace_armor",
    "reward": "",
    "duration": "-1",
    "description": "loc_data_longtext_quest_description_quest_first_armor",
    "prerequisite": "armor_acquire, 1"
  },
  {
    "key": "quest_first_perk",
    "type": "tutorial",
    "title": "loc_data_longtext_quest_title_quest_first_perk",
    "instruction": "loc_data_longtext_quest_instruction_quest_first_perk",
    "objective": "acquire_perk",
    "reward": "",
    "duration": "-1",
    "description": "loc_data_longtext_quest_description_quest_first_perk",
    "prerequisite": "perkpoint_acquire, 1"
  },
  {
    "key": "quest_build_command1",
    "type": "tutorial",
    "title": "loc_data_longtext_quest_title_quest_build_command1",
    "instruction": "loc_data_longtext_quest_instruction_quest_build_command1",
    "objective": "build_facility, command1\nfinish_build_facility, command1",
    "reward": "",
    "duration": "-1",
    "description": "loc_data_longtext_quest_description_quest_build_command1",
    "prerequisite": "agents_count, 4"
  },
  {
    "key": "quest_first_headhunter",
    "type": "tutorial",
    "title": "loc_data_longtext_quest_title_quest_first_headhunter",
    "instruction": "loc_data_longtext_quest_instruction_quest_first_headhunter",
    "objective": "finish_build_facility, recruit",
    "reward": "",
    "duration": "-1",
    "description": "loc_data_longtext_quest_description_quest_first_headhunter",
    "prerequisite": "block_quest, milestone_clear, 1"
  },
  {
    "key": "quest_first_trader",
    "type": "tutorial",
    "title": "loc_data_longtext_quest_title_quest_first_trader",
    "instruction": "loc_data_longtext_quest_instruction_quest_first_trader",
    "objective": "build_facility, market\nfinish_build_facility, market",
    "reward": "",
    "duration": "-1",
    "description": "loc_data_longtext_quest_description_quest_first_trader",
    "prerequisite": "block_quest, quest_finish, quest_first_purchase\nmilestone_clear, 1"
  },
  {
    "key": "quest_upgrade_base",
    "type": "tutorial",
    "title": "loc_data_longtext_quest_title_quest_upgrade_base",
    "instruction": "loc_data_longtext_quest_instruction_quest_upgrade_base",
    "objective": "finish_build_facility, base2",
    "reward": "",
    "duration": "-1",
    "description": "loc_data_longtext_quest_description_quest_upgrade_base",
    "prerequisite": "milestone_clear, 1"
  },
  {
    "key": "quest_first_trainer",
    "type": "tutorial",
    "title": "loc_data_longtext_quest_title_quest_first_trainer",
    "instruction": "loc_data_longtext_quest_instruction_quest_first_trainer",
    "objective": "finish_build_facility, training_general\r\ndepartment_work, maintain_training",
    "reward": "",
    "duration": "-1",
    "description": "loc_data_longtext_quest_description_quest_first_trainer",
    "prerequisite": "quest_finish, quest_upgrade_base",
    "on_start": ""
  },
  {
    "key": "quest_train",
    "type": "tutorial",
    "title": "loc_data_longtext_quest_title_quest_train",
    "instruction": "loc_data_longtext_quest_instruction_quest_train",
    "objective": "train, stat",
    "reward": "",
    "duration": "-1",
    "description": "loc_data_longtext_quest_description_quest_train",
    "prerequisite": "quest_finish, quest_first_trainer",
    "on_start": ""
  },
  {
    "key": "quest_search_staff",
    "type": "tutorial",
    "title": "loc_data_longtext_quest_title_quest_search_staff",
    "instruction": "loc_data_longtext_quest_instruction_quest_search_staff",
    "objective": "allocate_department_chief, base\ndepartment_work, search_staff\nfinish_department_work, search_staff",
    "reward": "",
    "duration": "-1",
    "description": "loc_data_longtext_quest_description_quest_search_staff",
    "prerequisite": "quest_finish, quest_upgrade_base",
    "on_start": ""
  },
  {
    "key": "quest_recruit_staff",
    "type": "tutorial",
    "title": "loc_data_longtext_quest_title_quest_recruit_staff",
    "instruction": "loc_data_longtext_quest_instruction_quest_recruit_staff",
    "objective": "allocate_department_chief, base\ndepartment_work, recruit_staff\nfinish_department_work, recruit_staff",
    "reward": "",
    "duration": "-1",
    "description": "loc_data_longtext_quest_description_quest_recruit_staff",
    "prerequisite": "quest_finish, quest_search_staff"
  },
  {
    "key": "quest_demo_00",
    "type": "milestone",
    "title": "창업",
    "instruction": "다섯(5) 명의 용병을 임무를 수행할 수 있는 상태로 준비시키세요\n레벨 3 이상의 용병을 확보하세요.",
    "objective": "demo_00\ndemo_00_1",
    "reward": "milestone_open, 1",
    "duration": "6048",
    "description": "회사 모양을 갖추기는 했지만, 아직 제 일을 맡길 수 있는 상태는 아니군요.\n제 일을 시작할 수 있는 최소한의 준비가 되면 다시 연락하겠습니다.\n당신이 일을 시작할 수 있도록 하기 위해 꽤 많은 노력이 들어갔습니다. 투자한 만큼의 성과가 있기를 바랍니다.",
    "prerequisite": "quest_finish, quest_first_dispatch"
  },
  {
    "key": "quest_demo_01",
    "type": "milestone",
    "title": "첫 번째 숙제",
    "instruction": "중요 임무 (눈에 밟히던 놈들)을 완료하세요.",
    "objective": "milestone_clear, 1",
    "reward": "",
    "duration": "2016",
    "description": "이전보다는 나아진 모양새네요.\n첫 번째 숙제를 드리겠습니다. 아직 큰 문제는 아니지만, 평소에 눈에 밟히던 놈들입니다.\n얼마나 깔끔하게 처리하는지 지켜보겠습니다.",
    "prerequisite": "quest_finish, quest_demo_00"
  },
  {
    "key": "quest_demo_02",
    "type": "milestone",
    "title": "준비 (1)",
    "instruction": "두(2) 명의 용병을, 2등급 이상의 무장을 장비하고 임무를 수행할 수 있는 상태로 준비시키세요.\n",
    "objective": "demo_02",
    "reward": "",
    "duration": "4032",
    "description": "다음 숙제를 드리고 싶은데, 준비가 더 필요해 보이네요.\n용병 둘을 준비하세요. 실력은 신경쓰지 않지만, 눈 먼 총알에 죽지 않을 정도는 되어야 합니다.",
    "prerequisite": "quest_finish, quest_demo_01"
  },
  {
    "key": "quest_demo_03",
    "type": "milestone",
    "title": "준비 (2)",
    "instruction": "척탄병 퍽 그룹의 [기초 척탄 훈련] 퍽을 보유한 용병을 확보하세요.",
    "objective": "demo_03",
    "reward": "milestone_open, 2",
    "duration": "6048",
    "description": "아주 작은 변수도 전투의 흐름을 뒤집을 수 있다는 것을, 당신은 알 겁니다. 수류탄처럼 작은 물건도 말이지요. \n그걸 다룰 수 있다면 이번 숙제를 더 수월하게 처리할 수 있을 겁니다.",
    "prerequisite": "quest_finish, quest_demo_02"
  },
  {
    "key": "quest_demo_04",
    "type": "milestone",
    "title": "두 번째 숙제",
    "instruction": "중요 임무 (폭죽 놀이)을 완료하세요.",
    "objective": "milestone_clear, 2",
    "reward": "",
    "duration": "2016",
    "description": "준비가 다 된 것 같군요. \n두 번째 숙제입니다. 이 지역에 우리가 그리고 있는 그림에 얼룩이 있습니다. \n단단히 무장했고, 용병도 좀 고용했군요. 당신이 그 얼룩을 지우면 좋겠습니다. \n신속히 처리하길 바랍니다.",
    "prerequisite": "quest_finish, quest_demo_03"
  }
];