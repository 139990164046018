import React from 'react';
import update from 'react-addons-update';
import { v2 } from './v2.mjs';
import { geomContains, createObstacle } from './geom.mjs';
import { presets } from './presets_mission.mjs'
import { Rng } from './rand';
import { SimView } from './SimView';
import { ENTITY_CONFIG_TMPL } from './opts.mjs';
import {
  team0_tmpl_agent_ar_high,
} from './presets.mjs';
import * as extobj from './extobj';

import './Editor.css';

const OBSTACLE_TY = ['half', 'full', 'door', 'random'];

function layerEq(a, b) {
  a = a ?? 'base';
  b = b ?? 'base';
  return a === b;
}

const LAYERS_DEFAULT = [
  { name: 'base', visible: true },
];

class Canv extends React.Component {
  constructor(props) {
    super(props);

    this.canvasRef = React.createRef();
    this.configRef = React.createRef();
    this.textRef = React.createRef();

    this.state = this.initialState(props);

    this.mouseDown = this.onMouseDown.bind(this);
    this.mouseMove = this.onMouseMove.bind(this);
    this.mouseUp = this.onMouseUp.bind(this);
    this.wheel = this.onWheel.bind(this);
    this.keyBindDown = (e) => this.onKeyBind(e, false);
    this.keyBindUp = (e) => this.onKeyBind(e, true);

    this.transposeAll = this.transposeAll.bind(this);
  }

  initialState(_props) {
    return {
      world: { width: 800, height: 800 },

      transposeX: 0,
      transposeY: 0,

      layers: LAYERS_DEFAULT,
      curLayer: 'base',
      moveLayer: null,

      obstacle_specs: [],
      spawnareas: [],

      alt: false,
      moving: { obs: null },
      current: { creating: false, resizing: false },

      optShowIndex: false,
    };
  }

  componentDidMount() {
    this.renderCanvas();
    document.addEventListener('mousedown', this.mouseDown);
    document.addEventListener('mousemove', this.mouseMove);
    document.addEventListener('mouseup', this.mouseUp);
    document.addEventListener('wheel', this.wheel, { passive: false });
    document.addEventListener('keydown', this.keyBindDown);
    document.addEventListener('keyup', this.keyBindUp);

    this.canvasRef.current.addEventListener('contextmenu', e => e.preventDefault());

    const edit = window.sessionStorage.getItem('edit');
    if (edit) {
      this.setState(this.deserialize(edit));
    }

    if (!window.editor) {
      window.editor = this;
    }
  }

  preview(extobjs) {
    const simstate = presets['ext']();
    extobj.convert_world(extobjs, simstate);
    const { world, obstacle_specs, spawnareas } = simstate;
    this.setState({ world, obstacle_specs, spawnareas });
  }

  sync(extobjs) {
    const { spawnareas, obstacle_specs, layers } = this.state;
    const simstate = presets['ext']();
    simstate.spawnareas = spawnareas.filter((s) => {
      let layer = layers.find((l) => layerEq(l.name, s.layer));
      return layer.visible;
    });
    extobj.convert_world(extobjs, simstate);

    for (let i = 0; i < obstacle_specs.length; i++) {
      const prev = obstacle_specs[i];
      let layer = layers.find((l) => layerEq(l.name, prev.layer));
      if (!layer.visible) {
        continue;
      }
      const next = simstate.obstacle_specs.find((o) => o.tags.includes(`${i}`));
      if (!next) {
        continue;
      }
      prev.pos = next.pos;
      prev.extent = next.extent;
      prev.heading = next.heading;
    }
    this.setState({ spawnareas, obstacle_specs });
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.mouseDown);
    document.removeEventListener('mousemove', this.mouseMove);
    document.removeEventListener('mouseup', this.mouseUp);
    document.removeEventListener('wheel', this.wheel);
    document.removeEventListener('keydown', this.keyBindDown);
    document.removeEventListener('keyup', this.keyBindUp);

    if (this === window.editor) {
      delete window.editor;
    }
  }

  deserialize(text) {
    const { world, layers, obstacle_specs, spawnareas } = JSON.parse(text);
    return {
      world: world ? { width: world.width, height: world.height } : { width: 800, height: 800 },
      layers: layers ?? LAYERS_DEFAULT,
      obstacle_specs: this.loadConfig(obstacle_specs),
      spawnareas: this.loadConfig(spawnareas)
    };
  }

  serialize() {
    const { world, layers, spawnareas, obstacle_specs } = this.state;
    return JSON.stringify({
      world,
      layers,
      spawnareas: spawnareas.map((o0) => {
        const o = { ...o0 };
        delete o.idx;
        for (const key of Object.keys(o)) {
          if (o[key] === null) {
            delete (o[key]);
          }
        }

        return o;
      }),
      obstacle_specs: obstacle_specs.map((o0) => {
        const o = { ...o0 };
        delete o.idx;
        if (!o.layer) {
          delete o.layer;
        }
        delete o.resizing;
        return o;
      }),
    }, null);
  }

  componentDidUpdate() {
    this.renderCanvas();

    const edit = this.serialize();
    this.textRef.current.value = edit;

    window.sessionStorage.setItem('edit', edit);
    this.serializeConfig();
  }

  update(obs, idx, command) {
    let obstacle_specs = this.state.obstacle_specs.slice();
    let spawnareas = this.state.spawnareas.slice();
    if (!OBSTACLE_TY.includes(obs.ty)) {
      if (command === "push") {
        spawnareas = update(spawnareas, {
          $push: [{ ...obs }],
        })
      } else if (command === "splice") {
        spawnareas = update(spawnareas, {
          $splice: [[idx, 1]],
        })
      } else if (command === "set") {
        spawnareas = update(spawnareas, {
          [idx]: { $set: obs }
        })
      }
    } else {
      if (command === "push") {
        obstacle_specs = update(obstacle_specs, {
          $push: [{ ...obs }],
        })
      } else if (command === "splice") {
        obstacle_specs = update(obstacle_specs, {
          $splice: [[idx, 1]],
        })
      } else if (command === "set") {
        obstacle_specs = update(obstacle_specs, {
          [idx]: { $set: obs }
        })
      }
    }
    return { obstacle_specs, spawnareas }
  }

  onMouseDown(e) {
    const { moveLayer } = this.state;
    const cursor = this.canvasCursor(e);
    if (!cursor) {
      return;
    }

    if (e.ctrlKey) {
      // TODO: duplicate obstacle
      let { idx, obs } = this.findObstacle(cursor);
      if (!obs) {
        return;
      }
      obs = { ...obs };
      if (moveLayer) {
        obs.layer = moveLayer;
      }

      // duplicate
      const obstacles = this.update(obs, idx, "push");

      this.setState({ ...obstacles }, () => {
        this.onMoveObstacle(e);
      });
      return;
    }

    if (e.altKey) {
      const { idx, obs } = this.findObstacle(cursor);
      if (!obs) {
        return;
      }

      if (e.button === 2) {
        // right click
        const obs0 = { ...obs };
        delete obs0.spawnheading;

        const obstacles = this.update(obs0, idx, "set");
        this.setState({ ...obstacles });
      } else {
        this.setState({
          current: { ...obs, resizing: true, idx },
        });
      }
      return;
    }

    this.onMoveObstacle(e);
  }

  onMoveObstacle(e) {
    const { curLayer, moveLayer } = this.state;
    const cursor = this.canvasCursor(e);
    if (!cursor) {
      return;
    }

    const { idx, obs } = this.findObstacle(cursor);

    if (obs) {
      if (e.button === 2) {
        // right click
        if (moveLayer) {
          const obstacles = this.update({ ...obs, layer: moveLayer }, idx, "set");
          this.setState({ ...obstacles });
        } else {
          const obstacles = this.update(obs, idx, "splice");
          this.setState({ ...obstacles });
        }
        return;
      } else if (e.button === 1) {
        this.changeType(cursor);
      } else {
        this.setState({ moving: { obs, offset: cursor, obsoffset: obs.pos } });
      }
      return;
    }

    this.setState({
      current: {
        creating: true,
        pos: cursor,
        extent: new v2(0, 0),
        heading: 0,
        ty: 'half',
        idx,
        layer: curLayer !== 'base' ? curLayer : null,
      },
    });
  }

  changeType(cursor) {
    const { idx, obs } = this.findObstacle(cursor);
    if (!obs) {
      return;
    }

    if (obs.ty === 'half') {
      const obstacles = this.update({ ...obs, ty: 'full' }, idx, "set");
      this.setState({ ...obstacles });
    } else if (obs.ty === 'full') {
      const obstacles = this.update({ ...obs, ty: 'door' }, idx, "set");
      this.setState({ ...obstacles });
    } else if (obs.ty === 'door') {
      const obs0 = { ...obs, ty: 'random', random: { ty: 'mixed', count: 0 } };
      const obstacles = this.update(obs0, idx, "set");
      this.setState({ ...obstacles });
    } else if (obs.ty === 'random') {
      const obstacles = this.update(obs, idx, "splice");
      obs.ty = 'spawnarea';
      delete obs.random;
      obstacles.spawnareas.push(obs);
      this.setState({ ...obstacles });
    } else if (obs.ty === 'spawnarea') {
      const obstacles = this.update(obs, idx, "splice");
      obs.ty = "half";
      delete obs.effect;
      delete obs.triggers;
      delete obs.spawn;
      delete obs.spawn1;
      delete obs.object;
      obstacles.obstacle_specs.push({ ...obs });
      this.setState({ ...obstacles });
    }
  }

  toggleWip(cursor) {
    const { idx, obs } = this.findObstacle(cursor);
    if (!obs) {
      return;
    }

    const obstacles = this.update({ ...obs, wip: !obs.wip }, idx, "set");
    this.setState({ ...obstacles });
  }

  putEffectType(cursor) {
    const { idx, obs } = this.findObstacle(cursor);
    if (!obs || obs.ty !== 'spawnarea') {
      return;
    }

    const effect_tys = [null, 'limvis', 'stun_gr'];
    const tyidx = effect_tys.indexOf(obs.effect);
    this.setState({
      spawnareas: update(this.state.spawnareas, {
        [idx]: { effect_ty: { $set: obs.effect ? effect_tys[(tyidx + 1) % effect_tys.length] : effect_tys[1] } },
      }),
    });
  }

  //chae 일단 기본적인거만 넣어놓고 대충하면 되지 않을까???!!
  putTriggers(cursor) {
    const { idx, obs } = this.findObstacle(cursor);
    if (!obs || obs.ty !== 'spawnarea') {
      return;
    }
    this.setState({
      spawnareas: update(this.state.spawnareas, {
        [idx]: {
          triggers: {
            $set: !obs.triggers ? [{
              condition: 'enter',
              conditiontarget: { ty: 'entity', team: 0 },
              action: 'push_rule',
              actiontarget: { ty: 'entity', team: 0 },
              actionrule: { ty: 'capture' }
            }] : null
          },
        }
      }),
    });
  }

  putTeam0Spawn(cursor) {
    const { idx, obs } = this.findObstacle(cursor);
    if (!obs || obs.ty !== 'spawnarea') {
      return;
    }

    const area = this.state.spawnareas[idx];

    const op = {
      spawn: { $set: null },
      spawn1: { $set: null },
      object: { $set: null },
    };

    if (area.spawn) {
      op.spawn1 = { $set: true };
    } else if (area.spawn1) {
      op.object = { $set: true };
    } else if (area.object) {
    } else {
      op.spawn = { $set: true };
    }

    this.setState({ spawnareas: update(this.state.spawnareas, { [idx]: op }) });
  }

  onMouseMove(e) {
    const cursor = this.canvasCursor(e);
    if (!cursor) {
      return;
    }
    const { moving, current } = this.state;

    if (moving.obs) {
      let obs = moving.obs;
      let cursordelta = moving.offset.sub(cursor);
      obs.pos = moving.obsoffset.sub(cursordelta);
      const obstacles = this.update(obs, moving.idx, "set");
      this.setState({ ...obstacles });
      return;
    }

    if (current.creating) {
      const extent = cursor.sub(current.pos);
      extent.x = Math.abs(extent.x);
      extent.y = Math.abs(extent.y);

      this.setState({
        current: update(current, { extent: { $set: extent } }),
      });
    }

    if (current.resizing) {

      const cursor2 = cursor.rot(current.pos, -current.heading).round();
      const extent = cursor2.sub(current.pos);
      extent.x = Math.abs(extent.x);
      extent.y = Math.abs(extent.y);

      const obs0 = { ...current, extent };

      const obstacles = this.update(obs0, current.idx, "set")

      this.setState({
        ...obstacles,
        current: update(current, { extent: { $set: extent }, }),
      });
    }

    const { obs } = this.findObstacle(cursor);

    this.setState({ cursor, obsOver: obs ? obs : null });
  }

  onMouseUp(_e) {
    const { moving, current } = this.state;
    if (moving.obs) {
      this.setState({ moving: { obs: null } });
    }

    if (current.creating) {
      if (current.extent.x === 0 || current.extent.y === 0) {
        return;
      }
      delete (current.creating);

      const obstacles = this.update(current, null, "push");

      this.setState({
        ...obstacles,
        current: { creating: false },
      });
    }

    if (current.resizing) {
      this.setState({
        current: { resizing: false },
      });
    }
  }

  onWheel(e) {
    const cursor = this.canvasCursor(e);
    if (!cursor) {
      return;
    }

    const { obs, idx } = this.findObstacle(cursor);
    if (!obs) {
      return;
    }

    e.preventDefault();

    let obs0 = null;
    if (this.state.alt) {
      if (obs.ty === 'spawnarea') {
        let spawnheading = obs.spawnheading ?? 0;
        spawnheading += e.deltaY / 1000;
        obs0 = { ...obs, spawnheading };
      }
    } else {
      obs0 = { ...obs, heading: Math.round((obs.heading + e.deltaY / 1000) * 100) / 100 }
    }

    if (obs0) {
      const obstacles = this.update(obs0, idx, "set");
      this.setState({ ...obstacles });
    }
  }

  onKeyBind(e, up) {
    this.setState({
      alt: e.altKey,
    });
    if (up) {
      return;
    }

    const { cursor } = this.state;
    if (!cursor) {
      return;
    }

    // ctrl 조합키 무시하기
    if (e.ctrlKey) {
      return;
    }

    if (e.key === 'c') {
      this.changeType(cursor);
    } else if (e.key === 'w') {
      this.toggleWip(cursor);
    } else if (e.key === '1') {
      this.putEffectType(cursor);
    } else if (e.key === '2') {
      this.putTriggers(cursor);
    } else if (e.key === '3') {
      this.putTeam0Spawn(cursor);
    }
  }

  transposeAll(x, y) {
    const { obstacle_specs, spawnareas } = this.state;
    this.setState({
      obstacle_specs: obstacle_specs.map(obs => {
        obs.pos = obs.pos.add(new v2(x, y));
        return obs;
      }),
      spawnareas: spawnareas.map(obs => {
        obs.pos = obs.pos.add(new v2(x, y));
        return obs;
      }),
    });
  }

  findObstacle(cursor, layer) {
    const { obstacle_specs, spawnareas, curLayer } = this.state;
    layer = layer ?? curLayer;

    for (let i = spawnareas.length - 1; i >= 0; i--) {
      const obs = spawnareas[i];
      if (layer && !layerEq(obs.layer, layer)) {
        continue;
      }
      const p = createObstacle(obs.pos, obs.extent, obs.heading, null, 0);
      if (geomContains(cursor, p.polygon)) {
        // return last object
        return { idx: i, obs };
      }
    }

    for (let i = obstacle_specs.length - 1; i >= 0; i--) {
      const obs = obstacle_specs[i];
      if (layer && !layerEq(obs.layer, layer)) {
        continue;
      }
      const p = createObstacle(obs.pos, obs.extent, obs.heading, null, 0);
      if (geomContains(cursor, p.polygon)) {
        return { idx: i, obs };
      }
    }

    return { idx: null, obs: null };
  }

  canvasCursor(e) {
    const { width, height } = this.state.world;

    const canvas = this.canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    let x = e.clientX - rect.left;
    let y = e.clientY - rect.top;

    if (x < 0 || y < 0 || x > width || y > height) {
      return null;
    }
    x -= width / 2;
    y -= height / 2;

    return new v2(x, y).round();
  }

  serializeConfig() {
    let spawnareas = "spawnareas: [\n";
    let obstacles = "obstacle_specs: [\n";

    const sv2 = (v) => `new v2(${v.x}, ${v.y})`;

    for (const obs of this.state.obstacle_specs) {
      obstacles += `{ pos: ${sv2(obs.pos)}, extent: ${sv2(obs.extent)}, heading: ${obs.heading}, ty: "${obs.ty}", `
        + (obs.random ? `random:` + JSON.stringify(obs.random) : ``)
        + `},\n`;
    }
    for (const area of this.state.spawnareas) {
      spawnareas += `{ pos: ${sv2(area.pos)}, extent: ${sv2(area.extent)}, heading: ${area.heading}, `;
      if (area.effect_ty) {
        spawnareas += `effect_ty: '${area.effect_ty}',`;
      }

      if (area.triggers) {
        spawnareas += `\ntriggers: `;
        spawnareas += JSON.stringify(area.triggers);
        spawnareas += `,\n`;
      }
      if (area.spawn) {
        spawnareas += `spawn: true,`;
      }
      if (area.spawn1) {
        spawnareas += `spawn1: true,`;
      }
      if (area.object) {
        spawnareas += `object: true,`;
      }
      spawnareas += `},\n`;
    }

    const s = `
${spawnareas}],
${obstacles}],`;
    this.configRef.current.value = s;
  }

  loadConfig(text) {
    for (const item of text) {
      item.pos = new v2(item.pos.x, item.pos.y);
      item.extent = new v2(item.extent.x, item.extent.y);
    }

    return text;
  }

  onTriggerOver(e) {
    this.setState({ triggerOver: e });
  }

  selectTrigger() {
    const { spawnareas, obsOver } = this.state;
    let actionruleForm = [];
    for (const area of spawnareas) {
      if (area.triggers) {
        const idx = spawnareas.indexOf(area);
        actionruleForm.push(
          <div key={idx}>
            spawnarea #{idx} trigger
            <p></p>
            <textarea
              style={{ backgroundColor: (area === obsOver ? 'pink' : '') }}
              onMouseOver={() => this.onTriggerOver(area)}
              onMouseLeave={() => this.onTriggerOver(null)}
              id={idx + "triggers"}
            >{JSON.stringify(area.triggers)}</textarea>
            <button onClick={() => {
              const t = document.getElementById(idx + "triggers").value;
              this.setState({
                spawnareas: update(spawnareas, {
                  [idx]: {
                    triggers: { $set: JSON.parse(t) },
                  }
                }),
              });
            }}>적용</button>
          </div>
        )
      }
    }
    return actionruleForm;
  }

  onRandomOver(e) {
    this.setState({
      randomOver: e
    })
  }

  selectRandomObs() {
    const { obstacle_specs, obsOver } = this.state;
    let randomObsForm = [];
    let areas = -1;
    for (const obs of obstacle_specs) {
      if (obs.ty === "random") {
        areas++;
        const idx = obstacle_specs.indexOf(obs);
        randomObsForm.push(
          <form style={{ backgroundColor: (obs === obsOver ? 'lightgreen' : '') }} key={areas}
            onMouseOver={() => this.onRandomOver(obs)} onMouseLeave={() => this.onRandomOver(null)}>
            randomArea #{areas}-
            <label htmlFor="ty">
              ty :
            </label>
            <select id="ty" onChange={(e) => {
              const randomTy = e.target.value;

              this.setState({
                obstacle_specs: update(obstacle_specs, {
                  [idx]: {
                    random: { ty: { $set: randomTy }, }
                  }
                }),
              });
            }}>
              <option value="mixed">mixed</option>
              <option value="full">full</option>
              <option value="half">half</option>
            </select>
            count :
            <input type="number" value={obs.random.count ? obs.random.count : ''} onChange={(e) => {
              const count = e.target.value;
              this.setState({
                obstacle_specs: update(obstacle_specs, {
                  [idx]: {
                    random: { count: { $set: count === '' ? 0 : count } }
                  }
                }),
              });
            }}></input>
          </form>
        );
      }
    }
    return randomObsForm;
  }

  loadbutton() {
    return Object.keys(presets)
      .filter((key) => presets[key]().editor)
      .map((key) => {
        return <button key={key} onClick={() => {
          const preset = presets[key]()
          const { spawnareas, layers, world, obstacle_specs } = preset;
          for (const area of spawnareas) {
            area.ty = "spawnarea";
          }
          this.setState({
            world,
            layers: layers ?? LAYERS_DEFAULT,
            spawnareas: this.loadConfig(spawnareas),
            obstacle_specs: this.loadConfig(obstacle_specs),
          });
        }}>{key}</button>;
      })
  }

  layerState(layer) {
    const { layers, curLayer } = this.state;
    const layer_name = layer ?? 'base';
    const found = layers.find(l => l.name === layer_name);
    const visible = found?.visible ?? false;
    const selected = layer_name === curLayer;
    return { visible, selected };
  }

  renderCanvas() {
    const { world, triggerOver, randomOver } = this.state;
    const { current, obstacle_specs, spawnareas } = this.state;
    const { optShowIndex } = this.state;

    const canvas = this.canvasRef.current;
    const ctx = canvas.getContext('2d');

    ctx.font = 'bold 12px monospace';

    ctx.resetTransform();

    ctx.fillStyle = 'black';
    ctx.globalAlpha = 1;
    ctx.fillRect(0, 0, world.width, world.height);

    ctx.translate(world.width / 2, world.height / 2);

    function renderPoly(polygon) {
      const start = polygon[polygon.length - 1];
      ctx.beginPath();
      ctx.moveTo(start.x, start.y);
      for (const p of polygon) {
        ctx.lineTo(p.x, p.y);
      }
    }

    ctx.strokeStyle = 'red';
    if (current.creating) {
      const obs = current;
      const p = createObstacle(obs.pos, obs.extent, obs.heading, null, 0);
      renderPoly(p.polygon);
      ctx.stroke();
    }

    let areas = 0;
    let randoms = 0;
    for (let i = 0; i < obstacle_specs.length; i++) {
      const obs = obstacle_specs[i];
      const { visible, selected } = this.layerState(obs.layer);
      if (!visible) {
        continue;
      }
      ctx.globalAlpha = selected ? 1 : 0.5;

      const p = createObstacle(obs.pos, obs.extent, obs.heading, null, 0);

      let style = null;
      if (obs.wip) {
        style = 'purple';
      }
      ctx.strokeStyle = style ?? 'white';
      renderPoly(p.polygon);

      if (randomOver === obs) {
        ctx.fillStyle = style ?? 'lightgreen';
        ctx.fill();
      }
      if (obs.ty === 'full') {
        ctx.fillStyle = style ?? 'white';
        ctx.fill();
      } else if (obs.ty === 'half') {
        ctx.stroke();
      } else if (obs.ty === 'door') {
        ctx.fillStyle = style ?? 'green';
        ctx.fill();
      }

      if (obs.ty === 'door' || optShowIndex) {
        const labelpos = obs.pos.sub(obs.extent);
        ctx.fillStyle = 'lightgray';
        let text = `#${i}`;
        if (obs.layer) {
          text += `: ${JSON.stringify(obs.layer)}`;
        }
        ctx.fillText(text, labelpos.x, labelpos.y);
      }

      if (obs.ty === 'door' && obs.doorname) {
        ctx.fillStyle = 'green';
        const text = `name: ${obs.doorname}`;
        ctx.fillText(text, obs.pos.x, obs.pos.y);
      }

      if (obs.ty === 'random') {
        ctx.fillStyle = 'yellow';
        const text = `random #${randoms}(ty: ${obs.random.ty}, count: ${obs.random.count})`
        ctx.fillText(text, obs.pos.x, obs.pos.y);
        randoms++;
      }
    }

    for (let i = 0; i < spawnareas.length; i++) {
      const obs = spawnareas[i];
      const { visible, selected } = this.layerState(obs.layer);
      if (!visible) {
        continue;
      }
      ctx.globalAlpha = selected ? 1 : 0.5;

      const p = createObstacle(obs.pos, obs.extent, obs.heading, null, 0);

      ctx.strokeStyle = 'white';
      renderPoly(p.polygon);
      if (triggerOver === obs) {
        ctx.fillStyle = 'pink';
        ctx.fill();
      } else {
        ctx.stroke();
      }

      if (optShowIndex) {
        const labelpos = obs.pos.sub(obs.extent);
        ctx.fillStyle = 'lightgray';
        ctx.fillText(`#${i}`, labelpos.x, labelpos.y);
      }

      ctx.fillStyle = 'yellow';
      let text = `spawnarea ${areas}`;
      if (obs.effect_ty) {
        text += `, effect=${obs.effect_ty}`;
      }
      if (obs.triggers) {
        text += `, triggers`;
      }
      if (obs.spawn) {
        text += `, startpoint`;
        ctx.fillStyle = 'skyblue';
      }
      if (obs.spawn1) {
        text += `, enemy`;
        ctx.fillStyle = 'aqua';
      }
      if (obs.object) {
        text += `, object`;
        ctx.fillStyle = 'blue';
      }
      ctx.fillText(text, obs.pos.x, obs.pos.y);
      areas += 1;

      if (obs.spawnheading) {
        let len = 20;
        ctx.strokeStyle = 'yellow';
        ctx.beginPath();
        ctx.moveTo(obs.pos.x, obs.pos.y);

        const delta = v2.fromdir(obs.spawnheading).mul(len);

        ctx.lineTo(obs.pos.x + delta.x, obs.pos.y + delta.y);
        ctx.stroke();
      }
    }
  }

  renderLayers() {
    const { layers, curLayer, moveLayer } = this.state;

    return <div className="box editor-layers">
      <div>
        <p>layers</p>
      </div>
      {layers.map((l, idx) => {
        const { visible } = l;
        const selected = l.name === curLayer;

        let movebtn = <button tabIndex="-1" onClick={() => {
          this.setState({ moveLayer: l.name });
        }}>move</button>;
        if (moveLayer === l.name) {
          movebtn = <button tabIndex="-1" onClick={() => {
            this.setState({ moveLayer: null });
          }}>cancel</button>;
        }

        let clsvis = 'editor-btn-eye';
        if (!visible) {
          clsvis += ' editor-btn-eye-off';
        }

        return <div className="box" key={idx}>
          <input onChange={(e) => {
            if (l.name === 'base' || e.target.value === 'base') {
              return;
            }

            const name0 = l.name;
            const name = e.target.value;
            let op = {
              layers: update(layers, {
                [idx]: { name: { $set: name } },
              }),
            };

            const dup = layers.filter(l => l.name === name0);
            if (dup.length === 1) {
              const obstacle_specs = this.state.obstacle_specs.map(obs => {
                let layer = obs.layer === l.name ? name : obs.layer;
                return { ...obs, layer };
              });
              const spawnareas = this.state.spawnareas.map(obs => {
                let layer = obs.layer === l.name ? name : obs.layer;
                return { ...obs, layer };
              });
              op = {
                ...op,
                obstacle_specs,
                spawnareas,
              };
            }

            this.setState(op);
          }} value={l.name} />

          <button tabIndex="-1" disabled={selected} onClick={() => {
            this.setState({ curLayer: l.name });
          }}>select</button>

          {movebtn}

          <button tabIndex="-1" onClick={() => {
            this.setState({
              layers: update(layers, {
                $splice: [[idx, 1]],
              }),
            });
          }}>␡</button>

          <button tabIndex="-1" onClick={() => {
            this.setState({
              layers: update(layers, {
                [idx]: { visible: { $set: !visible } },
              }),
            });
          }} className={clsvis}>{visible ? '👁' : '-'}</button>

          {idx > 0 ? <button tabIndex="-1" onClick={() => {
            const layers1 = layers.slice();
            const idx1 = idx - 1;
            [layers1[idx], layers1[idx1]] = [layers1[idx1], layers1[idx]];
            this.setState({ layers: layers1 });
          }}>↑</button> : null}
          {(idx < layers.length - 1) ? <button tabIndex="-1" onClick={() => {
            const layers1 = layers.slice();
            const idx1 = idx + 1;
            [layers1[idx], layers1[idx1]] = [layers1[idx1], layers1[idx]];
            this.setState({ layers: layers1 });
          }}>↓</button> : null}

        </div>;
      })}
      <button tabIndex="-1" onClick={() => {
        this.setState({
          layers: update(layers, {
            $push: [{ name: 'new', visible: true }],
          }),
        });
      }}>add</button>
    </div>;
  }

  render() {
    const { world, transposeX, transposeY, simstate, obstacle_specs, spawnareas } = this.state;
    const { layers, optShowIndex } = this.state;
    const { width, height } = world;

    const makePreset = () => {
      const team1_tmpl = {
        ...ENTITY_CONFIG_TMPL,
        team: 1,
        life: 20,
        armor: 20,
        default_rule: { ty: 'idle', alert: false },
        allow_crawl: false,
      };
      const entities = [];
      for (let i = 0; i < spawnareas.length; i++) {
        const spawnarea = spawnareas[i];
        if (spawnarea.spawn) {
          entities.push({ ...team0_tmpl_agent_ar_high, spawnarea: i });
          entities.push({ ...team0_tmpl_agent_ar_high, spawnarea: i })
        }
        if (spawnarea.spawn1) {
          entities.push({ ...team1_tmpl, spawnarea: i });
        }
      }

      this.setState({
        simstate: {
          world: {
            ...world,
            simover_rule: 'eliminate',
          },

          obstacle_specs,
          entities,
          spawnareas,
          goals: [],

          mission_rules: [
            { ty: 'capture' },
            { ty: 'explore' },
          ],
        }
      })
    }

    return (
      <div className="editor-root">
        {simstate ?
          <SimView
            m={this.props.m}
            seed={Rng.randomseed()}
            debug={true}
            noembed={true}
            onFinish={() => this.setState({ simstate: null })}
            {...simstate}
          /> : ``}
        <div className="box">
          <p>load presets</p>
          {this.loadbutton()}
        </div>
        {this.renderLayers()}
        <div>
          <button onClick={() => {
            const payload = JSON.stringify({
              world: {
                extent: {
                  x: world.width / 2,
                  y: world.height / 2,
                },
              },
              spawnareas: spawnareas.filter((s) => {
                let layer = layers.find((l) => layerEq(l.name, s.layer));
                return layer.visible;
              }).map((s) => {
                return {
                  pos: s.pos,
                  extent: s.extent,
                  heading: s.heading,
                };
              }),
              obstacles: obstacle_specs.map((obs, idx) => {
                let layer = layers.find((l) => layerEq(l.name, obs.layer));
                return {
                  pos: obs.pos,
                  extent: obs.extent,
                  heading: obs.heading,
                  ty: obs.ty,
                  visible: layer.visible,
                  idx,
                };
              }).filter((obs) => obs.visible),
            });
            window.ue.preview.printlog(payload);
          }}>push</button>
          <button onClick={() => {
            window.ue.preview.pull("");
          }}>pull</button>

          <button onClick={() => {
            this.setState({
              obstacle_specs: [],
              spawnareas: [],
              world: {
                width: 800, height: 800
              }
            })
          }}>reset</button>
          <input type="checkbox" defaultValue={optShowIndex} onChange={() => this.setState({ optShowIndex: !optShowIndex })} />show index
          <button onClick={() => {
            makePreset();
          }}>run</button>
        </div>
        <canvas id="canvas" width={width} height={height}
          ref={this.canvasRef}
        ></canvas>

        <div className="box">
          <p>doors</p>
          {obstacle_specs.map((o, i) => {
            if (!this.layerState(o.layer).visible) {
              return null;
            }
            if (o.ty !== 'door') {
              return null;
            }
            return <div key={i}>
              #{i} <input type="text" style={{ width: 400 }} defaultValue={o.doorname} onChange={(e) => {
                let doorname = e.target.value;
                this.setState({
                  obstacle_specs: update(obstacle_specs, {
                    [i]: { doorname: { $set: doorname } },
                  }),
                });
              }} />
            </div>;
          })}
        </div>

        <div className="box">
          <p>metadata</p>
          width: <input type="number" value={width} onChange={(e) => this.setState({ world: { width: 0 | e.target.value, height } })} />
          height: <input type="number" value={height} onChange={(e) => this.setState({ world: { width, height: 0 | e.target.value } })} />
          <br />
          x: <input type="number" defaultValue={transposeX} onChange={(e) => this.setState({ transposeX: 0 | e.target.value })} />
          y: <input type="number" defaultValue={transposeY} onChange={(e) => this.setState({ transposeY: 0 | e.target.value })} />
          <button onClick={() => this.transposeAll(transposeX, transposeY)}>transpose</button>
        </div>

        <div className="box">
          <p>save & load</p>

          <p>데이터: 선택/복사해서 저장하세요. 에디터에 불러오려면 붙여넣기하세요.</p>
          <textarea ref={this.textRef} onChange={(e) => {
            const text = this.textRef.current.value;
            this.setState(this.deserialize(text));
          }}></textarea>
          {this.selectTrigger()}
          {this.selectRandomObs()}
          <p>config</p>
          <textarea id={'config'} ref={this.configRef} readOnly></textarea>
          <button onClick={() => {
            const copy = document.getElementById('config');
            copy.select();
            document.execCommand("copy");
          }}> 복사</button>
        </div>
      </div>
    );
  }
}

export class Editor extends React.Component {
  render() {
    const man = `마우스 왼쪽 클릭 드래그: 오브젝트 생성하기 / 옮기기
마우스 휠: 회전
마우스 오름쪽 클릭: 삭제하기, 이동하기 (moveTo 켜짐), 스폰 방향 삭제하기 (alt)
마우스 휠 클릭: 종류 바꾸기
alt-click: 크기 바꾸기
ctrl-click: 복제하기, 선택한 레이어로 복사하기 (moveTo 켜짐)

w: mark wip
spawnarea 1:effect_ty
          2:triggers
          3:team0 spawn`;
    return <>
      <pre>{man}</pre>
      <Canv {...this.props} />
    </>;
  }
}
