import { data as source } from '../downloaded/missionGenerator.mjs';

export const list = [];
for (const row of source) {
  const weights = [];
  for (let i = 0; i < 11; i++) {
    const key = `${i}_missions`;
    weights.push(parseFloat(row[key]));
  }
  list.push(weights);
}

export function getMissionCount(rng, num_subdiv) {
  const weights = list[Math.min(num_subdiv, list.length - 1)];
  return rng.weighted(weights);
}
