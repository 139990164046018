export const colors = ['#673ab7', '#4caf50', '#cddc39', '#ffeb3b', '#ff9800', '#795548', '#9e9e9e', '#2196f3', '#00bcd4'];

export const colorOfDegree = {
  'S': '#ffd600',
  'A': '#d50000',
  'B': '#6200ea',
  'C': '#0091ea',
  'D': '#00c853',
  'F': '#616161'
}
