// downloaded data!
export const data = [
  {
    "title": "Asset Protection"
  },
  {
    "title": "Production Sabotage"
  },
  {
    "title": "Counterfeiting Takedown"
  },
  {
    "title": "Hostage Rescue"
  },
  {
    "title": "Weapon Recovery"
  },
  {
    "title": "Intelligence Gathering"
  },
  {
    "title": "Village Stabilization"
  },
  {
    "title": "Election Security"
  },
  {
    "title": "Cartel Informant Extraction"
  },
  {
    "title": "Counter-Smuggling"
  },
  {
    "title": "Gang Eradication"
  },
  {
    "title": "Arms Deal Interception"
  },
  {
    "title": "Prison Raid"
  },
  {
    "title": "Arms Shipment Interdiction"
  },
  {
    "title": "Pilot Rescue"
  },
  {
    "title": "Pirate Hideout Raid"
  },
  {
    "title": "Paramilitary Suppression"
  },
  {
    "title": "Drug Cartel Raid"
  },
  {
    "title": "VIP Protection: Pre-emptive Strike"
  },
  {
    "title": "Rebel Leader Elimination"
  },
  {
    "title": "Drug Lab Bust"
  },
  {
    "title": "Political Assassination"
  },
  {
    "title": "Oil Pipeline Protection"
  }
];