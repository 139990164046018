import React from 'react';
import { Rng } from './rand.mjs';
import { stats_const, stats_rand } from './stat.mjs';
import { presets } from './presets_mission.mjs';
import {
  tmpl_firearm_ar_low,
  tmpl_firearm_smg_low,
  tmpl_firearm_hostage,
} from './presets_firearm.mjs';
import { entityFromStat } from './stat';
import { SimView } from './SimView';
import { names, names3 } from './names';
import _ from 'lodash';

// LD: 재화를 소모해서 새 임무를 생성할 때, 필요한 재화의 양.
const COST_MISSION_NEW_INTEL = 5;

// LD: 최대 임무 수. 최대 임무 수가 넘어서면 오래된 미션부터 사라집니다.
const MAX_MISSIONS = 5;

// LD: agent의 식량 소모량
//  - 임무에 참여하는 경우, 출발하는 순간 임무 소요 시간만큼의 식량이 소모됩니다.
//  - 임무에 참여하지 않는 경우, 시간이 지날 때 마다 아래 단위의 식량이 소모됩니다.
const COST_FOOD_PER_AGENT = 10;

// 임무에 참여할 때, agent의 수에 따른 추가 연료 소모량입니다.
const COST_MISSION_FUEL_PER_AGENT = 1;

// LD: 임무에 참여하지 않는 agent는 시간마다 아래만큼의 체력을 회복합니다.
const LIFE_RECOVER_PER_HOUR = 1;

// LD: 아래 시간 간격마다 한 번 씩 새 임무가 생성됩니다.
const NEW_INTEL_INTERVAL = 5;

// LD: 시작 에이전트 수.
const INITIAL_AGENTS = 5;

// LD: 임무 완료 시 각 재화가 대략적으로 어느 비율로 생성될지를 정합니다.
const RESOURCE_UNITS = {
  // 탄약. 에이전트가 인게임에서 소모합니다.
  ammo: 50,
  // 식량.
  food: 50,
  // 연료. 임무마다 기본값이 소모되고, 임무에 파견하는 에이전트에 수에 따라 추가로 소모됩니다.
  fuel: 4,
  // 철.
  iron: 1
};

// LD: 시작 재화 량.
const INITIAL_RESOURCES = {
  ammo: 1000,
  food: 1000,
  fuel: 20,
  iron: 5,
};

function createMission(rng) {
  const difficulty = rng.integer(0, 2);
  let distance = difficulty * 10 + rng.integer(0, 10);
  if (distance < 5) {
    distance = 5;
  }

  const threats_min = Math.floor(distance * rng.range(0.3, 0.5));
  const resources_min = Math.floor(distance * rng.range(0.5, 1.5));
  const rescues_max = rng.integer(0, difficulty);

  return {
    ty: 'indoor',
    distance,
    expires_at: 100,

    resource_ty: rng.choice(Object.keys(INITIAL_RESOURCES)),

    cost: {
      fuel: Math.ceil(distance / 2),
      time: Math.ceil(distance / 4),
    },

    intel: {
      threats: [threats_min, Math.ceil(threats_min * 1.5)],
      resources: [resources_min, resources_min * 2],
      rescues: [0, rescues_max],
    },
  };
}

function instantiateMission(rng, mission, names) {
  const { resource_ty, intel } = mission;
  const resources_amount = rng.integer(...intel.resources);

  // distribute resources
  const keys = Object.keys(RESOURCE_UNITS);
  const weights = new Array(keys.length).fill(1);

  // weight resource
  const resource_ty_idx = keys.indexOf(resource_ty);
  weights[resource_ty_idx] *= rng.integer(3, 10);

  const resources = { ...RESOURCE_UNITS };
  for (const key in resources) {
    resources[key] = 0;
  }

  for (let i = 0; i < resources_amount; i++) {
    const idx = rng.weighted(weights);
    resources[keys[idx]] += RESOURCE_UNITS[keys[idx]];
  }

  const rescues_len = rng.integer(...intel.rescues);
  const rescues = [];
  while (rescues.length < rescues_len) {
    const stats = stats_rand(rng);
    stats.name = names.pop();
    rescues.push(stats);
  }

  return {
    ...mission,

    threats: rng.integer(...intel.threats),
    resources,
    rescues,
  };
}

function missionCostFood(mission, agents) {
  return agents.length * mission.cost.time * COST_FOOD_PER_AGENT;
}

function missionCostFuel(mission, agents) {
  return mission.cost.fuel + agents.length * COST_MISSION_FUEL_PER_AGENT;
}

function MissionItem(props) {
  const { readonly, mission, mission_selected, onMissionSelect, onMissionComplete } = props;

  let buttons = null;
  if (!readonly) {
    const selectmsg = mission === mission_selected ? 'unselect' : 'select';
    buttons = <>
      <button onClick={() => onMissionSelect(mission)}>{selectmsg}</button>
      <button onClick={() => onMissionComplete(mission)}>debug complete</button>
    </>;
  }

  return <div className="box">
    <p>임무 환경: {mission.ty}</p>
    <p>거리={mission.distance}km</p>
    <p>주요 자원={mission.resource_ty} 자원량={mission.intel.resources.join("-")}</p>
    <p>위협={mission.intel.threats.join("-")} 구조={mission.intel.rescues.join("-")}</p>
    {buttons}
  </div>;
}

function AgentItem(props) {
  const { agent, agents_selected, readonly, onAgentToggle } = props;

  let buttons = null;
  if (!readonly) {
    const selected = agents_selected.includes(agent);
    const msg = selected ? 'unselect' : 'select';
    buttons = <>
      <button onClick={() => onAgentToggle(agent)}>{msg}</button>
    </>;
  }

  return <div className="box">
    <p>name={agent.name} life={agent.life}/100 {buttons}</p>
  </div>;

    // <span style={{lineBreak: "anywhere"}}>stats={JSON.stringify(agent)}</span>
}


function PlanView(props) {
  const { mission, agents, resources, onMissionQueue } = props;

  let missionview = <p>임무를 선택해주세요</p>;
  if (mission) {
    missionview = <MissionItem mission={mission} readonly/>;
  }

  let agentsview = <p>인원을 선택해주세요</p>;
  if (agents.length > 0) {
    agentsview = agents.map(agent => <AgentItem key={agent.name} agent={agent} readonly/>);
  }

  const canstart = mission && agents.length > 0;

  const time = mission ? mission.cost.time : 0;
  const fuel = mission ? missionCostFuel(mission, agents) : 0;
  const food = mission ? missionCostFood(mission, agents) : 0;
  const ammo = Math.min(agents.length * 200, resources.ammo);

  return <div className="box">
    <p>임무 계획</p>
    {missionview}
    {agentsview}
    <p>연료 사용량: {fuel}</p>
    <p>소요 시간: {time} hours</p>
    <p>식량 사용량: {food}</p>
    <p>탄환 배정: {ammo}</p>
    <button onClick={() => onMissionQueue({mission, agents, cost: { food, time, fuel, ammo }})} disabled={!canstart}>
      임무 출발
    </button>
  </div>;
}

export class ExploreView extends React.Component {
  constructor(props) {
    super(props);

    this.simRef = React.createRef();

    const names = names3.slice();
    _.shuffle(names);

    const rng = new Rng(Rng.randomseed());
    const agents = [];
    while (agents.length < INITIAL_AGENTS) {
      const stats = stats_rand(rng);
      const name = names.pop();

      stats.name = name;
      agents.push({
        name,
        life: 100,
        stats,
      });
    }

    this.state = {
      rng,
      tick: 0,
      resources: { ...INITIAL_RESOURCES },

      journals: [],

      pendings: [{
        ty: 'intel',
        tick: NEW_INTEL_INTERVAL,
      }],

      agents,
      missions: new Array(3).fill(0).map(() => createMission(rng)),

      mission_selected: null,
      agents_selected: [],

      mission_state: null,

      names,
    };
  }

  pushJournal(msg, tick) {
    const { journals } = this.state;
    tick = tick ?? this.state.tick;
    const j = journals.slice();
    j.push({ tick, msg });
    this.setState({ journals: j });
  }

  onWait(hours) {
    const tick = this.state.tick + hours;

    const agents = this.state.agents.slice();
    const food = agents.length * COST_FOOD_PER_AGENT * hours;
    const resources = { ...this.state.resources };

    resources.food -= food;

    this.pushJournal(`${hours} 시간이 경과했습니다. ${agents.length}명의 인원이 ${food}만큼의 식량을 소비했습니다.`, tick);

    for (const agent of agents) {
      const life_next = Math.min(100, agent.life + hours * LIFE_RECOVER_PER_HOUR);
      if (agent.life !== life_next) {
        agent.life = life_next;
        this.pushJournal(`${agent.name}의 체력이 ${agent.life} => ${life_next}로 회복되었습니다.`, tick);
      }
    }

    let mission_state = this.state.mission_state;
    const pendings = this.state.pendings.slice();
    while (pendings.length > 0 && pendings[0].tick <= tick) {
      const ev = pendings.shift();
      if (ev.ty === 'intel') {
        this.pushJournal('새 임무 정보가 도착했습니다.', tick);

        ev.tick = tick + NEW_INTEL_INTERVAL;
        pendings.push(ev);
        pendings.sort((a, b) => a.tick - b.tick);

        this.appendNewMission();
      } else if (ev.ty === 'mission') {
        this.pushJournal('임무를 시작합니다', tick);
        mission_state = ev.mission_state;
      }
    }

    this.setState({
      tick: tick,
      agents,
      resources,

      mission_state,

      pendings,
    });
  }

  onMissionNewIntel() {
    const resources = { ...this.state.resources };
    if (resources.iron < COST_MISSION_NEW_INTEL) {
      return;
    }
    resources.iron -= COST_MISSION_NEW_INTEL;

    this.setState({ resources });
    this.appendNewMission();
  }

  appendNewMission() {
    const { rng } = this.state;
    const missions = this.state.missions.slice();
    missions.push(createMission(rng));
    while (missions.length > MAX_MISSIONS) {
      missions.shift();
    }

    this.setState({ missions });
  }

  onMissionSelect(mission) {
    const { mission_selected } = this.state;
    if (mission === mission_selected) {
      this.setState({ mission_selected: null });
    } else {
      this.setState({ mission_selected: mission });
    }
  }

  onMissionQueue(props) {
    const { rng } = this.state;
    const { mission, agents, cost } = props;
    const names = this.state.names.slice();

    const instantiated = instantiateMission(rng, mission, names);

    let preset = 'indoor';
    if (instantiated.threats > 7) {
      preset = 'indoor2';
    }
    if (instantiated.rescues.length > 0) {
      preset += '_rescue';
    }
    const simstate = presets[preset]();

    const entities = agents.map((agent) => {
      const entity = entityFromStat(agent.stats, tmpl_firearm_ar_low);
      entity.life = agent.life;
      entity.ammo_total = 0;
      entity.allow_fire_control = true;
      if (instantiated.rescues.length > 0) {
        entity.default_rule = 'mission';
      } else {
        entity.default_rule = 'explore';
      }
      return entity;
    });

    let ammo = cost.ammo;

    // distribute ammo
    let idx = 0;
    while (ammo > 0) {
      entities[idx].ammo_total += 1;
      ammo -= 1;
      idx = (idx + 1) % entities.length;
    }

    simstate.entities = entities.slice();

    for (let i = 0; i < instantiated.threats; i++) {
      const stats = stats_const(1);
      stats.name = rng.choice(names);
      const entity = entityFromStat(stats, tmpl_firearm_smg_low);
      entity.spawnarea = 1;
      entity.team = 1;
      entity.default_rule = 'idle';

      simstate.entities.push(entity);
    }

    for (const stats of instantiated.rescues) {
      const entity = entityFromStat(stats, tmpl_firearm_hostage);
      entity.spawnarea = 1;
      entity.team = 2;
      entity.default_rule = 'idle';
      entity.ty = 'vip';

      simstate.entities.push(entity);
    }

    // use resources
    const resources = { ...this.state.resources };
    resources.fuel -= cost.fuel;
    resources.food -= cost.food;
    resources.ammo -= cost.ammo;

    this.pushJournal(`${entities.length}명의 인원이 임무를 출발합니다. ${cost.time} 후 임무를 시작합니다.`);

    let tick = this.state.tick + cost.time;
    while (true) {
      const t = tick;
      if (!this.state.pendings.find((p) => p.tick === t)) {
        break;
      }
      tick += 1;
    }

    const pendings = this.state.pendings.slice();
    pendings.push({
      ty: 'mission',
      tick: this.state.tick + cost.time,
      mission_state: {
        instantiated,
        mission,
        agents,
        simstate,
      },
    });

    pendings.sort((a, b) => a.tick - b.tick);

    this.setState({
      resources,
      pendings,

      missions: this.state.missions.filter((m) => m !== mission),
      agents: this.state.agents.filter((a) => {
        return !agents.includes(a);
      }),

      mission_selected: null,
      agents_selected: [],

      names,
    });
  }

  onMissionComplete(mission) {
    const { rng } = this.state;
    const missions = this.state.missions.filter((m) => m !== mission);
    const resources = { ...this.state.resources };

    const instantiated = instantiateMission(rng, mission);
    console.log(instantiated);

    for (const key in instantiated.resources) {
      resources[key] += instantiated.resources[key];
    }

    this.setState({ missions, resources });
  }

  onMissionComplete2(obj) {
    const { mission_state, ammo_remain } = obj;
    const { sim, instantiated } = mission_state;

    const resources = { ...this.state.resources };

    const agents = this.state.agents.slice();
    for (const agent of mission_state.agents) {
      const entity = sim.entities.find((e) => e.name === agent.name);
      if (!entity) {
        agents.push(agent);
        continue;
      }
      agent.life = entity.life;
      if (agent.life === 0) {
        this.pushJournal(`${agent.name}이(가) 사망했습니다`);
        continue;
      }
      agents.push(agent);
    }

    for (const rescue of instantiated.rescues) {
      console.log(rescue);
      const entity = sim.entities.find((e) => e.name === rescue.name);
      if (entity.team !== 0 || entity.life <= 0) {
        continue;
      }

      this.pushJournal(`${entity.name}이(가) 합류합니다`);
      agents.push({
        name: rescue.name,
        life: entity.life,
        stats: rescue,
      });
    }

    for (const key in instantiated.resources) {
      resources[key] += instantiated.resources[key];
    }
    resources.ammo += ammo_remain;

    this.setState({
      agents: agents.filter((a) => a.life > 0),
      resources,
      mission_state: null,
      mission_selected: null,
      agents_selected: [],
    });
  }

  onAgentToggle(agent) {
    let agents_selected = this.state.agents_selected.slice();
    if (agents_selected.includes(agent)) {
      agents_selected = agents_selected.filter((a) => a !== agent);
    } else {
      agents_selected.push(agent);
    }
    this.setState({ agents_selected });
  }

  onFinish(res) {
    const { mission_state } = this.state;
    const sim = this.simRef.current.state.sim;

    this.setState({
      mission_state: { ...mission_state, sim, res },
    });
  }

  renderMission() {
    const {
      mission_selected,
      agents,
      agents_selected,
      mission_state,
      resources,
    } = this.state;

    if (resources.food < 0) {
      return <h1 style={{color: 'read'}}>insufficient food, game over</h1>;
    }

    if (!mission_state) {
      return <PlanView mission={mission_selected} agents={agents_selected} resources={resources}
        onMissionQueue={this.onMissionQueue.bind(this)}/>;
    }

    if (mission_state.res === undefined) {
      return <SimView ref={this.simRef}
        onFinish={(res) => this.onFinish(res)}
        {...mission_state.simstate}
        />;
    }

    const sim = mission_state.sim;
    let ammo_remain = 0;
    for (const entity of sim.entities) {
      if (entity.team !== 0 || entity.ty === 'vip') {
        continue;
      }
      ammo_remain += entity.ammo_total;
    }

    const life_changes = [];
    for (const agent of agents) {
      const entity = sim.entities.find((e) => e.name === agent.name);
      if (!entity) {
        life_changes.push({
          agent,
          life_before: agent.life,
          life_after: Math.min(100, agent.life + mission_state.mission.cost.time),
        });
      } else {
        life_changes.push({
          agent,
          life_before: agent.life,
          life_after: entity.life,
        });
      }
    }

    return <div className="box">
      res={mission_state.res === 0 ? 'win' : 'lose'}
      <br/>
      resources={JSON.stringify(mission_state.instantiated.resources)}
      <br/>
      미사용 탄약: {ammo_remain}
      <br/>
      체력 변화
      {life_changes
          .filter(({ life_before, life_after }) => life_before !== life_after)
          .map(({ agent, life_before, life_after }) => {
            return <p key={agent.name}>{agent.name} {life_before} {"=>"} {life_after}</p>;
          })
      }
      <br/>
      <button onClick={() => this.onMissionComplete2({ mission_state, ammo_remain })}>complete</button>
    </div>;
  }

  render() {
    const {
      mission_selected,
      agents,
      agents_selected,
      tick,
      resources,
      missions,
      journals,
      pendings,
      mission_state,
    } = this.state;

    const action_disabled = mission_state !== null;

    const j = journals.slice();
    j.reverse();

    const max_wait = pendings[0].tick - tick;

    return <>
      <div className="box">
        <div>
        elapsed={tick} hours
        <br/>

        <button disabled={action_disabled} onClick={() => this.onWait(1)}>wait 1 hour</button>
        <button disabled={action_disabled} onClick={() => this.onWait(max_wait)}>wait {max_wait} hour</button>
        </div>

        <p>resources</p>
        {Object.entries(resources).map(([k, v]) => <p key={k}>{k}: {v}</p>)}
      </div>

      <div className="box">
        upcomings
        {pendings.map((p, i) => {
          return <p key={i}>{p.tick - tick}시간 후: {p.ty}</p>;
        })}
      </div>

      {this.renderMission()}

      <div className="box">
        <p>agents</p>
        {agents.map((a, i) => <AgentItem key={i} agent={a} agents_selected={agents_selected}
          onAgentToggle={(a) => this.onAgentToggle(a)}/>
        )}
      </div>

      <div className="box">
        <p>missions {missions.length}/{MAX_MISSIONS}</p>
        {missions.map((m, i) =>
          <MissionItem mission={m} mission_selected={mission_selected} key={i}
            onMissionSelect={this.onMissionSelect.bind(this)}
            onMissionComplete={this.onMissionComplete.bind(this)}/>)}
        <button onClick={() => this.onMissionNewIntel()}>new intel ({COST_MISSION_NEW_INTEL} irons)</button>
        <br/>
      </div>

      <div>
      <p>journal</p>
      {j.map(({ tick: t, msg }, i) => <p key={i}>{tick - t}시간 전: {msg}</p>)}
      </div>
    </>;
  }
}
