// downloaded data!
export const data = [
  {
    "key": "perk_grenadier_base",
    "dep": "",
    "level": "1",
    "groups": "grenadier_regular,grenadier_chemical",
    "name": "loc_data_string_perk_name_perk_grenadier_base",
    "msg": "loc_data_string_perk_msg_perk_grenadier_base",
    "descr": "loc_data_longtext_perk_descr_perk_grenadier_base",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_grenadier_throw_range",
    "dep": "perk_grenadier_base",
    "level": "3",
    "groups": "grenadier_regular",
    "name": "loc_data_string_perk_name_perk_grenadier_throw_range",
    "msg": "loc_data_string_perk_msg_perk_grenadier_throw_range",
    "descr": "loc_data_longtext_perk_descr_perk_grenadier_throw_range",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_grenadier_amount",
    "dep": "perk_grenadier_throw_range",
    "level": "5",
    "groups": "grenadier_regular",
    "name": "loc_data_string_perk_name_perk_grenadier_amount",
    "msg": "loc_data_string_perk_msg_perk_grenadier_amount",
    "descr": "loc_data_longtext_perk_descr_perk_grenadier_amount",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_grenadier_shorter_blast",
    "dep": "perk_grenadier_amount",
    "level": "7",
    "groups": "grenadier_regular",
    "name": "loc_data_string_perk_name_perk_grenadier_shorter_blast",
    "msg": "loc_data_string_perk_msg_perk_grenadier_shorter_blast",
    "descr": "loc_data_longtext_perk_descr_perk_grenadier_shorter_blast",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_grenadier_special_1",
    "dep": "perk_grenadier_base",
    "level": "3",
    "groups": "grenadier_chemical",
    "name": "loc_data_string_perk_name_perk_grenadier_special_1",
    "msg": "loc_data_string_perk_msg_perk_grenadier_special_1",
    "descr": "loc_data_longtext_perk_descr_perk_grenadier_special_1",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_grenadier_effect_amount",
    "dep": "perk_grenadier_special_1",
    "level": "5",
    "groups": "grenadier_chemical",
    "name": "loc_data_string_perk_name_perk_grenadier_effect_amount",
    "msg": "loc_data_string_perk_msg_perk_grenadier_effect_amount",
    "descr": "loc_data_longtext_perk_descr_perk_grenadier_effect_amount",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_grenadier_special_2",
    "dep": "perk_grenadier_effect_amount",
    "level": "7",
    "groups": "grenadier_chemical",
    "name": "loc_data_string_perk_name_perk_grenadier_special_2",
    "msg": "loc_data_string_perk_msg_perk_grenadier_special_2",
    "descr": "loc_data_longtext_perk_descr_perk_grenadier_special_2",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_desmar_base",
    "dep": "",
    "level": "1",
    "groups": "desmar_defensive,desmar_offensive",
    "name": "loc_data_string_perk_name_perk_desmar_base",
    "msg": "loc_data_string_perk_msg_perk_desmar_base",
    "descr": "loc_data_longtext_perk_descr_perk_desmar_base",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "dmr"
  },
  {
    "key": "perk_desmar_priority_defensive",
    "dep": "perk_desmar_base",
    "level": "3",
    "groups": "desmar_defensive",
    "name": "loc_data_string_perk_name_perk_desmar_priority_defensive",
    "msg": "loc_data_string_perk_msg_perk_desmar_priority_defensive",
    "descr": "loc_data_longtext_perk_descr_perk_desmar_priority_defensive",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_desmar_distraction",
    "dep": "perk_desmar_priority_defensive",
    "level": "5",
    "groups": "desmar_defensive",
    "name": "loc_data_string_perk_name_perk_desmar_distraction",
    "msg": "loc_data_string_perk_msg_perk_desmar_distraction",
    "descr": "loc_data_longtext_perk_descr_perk_desmar_distraction",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "dmr"
  },
  {
    "key": "perk_desmar_distraction_range",
    "dep": "perk_desmar_distraction",
    "level": "7",
    "groups": "desmar_defensive",
    "name": "loc_data_string_perk_name_perk_desmar_distraction_range",
    "msg": "loc_data_string_perk_msg_perk_desmar_distraction_range",
    "descr": "loc_data_longtext_perk_descr_perk_desmar_distraction_range",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "dmr"
  },
  {
    "key": "perk_desmar_priority_offensive",
    "dep": "perk_desmar_base",
    "level": "3",
    "groups": "desmar_offensive",
    "name": "loc_data_string_perk_name_perk_desmar_priority_offensive",
    "msg": "loc_data_string_perk_msg_perk_desmar_priority_offensive",
    "descr": "loc_data_longtext_perk_descr_perk_desmar_priority_offensive",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_desmar_damage",
    "dep": "perk_desmar_priority_offensive",
    "level": "5",
    "groups": "desmar_offensive",
    "name": "loc_data_string_perk_name_perk_desmar_damage",
    "msg": "loc_data_string_perk_msg_perk_desmar_damage",
    "descr": "loc_data_longtext_perk_descr_perk_desmar_damage",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "dmr"
  },
  {
    "key": "perk_desmar_aimspeed",
    "dep": "perk_desmar_damage",
    "level": "7",
    "groups": "desmar_offensive",
    "name": "loc_data_string_perk_name_perk_desmar_aimspeed",
    "msg": "loc_data_string_perk_msg_perk_desmar_aimspeed",
    "descr": "loc_data_longtext_perk_descr_perk_desmar_aimspeed",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "dmr"
  },
  {
    "key": "perk_commed_base",
    "dep": "",
    "level": "1",
    "groups": "commed_regular,commed_unlicensed",
    "name": "loc_data_string_perk_name_perk_commed_base",
    "msg": "loc_data_string_perk_msg_perk_commed_base",
    "descr": "loc_data_longtext_perk_descr_perk_commed_base",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_commed_workspeed",
    "dep": "perk_commed_base",
    "level": "3",
    "groups": "commed_regular",
    "name": "loc_data_string_perk_name_perk_commed_workspeed",
    "msg": "loc_data_string_perk_msg_perk_commed_workspeed",
    "descr": "loc_data_longtext_perk_descr_perk_commed_workspeed",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_commed_count",
    "dep": "perk_commed_workspeed",
    "level": "5",
    "groups": "commed_regular",
    "name": "loc_data_string_perk_name_perk_commed_count",
    "msg": "loc_data_string_perk_msg_perk_commed_count",
    "descr": "loc_data_longtext_perk_descr_perk_commed_count",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_commed_amount",
    "dep": "perk_commed_count",
    "level": "7",
    "groups": "commed_regular",
    "name": "loc_data_string_perk_name_perk_commed_amount",
    "msg": "loc_data_string_perk_msg_perk_commed_amount",
    "descr": "loc_data_longtext_perk_descr_perk_commed_amount",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_commed_target_self",
    "dep": "perk_commed_base",
    "level": "3",
    "groups": "commed_unlicensed",
    "name": "loc_data_string_perk_name_perk_commed_target_self",
    "msg": "loc_data_string_perk_msg_perk_commed_target_self",
    "descr": "loc_data_longtext_perk_descr_perk_commed_target_self",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_commed_risk",
    "dep": "perk_commed_target_self",
    "level": "5",
    "groups": "commed_unlicensed",
    "name": "loc_data_string_perk_name_perk_commed_risk",
    "msg": "loc_data_string_perk_msg_perk_commed_risk",
    "descr": "loc_data_longtext_perk_descr_perk_commed_risk",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_commed_buff",
    "dep": "perk_commed_risk",
    "level": "7",
    "groups": "commed_unlicensed",
    "name": "loc_data_string_perk_name_perk_commed_buff",
    "msg": "loc_data_string_perk_msg_perk_commed_buff",
    "descr": "loc_data_longtext_perk_descr_perk_commed_buff",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_hit_antiarmor",
    "dep": "",
    "level": "5",
    "groups": "common",
    "name": "loc_data_string_perk_name_perk_hit_antiarmor",
    "msg": "loc_data_string_perk_msg_perk_hit_antiarmor",
    "descr": "loc_data_longtext_perk_descr_perk_hit_antiarmor",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_movespeed",
    "dep": "",
    "level": "5",
    "groups": "common",
    "name": "loc_data_string_perk_name_perk_movespeed",
    "msg": "loc_data_string_perk_msg_perk_movespeed",
    "descr": "loc_data_longtext_perk_descr_perk_movespeed",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_aimvar_incr_m10",
    "dep": "",
    "level": "6",
    "groups": "common",
    "name": "loc_data_string_perk_name_perk_aimvar_incr_m10",
    "msg": "loc_data_string_perk_msg_perk_aimvar_incr_m10",
    "descr": "loc_data_longtext_perk_descr_perk_aimvar_incr_m10",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_outgame_training_effect",
    "dep": "",
    "level": "8",
    "groups": "common",
    "name": "loc_data_string_perk_name_perk_outgame_training_effect",
    "msg": "loc_data_string_perk_msg_perk_outgame_training_effect",
    "descr": "loc_data_longtext_perk_descr_perk_outgame_training_effect",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_hit_incr_aimvar",
    "dep": "",
    "level": "1",
    "groups": "social",
    "name": "loc_data_string_perk_name_perk_hit_incr_aimvar",
    "msg": "loc_data_string_perk_msg_perk_hit_incr_aimvar",
    "descr": "loc_data_longtext_perk_descr_perk_hit_incr_aimvar",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_outgame_monthly_recover",
    "dep": "",
    "level": "3",
    "groups": "common",
    "name": "loc_data_string_perk_name_perk_outgame_monthly_recover",
    "msg": "loc_data_string_perk_msg_perk_outgame_monthly_recover",
    "descr": "loc_data_longtext_perk_descr_perk_outgame_monthly_recover",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_reduce_cover_effect",
    "dep": "",
    "level": "4",
    "groups": "common",
    "name": "loc_data_string_perk_name_perk_reduce_cover_effect",
    "msg": "loc_data_string_perk_msg_perk_reduce_cover_effect",
    "descr": "loc_data_longtext_perk_descr_perk_reduce_cover_effect",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_cover_reload_evade",
    "dep": "",
    "level": "4",
    "groups": "common",
    "name": "loc_data_string_perk_name_perk_cover_reload_evade",
    "msg": "loc_data_string_perk_msg_perk_cover_reload_evade",
    "descr": "loc_data_longtext_perk_descr_perk_cover_reload_evade",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_cover_effect",
    "dep": "",
    "level": "1",
    "groups": "common",
    "name": "loc_data_string_perk_name_perk_cover_effect",
    "msg": "loc_data_string_perk_msg_perk_cover_effect",
    "descr": "loc_data_longtext_perk_descr_perk_cover_effect",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_armor_effect",
    "dep": "",
    "level": "1",
    "groups": "common",
    "name": "loc_data_string_perk_name_perk_armor_effect",
    "msg": "loc_data_string_perk_msg_perk_armor_effect",
    "descr": "loc_data_longtext_perk_descr_perk_armor_effect",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_cover_reload",
    "dep": "",
    "level": "2",
    "groups": "common",
    "name": "loc_data_string_perk_name_perk_cover_reload",
    "msg": "loc_data_string_perk_msg_perk_cover_reload",
    "descr": "loc_data_longtext_perk_descr_perk_cover_reload",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_outgame_recover_amount",
    "dep": "",
    "level": "6",
    "groups": "common",
    "name": "loc_data_string_perk_name_perk_outgame_recover_amount",
    "msg": "loc_data_string_perk_msg_perk_outgame_recover_amount",
    "descr": "loc_data_longtext_perk_descr_perk_outgame_recover_amount",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_cover_steady",
    "dep": "",
    "level": "7",
    "groups": "common",
    "name": "loc_data_string_perk_name_perk_cover_steady",
    "msg": "loc_data_string_perk_msg_perk_cover_steady",
    "descr": "loc_data_longtext_perk_descr_perk_cover_steady",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_outgame_mission_days",
    "dep": "",
    "level": "7",
    "groups": "common",
    "name": "loc_data_string_perk_name_perk_outgame_mission_days",
    "msg": "loc_data_string_perk_msg_perk_outgame_mission_days",
    "descr": "loc_data_longtext_perk_descr_perk_outgame_mission_days",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_aim_execute",
    "dep": "",
    "level": "8",
    "groups": "common",
    "name": "loc_data_string_perk_name_perk_aim_execute",
    "msg": "loc_data_string_perk_msg_perk_aim_execute",
    "descr": "loc_data_longtext_perk_descr_perk_aim_execute",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_piercing_bullet",
    "dep": "",
    "level": "2",
    "groups": "common",
    "name": "loc_data_string_perk_name_perk_piercing_bullet",
    "msg": "loc_data_string_perk_msg_perk_piercing_bullet",
    "descr": "loc_data_longtext_perk_descr_perk_piercing_bullet",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_aimvar_max_10",
    "dep": "",
    "level": "1",
    "groups": "decision",
    "name": "loc_data_string_perk_name_perk_aimvar_max_10",
    "msg": "loc_data_string_perk_msg_perk_aimvar_max_10",
    "descr": "loc_data_longtext_perk_descr_perk_aimvar_max_10",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_aimvar_10",
    "dep": "",
    "level": "3",
    "groups": "decision",
    "name": "loc_data_string_perk_name_perk_aimvar_10",
    "msg": "loc_data_string_perk_msg_perk_aimvar_10",
    "descr": "loc_data_longtext_perk_descr_perk_aimvar_10",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_aim_together",
    "dep": "",
    "level": "6",
    "groups": "decision",
    "name": "loc_data_string_perk_name_perk_aim_together",
    "msg": "loc_data_string_perk_msg_perk_aim_together",
    "descr": "loc_data_longtext_perk_descr_perk_aim_together",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_cover_dash",
    "dep": "",
    "level": "5",
    "groups": "bravery",
    "name": "loc_data_string_perk_name_perk_cover_dash",
    "msg": "loc_data_string_perk_msg_perk_cover_dash",
    "descr": "loc_data_longtext_perk_descr_perk_cover_dash",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_suppress_m10",
    "dep": "",
    "level": "4",
    "groups": "bravery",
    "name": "loc_data_string_perk_name_perk_suppress_m10",
    "msg": "loc_data_string_perk_msg_perk_suppress_m10",
    "descr": "loc_data_longtext_perk_descr_perk_suppress_m10",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_engage_dash",
    "dep": "",
    "level": "1",
    "groups": "bravery",
    "name": "loc_data_string_perk_name_perk_engage_dash",
    "msg": "loc_data_string_perk_msg_perk_engage_dash",
    "descr": "loc_data_longtext_perk_descr_perk_engage_dash",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_pierce_moving_enemy",
    "dep": "",
    "level": "7",
    "groups": "toughness",
    "name": "loc_data_string_perk_name_perk_pierce_moving_enemy",
    "msg": "loc_data_string_perk_msg_perk_pierce_moving_enemy",
    "descr": "loc_data_longtext_perk_descr_perk_pierce_moving_enemy",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_stationary_aimspeed",
    "dep": "",
    "level": "6",
    "groups": "focus",
    "name": "loc_data_string_perk_name_perk_stationary_aimspeed",
    "msg": "loc_data_string_perk_msg_perk_stationary_aimspeed",
    "descr": "loc_data_longtext_perk_descr_perk_stationary_aimspeed",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_lastshoot",
    "dep": "",
    "level": "7",
    "groups": "focus",
    "name": "loc_data_string_perk_name_perk_lastshoot",
    "msg": "loc_data_string_perk_msg_perk_lastshoot",
    "descr": "loc_data_longtext_perk_descr_perk_lastshoot",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_move_cover",
    "dep": "",
    "level": "3",
    "groups": "reaction",
    "name": "loc_data_string_perk_name_perk_move_cover",
    "msg": "loc_data_string_perk_msg_perk_move_cover",
    "descr": "loc_data_longtext_perk_descr_perk_move_cover",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_glancing_blow",
    "dep": "",
    "level": "4",
    "groups": "reaction",
    "name": "loc_data_string_perk_name_perk_glancing_blow",
    "msg": "loc_data_string_perk_msg_perk_glancing_blow",
    "descr": "loc_data_longtext_perk_descr_perk_glancing_blow",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_standing_evade",
    "dep": "",
    "level": "1",
    "groups": "toughness",
    "name": "loc_data_string_perk_name_perk_standing_evade",
    "msg": "loc_data_string_perk_msg_perk_standing_evade",
    "descr": "loc_data_longtext_perk_descr_perk_standing_evade",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_healed_amount_15",
    "dep": "",
    "level": "7",
    "groups": "reaction",
    "name": "loc_data_string_perk_name_perk_healed_amount_15",
    "msg": "loc_data_string_perk_msg_perk_healed_amount_15",
    "descr": "loc_data_longtext_perk_descr_perk_healed_amount_15",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_recover_life",
    "dep": "",
    "level": "2",
    "groups": "toughness",
    "name": "loc_data_string_perk_name_perk_recover_life",
    "msg": "loc_data_string_perk_msg_perk_recover_life",
    "descr": "loc_data_longtext_perk_descr_perk_recover_life",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_armor_first",
    "dep": "",
    "level": "2",
    "groups": "focus",
    "name": "loc_data_string_perk_name_perk_armor_first",
    "msg": "loc_data_string_perk_msg_perk_armor_first",
    "descr": "loc_data_longtext_perk_descr_perk_armor_first",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_critical_standing",
    "dep": "",
    "level": "3",
    "groups": "precision",
    "name": "loc_data_string_perk_name_perk_critical_standing",
    "msg": "loc_data_string_perk_msg_perk_critical_standing",
    "descr": "loc_data_longtext_perk_descr_perk_critical_standing",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_critical_move_crawl",
    "dep": "",
    "level": "5",
    "groups": "precision",
    "name": "loc_data_string_perk_name_perk_critical_move_crawl",
    "msg": "loc_data_string_perk_msg_perk_critical_move_crawl",
    "descr": "loc_data_longtext_perk_descr_perk_critical_move_crawl",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_critical_add",
    "dep": "",
    "level": "6",
    "groups": "precision",
    "name": "loc_data_string_perk_name_perk_critical_add",
    "msg": "loc_data_string_perk_msg_perk_critical_add",
    "descr": "loc_data_longtext_perk_descr_perk_critical_add",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_sameperk_stats",
    "dep": "",
    "level": "3",
    "groups": "common",
    "name": "loc_data_string_perk_name_perk_sameperk_stats",
    "msg": "loc_data_string_perk_msg_perk_sameperk_stats",
    "descr": "loc_data_longtext_perk_descr_perk_sameperk_stats",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_reduce_stat_penalty",
    "dep": "",
    "level": "3",
    "groups": "social",
    "name": "loc_data_string_perk_name_perk_reduce_stat_penalty",
    "msg": "loc_data_string_perk_msg_perk_reduce_stat_penalty",
    "descr": "loc_data_longtext_perk_descr_perk_reduce_stat_penalty",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_outgame_renown_amount",
    "dep": "",
    "level": "7",
    "groups": "social",
    "name": "loc_data_string_perk_name_perk_outgame_renown_amount",
    "msg": "loc_data_string_perk_msg_perk_outgame_renown_amount",
    "descr": "loc_data_longtext_perk_descr_perk_outgame_renown_amount",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_outgame_alone_reward",
    "dep": "",
    "level": "2",
    "groups": "antisocial",
    "name": "loc_data_string_perk_name_perk_outgame_alone_reward",
    "msg": "loc_data_string_perk_msg_perk_outgame_alone_reward",
    "descr": "loc_data_longtext_perk_descr_perk_outgame_alone_reward",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_outgame_reduce_negative_renown",
    "dep": "",
    "level": "4",
    "groups": "antisocial",
    "name": "loc_data_string_perk_name_perk_outgame_reduce_negative_renown",
    "msg": "loc_data_string_perk_msg_perk_outgame_reduce_negative_renown",
    "descr": "loc_data_longtext_perk_descr_perk_outgame_reduce_negative_renown",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  },
  {
    "key": "perk_alone_heal",
    "dep": "",
    "level": "6",
    "groups": "antisocial",
    "name": "loc_data_string_perk_name_perk_alone_heal",
    "msg": "loc_data_string_perk_msg_perk_alone_heal",
    "descr": "loc_data_longtext_perk_descr_perk_alone_heal",
    "cost": "1",
    "term": "1",
    "available_firearm_ty": "all"
  }
];