import React from 'react';
import './SimOverlay.css';
import { IngameEntity } from './FigmaView.js';

export class SimOverlay extends React.Component {
  render() {
    const { data } = this.props;
    if (!data) {
      return null;
    }

    const { entities } = data;
    let rows = [];
    for (const entity of entities) {
      const { team } = entity;
      if (team === 1) {
        continue;
      }
      rows.push(<IngameEntity agent={entity} simple={true} state={true} selected={false} isLeader={false} />);
    }

    return <div className="simoverlay-root">
      {rows}
    </div>;
  }
}
