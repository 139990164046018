// downloaded data!
export const data = [
  {
    "key": "loc_ui_title_menu_contract",
    "_item": "의뢰 메뉴 명칭",
    "_comment": "",
    "ko-KR": "의뢰",
    "en-US": "Contracts"
  },
  {
    "key": "loc_ui_string_contract_list",
    "_item": "의뢰 목록 명칭",
    "_comment": "",
    "ko-KR": "의뢰 목록",
    "en-US": "Contract List"
  },
  {
    "key": "loc_ui_longtext_contract_milestone_renown",
    "_item": "의뢰 - 마일스톤 의뢰 명성 안내문",
    "_comment": "",
    "ko-KR": "명성을 올려야 합니다: {{renown.point}}/{{mission.milestone_renown}}",
    "en-US": "Need more Reputation: {{renown.point}}/{{mission.milestone_renown}}"
  },
  {
    "key": "loc_ui_string_contract_terrain",
    "_item": "의뢰 - 지형 명칭",
    "_comment": "",
    "ko-KR": "지형",
    "en-US": "Terrain"
  },
  {
    "key": "loc_ui_longtext_contract_terrain",
    "_item": "의뢰 - 지형 툴팁",
    "_comment": "",
    "ko-KR": "의뢰가 진행될 곳의 대략적인 지형지물의 패턴입니다.\n지형에 배치된 장애물, 건물, 혹은 교전 거리에 따라서 요원의 특성과 총기의 유불리가 달라집니다.\n장애물과 건물이 많거나 하는 이유로 짧은 거리에서 교전이 이루어지는 지형에서는 회전이 빠른 기관단총이나 근거리 화력이 탁월한 산탄총이 유리하며,\n우리 요원이 적의 화력에 필연적으로 노출되기 때문에 요원을 보호할 수단을 고려해야 합니다.\n반대로 교전 거리가 길어질 수 있는 지형에서는 사거리가 긴 무기를 운용하는 요원을 투입해 일방적으로 피해를 강요할 수 있습니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_string_contract_terrain_hatchery_010",
    "_item": "의뢰 - 지형 값: hatchery(variation)",
    "_comment": "terrain은 missions에 존재하는 기준으로 작업했습니다",
    "ko-KR": "담수 양식장",
    "en-US": "Fish Pond Farms"
  },
  {
    "key": "loc_data_string_contract_terrain_hatchery_020",
    "_item": "의뢰 - 지형 값: hatchery(variation)",
    "_comment": "",
    "ko-KR": "담수 양식장",
    "en-US": "Fish Pond Farms"
  },
  {
    "key": "loc_data_string_contract_terrain_hatchery_030",
    "_item": "의뢰 - 지형 값: hatchery(variation)",
    "_comment": "",
    "ko-KR": "담수 양식장",
    "en-US": "Fish Pond Farms"
  },
  {
    "key": "loc_data_string_contract_terrain_hatchery_031",
    "_item": "의뢰 - 지형 값: hatchery(variation)",
    "_comment": "",
    "ko-KR": "담수 양식장",
    "en-US": "Fish Pond Farms"
  },
  {
    "key": "loc_data_string_contract_terrain_hatchery_120",
    "_item": "의뢰 - 지형 값: hatchery(variation)",
    "_comment": "",
    "ko-KR": "담수 양식장",
    "en-US": "Fish Pond Farms"
  },
  {
    "key": "loc_data_string_contract_terrain_hatchery_130",
    "_item": "의뢰 - 지형 값: hatchery(variation)",
    "_comment": "",
    "ko-KR": "담수 양식장",
    "en-US": "Fish Pond Farms"
  },
  {
    "key": "loc_data_string_contract_terrain_hatchery_210",
    "_item": "의뢰 - 지형 값: hatchery(variation)",
    "_comment": "",
    "ko-KR": "담수 양식장",
    "en-US": "Fish Pond Farms"
  },
  {
    "key": "loc_data_string_contract_terrain_hatchery_220",
    "_item": "의뢰 - 지형 값: hatchery(variation)",
    "_comment": "",
    "ko-KR": "담수 양식장",
    "en-US": "Fish Pond Farms"
  },
  {
    "key": "loc_data_string_contract_terrain_hatchery_230",
    "_item": "의뢰 - 지형 값: hatchery(variation)",
    "_comment": "",
    "ko-KR": "담수 양식장",
    "en-US": "Fish Pond Farms"
  },
  {
    "key": "loc_data_string_contract_terrain_maze",
    "_item": "의뢰 - 지형 값: maze",
    "_comment": "",
    "ko-KR": "정글",
    "en-US": "Jungle"
  },
  {
    "key": "loc_data_string_contract_terrain_outdoor",
    "_item": "의뢰 - 지형 값: outdoor",
    "_comment": "",
    "ko-KR": "초원",
    "en-US": "Grassland"
  },
  {
    "key": "loc_data_string_contract_terrain_indoor",
    "_item": "의뢰 - 지형 값: indoor",
    "_comment": "",
    "ko-KR": "은신처",
    "en-US": "Hideout"
  },
  {
    "key": "loc_data_string_contract_terrain_indoor2",
    "_item": "의뢰 - 지형 값: indoor2",
    "_comment": "",
    "ko-KR": "안전가옥",
    "en-US": "Safehouse"
  },
  {
    "key": "loc_data_string_contract_terrain_embassy_short",
    "_item": "의뢰 - 지형 값: embassy_short",
    "_comment": "",
    "ko-KR": "고급 주택지",
    "en-US": "Mansion Streets"
  },
  {
    "key": "loc_data_string_contract_terrain_storehouse_short",
    "_item": "의뢰 - 지형 값: storehouse_short",
    "_comment": "",
    "ko-KR": "물류창고",
    "en-US": "Storehouse"
  },
  {
    "key": "loc_data_string_contract_terrain_border_checkpoint_short",
    "_item": "의뢰 - 지형 값: border_checkpoint_short",
    "_comment": "",
    "ko-KR": "요새화된 도로",
    "en-US": "Fortified Highway"
  },
  {
    "key": "loc_ui_string_contract_difficulty",
    "_item": "의뢰 - 난이도 명칭",
    "_comment": "",
    "ko-KR": "난이도",
    "en-US": "Difficulty"
  },
  {
    "key": "loc_ui_longtext_contract_difficulty",
    "_item": "의뢰 - 난이도 툴팁",
    "_comment": "",
    "ko-KR": "임무에서 적의 훈련, 무장 수준, 수를 고려한 위협 수준입니다.",
    "en-US": "Difficulty considering enemy training, equipments, and number in the mission."
  },
  {
    "key": "loc_dynamic_string_contract_difficulty",
    "_item": "의뢰 - 난이도 값에서 사용할 단위",
    "_comment": "",
    "ko-KR": "💀",
    "en-US": "💀"
  },
  {
    "key": "loc_dynamic_longtext_contract_difficulty",
    "_item": "의뢰 - 난이도 값 툴팁",
    "_comment": "",
    "ko-KR": "%% TODO %%",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_ui_string_contract_duration",
    "_item": "의뢰 - 수행 기간 명칭",
    "_comment": "",
    "ko-KR": "수행 기간",
    "en-US": "Dispatch Duration"
  },
  {
    "key": "loc_ui_longtext_contract_duration",
    "_item": "의뢰 - 수행 기간 툴팁",
    "_comment": "",
    "ko-KR": "임무 수행에 소요되는 시간입니다. 투입한 용병은 이 기간 동안 다른 활동을 할 수 없습니다. 거점과의 거리가 멀수록 길어집니다.",
    "en-US": "The amount of time spent on the mission. Your agents will not be able to perform any other activities during this time. The longer the distance from your base, the longer it will be."
  },
  {
    "key": "loc_dynamic_string_common_duration_days_one",
    "_item": "의뢰 - 수행 기간 값 + 단위\n요원 - 모디파이어 남은 기간 값 + 단위",
    "_comment": "",
    "ko-KR": "",
    "en-US": "a day"
  },
  {
    "key": "loc_dynamic_string_common_duration_days_other",
    "_item": "의뢰 - 수행 기간 값 + 단위\n요원 - 모디파이어 남은 기간 값 + 단위",
    "_comment": "",
    "ko-KR": "{{count}}일",
    "en-US": "{{count}} days"
  },
  {
    "key": "loc_dynamic_string_contract_expiration_days_one",
    "_item": "의뢰 - 만료까지 남은 기간",
    "_comment": "",
    "ko-KR": "",
    "en-US": "Expires in a day"
  },
  {
    "key": "loc_dynamic_string_contract_expiration_days_other",
    "_item": "의뢰 - 만료까지 남은 기간",
    "_comment": "",
    "ko-KR": "의뢰 만료까지 {{count}}일",
    "en-US": "Expires in {{count}} days"
  },
  {
    "key": "loc_ui_string_contract_reward_renown",
    "_item": "의뢰 - 의뢰 보상 명성 획득량 명칭",
    "_comment": "",
    "ko-KR": "명성 획득",
    "en-US": "Reputation Gain"
  },
  {
    "key": "loc_ui_string_contract_reward_resource",
    "_item": "의뢰 - 의뢰 보상 현금 획득량 명칭",
    "_comment": "",
    "ko-KR": "보상",
    "en-US": "Reward"
  },
  {
    "key": "loc_ui_title_menu_contract_overview",
    "_item": "의뢰 개요 메뉴 명칭",
    "_comment": "",
    "ko-KR": "의뢰 개요",
    "en-US": "Contract Overview"
  },
  {
    "key": "loc_ui_string_contract_selected_agents_one",
    "_item": "의뢰 개요 - 투입할 요원 수 표시",
    "_comment": "",
    "ko-KR": "",
    "en-US": "{{count}} selected Agent"
  },
  {
    "key": "loc_ui_string_contract_selected_agents_other",
    "_item": "의뢰 개요 - 투입할 요원 수 표시",
    "_comment": "",
    "ko-KR": "투입할 요원 {{count}}명",
    "en-US": "{{count}} selected Agents"
  },
  {
    "key": "loc_ui_string_contract_ongoing_contracts_one",
    "_item": "의뢰 개요 - 진행 중인 의뢰 수 표시",
    "_comment": "",
    "ko-KR": "",
    "en-US": "{{count}}/{{max}} ongoing Contract"
  },
  {
    "key": "loc_ui_string_contract_ongoing_contracts_other",
    "_item": "의뢰 개요 - 진행 중인 의뢰 수 표시",
    "_comment": "",
    "ko-KR": "진행중인 의뢰: {{count}}/{{max}}",
    "en-US": "{{count}}/{{max}} ongoing Contracts"
  },
  {
    "key": "loc_dynamic_string_agent_level",
    "_item": "의뢰 개요 - 요원 레벨 값 + 단위",
    "_comment": "",
    "ko-KR": "Lv. {{level}}",
    "en-US": "Lv. {{level}}"
  },
  {
    "key": "loc_ui_string_agent_name",
    "_item": "훈련 보고서 - 요원 이름 명칭",
    "_comment": "",
    "ko-KR": "이름",
    "en-US": "Name"
  },
  {
    "key": "loc_ui_string_agent_overall",
    "_item": "의뢰 개요 - 요원 전투력 명칭\n요원 목록 - 요원 전투력 명칭\n요원 상세 - 요원 전투력 명칭\n영입 목록 - 요원 전투력 명칭\n훈련 보고서 - 요원 전투력 명칭",
    "_comment": "",
    "ko-KR": "전투력",
    "en-US": "Overall"
  },
  {
    "key": "loc_ui_longtext_agent_overall",
    "_item": "의뢰 개요 - 요원 전투력 툴팁",
    "_comment": "",
    "ko-KR": "요원이 얼마나 전투에 능숙한지를 종합적으로 표현하는 수치입니다.\n전투력은 판단력, 용감함, 집중력, 반응속도, 강인함, 정교함의 평균치이며, 최대치는 20입니다.\n초반에는 전투력이 잘 실감되지 않겠지만 게임을 진행함에 따라\n임무에서 전투력이 높은 요원과 낮은 요원의 성과 차이를 느끼게 될 것입니다.\n부상을 당하거나 상태 변화에 의해 일시적으로 전투력이 변화할 수 있습니다.",
    "en-US": "The representation of how skilled an agent is in combat.\nOverall is an average of Decision, Bravery, Focus, Reaction, Toughness, and Precision, with a maximum of 20.\nYou'll notice the difference in performance between agents with high and low overalls in missions.\nInjuries and status changes can temporarily alter this."
  },
  {
    "key": "loc_ui_string_agent_life",
    "_item": "의뢰 개요 - 요원 생명력 명칭\n요원 목록 - 요원 생명력 명칭\n요원 상세 - 요원 생명력 명칭\n영입 목록 - 요원 생명력 명칭\n훈련 보고서 - 요원 생명력 명칭",
    "_comment": "생명력 값은 컬러 코드도 들어가고 해서 괜히 로컬라이제이션에 안 넣는게 나은가?",
    "ko-KR": "생명력",
    "en-US": "Life"
  },
  {
    "key": "loc_ui_longtext_agent_life",
    "_item": "의뢰 개요 - 요원 생명력 툴팁",
    "_comment": "",
    "ko-KR": "생명력은 요원이 전투에서 피해를 견딜수 있는 양입니다.\r\n강인함 수치가 오르면 최대 생명력도 높아집니다.",
    "en-US": "The amount of how much damage agent can endure in mission.\nIncreases when agent's toughness increases."
  },
  {
    "key": "loc_ui_string_agent_stamina",
    "_item": "의뢰 개요 - 요원 스태미나 명칭\n요원 목록 - 요원 스태미나 명칭\n요원 상세 - 요원 스태미나 명칭\n영입 목록 - 요원 스태미나 명칭",
    "_comment": "",
    "ko-KR": "스태미나",
    "en-US": "Stamina"
  },
  {
    "key": "loc_ui_longtext_agent_stamina",
    "_item": "의뢰 개요 - 요원 스태미나 툴팁",
    "_comment": "",
    "ko-KR": "스태미나는 요원이 임무에 투입될 때 소모하는 자원입니다.\n임무에 투입시 1씩 소모되며, 시간이 지나면 회복됩니다.",
    "en-US": "The resource of an agent used when deployed to the mission.\nUse 1 stamina per mission, recovers over time."
  },
  {
    "key": "loc_dynamic_string_agent_stamina",
    "_item": "의뢰 개요 - 요원 스태미나 월간 회복량 값",
    "_comment": "",
    "ko-KR": "매월 +{{value}}",
    "en-US": "`+{{value}} per month"
  },
  {
    "key": "loc_ui_string_agent_contract_type",
    "_item": "의뢰 개요 - 요원 계약 형태 명칭\n요원 상세 계약 - 계약 형태 항목\n영입 상세 개요 - 계약 형태 항목",
    "_comment": "",
    "ko-KR": "계약 형태",
    "en-US": "Contract Type"
  },
  {
    "key": "loc_ui_longtext_agent_contract_type",
    "_item": "의뢰 개요 - 요원 계약 형태 툴팁",
    "_comment": "",
    "ko-KR": "이 요원이 회사와 맺고 있는 계약 형태를 보여줍니다.\n\n[표준 계약]\n표준 계약 형태로 계약한 용병은 계약 기간동안 의뢰의 성패와 상관없이 일정한 기본급을 받습니다.\n\n[인센티브 계약]\n인센티브 계약은 성과를 기반으로 한 계약입니다.\n표준 계약의 40% 수준의 기본급을 받지만 의뢰 투입시 기본급만큼의 임무당 수당을 받아갑니다.\n또한 의뢰를 성공할 때마다 의뢰 수익의 일부분을 가져가며, 가져가는 양은 수익 배분율에 따라 정해집니다.\n\n재계약을 할 때마다 용병이 요구하는 기본급과 수익 배분율은 점차 높아집니다.",
    "en-US": "The type of contract this agent has with the company.\n\n[Standard Contract]\nAn agent contracted under the standard contract form receives a fixed monthly salary for the duration of the contract, regardless of the success or failure of the assignment.\n\n[Incentive contract]\nAn incentive contract is a performance-based contract.\nAgent with incentive contract receive a monthly salary of 40% of the standard contract, but you also receive a pay per mission equal to your monthly salary when you enter a job.\nFor each successful job, the agent also take a portion of the job's revenue, the amount of which is determined by revenue share rate.\n\nWith each renewal, the monthly salary and revenue share rate demanded by the agent gradually increases."
  },
  {
    "key": "loc_ui_string_agent_contract_type_standard",
    "_item": "의뢰 개요 - 요원 계약 형태 값(표준)\n요원 - 계약 형태(표준)",
    "_comment": "",
    "ko-KR": "표준",
    "en-US": "Standard"
  },
  {
    "key": "loc_ui_string_agent_contract_type_incentive",
    "_item": "의뢰 개요 - 요원 계약 형태 값(인센티브)\n요원 - 계약 형태(인센티브)",
    "_comment": "",
    "ko-KR": "인센티브",
    "en-US": "Incentive"
  },
  {
    "key": "loc_ui_string_common_firearm",
    "_item": "의뢰 개요 - 총기 명칭\n시장 - 총기 명칭",
    "_comment": "",
    "ko-KR": "총기",
    "en-US": "Firearm"
  },
  {
    "key": "loc_ui_string_common_firearm_full",
    "_item": "요원 - 장비 메뉴 - 총기 명칭",
    "_comment": "",
    "ko-KR": "총기",
    "en-US": "Firearm"
  },
  {
    "key": "loc_ui_longtext_agent_firearm_disabled",
    "_item": "의뢰 개요 - 총기 변경 불가 사유 툴팁",
    "_comment": "",
    "ko-KR": "교체할 수 있는 장비가 없습니다.",
    "en-US": "There are no equipments to change."
  },
  {
    "key": "loc_ui_string_common_equipment",
    "_item": "의뢰 개요 - 보호장구 명칭\n시장 - 보호장구 명칭",
    "_comment": "",
    "ko-KR": "보호장구",
    "en-US": "Protection"
  },
  {
    "key": "loc_ui_string_common_equipment_full",
    "_item": "요원 - 장비 메뉴 - 보호장구 명칭",
    "_comment": "",
    "ko-KR": "보호장구",
    "en-US": "Protection Gear"
  },
  {
    "key": "loc_ui_longtext_agent_equipment_disabled",
    "_item": "의뢰 개요 - 보호장구 변경 불가 사유 툴팁",
    "_comment": "지금 None에서 None으로 변경할 수 있는 상태라 고치게 되면 필요하게 됨",
    "ko-KR": "교체할 수 있는 장비가 없습니다.",
    "en-US": "There are no equipments to change."
  },
  {
    "key": "loc_ui_string_common_throwable",
    "_item": "의뢰 개요 - 수류탄 명칭\n시장 - 수류탄 명칭",
    "_comment": "",
    "ko-KR": "수류탄",
    "en-US": "Grenade"
  },
  {
    "key": "loc_ui_string_common_throwable_full",
    "_item": "요원 - 장비 메뉴 - 수류탄 명칭",
    "_comment": "",
    "ko-KR": "수류탄",
    "en-US": "Grenade"
  },
  {
    "key": "loc_ui_string_common_resource",
    "_item": "결산 - 선택 보상 - 현금 명칭",
    "_comment": "",
    "ko-KR": "현금",
    "en-US": "Cash"
  },
  {
    "key": "loc_ui_longtext_agent_throwable_disabled_no_item",
    "_item": "의뢰 개요 - 수류탄 변경 불가 사유 툴팁(아이템 없음)",
    "_comment": "변경 불가 사유 툴팁 통일하기?",
    "ko-KR": "교체할 수 있는 장비가 없습니다.",
    "en-US": "There are no equipments to change."
  },
  {
    "key": "loc_ui_longtext_agent_throwable_disabled_perk",
    "_item": "의뢰 개요 - 수류탄 변경 불가 사유 툴팁(기초 퍽 없음)",
    "_comment": "퍽 명칭 Locale 사용해야 함",
    "ko-KR": "[$t(loc_data_string_perk_name_perk_grenadier_base)] 퍽이 있는 용병만 수류탄을 장비할 수 있습니다.",
    "en-US": "Only agents with perk [$t(loc_data_string_perk_name_perk_grenadier_base)] can equip grenade."
  },
  {
    "key": "loc_ui_longtext_agent_throwable_disabled_perk_2",
    "_item": "의뢰 개요 - 수류탄 변경 불가 사유 툴팁(고급 퍽 없음)",
    "_comment": "퍽 명칭 Locale 사용해야 함",
    "ko-KR": "[$t(loc_data_string_perk_name_perk_grenadier_amount)] 퍽이 있는 용병만 수류탄을 두 개 장비할 수 있습니다.",
    "en-US": "Only agents with perk [$t(loc_data_string_perk_name_perk_grenadier_amount)] can equip two grenades."
  },
  {
    "key": "loc_ui_string_agent_relocating_duration",
    "_item": "의뢰 개요 - 요원 투입 불가 사유(재배치중)",
    "_comment": "",
    "ko-KR": "$t(loc_ui_string_common_agent_state_relocating): ",
    "en-US": "$t(loc_ui_string_common_agent_state_relocating): "
  },
  {
    "key": "loc_ui_string_agent_recovery_duration",
    "_item": "의뢰 개요 - 요원 투입 불가 사유(치료중)",
    "_comment": "",
    "ko-KR": "$t(loc_ui_string_common_agent_state_recovery): ",
    "en-US": "$t(loc_ui_string_common_agent_state_recovery): "
  },
  {
    "key": "loc_dynamic_string_agent_stamina_recovery_duration_one",
    "_item": "의뢰 개요 - 요원 투입 불가 사유(스태미너 부족)",
    "_comment": "",
    "ko-KR": "",
    "en-US": "Low stamina: {{count}} day to recover"
  },
  {
    "key": "loc_dynamic_string_agent_stamina_recovery_duration_other",
    "_item": "의뢰 개요 - 요원 투입 불가 사유(스태미너 부족)",
    "_comment": "",
    "ko-KR": "스태미너 부족: 회복까지 {{count}}일",
    "en-US": "Low stamina: {{count}} days to recover"
  },
  {
    "key": "loc_ui_string_contract_fine_prints",
    "_item": "의뢰 개요 - 세부 항목 명칭",
    "_comment": "",
    "ko-KR": "세부 항목",
    "en-US": "Fine Prints"
  },
  {
    "key": "loc_ui_string_contract_fine_prints_none",
    "_item": "의뢰 개요 - 세부 항목 없음",
    "_comment": "",
    "ko-KR": "없음",
    "en-US": "N/A"
  },
  {
    "key": "loc_ui_string_contract_fine_prints_enemy_detail",
    "_item": "의뢰 개요 - 세부 항목 적 구성 앞부분",
    "_comment": "",
    "ko-KR": "적 구성",
    "en-US": "Enemy Distribution"
  },
  {
    "key": "loc_ui_string_contract_deployment_pay_total",
    "_item": "의뢰 개요 - 임무 수당 합계 명칭",
    "_comment": "",
    "ko-KR": "임무 수당 합계",
    "en-US": "Total Deployment Pay"
  },
  {
    "key": "loc_ui_string_contract_hazard_pay_total",
    "_item": "의뢰 개요 - 위험 수당 합계 명칭",
    "_comment": "",
    "ko-KR": "위험 수당 합계",
    "en-US": "Total Hazard Pay"
  },
  {
    "key": "loc_ui_string_contract_profit_share_total",
    "_item": "의뢰 개요 - 수익 배분 합계 명칭",
    "_comment": "",
    "ko-KR": "수익 배분 합계",
    "en-US": "Total Profit Share"
  },
  {
    "key": "loc_ui_string_contract_opportunity_cost",
    "_item": "의뢰 개요 - 기회비용 명칭",
    "_comment": "",
    "ko-KR": "기회비용",
    "en-US": "Opportunity Cost"
  },
  {
    "key": "loc_ui_string_contract_expected_reward_perfect_play",
    "_item": "의뢰 개요 - 기대 이익: 무사 귀환 명칭",
    "_comment": "",
    "ko-KR": "기대 이익: 무사 귀환",
    "en-US": "Expected Reward: Perfect Play"
  },
  {
    "key": "loc_ui_string_contract_expected_reward_severe_damage",
    "_item": "의뢰 개요 - 기대 이익: 심한 피해 명칭",
    "_comment": "",
    "ko-KR": "기대 이익: 심한 피해",
    "en-US": "Expected Reward: Severe Damage"
  },
  {
    "key": "loc_ui_string_contract_expected_reward_failure",
    "_item": "의뢰 개요 - 기대 이익: 실패 명칭",
    "_comment": "",
    "ko-KR": "기대 이익: 실패",
    "en-US": "Expected Reward: Mission Failure"
  },
  {
    "key": "loc_ui_string_contract_expected_result",
    "_item": "의뢰 개요 - 예상 결과 명칭",
    "_comment": "",
    "ko-KR": "예상 결과",
    "en-US": "Expected Result"
  },
  {
    "key": "loc_ui_string_contract_expected_result_0",
    "_item": "의뢰 개요 - 예상 결과: 절망적임 명칭",
    "_comment": "",
    "ko-KR": "절망적임",
    "en-US": "Hopeless"
  },
  {
    "key": "loc_ui_string_contract_expected_result_1",
    "_item": "의뢰 개요 - 예상 결과: 매우 어려움 명칭",
    "_comment": "",
    "ko-KR": "매우 어려움",
    "en-US": "Decisive Defeat"
  },
  {
    "key": "loc_ui_string_contract_expected_result_2",
    "_item": "의뢰 개요 - 예상 결과: 조금 어려움 명칭",
    "_comment": "",
    "ko-KR": "조금 어려움",
    "en-US": "Likely Defeat"
  },
  {
    "key": "loc_ui_string_contract_expected_result_3",
    "_item": "의뢰 개요 - 예상 결과: 불확실 명칭",
    "_comment": "",
    "ko-KR": "불확실",
    "en-US": "Uncertain"
  },
  {
    "key": "loc_ui_string_contract_expected_result_4",
    "_item": "의뢰 개요 - 예상 결과: 조금 해볼만함 명칭",
    "_comment": "",
    "ko-KR": "조금 해볼만함",
    "en-US": "Likely Victory"
  },
  {
    "key": "loc_ui_string_contract_expected_result_5",
    "_item": "의뢰 개요 - 예상 결과: 매우 해볼만함 명칭",
    "_comment": "",
    "ko-KR": "매우 해볼만함",
    "en-US": "Decisive Victory"
  },
  {
    "key": "loc_ui_string_contract_expected_result_6",
    "_item": "의뢰 개요 - 예상 결과: 절대적으로 안전함 명칭",
    "_comment": "",
    "ko-KR": "절대적으로 안전함",
    "en-US": "Absolutely Safe"
  },
  {
    "key": "loc_ui_string_contract_expected_result_unknown",
    "_item": "의뢰 개요 - 예상 결과: 알 수 없음 명칭",
    "_comment": "",
    "ko-KR": "알 수 없음",
    "en-US": "Unknown"
  },
  {
    "key": "loc_ui_button_contract_confirm_contract",
    "_item": "의뢰 개요 - 의뢰 확정 버튼",
    "_comment": "",
    "ko-KR": "의뢰 확정",
    "en-US": "Confirm Contract"
  },
  {
    "key": "loc_ui_title_mission_start",
    "_item": "임무 시작 모달 제목",
    "_comment": "",
    "ko-KR": "임무 시작",
    "en-US": "Starting Mission"
  },
  {
    "key": "loc_ui_longtext_mission_start_count_one",
    "_item": "임무 시작 모달 - 시작 카운트다운",
    "_comment": "",
    "ko-KR": "",
    "en-US": "Starting Mission in {{count}} second."
  },
  {
    "key": "loc_ui_longtext_mission_start_count_other",
    "_item": "임무 시작 모달 - 시작 카운트다운",
    "_comment": "",
    "ko-KR": "{{count}}초 후 임무에 진입합니다.",
    "en-US": "Starting Mission in {{count}} seconds."
  },
  {
    "key": "loc_ui_longtext_mission_start_tip",
    "_item": "임무 시작 모달 - 임무 팁",
    "_comment": "",
    "ko-KR": "임무를 성공시킨 용병은 경험치를 획득합니다. 경험치가 최대치에 도달하면 레벨이 상승하며 퍽 포인트를 획득합니다.",
    "en-US": "Agents in successful mission earn experience. When agents reach the maximum amount of experience, they level up and earn perk points."
  },
  {
    "key": "loc_ui_button_mission_start_now",
    "_item": "임무 시작 모달 - 바로 진입하기 버튼",
    "_comment": "",
    "ko-KR": "바로 진입하기",
    "en-US": "Start now"
  },
  {
    "key": "loc_ui_title_result",
    "_item": "결산 제목(의뢰 결과 성공/실패)",
    "_comment": "",
    "ko-KR": "의뢰 ",
    "en-US": "Contract "
  },
  {
    "key": "loc_ui_string_result_contract_information",
    "_item": "결산 - 의뢰 정보 영역 제목\n임무 시작 모달 - 의뢰 정보 영역 제목",
    "_comment": "",
    "ko-KR": "의뢰 정보",
    "en-US": "Contract Information"
  },
  {
    "key": "loc_ui_string_result_mission_evaluation",
    "_item": "결산 - 임무 수행 평가 영역 제목",
    "_comment": "",
    "ko-KR": "임무 수행 평가: ",
    "en-US": "Performance Evaluation: "
  },
  {
    "key": "loc_ui_string_result_contract_goal",
    "_item": "결산 - 임무 수행 평가 목표 항목",
    "_comment": "",
    "ko-KR": "목표",
    "en-US": "Goal"
  },
  {
    "key": "loc_ui_string_result_contract_inputs",
    "_item": "결산 - 임무 수행 평가 투입 요원 수 항목\n임무 시작 모달 - 투입 요원 수 항목",
    "_comment": "",
    "ko-KR": "투입 요원 수",
    "en-US": "Agents Dispatched"
  },
  {
    "key": "loc_ui_string_result_contract_damaged",
    "_item": "결산 - 임무 수행 평가 중상 요원 수 항목",
    "_comment": "",
    "ko-KR": "중상을 입은 요원 수",
    "en-US": "Agents Heavily Wounded"
  },
  {
    "key": "loc_ui_string_result_contract_mission_duration",
    "_item": "결산 - 임무 수행 평가 임무 수행 시간 항목",
    "_comment": "",
    "ko-KR": "임무 수행 시간",
    "en-US": "Mission Duration"
  },
  {
    "key": "loc_ui_string_result_contract_enemy_threat",
    "_item": "결산 - 임무 수행 평가 적 위협 수준 항목",
    "_comment": "이거랑 규모 합쳐서 Difficulty가 되는게 일관적일 것 같은데",
    "ko-KR": "적 위협 수준",
    "en-US": "Enemy Threats"
  },
  {
    "key": "loc_ui_string_result_contract_enemy_count",
    "_item": "결산 - 임무 수행 평가 적 규모 항목",
    "_comment": "",
    "ko-KR": "적 규모",
    "en-US": "Enemy Count"
  },
  {
    "key": "loc_ui_string_result_statistics_mission",
    "_item": "결산 - 임무 통계 영역 제목",
    "_comment": "",
    "ko-KR": "임무 통계",
    "en-US": "Mission Statistics"
  },
  {
    "key": "loc_ui_string_result_statistics_summary",
    "_item": "결산 - 임무 요약 탭 제목",
    "_comment": "",
    "ko-KR": "임무 요약",
    "en-US": "Mission Summary"
  },
  {
    "key": "loc_dynamic_string_result_statistics_days_recovery_one",
    "_item": "결산 - 회복 필요 일 항목 + 값",
    "_comment": "",
    "ko-KR": "",
    "en-US": "In recovery: {{count}} day"
  },
  {
    "key": "loc_dynamic_string_result_statistics_days_recovery_other",
    "_item": "결산 - 회복 필요 일 항목 + 값",
    "_comment": "",
    "ko-KR": "회복 필요: {{count}}일",
    "en-US": "In recovery: {{count}} days"
  },
  {
    "key": "loc_ui_string_result_statistics_status_gained",
    "_item": "결산 - 임무 요약 새 상태 변화 항목",
    "_comment": "",
    "ko-KR": "새 상태 변화",
    "en-US": "New Status"
  },
  {
    "key": "loc_ui_string_result_statistics_kill_count",
    "_item": "결산 - 임무 요약 사살 수 항목\n결산 - 전투 통계 사살 수 항목",
    "_comment": "",
    "ko-KR": "사살",
    "en-US": "Kills"
  },
  {
    "key": "loc_ui_string_result_statistics_combat",
    "_item": "결산 - 전투 통계 탭 제목",
    "_comment": "",
    "ko-KR": "전투 통계",
    "en-US": "Combat Summary"
  },
  {
    "key": "loc_ui_string_result_grenade_used",
    "_item": "결산 - 전투 통계 수류탄 사용 여부(사용)",
    "_comment": "",
    "ko-KR": "(사용)",
    "en-US": "(used)"
  },
  {
    "key": "loc_ui_string_result_grenade_unused",
    "_item": "결산 - 전투 통계 수류탄 사용 여부(미사용)",
    "_comment": "",
    "ko-KR": "(미사용)",
    "en-US": "(unused)"
  },
  {
    "key": "loc_ui_string_result_statistics_shoots",
    "_item": "결산 - 전투 통계 사격량 항목",
    "_comment": "",
    "ko-KR": "사격량",
    "en-US": "Damage Intended"
  },
  {
    "key": "loc_ui_string_result_amount_healed",
    "_item": "결산 - 전투 통계 치료량 항목",
    "_comment": "",
    "ko-KR": "치료",
    "en-US": "Amount Healed"
  },
  {
    "key": "loc_ui_string_result_kills_throwable",
    "_item": "결산 - 전투 통계 수류탄 제압 항목",
    "_comment": "",
    "ko-KR": "수류탄 제압",
    "en-US": "Grenade Kills"
  },
  {
    "key": "loc_ui_string_result_kills_longrange",
    "_item": "결산 - 전투 통계 장거리 제압 항목",
    "_comment": "",
    "ko-KR": "장거리 제압",
    "en-US": "Longrange Kills"
  },
  {
    "key": "loc_dynamic_string_result_statistics_agents",
    "_item": "결산 - 요원 통계 영역 제목",
    "_comment": "",
    "ko-KR": "요원 통계: {{value}}",
    "en-US": "Agent Summary: {{value}}"
  },
  {
    "key": "loc_ui_string_result_statistics_agent_perks",
    "_item": "결산 - 요원 통계 퍽 항목",
    "_comment": "",
    "ko-KR": "퍽",
    "en-US": "Perks"
  },
  {
    "key": "loc_ui_string_result_agent_dead",
    "_item": "결산 - 요원 사망 항목",
    "_comment": "",
    "ko-KR": "치명상",
    "en-US": "Critical"
  },
  {
    "key": "loc_dynamic_button_result_agent_dead",
    "_item": "결산 - 요원 소생 버튼",
    "_comment": "",
    "ko-KR": "긴급 소생: ${{value}}",
    "en-US": "Revive: ${{value}}"
  },
  {
    "key": "loc_ui_string_result_contract_reward",
    "_item": "결산 - 현금 보상 영역 제목",
    "_comment": "",
    "ko-KR": "의뢰 수지",
    "en-US": "Contract Profits"
  },
  {
    "key": "loc_dynamic_string_result_reason_agent_dead",
    "_item": "결산 - 명성치 변경 사유(요원 사망)",
    "_comment": "",
    "ko-KR": "요원 치명상({{value}})",
    "en-US": "Agent fatal({{value}})"
  },
  {
    "key": "loc_ui_string_result_contract_reward_cash",
    "_item": "결산 - 현금 보상 항목",
    "_comment": "",
    "ko-KR": "현금 수입",
    "en-US": "Cash Gain"
  },
  {
    "key": "loc_ui_string_result_contract_reward_medical_expense",
    "_item": "결산 - 의료 비용 항목",
    "_comment": "",
    "ko-KR": "의료 비용",
    "en-US": "Medical Expenses"
  },
  {
    "key": "loc_ui_string_result_contract_reward_incentive_payment",
    "_item": "결산 - 인센티브 지출 항목",
    "_comment": "",
    "ko-KR": "인센티브 지출",
    "en-US": "Incentive Payments"
  },
  {
    "key": "loc_ui_string_result_contract_reward_net_profit",
    "_item": "결산 - 순 수익 항목",
    "_comment": "",
    "ko-KR": "순 수익",
    "en-US": "Net Profit"
  },
  {
    "key": "loc_ui_string_result_contract_reward_item",
    "_item": "결산 - 현물 보상 영역 제목",
    "_comment": "",
    "ko-KR": "현물 보상",
    "en-US": "Equipments Found"
  },
  {
    "key": "loc_ui_string_result_reward_select",
    "_item": "결산 - 선택 보상 영역 제목",
    "_comment": "",
    "ko-KR": "추가 보상 선택",
    "en-US": "Select additional reward"
  },
  {
    "key": "loc_ui_button_result_reward_none",
    "_item": "결산 - 선택 보상 없음 버튼",
    "_comment": "",
    "ko-KR": "보상 없음",
    "en-US": "No Reward"
  },
  {
    "key": "loc_ui_longtext_result_reward_equipment",
    "_item": "결산 - 선택 보상 설명(보호장구)",
    "_comment": "",
    "ko-KR": "지정된 등급의 $t(loc_ui_string_common_equipment_full)를 획득합니다.",
    "en-US": "Get a $t(loc_ui_string_common_equipment_full) with given grade."
  },
  {
    "key": "loc_ui_longtext_result_reward_throwable",
    "_item": "결산 - 선택 보상 설명(수류탄)",
    "_comment": "",
    "ko-KR": "지정된 종류의 $t(loc_ui_string_common_throwable_full)을 획득합니다.",
    "en-US": "Get a $t(loc_ui_string_common_throwable_full) with given grade."
  },
  {
    "key": "loc_ui_longtext_result_reward_firearm",
    "_item": "결산 - 선택 보상 설명(총기)",
    "_comment": "",
    "ko-KR": "지정된 등급, 임의 종류의 $t(loc_ui_string_common_firearm_full)를 획득합니다.",
    "en-US": "Get a $t(loc_ui_string_common_firearm_full) with given grade and random type."
  },
  {
    "key": "loc_ui_longtext_result_reward_resource",
    "_item": "결산 - 선택 보상 설명(현금)",
    "_comment": "",
    "ko-KR": "현금을 추가로 획득합니다.",
    "en-US": "Get some additional cash."
  },
  {
    "key": "loc_ui_string_result_reward_random",
    "_item": "결산 - 선택 보상 임의 명칭",
    "_comment": "",
    "ko-KR": "무작위 ",
    "en-US": "Random "
  },
  {
    "key": "loc_ui_string_result_event",
    "_item": "결산 - 이벤트 선택지 선택 영역 제목",
    "_comment": "",
    "ko-KR": "추가 결재 사안",
    "en-US": "Additional decision needed"
  },
  {
    "key": "loc_ui_button_longtext_result_event_none",
    "_item": "결산 - 이벤트 선택지 없음 버튼",
    "_comment": "",
    "ko-KR": "특별한 일은 없었다.",
    "en-US": "Nothing special happened."
  },
  {
    "key": "loc_ui_button_result_event_remove_modifier",
    "_item": "결산 - 이벤트 선택지로 모디파이어 제거 버튼",
    "_comment": "",
    "ko-KR": "삭제: ",
    "en-US": "Remove: "
  },
  {
    "key": "loc_ui_button_common_continue",
    "_item": "결산 - 계속하기 버튼",
    "_comment": "",
    "ko-KR": "계속하기",
    "en-US": "Continue"
  },
  {
    "key": "loc_ui_title_menu_agent",
    "_item": "요원 메뉴 명칭",
    "_comment": "",
    "ko-KR": "요원",
    "en-US": "Agents"
  },
  {
    "key": "loc_ui_string_agent_list",
    "_item": "요원 - 요원 목록 탭 명칭\n고용 - 요원 목록 탭 명칭\n재계약 - 요원 목록 탭 명칭",
    "_comment": "",
    "ko-KR": "요원 목록",
    "en-US": "Agent list"
  },
  {
    "key": "loc_dynamic_string_agent_perk_points_available_one",
    "_item": "요원 - 요원 목록 사용 가능한 퍽 포인트 안내",
    "_comment": "",
    "ko-KR": "",
    "en-US": "({{count}} available Perk point)"
  },
  {
    "key": "loc_dynamic_string_agent_perk_points_available_other",
    "_item": "요원 - 요원 목록 사용 가능한 퍽 포인트 안내",
    "_comment": "",
    "ko-KR": "(사용 가능한 퍽 포인트: {{count}}개)",
    "en-US": "({{count}} available Perk points)"
  },
  {
    "key": "loc_ui_title_agent_overview",
    "_item": "요원 상세 - 개요 탭 제목\n영입 상세 - 개요 탭 제목\n요원 재계약 팝업 - 개요 탭 제목",
    "_comment": "갱신 안 되는 문제 있음(탭 제목은 다 그럴듯)\n이거 고치면 Label로 조회하는 부분 있어서 게임 깨짐",
    "ko-KR": "개요",
    "en-US": "Overview"
  },
  {
    "key": "loc_dynamic_string_agent_contract_expiration_one",
    "_item": "요원 상세 - 계약 만료까지 기간 항목 + 값",
    "_comment": "",
    "ko-KR": "",
    "en-US": "contract expiration in a day"
  },
  {
    "key": "loc_dynamic_string_agent_contract_expiration_other",
    "_item": "요원 상세 - 계약 만료까지 기간 항목 + 값",
    "_comment": "",
    "ko-KR": "계약 만기까지: {{count}}일",
    "en-US": "{{count}} days until contract expiration"
  },
  {
    "key": "loc_ui_string_agent_nationality",
    "_item": "요원 상세 개요 - 국적 명칭\n영입 상세 개요 - 국적 명칭\n요원 재계약 팝업 - 국적 명칭",
    "_comment": "",
    "ko-KR": "국적",
    "en-US": "Nationality"
  },
  {
    "key": "loc_ui_string_agent_language",
    "_item": "요원 상세 개요 - 언어 명칭\n영입 상세 개요 - 언어 명칭\r\n요원 재계약 팝업 - 언어 명칭",
    "_comment": "",
    "ko-KR": "언어",
    "en-US": "Language"
  },
  {
    "key": "loc_ui_string_agent_background",
    "_item": "요원 상세 개요 - 배경 명칭\n영입 상세 개요 - 배경 명칭\r\n요원 재계약 팝업 - 배경 명칭",
    "_comment": "",
    "ko-KR": "배경",
    "en-US": "Background"
  },
  {
    "key": "loc_ui_string_agent_age",
    "_item": "요원 상세 개요 - 나이 명칭\n영입 상세 개요 - 나이 명칭\r\n요원 재계약 팝업 - 나이 명칭",
    "_comment": "",
    "ko-KR": "나이",
    "en-US": "Age"
  },
  {
    "key": "loc_ui_string_agent_level",
    "_item": "요원 상세 개요 - 레벨 명칭\n요원 재계약 팝업 - 레벨 명칭\r\n훈련 보고서 - 레벨 명칭",
    "_comment": "",
    "ko-KR": "레벨",
    "en-US": "Level"
  },
  {
    "key": "loc_ui_string_agent_experience",
    "_item": "요원 상세 개요 - 경험치 명칭\n요원 재계약 팝업 - 경험치 명칭\r\n훈련 보고서 -경험치 명칭",
    "_comment": "",
    "ko-KR": "경험치",
    "en-US": "Experience"
  },
  {
    "key": "loc_ui_string_agent_grade",
    "_item": "요원 상세 개요 - 등급 명칭\n영입 상세 계요 - 등급 명칭\r\n요원 재계약 팝업 - 등급 명칭",
    "_comment": "",
    "ko-KR": "등급",
    "en-US": "Grade"
  },
  {
    "key": "loc_ui_string_agent_weapon_qualification",
    "_item": "요원 상세 개요 - 무기 훈련 인증 명칭\n영입 상세 개요 - 무기 훈련 인증 명칭\n요원 재계약 팝업 - 무기 훈련 인증 명칭\r\n훈련 보고서 - 무기 훈련 인증 명칭",
    "_comment": "",
    "ko-KR": "무기 훈련 인증",
    "en-US": "Weapon Qualification"
  },
  {
    "key": "loc_ui_string_common_firearm_handgun_short",
    "_item": "요원 상세 개요 - 무기 훈련 인증 값(HG)\n영입 상세 개요 - 무기 훈련 인증 값(HG)\n요원 재계약 팝업 - 무기 훈련 인증 값(HG)\r\n훈련 보고서 - 무기 훈련 인증 값(HG)",
    "_comment": "",
    "ko-KR": "HG",
    "en-US": "HG"
  },
  {
    "key": "loc_ui_string_common_firearm_shotgun_short",
    "_item": "요원 상세 개요 - 무기 훈련 인증 값(SG)\n영입 상세 개요 - 무기 훈련 인증 값(SG)\n요원 재계약 팝업 - 무기 훈련 인증 값(SG)\r\n훈련 보고서 - 무기 훈련 인증 값(SG)",
    "_comment": "",
    "ko-KR": "SG",
    "en-US": "SG"
  },
  {
    "key": "loc_ui_string_common_firearm_submachine_gun_short",
    "_item": "요원 상세 개요 - 무기 훈련 인증 값(SMG)\n영입 상세 개요 - 무기 훈련 인증 값(SMG)\n요원 재계약 팝업 - 무기 훈련 인증 값(SMG)\r\n훈련 보고서 - 무기 훈련 인증 값(SMG)",
    "_comment": "",
    "ko-KR": "SMG",
    "en-US": "SMG"
  },
  {
    "key": "loc_ui_string_common_firearm_assult_rifle_short",
    "_item": "요원 상세 개요 - 무기 훈련 인증 값(AR)\n영입 상세 개요 - 무기 훈련 인증 값(AR)\n요원 재계약 팝업 - 무기 훈련 인증 값(AR)\r\n훈련 보고서 - 무기 훈련 인증 값(AR)",
    "_comment": "",
    "ko-KR": "AR",
    "en-US": "AR"
  },
  {
    "key": "loc_ui_string_common_firearm_designated_marksman_rifle_short",
    "_item": "요원 상세 개요 - 무기 훈련 인증 값(DMR)\n영입 상세 개요 - 무기 훈련 인증 값(DMR)\n요원 재계약 팝업 - 무기 훈련 인증 값(DMR)\r\n훈련 보고서 - 무기 훈련 인증 값(DMR)",
    "_comment": "",
    "ko-KR": "DMR",
    "en-US": "DMR"
  },
  {
    "key": "loc_ui_string_common_firearm_sniper_rifle_short",
    "_item": "요원 상세 개요 - 무기 훈련 인증 값(SR)\n영입 상세 개요 - 무기 훈련 인증 값(SR)\n요원 재계약 팝업 - 무기 훈련 인증 값(SR)\n훈련 보고서 - 무기 훈련 인증 값(SR)",
    "_comment": "",
    "ko-KR": "SR",
    "en-US": "SR"
  },
  {
    "key": "loc_ui_string_agent_growth_cap",
    "_item": "요원 상세 개요 - 성장 한계 항목\n영입 상세 개요 - 성장 한계 항목\n요원 재계약 팝업 - 성장 한계 항목\r\n훈련 보고서 - 성장 한계 항목",
    "_comment": "",
    "ko-KR": "성장 한계",
    "en-US": "Growth Cap"
  },
  {
    "key": "loc_ui_string_agent_potential",
    "_item": "요원 상세 개요 - 성장성 항목\n영입 상세 개요 - 성장성 항목\n요원 재계약 팝업 - 성장성 항목\r\n훈련 보고서 - 성장성 항목",
    "_comment": "",
    "ko-KR": "성장성",
    "en-US": "Potential"
  },
  {
    "key": "loc_ui_string_common_equipables",
    "_item": "요원 상세 개요 - 장비 영역 제목\n영입 상세 개요 - 장비 영역 제목\r\n요원 재계약 팝업 - 장비 영역 제목",
    "_comment": "",
    "ko-KR": "장비",
    "en-US": "Equipments"
  },
  {
    "key": "loc_dynamic_title_popup_agent_equipable_change_firearm",
    "_item": "요원 상세 개요 - 장비 변경 팝업 제목(총기)",
    "_comment": "",
    "ko-KR": "{{name}}의 $t(loc_ui_string_common_firearm_full) 변경",
    "en-US": "$t(loc_ui_string_common_firearm_full) change for {{name}}"
  },
  {
    "key": "loc_dynamic_title_popup_agent_equipable_change_equipment",
    "_item": "요원 상세 개요 - 장비 변경 팝업 제목(보호장구)",
    "_comment": "",
    "ko-KR": "{{name}}의 $t(loc_ui_string_common_equipment_full) 변경",
    "en-US": "$t(loc_ui_string_common_equipment_full) change for {{name}}"
  },
  {
    "key": "loc_dynamic_title_popup_agent_equipable_change_throwable",
    "_item": "요원 상세 개요 - 장비 변경 팝업 제목(수류탄)",
    "_comment": "",
    "ko-KR": "{{name}}의 $t(loc_ui_string_common_throwable_full) 변경",
    "en-US": "$t(loc_ui_string_common_throwable_full) change for {{name}}"
  },
  {
    "key": "loc_ui_string_popup_agent_equipment_current",
    "_item": "요원 상세 개요 - 장비 변경 팝업 - 장비중인 아이템 명칭",
    "_comment": "",
    "ko-KR": "현재 착용중",
    "en-US": "Equipped"
  },
  {
    "key": "loc_ui_string_popup_agent_equipment_no_alternatives",
    "_item": "요원 상세 개요 - 장비 변경 팝업 - 변경할 장비 없을때 알림(총기)",
    "_comment": "",
    "ko-KR": "착용 가능한 장비 없음",
    "en-US": "No equipments available"
  },
  {
    "key": "loc_ui_string_popup_agent_equipment_alternative_exists",
    "_item": "요원 상세 개요 - 장비 변경 팝업 - 변경할 장비 목록 제목",
    "_comment": "",
    "ko-KR": "착용할 장비",
    "en-US": "Equipment list"
  },
  {
    "key": "loc_ui_button_common_select",
    "_item": "요원 상세 개요 - 장비 변경 팝업 - 변경할 장비 선택 버튼\n조직 - 직원 배치 팝업 - 배치할 직원 선택 버튼\n창고 - 장비 착용 팝업 - 요원 선택 버튼",
    "_comment": "",
    "ko-KR": "선택",
    "en-US": "Select"
  },
  {
    "key": "loc_ui_button_popup_common_close",
    "_item": "요원 상세 개요 - 장비 변경 팝업 - 팝업 닫기 버튼\n퀘스트 - 퀘스트 모달 닫기 버튼",
    "_comment": "",
    "ko-KR": "닫기",
    "en-US": "Close"
  },
  {
    "key": "loc_ui_string_agent_trait",
    "_item": "요원 상세 개요 - 특성 영역 제목\n영입 상세 개요 - 특성 영역 제목\r\n요원 재계약 팝업 - 특성 영역 제목",
    "_comment": "",
    "ko-KR": "특성",
    "en-US": "Traits"
  },
  {
    "key": "loc_ui_string_agent_status",
    "_item": "요원 상세 개요 - 상태 변화 영역 제목\n영입 상세 개요 - 상태 변화 영역 제목\r\n요원 재계약 팝업 - 상태 변화 영역 제목",
    "_comment": "",
    "ko-KR": "상태 변화",
    "en-US": "Status"
  },
  {
    "key": "loc_ui_string_agent_statistics",
    "_item": "요원 상세 개요 - 통계 영역 제목\r\n요원 재계약 팝업 - 통계 영역 제목",
    "_comment": "",
    "ko-KR": "통계",
    "en-US": "Statistics"
  },
  {
    "key": "loc_ui_string_agent_statistics_mission",
    "_item": "요원 상세 개요 - 임무 항목",
    "_comment": "",
    "ko-KR": "임무",
    "en-US": "Mission"
  },
  {
    "key": "loc_ui_string_common_success",
    "_item": "요원 상세 개요 - 임무: 성공 항목\n요원 재계약 팝업 - 임무: 성공 항목\n결산 - 임무 결과 - 성공\n조직 - 업무 기록 내용 - 성공",
    "_comment": "요원에서 임무 상세 미구현 상태",
    "ko-KR": "성공",
    "en-US": "Success"
  },
  {
    "key": "loc_ui_string_common_failure",
    "_item": "요원 상세 개요 - 임무: 실패 항목\n요원 재계약 팝업 - 임무: 실패 항목\n결산 - 임무 결과 - 실패\n조직 - 업무 기록 내용 - 실패",
    "_comment": "요원에서 임무 상세 미구현 상태",
    "ko-KR": "실패",
    "en-US": "Failure"
  },
  {
    "key": "loc_ui_string_common_pass",
    "_item": "결산 - 임무 결과 - 패스",
    "_comment": "패스가 어떤 경우에 나오지?",
    "ko-KR": "패스",
    "en-US": "Pass"
  },
  {
    "key": "loc_ui_string_agent_statistics_damage",
    "_item": "요원 상세 개요 - 피해 항목\r\n요원 재계약 팝업 - 피해 항목",
    "_comment": "",
    "ko-KR": "피해",
    "en-US": "Damage"
  },
  {
    "key": "loc_ui_string_agent_statistics_damage_given",
    "_item": "요원 상세 개요 - 피해: 준 피해 항목\r\n요원 재계약 팝업 - 피해: 준 피해 항목",
    "_comment": "요원에서 피해 상세 미구현 상태",
    "ko-KR": "준 피해",
    "en-US": "Damage Given"
  },
  {
    "key": "loc_ui_string_agent_statistics_damage_taken",
    "_item": "요원 상세 개요 - 피해: 받은 피해 항목\r\n요원 재계약 팝업 - 피해: 받은 피해 항목",
    "_comment": "요원에서 피해 상세 미구현 상태",
    "ko-KR": "받은 피해",
    "en-US": "Damage Taken"
  },
  {
    "key": "loc_ui_string_agent_contribution",
    "_item": "요원 상세 개요 - 기여 영역 제목\r\n요원 재계약 팝업 - 기여 영역 제목",
    "_comment": "",
    "ko-KR": "기여",
    "en-US": "Contribution"
  },
  {
    "key": "loc_ui_title_agent_stats",
    "_item": "요원 상세 - 능력치 탭 제목\n영입 상세 - 능력치 탭 제목",
    "_comment": "",
    "ko-KR": "능력치",
    "en-US": "Stats"
  },
  {
    "key": "loc_ui_string_agent_stat_type",
    "_item": "요원 상세 능력치 - 능력치 종류 항목\n영입 상세 능력치 - 능력치 종류 항목",
    "_comment": "",
    "ko-KR": "능력치",
    "en-US": "Stat"
  },
  {
    "key": "loc_ui_string_agent_stat_value",
    "_item": "요원 상세 능력치 - 능력치 값 항목\n영입 상세 능력치 - 능력치 값 항목",
    "_comment": "",
    "ko-KR": "값",
    "en-US": "Value"
  },
  {
    "key": "loc_ui_string_agent_sharpness",
    "_item": "요원 상세 능력치 - 전투 감각 항목\n영입 상세 능력치 - 전투 감각 항목",
    "_comment": "",
    "ko-KR": "전투 감각",
    "en-US": "Sharpness"
  },
  {
    "key": "loc_ui_string_agent_sharpness_prediction",
    "_item": "요원 상세 능력치 - 전투 감각 항목 예상 안내\n영입 상세 능력치 - 전투 감각 항목 예상 안내",
    "_comment": "",
    "ko-KR": " (예상)",
    "en-US": " (Prediction)"
  },
  {
    "key": "loc_ui_string_agent_stat_overall",
    "_item": "요원 상세 능력치 - 전투력 항목\n영입 상세 능력치 - 전투력 항목",
    "_comment": "",
    "ko-KR": "전투력",
    "en-US": "Overall"
  },
  {
    "key": "loc_ui_string_agent_stat_decision",
    "_item": "요원 상세 능력치 - 판단력 항목\n영입 상세 능력치 - 판단력 항목",
    "_comment": "",
    "ko-KR": "판단력",
    "en-US": "Decision"
  },
  {
    "key": "loc_ui_longtext_agent_stat_decision",
    "_item": "요원 상세 능력치 - 판단력 툴팁\n영입 상세 능력치 - 판단력 툴팁",
    "_comment": "",
    "ko-KR": "- 좋은 판단 능력으로 어느 방향으로, 어떤 타이밍에 사격해야할지 알게 됩니다.",
    "en-US": "- With better Decision, agents have good timing to pull trigger."
  },
  {
    "key": "loc_ui_longtext_agent_stat_decision_effect",
    "_item": "요원 상세 능력치 - 판단력 효과 툴팁\n영입 상세 능력치 - 판단력 효과 툴팁",
    "_comment": "",
    "ko-KR": "- 조준 정확도 개선.",
    "en-US": "- Improves aim accuracy."
  },
  {
    "key": "loc_ui_string_agent_stat_bravery",
    "_item": "요원 상세 능력치 - 용감함 항목\n영입 상세 능력치 - 용감함 항목",
    "_comment": "",
    "ko-KR": "용감함",
    "en-US": "Bravery"
  },
  {
    "key": "loc_ui_longtext_agent_stat_bravery",
    "_item": "요원 상세 능력치 - 용감함 툴팁\n영입 상세 능력치 - 용감함 툴팁",
    "_comment": "",
    "ko-KR": "- 예상하지 못한 순간이나, 위기에 처해있을 때 당황하지 않고 얼마나 용감하게 대응하는지를 나타냅니다.",
    "en-US": "- With better Bravery, agents can behave better when being attacked."
  },
  {
    "key": "loc_ui_longtext_agent_stat_bravery_effect",
    "_item": "요원 상세 능력치 - 용감함 효과 툴팁\n영입 상세 능력치 - 용감함 효과 툴팁",
    "_comment": "",
    "ko-KR": "- 피격 당했을 때의 조준각 흔들림 감소.\n- 더 높은 티어의 보호장구 착용 가능.",
    "en-US": "- Reduces aim deviation when hit.\n- Enables equipping high-tier protection equipment."
  },
  {
    "key": "loc_ui_string_agent_stat_focus",
    "_item": "요원 상세 능력치 - 집중력 항목\n영입 상세 능력치 - 집중력 항목",
    "_comment": "",
    "ko-KR": "집중력",
    "en-US": "Focus"
  },
  {
    "key": "loc_ui_longtext_agent_stat_focus",
    "_item": "요원 상세 능력치 - 집중력 툴팁\n영입 상세 능력치 - 집중력 툴팁",
    "_comment": "",
    "ko-KR": "- 상황에 몰입하여 적을 제압하는 것에 더 뛰어난 능력을 발휘하게 됩니다.",
    "en-US": "- With better Focus, agents can concentrate when shooting."
  },
  {
    "key": "loc_ui_longtext_agent_stat_focus_effect",
    "_item": "요원 상세 능력치 - 집중력 효과 툴팁\n영입 상세 능력치 - 집중력 효과 툴팁",
    "_comment": "",
    "ko-KR": "- 연사시 집탄률 개선.",
    "en-US": "- Reduces aim deviation when shooting continuously."
  },
  {
    "key": "loc_ui_string_agent_stat_reaction",
    "_item": "요원 상세 능력치 - 반응속도 항목\n영입 상세 능력치 - 반응속도 항목",
    "_comment": "",
    "ko-KR": "반응속도",
    "en-US": "Reaction"
  },
  {
    "key": "loc_ui_longtext_agent_stat_reaction",
    "_item": "요원 상세 능력치 - 반응속도 툴팁\n영입 상세 능력치 - 반응속도 툴팁",
    "_comment": "",
    "ko-KR": "- 적보다 더 빠르게 반응하여 상황을 유리하게 이끌어갑니다.",
    "en-US": "- With better Reaction, agents can act before enemies do."
  },
  {
    "key": "loc_ui_longtext_agent_stat_reaction_effect",
    "_item": "요원 상세 능력치 - 반응속도 효과 툴팁\n영입 상세 능력치 - 반응속도 효과 툴팁",
    "_comment": "",
    "ko-KR": "- 반응속도 증가.",
    "en-US": "- Improves reaction speed."
  },
  {
    "key": "loc_ui_string_agent_stat_toughness",
    "_item": "요원 상세 능력치 - 강인함 항목\n영입 상세 능력치 - 강인함 항목",
    "_comment": "",
    "ko-KR": "강인함",
    "en-US": "Toughness"
  },
  {
    "key": "loc_ui_longtext_agent_stat_toughness",
    "_item": "요원 상세 능력치 - 강인함 툴팁\n영입 상세 능력치 - 강인함 툴팁",
    "_comment": "",
    "ko-KR": "- 빠른 체력 회복과 관련 되어 있습니다.",
    "en-US": "- With better Toughness, agents can heal faster."
  },
  {
    "key": "loc_ui_longtext_agent_stat_toughness_effect",
    "_item": "요원 상세 능력치 - 강인함 효과 툴팁\n영입 상세 능력치 - 강인함 효과 툴팁",
    "_comment": "",
    "ko-KR": "- 생명력 증가. 생명력 회복 속도 증가.\n- 부상에 의한 전투력 페널티 감소.",
    "en-US": "- Increases life & life recovery speed.\n- Reduces overall penalty with injury."
  },
  {
    "key": "loc_ui_string_agent_stat_precision",
    "_item": "요원 상세 능력치 - 정교함 항목\n영입 상세 능력치 - 정교함 항목",
    "_comment": "",
    "ko-KR": "정교함",
    "en-US": "Precision"
  },
  {
    "key": "loc_ui_longtext_agent_stat_precision",
    "_item": "요원 상세 능력치 - 정교함 툴팁\n영입 상세 능력치 - 정교함 툴팁",
    "_comment": "",
    "ko-KR": "- 적의 약점을 살피고, 더 치명적인 공격을 할 수 있게 됩니다.",
    "en-US": "With better Precison, agents can deal deadly damage."
  },
  {
    "key": "loc_ui_longtext_agent_stat_precision_effect",
    "_item": "요원 상세 능력치 - 정교함 효과 툴팁\n영입 상세 능력치 - 정교함 효과 툴팁",
    "_comment": "",
    "ko-KR": "- 헤드샷 확률 증가.",
    "en-US": "- Increases headshot chance."
  },
  {
    "key": "loc_dynamic_string_agent_stat_delta_summary",
    "_item": "요원 상세 능력치 - 능력치 툴팁(총합)\n영입 상세 능력치 - 능력치 툴팁(총합)\n요원 상세 전투 감각 - 능력치 툴팁(총합)\n영입 상세 전투 감각 - 능력치 툴팁(총합)",
    "_comment": "",
    "ko-KR": "총합: {{value}}",
    "en-US": "Total: {{value}}"
  },
  {
    "key": "loc_dynamic_string_agent_stat_gauge_delta_summary_excess_max",
    "_item": "요원 상세 전투 감각 - 능력치 툴팁(총합, 상한)\n영입 상세 전투 감각 - 능력치 툴팁(총합, 상한)",
    "_comment": "",
    "ko-KR": "총합: {{value}}({{originalValue}} {{excessValue}}(상한 초과분))",
    "en-US": "Total: {{value}}({{originalValue}} {{excessValue}}(Claw back))"
  },
  {
    "key": "loc_dynamic_string_agent_stat_gauge_delta_summary_excess_min",
    "_item": "요원 상세 전투 감각 - 능력치 툴팁(총합, 하한)\n영입 상세 전투 감각 - 능력치 툴팁(총합, 하한)",
    "_comment": "",
    "ko-KR": "총합: {{value}}({{originalValue}} {{excessValue}}(하한 부족분))",
    "en-US": "Total: {{value}}({{originalValue}} {{excessValue}}(Claw forward))"
  },
  {
    "key": "loc_dynamic_string_agent_stat_delta_potential",
    "_item": "요원 상세 능력치 - 능력치 툴팁(성장 한계)\n영입 상세 능력치 - 능력치 툴팁(성장 한계)",
    "_comment": "",
    "ko-KR": "성장 한계 효율({{percent}}%): {{value}}",
    "en-US": "Potential Effectiveness({{percent}}%): {{value}}"
  },
  {
    "key": "loc_dynamic_string_agent_stat_delta_growthrate",
    "_item": "요원 상세 능력치 - 능력치 툴팁(성장성)\n영입 상세 능력치 - 능력치 툴팁(성장성)",
    "_comment": "",
    "ko-KR": "성장성 효율({{percent}}%): {{value}}",
    "en-US": "Growth Rate Effectiveness({{percent}}%): {{value}}"
  },
  {
    "key": "loc_dynamic_string_agent_stat_delta_maxcap",
    "_item": "요원 상세 능력치 - 능력치 툴팁(상한 조정)\r\n영입 상세 능력치 - 능력치 툴팁(상한 조정)",
    "_comment": "",
    "ko-KR": "능력치 상한에 의한 조정: {{value}}",
    "en-US": "Claw back amount: {{value}}"
  },
  {
    "key": "loc_dynamic_string_agent_stat_delta_mincap",
    "_item": "요원 상세 능력치 - 능력치 툴팁(하한 조정)\r\n영입 상세 능력치 - 능력치 툴팁(하한 조정)",
    "_comment": "",
    "ko-KR": "능력치 하한에 의한 조정: {{value}}",
    "en-US": "Claw forward amount: {{value}}"
  },
  {
    "key": "loc_ui_string_agent_stat_gain",
    "_item": "요원 상세 능력치 - 능력치 성장 항목\n영입 상세 능력치 - 능력치 성장 항목",
    "_comment": "",
    "ko-KR": "성장",
    "en-US": "Gain"
  },
  {
    "key": "loc_ui_string_agent_stat_stable",
    "_item": "요원 상세 능력치 - 능력치 안정 항목\n영입 상세 능력치 - 능력치 안정 항목",
    "_comment": "",
    "ko-KR": "유지",
    "en-US": "Stable"
  },
  {
    "key": "loc_ui_string_agent_stat_loss",
    "_item": "요원 상세 능력치 - 능력치 감소 항목\n영입 상세 능력치 - 능력치 감소 항목",
    "_comment": "",
    "ko-KR": "감소",
    "en-US": "Loss"
  },
  {
    "key": "loc_dynamic_longtext_agent_stat_change",
    "_item": "요원 상세 능력치 - 능력치 변화 내역\n영입 상세 능력치 - 능력치 변화 내역",
    "_comment": "CharacterView.gaugeView.title 작업 필요",
    "ko-KR": "%% TODO %%",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_dynamic_longtext_agent_sharpness_cap",
    "_item": "요원 상세 능력치 - 전투 감각 최대치\n영입 상세 능력치 - 전투 감각 최대치",
    "_comment": "",
    "ko-KR": "노화로 인한 최대 전투 감각: {{value}}",
    "en-US": "Sharpness capped with age: {{value}}"
  },
  {
    "key": "loc_ui_title_popup_agent_stat_sharpness",
    "_item": "요원 상세 능력치 - 전투 감각 팝업 제목\n영입 상세 능력치 - 전투 감각 팝업 제목",
    "_comment": "스펙아웃",
    "ko-KR": "전투 감각",
    "en-US": "Sharpness"
  },
  {
    "key": "loc_ui_longtext_agent_stat_sharpness",
    "_item": "요원 상세 능력치 - 전투 감각 툴팁\n영입 상세 능력치 - 전투 감각 툴팁",
    "_comment": "스펙아웃",
    "ko-KR": "임무 중이 아닌 용병의 전투 감각은 점점 무뎌지며, 전투 감각이 일정 이하로 떨어지게 되면 관련 스탯 또한 감소하게 됩니다.\n훈련을 통해 특정 전투 감각을 끌어올릴 수 있으며, 전투 감각을 높게 유지하면 관련 스탯이 성장하게 됩니다.",
    "en-US": "loc_ui_longtext_agent_stat_sharpness"
  },
  {
    "key": "loc_ui_title_agent_perks",
    "_item": "요원 상세 - 퍽 탭 제목\n영입 상세 - 퍽 탭 제목",
    "_comment": "",
    "ko-KR": "퍽",
    "en-US": "Perks"
  },
  {
    "key": "loc_ui_string_agent_perk_point_available",
    "_item": "요원 상세 퍽 - 가용 퍽 포인트 항목\n영입 상세 퍽 - 가용 퍽 포인트 항목",
    "_comment": "퍽 탭 UI작업 안 된 상태로 인지하고 있고, 특히 텍스트 표기는 추후 변경될 것으로 보입니다. 따라서 작업 미룹니다.",
    "ko-KR": "가용 퍽 포인트",
    "en-US": "Perk Points Available"
  },
  {
    "key": "loc_ui_string_agent_perk_point_used",
    "_item": "요원 상세 퍽 - 투자된 퍽 포인트 항목\n영입 상세 퍽 - 투자된 퍽 포인트 항목",
    "_comment": "퍽 탭 UI작업 안 된 상태로 인지하고 있고, 특히 텍스트 표기는 추후 변경될 것으로 보입니다. 따라서 작업 미룹니다.",
    "ko-KR": "투자된 퍽 포인트",
    "en-US": "Perk Points Used"
  },
  {
    "key": "loc_ui_string_agent_perk_level",
    "_item": "요원 상세 퍽 - 퍽 레벨 항목\n영입 상세 퍽 - 퍽 레벨 항목",
    "_comment": "퍽 탭 UI작업 안 된 상태로 인지하고 있고, 특히 텍스트 표기는 추후 변경될 것으로 보입니다. 따라서 작업 미룹니다.",
    "ko-KR": "퍽 레벨",
    "en-US": "Perk Level"
  },
  {
    "key": "loc_ui_title_agent_contract",
    "_item": "요원 상세 - 계약 탭 제목",
    "_comment": "",
    "ko-KR": "계약",
    "en-US": "Contract"
  },
  {
    "key": "loc_ui_string_agent_contract_information",
    "_item": "요원 상세 계약 - 계약 정보 영역 제목\n영입 개요 - 계약 정보 영역 제목\n요원 재계약 팝업 - 계약 정보 영역 제목",
    "_comment": "",
    "ko-KR": "계약 정보",
    "en-US": "Contract Information"
  },
  {
    "key": "loc_ui_string_agent_contract_base_pay",
    "_item": "요원 상세 계약 - 기본급 항목\n영입 상세 개요 - 기본급 항목\n요원 재계약 팝업 - 기본급 항목",
    "_comment": "",
    "ko-KR": "기본급",
    "en-US": "Base Pay"
  },
  {
    "key": "loc_dynamic_string_agent_contract_monthly_pay",
    "_item": "요원 상세 계약 - 기본급 값\n영입 상세 개요 - 기본급 값\n영입 상세 개요 - 유리한 협상 시 기본급 값\r\n요원 재계약 팝업 - 기본급 값",
    "_comment": "",
    "ko-KR": "매월 ${{value}}",
    "en-US": "${{value}} per month"
  },
  {
    "key": "loc_ui_string_agent_contract_deployment_pay",
    "_item": "요원 상세 계약 - 임무당 수당 항목\n영입 상세 개요 - 임무당 수당 항목\r\n요원 재계약 팝업 - 임무당 수당 항목",
    "_comment": "",
    "ko-KR": "임무당 수당",
    "en-US": "Deployment Pay"
  },
  {
    "key": "loc_ui_string_agent_contract_danger_pay",
    "_item": "영입 상세 개요 - 위험 수당 항목\n요원 재계약 팝업 - 위험 수당 항목",
    "_comment": "",
    "ko-KR": "위험 수당",
    "en-US": "Hazard Pay"
  },
  {
    "key": "loc_ui_string_agent_contract_profit_sharing_rate",
    "_item": "요원 상세 계약 - 수익 배분율 항목\n영입 상세 개요 - 수익 배분율 항목\r\n요원 재계약 팝업 - 수익 배분율 항목",
    "_comment": "",
    "ko-KR": "수익 배분율",
    "en-US": "Profit Sharing Rate"
  },
  {
    "key": "loc_ui_string_agent_contract_remaining",
    "_item": "요원 상세 계약 - 남은 계약 기간 항목",
    "_comment": "",
    "ko-KR": "남은 계약 기간",
    "en-US": "Remaining Terms"
  },
  {
    "key": "loc_ui_string_agent_contract_medical_expense",
    "_item": "요원 상세 계약 - 의료비 항목\n영입 상세 개요 - 의료비 항목\r\n요원 재계약 팝업 - 의료비 항목",
    "_comment": "",
    "ko-KR": "의료비",
    "en-US": "Medical Expenses"
  },
  {
    "key": "loc_dynamic_string_agent_contract_daily_pay",
    "_item": "요원 상세 계약 - 의료비 값\n영입 상세 개요 - 의료비 값\r\n요원 재계약 팝업 - 의료비 값",
    "_comment": "",
    "ko-KR": "매일 ${{value}}",
    "en-US": "${{value}} per day"
  },
  {
    "key": "loc_ui_string_agent_contract_option",
    "_item": "요원 상세 계약 - 계약 옵션 영역 제목\r\n요원 재계약 팝업 - 계약 옵션 영역 제목",
    "_comment": "",
    "ko-KR": "계약 옵션",
    "en-US": "Contract Options"
  },
  {
    "key": "loc_ui_string_agent_contract_cancellation_fee",
    "_item": "요원 상세 계약 - 계약 중도 취소 비용 항목",
    "_comment": "",
    "ko-KR": "계약 중도 취소 비용\r",
    "en-US": "Contract Cancellation Fee"
  },
  {
    "key": "loc_ui_button_agent_contract_cancellation",
    "_item": "요원 상세 계약 - 계약 중도 취소 버튼",
    "_comment": "",
    "ko-KR": "계약 중도 취소",
    "en-US": "Cancel Contract"
  },
  {
    "key": "loc_ui_string_common_agent_state_mission",
    "_item": "의뢰 - 요원 투입 불가 사유(임무 진행중)\n요원 상세 계약 - 계약 중도 취소 불가 사유(임무 진행중)",
    "_comment": "",
    "ko-KR": "임무 수행중",
    "en-US": "In Mission"
  },
  {
    "key": "loc_ui_string_common_agent_state_recovery",
    "_item": "요원 상세 계약 - 계약 중도 취소 불가 사유(치료중)",
    "_comment": "",
    "ko-KR": "치료중",
    "en-US": "In recovery"
  },
  {
    "key": "loc_ui_string_common_agent_state_relocating",
    "_item": "요원 재배치중 알림",
    "_comment": "",
    "ko-KR": "재배치중",
    "en-US": "Relocating"
  },
  {
    "key": "loc_ui_string_common_agent_state_training",
    "_item": "요원 훈련중 알림",
    "_comment": "",
    "ko-KR": "훈련중",
    "en-US": "In Training"
  },
  {
    "key": "loc_ui_string_agent_contract_option_long_term",
    "_item": "요원 상세 계약 - 장기 계약 옵션 항목\n요원 재계약 팝업 - 장기 계약 옵션 항목",
    "_comment": "",
    "ko-KR": "장기 계약",
    "en-US": "Long Term"
  },
  {
    "key": "loc_ui_longtext_agent_contract_option_long_term",
    "_item": "요원 상세 계약 - 장기 계약 옵션 툴팁\r\n요원 재계약 팝업 - 장기 계약 옵션 툴팁",
    "_comment": "",
    "ko-KR": "계약 기간이 50% 길어집니다.",
    "en-US": "Contract term is elongated by 50%."
  },
  {
    "key": "loc_ui_string_agent_contract_option_medical_priority",
    "_item": "요원 상세 계약 - 의료 우대 옵션 항목\r\n요원 재계약 팝업 - 의료 우대 옵션 항목",
    "_comment": "",
    "ko-KR": "의료 우대",
    "en-US": "Medical Priority"
  },
  {
    "key": "loc_ui_longtext_agent_contract_option_medical_priority",
    "_item": "요원 상세 계약 - 의료 우대 옵션 툴팁\r\n요원 재계약 팝업 - 의료 우대 옵션 툴팁",
    "_comment": "",
    "ko-KR": "부상 시 치료비가 2배가 되는 대신 생명력 회복 속도가 2배로 증가합니다.",
    "en-US": "Recover 100% faster when hurt, but medical expense is also 100% higher."
  },
  {
    "key": "loc_ui_string_agent_contract_option_hazard_pay",
    "_item": "요원 상세 계약 - 위험 수당 옵션 항목\n요원 재계약 팝업 - 위험 수당 옵션 항목",
    "_comment": "",
    "ko-KR": "위험 수당",
    "en-US": "Hazard Pay"
  },
  {
    "key": "loc_ui_longtext_agent_contract_option_hazard_pay",
    "_item": "요원 상세 계약 - 위험 수당 옵션 툴팁\r\n요원 재계약 팝업 - 위험 수당 옵션 툴팁",
    "_comment": "",
    "ko-KR": "적 규모가 아군 요원 수의 3배 이상인 임무에 투입시 $250의 위험 수당을 지급합니다. 그 경우 해당 임무에서 모든 능력치가 2만큼 상승합니다.",
    "en-US": "When entering a mission where the enemy size is at least 3x the number of out agents, gets paid risk pay of $250. In that case, gain +2 overall bonus for the mission."
  },
  {
    "key": "loc_ui_title_menu_organization",
    "_item": "조직 메뉴 명칭",
    "_comment": "",
    "ko-KR": "조직",
    "en-US": "Organization"
  },
  {
    "key": "loc_ui_string_organization_list_department",
    "_item": "조직 - 부서 목록 영역 제목",
    "_comment": "",
    "ko-KR": "부서 목록",
    "en-US": "Departments"
  },
  {
    "key": "loc_ui_button_common_collapse",
    "_item": "조직 - 접기 버튼",
    "_comment": "어디갔지….",
    "ko-KR": "접기",
    "en-US": "Collapse"
  },
  {
    "key": "loc_ui_button_common_expand",
    "_item": "조직 - 펼치기 버튼",
    "_comment": "어디갔지….",
    "ko-KR": "펼치기",
    "en-US": "Expand"
  },
  {
    "key": "loc_dynamic_title_organization_department_name",
    "_item": "조직 - 부서 명칭(제목)\n조직 - 직원 배치 팝업 - 제목",
    "_comment": "",
    "ko-KR": "부서: {{value}}",
    "en-US": "Department: {{value}}"
  },
  {
    "key": "loc_ui_title_organization_tab_summary",
    "_item": "조직 - 탭 제목(개요)",
    "_comment": "",
    "ko-KR": "개요",
    "en-US": "Overview"
  },
  {
    "key": "loc_ui_title_organization_tab_base",
    "_item": "조직 - 탭 제목(총무)",
    "_comment": "",
    "ko-KR": "총무",
    "en-US": "General Affairs"
  },
  {
    "key": "loc_ui_title_organization_tab_recruit",
    "_item": "조직 - 탭 제목(모병)",
    "_comment": "",
    "ko-KR": "모병",
    "en-US": "Military Talents"
  },
  {
    "key": "loc_ui_title_organization_tab_market",
    "_item": "조직 - 탭 제목(조달)",
    "_comment": "",
    "ko-KR": "조달",
    "en-US": "Procurement & Inventory"
  },
  {
    "key": "loc_ui_title_organization_tab_training",
    "_item": "조직 - 탭 제목(훈련)",
    "_comment": "",
    "ko-KR": "훈련",
    "en-US": "Training Environment"
  },
  {
    "key": "loc_ui_title_organization_cashbook",
    "_item": "조직 - 장부 영역 제목",
    "_comment": "",
    "ko-KR": "손익계산서",
    "en-US": "Income Statement"
  },
  {
    "key": "loc_ui_longtext_organization_no_department",
    "_item": "조직 - 부서 없음 안내",
    "_comment": "",
    "ko-KR": "부서가 없습니다: 부서 기능 수행을 위해 시설을 건설해주세요.",
    "en-US": "No departments. Construct corresponding facilities to activate departments."
  },
  {
    "key": "loc_ui_longtext_organization_desc_base-1",
    "_item": "조직 - 부서 안내(총무 1)",
    "_comment": "",
    "ko-KR": "총무 부서는 조직의 모든 부서에서 일할 직원을 모집하고 관리하는 부서입니다.",
    "en-US": "General Affairs Deptartment is responsible of hiring and managing staffs."
  },
  {
    "key": "loc_ui_longtext_organization_desc_base-2",
    "_item": "조직 - 부서 안내(총무 2)",
    "_comment": "",
    "ko-KR": "더 능력치가 높은 직원을 고용하기 위해서는 총무 부서에 직원을 배치해야 합니다.",
    "en-US": "Put staffs into General Affairs to hire staffs with high ability."
  },
  {
    "key": "loc_ui_longtext_organization_desc_base-3",
    "_item": "조직 - 부서 안내(총무 3)",
    "_comment": "",
    "ko-KR": "* 총무 부서의 탐색 능력치가 높을수록 능력치가 높은 직원을 발견하기 쉬워집니다.",
    "en-US": "* Higher Search ability in General Affairs means staffs found have higher ability."
  },
  {
    "key": "loc_ui_longtext_organization_desc_base-4",
    "_item": "조직 - 부서 안내(총무 4)",
    "_comment": "",
    "ko-KR": "* 총무 부서의 협상 능력치가 높을수록 능력치가 높은 직원과 고용 협상을 성공할 가능성이 높습니다.",
    "en-US": "* Higher Negotiation ability in General Affairs means higher chance to hire staffs."
  },
  {
    "key": "loc_ui_longtext_organization_desc_recruit-1",
    "_item": "조직 - 부서 안내(총무 1)",
    "_comment": "",
    "ko-KR": "모병 부서는 실제로 전투를 진행할 요원을 모집하고 관리하는 부서입니다.",
    "en-US": "Military Talents Department is responsible of hiring and renewing contract with agents."
  },
  {
    "key": "loc_ui_longtext_organization_desc_recruit-2",
    "_item": "조직 - 부서 안내(총무 2)",
    "_comment": "",
    "ko-KR": "더 강한 용병을 탐색하여 고용하고 더 저렴한 비용에 요원을 운용하기 위해서는 모병 부서에 직원을 배치해야 합니다.",
    "en-US": "Put staffs into Military Talents to hire agents with high overall."
  },
  {
    "key": "loc_ui_longtext_organization_desc_recruit-3",
    "_item": "조직 - 부서 안내(총무 3)",
    "_comment": "",
    "ko-KR": "* 모병 부서의 탐색 능력치가 높을수록 전투력이 높은 요원을 발견하기 쉬워집니다.",
    "en-US": "* Higher Search ability in Military Talents means agents found have higher overall."
  },
  {
    "key": "loc_ui_longtext_organization_desc_recruit-4",
    "_item": "조직 - 부서 안내(총무 4)",
    "_comment": "",
    "ko-KR": "* 모병 부서의 협상 능력치가 높을수록 요원의 계약을 더 싸게 진행할 가능성이 높습니다.",
    "en-US": "* Higher Negotiation ability in Military Talents means higher chance of favorable contracts."
  },
  {
    "key": "loc_ui_longtext_organization_desc_market-1",
    "_item": "조직 - 부서 안내(총무 1)",
    "_comment": "",
    "ko-KR": "조달 부서는 전투를 진행할 요원이 사용할 총기, 방호장구, 수류탄을 관리하는 부서입니다.",
    "en-US": "Procurement & Inventory Department is responsible of managing firearms, protections and grenades."
  },
  {
    "key": "loc_ui_longtext_organization_desc_market-2",
    "_item": "조직 - 부서 안내(총무 2)",
    "_comment": "",
    "ko-KR": "더 강한 장비를 탐색하고 더 저렴하게 구입하기 위해서는 조달 부서에 직원을 배치해야 합니다.",
    "en-US": "Put staffs into Procurement & Inventory to search and secure stronger equipments."
  },
  {
    "key": "loc_ui_longtext_organization_desc_market-3",
    "_item": "조직 - 부서 안내(총무 3)",
    "_comment": "",
    "ko-KR": "* 조달 부서의 탐색 능력치가 높을수록 성능이 높은 장비를 발견하기 쉬워집니다.",
    "en-US": "* Higher Search ability in Procurement & Inventory means equipments found become stronger."
  },
  {
    "key": "loc_ui_longtext_organization_desc_market-4",
    "_item": "조직 - 부서 안내(총무 4)",
    "_comment": "",
    "ko-KR": "* 조달 부서의 흥정 능력치가 높을수록 장비를 더 싸게 구입할 가능성이 높습니다.",
    "en-US": "* Higher Bargaining ability in Procurement & Inventory means higher chance of favorable trade."
  },
  {
    "key": "loc_ui_longtext_organization_desc_training-1",
    "_item": "조직 - 부서 안내(훈련 1)",
    "_comment": "",
    "ko-KR": "훈련 부서는 요원이 전투 감각을 유지하기 위해 훈련하는 훈련 시설을 관리하는 부서입니다.",
    "en-US": "Training Environment Department is responsible of managing condition of training facilities."
  },
  {
    "key": "loc_ui_longtext_organization_desc_training-2",
    "_item": "조직 - 부서 안내(훈련 2)",
    "_comment": "",
    "ko-KR": "훈련 시설은 시간이 지나고 훈련에 사용하면 관리도가 떨어지므로, 관리도를 유지하기 위해서는 훈련 부서에 직원을 배치해야 합니다.",
    "en-US": "Put staffs into Training Environment to counter decreasing condition of training facilities."
  },
  {
    "key": "loc_ui_longtext_organization_desc_training-3",
    "_item": "조직 - 부서 안내(훈련 3)",
    "_comment": "",
    "ko-KR": "* 훈련 부서의 훈련 능력치가 높을수록 매일 상승하는 관리도의 양이 높습니다.",
    "en-US": "* Higher Training ability in Training Environment means higher amount of condition increased."
  },
  {
    "key": "loc_ui_longtext_organization_info_task_not_possible",
    "_item": "조직 - 부서장 없는 부서 안내",
    "_comment": "",
    "ko-KR": "부서장이 없는 부서는 업무 불가능",
    "en-US": "Department needs a head to perform work."
  },
  {
    "key": "loc_ui_longtext_organization_info_temporary_department",
    "_item": "조직 - 임시 부서 안내",
    "_comment": "",
    "ko-KR": "임시 부서는 주요 업무 할당 불가능",
    "en-US": "Temporary department cannot perform work."
  },
  {
    "key": "loc_ui_string_organization_info_no_task_ongoing",
    "_item": "조직 - 수행중인 주요 업무 없음 안내",
    "_comment": "",
    "ko-KR": "수행중인 주요 업무 없음",
    "en-US": "No Task Ongoing"
  },
  {
    "key": "loc_ui_string_organization_work_in_progress",
    "_item": "조직 - 진행중인 업무 영역 제목",
    "_comment": "",
    "ko-KR": "진행중인 업무",
    "en-US": "Works in progress"
  },
  {
    "key": "loc_ui_button_organization_assign_task",
    "_item": "조직 - 부서가 수행할 업무 선택 버튼",
    "_comment": "",
    "ko-KR": "업무 배정",
    "en-US": "Assign Task"
  },
  {
    "key": "loc_dynamic_string_organization_ongoing_task",
    "_item": "조직 - 수행중인 주요 업무 안내",
    "_comment": "",
    "ko-KR": "주요 업무 - ",
    "en-US": "Main Task - "
  },
  {
    "key": "loc_dynamic_string_organization_work_remaining_days_one",
    "_item": "조직 - 수행중인 업무 남은 기간 값 + 단위",
    "_comment": "",
    "ko-KR": "",
    "en-US": " - {{count}} day remaining"
  },
  {
    "key": "loc_dynamic_string_organization_work_remaining_days_other",
    "_item": "조직 - 수행중인 업무 남은 기간 값 + 단위",
    "_comment": "",
    "ko-KR": " - {{count}}일 남음",
    "en-US": " - {{count}} days remaining"
  },
  {
    "key": "loc_dynamic_string_organization_ongoing_task_sub",
    "_item": "조직 - 수행중인 보조 업무 안내",
    "_comment": "",
    "ko-KR": "보조 업무 -",
    "en-US": "Sub Task -"
  },
  {
    "key": "loc_ui_button_organization_abort_task",
    "_item": "조직 - 수행중인 업무 중단 버튼",
    "_comment": "",
    "ko-KR": "업무 중단",
    "en-US": "Abort Task"
  },
  {
    "key": "loc_ui_string_organization_tasks",
    "_item": "조직 - 부서의 수행 가능 업무 항목",
    "_comment": "",
    "ko-KR": "수행 가능 업무: ",
    "en-US": "Tasks Available: "
  },
  {
    "key": "loc_ui_string_organization_staff_name",
    "_item": "조직 - 직원 이름 항목",
    "_comment": "",
    "ko-KR": "이름",
    "en-US": "Name"
  },
  {
    "key": "loc_ui_string_organization_staff_wage",
    "_item": "조직 - 직원 월급 항목",
    "_comment": "",
    "ko-KR": "월급",
    "en-US": "Wage"
  },
  {
    "key": "loc_ui_string_organization_info_no_department_head",
    "_item": "조직 - 부서에 부서장 없음 안내",
    "_comment": "",
    "ko-KR": "부서장 없음",
    "en-US": "No Head"
  },
  {
    "key": "loc_ui_button_organization_department_head_change",
    "_item": "조직 - 부서의 부서장 변경 버튼",
    "_comment": "",
    "ko-KR": "부서장 변경",
    "en-US": "Change Head"
  },
  {
    "key": "loc_ui_button_organization_department_head_place",
    "_item": "조직 - 부서의 부서장 배치 버튼",
    "_comment": "",
    "ko-KR": "부서장 배치",
    "en-US": "Place Head"
  },
  {
    "key": "loc_dynamic_string_organization_info_no_staff",
    "_item": "조직 - 부서원 슬롯에 부서장 없음 안내",
    "_comment": "",
    "ko-KR": "슬롯 #{{value}}에 부서원 없음",
    "en-US": "No Staff in slot #{{value}}"
  },
  {
    "key": "loc_ui_button_organization_staff_change",
    "_item": "조직 - 부서원 슬롯에 부서원 변경 버튼",
    "_comment": "",
    "ko-KR": "부서원 변경",
    "en-US": "Change Staff"
  },
  {
    "key": "loc_ui_button_organization_staff_place",
    "_item": "조직 - 부서원 슬롯에 부서원 배치 버튼",
    "_comment": "",
    "ko-KR": "부서원 배치",
    "en-US": "Place Staff"
  },
  {
    "key": "loc_dynamic_longtext_organization_info_cannot_place_staff",
    "_item": "조직 - 부서 업무중 부서원 배치 불가 안내",
    "_comment": "",
    "ko-KR": "빈 부서원 슬롯 #{{value}}: 업무 수행중에는 부서원을 추가할 수 없습니다.",
    "en-US": "No Staff in slot #{{value}}: No department member can be added while performing task."
  },
  {
    "key": "loc_ui_string_organization_staffs_available",
    "_item": "조직 - 배치 가능 직원 영역 제목\n조직 - 직원 배치 팝업 - 배치 가능 직원 영역 제목",
    "_comment": "",
    "ko-KR": "배치 가능",
    "en-US": "Staffs Available"
  },
  {
    "key": "loc_ui_button_organization_staff_dismiss",
    "_item": "조직 - 직원 해고 버튼",
    "_comment": "",
    "ko-KR": "해고",
    "en-US": "Dismiss"
  },
  {
    "key": "loc_ui_string_organization_staff_candidates",
    "_item": "조직 - 직원 지원자 영역 제목",
    "_comment": "",
    "ko-KR": "지원자",
    "en-US": "Staffs for Hire"
  },
  {
    "key": "loc_ui_string_organization_staff_action",
    "_item": "조직 - 직원에 대한 행동 항목",
    "_comment": "",
    "ko-KR": "행동",
    "en-US": "Action"
  },
  {
    "key": "loc_ui_button_organization_staff_hire",
    "_item": "조직 - 직원 고용 버튼",
    "_comment": "",
    "ko-KR": "고용",
    "en-US": "Recruit"
  },
  {
    "key": "loc_dynamic_string_common_remaining_days",
    "_item": "조직 - 직원 지원자 고용 가능 기간 값 + 단위",
    "_comment": "",
    "ko-KR": "",
    "en-US": "{{count}} day remaining"
  },
  {
    "key": "loc_dynamic_string_common_remaining_days",
    "_item": "조직 - 직원 지원자 고용 가능 기간 값 + 단위",
    "_comment": "",
    "ko-KR": "{{count}}일 남음",
    "en-US": "{{count}} days remaining"
  },
  {
    "key": "loc_ui_string_organization_staff_hiring",
    "_item": "조직 - 직원 지원자 고용중 안내",
    "_comment": "",
    "ko-KR": "고용 진행중: ",
    "en-US": "Hiring: "
  },
  {
    "key": "loc_ui_string_organization_task_history",
    "_item": "조직 - 업무 기록 영역 제목",
    "_comment": "",
    "ko-KR": "업무 기록",
    "en-US": "Task History"
  },
  {
    "key": "loc_ul_dynamic_organization_task_history",
    "_item": "조직 - 업무 기록 내용",
    "_comment": "",
    "ko-KR": "{{tickToDateStr(turn)}} - {{department_name}} / {{pendingText}} / 부서 역량: {{Math.round(ability * 100) / 100}} / 업무 난이도: {{Math.round(difficulty * 100) / 100}} / {{getSuccessRateText(work_key)}}: {{(Math.min(ability / difficulty, 1) * 100).toFixed(2)}}% - {{resultText}}{{growthText}}",
    "en-US": "{{tickToDateStr(turn)}} - {{department_name}} / {{pendingText}} / Deprtment Ability: {{Math.round(ability * 100) / 100}} / Task Difficulty: {{Math.round(difficulty * 100) / 100}} / {{getSuccessRateText(work_key)}}: {{(Math.min(ability / difficulty, 1) * 100).toFixed(2)}}% - {{resultText}}{{growthText}}"
  },
  {
    "key": "loc_ui_string_organization_task_history_result_complete",
    "_item": "조직 - 업무 기록 내용 - 완료 + 성공률 값",
    "_comment": "",
    "ko-KR": "완료(성공률:${(success_rate * 100).toFixed(2)}%)",
    "en-US": "Complete(Chance:${(success_rate * 100).toFixed(2)}%)"
  },
  {
    "key": "loc_ui_button_common_cancel",
    "_item": "조직 - 직원 배치 팝업 - 직원 배치 취소 버튼\n조직 - 업무 선택 팝업 - 취소 버튼",
    "_comment": "",
    "ko-KR": "취소",
    "en-US": "Cancel"
  },
  {
    "key": "loc_ui_string_organization_department_reserve",
    "_item": "조직 - 직원 배치 팝업 - 예비부서 명칭",
    "_comment": "",
    "ko-KR": "예비 부서",
    "en-US": "Reserves"
  },
  {
    "key": "loc_dynamic_string_organization_place_head_preview",
    "_item": "조직 - 직원 배치 팝업 - 부서장 배치 미리보기 영역 제목",
    "_comment": "",
    "ko-KR": "{{value}}를 부서장으로 인사이동",
    "en-US": "Place {{value}} into Head"
  },
  {
    "key": "loc_dynamic_string_organization_place_head_previous_release_preview",
    "_item": "조직 - 직원 배치 팝업 - 부서장 배치 전임자 보직해제 미리보기 영역 제목",
    "_comment": "",
    "ko-KR": "전임 부서장 {{value}}를 보직 해제",
    "en-US": "Release current Head {{value}}"
  },
  {
    "key": "loc_dynamic_string_organization_place_head_previous_staff_preview",
    "_item": "조직 - 직원 배치 팝업 - 부서장 배치 전임자 부서원으로 미리보기 영역 제목",
    "_comment": "",
    "ko-KR": "전임 부서장 {{value}}를 부서원으로 인사이동",
    "en-US": "Place current Head {{value}} into Staff"
  },
  {
    "key": "loc_dynamic_string_organization_place_staff_preview",
    "_item": "조직 - 직원 배치 팝업 - 부서원 배치 미리보기 영역 제목",
    "_comment": "",
    "ko-KR": "{{value}}를 부서원으로 인사이동",
    "en-US": "Place {{value}} into Staff"
  },
  {
    "key": "loc_dynamic_string_organization_place_staff_previous_release_preview",
    "_item": "조직 - 직원 배치 팝업 - 부서원 배치 전임자 보직해제 미리보기 영역 제목",
    "_comment": "",
    "ko-KR": "전임 부서원 {{value}}를 보직 해제",
    "en-US": "Release current Staff {{value}}"
  },
  {
    "key": "loc_dynamic_string_organization_release_preview",
    "_item": "조직 - 직원 배치 팝업 - 직원 보직해제 미리보기 영역 제목",
    "_comment": "",
    "ko-KR": "{{value}}를 보직 해제",
    "en-US": "Release {{value}} from current position"
  },
  {
    "key": "loc_ui_string_organization_department_ability_change",
    "_item": "조직 - 직원 배치 팝업 - 부서 역량 변화 항목",
    "_comment": "",
    "ko-KR": "부서 역량 변화",
    "en-US": "Change in Department Ability"
  },
  {
    "key": "loc_ui_string_organization_task_efficiency_change",
    "_item": "조직 - 직원 배치 팝업 - 업무 효율 변화 항목",
    "_comment": "",
    "ko-KR": "업무 효율 변화",
    "en-US": "Change in Task Efficiency"
  },
  {
    "key": "loc_ui_button_organization_apply_change",
    "_item": "조직 - 직원 배치 팝업 - 변경 승인 항목",
    "_comment": "",
    "ko-KR": "변경 승인",
    "en-US": "Apply Change"
  },
  {
    "key": "loc_ui_string_organization_department_head",
    "_item": "조직 - 직원 배치 팝업 - 직원의 현재 부서 값(부서장)",
    "_comment": "",
    "ko-KR": "부서장",
    "en-US": "Head"
  },
  {
    "key": "loc_ui_string_organization_department_staff",
    "_item": "조직 - 직원 배치 팝업 - 직원의 현재 부서 값(부서원)",
    "_comment": "",
    "ko-KR": "부서원",
    "en-US": "Staff"
  },
  {
    "key": "loc_dynamic_string_organizatiom_rate_increase",
    "_item": "조직 - 직원 배치 팝업 - 변화율(증가)",
    "_comment": "",
    "ko-KR": " (${{value}}% 증가)",
    "en-US": " (${{value}}% increase)"
  },
  {
    "key": "loc_dynamic_string_organizatiom_rate_decrease",
    "_item": "조직 - 직원 배치 팝업 - 변화율(감소)",
    "_comment": "",
    "ko-KR": " (${{value}}% 감소)",
    "en-US": " (${{value}}% decrease)"
  },
  {
    "key": "loc_dynamic_string_organizatiom_amount_increase",
    "_item": "조직 - 직원 배치 팝업 - 변화량(증가)",
    "_comment": "",
    "ko-KR": " (${{value}} 증가)",
    "en-US": " (${{value}} increase)"
  },
  {
    "key": "loc_dynamic_string_organizatiom_amount_decrease",
    "_item": "조직 - 직원 배치 팝업 - 변화량(감소)",
    "_comment": "",
    "ko-KR": " (${{value}} 감소)",
    "en-US": " (${{value}} decrease)"
  },
  {
    "key": "loc_dynamic_string_organization_work_item_tier",
    "_item": "조직 - 직원 배치 팝업 - 대표 업무(장비 등급)",
    "_comment": "",
    "ko-KR": " ($t(loc_dynamic_string_common_item_tier){{value}})",
    "en-US": " ($t(loc_dynamic_string_common_item_tier){{value}})"
  },
  {
    "key": "loc_dynamic_string_organization_work_item_type",
    "_item": "조직 - 직원 배치 팝업 - 대표 업무(장비 종류)",
    "_comment": "",
    "ko-KR": " ({{value}})",
    "en-US": " ({{value}})"
  },
  {
    "key": "loc_ui_title_organization_select_task",
    "_item": "조직 - 업무 선택 팝업 - 제목",
    "_comment": "",
    "ko-KR": "부서 업무",
    "en-US": "Department Task"
  },
  {
    "key": "loc_ui_longtext_organization_select_task",
    "_item": "조직 - 업무 선택 팝업 - 안내",
    "_comment": "",
    "ko-KR": "진행할 업무를 선택해 주세요.",
    "en-US": "Select task to perform."
  },
  {
    "key": "loc_ui_string_common_not_applicable",
    "_item": "조직 - 업무 선택 팝업 - 선택된 업무 없음",
    "_comment": "",
    "ko-KR": "N/A",
    "en-US": "N/A"
  },
  {
    "key": "loc_ui_string_organization_task_required_ability_type",
    "_item": "조직 - 업무 선택 팝업 - 요구 능력치 항목 + 값",
    "_comment": "",
    "ko-KR": "요구 역량 종류: ",
    "en-US": "Required Ability Type: "
  },
  {
    "key": "loc_ui_string_organization_task_department_required_ability",
    "_item": "조직 - 업무 선택 팝업 - 부서의 요구된 능력치 항목 + 값",
    "_comment": "",
    "ko-KR": "부서의 해당 역량: ",
    "en-US": "Corresponding Department Ability: "
  },
  {
    "key": "loc_ui_string_organization_task_difficulty",
    "_item": "조직 - 업무 선택 팝업 - 업무 난이도 항목",
    "_comment": "",
    "ko-KR": "업무 난이도: ",
    "en-US": "Task Difficulty: "
  },
  {
    "key": "loc_ui_string_organization_task_duration",
    "_item": "조직 - 업무 선택 팝업 - 업무 수행 기간 항목",
    "_comment": "",
    "ko-KR": "수행 기간: ",
    "en-US": "Task Duration: "
  },
  {
    "key": "loc_dynamic_string_organization_task_duration_days_text_one",
    "_item": "조직 - 업무 선택 팝업 - 업무 수행 기간(일)",
    "_comment": "",
    "ko-KR": "",
    "en-US": " {{count}} day"
  },
  {
    "key": "loc_dynamic_string_organization_task_duration_days_text_other",
    "_item": "조직 - 업무 선택 팝업 - 업무 수행 기간(일)",
    "_comment": "",
    "ko-KR": " {{count}}일",
    "en-US": " {{count}} days"
  },
  {
    "key": "loc_dynamic_string_organization_task_duration_hours_text_one",
    "_item": "조직 - 업무 선택 팝업 - 업무 수행 기간(시간)",
    "_comment": "",
    "ko-KR": "",
    "en-US": " {{count}} hour"
  },
  {
    "key": "loc_dynamic_string_organization_task_duration_hours_text_other",
    "_item": "조직 - 업무 선택 팝업 - 업무 수행 기간(시간)",
    "_comment": "",
    "ko-KR": " {{count}}시간",
    "en-US": " {{count}} hours"
  },
  {
    "key": "loc_dynamic_string_organization_task_duration_shortened",
    "_item": "조직 - 업무 선택 팝업 - 업무 수행 기간 변화율(단축)",
    "_comment": "",
    "ko-KR": " ({{value}}% 단축됨)",
    "en-US": " ({{value}}% shortened)"
  },
  {
    "key": "loc_dynamic_string_organization_task_duration_elongated",
    "_item": "조직 - 업무 선택 팝업 - 업무 수행 기간 변화율(연장)",
    "_comment": "",
    "ko-KR": " ({{value}}% 연장됨)",
    "en-US": " ({{value}}% elongated)"
  },
  {
    "key": "loc_ui_button_organization_perform_task",
    "_item": "조직 - 업무 선택 팝업 - 업무 수행 버튼",
    "_comment": "",
    "ko-KR": "업무 수행",
    "en-US": "Perform Task"
  },
  {
    "key": "loc_ui_title_notification_task_complete",
    "_item": "알림 - 부서 업무 완료 - 제목",
    "_comment": "",
    "ko-KR": "부서 업무 완료",
    "en-US": "Task Complete"
  },
  {
    "key": "loc_ui_button_common_confirm",
    "_item": "알림 - 부서 업무 완료 - 확인 버튼",
    "_comment": "",
    "ko-KR": "확인",
    "en-US": "Confirm"
  },
  {
    "key": "loc_ui_button_notification_do_not_show_again",
    "_item": "알림 - 부서 업무 완료 - 다시 보지 않기 버튼\n임무 시작 모달 - 다시 보지 않기 버튼",
    "_comment": "",
    "ko-KR": "다시 보지 않기",
    "en-US": "Do not show again"
  },
  {
    "key": "loc_ui_title_notification_play_tip",
    "_item": "알림 - 플레이 팁 - 제목",
    "_comment": "",
    "ko-KR": "플레이 팁",
    "en-US": "Play Advice"
  },
  {
    "key": "loc_ui_longtext_notification_play_tip_high_difficulty",
    "_item": "알림 - 플레이 팁 - 높은 난이도 임무 경고 툴팁",
    "_comment": "",
    "ko-KR": "실패할 가능성이 높습니다. 투입하는 용병의 수를 늘리거나, 더 좋은 장비로 무장시키거나, 영입과 훈련으로 수준을 높이십시오.",
    "en-US": "Very likely to fail mission. Try to put more agents, powerful weapons, or power up agents."
  },
  {
    "key": "loc_ui_button_common_proceed_anyway",
    "_item": "알림 - 플레이 팁 - 높은 난이도 임무 진행하기 버튼",
    "_comment": "",
    "ko-KR": "아무튼 진행하기",
    "en-US": "Proceed anyway"
  },
  {
    "key": "loc_ui_button_common_reconsider",
    "_item": "알림 - 플레이 팁 - 높은 난이도 임무 돌아가기 버튼",
    "_comment": "",
    "ko-KR": "재고하기",
    "en-US": "Reconsider"
  },
  {
    "key": "loc_ui_longtext_notification_task_complete",
    "_item": "알림 - 부서 업무 완료 - 안내",
    "_comment": "이거 어느 부서 업무였는지 알려줬으면 함",
    "ko-KR": "다음 부서 업무가 완료되었습니다: ",
    "en-US": "Following task is complete:"
  },
  {
    "key": "loc_ui_longtext_notification_task_complete_department",
    "_item": "알림 - 부서 업무 완료 - 부서 갱신",
    "_comment": "",
    "ko-KR": "부서 정보가 갱신되었습니다.",
    "en-US": "Department information is updated."
  },
  {
    "key": "loc_ui_longtext_notification_task_complete_market_staff",
    "_item": "알림 - 부서 업무 완료 - 새 직원 지원자",
    "_comment": "",
    "ko-KR": "새로운 직원이 영입 가능 목록에 추가되었습니다.",
    "en-US": "New candidate for staff is found."
  },
  {
    "key": "loc_ui_longtext_notification_task_complete_new_staff",
    "_item": "알림 - 부서 업무 완료 - 새 직원",
    "_comment": "",
    "ko-KR": "새로운 직원을 영입했습니다.",
    "en-US": "Hired new staff."
  },
  {
    "key": "loc_ui_longtext_notification_task_complete_market",
    "_item": "알림 - 부서 업무 완료 - 새 시장 물품",
    "_comment": "",
    "ko-KR": "상점에 새로운 물건이 입고되었습니다.",
    "en-US": "New equipment is listed on market."
  },
  {
    "key": "loc_ui_longtext_notification_task_complete_recruit",
    "_item": "알림 - 부서 업무 완료 - 새 용병 지원자",
    "_comment": "",
    "ko-KR": "새로운 용병이 영입 가능 목록에 추가되었습니다.",
    "en-US": "New mercenary is available for hire."
  },
  {
    "key": "loc_ui_longtext_notification_task_complete_intel",
    "_item": "알림 - 부서 업무 완료 - 새 의뢰",
    "_comment": "",
    "ko-KR": "새로운 의뢰가 의뢰 목록에 추가되었습니다.",
    "en-US": "New contract is available."
  },
  {
    "key": "loc_ui_title_menu_market",
    "_item": "시장 메뉴 명칭",
    "_comment": "",
    "ko-KR": "시장",
    "en-US": "Market"
  },
  {
    "key": "loc_ui_string_common_item_list",
    "_item": "창고 - 장비 목록 영역 제목",
    "_comment": "",
    "ko-KR": "장비 목록",
    "en-US": "Item List"
  },
  {
    "key": "loc_ui_string_market_item_list",
    "_item": "시장 - 장비 목록 영역 제목",
    "_comment": "",
    "ko-KR": "구매 가능한 장비",
    "en-US": "Items on Market"
  },
  {
    "key": "loc_ui_longtext_common_list_empty",
    "_item": "시장 - 장비 목록 비어있음 안내\n의뢰 - 요원 목록 비어있음 안내",
    "_comment": "",
    "ko-KR": "목록이 비었습니다.",
    "en-US": "List is empty."
  },
  {
    "key": "loc_dynamic_string_common_item_tier",
    "_item": "시장 - 아이템 티어에서 사용하는 단위",
    "_comment": "",
    "ko-KR": "★",
    "en-US": "★"
  },
  {
    "key": "loc_ui_string_common_firearm_handgun",
    "_item": "시장 - 총기 종류(권총)",
    "_comment": "",
    "ko-KR": "권총(HG)",
    "en-US": "Pistol(HG)"
  },
  {
    "key": "loc_ui_string_common_firearm_handgun_tier_1",
    "_item": "시장 - 총기(권총, 1티어)",
    "_comment": "",
    "ko-KR": "1911",
    "en-US": "1911"
  },
  {
    "key": "loc_ui_string_common_firearm_handgun_tier_2",
    "_item": "시장 - 총기(권총, 2티어)",
    "_comment": "",
    "ko-KR": "Mk22",
    "en-US": "Mk22"
  },
  {
    "key": "loc_ui_string_common_firearm_handgun_tier_3",
    "_item": "시장 - 총기(권총, 3티어)",
    "_comment": "",
    "ko-KR": "BHP",
    "en-US": "BHP"
  },
  {
    "key": "loc_ui_string_common_firearm_handgun_tier_4",
    "_item": "시장 - 총기(권총, 4티어)",
    "_comment": "",
    "ko-KR": "92",
    "en-US": "92"
  },
  {
    "key": "loc_ui_string_common_firearm_shotgun",
    "_item": "시장 - 총기 종류(산탄총)",
    "_comment": "",
    "ko-KR": "산탄총(SG)",
    "en-US": "Shotgun(SG)"
  },
  {
    "key": "loc_ui_string_common_firearm_shotgun_tier_1",
    "_item": "시장 - 총기(산탄총, 1티어)",
    "_comment": "",
    "ko-KR": "Auto-5",
    "en-US": "Auto-5"
  },
  {
    "key": "loc_ui_string_common_firearm_shotgun_tier_2",
    "_item": "시장 - 총기(산탄총, 2티어)",
    "_comment": "",
    "ko-KR": "M37",
    "en-US": "M37"
  },
  {
    "key": "loc_ui_string_common_firearm_shotgun_tier_3",
    "_item": "시장 - 총기(산탄총, 3티어)",
    "_comment": "",
    "ko-KR": "M870",
    "en-US": "M870"
  },
  {
    "key": "loc_ui_string_common_firearm_shotgun_tier_4",
    "_item": "시장 - 총기(산탄총, 4티어)",
    "_comment": "",
    "ko-KR": "M590",
    "en-US": "M590"
  },
  {
    "key": "loc_ui_string_common_firearm_shotgun_tier_5",
    "_item": "시장 - 총기(산탄총, 5티어)",
    "_comment": "",
    "ko-KR": "SPAS-15",
    "en-US": "SPAS-15"
  },
  {
    "key": "loc_ui_string_common_firearm_submachine_gun",
    "_item": "시장 - 총기 종류(기관단총)",
    "_comment": "",
    "ko-KR": "기관단총(SMG)",
    "en-US": "Submachine Gun(SMG)"
  },
  {
    "key": "loc_ui_string_common_firearm_submachine_gun_tier_1",
    "_item": "시장 - 총기(기관단총, 1티어)",
    "_comment": "",
    "ko-KR": "MAC-10",
    "en-US": "MAC-10"
  },
  {
    "key": "loc_ui_string_common_firearm_submachine_gun_tier_2",
    "_item": "시장 - 총기(기관단총, 2티어)",
    "_comment": "",
    "ko-KR": "스털링 L2A1",
    "en-US": "Sterling L2A1"
  },
  {
    "key": "loc_ui_string_common_firearm_submachine_gun_tier_3",
    "_item": "시장 - 총기(기관단총, 3티어)",
    "_comment": "",
    "ko-KR": "PM125",
    "en-US": "PM125"
  },
  {
    "key": "loc_ui_string_common_firearm_submachine_gun_tier_4",
    "_item": "시장 - 총기(기관단총, 4티어)",
    "_comment": "",
    "ko-KR": "MPL",
    "en-US": "MPL"
  },
  {
    "key": "loc_ui_string_common_firearm_submachine_gun_tier_5",
    "_item": "시장 - 총기(기관단총, 5티어)",
    "_comment": "",
    "ko-KR": "MP5A3",
    "en-US": "MP5A3"
  },
  {
    "key": "loc_ui_string_common_firearm_assult_rifle",
    "_item": "시장 - 총기 종류(돌격소총)",
    "_comment": "",
    "ko-KR": "돌격소총(AR)",
    "en-US": "Assult Rifle(AR)"
  },
  {
    "key": "loc_ui_string_common_firearm_assult_rifle_tier_1",
    "_item": "시장 - 총기(돌격소총, 1티어)",
    "_comment": "",
    "ko-KR": "AR-15",
    "en-US": "AR-15"
  },
  {
    "key": "loc_ui_string_common_firearm_assult_rifle_tier_2",
    "_item": "시장 - 총기(돌격소총, 2티어)",
    "_comment": "",
    "ko-KR": "HK33",
    "en-US": "HK33"
  },
  {
    "key": "loc_ui_string_common_firearm_assult_rifle_tier_3",
    "_item": "시장 - 총기(돌격소총, 3티어)",
    "_comment": "",
    "ko-KR": "GAU-5",
    "en-US": "GAU-5"
  },
  {
    "key": "loc_ui_string_common_firearm_assult_rifle_tier_4",
    "_item": "시장 - 총기(돌격소총, 4티어)",
    "_comment": "",
    "ko-KR": "FNC-76",
    "en-US": "FNC-76"
  },
  {
    "key": "loc_ui_string_common_firearm_assult_rifle_tier_5",
    "_item": "시장 - 총기(돌격소총, 5티어)",
    "_comment": "",
    "ko-KR": "C7A1",
    "en-US": "C7A1"
  },
  {
    "key": "loc_ui_string_common_firearm_designated_marksman_rifle",
    "_item": "시장 - 총기 종류(지정사수소총)",
    "_comment": "",
    "ko-KR": "지정사수소총(DMR)",
    "en-US": "Designated Marksman Rifle(DMR)"
  },
  {
    "key": "loc_ui_string_common_firearm_designated_marksman_rifle_tier_1",
    "_item": "시장 - 총기(지정사수소총, 1티어)",
    "_comment": "",
    "ko-KR": "FN-49",
    "en-US": "FN-49"
  },
  {
    "key": "loc_ui_string_common_firearm_designated_marksman_rifle_tier_2",
    "_item": "시장 - 총기(지정사수소총, 2티어)",
    "_comment": "",
    "ko-KR": "L1A1 SLR",
    "en-US": "L1A1 SLR"
  },
  {
    "key": "loc_ui_string_common_firearm_designated_marksman_rifle_tier_3",
    "_item": "시장 - 총기(지정사수소총, 3티어)",
    "_comment": "",
    "ko-KR": "M21",
    "en-US": "M21"
  },
  {
    "key": "loc_ui_string_common_firearm_designated_marksman_rifle_tier_4",
    "_item": "시장 - 총기(지정사수소총, 4티어)",
    "_comment": "",
    "ko-KR": "G3/SG-1",
    "en-US": "G3/SG-1"
  },
  {
    "key": "loc_ui_string_common_firearm_designated_marksman_rifle_tier_5",
    "_item": "시장 - 총기(지정사수소총, 5티어)",
    "_comment": "",
    "ko-KR": "PSG-1",
    "en-US": "PSG-1"
  },
  {
    "key": "loc_ui_string_common_firearm_sniper_rifle",
    "_item": "시장 - 총기 종류(저격소총)",
    "_comment": "",
    "ko-KR": "저격소총(SR)",
    "en-US": "Sniper Rifle(SR)"
  },
  {
    "key": "loc_ui_string_common_firearm_sniper_rifle_tier_1",
    "_item": "시장 - 총기(저격소총, 1티어)",
    "_comment": "",
    "ko-KR": "모델 70",
    "en-US": "Model 70"
  },
  {
    "key": "loc_ui_string_common_firearm_sniper_rifle_tier_2",
    "_item": "시장 - 총기(저격소총, 2티어)",
    "_comment": "",
    "ko-KR": "L42A1",
    "en-US": "L42A1"
  },
  {
    "key": "loc_ui_string_common_firearm_sniper_rifle_tier_3",
    "_item": "시장 - 총기(저격소총, 3티어)",
    "_comment": "",
    "ko-KR": "파커 헤일 M82",
    "en-US": "Parker-Hale M82"
  },
  {
    "key": "loc_ui_string_common_firearm_sniper_rifle_tier_4",
    "_item": "시장 - 총기(저격소총, 4티어)",
    "_comment": "",
    "ko-KR": "M40A1",
    "en-US": "M40A1"
  },
  {
    "key": "loc_ui_string_common_firearm_sniper_rifle_tier_5",
    "_item": "시장 - 총기(저격소총, 5티어)",
    "_comment": "",
    "ko-KR": "AW",
    "en-US": "AW"
  },
  {
    "key": "loc_ui_string_common_protection_tier_0",
    "_item": "시장 - 보호장구(없음)",
    "_comment": "",
    "ko-KR": "없음",
    "en-US": "None"
  },
  {
    "key": "loc_ui_string_common_protection_tier_1",
    "_item": "시장 - 보호장구(티어1)",
    "_comment": "",
    "ko-KR": "방탄판($t(loc_data_string_perktree_perk_group_name_bravery) 4 필요)",
    "en-US": "Bulletproof Plate"
  },
  {
    "key": "loc_ui_string_common_protection_tier_2",
    "_item": "시장 - 보호장구(티어2)",
    "_comment": "",
    "ko-KR": "급조 방탄조끼($t(loc_data_string_perktree_perk_group_name_bravery) 8 필요)",
    "en-US": "Improvised Bulletproof Vest"
  },
  {
    "key": "loc_ui_string_common_protection_tier_3",
    "_item": "시장 - 보호장구(티어3)",
    "_comment": "",
    "ko-KR": "정규 방탄조끼($t(loc_data_string_perktree_perk_group_name_bravery) 12 필요)",
    "en-US": "Reglar Bulletproof Vest"
  },
  {
    "key": "loc_ui_string_common_protection_tier_4",
    "_item": "시장 - 보호장구(티어4)",
    "_comment": "",
    "ko-KR": "복합 방탄조끼($t(loc_data_string_perktree_perk_group_name_bravery) 16 필요)",
    "en-US": "Composite Bulletproof Vest"
  },
  {
    "key": "loc_ui_string_common_grenade_fragment",
    "_item": "시장 - 수류탄 종류(세열 수류탄)",
    "_comment": "수류탄 티어 생긴 이후 비교 관련 기획이 업데이트되지 않았음. 언제 할 수 있을지 봐야",
    "ko-KR": "세열 수류탄",
    "en-US": "Fragment Grenade"
  },
  {
    "key": "loc_ui_string_common_granade_tier_0",
    "_item": "시장 - 수류탄(없음)",
    "_comment": "",
    "ko-KR": "없음",
    "en-US": "None"
  },
  {
    "key": "loc_ui_string_common_grenade_fragment_tier_1",
    "_item": "시장 - 세열 수류탄(티어1)",
    "_comment": "",
    "ko-KR": "RGD-ST Mk1",
    "en-US": "RGD-ST Mk1"
  },
  {
    "key": "loc_ui_string_common_grenade_fragment_tier_2",
    "_item": "시장 - 세열 수류탄(티어2)",
    "_comment": "",
    "ko-KR": "RGD-ST Mk2",
    "en-US": "RGD-ST Mk2"
  },
  {
    "key": "loc_ui_string_common_grenade_fragment_tier_3",
    "_item": "시장 - 세열 수류탄(티어3)",
    "_comment": "",
    "ko-KR": "RGO-ST",
    "en-US": "RGO-ST"
  },
  {
    "key": "loc_ui_string_common_grenade_fragment_impact",
    "_item": "시장 - 수류탄 종류(충격신관 세열 수류탄)",
    "_comment": "",
    "ko-KR": "충격신관 세열 수류탄",
    "en-US": "Impact Fuse Fragment Grenade"
  },
  {
    "key": "loc_ui_string_common_grenade_fragment_impact_tier_1",
    "_item": "시장 - 충격신관 세열 수류탄(티어1)",
    "_comment": "",
    "ko-KR": "RGN-I Mk1",
    "en-US": "RGN-I Mk1"
  },
  {
    "key": "loc_ui_string_common_grenade_fragment_impact_tier_2",
    "_item": "시장 - 충격신관 세열 수류탄(티어2)",
    "_comment": "",
    "ko-KR": "RGN-I Mk2",
    "en-US": "RGN-I Mk2"
  },
  {
    "key": "loc_ui_string_common_grenade_high_explosive",
    "_item": "시장 - 수류탄 종류(고폭 수류탄)",
    "_comment": "",
    "ko-KR": "고폭 수류탄",
    "en-US": "High Explosive Fragment Grenade"
  },
  {
    "key": "loc_ui_string_common_grenade_high_explosive_tier_1",
    "_item": "시장 - 고폭 수류탄(티어1)",
    "_comment": "",
    "ko-KR": "M3-HE Mk1",
    "en-US": "M3-HE Mk1"
  },
  {
    "key": "loc_ui_string_common_grenade_high_explosive_tier_2",
    "_item": "시장 - 고폭 수류탄(티어2)",
    "_comment": "",
    "ko-KR": "M3-HE Mk2",
    "en-US": "M3-HE Mk2"
  },
  {
    "key": "loc_ui_string_common_grenade_stun",
    "_item": "시장 - 수류탄 종류(섬광 수류탄)",
    "_comment": "",
    "ko-KR": "섬광 수류탄",
    "en-US": "Stun Grenade"
  },
  {
    "key": "loc_ui_string_common_grenade_stun_tier_1",
    "_item": "시장 - 섬광 수류탄(티어1)",
    "_comment": "",
    "ko-KR": "FB-60 Mk1",
    "en-US": "FB-60 Mk1"
  },
  {
    "key": "loc_ui_string_common_grenade_stun_tier_2",
    "_item": "시장 - 섬광 수류탄(티어2)",
    "_comment": "",
    "ko-KR": "FB-60 Mk2",
    "en-US": "FB-60 Mk2"
  },
  {
    "key": "loc_ui_string_common_grenade_stun_tier_3",
    "_item": "시장 - 섬광 수류탄(티어3)",
    "_comment": "",
    "ko-KR": "FB-60/9",
    "en-US": "FB-60/9"
  },
  {
    "key": "loc_ui_string_market_expiration_one",
    "_item": "시장 - 리스팅 만료까지 남은 시간 안내",
    "_comment": "",
    "ko-KR": "",
    "en-US": " / expires in a day"
  },
  {
    "key": "loc_ui_string_market_expiration_other",
    "_item": "시장 - 리스팅 만료까지 남은 시간 안내",
    "_comment": "",
    "ko-KR": " / 리스팅 만료까지 {{count}}일 남음",
    "en-US": " / {{count}} days left until expiration"
  },
  {
    "key": "loc_ui_button_market_purchase",
    "_item": "시장 - 구입 버튼",
    "_comment": "게임에 업무 수행 버튼으로 들어가 있음",
    "ko-KR": "구입",
    "en-US": "Purchase"
  },
  {
    "key": "lic_ui_longtext_market_confirm_no_equippable_agent",
    "_item": "시장 - 착용할 수 있는 용병 없음 안내",
    "_comment": "현재 작동하지 않음",
    "ko-KR": "아이템을 착용할 수 있는 용병이 없습니다. 그래도 구매 업무를 진행하시겠습니까?",
    "en-US": "Currently we don't have agent who can equip this item. Proceed anyway?"
  },
  {
    "key": "loc_ui_button_common_yes",
    "_item": "시장 - 착용할 수 있는 용병 없음 안내 속행 버튼",
    "_comment": "현재 작동하지 않음",
    "ko-KR": "예",
    "en-US": "Yes"
  },
  {
    "key": "loc_ui_button_common_no",
    "_item": "시장 - 착용할 수 있는 용병 없음 안내 취소 버튼",
    "_comment": "현재 작동하지 않음",
    "ko-KR": "아니오",
    "en-US": "No"
  },
  {
    "key": "loc_ui_string_common_market_item_base",
    "_item": "시장 - 아이템 미리보기 - 비교할 대상 없음\n창고 -  아이템 미리보기 - 비교할 대상 없음",
    "_comment": "",
    "ko-KR": "시장 장비",
    "en-US": "Item on Market"
  },
  {
    "key": "loc_ui_string_common_market_item_compare_agent_change",
    "_item": "시장 - 아이템 미리보기 - 비교할 대상 바꾸기 안내\n창고 -  아이템 미리보기 - 비교할 대상 바꾸기 안내",
    "_comment": "",
    "ko-KR": "(우클릭으로 비교할 요원 변경)",
    "en-US": "(Right click to cycle through agents)"
  },
  {
    "key": "loc_ui_string_common_compare_target",
    "_item": "시장 - 아이템 미리보기 - 비교할 대상\n창고 -  아이템 미리보기 - 비교할 대상",
    "_comment": "",
    "ko-KR": "{{value}} 착용 장비",
    "en-US": "Item on {{value}}"
  },
  {
    "key": "loc_ui_string_common_compare_name",
    "_item": "시장 - 아이템 미리보기 - 명칭\n창고 -  아이템 미리보기 - 명칭",
    "_comment": "",
    "ko-KR": "명칭",
    "en-US": "Name"
  },
  {
    "key": "loc_ui_string_common_compare_firearm_class_and_tier",
    "_item": "시장 - 아이템 미리보기 - 총기 분류와 등급\n창고 -  아이템 미리보기 - 총기 분류와 등급",
    "_comment": "",
    "ko-KR": "분류 및 등급",
    "en-US": "Class & Tier"
  },
  {
    "key": "loc_ui_string_common_compare_firearm_ammo",
    "_item": "시장 - 아이템 미리보기 - 총기 장탄수\n창고 -  아이템 미리보기 - 총기 장탄수",
    "_comment": "",
    "ko-KR": "장탄수",
    "en-US": "Ammo"
  },
  {
    "key": "loc_ui_string_common_compare_firearm_rpm",
    "_item": "시장 - 아이템 미리보기 - 총기 RPM\n창고 -  아이템 미리보기 - 총기 RPM",
    "_comment": "",
    "ko-KR": "RPM",
    "en-US": "RPM"
  },
  {
    "key": "loc_ui_string_common_compare_firearm_damage",
    "_item": "시장 - 아이템 미리보기 - 총기 피해량\n창고 -  아이템 미리보기 - 총기 피해량",
    "_comment": "",
    "ko-KR": "피해량",
    "en-US": "Damage"
  },
  {
    "key": "loc_ui_string_common_compare_firearm_recoil",
    "_item": "시장 - 아이템 미리보기 - 총기 반동\n창고 -  아이템 미리보기 - 총기 반동",
    "_comment": "",
    "ko-KR": "반동",
    "en-US": "Recoil"
  },
  {
    "key": "loc_ui_string_common_compare_firearm_recoil_weak",
    "_item": "시장 - 아이템 미리보기 - 총기 반동(약함)\n창고 -  아이템 미리보기 - 총기 반동(약함)",
    "_comment": "",
    "ko-KR": "약함",
    "en-US": "Weak"
  },
  {
    "key": "loc_ui_string_common_compare_firearm_recoil_medium",
    "_item": "시장 - 아이템 미리보기 - 총기 반동(보통)\n창고 -  아이템 미리보기 - 총기 반동(보통)",
    "_comment": "",
    "ko-KR": "보통",
    "en-US": "Medium"
  },
  {
    "key": "loc_ui_string_common_compare_firearm_recoil_strong",
    "_item": "시장 - 아이템 미리보기 - 총기 반동(셈)\n창고 -  아이템 미리보기 - 총기 반동(셈)",
    "_comment": "",
    "ko-KR": "셈",
    "en-US": "Strong"
  },
  {
    "key": "loc_ui_string_common_compare_firearm_recoil_verystrong",
    "_item": "시장 - 아이템 미리보기 - 총기 반동(아주 셈)\n창고 -  아이템 미리보기 - 총기 반동(아주 셈)",
    "_comment": "",
    "ko-KR": "아주 셈",
    "en-US": "Very Strong"
  },
  {
    "key": "loc_ui_string_common_compare_firearm_recoil_control",
    "_item": "시장 - 아이템 미리보기 - 총기 반동 제어\n창고 -  아이템 미리보기 - 총기 반동 제어",
    "_comment": "",
    "ko-KR": "반동 제어",
    "en-US": "Recoil Control"
  },
  {
    "key": "loc_ui_string_common_compare_firearm_range",
    "_item": "시장 - 아이템 미리보기 - 총기 사거리\n창고 -  아이템 미리보기 - 총기 사거리",
    "_comment": "",
    "ko-KR": "사거리",
    "en-US": "Range"
  },
  {
    "key": "loc_ui_string_common_compare_firearm_accuracy",
    "_item": "시장 - 아이템 미리보기 - 총기 정확성\n창고 -  아이템 미리보기 - 총기 정확성",
    "_comment": "",
    "ko-KR": "정확성",
    "en-US": "Accuracy"
  },
  {
    "key": "loc_ui_string_common_compare_protection_tier",
    "_item": "시장 - 아이템 미리보기 - 보호장구 등급\n창고 -  아이템 미리보기 - 보호장구 등급",
    "_comment": "",
    "ko-KR": "등급",
    "en-US": "Tier"
  },
  {
    "key": "loc_ui_string_common_compare_protection_durability",
    "_item": "시장 - 아이템 미리보기 - 보호장구 내구도\n창고 -  아이템 미리보기 - 보호장구 내구도",
    "_comment": "",
    "ko-KR": "내구도",
    "en-US": "Durability"
  },
  {
    "key": "loc_ui_string_common_compare_protection_coverage",
    "_item": "시장 - 아이템 미리보기 - 보호장구 방호력\n창고 -  아이템 미리보기 - 보호장구 방호력",
    "_comment": "",
    "ko-KR": "방호력",
    "en-US": "Coverage"
  },
  {
    "key": "loc_ui_string_common_compare_protection_coverage_low",
    "_item": "시장 - 아이템 미리보기 - 보호장구 방호력(낮음)\n창고 -  아이템 미리보기 - 보호장구 방호력(낮음)",
    "_comment": "",
    "ko-KR": "낮음",
    "en-US": "Low"
  },
  {
    "key": "loc_ui_string_common_compare_protection_coverage_adequate",
    "_item": "시장 - 아이템 미리보기 - 보호장구 방호력(적당함)\n창고 -  아이템 미리보기 - 보호장구 방호력(적당함)",
    "_comment": "",
    "ko-KR": "적당함",
    "en-US": "Adequate"
  },
  {
    "key": "loc_ui_string_common_compare_protection_coverage_high",
    "_item": "시장 - 아이템 미리보기 - 보호장구 방호력(높음)\n창고 -  아이템 미리보기 - 보호장구 방호력(높음)",
    "_comment": "",
    "ko-KR": "높음",
    "en-US": "High"
  },
  {
    "key": "loc_ui_string_common_compare_protection_coverage_veryhigh",
    "_item": "시장 - 아이템 미리보기 - 보호장구 방호력(매우 높음)\n창고 -  아이템 미리보기 - 보호장구 방호력(매우 높음)",
    "_comment": "",
    "ko-KR": "매우 높음",
    "en-US": "Very High"
  },
  {
    "key": "loc_dynamic_string_common_item_compare_higher",
    "_item": "아이템 비교에서 사용하는 아이콘(높음)",
    "_comment": "",
    "ko-KR": "▲",
    "en-US": "▲"
  },
  {
    "key": "loc_dynamic_string_common_item_compare_equal",
    "_item": "아이템 비교에서 사용하는 아이콘(차이없음)",
    "_comment": "",
    "ko-KR": "-",
    "en-US": "-"
  },
  {
    "key": "loc_dynamic_string_common_item_compare_lower",
    "_item": "아이템 비교에서 사용하는 아이콘(낮음)",
    "_comment": "",
    "ko-KR": "▼",
    "en-US": "▼"
  },
  {
    "key": "loc_ui_string_market_disabled_insufficient_cash",
    "_item": "시장 - 아이템 상호작용 불가 이유 안내(현금 부족)",
    "_comment": "",
    "ko-KR": "현금이 부족합니다.",
    "en-US": "Insufficient cash."
  },
  {
    "key": "loc_ui_string_market_disabled_task_ongoing_one",
    "_item": "시장 - 아이템 상호작용 불가 이유 안내(구매 업무중)",
    "_comment": "",
    "ko-KR": "구매 진행 중: {{count}}일 남음",
    "en-US": "Purchase ongoing: {{count}} day left"
  },
  {
    "key": "loc_ui_string_market_disabled_task_ongoing_other",
    "_item": "시장 - 아이템 상호작용 불가 이유 안내(구매 업무중)",
    "_comment": "",
    "ko-KR": "구매 진행 중: {{count}}일 남음",
    "en-US": "Purchase ongoing: {{count}} days left"
  },
  {
    "key": "loc_ui_string_market_disabled_milestone",
    "_item": "시장 - 아이템 상호작용 불가 이유 안내(마일스톤 미도달)",
    "_comment": "",
    "ko-KR": "마일스톤 #{{value}} 클리어 이후 구매 가능",
    "en-US": "Available after Clearing Milestone #{{value}})"
  },
  {
    "key": "loc_ui_button_market_purchase",
    "_item": "시장 - 구매 버튼",
    "_comment": "",
    "ko-KR": "${{value}}에 구매",
    "en-US": "Purchase at ${{value}}"
  },
  {
    "key": "loc_ui_title_menu_warehouse",
    "_item": "창고 메뉴 명칭",
    "_comment": "",
    "ko-KR": "창고",
    "en-US": "Warehouse"
  },
  {
    "key": "loc_ui_longtext_warehouse_no_equipment",
    "_item": "창고 - 장비 없음 안내",
    "_comment": "",
    "ko-KR": "창고에 장비가 없습니다.",
    "en-US": "Warehouse is empty."
  },
  {
    "key": "loc_ui_button_warehouse_sell",
    "_item": "창고 - 판매 버튼",
    "_comment": "",
    "ko-KR": "${{value}}에 판매",
    "en-US": "Sell at ${{value}}"
  },
  {
    "key": "loc_ui_tooltip_warehouse_equip",
    "_item": "창고 - 착용 추천 안내",
    "_comment": "",
    "ko-KR": "장비 착용시 강해질 수 있는 요원이 존재합니다!",
    "en-US": "There is an agent who can be stronger with the item."
  },
  {
    "key": "loc_ui_button_warehouse_equip",
    "_item": "창고 - 착용 버튼",
    "_comment": "",
    "ko-KR": "착용",
    "en-US": "Equip"
  },
  {
    "key": "loc_ui_string_warehouse_select_equipment_agent",
    "_item": "창고 - 장비 착용 팝업 제목",
    "_comment": "",
    "ko-KR": "장비를 착용할 요원 선택",
    "en-US": "Equipment Change"
  },
  {
    "key": "loc_ui_string_warehouse_select_equipment",
    "_item": "창고 - 장비 착용 팝업 - 장착시킬 장비 항목",
    "_comment": "",
    "ko-KR": "장착시킬 장비",
    "en-US": "Select Equipment"
  },
  {
    "key": "loc_ui_string_warehouse_select_agent_to_equip",
    "_item": "창고 - 장비 착용 팝업 - 장착할 요원 항목",
    "_comment": "",
    "ko-KR": "요원 선택",
    "en-US": "Select Agent"
  },
  {
    "key": "loc_ui_string_warehouse_no_alternatives",
    "_item": "창고 - 장비 착용 팝업 - 장착 가능한 요원 없음 안내",
    "_comment": "",
    "ko-KR": "착용 가능한 요원 없음",
    "en-US": "No equipable agents"
  },
  {
    "key": "loc_ui_string_warehouse_currently_equipped",
    "_item": "창고 - 장비 착용 팝업 - 장비 장착중 항목",
    "_comment": "이거 구조에 React Component를 내재하고 있어서 당장 작업하지 않음",
    "ko-KR": "착용중",
    "en-US": "Currently Equipping"
  },
  {
    "key": "loc_ui_string_warehouse_disabled_task_ongoing_one",
    "_item": "창고 - 아이템 상호작용 불가 이유 안내(판매 업무중)",
    "_comment": "",
    "ko-KR": "판매 진행 중: {{count}}일 남음",
    "en-US": "Selling ongoing: {{count}} days"
  },
  {
    "key": "loc_ui_string_warehouse_disabled_task_ongoing_other",
    "_item": "창고 - 아이템 상호작용 불가 이유 안내(판매 업무중)",
    "_comment": "",
    "ko-KR": "판매 진행 중: {{count}}일 남음",
    "en-US": "Selling ongoing: {{count}} days"
  },
  {
    "key": "loc_ui_title_menu_recruit",
    "_item": "영입 메뉴 명칭",
    "_comment": "",
    "ko-KR": "영입",
    "en-US": "Recruit"
  },
  {
    "key": "loc_ui_string_recruit_list",
    "_item": "영입 목록 명칭",
    "_comment": "",
    "ko-KR": "신규 영입 협상 가능한 용병",
    "en-US": "Candidates for Hire"
  },
  {
    "key": "loc_ui_string_renewal_list",
    "_item": "재계약 목록 명칭",
    "_comment": "",
    "ko-KR": "재계약 결정이 필요한 요원",
    "en-US": "Renewal Decesion Needed"
  },
  {
    "key": "loc_dynamic_string_agent_recruit_expiration_one",
    "_item": "영입 상세 - 리스팅 만료까지 기간 항목 + 값",
    "_comment": "",
    "ko-KR": "",
    "en-US": "Negotiation channel closes in a day"
  },
  {
    "key": "loc_dynamic_string_agent_recruit_expiration_other",
    "_item": "영입 상세 - 리스팅 만료까지 기간 항목 + 값",
    "_comment": "",
    "ko-KR": "협상 가능 기한: {{count}}일",
    "en-US": "Negotiation channel closes in {{count}} days"
  },
  {
    "key": "loc_ui_string_recruit_contract_task_depaartment",
    "_item": "영입 상세 개요 - 협상 진행 부서 항목",
    "_comment": "",
    "ko-KR": "협상 진행 부서",
    "en-US": "Negotiating Department"
  },
  {
    "key": "loc_ui_button_common_change",
    "_item": "영입 상세 개요 - 협상 진행 부서 변경 버튼",
    "_comment": "",
    "ko-KR": "변경",
    "en-US": "Change"
  },
  {
    "key": "loc_ui_string_recruit_base_pay_when_favorable",
    "_item": "영입 상세 개요 - 유리한 협상 시 기본급 항목",
    "_comment": "",
    "ko-KR": "유리한 협상 시 기본급",
    "en-US": "Base Pay(when Favorable)"
  },
  {
    "key": "loc_ui_button_recruit_start_recruit_negotiation",
    "_item": "영입 상세 개요 - 고용 협상 시작하기 버튼",
    "_comment": "",
    "ko-KR": "고용 협상 시작하기",
    "en-US": "Start Recruitment Negotiation"
  },
  {
    "key": "loc_ui_button_recruit_recruit_immediately",
    "_item": "영입 상세 개요 - 고용하기 버튼",
    "_comment": "",
    "ko-KR": "고용 계약하기",
    "en-US": "Sign Recruit Contract"
  },
  {
    "key": "loc_ui_button_recruit_not_recruit",
    "_item": "영입 상세 개요 - 고용하지 않기 버튼",
    "_comment": "",
    "ko-KR": "고용하지 않기",
    "en-US": "Do not Recruit"
  },
  {
    "key": "loc_ui_string_recruit_cannot_recruit_no_vacancy",
    "_item": "영입 상세 개요 - 고용 불가 안내(자리 없음)",
    "_comment": "",
    "ko-KR": "고용 불가(용병 수 최대. 거점 업그레이드를 통해 제한을 높여야 합니다)",
    "en-US": "Cannot Recruit(No Vacancy)"
  },
  {
    "key": "loc_ui_string_recruit_cannot_recruit_milestone",
    "_item": "영입 상세 개요 - 고용 불가 안내(마일스톤 미도달)",
    "_comment": "",
    "ko-KR": "고용 불가(마일스톤 #{{count}} 클리어 이후 고용 가능)",
    "en-US": "Cannot Recruit(Available after Clearing Milestone #{{count}})"
  },
  {
    "key": "loc_ui_button_recruit_ongoing_recruit_negotiation_one",
    "_item": "영입 상세 개요 - 고용 협상 진행중 안내(버튼)",
    "_comment": "",
    "ko-KR": "",
    "en-US": "In Recruitment Negotiation: {{count}} day left"
  },
  {
    "key": "loc_ui_button_recruit_ongoing_recruit_negotiation_other",
    "_item": "영입 상세 개요 - 고용 협상 진행중 안내(버튼)",
    "_comment": "",
    "ko-KR": "고용 협상 진행중: {{count}}일 남음",
    "en-US": "In Recruitment Negotiation: {{count}} days left"
  },
  {
    "key": "loc_ui_button_recruit_cancel_recruit_negotiation",
    "_item": "영입 상세 개요 - 고용 협상 취소 버튼",
    "_comment": "",
    "ko-KR": "협상 취소",
    "en-US": "Cancel Negotiation"
  },
  {
    "key": "loc_ui_button_agent_contract_renew",
    "_item": "요원 재계약 팝업 - 재계약하기 버튼",
    "_comment": "",
    "ko-KR": "재계약",
    "en-US": "Renew Contract"
  },
  {
    "key": "loc_ui_button_agent_contract_not_renew",
    "_item": "요원 재계약 팝업 - 재계약하지 않기 버튼",
    "_comment": "",
    "ko-KR": "재계약하지 않기",
    "en-US": "Do not Renew"
  },
  {
    "key": "loc_ui_string_agent_contract_cannot_renew_no_vacancy",
    "_item": "요원 재계약 팝업 - 재계약 불가 안내(자리 없음)",
    "_comment": "",
    "ko-KR": "재계약 불가(용병 수 최대. 거점 업그레이드를 통해 제한을 높여야 합니다)",
    "en-US": "Cannot Renew(No Vacancy)"
  },
  {
    "key": "loc_ui_string_agent_contract_must_renew",
    "_item": "요원 재계약 팝업 - 재계약 강제 안내",
    "_comment": "",
    "ko-KR": "자동 재계약(업무중)",
    "en-US": "Auto Renew(Agent on job)"
  },
  {
    "key": "loc_ui_common_detail",
    "_item": "월드맵 오버레이 - 요원 - 자세히 보기 버튼",
    "_comment": "",
    "ko-KR": "자세히",
    "en-US": "Inspect"
  },
  {
    "key": "loc_ui_title_menu_training",
    "_item": "훈련 메뉴 명칭",
    "_comment": "",
    "ko-KR": "훈련",
    "en-US": "Training"
  },
  {
    "key": "loc_ui_longtext_training_reason_agent_not_assigned",
    "_item": "훈련 로케일 - 요원 미지정 안내",
    "_comment": "",
    "ko-KR": "요원을 지정하세요.",
    "en-US": "Assign agent to train."
  },
  {
    "key": "loc_ui_longtext_training_reason_instructor_not_assigned",
    "_item": "훈련 로케일 - 교관 미지정 안내",
    "_comment": "교관 삭제로 인해 제거",
    "ko-KR": "loc_ui_longtext_training_reason_instructor_not_assigned",
    "en-US": "loc_ui_longtext_training_reason_instructor_not_assigned"
  },
  {
    "key": "loc_ui_longtext_training_reason_insufficient_life",
    "_item": "훈련 로케일 - 요원 생명력 부족 안내",
    "_comment": "미구현",
    "ko-KR": "요원의 생명력이 부족합니다.",
    "en-US": "Agent has insufficient life."
  },
  {
    "key": "loc_ui_longtext_training_reason_power_cap",
    "_item": "훈련 로케일 - 요원 전투력 상한 안내",
    "_comment": "",
    "ko-KR": "요원의 전투력이 성장 한계에 도달했습니다.",
    "en-US": "Agent reached their growth cap."
  },
  {
    "key": "loc_ui_longtext_training_reason_power_thres",
    "_item": "훈련 로케일 - 시설 전투력 상한 안내",
    "_comment": "",
    "ko-KR": "요원의 전투력이 높아 더 좋은 시설에서 훈련해야 합니다.",
    "en-US": "Agent need higher level of facility for its overall."
  },
  {
    "key": "loc_ui_longtext_training_reason_physical_cap",
    "_item": "훈련 로케일 - 요원 physical 상한 안내",
    "_comment": "physical 삭제로 인해 제거",
    "ko-KR": "loc_ui_longtext_training_reason_physical_cap",
    "en-US": "loc_ui_longtext_training_reason_physical_cap"
  },
  {
    "key": "loc_ui_longtext_training_reason_physical_thres",
    "_item": "훈련 로케일 - 시설 physical 상한 안내",
    "_comment": "physical 삭제로 인해 제거",
    "ko-KR": "loc_ui_longtext_training_reason_physical_cap",
    "en-US": "loc_ui_longtext_training_reason_physical_cap"
  },
  {
    "key": "loc_ui_longtext_training_reason_stat2_thres",
    "_item": "훈련 로케일 - 시설 스탯 상한 안내",
    "_comment": "",
    "ko-KR": "요원의 능력치가 높아 더 좋은 시설에서 훈련해야 합니다.",
    "en-US": "Agent need higher level of facility for its ability."
  },
  {
    "key": "loc_ui_longtext_training_reason_perk_point",
    "_item": "훈련 로케일 - 요원 퍽 포인트 부족 안내",
    "_comment": "퍽 훈련 삭제로 인해 제거",
    "ko-KR": "loc_ui_longtext_training_reason_perk_point",
    "en-US": "loc_ui_longtext_training_reason_perk_point"
  },
  {
    "key": "loc_ui_longtext_training_reason_perk_not_assigned",
    "_item": "훈련 로케일 - 퍽 미지정 안내",
    "_comment": "퍽 훈련 삭제로 인해 제거",
    "ko-KR": "loc_ui_longtext_training_reason_perk_not_assigned",
    "en-US": "loc_ui_longtext_training_reason_perk_not_assigned"
  },
  {
    "key": "loc_ui_longtext_training_reason_agent_perk_already",
    "_item": "훈련 로케일 - 요원 퍽 기보유 안내",
    "_comment": "퍽 훈련 삭제로 인해 제거",
    "ko-KR": "loc_ui_longtext_training_reason_agent_perk_already",
    "en-US": "loc_ui_longtext_training_reason_agent_perk_already"
  },
  {
    "key": "loc_ui_longtext_training_reason_instructor_perk_not_avail",
    "_item": "훈련 로케일 - 교관 퍽 모름 안내",
    "_comment": "퍽 훈련 삭제로 인해 제거",
    "ko-KR": "loc_ui_longtext_training_reason_instructor_perk_not_avail",
    "en-US": "loc_ui_longtext_training_reason_instructor_perk_not_avail"
  },
  {
    "key": "loc_ui_longtext_training_reason_agent_aptitude_already",
    "_item": "훈련 로케일 - 요원 무기 훈련 인증 기보유 안내",
    "_comment": "무기 훈련 삭제로 인해 제거",
    "ko-KR": "loc_ui_longtext_training_reason_agent_aptitude_already",
    "en-US": "loc_ui_longtext_training_reason_agent_aptitude_already"
  },
  {
    "key": "loc_ui_longtext_training_reason_instructor_aptitude_not_avail",
    "_item": "훈련 로케일 - 교관 무기 훈련 인증 없음 안내",
    "_comment": "무기 훈련 삭제로 인해 제거",
    "ko-KR": "loc_ui_longtext_training_reason_instructor_aptitude_not_avail",
    "en-US": "loc_ui_longtext_training_reason_instructor_aptitude_not_avail"
  },
  {
    "key": "loc_ui_longtext_training_reason_session_not_assigned",
    "_item": "훈련 로케일 - 훈련 세션 미지정 안내",
    "_comment": "",
    "ko-KR": "세션을 지정하세요.",
    "en-US": "Pick program to train agent."
  },
  {
    "key": "loc_ui_string_training_efficiency_bonus_train_duration_all",
    "_item": "훈련 로케일 - 효율 보너스(전체 훈련 기간)",
    "_comment": "",
    "ko-KR": "전체 훈련 기간",
    "en-US": "Total training duration"
  },
  {
    "key": "loc_ui_string_training_efficiency_bonus_train_effeciency_all",
    "_item": "훈련 로케일 - 효율 보너스(전체 훈련 효율)",
    "_comment": "",
    "ko-KR": "전체 훈련 효율",
    "en-US": "Total training efficiency"
  },
  {
    "key": "loc_ui_string_training_efficiency_bonus_train_effeciency_training_adjacent",
    "_item": "훈련 로케일 - 효율 보너스(전지 훈련장)",
    "_comment": "마스터데이터 로케일로 갈음해야함",
    "ko-KR": "특수 훈련장",
    "en-US": "Special Training Yard"
  },
  {
    "key": "loc_ui_string_training_efficiency_bonus_train_effeciency_gym",
    "_item": "훈련 로케일 - 효율 보너스(체육관)",
    "_comment": "마스터데이터 로케일로 갈음해야함",
    "ko-KR": "체육관",
    "en-US": "Gym"
  },
  {
    "key": "loc_dynamic_longtext_training_sharpness_base_increase",
    "_item": "훈련 - 전투 감각 툴팁 - 기본 상승량",
    "_comment": "",
    "ko-KR": "{{main ? '총합' : '훈련'}}: {{roundsign(beforeAvailability, Stats2TooltipDigits)}} * 관리도({{availability}}%) = {{roundsign(value, Stats2TooltipDigits)}}",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_dynamic_longtext_training_sharpness_base_decrease",
    "_item": "훈련 - 전투 감각 툴팁 - 기본 하락량",
    "_comment": "",
    "ko-KR": "기본 일일 변동치: {{roundsign(data.original, Stats2TooltipDigits)}}",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_dynamic_longtext_training_sharpness_facility_efficiency",
    "_item": "훈련 - 전투 감각 툴팁 - 효율 보너스(시설)",
    "_comment": "getTrainEfficiencyBonuses",
    "ko-KR": "훈련 시설 효율 보너스 (+{{((facility_mult) * 100).toFixed(1)}{%): {{roundsign(data.facility - 1, Stats2TooltipDigits)}}",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_dynamic_longtext_training_sharpness_other_efficiency",
    "_item": "훈련 - 전투 감각 툴팁 - 효율 보너스(기타)",
    "_comment": "getTrainEfficiencyBonuses",
    "ko-KR": "{{TRAINING_BONUS_TABLE[modifier_key]}} 효율 보너스 (+{{(efficiency * 100).toFixed(1)}}%): {{roundsign(amount, Stats2TooltipDigits)}}",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_ui_string_training_cannot_select_agent_training",
    "_item": "훈련 - 요원 선택 불가 이유(훈련중)",
    "_comment": "",
    "ko-KR": " ($t(loc_ui_string_common_agent_state_training))",
    "en-US": " ($t(loc_ui_string_common_agent_state_training))"
  },
  {
    "key": "loc_ui_string_training_cannot_select_agent_mission",
    "_item": "훈련 - 요원 선택 불가 이유(임무 수행중)",
    "_comment": "",
    "ko-KR": " ($t(loc_ui_string_common_agent_state_mission))",
    "en-US": " ($t(loc_ui_string_common_agent_state_mission))"
  },
  {
    "key": "loc_ui_string_training_cannot_select_agent_recovery",
    "_item": "훈련 - 요원 선택 불가 이유(치료중)\n요원 상세 - 요원 능력치 증감 이유(치료중)",
    "_comment": "",
    "ko-KR": " ($t(loc_ui_string_common_agent_state_recovery))",
    "en-US": " ($t(loc_ui_string_common_agent_state_recovery))"
  },
  {
    "key": "loc_dynamic_longtext_training_slot_sharpness_facility_efficiency",
    "_item": "훈련 - 훈련 슬롯 효율 보너스(시설)",
    "_comment": "TrainingView/TrainSlot",
    "ko-KR": "훈련 시설 효율 보너스: +{{((trainGaugeInfo.mult.facility) * 100).toFixed(2)}}%",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_dynamic_longtext_training_slot_sharpness_other_efficiency",
    "_item": "훈련 - 훈련 슬롯 효율 보너스(기타)",
    "_comment": "TrainingView/TrainSlot",
    "ko-KR": "[{TRAINING_BONUS_TABLE[key]}} 효율 보너스: +{{(efficiency * 100).toFixed(2)}}%",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_dynamic_longtext_training_sharpness_total_efficiency",
    "_item": "훈련 - 효율 보너스(전체)",
    "_comment": "efficiencyBonusView",
    "ko-KR": "효율 보너스(전체): +{{((trainGaugeInfo.mult.all - 1) * 100).toFixed(1)}}%",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_ui_string_training_decay_training",
    "_item": "훈련 로케일 - 훈련중인 요원 능력치 일일 변동량 항목",
    "_comment": "",
    "ko-KR": "기본 일일 변동치(훈련중)",
    "en-US": "Base change(in training)"
  },
  {
    "key": "loc_ui_string_training_decay_normal",
    "_item": "훈련 로케일 - 훈련중이지 않은 요원 능력치 일일 변동량 항목",
    "_comment": "",
    "ko-KR": "기본 일일 변동치",
    "en-US": "Base change"
  },
  {
    "key": "loc_ui_string_training_maintain_training",
    "_item": "훈련 로케일 - 훈련장 관리 업무 결과 항목",
    "_comment": "",
    "ko-KR": "훈련장 관리 업무 결과",
    "en-US": "Training Slot Maintaining Task Result"
  },
  {
    "key": "loc_dynamic_string_training_task_maintain_result",
    "_item": "훈련 - 훈련장 관리도 변화량 총합 항목 + 값",
    "_comment": "",
    "ko-KR": "합계: {{value}}%",
    "en-US": "Total: {{value}}%"
  },
  {
    "key": "loc_ui_longtext_common_separator",
    "_item": "구분선",
    "_comment": "",
    "ko-KR": "-----",
    "en-US": "-----"
  },
  {
    "key": "loc_dynamic_string_training_slot_condition",
    "_item": "훈련 - 관리도 현재량",
    "_comment": "",
    "ko-KR": "관리도: {{value}}%",
    "en-US": "Condition: {{value}}%"
  },
  {
    "key": "loc_ui_longtext_training_suspend_mission",
    "_item": "훈련 - 용병 훈련 중지 사유 안내(임무 수행)",
    "_comment": "",
    "ko-KR": "용병이 임무를 수행중인 동안 훈련이 중지됩니다!",
    "en-US": "Training is suspended while in mission."
  },
  {
    "key": "loc_ui_longtext_training_suspend_recovery",
    "_item": "훈련 - 용병 훈련 중지 사유 안내(부상 치료)",
    "_comment": "",
    "ko-KR": "용병이 부상에서 회복되는 동안 훈련이 중지됩니다!",
    "en-US": "Training is suspended while in recovery."
  },
  {
    "key": "loc_ui_longtext_training_reset_warning_one",
    "_item": "훈련 - 용병 훈련 재시작시 안내",
    "_comment": "",
    "ko-KR": "",
    "en-US": "Restarting training now resets current progression of {{count}} day."
  },
  {
    "key": "loc_ui_longtext_training_reset_warning_other",
    "_item": "훈련 - 용병 훈련 재시작시 안내",
    "_comment": "",
    "ko-KR": "해당 용병은 일반 훈련 중이며, 훈련이 시작되면 {{count}}일 치의 훈련은 초기화 됩니다.",
    "en-US": "Restarting training now resets current progression of {{count}} days."
  },
  {
    "key": "loc_ui_button_training_start",
    "_item": "훈련 - 훈련 시작 버튼",
    "_comment": "",
    "ko-KR": "훈련 보내기",
    "en-US": "Start Training"
  },
  {
    "key": "loc_ui_button_training_start_repeat",
    "_item": "훈련 - 반복 훈련 시작 버튼",
    "_comment": "",
    "ko-KR": "반복 훈련 보내기 🔁",
    "en-US": "Start Training(Repeating)"
  },
  {
    "key": "loc_ui_button_training_cancel_training_immediately",
    "_item": "훈련 - 훈련 즉시 중단 버튼",
    "_comment": "Cancel Training",
    "ko-KR": "훈련 즉시 중단",
    "en-US": "Cancel Training Immediately"
  },
  {
    "key": "loc_ui_button_training_cancel_scheduled_training",
    "_item": "훈련 - 이번 훈련까지만 하기 버튼",
    "_comment": "Cancel Repeat",
    "ko-KR": "이번 훈련 종료 후 중단",
    "en-US": "Cancel Scheduled Training"
  },
  {
    "key": "loc_ui_longtext_training_sharpness",
    "_item": "훈련 - 전투 감각 툴팁",
    "_comment": "",
    "ko-KR": "임무 중이 아닌 용병의 전투 감각은 점점 무뎌지며, 전투 감각이 일정 이하로 떨어지게 되면 관련 스탯 또한 감소하게 됩니다. 훈련을 통해 특정 전투 감각을 끌어올릴 수 있으며, 전투 감각을 높게 유지하면 관련 스탯이 성장하게 됩니다.",
    "en-US": "loc_ui_longtext_training_sharpness"
  },
  {
    "key": "loc_ui_string_training_report_perk_acquired",
    "_item": "훈련 보고서 - 획득한 퍽 명칭",
    "_comment": "",
    "ko-KR": "획득한 퍽",
    "en-US": "Perks acquired"
  },
  {
    "key": "loc_ui_string_training_report_perk",
    "_item": "훈련 보고서 - 퍽 명칭",
    "_comment": "",
    "ko-KR": "퍽",
    "en-US": "Perks"
  },
  {
    "key": "loc_ui_string_training_report_aptitude_acquired",
    "_item": "훈련 보고서 - 획득한 무기 훈련 인증 명칭",
    "_comment": "",
    "ko-KR": "획득한 무기 훈련 인증",
    "en-US": "Weapon Qualifications acquired"
  },
  {
    "key": "loc_ui_string_training_report_training_attended",
    "_item": "훈련 보고서 - 참여한 훈련 명칭",
    "_comment": "",
    "ko-KR": "참여한 훈련",
    "en-US": "Trainings Attended"
  },
  {
    "key": "loc_ui_string_training_report_perk_point",
    "_item": "훈련 보고서 - 퍽 포인트 명칭",
    "_comment": "",
    "ko-KR": "퍽 포인트",
    "en-US": "Perk Point"
  },
  {
    "key": "loc_ui_string_training_report_removed",
    "_item": "훈련 보고서 - 제거됨 명칭",
    "_comment": "",
    "ko-KR": "제거됨",
    "en-US": "Removed"
  },
  {
    "key": "loc_ui_string_training_report_agent_status",
    "_item": "훈련 보고서 - 요원 현황 명칭",
    "_comment": "",
    "ko-KR": "요원 현황",
    "en-US": "Agent Status"
  },
  {
    "key": "loc_ui_string_training_report_waiting",
    "_item": "훈련 보고서 - 대기중 명칭",
    "_comment": "",
    "ko-KR": "대기중",
    "en-US": "Waiting"
  },
  {
    "key": "loc_ui_string_training_report_mission",
    "_item": "훈련 보고서 - 임무중 명칭",
    "_comment": "",
    "ko-KR": "임무중",
    "en-US": "In Mission"
  },
  {
    "key": "loc_dynamic_string_training_report_training_done_stat",
    "_item": "훈련 보고서 - 일반 훈련 횟수 값",
    "_comment": "",
    "ko-KR": "일반 훈련 {{result.count.stat}}회",
    "en-US": "{{result.count.stat}} Basic Trainings"
  },
  {
    "key": "loc_dynamic_string_training_report_training_done_perk",
    "_item": "훈련 보고서 - 고등 훈련 횟수 값",
    "_comment": "",
    "ko-KR": "고등 훈련 {{result.count.perk}}회",
    "en-US": "{{result.count.perk}} Advanced Trainings"
  },
  {
    "key": "loc_dynamic_string_training_report_training_done_aptitude",
    "_item": "훈련 보고서 - 사격 훈련 횟수 값",
    "_comment": "",
    "ko-KR": "사격 훈련 {{result.count.aptitude}}회",
    "en-US": "{{result.count.aptitude}} Shooting Trainings"
  },
  {
    "key": "loc_dynamic_longtext_training_cannot_train",
    "_item": "훈련 - 훈련 불가 안내(w/ 이유)",
    "_comment": "",
    "ko-KR": "훈련을 시작할 수 없습니다: {{reason}}",
    "en-US": "Unable to start training: {{reason}}"
  },
  {
    "key": "loc_dynamic_longtext_training_abort_train",
    "_item": "훈련 - 훈련 중단 안내(w/ 이유)",
    "_comment": "",
    "ko-KR": "{{agent.name}}이(가) {{slot.idx}}번 훈련 슬롯에서 훈련을 중단했습니다. 사유: {{reason}}",
    "en-US": "{{agent.name}} returned from training slot {{slot.idx}}. reason: {{reason}}"
  },
  {
    "key": "loc_dynamic_longtext_training_finish_train",
    "_item": "훈련 - 훈련 완료 안내(w/ 이유)",
    "_comment": "",
    "ko-KR": "{{agent.name}}이(가) {{slot.idx}}번 훈련 슬롯에서 훈련을 마쳤습니다.(훈련 횟수: {{Object.entries(result.count).map(([key, value]) => `${key}=${value}`).join(\", \")}}, 사유: {{reason}}"
  },
  {
    "key": "loc_ui_string_training_report_no_training",
    "_item": "훈련 보고서 - 빈 훈련 보고서 알림",
    "_comment": "",
    "ko-KR": "훈련 결과가 없습니다.",
    "en-US": "There were no trainings."
  },
  {
    "key": "loc_dynamic_shelf_training_currently_training_one",
    "_item": "우측 서랍 - 훈련 - 훈련중인 요원 항목 + 값",
    "_comment": "",
    "ko-KR": "",
    "en-US": "Training: {{slots0.length}} agent"
  },
  {
    "key": "loc_dynamic_shelf_training_currently_training_other",
    "_item": "우측 서랍 - 훈련 - 훈련중인 요원 항목 + 값",
    "_comment": "",
    "ko-KR": "훈련 중: {{slots0.length}}",
    "en-US": "Training: {{slots0.length}} agents"
  },
  {
    "key": "loc_dynamic_shelf_training_available_one",
    "_item": "우측 서랍 - 훈련 - 훈련가능한 슬롯 항목 + 값",
    "_comment": "",
    "ko-KR": "",
    "en-US": "Available: {{slots.length - slots0.length}} slot"
  },
  {
    "key": "loc_dynamic_shelf_training_available_other",
    "_item": "우측 서랍 - 훈련 - 훈련가능한 슬롯 항목 + 값",
    "_comment": "",
    "ko-KR": "빈 슬롯: {{slots.length - slots0.length}}",
    "en-US": "Available: {{slots.length - slots0.length}} slots"
  },
  {
    "key": "loc_ui_title_menu_region",
    "_item": "지역 메뉴 명칭",
    "_comment": "다른 데이터 읽는 로케일 표현법은 i18next의 interpolation 문법으로",
    "ko-KR": "지역",
    "en-US": "Region"
  },
  {
    "key": "loc_ui_string_region_list",
    "_item": "지역 목록 제목",
    "_comment": "",
    "ko-KR": "지역 목록",
    "en-US": "Region List"
  },
  {
    "key": "loc_ui_string_region_detail",
    "_item": "지역 자세히 보기 제목",
    "_comment": "",
    "ko-KR": "자세히",
    "en-US": "Region Detail"
  },
  {
    "key": "loc_ui_button_region_base",
    "_item": "지역 - 거점 보기 버튼",
    "_comment": "",
    "ko-KR": "거점 보기",
    "en-US": "Toggle Base"
  },
  {
    "key": "loc_ui_button_region_region",
    "_item": "지역 - 지역 보기 버튼",
    "_comment": "",
    "ko-KR": "지역 보기",
    "en-US": "Toggle Region"
  },
  {
    "key": "loc_dynamic_string_region_status",
    "_item": "지역 - 지역 상태 항목 + 값",
    "_comment": "",
    "ko-KR": "지역 상태: ",
    "en-US": "Regional Status:"
  },
  {
    "key": "loc_ui_string_region_status_0",
    "_item": "지역 - 지역 상태 값(안정)",
    "_comment": "",
    "ko-KR": "안정",
    "en-US": "Stable"
  },
  {
    "key": "loc_ui_string_region_status_1",
    "_item": "지역 - 지역 상태 값(무질서)",
    "_comment": "",
    "ko-KR": "무질서",
    "en-US": "Unstable"
  },
  {
    "key": "loc_ui_string_region_status_2",
    "_item": "지역 - 지역 상태 값(전장)",
    "_comment": "",
    "ko-KR": "전장",
    "en-US": "Conflicts Everywhere"
  },
  {
    "key": "loc_ui_longtext_region_status",
    "_item": "지역 - 지역 상태 툴팁",
    "_comment": "",
    "ko-KR": "구역은 여러 상황에 처할 수 있습니다.\n안정: 범죄자 외 세력이 하나뿐이며 범죄자보다 강할 때. 임무가 가끔 생성됩니다. support 시설이 활성화되고 기지를 설치할 수 있습니다.\n무질서: 범죄자 외 세력이 하나뿐이지만 범죄자보다 약할 때. 임무가 자주 생성됩니다.\n전장: 범죄자 외 세력이 둘 이상일 때. 임무가 자주 생성됩니다.",
    "en-US": "A Region can have various state.\nStable: Exactly one force exists other than minor criminals, and the force is stronger than minor criminals. Contracts spawn sporadically. Can build base and supporting facilities are activated.\nUnstable: Exactly one force exists other than minor criminals, but the force is weaker than minor criminals. Contracts spawn frequently.\nConflicts Everywhere: Two or more force exists other than minor criminals. Contracts spawn frequently."
  },
  {
    "key": "loc_ui_longtext_region_control_status",
    "_item": "지역 - 지역 장악도 툴팁",
    "_comment": "",
    "ko-KR": "구역 내 해당 세력의 힘입니다. 임무를 성공시키면 고객의 힘이 강해지고 타겟의 힘이 약해집니다.",
    "en-US": "Share of influence the force has. Increases on successfully completing corresponding force's contract."
  },
  {
    "key": "loc_ui_longtext_region_control_force_indicator",
    "_item": "지역 - 지역 장악도 세력 표시",
    "_comment": "",
    "ko-KR": "(장악)",
    "en-US": "(has control)"
  },
  {
    "key": "loc_ui_string_region_force_locals",
    "_item": "지역 - 세력 종류 값(구역 세력)",
    "_comment": "",
    "ko-KR": "현지 주민",
    "en-US": "Locals"
  },
  {
    "key": "loc_ui_string_region_force_minor_criminals",
    "_item": "지역 - 세력 종류 값(범죄자)",
    "_comment": "",
    "ko-KR": "잡범",
    "en-US": "Minor Criminals"
  },
  {
    "key": "loc_ui_string_region_force_faction",
    "_item": "지역 - 세력 종류 값(지역 세력)",
    "_comment": "",
    "ko-KR": "세력",
    "en-US": "Faction"
  },
  {
    "key": "loc_ui_longtext_region_secure_safehouse",
    "_item": "지역 - 안전시설 확보 툴팁",
    "_comment": "",
    "ko-KR": "안전가옥을 확보하면 해당 구역에서 임무를 수행할 수 있게 됩니다.",
    "en-US": "Secure safehouse to unlock contracts happening in the region."
  },
  {
    "key": "loc_dynamic_button_region_secure_safehouse",
    "_item": "지역 - 안전시설 확보 버튼",
    "_comment": "",
    "ko-KR": "시설 확보: {{value}}",
    "en-US": "Secure Facility: {{value}}"
  },
  {
    "key": "loc_dynamic_string_region_facility_build_condition_cash",
    "_item": "지역 - 시설 설치 조건(현금)\n지역 - 세력 확장 조건(현금)",
    "_comment": "",
    "ko-KR": "현금 ${{value}}",
    "en-US": "Can pay ${{value}}"
  },
  {
    "key": "loc_dynamic_string_region_facility_build_condition_milestone_contract",
    "_item": "지역 - 시설 설치 조건(마일스톤 의뢰)\n지역 - 세력 확장 조건(마일스톤 의뢰)",
    "_comment": "",
    "ko-KR": "{{value}}번째 마일스톤 의뢰 달성",
    "en-US": "Clear Milestone contract #{{value}}"
  },
  {
    "key": "loc_ui_string_region_facility_build_condition_region_facility_status",
    "_item": "지역 - 시설 설치 조건(지역 상태)",
    "_comment": "",
    "ko-KR": "지역 안정화",
    "en-US": "Region status is Stable"
  },
  {
    "key": "loc_ui_string_region_facility_build_condition_build_safehouse",
    "_item": "지역 - 시설 설치 조건(안전가옥 확보)",
    "_comment": "",
    "ko-KR": "안전가옥을 확보함",
    "en-US": "Secured Safehouse"
  },
  {
    "key": "loc_dynamic_string_region_facility_build_condition_build_tier",
    "_item": "지역 - 시설 설치 조건(사무실 티어 확보)",
    "_comment": "",
    "ko-KR": "다음 시설 필요: {{value}}단계 사무실",
    "en-US": "Have {{value}} tier Office"
  },
  {
    "key": "loc_ui_string_region_facility_build_condition_one_at_once",
    "_item": "지역 - 시설 설치 조건(동시 설치 불가)",
    "_comment": "",
    "ko-KR": "거점에 시설을 건설중이지 않음",
    "en-US": "Not building another facility"
  },
  {
    "key": "loc_ui_string_region_facility_build_condition_prerequisite",
    "_item": "지역 - 시설 설치 조건(선결 시설 확보)",
    "_comment": "",
    "ko-KR": "다음 시설 필요: ",
    "en-US": "Have facility: "
  },
  {
    "key": "loc_ui_string_region_expand_condition_proximity",
    "_item": "지역 - 세력 확장 조건(인접)",
    "_comment": "",
    "ko-KR": "회사 활동영역과 인접함",
    "en-US": "Adjacent to accessible region"
  },
  {
    "key": "loc_ui_string_region_expand_condition_stability",
    "_item": "지역 - 세력 확장 조건(안정)",
    "_comment": "",
    "ko-KR": "지역이 안정화되어야 함",
    "en-US": "Region is stable"
  },
  {
    "key": "loc_ui_string_region_facility_build_upgrading",
    "_item": "지역 - 시설 설치중(업그레이드) 항목",
    "_comment": "",
    "ko-KR": "업그레이드중: ",
    "en-US": "Upgrading: "
  },
  {
    "key": "loc_ui_button_region_facility_build_destroy",
    "_item": "지역 - 시설 철거하기 버튼",
    "_comment": "",
    "ko-KR": "철거하기",
    "en-US": "Destroy"
  },
  {
    "key": "loc_ui_button_region_facility_build_build",
    "_item": "지역 - 시설 건설하기 버튼",
    "_comment": "",
    "ko-KR": "건설하기",
    "en-US": "Build"
  },
  {
    "key": "loc_ui_button_region_facility_build_upgrade",
    "_item": "지역 - 시설 업그레이드하기 버튼",
    "_comment": "",
    "ko-KR": "시설 업그레이드하기",
    "en-US": "Upgrade"
  },
  {
    "key": "loc_dynamic_string_region_facility_requiring_ability",
    "_item": "지역 - 시설 요구 직원 능력치",
    "_comment": "",
    "ko-KR": "필요 능력치: {{list}}",
    "en-US": "Staffs will need: {{list}}"
  },
  {
    "key": "loc_dynamic_string_region_facility_tasks",
    "_item": "지역 - 시설 수행 가능한 업무",
    "_comment": "",
    "ko-KR": "수행 가능한 업무: {{list}}",
    "en-US": "Available Tasks: {{list}}"
  },
  {
    "key": "loc_ui_string_region_facility_buildable",
    "_item": "지역 - 시설 건설 가능 표시",
    "_comment": "",
    "ko-KR": "건설 가능",
    "en-US": "Build available"
  },
  {
    "key": "loc_ui_string_region_facility_upgradable",
    "_item": "지역 - 시설 업그레이드 가능 안내",
    "_comment": "",
    "ko-KR": "업그레이드 가능",
    "en-US": "Upgrade available"
  },
  {
    "key": "loc_ui_longtext_region_facility_exist_upgradable",
    "_item": "지역 - 업그레이드 가능 슬롯 존재 안내",
    "_comment": "",
    "ko-KR": "건물을 짓거나 업그레이드할 수 있습니다.",
    "en-US": "New construction is available."
  },
  {
    "key": "loc_dynamic_string_region_facility_build_duration",
    "_item": "지역 - 건설 가능한 시설 건설 기간",
    "_comment": "",
    "ko-KR": "건설 기간: {{value}}일",
    "en-US": "Construction takes {{value}} days"
  },
  {
    "key": "loc_dynamic_string_region_facility_build_maintenance",
    "_item": "지역 - 건설 가능한 시설 유지비",
    "_comment": "",
    "ko-KR": "유지비: 매월 ${{value}}",
    "en-US": "Maintenance: ${{value}} per month"
  },
  {
    "key": "loc_ui_button_region_facility_upgrade",
    "_item": "지역 - 시설 업그레이드 버튼",
    "_comment": "",
    "ko-KR": "업그레이드",
    "en-US": "Upgrade"
  },
  {
    "key": "loc_ui_longtext_region_facility_upgrade_disabled_not_enabled",
    "_item": "지역 - 시설 업그레이드 불가 안내(시설 비활성화)",
    "_comment": "지금 이유가 파악이 안 되는데 재혁님께 확인해서 제대로 설명문을 써야 함",
    "ko-KR": "시설이 활성화되지 않아 업그레이드할 수 없습니다.",
    "en-US": "Cannot upgrade deactivated facility."
  },
  {
    "key": "loc_ui_string_region_agent_maximum_reached",
    "_item": "지역 - 용병 배치 수 제한 초과 안내",
    "_comment": "",
    "ko-KR": "이 지역에 더 요원을 배치할 수 없음",
    "en-US": "Regional Agent maximum reached"
  },
  {
    "key": "loc_ui_button_region_agent_reallocate",
    "_item": "지역 - 용병 재배치 버튼",
    "_comment": "",
    "ko-KR": "재배치",
    "en-US": "Reallocate"
  },
  {
    "key": "loc_dynamic_string_region_agent_reallocating_one",
    "_item": "지역 - 용병 재배치중 안내",
    "_comment": "",
    "ko-KR": "(재배치중: {{remain_days}}일 후 완료)",
    "en-US": "(Reallocationg: {{remain_days}}day left)"
  },
  {
    "key": "loc_dynamic_string_region_agent_reallocating_other",
    "_item": "지역 - 용병 재배치중 안내",
    "_comment": "",
    "ko-KR": "(재배치중: {{remain_days}}일 후 완료)",
    "en-US": "(Reallocationg: {{remain_days}}days left)"
  },
  {
    "key": "loc_dynamic_string_region_agent_list_allocated",
    "_item": "지역 - 현재 지역에 배치된 용병 목록 + 수",
    "_comment": "",
    "ko-KR": "용병 목록: 현재 거점 {{agents_cur.length}}/{{agents_limit}}",
    "en-US": "Agents in current base: {{agents_cur.length}}/{{agents_limit}}"
  },
  {
    "key": "loc_ui_string_region_agent_list_relocatable",
    "_item": "지역 - 재배치 가능한 용병 목록",
    "_comment": "",
    "ko-KR": "용병 목록: 재배치 가능",
    "en-US": "Agents available to allocate here"
  },
  {
    "key": "loc_ui_string_region_agent_not_allocated",
    "_item": "지역 - 배치되지 않은 용병 위치 안내",
    "_comment": "팝업들 찾을 때 '다시 보지 않기'로 찾기",
    "ko-KR": "배치 예정",
    "en-US": "Not allocated"
  },
  {
    "key": "loc_dynamic_string_regional_renown_total",
    "_item": "지역 - 지역 누적 명성 항목 + 값",
    "_comment": "",
    "ko-KR": "$t(loc_ui_string_regional_renown_total): {{value}}",
    "en-US": "$t(loc_ui_string_regional_renown_total): {{value}}"
  },
  {
    "key": "loc_dynamic_string_regional_influence_level",
    "_item": "지역 - 지역 영향력 단계 항목 + 값",
    "_comment": "",
    "ko-KR": "$t(loc_ui_string_regional_influence_level): {{value}}/{{total}}",
    "en-US": "$t(loc_ui_string_regional_influence_level): {{value}}/{{total}}"
  },
  {
    "key": "loc_ui_string_regional_renown_total",
    "_item": "지역 - 지역 누적 명성 항목",
    "_comment": "",
    "ko-KR": "총 지역 명성",
    "en-US": "Total Reigonal Reputation"
  },
  {
    "key": "loc_ui_string_regional_influence_level",
    "_item": "지역 - 지역 영향력 단계 항목",
    "_comment": "",
    "ko-KR": "지역 영향력 단계",
    "en-US": "Regional Influence Level"
  },
  {
    "key": "loc_ui_string_regional_renown_next_influence",
    "_item": "지역 - 지역 명성 다음 영향력까지 항목",
    "_comment": "",
    "ko-KR": "다음 단계까지",
    "en-US": "Next level at"
  },
  {
    "key": "loc_ui_title_menu_system",
    "_item": "시스템 메뉴 명칭",
    "_comment": "",
    "ko-KR": "시스템",
    "en-US": "System"
  },
  {
    "key": "loc_ui_string_save_load",
    "_item": "시스템 - 저장하기·불러오기 영역 제목",
    "_comment": "이 부분 세이브&로드로 실수하기 좋아서 end product에서는 수정해야 할듯",
    "ko-KR": "저장하기·불러오기",
    "en-US": "Save & Load"
  },
  {
    "key": "loc_dynamic_string_save_slot_empty",
    "_item": "시스템 - 저장 슬롯(비어있음)",
    "_comment": "",
    "ko-KR": "{{idx}}번 슬롯: 비어있음",
    "en-US": "Slot {{idx}}: empty"
  },
  {
    "key": "loc_dynamic_string_save_slot",
    "_item": "시스템 - 저장 슬롯(세이브 있음)",
    "_comment": "",
    "ko-KR": "{{idx}}번 슬롯: {{descr}}",
    "en-US": "Slot {{idx}}: {{descr}}"
  },
  {
    "key": "loc_ui_button_system_load",
    "_item": "시스템 - 불러오기 버튼",
    "_comment": "",
    "ko-KR": "불러오기",
    "en-US": "Load"
  },
  {
    "key": "loc_ui_button_system_export_file",
    "_item": "시스템 - 파일로 내보내기 버튼",
    "_comment": "디버그용에 가까워서 지워질듯",
    "ko-KR": "파일로 내보내기",
    "en-US": "Export to File"
  },
  {
    "key": "loc_ui_button_system_export_string",
    "_item": "시스템 - 아래로 내보내기 버튼",
    "_comment": "디버그용이라 지워질듯",
    "ko-KR": "텍스트로 내보내기",
    "en-US": "Export to Text"
  },
  {
    "key": "loc_ui_button_system_save",
    "_item": "시스템 - 저장하기 버튼",
    "_comment": "",
    "ko-KR": "저장하기",
    "en-US": "Save"
  },
  {
    "key": "loc_ui_button_system_delete_save",
    "_item": "시스템 - 세이브 지우기 버튼",
    "_comment": "",
    "ko-KR": "지우기",
    "en-US": "Delete"
  },
  {
    "key": "loc_ui_string_system_setting",
    "_item": "시스템 - 설정 영역 제목",
    "_comment": "",
    "ko-KR": "설정",
    "en-US": "Setting"
  },
  {
    "key": "loc_ui_string_system_language",
    "_item": "시스템 - 언어 설정 항목",
    "_comment": "",
    "ko-KR": "언어",
    "en-US": "Language"
  },
  {
    "key": "loc_ui_string_system_language_ko-kr",
    "_item": "시스템 - 언어 설정 값(한국어)",
    "_comment": "",
    "ko-KR": "한국어",
    "en-US": "Korean"
  },
  {
    "key": "loc_ui_string_system_language_en-us",
    "_item": "시스템 - 언어 설정 값(영어)",
    "_comment": "",
    "ko-KR": "영어",
    "en-US": "English"
  },
  {
    "key": "loc_ui_string_system_pause",
    "_item": "시스템 - 일시정지 설정",
    "_comment": "이건 게임 내용 설정에 가까운 부분일 것 같은데",
    "ko-KR": "일시정지 설정",
    "en-US": "Pause Setting"
  },
  {
    "key": "loc_ui_string_system_pause_facility_upgrade",
    "_item": "시스템 - 일시정지 설정 항목(시설 업그레이드 완료시)",
    "_comment": "",
    "ko-KR": "시설 업그레이드 완료시",
    "en-US": "When upgrading a facility finished"
  },
  {
    "key": "loc_ui_string_system_pause_agent_recover",
    "_item": "시스템 - 일시정지 설정 항목(요원 회복 완료시)",
    "_comment": "",
    "ko-KR": "요원 회복 완료시",
    "en-US": "When an agent recovered from injury"
  },
  {
    "key": "loc_ui_string_system_pause_department",
    "_item": "시스템 - 일시정지 설정 항목(부서 업무 완료시)",
    "_comment": "",
    "ko-KR": "부서 업무 완료시",
    "en-US": "When a department finished task"
  },
  {
    "key": "loc_ui_string_system_pause_training_finished",
    "_item": "시스템 - 일시정지 설정 항목(요원 훈련 완료시)",
    "_comment": "",
    "ko-KR": "요원 훈련 완료시",
    "en-US": "When an agent finished training"
  },
  {
    "key": "loc_ui_string_system_pause_market",
    "_item": "시스템 - 일시정지 설정 항목(새 장비가 시장에 등장했을 때)",
    "_comment": "",
    "ko-KR": "새 장비가 시장에 등장했을 때",
    "en-US": "When a new equipment is listed"
  },
  {
    "key": "loc_ui_string_system_pause_recruit",
    "_item": "시스템 - 일시정지 설정 항목(새 용병이 영입 목록에 등장했을 때)",
    "_comment": "",
    "ko-KR": "새 용병이 영입 목록에 등장했을 때",
    "en-US": "When a new recruit is listed"
  },
  {
    "key": "loc_ui_string_system_pause_renown_changed",
    "_item": "시스템 - 일시정지 설정 항목(명성 변경시)",
    "_comment": "",
    "ko-KR": "명성 변경시",
    "en-US": "When reputation changed"
  },
  {
    "key": "loc_ui_string_system_pause_agent_dead",
    "_item": "시스템 - 일시정지 설정 항목(요원 사망시)",
    "_comment": "",
    "ko-KR": "요원 사망시",
    "en-US": "When an agent is dead"
  },
  {
    "key": "loc_ui_string_system_pause_mission_start",
    "_item": "시스템 - 일시정지 설정 항목(임무 시작시)",
    "_comment": "",
    "ko-KR": "임무 시작시",
    "en-US": "When starting a mission"
  },
  {
    "key": "loc_ui_string_system_pause_mission_warning",
    "_item": "시스템 - 일시정지 설정 항목(높은 난이도 임무 경고)",
    "_comment": "",
    "ko-KR": "높은 난이도 임무 경고",
    "en-US": "Extremely dangerous contract warning"
  },
  {
    "key": "loc_ui_string_system_pause_department_market_staff",
    "_item": "시스템 - 일시정지 설정 항목(새 직원 후보가 고용 목록에 등장했을 때)",
    "_comment": "",
    "ko-KR": "새 직원 후보가 고용 목록에 등장했을 때",
    "en-US": "When a candidate for new staff is listed"
  },
  {
    "key": "loc_ui_string_system_pause_department_new_staff",
    "_item": "시스템 - 일시정지 설정 항목(새 직원 고용시)",
    "_comment": "",
    "ko-KR": "새 직원 고용시",
    "en-US": "When a new staff is hired"
  },
  {
    "key": "loc_ui_string_system_pause_department_market",
    "_item": "시스템 - 일시정지 설정 항목(장비 조달 업무를 완료했을 때)",
    "_comment": "",
    "ko-KR": "장비 조달 업무를 완료했을 때",
    "en-US": "When finished purchasing task"
  },
  {
    "key": "loc_ui_string_system_pause_department_department_market",
    "_item": "시스템 - 일시정지 설정 항목(장비 조달 업무를 완료했을 때)",
    "_comment": "",
    "ko-KR": "장비 조달 업무를 완료했을 때",
    "en-US": "When finished purchasing task"
  },
  {
    "key": "loc_ui_string_system_pause_department_department_recruit",
    "_item": "시스템 - 일시정지 설정 항목(용병 탐색 업무를 완료했을 때)",
    "_comment": "",
    "ko-KR": "용병 탐색 업무를 완료했을 때",
    "en-US": "When fnished searching agent task"
  },
  {
    "key": "loc_ui_string_system_pause_department_intel",
    "_item": "시스템 - 일시정지 설정 항목(의뢰 탐색 업무를 완료했을 때)",
    "_comment": "의뢰 탐색을 부서에서 안 하게 되면서 사라질 내용",
    "ko-KR": "의뢰 탐색 업무를 완료했을 때",
    "en-US": "When finished searching contract task"
  },
  {
    "key": "loc_ui_title_popup_pause_facility_upgrade",
    "_item": "일시정지 팝업 제목(시설 업그레이드 완료시)",
    "_comment": "",
    "ko-KR": "시설 업그레이드 완료",
    "en-US": "Finished upgrading facility"
  },
  {
    "key": "loc_ui_title_popup_pause_agent_recover",
    "_item": "일시정지 팝업 제목(요원 회복 완료시)",
    "_comment": "",
    "ko-KR": "요원 회복 완료",
    "en-US": "An agent is recovered from injury"
  },
  {
    "key": "loc_ui_title_popup_pause_department",
    "_item": "일시정지 팝업 제목(부서 업무 완료시)",
    "_comment": "",
    "ko-KR": "부서 업무 완료",
    "en-US": "A Department finished task"
  },
  {
    "key": "loc_ui_title_popup_pause_training_finished",
    "_item": "일시정지 팝업 제목(요원 훈련 완료시)",
    "_comment": "",
    "ko-KR": "요원 훈련 완료",
    "en-US": "An agent finished training"
  },
  {
    "key": "loc_ui_title_popup_pause_market",
    "_item": "일시정지 팝업 제목(새 장비가 시장에 등장했을 때)",
    "_comment": "",
    "ko-KR": "새 장비가 시장에 등장",
    "en-US": "A new equipment is listed"
  },
  {
    "key": "loc_ui_title_popup_pause_recruit",
    "_item": "일시정지 팝업 제목(새 용병이 영입 목록에 등장했을 때)",
    "_comment": "",
    "ko-KR": "새 용병이 영입 목록에 등장",
    "en-US": "A new recruit is listed"
  },
  {
    "key": "loc_ui_title_popup_pause_renown_changed",
    "_item": "일시정지 팝업 제목(명성 변경시)",
    "_comment": "",
    "ko-KR": "명성 변경",
    "en-US": "Reputation changed"
  },
  {
    "key": "loc_ui_title_popup_pause_agent_dead",
    "_item": "일시정지 팝업 제목(요원 사망시)",
    "_comment": "",
    "ko-KR": "요원 사망",
    "en-US": "An agent is dead"
  },
  {
    "key": "loc_ui_title_popup_pause_mission_start",
    "_item": "일시정지 팝업 제목(임무 시작시)",
    "_comment": "",
    "ko-KR": "임무 시작",
    "en-US": "Starting mission"
  },
  {
    "key": "loc_ui_title_popup_pause_mission_warning",
    "_item": "일시정지 팝업 제목(높은 난이도 임무 경고)",
    "_comment": "",
    "ko-KR": "높은 난이도 임무 경고",
    "en-US": "This contract is extremely dangerous"
  },
  {
    "key": "loc_ui_title_popup_pause_department_market_staff",
    "_item": "일시정지 팝업 제목(새 직원 후보가 고용 목록에 등장했을 때)",
    "_comment": "",
    "ko-KR": "새 직원 후보가 고용 목록에 등장",
    "en-US": "A candidate for new staff is listed"
  },
  {
    "key": "loc_ui_title_popup_pause_department_new_staff",
    "_item": "일시정지 팝업 제목(새 직원 고용시)",
    "_comment": "",
    "ko-KR": "새 직원 고용",
    "en-US": "A new staff is hired"
  },
  {
    "key": "loc_ui_title_popup_pause_department_market",
    "_item": "일시정지 팝업 제목(장비 조달 업무를 완료했을 때)",
    "_comment": "",
    "ko-KR": "장비 조달 업무 완료",
    "en-US": "Finished purchasing task"
  },
  {
    "key": "loc_ui_title_popup_pause_department_department_market",
    "_item": "일시정지 팝업 제목(장비 조달 업무를 완료했을 때)",
    "_comment": "",
    "ko-KR": "장비 조달 업무 완료",
    "en-US": "Finished purchasing task"
  },
  {
    "key": "loc_ui_title_popup_pause_department_department_recruit",
    "_item": "일시정지 팝업 제목(용병 탐색 업무를 완료했을 때)",
    "_comment": "",
    "ko-KR": "용병 탐색 업무 완료",
    "en-US": "Finished searching agent task"
  },
  {
    "key": "loc_ui_title_popup_pause_department_intel",
    "_item": "일시정지 팝업 제목(의뢰 탐색 업무를 완료했을 때)",
    "_comment": "의뢰 탐색을 부서에서 안 하게 되면서 사라질 내용",
    "ko-KR": "의뢰 탐색 업무 완료",
    "en-US": "Finished searcing contract task"
  },
  {
    "key": "loc_ui_string_system_notification",
    "_item": "시스템 - 알림 설정",
    "_comment": "",
    "ko-KR": "알림 설정",
    "en-US": "Notification Setting"
  },
  {
    "key": "loc_ui_string_system_audio",
    "_item": "시스템 - 오디오 설정",
    "_comment": "데모에 사운드 설정은 BGM ON, OFF로만 나갈건가?",
    "ko-KR": "오디오 설정",
    "en-US": "Audio Setting"
  },
  {
    "key": "loc_ui_string_common_mission_milestone",
    "_item": "월드맵 - 마일스톤 의뢰 표시",
    "_comment": "",
    "ko-KR": "마일스톤 의뢰",
    "en-US": "Milestone Contract"
  },
  {
    "key": "loc_ui_longtext_drawer_milestone",
    "_item": "마일스톤 - 마일스톤 의뢰 안내",
    "_comment": "",
    "ko-KR": "!미션을 기한내에 완수하지 않으면 게임이 종료됩니다!",
    "en-US": "Game will be over if milestone contract is not complete before it expires."
  },
  {
    "key": "loc_ui_string_drawer_milestone_expiration_remaining",
    "_item": "마일스톤 - 마일스톤 의뢰 남은 기간 항목",
    "_comment": "",
    "ko-KR": "남은 기간",
    "en-US": "Remaining"
  },
  {
    "key": "loc_dynamic_string_drawer_milestone_expiration_remaining_one",
    "_item": "마일스톤 - 마일스톤 의뢰 남은 기간 값",
    "_comment": "",
    "ko-KR": "",
    "en-US": "today"
  },
  {
    "key": "loc_dynamic_string_drawer_milestone_expiration_remaining_other",
    "_item": "마일스톤 - 마일스톤 의뢰 남은 기간 값",
    "_comment": "",
    "ko-KR": "{{count}}일",
    "en-US": "{{count}} days"
  },
  {
    "key": "loc_ui_string_drawer_milestone_reward",
    "_item": "마일스톤 - 마일스톤 의뢰 보상 항목",
    "_comment": "",
    "ko-KR": "보상",
    "en-US": "Reward"
  },
  {
    "key": "loc_dynamic_string_drawer_milestone_reward",
    "_item": "마일스톤 - 마일스톤 의뢰 보상 값",
    "_comment": "",
    "ko-KR": "${{value}}",
    "en-US": "${{value}}"
  },
  {
    "key": "loc_dynamic_string_drawer_milestone_renown_remaining",
    "_item": "마일스톤 - 마일스톤 의뢰 해금 남은 명성 항목 + 값",
    "_comment": "",
    "ko-KR": "잠금해제에 명성 {{value}} 필요",
    "en-US": "Need {{value}} more Reputation"
  },
  {
    "key": "loc_ui_string_drawer_milestone_toggle_accordion",
    "_item": "마일스톤 - 마일스톤 서랍 펼치기",
    "_comment": "",
    "ko-KR": "마일스톤 개요",
    "en-US": "Milestone Overview"
  },
  {
    "key": "loc_ui_title_drawer_quest",
    "_item": "퀘스트 서랍 제목",
    "_comment": "",
    "ko-KR": "퀘스트",
    "en-US": "Quests"
  },
  {
    "key": "loc_ui_string_common_tutorial",
    "_item": "퀘스트 서랍 - 튜토리얼 명칭",
    "_comment": "",
    "ko-KR": "튜토리얼",
    "en-US": "Tutorial"
  },
  {
    "key": "loc_dynamic_string_quest_reward",
    "_item": "퀘스트 - 퀘스트 보상 항목 + 값",
    "_comment": "",
    "ko-KR": "보상: {{rewardString}}",
    "en-US": "Reward: {{rewardString}}"
  },
  {
    "key": "loc_dynamic_button_quest_claim_reward",
    "_item": "퀘스트 - 퀘스트 보상 수령 버튼",
    "_comment": "",
    "ko-KR": "보상 수령: {{rewardString}}",
    "en-US": "Claim Reward: {{rewardString}}"
  },
  {
    "key": "loc_dynamic_string_quest_reward_resource",
    "_item": "퀘스트 - 퀘스트 보상 항목(현금)",
    "_comment": "",
    "ko-KR": "현금 ${{value}}",
    "en-US": "${{value}} in Cash"
  },
  {
    "key": "loc_ui_title_modal_quest_new",
    "_item": "퀘스트 - 퀘스트 모달 신규 퀘스트 제목",
    "_comment": "",
    "ko-KR": "신규 퀘스트",
    "en-US": "New Quest"
  },
  {
    "key": "loc_ui_title_modal_quest_finish",
    "_item": "퀘스트 - 퀘스트 모달 퀘스트 완료 제목",
    "_comment": "",
    "ko-KR": "퀘스트 완료",
    "en-US": "Quest Complete"
  },
  {
    "key": "loc_ui_title_modal_tutorial_new",
    "_item": "퀘스트 - 퀘스트 모달 신규 튜토리얼 제목",
    "_comment": "",
    "ko-KR": "신규 튜토리얼",
    "en-US": "New Tutorial"
  },
  {
    "key": "loc_ui_title_modal_tutorial_finish",
    "_item": "퀘스트 - 퀘스트 모달 튜토리얼 완료 제목",
    "_comment": "",
    "ko-KR": "튜토리얼 완료",
    "en-US": "Tutorial Complete"
  },
  {
    "key": "loc_ui_string_quest_ongoing",
    "_item": "월간 보고서 - 진행 중인 퀘스트 제목",
    "_comment": "",
    "ko-KR": "진행 중인 퀘스트",
    "en-US": "Current Quest"
  },
  {
    "key": "loc_ui_title_drawer_agents_available",
    "_item": "임무 수행 가능 요원 서랍 제목",
    "_comment": "",
    "ko-KR": "임무 수행 가능",
    "en-US": "Avaiable Agents"
  },
  {
    "key": "loc_ui_title_drawer_notification",
    "_item": "알림 서랍 제목",
    "_comment": "",
    "ko-KR": "알림",
    "en-US": "Notification"
  },
  {
    "key": "loc_ui_button_drawer_notification_setting",
    "_item": "알림 - 알림 설정 열기 버튼",
    "_comment": "",
    "ko-KR": "설정",
    "en-US": "Setting"
  },
  {
    "key": "loc_ui_button_drawer_notification_fixnow",
    "_item": "알림 - 알림 내용 바로가기 버튼",
    "_comment": "",
    "ko-KR": "바로가기",
    "en-US": "Fix now"
  },
  {
    "key": "loc_ui_button_drawer_notification_dismiss",
    "_item": "알림 - 알림 끄기 버튼",
    "_comment": "",
    "ko-KR": "끄기",
    "en-US": "Dismiss"
  },
  {
    "key": "loc_dynamic_string_drawer_notification_milestone_renown",
    "_item": "알림 - 다음 마일스톤 임무 알림",
    "_comment": "",
    "ko-KR": "다음 마일스톤 의뢰까지: 명성 {{value}} 필요",
    "en-US": "Need {{value}} more reputation for next milestone contract"
  },
  {
    "key": "loc_ui_longtext_drawer_notification_milestone_renown",
    "_item": "알림 - 다음 마일스톤 임무 툴팁",
    "_comment": "",
    "ko-KR": "명성을 추가로 획득하면 마일스톤 임무를 수행할 수 있습니다. 마일스톤 임무를 수행하면 새로운 지역과 시설에 접근할 수 있습니다",
    "en-US": "Gain more reputation to unlock milestone contract. Completing it allows you to access new region."
  },
  {
    "key": "loc_ui_string_drawer_notification_milestone_available",
    "_item": "알림 - 마일스톤 임무 진행 가능 알림",
    "_comment": "",
    "ko-KR": "마일스톤 임무 진행 가능",
    "en-US": "Milestone contract available"
  },
  {
    "key": "loc_ui_longtext_drawer_notification_milestone_available",
    "_item": "알림 - 마일스톤 임무 진행 가능 툴팁",
    "_comment": "",
    "ko-KR": "마일스톤 임무를 수행할 수 있습니다. 마일스톤 임무를 수행하면 새로운 지역과 시설에 접근할 수 있습니다",
    "en-US": "You can perform milestone contract. Completing it allows you to access new region."
  },
  {
    "key": "loc_ui_string_drawer_notification_agents_notlocated",
    "_item": "알림 - 용병 미배치 알림",
    "_comment": "",
    "ko-KR": "용병 미배치",
    "en-US": "Agent location not designated"
  },
  {
    "key": "loc_ui_longtext_drawer_notification_agents_notlocated",
    "_item": "알림 - 용병 미배치 툴팁",
    "_comment": "",
    "ko-KR": "거점에 배치되지 않은 용병이 있습니다: 용병을 거점에 배치하세요",
    "en-US": "Agent need to be designated in base to be selected in contract."
  },
  {
    "key": "loc_ui_string_drawer_notification_world_area_safehouse",
    "_item": "알림 - 작전 지역 확장 가능 알림",
    "_comment": "",
    "ko-KR": "작전 지역 확장 가능",
    "en-US": "Expanding operating region available"
  },
  {
    "key": "loc_ui_longtext_drawer_notification_world_area_safehouse",
    "_item": "알림 - 작전 지역 확장 가능 툴팁",
    "_comment": "",
    "ko-KR": "작전 가능 지역을 확장할 수 있습니다: 추가 업무에 접근하고 싶다면 작전 지역을 확장하세요",
    "en-US": "You can build safehouse to expand operating region."
  },
  {
    "key": "loc_ui_string_drawer_notification_world_area_office",
    "_item": "알림 - 거점 확보 가능 알림",
    "_comment": "",
    "ko-KR": "거점 확보 가능",
    "en-US": "Securing base available"
  },
  {
    "key": "loc_ui_longtext_drawer_notification_world_area_office",
    "_item": "알림 - 거점 확보 가능 툴팁",
    "_comment": "",
    "ko-KR": "확보할 수 있는 새로운 거점이 있습니다",
    "en-US": "You can build office to secure base."
  },
  {
    "key": "loc_ui_string_drawer_notification_facility_upgrade_avail",
    "_item": "알림 - 시설 업그레이드 가능 알림",
    "_comment": "",
    "ko-KR": "시설 업그레이드 가능",
    "en-US": "Facility upgrade available"
  },
  {
    "key": "loc_ui_longtext_drawer_notification_facility_upgrade_avail",
    "_item": "알림 - 시설 업그레이드 가능 툴팁",
    "_comment": "",
    "ko-KR": "업그레이드할 수 있는 시설이 있습니다",
    "en-US": "There are upgradable facilities."
  },
  {
    "key": "loc_ui_string_drawer_notification_slots_idle",
    "_item": "알림 - 훈련 필요 알림",
    "_comment": "",
    "ko-KR": "훈련 필요",
    "en-US": "Agent needs training"
  },
  {
    "key": "loc_ui_longtext_drawer_notification_slots_idle",
    "_item": "알림 - 훈련 필요 툴팁",
    "_comment": "",
    "ko-KR": "훈련 슬롯에 배정되지 않은 용병이 있습니다. 훈련 슬롯이 부족하다면 훈련장을 건설하세요.",
    "en-US": "Some agents are not in training slot."
  },
  {
    "key": "loc_ui_string_drawer_notification_slots_insufficient",
    "_item": "알림 - 훈련장 관리 안 됨 알림",
    "_comment": "",
    "ko-KR": "훈련장 관리 안 됨",
    "en-US": "Training facility not maintained"
  },
  {
    "key": "loc_ui_longtext_drawer_notification_slots_insufficient",
    "_item": "알림 - 훈련장 관리 안 됨 툴팁",
    "_comment": "",
    "ko-KR": "훈련장의 관리가 제대로 되지 않고 있습니다: 훈련 효율이 감소합니다. 훈련장을 관리해주세요",
    "en-US": "Training will be less effective if not managed by department."
  },
  {
    "key": "loc_ui_string_drawer_notification_department_empty",
    "_item": "알림 - 직원 배치 가능 알림",
    "_comment": "",
    "ko-KR": "직원 배치 가능",
    "en-US": "Staff allocatable"
  },
  {
    "key": "loc_ui_longtext_drawer_notification_department_empty",
    "_item": "알림 - 직원 배치 가능 툴팁",
    "_comment": "",
    "ko-KR": "부서에 직원을 배치할 수 있습니다.",
    "en-US": "You can allocate staff into department slot."
  },
  {
    "key": "loc_ui_string_drawer_notification_department_idle_staff",
    "_item": "알림 - 부서 업무 없음 알림",
    "_comment": "",
    "ko-KR": "부서 업무 없음",
    "en-US": "Department can work"
  },
  {
    "key": "loc_ui_longtext_drawer_notification_department_idle_staff",
    "_item": "알림 - 부서 업무 없음 툴팁",
    "_comment": "",
    "ko-KR": "업무가 배정되지 않은 부서가 있습니다: 부서에 업무를 배정해주세요",
    "en-US": "Some departments can work, but is doing nothing. designate task to departments."
  },
  {
    "key": "loc_ui_string_drawer_notification_department_idle_task",
    "_item": "알림 - 직원 미배치 알림",
    "_comment": "",
    "ko-KR": "직원 미배치",
    "en-US": "Staff allocatable"
  },
  {
    "key": "loc_ui_longtext_drawer_notification_department_idle_task",
    "_item": "알림 - 직원 미배치 툴팁",
    "_comment": "",
    "ko-KR": "부서에 배정되지 않은 직원이 있습니다: 직원을 부서에 배정해주세요",
    "en-US": "You can allocate staff into department slot."
  },
  {
    "key": "loc_ui_string_drawer_notification_department_notask_agent",
    "_item": "알림 - 신규 용병 탐색 업무 없음 알림",
    "_comment": "",
    "ko-KR": "신규 용병 탐색 업무 없음",
    "en-US": "Recruits are not being searched"
  },
  {
    "key": "loc_ui_longtext_drawer_notification_department_notask_agent",
    "_item": "알림 - 신규 용병 탐색 업무 없음 툴팁",
    "_comment": "",
    "ko-KR": "용병 탐색 업무가 진행되지 않고 있습니다: 새로운 용병이 고용 가능 목록에 추가되지 않습니다. 용병을 추가로 고용하고 싶은 경우, 용병 탐색 업무를 진행해주세요",
    "en-US": "You need to perform search recruits task to find new recruit."
  },
  {
    "key": "loc_ui_string_drawer_notification_department_notask_gear",
    "_item": "알림 - 신규 장비 탐색 업무 없음 알림",
    "_comment": "",
    "ko-KR": "신규 장비 탐색 업무 없음",
    "en-US": "New equipments are not being searched"
  },
  {
    "key": "loc_ui_longtext_drawer_notification_department_notask_gear",
    "_item": "알림 - 신규 장비 탐색 업무 없음 툴팁",
    "_comment": "",
    "ko-KR": "장비 탐색 업무가 진행되지 않고 있습니다: 새로운 장비가 상점 목록에 추가되지 않습니다. 장비를 수급하고 싶은 경우, 장비 탐색 업무를 진행해주세요",
    "en-US": "You need to perform search equipments tasks to find new equipments."
  },
  {
    "key": "loc_ui_string_drawer_notification_quest_notclaim",
    "_item": "알림 - 퀘스트 보상 수령 가능 알림",
    "_comment": "",
    "ko-KR": "퀘스트 보상 수령 가능",
    "en-US": "Quest reward claimable"
  },
  {
    "key": "loc_ui_longtext_drawer_notification_quest_notclaim",
    "_item": "알림 - 퀘스트 보상 수령 가능 툴팁",
    "_comment": "",
    "ko-KR": "완료 조건을 모두 만족한 퀘스트가 존재합니다. 보상 수령 버튼을 눌러주세요",
    "en-US": "You have claimable quest reward."
  },
  {
    "key": "loc_ui_string_cutscene_skip",
    "_item": "컷신 - ESC를 눌러 스킵",
    "_comment": "",
    "ko-KR": "[ESC]를 눌러 스킵",
    "en-US": "Press [ESC] to skip"
  },
  {
    "key": "loc_ui_string_cutscene_continue",
    "_item": "컷신 - ESC를 눌러 계속",
    "_comment": "",
    "ko-KR": "[ESC]를 눌러 계속",
    "en-US": "Press [ESC] to continue"
  },
  {
    "key": "loc_ui_title_drawer_journal",
    "_item": "기록 서랍 제목",
    "_comment": "",
    "ko-KR": "기록",
    "en-US": "Journal"
  },
  {
    "key": "loc_ui_string_worldmap_cursor_base_none",
    "_item": "월드맵 - 월드맵 커서 거점(거점 없음)",
    "_comment": "",
    "ko-KR": "거점: 없음",
    "en-US": "Base: None"
  },
  {
    "key": "loc_ui_string_worldmap_cursor_base",
    "_item": "월드맵 - 월드맵 커서 거점\n월드맵 - 월드맵 지역 안내 거점 제목",
    "_comment": "",
    "ko-KR": "거점: ",
    "en-US": "Base: "
  },
  {
    "key": "loc_ui_string_worldmap_mission_ongoing",
    "_item": "월드맵 - 월드맵 상 진행중 임무 표시",
    "_comment": "",
    "ko-KR": " (진행중)",
    "en-US": " (ongoing)"
  },
  {
    "key": "loc_dynamic_string_worldmap_work_progress",
    "_item": "월드맵 - 월드맵 상 업무 진척도 표시",
    "_comment": "",
    "ko-KR": "{{value}}/{{max}}일",
    "en-US": "{{value}}/{{max}} days"
  },
  {
    "key": "loc_ui_string_worldmap_base_agents",
    "_item": "월드맵 - 월드맵 거점 요원 목록 제목",
    "_comment": "",
    "ko-KR": "배치된 요원",
    "en-US": "Agents Standby"
  },
  {
    "key": "loc_data_string_backgrounds_name_history_1_sas",
    "_item": "마스터데이터 - 배경 이름(SAS)",
    "_comment": "",
    "ko-KR": "SAS",
    "en-US": "SAS"
  },
  {
    "key": "loc_data_string_backgrounds_name_history_2_swiss",
    "_item": "마스터데이터 - 배경 이름(스위스 근위대)",
    "_comment": "",
    "ko-KR": "스위스 근위대",
    "en-US": "Swiss Guard"
  },
  {
    "key": "loc_data_string_backgrounds_name_history_3_officer",
    "_item": "마스터데이터 - 배경 이름(장교)",
    "_comment": "",
    "ko-KR": "장교",
    "en-US": "Military Officer"
  },
  {
    "key": "loc_data_string_backgrounds_name_history_4_gurkha",
    "_item": "마스터데이터 - 배경 이름(구르카)",
    "_comment": "",
    "ko-KR": "구르카",
    "en-US": "Gurkha Warrior"
  },
  {
    "key": "loc_data_string_backgrounds_name_history_5_gambler",
    "_item": "마스터데이터 - 배경 이름(도박꾼)",
    "_comment": "",
    "ko-KR": "도박꾼",
    "en-US": "Gambler"
  },
  {
    "key": "loc_data_string_backgrounds_name_history_6_noble",
    "_item": "마스터데이터 - 배경 이름(추방된 귀족)",
    "_comment": "",
    "ko-KR": "추방된 귀족",
    "en-US": "Former Noble"
  },
  {
    "key": "loc_data_string_backgrounds_name_history_7_revolution",
    "_item": "마스터데이터 - 배경 이름(실패한 혁명가)",
    "_comment": "",
    "ko-KR": "실패한 혁명가",
    "en-US": "Exiled Rebel"
  },
  {
    "key": "loc_data_string_backgrounds_name_history_8_gangster",
    "_item": "마스터데이터 - 배경 이름(갱단원)",
    "_comment": "",
    "ko-KR": "갱단원",
    "en-US": "Gang"
  },
  {
    "key": "loc_data_string_backgrounds_name_history_9_sailor",
    "_item": "마스터데이터 - 배경 이름(선원)",
    "_comment": "",
    "ko-KR": "선원",
    "en-US": "Sailor"
  },
  {
    "key": "loc_data_string_backgrounds_name_history_10_pirate",
    "_item": "마스터데이터 - 배경 이름(해적)",
    "_comment": "",
    "ko-KR": "해적",
    "en-US": "Pirate"
  },
  {
    "key": "loc_data_string_backgrounds_name_history_11_drugdealer",
    "_item": "마스터데이터 - 배경 이름(마약상)",
    "_comment": "",
    "ko-KR": "마약상",
    "en-US": "Drug Dealer"
  },
  {
    "key": "loc_data_string_backgrounds_name_history_12_convict",
    "_item": "마스터데이터 - 배경 이름(전과자)",
    "_comment": "",
    "ko-KR": "전과자",
    "en-US": "Criminal"
  },
  {
    "key": "loc_data_string_backgrounds_name_history_13_snitch",
    "_item": "마스터데이터 - 배경 이름(내부 고발자)",
    "_comment": "",
    "ko-KR": "내부 고발자",
    "en-US": "Whistleblower"
  },
  {
    "key": "loc_data_string_backgrounds_name_history_14_graduate",
    "_item": "마스터데이터 - 배경 이름(대학원생)",
    "_comment": "",
    "ko-KR": "대학원생",
    "en-US": "Graduate Student"
  },
  {
    "key": "loc_data_string_backgrounds_name_history_15_mania",
    "_item": "마스터데이터 - 배경 이름(밀리터리 애호가)",
    "_comment": "",
    "ko-KR": "밀리터리 애호가",
    "en-US": "Military Otaku"
  },
  {
    "key": "loc_data_string_backgrounds_name_history_16_miner",
    "_item": "마스터데이터 - 배경 이름(광부)",
    "_comment": "",
    "ko-KR": "광부",
    "en-US": "Miner"
  },
  {
    "key": "loc_data_string_backgrounds_name_history_17_farmer",
    "_item": "마스터데이터 - 배경 이름(농부)",
    "_comment": "",
    "ko-KR": "농부",
    "en-US": "Farmer"
  },
  {
    "key": "loc_data_string_backgrounds_name_history_18_football",
    "_item": "마스터데이터 - 배경 이름(축구선수)",
    "_comment": "",
    "ko-KR": "축구선수",
    "en-US": "Soccer Player"
  },
  {
    "key": "loc_data_string_backgrounds_name_history_19_refuge",
    "_item": "마스터데이터 - 배경 이름(난민)",
    "_comment": "",
    "ko-KR": "난민",
    "en-US": "Refugee"
  },
  {
    "key": "loc_data_string_backgrounds_name_history_20_deserter",
    "_item": "마스터데이터 - 배경 이름(탈영병)",
    "_comment": "",
    "ko-KR": "탈영병",
    "en-US": "Deserter"
  },
  {
    "key": "loc_data_string_backgrounds_name_history_21_cultist",
    "_item": "마스터데이터 - 배경 이름(사이비 교도)",
    "_comment": "",
    "ko-KR": "사이비 교도",
    "en-US": "Cult"
  },
  {
    "key": "loc_data_string_backgrounds_name_history_22_poacher",
    "_item": "마스터데이터 - 배경 이름(밀렵꾼)",
    "_comment": "",
    "ko-KR": "밀렵꾼",
    "en-US": "Poacher"
  },
  {
    "key": "loc_data_string_backgrounds_name_history_23_police",
    "_item": "마스터데이터 - 배경 이름(경찰)",
    "_comment": "",
    "ko-KR": "경찰",
    "en-US": "Police"
  },
  {
    "key": "loc_data_string_backgrounds_name_history_24_bountyhunter",
    "_item": "마스터데이터 - 배경 이름(현상금 사냥꾼)",
    "_comment": "",
    "ko-KR": "현상금 사냥꾼",
    "en-US": "Bounty Hunter"
  },
  {
    "key": "loc_data_string_backgrounds_name_history_25_guerilla",
    "_item": "마스터데이터 - 배경 이름(게릴라)",
    "_comment": "",
    "ko-KR": "게릴라",
    "en-US": "Guerilla"
  },
  {
    "key": "loc_data_string_backgrounds_name_history_26_espionage",
    "_item": "마스터데이터 - 배경 이름(산업 스파이)",
    "_comment": "",
    "ko-KR": "산업 스파이",
    "en-US": "Industrial Spy"
  },
  {
    "key": "loc_data_string_backgrounds_name_history_27_circus",
    "_item": "마스터데이터 - 배경 이름(서커스 단원)",
    "_comment": "",
    "ko-KR": "서커스 단원",
    "en-US": "Circus Acrobat"
  },
  {
    "key": "loc_data_string_backgrounds_name_history_28_priest",
    "_item": "마스터데이터 - 배경 이름(성직자)",
    "_comment": "",
    "ko-KR": "성직자",
    "en-US": "Priest"
  },
  {
    "key": "loc_data_string_backgrounds_name_history_29_scientist",
    "_item": "마스터데이터 - 배경 이름(미친 과학자)",
    "_comment": "",
    "ko-KR": "미친 과학자",
    "en-US": "Mad Scientist"
  },
  {
    "key": "loc_data_string_backgrounds_name_history_30_artist",
    "_item": "마스터데이터 - 배경 이름(예술가)",
    "_comment": "",
    "ko-KR": "예술가",
    "en-US": "Artist"
  },
  {
    "key": "loc_data_longtext_backgrounds_descr_history_1_sas",
    "_item": "마스터데이터 - 배경 설명(SAS)",
    "_comment": "",
    "ko-KR": "영국 최정예 특수부대 SAS에서 훈련 받았습니다. 자신의 능력을 올바르게 활용할 고용주를 바라고 있습니다.",
    "en-US": "Trained in SAS, looking for an employer who will put skills to good use."
  },
  {
    "key": "loc_data_longtext_backgrounds_descr_history_2_swiss",
    "_item": "마스터데이터 - 배경 설명(스위스 근위대)",
    "_comment": "",
    "ko-KR": "500년 전통의 교황청 근위대의 일원이었지만 자신의 실력을 더 유용하게 쓸 수 있는 곳을 찾아 떠났습니다.",
    "en-US": "Was a member of the 500-year-old Papal Guard, but left in search of a place where his skills could be put to better use."
  },
  {
    "key": "loc_data_longtext_backgrounds_descr_history_3_officer",
    "_item": "마스터데이터 - 배경 설명(장교)",
    "_comment": "",
    "ko-KR": "명령 불복종으로 퇴역했지만 장래를 촉망받는 뛰어난 장교였습니다.",
    "en-US": "Was discharged for disobeying orders, but was an outstanding officer with a promising future."
  },
  {
    "key": "loc_data_longtext_backgrounds_descr_history_4_gurkha",
    "_item": "마스터데이터 - 배경 설명(구르카)",
    "_comment": "",
    "ko-KR": "네팔의 험난한 고산지대에서 성장하고 오랜 전통의 구르카 부대에서 훈련 받았습니다. 큰 돈을 모아 고향의 가족들이 풍족한 생활을 하게 되기를 바랍니다.",
    "en-US": "Raised in the harsh highlands of Nepal and trained in the time-honored Gurkha unit, hopes to make a fortune for family back home."
  },
  {
    "key": "loc_data_longtext_backgrounds_descr_history_5_gambler",
    "_item": "마스터데이터 - 배경 설명(도박꾼)",
    "_comment": "",
    "ko-KR": "도박에 중독되어 가족과 전재산을 잃었습니다. 삶의 이유를 다시 찾게 되면 새롭게 태어날 수도 있습니다.",
    "en-US": "Addicted to gambling, lost family and entire fortune. Maybe find reason for living again, might be reborn."
  },
  {
    "key": "loc_data_longtext_backgrounds_descr_history_6_noble",
    "_item": "마스터데이터 - 배경 설명(추방된 귀족)",
    "_comment": "",
    "ko-KR": "상속 갈등으로 인해 가문에서 추방된 상류층 자제입니다. 오만하고 현실감각이 떨어지지만 성장 가능성이 있습니다.",
    "en-US": "An upper-class scion who was exiled from family due to an inheritance conflict. Arrogant and unrealistic, but have the potential to grow."
  },
  {
    "key": "loc_data_longtext_backgrounds_descr_history_7_revolution",
    "_item": "마스터데이터 - 배경 설명(실패한 혁명가)",
    "_comment": "",
    "ko-KR": "조국에서 혁명을 시도했지만 실패하여 망명했습니다. 정부에 대한 원한과 동지들에 대한 죄책감에 시달리지만 전투 기술을 배워 다시 혁명을 시도할 기회를 꿈꾸고 있습니다.",
    "en-US": "Attempted a revolution in homeland, failed, and went into exile. Plagued by resentment toward the government and guilt toward comrades, but aim to learn combat skills and attempt a revolution again."
  },
  {
    "key": "loc_data_longtext_backgrounds_descr_history_8_gangster",
    "_item": "마스터데이터 - 배경 설명(갱단원)",
    "_comment": "",
    "ko-KR": "거리에서 자랐으며 범죄와 갱단의 폭력 속에서 어떻게든 살아남았습니다. 자신의 실력을 더 소중히 여길 조직을 찾아 갱을 떠났습니다.",
    "en-US": "Raised on the streets and somehow survived crime and gang violence, leaving the gangs in search of an organization that would value the skills more."
  },
  {
    "key": "loc_data_longtext_backgrounds_descr_history_9_sailor",
    "_item": "마스터데이터 - 배경 설명(선원)",
    "_comment": "",
    "ko-KR": "평생 바다를 누비며 폭풍우와 해적에 맞서 싸웠습니다. 한 곳에 오래 머무는 것에 익숙하지 않습니다.",
    "en-US": "Spent entire life at sea, battling storms and pirates. Not used to staying in one place for long."
  },
  {
    "key": "loc_data_longtext_backgrounds_descr_history_10_pirate",
    "_item": "마스터데이터 - 배경 설명(해적)",
    "_comment": "",
    "ko-KR": "카리브 해에서 상선과 유람선을 약탈하며 살아왔습니다. 더 큰 돈을 노리고 용병이 되었습니다.",
    "en-US": "Made a living looting merchant and cruise ships in the Caribbean. became a mercenary for bigger money."
  },
  {
    "key": "loc_data_longtext_backgrounds_descr_history_11_drugdealer",
    "_item": "마스터데이터 - 배경 설명(마약상)",
    "_comment": "",
    "ko-KR": "마약 유통은 가난에서 탈출할 수 있는 유일한 방법처럼 보였습니다. 하지만 마약의 폐해에 죄책감을 느껴 다른 일을 찾아 나섰습니다.",
    "en-US": "Distributing drugs seemed like the only way out of poverty, but felt guilty seeing abuses, looking for something else."
  },
  {
    "key": "loc_data_longtext_backgrounds_descr_history_12_convict",
    "_item": "마스터데이터 - 배경 설명(전과자)",
    "_comment": "",
    "ko-KR": "범죄를 저질러 징역형을 살고 나온 그가 선택할 수 있는 직업은 별로 많지 않았습니다.",
    "en-US": "After serving some sentence for crimes, didn't have many options."
  },
  {
    "key": "loc_data_longtext_backgrounds_descr_history_13_snitch",
    "_item": "마스터데이터 - 배경 설명(내부 고발자)",
    "_comment": "",
    "ko-KR": "양심의 가책을 느껴 조직의 부패를 폭로하고 도피 중입니다.",
    "en-US": "With a clear conscience, exposed the corruption in the organization and went on the run."
  },
  {
    "key": "loc_data_longtext_backgrounds_descr_history_14_graduate",
    "_item": "마스터데이터 - 배경 설명(대학원생)",
    "_comment": "",
    "ko-KR": "연구와 학문에 염증을 느끼고 자퇴했습니다. 이론을 중시하는 경향을 보입니다.",
    "en-US": "Became disenchanted with research and academia and dropped out; tends to favor theory over reality."
  },
  {
    "key": "loc_data_longtext_backgrounds_descr_history_15_mania",
    "_item": "마스터데이터 - 배경 설명(밀리터리 애호가)",
    "_comment": "",
    "ko-KR": "평생 현대전과 무기에 대한 로망을 불태웠습니다. 마침내 자신의 꿈을 이루기 위해 목숨을 걸기로 했습니다.",
    "en-US": "Have had a lifelong fascination with modern warfare and weapons. Finally decided to risk own life to fulfill the dream."
  },
  {
    "key": "loc_data_longtext_backgrounds_descr_history_16_miner",
    "_item": "마스터데이터 - 배경 설명(광부)",
    "_comment": "",
    "ko-KR": "광산에서 일하다 벌어진 매몰사고에서 가까스로 살아남았습니다. 어차피 목숨 걸고 일할 거면 더 많은 돈을 받겠다는 생각에 용병이 되었습니다.",
    "en-US": "Narrowly survived a cave-in while working in a mine. Became a mercenary, looking for getting paid more if going to risk his life anyway."
  },
  {
    "key": "loc_data_longtext_backgrounds_descr_history_17_farmer",
    "_item": "마스터데이터 - 배경 설명(농부)",
    "_comment": "",
    "ko-KR": "기업의 이해관계로 인해 농사 짓던 땅에서 쫓겨나 새 직업을 찾아 왔습니다.",
    "en-US": "Kicked off the farmland by corporate interests and came looking for a new job."
  },
  {
    "key": "loc_data_longtext_backgrounds_descr_history_18_football",
    "_item": "마스터데이터 - 배경 설명(축구선수)",
    "_comment": "",
    "ko-KR": "축구 유망주였지만 프로선수가 되지 못했습니다. 뛰어난 신체능력을 써먹기 위해 용병 일에 뛰어들었습니다.",
    "en-US": "Was a promising soccer player but never made it to the pros, turned to mercenary work to put his physical prowess to use."
  },
  {
    "key": "loc_data_longtext_backgrounds_descr_history_19_refuge",
    "_item": "마스터데이터 - 배경 설명(난민)",
    "_comment": "",
    "ko-KR": "내전과 혼란, 폭정을 피해 조국을 탈출했습니다. 기반도 특별한 기술도 없이 할 수 있는 일은 이것뿐이었습니다.",
    "en-US": "Fled your country to escape civil war, chaos, and tyranny. With no foundation and no special skills, grabbed a gun at last."
  },
  {
    "key": "loc_data_longtext_backgrounds_descr_history_20_deserter",
    "_item": "마스터데이터 - 배경 설명(탈영병)",
    "_comment": "",
    "ko-KR": "비윤리적인 작전을 수행했다가 죄책감을 견디지 못해 탈영했습니다.",
    "en-US": "Carried out unethical operations and then deserted because of the guilt."
  },
  {
    "key": "loc_data_longtext_backgrounds_descr_history_21_cultist",
    "_item": "마스터데이터 - 배경 설명(사이비 교도)",
    "_comment": "",
    "ko-KR": "사이비 종교에 빠졌다가 겨우 빠져나왔습니다. 옛 동료들을 피하기 위해 세상을 떠돕니다.",
    "en-US": "Fell into a cult and barely made it out, dying to avoid your former comrades."
  },
  {
    "key": "loc_data_longtext_backgrounds_descr_history_22_poacher",
    "_item": "마스터데이터 - 배경 설명(밀렵꾼)",
    "_comment": "",
    "ko-KR": "밀림을 헤집고 다니며 진귀한 동물을 잡아 팔면서 생계를 유지했지만 동료의 배신으로 체포됐고 모든 걸 잃었습니다. 사람 잡는 기술을 배우러 용병이 되었습니다.",
    "en-US": "Made a living roaming the jungle, catching and selling rare animals, but a colleague's betrayal led to arrest and lost everything. Became a mercenary to learn the art of man-hunting."
  },
  {
    "key": "loc_data_longtext_backgrounds_descr_history_23_police",
    "_item": "마스터데이터 - 배경 설명(경찰)",
    "_comment": "",
    "ko-KR": "부패와 관료주의에 환멸을 느껴 경찰을 떠났습니다. 아직 자신이 정의의 길에서 벗어나지 않았다고 믿습니다.",
    "en-US": "Left the police force, disillusioned with corruption and bureaucracy. Still believes in walking the path of justice."
  },
  {
    "key": "loc_data_longtext_backgrounds_descr_history_24_bountyhunter",
    "_item": "마스터데이터 - 배경 설명(현상금 사냥꾼)",
    "_comment": "",
    "ko-KR": "한때 도망자를 추적하는 것이 생업이었습니다. 혼자 다니는 것에 익숙합니다.",
    "en-US": "Made a living tracking down fugitives. Used to traveling alone."
  },
  {
    "key": "loc_data_longtext_backgrounds_descr_history_25_guerilla",
    "_item": "마스터데이터 - 배경 설명(게릴라)",
    "_comment": "",
    "ko-KR": "남아메리카의 정글에서 수년간 전투를 치렀지만 성과 없는 싸움에 지쳐 포기했습니다. 이제 안정된 삶을 위해 돈을 버는 게 목표입니다.",
    "en-US": "Spent years fighting in the jungles of South America but gave up because of tiring fruitless fights. Now aims to make money for a stable life."
  },
  {
    "key": "loc_data_longtext_backgrounds_descr_history_26_espionage",
    "_item": "마스터데이터 - 배경 설명(산업 스파이)",
    "_comment": "",
    "ko-KR": "기업의 정보를 빼돌리는 일을 오랫동안 했지만 결국 발각됐고, 체포를 피해 용병이 되었습니다.",
    "en-US": "Spent years stealing information from companies, deserted former identity avoiding capture."
  },
  {
    "key": "loc_data_longtext_backgrounds_descr_history_27_circus",
    "_item": "마스터데이터 - 배경 설명(서커스 단원)",
    "_comment": "",
    "ko-KR": "어렸을 때부터 서커스단에서 자라 곡예와 쇼맨십에 능숙합니다.",
    "en-US": "Raised in a circus since childhood, skilled in acrobatics and showmanship."
  },
  {
    "key": "loc_data_longtext_backgrounds_descr_history_28_priest",
    "_item": "마스터데이터 - 배경 설명(성직자)",
    "_comment": "",
    "ko-KR": "신의 뜻을 받들기 위한 성전을 결심, 스스로 교단에서 제명하고 전장에 뛰어들었습니다.",
    "en-US": "Deciding on a crusade to uphold the will of the gods, disfellowshipped oneself from the church and entered the battlefield."
  },
  {
    "key": "loc_data_longtext_backgrounds_descr_history_29_scientist",
    "_item": "마스터데이터 - 배경 설명(미친 과학자)",
    "_comment": "",
    "ko-KR": "비윤리적인 연구로 논란이 되어 학계에서 추방당했습니다. 하지만 폭발에 대한 열정은 죽지 않았습니다.",
    "en-US": "Controversy over unethical research led to expulsion from academia, but passion for explosions never died."
  },
  {
    "key": "loc_data_longtext_backgrounds_descr_history_30_artist",
    "_item": "마스터데이터 - 배경 설명(예술가)",
    "_comment": "",
    "ko-KR": "거침없는 풍자와 사회비판 끝에 권력자의 미움을 산 예술가입니다.",
    "en-US": "Unflinching satire and social criticism resulted in the hatred of authorities."
  },
  {
    "key": "loc_data_longtext_cutscenes_paragraph_0",
    "_item": "마스터데이터 - 오프닝 컷신",
    "_comment": "* 원본 데이터에 번호 지정 되어 있지 않음\n* 내용 바뀔 것임",
    "ko-KR": "당신의 일은 간단합니다. \n우리가 마련해준 이 회사를 잘 운영하세요. 파산시키지 말고. \n당신이 있는 곳은 그란 바가타. 국제적으로 유명한 무법지대입니다. \n용병회사로서, 일거리를 찾는 건 그리 어렵지 않을 겁니다. \n의뢰를 수행해 회사를 유지하고, 더 강한 장비와 경험으로 무장한 강력한 용병단을 육성하세요. \n당신이 필요해지면 다시 연락하겠습니다.\n\n전화는 제멋대로 끊어졌다.",
    "en-US": "Your job is simple.\nRun this company we've given you well. Don't bankrupt it.\nYou are in Gran Bagata. It's an internationally renowned lawless zone.\nIt shouldn't be too hard to find work as a mercenary company.\nTake on jobs to keep your company afloat, and build a stronger mercenary corps, armed with better equipment and experience.\nIf we need you, we'll call you back.\n\nThe call ended abruptly."
  },
  {
    "key": "loc_data_longtext_cutscenes_paragraph_1",
    "_item": "마스터데이터 - 컷신 1번",
    "_comment": "",
    "ko-KR": "지역의 브로커들 사이에서 당신의 명함이 퍼지고 있습니다. 좋은 소식이에요. \n더 많은 의뢰와 원한이 당신을 찾을 것입니다. \n더 위험한 적을 상대하게 되지만, 더 큰 보상을 받을 수 있습니다. \n회사를 유지하며 더 강력한 용병단을 준비하세요. 우리 일은 이제 시작입니다. \n\n전화는 제멋대로 끊어졌다.",
    "en-US": "Your business card is circulating among the local brokers. That's good news.\nMore commissions and grudges will find you.\nYou'll face more dangerous enemies, but the rewards will be greater.\nStay with the company and build a stronger mercenary corps. Our work is just beginning.\n\nThe call ended abruptly."
  },
  {
    "key": "loc_data_longtext_cutscenes_paragraph_2",
    "_item": "마스터데이터 - 컷신 2번",
    "_comment": "",
    "ko-KR": "훌륭합니다. 투자한 보람이 있군요. \n이 지역에서는 경쟁자를 찾기 어려울 정도로 성장했습니다. \n브로커들은 아마추어에게 맡기느니, 줄을 서는 한이 있어도 당신을 찾을 겁니다. \n이제 큰 물로 나갈 때가 되었습니다. \n탐욕스러운 카르텔과 무장단체들이 난투극을 벌이는 도시 중심부로 나아갈 차례입니다. \n생존함으로써 당신의 역량을 증명하세요.\n\n전화는 제멋대로 끊어졌다.",
    "en-US": "Excellent. Looks like my investment is paying off.\nYou've grown to the point where it's hard to find competition in this area.\nBrokers will seek you out as long as they can, rather than leave it to an amateur.\nNow it's time to head out into the real deal.\nIt's time to make your way into the heart of the city, where greedy cartels and armed gangs brawl.\nProve your mettle by surviving.\n\nThe call ended abruptly."
  },
  {
    "key": "loc_data_longtext_cutscenes_paragraph_3",
    "_item": "마스터데이터 - 컷신 3번",
    "_comment": "",
    "ko-KR": "기대 이상입니다. \n당신은 도시의 모든 용병들에게 두려움 혹은 선망의 대상이 됐습니다. \n강력한 카르텔의 보스들도 당신의 이름을 알게 되었죠. \n하지만 명심하세요. 당신은 우리 자산입니다. \n이 회사가 어디서 어떻게 시작했는지 잊어버리지 않길 바랍니다. \n그런 실수를 저지른 전임자들이 있었습니다. \n\n전화는 제멋대로 끊어졌다. ",
    "en-US": "You've exceeded my expectations.\nYou've become the object of fear or envy for every mercenary in the city.\nEven the bosses of powerful cartels have come to know your name.\nBut remember, you are our property.\nI don't want you to forget where and how this company started.\nI had predecessors who made that mistake.\n\nThe call ended abruptly."
  },
  {
    "key": "loc_data_longtext_cutscenes_paragraph_4",
    "_item": "마스터데이터 - 컷신 4번",
    "_comment": "",
    "ko-KR": "잘 했습니다. 이제 당신은 경외의 대상이 되었습니다. \n마약상, 정치인, 혁명가, 사업가 할 것 없이 모두가 당신을 두려워합니다. \n안타깝지만 당신의 일은 끝났습니다. \n후임자를 보낼 테니 회사를 인수인계하고 공항으로 오세요. 혼자, 비무장 상태로. \n그동안 수고 많았습니다. \n\n전화는 제멋대로 끊어졌다. \n하지만 난 돌아갈 생각이 없다. ",
    "en-US": "Well done, you are now an icon of awe.\nDrug dealers, politicians, revolutionaries, businessmen, everyone is afraid of you.\nUnfortunately, your job is done.\nI'm sending you a successor, so hand over your company and come to the airport. Alone, unarmed.\nThank you for all your hard work.\n\nThe call ended abruptly. \nBut I have no intention of going back."
  },
  {
    "key": "loc_data_longtext_cutscenes_paragraph_5",
    "_item": "마스터데이터 - 컷신 5번",
    "_comment": "",
    "ko-KR": "내가 있는 곳은 그란 바가타. 국제적으로 유명한 무법지대다. \n나는 이곳의 유일한 규칙이 되었다. ",
    "en-US": "I'm in Gran Bagata. It's an internationally renowned lawless zone. \nI've become the only rule here."
  },
  {
    "key": "loc_data_string_facilities_name_base1",
    "_item": "마스터데이터 - 시설 명칭(방탄사무실)",
    "_comment": "",
    "ko-KR": "방탄사무실",
    "en-US": "Bulletproof Office"
  },
  {
    "key": "loc_data_string_facilities_name_base2",
    "_item": "마스터데이터 - 시설 명칭(사무용 벙커)",
    "_comment": "",
    "ko-KR": "사무용 벙커",
    "en-US": "Office Bunker"
  },
  {
    "key": "loc_data_string_facilities_name_base3",
    "_item": "마스터데이터 - 시설 명칭(사설 군사기지)",
    "_comment": "",
    "ko-KR": "사설 군사기지",
    "en-US": "Private Base"
  },
  {
    "key": "loc_data_string_facilities_name_base4",
    "_item": "마스터데이터 - 시설 명칭(요새)",
    "_comment": "",
    "ko-KR": "요새",
    "en-US": "Fortress"
  },
  {
    "key": "loc_data_string_facilities_name_base_empty",
    "_item": "마스터데이터 - 시설 명칭(빈 슬롯)",
    "_comment": "",
    "ko-KR": "빈 슬롯",
    "en-US": "Empty Slot"
  },
  {
    "key": "loc_data_string_facilities_name_command1",
    "_item": "마스터데이터 - 시설 명칭(지휘통제실 lv1)",
    "_comment": "",
    "ko-KR": "지휘통제실 lv1",
    "en-US": "Command & Control Center(lv1)"
  },
  {
    "key": "loc_data_string_facilities_name_command2",
    "_item": "마스터데이터 - 시설 명칭(지휘통제실 lv2)",
    "_comment": "",
    "ko-KR": "지휘통제실 lv2",
    "en-US": "Command & Control Center(lv2)"
  },
  {
    "key": "loc_data_string_facilities_name_command3",
    "_item": "마스터데이터 - 시설 명칭(지휘통제실 lv3)",
    "_comment": "",
    "ko-KR": "지휘통제실 lv3",
    "en-US": "Command & Control Center(lv3)"
  },
  {
    "key": "loc_data_string_facilities_name_command4",
    "_item": "마스터데이터 - 시설 명칭(지휘통제실 lv4)",
    "_comment": "",
    "ko-KR": "지휘통제실 lv4",
    "en-US": "Command & Control Center(lv4)"
  },
  {
    "key": "loc_data_string_facilities_name_market0_1",
    "_item": "마스터데이터 - 시설 명칭(무기상 lv1)",
    "_comment": "",
    "ko-KR": "무기상 lv1",
    "en-US": "Armory Broker(lv1)"
  },
  {
    "key": "loc_data_string_facilities_name_market0_2",
    "_item": "마스터데이터 - 시설 명칭(무기상 lv2)",
    "_comment": "",
    "ko-KR": "무기상 lv2",
    "en-US": "Armory Broker(lv2)"
  },
  {
    "key": "loc_data_string_facilities_name_market0_3",
    "_item": "마스터데이터 - 시설 명칭(무기상 lv3)",
    "_comment": "",
    "ko-KR": "무기상 lv3",
    "en-US": "Armory Broker(lv3)"
  },
  {
    "key": "loc_data_string_facilities_name_market0_4",
    "_item": "마스터데이터 - 시설 명칭(무기상 lv4)",
    "_comment": "",
    "ko-KR": "무기상 lv4",
    "en-US": "Armory Broker(lv4)"
  },
  {
    "key": "loc_data_string_facilities_name_recruit0_1",
    "_item": "마스터데이터 - 시설 명칭(헤드헌터 lv1)",
    "_comment": "",
    "ko-KR": "헤드헌터 lv1",
    "en-US": "Headhunter(lv1)"
  },
  {
    "key": "loc_data_string_facilities_name_recruit0_2",
    "_item": "마스터데이터 - 시설 명칭(헤드헌터 lv2)",
    "_comment": "",
    "ko-KR": "헤드헌터 lv2",
    "en-US": "Headhunter(lv2)"
  },
  {
    "key": "loc_data_string_facilities_name_recruit0_3",
    "_item": "마스터데이터 - 시설 명칭(헤드헌터 lv3)",
    "_comment": "",
    "ko-KR": "헤드헌터 lv3",
    "en-US": "Headhunter(lv3)"
  },
  {
    "key": "loc_data_string_facilities_name_recruit0_4",
    "_item": "마스터데이터 - 시설 명칭(헤드헌터 lv4)",
    "_comment": "",
    "ko-KR": "헤드헌터 lv4",
    "en-US": "Headhunter(lv4)"
  },
  {
    "key": "loc_data_string_facilities_name_training_general1",
    "_item": "마스터데이터 - 시설 명칭(일반 훈련장1)",
    "_comment": "",
    "ko-KR": "훈련장 lv1",
    "en-US": "Training Yard(lv1)"
  },
  {
    "key": "loc_data_string_facilities_name_training_general2",
    "_item": "마스터데이터 - 시설 명칭(일반 훈련장2)",
    "_comment": "",
    "ko-KR": "훈련장 lv2",
    "en-US": "Training Yard(lv2)"
  },
  {
    "key": "loc_data_string_facilities_name_training_general3",
    "_item": "마스터데이터 - 시설 명칭(일반 훈련장3)",
    "_comment": "",
    "ko-KR": "훈련장 lv3",
    "en-US": "Training Yard(lv3)"
  },
  {
    "key": "loc_data_string_facilities_name_training_general4",
    "_item": "마스터데이터 - 시설 명칭(일반 훈련장4)",
    "_comment": "",
    "ko-KR": "훈련장 lv4",
    "en-US": "Training Yard(lv4)"
  },
  {
    "key": "loc_data_string_facilities_name_trauma1",
    "_item": "마스터데이터 - 시설 명칭(외상센터 lv1)",
    "_comment": "",
    "ko-KR": "외상센터 lv1",
    "en-US": "Trauma Team(lv1)"
  },
  {
    "key": "loc_data_string_facilities_name_trauma2",
    "_item": "마스터데이터 - 시설 명칭(외상센터 lv2)",
    "_comment": "",
    "ko-KR": "외상센터 lv2",
    "en-US": "Trauma Team(lv2)"
  },
  {
    "key": "loc_data_string_facilities_name_trauma3",
    "_item": "마스터데이터 - 시설 명칭(외상센터 lv3)",
    "_comment": "",
    "ko-KR": "외상센터 lv3",
    "en-US": "Trauma Team(lv3)"
  },
  {
    "key": "loc_data_string_facilities_name_trauma4",
    "_item": "마스터데이터 - 시설 명칭(외상센터 lv4)",
    "_comment": "",
    "ko-KR": "외상센터 lv4",
    "en-US": "Trauma Team(lv4)"
  },
  {
    "key": "loc_data_string_facilities_name_manage1",
    "_item": "마스터데이터 - 시설 명칭(매점 lv1)",
    "_comment": "",
    "ko-KR": "매점 lv1",
    "en-US": "General Affairs(lv1)"
  },
  {
    "key": "loc_data_string_facilities_name_manage2",
    "_item": "마스터데이터 - 시설 명칭(매점 lv2)",
    "_comment": "",
    "ko-KR": "매점 lv2",
    "en-US": "General Affairs(lv2)"
  },
  {
    "key": "loc_data_string_facilities_name_manage3",
    "_item": "마스터데이터 - 시설 명칭(매점 lv3)",
    "_comment": "",
    "ko-KR": "매점 lv3",
    "en-US": "General Affairs(lv3)"
  },
  {
    "key": "loc_data_string_facilities_name_manage4",
    "_item": "마스터데이터 - 시설 명칭(매점 lv4)",
    "_comment": "",
    "ko-KR": "매점 lv4",
    "en-US": "General Affairs(lv4)"
  },
  {
    "key": "loc_data_string_facilities_name_search_bonus1",
    "_item": "마스터데이터 - 시설 명칭(정보망 lv1)",
    "_comment": "",
    "ko-KR": "정보망 lv1",
    "en-US": "Intelligence Room(lv1)"
  },
  {
    "key": "loc_data_string_facilities_name_search_bonus2",
    "_item": "마스터데이터 - 시설 명칭(정보망 lv2)",
    "_comment": "",
    "ko-KR": "정보망 lv2",
    "en-US": "Intelligence Room(lv2)"
  },
  {
    "key": "loc_data_string_facilities_name_search_bonus3",
    "_item": "마스터데이터 - 시설 명칭(정보망 lv3)",
    "_comment": "",
    "ko-KR": "정보망 lv3",
    "en-US": "Intelligence Room(lv3)"
  },
  {
    "key": "loc_data_string_facilities_name_train_bonus1",
    "_item": "마스터데이터 - 시설 명칭(도서관 lv1)",
    "_comment": "",
    "ko-KR": "도서관 lv1",
    "en-US": "Library(lv1)"
  },
  {
    "key": "loc_data_string_facilities_name_train_bonus2",
    "_item": "마스터데이터 - 시설 명칭(도서관 lv2)",
    "_comment": "",
    "ko-KR": "도서관 lv2",
    "en-US": "Library(lv2)"
  },
  {
    "key": "loc_data_string_facilities_name_train_bonus3",
    "_item": "마스터데이터 - 시설 명칭(도서관 lv3)",
    "_comment": "",
    "ko-KR": "도서관 lv3",
    "en-US": "Library(lv3)"
  },
  {
    "key": "loc_data_string_facilities_name_hq1",
    "_item": "마스터데이터 - 시설 명칭(본부)",
    "_comment": "",
    "ko-KR": "본부",
    "en-US": "Headquater"
  },
  {
    "key": "loc_data_string_facilities_name_safehouse1",
    "_item": "마스터데이터 - 시설 명칭(안전가옥: 하수구)",
    "_comment": "",
    "ko-KR": "안전가옥: 하수구",
    "en-US": "Safehouse: Sewer"
  },
  {
    "key": "loc_data_string_facilities_name_safehouse2",
    "_item": "마스터데이터 - 시설 명칭(안전가옥: 창고)",
    "_comment": "",
    "ko-KR": "안전가옥: 창고",
    "en-US": "Safehouse: Storage"
  },
  {
    "key": "loc_data_string_facilities_name_safehouse3",
    "_item": "마스터데이터 - 시설 명칭(안전가옥: 식당)",
    "_comment": "",
    "ko-KR": "안전가옥: 식당",
    "en-US": "Safehouse: Diner"
  },
  {
    "key": "loc_data_string_facilities_name_safehouse4",
    "_item": "마스터데이터 - 시설 명칭(안전가옥: 별장)",
    "_comment": "",
    "ko-KR": "안전가옥: 별장",
    "en-US": "Safehouse: Manor"
  },
  {
    "key": "loc_data_string_facilities_name_pharmacy0",
    "_item": "마스터데이터 - 시설 명칭(버려진 약국)",
    "_comment": "",
    "ko-KR": "버려진 약국",
    "en-US": "Abandoned Pharmacy"
  },
  {
    "key": "loc_data_string_facilities_name_pharmacy1",
    "_item": "마스터데이터 - 시설 명칭(약국)",
    "_comment": "",
    "ko-KR": "약국",
    "en-US": "Pharmacy"
  },
  {
    "key": "loc_data_string_facilities_name_bar0",
    "_item": "마스터데이터 - 시설 명칭(파산한 바)",
    "_comment": "",
    "ko-KR": "파산한 바",
    "en-US": "Bankrupt Bar"
  },
  {
    "key": "loc_data_string_facilities_name_bar1",
    "_item": "마스터데이터 - 시설 명칭(바)",
    "_comment": "",
    "ko-KR": "바",
    "en-US": "Bar"
  },
  {
    "key": "loc_data_string_facilities_name_estate0",
    "_item": "마스터데이터 - 시설 명칭(낡은 부동산 사무소)",
    "_comment": "",
    "ko-KR": "낡은 부동산 사무소",
    "en-US": "Outdated Estate Agent"
  },
  {
    "key": "loc_data_string_facilities_name_estate1",
    "_item": "마스터데이터 - 시설 명칭(부동산 사무소)",
    "_comment": "",
    "ko-KR": "부동산 사무소",
    "en-US": "Estate Agent"
  },
  {
    "key": "loc_data_string_facilities_name_accountant0",
    "_item": "마스터데이터 - 시설 명칭(파산한 건축사 사무소)",
    "_comment": "",
    "ko-KR": "파산한 건축사 사무소",
    "en-US": "Retired Architect Office"
  },
  {
    "key": "loc_data_string_facilities_name_accountant1",
    "_item": "마스터데이터 - 시설 명칭(건축사 사무소)",
    "_comment": "",
    "ko-KR": "건축사 사무소",
    "en-US": "Artchitect Office"
  },
  {
    "key": "loc_data_string_facilities_name_mechanic0",
    "_item": "마스터데이터 - 시설 명칭(정비소 lv1)",
    "_comment": "",
    "ko-KR": "정비소 lv1",
    "en-US": "Repair Shop(lv1)"
  },
  {
    "key": "loc_data_string_facilities_name_mechanic1",
    "_item": "마스터데이터 - 시설 명칭(정비소 lv2)",
    "_comment": "",
    "ko-KR": "정비소 lv2",
    "en-US": "Repair Shop(lv2)"
  },
  {
    "key": "loc_data_string_facilities_name_mechanic2",
    "_item": "마스터데이터 - 시설 명칭(정비소 lv3)",
    "_comment": "",
    "ko-KR": "정비소 lv3",
    "en-US": "Repair Shop(lv3)"
  },
  {
    "key": "loc_data_string_facilities_name_mechanic3",
    "_item": "마스터데이터 - 시설 명칭(정비소 lv4)",
    "_comment": "",
    "ko-KR": "정비소 lv4",
    "en-US": "Repair Shop(lv4)"
  },
  {
    "key": "loc_data_string_facilities_name_remedy0",
    "_item": "마스터데이터 - 시설 명칭(물리치료실 lv1)",
    "_comment": "",
    "ko-KR": "물리치료실 lv1",
    "en-US": "Therapy(lv1)"
  },
  {
    "key": "loc_data_string_facilities_name_remedy1",
    "_item": "마스터데이터 - 시설 명칭(물리치료실 lv2)",
    "_comment": "",
    "ko-KR": "물리치료실 lv2",
    "en-US": "Therapy(lv2)"
  },
  {
    "key": "loc_data_string_facilities_name_remedy2",
    "_item": "마스터데이터 - 시설 명칭(물리치료실 lv3)",
    "_comment": "",
    "ko-KR": "물리치료실 lv3",
    "en-US": "Therapy(lv3)"
  },
  {
    "key": "loc_data_string_facilities_name_remedy3",
    "_item": "마스터데이터 - 시설 명칭(물리치료실 lv4)",
    "_comment": "",
    "ko-KR": "물리치료실 lv4",
    "en-US": "Therapy(lv4)"
  },
  {
    "key": "loc_data_string_facilities_name_hideout0",
    "_item": "마스터데이터 - 시설 명칭(은신처 lv1)",
    "_comment": "",
    "ko-KR": "은신처 lv1",
    "en-US": "Hideout(lv1)"
  },
  {
    "key": "loc_data_string_facilities_name_hideout1",
    "_item": "마스터데이터 - 시설 명칭(은신처 lv2)",
    "_comment": "",
    "ko-KR": "은신처 lv2",
    "en-US": "Hideout(lv2)"
  },
  {
    "key": "loc_data_string_facilities_name_hideout2",
    "_item": "마스터데이터 - 시설 명칭(은신처 lv3)",
    "_comment": "",
    "ko-KR": "은신처 lv3",
    "en-US": "Hideout(lv3)"
  },
  {
    "key": "loc_data_string_facilities_name_hideout3",
    "_item": "마스터데이터 - 시설 명칭(은신처 lv4)",
    "_comment": "",
    "ko-KR": "은신처 lv4",
    "en-US": "Hideout(lv4)"
  },
  {
    "key": "loc_data_string_facilities_name_pier",
    "_item": "마스터데이터 - 시설 명칭(부두)",
    "_comment": "",
    "ko-KR": "부두",
    "en-US": "Pier"
  },
  {
    "key": "loc_data_string_facilities_name_gym",
    "_item": "마스터데이터 - 시설 명칭(체육관)",
    "_comment": "",
    "ko-KR": "체육관",
    "en-US": "Public Gym"
  },
  {
    "key": "loc_data_string_facilities_name_college",
    "_item": "마스터데이터 - 시설 명칭(대학교)",
    "_comment": "",
    "ko-KR": "대학교",
    "en-US": "Univercity"
  },
  {
    "key": "loc_data_string_facilities_name_redevelop",
    "_item": "마스터데이터 - 시설 명칭(재개발)",
    "_comment": "",
    "ko-KR": "재개발",
    "en-US": "Development Zone"
  },
  {
    "key": "loc_data_longtext_facilities_descr_base1",
    "_item": "마스터데이터 - 시설 설명(방탄사무실)",
    "_comment": "",
    "ko-KR": "1티어 시설 설치 가능. 시설 슬롯 6개 제공.",
    "en-US": "Tier 1 facility can be built. Provides 6 facility slots."
  },
  {
    "key": "loc_data_longtext_facilities_descr_base2",
    "_item": "마스터데이터 - 시설 설명(사무용 벙커)",
    "_comment": "",
    "ko-KR": "2티어 시설 설치 가능. 시설 슬롯 7개 제공.",
    "en-US": "Tier 2 facility can be built. Provides 7 facility slots."
  },
  {
    "key": "loc_data_longtext_facilities_descr_base3",
    "_item": "마스터데이터 - 시설 설명(사설 군사기지)",
    "_comment": "",
    "ko-KR": "3티어 시설 설치 가능. 시설 슬롯 8개 제공.",
    "en-US": "Tier 3 facility can be built. Provides 8 facility slots."
  },
  {
    "key": "loc_data_longtext_facilities_descr_base4",
    "_item": "마스터데이터 - 시설 설명(요새)",
    "_comment": "",
    "ko-KR": "4티어 시설 설치 가능. 시설 슬롯 8개 제공.",
    "en-US": "Tier 4 facility can be built. Provides 8 facility slots."
  },
  {
    "key": "loc_data_longtext_facilities_descr_base_empty",
    "_item": "마스터데이터 - 시설 설명(빈 슬롯)",
    "_comment": "",
    "ko-KR": "새로운 시설을 설치할 수 있습니다.",
    "en-US": "New facility can be built."
  },
  {
    "key": "loc_data_longtext_facilities_descr_command1",
    "_item": "마스터데이터 - 시설 설명(지휘통제실 lv1)",
    "_comment": "",
    "ko-KR": "이 본부의 요원 수 제한 +2",
    "en-US": "+2 maximum agents located in this base."
  },
  {
    "key": "loc_data_longtext_facilities_descr_command2",
    "_item": "마스터데이터 - 시설 설명(지휘통제실 lv2)",
    "_comment": "",
    "ko-KR": "이 본부의 요원 수 제한 +4",
    "en-US": "+4 maximum agents located in this base."
  },
  {
    "key": "loc_data_longtext_facilities_descr_command3",
    "_item": "마스터데이터 - 시설 설명(지휘통제실 lv3)",
    "_comment": "",
    "ko-KR": "이 본부의 요원 수 제한 +6",
    "en-US": "+6 maximum agents located in this base."
  },
  {
    "key": "loc_data_longtext_facilities_descr_command4",
    "_item": "마스터데이터 - 시설 설명(지휘통제실 lv4)",
    "_comment": "",
    "ko-KR": "이 본부의 요원 수 제한 +8",
    "en-US": "+8 maximum agents located in this base."
  },
  {
    "key": "loc_data_longtext_facilities_descr_market0_1",
    "_item": "마스터데이터 - 시설 설명(무기상 lv1)",
    "_comment": "",
    "ko-KR": "매 달 1티어 장비 1개가 추가로 상점에 출현.",
    "en-US": "Every month, one additional equipment of tier 1 is listed in market."
  },
  {
    "key": "loc_data_longtext_facilities_descr_market0_2",
    "_item": "마스터데이터 - 시설 설명(무기상 lv2)",
    "_comment": "",
    "ko-KR": "매 달 2티어 장비 1개가 추가로 상점에 출현.",
    "en-US": "Every month, one additional equipment of tier 2 is listed in market."
  },
  {
    "key": "loc_data_longtext_facilities_descr_market0_3",
    "_item": "마스터데이터 - 시설 설명(무기상 lv3)",
    "_comment": "",
    "ko-KR": "매 달 3티어 장비 1개가 추가로 상점에 출현.",
    "en-US": "Every month, one additional equipment of tier 3 is listed in market."
  },
  {
    "key": "loc_data_longtext_facilities_descr_market0_4",
    "_item": "마스터데이터 - 시설 설명(무기상 lv4)",
    "_comment": "",
    "ko-KR": "매 달 4티어 장비 1개가 추가로 상점에 출현.",
    "en-US": "Every month, one additional equipment of tier 4 is listed in market."
  },
  {
    "key": "loc_data_longtext_facilities_descr_recruit0_1",
    "_item": "마스터데이터 - 시설 설명(헤드헌터 lv1)",
    "_comment": "",
    "ko-KR": "매 달 1티어 요원 1명이 추가로 상점에 출현.",
    "en-US": "Every month, one additional recruit of tier 1 is listed in mercenary market."
  },
  {
    "key": "loc_data_longtext_facilities_descr_recruit0_2",
    "_item": "마스터데이터 - 시설 설명(헤드헌터 lv2)",
    "_comment": "",
    "ko-KR": "매 달 2티어 요원 1명이 추가로 상점에 출현.",
    "en-US": "Every month, one additional recruit of tier 2 is listed in mercenary market."
  },
  {
    "key": "loc_data_longtext_facilities_descr_recruit0_3",
    "_item": "마스터데이터 - 시설 설명(헤드헌터 lv3)",
    "_comment": "",
    "ko-KR": "매 달 3티어 요원 1명이 추가로 상점에 출현.",
    "en-US": "Every month, one additional recruit of tier 3 is listed in mercenary market."
  },
  {
    "key": "loc_data_longtext_facilities_descr_recruit0_4",
    "_item": "마스터데이터 - 시설 설명(헤드헌터 lv4)",
    "_comment": "",
    "ko-KR": "매 달 4티어 요원 1명이 추가로 상점에 출현.",
    "en-US": "Every month, one additional recruit of tier 4 is listed in mercenary market."
  },
  {
    "key": "loc_data_longtext_facilities_descr_training_general1",
    "_item": "마스터데이터 - 시설 설명(일반 훈련장1)",
    "_comment": "",
    "ko-KR": "1티어 훈련슬롯 4개 제공.",
    "en-US": "Provides 4 training slot of tier 1."
  },
  {
    "key": "loc_data_longtext_facilities_descr_training_general2",
    "_item": "마스터데이터 - 시설 설명(일반 훈련장2)",
    "_comment": "",
    "ko-KR": "2티어 훈련슬롯 4개 제공. ",
    "en-US": "Provides 4 training slot of tier 2."
  },
  {
    "key": "loc_data_longtext_facilities_descr_training_general3",
    "_item": "마스터데이터 - 시설 설명(일반 훈련장3)",
    "_comment": "",
    "ko-KR": "3티어 훈련슬롯 4개 제공. ",
    "en-US": "Provides 4 training slot of tier 3."
  },
  {
    "key": "loc_data_longtext_facilities_descr_training_general4",
    "_item": "마스터데이터 - 시설 설명(일반 훈련장4)",
    "_comment": "",
    "ko-KR": "4티어 훈련슬롯 4개 제공. ",
    "en-US": "Provides 4 training slot of tier 4."
  },
  {
    "key": "loc_data_longtext_facilities_descr_trauma1",
    "_item": "마스터데이터 - 시설 설명(외상센터 lv1)",
    "_comment": "",
    "ko-KR": "소속 요원 회복속도 +10%",
    "en-US": "Agents located here have +10% to recovery speed."
  },
  {
    "key": "loc_data_longtext_facilities_descr_trauma2",
    "_item": "마스터데이터 - 시설 설명(외상센터 lv2)",
    "_comment": "",
    "ko-KR": "소속 요원 회복속도 +20%",
    "en-US": "Agents located here have +20% to recovery speed."
  },
  {
    "key": "loc_data_longtext_facilities_descr_trauma3",
    "_item": "마스터데이터 - 시설 설명(외상센터 lv3)",
    "_comment": "",
    "ko-KR": "소속 요원 회복속도 +30%",
    "en-US": "Agents located here have +30% to recovery speed."
  },
  {
    "key": "loc_data_longtext_facilities_descr_trauma4",
    "_item": "마스터데이터 - 시설 설명(외상센터 lv4)",
    "_comment": "",
    "ko-KR": "소속 요원 회복속도 +40%",
    "en-US": "Agents located here have +40% to recovery speed."
  },
  {
    "key": "loc_data_longtext_facilities_descr_manage1",
    "_item": "마스터데이터 - 시설 설명(매점 lv1)",
    "_comment": "",
    "ko-KR": "소속 요원 월급 -10%",
    "en-US": "Agents located here have -10% monthly pay."
  },
  {
    "key": "loc_data_longtext_facilities_descr_manage2",
    "_item": "마스터데이터 - 시설 설명(매점 lv2)",
    "_comment": "",
    "ko-KR": "소속 요원 월급 -20%",
    "en-US": "Agents located here have -20% monthly pay."
  },
  {
    "key": "loc_data_longtext_facilities_descr_manage3",
    "_item": "마스터데이터 - 시설 설명(매점 lv3)",
    "_comment": "",
    "ko-KR": "소속 요원 월급 -30%",
    "en-US": "Agents located here have -30% monthly pay."
  },
  {
    "key": "loc_data_longtext_facilities_descr_manage4",
    "_item": "마스터데이터 - 시설 설명(매점 lv4)",
    "_comment": "",
    "ko-KR": "소속 요원 월급 -40%",
    "en-US": "Agents located here have -40% monthly pay."
  },
  {
    "key": "loc_data_longtext_facilities_descr_search_bonus1",
    "_item": "마스터데이터 - 시설 설명(정보망 lv1)",
    "_comment": "미사용",
    "ko-KR": "소속 직원의 탐색 역량 +2",
    "en-US": "loc_data_longtext_facilities_descr_search_bonus1"
  },
  {
    "key": "loc_data_longtext_facilities_descr_search_bonus2",
    "_item": "마스터데이터 - 시설 설명(정보망 lv2)",
    "_comment": "미사용",
    "ko-KR": "소속 직원의 탐색 역량 +5",
    "en-US": "loc_data_longtext_facilities_descr_search_bonus2"
  },
  {
    "key": "loc_data_longtext_facilities_descr_search_bonus3",
    "_item": "마스터데이터 - 시설 설명(정보망 lv3)",
    "_comment": "미사용",
    "ko-KR": "소속 직원의 탐색 역량 +9",
    "en-US": "loc_data_longtext_facilities_descr_search_bonus3"
  },
  {
    "key": "loc_data_longtext_facilities_descr_train_bonus1",
    "_item": "마스터데이터 - 시설 설명(도서관 lv1)",
    "_comment": "미사용",
    "ko-KR": "소속 직원의 훈련 역량 +2",
    "en-US": "loc_data_longtext_facilities_descr_train_bonus1"
  },
  {
    "key": "loc_data_longtext_facilities_descr_train_bonus2",
    "_item": "마스터데이터 - 시설 설명(도서관 lv2)",
    "_comment": "미사용",
    "ko-KR": "소속 직원의 훈련 역량 +5",
    "en-US": "loc_data_longtext_facilities_descr_train_bonus2"
  },
  {
    "key": "loc_data_longtext_facilities_descr_train_bonus3",
    "_item": "마스터데이터 - 시설 설명(도서관 lv3)",
    "_comment": "미사용",
    "ko-KR": "소속 직원의 훈련 역량 +9",
    "en-US": "loc_data_longtext_facilities_descr_train_bonus3"
  },
  {
    "key": "loc_data_longtext_facilities_descr_hq1",
    "_item": "마스터데이터 - 시설 설명(본부)",
    "_comment": "미사용",
    "ko-KR": "소속 직원의 역량 +3",
    "en-US": "loc_data_longtext_facilities_descr_hq1"
  },
  {
    "key": "loc_data_longtext_facilities_descr_safehouse1",
    "_item": "마스터데이터 - 시설 설명(안전가옥: 하수구)",
    "_comment": "",
    "ko-KR": "아무도 찾아내지 못할 은신처입니다. 냄새는... 익숙해집니다. 이 구역에서 작전 수행 / 이동 가능",
    "en-US": "No one can find here. Just ignore the stench.\nUnlock contracts in this region and agents can be located."
  },
  {
    "key": "loc_data_longtext_facilities_descr_safehouse2",
    "_item": "마스터데이터 - 시설 설명(안전가옥: 창고)",
    "_comment": "",
    "ko-KR": "낡은 창고처럼 위장한 거점입니다. 이 구역에서 작전 수행 / 이동 가능, 작전 수행 후 hp 5% 즉시 회복",
    "en-US": "Safehouse in form of storehouse.\nUnlock contracts in this region and agents can be located.\nAfter missions, agents immediately get recovered by 5%."
  },
  {
    "key": "loc_data_longtext_facilities_descr_safehouse3",
    "_item": "마스터데이터 - 시설 설명(안전가옥: 식당)",
    "_comment": "",
    "ko-KR": "평범한 식당 같지만, 주방 지하에는 수술실, 창고에는 탄약고가 있습니다. 이 구역에서 작전 수행 / 이동 가능, 작전 수행 후 hp 10% 즉시 회복",
    "en-US": "This diner looks innocent, has armory, gets bullets removed from agents' stomach.\nUnlock contracts in this region and agents can be located.\nAfter missions, agents immediately get recovered by 10%."
  },
  {
    "key": "loc_data_longtext_facilities_descr_safehouse4",
    "_item": "마스터데이터 - 시설 설명(안전가옥: 별장)",
    "_comment": "",
    "ko-KR": "돈 많은 외국인이 소유한 별장처럼 위장했습니다. 이 구역에서 작전 수행 / 이동 가능, 작전 수행 후 hp 15% 즉시 회복",
    "en-US": "Wealthy foreigner owns this manor. On official record of course.\nUnlock contracts in this region and agents can be located.\nAfter missions, agents immediately get recovered by 15%."
  },
  {
    "key": "loc_data_longtext_facilities_descr_pharmacy0",
    "_item": "마스터데이터 - 시설 설명(버려진 약국)",
    "_comment": "",
    "ko-KR": "투자하면 치안이 유지되는 한 의약품을 제공받을 수 있습니다.",
    "en-US": "If invested, provides medical supplies."
  },
  {
    "key": "loc_data_longtext_facilities_descr_pharmacy1",
    "_item": "마스터데이터 - 시설 설명(약국)",
    "_comment": "",
    "ko-KR": "(전역)요원 회복 속도 +5%",
    "en-US": "All agents have +5% to recovery speed."
  },
  {
    "key": "loc_data_longtext_facilities_descr_bar0",
    "_item": "마스터데이터 - 시설 설명(파산한 바)",
    "_comment": "",
    "ko-KR": "투자하면 요원들이 스트레스 풀 곳이 생깁니다. 술값 일부는 회사로 돌아오죠.",
    "en-US": "If invested, agents payback their payroll voluntarily."
  },
  {
    "key": "loc_data_longtext_facilities_descr_bar1",
    "_item": "마스터데이터 - 시설 설명(바)",
    "_comment": "",
    "ko-KR": "(전역)요원 기본급 지출 -5%",
    "en-US": "Base pay of all agents get discounted by 5%."
  },
  {
    "key": "loc_data_longtext_facilities_descr_estate0",
    "_item": "마스터데이터 - 시설 설명(낡은 부동산 사무소)",
    "_comment": "",
    "ko-KR": "도시의 모든 건물과 시설에 통달한 이들입니다. 은밀한 안전가옥을 구할 때 자문을 구할 수 있을 겁니다.",
    "en-US": "If invested, finding safehouse location is easier."
  },
  {
    "key": "loc_data_longtext_facilities_descr_estate1",
    "_item": "마스터데이터 - 시설 설명(부동산 사무소)",
    "_comment": "",
    "ko-KR": "(전역)안전가옥 설치 비용 -5%",
    "en-US": "Building safehouse get discounted by 5% everywhere."
  },
  {
    "key": "loc_data_longtext_facilities_descr_accountant0",
    "_item": "마스터데이터 - 시설 설명(파산한 건축사 사무소)",
    "_comment": "",
    "ko-KR": "투자하면 무언가를 짓거나 설치할 때 도움 받을 수 있습니다.",
    "en-US": "If invested, building project will be economic."
  },
  {
    "key": "loc_data_longtext_facilities_descr_accountant1",
    "_item": "마스터데이터 - 시설 설명(건축사 사무소)",
    "_comment": "",
    "ko-KR": "(전역)기지 업그레이드 비용 -5%, 업그레이드 소요시간 -5%",
    "en-US": "Upgrading facilities is 5% cheaper and faster everywhere."
  },
  {
    "key": "loc_data_longtext_facilities_descr_mechanic0",
    "_item": "마스터데이터 - 시설 설명(정비소 lv1)",
    "_comment": "",
    "ko-KR": "소속 요원 임무 소요시간 -10%",
    "en-US": "Agents in this region are dispatched to mission 10% faster."
  },
  {
    "key": "loc_data_longtext_facilities_descr_mechanic1",
    "_item": "마스터데이터 - 시설 설명(정비소 lv2)",
    "_comment": "",
    "ko-KR": "소속 요원 임무 소요시간 -20%",
    "en-US": "Agents in this region are dispatched to mission 20% faster."
  },
  {
    "key": "loc_data_longtext_facilities_descr_mechanic2",
    "_item": "마스터데이터 - 시설 설명(정비소 lv3)",
    "_comment": "",
    "ko-KR": "소속 요원 임무 소요시간 -30%",
    "en-US": "Agents in this region are dispatched to mission 30% faster."
  },
  {
    "key": "loc_data_longtext_facilities_descr_mechanic3",
    "_item": "마스터데이터 - 시설 설명(정비소 lv4)",
    "_comment": "",
    "ko-KR": "소속 요원 임무 소요시간 -40%",
    "en-US": "Agents in this region are dispatched to mission 40% faster."
  },
  {
    "key": "loc_data_longtext_facilities_descr_remedy0",
    "_item": "마스터데이터 - 시설 설명(물리치료실 lv1)",
    "_comment": "",
    "ko-KR": "소속 요원 스태미나 회복 속도 +10%",
    "en-US": "Agents in this region recover stamina 10% faster."
  },
  {
    "key": "loc_data_longtext_facilities_descr_remedy1",
    "_item": "마스터데이터 - 시설 설명(물리치료실 lv2)",
    "_comment": "",
    "ko-KR": "소속 요원 스태미나 회복 속도 +20%",
    "en-US": "Agents in this region recover stamina 20% faster."
  },
  {
    "key": "loc_data_longtext_facilities_descr_remedy2",
    "_item": "마스터데이터 - 시설 설명(물리치료실 lv3)",
    "_comment": "",
    "ko-KR": "소속 요원 스태미나 회복 속도 +30%",
    "en-US": "Agents in this region recover stamina 30% faster."
  },
  {
    "key": "loc_data_longtext_facilities_descr_remedy3",
    "_item": "마스터데이터 - 시설 설명(물리치료실 lv4)",
    "_comment": "",
    "ko-KR": "소속 요원 스태미나 회복 속도 +40%",
    "en-US": "Agents in this region recover stamina 40% faster."
  },
  {
    "key": "loc_data_longtext_facilities_descr_hideout0",
    "_item": "마스터데이터 - 시설 설명(은신처 lv1)",
    "_comment": "",
    "ko-KR": "작전 수행 후 요원 생명력 5% 즉시 회복(전투불능 후 소생 시 제외)",
    "en-US": "Agents in thir region get life recovered by 5% immediately after missions.\n(exclude revived agents)"
  },
  {
    "key": "loc_data_longtext_facilities_descr_hideout1",
    "_item": "마스터데이터 - 시설 설명(은신처 lv2)",
    "_comment": "",
    "ko-KR": "작전 수행 후 요원 생명력 10% 즉시 회복(전투불능 후 소생 시 제외)",
    "en-US": "Agents in thir region get life recovered by 10% immediately after missions.\n(exclude revived agents)"
  },
  {
    "key": "loc_data_longtext_facilities_descr_hideout2",
    "_item": "마스터데이터 - 시설 설명(은신처 lv3)",
    "_comment": "",
    "ko-KR": "작전 수행 후 요원 생명력 15% 즉시 회복(전투불능 후 소생 시 제외)",
    "en-US": "Agents in thir region get life recovered by 15% immediately after missions.\n(exclude revived agents)"
  },
  {
    "key": "loc_data_longtext_facilities_descr_hideout3",
    "_item": "마스터데이터 - 시설 설명(은신처 lv4)",
    "_comment": "",
    "ko-KR": "작전 수행 후 요원 생명력 20% 즉시 회복(전투불능 후 소생 시 제외)",
    "en-US": "Agents in thir region get life recovered by 20% immediately after missions.\n(exclude revived agents)"
  },
  {
    "key": "loc_data_longtext_facilities_descr_pier",
    "_item": "마스터데이터 - 시설 설명(부두)",
    "_comment": "",
    "ko-KR": "혼란에 이끌린 물자, 정보와 야심가들이 도시에 들어오는 창구입니다.\n이 구역에 세워진 기지 직원의 탐색 역량 +5",
    "en-US": "It's a window for chaos-driven supplies, information and ambitious people to enter the city.\nStaffs in this area get +5 to search ability."
  },
  {
    "key": "loc_data_longtext_facilities_descr_gym",
    "_item": "마스터데이터 - 시설 설명(체육관)",
    "_comment": "",
    "ko-KR": "이 최첨단 체육관은 운동을 향한 주인의 열렬한 애정으로 유지됩니다. 요원들이 몸 상태를 유지하는 데 도움이 됩니다. 이 구역에 세워진 기지 훈련 효율 +30%",
    "en-US": "This state-of-the-art gym is maintained by the owner's ardent affection for exercise. It helps agents stay in shape.\nBase training efficiency in this area +30%"
  },
  {
    "key": "loc_data_longtext_facilities_descr_college",
    "_item": "마스터데이터 - 시설 설명(대학교)",
    "_comment": "",
    "ko-KR": "사무실에서 회사를 굴려나갈 인재의 보고입니다. 이 구역에 세워진 기지에서 신규 직원 탐색 시 나타나는 직원 능력치 +30%",
    "en-US": "It's a cradle of talent who can run the company and do some chores.\n+30% of new staff capacity at the base established in this area"
  },
  {
    "key": "loc_data_longtext_facilities_descr_redevelop",
    "_item": "마스터데이터 - 시설 설명(재개발)",
    "_comment": "",
    "ko-KR": "도시가 혼란에 빠지기 전, 재개발이 한창 진행 중이던 블록입니다. 갈 곳 잃은 건설자재가 쌓여 있습니다. 이 구역에 세워진 기지 업그레이드 비용 -20%, 소요시간 -20%",
    "en-US": "This block was undergoing redevelopment before the city fell into chaos. There are piles of construction materials nobody knows who owns them everywhere.\nBase upgrades in this area -20%; time required -20%"
  },
  {
    "key": "loc_data_string_forces_0",
    "_item": "마스터데이터 - 지역 명칭(0번)",
    "_comment": "지역 명칭은 마스터데이터가 아니고 presets_world.mjs쪽에 박혀 있어서 작업 불가능. 미룹니다.",
    "ko-KR": "자모라",
    "en-US": "Zamora"
  },
  {
    "key": "loc_data_string_forces_1",
    "_item": "마스터데이터 - 지역 명칭(1번)",
    "_comment": "",
    "ko-KR": "산베니토",
    "en-US": "San Benito"
  },
  {
    "key": "loc_data_string_forces_2",
    "_item": "마스터데이터 - 지역 명칭(2번)",
    "_comment": "",
    "ko-KR": "엔비가도",
    "en-US": "Envigado"
  },
  {
    "key": "loc_data_string_forces_3",
    "_item": "마스터데이터 - 지역 명칭(3번)",
    "_comment": "",
    "ko-KR": "벨렌",
    "en-US": "Belén"
  },
  {
    "key": "loc_data_string_forces_4",
    "_item": "마스터데이터 - 지역 명칭(4번)",
    "_comment": "",
    "ko-KR": "코라존데헤수스",
    "en-US": "Corazón de Jesús"
  },
  {
    "key": "loc_data_string_forces_5",
    "_item": "마스터데이터 - 지역 명칭(5번)",
    "_comment": "",
    "ko-KR": "망가",
    "en-US": "Manga"
  },
  {
    "key": "loc_data_string_forces_6",
    "_item": "마스터데이터 - 지역 명칭(6번)",
    "_comment": "",
    "ko-KR": "엘팔마",
    "en-US": "El Palmar"
  },
  {
    "key": "loc_data_string_forces_7",
    "_item": "마스터데이터 - 지역 명칭(7번)",
    "_comment": "",
    "ko-KR": "산루이스",
    "en-US": "San Luis"
  },
  {
    "key": "loc_data_string_forces_8",
    "_item": "마스터데이터 - 지역 명칭(8번)",
    "_comment": "",
    "ko-KR": "라스모라스",
    "en-US": "Las Moras"
  },
  {
    "key": "loc_data_string_forces_9",
    "_item": "마스터데이터 - 지역 명칭(9번)",
    "_comment": "",
    "ko-KR": "산타모니카",
    "en-US": "Santa Monica"
  },
  {
    "key": "loc_data_string_forces_10",
    "_item": "마스터데이터 - 지역 명칭(10번)",
    "_comment": "",
    "ko-KR": "마벨라",
    "en-US": "Marbella"
  },
  {
    "key": "loc_data_string_forces_11",
    "_item": "마스터데이터 - 지역 명칭(11번)",
    "_comment": "",
    "ko-KR": "라플로리다",
    "en-US": "La Florida"
  },
  {
    "key": "loc_data_string_forces_12",
    "_item": "마스터데이터 - 지역 명칭(12번)",
    "_comment": "",
    "ko-KR": "에스페란차",
    "en-US": "Esperanza"
  },
  {
    "key": "loc_data_string_forces_13",
    "_item": "마스터데이터 - 지역 명칭(13번)",
    "_comment": "",
    "ko-KR": "보카그란데",
    "en-US": "Bocagrande"
  },
  {
    "key": "loc_data_string_forces_14",
    "_item": "마스터데이터 - 지역 명칭(14번, 시작 지역)",
    "_comment": "",
    "ko-KR": "아르메니아",
    "en-US": "Armenia"
  },
  {
    "key": "loc_data_string_forcetypes_thug",
    "_item": "마스터데이터 - 세력 명칭(잡범)",
    "_comment": "",
    "ko-KR": "잡범",
    "en-US": "Local thugs"
  },
  {
    "key": "loc_data_string_forcetypes_gang",
    "_item": "마스터데이터 - 세력 명칭(갱단)",
    "_comment": "",
    "ko-KR": "갱단",
    "en-US": "Gangs"
  },
  {
    "key": "loc_data_string_forcetypes_militia",
    "_item": "마스터데이터 - 세력 명칭(민병대)",
    "_comment": "",
    "ko-KR": "민병대",
    "en-US": "Local Militia"
  },
  {
    "key": "loc_data_string_forcetypes_cartel",
    "_item": "마스터데이터 - 세력 명칭(카르텔)",
    "_comment": "",
    "ko-KR": "카르텔",
    "en-US": "Cartel"
  },
  {
    "key": "loc_data_string_missionmilestonetitles_title_1",
    "_item": "마스터데이터 - 마일스톤 의뢰 명칭(1번)",
    "_comment": "",
    "ko-KR": "[마일스톤] 눈에 밟히던 놈들",
    "en-US": "[Milestone] An Eyesore"
  },
  {
    "key": "loc_data_string_missionmilestonetitles_title_2",
    "_item": "마스터데이터 - 마일스톤 의뢰 명칭(2번)",
    "_comment": "",
    "ko-KR": "[마일스톤] 폭죽 놀이",
    "en-US": "[Milestone] Fireworks"
  },
  {
    "key": "loc_data_string_missionmilestonetitles_title_3",
    "_item": "마스터데이터 - 마일스톤 의뢰 명칭(3번)",
    "_comment": "",
    "ko-KR": "[마일스톤] 지역사회의 그림자",
    "en-US": "[Milestone] Shadows of Community"
  },
  {
    "key": "loc_data_string_missionmilestonetitles_title_4",
    "_item": "마스터데이터 - 마일스톤 의뢰 명칭(4번)",
    "_comment": "",
    "ko-KR": "[마일스톤] 치외법권",
    "en-US": "[Milestone] Extraterritoriality"
  },
  {
    "key": "loc_data_string_missionmilestonetitles_title_5",
    "_item": "마스터데이터 - 마일스톤 의뢰 명칭(5번)",
    "_comment": "",
    "ko-KR": "[마일스톤] 메세지",
    "en-US": "[Milestone] The Message"
  },
  {
    "key": "loc_data_longtext_missionmilestonetitles_desc_1",
    "_item": "마스터데이터 - 마일스톤 의뢰 설명(1번)",
    "_comment": "",
    "ko-KR": "도시 근교에 있는 많은 양의 마약을 가공하고 있는 시설이 있다고 하지만 정보원은 내게 정보를 주기 꺼려한다. 나도 결국 마약으로 돈을 벌려는 속셈으로 의심하는 모양이지. 신뢰를 쌓기 위해서는 좀 더 착실하게 평판을 쌓아야 한다.",
    "en-US": "There is a facility near the city that processes large amounts of drugs, but the informant is reluctant to give me information. I guess she suspects that I'm trying to make money from drugs after all. In order to build trust, you need to build a more steady reputation."
  },
  {
    "key": "loc_data_longtext_missionmilestonetitles_desc_2",
    "_item": "마스터데이터 - 마일스톤 의뢰 설명(2번)",
    "_comment": "",
    "ko-KR": "카르텔의 주요 인물들이 선상 파티를 벌인다고 한다. 도망칠 수 없는 곳에서 쓸어버리기 좋은 기회이지만, 꽁무니를 잡히지 않기 위한 뒷작업이 필요하다. 일단 초대장을 받을 수 있는 정도로는 유명해져야겠지.",
    "en-US": "Major players of the cartel are said to have a shipboard party. It's a great opportunity to sweep away where you can't run away, but you need to work behind the scenes to avoid getting caught. First of all, you should be famous enough to receive an invitation."
  },
  {
    "key": "loc_data_longtext_missionmilestonetitles_desc_3",
    "_item": "마스터데이터 - 마일스톤 의뢰 설명(3번)",
    "_comment": "",
    "ko-KR": "지역 주민에게 마약을 공급하고 노동력을 갈취하는 움직임이 포착되었다. 이 쓰레기들을 밀어버려야 한다. 그런데 정보가 꽤 잘 은닉되어있다. 정보 획득을 위해 회사에 더 많은 영향력이 필요하다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missionmilestonetitles_desc_4",
    "_item": "마스터데이터 - 마일스톤 의뢰 설명(4번)",
    "_comment": "",
    "ko-KR": "카르텔의 중역이 보스와 대립한 나머지 어처구니없게도 미국에 신병 보호를 요청하려 한다고 한다. 놈이 대사관에 도착하기 전에 중간에 가로채면 조직에 큰 피해를 줄 수 있을 것이다.",
    "en-US": "It is said that the executive of the cartel confronted the boss and is ridiculously trying to ask the U.S. to protect the recruits. If he is intercepted in the middle before arriving at the embassy, it could cause great damage to the organization."
  },
  {
    "key": "loc_data_longtext_missionmilestonetitles_desc_5",
    "_item": "마스터데이터 - 마일스톤 의뢰 설명(5번)",
    "_comment": "",
    "ko-KR": "사람들에게는 충격이 필요하다. 도시 한복판에서 총격전이 벌어지고 카르텔이 몰살된다면 모두가 메세지를 이해할 것이다. 충분한 명성을 쌓은 뒤, 피를 뿌릴 것이다.",
    "en-US": "People need shock. Everyone will understand the message if there is a gunfight in the middle of the city and the cartels are exterminated. Just after building enough fame, I will spray blood."
  },
  {
    "key": "loc_data_string_missionevents_event_title_42",
    "_item": "마스터데이터 - 임무 이벤트 제목(42번)",
    "_comment": "",
    "ko-KR": "마약 가공 시설 파괴",
    "en-US": "Destroy drug processing facilities"
  },
  {
    "key": "loc_data_string_missionevents_event_title_43",
    "_item": "마스터데이터 - 임무 이벤트 제목(43번)",
    "_comment": "",
    "ko-KR": "선상 파티 참가",
    "en-US": "Partake in the cruise party"
  },
  {
    "key": "loc_data_string_missionevents_event_title_44",
    "_item": "마스터데이터 - 임무 이벤트 제목(44번)",
    "_comment": "",
    "ko-KR": "지역 사회 보호",
    "en-US": "Protect local community"
  },
  {
    "key": "loc_data_string_missionevents_event_title_45",
    "_item": "마스터데이터 - 임무 이벤트 제목(45번)",
    "_comment": "",
    "ko-KR": "대사관 진입 저지",
    "en-US": "Blocking entry to the embassy"
  },
  {
    "key": "loc_data_string_missionevents_event_title_46",
    "_item": "마스터데이터 - 임무 이벤트 제목(46번)",
    "_comment": "",
    "ko-KR": "메세지",
    "en-US": "Delivering message"
  },
  {
    "key": "loc_data_string_missionevents_event_title_49",
    "_item": "마스터데이터 - 임무 이벤트 제목(49번)",
    "_comment": "",
    "ko-KR": "첫발을 내딛다",
    "en-US": "Take first step"
  },
  {
    "key": "loc_data_string_missionevents_event_title_101",
    "_item": "마스터데이터 - 임무 이벤트 제목(101번)",
    "_comment": "",
    "ko-KR": "지역경제 활성화 기회",
    "en-US": "Chance to boost local economy"
  },
  {
    "key": "loc_data_string_missionevents_event_title_102",
    "_item": "마스터데이터 - 임무 이벤트 제목(102번)",
    "_comment": "",
    "ko-KR": "총체적 부패",
    "en-US": "Total corruption"
  },
  {
    "key": "loc_data_string_missionevents_event_title_103",
    "_item": "마스터데이터 - 임무 이벤트 제목(103번)",
    "_comment": "",
    "ko-KR": "의도치 않은 노출",
    "en-US": "Unintentional media coverage"
  },
  {
    "key": "loc_data_string_missionevents_event_title_104",
    "_item": "마스터데이터 - 임무 이벤트 제목(104번)",
    "_comment": "",
    "ko-KR": "용병 말고 다른 일",
    "en-US": "Other ways to make living"
  },
  {
    "key": "loc_data_string_missionevents_event_title_105",
    "_item": "마스터데이터 - 임무 이벤트 제목(105번)",
    "_comment": "",
    "ko-KR": "보급지 정보",
    "en-US": "Supply intel"
  },
  {
    "key": "loc_data_string_missionevents_event_title_106",
    "_item": "마스터데이터 - 임무 이벤트 제목(106번)",
    "_comment": "",
    "ko-KR": "쉬운 일",
    "en-US": "Easy work"
  },
  {
    "key": "loc_data_string_missionevents_event_title_107",
    "_item": "마스터데이터 - 임무 이벤트 제목(107번)",
    "_comment": "",
    "ko-KR": "꽤 어려운 일",
    "en-US": "Quite a work"
  },
  {
    "key": "loc_data_string_missionevents_event_title_108",
    "_item": "마스터데이터 - 임무 이벤트 제목(108번)",
    "_comment": "",
    "ko-KR": "불운한 사고",
    "en-US": "An unfortunate incident"
  },
  {
    "key": "loc_data_string_missionevents_event_title_109",
    "_item": "마스터데이터 - 임무 이벤트 제목(109번)",
    "_comment": "",
    "ko-KR": "벌집을 건드림",
    "en-US": "Stirred the hornet's nest"
  },
  {
    "key": "loc_data_string_missionevents_event_title_110",
    "_item": "마스터데이터 - 임무 이벤트 제목(110번)",
    "_comment": "",
    "ko-KR": "갑작스런 군축",
    "en-US": "Unexpected Reduction"
  },
  {
    "key": "loc_data_string_missionevents_event_title_111",
    "_item": "마스터데이터 - 임무 이벤트 제목(111번)",
    "_comment": "",
    "ko-KR": "사람 좋은 의뢰주",
    "en-US": "Nice Client"
  },
  {
    "key": "loc_data_string_missionevents_event_title_112",
    "_item": "마스터데이터 - 임무 이벤트 제목(112번)",
    "_comment": "",
    "ko-KR": "성과를 인정해",
    "en-US": "Acknowledging the achievements"
  },
  {
    "key": "loc_data_string_missionevents_event_title_113",
    "_item": "마스터데이터 - 임무 이벤트 제목(113번)",
    "_comment": "",
    "ko-KR": "총기 시장 전문가",
    "en-US": "Expert in firearms market"
  },
  {
    "key": "loc_data_string_missionevents_event_title_114",
    "_item": "마스터데이터 - 임무 이벤트 제목(114번)",
    "_comment": "",
    "ko-KR": "보호장구 시장 전문가",
    "en-US": "Expert in vests market"
  },
  {
    "key": "loc_data_string_missionevents_event_title_115",
    "_item": "마스터데이터 - 임무 이벤트 제목(115번)",
    "_comment": "",
    "ko-KR": "수류탄 시장 전문가",
    "en-US": "Expert in grenade market"
  },
  {
    "key": "loc_data_string_missionevents_event_title_116",
    "_item": "마스터데이터 - 임무 이벤트 제목(116번)",
    "_comment": "",
    "ko-KR": "사소한 계약 위반",
    "en-US": "Subtle violation"
  },
  {
    "key": "loc_data_string_missionevents_event_title_117",
    "_item": "마스터데이터 - 임무 이벤트 제목(117번)",
    "_comment": "",
    "ko-KR": "예상외의 수입",
    "en-US": "Extra income"
  },
  {
    "key": "loc_data_string_missionevents_event_title_118",
    "_item": "마스터데이터 - 임무 이벤트 제목(118번)",
    "_comment": "",
    "ko-KR": "치료 질 상승 요구",
    "en-US": "Demand on medical quality"
  },
  {
    "key": "loc_data_string_missionevents_event_title_119",
    "_item": "마스터데이터 - 임무 이벤트 제목(119번)",
    "_comment": "",
    "ko-KR": "지시 불만",
    "en-US": "Complaint on field order"
  },
  {
    "key": "loc_data_string_missionevents_event_title_120",
    "_item": "마스터데이터 - 임무 이벤트 제목(120번)",
    "_comment": "",
    "ko-KR": "용병 성장",
    "en-US": "Agent growth"
  },
  {
    "key": "loc_data_string_missionevents_event_title_121",
    "_item": "마스터데이터 - 임무 이벤트 제목(121번)",
    "_comment": "",
    "ko-KR": "강적",
    "en-US": "Elite enemies"
  },
  {
    "key": "loc_data_string_missionevents_event_title_122",
    "_item": "마스터데이터 - 임무 이벤트 제목(122번)",
    "_comment": "",
    "ko-KR": "많은 적",
    "en-US": "More enemies"
  },
  {
    "key": "loc_data_string_missionevents_event_title_123",
    "_item": "마스터데이터 - 임무 이벤트 제목(123번)",
    "_comment": "",
    "ko-KR": "포텐셜 있는 용병들",
    "en-US": "Mercs with potential"
  },
  {
    "key": "loc_data_string_missionevents_event_title_124",
    "_item": "마스터데이터 - 임무 이벤트 제목(124번)",
    "_comment": "",
    "ko-KR": "방랑 의사",
    "en-US": "Travelling doctor"
  },
  {
    "key": "loc_data_string_missionevents_event_title_125",
    "_item": "마스터데이터 - 임무 이벤트 제목(125번)",
    "_comment": "",
    "ko-KR": "처우 불만",
    "en-US": "Unsatisfactory work environment"
  },
  {
    "key": "loc_data_string_missionevents_event_title_126",
    "_item": "마스터데이터 - 임무 이벤트 제목(126번)",
    "_comment": "",
    "ko-KR": "암시장 전문가",
    "en-US": "Expert in black market"
  },
  {
    "key": "loc_data_string_missionevents_event_title_127",
    "_item": "마스터데이터 - 임무 이벤트 제목(127번)",
    "_comment": "",
    "ko-KR": "잠시간의 평화",
    "en-US": "A brief peace"
  },
  {
    "key": "loc_data_string_missionevents_event_title_128",
    "_item": "마스터데이터 - 임무 이벤트 제목(128번)",
    "_comment": "",
    "ko-KR": "이상한 국제 원조",
    "en-US": "Strange ODA"
  },
  {
    "key": "loc_data_string_missionevents_event_title_129",
    "_item": "마스터데이터 - 임무 이벤트 제목(129번)",
    "_comment": "",
    "ko-KR": "경쟁사의 흉계",
    "en-US": "Competitor's scheme"
  },
  {
    "key": "loc_data_string_missionevents_event_title_130",
    "_item": "마스터데이터 - 임무 이벤트 제목(130번)",
    "_comment": "",
    "ko-KR": "지역 경제 활성화",
    "en-US": "Local economic boom"
  },
  {
    "key": "loc_data_string_missionevents_event_title_131",
    "_item": "마스터데이터 - 임무 이벤트 제목(131번)",
    "_comment": "",
    "ko-KR": "지역 경제 침체",
    "en-US": "Local economic slump"
  },
  {
    "key": "loc_data_string_missionevents_event_title_132",
    "_item": "마스터데이터 - 임무 이벤트 제목(132번)",
    "_comment": "",
    "ko-KR": "취업 문제",
    "en-US": "Underemployment"
  },
  {
    "key": "loc_data_string_missionevents_event_title_133",
    "_item": "마스터데이터 - 임무 이벤트 제목(133번)",
    "_comment": "",
    "ko-KR": "바다 건너의 기회",
    "en-US": "An opportunity across the sea"
  },
  {
    "key": "loc_data_string_missionevents_event_title_136",
    "_item": "마스터데이터 - 임무 이벤트 제목(136번)",
    "_comment": "",
    "ko-KR": "해고된 장교들",
    "en-US": "Officers without offices"
  },
  {
    "key": "loc_data_string_missionevents_event_title_137",
    "_item": "마스터데이터 - 임무 이벤트 제목(137번)",
    "_comment": "",
    "ko-KR": "사병 출신 인력",
    "en-US": "Former soldiers"
  },
  {
    "key": "loc_data_string_missionevents_event_title_138",
    "_item": "마스터데이터 - 임무 이벤트 제목(138번)",
    "_comment": "",
    "ko-KR": "무기여 잘있거라",
    "en-US": "Farewell to armnaments"
  },
  {
    "key": "loc_data_string_missionevents_event_title_139",
    "_item": "마스터데이터 - 임무 이벤트 제목(139번)",
    "_comment": "",
    "ko-KR": "그래도 옷은 입으렴",
    "en-US": "Should still wear clothes"
  },
  {
    "key": "loc_data_string_missionevents_event_title_140",
    "_item": "마스터데이터 - 임무 이벤트 제목(140번)",
    "_comment": "",
    "ko-KR": "글로벌 나비 효과",
    "en-US": "Global butterfly effect"
  },
  {
    "key": "loc_data_string_missionevents_event_title_141",
    "_item": "마스터데이터 - 임무 이벤트 제목(141번)",
    "_comment": "",
    "ko-KR": "갑작스런 변화",
    "en-US": "A sudden change"
  },
  {
    "key": "loc_data_string_missionevents_event_title_142",
    "_item": "마스터데이터 - 임무 이벤트 제목(142번)",
    "_comment": "",
    "ko-KR": "심경의 변화",
    "en-US": "A change of mind"
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_42",
    "_item": "마스터데이터 - 임무 이벤트 설명(42번)",
    "_comment": "",
    "ko-KR": "마약 가공 시설을 일소하고 카르텔의 운영에 타격을 주었다. ",
    "en-US": "It wiped out drug processing facilities and hurt the operation of the cartel."
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_43",
    "_item": "마스터데이터 - 임무 이벤트 설명(43번)",
    "_comment": "",
    "ko-KR": "우리는 어디까지나 이번 파티에 참가하지 못한 거다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_44",
    "_item": "마스터데이터 - 임무 이벤트 설명(44번)",
    "_comment": "",
    "ko-KR": "어쩐지 수상하다 했는데 정치인이 연루되어 있었다.",
    "en-US": "I thought it was suspicious, but a politician was involved."
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_45",
    "_item": "마스터데이터 - 임무 이벤트 설명(45번)",
    "_comment": "",
    "ko-KR": "녀석이 미국에 넘기려 했던 정보는 무사히 확보했다.",
    "en-US": "The information he tried to hand over to the United States was secured safely."
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_46",
    "_item": "마스터데이터 - 임무 이벤트 설명(46번)",
    "_comment": "",
    "ko-KR": "할 수 있는 일은 했다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_49",
    "_item": "마스터데이터 - 임무 이벤트 설명(49번)",
    "_comment": "",
    "ko-KR": "첫 의뢰를 성공적으로 끝냈다. 앞으로 더 어려운 의뢰와 강력한 적을 만나게 될 것이다.",
    "en-US": "The first request was successfully completed. In the future, you will encounter more difficult requests and powerful enemies."
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_101",
    "_item": "마스터데이터 - 임무 이벤트 설명(101번)",
    "_comment": "",
    "ko-KR": "지역 정치인이 인프라 투자에서 빼먹은 자금을 발견했다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_102",
    "_item": "마스터데이터 - 임무 이벤트 설명(102번)",
    "_comment": "",
    "ko-KR": "정의감에 찬 기자가 지역 은행의 대규모 횡령을 고발하려 한다. 하지만 아무 것도 바꿀 수 없고 투자만 위축되겠지. 기자의 생명은 이미 위협받고 있다.",
    "en-US": "A journalist with a sense of justice tries to accuse a local bank of large-scale embezzlement. But nothing can change, and investment will only shrink. The journalist's life is already under threat."
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_103",
    "_item": "마스터데이터 - 임무 이벤트 설명(103번)",
    "_comment": "",
    "ko-KR": "임무를 마치고 철수하는 장면이 지역 언론에 노출되었는데, 의도치 않게… 멋진 화면이 나와버렸다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_104",
    "_item": "마스터데이터 - 임무 이벤트 설명(104번)",
    "_comment": "",
    "ko-KR": "의뢰를 성공적으로 마친 덕에 일자리 알선 사업이 재개될 듯하다. 잘 되면 총소리가 덜 들릴지도 모르겠다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_105",
    "_item": "마스터데이터 - 임무 이벤트 설명(105번)",
    "_comment": "",
    "ko-KR": "무기상의 밀수 거점을 알아냈다. 털면 유용한 물자가 나올 것 같다. 어차피 신고도 못할 거 아냐?",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_106",
    "_item": "마스터데이터 - 임무 이벤트 설명(106번)",
    "_comment": "",
    "ko-KR": "만만한 불량배들을 지역 경찰이 실적용으로 건드릴 거라는 정보를 얻었다. 여기서 이득을 챙길 수 있을까?",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_107",
    "_item": "마스터데이터 - 임무 이벤트 설명(107번)",
    "_comment": "",
    "ko-KR": "의뢰주가 결과에 만족한 추가 보상이라며 새 의뢰를 제안했다. 얘기를 들어보니 좀 어려운 전투가 될 것 같은데.",
    "en-US": "The client suggested a new request, saying it was an additional compensation that was satisfied with the result. I heard that it's going to be a difficult battle."
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_108",
    "_item": "마스터데이터 - 임무 이벤트 설명(108번)",
    "_comment": "",
    "ko-KR": "교전 결과 민간인 피해가 발생했고, 피해자 가족이 문제를 제기하고 나섰다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_109",
    "_item": "마스터데이터 - 임무 이벤트 설명(109번)",
    "_comment": "",
    "ko-KR": "이번에 상대한 놈들은 아무래도 뒤끝이 있어보인다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_110",
    "_item": "마스터데이터 - 임무 이벤트 설명(110번)",
    "_comment": "",
    "ko-KR": "정치인들의 사정으로 군축이 진행된다고 한다. 인력을 좀 뽑아올 기회 같은데.",
    "en-US": "It is said that the disarmament proceeds due to the circumstances of politicians. I think it's a chance to get some manpower."
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_111",
    "_item": "마스터데이터 - 임무 이벤트 설명(111번)",
    "_comment": "",
    "ko-KR": "임무에서 도움을 받은 의뢰주가 감사를 표하며, 다가올 임무에서 자기 부하들이 도움을 줄 수 있다고 말한다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_112",
    "_item": "마스터데이터 - 임무 이벤트 설명(112번)",
    "_comment": "",
    "ko-KR": "요원들이 최근 임무 평가에 대해서 불만을 가지고 있다.",
    "en-US": "Agents are unhappy with the recent mission assessment."
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_113",
    "_item": "마스터데이터 - 임무 이벤트 설명(113번)",
    "_comment": "",
    "ko-KR": "총기 시장 전문가를 소개받아 만나게 되었다. 어떤 노하우를 물어볼까.",
    "en-US": "I was introduced to a gun market expert and met him. What kind of know-how should I ask."
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_114",
    "_item": "마스터데이터 - 임무 이벤트 설명(114번)",
    "_comment": "",
    "ko-KR": "보호장구 시장 전문가를 소개받아 만나게 되었다. 어떤 노하우를 물어볼까.",
    "en-US": "I was introduced to a market expert in protective equipment and met him. What kind of know-how should I ask."
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_115",
    "_item": "마스터데이터 - 임무 이벤트 설명(115번)",
    "_comment": "",
    "ko-KR": "수류탄 시장 전문가를 소개받아 만나게 되었다. 어떤 노하우를 물어볼까.",
    "en-US": "I was introduced to a grenade market expert and met him. What kind of know-how should I ask."
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_116",
    "_item": "마스터데이터 - 임무 이벤트 설명(116번)",
    "_comment": "",
    "ko-KR": "요원이 사소한 계약 위반을 저질렀다. 이걸 재계약때 재료로 쓰기엔 살짝 애매한데.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_117",
    "_item": "마스터데이터 - 임무 이벤트 설명(117번)",
    "_comment": "",
    "ko-KR": "사무실 서랍장 뒤편에서 지폐뭉치를 발견했다.",
    "en-US": "I found a bunch of bills in the back of the office drawers."
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_118",
    "_item": "마스터데이터 - 임무 이벤트 설명(118번)",
    "_comment": "",
    "ko-KR": "요원들이 의료 서비스의 질에 대해 불만을 토로했다.",
    "en-US": "Agents complained about the quality of health care."
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_119",
    "_item": "마스터데이터 - 임무 이벤트 설명(119번)",
    "_comment": "",
    "ko-KR": "요원들이 최근 원격 지시에 불만을 느끼고 있다. 강제로 지시하는 방법도 있겠지만…",
    "en-US": "Agents are unhappy with the recent remote instruction. There's a way to force orders, but..."
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_120",
    "_item": "마스터데이터 - 임무 이벤트 설명(120번)",
    "_comment": "",
    "ko-KR": "요원 중 한 명이 갑자기 왕성한 활동을 보이고 있다. 포텐셜이 터진 걸까.",
    "en-US": "One of the agents is suddenly active. Did the potential burst."
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_121",
    "_item": "마스터데이터 - 임무 이벤트 설명(121번)",
    "_comment": "",
    "ko-KR": "꽤 잘 싸우는 패거리의 근거지 정보가 손에 들어왔다.",
    "en-US": "I got information about the base of the pretty good fighting gang."
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_122",
    "_item": "마스터데이터 - 임무 이벤트 설명(122번)",
    "_comment": "",
    "ko-KR": "반쯤 사이비 종교화된 패거리의 위치를 알게 되었다. 머리수 하나는 많을 것이다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_123",
    "_item": "마스터데이터 - 임무 이벤트 설명(123번)",
    "_comment": "",
    "ko-KR": "분쟁지역에서 살아남은 용병들과 계약할 기회를 얻었다. 어떤 용병이 좋을까.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_124",
    "_item": "마스터데이터 - 임무 이벤트 설명(124번)",
    "_comment": "",
    "ko-KR": "자신을 의사라고 주장하는 수상한 남자가 나타났다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_125",
    "_item": "마스터데이터 - 임무 이벤트 설명(125번)",
    "_comment": "",
    "ko-KR": "요원들이 업무환경이 좋지 않다고 느끼고 있다. 개선할 부분이 있을 법한데. ",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_126",
    "_item": "마스터데이터 - 임무 이벤트 설명(126번)",
    "_comment": "",
    "ko-KR": "암시장 전문가를 소개받아 만나게 되었다. 어떤 노하우를 물어볼까.",
    "en-US": "I was introduced to a black market expert and met him. What kind of know-how should I ask."
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_127",
    "_item": "마스터데이터 - 임무 이벤트 설명(127번)",
    "_comment": "",
    "ko-KR": "평화롭다. 가끔은 이런 날도 있어야지.",
    "en-US": "It's peaceful. Sometimes it should be like this."
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_128",
    "_item": "마스터데이터 - 임무 이벤트 설명(128번)",
    "_comment": "",
    "ko-KR": "우리 회사를 무슨 지원 대상에 포함해서 용병 한 명당 지원금을 지급한다고 한다. 오는 돈 막을 이유는 없는데.",
    "en-US": "It is said that each mercenary pays a subsidy, including our company as a target of support. There's no reason to stop the money coming."
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_129",
    "_item": "마스터데이터 - 임무 이벤트 설명(129번)",
    "_comment": "",
    "ko-KR": "신생 회사 하나가 비정상적으로 높은 연봉으로 우리 용병을 유혹하고 있다. 보너스로 달래는 수밖에.",
    "en-US": "A start-up company is luring our mercenaries with unusually high salaries. I have no choice but to soothe it as a bonus."
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_130",
    "_item": "마스터데이터 - 임무 이벤트 설명(130번)",
    "_comment": "",
    "ko-KR": "갑작스레 이 지역에 돈이 돌기 시작했다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_131",
    "_item": "마스터데이터 - 임무 이벤트 설명(131번)",
    "_comment": "",
    "ko-KR": "갑자기 이 지역에서 돈이 빠져나갔다.",
    "en-US": "All of a sudden, money has escaped from this area."
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_132",
    "_item": "마스터데이터 - 임무 이벤트 설명(132번)",
    "_comment": "",
    "ko-KR": "지역경제 타격의 여파로 젊은이들의 취업률이 크게 떨어졌다. 우리 업계에 영향이 있겠지.",
    "en-US": "The employment rate of young people has fallen significantly in the aftermath of the local economy. There must be an impact on our industry."
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_133",
    "_item": "마스터데이터 - 임무 이벤트 설명(133번)",
    "_comment": "",
    "ko-KR": "업계에 신입의 싹이 마르고 있다. 해외 분쟁지역이 더 잘 쳐 준다나.",
    "en-US": "Newcomers are drying up in the industry. Overseas conflict zones are better at hitting."
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_136",
    "_item": "마스터데이터 - 임무 이벤트 설명(136번)",
    "_comment": "",
    "ko-KR": "군축이 진행되는데 장성들은 멀쩡하고 장교들만 옷 벗고 있다 한다. 안타까운 일이지만, 우리한텐 아니지.",
    "en-US": "Disarmament is underway, but the generals are fine and only the officers are undressed. It's unfortunate, but not for us."
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_137",
    "_item": "마스터데이터 - 임무 이벤트 설명(137번)",
    "_comment": "",
    "ko-KR": "전역자들이 대규모로 발생한다고 한다. 일자리는 한정되어 있고. 우리 업계가 좀 완충 역할을 할 수 있을 것 같은데.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_138",
    "_item": "마스터데이터 - 임무 이벤트 설명(138번)",
    "_comment": "",
    "ko-KR": "미국과 소련의 화해 분위기에 각국 국방예산이 동결됐다는 뉴스다. 각국 방산업체는 재고 걱정에 휩싸였겠지.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_139",
    "_item": "마스터데이터 - 임무 이벤트 설명(139번)",
    "_comment": "",
    "ko-KR": "방탄복 무용론이 퍼지고 있다. 신형 관통 탄자 때문이라는데, 내가 보기에는 헛소리다.",
    "en-US": "The theory that body armor is useless is spreading. It's because of the new penetrator, which I think is nonsense."
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_140",
    "_item": "마스터데이터 - 임무 이벤트 설명(140번)",
    "_comment": "",
    "ko-KR": "지구 반대편의 수류탄 제조 업체가 정치적인 이유로 망했다는 모양이다. 압류된 재고들이 여기까지 흘러왔다.",
    "en-US": "It seems that a grenade manufacturer on the other side of the world has gone bankrupt for political reasons. Seized inventories have flowed all the way here."
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_141",
    "_item": "마스터데이터 - 임무 이벤트 설명(141번)",
    "_comment": "",
    "ko-KR": "오랫동안 계속되리라 생각했던 일이 맥없이 끝나곤 한다. 지금이 그런 때인 것 같다.",
    "en-US": "Things that I thought would last for a long time often end without a hitch. I think this is the time."
  },
  {
    "key": "loc_data_longtext_missionevents_event_desc_142",
    "_item": "마스터데이터 - 임무 이벤트 설명(142번)",
    "_comment": "",
    "ko-KR": "요원들과 이야기할 기회다. 생각을 듣고, 어쩌면 생각을 바꿀 수도 있겠지.",
    "en-US": "It's a chance to talk to the agents. You hear what you think, and maybe you change what you think."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_42_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(42_1번)",
    "_comment": "",
    "ko-KR": "이 정도는 시작일 뿐이다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_43_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(43_1번)",
    "_comment": "",
    "ko-KR": "다음 기회에는 꼭 참가하도록 하지.",
    "en-US": "I'll make sure to participate next time."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_44_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(44_1번)",
    "_comment": "",
    "ko-KR": "이건 좀 위험한데.",
    "en-US": "This is a bit dangerous."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_45_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(45_1번)",
    "_comment": "",
    "ko-KR": "이게 카르텔의 숨통을 끊을 것이다.",
    "en-US": "This will take the cartel's breath away."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_46_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(46_1번)",
    "_comment": "",
    "ko-KR": "나는 수호자가 아니다. 나는 파괴자다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_49_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(49_1번)",
    "_comment": "",
    "ko-KR": "뛰어난 인재를 뽑아 훈련시키고 좋은 장비로 무장시키는 한편 임무를 수행해 우리의 이름을 알려야 한다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_101_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(101_1번)",
    "_comment": "",
    "ko-KR": "이 돈이 가야할 곳으로 가면, 지역 경제가 성장할 것이다.",
    "en-US": "If this money goes where it should go, the local economy will grow."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_101_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(101_2번)",
    "_comment": "",
    "ko-KR": "조금 빼 가도 티도 안 날 것이다.",
    "en-US": "You won't be able to tell if you take a little out."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_101_3",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(101_3번)",
    "_comment": "",
    "ko-KR": "지역을 안정시킬 좋은 투자처를 '제안'할 수 있겠군.",
    "en-US": "You can 'suggest' a good investment destination to stabilize the region."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_102_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(102_1번)",
    "_comment": "",
    "ko-KR": "숨기는 건 능사가 아니다. 고발하게 두자.",
    "en-US": "It's not everything to hide. Let's let him file a complaint."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_102_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(102_2번)",
    "_comment": "",
    "ko-KR": "뒷세계 친구들이 기자를 안전히 보호해줄 수 있을 것이다. 그들이 더 활개치겠지만.",
    "en-US": "Friends in the back world will be able to protect the reporter safely. Although they will be more active."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_102_3",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(102_3번)",
    "_comment": "",
    "ko-KR": "기자를 보호하고, '나쁜 놈들'을 죽이면 명성을 쌓을 좋은 기회다.",
    "en-US": "Protecting journalists and killing \"bad guys\" is a good opportunity to build a reputation."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_103_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(103_1번)",
    "_comment": "",
    "ko-KR": "가만히 있자. 기껏해봐야 업계에 애송이들 늘어나기밖에 더 하겠어.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_103_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(103_2번)",
    "_comment": "",
    "ko-KR": "저기에 마음이 동한 친구를 꼬셔볼 수 있지 않을까.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_104_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(104_1번)",
    "_comment": "",
    "ko-KR": "좋은 일이지. 우리도 사람 뽑기 힘들어지겠지만.",
    "en-US": "It's a good thing. It's going to be hard for us to pick people, too."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_104_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(104_2번)",
    "_comment": "",
    "ko-KR": "이 지역은 조금만 더 혼란했으면 좋겠어.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_105_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(105_1번)",
    "_comment": "",
    "ko-KR": "화력을 보충할 타이밍이다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_105_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(105_2번)",
    "_comment": "",
    "ko-KR": "생존이 최우선이다.",
    "en-US": "Survival is the top priority."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_105_3",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(105_3번)",
    "_comment": "",
    "ko-KR": "전술적 선택지를 늘리고 싶군.",
    "en-US": "I want to increase my tactical options."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_106_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(106_1번)",
    "_comment": "",
    "ko-KR": "뒷정리 제안을 해 보자. 파상 공격이 효과적이겠지.",
    "en-US": "Let's make a suggestion to clean up. A wave attack would be effective."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_106_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(106_2번)",
    "_comment": "",
    "ko-KR": "영양가가 별로 없을 것 같다. 정보를 팔아버리지.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_107_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(107_1번)",
    "_comment": "",
    "ko-KR": "새로운 기회를 마다할 이유가 없다.",
    "en-US": "There is no reason to refuse a new opportunity."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_107_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(107_2번)",
    "_comment": "",
    "ko-KR": "확실한 현금이 좋다.",
    "en-US": "Clear cash is good."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_108_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(108_1번)",
    "_comment": "",
    "ko-KR": "현장 요원이 문제를 일으켰다는 자세를 취한다.",
    "en-US": "Take the position that the field agent caused the problem."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_108_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(108_2번)",
    "_comment": "",
    "ko-KR": "돈으로 막을 수 있는 문제는 돈으로 막자.",
    "en-US": "Let's stop the problem that money can prevent."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_108_3",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(108_3번)",
    "_comment": "",
    "ko-KR": "그런 거 신경 써가며 이 일을 할 수는 없어. 대응하지 말자.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_109_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(109_1번)",
    "_comment": "",
    "ko-KR": "소문이 쫙 퍼졌다. 다들 우리의 습격에 대비하고 있을 거다.",
    "en-US": "The rumor spread widely. I'm sure they're all ready for our raid."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_109_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(109_2번)",
    "_comment": "",
    "ko-KR": "놈들이 우리를 무서워 해야지. 쫄릴 거 없다.",
    "en-US": "They should be afraid of us. There's nothing to be nervous about."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_110_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(110_1번)",
    "_comment": "",
    "ko-KR": "장교 쪽으로 집중해서 뽑으면 정예 병력을 굴릴 수 있겠지.",
    "en-US": "If you focus on the officers, you can roll the elite troops."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_110_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(110_2번)",
    "_comment": "",
    "ko-KR": "경험 있는 병사들이 많아지면 사람 채우기 쉽겠네.",
    "en-US": "It would be easier to fill people with more experienced soldiers."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_111_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(111_1번)",
    "_comment": "",
    "ko-KR": "양동을 요청한다.",
    "en-US": "I ask for a bucket."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_111_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(111_2번)",
    "_comment": "",
    "ko-KR": "동행을 요청한다.",
    "en-US": "I ask for a companion."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_112_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(112_1번)",
    "_comment": "",
    "ko-KR": "잘 싸우면 돈은 좀 더 잘 챙겨줄 수 있지.",
    "en-US": "If you fight well, you can take care of the money better."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_112_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(112_2번)",
    "_comment": "",
    "ko-KR": "분명 또 재계약때 뭐라고 할텐데.",
    "en-US": "I'm sure you'll say something when you renew your contract."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_113_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(113_1번)",
    "_comment": "",
    "ko-KR": "최근 좋은 총기가 어디로 흘러갔는지 궁금하다.",
    "en-US": "I wonder where good guns have gone lately."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_113_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(113_2번)",
    "_comment": "",
    "ko-KR": "총기를 싸게 구할 방법을 묻는다.",
    "en-US": "Ask how to get a firearm cheaply."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_114_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(114_1번)",
    "_comment": "",
    "ko-KR": "보호장구를 많이 구입한 곳의 정보를 알고 싶다.",
    "en-US": "I'd like to know information about places where I bought a lot of protective gear."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_114_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(114_2번)",
    "_comment": "",
    "ko-KR": "보호장구 판매자의 연줄을 원한다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_115_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(115_1번)",
    "_comment": "",
    "ko-KR": "수류탄 시장의 최근 현황이 궁금하다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_115_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(115_2번)",
    "_comment": "",
    "ko-KR": "수류탄을 싸게 파는 업자를 소개시켜 달라.",
    "en-US": "Please introduce me to a vendor who sells grenades cheaply."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_116_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(116_1번)",
    "_comment": "",
    "ko-KR": "그래도 계약은 계약이지.",
    "en-US": "But a contract is a contract."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_116_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(116_2번)",
    "_comment": "",
    "ko-KR": "넘어가자.",
    "en-US": "Let's move on."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_117_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(117_1번)",
    "_comment": "",
    "ko-KR": "누가 챙겨놨는지는 모르겠지만 이젠 회사 돈이다.",
    "en-US": "I don't know who took care of it, but now it's company money."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_118_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(118_1번)",
    "_comment": "",
    "ko-KR": "다음번 계약 때 반영할게.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_118_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(118_2번)",
    "_comment": "",
    "ko-KR": "일단 이걸로 진통제 재고부터 채워.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_119_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(119_1번)",
    "_comment": "",
    "ko-KR": "지시 없으면 더 잘 싸워? 해 보라 그래.",
    "en-US": "Do you fight better without instructions? Tell them to try it."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_119_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(119_2번)",
    "_comment": "",
    "ko-KR": "명령 듣게 만들어.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_120_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(120_1번)",
    "_comment": "",
    "ko-KR": "전투력이 성장했으면 좋겠어.",
    "en-US": "I want the combat power to grow."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_120_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(120_2번)",
    "_comment": "",
    "ko-KR": "다양한 경험을 해 봤으면 하네.",
    "en-US": "I'd like to have a variety of experiences."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_120_3",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(120_3번)",
    "_comment": "",
    "ko-KR": "당장 잘 싸우는 게 필요해.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_121_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(121_1번)",
    "_comment": "",
    "ko-KR": "우리 요원들이 다 쓸어버릴 수 있어.",
    "en-US": "Our agents can wipe it out."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_121_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(121_2번)",
    "_comment": "",
    "ko-KR": "그 친구들 힘을 좀 뺀 다음 싸우면 되겠지?",
    "en-US": "Let's just relax them and fight, right?"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_122_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(122_1번)",
    "_comment": "",
    "ko-KR": "도발해. 오합지졸들, 한 번에 사냥한다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_122_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(122_2번)",
    "_comment": "",
    "ko-KR": "양동시키고, 소수로 잠입해서 무력화한다. ",
    "en-US": "Bucket, infiltrate and neutralize in small numbers."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_123_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(123_1번)",
    "_comment": "",
    "ko-KR": "성장 가능성이 높은 용병을 영입하고 싶어.",
    "en-US": "I want to recruit mercenaries with high growth potential."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_123_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(123_2번)",
    "_comment": "",
    "ko-KR": "지금 당장 소방수 역할을 맡을 요원이 필요해.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_124_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(124_1번)",
    "_comment": "",
    "ko-KR": "치료를 요청한다.",
    "en-US": "I call for treatment."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_124_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(124_2번)",
    "_comment": "",
    "ko-KR": "수단과 방법을 가리지 않고 강해지는 방법이 없는지 묻는다.",
    "en-US": "Ask if there is any way to be strong by any means."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_124_3",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(124_3번)",
    "_comment": "",
    "ko-KR": "경찰서에 넘긴다",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_125_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(125_1번)",
    "_comment": "",
    "ko-KR": "위험한 임무에 보낼 때 조금이라도 얹어 줘야.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_125_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(125_2번)",
    "_comment": "",
    "ko-KR": "다쳐서 돌아오면 치료라도 잘 받게 해 줘야.",
    "en-US": "If you get hurt and come back, you should at least let me get treatment."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_125_3",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(125_3번)",
    "_comment": "",
    "ko-KR": "계좌에 바로 꽂히는 현금만큼 좋은 게 별로 없지.",
    "en-US": "There's nothing like cash that goes straight into your account."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_126_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(126_1번)",
    "_comment": "",
    "ko-KR": "고급 총기를 내어줄 거래처를 알고 싶다.",
    "en-US": "I'd like to know a client for high-end firearms."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_126_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(126_2번)",
    "_comment": "",
    "ko-KR": "좋은 보호장구를 고르는 법이 궁금하다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_126_3",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(126_3번)",
    "_comment": "",
    "ko-KR": "품질이 좋은 수류탄을 만드는 업자와 연락하고 싶다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_127_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(127_1번)",
    "_comment": "",
    "ko-KR": "내일도 오늘의 평온이 이어지길.",
    "en-US": "May today's calm continue tomorrow."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_128_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(128_1번)",
    "_comment": "",
    "ko-KR": "무슨 일 생기면 우린 모르는 일이야.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_129_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(129_1번)",
    "_comment": "",
    "ko-KR": "우리가 그래도 잘 챙겨주지.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_130_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(130_1번)",
    "_comment": "",
    "ko-KR": "버는 돈도 직원한테 줄 돈도 늘겠군.",
    "en-US": "You'll earn more and more money to give to your employees."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_131_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(131_1번)",
    "_comment": "",
    "ko-KR": "버는 돈도 직원한테 줄 돈도 줄겠군.",
    "en-US": "I'll give you the money you earn and the money you'll give to your employees."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_132_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(132_1번)",
    "_comment": "",
    "ko-KR": "사람이 늘겠군. 뽑을 사람도 쏠 사람도.",
    "en-US": "There must be more people. Anyone who wants to pick one or buy one."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_133_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(133_1번)",
    "_comment": "",
    "ko-KR": "사람이 줄겠군. 뽑을 사람도 쏠 사람도.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_136_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(136_1번)",
    "_comment": "",
    "ko-KR": "비싸지만 경험있는 인력이 많이 풀리겠군.",
    "en-US": "It's expensive, but there will be a lot of experienced manpower."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_137_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(137_1번)",
    "_comment": "",
    "ko-KR": "짧게 일할 인력을 많이 찾을 수 있겠군.",
    "en-US": "We can find a lot of people to work short."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_138_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(138_1번)",
    "_comment": "",
    "ko-KR": "곧 고품질 무기가 시장에 풀리겠군.",
    "en-US": "High-quality weapons will soon be released to the market."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_139_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(139_1번)",
    "_comment": "",
    "ko-KR": "멀쩡한 거 안 사가면 나야 고맙지.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_140_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(140_1번)",
    "_comment": "",
    "ko-KR": "평소에 사기 어려운 수류탄도 구할 수 있을 것 같다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_141_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(141_1번)",
    "_comment": "",
    "ko-KR": "하지만 이게 이렇게 끝날 줄이야.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_142_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(142_1번)",
    "_comment": "",
    "ko-KR": "이렇게 생각해 보는 건 어때?",
    "en-US": "Why don't you think about it like this?"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_142_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(142_2번)",
    "_comment": "",
    "ko-KR": "그건 오해였어.",
    "en-US": "That was a misunderstanding."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_desc_142_3",
    "_item": "마스터데이터 - 임무 이벤트 선택지 설명(142_3번)",
    "_comment": "",
    "ko-KR": "별 일 없었지? 내일도 잘 하자.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_42_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(42_1번)",
    "_comment": "이것들 적당히 데이터로 빼는 게 맞을듯(중복되는 종류 줄이기)",
    "ko-KR": "명성 수치 최대치 500으로 상승",
    "en-US": "Rising to 500 in Fame"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_43_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(43_1번)",
    "_comment": "",
    "ko-KR": "명성 수치 최대치 2000으로 상승",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_44_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(44_1번)",
    "_comment": "",
    "ko-KR": "명성 수치 최대치 8000으로 상승",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_45_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(45_1번)",
    "_comment": "",
    "ko-KR": "명성 수치 최대치 15000으로 상승",
    "en-US": "Fame numbers rise to 15000 maximum"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_46_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(46_1번)",
    "_comment": "",
    "ko-KR": "GAME CLEAR",
    "en-US": "GAME CLEAR"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_49_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(49_1번)",
    "_comment": "",
    "ko-KR": "계속 성장해야 한다.",
    "en-US": "We need to keep going."
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_101_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(101_1번)",
    "_comment": "",
    "ko-KR": "지역이 4개월간 [호경기] 획득",
    "en-US": "the region has won [a boom] for four months"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_101_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(101_2번)",
    "_comment": "",
    "ko-KR": "$1000 획득",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_101_3",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(101_3번)",
    "_comment": "",
    "ko-KR": "지역에서 가장 영향력이 높고 범죄자가 아닌 세력이 영향력을 5 획득",
    "en-US": "The most influential and non-criminal forces in the region gain influence 5"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_102_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(102_1번)",
    "_comment": "",
    "ko-KR": "지역이 4개월간 [불경기] 획득",
    "en-US": "the region has won a four-month [recession]"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_102_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(102_2번)",
    "_comment": "",
    "ko-KR": "지역의 범죄자 세력이 영향력을 5 획득",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_102_3",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(102_3번)",
    "_comment": "",
    "ko-KR": "지역에 [원한 쌓임] 세부 항목이 있는 의뢰가 하나 생성",
    "en-US": "Create one referral with [wanted build] detail in the area"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_103_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(103_1번)",
    "_comment": "",
    "ko-KR": "지역이 4개월간 [유망한 업계] 획득",
    "en-US": "the region has won [promising industry] for four months"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_103_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(103_2번)",
    "_comment": "",
    "ko-KR": "지역에 용병 1명이 리스팅되고, 해당 용병은 8개월간 [사춘기]를 지니고 있음",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_104_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(104_1번)",
    "_comment": "",
    "ko-KR": "지역이 4개월간 [구인난] 획득",
    "en-US": "a four-month local acquisition"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_104_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(104_2번)",
    "_comment": "",
    "ko-KR": "지역의 범죄자 세력이 영향력을 5 획득",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_105_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(105_1번)",
    "_comment": "",
    "ko-KR": "해당 지역에 [무기고] 세부 항목이 있는 의뢰가 하나 생성",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_105_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(105_2번)",
    "_comment": "",
    "ko-KR": "해당 지역에 [방탄장비 보관소] 세부 항목이 있는 의뢰가 하나 생성",
    "en-US": "Create one request with details of [bulletproof equipment storage] in the area"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_105_3",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(105_3번)",
    "_comment": "",
    "ko-KR": "해당 지역에 [폭발물 창고] 세부 항목이 있는 의뢰가 하나 생성",
    "en-US": "Create one referral with [explosive warehouse] detail in the area"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_106_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(106_1번)",
    "_comment": "",
    "ko-KR": "지역에 [후속 타격 의뢰] 세부 항목이 있는 의뢰가 하나 생성",
    "en-US": "Create one referral with [follow-up strike] detail in the area"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_106_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(106_2번)",
    "_comment": "",
    "ko-KR": "$300 획득",
    "en-US": "Get $300"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_107_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(107_1번)",
    "_comment": "",
    "ko-KR": "지역에 [정예 의뢰] 세부 항목이 있는 의뢰가 하나 생성",
    "en-US": "Create one referral with details in the area"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_107_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(107_2번)",
    "_comment": "",
    "ko-KR": "$500 획득",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_108_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(108_1번)",
    "_comment": "",
    "ko-KR": "임무에 참여한 요원 중 2명이 4개월간 [낮은 모럴] 획득",
    "en-US": "Two of the agents in the mission acquire [low morpheme] for four months"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_108_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(108_2번)",
    "_comment": "",
    "ko-KR": "$1000 지불",
    "en-US": "a payment of a thousand dollars"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_108_3",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(108_3번)",
    "_comment": "",
    "ko-KR": "명성 50 감소",
    "en-US": "50 Declines in Fame"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_109_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(109_1번)",
    "_comment": "",
    "ko-KR": "해당 지역에 [보급 어려움] 임무가 하나 생성",
    "en-US": "Create one [difficulty distributing] mission in the area"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_109_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(109_2번)",
    "_comment": "",
    "ko-KR": "해당 지역에 [원한 쌓임] 임무가 하나 생성",
    "en-US": "Create one [wanted pile] mission in the area"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_110_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(110_1번)",
    "_comment": "",
    "ko-KR": "지역이 4개월간 [고급 인력] 획득",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_110_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(110_2번)",
    "_comment": "",
    "ko-KR": "지역이 4개월간 [많은 인력] 획득",
    "en-US": "the region has won [a large workforce] for four months"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_111_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(111_1번)",
    "_comment": "",
    "ko-KR": "해당 지역에 [분산된 적] 임무가 하나 생성",
    "en-US": "Create one [Distributed Enemy] mission in the area"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_111_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(111_2번)",
    "_comment": "",
    "ko-KR": "해당 지역에 [현지 증원] 임무가 하나 생성",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_112_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(112_1번)",
    "_comment": "",
    "ko-KR": "임무에 참여한 요원 중 2명이 [협상 강점] 아젠다, 4개월간 [사기 충천] 획득",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_112_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(112_2번)",
    "_comment": "",
    "ko-KR": "임무에 참여한 요원 중 2명이 4개월간 [낮은 모럴] 획득",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_113_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(113_1번)",
    "_comment": "",
    "ko-KR": "지역이 4개월간 [총기 보상 강화] 획득",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_113_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(113_2번)",
    "_comment": "",
    "ko-KR": "지역이 4개월간 [총기 시장 보급화] 획득",
    "en-US": "Region wins [gun market penetration] for 4 months"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_114_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(114_1번)",
    "_comment": "",
    "ko-KR": "지역이 4개월간 [보호장구 보상 강화] 획득",
    "en-US": "Regions win [enhanced protective gear compensation] for 4 months"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_114_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(114_2번)",
    "_comment": "",
    "ko-KR": "지역이 4개월간 [보호장구 시장 보급화] 획득",
    "en-US": "Regions Acquire [Providing Protective Equipment Market] for 4 Months"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_115_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(115_1번)",
    "_comment": "",
    "ko-KR": "지역이 4개월간 [수류탄 보상 강화] 획득",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_115_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(115_2번)",
    "_comment": "",
    "ko-KR": "지역이 4개월간 [수류탄 시장 보급화] 획득",
    "en-US": "The region has been acquired for four months [invigorating the hydrocarbon market]"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_116_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(116_1번)",
    "_comment": "",
    "ko-KR": "임무에 참여한 요원 중 1명이 [협상 약점] 아젠다, 4개월간 [낮은 모럴] 획득",
    "en-US": "One of the agents on the mission gets [negotiation weakness] Agenda, 4 months [low moral]"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_116_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(116_2번)",
    "_comment": "",
    "ko-KR": "아무 일도 일어나지 않음",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_117_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(117_1번)",
    "_comment": "",
    "ko-KR": "$500 획득",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_118_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(118_1번)",
    "_comment": "",
    "ko-KR": "계약중인 요원 중 2명이 [위험 수당 요구] 아젠다를 획득",
    "en-US": "Two of the contracting agents have obtained an agenda [requiring hazard pay]"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_118_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(118_2번)",
    "_comment": "",
    "ko-KR": "$1000 지불",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_119_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(119_1번)",
    "_comment": "",
    "ko-KR": "임무에 참여한 요원 중 2명이 4개월간 [현장 판단 우선] 획득",
    "en-US": "Two of the agents in the mission get [on-site judgment first] for four months"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_119_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(119_2번)",
    "_comment": "",
    "ko-KR": "임무에 참여한 요원 중 2명이 4개월간 [낮은 모럴] 획득",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_120_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(120_1번)",
    "_comment": "",
    "ko-KR": "임무에 참여한 요원 중 1명이 4개월간 [성장기] 획득",
    "en-US": "One of the agents in the mission gets four months [growth period]"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_120_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(120_2번)",
    "_comment": "",
    "ko-KR": "임무에 참여한 요원 중 1명이 4개월간 [사춘기] 획득",
    "en-US": "One of the agents in the mission has four months [adolescence] obtained"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_120_3",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(120_3번)",
    "_comment": "",
    "ko-KR": "임무에 참여한 요원 중 1명이 4개월간 [사기충천] 획득",
    "en-US": "One of the agents in the mission has obtained [fraud] for four months"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_121_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(121_1번)",
    "_comment": "",
    "ko-KR": "해당 지역에 [정예 의뢰] 세부 항목이 있는 의뢰가 하나 생성",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_121_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(121_2번)",
    "_comment": "",
    "ko-KR": "해당 지역에 [후속 타격 의뢰] 세부 항목이 있는 의뢰가 하나 생성",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_122_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(122_1번)",
    "_comment": "",
    "ko-KR": "해당 지역에 [원한 쌓임] 임무가 하나 생성",
    "en-US": "Create one [wanted pile] mission in the area"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_122_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(122_2번)",
    "_comment": "",
    "ko-KR": "해당 지역에 [보급 어려움] 임무가 하나 생성",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_123_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(123_1번)",
    "_comment": "",
    "ko-KR": "지역에 용병 1명이 리스팅되고, 해당 용병은 8개월간 [성장기]를 지니고 있음",
    "en-US": "One mercenary is listed in the region, and the mercenary has a [growing season] for eight months"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_123_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(123_2번)",
    "_comment": "",
    "ko-KR": "지역에 용병 1명이 리스팅되고, 해당 용병은 4개월간 [사기충천]을 지니고 있음",
    "en-US": "One mercenary is listed in the area, and the mercenary is [fraudulent] for four months"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_124_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(124_1번)",
    "_comment": "",
    "ko-KR": "$1000을 지불하고 모든 요원의 생명력을 모두 회복",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_124_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(124_2번)",
    "_comment": "",
    "ko-KR": "$500을 지불하고 계약중인 요원 중 1명이 4개월간 [약물 투여]를 획득",
    "en-US": "One of the contracting agents paid $500 to obtain [drug administration] for four months"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_124_3",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(124_3번)",
    "_comment": "",
    "ko-KR": "$500 획득",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_125_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(125_1번)",
    "_comment": "",
    "ko-KR": "계약중인 요원 중 2명이 [위험 수당 요구] 아젠다를 획득",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_125_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(125_2번)",
    "_comment": "",
    "ko-KR": "계약중인 요원 중 2명이 [의료 우대 요구] 아젠다를 획득",
    "en-US": "Two of the contracting agents have obtained an agenda"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_125_3",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(125_3번)",
    "_comment": "",
    "ko-KR": "$1000 지불",
    "en-US": "a payment of a thousand dollars"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_126_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(126_1번)",
    "_comment": "",
    "ko-KR": "4개월간 [총기 시장 고급화] 획득",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_126_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(126_2번)",
    "_comment": "",
    "ko-KR": "4개월간 [보호장구 시장 고급화] 획득",
    "en-US": "Obtained [Advanced Protective Equipment Market] for 4 months"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_126_3",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(126_3번)",
    "_comment": "",
    "ko-KR": "4개월간 [수류탄 시장 고급화] 획득",
    "en-US": "Obtained [Hydrocarbon Market Advanced] for 4 months"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_127_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(127_1번)",
    "_comment": "",
    "ko-KR": "아무 일도 일어나지 않음",
    "en-US": "Nothing Happens"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_128_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(128_1번)",
    "_comment": "",
    "ko-KR": "계약중인 요원 1명당 $200 획득",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_129_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(129_1번)",
    "_comment": "",
    "ko-KR": "계약중인 요원 1명당 $200 상실(상실의 결과 잔고가 $0 아래로 떨어지지는 않음)",
    "en-US": "Loss of $200 per agent under contract (the balance does not fall below $0 as a result of the loss)"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_130_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(130_1번)",
    "_comment": "",
    "ko-KR": "지역이 12개월간 [호경기] 획득",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_131_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(131_1번)",
    "_comment": "",
    "ko-KR": "지역이 12개월간 [불경기] 획득",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_132_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(132_1번)",
    "_comment": "",
    "ko-KR": "지역이 8개월간 [유망한 업계] 획득",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_133_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(133_1번)",
    "_comment": "",
    "ko-KR": "지역이 8개월간 [구인난] 획득",
    "en-US": "eight months of local [rescue] acquisition"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_136_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(136_1번)",
    "_comment": "",
    "ko-KR": "지역이 8개월간 [고급 인력] 획득",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_137_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(137_1번)",
    "_comment": "",
    "ko-KR": "지역이 8개월간 [많은 인력] 획득",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_138_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(138_1번)",
    "_comment": "",
    "ko-KR": "지역이 2개월간 [총기 시장 고급화] 획득",
    "en-US": "The region has won [gun market luxury] for two months"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_139_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(139_1번)",
    "_comment": "",
    "ko-KR": "지역이 2개월간 [보호장구 시장 고급화] 획득",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_140_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(140_1번)",
    "_comment": "",
    "ko-KR": "지역이 2개월간 [수류탄 시장 고급화] 획득",
    "en-US": "Region acquires [Hydrocarbon Market Advanced] for 2 months"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_141_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(141_1번)",
    "_comment": "",
    "ko-KR": "[] 지역의 [] 삭제",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_142_1",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(142_1번)",
    "_comment": "",
    "ko-KR": "[] 용병의 [] 삭제",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_142_2",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(142_2번)",
    "_comment": "",
    "ko-KR": "[] 용병의 [] 삭제",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_missioneventchoices_choice_result_desc_142_3",
    "_item": "마스터데이터 - 임무 이벤트 선택지 결과 설명(142_3번)",
    "_comment": "",
    "ko-KR": "아무 일도 일어나지 않음",
    "en-US": "Nothing Happens"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_global_1_boom",
    "_item": "마스터데이터 - 모디파이어 이름(호경기)",
    "_comment": "",
    "ko-KR": "호경기",
    "en-US": "a booming economy"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_global_2_recession",
    "_item": "마스터데이터 - 모디파이어 이름(불경기)",
    "_comment": "",
    "ko-KR": "불경기",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_global_3_promising",
    "_item": "마스터데이터 - 모디파이어 이름(유망한 업계)",
    "_comment": "",
    "ko-KR": "유망한 업계",
    "en-US": "a promising industry"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_global_4_youtuber",
    "_item": "마스터데이터 - 모디파이어 이름(바다 건너의 기회)",
    "_comment": "",
    "ko-KR": "바다 건너의 기회",
    "en-US": "an opportunity across the sea"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_global_5_excess_firearm",
    "_item": "마스터데이터 - 모디파이어 이름(무기 보상 강화)",
    "_comment": "",
    "ko-KR": "무기 보상 강화",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_global_6_excess_equipment",
    "_item": "마스터데이터 - 모디파이어 이름(장비 보상 강화)",
    "_comment": "",
    "ko-KR": "장비 보상 강화",
    "en-US": "Enhance equipment compensation"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_global_7_excess_throwable",
    "_item": "마스터데이터 - 모디파이어 이름(투척물 보상 강화)",
    "_comment": "",
    "ko-KR": "투척물 보상 강화",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_global_8_contract_faithful",
    "_item": "마스터데이터 - 모디파이어 이름(고용안정성 증가)",
    "_comment": "",
    "ko-KR": "고용안정성 증가",
    "en-US": "Increased job security"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_global_9_contract_decieve",
    "_item": "마스터데이터 - 모디파이어 이름(부당 계약)",
    "_comment": "",
    "ko-KR": "부당 계약",
    "en-US": "an unfair contract"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_global_10_high_manpower",
    "_item": "마스터데이터 - 모디파이어 이름(고급 인력)",
    "_comment": "",
    "ko-KR": "고급 인력",
    "en-US": "high-quality human resources"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_global_11_platform_labor",
    "_item": "마스터데이터 - 모디파이어 이름(사병 출신 인력)",
    "_comment": "",
    "ko-KR": "사병 출신 인력",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_global_12_shop_firearm",
    "_item": "마스터데이터 - 모디파이어 이름(무기 상점 강화)",
    "_comment": "",
    "ko-KR": "무기 상점 강화",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_global_13_shop_equipment",
    "_item": "마스터데이터 - 모디파이어 이름(장비 상점 강화)",
    "_comment": "",
    "ko-KR": "장비 상점 강화",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_global_14_shop_throwable",
    "_item": "마스터데이터 - 모디파이어 이름(투척물 상점 강화)",
    "_comment": "",
    "ko-KR": "투척물 상점 강화",
    "en-US": "Strengthen throwaway stores"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_global_25_mission_supply",
    "_item": "마스터데이터 - 모디파이어 이름(보급품 부족)",
    "_comment": "",
    "ko-KR": "보급품 부족",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_global_26_mission_resent",
    "_item": "마스터데이터 - 모디파이어 이름(깊은 원한)",
    "_comment": "",
    "ko-KR": "깊은 원한",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_mission_1_firearm",
    "_item": "마스터데이터 - 모디파이어 이름(무기고)",
    "_comment": "",
    "ko-KR": "무기고",
    "en-US": "Armory"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_mission_2_equipment",
    "_item": "마스터데이터 - 모디파이어 이름(방탄장비 보관소)",
    "_comment": "",
    "ko-KR": "방탄장비 보관소",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_mission_3_throwable",
    "_item": "마스터데이터 - 모디파이어 이름(투척물 창고)",
    "_comment": "",
    "ko-KR": "투척물 창고",
    "en-US": "Grenade storage"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_mission_4_hyena",
    "_item": "마스터데이터 - 모디파이어 이름(후속 타격 의뢰)",
    "_comment": "",
    "ko-KR": "후속 타격 의뢰",
    "en-US": "Subsequent strike request"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_mission_5_elite",
    "_item": "마스터데이터 - 모디파이어 이름(정예 의뢰)",
    "_comment": "",
    "ko-KR": "정예 의뢰",
    "en-US": "an elite commission"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_mission_6_supply",
    "_item": "마스터데이터 - 모디파이어 이름(보급 어려움)",
    "_comment": "",
    "ko-KR": "보급 어려움",
    "en-US": "difficulty in dissemination"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_mission_7_resent",
    "_item": "마스터데이터 - 모디파이어 이름(원한 쌓임)",
    "_comment": "",
    "ko-KR": "원한 쌓임",
    "en-US": "a rancorous build-up"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_mission_8_weaken",
    "_item": "마스터데이터 - 모디파이어 이름(분산된 적)",
    "_comment": "",
    "ko-KR": "분산된 적",
    "en-US": "a dispersed enemy"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_mission_9_militia",
    "_item": "마스터데이터 - 모디파이어 이름(현지 증원)",
    "_comment": "",
    "ko-KR": "현지 증원",
    "en-US": "local reinforcements"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_agent_5_disable_prompt",
    "_item": "마스터데이터 - 모디파이어 이름(현장 판단 우선)",
    "_comment": "",
    "ko-KR": "현장 판단 우선",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_agent_6_morale_high",
    "_item": "마스터데이터 - 모디파이어 이름(사기충천)",
    "_comment": "",
    "ko-KR": "사기충천",
    "en-US": "fraudulent recommendation"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_agent_7_morale_low",
    "_item": "마스터데이터 - 모디파이어 이름(낮은 모럴)",
    "_comment": "",
    "ko-KR": "낮은 모럴",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_agent_8_growth_power",
    "_item": "마스터데이터 - 모디파이어 이름(성장기)",
    "_comment": "",
    "ko-KR": "성장기",
    "en-US": "Season of growth"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_agent_9_growth_perk",
    "_item": "마스터데이터 - 모디파이어 이름(사춘기)",
    "_comment": "",
    "ko-KR": "사춘기",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_agent_10_drug",
    "_item": "마스터데이터 - 모디파이어 이름(약물 투여)",
    "_comment": "",
    "ko-KR": "약물 투여",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_agent_13_agenda_strong",
    "_item": "마스터데이터 - 모디파이어 이름(협상 강점)",
    "_comment": "",
    "ko-KR": "협상 강점",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_agent_14_agenda_weak",
    "_item": "마스터데이터 - 모디파이어 이름(협상 약점)",
    "_comment": "",
    "ko-KR": "협상 약점",
    "en-US": "Weakness in Negotiation"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_agent_15_agenda_expensive",
    "_item": "마스터데이터 - 모디파이어 이름(몸값 높이기 욕구)",
    "_comment": "",
    "ko-KR": "몸값 높이기 욕구",
    "en-US": "a desire to raise the ransom"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_agent_16_agenda_frequent",
    "_item": "마스터데이터 - 모디파이어 이름(빠른 재협상 욕구)",
    "_comment": "",
    "ko-KR": "빠른 재협상 욕구",
    "en-US": "a desire for a quick renegotiation"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_agent_17_agenda_safety",
    "_item": "마스터데이터 - 모디파이어 이름(안전 욕구)",
    "_comment": "",
    "ko-KR": "안전 욕구",
    "en-US": "Need for safety"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_agent_18_agenda_wellbeing",
    "_item": "마스터데이터 - 모디파이어 이름(웰빙 욕구)",
    "_comment": "",
    "ko-KR": "웰빙 욕구",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_agent_19_agenda_complicated",
    "_item": "마스터데이터 - 모디파이어 이름(복잡한 사정)",
    "_comment": "",
    "ko-KR": "복잡한 사정",
    "en-US": "Complicated circumstances"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_contract_1_frequency_high",
    "_item": "마스터데이터 - 모디파이어 이름(많은 충돌)",
    "_comment": "",
    "ko-KR": "많은 충돌",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_contract_2_frequency_low",
    "_item": "마스터데이터 - 모디파이어 이름(적은 충돌)",
    "_comment": "",
    "ko-KR": "적은 충돌",
    "en-US": "the enemy's clash"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_contract_3_difficulty_high",
    "_item": "마스터데이터 - 모디파이어 이름(높은 무장 수준)",
    "_comment": "",
    "ko-KR": "높은 무장 수준",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_contract_4_difficulty_low",
    "_item": "마스터데이터 - 모디파이어 이름(낮은 무장 수준)",
    "_comment": "",
    "ko-KR": "낮은 무장 수준",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_contract_5_reward_high",
    "_item": "마스터데이터 - 모디파이어 이름(높은 보상)",
    "_comment": "",
    "ko-KR": "높은 보상",
    "en-US": "a high reward"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_contract_6_reward_low",
    "_item": "마스터데이터 - 모디파이어 이름(낮은 보상)",
    "_comment": "",
    "ko-KR": "낮은 보상",
    "en-US": "a low reward"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_recruit_1_apply_high",
    "_item": "마스터데이터 - 모디파이어 이름(많은 지원서)",
    "_comment": "",
    "ko-KR": "많은 지원서",
    "en-US": "a lot of applications"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_recruit_2_apply_low",
    "_item": "마스터데이터 - 모디파이어 이름(적은 지원서)",
    "_comment": "",
    "ko-KR": "적은 지원서",
    "en-US": "a small application"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_recruit_3_wage_high",
    "_item": "마스터데이터 - 모디파이어 이름(높은 인건비)",
    "_comment": "",
    "ko-KR": "높은 인건비",
    "en-US": "high labor costs"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_recruit_4_wage_low",
    "_item": "마스터데이터 - 모디파이어 이름(낮은 인건비)",
    "_comment": "",
    "ko-KR": "낮은 인건비",
    "en-US": "low labor costs"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_recruit_5_quality_high",
    "_item": "마스터데이터 - 모디파이어 이름(높은 인력 수준)",
    "_comment": "",
    "ko-KR": "높은 인력 수준",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_string_modifiers_name_mod_recruit_6_quality_low",
    "_item": "마스터데이터 - 모디파이어 이름(낮은 인력 수준)",
    "_comment": "",
    "ko-KR": "낮은 인력 수준",
    "en-US": "a low level of manpower"
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_global_1_boom",
    "_item": "마스터데이터 - 모디파이어 설명(호경기)",
    "_comment": "",
    "ko-KR": "임무 보상 50% 증가, 용병 기본급 및 인센티브 50% 증가",
    "en-US": "50% increase in mission compensation, 50% increase in mercenary base salary and incentives"
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_global_2_recession",
    "_item": "마스터데이터 - 모디파이어 설명(불경기)",
    "_comment": "",
    "ko-KR": "임무 보상 30% 감소, 용병 기본급 및 인센티브 50% 감소",
    "en-US": "30% reduction in mission compensation, 50% reduction in mercenary base salary and incentives"
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_global_3_promising",
    "_item": "마스터데이터 - 모디파이어 설명(유망한 업계)",
    "_comment": "",
    "ko-KR": "생성되는 임무의 위협이 1 증가, 용병의 첫 계약 기본급 및 인센티브 50% 감소",
    "en-US": "Threats of mission created increased by 1; mercenaries' first contract base salary and incentives reduced by 50%"
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_global_4_youtuber",
    "_item": "마스터데이터 - 모디파이어 설명(바다 건너의 기회)",
    "_comment": "",
    "ko-KR": "모든 임무의 위협이 1 감소, 상점에 리스팅되는 용병 수 -1",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_global_5_excess_firearm",
    "_item": "마스터데이터 - 모디파이어 설명(무기 보상 강화)",
    "_comment": "",
    "ko-KR": "선택보상 발생 시 무기를 선택하면 2배를 받지만 장비, 투척물은 선택 불가.",
    "en-US": "If you select a weapon in case of optional compensation, you will receive twice as much, but you will not be able to select equipment or throws."
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_global_6_excess_equipment",
    "_item": "마스터데이터 - 모디파이어 설명(장비 보상 강화)",
    "_comment": "",
    "ko-KR": "선택보상 발생 시 장비를 선택하면 2배를 받지만 무기, 투척물은 선택 불가.",
    "en-US": "When optional compensation occurs, you get twice as much if you choose equipment, but weapons and throws cannot be selected."
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_global_7_excess_throwable",
    "_item": "마스터데이터 - 모디파이어 설명(투척물 보상 강화)",
    "_comment": "",
    "ko-KR": "선택보상 발생 시 투척물을 선택하면 2배를 받지만 무기, 장비는 선택 불가.",
    "en-US": "When optional compensation occurs, you receive twice as much if you choose a throwaway, but weapons and equipment cannot be selected."
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_global_8_contract_faithful",
    "_item": "마스터데이터 - 모디파이어 설명(고용안정성 증가)",
    "_comment": "",
    "ko-KR": "모든 계약금에 120%가 곱해짐. 계약 옵션으로 [장기 계약]을 붙일 때 계약 총액 180% 효과가 110% 효과로 완화",
    "en-US": "120% multiplied by all down payment. 180% effect of contract total to 110% effect when attaching [long-term contract] as contract option"
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_global_9_contract_decieve",
    "_item": "마스터데이터 - 모디파이어 설명(부당 계약)",
    "_comment": "",
    "ko-KR": "용병과 처음 계약할 때 계약금을 최초 계약금의 50%로 계약하지만, 계약 아젠다로 [협상 강점]을 획득",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_global_10_high_manpower",
    "_item": "마스터데이터 - 모디파이어 설명(고급 인력)",
    "_comment": "",
    "ko-KR": "새 Recruit Report에서 용병의 전투력이 1 증가하지만, 최초 계약금도 20% 증가",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_global_11_platform_labor",
    "_item": "마스터데이터 - 모디파이어 설명(사병 출신 인력)",
    "_comment": "",
    "ko-KR": "새 Recruit Report에서 용병의 수가 2 증가하고, 최초 계약금이 20%, 계약기간이 33% 감소",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_global_12_shop_firearm",
    "_item": "마스터데이터 - 모디파이어 설명(무기 상점 강화)",
    "_comment": "",
    "ko-KR": "상점에 생성되는 무기의 ★이 1 증가.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_global_13_shop_equipment",
    "_item": "마스터데이터 - 모디파이어 설명(장비 상점 강화)",
    "_comment": "",
    "ko-KR": "상점에 생성되는 장비의 ★이 1 증가.",
    "en-US": "1 increase in ★ of equipment created in the store."
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_global_14_shop_throwable",
    "_item": "마스터데이터 - 모디파이어 설명(투척물 상점 강화)",
    "_comment": "",
    "ko-KR": "상점이 서로 다른 투척물 2종을 판매.",
    "en-US": "The store sells two different kinds of throws."
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_global_25_mission_supply",
    "_item": "마스터데이터 - 모디파이어 설명(보급품 부족)",
    "_comment": "",
    "ko-KR": "임무가 생성될 때 25% 확률로 [보급 어려움]을 가짐.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_global_26_mission_resent",
    "_item": "마스터데이터 - 모디파이어 설명(깊은 원한)",
    "_comment": "",
    "ko-KR": "임무가 생성될 때 25% 확률로 [원한 쌓임]을 가짐.",
    "en-US": "Have [wanted build-up] with 25% probability when missions are created."
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_mission_1_firearm",
    "_item": "마스터데이터 - 모디파이어 설명(무기고)",
    "_comment": "",
    "ko-KR": "2티어 이상 무기를 보상으로 추가 획득.",
    "en-US": "Additional 2 Tier or more weapons as rewards."
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_mission_2_equipment",
    "_item": "마스터데이터 - 모디파이어 설명(방탄장비 보관소)",
    "_comment": "",
    "ko-KR": "방탄장비를 보상으로 추가 획득.",
    "en-US": "Get additional bulletproof equipment as a reward."
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_mission_3_throwable",
    "_item": "마스터데이터 - 모디파이어 설명(투척물 창고)",
    "_comment": "",
    "ko-KR": "투척물을 보상으로 추가 획득.",
    "en-US": "Earn additional throws as a reward."
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_mission_4_hyena",
    "_item": "마스터데이터 - 모디파이어 설명(후속 타격 의뢰)",
    "_comment": "",
    "ko-KR": "적들이 절반의 체력만 가진 채 임무 시작. 보상 선택지가 1개로 감소.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_mission_5_elite",
    "_item": "마스터데이터 - 모디파이어 설명(정예 의뢰)",
    "_comment": "",
    "ko-KR": "적들의 훈련 1 증가. 선택보상으로 계약하는 용병이 퍽 포인트 1을 지니고 있음.",
    "en-US": "Increased enemy training 1. The mercenary who contracts as optional compensation has a puck point of 1."
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_mission_6_supply",
    "_item": "마스터데이터 - 모디파이어 설명(보급 어려움)",
    "_comment": "",
    "ko-KR": "이 미션에 투입할 수 있는 용병 수를 3명 이하로 제한.",
    "en-US": "Limit the number of mercenaries that can be used in this mission to no more than three."
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_mission_7_resent",
    "_item": "마스터데이터 - 모디파이어 설명(원한 쌓임)",
    "_comment": "",
    "ko-KR": "이 미션에서 적의 수 2 증가. 이 미션 성공시 이번 명성 단계 필요치의 10% 명성 획득.",
    "en-US": "2 enemies increased in this mission. If this mission is successful, 10% of the required value of this fame stage will be obtained."
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_mission_8_weaken",
    "_item": "마스터데이터 - 모디파이어 설명(분산된 적)",
    "_comment": "",
    "ko-KR": "이 미션에서 적의 수 2 감소.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_mission_9_militia",
    "_item": "마스터데이터 - 모디파이어 설명(현지 증원)",
    "_comment": "",
    "ko-KR": "동맹 전투원 2명이 지원 옴.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_agent_5_disable_prompt",
    "_item": "마스터데이터 - 모디파이어 설명(현장 판단 우선)",
    "_comment": "",
    "ko-KR": "전투력 2 증가. 이 용병이 참여한 임무에서 수류탄 투척을 비롯한 행동지시를 내릴 수 없음",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_agent_6_morale_high",
    "_item": "마스터데이터 - 모디파이어 설명(사기충천)",
    "_comment": "",
    "ko-KR": "전투력 2 증가.",
    "en-US": "2 increased combat power."
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_agent_7_morale_low",
    "_item": "마스터데이터 - 모디파이어 설명(낮은 모럴)",
    "_comment": "",
    "ko-KR": "전투력 2 감소.",
    "en-US": "2 reduced combat power."
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_agent_8_growth_power",
    "_item": "마스터데이터 - 모디파이어 설명(성장기)",
    "_comment": "",
    "ko-KR": "훈련으로 상승하는 전투력이 2배. 임무에서 퍽 포인트를 획득하지 않음.",
    "en-US": "2x combat power rising by training. No puck points from mission."
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_agent_9_growth_perk",
    "_item": "마스터데이터 - 모디파이어 설명(사춘기)",
    "_comment": "",
    "ko-KR": "임무에서 퍽 포인트를 획득할 확률이 2배. 훈련으로 상승하는 전투력 절반.",
    "en-US": "2x chance of earning a puck point on the mission. Half the combat power rising with training."
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_agent_10_drug",
    "_item": "마스터데이터 - 모디파이어 설명(약물 투여)",
    "_comment": "",
    "ko-KR": "모디파이어 만료 시 로스터에서 사라짐. 임무 종료 시 체력을 모두 회복.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_agent_13_agenda_strong",
    "_item": "마스터데이터 - 모디파이어 설명(협상 강점)",
    "_comment": "",
    "ko-KR": "재계약 시 요구하는 기본급과 생명수당이 기준보다 40% 증가",
    "en-US": "Basic salaries and life allowances required for renewing contracts are 40% higher than standard"
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_agent_14_agenda_weak",
    "_item": "마스터데이터 - 모디파이어 설명(협상 약점)",
    "_comment": "",
    "ko-KR": "재계약 시 요구하는 기본급과 생명수당이 기준보다 17% 감소",
    "en-US": "17% less basic salary and life benefits required for renewal than standard"
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_agent_15_agenda_expensive",
    "_item": "마스터데이터 - 모디파이어 설명(몸값 높이기 욕구)",
    "_comment": "",
    "ko-KR": "재계약 시 [임무 심사 편의] 옵션 강제.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_agent_16_agenda_frequent",
    "_item": "마스터데이터 - 모디파이어 설명(빠른 재협상 욕구)",
    "_comment": "",
    "ko-KR": "재계약 시 [장기 계약] 옵션 선택 불가능.",
    "en-US": "Unable to select the [Long Term Contract] option when renewing the contract."
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_agent_17_agenda_safety",
    "_item": "마스터데이터 - 모디파이어 설명(안전 욕구)",
    "_comment": "",
    "ko-KR": "재계약 시 [위험 수당] 옵션 강제.",
    "en-US": "Forcing the [Hazard Allowance] option upon renewal."
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_agent_18_agenda_wellbeing",
    "_item": "마스터데이터 - 모디파이어 설명(웰빙 욕구)",
    "_comment": "",
    "ko-KR": "재계약 시 [고급 케어] 옵션 강제.",
    "en-US": "Force the [Advanced Care] option upon renewal."
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_agent_19_agenda_complicated",
    "_item": "마스터데이터 - 모디파이어 설명(복잡한 사정)",
    "_comment": "",
    "ko-KR": "재계약 시 [장기 계약] 옵션의 추가 비용이 80%에서 20%로 감소함.",
    "en-US": "The additional cost of the [long-term contract] option is reduced from 80% to 20% upon renewal."
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_contract_1_frequency_high",
    "_item": "마스터데이터 - 모디파이어 설명(많은 충돌)",
    "_comment": "",
    "ko-KR": "이 지역의 의뢰 발생 빈도가 20% 증가합니다.",
    "en-US": "The frequency of referrals in this area increases by 20%."
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_contract_2_frequency_low",
    "_item": "마스터데이터 - 모디파이어 설명(적은 충돌)",
    "_comment": "",
    "ko-KR": "이 지역의 의뢰 발생 빈도가 20% 감소합니다.",
    "en-US": "The frequency of referrals in this area is reduced by 20%."
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_contract_3_difficulty_high",
    "_item": "마스터데이터 - 모디파이어 설명(높은 무장 수준)",
    "_comment": "",
    "ko-KR": "이 지역의 의뢰는 적이 20% 많이 등장합니다.",
    "en-US": "Commissioning in this area features 20% of the enemy."
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_contract_4_difficulty_low",
    "_item": "마스터데이터 - 모디파이어 설명(낮은 무장 수준)",
    "_comment": "",
    "ko-KR": "이 지역의 의뢰는 적이 20% 적게 등장합니다.",
    "en-US": "Commissioning in this area features 20% fewer enemies."
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_contract_5_reward_high",
    "_item": "마스터데이터 - 모디파이어 설명(높은 보상)",
    "_comment": "",
    "ko-KR": "이 지역의 의뢰는 보상이 20% 높게 책정됩니다.",
    "en-US": "Commissioners in this area are set 20% higher in compensation."
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_contract_6_reward_low",
    "_item": "마스터데이터 - 모디파이어 설명(낮은 보상)",
    "_comment": "",
    "ko-KR": "이 지역의 의뢰는 보상이 20% 낮게 책정됩니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_recruit_1_apply_high",
    "_item": "마스터데이터 - 모디파이어 설명(많은 지원서)",
    "_comment": "",
    "ko-KR": "이 지역의 지원자 발견 빈도가 20% 증가합니다.",
    "en-US": "Increase the frequency of volunteer discoveries in this area by 20%."
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_recruit_2_apply_low",
    "_item": "마스터데이터 - 모디파이어 설명(적은 지원서)",
    "_comment": "",
    "ko-KR": "이 지역의 지원자 발견 빈도가 20% 감소합니다.",
    "en-US": "Reduce the frequency of volunteer discoveries in this area by 20%."
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_recruit_3_wage_high",
    "_item": "마스터데이터 - 모디파이어 설명(높은 인건비)",
    "_comment": "",
    "ko-KR": "이 지역의 지원자는 계약금이 20% 높습니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_recruit_4_wage_low",
    "_item": "마스터데이터 - 모디파이어 설명(낮은 인건비)",
    "_comment": "",
    "ko-KR": "이 지역의 지원자는 계약금이 20% 낮습니다.",
    "en-US": "Applicants in this area have a 20% lower down payment."
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_recruit_5_quality_high",
    "_item": "마스터데이터 - 모디파이어 설명(높은 인력 수준)",
    "_comment": "",
    "ko-KR": "이 지역의 지원자는 전반적 전투력이 1 높습니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_modifiers_desc_mod_recruit_6_quality_low",
    "_item": "마스터데이터 - 모디파이어 설명(낮은 인력 수준)",
    "_comment": "",
    "ko-KR": "이 지역의 지원자는 전반적 전투력이 1 낮습니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_1_col",
    "_item": "마스터데이터 - 국적 국가(콜롬비아)",
    "_comment": "",
    "ko-KR": "콜롬비아",
    "en-US": "Colombia"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_2_mex",
    "_item": "마스터데이터 - 국적 국가(멕시코)",
    "_comment": "",
    "ko-KR": "멕시코",
    "en-US": "Mexico"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_3_usa",
    "_item": "마스터데이터 - 국적 국가(미국)",
    "_comment": "",
    "ko-KR": "미국",
    "en-US": "United States of America"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_4_fr",
    "_item": "마스터데이터 - 국적 국가(프랑스)",
    "_comment": "",
    "ko-KR": "프랑스",
    "en-US": "France"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_5_viet",
    "_item": "마스터데이터 - 국적 국가(베트남)",
    "_comment": "",
    "ko-KR": "베트남",
    "en-US": "Vietnam"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_6_spa",
    "_item": "마스터데이터 - 국적 국가(스페인)",
    "_comment": "",
    "ko-KR": "스페인",
    "en-US": "Spain"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_7_uk",
    "_item": "마스터데이터 - 국적 국가(영국)",
    "_comment": "",
    "ko-KR": "영국",
    "en-US": "United Kingdom"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_8_ira",
    "_item": "마스터데이터 - 국적 국가(이란)",
    "_comment": "",
    "ko-KR": "이란",
    "en-US": "Iran"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_9_cub",
    "_item": "마스터데이터 - 국적 국가(쿠바)",
    "_comment": "",
    "ko-KR": "쿠바",
    "en-US": "Cuba"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_10_ven",
    "_item": "마스터데이터 - 국적 국가(베네수엘라)",
    "_comment": "",
    "ko-KR": "베네수엘라",
    "en-US": "Venezuela"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_11_bol",
    "_item": "마스터데이터 - 국적 국가(볼리비아)",
    "_comment": "",
    "ko-KR": "볼리비아",
    "en-US": "Bolivia"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_12_ecu",
    "_item": "마스터데이터 - 국적 국가(에콰도르)",
    "_comment": "",
    "ko-KR": "에콰도르",
    "en-US": "Ecuador"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_13_hon",
    "_item": "마스터데이터 - 국적 국가(온두라스)",
    "_comment": "",
    "ko-KR": "온두라스",
    "en-US": "Honduras"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_14_uru",
    "_item": "마스터데이터 - 국적 국가(우루과이)",
    "_comment": "",
    "ko-KR": "우루과이",
    "en-US": "Uruguay"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_15_isr",
    "_item": "마스터데이터 - 국적 국가(이스라엘)",
    "_comment": "",
    "ko-KR": "이스라엘",
    "en-US": "Israel"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_16_pan",
    "_item": "마스터데이터 - 국적 국가(파나마)",
    "_comment": "",
    "ko-KR": "파나마",
    "en-US": "Panama"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_17_par",
    "_item": "마스터데이터 - 국적 국가(파라과이)",
    "_comment": "",
    "ko-KR": "파라과이",
    "en-US": "Paraguay"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_18_peru",
    "_item": "마스터데이터 - 국적 국가(페루)",
    "_comment": "",
    "ko-KR": "페루",
    "en-US": "Peru"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_19_gua",
    "_item": "마스터데이터 - 국적 국가(과테말라)",
    "_comment": "",
    "ko-KR": "과테말라",
    "en-US": "Guatemala"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_20_nep",
    "_item": "마스터데이터 - 국적 국가(네팔)",
    "_comment": "",
    "ko-KR": "네팔",
    "en-US": "Nepal"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_21_nic",
    "_item": "마스터데이터 - 국적 국가(니카라과)",
    "_comment": "",
    "ko-KR": "니카라과",
    "en-US": "Nicaragua"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_22_rok",
    "_item": "마스터데이터 - 국적 국가(대한민국)",
    "_comment": "",
    "ko-KR": "대한민국",
    "en-US": "Republic of Korea"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_23_ger",
    "_item": "마스터데이터 - 국적 국가(독일)",
    "_comment": "",
    "ko-KR": "독일",
    "en-US": "Germany"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_24_rus",
    "_item": "마스터데이터 - 국적 국가(러시아)",
    "_comment": "",
    "ko-KR": "러시아",
    "en-US": "Russia"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_25_rwa",
    "_item": "마스터데이터 - 국적 국가(르완다)",
    "_comment": "",
    "ko-KR": "르완다",
    "en-US": "Rwanda"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_26_lib",
    "_item": "마스터데이터 - 국적 국가(리비아)",
    "_comment": "",
    "ko-KR": "리비아",
    "en-US": "Libya"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_27_bra",
    "_item": "마스터데이터 - 국적 국가(브라질)",
    "_comment": "",
    "ko-KR": "브라질",
    "en-US": "Brazil"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_28_sud",
    "_item": "마스터데이터 - 국적 국가(수단)",
    "_comment": "",
    "ko-KR": "수단",
    "en-US": "Sudan"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_29_sur",
    "_item": "마스터데이터 - 국적 국가(수리남)",
    "_comment": "",
    "ko-KR": "수리남",
    "en-US": "Suriname"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_30_swe",
    "_item": "마스터데이터 - 국적 국가(스웨덴)",
    "_comment": "",
    "ko-KR": "스웨덴",
    "en-US": "Sweden"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_31_swi",
    "_item": "마스터데이터 - 국적 국가(스위스)",
    "_comment": "",
    "ko-KR": "스위스",
    "en-US": "Switzerland"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_32_arg",
    "_item": "마스터데이터 - 국적 국가(아르헨티나)",
    "_comment": "",
    "ko-KR": "아르헨티나",
    "en-US": "Argentina"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_33_hai",
    "_item": "마스터데이터 - 국적 국가(아이티)",
    "_comment": "",
    "ko-KR": "아이티",
    "en-US": "Haiti"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_34_eth",
    "_item": "마스터데이터 - 국적 국가(에티오피아)",
    "_comment": "",
    "ko-KR": "에티오피아",
    "en-US": "Ethiopia"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_35_yug",
    "_item": "마스터데이터 - 국적 국가(유고슬라비아)",
    "_comment": "",
    "ko-KR": "유고슬라비아",
    "en-US": "Yugoslavia"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_36_egy",
    "_item": "마스터데이터 - 국적 국가(이집트)",
    "_comment": "",
    "ko-KR": "이집트",
    "en-US": "Egypt"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_37_ind",
    "_item": "마스터데이터 - 국적 국가(인도)",
    "_comment": "",
    "ko-KR": "인도",
    "en-US": "India"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_38_jp",
    "_item": "마스터데이터 - 국적 국가(일본)",
    "_comment": "",
    "ko-KR": "일본",
    "en-US": "Japan"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_39_chi",
    "_item": "마스터데이터 - 국적 국가(중국)",
    "_comment": "",
    "ko-KR": "중국",
    "en-US": "China"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_40_chil",
    "_item": "마스터데이터 - 국적 국가(칠레)",
    "_comment": "",
    "ko-KR": "칠레",
    "en-US": "Chile"
  },
  {
    "key": "loc_data_string_nationalities_nation_nation_41_can",
    "_item": "마스터데이터 - 국적 국가(캐나다)",
    "_comment": "",
    "ko-KR": "캐나다",
    "en-US": "Canada"
  },
  {
    "key": "loc_data_string_nationalities_language_spanish",
    "_item": "마스터데이터 - 국적 언어(스페인어)",
    "_comment": "",
    "ko-KR": "스페인어",
    "en-US": "Spanish"
  },
  {
    "key": "loc_data_string_nationalities_language_english",
    "_item": "마스터데이터 - 국적 언어(영어)",
    "_comment": "",
    "ko-KR": "영어",
    "en-US": "English"
  },
  {
    "key": "loc_data_string_nationalities_language_french",
    "_item": "마스터데이터 - 국적 언어(프랑스어)",
    "_comment": "",
    "ko-KR": "프랑스어",
    "en-US": "French"
  },
  {
    "key": "loc_data_string_nationalities_language_vietnamese",
    "_item": "마스터데이터 - 국적 언어(베트남어)",
    "_comment": "",
    "ko-KR": "베트남어",
    "en-US": "Vietnamese"
  },
  {
    "key": "loc_data_string_nationalities_language_persian",
    "_item": "마스터데이터 - 국적 언어(페르시아어)",
    "_comment": "",
    "ko-KR": "페르시아어",
    "en-US": "Persian"
  },
  {
    "key": "loc_data_string_nationalities_language_hebrew",
    "_item": "마스터데이터 - 국적 언어(히브리어)",
    "_comment": "",
    "ko-KR": "히브리어",
    "en-US": "Hebrew"
  },
  {
    "key": "loc_data_string_nationalities_language_nepali",
    "_item": "마스터데이터 - 국적 언어(네팔어)",
    "_comment": "",
    "ko-KR": "네팔어",
    "en-US": "Nepali"
  },
  {
    "key": "loc_data_string_nationalities_language_korean",
    "_item": "마스터데이터 - 국적 언어(한국어)",
    "_comment": "",
    "ko-KR": "한국어",
    "en-US": "Korean"
  },
  {
    "key": "loc_data_string_nationalities_language_german",
    "_item": "마스터데이터 - 국적 언어(독일어)",
    "_comment": "",
    "ko-KR": "독일어",
    "en-US": "German"
  },
  {
    "key": "loc_data_string_nationalities_language_russian",
    "_item": "마스터데이터 - 국적 언어(러시아어)",
    "_comment": "",
    "ko-KR": "러시아어",
    "en-US": "Russian"
  },
  {
    "key": "loc_data_string_nationalities_language_kinyarwanda",
    "_item": "마스터데이터 - 국적 언어(르완다어)",
    "_comment": "",
    "ko-KR": "르완다어",
    "en-US": "Kinyarwanda"
  },
  {
    "key": "loc_data_string_nationalities_language_arabic",
    "_item": "마스터데이터 - 국적 언어(아랍어)",
    "_comment": "",
    "ko-KR": "아랍어",
    "en-US": "Arabic"
  },
  {
    "key": "loc_data_string_nationalities_language_portuguese",
    "_item": "마스터데이터 - 국적 언어(포르투갈어)",
    "_comment": "",
    "ko-KR": "포르투갈어",
    "en-US": "Portuguese"
  },
  {
    "key": "loc_data_string_nationalities_language_dutch",
    "_item": "마스터데이터 - 국적 언어(네덜란드어)",
    "_comment": "",
    "ko-KR": "네덜란드어",
    "en-US": "Dutch"
  },
  {
    "key": "loc_data_string_nationalities_language_swedish",
    "_item": "마스터데이터 - 국적 언어(스웨덴어)",
    "_comment": "",
    "ko-KR": "스웨덴어",
    "en-US": "Swedish"
  },
  {
    "key": "loc_data_string_nationalities_language_amharic",
    "_item": "마스터데이터 - 국적 언어(암하라어)",
    "_comment": "",
    "ko-KR": "암하라어",
    "en-US": "Amharic"
  },
  {
    "key": "loc_data_string_nationalities_language_servokroatische",
    "_item": "마스터데이터 - 국적 언어(세르보크로아트어)",
    "_comment": "",
    "ko-KR": "세르보크로아트어",
    "en-US": "Servo-Croatian"
  },
  {
    "key": "loc_data_string_nationalities_language_slovenian",
    "_item": "마스터데이터 - 국적 언어(슬로베니아어)",
    "_comment": "",
    "ko-KR": "슬로베니아어",
    "en-US": "Slovenian"
  },
  {
    "key": "loc_data_string_nationalities_language_macedonian",
    "_item": "마스터데이터 - 국적 언어(마케도니아어)",
    "_comment": "",
    "ko-KR": "마케도니아어",
    "en-US": "Macedonian"
  },
  {
    "key": "loc_data_string_nationalities_language_hindi",
    "_item": "마스터데이터 - 국적 언어(힌디어)",
    "_comment": "",
    "ko-KR": "힌디어",
    "en-US": "Hindi"
  },
  {
    "key": "loc_data_string_nationalities_language_japanese",
    "_item": "마스터데이터 - 국적 언어(일본어)",
    "_comment": "",
    "ko-KR": "일본어",
    "en-US": "Japanese"
  },
  {
    "key": "loc_data_string_nationalities_language_chinese",
    "_item": "마스터데이터 - 국적 언어(중국어)",
    "_comment": "",
    "ko-KR": "중국어",
    "en-US": "Chinese"
  },
  {
    "key": "loc_data_string_perk_name_perk_grenadier_base",
    "_item": "마스터데이터 - 퍽 이름(기초 척탄 훈련)",
    "_comment": "",
    "ko-KR": "기초 척탄 훈련",
    "en-US": "Basic Grenadier Training"
  },
  {
    "key": "loc_data_string_perk_name_perk_grenadier_throw_range",
    "_item": "마스터데이터 - 퍽 이름(투척거리 강화)",
    "_comment": "",
    "ko-KR": "투척거리 강화",
    "en-US": "Increased Throw Distance"
  },
  {
    "key": "loc_data_string_perk_name_perk_grenadier_amount",
    "_item": "마스터데이터 - 퍽 이름(투척물 개수 관리)",
    "_comment": "",
    "ko-KR": "투척물 개수 관리",
    "en-US": "Improved Grenade Management"
  },
  {
    "key": "loc_data_string_perk_name_perk_grenadier_shorter_blast",
    "_item": "마스터데이터 - 퍽 이름(쿠킹)",
    "_comment": "",
    "ko-KR": "쿠킹",
    "en-US": "Cooking"
  },
  {
    "key": "loc_data_string_perk_name_perk_grenadier_special_1",
    "_item": "마스터데이터 - 퍽 이름(섬광탄)",
    "_comment": "",
    "ko-KR": "섬광탄",
    "en-US": "Flashbang"
  },
  {
    "key": "loc_data_string_perk_name_perk_grenadier_effect_amount",
    "_item": "마스터데이터 - 퍽 이름(투척물 효과 극대화)",
    "_comment": "",
    "ko-KR": "투척물 효과 극대화",
    "en-US": "Improved Grenade Effect"
  },
  {
    "key": "loc_data_string_perk_name_perk_grenadier_special_2",
    "_item": "마스터데이터 - 퍽 이름(연막탄)",
    "_comment": "",
    "ko-KR": "연막탄",
    "en-US": "Smoke Grenade"
  },
  {
    "key": "loc_data_string_perk_name_perk_desmar_base",
    "_item": "마스터데이터 - 퍽 이름(사수 지정)",
    "_comment": "",
    "ko-KR": "사수 지정",
    "en-US": "Marksman Designation"
  },
  {
    "key": "loc_data_string_perk_name_perk_desmar_priority_defensive",
    "_item": "마스터데이터 - 퍽 이름(방어적 우선순위)",
    "_comment": "",
    "ko-KR": "방어적 우선순위",
    "en-US": "Defensive Priority"
  },
  {
    "key": "loc_data_string_perk_name_perk_desmar_distraction",
    "_item": "마스터데이터 - 퍽 이름(견제 사격)",
    "_comment": "",
    "ko-KR": "견제 사격",
    "en-US": "Distraction"
  },
  {
    "key": "loc_data_string_perk_name_perk_desmar_distraction_range",
    "_item": "마스터데이터 - 퍽 이름(광역 견제)",
    "_comment": "",
    "ko-KR": "광역 견제",
    "en-US": "Area Distraction"
  },
  {
    "key": "loc_data_string_perk_name_perk_desmar_priority_offensive",
    "_item": "마스터데이터 - 퍽 이름(공격적 우선순위)",
    "_comment": "",
    "ko-KR": "공격적 우선순위",
    "en-US": "Offensive Priority"
  },
  {
    "key": "loc_data_string_perk_name_perk_desmar_damage",
    "_item": "마스터데이터 - 퍽 이름(치명적 사격)",
    "_comment": "",
    "ko-KR": "치명적 사격",
    "en-US": "Critical Trigger"
  },
  {
    "key": "loc_data_string_perk_name_perk_desmar_aimspeed",
    "_item": "마스터데이터 - 퍽 이름(신선한 사냥감)",
    "_comment": "",
    "ko-KR": "신선한 사냥감",
    "en-US": "Ripe Prey"
  },
  {
    "key": "loc_data_string_perk_name_perk_commed_base",
    "_item": "마스터데이터 - 퍽 이름(기초 의무 훈련)",
    "_comment": "",
    "ko-KR": "기초 의무 훈련",
    "en-US": "Basic Medic Training"
  },
  {
    "key": "loc_data_string_perk_name_perk_commed_workspeed",
    "_item": "마스터데이터 - 퍽 이름(의무 숙달 훈련)",
    "_comment": "",
    "ko-KR": "의무 숙달 훈련",
    "en-US": "Combat Medic Proficiency"
  },
  {
    "key": "loc_data_string_perk_name_perk_commed_count",
    "_item": "마스터데이터 - 퍽 이름(의무 역량 증대)",
    "_comment": "",
    "ko-KR": "의무 역량 증대",
    "en-US": "Deft Hands"
  },
  {
    "key": "loc_data_string_perk_name_perk_commed_amount",
    "_item": "마스터데이터 - 퍽 이름(복합 치료)",
    "_comment": "",
    "ko-KR": "복합 치료",
    "en-US": "Complex Care"
  },
  {
    "key": "loc_data_string_perk_name_perk_commed_target_self",
    "_item": "마스터데이터 - 퍽 이름(자가 치료)",
    "_comment": "",
    "ko-KR": "자가 치료",
    "en-US": "Self-medication"
  },
  {
    "key": "loc_data_string_perk_name_perk_commed_risk",
    "_item": "마스터데이터 - 퍽 이름(과감한 조치)",
    "_comment": "",
    "ko-KR": "과감한 조치",
    "en-US": "Daring Treatment"
  },
  {
    "key": "loc_data_string_perk_name_perk_commed_buff",
    "_item": "마스터데이터 - 퍽 이름(미심쩍은 약물)",
    "_comment": "",
    "ko-KR": "미심쩍은 약물",
    "en-US": "Suspicious Pills"
  },
  {
    "key": "loc_data_string_perk_name_perk_hit_antiarmor",
    "_item": "마스터데이터 - 퍽 이름(방탄복 파괴)",
    "_comment": "",
    "ko-KR": "방탄복 파괴",
    "en-US": "Antibulletproof"
  },
  {
    "key": "loc_data_string_perk_name_perk_movespeed",
    "_item": "마스터데이터 - 퍽 이름(이동 속도 증가)",
    "_comment": "",
    "ko-KR": "이동 속도 증가",
    "en-US": "Fast Movement"
  },
  {
    "key": "loc_data_string_perk_name_perk_aimvar_incr_m10",
    "_item": "마스터데이터 - 퍽 이름(정신력)",
    "_comment": "",
    "ko-KR": "정신력",
    "en-US": "Spirit"
  },
  {
    "key": "loc_data_string_perk_name_perk_outgame_training_effect",
    "_item": "마스터데이터 - 퍽 이름(의외의 재능)",
    "_comment": "",
    "ko-KR": "의외의 재능",
    "en-US": "Unexpected Talent"
  },
  {
    "key": "loc_data_string_perk_name_perk_hit_incr_aimvar",
    "_item": "마스터데이터 - 퍽 이름(위협 전문가)",
    "_comment": "",
    "ko-KR": "위협 전문가",
    "en-US": "Indimidation Specialist"
  },
  {
    "key": "loc_data_string_perk_name_perk_outgame_monthly_recover",
    "_item": "마스터데이터 - 퍽 이름(동기 부여)",
    "_comment": "",
    "ko-KR": "동기 부여",
    "en-US": "Motivation"
  },
  {
    "key": "loc_data_string_perk_name_perk_reduce_cover_effect",
    "_item": "마스터데이터 - 퍽 이름(노출 부위 탐색)",
    "_comment": "",
    "ko-KR": "노출 부위 탐색",
    "en-US": "Search Exposure"
  },
  {
    "key": "loc_data_string_perk_name_perk_cover_reload_evade",
    "_item": "마스터데이터 - 퍽 이름(은폐 재장전)",
    "_comment": "",
    "ko-KR": "은폐 재장전",
    "en-US": "Hiding Reload"
  },
  {
    "key": "loc_data_string_perk_name_perk_cover_effect",
    "_item": "마스터데이터 - 퍽 이름(밀착 엄폐)",
    "_comment": "",
    "ko-KR": "밀착 엄폐",
    "en-US": "Exhaustive Cover"
  },
  {
    "key": "loc_data_string_perk_name_perk_armor_effect",
    "_item": "마스터데이터 - 퍽 이름(효과적인 방탄복)",
    "_comment": "",
    "ko-KR": "효과적인 방탄복",
    "en-US": "Effective Bulletproof Gear"
  },
  {
    "key": "loc_data_string_perk_name_perk_cover_reload",
    "_item": "마스터데이터 - 퍽 이름(안정적 화력)",
    "_comment": "",
    "ko-KR": "안정적 화력",
    "en-US": "Stable Firepower"
  },
  {
    "key": "loc_data_string_perk_name_perk_outgame_recover_amount",
    "_item": "마스터데이터 - 퍽 이름(회복 전념)",
    "_comment": "",
    "ko-KR": "회복 전념",
    "en-US": "Better Recovery"
  },
  {
    "key": "loc_data_string_perk_name_perk_cover_steady",
    "_item": "마스터데이터 - 퍽 이름(반동 제어)",
    "_comment": "",
    "ko-KR": "반동 제어",
    "en-US": "Steadier Recoil"
  },
  {
    "key": "loc_data_string_perk_name_perk_outgame_mission_days",
    "_item": "마스터데이터 - 퍽 이름(현지 조력자)",
    "_comment": "",
    "ko-KR": "현지 조력자",
    "en-US": "Local Cooperator"
  },
  {
    "key": "loc_data_string_perk_name_perk_aim_execute",
    "_item": "마스터데이터 - 퍽 이름(마무리)",
    "_comment": "",
    "ko-KR": "마무리",
    "en-US": "Culling"
  },
  {
    "key": "loc_data_string_perk_name_perk_piercing_bullet",
    "_item": "마스터데이터 - 퍽 이름(관통 탄자 운용)",
    "_comment": "",
    "ko-KR": "관통 탄자 운용",
    "en-US": "Armor-piercing Bullet"
  },
  {
    "key": "loc_data_string_perk_name_perk_aimvar_max_10",
    "_item": "마스터데이터 - 퍽 이름(몰입)",
    "_comment": "",
    "ko-KR": "몰입",
    "en-US": "Flow"
  },
  {
    "key": "loc_data_string_perk_name_perk_aimvar_10",
    "_item": "마스터데이터 - 퍽 이름(마음의 준비)",
    "_comment": "",
    "ko-KR": "마음의 준비",
    "en-US": "Ready Mind"
  },
  {
    "key": "loc_data_string_perk_name_perk_aim_together",
    "_item": "마스터데이터 - 퍽 이름(견제 활용)",
    "_comment": "",
    "ko-KR": "견제 활용",
    "en-US": "Utilize Ally"
  },
  {
    "key": "loc_data_string_perk_name_perk_cover_dash",
    "_item": "마스터데이터 - 퍽 이름(신속 엄폐)",
    "_comment": "",
    "ko-KR": "신속 엄폐",
    "en-US": "Fast Cover"
  },
  {
    "key": "loc_data_string_perk_name_perk_suppress_m10",
    "_item": "마스터데이터 - 퍽 이름(영웅심)",
    "_comment": "",
    "ko-KR": "영웅심",
    "en-US": "Heroism"
  },
  {
    "key": "loc_data_string_perk_name_perk_engage_dash",
    "_item": "마스터데이터 - 퍽 이름(돌진본능)",
    "_comment": "",
    "ko-KR": "돌진본능",
    "en-US": "Dash Instinct"
  },
  {
    "key": "loc_data_string_perk_name_perk_pierce_moving_enemy",
    "_item": "마스터데이터 - 퍽 이름(움직임 포착)",
    "_comment": "",
    "ko-KR": "움직임 포착",
    "en-US": "Enemy In Sight"
  },
  {
    "key": "loc_data_string_perk_name_perk_stationary_aimspeed",
    "_item": "마스터데이터 - 퍽 이름(자연체)",
    "_comment": "",
    "ko-KR": "자연체",
    "en-US": "Neutral Posture"
  },
  {
    "key": "loc_data_string_perk_name_perk_lastshoot",
    "_item": "마스터데이터 - 퍽 이름(마지막 한 발)",
    "_comment": "",
    "ko-KR": "마지막 한 발",
    "en-US": "Last Shooting"
  },
  {
    "key": "loc_data_string_perk_name_perk_move_cover",
    "_item": "마스터데이터 - 퍽 이름(현란한 이동)",
    "_comment": "",
    "ko-KR": "현란한 이동",
    "en-US": "Flashy Movement"
  },
  {
    "key": "loc_data_string_perk_name_perk_glancing_blow",
    "_item": "마스터데이터 - 퍽 이름(비껴맞기)",
    "_comment": "",
    "ko-KR": "비껴맞기",
    "en-US": "Glancing Blow"
  },
  {
    "key": "loc_data_string_perk_name_perk_standing_evade",
    "_item": "마스터데이터 - 퍽 이름(총알 탄 사나이)",
    "_comment": "",
    "ko-KR": "총알 탄 사나이",
    "en-US": "The Naked Bullet"
  },
  {
    "key": "loc_data_string_perk_name_perk_healed_amount_15",
    "_item": "마스터데이터 - 퍽 이름(타고난 몸)",
    "_comment": "",
    "ko-KR": "타고난 몸",
    "en-US": "Inborn Health"
  },
  {
    "key": "loc_data_string_perk_name_perk_recover_life",
    "_item": "마스터데이터 - 퍽 이름(숨 고르기)",
    "_comment": "",
    "ko-KR": "숨 고르기",
    "en-US": "Deep Breath"
  },
  {
    "key": "loc_data_string_perk_name_perk_armor_first",
    "_item": "마스터데이터 - 퍽 이름(거북이)",
    "_comment": "",
    "ko-KR": "거북이",
    "en-US": "Turtle Shell"
  },
  {
    "key": "loc_data_string_perk_name_perk_critical_standing",
    "_item": "마스터데이터 - 퍽 이름(쉬운 목표)",
    "_comment": "",
    "ko-KR": "쉬운 목표",
    "en-US": "Easy Prey"
  },
  {
    "key": "loc_data_string_perk_name_perk_critical_move_crawl",
    "_item": "마스터데이터 - 퍽 이름(움직이는 목표)",
    "_comment": "",
    "ko-KR": "움직이는 목표",
    "en-US": "Moving Prey"
  },
  {
    "key": "loc_data_string_perk_name_perk_critical_add",
    "_item": "마스터데이터 - 퍽 이름(약점 파악)",
    "_comment": "",
    "ko-KR": "약점 파악",
    "en-US": "Find Weakness"
  },
  {
    "key": "loc_data_string_perk_name_perk_sameperk_stats",
    "_item": "마스터데이터 - 퍽 이름(전우애)",
    "_comment": "",
    "ko-KR": "전우애",
    "en-US": "Comradeship"
  },
  {
    "key": "loc_data_string_perk_name_perk_reduce_stat_penalty",
    "_item": "마스터데이터 - 퍽 이름(고통 나눔)",
    "_comment": "",
    "ko-KR": "고통 나눔",
    "en-US": "Share Pain"
  },
  {
    "key": "loc_data_string_perk_name_perk_outgame_renown_amount",
    "_item": "마스터데이터 - 퍽 이름(소문의 주인공)",
    "_comment": "",
    "ko-KR": "소문의 주인공",
    "en-US": "That Guy"
  },
  {
    "key": "loc_data_string_perk_name_perk_outgame_alone_reward",
    "_item": "마스터데이터 - 퍽 이름(생존 전략)",
    "_comment": "",
    "ko-KR": "생존 전략",
    "en-US": "How To Survive"
  },
  {
    "key": "loc_data_string_perk_name_perk_outgame_reduce_negative_renown",
    "_item": "마스터데이터 - 퍽 이름(악역은 익숙하니까)",
    "_comment": "",
    "ko-KR": "악역은 익숙하니까",
    "en-US": "Bad Cop"
  },
  {
    "key": "loc_data_string_perk_name_perk_alone_heal",
    "_item": "마스터데이터 - 퍽 이름(외로운 생존자)",
    "_comment": "",
    "ko-KR": "외로운 생존자",
    "en-US": "Lone Survivor"
  },
  {
    "key": "loc_data_string_perk_msg_perk_grenadier_base",
    "_item": "마스터데이터 - 퍽 설명(기초 척탄 훈련)",
    "_comment": "",
    "ko-KR": "%% NOMSG %%",
    "en-US": "%% NOMSG %%"
  },
  {
    "key": "loc_data_string_perk_msg_perk_grenadier_throw_range",
    "_item": "마스터데이터 - 퍽 설명(투척거리 강화)",
    "_comment": "",
    "ko-KR": "수류탄이 더 멀리 날아갑니다.",
    "en-US": "The grenade is flying further."
  },
  {
    "key": "loc_data_string_perk_msg_perk_grenadier_amount",
    "_item": "마스터데이터 - 퍽 설명(투척물 개수 관리)",
    "_comment": "",
    "ko-KR": "%% NOMSG %%",
    "en-US": "%% NOMSG %%"
  },
  {
    "key": "loc_data_string_perk_msg_perk_grenadier_shorter_blast",
    "_item": "마스터데이터 - 퍽 설명(쿠킹)",
    "_comment": "",
    "ko-KR": "수류탄이 이른 타이밍에 터집니다.",
    "en-US": "The grenade goes off early."
  },
  {
    "key": "loc_data_string_perk_msg_perk_grenadier_special_1",
    "_item": "마스터데이터 - 퍽 설명(섬광탄)",
    "_comment": "",
    "ko-KR": "%% NOMSG %%",
    "en-US": "%% NOMSG %%"
  },
  {
    "key": "loc_data_string_perk_msg_perk_grenadier_effect_amount",
    "_item": "마스터데이터 - 퍽 설명(투척물 효과 극대화)",
    "_comment": "",
    "ko-KR": "수류탄의 효과가 증가합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_string_perk_msg_perk_grenadier_special_2",
    "_item": "마스터데이터 - 퍽 설명(연막탄)",
    "_comment": "",
    "ko-KR": "%% NOMSG %%",
    "en-US": "%% NOMSG %%"
  },
  {
    "key": "loc_data_string_perk_msg_perk_desmar_base",
    "_item": "마스터데이터 - 퍽 설명(사수 지정)",
    "_comment": "",
    "ko-KR": "지정사수가 거리를 두고 행동합니다.",
    "en-US": "The designated ambassador acts at a distance."
  },
  {
    "key": "loc_data_string_perk_msg_perk_desmar_priority_defensive",
    "_item": "마스터데이터 - 퍽 설명(방어적 우선순위)",
    "_comment": "",
    "ko-KR": "위협적인 적을 우선합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_string_perk_msg_perk_desmar_distraction",
    "_item": "마스터데이터 - 퍽 설명(견제 사격)",
    "_comment": "",
    "ko-KR": "견제 사격으로 이동을 저지합니다.",
    "en-US": "Block movement with check fire."
  },
  {
    "key": "loc_data_string_perk_msg_perk_desmar_distraction_range",
    "_item": "마스터데이터 - 퍽 설명(광역 견제)",
    "_comment": "",
    "ko-KR": "광범위 견제 사격으로 이동을 저지합니다.",
    "en-US": "Block movement with wide range check fire."
  },
  {
    "key": "loc_data_string_perk_msg_perk_desmar_priority_offensive",
    "_item": "마스터데이터 - 퍽 설명(공격적 우선순위)",
    "_comment": "",
    "ko-KR": "피해를 많이 주기를 우선합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_string_perk_msg_perk_desmar_damage",
    "_item": "마스터데이터 - 퍽 설명(치명적 사격)",
    "_comment": "",
    "ko-KR": "피해량이 증가합니다.",
    "en-US": "Increases damage done."
  },
  {
    "key": "loc_data_string_perk_msg_perk_desmar_aimspeed",
    "_item": "마스터데이터 - 퍽 설명(신선한 사냥감)",
    "_comment": "",
    "ko-KR": "피해를 입지 않은 적을 빠르게 조준합니다.",
    "en-US": "Quickly aim at an unscathed enemy."
  },
  {
    "key": "loc_data_string_perk_msg_perk_commed_base",
    "_item": "마스터데이터 - 퍽 설명(기초 의무 훈련)",
    "_comment": "",
    "ko-KR": "%% NOMSG %%",
    "en-US": "%% NOMSG %%"
  },
  {
    "key": "loc_data_string_perk_msg_perk_commed_workspeed",
    "_item": "마스터데이터 - 퍽 설명(의무 숙달 훈련)",
    "_comment": "",
    "ko-KR": "더 빠르게 치료합니다.",
    "en-US": "It treats faster."
  },
  {
    "key": "loc_data_string_perk_msg_perk_commed_count",
    "_item": "마스터데이터 - 퍽 설명(의무 역량 증대)",
    "_comment": "",
    "ko-KR": "%% NOMSG %%",
    "en-US": "%% NOMSG %%"
  },
  {
    "key": "loc_data_string_perk_msg_perk_commed_amount",
    "_item": "마스터데이터 - 퍽 설명(복합 치료)",
    "_comment": "",
    "ko-KR": "치료양이 증가합니다.",
    "en-US": "Increase the amount of treatment."
  },
  {
    "key": "loc_data_string_perk_msg_perk_commed_target_self",
    "_item": "마스터데이터 - 퍽 설명(자가 치료)",
    "_comment": "",
    "ko-KR": "%% NOMSG %%",
    "en-US": "%% NOMSG %%"
  },
  {
    "key": "loc_data_string_perk_msg_perk_commed_risk",
    "_item": "마스터데이터 - 퍽 설명(과감한 조치)",
    "_comment": "",
    "ko-KR": "과감하게 상처를 벌린 뒤 치료합니다.",
    "en-US": "Boldly open the wound and treat it."
  },
  {
    "key": "loc_data_string_perk_msg_perk_commed_buff",
    "_item": "마스터데이터 - 퍽 설명(미심쩍은 약물)",
    "_comment": "",
    "ko-KR": "일시적으로 능력을 상승시킵니다.",
    "en-US": "It temporarily boosts your ability."
  },
  {
    "key": "loc_data_string_perk_msg_perk_hit_antiarmor",
    "_item": "마스터데이터 - 퍽 설명(방탄복 파괴)",
    "_comment": "",
    "ko-KR": "방탄적에 부수적 피해를 입힙니다.",
    "en-US": "Takes collateral damage to bulletproof."
  },
  {
    "key": "loc_data_string_perk_msg_perk_movespeed",
    "_item": "마스터데이터 - 퍽 설명(이동 속도 증가)",
    "_comment": "",
    "ko-KR": "%% NOMSG %%",
    "en-US": "%% NOMSG %%"
  },
  {
    "key": "loc_data_string_perk_msg_perk_aimvar_incr_m10",
    "_item": "마스터데이터 - 퍽 설명(정신력)",
    "_comment": "",
    "ko-KR": "강한 정신력으로 조준을 다잡습니다.",
    "en-US": "Hold on to aim with a strong mentality."
  },
  {
    "key": "loc_data_string_perk_msg_perk_outgame_training_effect",
    "_item": "마스터데이터 - 퍽 설명(의외의 재능)",
    "_comment": "",
    "ko-KR": "%% OUTGAME %%",
    "en-US": "%% OUTGAME %%"
  },
  {
    "key": "loc_data_string_perk_msg_perk_hit_incr_aimvar",
    "_item": "마스터데이터 - 퍽 설명(위협 전문가)",
    "_comment": "",
    "ko-KR": "상대의 조준이 더 크게 흐트러집니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_string_perk_msg_perk_outgame_monthly_recover",
    "_item": "마스터데이터 - 퍽 설명(동기 부여)",
    "_comment": "",
    "ko-KR": "%% OUTGAME %%",
    "en-US": "%% OUTGAME %%"
  },
  {
    "key": "loc_data_string_perk_msg_perk_reduce_cover_effect",
    "_item": "마스터데이터 - 퍽 설명(노출 부위 탐색)",
    "_comment": "",
    "ko-KR": "엄폐중인 적의 노출된 부분을 찾습니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_string_perk_msg_perk_cover_reload_evade",
    "_item": "마스터데이터 - 퍽 설명(은폐 재장전)",
    "_comment": "",
    "ko-KR": "재장전 중 피격당하지 않습니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_string_perk_msg_perk_cover_effect",
    "_item": "마스터데이터 - 퍽 설명(밀착 엄폐)",
    "_comment": "",
    "ko-KR": "철저한 엄폐로 총알을 피합니다.",
    "en-US": "It avoids bullets with strict cover."
  },
  {
    "key": "loc_data_string_perk_msg_perk_armor_effect",
    "_item": "마스터데이터 - 퍽 설명(효과적인 방탄복)",
    "_comment": "",
    "ko-KR": "방탄복에 요령껏 맞습니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_string_perk_msg_perk_cover_reload",
    "_item": "마스터데이터 - 퍽 설명(안정적 화력)",
    "_comment": "",
    "ko-KR": "엄폐 중에 재장전합니다.",
    "en-US": "I reload under cover."
  },
  {
    "key": "loc_data_string_perk_msg_perk_outgame_recover_amount",
    "_item": "마스터데이터 - 퍽 설명(회복 전념)",
    "_comment": "",
    "ko-KR": "%% OUTGAME %%",
    "en-US": "%% OUTGAME %%"
  },
  {
    "key": "loc_data_string_perk_msg_perk_cover_steady",
    "_item": "마스터데이터 - 퍽 설명(반동 제어)",
    "_comment": "",
    "ko-KR": "엄폐한 채로 총기 반동을 제어합니다.",
    "en-US": "Control gun recoil with cover."
  },
  {
    "key": "loc_data_string_perk_msg_perk_outgame_mission_days",
    "_item": "마스터데이터 - 퍽 설명(현지 조력자)",
    "_comment": "",
    "ko-KR": "%% OUTGAME %%",
    "en-US": "%% OUTGAME %%"
  },
  {
    "key": "loc_data_string_perk_msg_perk_aim_execute",
    "_item": "마스터데이터 - 퍽 설명(마무리)",
    "_comment": "",
    "ko-KR": "피해입은 적을 빠르게 조준합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_string_perk_msg_perk_piercing_bullet",
    "_item": "마스터데이터 - 퍽 설명(관통 탄자 운용)",
    "_comment": "",
    "ko-KR": "장애물을 관통하여 사격합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_string_perk_msg_perk_aimvar_max_10",
    "_item": "마스터데이터 - 퍽 설명(몰입)",
    "_comment": "",
    "ko-KR": "더 정밀하게 조준합니다.",
    "en-US": "It aims more precisely."
  },
  {
    "key": "loc_data_string_perk_msg_perk_aimvar_10",
    "_item": "마스터데이터 - 퍽 설명(마음의 준비)",
    "_comment": "",
    "ko-KR": "준비된 상태로 조준합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_string_perk_msg_perk_aim_together",
    "_item": "마스터데이터 - 퍽 설명(견제 활용)",
    "_comment": "",
    "ko-KR": "견제를 활용해 빠르게 조준합니다.",
    "en-US": "Use checks to aim quickly."
  },
  {
    "key": "loc_data_string_perk_msg_perk_cover_dash",
    "_item": "마스터데이터 - 퍽 설명(신속 엄폐)",
    "_comment": "",
    "ko-KR": "빠르게 엄폐물까지 접근합니다.",
    "en-US": "It quickly approaches the cover."
  },
  {
    "key": "loc_data_string_perk_msg_perk_suppress_m10",
    "_item": "마스터데이터 - 퍽 설명(영웅심)",
    "_comment": "",
    "ko-KR": "강한 정신력으로 조준을 다잡습니다.",
    "en-US": "Hold on to aim with a strong mentality."
  },
  {
    "key": "loc_data_string_perk_msg_perk_engage_dash",
    "_item": "마스터데이터 - 퍽 설명(돌진본능)",
    "_comment": "",
    "ko-KR": "%% NOMSG %%",
    "en-US": "%% NOMSG %%"
  },
  {
    "key": "loc_data_string_perk_msg_perk_pierce_moving_enemy",
    "_item": "마스터데이터 - 퍽 설명(움직임 포착)",
    "_comment": "",
    "ko-KR": "이동중인 적을 정확히 포착합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_string_perk_msg_perk_stationary_aimspeed",
    "_item": "마스터데이터 - 퍽 설명(자연체)",
    "_comment": "",
    "ko-KR": "멈춰있는 상태에서 빠르게 조준합니다.",
    "en-US": "Aim quickly while at rest."
  },
  {
    "key": "loc_data_string_perk_msg_perk_lastshoot",
    "_item": "마스터데이터 - 퍽 설명(마지막 한 발)",
    "_comment": "",
    "ko-KR": "탄창의 마지막 탄환을 쏩니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_string_perk_msg_perk_move_cover",
    "_item": "마스터데이터 - 퍽 설명(현란한 이동)",
    "_comment": "",
    "ko-KR": "적을 기만하며 이동합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_string_perk_msg_perk_glancing_blow",
    "_item": "마스터데이터 - 퍽 설명(비껴맞기)",
    "_comment": "",
    "ko-KR": "자세를 틀어 급소를 피해 맞습니다.",
    "en-US": "Stretch your position and get hit from the vital point."
  },
  {
    "key": "loc_data_string_perk_msg_perk_standing_evade",
    "_item": "마스터데이터 - 퍽 설명(총알 탄 사나이)",
    "_comment": "",
    "ko-KR": "기묘한 자세로 사격을 피합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_string_perk_msg_perk_healed_amount_15",
    "_item": "마스터데이터 - 퍽 설명(타고난 몸)",
    "_comment": "",
    "ko-KR": "치료로부터 더욱 많이 회복합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_string_perk_msg_perk_recover_life",
    "_item": "마스터데이터 - 퍽 설명(숨 고르기)",
    "_comment": "",
    "ko-KR": "숨을 고르고 피해를 수습합니다",
    "en-US": "Take a breath and fix the damage"
  },
  {
    "key": "loc_data_string_perk_msg_perk_armor_first",
    "_item": "마스터데이터 - 퍽 설명(거북이)",
    "_comment": "",
    "ko-KR": "방탄복에 맞습니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_string_perk_msg_perk_critical_standing",
    "_item": "마스터데이터 - 퍽 설명(쉬운 목표)",
    "_comment": "",
    "ko-KR": "%% NOMSG %%",
    "en-US": "%% NOMSG %%"
  },
  {
    "key": "loc_data_string_perk_msg_perk_critical_move_crawl",
    "_item": "마스터데이터 - 퍽 설명(움직이는 목표)",
    "_comment": "",
    "ko-KR": "%% NOMSG %%",
    "en-US": "%% NOMSG %%"
  },
  {
    "key": "loc_data_string_perk_msg_perk_critical_add",
    "_item": "마스터데이터 - 퍽 설명(약점 파악)",
    "_comment": "",
    "ko-KR": "%% NOMSG %%",
    "en-US": "%% NOMSG %%"
  },
  {
    "key": "loc_data_string_perk_msg_perk_sameperk_stats",
    "_item": "마스터데이터 - 퍽 설명(전우애)",
    "_comment": "",
    "ko-KR": "%% NOMSG %%",
    "en-US": "%% NOMSG %%"
  },
  {
    "key": "loc_data_string_perk_msg_perk_reduce_stat_penalty",
    "_item": "마스터데이터 - 퍽 설명(고통 나눔)",
    "_comment": "",
    "ko-KR": "%% NOMSG %%",
    "en-US": "%% NOMSG %%"
  },
  {
    "key": "loc_data_string_perk_msg_perk_outgame_renown_amount",
    "_item": "마스터데이터 - 퍽 설명(소문의 주인공)",
    "_comment": "",
    "ko-KR": "%% OUTGAME %%",
    "en-US": "%% OUTGAME %%"
  },
  {
    "key": "loc_data_string_perk_msg_perk_outgame_alone_reward",
    "_item": "마스터데이터 - 퍽 설명(생존 전략)",
    "_comment": "",
    "ko-KR": "%% OUTGAME %%",
    "en-US": "%% OUTGAME %%"
  },
  {
    "key": "loc_data_string_perk_msg_perk_outgame_reduce_negative_renown",
    "_item": "마스터데이터 - 퍽 설명(악역은 익숙하니까)",
    "_comment": "",
    "ko-KR": "%% OUTGAME %%",
    "en-US": "%% OUTGAME %%"
  },
  {
    "key": "loc_data_string_perk_msg_perk_alone_heal",
    "_item": "마스터데이터 - 퍽 설명(외로운 생존자)",
    "_comment": "",
    "ko-KR": "혼자가 되었습니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_grenadier_base",
    "_item": "마스터데이터 - 퍽 설명(기초 척탄 훈련)",
    "_comment": "",
    "ko-KR": "수류탄을 소지하고 투척할 수 있게 됩니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_grenadier_throw_range",
    "_item": "마스터데이터 - 퍽 설명(투척거리 강화)",
    "_comment": "",
    "ko-KR": "수류탄의 투척 거리가 20% 증가합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_grenadier_amount",
    "_item": "마스터데이터 - 퍽 설명(투척물 개수 관리)",
    "_comment": "",
    "ko-KR": "수류탄의 소지 가능 개수가 1개 증가합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_grenadier_shorter_blast",
    "_item": "마스터데이터 - 퍽 설명(쿠킹)",
    "_comment": "",
    "ko-KR": "수류탄이 폭발할 때까지 걸리는 시간이 1초 감소합니다.",
    "en-US": "It reduces the amount of time it takes for a grenade to explode by one second."
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_grenadier_special_1",
    "_item": "마스터데이터 - 퍽 설명(섬광탄)",
    "_comment": "",
    "ko-KR": "섬광탄을 소지하고 투척할 수 있게 됩니다.",
    "en-US": "You will be able to carry and throw flash bombs."
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_grenadier_effect_amount",
    "_item": "마스터데이터 - 퍽 설명(투척물 효과 극대화)",
    "_comment": "",
    "ko-KR": "수류탄의 피해량, 상태이상의 지속시간이 25% 증가합니다.",
    "en-US": "Increases grenade damage, and duration of abnormal conditions by 25%."
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_grenadier_special_2",
    "_item": "마스터데이터 - 퍽 설명(연막탄)",
    "_comment": "",
    "ko-KR": "연막탄을 소지하고 투척할 수 있게 됩니다.",
    "en-US": "You will be able to carry and throw smoke bombs."
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_desmar_base",
    "_item": "마스터데이터 - 퍽 설명(사수 지정)",
    "_comment": "",
    "ko-KR": "DMR 장비 시 분대와 거리를 두고 행동하게 됩니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_desmar_priority_defensive",
    "_item": "마스터데이터 - 퍽 설명(방어적 우선순위)",
    "_comment": "",
    "ko-KR": "사격목표 전환시 아군에게 위협적인 적을 우선합니다.",
    "en-US": "When switching shooting targets, prioritize enemies that are threatening to our allies."
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_desmar_distraction",
    "_item": "마스터데이터 - 퍽 설명(견제 사격)",
    "_comment": "",
    "ko-KR": "DMR 사격 시 [장거리 제압] 상태를 유발해 접근하는 적이 오래 포복하게 합니다.",
    "en-US": "DMR shooting causes [long-range overpowering] conditions, allowing enemies to approach for a long time."
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_desmar_distraction_range",
    "_item": "마스터데이터 - 퍽 설명(광역 견제)",
    "_comment": "",
    "ko-KR": "DMR 사격 시 [장거리 제압]의 효과 범위가 사격 대상 중심 10m가 됩니다.",
    "en-US": "When shooting DMR, [Long Range Overpower] has an effect range of 10 meters centered on the target."
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_desmar_priority_offensive",
    "_item": "마스터데이터 - 퍽 설명(공격적 우선순위)",
    "_comment": "",
    "ko-KR": "사격목표 전환시 가장 많은 피해를 줄 수 있는 적을 우선합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_desmar_damage",
    "_item": "마스터데이터 - 퍽 설명(치명적 사격)",
    "_comment": "",
    "ko-KR": "DMR 장비 시 명중한 사격의 피해량이 20% 증가합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_desmar_aimspeed",
    "_item": "마스터데이터 - 퍽 설명(신선한 사냥감)",
    "_comment": "",
    "ko-KR": "DMR 장비 시 생명력 피해를 입지 않은 적을 조준하는 속도가 50% 빨라집니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_commed_base",
    "_item": "마스터데이터 - 퍽 설명(기초 의무 훈련)",
    "_comment": "",
    "ko-KR": "임무당 한 번 다른 용병을 치료해 생명력을 회복할 수 있습니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_commed_workspeed",
    "_item": "마스터데이터 - 퍽 설명(의무 숙달 훈련)",
    "_comment": "",
    "ko-KR": "치료 속도가 20% 증가합니다.",
    "en-US": "Increases the rate of treatment by 20%."
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_commed_count",
    "_item": "마스터데이터 - 퍽 설명(의무 역량 증대)",
    "_comment": "",
    "ko-KR": "임무 당 치료 회수가 1회 증가합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_commed_amount",
    "_item": "마스터데이터 - 퍽 설명(복합 치료)",
    "_comment": "",
    "ko-KR": "치료로 회복되는 생명력이 20% 증가합니다.",
    "en-US": "Increases vitality recovered by treatment by 20%."
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_commed_target_self",
    "_item": "마스터데이터 - 퍽 설명(자가 치료)",
    "_comment": "",
    "ko-KR": "임무당 한 번 자기 자신을 치료할 수 있습니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_commed_risk",
    "_item": "마스터데이터 - 퍽 설명(과감한 조치)",
    "_comment": "",
    "ko-KR": "용병의 체력이 충분할 때, 치료양의 50%만큼 피해를 준 다음 200%만큼 회복합니다.",
    "en-US": "When the mercenary has enough physical strength, deal 50% damage to the amount of treatment and then recover by 200%."
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_commed_buff",
    "_item": "마스터데이터 - 퍽 설명(미심쩍은 약물)",
    "_comment": "",
    "ko-KR": "치료받은 용병이 다음 피해를 입기 전까지 전투력 2 보너스를 얻습니다.",
    "en-US": "Get a combat power 2 bonus before the treated mercenaries deal their next damage."
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_hit_antiarmor",
    "_item": "마스터데이터 - 퍽 설명(방탄복 파괴)",
    "_comment": "",
    "ko-KR": "사격으로 생명력 피해를 입히면 방탄복에도 같은 피해를 줍니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_movespeed",
    "_item": "마스터데이터 - 퍽 설명(이동 속도 증가)",
    "_comment": "",
    "ko-KR": "이동 속도가 약간 증가합니다.",
    "en-US": "The speed of movement increases slightly."
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_aimvar_incr_m10",
    "_item": "마스터데이터 - 퍽 설명(정신력)",
    "_comment": "",
    "ko-KR": "피격 당했을 때의 조준이 흐트러지는 패널티가 15% 줄어듭니다.",
    "en-US": "15% less penalty for disrupting aim when hit."
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_outgame_training_effect",
    "_item": "마스터데이터 - 퍽 설명(의외의 재능)",
    "_comment": "",
    "ko-KR": "훈련으로 상승하는 능력치 양이 10% 확률로 두 배가 됩니다.",
    "en-US": "Double the amount of ability you increase with training with a 10% chance."
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_hit_incr_aimvar",
    "_item": "마스터데이터 - 퍽 설명(위협 전문가)",
    "_comment": "",
    "ko-KR": "유효사격 시 표적의 조준이 100% 더 크게 흐트러집니다.",
    "en-US": "Effective shooting disrupts the target's aim by 100%."
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_outgame_monthly_recover",
    "_item": "마스터데이터 - 퍽 설명(동기 부여)",
    "_comment": "",
    "ko-KR": "월급을 받는 날 생명력을 15% 회복합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_reduce_cover_effect",
    "_item": "마스터데이터 - 퍽 설명(노출 부위 탐색)",
    "_comment": "",
    "ko-KR": "내 사격이 엄폐중인 적에게 명중할 확률이 50% 증가합니다.",
    "en-US": "Increases my shot's 50% chance of hitting an undercover enemy."
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_cover_reload_evade",
    "_item": "마스터데이터 - 퍽 설명(은폐 재장전)",
    "_comment": "",
    "ko-KR": "엄폐하며 재장전중인 동안 피격당하지 않습니다.",
    "en-US": "You won't be shot while you're reloading with cover."
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_cover_effect",
    "_item": "마스터데이터 - 퍽 설명(밀착 엄폐)",
    "_comment": "",
    "ko-KR": "엄폐중일 때 피해를 받을 확률이 50% 감소합니다.",
    "en-US": "It reduces your chances of being damaged by 50% when you're covered."
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_armor_effect",
    "_item": "마스터데이터 - 퍽 설명(효과적인 방탄복)",
    "_comment": "",
    "ko-KR": "방탄복에 맞는 공격의 피해량이 50% 감소합니다.",
    "en-US": "Increases damage done by 50% from body armor."
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_cover_reload",
    "_item": "마스터데이터 - 퍽 설명(안정적 화력)",
    "_comment": "",
    "ko-KR": "엄폐중일 때 재장전 속도가 100% 증가합니다.",
    "en-US": "The reload speed increases by 100% when covered."
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_outgame_recover_amount",
    "_item": "마스터데이터 - 퍽 설명(회복 전념)",
    "_comment": "",
    "ko-KR": "생명력 피해를 15% 빠르게 회복합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_cover_steady",
    "_item": "마스터데이터 - 퍽 설명(반동 제어)",
    "_comment": "",
    "ko-KR": "엄폐 중 사격 시, 반동으로 인한 조준 흔들림이 20% 감소합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_outgame_mission_days",
    "_item": "마스터데이터 - 퍽 설명(현지 조력자)",
    "_comment": "",
    "ko-KR": "임무에 투입되는 데 걸리는 날짜가 3일 감소합니다.",
    "en-US": "It reduces the number of days it takes to be deployed to the mission by 3 days."
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_aim_execute",
    "_item": "마스터데이터 - 퍽 설명(마무리)",
    "_comment": "",
    "ko-KR": "이미 피해를 입은 적을 조준하는 속도가 50% 빨라집니다.",
    "en-US": "Increases the speed of aiming at enemies that have already been damaged by 50%."
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_piercing_bullet",
    "_item": "마스터데이터 - 퍽 설명(관통 탄자 운용)",
    "_comment": "",
    "ko-KR": "적을 사격할 때 엄폐, 장애물을 무시합니다.",
    "en-US": "Cover up when shooting enemies, ignore obstacles."
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_aimvar_max_10",
    "_item": "마스터데이터 - 퍽 설명(몰입)",
    "_comment": "",
    "ko-KR": "더 정밀하게 조준하여 최소 조준각이 10% 더 좁혀집니다.",
    "en-US": "It aims more precisely, narrowing the minimum angle of sight by 10%."
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_aimvar_10",
    "_item": "마스터데이터 - 퍽 설명(마음의 준비)",
    "_comment": "",
    "ko-KR": "조준을 시작했을 때의 최초 조준각이 10% 더 좁혀집니다.",
    "en-US": "The initial angle of sight is narrowed by 10% more when you start aiming."
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_aim_together",
    "_item": "마스터데이터 - 퍽 설명(견제 활용)",
    "_comment": "",
    "ko-KR": "다른 용병이 공격중인 적을 조준하는 속도가 50% 빨라집니다.",
    "en-US": "50% faster other mercenaries aim at an attacking enemy."
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_cover_dash",
    "_item": "마스터데이터 - 퍽 설명(신속 엄폐)",
    "_comment": "",
    "ko-KR": "적에게 공격 받을 때 엄폐물까지 20% 빠르게 달려갈 수 있습니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_suppress_m10",
    "_item": "마스터데이터 - 퍽 설명(영웅심)",
    "_comment": "",
    "ko-KR": "사격 받을 때의 조준이 흐트러지는 패널티가 10% 줄어듭니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_engage_dash",
    "_item": "마스터데이터 - 퍽 설명(돌진본능)",
    "_comment": "",
    "ko-KR": "교전 중 50% 빠르게 움직입니다. 엎드리지 않습니다.",
    "en-US": "Move 50% fast during skirmishes. I don't lie down."
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_pierce_moving_enemy",
    "_item": "마스터데이터 - 퍽 설명(움직임 포착)",
    "_comment": "",
    "ko-KR": "이동중인 적을 사격할 때 장애물을 무시합니다.",
    "en-US": "Ignore obstacles when shooting enemies on the move."
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_stationary_aimspeed",
    "_item": "마스터데이터 - 퍽 설명(자연체)",
    "_comment": "",
    "ko-KR": "정지 상태에서 사격할 때의 조준 속도가 10% 빨라집니다.",
    "en-US": "10% faster aiming speed when shooting at rest."
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_lastshoot",
    "_item": "마스터데이터 - 퍽 설명(마지막 한 발)",
    "_comment": "",
    "ko-KR": "재장전 전 마지막 탄환이 엄폐, 장애물을 무시하여 명중하고, 50% 증가한 피해를 줍니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_move_cover",
    "_item": "마스터데이터 - 퍽 설명(현란한 이동)",
    "_comment": "",
    "ko-KR": "이동 중 공격받을 때 엄폐 효과를 받습니다.",
    "en-US": "It gets a cover effect when attacked on the go."
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_glancing_blow",
    "_item": "마스터데이터 - 퍽 설명(비껴맞기)",
    "_comment": "",
    "ko-KR": "피격 당해도 급소를 피하여 치명상을 입을 확률이 50% 감소합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_standing_evade",
    "_item": "마스터데이터 - 퍽 설명(총알 탄 사나이)",
    "_comment": "",
    "ko-KR": "엄폐 없이 서 있는 동안 50% 확률로 사격이 빗나갑니다.",
    "en-US": "50% chance of missed shooting while standing without cover."
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_healed_amount_15",
    "_item": "마스터데이터 - 퍽 설명(타고난 몸)",
    "_comment": "",
    "ko-KR": "치료 받을 때의 회복 효율 보너스가 15% 증가 합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_recover_life",
    "_item": "마스터데이터 - 퍽 설명(숨 고르기)",
    "_comment": "",
    "ko-KR": "마지막 피해로부터 5초간 피해가 없으면 해당 피해의 10%를 회복합니다.",
    "en-US": "If there is no damage for 5 seconds from the last damage, 10% of that damage will be recovered."
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_armor_first",
    "_item": "마스터데이터 - 퍽 설명(거북이)",
    "_comment": "",
    "ko-KR": "방탄복이 유효한 경우, 항상 방탄복에 먼저 명중합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_critical_standing",
    "_item": "마스터데이터 - 퍽 설명(쉬운 목표)",
    "_comment": "",
    "ko-KR": "엄폐 없이 서있는 상태의 적에게 치명상을 입힐 확률이 2% 증가합니다.",
    "en-US": "Increases your chances of dealing fatal injuries to an enemy standing without cover by 2%."
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_critical_move_crawl",
    "_item": "마스터데이터 - 퍽 설명(움직이는 목표)",
    "_comment": "",
    "ko-KR": "이동 중이거나 포복중인 상태의 적에게 치명상을 입힐 확률이 2% 증가합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_critical_add",
    "_item": "마스터데이터 - 퍽 설명(약점 파악)",
    "_comment": "",
    "ko-KR": "치명상을 입힐 확률이 2% 증가합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_sameperk_stats",
    "_item": "마스터데이터 - 퍽 설명(전우애)",
    "_comment": "",
    "ko-KR": "같은 퍽을 지닌 동료가 같은 임무에 있으면 임무 동안 모든 능력치에 +1을 받습니다.",
    "en-US": "If a teammate with the same puck is on the same mission, he gets +1 for all of his abilities during the mission."
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_reduce_stat_penalty",
    "_item": "마스터데이터 - 퍽 설명(고통 나눔)",
    "_comment": "",
    "ko-KR": "이 용병이 생명력 피해를 입으면 임무 종료시까지 모든 아군의 전투력 페널티가 20% 감소합니다.",
    "en-US": "If this mercenary suffers vital damage, all friendly combat power penalties will be reduced by 20% until the end of the mission."
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_outgame_renown_amount",
    "_item": "마스터데이터 - 퍽 설명(소문의 주인공)",
    "_comment": "",
    "ko-KR": "이 용병이 참가한 임무의 명성 변화량이 20% 증가합니다.",
    "en-US": "Increases the amount of change in reputation of the mission that this mercenary participates in by 20%."
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_outgame_alone_reward",
    "_item": "마스터데이터 - 퍽 설명(생존 전략)",
    "_comment": "",
    "ko-KR": "혼자 생존한 상태로 임무를 달성한 경우, 기본 임무 보상이 20% 증가합니다.",
    "en-US": "If you achieve your mission while surviving alone, your basic mission rewards will increase by 20%."
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_outgame_reduce_negative_renown",
    "_item": "마스터데이터 - 퍽 설명(악역은 익숙하니까)",
    "_comment": "",
    "ko-KR": "명성이 감소하는 임무에서 생존해 돌아온 경우, 명성이 감소하는 양이 20% 감소합니다.",
    "en-US": "If you survive and return from a mission of diminishing fame, the amount of diminished fame is reduced by 20%."
  },
  {
    "key": "loc_data_longtext_perk_descr_perk_alone_heal",
    "_item": "마스터데이터 - 퍽 설명(외로운 생존자)",
    "_comment": "",
    "ko-KR": "자신 이외의 모든 동료 용병이 무력화되면, 생명력을 최대로 회복합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_perktree_description_0",
    "_item": "마스터데이터 - 퍽 트리 배경 설명(0번)",
    "_comment": "",
    "ko-KR": "특기할 사항은 없습니다.",
    "en-US": "There's nothing special about it."
  },
  {
    "key": "loc_data_longtext_perktree_description_1",
    "_item": "마스터데이터 - 퍽 트리 배경 설명(1번)",
    "_comment": "",
    "ko-KR": "사격시 조준을 향상시키는 데 관심이 있습니다.",
    "en-US": "I'm interested in improving my aim when shooting."
  },
  {
    "key": "loc_data_longtext_perktree_description_2",
    "_item": "마스터데이터 - 퍽 트리 배경 설명(2번)",
    "_comment": "",
    "ko-KR": "타고난 용기를 전장에서 가다듬을 기회가 있습니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_perktree_description_3",
    "_item": "마스터데이터 - 퍽 트리 배경 설명(3번)",
    "_comment": "",
    "ko-KR": "어떠한 상황에서도 적을 명중시키는 데 관심이 있습니다.",
    "en-US": "I'm interested in hitting the enemy under any circumstances."
  },
  {
    "key": "loc_data_longtext_perktree_description_4",
    "_item": "마스터데이터 - 퍽 트리 배경 설명(4번)",
    "_comment": "",
    "ko-KR": "비현실적인 생존본능을 발달시킬 수 있습니다.",
    "en-US": "It can develop an unrealistic survival instinct."
  },
  {
    "key": "loc_data_longtext_perktree_description_5",
    "_item": "마스터데이터 - 퍽 트리 배경 설명(5번)",
    "_comment": "",
    "ko-KR": "강인한 정신과 육체로 활약하고자 합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_perktree_description_6",
    "_item": "마스터데이터 - 퍽 트리 배경 설명(6번)",
    "_comment": "",
    "ko-KR": "일격필살의 정밀함을 갈고닦으려 합니다.",
    "en-US": "I'm trying to sharpen the precision of a stroke."
  },
  {
    "key": "loc_data_longtext_perktree_description_7",
    "_item": "마스터데이터 - 퍽 트리 배경 설명(7번)",
    "_comment": "",
    "ko-KR": "좋은 동료들과 일하는 데 관심이 있습니다.",
    "en-US": "I'm interested in working with good colleagues."
  },
  {
    "key": "loc_data_longtext_perktree_description_8",
    "_item": "마스터데이터 - 퍽 트리 배경 설명(8번)",
    "_comment": "",
    "ko-KR": "혼자서 일하는 것을 선호합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_perktree_description_9",
    "_item": "마스터데이터 - 퍽 트리 배경 설명(9번)",
    "_comment": "",
    "ko-KR": "부정적인 사고가 눈에 띕니다.",
    "en-US": "Negative thinking is noticeable."
  },
  {
    "key": "loc_data_longtext_perktree_description_10",
    "_item": "마스터데이터 - 퍽 트리 배경 설명(10번)",
    "_comment": "",
    "ko-KR": "공격받지 않는 거리에서 적을 하나씩 제거하기를 즐깁니다.",
    "en-US": "Enjoy removing enemies one by one from a distance that is not attacked."
  },
  {
    "key": "loc_data_longtext_perktree_description_11",
    "_item": "마스터데이터 - 퍽 트리 배경 설명(11번)",
    "_comment": "",
    "ko-KR": "급조한 붕대와 데낄라로 총에 맞은 사람을 살리곤 합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_perktree_description_12",
    "_item": "마스터데이터 - 퍽 트리 배경 설명(12번)",
    "_comment": "",
    "ko-KR": "뒷골목에서 세월을 보냈습니다.",
    "en-US": "I spent the years in the back alley."
  },
  {
    "key": "loc_data_longtext_perktree_description_13",
    "_item": "마스터데이터 - 퍽 트리 배경 설명(13번)",
    "_comment": "",
    "ko-KR": "수류탄 투척 훈련을 우수하게 통과했습니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_perktree_description_14",
    "_item": "마스터데이터 - 퍽 트리 배경 설명(14번)",
    "_comment": "",
    "ko-KR": "지정사수로 복무한 경험이 있습니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_perktree_description_15",
    "_item": "마스터데이터 - 퍽 트리 배경 설명(15번)",
    "_comment": "",
    "ko-KR": "의무병 과정을 수료했습니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_perktree_description_16",
    "_item": "마스터데이터 - 퍽 트리 배경 설명(16번)",
    "_comment": "",
    "ko-KR": "전멸한 부대의 유일한 생존자입니다.",
    "en-US": "The only survivor of the unit that was wiped out."
  },
  {
    "key": "loc_data_longtext_perktree_description_17",
    "_item": "마스터데이터 - 퍽 트리 배경 설명(17번)",
    "_comment": "",
    "ko-KR": "비교적 평범한 군인이었습니다.",
    "en-US": "He was a relatively ordinary soldier."
  },
  {
    "key": "loc_data_longtext_perktree_description_18",
    "_item": "마스터데이터 - 퍽 트리 배경 설명(18번)",
    "_comment": "",
    "ko-KR": "삶의 굴곡이 죽음으로부터 도망치는 법을 가르쳤습니다.",
    "en-US": "The twists and turns of life taught me to run away from death."
  },
  {
    "key": "loc_data_longtext_perktree_description_19",
    "_item": "마스터데이터 - 퍽 트리 배경 설명(19번)",
    "_comment": "",
    "ko-KR": "굴곡진 삶을 살았습니다.",
    "en-US": "I lived a curvy life."
  },
  {
    "key": "loc_data_longtext_perktree_description_20",
    "_item": "마스터데이터 - 퍽 트리 배경 설명(20번)",
    "_comment": "",
    "ko-KR": "거친 일에 익숙하지 않습니다.",
    "en-US": "I'm not used to rough work."
  },
  {
    "key": "loc_data_longtext_perktree_description_21",
    "_item": "마스터데이터 - 퍽 트리 배경 설명(21번)",
    "_comment": "",
    "ko-KR": "사람들의 감정을 조절하는 천부적인 재능이 있습니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_perktree_description_22",
    "_item": "마스터데이터 - 퍽 트리 배경 설명(22번)",
    "_comment": "",
    "ko-KR": "어떻게 사람들을 이끄는 자리에 있었는지 이해할 수 없게 평범합니다.",
    "en-US": "I can't understand how I was in a position to lead people."
  },
  {
    "key": "loc_data_longtext_perktree_description_23",
    "_item": "마스터데이터 - 퍽 트리 배경 설명(23번)",
    "_comment": "",
    "ko-KR": "노동으로 단련된 강인한 어깨를 지녔습니다.",
    "en-US": "It has strong shoulders trained by labor."
  },
  {
    "key": "loc_data_longtext_perktree_description_24",
    "_item": "마스터데이터 - 퍽 트리 배경 설명(24번)",
    "_comment": "",
    "ko-KR": "보이는 것보다 노동으로 단련된 육체가 탄탄합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_perktree_description_25",
    "_item": "마스터데이터 - 퍽 트리 배경 설명(25번)",
    "_comment": "",
    "ko-KR": "오래된 노동을 지긋지긋해하고 있습니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_perktree_description_26",
    "_item": "마스터데이터 - 퍽 트리 배경 설명(26번)",
    "_comment": "",
    "ko-KR": "화학물질을 안전하게 운용할 수 있습니다.",
    "en-US": "Chemicals can be operated safely."
  },
  {
    "key": "loc_data_longtext_perktree_description_27",
    "_item": "마스터데이터 - 퍽 트리 배경 설명(27번)",
    "_comment": "",
    "ko-KR": "의무 훈련을 받을 기초지식이 있습니다.",
    "en-US": "I have basic knowledge for compulsory training."
  },
  {
    "key": "loc_data_longtext_perktree_description_28",
    "_item": "마스터데이터 - 퍽 트리 배경 설명(28번)",
    "_comment": "",
    "ko-KR": "책상물림입니다.",
    "en-US": "It's a desk bite."
  },
  {
    "key": "loc_data_string_perktree_perk_group_name_common",
    "_item": "마스터데이터 - 퍽 트리 퍽 그룹 이름(공통)",
    "_comment": "",
    "ko-KR": "공통",
    "en-US": "Common"
  },
  {
    "key": "loc_data_string_perktree_perk_group_name_decision",
    "_item": "마스터데이터 - 퍽 트리 퍽 그룹 이름(판단력)",
    "_comment": "",
    "ko-KR": "판단력",
    "en-US": "Decision"
  },
  {
    "key": "loc_data_string_perktree_perk_group_name_bravery",
    "_item": "마스터데이터 - 퍽 트리 퍽 그룹 이름(용감함)",
    "_comment": "",
    "ko-KR": "용감함",
    "en-US": "Bravery"
  },
  {
    "key": "loc_data_string_perktree_perk_group_name_focus",
    "_item": "마스터데이터 - 퍽 트리 퍽 그룹 이름(집중력)",
    "_comment": "",
    "ko-KR": "집중력",
    "en-US": "Focus"
  },
  {
    "key": "loc_data_string_perktree_perk_group_name_reaction",
    "_item": "마스터데이터 - 퍽 트리 퍽 그룹 이름(반응속도)",
    "_comment": "",
    "ko-KR": "반응속도",
    "en-US": "Reaction"
  },
  {
    "key": "loc_data_string_perktree_perk_group_name_toughness",
    "_item": "마스터데이터 - 퍽 트리 퍽 그룹 이름(강인함)",
    "_comment": "",
    "ko-KR": "강인함",
    "en-US": "Toughness"
  },
  {
    "key": "loc_data_string_perktree_perk_group_name_precision",
    "_item": "마스터데이터 - 퍽 트리 퍽 그룹 이름(정밀함)",
    "_comment": "",
    "ko-KR": "정밀함",
    "en-US": "Precision"
  },
  {
    "key": "loc_data_string_perktree_perk_group_name_social",
    "_item": "마스터데이터 - 퍽 트리 퍽 그룹 이름(사교적)",
    "_comment": "",
    "ko-KR": "사교적",
    "en-US": "Social"
  },
  {
    "key": "loc_data_string_perktree_perk_group_name_antisocial",
    "_item": "마스터데이터 - 퍽 트리 퍽 그룹 이름(외톨이)",
    "_comment": "",
    "ko-KR": "외톨이",
    "en-US": "Antisocial"
  },
  {
    "key": "loc_data_string_perktree_perk_group_name_negative",
    "_item": "마스터데이터 - 퍽 트리 퍽 그룹 이름(부정적)",
    "_comment": "",
    "ko-KR": "부정적",
    "en-US": "Negative"
  },
  {
    "key": "loc_data_string_perktree_perk_group_name_desmar_offensive",
    "_item": "마스터데이터 - 퍽 트리 퍽 그룹 이름(지정사수(뒷골목))",
    "_comment": "",
    "ko-KR": "지정사수(뒷골목)",
    "en-US": "Designated Marksman(Outlaw)"
  },
  {
    "key": "loc_data_string_perktree_perk_group_name_commed_unlicensed",
    "_item": "마스터데이터 - 퍽 트리 퍽 그룹 이름(전투의무병(뒷골목))",
    "_comment": "",
    "ko-KR": "전투의무병(뒷골목)",
    "en-US": "Combat Medic(Outlaw)"
  },
  {
    "key": "loc_data_string_perktree_perk_group_name_grenadier_regular",
    "_item": "마스터데이터 - 퍽 트리 퍽 그룹 이름(척탄병(정규))",
    "_comment": "",
    "ko-KR": "척탄병(정규)",
    "en-US": "Grenadier(Regular)"
  },
  {
    "key": "loc_data_string_perktree_perk_group_name_desmar_defensive",
    "_item": "마스터데이터 - 퍽 트리 퍽 그룹 이름(지정사수(정규))",
    "_comment": "",
    "ko-KR": "지정사수(정규)",
    "en-US": "Designated Marksman(Regular)"
  },
  {
    "key": "loc_data_string_perktree_perk_group_name_commed_regular",
    "_item": "마스터데이터 - 퍽 트리 퍽 그룹 이름(전투의무병(정규))",
    "_comment": "",
    "ko-KR": "전투의무병(정규)",
    "en-US": "Combat Medic(Regular)"
  },
  {
    "key": "loc_data_string_perktree_perk_group_name_grenadier_chemical",
    "_item": "마스터데이터 - 퍽 트리 퍽 그룹 이름(척탄병(특수탄))",
    "_comment": "",
    "ko-KR": "척탄병(특수탄)",
    "en-US": "Grenadier(Chemical)"
  },
  {
    "key": "loc_data_longtext_playtip_message_all_or_nothing",
    "_item": "마스터데이터 - 플레이 팁(all_or_nothing)",
    "_comment": "",
    "ko-KR": "교전 중 쓰러진 용병은 큰 돈과 시간을 들여야 회복시킬 수 있습니다.",
    "en-US": "The mercenaries who fell during the skirmish must spend a lot of money and time to recover."
  },
  {
    "key": "loc_data_longtext_playtip_message_renown",
    "_item": "마스터데이터 - 플레이 팁(renown)",
    "_comment": "",
    "ko-KR": "명성 레벨을 올리면 더 도전적이고 더 많은 보상을 주는 임무를 수행할 수 있습니다.",
    "en-US": "Raising your reputation level will make you more challenging and more rewarding."
  },
  {
    "key": "loc_data_longtext_playtip_message_training",
    "_item": "마스터데이터 - 플레이 팁(training)",
    "_comment": "",
    "ko-KR": "용병을 잘 훈련시키세요: 훈련을 통해 용병의 전투 역량을 크게 강화할 수 있습니다.",
    "en-US": "Train your mercenaries well: You can greatly strengthen your mercenaries' combat capabilities through training."
  },
  {
    "key": "loc_data_longtext_playtip_message_market",
    "_item": "마스터데이터 - 플레이 팁(market)",
    "_comment": "",
    "ko-KR": "좋은 화기를 통해 용병의 화력을 크게 올릴 수 있습니다. 좋은 방어구로 용병의 교전 지속력과 생존성을 크게 개선할 수 있습니다.",
    "en-US": "A good firearm can greatly increase the firepower of mercenaries. A good defense can significantly improve the combat persistence and viability of mercenaries."
  },
  {
    "key": "loc_data_longtext_playtip_message_task",
    "_item": "마스터데이터 - 플레이 팁(task)",
    "_comment": "",
    "ko-KR": "영입 메뉴와 시장 메뉴를 통해 실력있는 용병과 좋은 장비를 확보할 수 있습니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_playtip_message_training_maintanance",
    "_item": "마스터데이터 - 플레이 팁(training_maintanance)",
    "_comment": "",
    "ko-KR": "훈련장을 잘 관리하세요: 낙후된 훈련장에서는 용병을 적절하게 훈련시킬 수 없습니다.",
    "en-US": "Take good care of your training ground: You can't train mercenaries properly in an outdated training ground."
  },
  {
    "key": "loc_data_longtext_playtip_message_safehouse",
    "_item": "마스터데이터 - 플레이 팁(safehouse)",
    "_comment": "",
    "ko-KR": "작전 가능 영역을 확장하면 더 많은 의뢰에 접근할 수 있습니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_playtip_message_base",
    "_item": "마스터데이터 - 플레이 팁(base)",
    "_comment": "",
    "ko-KR": "거점의 시설을 업그레이드하세요. 다양한 효과를 제공하며 성장의 강력한 동력이 될 것입니다.",
    "en-US": "Upgrade your base's facilities. It provides a variety of effects and will be a powerful driver of growth."
  },
  {
    "key": "loc_data_longtext_playtip_message_risk",
    "_item": "마스터데이터 - 플레이 팁(risk)",
    "_comment": "",
    "ko-KR": "의뢰 수락 전, 기대 결과를 주의 깊게 살피세요. 항상 맞는 것은 아니지만 위험을 통제하는데 도움이 됩니다.",
    "en-US": "Before accepting the request, look carefully for the expected results. It's not always right, but it helps you control your risk."
  },
  {
    "key": "loc_data_longtext_playtip_message_cashflow",
    "_item": "마스터데이터 - 플레이 팁(cashflow)",
    "_comment": "",
    "ko-KR": "현금 흐름을 잘 관리하세요: 회사의 번레이트가 너무 높은 경우, 회사를 유지하기 어려울 수 있습니다.",
    "en-US": "Take good care of your cash flow: If a company's burn rate is too high, it can be difficult to maintain a company."
  },
  {
    "key": "loc_data_longtext_playtip_message_preset",
    "_item": "마스터데이터 - 플레이 팁(preset)",
    "_comment": "",
    "ko-KR": "임무 배경을 주시하세요: 임무 배경에 따라 의뢰에 참여하는 용병을 주의깊게 선택해야 할 수 있습니다.",
    "en-US": "Keep an eye on the mission background: Depending on the mission background, you may have to carefully select the mercenaries who participate in the commission."
  },
  {
    "key": "loc_data_longtext_playtip_message_perk",
    "_item": "마스터데이터 - 플레이 팁(perk)",
    "_comment": "",
    "ko-KR": "임무를 성공시킨 용병은 경험치를 획득합니다. 경험치가 최대치에 도달하면 레벨이 상승하며 퍽 포인트를 획득합니다.",
    "en-US": "The mercenaries who succeed in the mission gain experience. When the experience reaches its maximum, the level rises to earn puck points."
  },
  {
    "key": "loc_data_longtext_playtip_message_aptitude",
    "_item": "마스터데이터 - 플레이 팁(aptitude)",
    "_comment": "",
    "ko-KR": "임무 중 피해를 입을 때마다 용병들의 사기가 떨어집니다. 사기가 바닥나면 용병들은 임무를 포기하고 후퇴합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_playtip_message_throwable",
    "_item": "마스터데이터 - 플레이 팁(throwable)",
    "_comment": "",
    "ko-KR": "수류탄을 잘 활용하면 좁은 공간에 모여있는 적을 쉽게 제압할 수 있습니다.",
    "en-US": "If you make good use of grenades, you can easily overpower enemies that are gathered in a small space."
  },
  {
    "key": "loc_data_longtext_playtip_message_potential",
    "_item": "마스터데이터 - 플레이 팁(potential)",
    "_comment": "",
    "ko-KR": "용병마다 성장 속도와 성장 한계가 다릅니다: 용병을 신중하게 영입하세요.",
    "en-US": "Each mercenary has a different growth rate and limits: Recruit mercenaries carefully."
  },
  {
    "key": "loc_data_longtext_playtip_message_agentcount",
    "_item": "마스터데이터 - 플레이 팁(agentcount)",
    "_comment": "",
    "ko-KR": "거점마다 배치할 수 있는 용병의 수의 제한이 있습니다. 더 많은 용병을 한 거점에 배치하고 싶다면 거점을 업그레이드하세요.",
    "en-US": "Each base has a limit on the number of mercenaries that can be deployed. Upgrade your base if you want to deploy more mercenaries in one base."
  },
  {
    "key": "loc_data_longtext_playtip_message_agentrole",
    "_item": "마스터데이터 - 플레이 팁(agentrole)",
    "_comment": "",
    "ko-KR": "몇몇 용병은 임무 중 특수한 역할을 수행할 수 있습니다: 예를 들어, 의료 지식이 있는 용병은 부상당한 다른 용병을 응급처치할 수 있습니다. 특수한 역할은 퍽을 통해 부여할 수 있습니다.",
    "en-US": "Some mercenaries can perform special roles during missions: For example, mercenaries with medical knowledge can provide first aid to other injured mercenaries. Special roles can be given through the puck."
  },
  {
    "key": "loc_data_longtext_playtip_message_contract",
    "_item": "마스터데이터 - 플레이 팁(contract)",
    "_comment": "",
    "ko-KR": "용병마다 요구하는 계약 조건이 다릅니다: 표준 계약은 계약 기간동안 일정한 지출이 발생하지만, 임무 수행에 따른 추가 지출이 없습니다. 인센티브 계약의 경우 고정 비용이 낮지만, 용병이 임무에 참여할 경우 수당을 지급하고 성공 시 임무 보상의 일부를 추가로 지급해야 합니다.",
    "en-US": "Different mercenaries have different terms and conditions of the contract: Standard contracts incur certain expenditures during the contract period, but there is no additional expenditure on the performance of the mission. In the case of incentive contracts, the fixed cost is low, but if mercenaries participate in the mission, they will have to pay an allowance and if they succeed, they will have to pay an additional portion of the mission compensation."
  },
  {
    "key": "loc_data_longtext_quest_title_quest_first_dispatch",
    "_item": "마스터데이터 - 퀘스트 제목(첫 번째 의뢰)",
    "_comment": "",
    "ko-KR": "첫 번째 의뢰",
    "en-US": "First Contract"
  },
  {
    "key": "loc_data_longtext_quest_title_quest_progress_time",
    "_item": "마스터데이터 - 퀘스트 제목(게임 진행)",
    "_comment": "",
    "ko-KR": "게임 진행",
    "en-US": "Game Progress"
  },
  {
    "key": "loc_data_longtext_quest_title_quest_first_firearm",
    "_item": "마스터데이터 - 퀘스트 제목(화기)",
    "_comment": "",
    "ko-KR": "화기",
    "en-US": "Firearms"
  },
  {
    "key": "loc_data_longtext_quest_title_quest_first_armor",
    "_item": "마스터데이터 - 퀘스트 제목(방탄장비)",
    "_comment": "",
    "ko-KR": "방탄장비",
    "en-US": "Protective Equipment"
  },
  {
    "key": "loc_data_longtext_quest_title_quest_first_purchase",
    "_item": "마스터데이터 - 퀘스트 제목(아이템 구매)",
    "_comment": "",
    "ko-KR": "아이템 구매",
    "en-US": "Item Purchase"
  },
  {
    "key": "loc_data_longtext_quest_title_quest_first_trader",
    "_item": "마스터데이터 - 퀘스트 제목(시설과 부서)",
    "_comment": "",
    "ko-KR": "시설과 부서",
    "en-US": "Facility and Company"
  },
  {
    "key": "loc_data_longtext_quest_title_quest_upgrade_base",
    "_item": "마스터데이터 - 퀘스트 제목(시설 업그레이드)",
    "_comment": "",
    "ko-KR": "시설 업그레이드",
    "en-US": "Next Step"
  },
  {
    "key": "loc_data_longtext_quest_title_quest_first_perk",
    "_item": "마스터데이터 - 퀘스트 제목(스텝업)",
    "_comment": "",
    "ko-KR": "스텝업",
    "en-US": "Powering Up"
  },
  {
    "key": "loc_data_longtext_quest_title_quest_search_armor",
    "_item": "마스터데이터 - 퀘스트 제목(장비 탐색)",
    "_comment": "",
    "ko-KR": "장비 탐색",
    "en-US": "Finding Gears"
  },
  {
    "key": "loc_data_longtext_quest_title_quest_full_metal_jacket",
    "_item": "마스터데이터 - 퀘스트 제목(안전은 용기의 아버지)",
    "_comment": "",
    "ko-KR": "안전은 용기의 아버지",
    "en-US": "Safety Maketh Soldier"
  },
  {
    "key": "loc_data_longtext_quest_title_quest_first_recruit",
    "_item": "마스터데이터 - 퀘스트 제목(영입)",
    "_comment": "",
    "ko-KR": "영입",
    "en-US": "Recruit"
  },
  {
    "key": "loc_data_longtext_quest_title_quest_first_headhunter",
    "_item": "마스터데이터 - 퀘스트 제목(용병 탐색)",
    "_comment": "",
    "ko-KR": "용병 탐색",
    "en-US": "Mercenary Search"
  },
  {
    "key": "loc_data_longtext_quest_title_quest_search_staff",
    "_item": "마스터데이터 - 퀘스트 제목(직원 탐색)",
    "_comment": "",
    "ko-KR": "직원 탐색",
    "en-US": "Searching Staff"
  },
  {
    "key": "loc_data_longtext_quest_title_quest_recruit_staff",
    "_item": "마스터데이터 - 퀘스트 제목(직원 고용)",
    "_comment": "",
    "ko-KR": "직원 고용",
    "en-US": "Mercena Non Combata"
  },
  {
    "key": "loc_data_longtext_quest_title_quest_first_trainer",
    "_item": "마스터데이터 - 퀘스트 제목(훈련장)",
    "_comment": "",
    "ko-KR": "훈련장",
    "en-US": "Training Yards"
  },
  {
    "key": "loc_data_longtext_quest_title_quest_train",
    "_item": "마스터데이터 - 퀘스트 제목(용병 훈련)",
    "_comment": "",
    "ko-KR": "용병 훈련",
    "en-US": "Win Before Fight"
  },
  {
    "key": "loc_data_longtext_quest_title_quest_build_command1",
    "_item": "마스터데이터 - 퀘스트 제목(지휘통제실)",
    "_comment": "",
    "ko-KR": "지휘통제실",
    "en-US": "Command and Control"
  },
  {
    "key": "loc_data_longtext_quest_title_quest_clear_district",
    "_item": "마스터데이터 - 퀘스트 제목(질서 회복)",
    "_comment": "",
    "ko-KR": "질서 회복(게임에 안 들어가야 함)",
    "en-US": "Reform Order"
  },
  {
    "key": "loc_data_longtext_quest_title_quest_build_safehouse",
    "_item": "마스터데이터 - 퀘스트 제목(영역 확장)",
    "_comment": "",
    "ko-KR": "영역 확장(게임에 안 들어가야 함)",
    "en-US": "Expansion"
  },
  {
    "key": "loc_data_longtext_quest_title_quest_tier_2",
    "_item": "마스터데이터 - 퀘스트 제목(기지 증축)",
    "_comment": "",
    "ko-KR": "기지 증축(게임에 안 들어가야 함)",
    "en-US": "Upgrading Base"
  },
  {
    "key": "loc_data_longtext_quest_title_quest_build_office",
    "_item": "마스터데이터 - 퀘스트 제목(전진 기지)",
    "_comment": "",
    "ko-KR": "전진 기지(게임에 안 들어가야 함)",
    "en-US": "Pushing Frontline"
  },
  {
    "key": "loc_data_longtext_quest_title_quest_demo_00",
    "_item": "마스터데이터 - 퀘스트 제목(창업)",
    "_comment": "",
    "ko-KR": "창업",
    "en-US": "A Startup Company"
  },
  {
    "key": "loc_data_longtext_quest_title_quest_demo_01",
    "_item": "마스터데이터 - 퀘스트 제목(첫 번째 숙제)",
    "_comment": "",
    "ko-KR": "첫 번째 숙제",
    "en-US": "First Homework"
  },
  {
    "key": "loc_data_longtext_quest_title_quest_demo_02",
    "_item": "마스터데이터 - 퀘스트 제목(준비 (1))",
    "_comment": "",
    "ko-KR": "준비(1)",
    "en-US": "Preparation(1)"
  },
  {
    "key": "loc_data_longtext_quest_title_quest_demo_03",
    "_item": "마스터데이터 - 퀘스트 제목(준비 (2))",
    "_comment": "",
    "ko-KR": "준비(2)",
    "en-US": "Preparation(2)"
  },
  {
    "key": "loc_data_longtext_quest_title_quest_demo_04",
    "_item": "마스터데이터 - 퀘스트 제목(두 번째 숙제)",
    "_comment": "",
    "ko-KR": "두 번째 숙제",
    "en-US": "Second Homework"
  },
  {
    "key": "loc_data_longtext_quest_instruction_quest_first_dispatch",
    "_item": "마스터데이터 - 퀘스트 지시(첫 번째 의뢰)",
    "_comment": "",
    "ko-KR": "의뢰에 용병을 파견하세요.",
    "en-US": "Send mercenaries to the commission."
  },
  {
    "key": "loc_data_longtext_quest_instruction_quest_progress_time",
    "_item": "마스터데이터 - 퀘스트 지시(게임 진행)",
    "_comment": "",
    "ko-KR": "스페이스 키를 눌러 시간을 진행하세요.\n1, 2, 3 키를 눌러 시간이 지나는 속도를 조절하세요.\n파견한 용병들이 의뢰를 수행하기를 기다리세요.",
    "en-US": "Press the space key to proceed with the time.\nPress 1, 2, and 3 to adjust the speed at which time passes.\nWait for the dispatched mercenaries to carry out the commission."
  },
  {
    "key": "loc_data_longtext_quest_instruction_quest_first_firearm",
    "_item": "마스터데이터 - 퀘스트 지시(화기)",
    "_comment": "",
    "ko-KR": "획득한 화기를 용병에게 장착시키세요.",
    "en-US": "Mount the acquired firearm to the mercenary."
  },
  {
    "key": "loc_data_longtext_quest_instruction_quest_first_armor",
    "_item": "마스터데이터 - 퀘스트 지시(방탄장비)",
    "_comment": "",
    "ko-KR": "획득한 방탄장비를 용병에게 장착시키세요.",
    "en-US": "Mount the acquired bulletproof equipment to the mercenaries."
  },
  {
    "key": "loc_data_longtext_quest_instruction_quest_first_purchase",
    "_item": "마스터데이터 - 퀘스트 지시(아이템 구매)",
    "_comment": "",
    "ko-KR": "상점에서 아이템을 구입하세요.\n구매 업무가 완료될 때까지 기다리세요.",
    "en-US": "Buy items from the store.\nPlease wait for the purchase to be completed."
  },
  {
    "key": "loc_data_longtext_quest_instruction_quest_first_trader",
    "_item": "마스터데이터 - 퀘스트 지시(시설과 부서)",
    "_comment": "",
    "ko-KR": "거점을 선택해 무기상 시설을 건설하세요.\n무기상 시설이 완성될 때까지 기다리세요.",
    "en-US": "Select a base to build an arms dealer facility.\nWait for the weaponry facility to be completed."
  },
  {
    "key": "loc_data_longtext_quest_instruction_quest_upgrade_base",
    "_item": "마스터데이터 - 퀘스트 지시(시설 업그레이드)",
    "_comment": "",
    "ko-KR": "방탄사무실을 2티어로 업그레이드하세요.",
    "en-US": "%% TODO %%\r\n%% TODO %%"
  },
  {
    "key": "loc_data_longtext_quest_instruction_quest_first_perk",
    "_item": "마스터데이터 - 퀘스트 지시(스텝업)",
    "_comment": "",
    "ko-KR": "퍽포인트를 사용해 퍽을 획득하세요.",
    "en-US": "Use the puckpoint to get the puck."
  },
  {
    "key": "loc_data_longtext_quest_instruction_quest_search_armor",
    "_item": "마스터데이터 - 퀘스트 지시(장비 탐색)",
    "_comment": "",
    "ko-KR": "무기상 부서에 부서장을 배치하세요.\n무기상 부서에 방탄장비 조달 업무를 할당하세요.\n무기상 부서가 업무를 완료할 때까지 기다리세요.",
    "en-US": "%% TODO %%\r\n%% TODO %%\r\n%% TODO %%"
  },
  {
    "key": "loc_data_longtext_quest_instruction_quest_full_metal_jacket",
    "_item": "마스터데이터 - 퀘스트 지시(안전은 용기의 아버지)",
    "_comment": "",
    "ko-KR": "소속된 모든 용병에게 방탄장비를 착용시키세요.",
    "en-US": "Wear bulletproof equipment to all of your mercenaries."
  },
  {
    "key": "loc_data_longtext_quest_instruction_quest_first_recruit",
    "_item": "마스터데이터 - 퀘스트 지시(영입)",
    "_comment": "",
    "ko-KR": "영입 목록에서 용병을 영입하세요.\n용병 영입 업무가 완료될 때까지 기다리세요.\n거점에 신입 용병을 배치하세요.",
    "en-US": "Recruit mercenaries from the recruiting list.\nWait for the mercenary recruitment work to be completed.\nPlace new mercenaries in the base."
  },
  {
    "key": "loc_data_longtext_quest_instruction_quest_first_headhunter",
    "_item": "마스터데이터 - 퀘스트 지시(용병 탐색)",
    "_comment": "",
    "ko-KR": "모병 부서에 신규 용병 탐색 업무를 할당하세요.\n모병 부서가 업무를 완료할 때까지 기다리세요.",
    "en-US": "Assign a new mercenary search task to the recruitment department.\nWait for the recruitment department to complete the task."
  },
  {
    "key": "loc_data_longtext_quest_instruction_quest_search_staff",
    "_item": "마스터데이터 - 퀘스트 지시(직원 탐색)",
    "_comment": "",
    "ko-KR": "총무 부서에 부서장을 배치하세요.\n총무 부서에 신규 직원 탐색 업무를 할당하세요.\n총무 부서가 신규 직원 탐색 업무를 마치길 기다리세요.",
    "en-US": "Place a department head in the general affairs department.\nAssign a new employee search task to the general affairs department.\nWait for the general affairs department to finish exploring new employees."
  },
  {
    "key": "loc_data_longtext_quest_instruction_quest_recruit_staff",
    "_item": "마스터데이터 - 퀘스트 지시(직원 고용)",
    "_comment": "",
    "ko-KR": "총무 부서에 부서장이 배치되어 있어야 합니다.\n직원 후보 목록에서 직원을 고용하세요.\n직원 고용 업무가 완료될 때까지 기다리세요.",
    "en-US": "There should be a department head in the general affairs department.\nHire an employee from the employee candidate list.\nPlease wait for the hiring of your employees to complete."
  },
  {
    "key": "loc_data_longtext_quest_instruction_quest_first_trainer",
    "_item": "마스터데이터 - 퀘스트 지시(훈련장)",
    "_comment": "",
    "ko-KR": "거점을 선택해 일반 훈련장 시설을 건설하세요.\n훈련 부서에 훈련장 관리 업무를 할당하세요.",
    "en-US": "Choose a base to build a regular training ground facility.\nAllocate training site management tasks to the training department."
  },
  {
    "key": "loc_data_longtext_quest_instruction_quest_train",
    "_item": "마스터데이터 - 퀘스트 지시(용병 훈련)",
    "_comment": "",
    "ko-KR": "훈련 슬롯에서 용병을 훈련시키세요.",
    "en-US": "Train mercenaries in training slots."
  },
  {
    "key": "loc_data_longtext_quest_instruction_quest_build_command1",
    "_item": "마스터데이터 - 퀘스트 지시(지휘통제실)",
    "_comment": "",
    "ko-KR": "거점을 선택해 지휘통제실 시설을 건설하세요.\n지휘통제실 시설이 완성될 때까지 기다리세요.",
    "en-US": "%% TODO %%\r\n%% TODO %%"
  },
  {
    "key": "loc_data_longtext_quest_instruction_quest_clear_district",
    "_item": "마스터데이터 - 퀘스트 지시(질서 회복)",
    "_comment": "",
    "ko-KR": "아르메니아 지역을 안정화하세요.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_quest_instruction_quest_build_safehouse",
    "_item": "마스터데이터 - 퀘스트 지시(영역 확장)",
    "_comment": "",
    "ko-KR": "에스페란차 혹은 보카그란데에 안전가옥을 확보하여 활동 영역을 넓히세요.",
    "en-US": "Expand your range of activities by securing a safe house in Esperanza or Boca Grande."
  },
  {
    "key": "loc_data_longtext_quest_instruction_quest_tier_2",
    "_item": "마스터데이터 - 퀘스트 지시(기지 증축)",
    "_comment": "",
    "ko-KR": "마일스톤 임무를 성공시키세요.\n2티어 지역에 안전가옥을 확보하여 활동 영역을 넓히세요.\n거점 시설을 2티어로 업그레이드하세요.",
    "en-US": "Make the milestone mission a success.\nExpand your area of activity by securing a safe house in Tier 2 area.\nUpgrade your base facilities to Tier 2."
  },
  {
    "key": "loc_data_longtext_quest_instruction_quest_build_office",
    "_item": "마스터데이터 - 퀘스트 지시(전진 기지)",
    "_comment": "",
    "ko-KR": "두번째 거점 설치",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_quest_title_quest_demo_00",
    "_item": "마스터데이터 - 퀘스트 지시(창업)",
    "_comment": "",
    "ko-KR": "다섯(5) 명의 용병을 임무를 수행할 수 있는 상태로 준비시키세요\n레벨 3 이상의 용병을 확보하세요.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_quest_title_quest_demo_01",
    "_item": "마스터데이터 - 퀘스트 지시(첫 번째 숙제)",
    "_comment": "",
    "ko-KR": "중요 임무 (눈에 밟히던 놈들)을 완료하세요.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_quest_title_quest_demo_02",
    "_item": "마스터데이터 - 퀘스트 지시(준비 (1))",
    "_comment": "",
    "ko-KR": "두(2) 명의 용병을, 2등급 이상의 무장을 장비하고 임무를 수행할 수 있는 상태로 준비시키세요.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_quest_title_quest_demo_03",
    "_item": "마스터데이터 - 퀘스트 지시(준비 (2))",
    "_comment": "",
    "ko-KR": "척탄병 퍽 그룹의 [기초 척탄 훈련] 퍽을 보유한 용병을 확보하세요.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_quest_title_quest_demo_04",
    "_item": "마스터데이터 - 퀘스트 지시(두 번째 숙제)",
    "_comment": "",
    "ko-KR": "중요 임무 (폭죽 놀이)을 완료하세요.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_quest_description_quest_first_dispatch",
    "_item": "마스터데이터 - 퀘스트 설명(첫 번째 의뢰)",
    "_comment": "",
    "ko-KR": "SLB에서 오신 것을 환영합니다.\n회사를 운영하기 위해서 현금이 필요하고, 의뢰를 성공적으로 수행하고 받는 의뢰 수행금이 회사의 주된 수익원입니다.\n의뢰를 선택하고 참여할 요원을 고르면 전력에 따라 승패 예측을 볼 수 있습니다.\n* Decesive Defeat:임무는 거의 대부분 실패할 것입니다.\n* Uncertain: 임무는 성공할 수도 있고 실패할 수도 있습니다.\n* Likely Victory: 임무는 아마 성공할 것입니다.\n\n월드맵의 임무 마커를 클릭하거나 화면 좌측의 의뢰 메뉴에서 임무를 선택하세요. \n의뢰에 투입할 용병을 정한 뒤, \"의뢰 확정\"를 눌러 의뢰 수행을 시작합니다.",
    "en-US": "Welcome from SLB.\nCash is needed to run the company, and the commission performance fee that is successfully performed and received is the main source of revenue for the company.\nOnce you select a referral and select an agent to participate, you can view the win-loss prediction based on your power.\n* Desive Defeat: The mission will mostly fail.\n* Uncertain: A mission can be successful or unsuccessful.\n* Likly Victory: The mission will probably succeed.\n\nClick the mission marker on the World Map or select a mission from the commission menu on the left side of the screen. \nAfter selecting the mercenaries to be put into the request, press \"Confirm the request\" to start the request."
  },
  {
    "key": "loc_data_longtext_quest_description_quest_progress_time",
    "_item": "마스터데이터 - 퀘스트 설명(게임 진행)",
    "_comment": "",
    "ko-KR": "현재 게임은 일시정지되어있습니다.\n게임 내 대부분의 행동은 게임 내의 시간이 흐름에 따라 진행됩니다.\nSpace 키를 눌러서 시간을 진행시키거나 일시정지시킬 수 있습니다.\n1, 2, 3 키를 눌러서 게임에서 시간이 흐르는 속도를 변경할 수 있습니다.",
    "en-US": "The game is currently paused.\nMost of the actions in the game are done over time in the game.\nPress the Space key to progress or pause time.\nPress 1, 2, and 3 to change the speed of time in the game."
  },
  {
    "key": "loc_data_longtext_quest_description_quest_first_firearm",
    "_item": "마스터데이터 - 퀘스트 설명(화기)",
    "_comment": "",
    "ko-KR": "새로운 화기를 획득했습니다. \n용병을 좋은 화기로 무장시켜야 임무에서 적을 제압하기 쉬워집니다.\n화기의 종류에 따라 다른 특징을 가지며, 같은 종류 내에서는 티어가 상승할수록 더 강력해집니다. \n용병 한 명을 새 화기로 무장시켜보세요. \n화면 좌측의 창고 메뉴에서 장착하지 않은 아이템을 관리하고 판매할 수 있습니다. \n화면 좌측의 요원 메뉴에서 볼 수 있는 상세창이나 임무 로드아웃 창에서 장착한 아이템을 관리할 수 있습니다. ",
    "en-US": "We have acquired a new firearm. \nYou have to arm your mercenaries with good firearm to make it easier to overpower the enemy in the mission.\nIt has different characteristics depending on the type of firearm, and within the same type, it becomes stronger as the tier rises. \nArm a mercenary with a new firearm. \nYou can manage and sell uninstalled items from the warehouse menu on the left side of the screen. \nYou can manage items mounted in the detail pane or the mission loadout pane that you see in the Agent menu on the left side of the screen."
  },
  {
    "key": "loc_data_longtext_quest_description_quest_first_armor",
    "_item": "마스터데이터 - 퀘스트 설명(방탄장비)",
    "_comment": "",
    "ko-KR": "새로운 방탄장비를 획득했습니다. \n방탄장비는 높은 확률로 플레이어가 받을 데미지를 대신 받아내는 아이템입니다. \n용병을 좋은 방탄장비로 무장시켜 임무에 보내는 것은 높은 생존률로 이어지며, 이는 회사의 이익이 됩니다. \n방탄장비는 티어가 상승할수록 더 좋은 성능을 갖습니다. \n용병 한 명을 새 방탄장비로 무장시켜보세요.",
    "en-US": "We have acquired new bulletproof equipment. \nBulletproof equipment is an item that receives damage on behalf of players with high probability. \nArmed with good bulletproof equipment, sending mercenaries on duty leads to a high survival rate, which is in the company's interest. \nBulletproof equipment has better performance as the tier rises. \nArm one mercenary with new bulletproof equipment."
  },
  {
    "key": "loc_data_longtext_quest_description_quest_first_purchase",
    "_item": "마스터데이터 - 퀘스트 설명(아이템 구매)",
    "_comment": "",
    "ko-KR": "임무에서와 달리, 안정적으로 전투장비를 구할 수 있는 방법도 있습니다.\n좌측의 시장 메뉴의 아이템 목록에서 사고 싶은 아이템을 골라 구매를 진행할 수 있습니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_quest_description_quest_first_trader",
    "_item": "마스터데이터 - 퀘스트 설명(시설과 부서)",
    "_comment": "",
    "ko-KR": "(obsolete)더 좋은 아이템을 더 빠르게 탐색하고 구매하려면 직원들에 탐색과 구매 업무를 맡기는 게 좋습니다.\n업무를 위해서는 부서가 필요하고, 부서는 시설을 필요로 합니다.\n월드맵에서 거점을 선택한 뒤 빈 슬롯에서 무기상 시설을 선택하고 건설 버튼을 누릅니다.",
    "en-US": "(obsolete) If you want to explore and purchase better items faster, it's better to leave your employees to explore and purchase them.\nFor work, you need a department, and the department needs facilities.\nSelect a base from the World Map, select Weapons Facility from an empty slot, and press the Construction button."
  },
  {
    "key": "loc_data_longtext_quest_description_quest_upgrade_base",
    "_item": "마스터데이터 - 퀘스트 설명(시설 업그레이드)",
    "_comment": "",
    "ko-KR": "회사 명성 티어가 상승하면 거점의 중심 시설을 업그레이드할 수 있게 됩니다. \n거점의 방탄사무실 시설을 사무용 벙커로 업그레이드하여 2티어 시설을 해금하세요.",
    "en-US": "When the company's reputation tier rises, it will be possible to upgrade the hub's central facilities. \nUpgrade the base's bulletproof office facility to an office bunker to release the Tier 2 facility."
  },
  {
    "key": "loc_data_longtext_quest_description_quest_first_perk",
    "_item": "마스터데이터 - 퀘스트 설명(스텝업)",
    "_comment": "",
    "ko-KR": "용병은 임무를 수행하며 충분한 경험치를 얻으면 레벨업하며 퍽 포인트를 획득합니다.\n획득한 퍽 포인트는 좌측 요원 메뉴에서 볼 수 있는 상세창 퍽 탭에서 소모하여 퍽을 습득할 수 있습니다.\n퍽을 조합함으로써 용병을 다양한 방향으로 강화, 특화시킬 수 있습니다.",
    "en-US": "The mercenaries perform their duties and when they get enough experience, they level up and earn puck points.\nThe acquired puck points can be consumed from the detailed window puck tab found in the left agent menu to acquire the puck.\nBy combining the pucks, mercenaries can be strengthened and specialized in various directions."
  },
  {
    "key": "loc_data_longtext_quest_description_quest_search_armor",
    "_item": "마스터데이터 - 퀘스트 설명(장비 탐색)",
    "_comment": "",
    "ko-KR": "(obsolete)무기상 시설은 무기상 부서를 제공하며, 직원을 배치해 업무를 지시할 수 있습니다. \n좌측의 조직 메뉴에서 부서에 직원들을 배치하고 업무를 지시할 수 있습니다. \n부서장만 있어도 업무가 가능하지만, 정원을 꽉 채우는 게 좋습니다. \n직원들은 암시장에 접촉해 총기, 방탄복, 수류탄 등 다양한 매물을 찾아내 보고할 것입니다. \n그렇게 찾은 매물 중 마음에 드는 것에 구매 업무를 지시, 완수하면 확보할 수 있습니다.\n\n부서 능력치는 부서장과 부서원 능력치의 가중 합으로서, 부서원 수가 많고 부서장 관리 능력치가 낮을수록 효율이 감소합니다.\n각 부서의 능력치는 관련 업무의 소요시간과 성공률, 성과에 영향을 줍니다.",
    "en-US": "(obsolete) Weapons facilities provide weapons department, and staff can be deployed to direct work. \nFrom the Organization menu on the left, you can assign employees to the department and direct them to work. \nYou can work with a department head, but it's better to have a full capacity. \nEmployees will contact the black market to find and report various sales such as guns, body armor, and grenades. \nYou can secure it by ordering and completing the purchase work on what you like among the sales you find.\n\nDepartment capabilities are the weighted sum of the department head and department member capabilities, and the higher the number of department members and the lower the department head management capabilities, the lower the efficiency.\nThe ability of each department affects the time required, success rate, and performance of related tasks."
  },
  {
    "key": "loc_data_longtext_quest_description_quest_full_metal_jacket",
    "_item": "마스터데이터 - 퀘스트 설명(안전은 용기의 아버지)",
    "_comment": "",
    "ko-KR": "(obsolete)방탄복은 부상 가능성을 크게 낮춰줍니다. 중상을 입을 상황도 경상에서 끝나게 해 주고요. \n모든 용병에게 방탄장비를 지급하는 건, 훌륭한 용병회사가 되기 위한 필수조건이라 할 수도 있습니다. \n인도주의적인 얘기는 아니고, 용병들이 멀쩡히 돌아와야 빨리 다음 임무에 보낼 거 아닙니까? \n아파서 누워 있으면 병원비만 나간다구요. ",
    "en-US": "(obsolete) Bulletproof clothing significantly reduces the chance of injury. The situation of serious injury also ends in minor injuries. \nGiving all mercenaries bulletproof equipment is a prerequisite for becoming a great mercenary company. \nIt's not humanitarian, but won't you send the mercenaries to the next mission as soon as they return safely? \nIf I lie down sick, I'll only pay for the hospital."
  },
  {
    "key": "loc_data_longtext_quest_description_quest_first_recruit",
    "_item": "마스터데이터 - 퀘스트 설명(영입)",
    "_comment": "",
    "ko-KR": "더 많은 용병을 보유하는 것은 회사 운영에 큰 도움이 됩니다. \n한 임무에 더 많은 용병을 보내 성공률을 높일 수도 있고, 동시에 더 많은 임무를 수행할 수도 있습니다. \n훈련, 치료나 휴식을 위한 여유도 생깁니다. \n좌측 영입 메뉴의 협상 가능한 용병 목록에서 원하는 용병과 고용 협상을 벌일 수 있습니다. \n용병마다 다른 계약 형태를 요구합니다. \n표준 계약은 안정적인 기본급 위주로 지급하는 형태입니다. \n인센티브 계약은 기본급은 낮지만 임무당 수당을 지급하며 임무 보상금의 일부를 배분합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_quest_description_quest_first_headhunter",
    "_item": "마스터데이터 - 퀘스트 설명(용병 탐색)",
    "_comment": "",
    "ko-KR": "중요 임무에 성공할 경우 회사 명성 티어가 상승합니다. 명성 2티어부터 부서 기능을 사용할 수 있습니다. \n그중 모병 부서에 업무를 지시하여 용병을 더 빠르게 탐색하고 영입할 수 있습니다. \n부서에 직원을 배치해 업무를 지시합니다. 성공 시 고용 가능한 용병이 영입 목록에 추가됩니다. \n부서의 탐색 능력치가 높을수록 더 좋은 용병을 찾거나 더 빨리 찾을 수 있고, \n부서의 협상 능력치가 높으면 더 좋은 조건으로 계약할 수 있습니다.",
    "en-US": "If you succeed in critical tasks, your company's reputation tier will rise. Department functions are available from the reputation tier 2. \nAmong them, you can instruct the recruitment department to explore and recruit mercenaries faster. \nWe assign staff to the department to direct the work. If successful, available mercenaries will be added to the Recruitment List. \nThe higher the search capability of the department, the better the mercenaries or the faster you can find them, \nIf the department's bargaining ability is high, we can sign a contract on better terms."
  },
  {
    "key": "loc_data_longtext_quest_description_quest_search_staff",
    "_item": "마스터데이터 - 퀘스트 설명(직원 탐색)",
    "_comment": "",
    "ko-KR": "부서는 회사 운영에 중요한 요소입니다. \n부서에 빈 자리를 채우고, 능률 떨어지는 직원을 교체해야 합니다. \n총무 부서에 업무를 지시함으로써 새 직원을 찾을 수 있습니다.\n좌측 조직 메뉴의 총무 탭에서 총무 부서를 관리할 수 있습니다.",
    "en-US": "The department is an important factor in the operation of the company. \nThe department needs to fill in the vacancies and replace the inefficient staff. \nYou can find a new employee by directing the general affairs department to work.\nYou can manage the general affairs department from the General Affairs tab of the left organization menu."
  },
  {
    "key": "loc_data_longtext_quest_description_quest_recruit_staff",
    "_item": "마스터데이터 - 퀘스트 설명(직원 고용)",
    "_comment": "",
    "ko-KR": "조직 메뉴의 직원 후보 목록에서 뽑고 싶은 직원 후보가 있으면 고용할 수 있습니다.\n총무 부서에서 고용을 진행할 수 있으며, 부서 능력치가 높을수록 성공률이 높습니다.",
    "en-US": "You can hire any employee candidates you want to pull from the list of employee candidates on the Organization menu.\nThe general affairs department can hire, and the higher the department's ability, the higher the success rate."
  },
  {
    "key": "loc_data_longtext_quest_description_quest_first_trainer",
    "_item": "마스터데이터 - 퀘스트 설명(훈련장)",
    "_comment": "",
    "ko-KR": "용병을 훈련하는 기능을 해금하기 위해 일반 훈련장 시설을 건설하세요.\n훈련장의 훈련 슬롯은 시간이 지남에 따라 관리도가 떨어지며, 관리도가 떨어지면 훈련 효율이 떨어집니다.\n훈련 부서에 훈련장 관리 업무를 지시하면 해당 훈련장에 속한 모든 슬롯의 관리도를 올릴 수 있습니다.\n좌측 조직 메뉴의 훈련 탭에서 훈련 부서를 관리할 수 있습니다.\n부서의 훈련 능력치가 높을수록 관리도가 오르는 양이 늘어납니다.",
    "en-US": "Build a regular training ground facility to dissuade the function of training mercenaries.\nTraining slots at the training site become less manageable over time, and less manageable if they become less manageable.\nIf you instruct the training department to manage the training site, you can raise the control chart for all slots in that training site.\nYou can manage the training department from the Training tab on the left organization menu.\nThe higher the training capacity of the department, the more the degree of management increases."
  },
  {
    "key": "loc_data_longtext_quest_description_quest_train",
    "_item": "마스터데이터 - 퀘스트 설명(용병 훈련)",
    "_comment": "",
    "ko-KR": "용병의 능력치는 훈련 감각이 높게 유지될 때 성장하고,\n훈련 감각이 낮으면 능력치가 성장하지 않거나 감소합니다.\n훈련에 용병을 편성해 놓으면, 훈련의 종류에 따라 서로 다른 능력치의 훈련 감각이 상승하며,\n여러 종류의 훈련을 동시에 편성할 수 있습니다.\n임무 수행 혹은 회복중인 용병은 훈련을 진행하지 않습니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_quest_description_quest_build_command1",
    "_item": "마스터데이터 - 퀘스트 설명(지휘통제실)",
    "_comment": "",
    "ko-KR": "한 거점에 배치할 수 있는 용병은 4명까지입니다. \n더 많은 요원을 배치, 운용하기 위해서는 지휘통제실 시설을 건설해야 합니다.\n월드맵에서 거점을 선택한 뒤 빈 슬롯에서 지휘통제실 시설을 선택하고 업그레이드 버튼을 누릅니다.",
    "en-US": "Up to four mercenaries can be deployed in one base. \nTo deploy and operate more agents, we need to build command and control room facilities.\nSelect a base on the World Map, select Command and Control Room Facility in an empty slot, and press the Upgrade button."
  },
  {
    "key": "loc_data_longtext_quest_description_quest_clear_district",
    "_item": "마스터데이터 - 퀘스트 설명(질서 회복)",
    "_comment": "",
    "ko-KR": "(obsolete)임무를 성공시키면 의뢰한 세력의 힘이 강해지고 타겟이 된 세력의 힘이 약해집니다.\n약해진 세력은 지역에서 축출당하며, 범죄자 세력은 축출당하진 않지만 약화시킬 수 있습니다.\n지역 내에 범죄자가 아닌 세력이 하나뿐이며 범죄자보다 우세할 경우 그 지역은 안정됩니다. \n\n지배세력 아래에서 질서가 회복되므로 임무는 잘 발생하지 않습니다. \n하지만 전란 속 폐업했던 민간 시설에 투자해 재개장하고 회사에 이로운 효과를 받을 수 있습니다.\n또한 안정된 지역에는 새로운 거점을 설치해 새로운 활동 기지로 삼을 수 있습니다.",
    "en-US": "(obsolete) Success in the mission strengthens the power of the commissioned force and weakens the power of the targeted force.\nWeakened forces are expelled from the region, and criminal forces are not expelled, but they can weaken.\nIf there is only one non-criminal force in the area and it is superior to the criminal, the area is stable. \n\nSince order is restored under the ruling power, missions do not occur well. \nHowever, you can invest in private facilities that were closed during the war to reopen and benefit the company.\nIn addition, new bases can be set up in stable areas to serve as new bases for activities."
  },
  {
    "key": "loc_data_longtext_quest_description_quest_build_safehouse",
    "_item": "마스터데이터 - 퀘스트 설명(영역 확장)",
    "_comment": "",
    "ko-KR": "(obsolete)지역이 안정됐다는 것은 우리 돈줄이 말라간다는 뜻입니다. 분쟁과 현금이 가득한 새로운 지역으로 진출해야 합니다.\n새로운 지역의 활동 영역에 편입하려면 그 지역에 우리 소유의 안전가옥이 있어야 합니다. \n안전가옥은 용병들이 작전을 준비하고, 작전 후 응급처치하고, 위기에 처했을 때 대피하는 장소입니다.\n\n월드맵에서 활동 영역과 인접한 지역을 선택하고 시설 확보: safehouse 버튼을 눌러 안전가옥을 확보합니다.\n단, 활동 영역에 인접한 지역의 안전가옥만 확보할 수 있습니다. \n또한 지역의 티어가 회사의 명성 티어보다 높으면 안전가옥을 확보할 수 없습니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_quest_description_quest_tier_2",
    "_item": "마스터데이터 - 퀘스트 설명(기지 증축)",
    "_comment": "",
    "ko-KR": "(obsolete)[미완성 퀘스트입니다. 웬만하면 다음 이터레이션에 구현 예정입니다.]\n\n임무를 성공시킬 때마다 명성 수치가 상승하며, 최대치에 도달하면 마일스톤 임무를 수행할 수 있게 됩니다.\n마일스톤 임무는 일반 임무에 비해 어렵지만 커다란 보상이 준비돼 있으며, 성공시 회사 명성 티어가 상승합니다. \n회사 명성 티어가 상승하면 더 높은 티어의 지역에 진출할 수 있고, 시설을 더 높은 티어로 업그레이드할 수 있습니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_quest_description_quest_build_office",
    "_item": "마스터데이터 - 퀘스트 설명(전진 기지)",
    "_comment": "",
    "ko-KR": "(obsolete)임무를 수행하는 장소와 거점 간의 거리가 너무 멀어지면 손해를 보게 됩니다. \n또한, 거점마다 운용할 수 있는 용병이나 시설의 수에 한계가 있습니다. \n따라서 회사를 성장시키려면 새로운 거점을 설치해야 하는 때가 옵니다. \n안정된 지역에 시설 확보: office 버튼을 눌러 새 거점을 설치합니다.",
    "en-US": "(obsolete) If the distance between the place of the mission and the base is too far, you lose money. \nIn addition, there is a limit to the number of mercenaries or facilities that can be operated in each base. \nSo there comes a time when you need to set up a new base to grow your company. \nSecure facilities in a stable area: Press the office button to install a new base."
  },
  {
    "key": "loc_data_longtext_quest_title_quest_demo_00",
    "_item": "마스터데이터 - 퀘스트 설명(창업)",
    "_comment": "",
    "ko-KR": "회사 모양을 갖추기는 했지만, 아직 제 일을 맡길 수 있는 상태는 아니군요.\n제 일을 시작할 수 있는 최소한의 준비가 되면 다시 연락하겠습니다.\n당신이 일을 시작할 수 있도록 하기 위해 꽤 많은 노력이 들어갔습니다. 투자한 만큼의 성과가 있기를 바랍니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_quest_title_quest_demo_01",
    "_item": "마스터데이터 - 퀘스트 설명(첫 번째 숙제)",
    "_comment": "",
    "ko-KR": "이전보다는 나아진 모양새네요.\n첫 번째 숙제를 드리겠습니다. 아직 큰 문제는 아니지만, 평소에 눈에 밟히던 놈들입니다.\n얼마나 깔끔하게 처리하는지 지켜보겠습니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_quest_title_quest_demo_02",
    "_item": "마스터데이터 - 퀘스트 설명(준비 (1))",
    "_comment": "",
    "ko-KR": "다음 숙제를 드리고 싶은데, 준비가 더 필요해 보이네요.\n용병 둘을 준비하세요. 실력은 신경쓰지 않지만, 눈 먼 총알에 죽지 않을 정도는 되어야 합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_quest_title_quest_demo_03",
    "_item": "마스터데이터 - 퀘스트 설명(준비 (2))",
    "_comment": "",
    "ko-KR": "아주 작은 변수도 전투의 흐름을 뒤집을 수 있다는 것을, 당신은 알 겁니다. 수류탄처럼 작은 물건도 말이지요. \n그걸 다룰 수 있다면 이번 숙제를 더 수월하게 처리할 수 있을 겁니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_quest_title_quest_demo_04",
    "_item": "마스터데이터 - 퀘스트 설명(두 번째 숙제)",
    "_comment": "",
    "ko-KR": "준비가 다 된 것 같군요. \n두 번째 숙제입니다. 이 지역에 우리가 그리고 있는 그림에 얼룩이 있습니다. \n단단히 무장했고, 용병도 좀 고용했군요. 당신이 그 얼룩을 지우면 좋겠습니다. \n신속히 처리하길 바랍니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_string_staffability_name_search",
    "_item": "마스터데이터 - 직원 능력 이름(탐색)",
    "_comment": "",
    "ko-KR": "탐색",
    "en-US": "Search"
  },
  {
    "key": "loc_data_string_staffability_name_negotiate",
    "_item": "마스터데이터 - 직원 능력 이름(협상)",
    "_comment": "",
    "ko-KR": "협상",
    "en-US": "Negotiation"
  },
  {
    "key": "loc_data_string_staffability_name_bargain",
    "_item": "마스터데이터 - 직원 능력 이름(흥정)",
    "_comment": "",
    "ko-KR": "흥정",
    "en-US": "Bargaining"
  },
  {
    "key": "loc_data_string_staffability_name_train",
    "_item": "마스터데이터 - 직원 능력 이름(훈련)",
    "_comment": "",
    "ko-KR": "훈련",
    "en-US": "Training"
  },
  {
    "key": "loc_data_string_staffability_name_manage",
    "_item": "마스터데이터 - 직원 능력 이름(관리)",
    "_comment": "",
    "ko-KR": "관리",
    "en-US": "Management"
  },
  {
    "key": "loc_data_longtext_staffability_desc_search",
    "_item": "마스터데이터 - 직원 능력 설명(탐색)",
    "_comment": "",
    "ko-KR": "탐색은 시장에서 용병, 무기, 의뢰를 발굴할 때 쓰이는 능력입니다.\n* 신규 용병 탐색\n* 신규 직원 탐색\n* 총기, 방탄장비, 수류탄 조달\n* 의뢰 탐색",
    "en-US": "Navigation is the ability to discover mercenaries, weapons, and commissions in the market.\n* Search for new mercenaries\n* Explore new employees\n* Guns, bulletproof equipment, grenades procurement\n* Search for a referral"
  },
  {
    "key": "loc_data_longtext_staffability_desc_negotiate",
    "_item": "마스터데이터 - 직원 능력 설명(협상)",
    "_comment": "",
    "ko-KR": "협상은 노동계약과 업무 계약을 유리하게 끌고갈 때 쓰이는 능력입니다.\n* 신규 용병 계약\n* 신규 직원 계약\n* 의뢰 탐색(계약 금액에 영향)",
    "en-US": "Negotiation is the ability to take advantage of labor contracts and work contracts.\n* a new mercenary contract\n* a new employee contract\n* Search for referral (impact on contract amount)"
  },
  {
    "key": "loc_data_longtext_staffability_desc_bargain",
    "_item": "마스터데이터 - 직원 능력 설명(흥정)",
    "_comment": "",
    "ko-KR": "흥정은 물품을 구매하거나 판매할 때의 금액에 영향을 주는 능력입니다.\n* 총기, 방탄장비, 수류탄 구매\n* 총기, 방탄장비, 수류탄 판매",
    "en-US": "Bargaining is the ability to influence the amount of money you buy or sell an item.\n* Firearms, bulletproof equipment, grenades\n* Guns, bulletproof equipment, grenades sold"
  },
  {
    "key": "loc_data_longtext_staffability_desc_train",
    "_item": "마스터데이터 - 직원 능력 설명(훈련)",
    "_comment": "",
    "ko-KR": "훈련은 용병을 훈련시킬 때의 효율에 영향을 주는 능력입니다.\n* 훈련",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_staffability_desc_manage",
    "_item": "마스터데이터 - 직원 능력 설명(관리)",
    "_comment": "",
    "ko-KR": "관리는 부서장이 되었을 때 부서원의 능력치가 부서 능력치에 반영되게 돕는 능력입니다.\n부서원의 능력치는 부서장의 관리 능력이 높을수록 부서 능력치에 잘 반영됩니다.",
    "en-US": "Management is the ability to help a department member's ability to be reflected in the department's ability when he or she becomes a department head.\nThe higher the management ability of the head of the department, the better the ability of the department member is reflected in the ability of the department."
  },
  {
    "key": "loc_data_longtext_statsdescr_descr_decision",
    "_item": "마스터데이터 - 능력치 설명(판단력)",
    "_comment": "",
    "ko-KR": "- 좋은 판단 능력으로 어느 방향으로, 어떤 타이밍에 사격해야할지 알게 됩니다.\n- 더 정밀하게 사격하는 것과 관련 되어 있습니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_statsdescr_descr_bravery",
    "_item": "마스터데이터 - 능력치 설명(용감함)",
    "_comment": "",
    "ko-KR": "- 예상하지 못한 순간이나, 위기에 처해있을 때 당황하지 않고 얼마나 용감하게 대응하는지를 나타냅니다.",
    "en-US": "- It indicates how brave you are to respond to unexpected moments or crises without panicking."
  },
  {
    "key": "loc_data_longtext_statsdescr_descr_focus",
    "_item": "마스터데이터 - 능력치 설명(집중력)",
    "_comment": "",
    "ko-KR": "- 상황에 몰입하여 적을 제압하는 것에 더 뛰어난 능력을 발휘하게 됩니다.\n- 연속으로 사격할 때의 집탄률이 향상됩니다.",
    "en-US": "- You will become more capable of immersing yourself in situations and overpowering your enemies.\n- Increases the coalescence rate when shooting consecutively."
  },
  {
    "key": "loc_data_longtext_statsdescr_descr_reaction",
    "_item": "마스터데이터 - 능력치 설명(반응속도)",
    "_comment": "",
    "ko-KR": "- 적보다 더 빠르게 반응하여 상황을 유리하게 이끌어갑니다.",
    "en-US": "- It responds faster than the enemy, leading the situation to its advantage."
  },
  {
    "key": "loc_data_longtext_statsdescr_descr_toughness",
    "_item": "마스터데이터 - 능력치 설명(강인함)",
    "_comment": "",
    "ko-KR": "- 빠른 체력 회복과 관련 되어 있습니다.",
    "en-US": "- It's related to a quick recovery of physical strength."
  },
  {
    "key": "loc_data_longtext_statsdescr_descr_precision",
    "_item": "마스터데이터 - 능력치 설명(정교함)",
    "_comment": "",
    "ko-KR": "- 적의 약점을 살피고, 더 치명적인 공격을 할 수 있게 됩니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_statsdescr_descr_effect_decision",
    "_item": "마스터데이터 - 능력치 효과 설명(판단력)",
    "_comment": "",
    "ko-KR": "- 조준각이 스탯 당 2.5% 개선.",
    "en-US": "- Improved angle of sight by 2.5% per stat."
  },
  {
    "key": "loc_data_longtext_statsdescr_descr_effect_bravery",
    "_item": "마스터데이터 - 능력치 효과 설명(용감함)",
    "_comment": "",
    "ko-KR": "- 피격 당했을 때의 조준각 흔들림이 스탯 당 2.5% 감소.",
    "en-US": "- Shake of the angle of sight when attacked by 2.5% per stroke."
  },
  {
    "key": "loc_data_longtext_statsdescr_descr_effect_focus",
    "_item": "마스터데이터 - 능력치 효과 설명(집중력)",
    "_comment": "",
    "ko-KR": "- 연사시 집탄률이 스탯 당 2.5% 개선.",
    "en-US": "- Increased coalescence by 2.5% per stat during continuous performance."
  },
  {
    "key": "loc_data_longtext_statsdescr_descr_effect_reaction",
    "_item": "마스터데이터 - 능력치 효과 설명(반응속도)",
    "_comment": "",
    "ko-KR": "- 회피율이 0.5%씩 증가.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_statsdescr_descr_effect_toughness",
    "_item": "마스터데이터 - 능력치 효과 설명(강인함)",
    "_comment": "",
    "ko-KR": "- 회복 효율이 1.5%씩 증가.\n- 전투력 패널티가 1%씩 감소.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_statsdescr_descr_effect_precision",
    "_item": "마스터데이터 - 능력치 효과 설명(정교함)",
    "_comment": "",
    "ko-KR": "- 치명상을 입힐 확률이 스탯 당 0.2% 포인트 증가.",
    "en-US": "- Increases your chance of dealing fatal injuries by 0.2 percentage points per stat."
  },
  {
    "key": "loc_data_string_training_name_all",
    "_item": "마스터데이터 - 훈련 이름(종합 훈련)",
    "_comment": "",
    "ko-KR": "종합 훈련",
    "en-US": "General Training"
  },
  {
    "key": "loc_data_string_training_name_leadership",
    "_item": "마스터데이터 - 훈련 이름(리더십)",
    "_comment": "",
    "ko-KR": "리더십",
    "en-US": "Leadership"
  },
  {
    "key": "loc_data_string_training_name_climbing",
    "_item": "마스터데이터 - 훈련 이름(암벽 등반)",
    "_comment": "",
    "ko-KR": "암벽 등반",
    "en-US": "Climbing"
  },
  {
    "key": "loc_data_string_training_name_shooting",
    "_item": "마스터데이터 - 훈련 이름(사격)",
    "_comment": "",
    "ko-KR": "사격",
    "en-US": "Shooting"
  },
  {
    "key": "loc_data_string_training_name_reaction_drills",
    "_item": "마스터데이터 - 훈련 이름(반응속도 훈련)",
    "_comment": "",
    "ko-KR": "반응속도 훈련",
    "en-US": "Reaction Drills"
  },
  {
    "key": "loc_data_string_training_name_pull_ups",
    "_item": "마스터데이터 - 훈련 이름(턱걸이)",
    "_comment": "",
    "ko-KR": "턱걸이",
    "en-US": "Pull-ups"
  },
  {
    "key": "loc_data_string_training_name_agility_circuits",
    "_item": "마스터데이터 - 훈련 이름(민첩성 서킷)",
    "_comment": "",
    "ko-KR": "민첩성 서킷",
    "en-US": "Agility Circuits"
  },
  {
    "key": "loc_data_string_traits_name_trait_1_hawkeye",
    "_item": "마스터데이터 - 특성 이름(매의 눈)",
    "_comment": "",
    "ko-KR": "매의 눈",
    "en-US": "Hawkeye"
  },
  {
    "key": "loc_data_string_traits_name_trait_2_kind",
    "_item": "마스터데이터 - 특성 이름(선량함)",
    "_comment": "",
    "ko-KR": "선량함",
    "en-US": "Kind"
  },
  {
    "key": "loc_data_string_traits_name_trait_3_rash",
    "_item": "마스터데이터 - 특성 이름(성급함)",
    "_comment": "",
    "ko-KR": "성급함",
    "en-US": "Rash"
  },
  {
    "key": "loc_data_string_traits_name_trait_4_pessimist",
    "_item": "마스터데이터 - 특성 이름(비관주의)",
    "_comment": "",
    "ko-KR": "비관주의",
    "en-US": "Pessimist"
  },
  {
    "key": "loc_data_string_traits_name_trait_5_optimist",
    "_item": "마스터데이터 - 특성 이름(낙관주의)",
    "_comment": "",
    "ko-KR": "낙관주의",
    "en-US": "Optimist"
  },
  {
    "key": "loc_data_string_traits_name_trait_6_loner",
    "_item": "마스터데이터 - 특성 이름(외톨이)",
    "_comment": "",
    "ko-KR": "외톨이",
    "en-US": "Loner"
  },
  {
    "key": "loc_data_string_traits_name_trait_7_cautious",
    "_item": "마스터데이터 - 특성 이름(신중함)",
    "_comment": "",
    "ko-KR": "신중함",
    "en-US": "Cautious"
  },
  {
    "key": "loc_data_string_traits_name_trait_8_agile",
    "_item": "마스터데이터 - 특성 이름(날렵함)",
    "_comment": "",
    "ko-KR": "날렵함",
    "en-US": "Agile"
  },
  {
    "key": "loc_data_string_traits_name_trait_9_impulsive",
    "_item": "마스터데이터 - 특성 이름(충동)",
    "_comment": "",
    "ko-KR": "충동",
    "en-US": "Impulsive"
  },
  {
    "key": "loc_data_string_traits_name_trait_10_intuitive",
    "_item": "마스터데이터 - 특성 이름(직감)",
    "_comment": "",
    "ko-KR": "직감",
    "en-US": "Intuitive"
  },
  {
    "key": "loc_data_string_traits_name_trait_11_resilient",
    "_item": "마스터데이터 - 특성 이름(회복력)",
    "_comment": "",
    "ko-KR": "회복력",
    "en-US": "Resilient"
  },
  {
    "key": "loc_data_string_traits_name_trait_12_gregarious",
    "_item": "마스터데이터 - 특성 이름(사교성)",
    "_comment": "",
    "ko-KR": "사교성",
    "en-US": "Gregarious"
  },
  {
    "key": "loc_data_string_traits_name_trait_13_skeptical",
    "_item": "마스터데이터 - 특성 이름(회의주의)",
    "_comment": "",
    "ko-KR": "회의주의",
    "en-US": "Skeptical"
  },
  {
    "key": "loc_data_string_traits_name_trait_14_adaptive",
    "_item": "마스터데이터 - 특성 이름(적응력)",
    "_comment": "",
    "ko-KR": "적응력",
    "en-US": "Adaptive"
  },
  {
    "key": "loc_data_string_traits_name_trait_15_observing",
    "_item": "마스터데이터 - 특성 이름(관찰력)",
    "_comment": "",
    "ko-KR": "관찰력",
    "en-US": "Observing"
  },
  {
    "key": "loc_data_string_traits_name_trait_16_cunning",
    "_item": "마스터데이터 - 특성 이름(교활함)",
    "_comment": "",
    "ko-KR": "교활함",
    "en-US": "Cunning"
  },
  {
    "key": "loc_data_string_traits_name_trait_17_tactical",
    "_item": "마스터데이터 - 특성 이름(전술가)",
    "_comment": "",
    "ko-KR": "전술가",
    "en-US": "Tactical"
  },
  {
    "key": "loc_data_string_traits_name_trait_18_loyal",
    "_item": "마스터데이터 - 특성 이름(충성심)",
    "_comment": "",
    "ko-KR": "충성심",
    "en-US": "Loyal"
  },
  {
    "key": "loc_data_string_traits_name_trait_19_stoic",
    "_item": "마스터데이터 - 특성 이름(자제력)",
    "_comment": "",
    "ko-KR": "자제력",
    "en-US": "Stoic"
  },
  {
    "key": "loc_data_string_traits_name_trait_20_free",
    "_item": "마스터데이터 - 특성 이름(자유로운 영혼)",
    "_comment": "",
    "ko-KR": "자유로운 영혼",
    "en-US": "Free"
  },
  {
    "key": "loc_data_string_traits_name_trait_21_idealist",
    "_item": "마스터데이터 - 특성 이름(이상주의)",
    "_comment": "",
    "ko-KR": "이상주의",
    "en-US": "Idealist"
  },
  {
    "key": "loc_data_string_traits_name_trait_22_philosophical",
    "_item": "마스터데이터 - 특성 이름(철학)",
    "_comment": "",
    "ko-KR": "철학적",
    "en-US": "Philosophical"
  },
  {
    "key": "loc_data_string_traits_name_trait_23_ironwill",
    "_item": "마스터데이터 - 특성 이름(철의 의지)",
    "_comment": "",
    "ko-KR": "철의 의지",
    "en-US": "Iron Will"
  },
  {
    "key": "loc_data_string_traits_name_trait_24_depressive",
    "_item": "마스터데이터 - 특성 이름(우울함)",
    "_comment": "",
    "ko-KR": "우울함",
    "en-US": "Depressive"
  },
  {
    "key": "loc_data_string_traits_name_trait_25_charismatic",
    "_item": "마스터데이터 - 특성 이름(카리스마)",
    "_comment": "",
    "ko-KR": "카리스마",
    "en-US": "Charismatic"
  },
  {
    "key": "loc_data_string_traits_name_trait_26_pacifist",
    "_item": "마스터데이터 - 특성 이름(평화주의)",
    "_comment": "",
    "ko-KR": "평화주의",
    "en-US": "Pacifist"
  },
  {
    "key": "loc_data_string_traits_name_trait_27_pokerface",
    "_item": "마스터데이터 - 특성 이름(포커페이스)",
    "_comment": "",
    "ko-KR": "포커페이스",
    "en-US": "Pokerface"
  },
  {
    "key": "loc_data_string_traits_name_trait_28_inventive",
    "_item": "마스터데이터 - 특성 이름(발명가)",
    "_comment": "",
    "ko-KR": "발명가",
    "en-US": "Inventive"
  },
  {
    "key": "loc_data_string_traits_name_trait_29_strong",
    "_item": "마스터데이터 - 특성 이름(튼튼함)",
    "_comment": "",
    "ko-KR": "튼튼함",
    "en-US": "Strong"
  },
  {
    "key": "loc_data_string_traits_name_trait_30_perfectionist",
    "_item": "마스터데이터 - 특성 이름(완벽주의)",
    "_comment": "",
    "ko-KR": "완벽주의",
    "en-US": "Perfectionist"
  },
  {
    "key": "loc_data_string_traits_name_trait_31_improviser",
    "_item": "마스터데이터 - 특성 이름(임기응변)",
    "_comment": "",
    "ko-KR": "임기응변",
    "en-US": "Improviser"
  },
  {
    "key": "loc_data_string_traits_name_trait_32_brave",
    "_item": "마스터데이터 - 특성 이름(용맹)",
    "_comment": "",
    "ko-KR": "용맹",
    "en-US": "Brave"
  },
  {
    "key": "loc_data_string_traits_name_trait_33_ambitious",
    "_item": "마스터데이터 - 특성 이름(야심가)",
    "_comment": "",
    "ko-KR": "야심가",
    "en-US": "Ambitious"
  },
  {
    "key": "loc_data_string_traits_name_trait_34_conservative",
    "_item": "마스터데이터 - 특성 이름(보수주의)",
    "_comment": "",
    "ko-KR": "보수주의",
    "en-US": "Conservative"
  },
  {
    "key": "loc_data_string_traits_name_trait_35_rhetoric",
    "_item": "마스터데이터 - 특성 이름(달변가)",
    "_comment": "",
    "ko-KR": "달변가",
    "en-US": "Rhetoric"
  },
  {
    "key": "loc_data_string_traits_name_trait_36_revengeful",
    "_item": "마스터데이터 - 특성 이름(복수심)",
    "_comment": "",
    "ko-KR": "복수심",
    "en-US": "Revengeful"
  },
  {
    "key": "loc_data_string_traits_name_trait_37_romantic",
    "_item": "마스터데이터 - 특성 이름(낭만)",
    "_comment": "",
    "ko-KR": "낭만",
    "en-US": "Romantic"
  },
  {
    "key": "loc_data_string_traits_name_trait_38_paranoid",
    "_item": "마스터데이터 - 특성 이름(편집증)",
    "_comment": "",
    "ko-KR": "편집증",
    "en-US": "Paranoid"
  },
  {
    "key": "loc_data_string_traits_name_trait_39_merciless",
    "_item": "마스터데이터 - 특성 이름(무자비)",
    "_comment": "",
    "ko-KR": "무자비",
    "en-US": "Merciless"
  },
  {
    "key": "loc_data_string_traits_name_trait_40_humble",
    "_item": "마스터데이터 - 특성 이름(겸손)",
    "_comment": "",
    "ko-KR": "겸손",
    "en-US": "Humble"
  },
  {
    "key": "loc_data_string_traits_name_trait_41_shortsight",
    "_item": "마스터데이터 - 특성 이름(근시)",
    "_comment": "",
    "ko-KR": "근시",
    "en-US": "Shortsight"
  },
  {
    "key": "loc_data_string_traits_name_trait_42_proud",
    "_item": "마스터데이터 - 특성 이름(자부심)",
    "_comment": "",
    "ko-KR": "자부심",
    "en-US": "Proud"
  },
  {
    "key": "loc_data_string_traits_name_trait_43_pragmatic",
    "_item": "마스터데이터 - 특성 이름(냉철함)",
    "_comment": "",
    "ko-KR": "냉철함",
    "en-US": "Pragmatic"
  },
  {
    "key": "loc_data_string_traits_name_trait_44_openmind",
    "_item": "마스터데이터 - 특성 이름(열린 마음)",
    "_comment": "",
    "ko-KR": "열린 마음",
    "en-US": "Open Minded"
  },
  {
    "key": "loc_data_string_traits_name_trait_45_ridiculous",
    "_item": "마스터데이터 - 특성 이름(우스꽝스러움)",
    "_comment": "",
    "ko-KR": "우스꽝스러움",
    "en-US": "Ridiculous"
  },
  {
    "key": "loc_data_string_traits_name_trait_46_mumbling",
    "_item": "마스터데이터 - 특성 이름(웅얼거림)",
    "_comment": "",
    "ko-KR": "웅얼거림",
    "en-US": "Mumbling"
  },
  {
    "key": "loc_data_string_traits_name_trait_47_weak",
    "_item": "마스터데이터 - 특성 이름(허약함)",
    "_comment": "",
    "ko-KR": "허약함",
    "en-US": "Weak"
  },
  {
    "key": "loc_data_string_traits_name_trait_48_unfocused",
    "_item": "마스터데이터 - 특성 이름(산만함)",
    "_comment": "",
    "ko-KR": "산만함",
    "en-US": "Unfocused"
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_1_hawkeye",
    "_item": "마스터데이터 - 특성 설명(매의 눈)",
    "_comment": "",
    "ko-KR": "뛰어난 시력을 가지고 있어 적을 더 쉽게 발견하거나 작은 디테일까지 알아챌 수 있습니다.",
    "en-US": "It has excellent eyesight, making it easier to spot enemies or even notice small details."
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_2_kind",
    "_item": "마스터데이터 - 특성 설명(선량함)",
    "_comment": "",
    "ko-KR": "뚜렷한 도덕관을 갖고 행동합니다. 투철한 정신력을 가졌지만 적에게 연민을 느끼기도 합니다.",
    "en-US": "They act with a clear sense of morality. He has a strong mentality, but he also feels compassion for his enemies."
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_3_rash",
    "_item": "마스터데이터 - 특성 설명(성급함)",
    "_comment": "",
    "ko-KR": "먼저 행동하고 나중에 생각합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_4_pessimist",
    "_item": "마스터데이터 - 특성 설명(비관주의)",
    "_comment": "",
    "ko-KR": "항상 최악의 상황을 예상합니다. 신중하게 행동하지만 쉽게 포기합니다.",
    "en-US": "We always expect the worst. They act carefully, but they give up easily."
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_5_optimist",
    "_item": "마스터데이터 - 특성 설명(낙관주의)",
    "_comment": "",
    "ko-KR": "항상 긍정적인 태도를 유지하지만 조심성이 부족합니다.",
    "en-US": "Always keep a positive attitude, but lack caution."
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_6_loner",
    "_item": "마스터데이터 - 특성 설명(외톨이)",
    "_comment": "",
    "ko-KR": "혼자 일하는 것을 선호합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_7_cautious",
    "_item": "마스터데이터 - 특성 설명(신중함)",
    "_comment": "",
    "ko-KR": "잠재적인 위험이나 실수를 피하기 위해 주의를 기울입니다. 스스로 확신을 잘 갖지 못해 돌발상황에 느리게 반응합니다.",
    "en-US": "Take care to avoid any potential dangers or mistakes. I'm not sure about myself, so I respond slowly to unexpected situations."
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_8_agile",
    "_item": "마스터데이터 - 특성 설명(날렵함)",
    "_comment": "",
    "ko-KR": "몸이 빠르고 민첩합니다.",
    "en-US": "My body is quick and nimble."
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_9_impulsive",
    "_item": "마스터데이터 - 특성 설명(충동)",
    "_comment": "",
    "ko-KR": "감정에 따라 행동하는 경우가 많습니다. 이성적인 판단을 내리기 힘들고 일에 집중하지 못합니다.",
    "en-US": "They often act on their emotions. It's hard to make rational judgments and you can't focus on your work."
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_10_intuitive",
    "_item": "마스터데이터 - 특성 설명(직감)",
    "_comment": "",
    "ko-KR": "날카로운 직감 덕분에 상황 변화에 빠르게 반응합니다.",
    "en-US": "Thanks to a sharp intuition, they respond quickly to changes in the situation."
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_11_resilient",
    "_item": "마스터데이터 - 특성 설명(회복력)",
    "_comment": "",
    "ko-KR": "부상과 스트레스로부터 빠르게 회복할 수 있습니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_12_gregarious",
    "_item": "마스터데이터 - 특성 설명(사교성)",
    "_comment": "",
    "ko-KR": "밝은 성격과 말솜씨로 주변 사람을 기분 좋게 만드는 능력이 있습니다.",
    "en-US": "He has the ability to make people around him feel good with his bright personality and speaking ability."
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_13_skeptical",
    "_item": "마스터데이터 - 특성 설명(회의주의)",
    "_comment": "",
    "ko-KR": "모든 것에 의문을 품고 쉽게 납득하지 않습니다.",
    "en-US": "I have doubts about everything and I don't easily understand it."
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_14_adaptive",
    "_item": "마스터데이터 - 특성 설명(적응력)",
    "_comment": "",
    "ko-KR": "새로운 환경이나 상황에 빠르게 적응합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_15_observing",
    "_item": "마스터데이터 - 특성 설명(관찰력)",
    "_comment": "",
    "ko-KR": "주위 환경이나 사람을 관찰해 정보를 입수하는 실력이 뛰어납니다.",
    "en-US": "You have excellent ability to obtain information by observing your surroundings or people."
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_16_cunning",
    "_item": "마스터데이터 - 특성 설명(교활함)",
    "_comment": "",
    "ko-KR": "속임수와 조작의 달인입니다. 스스로에게 이득이 되는 방향이 무엇인지 정확히 판단합니다.",
    "en-US": "They are masters of deception and manipulation. Determine exactly what direction is beneficial to you."
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_17_tactical",
    "_item": "마스터데이터 - 특성 설명(전술가)",
    "_comment": "",
    "ko-KR": "전술 이해도가 높아 복잡한 계획을 세우고 즉석에서 조정할 수 있습니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_18_loyal",
    "_item": "마스터데이터 - 특성 설명(충성심)",
    "_comment": "",
    "ko-KR": "팀과 대의에 대한 충성심이 깊습니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_19_stoic",
    "_item": "마스터데이터 - 특성 설명(자제력)",
    "_comment": "",
    "ko-KR": "웬만한 욕구나 감정변화에는 흔들리지 않습니다. 스트레스가 심한 상황에서도 침착하고 평정심을 유지합니다.",
    "en-US": "I am not swayed by any kind of desire or emotional change. Keep calm and calm even in stressful situations."
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_20_free",
    "_item": "마스터데이터 - 특성 설명(자유로운 영혼)",
    "_comment": "",
    "ko-KR": "어딘가 소속되는 것을 꺼리며 방랑벽이 있습니다.",
    "en-US": "There is a wandering wall, reluctant to belong somewhere."
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_21_idealist",
    "_item": "마스터데이터 - 특성 설명(이상주의)",
    "_comment": "",
    "ko-KR": "삶과 행동에 대한 명확한 비전을 가지고 있으며, 그 비전을 위해 현실을 무시하기도 합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_22_philosophical",
    "_item": "마스터데이터 - 특성 설명(철학)",
    "_comment": "",
    "ko-KR": "삶과 전쟁의 본질에 대해 자주 고민합니다. 투철한 의지를 갖고 행동하지만 임무 중에도 사색에 잠길 때가 있습니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_23_ironwill",
    "_item": "마스터데이터 - 특성 설명(철의 의지)",
    "_comment": "",
    "ko-KR": "어떤 상황에서도 절대 포기하지 않습니다. 그 어떤 난관도 두려워하지 않습니다.",
    "en-US": "I never give up under any circumstances. I'm not afraid of any difficulties."
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_24_depressive",
    "_item": "마스터데이터 - 특성 설명(우울함)",
    "_comment": "",
    "ko-KR": "슬픈 과거사를 가졌습니다. 항상 우울한 상태라 삶의 의지가 약합니다.",
    "en-US": "I have a sad history. I'm always depressed, so I have a weak will to live."
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_25_charismatic",
    "_item": "마스터데이터 - 특성 설명(카리스마)",
    "_comment": "",
    "ko-KR": "존경과 공포를 불러일으키는 존재감을 발산합니다.",
    "en-US": "It gives off a presence that evokes respect and fear."
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_26_pacifist",
    "_item": "마스터데이터 - 특성 설명(평화주의)",
    "_comment": "",
    "ko-KR": "폭력과 살인에 염증을 느끼며 불필요한 싸움을 피합니다.",
    "en-US": "Sick of violence and murder and avoid unnecessary fights."
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_27_pokerface",
    "_item": "마스터데이터 - 특성 설명(포커페이스)",
    "_comment": "",
    "ko-KR": "감정을 숨기는 데 능숙하며 언제나 냉철한 집중력을 유지합니다.",
    "en-US": "They are good at hiding their emotions and always maintain a cool-headed concentration."
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_28_inventive",
    "_item": "마스터데이터 - 특성 설명(발명가)",
    "_comment": "",
    "ko-KR": "문제에 대한 창의적인 해결책을 잘 생각해냅니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_29_strong",
    "_item": "마스터데이터 - 특성 설명(튼튼함)",
    "_comment": "",
    "ko-KR": "유별나게 강한 육체를 가져 부상과 피로에 더 잘 견딥니다.",
    "en-US": "It has an exceptionally strong body, so it is more resistant to injury and fatigue."
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_30_perfectionist",
    "_item": "마스터데이터 - 특성 설명(완벽주의)",
    "_comment": "",
    "ko-KR": "스스로 매우 높은 기준을 설정하고 세세한 부분까지 완벽을 추구합니다.",
    "en-US": "They set very high standards on their own and pursue perfection in detail."
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_31_improviser",
    "_item": "마스터데이터 - 특성 설명(임기응변)",
    "_comment": "",
    "ko-KR": "예상 못한 상황에서도 당장 주어진 자원을 활용하는 일에 능숙합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_32_brave",
    "_item": "마스터데이터 - 특성 설명(용맹)",
    "_comment": "",
    "ko-KR": "목표를 이루기 위해서라면 위험을 감수하는 것도 주저하지 않습니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_33_ambitious",
    "_item": "마스터데이터 - 특성 설명(야심가)",
    "_comment": "",
    "ko-KR": "큰 꿈을 품고 그 실현을 위해 노력합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_34_conservative",
    "_item": "마스터데이터 - 특성 설명(보수주의)",
    "_comment": "",
    "ko-KR": "어떤 일을 하든 새로운 방법보다 검증된 방법을 선호합니다. 자신의 결정에 확고한 믿음을 가집니다.",
    "en-US": "Whatever you do, you prefer a proven method to a new one. I have a firm belief in my decision."
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_35_rhetoric",
    "_item": "마스터데이터 - 특성 설명(달변가)",
    "_comment": "",
    "ko-KR": "말을 잘 하기 때문에 임무 중 상황을 전파하는 데에 탁월합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_36_revengeful",
    "_item": "마스터데이터 - 특성 설명(복수심)",
    "_comment": "",
    "ko-KR": "쉽게 원한을 품으며 억울한 일을 당하면 복수를 추구합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_37_romantic",
    "_item": "마스터데이터 - 특성 설명(낭만)",
    "_comment": "",
    "ko-KR": "감정이 풍부해 이성적인 결정에 방해가 됩니다.",
    "en-US": "It's full of emotions that interfere with rational decisions."
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_38_paranoid",
    "_item": "마스터데이터 - 특성 설명(편집증)",
    "_comment": "",
    "ko-KR": "타인을 지나치게 의심하고 불신하며, 이것이 정상적인 판단을 방해할 수 있습니다.",
    "en-US": "Too much doubt and distrust of others, which can interfere with normal judgment."
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_39_merciless",
    "_item": "마스터데이터 - 특성 설명(무자비)",
    "_comment": "",
    "ko-KR": "양심이나 연민은 사치라고 생각합니다. 수단과 방법을 가리지 않고 목표를 달성합니다.",
    "en-US": "I think conscience or compassion is a luxury. You achieve your goals by any means or means."
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_40_humble",
    "_item": "마스터데이터 - 특성 설명(겸손)",
    "_comment": "",
    "ko-KR": "관심이나 칭찬에 연연하지 않고, 종종 자신의 업적을 깎아 내립니다. 절대 방심하지 않습니다.",
    "en-US": "They don't dwell on attention or praise, but often undermine their achievements. I never let my guard down."
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_41_shortsight",
    "_item": "마스터데이터 - 특성 설명(근시)",
    "_comment": "",
    "ko-KR": "시력이 안 좋습니다.",
    "en-US": "My eyesight is bad."
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_42_proud",
    "_item": "마스터데이터 - 특성 설명(자부심)",
    "_comment": "",
    "ko-KR": "스스로 높게 평가합니다. 강한 정신력을 가졌지만 방심할 때가 있습니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_43_pragmatic",
    "_item": "마스터데이터 - 특성 설명(냉철함)",
    "_comment": "",
    "ko-KR": "감정에 흔들리지 않고 논리적으로 판단합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_44_openmind",
    "_item": "마스터데이터 - 특성 설명(열린 마음)",
    "_comment": "",
    "ko-KR": "절대적인 진리는 없다고 생각하기에 새로운 생각이나 기술을 받아들이는 데에 적극적입니다.",
    "en-US": "I think there is no absolute truth, so I am active in accepting new ideas or technologies."
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_45_ridiculous",
    "_item": "마스터데이터 - 특성 설명(우스꽝스러움)",
    "_comment": "",
    "ko-KR": "옷 입는 스타일, 걸음걸이, 말투 등 모든 부분에서 웃음을 자아냅니다.",
    "en-US": "It brings out laughter in everything, including the style of dressing, walking, and speaking."
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_46_mumbling",
    "_item": "마스터데이터 - 특성 설명(웅얼거림)",
    "_comment": "",
    "ko-KR": "말할 때 입을 거의 안 벌립니다. 알아듣기 어렵습니다.",
    "en-US": "I rarely open my mouth when I talk. It's hard to understand."
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_47_weak",
    "_item": "마스터데이터 - 특성 설명(허약함)",
    "_comment": "",
    "ko-KR": "용병치고는 건강이 안 좋습니다.",
    "en-US": "It's not healthy for a mercenary."
  },
  {
    "key": "loc_data_longtext_traits_descr_trait_48_unfocused",
    "_item": "마스터데이터 - 특성 설명(산만함)",
    "_comment": "",
    "ko-KR": "한 가지 일에 오래 집중하는 것을 어려워 합니다.",
    "en-US": "I find it difficult to focus on one thing for a long time."
  },
  {
    "key": "loc_data_string_work_work_name_search_agent_tier",
    "_item": "마스터데이터 - 업무 이름(신규 용병 탐색)",
    "_comment": "",
    "ko-KR": "신규 용병 탐색",
    "en-US": "Search Recruit"
  },
  {
    "key": "loc_data_string_work_work_name_search_agent_possible",
    "_item": "마스터데이터 - 업무 이름(신규 용병 탐색(자동))",
    "_comment": "미사용",
    "ko-KR": "신규 용병 탐색(자동)",
    "en-US": "loc_data_string_work_work_name_search_agent_possible"
  },
  {
    "key": "loc_data_string_work_work_name_recruit_agent",
    "_item": "마스터데이터 - 업무 이름(신규 용병 계약)",
    "_comment": "",
    "ko-KR": "신규 용병 계약",
    "en-US": "Contract New Agent"
  },
  {
    "key": "loc_data_string_work_work_name_discount_renewal_agent",
    "_item": "마스터데이터 - 업무 이름(용병 재계약비용 조정)",
    "_comment": "미사용",
    "ko-KR": "용병 재계약비용 조정",
    "en-US": "loc_data_string_work_work_name_discount_renewal_agent"
  },
  {
    "key": "loc_data_string_work_work_name_search_staff",
    "_item": "마스터데이터 - 업무 이름(신규 직원 탐색)",
    "_comment": "",
    "ko-KR": "신규 직원 탐색",
    "en-US": "Search Staff Candidate"
  },
  {
    "key": "loc_data_string_work_work_name_recruit_staff",
    "_item": "마스터데이터 - 업무 이름(신규 직원 계약)",
    "_comment": "",
    "ko-KR": "신규 직원 계약",
    "en-US": "Hire New Staff"
  },
  {
    "key": "loc_data_string_work_work_name_source_gun_general",
    "_item": "마스터데이터 - 업무 이름(총기 조달)",
    "_comment": "미사용",
    "ko-KR": "총기 조달",
    "en-US": "loc_data_string_work_work_name_source_gun_general"
  },
  {
    "key": "loc_data_string_work_work_name_source_gun_ty",
    "_item": "마스터데이터 - 업무 이름(총기 조달(종류 지정))",
    "_comment": "미사용",
    "ko-KR": "총기 조달(종류 지정)",
    "en-US": "loc_data_string_work_work_name_source_gun_ty"
  },
  {
    "key": "loc_data_string_work_work_name_source_gun_tier",
    "_item": "마스터데이터 - 업무 이름(총기 조달(등급 지정))",
    "_comment": "",
    "ko-KR": "총기 조달(등급 지정)",
    "en-US": "Search Firearm"
  },
  {
    "key": "loc_data_string_work_work_name_source_gun_ty_tier",
    "_item": "마스터데이터 - 업무 이름(총기 조달(종류, 등급 지정))",
    "_comment": "미사용",
    "ko-KR": "총기 조달(종류, 등급 지정)",
    "en-US": "loc_data_string_work_work_name_source_gun_ty_tier"
  },
  {
    "key": "loc_data_string_work_work_name_source_bulletproof_general",
    "_item": "마스터데이터 - 업무 이름(방탄장비 조달)",
    "_comment": "미사용",
    "ko-KR": "방탄장비 조달",
    "en-US": "loc_data_string_work_work_name_source_bulletproof_general"
  },
  {
    "key": "loc_data_string_work_work_name_source_bulletproof_tier",
    "_item": "마스터데이터 - 업무 이름(방탄장비 조달(등급 지정))",
    "_comment": "",
    "ko-KR": "방탄장비 조달(등급 지정)",
    "en-US": "Search Protection Gear"
  },
  {
    "key": "loc_data_string_work_work_name_source_granade_tier",
    "_item": "마스터데이터 - 업무 이름(수류탄 조달(종류 지정))",
    "_comment": "",
    "ko-KR": "수류탄 조달(종류 지정)",
    "en-US": "Search Grenade"
  },
  {
    "key": "loc_data_string_work_work_name_buy_item",
    "_item": "마스터데이터 - 업무 이름(장비 구입)",
    "_comment": "",
    "ko-KR": "장비 구입",
    "en-US": "Buy Equipment"
  },
  {
    "key": "loc_data_string_work_work_name_sell_item",
    "_item": "마스터데이터 - 업무 이름(장비 판매)",
    "_comment": "",
    "ko-KR": "장비 판매",
    "en-US": "Sell Equipment"
  },
  {
    "key": "loc_data_string_work_work_name_maintain_training",
    "_item": "마스터데이터 - 업무 이름(훈련장 관리)",
    "_comment": "",
    "ko-KR": "훈련장 관리",
    "en-US": "Mainting Training Facility"
  },
  {
    "key": "loc_data_string_work_work_name_search_contract",
    "_item": "마스터데이터 - 업무 이름(의뢰 탐색)",
    "_comment": "미사용",
    "ko-KR": "의뢰 탐색",
    "en-US": "loc_data_string_work_work_name_search_contract"
  },
  {
    "key": "loc_data_longtext_work_work_desc_search_agent_tier",
    "_item": "마스터데이터 - 업무 설명(신규 용병 탐색)",
    "_comment": "",
    "ko-KR": "원하는 수준의 용병을 모집창에 추가합니다. 용병의 수준이 높아질수록 실패 확률이 높아지고 수행 기간 또한 길어집니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_work_work_desc_search_agent_possible",
    "_item": "마스터데이터 - 업무 설명(신규 용병 탐색(자동))",
    "_comment": "미사용",
    "ko-KR": "부서가 확실하게 탐색 가능한 가장 높은 수준의 용병을 모집창에 추가합니다.",
    "en-US": "loc_data_longtext_work_work_desc_search_agent_possible"
  },
  {
    "key": "loc_data_longtext_work_work_desc_recruit_agent",
    "_item": "마스터데이터 - 업무 설명(신규 용병 계약)",
    "_comment": "",
    "ko-KR": "해당 용병과 계약을 진행합니다. 업무 성공시 계약금이 약간 할인되고, 업무 실패시에도 계약은 진행됩니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_work_work_desc_discount_renewal_agent",
    "_item": "마스터데이터 - 업무 설명(용병 재계약비용 조정)",
    "_comment": "미사용",
    "ko-KR": "해당 용병의 기대 몸값(이후 재계약때 사용될 기준 수치)을 낮춥니다.",
    "en-US": "loc_data_longtext_work_work_desc_discount_renewal_agent"
  },
  {
    "key": "loc_data_longtext_work_work_desc_search_staff",
    "_item": "마스터데이터 - 업무 설명(신규 직원 탐색)",
    "_comment": "",
    "ko-KR": "고용 가능한 직원을 탐색합니다. ",
    "en-US": "Explore available employees."
  },
  {
    "key": "loc_data_longtext_work_work_desc_recruit_staff",
    "_item": "마스터데이터 - 업무 설명(신규 직원 계약)",
    "_comment": "",
    "ko-KR": "해당 직원을 고용합니다. 직원의 능력치에 비례하여 고용에 실패할 확률이 높아집니다.",
    "en-US": "Hire that employee. In proportion to the employee's ability, you are more likely to fail in hiring."
  },
  {
    "key": "zc",
    "_item": "마스터데이터 - 업무 설명(총기 조달)",
    "_comment": "미사용",
    "ko-KR": "무작위 총기를 상점에 추가합니다. \n등장 가능한 총기의 최고 등급은 부서의 역량치에 따라 달라지며, 종류나 등급을 지정할 수 있는 조달에 비해 난이도가 낮습니다.",
    "en-US": "loc_data_longtext_work_work_desc_discount_renewal_agent"
  },
  {
    "key": "loc_data_longtext_work_work_desc_source_gun_ty",
    "_item": "마스터데이터 - 업무 설명(총기 조달(종류 지정))",
    "_comment": "미사용",
    "ko-KR": "선택한 종류의 총기를 상점에 추가합니다. 등장 가능한 총기의 최고 등급은 부서의 역량치에 따라 달라집니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_work_work_desc_source_gun_tier",
    "_item": "마스터데이터 - 업무 설명(총기 조달(등급 지정))",
    "_comment": "",
    "ko-KR": "선택한 등급의 총기를 상점에 추가합니다. 등급에 비례하여 조달에 실패할 확률이 높아집니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_work_work_desc_source_gun_ty_tier",
    "_item": "마스터데이터 - 업무 설명(총기 조달(종류, 등급 지정))",
    "_comment": "미사용",
    "ko-KR": "선택한 종류/등급의 총기를 상점에 추가합니다. 등급에 비례하여 조달에 실패할 확률이 높아집니다.",
    "en-US": "loc_data_longtext_work_work_desc_source_gun_ty_tier"
  },
  {
    "key": "loc_data_longtext_work_work_desc_source_bulletproof_general",
    "_item": "마스터데이터 - 업무 설명(방탄장비 조달)",
    "_comment": "미사용",
    "ko-KR": "무작위 방탄장비를 상점에 추가합니다. \n등장 가능한 방탄장비의 최고 등급은 부서의 역량치에 따라 달라지며, 등급을 지정할 수 있는 조달에 비해 난이도가 낮습니다.",
    "en-US": "loc_data_longtext_work_work_desc_source_bulletproof_general"
  },
  {
    "key": "loc_data_longtext_work_work_desc_source_bulletproof_tier",
    "_item": "마스터데이터 - 업무 설명(방탄장비 조달(등급 지정))",
    "_comment": "",
    "ko-KR": "선택한 등급의 방탄장비를 상점에 추가합니다. 등급에 비례하여 조달에 실패할 확률이 높아집니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_work_work_desc_source_granade_tier",
    "_item": "마스터데이터 - 업무 설명(수류탄 조달(종류 지정))",
    "_comment": "",
    "ko-KR": "선택한 종류의 수류탄을 상점에 추가합니다.",
    "en-US": "%% TODO %%"
  },
  {
    "key": "loc_data_longtext_work_work_desc_buy_item",
    "_item": "마스터데이터 - 업무 설명(장비 구입)",
    "_comment": "",
    "ko-KR": "해당 장비를 구입합니다. 업무 성공시 구매금이 약간 할인되고, 업무 실패시에도 거래는 진행됩니다.",
    "en-US": "I will purchase the equipment. If the work is successful, the purchase amount is slightly discounted, and even if the work fails, the transaction proceeds."
  },
  {
    "key": "loc_data_longtext_work_work_desc_sell_item",
    "_item": "마스터데이터 - 업무 설명(장비 판매)",
    "_comment": "",
    "ko-KR": "해당 장비를 판매합니다. 업무 성공시 판매금이 약간 증가하고, 업무 실패시에도 거래는 진행됩니다.",
    "en-US": "We sell those equipment. Sales increase slightly when the work is successful, and transactions proceed even when the work fails."
  },
  {
    "key": "loc_data_longtext_work_work_desc_maintain_training",
    "_item": "마스터데이터 - 업무 설명(훈련장 관리)",
    "_comment": "",
    "ko-KR": "훈련시설의 관리도를 높입니다. 효과는 업무기간동안 꾸준히 적용됩니다.",
    "en-US": "Increase the management of training facilities. The effect is applied steadily throughout the working period."
  },
  {
    "key": "loc_data_longtext_work_work_desc_search_contract",
    "_item": "마스터데이터 - 업무 설명(의뢰 탐색)",
    "_comment": "미사용",
    "ko-KR": "선택한 훈련 수준의 적이 등장하는 의뢰를 탐색합니다. 업무 성공시 보수금이 약간 증가하고, 업무 실패시에도 의뢰는 등장합니다.",
    "en-US": "loc_data_longtext_work_work_desc_search_contract"
  },
  {
    "key": "loc_data_string_workdetails_finish_success_rate_desc_success",
    "_item": "마스터데이터 - 업무 성공 확률 내용(성공 확률)",
    "_comment": "",
    "ko-KR": "성공 확률",
    "en-US": "Success Chance"
  },
  {
    "key": "loc_data_string_workdetails_finish_success_rate_desc_favorable_negotiation",
    "_item": "마스터데이터 - 업무 성공 확률 내용(유리한 협상 확률)",
    "_comment": "",
    "ko-KR": "유리한 협상 확률",
    "en-US": "Favorable Negotiation Chance"
  },
  {
    "key": "loc_data_string_workdetails_finish_success_rate_desc_discount_buy",
    "_item": "마스터데이터 - 업무 성공 확률 내용(할인 구매 확률)",
    "_comment": "",
    "ko-KR": "할인 구매 확률",
    "en-US": "Purchase Discount Chance"
  },
  {
    "key": "loc_data_string_workdetails_finish_success_rate_desc_increase_sell",
    "_item": "마스터데이터 - 업무 성공 확률 내용(유리한 판매 확률)",
    "_comment": "",
    "ko-KR": "유리한 판매 확률",
    "en-US": "Selling Price Rise Chance"
  },
  {
    "key": "loc_data_string_workdetails_finish_success_rate_desc_more_rewards",
    "_item": "마스터데이터 - 업무 성공 확률 내용(보상 증가 확률)",
    "_comment": "",
    "ko-KR": "보상 증가 확률",
    "en-US": "Reward Increase Chance"
  },
  {
    "key": "loc_data_string_workdetails_finish_success_effect_desc_overall_agent",
    "_item": "마스터데이터 - 업무 성공시 내용(탐색한 용병의 기대 전투력)",
    "_comment": "",
    "ko-KR": "탐색한 용병의 기대 전투력",
    "en-US": "Expected Overall Ability of Agent Recruits"
  },
  {
    "key": "loc_data_string_workdetails_finish_success_effect_desc_discount_hire",
    "_item": "마스터데이터 - 업무 성공시 내용(월간 계약금 할인율)",
    "_comment": "",
    "ko-KR": "월간 계약금 할인율",
    "en-US": "Discount Rate of Monthly Pay"
  },
  {
    "key": "loc_data_string_workdetails_finish_success_effect_desc_discount_extend",
    "_item": "마스터데이터 - 업무 성공시 내용(재계약시 월간 계약금 할인율)",
    "_comment": "",
    "ko-KR": "재계약시 월간 계약금 할인율",
    "en-US": "Discount Rate of Monthly Pay(when Renew)"
  },
  {
    "key": "loc_data_string_workdetails_finish_success_effect_desc_overall_staff",
    "_item": "마스터데이터 - 업무 성공시 내용(직원 후보자의 예상 평균 능력치)",
    "_comment": "",
    "ko-KR": "직원 후보자의 예상 평균 능력치",
    "en-US": "Expected Overall Ability of Staff Recruits"
  },
  {
    "key": "loc_data_string_workdetails_finish_success_effect_desc_discount_buy",
    "_item": "마스터데이터 - 업무 성공시 내용(유리한 구매로 인한 할인율)",
    "_comment": "",
    "ko-KR": "유리한 구매로 인한 할인율",
    "en-US": "Discount Rate when Favorable"
  },
  {
    "key": "loc_data_string_workdetails_finish_success_effect_desc_increase_sell",
    "_item": "마스터데이터 - 업무 성공시 내용(유리한 판매로 인한 인상률)",
    "_comment": "",
    "ko-KR": "유리한 판매로 인한 인상률",
    "en-US": "Price Rise Rate when Favorable"
  },
  {
    "key": "loc_data_string_workdetails_finish_success_effect_desc_daily_maintain",
    "_item": "마스터데이터 - 업무 성공시 내용(일간 관리도 회복량)",
    "_comment": "",
    "ko-KR": "일간 관리도 회복량",
    "en-US": "Daily Condition Recover"
  },
  {
    "key": "loc_data_string_workdetails_finish_success_effect_desc_more_rewards",
    "_item": "마스터데이터 - 업무 성공시 내용(보상 증가 비율)",
    "_comment": "",
    "ko-KR": "보상 증가 비율",
    "en-US": "Reward Increase Rate"
  },
  {
    "key": "loc_data_string_fatigue_0",
    "_item": "마스터데이터 - 용병 피로도 0",
    "_comment": "",
    "ko-KR": "출동 불가",
    "en-US": "Unavailable"
  },
  {
    "key": "loc_data_string_fatigue_1",
    "_item": "마스터데이터 - 용병 피로도 1",
    "_comment": "",
    "ko-KR": "기진맥진",
    "en-US": "Exhausted"
  },
  {
    "key": "loc_data_string_fatigue_2",
    "_item": "마스터데이터 - 용병 피로도 2",
    "_comment": "",
    "ko-KR": "피곤함",
    "en-US": "Tired"
  },
  {
    "key": "loc_data_string_fatigue_3",
    "_item": "마스터데이터 - 용병 피로도 3",
    "_comment": "",
    "ko-KR": "활기참",
    "en-US": "Lively"
  },
  {
    "key": "loc_data_string_fatigue_4",
    "_item": "마스터데이터 - 용병 피로도 4",
    "_comment": "",
    "ko-KR": "최고",
    "en-US": "Apex"
  }
];