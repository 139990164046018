// downloaded data!
export const data = [
  {
    "key": "all",
    "name": "loc_data_string_training_name_all",
    "decision": "0.1",
    "bravery": "0.1",
    "focus": "0.1",
    "reaction": "0.1",
    "toughness": "0.1",
    "precision": "0.1"
  },
  {
    "key": "leadership",
    "name": "loc_data_string_training_name_leadership",
    "decision": "0.4",
    "bravery": "0.1",
    "focus": "0",
    "reaction": "0",
    "toughness": "0.1",
    "precision": "0"
  },
  {
    "key": "climbing",
    "name": "loc_data_string_training_name_climbing",
    "decision": "0",
    "bravery": "0.4",
    "focus": "0.1",
    "reaction": "0",
    "toughness": "0.1",
    "precision": "0"
  },
  {
    "key": "shooting",
    "name": "loc_data_string_training_name_shooting",
    "decision": "0",
    "bravery": "0",
    "focus": "0.4",
    "reaction": "0.1",
    "toughness": "0",
    "precision": "0.1"
  },
  {
    "key": "reaction_drills",
    "name": "loc_data_string_training_name_reaction_drills",
    "decision": "0.1",
    "bravery": "0",
    "focus": "0.1",
    "reaction": "0.4",
    "toughness": "0",
    "precision": "0"
  },
  {
    "key": "pull_ups",
    "name": "loc_data_string_training_name_pull_ups",
    "decision": "0.1",
    "bravery": "0",
    "focus": "0",
    "reaction": "0",
    "toughness": "0.4",
    "precision": "0.1"
  },
  {
    "key": "agility_circuits",
    "name": "loc_data_string_training_name_agility_circuits",
    "decision": "0",
    "bravery": "0.1",
    "focus": "0",
    "reaction": "0.1",
    "toughness": "0",
    "precision": "0.4"
  }
];