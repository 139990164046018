import { Routes, Route } from 'react-router-dom';
import React from 'react';

import { App } from './App';
import { UETest } from './UETest';
import { GamefaceSimOverlay } from './GamefaceSimOverlay';
import { Testbed } from './Testbed';
import { Editor } from './Editor';
import { Rooms } from './Rooms';
import { Wasm } from './Wasm';
import { WebGL } from './WebGL';
import { ExploreView } from './ExploreView';
import { HireView } from './component/HireView';
import { SkirmishView } from './SkirmishView';
import { LoopView } from './LoopView';
import { RoguelikeView } from './RoguelikeView';
import { WorldView } from './WorldView';
import { WorldView2 } from './WorldView2';
import { WorldView3 } from './WorldView3';
import { TableView } from './TableView';
import { CutSceneView } from './CutSceneView';
import { CharacterView } from './CharacterView';
import { FigmaView } from './FigmaView';
import { FigmaUpcomingView } from './FigmaUpcomingView';
import { BuildReport } from './component/BuildReport';
import { ResearchRoot } from './ResearchView';
import { NegotiateView } from './NegotiateView';
import { SimBattleView } from './SimBattleView';
import { FirearmsView } from './FirearmView';
import { GearsView } from './GearView';
import { TrainingRoot } from './TrainingView';
import { PortraitsView } from './PortraitsView';
import { FigComponentsView } from './FigComponentsView';
import { GridView } from './GridView';
import { CanvasTestView } from './CanvasTestView';
import { ThreatsView } from './ThreatsView';
import { TitleView } from './TitleView';
import init, * as m from 'asm';

let loading = false;
let loaded = false;
const callbacks = [];
const initWasm = function (cb) {
  if (loaded) {
    cb();
    return;
  }
  callbacks.push(cb);
  if (loading) {
    return;
  }
  loading = true;

  const onInitWasm = () => {
    loaded = true;
    const pending = callbacks.splice(0);
    for (const cb of pending) {
      cb();
    }
  };

  if (window.gameface) {
    onInitWasm();
  } else {
    init().then(onInitWasm);
  }
};

export class Main extends React.Component {
  constructor(props) {
    super(props);

    this.state = { loaded: false };
  }

  componentDidMount() {
    initWasm(() => {
      this.setState({ loaded: true });
    });
  }

  render() {
    if (!this.state.loaded) {
      return "Loading";
    }


    return (
      <Routes>
        <Route path="/" element={<App m={m} />}></Route>
        <Route path="/uetest" element={<UETest m={m} />}></Route>
        <Route path="/overlay" element={<GamefaceSimOverlay />}></Route>
        <Route path="/testbed" element={<Testbed m={m} />}></Route>
        <Route path="editor" element={<Editor m={m} />}></Route>
        <Route path="room" element={<Rooms />}></Route>
        <Route path="wasm" element={<Wasm m={m} />}></Route>
        <Route path="webgl" element={<WebGL />}></Route>
        <Route path="skirmish" element={<SkirmishView />}></Route>
        <Route path="loop" element={<LoopView />}></Route>
        <Route path="hire" element={<HireView />}></Route>
        <Route path="explore" element={<ExploreView />}></Route>
        <Route path="final" element={<BuildReport />}></Route>
        <Route path="roguelike" element={<RoguelikeView m={m} />}></Route>
        <Route path="world" element={<WorldView m={m} />}></Route>
        <Route path="world2" element={<WorldView2 />}></Route>
        <Route path="world3" element={<WorldView3 />}></Route>
        <Route path="table" element={<TableView />}></Route>
        <Route path="cutscene" element={<CutSceneView />}></Route>
        <Route path="character" element={<CharacterView />}></Route>
        <Route path="figma" element={<FigmaView />}></Route>
        <Route path="figmaupcoming" element={<FigmaUpcomingView />}></Route>
        <Route path="research" element={<ResearchRoot />}></Route>
        <Route path="negotiate" element={<NegotiateView />}></Route>
        <Route path="simbattle" element={<SimBattleView />}></Route>
        <Route path="firearm" element={<FirearmsView />}></Route>
        <Route path="gear" element={<GearsView />}></Route>
        <Route path="training" element={<TrainingRoot />}></Route>
        <Route path="portrait" element={<PortraitsView />}></Route>
        <Route path="figcomp" element={<FigComponentsView />}></Route>
        <Route path="grid" element={<GridView />}></Route>
        <Route path="canvastest" element={<CanvasTestView />}></Route>
        <Route path="threats" element={<ThreatsView />}></Route>
        <Route path="title" element={<TitleView m={m} />}></Route>
      </Routes>
    );
  }
}

export default Main;
