import { data as source } from '../downloaded/facilities.mjs';
import { results } from './data_facilityresult.mjs';

function parse_tasks(s) {
  const tasks = [];
  for (var line of s.replace(/\r/g, '').split('\n')) {
    if (line === '') {
      continue;
    }
    let idx = line.indexOf(',');
    if (idx === -1) {
      idx = line.length;
    }

    const work_key = line.slice(0, idx);
    line = line.slice(idx + 1).trim();

    const vars = [];
    let l0 = line.length;
    while (line.length > 0) {
      if (line[0] === '[') {
        let idx = line.indexOf(']');
        let l = line.slice(1, idx);
        line = line.slice(idx + 1).trim();

        vars.push(l.split(',').map((s) => s.trim()));
      }
      if (line[0] === ',') {
        line = line.slice(1).trim();
      }
      if (l0 === line.length) {
        console.log(line, line.length);
        throw new Error(line);
      }
      l0 = line.length;
    }

    tasks.push({
      work_key,
      var1: vars[0],
      var2: vars[1],
    });
  }
  return tasks;
}

export const facilities = [];
for (const row of source) {
  let { idx, ty0, ty1, name, tier, key, prerequisities, cost, maintenance, duration, favors, token, descr, facilityresult } = row;
  let { staff_heads, staffs, tasks, staff_abilities } = row;

  facilities.push({
    idx: 0 | idx,
    ty0,
    ty1,
    name,
    tier: 0 | tier,
    key,
    prerequisities: prerequisities?.split(',').map(s => s.trim()) ?? [],
    cost: 0 | cost,
    maintenance: 0 | maintenance,
    duration: 0 | duration,
    favors: 0 | favors,
    tokens: 0 | token,
    descr,
    result: results.find(({ result_key }) => result_key === facilityresult),

    staff_heads: 0 | staff_heads,
    staffs: 0 | staffs,
    staff_abilities: staff_abilities?.split(',').map(s => s.trim()) ?? [],
    tasks: tasks ? parse_tasks(tasks) : [],
  });
}

export function facilityByKey(key) {
  return facilities.find(({ key: k }) => k === key);
}

export function facilityDefault(ty0) {
  return facilities.find((f) => f.ty0 === ty0 && f.tier === 0);
}

export function facilitiesByTy0(ty0) {
  return facilities.filter((f) => f.ty0 === ty0);
}

export function facilityIsBase(key) {
  const data = facilityByKey(key);
  return ['base_main', 'base_sub'].includes(data.ty0);
}
