
import { useState } from 'react';
import * as data_facilities from './data/google/processor/data_facilities.mjs';
import { staffAbilities as data_staffAbilities } from './data/google/processor/data_staffAbility.mjs';
import { work as data_work } from './data/google/processor/data_work.mjs';

import {
  FH1ButtonTiny as Button,
  FH1ButtonFoldable as ButtonFoldable,
  FH1ProgressBar as ProgressBar,
} from './component/figma/fh1';

import { TICK_PER_DAY } from './tick.mjs';
import { L } from './localization.mjs';

export function Checklist(props) {
  const { conditions } = props;
  return <div className="checklist box">
    {conditions.map(({ done, label }, i) => {
      let cls = done ? 'flex-cond-ok' : 'flex-cond-nok';
      return <span key={i} className={cls}>{label}</span>;
    })}
  </div>;
}

export function facilityUpgrades(args) {
  const { facilities, effects, global_effects, cur, resources, tokens, mode } = args;

  let nexts = data_facilities.facilities.filter(({ prerequisities, ty0, ty1 }) => {
    if (facilities.find((f) => f.key !== cur.key && data_facilities.facilityByKey(f.key).ty1 === ty1)) {
      return false;
    }
    return prerequisities.includes(cur.key) && ty0 === cur.ty0;
  });

  const is_base = data_facilities.facilityIsBase;
  const has_safehouse = facilities.find((f) => data_facilities.facilityByKey(f.key).ty0 === 'safehouse');
  const upgrading = facilities.find((f) => is_base(f.key) && f.state === 'upgrading');

  return nexts.map((info) => {
    const { key, tokens: tokens0, prerequisities } = info;
    let { cost, duration } = info;
    cost = Math.floor(cost * effects.cost_facility_mult * global_effects.cost_facility_mult);
    duration = duration * effects.duration_facility_mult * global_effects.duration_facility_mult;

    const conds = [
      { done: cost <= resources.resource, label: L('loc_dynamic_string_region_facility_build_condition_cash', { value: cost }) },
    ];

    if (tokens0 > 1) {
      conds.push({
        done: tokens0 <= tokens,
        label: L('loc_dynamic_string_region_facility_build_condition_milestone_contract', { value: tokens0 - 1 }),
      });
    }

    const facility_data = data_facilities.facilityByKey(key);

    if (facility_data.ty0 === 'support') {
      conds.push({
        done: mode === 0,
        label: L('loc_ui_string_region_facility_build_condition_region_facility_status'),
      });
      conds.push({
        done: has_safehouse,
        label: L('loc_ui_string_region_facility_build_condition_build_safehouse'),
      });
    }

    if (info.tier > 0) {
      if (facility_data.ty0 === 'base_main') {
        // do nothing
      } else if (facility_data.ty1 === 'safehouse_main') {
        conds.push({
          done: info.tier - 1 <= resources.renown.level,
          label: L('loc_dynamic_string_region_facility_build_condition_milestone_contract', { value: info.tier - 1 }),
        });
      } else if (facility_data.ty0 !== 'support') {
        let base_tier = effects.base_tier;
        conds.push({
          done: base_tier >= info.tier,
          label: L('loc_dynamic_string_region_facility_build_condition_build_tier', { value: info.tier }),
        });
      }
    }

    if (is_base(cur.key)) {
      conds.push({
        done: !upgrading,
        label: L('loc_ui_string_region_facility_build_condition_one_at_once'),
      });
    }

    for (const prerequisite of prerequisities.slice(1)) {
      if (prerequisite.includes("training")) {
        // 같은 지역인지 보유하고 있는 지역인지 미정
        // 일반 훈련소 1인지 훈련소 전부인지 미정
        // training1 -> training_general1 로 바꾸는게 좋을 것 같은데....
        const pre_training = facilities.find((f) => f.key.startsWith("training"));
        conds.push({
          done: pre_training !== undefined,
          label: L('loc_ui_string_region_facility_build_condition_prerequisite') + L(data_facilities.facilities.find(({ key }) => prerequisite === key).name),
        })
      }
    }

    const avail = conds.find(({ done }) => !done) === undefined;
    return { info: { ...info, cost, duration }, conds, avail };
  });
}

export function WorldFacilityUpgradeView(props) {
  const { facility: f, turn, effects, global_effects, flexoverlay } = props;
  const nextInfo = data_facilities.facilities.find(({ key }) => f.next === key);
  const dur = Math.ceil(nextInfo.duration * effects.duration_facility_mult * global_effects.duration_facility_mult * TICK_PER_DAY);
  const started = f.ends_at - dur;
  const elapsed = turn - started;

  return <div className={flexoverlay ? 'flexoverlay-upgrading' : 'flexcenter-upgrading'}>
    <div className={flexoverlay ? 'flexoverlay-upgrading-desc' : 'flexcenter-upgrading-desc'}>{L('loc_ui_string_region_facility_build_upgrading')}<span title={L(nextInfo.descr)}>{L(nextInfo.name)}</span></div>
    <ProgressBar cur={elapsed} cap={dur} max={dur} date={true} classname={flexoverlay ? 'overlay-progress' : ''} />
  </div>;
}

export function WorldFacilityView(props) {
  const { i, state, facility: f, game, idx, onFacilityUpgrade, onFacilityReset, mode, readonly } = props;
  const { tokens, turn, resources, effects: global_effects } = game;
  const { effects, facilities } = state;

  const [folded, setFolded] = useState(true);

  let cur = data_facilities.facilities.find(({ key }) => f.key === key);
  let nexts = facilityUpgrades({ facilities, effects, global_effects, cur, resources, tokens, mode });
  let avail = nexts.find(({ avail }) => avail) !== undefined;

  let btnReset = null;
  if (onFacilityReset) {
    btnReset = <Button key='reset' onClick={() => onFacilityReset({ idx }, i)}>{L('loc_ui_button_region_facility_build_destroy')}</Button>;
  }

  const upgradeText = cur.ty1 === 'empty' ? 'loc_ui_button_region_facility_build_build' : 'loc_ui_button_region_facility_build_upgrade';
  let body = null;
  if (nexts.length > 0) {
    body = <div>
      <ButtonFoldable key='fold' folded={folded} onClick={() => setFolded(false)}>{L(upgradeText)}</ButtonFoldable>
      {btnReset}
    </div>;
  }

  if (!folded) {
    body = <div>
      <ButtonFoldable folded={folded} onClick={() => setFolded(true)}>{L(upgradeText)}</ButtonFoldable>
      {btnReset}
      {nexts.map(({ info, conds, avail }) => {
        const { name, ty0, ty1, key, duration, descr, maintenance } = info;
        avail = avail && onFacilityUpgrade !== null;

        let departmentDesc = <></>;
        const { tasks, staff_abilities } = info;

        const abilities = data_staffAbilities.filter((d) => staff_abilities.find((a) => a === d.key));
        const abilitiesDesc = abilities.length > 0 ? <>{L('loc_dynamic_string_region_facility_requiring_ability', { list: abilities.map((a) => L(a.name)).join(', ') })}< br /></> : <></>;
        const tasksDesc = tasks.length > 0 ? <>{L('loc_dynamic_string_region_facility_tasks', { list: tasks.map((t) => L(data_work.find((d) => d.work_key === t.work_key).work_name)).join(', ') })}< br /></> : <></>;

        departmentDesc = <>
          {abilitiesDesc}
          {tasksDesc}
        </>

        return <div className="box" key={key}>
          <p>
            {L(name)}
          </p>
          <p>
            {L('loc_dynamic_string_region_facility_build_duration', { value: duration.toFixed(1) })}
          </p>
          <p>
            {maintenance > 0 ? L('loc_dynamic_string_region_facility_build_maintenance', { value: maintenance }) : ''}
          </p>
          <p>
            {L(descr)}
          </p>
          <p>
            {departmentDesc}
          </p>
          <Checklist conditions={conds} />
          <Button key={key} disabled={!avail} onClick={() => {
            onFacilityUpgrade({ idx }, i, info);
          }}>{L('loc_ui_button_region_facility_upgrade')}</Button>
        </div>;
      })}
    </div>;
  }

  if (readonly) {
    body = null;
  }

  if (f.state === 'upgrading') {
    body = <WorldFacilityUpgradeView facility={f} turn={turn} effects={effects} global_effects={global_effects} />;
  }

  if (!f.enabled) {
    body = <div className="flex-worldcenter-facility-alert">{L('loc_ui_longtext_region_facility_upgrade_disabled_not_enabled')}</div>;
  }

  let availtext = null;
  if (avail) {
    let label = 'loc_ui_string_region_facility_upgradable';
    if (cur.ty1 === 'empty') {
      label = 'loc_ui_string_region_facility_buildable';
    }
    availtext = <span className="flex-worldcenter-facility-avail">[{L(label)}]</span>;
  }

  return <div className="flex-worldcenter-facility" key={i}>
    <p>#{i} | {L(cur.name)} {availtext}</p>
    <p>{cur.maintenance > 0 ? L('loc_dynamic_string_region_facility_build_maintenance', { value: cur.maintenance }) : ''}</p>
    <p className='flex-worldcenter-facility-descr'>{L(cur.descr)}</p>
    {body}
  </div>;
}
