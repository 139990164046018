// downloaded data!
export const data = [
  {
    "key": "holdfire",
    "title": "사격 중지",
    "descr": "어떤 상황에서도 사격을 하지 않도록 지시합니다."
  },
  {
    "key": "holdpos",
    "title": "위치 사수",
    "descr": "현재 위치에서 움직이지 않도록 지시합니다."
  },
  {
    "key": "cover",
    "title": "재정비",
    "descr": "엄폐물 뒤에 숨어있는 상태를 유지하도록 지시합니다. 모두 엄폐한 경우 재정비할 수 있습니다."
  },
  {
    "key": "caution",
    "title": "행동 정책: 수비",
    "descr": "조심히 움직이도록 지시합니다. 사격당할 시 엄폐물에서 벗어나지 않습니다."
  },
  {
    "key": "normal",
    "title": "행동 정책: 공격",
    "descr": "빠르게 움직이도록 지시합니다. 팀원을 기다리지 않고 도어 브리칭, 수색 등의 행동을 바로 수행합니다."
  },
  {
    "key": "throwable",
    "title": "투척물 사용",
    "descr": "용병들이 상황에 따라 투척물을 사용할 수 있도록 지시합니다."
  },
  {
    "key": "gather",
    "title": "집결",
    "descr": "모든 용병들이 한 지점에 집결하도록 지시합니다."
  },
  {
    "key": "clear",
    "title": "탐색 재시작",
    "descr": "용병들이 아직 탐색하지 않은 영역을 수색하도록 지시합니다."
  },
  {
    "key": "reorg",
    "title": "재정비",
    "descr": ""
  },
  {
    "key": "withdraw",
    "title": "임무 포기",
    "descr": ""
  }
];
