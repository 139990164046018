import React from 'react';
import './cohtml.js';
import { SimOverlay } from './SimOverlay.mjs';

export class GamefaceSimOverlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
    engine.on('Update', (data) => {
      this.setState({ data: JSON.parse(data) });
    });
  }

  render() {
    const { data } = this.state;
    return <SimOverlay data={data} />;
  }
}
