/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { PerkLevel } from "../PerkLevel";
import { PerkLevels } from "../PerkLevels";
import { PerkSlot } from "../PerkSlot";
import { PerkTreeColumn } from "../PerkTreeColumn";
import { PerkTreesContainer } from "../PerkTreesContainer";
import { PgbConC } from "../PgbConC";
import "./style.css";

export const AgentPerk = ({
  ptTotalPerkPoint = "7",
  ptAvailablePerkPoint = "2",
  perkTreesContainer = (
    <PerkTreeColumn
      override={
        <PerkSlot
          className="design-component-instance-node-2"
          frameEc="/anima/img/frame-8ec-93630.svg"
          img="/anima/img/perkimg-sample-2.png"
          label="PERK"
          onClick="D"
          onMouseMove="D"
          onMouseOut="D"
          onMouseOver="D"
          state="completed"
          v="/anima/img/v1fb2a71d-1.svg"
          v1="/anima/img/v-90633387.svg"
          vE="/anima/img/v48146e38-2.svg"
          vFceEc="/anima/img/v-5fce-7-9ec.svg"
          vf="/anima/img/vf6ee8613-2.svg"
        />
      }
    />
  ),
  perkLevels = <PerkLevel PTPerklevel="1" className="design-component-instance-node-2" />,
  override = (
    <PgbConC
      PGBButtonOnClick="onClick31"
      PGBButtonOnClick1="onClick32"
      PGBButtonOnClick2="onClick33"
      PGBButtonOnMouseMove="D"
      PGBButtonOnMouseMove1="D"
      PGBButtonOnMouseMove2="D"
      PGBButtonOnMouseOut="D"
      PGBButtonOnMouseOut1="D"
      PGBButtonOnMouseOut2="D"
      PGBButtonOnMouseOver="D"
      PGBButtonOnMouseOver1="D"
      PGBButtonOnMouseOver2="D"
      PGBButtonPtLabel="Buttonlabel31"
      PGBButtonPtLabel1="Buttonlabel32"
      PGBButtonPtLabel2="Buttonlabel33"
      PGBButtonPtSubLabel="ButtonSubLabel32"
      PGBButtonPtSubLabel1="ButtonSubLabel33"
      PGBButtonVA="/anima/img/v-32-0cff-74.svg"
      PGBButtonVA1="/anima/img/v320cff74-1.svg"
      PGBButtonVCff="/anima/img/v-32-0cff-74.svg"
      PGBButtonVCff1="/anima/img/v320cff74-1.svg"
      PGBButtonVarState="on"
      PGBButtonVarState1="on"
      PGBButtonVarState2="on"
      className="PGB-con-c-instance"
    />
  ),
  lineClassName,
  frame = "/anima/img/frame-3411.svg",
}) => {
  return (
    <div className="agent-perk">
      <div className="overlap-14">
        <div className="bg-4">
          <div className="rectangle-17" />
          <div className="rectangle-18" />
        </div>
        <div className="scroll-area">
          <PerkTreesContainer className="perk-trees-container-instance" override={perkTreesContainer} />
          <PerkLevels className="perk-levels-container" override={perkLevels} />
        </div>
        <div className="perk-level-trim">
          <div className="rectangle-19" />
          <div className="rectangle-20" />
        </div>
        <div className="fixed-header">
          <div className="perklevel-wrapper">
            <div className="perklevel">퍽 레벨</div>
          </div>
        </div>
      </div>
      <div className="overlap-15">
        <div className="div-5">
          <div className="overlap-16">
            <div className="div-5">
              <div className="rectangle-21" />
              <img className="vector-9" alt="Vector" src="/anima/img/vector.svg" />
              <img className="vector-10" alt="Vector" src="/anima/img/vector-2.svg" />
              <img className="vector-11" alt="Vector" src="/anima/img/vector-3.svg" />
              <img className="vector-12" alt="Vector" src="/anima/img/vector-5.svg" />
            </div>
            <img className="vector-13" alt="Vector" src="/anima/img/vector-1.svg" />
            <img className="vector-14" alt="Vector" src="/anima/img/vector-4.svg" />
          </div>
        </div>
        {override}
      </div>
      <div className="head-info">
        <div className="headinfo">
          <div className="div-6">
            <div className="total">가용 퍽 포인트:</div>
            <div className="text-wrapper-9">{ptAvailablePerkPoint}</div>
          </div>
          <div className={`line-2 ${lineClassName}`} />
          <div className="div-6">
            <img className="frame-38" alt="Frame" src={frame} />
            <div className="total">투자된 퍽 포인트:</div>
            <div className="text-wrapper-9">{ptTotalPerkPoint}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

AgentPerk.propTypes = {
  ptTotalPerkPoint: PropTypes.string,
  ptAvailablePerkPoint: PropTypes.string,
  frame: PropTypes.string,
};
