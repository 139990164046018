// downloaded data!
export const data = [
  {
    "key": "history_1_sas",
    "name": "loc_data_string_backgrounds_name_history_1_sas",
    "decision": "2",
    "bravery": "2",
    "focus": "2",
    "reaction": "2",
    "toughness": "2",
    "precision": "2",
    "vocations": "SG, SMG, AR, DMR, SR",
    "modifiers": "mod_agent_16_agenda_frequent, mod_agent_17_agenda_safety, mod_agent_18_agenda_wellbeing",
    "contract": "incentive",
    "nationalities": "nation_7_uk",
    "area": "2",
    "traits": "trait_43_pragmatic, trait_23_ironwill, trait_17_tactical",
    "trait_rule": "all",
    "ambition": "ambition_1_honor",
    "ambition_modifiers": "mod_agent_14_agenda_weak, mod_agent_19_agenda_complicated",
    "ambition_traits_gain": "",
    "ambition_traits_lose": "",
    "descr": "영국 최정예 특수부대 SAS에서 훈련 받았습니다. 자신의 능력을 올바르게 활용할 고용주를 바라고 있습니다.",
    "background_perk_group": "soldier"
  },
  {
    "key": "history_2_swiss",
    "name": "loc_data_string_backgrounds_name_history_2_swiss",
    "decision": "2",
    "bravery": "4",
    "focus": "2",
    "reaction": "4",
    "toughness": "2",
    "precision": "2",
    "vocations": "SG, SMG, AR, DMR, SR",
    "modifiers": "mod_agent_16_agenda_frequent, mod_agent_18_agenda_wellbeing",
    "contract": "",
    "nationalities": "nation_31_swi",
    "area": "2",
    "traits": "trait_42_proud, trait_18_loyal, trait_30_perfectionist",
    "trait_rule": "all",
    "ambition": "ambition_1_honor",
    "ambition_modifiers": "mod_agent_14_agenda_weak, mod_agent_19_agenda_complicated",
    "ambition_traits_gain": "",
    "ambition_traits_lose": "",
    "descr": "500년 전통의 교황청 근위대의 일원이었지만 자신의 실력을 더 유용하게 쓸 수 있는 곳을 찾아 떠났습니다.",
    "background_perk_group": "soldier"
  },
  {
    "key": "history_3_officer",
    "name": "loc_data_string_backgrounds_name_history_3_officer",
    "decision": "2",
    "bravery": "1",
    "focus": "1",
    "reaction": "0",
    "toughness": "-1",
    "precision": "-1",
    "vocations": "SG, SMG, AR, DMR, SR",
    "modifiers": "",
    "contract": "",
    "nationalities": "",
    "area": "2",
    "traits": "trait_30_perfectionist, trait_43_pragmatic",
    "trait_rule": "one",
    "ambition": "ambition_1_honor",
    "ambition_modifiers": "",
    "ambition_traits_gain": "trait_44_openmind",
    "ambition_traits_lose": "",
    "descr": "명령 불복종으로 퇴역했지만 장래를 촉망받는 뛰어난 장교였습니다.",
    "background_perk_group": "soldier"
  },
  {
    "key": "history_4_gurkha",
    "name": "loc_data_string_backgrounds_name_history_4_gurkha",
    "decision": "2",
    "bravery": "2",
    "focus": "4",
    "reaction": "2",
    "toughness": "4",
    "precision": "2",
    "vocations": "SG, SMG, AR, DMR, SR",
    "modifiers": "mod_agent_16_agenda_frequent, mod_agent_17_agenda_safety, mod_agent_18_agenda_wellbeing",
    "contract": "standard",
    "nationalities": "nation_20_nep, nation_37_ind",
    "area": "2",
    "traits": "trait_29_strong, trait_31_improviser, trait_11_resilient",
    "trait_rule": "all",
    "ambition": "ambition_2_deposit",
    "ambition_modifiers": "mod_agent_14_agenda_weak, mod_agent_19_agenda_complicated",
    "ambition_traits_gain": "",
    "ambition_traits_lose": "",
    "descr": "네팔의 험난한 고산지대에서 성장하고 오랜 전통의 구르카 부대에서 훈련 받았습니다. 큰 돈을 모아 고향의 가족들이 풍족한 생활을 하게 되기를 바랍니다.",
    "background_perk_group": "soldier"
  },
  {
    "key": "history_5_gambler",
    "name": "loc_data_string_backgrounds_name_history_5_gambler",
    "decision": "1",
    "bravery": "-2",
    "focus": "1",
    "reaction": "0",
    "toughness": "0",
    "precision": "0",
    "vocations": "SG, AR",
    "modifiers": "mod_agent_13_agenda_strong",
    "contract": "",
    "nationalities": "",
    "area": "0",
    "traits": "trait_9_impulsive, trait_10_intuitive",
    "trait_rule": "all",
    "ambition": "ambition_1_honor",
    "ambition_modifiers": "mod_agent_8_growth_power",
    "ambition_traits_gain": "trait_2_kind",
    "ambition_traits_lose": "trait_9_impulsive",
    "descr": "도박에 중독되어 가족과 전재산을 잃었습니다. 삶의 이유를 다시 찾게 되면 새롭게 태어날 수도 있습니다.",
    "background_perk_group": "commoner"
  },
  {
    "key": "history_6_noble",
    "name": "loc_data_string_backgrounds_name_history_6_noble",
    "decision": "1",
    "bravery": "0",
    "focus": "1",
    "reaction": "0",
    "toughness": "-1",
    "precision": "0",
    "vocations": "AR, DMR, SR",
    "modifiers": "mod_agent_5_disable_prompt",
    "contract": "",
    "nationalities": "",
    "area": "0",
    "traits": "trait_42_proud, trait_37_romantic",
    "trait_rule": "all",
    "ambition": "ambition_1_honor",
    "ambition_modifiers": "mod_agent_6_morale_high",
    "ambition_traits_gain": "trait_40_humble",
    "ambition_traits_lose": "trait_42_proud",
    "descr": "상속 갈등으로 인해 가문에서 추방된 상류층 자제입니다. 오만하고 현실감각이 떨어지지만 성장 가능성이 있습니다.",
    "background_perk_group": "bourgeois"
  },
  {
    "key": "history_7_revolution",
    "name": "loc_data_string_backgrounds_name_history_7_revolution",
    "decision": "0",
    "bravery": "2",
    "focus": "0",
    "reaction": "0",
    "toughness": "-1",
    "precision": "0",
    "vocations": "SMG",
    "modifiers": "mod_agent_7_morale_low",
    "contract": "",
    "nationalities": "nation_8_ira, nation_26_lib, nation_32_arg, nation_35_yug",
    "area": "0",
    "traits": "trait_22_philosophical, trait_24_depressive, trait_36_revengeful",
    "trait_rule": "all",
    "ambition": "ambition_4_expert",
    "ambition_modifiers": "mod_agent_6_morale_high",
    "ambition_traits_gain": "trait_33_ambitious",
    "ambition_traits_lose": "trait_24_depressive, trait_36_revengeful",
    "descr": "조국에서 혁명을 시도했지만 실패하여 망명했습니다. 정부에 대한 원한과 동지들에 대한 죄책감에 시달리지만 전투 기술을 배워 다시 혁명을 시도할 기회를 꿈꾸고 있습니다.",
    "background_perk_group": "agitator"
  },
  {
    "key": "history_8_gangster",
    "name": "loc_data_string_backgrounds_name_history_8_gangster",
    "decision": "-1",
    "bravery": "-1",
    "focus": "1",
    "reaction": "1",
    "toughness": "0",
    "precision": "0",
    "vocations": "SMG, AR",
    "modifiers": "",
    "contract": "",
    "nationalities": "",
    "area": "0",
    "traits": "trait_16_cunning, trait_39_merciless",
    "trait_rule": "one",
    "ambition": "ambition_2_deposit",
    "ambition_modifiers": "",
    "ambition_traits_gain": "trait_18_loyal",
    "ambition_traits_lose": "",
    "descr": "거리에서 자랐으며 범죄와 갱단의 폭력 속에서 어떻게든 살아남았습니다. 자신의 실력을 더 소중히 여길 조직을 찾아 갱을 떠났습니다.",
    "background_perk_group": "thug"
  },
  {
    "key": "history_9_sailor",
    "name": "loc_data_string_backgrounds_name_history_9_sailor",
    "decision": "-1",
    "bravery": "0",
    "focus": "-1",
    "reaction": "0",
    "toughness": "1",
    "precision": "1",
    "vocations": "SG, AR",
    "modifiers": "",
    "contract": "",
    "nationalities": "",
    "area": "0",
    "traits": "trait_1_hawkeye, trait_20_free",
    "trait_rule": "one",
    "ambition": "ambition_2_deposit",
    "ambition_modifiers": "",
    "ambition_traits_gain": "trait_7_cautious",
    "ambition_traits_lose": "",
    "descr": "평생 바다를 누비며 폭풍우와 해적에 맞서 싸웠습니다. 한 곳에 오래 머무는 것에 익숙하지 않습니다.",
    "background_perk_group": "bluecollar"
  },
  {
    "key": "history_10_pirate",
    "name": "loc_data_string_backgrounds_name_history_10_pirate",
    "decision": "-1",
    "bravery": "-1",
    "focus": "-1",
    "reaction": "1",
    "toughness": "1",
    "precision": "1",
    "vocations": "AR",
    "modifiers": "",
    "contract": "incentive",
    "nationalities": "",
    "area": "0",
    "traits": "trait_39_merciless, trait_20_free",
    "trait_rule": "one",
    "ambition": "ambition_2_deposit",
    "ambition_modifiers": "",
    "ambition_traits_gain": "trait_18_loyal ",
    "ambition_traits_lose": "",
    "descr": "카리브 해에서 상선과 유람선을 약탈하며 살아왔습니다. 더 큰 돈을 노리고 용병이 되었습니다.",
    "background_perk_group": "thug"
  },
  {
    "key": "history_11_drugdealer",
    "name": "loc_data_string_backgrounds_name_history_11_drugdealer",
    "decision": "0",
    "bravery": "-1",
    "focus": "0",
    "reaction": "0",
    "toughness": "0",
    "precision": "0",
    "vocations": "SMG, AR",
    "modifiers": "mod_agent_19_agenda_complicated",
    "contract": "",
    "nationalities": "nation_1_col",
    "area": "0",
    "traits": "trait_39_merciless, trait_3_rash",
    "trait_rule": "one",
    "ambition": "ambition_3_success",
    "ambition_modifiers": "",
    "ambition_traits_gain": "trait_18_loyal ",
    "ambition_traits_lose": "",
    "descr": "마약 유통은 가난에서 탈출할 수 있는 유일한 방법처럼 보였습니다. 하지만 마약의 폐해에 죄책감을 느껴 다른 일을 찾아 나섰습니다.",
    "background_perk_group": "thug"
  },
  {
    "key": "history_12_convict",
    "name": "loc_data_string_backgrounds_name_history_12_convict",
    "decision": "0",
    "bravery": "0",
    "focus": "0",
    "reaction": "0",
    "toughness": "0",
    "precision": "0",
    "vocations": "SG, SMG, AR",
    "modifiers": "",
    "contract": "",
    "nationalities": "",
    "area": "0",
    "traits": "trait_14_adaptive, trait_9_impulsive",
    "trait_rule": "one",
    "ambition": "ambition_3_success",
    "ambition_modifiers": "",
    "ambition_traits_gain": "trait_18_loyal ",
    "ambition_traits_lose": "",
    "descr": "범죄를 저질러 징역형을 살고 나온 그가 선택할 수 있는 직업은 별로 많지 않았습니다.",
    "background_perk_group": "thug"
  },
  {
    "key": "history_13_snitch",
    "name": "loc_data_string_backgrounds_name_history_13_snitch",
    "decision": "0",
    "bravery": "1",
    "focus": "0",
    "reaction": "0",
    "toughness": "-1",
    "precision": "0",
    "vocations": "SG, SMG",
    "modifiers": "mod_agent_19_agenda_complicated",
    "contract": "",
    "nationalities": "",
    "area": "0",
    "traits": "trait_2_kind, trait_36_revengeful, trait_32_brave",
    "trait_rule": "one",
    "ambition": "ambition_3_success",
    "ambition_modifiers": "",
    "ambition_traits_gain": "trait_42_proud ",
    "ambition_traits_lose": "",
    "descr": "양심의 가책을 느껴 조직의 부패를 폭로하고 도피 중입니다.",
    "background_perk_group": "whitecollar"
  },
  {
    "key": "history_14_graduate",
    "name": "loc_data_string_backgrounds_name_history_14_graduate",
    "decision": "2",
    "bravery": "0",
    "focus": "1",
    "reaction": "0",
    "toughness": "-1",
    "precision": "-1",
    "vocations": "SG, SMG, AR, DMR, SR",
    "modifiers": "mod_agent_19_agenda_complicated, mod_agent_14_agenda_weak",
    "contract": "",
    "nationalities": "",
    "area": "0",
    "traits": "trait_30_perfectionist, trait_21_idealist",
    "trait_rule": "all",
    "ambition": "ambition_3_success",
    "ambition_modifiers": "",
    "ambition_traits_gain": "trait_33_ambitious ",
    "ambition_traits_lose": "",
    "descr": "연구와 학문에 염증을 느끼고 자퇴했습니다. 이론을 중시하는 경향을 보입니다.",
    "background_perk_group": "whitecollar"
  },
  {
    "key": "history_15_mania",
    "name": "loc_data_string_backgrounds_name_history_15_mania",
    "decision": "0",
    "bravery": "1",
    "focus": "1",
    "reaction": "0",
    "toughness": "-1",
    "precision": "-1",
    "vocations": "SG, SMG, AR, DMR, SR",
    "modifiers": "mod_agent_14_agenda_weak",
    "contract": "",
    "nationalities": "",
    "area": "0",
    "traits": "trait_28_inventive, trait_5_optimist",
    "trait_rule": "one",
    "ambition": "ambition_7_hobby",
    "ambition_modifiers": "",
    "ambition_traits_gain": "trait_17_tactical ",
    "ambition_traits_lose": "",
    "descr": "평생 현대전과 무기에 대한 로망을 불태웠습니다. 마침내 자신의 꿈을 이루기 위해 목숨을 걸기로 했습니다.",
    "background_perk_group": "bourgeois"
  },
  {
    "key": "history_16_miner",
    "name": "loc_data_string_backgrounds_name_history_16_miner",
    "decision": "0",
    "bravery": "0",
    "focus": "0",
    "reaction": "0",
    "toughness": "1",
    "precision": "-1",
    "vocations": "SMG, AR",
    "modifiers": "",
    "contract": "",
    "nationalities": "",
    "area": "0",
    "traits": "trait_41_shortsight, trait_29_strong, trait_34_conservative",
    "trait_rule": "one",
    "ambition": "ambition_5_onboard",
    "ambition_modifiers": "",
    "ambition_traits_gain": "trait_44_openmind ",
    "ambition_traits_lose": "",
    "descr": "광산에서 일하다 벌어진 매몰사고에서 가까스로 살아남았습니다. 어차피 목숨 걸고 일할 거면 더 많은 돈을 받겠다는 생각에 용병이 되었습니다.",
    "background_perk_group": "bluecollar"
  },
  {
    "key": "history_17_farmer",
    "name": "loc_data_string_backgrounds_name_history_17_farmer",
    "decision": "0",
    "bravery": "0",
    "focus": "0",
    "reaction": "0",
    "toughness": "0",
    "precision": "0",
    "vocations": "SG, AR",
    "modifiers": "",
    "contract": "standard",
    "nationalities": "",
    "area": "0",
    "traits": "trait_7_cautious, trait_18_loyal",
    "trait_rule": "one",
    "ambition": "ambition_5_onboard",
    "ambition_modifiers": "",
    "ambition_traits_gain": "trait_14_adaptive",
    "ambition_traits_lose": "",
    "descr": "기업의 이해관계로 인해 농사 짓던 땅에서 쫓겨나 새 직업을 찾아 왔습니다.",
    "background_perk_group": "bluecollar"
  },
  {
    "key": "history_18_football",
    "name": "loc_data_string_backgrounds_name_history_18_football",
    "decision": "-1",
    "bravery": "-1",
    "focus": "-1",
    "reaction": "2",
    "toughness": "1",
    "precision": "0",
    "vocations": "SG, AR",
    "modifiers": "",
    "contract": "",
    "nationalities": "",
    "area": "0",
    "traits": "trait_29_strong, trait_8_agile, trait_11_resilient",
    "trait_rule": "one",
    "ambition": "ambition_5_onboard",
    "ambition_modifiers": "",
    "ambition_traits_gain": "trait_18_loyal",
    "ambition_traits_lose": "",
    "descr": "축구 유망주였지만 프로선수가 되지 못했습니다. 뛰어난 신체능력을 써먹기 위해 용병 일에 뛰어들었습니다.",
    "background_perk_group": "bluecollar"
  },
  {
    "key": "history_19_refuge",
    "name": "loc_data_string_backgrounds_name_history_19_refuge",
    "decision": "0",
    "bravery": "0",
    "focus": "0",
    "reaction": "0",
    "toughness": "0",
    "precision": "0",
    "vocations": "SG, SMG, AR, DMR, SR",
    "modifiers": "mod_agent_19_agenda_complicated",
    "contract": "",
    "nationalities": "nation_21_nic, nation_28_sud, nation_5_viet, nation_25_rwa",
    "area": "0",
    "traits": "trait_4_pessimist, trait_24_depressive",
    "trait_rule": "all",
    "ambition": "ambition_5_onboard",
    "ambition_modifiers": "",
    "ambition_traits_gain": "trait_14_adaptive",
    "ambition_traits_lose": "trait_4_pessimist, trait_24_depressive",
    "descr": "내전과 혼란, 폭정을 피해 조국을 탈출했습니다. 기반도 특별한 기술도 없이 할 수 있는 일은 이것뿐이었습니다.",
    "background_perk_group": "commoner"
  },
  {
    "key": "history_20_deserter",
    "name": "loc_data_string_backgrounds_name_history_20_deserter",
    "decision": "1",
    "bravery": "-3",
    "focus": "1",
    "reaction": "0",
    "toughness": "0",
    "precision": "1",
    "vocations": "AR, DMR, SR",
    "modifiers": "mod_agent_17_agenda_safety",
    "contract": "",
    "nationalities": "",
    "area": "0",
    "traits": "trait_24_depressive, trait_13_skeptical, trait_10_intuitive",
    "trait_rule": "all",
    "ambition": "ambition_5_onboard",
    "ambition_modifiers": "",
    "ambition_traits_gain": "trait_18_loyal",
    "ambition_traits_lose": "trait_24_depressive, trait_13_skeptical",
    "descr": "비윤리적인 작전을 수행했다가 죄책감을 견디지 못해 탈영했습니다.",
    "background_perk_group": "soldier"
  },
  {
    "key": "history_21_cultist",
    "name": "loc_data_string_backgrounds_name_history_21_cultist",
    "decision": "-1",
    "bravery": "-2",
    "focus": "1",
    "reaction": "1",
    "toughness": "0",
    "precision": "1",
    "vocations": "AR, DMR",
    "modifiers": "mod_agent_19_agenda_complicated",
    "contract": "",
    "nationalities": "",
    "area": "0",
    "traits": "trait_9_impulsive, trait_4_pessimist, trait_46_mumbling",
    "trait_rule": "one",
    "ambition": "ambition_5_onboard",
    "ambition_modifiers": "",
    "ambition_traits_gain": "trait_5_optimist ",
    "ambition_traits_lose": "",
    "descr": "사이비 종교에 빠졌다가 겨우 빠져나왔습니다. 옛 동료들을 피하기 위해 세상을 떠돕니다.",
    "background_perk_group": "commoner"
  },
  {
    "key": "history_22_poacher",
    "name": "loc_data_string_backgrounds_name_history_22_poacher",
    "decision": "-1",
    "bravery": "-1",
    "focus": "0",
    "reaction": "1",
    "toughness": "0",
    "precision": "1",
    "vocations": "SG, DMR, SR",
    "modifiers": "",
    "contract": "",
    "nationalities": "",
    "area": "0",
    "traits": "trait_20_free, trait_15_observing",
    "trait_rule": "one",
    "ambition": "ambition_12_desmar",
    "ambition_modifiers": "",
    "ambition_traits_gain": "trait_39_merciless ",
    "ambition_traits_lose": "",
    "descr": "밀림을 헤집고 다니며 진귀한 동물을 잡아 팔면서 생계를 유지했지만 동료의 배신으로 체포됐고 모든 걸 잃었습니다. 사람 잡는 기술을 배우러 용병이 되었습니다.",
    "background_perk_group": "thug"
  },
  {
    "key": "history_23_police",
    "name": "loc_data_string_backgrounds_name_history_23_police",
    "decision": "1",
    "bravery": "0",
    "focus": "1",
    "reaction": "0",
    "toughness": "0",
    "precision": "-1",
    "vocations": "SG, SMG, AR, DMR, SR",
    "modifiers": "",
    "contract": "",
    "nationalities": "",
    "area": "1",
    "traits": "trait_17_tactical, trait_42_proud",
    "trait_rule": "one",
    "ambition": "ambition_1_honor",
    "ambition_modifiers": "",
    "ambition_traits_gain": "trait_23_ironwill ",
    "ambition_traits_lose": "",
    "descr": "부패와 관료주의에 환멸을 느껴 경찰을 떠났습니다. 아직 자신이 정의의 길에서 벗어나지 않았다고 믿습니다.",
    "background_perk_group": "soldier"
  },
  {
    "key": "history_24_bountyhunter",
    "name": "loc_data_string_backgrounds_name_history_24_bountyhunter",
    "decision": "0",
    "bravery": "0",
    "focus": "0",
    "reaction": "0",
    "toughness": "0",
    "precision": "1",
    "vocations": "DMR, SR",
    "modifiers": "",
    "contract": "",
    "nationalities": "",
    "area": "1",
    "traits": "trait_6_loner, trait_15_observing",
    "trait_rule": "one",
    "ambition": "ambition_2_deposit",
    "ambition_modifiers": "",
    "ambition_traits_gain": "trait_5_optimist ",
    "ambition_traits_lose": "",
    "descr": "한때 도망자를 추적하는 것이 생업이었습니다. 혼자 다니는 것에 익숙합니다.",
    "background_perk_group": "thug"
  },
  {
    "key": "history_25_guerilla",
    "name": "loc_data_string_backgrounds_name_history_25_guerilla",
    "decision": "-1",
    "bravery": "1",
    "focus": "0",
    "reaction": "1",
    "toughness": "1",
    "precision": "-1",
    "vocations": "SG, SMG, AR, DMR, SR",
    "modifiers": "",
    "contract": "standard",
    "nationalities": "",
    "area": "1",
    "traits": "trait_14_adaptive, trait_31_improviser, trait_13_skeptical",
    "trait_rule": "one",
    "ambition": "ambition_3_success",
    "ambition_modifiers": "",
    "ambition_traits_gain": "trait_42_proud",
    "ambition_traits_lose": "",
    "descr": "남아메리카의 정글에서 수년간 전투를 치렀지만 성과 없는 싸움에 지쳐 포기했습니다. 이제 안정된 삶을 위해 돈을 버는 게 목표입니다.",
    "background_perk_group": "soldier"
  },
  {
    "key": "history_26_espionage",
    "name": "loc_data_string_backgrounds_name_history_26_espionage",
    "decision": "0",
    "bravery": "-1",
    "focus": "0",
    "reaction": "1",
    "toughness": "0",
    "precision": "1",
    "vocations": "SMG, AR",
    "modifiers": "",
    "contract": "",
    "nationalities": "",
    "area": "1",
    "traits": "trait_7_cautious, trait_16_cunning, trait_15_observing",
    "trait_rule": "one",
    "ambition": "ambition_3_success",
    "ambition_modifiers": "",
    "ambition_traits_gain": "trait_32_brave ",
    "ambition_traits_lose": "",
    "descr": "기업의 정보를 빼돌리는 일을 오랫동안 했지만 결국 발각됐고, 체포를 피해 용병이 되었습니다.",
    "background_perk_group": "whitecollar"
  },
  {
    "key": "history_27_circus",
    "name": "loc_data_string_backgrounds_name_history_27_circus",
    "decision": "-1",
    "bravery": "0",
    "focus": "0",
    "reaction": "2",
    "toughness": "1",
    "precision": "-1",
    "vocations": "SMG",
    "modifiers": "",
    "contract": "",
    "nationalities": "",
    "area": "1",
    "traits": "trait_8_agile, trait_11_resilient, trait_35_rhetoric",
    "trait_rule": "one",
    "ambition": "ambition_3_success",
    "ambition_modifiers": "",
    "ambition_traits_gain": "trait_42_proud ",
    "ambition_traits_lose": "",
    "descr": "어렸을 때부터 서커스단에서 자라 곡예와 쇼맨십에 능숙합니다.",
    "background_perk_group": "bluecollar"
  },
  {
    "key": "history_28_priest",
    "name": "loc_data_string_backgrounds_name_history_28_priest",
    "decision": "0",
    "bravery": "2",
    "focus": "-1",
    "reaction": "0",
    "toughness": "0",
    "precision": "0",
    "vocations": "SMG, AR",
    "modifiers": "",
    "contract": "standard",
    "nationalities": "",
    "area": "1",
    "traits": "trait_2_kind, trait_19_stoic, trait_21_idealist",
    "trait_rule": "one",
    "ambition": "ambition_8_commed",
    "ambition_modifiers": "mod_agent_6_morale_high",
    "ambition_traits_gain": "trait_23_ironwill",
    "ambition_traits_lose": "",
    "descr": "신의 뜻을 받들기 위한 성전을 결심, 스스로 교단에서 제명하고 전장에 뛰어들었습니다.",
    "background_perk_group": "agitator"
  },
  {
    "key": "history_29_scientist",
    "name": "loc_data_string_backgrounds_name_history_29_scientist",
    "decision": "2",
    "bravery": "2",
    "focus": "3",
    "reaction": "-2",
    "toughness": "-2",
    "precision": "-2",
    "vocations": "SMG",
    "modifiers": "mod_agent_6_morale_high",
    "contract": "",
    "nationalities": "",
    "area": "1",
    "traits": "trait_45_ridiculous, trait_28_inventive, trait_30_perfectionist",
    "trait_rule": "all",
    "ambition": "ambition_10_grenadier",
    "ambition_modifiers": "",
    "ambition_traits_gain": "trait_39_merciless ",
    "ambition_traits_lose": "",
    "descr": "비윤리적인 연구로 논란이 되어 학계에서 추방당했습니다. 하지만 폭발에 대한 열정은 죽지 않았습니다.",
    "background_perk_group": "whitecollar"
  },
  {
    "key": "history_30_artist",
    "name": "loc_data_string_backgrounds_name_history_30_artist",
    "decision": "0",
    "bravery": "-3",
    "focus": "3",
    "reaction": "0",
    "toughness": "-1",
    "precision": "2",
    "vocations": "SMG",
    "modifiers": "",
    "contract": "",
    "nationalities": "",
    "area": "1",
    "traits": "trait_20_free, trait_47_weak, trait_30_perfectionist",
    "trait_rule": "all",
    "ambition": "ambition_10_grenadier",
    "ambition_modifiers": "",
    "ambition_traits_gain": "trait_39_merciless ",
    "ambition_traits_lose": "trait_47_weak, trait_30_perfectionist",
    "descr": "거침없는 풍자와 사회비판 끝에 권력자의 미움을 산 예술가입니다.",
    "background_perk_group": "agitator"
  },
  {
    "key": "history_31_developer",
    "name": "게임개발자",
    "decision": "0",
    "bravery": "0",
    "focus": "0",
    "reaction": "0",
    "toughness": "0",
    "precision": "0",
    "vocations": "SG",
    "modifiers": "mod_agent_7_morale_low",
    "contract": "standard",
    "nationalities": "nation_22_rok",
    "area": "1",
    "traits": "trait_9_impulsive, trait_20_free, trait_24_depressive, trait_38_paranoid, trait_47_weak, trait_48_unfocused",
    "trait_rule": "all",
    "ambition": "ambition_1_honor",
    "ambition_modifiers": "mod_agent_6_morale_high",
    "ambition_traits_gain": "trait_19_stoic, trait_23_ironwill, trait_32_brave, trait_29_strong",
    "ambition_traits_lose": "trait_9_impulsive, trait_24_depressive, trait_38_paranoid, trait_47_weak, trait_48_unfocused",
    "descr": "[출시 전 삭제 요망]이 한국인 게임 개발자는 인생의 실패를 겪고 콜롬비아까지 떠내려 왔습니다. 당신은 이 엄청난 폐급에게서 불가사의한 잠재력을 느낍니다.",
    "background_perk_group": "bourgeois"
  }
];