// downloaded data!
export const data = [
  {
    "key": "all_or_nothing",
    "message": "교전 중 쓰러진 용병은 큰 돈과 시간을 들여야 회복시킬 수 있습니다."
  },
  {
    "key": "renown",
    "message": "명성 레벨을 올리면 더 도전적이고 더 많은 보상을 주는 임무를 수행할 수 있습니다."
  },
  {
    "key": "training",
    "message": "용병을 잘 훈련시키세요: 훈련을 통해 용병의 전투 역량을 크게 강화할 수 있습니다."
  },
  {
    "key": "market",
    "message": "좋은 화기를 통해 용병의 화력을 크게 올릴 수 있습니다. 좋은 방어구로 용병의 교전 지속력과 생존성을 크게 개선할 수 있습니다."
  },
  {
    "key": "task",
    "message": "영입 메뉴와 시장 메뉴를 통해 실력있는 용병과 좋은 장비를 확보할 수 있습니다."
  },
  {
    "key": "training_maintanance",
    "message": "훈련장을 잘 관리하세요: 낙후된 훈련장에서는 용병을 적절하게 훈련시킬 수 없습니다."
  },
  {
    "key": "safehouse",
    "message": "작전 가능 영역을 확장하면 더 많은 의뢰에 접근할 수 있습니다."
  },
  {
    "key": "base",
    "message": "거점의 시설을 업그레이드하세요. 다양한 효과를 제공하며 성장의 강력한 동력이 될 것입니다."
  },
  {
    "key": "risk",
    "message": "의뢰 수락 전, 기대 결과를 주의 깊게 살피세요. 항상 맞는 것은 아니지만 위험을 통제하는데 도움이 됩니다."
  },
  {
    "key": "cashflow",
    "message": "현금 흐름을 잘 관리하세요: 회사의 번레이트가 너무 높은 경우, 회사를 유지하기 어려울 수 있습니다."
  },
  {
    "key": "preset",
    "message": "임무 배경을 주시하세요: 임무 배경에 따라 의뢰에 참여하는 용병을 주의깊게 선택해야 할 수 있습니다."
  },
  {
    "key": "perk",
    "message": "임무를 성공시킨 용병은 경험치를 획득합니다. 경험치가 최대치에 도달하면 레벨이 상승하며 퍽 포인트를 획득합니다."
  },
  {
    "key": "aptitude",
    "message": "임무 중 피해를 입을 때마다 용병들의 사기가 떨어집니다. 사기가 바닥나면 용병들은 임무를 포기하고 후퇴합니다."
  },
  {
    "key": "throwable",
    "message": "수류탄을 잘 활용하면 좁은 공간에 모여있는 적을 쉽게 제압할 수 있습니다."
  },
  {
    "key": "potential",
    "message": "용병마다 성장 속도와 성장 한계가 다릅니다: 용병을 신중하게 영입하세요."
  },
  {
    "key": "agentcount",
    "message": "거점마다 배치할 수 있는 용병의 수의 제한이 있습니다. 더 많은 용병을 한 거점에 배치하고 싶다면 거점을 업그레이드하세요."
  },
  {
    "key": "agentrole",
    "message": "몇몇 용병은 임무 중 특수한 역할을 수행할 수 있습니다: 예를 들어, 의료 지식이 있는 용병은 부상당한 다른 용병을 응급처치할 수 있습니다. 특수한 역할은 퍽을 통해 부여할 수 있습니다."
  },
  {
    "key": "contract",
    "message": "용병마다 요구하는 계약 조건이 다릅니다: 표준 계약은 계약 기간동안 일정한 지출이 발생하지만, 임무 수행에 따른 추가 지출이 없습니다. 인센티브 계약의 경우 고정 비용이 낮지만, 용병이 임무에 참여할 경우 수당을 지급하고 성공 시 임무 보상의 일부를 추가로 지급해야 합니다."
  }
];