import { data as sourceMissions } from '../downloaded/missions.mjs';
import { data as sourceRenownBand } from '../downloaded/renownBand.mjs';
import { data as sourceOnboarding } from '../downloaded/onboardingMissions.mjs';

export const onboardings = sourceOnboarding.map(row => {
  const { index, tick, world_idx, renown } = row;
  return {
    index: 0 | index,
    tick: 0 | tick,
    world_idx: 0 | world_idx,
    renown: 0 | renown,
  };
});

export const missions0 = [];

for (const row of sourceMissions) {
  let { stability_level, mission_level, difficulty,
    training, threats_min, threats_max, agents_max,
    reward_resource_min, reward_resource_max, weight,
    reward_firearm_tier_min, reward_firearm_tier_max,
    reward_equipment_tier_min, reward_equipment_tier_max,
    reward_agent_power_min, reward_agent_power_max,
    reward_throwable_type, reward_resource_add_amount,
    total_contribution_rate, event_group,
    renown_gain, newbie,
    drop_item_tier, drop_item_prob, drop_item_ty, exp } = row;

  mission_level = 0 | mission_level;
  difficulty = 0 | difficulty;
  stability_level = stability_level.split(', ');
  training = parseInt(training);
  threats_min = parseInt(threats_min);
  threats_max = parseInt(threats_max);
  agents_max = parseInt(agents_max);
  reward_resource_min = parseInt(reward_resource_min);
  reward_resource_max = parseInt(reward_resource_max);
  weight = parseFloat(weight) * 10;
  reward_firearm_tier_min = parseInt(reward_firearm_tier_min);
  reward_firearm_tier_max = parseInt(reward_firearm_tier_max);
  reward_equipment_tier_min = parseInt(reward_equipment_tier_min);
  reward_equipment_tier_max = parseInt(reward_equipment_tier_max);
  reward_agent_power_min = parseInt(reward_agent_power_min);
  reward_agent_power_max = parseInt(reward_agent_power_max);
  reward_resource_add_amount = parseFloat(reward_resource_add_amount);
  total_contribution_rate = parseFloat(total_contribution_rate);
  renown_gain = parseInt(renown_gain);
  newbie = newbie === '1';
  drop_item_tier = parseInt(drop_item_tier);
  drop_item_prob = parseInt(drop_item_prob);
  exp = parseInt(exp);


  missions0.push({
    stability_level, mission_level, difficulty,
    training, threats_min, threats_max, agents_max,
    reward_resource_min, reward_resource_max, weight,
    reward_firearm_tier_min, reward_firearm_tier_max,
    reward_equipment_tier_min, reward_equipment_tier_max,
    reward_agent_power_min, reward_agent_power_max,
    reward_throwable_type, reward_resource_add_amount,
    total_contribution_rate, event_group,
    renown_gain, newbie,
    drop_item_tier, drop_item_prob, drop_item_ty, exp,

    maps: row.maps.split(',').map(m => m.trim()),
  });
}

export function sampleMission(rng, sample_function) {
  const entries = missions0.filter(sample_function);
  let cur = 0;
  const cdf = [];
  for (const entry of entries) {
    cur += entry.weight;
    cdf.push(cur);
  }

  let sample = rng.range(0, cur);
  for (let i = 0; i < entries.length; i++) {
    if (sample < cdf[i]) {
      return entries[i];
    }
  }

  // unreachable
  throw new Error('unreachable');
}


export const renownBand = [];

for (const row of sourceRenownBand) {
  let { areaNum, min, max } = row;

  areaNum = parseInt(areaNum);
  min = parseInt(min);
  max = parseInt(max);

  renownBand.push({
    areaNum, min, max,
  });
}
