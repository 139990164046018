import './App.css';
import React from 'react';

import { presets, preset_default } from './presets_mission';
import { Rng } from './rand';
import { SimView } from './SimView';
import * as extobj from './extobj';
import { SAMPLE } from './extobj.mjs';

export class UETest extends React.Component {
  constructor(props) {
    super(props);

    const seed = Rng.randomseed();

    this.serializeRef = React.createRef();
    this.simRef = React.createRef();
    this.winCounterRef = React.createRef();
    this.restartTimer = null;

    this.onExport = this.onExport.bind(this);
    this.onImport = this.onImport.bind(this);
    this.onSeed = this.onSeed.bind(this);
    this.onPreset = this.onPreset.bind(this);
    this.onFinish = this.onFinish.bind(this);

    let preset_selected = window.localStorage.getItem('preset_selected_uetest');
    window.localStorage.removeItem('preset_selected_uetest');
    if (!presets[preset_selected]) {
      preset_selected = preset_default;
    }

    this.state = {
      search_term: '',

      presets,
      preset_selected,
      simstate: null,

      seed,
      started: false,

      invis: false,
      mute_bgm: false,
    };
  }

  componentDidMount() {
    const { preset_selected } = this.state;

    if (window.ue?.connection?.onsyncobs) {
      window.ue.connection.onsyncobs();
      setTimeout(() => {
        this.onPreset(preset_selected);
      }, 100);
    } else {
      this.onPreset(preset_selected);
    }
  }

  onChangeConfig(entities) {
    this.setState({ entities });
  }

  onExport() {
    const state = {
      seed: this.simRef.current.state.seed,
      preset: this.state.preset_selected,
      simstate: { ...this.state.simstate },
    }

    const text = JSON.stringify(state);
    this.serializeRef.current.value = text;
  }

  onImport() {
    const state = JSON.parse(this.serializeRef.current.value);
    this.setState({
      seed: state.seed,
      preset_selected: state.preset,
      simstate: state.simstate,
    }, () => {
      // this.simRef.current.restart();
    });
  }

  onSeed(seed) {
    const { presets, preset_selected } = this.state;
    this.setState({
      seed,
      simstate: presets[preset_selected](seed),
    }, () => {
      // this.simRef.current.restart();
    });
  }

  onPreset(p, invis) {
    const { presets, seed } = this.state;
    const simstate = presets[p](seed);

    if (p === 'ext') {
      // pull objects from ue
      let spec = SAMPLE;
      if (window.ueobs) {
        spec = JSON.parse(window.ueobs[0]);
      }

      simstate.obstacle_specs = [];
      extobj.convert_world(spec, simstate);
    }

    if (invis) {
      for (const o of simstate.obstacle_specs) {
        o.imported = true;
      }
    }

    this.setState({
      preset_selected: p,
      simstate,
      invis,
    }, () => {
      // this.simRef.current.restart();
      window.localStorage.setItem('preset_selected_uetest', p);
    });
  }

  onStart() {
    this.setState({ started: true });
  }

  onFinish(res) {
    const view = this.simRef.current;
    view?.stopTimer();

    this.setState({ started: false });
  }

  render() {
    const { search_term, invis, mute_bgm, presets, preset_selected, simstate, seed, started } = this.state;

    if (started && simstate) {
      return <SimView ref={this.simRef}
        m={this.props.m}
        seed={seed}
        debug={false}
        onFinish={(res) => this.onFinish(res)}
        autoscroll={true}
        embed={true}
        mute_bgm={mute_bgm}
        viewWidth={360}
        viewHeight={360}
        {...simstate}
      />;
    }

    const presets1 = Object.keys(presets).filter((p) => {
      if (search_term === '') {
        return true;
      }
      if (p === preset_selected) {
        return true;
      }
      return p.includes(search_term);
    });

    return <>
      <p>shortcuts: (r) restart / (R) restart with same map / (z) zoom / (Tab) change speed / ( ) pause/resume / (s) single-step</p>
      <div>
        presets <input type="text" placeholder="search" value={search_term} onChange={(e) => {
          e.stopPropagation();
          this.setState({ search_term: e.target.value });
        }}></input>
        <br />
        {presets1.map((p) => {
          const cls = (p === preset_selected) ? 'selected' : '';
          return <button className={cls} onClick={() => this.onPreset(p, invis)} key={p}>{p}</button>;
        })}
      </div>
      <div>
        save&load
        <button onClick={this.onExport}>export</button>
        <button onClick={this.onImport}>import</button>
        <input ref={this.serializeRef} type="text"></input>
      </div>
      <button onClick={() => { this.onSeed(seed) }}>reload with seed:</button>
      <input type="text" placeholder="seed" value={seed} onChange={(e) => this.setState({ seed: parseInt(e.target.value, 10) || 0 })}></input>
      <button onClick={() => { this.onSeed(Rng.randomseed()) }}> randomize </button>
      <button onClick={() => { this.onSeed(seed + 1) }}>&nbsp;+&nbsp;</button>
      <button onClick={() => { this.onSeed(seed - 1) }}>&nbsp;-&nbsp;</button>

      <br />
      <input type="checkbox" checked={invis} onChange={(e) => {
        this.onPreset(preset_selected, e.target.checked);
      }}></input>invis?
      <input type="checkbox" checked={mute_bgm} onChange={(e) => {
        this.setState({ mute_bgm: e.target.checked });
      }}></input>mute?
      |
      <button onClick={() => { this.onStart() }}>start</button>
    </>;
  }
}
