import '../style.css';

export function FH1Window(props) {
  return <div className='fh1-window' style={props.style}>
    <div className='fh1-title'>
      {props.onClickButtonBack ?
        <div className='fh1-title-right'>
          <button onClick={props.onClickButtonBack}>🢀</button>
        </div> : null
      }
      <div className='fh1-title-label'>{props.title}</div>
      {props.onClickButtonClose ?
        <div className='fh1-title-right'>
          <button onClick={props.onClickButtonClose}>🗙</button>
        </div> : null
      }
    </div>
    <div className='fh1-content'>
      {props.children}
    </div>
  </div>;
}
