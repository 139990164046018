import _ from 'lodash';

import { sampleMission, missions0, renownBand } from './data/google/processor/data_missions.mjs';
import { maps, titles as maps_titles, milestone_titles, milestone_descs, sampleMapTy } from './data/google/processor/data_maps.mjs';
import { eventNotes } from './data/google/processor/data_events.mjs'
import * as enemies from './data/google/processor/data_enemies.mjs';
import { names as names_enemy } from './names.mjs';
import * as pot from './data/google/processor/data_potential.mjs';
import { perk_apply_entity } from './data/google/processor/data_perks2.mjs';

import { createAgent, agentEffectiveStat } from './character.mjs';

import { stats2_const, entityFromStat2, updateEntityStat2, stats2_rand } from './stats2.mjs';

import { firearms, tmpl_firearm_smg_low } from './presets_firearm.mjs';
import { presets, spawnareas_ally, spawnareas_enemy } from './presets_mission.mjs';
import { gears_vest_bulletproof } from './presets_gear.mjs';

import { TICK_PER_DAY, TICK_PER_WEEK, TICK_PER_MONTH } from './tick.mjs';

export const MILESTONE_LEVEL_MULT = [0.4, 0.7, 1.0];

export function createMilestonMission(rng, turn, renown, level, fresh) {
  const config = missions0.find((mission) => mission.stability_level[0] === '마일스톤' && mission.mission_level === renown.milestoneNum + 1);

  const { mission_level, difficulty, training, threats_min, threats_max,
    reward_resource_min, reward_resource_max,
    reward_firearm_tier_min, reward_firearm_tier_max,
    reward_equipment_tier_min, reward_equipment_tier_max,
    reward_throwable_type, reward_resource_add_amount,
    total_contribution_rate, event_group, renown_gain,
    drop_item_tier, drop_item_prob, drop_item_ty, exp } = config

  const missionSeed = rng.next_seed();

  let threats = Math.max(1, Math.round(rng.integer(threats_min, threats_max) * MILESTONE_LEVEL_MULT[level]));
  let resource = rng.integer(reward_resource_min, reward_resource_max);

  let milestone_renown = Math.floor(renown.max * ((level + 1) / MILESTONE_LEVEL_MULT.length));
  let milestone_last = level === MILESTONE_LEVEL_MULT.length - 1;

  const group = milestone_last ? event_group : 'neutral';
  const eventlist = eventNotes.filter((e) => group === e.group)
  const event = rng.choice(eventlist);

  // days
  let duration = 3 * TICK_PER_MONTH;
  let expires_at = turn + duration;

  const mission = {
    title: milestone_titles[renown.milestoneNum],
    desc: milestone_descs[renown.milestoneNum],
    ty: renown.milestone,
    seed: missionSeed,
    resource,
    extraResource: 0,
    modifier: [],
    total_contribution_rate,
    event,

    milestone: true,
    milestone_last,
    milestone_renown,
    fresh,

    expires_at,
    mission_level,
    difficulty,
    renown_gain,

    area: {
      num: renown.milestoneNum,
      level,
      stability_delta: 0,
    },

    intel: {
      threats,
      extraThreats: 0,
      training,
    },

    afterReward: {
      firearm: rng.integer(reward_firearm_tier_min, reward_firearm_tier_max),
      equipment: rng.integer(reward_equipment_tier_min, reward_equipment_tier_max),
      throwable: reward_throwable_type,
      resource: reward_resource_add_amount,
    },

    drop_item_tier,
    drop_item_prob,
    drop_item_ty,

    exp,
  };

  mission.threats = instantiateMissionThreats(rng, { threats, training });
  return mission;
}

export function createMission(rng, turn, newbie, areaNum, global_modifier, modifier, fresh, sample_function) {
  let mission = null;
  for (let i = 0; i < 10; i++) {
    try {
      mission = createMission0(rng, turn, areaNum, global_modifier, modifier, fresh, sample_function);
      // 테스트 instantiate: 실패하면 새로운 seed로 미션을 다시 생성합니다.
      missionUpdateRewards(mission, rng, turn, global_modifier, newbie);
      return mission;
    } catch (e) {
      console.log(mission, e);
      continue;
    }
  }
  throw new Error('failed to instantiate mission');
}

export function createMission0(rng, turn, areaNum, global_modifier, modifier, fresh, sample_function) {
  modifier = modifier ?? [];
  const config = sampleMission(rng, sample_function);
  const { mission_level, difficulty, training, threats_min, threats_max,
    reward_firearm_tier_min, reward_firearm_tier_max,
    reward_equipment_tier_min, reward_equipment_tier_max,
    reward_throwable_type, reward_resource_add_amount,
    total_contribution_rate, event_group, renown_gain,
    drop_item_tier, drop_item_prob, drop_item_ty, exp } = config;

  const missionSeed = rng.next_seed();

  let threats = rng.integer(threats_min, threats_max);
  let extraThreats = 0;
  if (global_modifier.find((m) => m.key === 'mod_global_3_promising')) {
    extraThreats = 1;
  }
  if (global_modifier.find((m) => m.key === 'mod_global_4_youtuber')) {
    extraThreats = -1;
  }

  // fallback default
  let ty = 'embassy_short';
  if (config.maps.length > 0) {
    ty = rng.choice(config.maps);
  } else {
    ty = sampleMapTy(rng, threats).preset;
  }

  const eventlist = eventNotes.filter((e) => event_group === e.group)
  let event = rng.choice(eventlist);

  if (global_modifier.find((m) => m.key === 'mod_global_18_liquidity_mismatch') && rng.range(0, 1) < 0.25) {
    modifier.push('mod_mission_10_delayed_pay')
  }
  if (global_modifier.find((m) => m.key === 'mod_global_25_mission_supply') && rng.range(0, 1) < 0.25) {
    modifier.push('mod_mission_6_supply')
  }
  let renown_point = 0;
  if (global_modifier.find((m) => m.key === 'mod_global_26_mission_resent') && rng.range(0, 1) < 0.25) {
    modifier.push('mod_mission_7_resent')
  }

  // days
  let duration = TICK_PER_DAY * 4;
  if (config.stability_level.includes('온보딩')) {
    duration = TICK_PER_WEEK * 4;
  }
  let expires_at = turn + duration;

  if (event_group === 'initial') {
    event = eventNotes.find((e) => e.num === 49);
  }
  if (config.stability_level.includes('시작')) {
    ty = 'embassy_short';
    modifier = ['mod_mission_4_hyena'];
  }

  const mission = {
    title: rng.choice(maps_titles),
    ty,
    stability_level: config.stability_level,
    seed: missionSeed,
    resource: 0,
    extraResource: 0,
    modifier,
    total_contribution_rate,
    event,
    milestone: false,
    fresh,
    expires_at,
    mission_level,
    difficulty,
    renown_gain: renown_gain + renown_point,

    area: {
      num: areaNum,
      stability_delta: 0,
    },

    intel: {
      threats,
      extraThreats,
      training,
    },

    afterReward: {
      firearm: rng.integer(reward_firearm_tier_min, reward_firearm_tier_max),
      equipment: rng.integer(reward_equipment_tier_min, reward_equipment_tier_max),
      throwable: reward_throwable_type,
      resource: reward_resource_add_amount,
    },

    drop_item_tier,
    drop_item_prob,
    drop_item_ty,

    exp,
  };
  mission.threats = instantiateMissionThreats(rng, { threats: threats + extraThreats, training });

  return mission;
}

export function missionUpdateRewards(mission, rng, turn, global_modifier, newbie) {
  // 테스트 instantiate: 실패하면 새로운 seed로 미션을 다시 생성합니다.
  const instantiated = instantiateMission(rng, mission);
  const entities = instantiateMissionEntities(rng, turn, instantiated, [], null, global_modifier);

  const { intel, ty, milestone } = mission;
  const { threats, training } = intel;

  const mission_mult = maps.find(({ preset }) => preset === ty)?.resource_multiplier ?? 1;

  let renown_gain = mission.renown_gain;
  // 음수 명성 임시로 빼기
  if (!mission.milestone) {
    const band = renownBand.find((band) => band.areaNum === mission.area.num);
    renown_gain = rng.integer(band.min, band.max);
  }

  const entities_per_area = Object.values(_.countBy(entities.filter((e) => e.team === 1).map(({ spawnarea }) => spawnarea)));
  entities_per_area.sort((a, b) => { return b - a; });
  let max_entities_per_area = entities_per_area[0] ?? 0;
  if (['indoor', 'indoor2'].includes(ty)) {
    max_entities_per_area = 1;
  }

  //보상수식
  let resource_base = (threats * training) * 125 + (max_entities_per_area * 300) - renown_gain * 6 + 400;
  let resource = rng.range(1, 1.05) * resource_base * mission_mult;
  if (newbie) {
    resource_base = (threats * training) * 150 + (max_entities_per_area * 300) - renown_gain * 7 + 700;
    resource = rng.range(0.95, 1.05) * resource_base * mission_mult;
  }

  let extraResource = 0;

  if (global_modifier.find((m) => m.key === 'mod_global_1_boom')) {
    extraResource = resource * 0.5;
  }
  if (global_modifier.find((m) => m.key === 'mod_global_2_recession')) {
    extraResource = -(resource * 0.3);
  }

  if (!milestone) {
    mission.renown_gain = renown_gain;
    mission.resource = Math.round(resource);
    mission.extraResource = Math.round(extraResource);
  }

  let enemies = entities.filter((e) => e.team === 1);
  mission.intel.stats = _.sum(enemies.map((e) => e._stat?._stat ?? 10)) / enemies.length;
}

export function threatsSummary(threats) {
  const summary = {};
  for (const threat of threats) {
    const { level, firearm_ty } = threat;
    const key = `${level} ${firearm_ty}`;
    summary[key] = (summary[key] ?? 0) + 1;
  }
  return Object.entries(summary).map(([k, v]) => {
    const [level, firearm_ty] = k.split(' ');
    return `[${firearm_ty} ${'💀'.repeat((0 | level) + 1)}] x ${v}`;
  }).join(', ');
}


export function instantiateMissionThreats(rng, intel) {
  const { threats, training } = intel;

  const threat_levels = sampleThreats(rng, threats, training);
  const l = [];
  for (const level of threat_levels) {
    const data = enemies.data[level];
    const firearm_ty = rng.choice(data.firearm_types);

    l.push({
      level,
      firearm_ty,
      data,
    });
  }

  return l;
}

export function instantiateMission(rng, mission) {
  const rescues_len = mission.intel.rescues ?? 0;
  const rescues = [];
  while (rescues.length < rescues_len) {
    const stats = stats2_rand(rng);
    stats.name = rng.choice(names_enemy);
    rescues.push(stats);
  }

  let ty = mission.ty;
  const simstate = presets[ty](mission.seed);

  // spawn_entity 트리거 지우기
  for (const spawnarea of simstate.spawnareas) {
    if (spawnarea.triggers) {
      spawnarea.triggers = spawnarea.triggers.filter(({ action }) => action !== 'spawn_entity');
    }
  }

  return {
    ...mission,

    ty,
    rescues,

    simstate,
  };
}

function sampleThreats(rng, count, target) {
  const levels = new Array(count).fill(target - 1);
  if (target < 2) {
    return levels;
  }

  const n = Math.floor(count * 0.2);
  let m = 100;
  let i = 0;
  while (i < n && m > 0) {
    m -= 1;

    const idx0 = rng.integer(0, count - 1);
    let idx1 = rng.integer(0, count - 2);
    if (idx1 >= idx0) {
      idx1 += 1;
    }

    if (levels[idx0] === 0 || levels[idx1] >= enemies.data.length - 1) {
      continue;
    }
    levels[idx0] -= 1;
    levels[idx1] += 1;
    i += 1
  }

  return levels;
}

export function instantiateMissionEntities(rng, turn, instantiated, agents, global_modifier) {
  const mission = instantiated;
  const { simstate } = instantiated;


  if (mission.modifier.find((m) => m === 'mod_mission_9_militia')) {
    agents = agents.slice();
    const areaNum = mission.area.num;
    for (let i = 0; i < 2; i++) {
      const name = 'TBD';
      const power = rng.integer(5, 20);
      const potential = pot.sample(rng, areaNum);
      const agent = createAgent(rng, name, 0,
        { power, potential, areaNum, turn, global_modifier });
      agent.temporary = true;
      agents.push(agent);
    }
  }

  let entities_other = [];

  let areaidx = 0;
  const areas = spawnareas_enemy(simstate);
  function enemyarea() {
    let ret = areas[areaidx];
    areaidx = (areaidx + 1) % areas.length;
    return ret;
  }

  // TODO: 반복 미션인 경우에만
  if (!mission.milestone) {
    // 적 에이전트 생성
    for (const { firearm_ty, data: enemyData } of mission.threats) {
      const stats = stats2_const(enemyData.power);

      let firearm = firearms.find((firearm) => {
        return firearm.firearm_ty === firearm_ty && firearm.firearm_rate === enemyData.firearm_rate;
      });
      if (!firearm) {
        firearm = tmpl_firearm_smg_low;
      }

      const equipment = gears_vest_bulletproof.find(({ vest_rate }) => vest_rate === enemyData.gear_vest_rate);

      stats.name = rng.choice(names_enemy);
      const entity = entityFromStat2(stats, {
        ...firearm,
        ...equipment,
      });

      if (maps.find(({ preset }) => preset === mission.ty)) {
        entity.spawnarea = enemyarea();
      } else {
        entity.spawnarea = this.missionSpawnAreaForThreat();
      }

      entity.team = 1;
      entity.default_rule = { ty: 'idle', alert: false };
      entity.allow_crawl = false;

      entity.life = rng.integer(enemyData.life_min, enemyData.life_max);
      entity.vis_color = enemyData.color;

      entity.threat_level = enemyData.threat_level;
      entity.training_level = mission.intel.training;

      entities_other.push(entity);
    }
  } else {
    const enemy_entities = simstate.entities.filter((e) => e.team > 0);

    for (let i = 0; i < mission.threats.length; i++) {
      const { data: enemyData } = mission.threats[i];
      const stats = stats2_const(enemyData.power);

      let entity = null;
      if (i < enemy_entities.length) {
        // 기본 entity. 위협으로 증가되지 않은 친구들입니다.
        entity = { ...enemy_entities[i] };
      } else {
        // 원한 값에 따라 새로 추가되는 entity입니다.
        entity = { ...(rng.choice(enemy_entities)) };
      }

      // 평균 overall 계산하기 위함
      entity.power = enemyData.power;

      updateEntityStat2(entity, stats);
      entity.life = rng.integer(enemyData.life_min, enemyData.life_max);;
      entity.vis_color = enemyData.color;

      entities_other.push(entity);
    }
    // TODO: 추가적인 위협량에 따라, entity를 무작위로 선택해 복사해서 넣을 것
  }

  // 전투 승리 보정
  const is_alias_twice_than_enemy = entities_other.filter(e => e.team === 1).length * 2 < agents.length;
  const alias_avg_overall = _.meanBy(agents, (e) => e.power);
  const enemy_avg_overall = _.meanBy(entities_other.filter(e => e.team === 1), (e) => e.power);
  const is_alias_avg_overall_more_than_enemy = alias_avg_overall > enemy_avg_overall;
  const is_enemy_has_sg = entities_other.filter(e => e.team === 1).find(e => e.firearm_ty === 'sg') !== undefined;
  const is_enemy_less_equal_than_6 = entities_other.filter(e => e.team === 1).length <= 6;
  const { expectation } = mission;
  const is_definiteVictory = expectation?.resp['class'] === 4;
  const victory_correction =
    (
      is_alias_twice_than_enemy &&
      is_alias_avg_overall_more_than_enemy &&
      !is_enemy_has_sg &&
      is_enemy_less_equal_than_6
    ) ||
    is_definiteVictory

  const areas_ally = spawnareas_ally(simstate);

  // 우리팀
  let leader = null;
  let entities_team0 = null;
  entities_team0 = agents.map((agent) => {
    const { firearm, equipment, throwables, perks, name } = agent;

    // TODO
    // const power = agent.power + agentPowerModifiers(agent, turn, squads).amount;
    // const stats = perk_apply_stats(stats_const(power, 10), perks.list);
    // stats.name = name;

    const stats2 = agentEffectiveStat(agent);
    if (victory_correction) {
      for (const key in stats2) {
        stats2[key] += 2;
      }
    }
    const entity = entityFromStat2({ name, ...stats2 }, {
      ...firearm,
      ...equipment,
    });
    perk_apply_entity(entity, perks.list);

    // growth-33: 스텟에 연결된 퍽 제거
    /*
    for (const s of data_stats.stats) {
      if (s.value > agent.stats2[s.stat_key]) {
        continue;
      }
      entity[s.perk] = true;
    }
    */

    entity.life = agent.life;
    entity.life_max = agent.life_max;
    entity.allow_fire_control = true;
    entity.allow_cover_edge = true;

    entity.idx = agent.idx;
    entity.level = agent.level.cur;
    entity.spawnarea = agent.spawnarea;
    if (areas_ally.length > 0) {
      entity.spawnarea = areas_ally[0];
    }

    if (perks.list.find((p) => p === 'perk_commed_base')) {
      entity.heals = [
        {
          heal_amount: 25,
          heal_duration: 3,
        },
      ];
    }
    if (perks.list.find((p) => p === 'perk_commed_count')) {
      entity.heals.push(
        {
          heal_amount: 25,
          heal_duration: 3,
        }
      );
    }

    entity.throwables = [];
    if (throwables[0].throwable_rate !== 0) {
      entity.throwables.push(throwables[0]);
    }
    if (throwables[1].throwable_rate !== 0) {
      entity.throwables.push(throwables[1]);
    }

    if (leader === null
      && !(firearm.firearm_ty === 'dmr'
        && perks.list.find((perk) => perk === 'perk_desmar_base'))) {
      leader = agents.indexOf(agent);
      entity.leader = null;
    } else if (firearm.firearm_ty === 'dmr'
      && perks.list.find((perk) => perk === 'perk_desmar_base')) {
      entity.leader = null;
    } else {
      entity.leader = leader;
    }

    if (instantiated.rescues.length > 0) {
      entity.default_rule = 'mission';
    } else {
      entity.default_rule = 'explore';
    }
    return entity;
  });

  if (mission.modifier.find((m) => m === 'mod_mission_4_hyena')) {
    entities_other.map((e) => {
      if (e.team === 1) {
        e.life = Math.floor(e.life / 2);
      }
      return e;
    })
  }

  const entities = [...entities_team0, ...entities_other];

  return entities;
}


