import _ from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
/* eslint-disable */
import 'dayjs/locale/ko';
/* eslint-enable */

import './vitedist/comp1/style.css';
import './RoguelikeView.css';

import { GAMEFACE, CanvasWrapper } from './gameface';
import { Rng } from './rand.mjs';
import { SimView } from './SimView';
import { names2, names3 } from './names';
import { v2 } from './v2';
import { events } from './data/google/processor/data_events.mjs'
import { modifiers } from './data/google/processor/data_modifiers.mjs';
import { perks2ByKey } from './data/google/processor/data_perks2.mjs';
import * as pot from './data/google/processor/data_potential.mjs';
import * as gcap from './data/google/processor/data_growthcap.mjs';
import * as pscap from './data/google/processor/data_physicalcap.mjs';
import * as exps from './data/google/processor/data_exp.mjs';
import * as data_facilities from './data/google/processor/data_facilities.mjs';
import * as data_traits from './data/google/processor/data_traits.mjs';
import { modifiers as data_modifiers } from './data/google/processor/data_modifiers.mjs';
import { backgrounds as data_backgrounds } from './data/google/processor/data_backgrounds.mjs';
import { getFatigueData } from '././data/google/processor/data_fatigue.mjs'
import { unlocksByRenown, renownStep } from './data/google/processor/data_renown.mjs';
import { data as data_playtip } from './data/google/downloaded/playtip.mjs';
import { questObjective, questClaimable, questTitle, questRewardString, questExpire, QuestElement } from './quest';

import localforage from 'localforage';
import { CutSceneView } from './CutSceneView';
import { ResearchView, ResearchEffects } from './ResearchView';
import { WorldCanvas, FacilityTableView, TYNAMES } from './WorldView2';
import { WorldState2, qrEq } from './worldmap';
import { AgentsTrainingResult, TrainingListWidget, TrainSlot } from './TrainingView';
import { CashbookView, ValueSpan } from './CashbookView.js';
import { WorldFacilityView, WorldFacilityUpgradeView, Checklist, facilityUpgrades } from './FacilityView';
import { TICK_PER_DAY, TICK_PER_WEEK, TICK_PER_MONTH, tickToDate, tickToDateStr, tickToMonth } from './tick';
import { firearm_ty_name, firearm_ty_full } from './presets_firearm.mjs';
import { threatsSummary } from './mission.mjs';
import { L, localeKeys, localeSet } from './localization.mjs';
import { OutgameHeader } from './FigmaView';
import { FirearmLabel, GearLabel, ThrowableLabel, FirearmBox, GearBox, ThrowableBox, CashBox, NoneBox } from './GearView';

// TODO
import {
  slotAutomationUpdate,
  slotCancel,
  trainStart,
  isAgentTrainable,
} from './training';

import { agentBackgroundTitle, agentPowerModifiers, agentHasThrowableSlot1, agentHasThrowableSlot2, tickToAge } from './character';
import { AgentItem, AgentLife, NameView, PortraitWrapper, PORTRAIT_PRESET_ZOOM } from './CharacterView';
import { AgentRecoverDays, AgentStats, AgentNature, AgentPotential, AgentGrowthCap, AgentPhysicalCap, AgentStatGuages, statView, AgentItemGears, AgentEquipButton } from './CharacterView';

import { AGENT_CONTRACT_TICKS, CONTRACT_OPTIONS } from './contract';
import { agentContractCost, contractDetails, contractDetails0, contractRenew, contractToString, terminations } from './contract';

import { RENOWN, ENABLE_RESEARCH } from './RoguelikeGame';
import { RoguelikeGame, renderReward } from './RoguelikeGame';
import { HorizontalInfoView } from './utils';

import { ReportView } from './ReportView';
import { DepartmentSelectView, DepartmentView, DepartmentResultView, WorldDepartmentWorkView, departmentName, getSuccessRateText, getPercentageText } from './DepartmentView';
import { TEMPORAL_DEPARTMENT_KEY } from './department';

import {
  convertAgent as figmaConvertAgent,
  convertAgents as figmaConvertAgents,
} from './figAdapter';
import {
  ResetRoot as FigmaRoot,
  MercenariesAgentFull,
  MercenariesAgentDetail,
  MercenariesAgentDetailOverview,
  MercenariesAgentDetailPlaceholder,
} from './vitedist/comp1/vite-react-ts.mjs';
import { AgentPerkWrapper } from './component/animawrapper/AgentPerkWrapper';
import { AgentContractWrapper } from './component/animawrapper/AgentContractWrapper';
import { AgentSummaryContractWrapper } from './component/animawrapper/AgentSummaryContract';
import { ContractCompact } from './component/anima/src/components/ContractCompact';
import { ContractInProgress } from './component/anima/src/components/ContractInProgress';
import { CipoCell } from './component/anima/src/components/CipoCell';
import { FirearmShape } from './component/anima/src/components/FirearmShape';

import {
  FH1Button as ButtonPrimary,
  FH1ButtonTiny as Button,
  FH1ButtonInline as ButtonInline,
  FH1Grade as Grade,
  FH1KVP as KVP,
  FH1Panel as Panel,
  FH1PanelSmall as PanelSmall,
  FH1ProgressBar as ProgressBar,
  FH1Tabs as Tabs,
  FH1Well as Well,
  FH1WellSeg as WellSegment,
  FH1Window as Window,
  FH1Check as CheckBox,
  FH1ButtonFoldable as ButtonFoldable,
} from './component/figma/fh1';

import { FirearmView } from './FirearmView';
import { GearView } from './GearView';
import { PerkView } from './PerkView';

dayjs.extend(require('dayjs/plugin/duration'))
dayjs.extend(require('dayjs/plugin/relativeTime'))
dayjs.locale('ko');

if (!GAMEFACE) {
} else {
  (async () => {
    await import('./gameface.css');
  })();
}

const DEBUG = false;
const AUTOSTART = false;
const NOQUESTPOPUP = false;
const AUTOLOAD = -1;
const SAVE_SLOTS = 3;

const NOTIFICATION_TABLE = {
  intel: {
    tab: 'CONTRACTS',
    mentUpcoming: '다음 수의 의뢰 접수까지',
    mentComplete: 'loc_ui_longtext_notification_task_complete_intel',
  },
  market: {
    tab: 'MARKET',
    mentUpcoming: '다음 수의 물건이 입고까지',
    mentComplete: 'loc_ui_longtext_notification_task_complete_market',
  },
  recruit: {
    tab: 'RECRUIT',
    mentUpcoming: '다음 수의 영입 목록이 갱신까지',
    mentComplete: 'loc_ui_longtext_notification_task_complete_recruit',
  },
  department_market: {
    tab: 'MARKET',
    mentUpcoming: '다음 수의 물건이 입고까지',
    mentComplete: 'loc_ui_longtext_notification_task_complete_market',
  },
  department_recruit: {
    tab: 'RECRUIT',
    mentUpcoming: '다음 수의 영입 목록이 갱신까지',
    mentComplete: 'loc_ui_longtext_notification_task_complete_recruit',
  },
  market_staff: {
    tab: 'COMPANY',
    mentComplete: 'loc_ui_longtext_notification_task_complete_market_staff',
  },
  new_staff: {
    tab: 'COMPANY',
    mentComplete: 'loc_ui_longtext_notification_task_complete_new_staff',
  },
  department: {
    tab: 'COMPANY',
    mentComplete: 'loc_ui_longtext_notification_task_complete_department',
  },
};

const SUBTABS_AGENT = [
  { key: 'OVERVIEW', locale: 'loc_ui_title_agent_overview' },
  { key: 'STAT', locale: 'loc_ui_title_agent_stats' },
  { key: 'PERK', locale: 'loc_ui_title_agent_perks' },
  { key: 'CONTRACT', locale: 'loc_ui_title_agent_contract' },
];

const SUBTABS_RECRUIT = [
  { key: 'OVERVIEW', locale: 'loc_ui_title_agent_overview' },
  { key: 'STAT', locale: 'loc_ui_title_agent_stats' },
  { key: 'PERK', locale: 'loc_ui_title_agent_perks' },
];

const SUBTABS_RENEWAL = [
  { key: 'OVERVIEW', locale: 'loc_ui_title_agent_overview' },
  { key: 'STAT', locale: 'loc_ui_title_agent_stats' },
  { key: 'PERK', locale: 'loc_ui_title_agent_perks' },
];

const SUBTABS_ITEM = [
  { key: 'firearm', locale: '총기' },
  { key: 'equipment', locale: '보호장구' },
  { key: 'throwable', locale: '수류탄' },
];

function contractExpiresInStr(contract, turn) {
  if (!contract) return null;
  const details = contractDetails0(contract, turn);
  return details.remaining;
}

function recruitExpiresInStr(expires_at, turn) {
  if (!expires_at) return null;
  const expires_in = Math.max(expires_at - turn, 0);
  const str = Math.ceil(expires_in / 24);
  return L('loc_dynamic_string_agent_recruit_expiration', { count: str });
}

function Sep() {
  return <span className="sep" />;
}

function itemSellPrice(item) {
  return item.sell_cost;
}

function itemRate(item) {
  if (item.equipment) {
    return item.equipment.vest_rate;
  }
  if (item.firearm) {
    return item.firearm.firearm_rate;
  }
  if (item.throwable) {
    return item.throwable.throwable_rate;
  }
  throw new Error('unknown item');
}

function DashedListView(props) {
  const { data } = props;
  return <>
    {data.map(([key, val]) => {
      return <div key={key} className="flex-plan-preview-row">
        <div className="flex-plan-preview-key">{key}</div>
        <div className="flex-plan-preview-dash"></div>
        <div className="flex-plan-preview-value">{val}</div>
      </div>;
    })}
  </>;
}

function ListView(props) {
  const { header, rows } = props;
  const classes = props.classes ?? [];

  let cls = (props.className ?? '') + ' flex-list-root';
  let body = rows.map(({ columns, extra }, i) => {
    return <div key={i} className='flex-list-row'>
      {columns.map((column, j) => <div key={j} className={classes[j] ?? ''}>{column}</div>)}
      {extra}
    </div>;
  });

  if (rows.length === 0) {
    body = L('loc_ui_longtext_common_list_empty');
  }

  return <div className={cls}>
    <div className="flex-list-header">
      {header.map((label, i) => <div key={i} className={classes[i] ?? ''}>{label}</div>)}
    </div>

    <div className='flex-list-items'>
      {body}
    </div>
  </div>;
}

function Market(props) {
  const { market_listings, world, game, onMarketListingPurchase, onClickClose } = props;
  const [tab_key_selected, set_tab_key_selected] = useState(SUBTABS_ITEM[0].key);

  const counts = { 'firearm': 0, 'equipment': 0, 'throwable': 0 };
  const market_listings_type = [];
  for (const item of market_listings) {
    counts[item.ty]++;
    if (item.ty === tab_key_selected) {
      market_listings_type.push(item);
    }
  }

  const tabs = SUBTABS_ITEM.map((t) => {
    t.label = <span>{L(t.locale)} <span className='contract-agent-perkpoint-indicator'>({counts[t.key]})</span></span>;
    return t;
  })

  let body = market_listings_type.map((m, i) => {
    return <MarketListingView key={i} item={m} world={world} game={game}
      onMarketListingPurchase={onMarketListingPurchase}
    />;
  });
  if (market_listings_type.length === 0) {
    body = L('loc_ui_longtext_common_list_empty');
  }

  return <Window
    title={L('loc_ui_title_menu_market')}
    showBackButton={false}
    showCloseButton={true}
    onClickButtonClose={onClickClose}
  >
    <Panel title={L('loc_ui_string_market_item_list')} className='fh1-panel-left'>
      <Tabs className='fh1-style2' items={tabs} selected={tab_key_selected} onChange={set_tab_key_selected} />
      <div className="fh1-flatwell">
        {body}
      </div>
    </Panel>
  </Window>;
}

function Inventory(props) {
  const { inventories, world, game, onInventorySell, onItemEquip, onClickClose, readonly } = props;
  const [tab_key_selected, set_tab_key_selected] = useState(SUBTABS_ITEM[0].key);

  const counts = { 'firearm': 0, 'equipment': 0, 'throwable': 0 };

  const inventories0 = inventories.filter((m) => m.sell_cost > 0);
  const inventories_type = [];
  for (const item of inventories0) {
    counts[item.ty]++;
    if (item.ty === tab_key_selected) {
      inventories_type.push(item);
    }
  }

  const tabs = SUBTABS_ITEM.map((t) => {
    t.label = <span>{L(t.locale)} <span className='contract-agent-perkpoint-indicator'>({counts[t.key]})</span></span>;
    return t;
  })

  let body = inventories_type.map((m, i) => {
    return <MarketListingView key={i} item={m} world={world} game={game}
      onInventorySell={onInventorySell}
      onItemEquip={onItemEquip}
      readonly={readonly}
    />;
  });
  if (inventories_type.length === 0) {
    body = L('loc_ui_longtext_common_list_empty');
  }

  return <Window
    title={L('loc_ui_title_menu_warehouse')}
    showBackButton={false}
    showCloseButton={true}
    onClickButtonClose={onClickClose}
  >
    <Panel title={L('loc_ui_string_common_item_list')} className='fh1-panel-left'>
      <Tabs className='fh1-style2' items={tabs} selected={tab_key_selected} onChange={set_tab_key_selected} />
      <div className="fh1-flatwell">
        {body}
      </div>
    </Panel>
  </Window>;
}

function Missions(props) {
  const { missions, mission_selected, milestone_missions, onMissionSelect, turn, can_progress, resources, global_effects, onClickClose, renderedPlan } = props;
  const { renown } = resources;

  const missions0 = missions.map((mission) => ({ reason: null, mission }));

  return <Window
    title={L('loc_ui_title_menu_contract')}
    showBackButton={false}
    showCloseButton={true}
    onClickButtonClose={onClickClose}
  >
    <Panel title={L('loc_ui_string_contract_list')} className="fh1-panel-left"><Well style={styleHeight}>
      {milestone_missions.map((mission) => {
        return <MissionItem key={mission.seed} turn={turn} mission={mission} mission_selected={mission_selected}
          onMissionSelect={onMissionSelect} reason={null}
          readonly={!can_progress} global_effects={global_effects} />;
      })}

      {missions0.map(({ mission, reason }) => {
        return <MissionItem key={mission.seed} turn={turn} mission={mission} mission_selected={mission_selected}
          onMissionSelect={onMissionSelect} reason={reason}
          readonly={!can_progress} global_effects={global_effects} />;
      })}
    </Well></Panel>
    {renderedPlan}
  </Window>;
}

function GlobalModifiersView(props) {
  const { game } = props;
  const { global_modifier, turn } = game;

  return <p className="overlay-modifiers">global_modifier:[{global_modifier.map((m) => {
    const descr = modifiers[m.key];
    const remain = dayjs.duration(m.start + m.term - turn, 'hour').humanize();
    return <span key={m.key} title={descr.desc}>{descr.name}({remain}), </span>;
  })}]</p>
}

function missionTitle(mission) {
  let mission_title = L(mission.title);
  if (!mission.milestone) {
    mission_title = `[${L(mission.client)} -> ${L(mission.target)}]`;
  }
  return mission_title;
}

function MissionItem(props) {
  const { turn, mission, mission_selected, onMissionSelect, reason, readonly, global_effects } = props;

  const selected = mission === mission_selected;

  const fresh = readonly ? false : mission.fresh;
  const freshPrev = useRef();

  useEffect(() => {
    freshPrev.current = fresh;
    mission.fresh = false;
  }, [freshPrev, fresh, mission]);

  /*
  if (!readonly) {
    selectbutton = <Button reason={reason} disabled={reason !== null}
      onClick={(ev) => {
        ev.stopPropagation();
        onMissionSelect(mission);
      }}>{msg}</Button>;
  }

  const previewbutton = <Button onClick={() => setShowpreview(!showpreview)}>preview</Button>;
  let preview = null;
  if (showpreview) {
    const preset_name = mission.ty;
    const preset_func = presets[preset_name];
    const preset = preset_func(seed);
    preset['entities'] = [];
    preset['goals'] = [];
    preset['mission_rules'] = [];
    preview =
      <SimView
        // TODO: debug=true에 의존하지 말고 preview 플래그같은거 만들기
        seed={seed}
        debug={true}
        onFinish={(_) => { }}
        canvasScale={0.5}
        {...preset}
      />
  }

  const buttons = (
    <>
      <Sep />
      {previewbutton}
      {selectbutton}
    </>
  );
  */

  let extraThreats = mission.intel.extraThreats ?? 0;
  if (mission.modifier.find((m) => m === 'mod_mission_7_resent')) {
    extraThreats += 2
  }
  if (mission.modifier.find((m) => m === 'mod_mission_8_weaken')) {
    extraThreats -= 2
  }

  const num = mission.event.num;
  const events0 = events.filter((event) => event.num === num);

  let title = '';
  for (const event of events0) {
    for (const mkey of event.modifier ?? []) {
      const { name, desc } = modifiers[mkey];
      title += `${L(name)}: ${L(desc)}\n`;
    }
  }
  title = title.trim();

  let event_descr = <span title={title}>[{mission.event.title}]</span>;
  if (mission.event.group === 'major') {
    event_descr = '[???]';
  } else if (mission.event.group.startsWith('milestone')) {
    event_descr = '[!!!]';
  }

  const cost_time_mult = global_effects?.mission_time_mult ?? 1;

  const time_str = L('loc_dynamic_string_common_duration_days', { count: Math.ceil(mission.cost.time * cost_time_mult / 24) });
  const expires_str = L('loc_dynamic_string_contract_expiration_days', { count: Math.ceil((mission.expires_at - turn) / 24) })
  /*
   <div className="flex-missionitem-extra">[레벨:{mission.mission_level}]임무 환경={mission.ty} 이벤트: 안정도변화량=[{mission.area.stability_delta}] 모디파이어=[{mission.modifier.map((m) => {
      const descr = modifiers[m];
      return <span key={descr.name} title={descr.desc}>{descr.name}</span>;
    })}]
      {buttons}
    */

  const info0 = [
    {
      key: <span title={L('loc_ui_longtext_contract_terrain')}>{L('loc_ui_string_contract_terrain')}</span>, value: mission.ty
    },
    {
      key: <span>tier</span>,
      value: <span>{'★'.repeat(mission.area.num + 1)}</span>
    },
    {
      key: <span title={L('loc_ui_longtext_contract_difficulty')}>{L('loc_ui_string_contract_difficulty')}</span>,
      value: <span title={threatsSummary(mission.threats)}>{L('loc_dynamic_string_contract_difficulty').repeat(mission.difficulty)}</span>
    },
    {
      key: <span title={L('loc_ui_longtext_contract_duration')}>{L('loc_ui_string_contract_duration')}</span>,
      value: time_str,
    },
  ];

  let cls = 'flex-missionitem';
  if (selected) {
    cls += ' flex-missionitem-selected fh1-selected';
  }
  if (mission.milestone) {
    cls += ' flex-missionitem-milestone fh1-special';
  }

  if (fresh || freshPrev.current) {
    cls += ' flex-missionitem-highlight fh1-highlight';
  }

  return <WellSegment className={cls} onClick={() => (reason === null) && onMissionSelect?.(mission)} title={
    <>
      <span>{missionTitle(mission)}</span>
      {mission.modifier.length > 0 ? <><Sep />[{mission.modifier.map((m) => {
        const descr = modifiers[m];
        return <span key={descr.name} title={L(descr.desc)}>{L(descr.name)}</span>;
      })}]</> : null}
      <Sep />
      {event_descr}
      <Sep />
    </>
  }
    overlay={reason}
  >
    <HorizontalInfoView pairs={info0} className="fh1-content-inner flex-missionitem-info" />
    <div className="flex-missionitem-footer">
      <div className="flex-missionitem-expires">{expires_str}</div>
      <div className="flex-missionitem-renown">
        <div className="flex-missionitem-footer-key">{L('loc_ui_string_contract_reward_renown')}</div>
        <div className="flex-missionitem-footer-value">{mission.renown_gain}</div>
      </div>
      <div className="flex-missionitem-reward">
        <div className="flex-missionitem-footer-key">{L('loc_ui_string_contract_reward_resource')}</div>
        <div className="flex-missionitem-footer-value">+${mission.resource + mission.extraResource}
        </div>
      </div>
    </div>
  </WellSegment>;
}

function AgentNatureHorizontalList(props) {
  const { agent } = props;
  const { potential, growthcap, physicalcap } = agent;

  const potentialData = pot.findByPotential(potential);

  const gcapData = gcap.find(growthcap);
  const gcapDescr = `${L('loc_ui_string_agent_growth_cap')}: ${gcapData.power_cap}`;

  const info0 = [
    { key: 'Potential', value: <><span title={`${L('loc_ui_string_agent_potential')}: ${potential.toFixed(2)}\n${pot.descrPotential(potentialData)}`}>{potentialData.label}</span></> },
    { key: 'Growth', value: <span title={gcapDescr}>{growthcap}</span> },
    { key: 'Physical', value: <span title={`${pscap.descr(pscap.find(physicalcap))}`}>{physicalcap}</span> },
  ];

  return <HorizontalInfoView pairs={info0} />;
}

function Currency({ amount }) {
  if (amount < 0) {
    return <span className="cashbook-neg">-${-amount.toFixed(0)}</span>;
  } else {
    return <span className="cashbook-pos">${amount.toFixed(0)}</span>;
  }
}

function PlanView(props) {
  const { turn, game, mission, onMissionQueue, onSpawnChange, agents_selected } = props;
  const { onAgentDetail, onAgentSelect, onAgentEquipAvail, onAgentEquipPopup, onAgentDisarm, onBaseSelect, showMission } = props;

  const [expectation, setExpectation] = useState(null);

  if (!mission) {
    return null;
  }

  let agentsview = <p>{L('loc_ui_longtext_common_list_empty')}</p>;
  if (props.agents.length > 0) {
    agentsview = props.agents.map((agent) => {

      let state = null;
      if (agent.state === 'mission') {
        state = L('loc_ui_string_common_agent_state_mission');
      } else if (agent.state === 'relocate') {
        const days = (agent.relocate_at - turn) / TICK_PER_DAY;
        state = L('loc_dynamic_string_agent_relocating') + L('loc_dynamic_string_common_duration_days', { count: days });
      } else if (agent.state) {
        state = agent.state;
      } else if (!game.agentAvail(agent)) {
        const recoverDays = AgentRecoverDays(agent);
        state = L('loc_ui_string_common_agent_state_recovery') + ': ' + L('loc_dynamic_string_common_duration_days', { count: Math.ceil(recoverDays) });
      } else if (agent.stamina < 1) {
        const stamina_recover_mult = game.effects.stamina_recover_mult ?? 1;
        const stamina_regen_per_day = agent.stamina_regen_per_day * stamina_recover_mult;
        state = L('loc_dynamic_string_agent_stamina_recovery_duration', { count: Math.ceil((1 - agent.stamina) / stamina_regen_per_day) });
      }

      let substate = null;
      if (game.slots.find((slot) => slot.training?.agentIdx === agent.idx)) {
        state = L('loc_ui_string_common_agent_state_training');
      }

      const gears = <AgentItemGears
        agent={agent}
        onAgentEquipAvail={onAgentEquipAvail}
        onAgentEquipPopup={onAgentEquipPopup}
        onAgentDisarm={onAgentDisarm} />;

      return <AgentItem key={agent.name} agent={agent} turn={turn} state={state} substate={substate} gears={gears}
        onAgentDetail={onAgentDetail} onSpawnChange={onSpawnChange}
        onAgentSelect={onAgentSelect} selected={agents_selected.includes(agent)} />
    });
  }

  const agents = agents_selected.filter((agent) => agent.state === null && game.agentAvail(agent) && agent.stamina >= 1);
  const resource0 = _.sum(agents.map((a) => a.contract.missionCost));
  const resource = resource0;

  let dangerpay = 0;
  if (mission && mission.intel.threats > agents.length * 3) {
    dangerpay = 250 * agents.filter((a) => a.contract.option.danger).length;
  }

  const pendingMissions = game.pendings.filter((p) => p.ty === 'mission');
  // TODO: 데이터로 빼기
  const maxPendingMissions = 10; //_.sum(game.world.facilitiesByKey('office').map((n, i) => (i % 2) * n)) + 1;

  const canstart = mission && agents.length > 0 && pendingMissions.length < maxPendingMissions;

  const args = { mission, agents, cost: { time: mission?.cost?.time, resource, dangerpay } };
  const cost_payroll_per_month = _.sum(agents.map((a) => a.contract.turnCost));
  const cost_payroll = cost_payroll_per_month * (mission?.cost?.time ?? 0) / TICK_PER_MONTH;

  let mission_resource = mission?.resource ?? 0;
  const share_ratio_total = _.sum(agents.map((a) => {
    return a.contract.shareRatio;
  }));

  let incentive = Math.round(share_ratio_total * mission_resource);

  let reward = mission.resource + mission.extraResource;
  let cost = resource + (dangerpay ?? 0) + cost_payroll + incentive;
  let profit_max = 0;
  if (mission) {
    profit_max = reward - cost;
  }

  let profit_wiped = 0;
  if (mission) {
    profit_wiped = -cost;
    for (const agent of agents) {
      const { days, cost_per_heal } = game.costRecover(agent);
      profit_wiped -= agent.contract.turnCost * days / 28 + (days * cost_per_heal);
    }
  }

  if (mission && agents.length > 0) {
    fetchExcetation();
  }
  function fetchExcetation(onExpectation) {
    const _setExpectation = onExpectation ?? setExpectation;

    const point_threats = -25 * (mission.threats.length - agents.length - 3);

    const overall_ally = _.sum(agents.map((a) => a.power)) / agents.length;
    const overall_enemy = _.sum(mission.threats.map((t) => t.data.power)) / mission.threats.length;
    const point_overall = 25 * Math.log(overall_ally / overall_enemy) / Math.log(2);

    const equip_ally = _.sum(agents.map((a) => (a.firearm.firearm_rate - (a.firearm.firearm_ty === 'hg' ? 0.99 : 0)) * 2 + a.equipment.vest_rate)) / agents.length;
    const equip_enemy = _.sum(mission.threats.map((t) => (t.data.firearm_rate - (t.firearm_ty === 'hg' ? 0.99 : 0)) * 2 + t.data.gear_vest_rate)) / mission.threats.length;

    const point_equip = 25 * Math.log(equip_ally / equip_enemy) / Math.log(2);

    const point_perk = _.sum(agents.map((a) => a.perks.point_total));

    let multiplier = 1;
    if (agents.length < 3) {
      multiplier = 0.25;
    }
    else if (agents.length < 5) {
      multiplier = 0.5;
    }
    else if (agents.length < 7) {
      multiplier = 0.75;
    }

    const point_total = (point_threats + point_overall + point_equip + point_perk * 0.5) * multiplier;

    const body = JSON.stringify({
      "ty": mission.ty,
      "point": point_total,
    });

    if (body !== expectation?.body) {
      if (mission.milestone) {
        _setExpectation({
          body, resp: {
            class: 2,
            class_name: L('loc_ui_string_contract_expected_result_unknown'),
            desc: '',
          }
        });
      }
      else {
        let class_index;
        if (point_total < -50) {
          class_index = 0;
        }
        else if (point_total < -15) {
          class_index = 1;
        }
        else if (point_total < 0) {
          class_index = 2;
        }
        else if (point_total < 15) {
          class_index = 3;
        }
        else if (point_total < 40) {
          class_index = 4;
        }
        else if (point_total < 60) {
          class_index = 5;
        }
        else {
          class_index = 6;
        }

        _setExpectation({
          body, resp: {
            class: class_index,
            class_name: L(`loc_ui_string_contract_expected_result_${class_index}`),
            desc: `수적 우위 점수 : ${point_threats}\n질적 우위 점수 : ${point_overall}\n무장 우위 점수 : ${point_equip}\n퍽 점수 : ${point_perk}\n아군 수에 따른 가중치 : ${multiplier}\n최종 점수 : ${point_total}`,
          }
        });
      }
    } else {
      onExpectation?.(expectation);
    }
  }

  let tabs = game.baseList().map(({ idx }) => {
    const { centerstate } = game.world.storage[idx];
    return { idx, key: idx, label: centerstate.name };
  });

  const previews = [
    [L('loc_ui_string_contract_deployment_pay_total'), <Currency amount={resource0} />],
    [L('loc_ui_string_contract_hazard_pay_total'), <Currency amount={dangerpay} />],
    [L('loc_ui_string_contract_profit_share_total'), <><Currency amount={incentive} /> ({(share_ratio_total * 100).toFixed(1)}%)</>],
    [L('loc_ui_string_contract_opportunity_cost'), <Currency amount={cost_payroll} />],
  ];

  const scenarios = [
    [L('loc_ui_string_contract_expected_reward_perfect_play'), <Currency amount={profit_max} />],
    [L('loc_ui_string_contract_expected_reward_severe_damage'), <Currency amount={reward + profit_wiped} />],
    [L('loc_ui_string_contract_expected_reward_failure'), <Currency amount={profit_wiped} />],
  ];

  if (expectation?.resp) {
    scenarios.push([L('loc_ui_string_contract_expected_result'),
    <span className={`flex-expectation-label-${expectation?.resp['class']}`} title={expectation.resp.desc}>
      {expectation.resp.class_name}
    </span>
    ]);
  }

  let missionview = null;
  if (showMission) {
    missionview = <MissionItem key={mission.seed} turn={turn} mission={mission}
      reason={null} readonly={true} global_effects={game.effects} />;
  }

  const details = [];
  details.push(<p key="detail">{L('loc_ui_string_contract_fine_prints_enemy_detail')}: {threatsSummary(mission.threats)}</p>);

  for (const m of mission.modifier) {
    const descr = modifiers[m];
    details.push(<p key={descr.name}>{descr.name}: {descr.desc}</p>);
  }

  return <Panel title={L('loc_ui_title_menu_contract_overview')} style={{ width: 720 }}>
    <div>
      <span className='agent-selected-num'>{L('loc_ui_string_contract_selected_agents', { count: agents.length })}</span>
      <span className='agent-selected-num'>{L('loc_ui_string_contract_ongoing_contracts', { count: pendingMissions.length, max: maxPendingMissions })}</span>
    </div>

    {missionview}

    {tabs.length > 1 ? <Tabs className='fh1-style2' items={tabs} selected={game.selected_world_idx} onChange={onBaseSelect} /> : null}
    <Well style={{ width: '100%', height: 436, margin: '4px 0' }}>
      {agentsview}
    </Well>

    <PanelSmall title={L('loc_ui_string_contract_fine_prints')}>
      <div className="flex-plan-box-body flex-plan-box-body-modifiers">
        {details.length > 0 ? details : L('loc_ui_string_contract_fine_prints_none')}
      </div>
    </PanelSmall>

    <div className="flex-plan-preview">
      <div className="flex-plan-preview-column">
        <DashedListView data={previews} />
      </div>
      <div className="flex-plan-preview-column">
        <DashedListView data={scenarios} />
      </div>
    </div>

    <div className="flex-plan-btngroup">
      <ButtonPrimary onClick={() => {
        const missionQueue = { ...args };
        fetchExcetation((expectation) => {
          missionQueue.expectation = expectation;
          onMissionQueue(missionQueue);
        })
      }} disabled={!canstart}>{L('loc_ui_button_contract_confirm_contract')}</ButtonPrimary>
    </div>
  </Panel>;
}

function MarketListingView(props) {
  const { item, onAgentDetail, onMarketListingPurchase, onInventorySell, onItemEquip, world, readonly, game } = props;
  const { departmentRoot, turn, agents, milestone_clears, resources: { resource } } = game;
  const { ty, buy_cost: cost, idx } = item;

  const [work_key, setWorkKey] = useState('');
  const [item_warning, setItemWarning] = useState(false);
  const [item_detail, setItemDetail] = useState(false);
  const [agent_index, setAgentIndex] = useState(0);

  let needWarn = false;
  let needSpecUpMessage = false;

  let detail = null;

  let item_rate = 0;

  switch (ty) {
    case 'agent':
      const { power, contract } = item.agent;
      detail = <span>
        <NameView agent={item.agent} onAgentDetail={onAgentDetail} additional={null} />
        전투력={power.toFixed(1)} 기본급=${contract.turnCost} 계약={contract.ty}
      </span>;
      break;
    case 'equipment':
      const { equipment } = item;
      detail = <GearLabel equipment={equipment} />;
      item_rate = equipment.vest_rate;
      if (agents.find((a) => a.equipment.vest_rate < equipment.vest_rate)) {
        needSpecUpMessage = true;
      }
      break;
    case 'firearm':
      const { firearm } = item;
      item_rate = firearm.firearm_ty === 'hg' ? 0 : firearm.firearm_rate;
      if (agents.length > 0) {
        if (agents.find((a) => a.firearm.firearm_rate < firearm.firearm_rate)) {
          needSpecUpMessage = true;
        }
      }
      else {
        needWarn = true;
      }
      detail = <FirearmLabel firearm={firearm} />;
      break;
    case 'throwable':
      const { throwable } = item;
      item_rate = throwable.throwable_rate;
      if (!agents.find((a) => !agentHasThrowableSlot1(a))) {
        needWarn = true;
      }
      detail = <ThrowableLabel throwable={throwable} />;
      break;
    default:
      break;
  }

  let expires_str = null;
  expires_str = L('loc_ui_string_market_expiration', { count: Math.ceil(Math.max(item.expires_at - turn, 0) / 24) });

  let buttons = [];

  const findPending = (work_key, item) => {
    for (const d of departmentRoot.departments) {
      for (const p of d.pendings) {
        if (p.work_key === work_key && p.var1.id === item.id) {
          return p;
        }
      }
    }
    return null;
  };

  let reason = null;
  if (onMarketListingPurchase && !readonly) {
    let disabled = false

    const found = findPending('buy_item', item);
    if (found) {
      reason = L('loc_ui_string_market_disabled_task_ongoing', {
        count: Math.ceil(found.ticks / TICK_PER_DAY),
      });
      disabled = true;
    }
    else if (item_rate > milestone_clears + 1) {
      disabled = true;
      reason = L('loc_ui_string_market_disabled_milestone', { value: item_rate - 1 });
    }
    else if (resource < cost) {
      disabled = true;
      reason = L('loc_ui_string_market_disabled_insufficient_cash');
    }

    buttons.push(<Button key="purchase" disabled={disabled} title={reason}
      onClick={() => {
        if (needWarn) {
          setItemWarning(true);
          return;
        }

        const work_key = 'buy_item'
        const department = departmentRoot.defaultDepartment(work_key);
        const pending = departmentRoot.createPending(work_key, department, item, null);
        departmentRoot.addPending(department, pending, game);

        setWorkKey(work_key);
      }}>{L('loc_ui_button_market_purchase', { value: cost })}</Button>);
  }

  let noticeMessage = null;

  if (onInventorySell && !readonly) {
    let disabled = false;

    if (departmentRoot) {
      let found = findPending('sell_item', item);
      if (found) {
        reason = L('loc_ui_string_warehouse_disabled_task_ongoing', {
          count: Math.ceil(found.ticks / TICK_PER_DAY),
        });
        disabled = true;
      }
    }
    buttons.push(<Button className='inventory-button' key="sell" disabled={disabled} title={reason} onClick={() => {
      const work_key = 'sell_item'
      const department = departmentRoot.defaultDepartment(work_key);
      const pending = departmentRoot.createPending(work_key, department, item, null);
      departmentRoot.addPending(department, pending, game);

      setWorkKey(work_key);
    }}>
      {L('loc_ui_button_warehouse_sell', { value: itemSellPrice(item) })}
    </Button>);

    if (item.ty !== 'throwable') {
      buttons.push(<Button className='inventory-button' key="equip" disabled={disabled} onClick={() => onItemEquip(item)}>{L('loc_ui_button_warehouse_equip')}</Button>)
    }

    if (needSpecUpMessage) {
      noticeMessage = <font className="inventory-item-notice">{L('loc_ui_tooltip_warehouse_equip')}</font>;
    }
  }

  let extra = null;
  if (world && idx) {
    const { centerstate } = world.storage[idx];
    extra = `출처: ${centerstate.name}`;
  }

  const warningView = item_warning ?
    <div className='market-addon'>
      LOC_TODO_아이템을 착용할 수 있는 용병이 없습니다. 그래도 구매 업무를 진행하시겠습니까?
      <br />
      <Button onClick={() => { setItemWarning(false); setWorkKey(work_key === 'buy_item' ? '' : 'buy_item') }}>LOC_TODO_예</Button>
      <Button onClick={() => setItemWarning(false)}>LOC_TODO_아니오</Button>
    </div >
    : null;

  let itemDetailView = null;
  if (item_detail) {
    let targetAgents = [];
    switch (ty) {
      case 'firearm':
        targetAgents = agents.slice();
        break;
      case 'equipment':
        targetAgents = agents.slice();
        break;
      case 'throwable':
        targetAgents = agents.filter((a) => agentHasThrowableSlot1(a));
        break;
      default:
        break;
    }

    if (targetAgents.length <= 0) {
      let body = null;
      switch (ty) {
        case 'firearm':
          body = <FirearmView firearm={item.firearm} prev_firearm={null} hideDetail />;
          break;
        case 'equipment':
          body = <GearView gear={item.equipment} prev_gear={null} hideDetail />;
          break;
        case 'throwable':
          body = <><br /> <ThrowableLabel throwable={item.throwable} /></>;
          break;
        default:
          break;
      }
      itemDetailView = <div className='market-item-detail'>
        <div className='market-item-detail-left'>
          {L('loc_ui_string_common_market_item_base')}
          {body}
        </div>
      </div>;
    }
    else {
      const agent = targetAgents[agent_index % targetAgents.length];
      let item_body, agent_body = null;
      switch (ty) {
        case 'firearm':
          item_body = <FirearmView firearm={item.firearm} prev_firearm={agent.firearm} hideDetail />;
          agent_body = <FirearmView firearm={agent.firearm} prev_firearm={null} hideDetail />;
          break;
        case 'equipment':
          item_body = <GearView gear={item.equipment} prev_gear={agent.equipment} hideDetail />;
          agent_body = <GearView gear={agent.equipment} prev_gear={null} hideDetail />;
          break;
        case 'throwable':
          item_body = <><br /> <ThrowableLabel throwable={item.throwable} /></>;
          agent_body = <><br /><ThrowableLabel throwable={agent.throwables[0]} /> / <ThrowableLabel throwable={agent.throwables[1]} /></>
          break;
        default:
          break;
      }

      itemDetailView = <div className='market-item-detail'>
        <div className='market-item-detail-left'>
          {L('loc_ui_string_common_market_item_base')} {targetAgents.length > 1 ? L('loc_ui_string_common_market_item_compare_agent_change') : null}
          {item_body}
        </div>
        <div className='market-item-detail-right'>
          {L('loc_ui_string_common_compare_target', { value: agent.name })}
          {agent_body}
        </div>
      </div>;
    }
  }

  let workview = null;
  if (work_key) {
    // TODO: 20231110 빌드 가림
    // workview = <DepartmentSelectView game={game} work_key={work_key} var1={item} onPending={() => setWorkKey('')} onCancel={() => setWorkKey('')} />;
  }

  const title = <div className='listing-item-desc'>{detail} {onInventorySell ? '' : expires_str}</div>;
  return <WellSegment title={title} className="box-overlayhost">
    <div className="fh1-content-inner" onMouseEnter={() => setItemDetail(true)} onMouseLeave={() => setItemDetail(false)} onMouseDown={(e) => { if (e.button === 2) { setAgentIndex(agent_index + 1); } }}>
      {itemDetailView}
      {buttons}
      {workview}
      {noticeMessage}
      {extra}
      {reason ? <div className="box-overlay"><h1>{reason}</h1></div> : null}
    </div>
    {warningView}
  </WellSegment>;
}

function tabsWrap(agent, tabs) {
  return tabs.map((t) => {
    if (t.key === 'PERK') {
      t = { ...t };
      if (t.locale && agent.perks.point > 0) {
        t.label = <span>{L(t.locale)} <span className="contract-agent-perkpoint-indicator">({agent.perks.point})</span></span>;
      }
    }
    return t;
  });
}

function AgentsContract(props) {
  const { agents, game, onContractRenewal, onDone, tooltipHandlers } = props;
  const { turn } = game;

  const [agent_idx, set_agent_idx] = useState(0);
  let tabs = SUBTABS_RENEWAL;
  const [tab_key_selected, set_tab_key_selected] = useState(tabs[0].key);
  const tab_selected = tabs.find((tab) => tab.key === tab_key_selected).key;

  const initial_options = agents.map((a) => ({ ...a.contract.option }));
  const [options, set_options] = useState(initial_options);

  if (agents.length === 0) {
    // TODO
    return null;
  }

  const idx = Math.min(agents.length - 1, agent_idx);
  if (idx !== agent_idx) {
    set_agent_idx(idx);
  }

  const agent = agents[idx];
  tabs = tabsWrap(agent, tabs);
  const { contract } = agent;

  const onAgentSelect = (agent) => {
    const selected_idx = agents.findIndex((a) => a.idx === agent.idx);
    if (selected_idx >= 0) {
      set_agent_idx(selected_idx);
    }
  }

  let body = null;
  if (tab_selected === 'OVERVIEW') {
    body = <AgentRecruitOverview
      agent={agent}
      option={options[idx]}
      game={game}
      onContract={(ty, _agent0, option) => {
        onContractRenewal(ty, agent, option);

        // renewal한 용병은 목록에서 사라지기때문에
        // 대응하는 option도 날려야한다
        const options_ = [...options];
        options_.splice(idx, 1);
        set_options(options_);
      }}
      onDone={onDone}
      onChangeOption={(option) => {
        const options0 = [...options];
        options0[idx] = option;
        set_options(options0);
      }}
      tooltipHandlers={tooltipHandlers}
      avail negotiated
    />;

  } else if (tab_selected === 'STAT') {
    body = <AgentStatGuages agent={agent} slots={game.slots} tick={game.turn} global_effects={game.effects} />;

  } else if (tab_selected === 'PERK') {
    body = <AgentDetailPerk agent={agent} />;
  }


  return <Window
    title={L('loc_ui_string_renewal_list')}
    showBackButton={false}
    showCloseButton={false}
    style={{
      marginTop: 'var(--card-top)',
      minWidth: 1400,
      maxWidth: 1418,
      position: 'relative',
      left: 146,
    }}
  >
    <Panel title={L('loc_ui_string_agent_list')} className="fh1-panel-left">
      <Well style={{ width: '100%', ...styleHeight }}>
        {agents.map((a) =>
          <AgentItem key={a.name} agent={a} turn={turn}
            onAgentSelect={onAgentSelect} selected={agent?.idx === a.idx} />
        )}
      </Well>
    </Panel>
    <Panel
      title={agent?.name}
      subtitle={contractExpiresInStr(contract, turn)}
      style={{ width: 800 }}>
      <Tabs className='fh1-style2' items={tabs} selected={tab_key_selected} onChange={set_tab_key_selected} />
      <Panel className='fh1-style2'>
        {body}
      </Panel>
    </Panel>
  </Window>;
}

function AgentsRecruit(props) {
  const { agents, game, onContractRecruit, onDone, onClose } = props;
  const { turn } = game;

  let tabs = SUBTABS_RECRUIT;
  const [agent_idx, set_agent_idx] = useState(agents[0]?.idx);
  const [tab_key_selected, set_tab_key_selected] = useState(tabs[0].key);
  const tab_selected = tabs.find(({ key }) => key === tab_key_selected)?.key;

  // key: agent_idx, value: depart idx
  // 각 용병별로 어떤 부서에 협상을 맡길지 선택해둔 상태 테이블
  // 해당 상태가 이 UI 컴포넌트에 들어있기 때문에,
  // 다른 UI 화면 갔다가 오면 부서 상태가 리셋된다
  // TODO: 다른 화면 갔다와도 보존되어야할 필요가 생긴다면
  // RoguelikeView 수준으로 끌어올리든가 해야한다
  const [depart_idx_table, set_depart_idx_table] = useState({});

  if (agents.length === 0) {
    // TODO
    return null;
  }

  const idx = agents.findIndex((a) => a.idx === agent_idx);
  if (idx === -1) {
    const last = agents[agents.length - 1];
    set_agent_idx(last.idx);
    return null;
  }

  const agent = agents.find((a) => a.idx === agent_idx);
  tabs = tabsWrap(agent, tabs);
  const recruit = game.recruit_listings.find((r) => r.agent.idx === agent.idx);

  const idxcenter = game.world.centers.find((c) => game.world.storage[c.idx].centerstate.office0)?.idx;
  const agents_limit = game.baseAgentsCount(idxcenter);

  let agents_recruiting_count = 0;
  for (const department of game.departmentRoot.departments) {
    for (const pending of department.pendings) {
      if (pending.work_key === 'recruit_agent') {
        agents_recruiting_count++;
      }
    }
  }

  const agent_avail_limit = (game.agents.length + agents_recruiting_count) < agents_limit;

  const checkAgentAvailable = (a) => {
    let avail = true;
    let state = '';

    const r = game.recruit_listings.find((r) => r.agent.idx === a.idx);

    if (r.areaNum > game.milestone_clears) {
      avail = false;
      state = L('loc_ui_string_recruit_cannot_recruit_milestone', { count: r.areaNum });
    }
    else if (!agent_avail_limit) {
      avail = false;
      state = L('loc_ui_string_recruit_cannot_recruit_no_vacancy');
    }

    return { avail, state };
  }

  const { avail: agent_avail, state: agent_state } = checkAgentAvailable(agent);

  const agentItems = [];
  for (const a of agents) {
    const { avail, state } = checkAgentAvailable(a);

    agentItems.push(<AgentItem key={a.name} agent={a} turn={turn}
      onAgentSelect={(a) => set_agent_idx(a.idx)} state={state} selected={a.idx === agent.idx} avail={avail} />);
  }

  return <Window
    title={L('loc_ui_title_menu_recruit')}
    showBackButton={false}
    showCloseButton={true}
    onClickButtonClose={onClose}
    style={{ marginTop: 'var(--card-top)' }}
  >
    <Panel title={L('loc_ui_string_recruit_list')} className="fh1-panel-left">
      <Well style={{ width: '100%', ...styleHeight }}>
        {agentItems}
      </Well>
    </Panel>
    <Panel
      title={agent?.name}
      subtitle={recruitExpiresInStr(recruit?.expires_at, turn)}
      style={{ width: 800 }}
    >
      <Tabs className='fh1-style2' items={tabs} selected={tab_key_selected} onChange={set_tab_key_selected} />
      <Panel className='fh1-style2'>
        {agent && tab_selected === 'OVERVIEW' && <AgentRecruitOverview
          agent={agent}
          game={game}
          depart_idx={depart_idx_table[agent_idx]}
          onContract={(ty, _agent0, option) => {
            onContractRecruit(ty, agent, option);
          }}
          onDone={onDone}
          onChangeDepartIdx={(depart_idx) => {
            const new_table = { ...depart_idx_table };
            new_table[agent_idx] = depart_idx;
            set_depart_idx_table(new_table);
          }}
          avail={agent_avail}
          state={agent_state}
          negotiated={false}
        />}
        {agent && tab_selected === 'STAT' && <AgentStatGuages agent={agent} slots={game.slots} tick={game.turn} global_effects={game.effects} />}
        {agent && tab_selected === 'PERK' && <AgentDetailPerk agent={agent} />}
      </Panel>
    </Panel>
  </Window>;
}

function AgentDetail(props) {
  const { agent, game, tabs: tabs0, tab_selected, onTabSelect } = props;
  const {
    onAgentEquipPopup,
    onAgentEquipAvail,

    onDone,

    onAgentTerminate,

    onAgentAcquirePerk,
  } = props;

  if (!agent) return null;

  let tabs = tabs0 ?? SUBTABS_AGENT;
  tabs = tabsWrap(agent, tabs);
  const selected = tabs.find(({ key }) => key === tab_selected)?.key ?? null;

  const { contract } = agent;
  const contracted = contract.term > 0;

  return <div>
    <Tabs className='fh1-style2' items={tabs} selected={selected} onChange={onTabSelect} />
    <Panel className='fh1-style2'>
      {tab_selected === 'OVERVIEW' && contracted && <AgentDetailOverview
        agent={agent}
        game={game}
        onAgentEquipPopup={onAgentEquipPopup}
        onAgentEquipAvail={onAgentEquipAvail}
      />}
      {tab_selected === 'OVERVIEW' && !contracted && <AgentRecruitOverview
        agent={agent}
        game={game}
        onDone={onDone}
      />}

      {tab_selected === 'STAT' && <AgentStatGuages agent={agent} slots={game.slots} tick={game.turn} global_effects={game.effects} />}
      {tab_selected === 'PERK' && <AgentDetailPerk agent={agent} onAgentAcquirePerk={onAgentAcquirePerk} />}
      {tab_selected === 'CONTRACT' && <AgentDetailContract
        agent={agent}
        game={game}
        onAgentTerminate={onAgentTerminate}
      />}
    </Panel>
  </div>;
}

function AgentContract(props) {
  const { agent, global_modifier, turn, avail, modifiers } = props;
  const { onAgentPreview, onContractRenewal } = props;
  const { agenda } = agent.contract;

  const disabled = {};
  const option0 = {};
  for (const opt of CONTRACT_OPTIONS) {
    disabled[opt.key] = false;
    option0[opt.key] = agent.contract.option[opt.key] ?? false;
  }

  if (agenda === 'mod_agent_16_agenda_frequent') {
    disabled['long'] = true;
    option0['long'] = false;
  }
  if (agenda === 'mod_agent_17_agenda_safety') {
    disabled['danger'] = true;
    option0['danger'] = true;
  }
  if (agenda === 'mod_agent_18_agenda_wellbeing') {
    disabled['advanced'] = true;
    option0['advanced'] = true;
  }

  const [option, setOption] = useState(option0);

  const checkOption = (_target, x) => {
    const option0 = { ...option };
    option0[x] = !option[x];
    setOption(option0);
  }

  const { ty } = agent.contract;

  let turn0 = AGENT_CONTRACT_TICKS;
  let installment = agentContractCost(agent, option, global_modifier, modifiers);

  const installment_title =
    avail ?
      '계약 총액 $' + (installment * (turn0 / TICK_PER_MONTH)) :
      '고용 가능한 용병 수 제한에 도달했습니다.';
  const installment_btn =
    <Button
      className='tooltip'
      title={installment_title}
      disabled={!avail}
      onClick={() => onContractRenewal(ty, agent, option)}>
      Confirm {ty} Contract
    </Button>;
  const cancel_btn =
    <Button
      disabled={agent.state}
      onClick={() => onContractRenewal(null, agent, option)}>
      Dismiss
    </Button>;

  const d = dayjs.duration(turn0, 'hour');

  const preview = contractRenew(turn, agent, option, global_modifier);

  let detailBtn = null;
  if (onAgentPreview) {
    detailBtn = <Button onClick={() => {
      onAgentPreview(agent);
    }}>{L('loc_ui_common_detail')}</Button>;
  }

  return (<div key={agent.name + 'contract'} className="box">
    [{agent.name}]({d.humanize()} 계약) {detailBtn}
    <br />
    {CONTRACT_OPTIONS.map(({ key, name, desc }) => {
      return <div key={key}>
        <input key={key} type="checkbox" disabled={disabled[key]} checked={option[key]} onChange={(e) => checkOption(e, key)} />
        <span className='tooltip'>{name}: {desc}</span>
        <br />
      </div>;
    })}
    <div style={{ whiteSpace: 'pre-wrap' }}>
      {contractToString(preview, turn)}
    </div>
    {installment_btn} {cancel_btn}
  </div>
  );
}

function MissionStartView(props) {
  const { turn, resources, mission_state, onProceed, hide, onToggleHide, global_effects, playtip } = props;
  const { mission, agents } = mission_state;

  const [seconds, setSeconds] = useState(5);
  const timer = setTimeout(() => {
    if (seconds > 1) {
      setSeconds(seconds - 1);
    } else {
      onProceed();
    }
  }, 1000);

  let btn2cls = "mission-warning-overlay-btn2";
  if (hide) {
    btn2cls += " mission-warning-overlay-btn2-selected";
  }

  return <div className="overlay-root overlay-root-shrink">
    <div className="overlay-flex">
      <p className="mission-warning-overlay-title">{L('loc_ui_title_mission_start')}</p>
      <p className="mission-warning-overlay-subtitle">{L('loc_ui_longtext_mission_start_count', { count: seconds })}</p>

      <p className="mission-warning-overlay-subtitle">{L('loc_ui_string_result_contract_information')}</p>
      <MissionItem key={mission.seed} turn={turn} mission={mission} readonly={true} global_effects={global_effects} />

      <p className="mission-warning-overlay-subtitle">{L('loc_ui_string_result_contract_inputs')}</p>
      <div className="mission-warning-overlay-body">
        {agents.map((a) => {
          return <AgentItem key={a.idx} agent={a} turn={turn} state={null} substate={null} readonly />;
        })}
      </div>

      <p className="mission-warning-overlay-body-important mission-start-tip">{L(playtip)}</p>

      <div className="overlay-flex-btngroup">
        <Button className="mission-warning-overlay-btn" onClick={() => {
          clearTimeout(timer);
          onProceed();
        }}>{L('loc_ui_button_mission_start_now')}</Button>
      </div>
      <Button className={btn2cls} onClick={onToggleHide}>{L('loc_ui_button_notification_do_not_show_again')}</Button>
    </div>
  </div>;
}

const TABS = [
  'REGIONS',
  'CONTRACTS',
  'TRAINING',
  'RECRUIT',
  'MARKET',
  'WAREHOUSE',
  'MERCENARIES',
  'COMPANY',
];

if (ENABLE_RESEARCH) {
  TABS.push('RESEARCH');
}

TABS.push('SYSTEM');

const TPS_BASE = 10;
const TPS_MULTS = [1, 3, 10];

function ClipboardView(props) {
  const { content, label } = props;
  const ref = useRef();

  if (!content) {
    return null;
  }

  return (<div className="flex-clipboard">
    <Button onClick={() => {
      const elem = ref.current;
      if (!elem) {
        return;
      }
      elem.select();
      document.execCommand("copy");
    }}>{label}</Button>
    <input value={content} ref={ref} type="text" readOnly></input>
  </div>);
}

const styleHeight = {
  height: '80vh',
};

function RenderLeft(props) {
  const { selected, onSelect, tabs } = props;
  const ref = React.createRef();

  const buttons = [];
  for (const { tab, avail, count } of tabs) {
    let src = `/img/lobby/tab-${tab.toLowerCase()}.png`;
    let css = 'flexleft-icon';
    if (selected === tab) {
      src = `/img/lobby/tab-${tab.toLowerCase()}_slct.png`;
    } else if (!avail) {
      css += ` flexleft-icon-disabled`;
    } else {
      css += ` flexleft-icon-unselected`;
    }

    let bubble = null;
    if (count > 0) {
      bubble = <div className="flexleftbtn-bubble">{count}</div>;
    }

    buttons.push(<div key={tab} className="flexleft-icon-container">
      <img className={css} src={src}
        onClick={() => {
          if (avail) {
            onSelect(tab);
          }
        }} />
      {bubble}
    </div>);
  }

  let lupe = null;
  const idx = TABS.indexOf(selected);
  if (idx >= 0) {
    const pos = 52 * idx;
    const style = { transform: `translateY(${pos}px)` };
    lupe = <img ref={ref} className="flexleft-lupe" src="/img/lobby/UI_Lupe.png" style={style} />;
  }

  return <div className="flexleft0">
    <div className="flexleft">
      <img src="/img/lobby/Main_Menu_Panel.png" />
      {lupe}
      <div className="flexleft-icons">
        {buttons}
      </div>
    </div>
  </div>;
}


export class RoguelikeView extends React.Component {
  constructor(props) {
    super(props);

    this.keyDown = this.onKeyDown.bind(this);

    this.timer = null;
    this.now = Date.now();
    this.last_ms = this.now;
    this.onTimer = () => {
      if (!this.onTick()) {
        this.startTimer();
      }
    };

    this.simRef = React.createRef();
    this.state = RoguelikeView.initialState(names3);

    this.saveRef = React.createRef();
    this.canvasRef = React.createRef();
    this.departmentRef = React.createRef();

    this.tooltipHandlers = tooltipHandlers.bind(this)();

    (async () => {
      const savedescrs = [];
      for (let i = 0; i < SAVE_SLOTS; i++) {
        savedescrs[i] = await localforage.getItem(`proto_loop_save_${i}_descr`);
      }
      this.state.savedescrs = savedescrs;

      if (!savedescrs.find((d) => d !== null)) {
        this.setState({ cutscene: 0 });
      }
    })();
  }

  static initialState(names) {
    names = names.slice();

    const game = new RoguelikeGame(names, null);
    game.pauseconfig.mission_start = !AUTOSTART;

    const tabs_badge = TABS.reduce((a0, tab) => {
      a0[tab] = { new_badge: false, badge: null };
      return a0;
    }, {});

    //처음엔 무조건 뉴
    tabs_badge['MERCENARIES'].new_badge = true;

    const { idx: start_idx } = game.world.centers.find((c) => {
      return game.world.storage[c.idx].centerstate.office;
    });

    let worldselect = game.world.qridx(start_idx);

    // game.missionSelect(game.missions[0]);

    const locale = 'ko-KR';
    localeSet(locale);

    return {
      game,

      simtps: TPS_BASE,
      paused: !AUTOSTART,

      view: 'game',

      savedescrs: [],

      agents_selected: [],

      agent_recruitDetail: null,
      agent_agentsDetail: game.agents[0],
      subtab_agentsDetail: 'OVERVIEW',

      tab_selected: 'COMPANY',
      // tab_selected: 'CONTRACTS',
      // tab_selected: 'MERCENARIES',
      // tab_selected: 'REGION',

      tabs_badge,

      showMissionList: true,
      showAgentPointList: true,
      showAgentFullLifeList: true,

      overlays: [
        // { ty: 'EQUIP', equip_ty: 'firearm', agent: game.agents[0] },
        // { ty: 'PREVIEW', agent: game.agents[0] },
      ],

      cutscene: -1,
      tooltip: { show: false, x: 0, y: 0, title: '툴팁제목', content: '툴팁내용' },

      worldselect,
      play_bgm: false,

      overlayoptions: {
        base: true,
        center: false,
        mission: true,
        mission_ongoing: true,
      },

      locale,
    };
  }

  get overlay_cur() {
    const { overlays } = this.state;
    if (overlays.length === 0) {
      return null;
    }
    return overlays[overlays.length - 1];
  }

  componentDidMount() {
    document.addEventListener('keydown', this.keyDown);

    if (!this.state.paused) {
      this.startTimer();
    }

    this.loadSaveDescrs();

    // disallow scroll
    const metaTag = document.createElement('meta');
    metaTag.name = "viewport";
    metaTag.content = "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no";

    document.getElementsByTagName('head')[0].appendChild(metaTag);
    this.onRender();

    if (AUTOSTART) {
      const { game } = this.state;
      const { missions, agents } = game;
      if (missions.length === 0) {
        return;
      }
      const args = { mission: missions[0], agents, cost: { time: 0, resource: 0, dangerpay: 0 } };
      this.onMissionQueue(args);

      this.onTick();

      this.autostart = setTimeout(() => {
        this.onFinish(0);
      }, 1000);
    }
    if (AUTOLOAD >= 0) {
      (async () => {
        const data = await localforage.getItem(`proto_loop_save_${AUTOLOAD}_data`);
        this.deserializeState(data);
        this.setPaused(false);
      })();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyDown);

    this.stopTimer();

    /*
    if (this.autostart) {
      clearTimeout(this.autostart);
      this.autostart = null;
    }
    */
  }

  componentDidUpdate() {
    if (NOQUESTPOPUP) {
      return;
    }

    const { game } = this.state;

    // quest overlay
    for (const quest of game.questTracker.onProgress) {
      if (!quest.notified_spawn) {
        quest.notified_spawn = true;
        this.overlayPush({ ty: 'QUEST', quest });
      }
      if (quest.completed && !quest.notified_complete) {
        quest.notified_complete = true;
        this.overlayPush({ ty: 'QUEST', quest });
      }
    }
    // 일단 매 틱 검사하도록
    for (const key of ['demo_00', 'demo_00_1', 'demo_02', 'demo_03']) {
      this.state.game.triggerQuest(key);
    }
  }

  startTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.timer = window.requestAnimationFrame(this.onTimer);
  }

  stopTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  onKeyEscape() {
    if (this.overlay_cur?.ty === 'PREVIEW') {
      this.overlayPopMaybe('PREVIEW');
    } else {
      let { game } = this.state;
      game.missionSelect(null);
      this.setState({ game, tab_selected: null, worldselect: null });
    }
  }

  onKeyDown(ev) {
    if (ev.key === ' ') {
      const { paused } = this.state;
      ev.preventDefault();
      this.setPaused(!paused);
    }
    if (ev.key === 'Escape') {
      this.onKeyEscape();
    }

    if (this.state.game.state !== 'mission') {
      for (let i = 1; i <= TPS_MULTS.length; i++) {
        const tps_mult = TPS_MULTS[i - 1];
        if (ev.key === (i).toString()) {
          const { simtps, game } = this.state;
          const tps = TPS_BASE * tps_mult;
          if (tps !== simtps) { game.triggerQuest('change_gamespeed'); }
          this.setState({ simtps: tps })
        }
      }
      if (ev.key === "F5") {
        this.serializeState(0);
        ev.preventDefault();
      }
      if (ev.key === "F9") {
        (async () => {
          const data = await localforage.getItem(`proto_loop_save_${0}_data`);
          this.deserializeState(data);
        })();
        ev.preventDefault();
      }
    }

    if (ev.key === ',') {
      this.onTickToPause();
    }
  }

  onTickToPause() {
    while (true) {
      const paused = this.onTickGame();
      if (!paused) {
        continue;
      }

      this.setState({ paused });
      break;
    }
  }

  onTick() {
    const { simtps } = this.state;
    let { paused } = this.state;

    this.now = Date.now();

    if (paused) {
      this.last_ms = this.now;
      return paused;
    }

    const ms_per_tick = 1000 / simtps;
    let max_tick = 100;
    while (this.last_ms + ms_per_tick < this.now) {
      this.last_ms += ms_per_tick;
      paused = this.onTickGame();
      if (paused) {
        break;
      }

      max_tick -= 1;
      if (max_tick <= 0) {
        break;
      }
    }

    this.setState({ paused });
    return paused;
  }

  onTickGame() {
    let { game, tabs_badge, overlays } = this.state;
    const { paused, badge } = game.onTick();

    for (const [key, value] of Object.entries(badge)) {
      tabs_badge[key] = { ...tabs_badge[key], ...value }
    }

    if (paused) {
      const journalItems = game.journals.filter(({ turn, paused }) => paused && turn === game.turn);
      for (const journal of journalItems) {
        this.overlayPush({ ty: 'JOURNAL', journal });
      }
    }

    this.setState({ game, tabs_badge });

    if (game.agentRenewalCount() > 0 && !overlays.find(({ ty }) => ty === 'RENEWAL')) {
      this.overlayPush({ ty: 'RENEWAL' });
    }

    if (game.department_events.length > 0) {
      const [event] = game.department_events;
      if (event !== this.overlay_cur) {
        this.overlayPush(event);
      }
      // overlay가 닫힐 때 department_events를 비워준다.
      // game.department_events.splice(0, game.department_events.length);
    }

    return paused;
  }

  static stateDescr(state) {
    const { turn, stage, agents } = state;
    return `turn=${turn}, stage=${stage} agents=${agents.length}`;
  }

  checkCyclic(obj, stack = []) {
    if (stack.includes(obj)) {
      console.log('cyclic', obj, stack);
      throw new Error("HERE");
    }
    if (typeof obj !== 'object') {
      return;
    }
    stack.push(obj);
    for (const key in obj) {
      this.checkCyclic(obj[key], stack);
    }
    stack.pop();
  }

  // save-load
  serializeState(idx) {
    const { savedescrs, game } = this.state;

    (async () => {
      const descr = RoguelikeView.stateDescr(game);
      await localforage.setItem(`proto_loop_save_${idx}_descr`, descr);

      const state = { ...game };
      state.world = state.world.serialize();

      this.checkCyclic(state, []);

      await localforage.setItem(`proto_loop_save_${idx}_data`, JSON.stringify(state));
      savedescrs[idx] = descr;

      this.setState({ savedescrs });
    })();
  }

  discardState(idx) {
    const { savedescrs } = this.state;

    (async () => {
      await localforage.removeItem(`proto_loop_save_${idx}_descr`);
      await localforage.removeItem(`proto_loop_save_${idx}_data`);
      savedescrs[idx] = null;

      this.setState({ savedescrs });
    })();
  }

  deserializeState(data) {
    function fixObject(o) {
      if (Array.isArray(o)) {
        return o.map(fixObject);
      }
      if (!isNaN(o)) {
        return o;
      }
      if (typeof o === 'string') {
        return o;
      }

      const keys = Object.keys(o);
      if (keys.length === 2 && keys[0] === 'x' && keys[1] === 'y') {
        return new v2(o.x, o.y);
      }

      for (const key of keys) {
        o[key] = fixObject(o[key]);
      }
      return o;
    }

    const state = JSON.parse(data);
    fixObject(state);

    state.mission_selected = state.missions.find((m) => _.isEqual(m, state.mission_selected));
    const pending_missions = state.pendings.filter((pending) => pending.ty === 'mission').map(({ mission_state }) => mission_state);
    // TODO: 임무 못 돌아오는 버그 우회
    for (const agent of state.agents) {
      if (agent.state === 'mission') {
        const found = pending_missions.find(({ agents }) => {
          return agents.find((a) => a.idx === agent.idx);
        });
        if (!found) {
          agent.state = null;
        }
      }

      if (agent.power === null) {
        agent.power = 8;
        for (const key of Object.keys(agent.stats2)) {
          agent.stats2[key] = 8;
        }
      }
    }

    // 이름 버그 우회
    const names = names3.filter((name) => {
      if (name === null || name === undefined) {
        return false;
      }
      if (state.agents.find((a) => a.name === name)) {
        return false;
      }
      if (pending_missions.find(({ agents }) => agents.find((a) => a.name === name))) {
        return false;
      }
      if (state.recruit_listings.find(({ agent }) => agent.name === name)) {
        return false;
      }
      return true;
    });
    state.names = names;

    for (const item of state.cashbook) {
      if (item.agents) {
        item.agents = item.agents.map(({ agent, idx }) => {
          return { agent, idx };
        });
      }
    }

    state.rng = new Rng(Rng.randomseed());

    // TODO: mission 시드 업데이트
    for (const mission of state.missions) {
      mission.seed = state.rng.next_seed();
    }
    delete state.agents_selected;

    state.world = new WorldState2(state.world);

    const game = new RoguelikeGame(null, state);

    this.setPaused(true);
    this.setState({
      game,
      agents_selected: [],
      overlays: [],
    });
  }

  loadSaveDescrs() {
    (async () => {
      const savedescrs = [];
      for (let i = 0; i < SAVE_SLOTS; i++) {
        savedescrs[i] = await localforage.getItem(`proto_loop_save_${i}_descr`);
      }
      this.setState({ savedescrs });
    })();
  }

  renderSystem() {
    const { game, play_bgm } = this.state;
    function downloadStringAsFile(filename, content) {
      var blob = new Blob([content], { type: "text/plain;charset=utf-8" });
      var downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = filename;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }

    const cansave = !this.state.game.state;

    let slots = [];
    for (let i = 0; i < SAVE_SLOTS; i++) {
      let idx = i;
      const descr = this.state.savedescrs[i];
      const loadButton = <>
        <Button onClick={() => {
          (async () => {
            const data = await localforage.getItem(`proto_loop_save_${idx}_data`);
            this.deserializeState(data);
          })();
        }}>{L('loc_ui_button_system_load')}</Button>
        <Button onClick={() => {
          (async () => {
            const data = await localforage.getItem(`proto_loop_save_${idx}_data`);
            downloadStringAsFile(`save_${idx}.json`, data);
          })();
        }}>{L('loc_ui_button_system_export_file')}</Button>
        <Button onClick={() => {
          (async () => {
            const data = await localforage.getItem(`proto_loop_save_${idx}_data`);
            this.saveRef.current.value = data;
          })();
        }}>{L('loc_ui_button_system_export_string')}</Button>
      </>;
      let saveButton = null;
      if (i > 0) {
        <Button onClick={() => {
          (async () => {
            const data = await localforage.getItem(`proto_loop_save_${idx}_data`);
            this.deserializeState(data);
          })();
        }}>{L('loc_ui_button_system_load')}</Button>
        saveButton = <Button onClick={() => this.serializeState(idx)} disabled={!cansave}>{L('loc_ui_button_system_save')}</Button>;
      }
      const discardButton = <Button onClick={() => this.discardState(idx)}>{L('loc_ui_button_system_delete_save')}</Button>;

      if (!descr) {
        slots.push(<div key={idx}>{L('loc_dynamic_string_save_slot_empty')} {saveButton}</div>);
        continue;
      }
      slots.push(<div key={idx}>{L('loc_dynamic_string_save_slot', { idx, descr })} {loadButton} {saveButton} {discardButton}</div>);
    }

    const renderConfig = (obj) => {
      return Object.keys(obj).map((ty) => {
        const enabled = obj[ty];
        return <div key={ty}>
          <CheckBox checked={enabled} onChange={(ev) => {
            obj[ty] = ev.target.checked;
            this.setState({ game });
          }}>{L('loc_ui_string_system_pause_' + ty)}</CheckBox>
        </div>;
      });
    };

    return <Window
      title={L('loc_ui_title_menu_system')}
      showBackButton={false}
      onClickButtonClose={this.onKeyEscape.bind(this)}
    >
      <Panel title={`${L('loc_ui_string_save_load')} %% TODO %% (seed: ${this.state.game.seed})`}>
        <div className="box">
          <p className="flex-title"></p>
          {slots}
          <textarea ref={this.saveRef} />
          <Button onClick={(() => {
            const data = this.saveRef.current.value;
            this.deserializeState(data);
          })}>import</Button>
          <Button onClick={() => {
            this.setState({ tab_selected: 'JOURNAL' });
          }}>journal</Button>
        </div>
      </Panel>
      <Panel title={L('loc_ui_string_system_setting')}>
        <div className="box">
          <p className="flex-config-title">{L('loc_ui_string_system_language')}</p>
          {localeKeys.map((locale) => {
            let cls = "mission-warning-overlay-btn2";
            if (locale === this.state.locale) {
              cls += " mission-warning-overlay-btn2-selected";
            }

            return <button key={locale} className={cls} onClick={() => {
              localeSet(locale);
              this.setState({ locale });
            }}>{L('loc_ui_string_system_language_' + locale.toLowerCase())}</button>;
          })}
        </div>

        <div className="box">
          <p className="flex-config-title">{L('loc_ui_string_system_pause')}</p>
          {renderConfig(game.pauseconfig)}
        </div>
        <div className="box">
          <p className="flex-config-title">{L('loc_ui_string_system_notification')}</p>
          {renderConfig(game.notificationconfig)}
        </div>
        <div className="box">
          <p className="flex-config-title">{L('loc_ui_string_system_audio')}</p>
          <Button onClick={() => this.setState({ play_bgm: !play_bgm })}>BGM: {play_bgm ? 'ON' : 'OFF'}</Button>
        </div>
      </Panel>
    </Window>;
  }

  onTogglePermadeath() {
    const { game } = this.state;
    game.permadeath = !game.permadeath;
    this.setState({ game });
  }

  onTabSelect(tab) {
    const { game, tabs_badge, tab_selected } = this.state;
    const { notifications } = game;

    if (tab === tab_selected) {
      this.setState({ tab_selected: null, worldselect: null });
      return;
    }

    tabs_badge[tab].new_badge = false;

    const relevant_noti_tys =
      Object.entries(NOTIFICATION_TABLE).filter(([_k, v]) => v.tab === tab).map(([k, _v]) => k);
    for (const n of notifications.filter((n) => relevant_noti_tys.includes(n.ty))) {
      game.dismissNotification(n);
    }

    this.setState({ game, tab_selected: tab });
  }

  renderPause() {
    const { game, paused } = this.state;
    if (!paused) {
      return null;
    }

    const { journals, turn } = game;

    return <div className="top-paused">
      <h1>PAUSED</h1>
      {journals.filter((item) => item.turn === turn).slice(0, 4).map(({ msg }, i) => <p key={i}>{msg}</p>)}
    </div>;
  }

  onPlay() {
    this.setPaused(false);
  }

  onPause() {
    this.setPaused(true);
  }

  setPaused(paused) {
    if (paused === this.state.paused) {
      return;
    }

    if (paused) {
      this.stopTimer();
    } else {
      this.last_ms = Date.now();
      this.startTimer();
    }
    this.setState({ paused });
  }

  renderTime() {
    const { game, paused, simtps } = this.state;

    let curspeed = Math.round(simtps / TPS_BASE);
    let curspeedidx = TPS_MULTS.indexOf(curspeed);
    let speedidx_prev = Math.max(0, curspeedidx - 1);
    let speedidx_next = Math.min(TPS_MULTS.length - 1, curspeedidx + 1);

    const setspeed = (idx) => {
      const tps = TPS_BASE * TPS_MULTS[idx];
      if (tps !== simtps) {
        game.triggerQuest('change_gamespeed');
      }
      this.setState({ game, simtps: tps });
    };

    const togglebtn = (src, on, onClick) => {
      let led = "/img/lobby/UI_LED_OFF.png";
      if (on) {
        led = "/img/lobby/UI_LED_ON.png";
      }

      return <div className="flex-player-btn2" onClick={() => onClick()} >
        <img src={src} />
        <img src={led} />
      </div>
    };

    return <>
      {togglebtn("/img/lobby/UI_BTN_Pause.png", paused, this.onPause.bind(this))}
      {togglebtn("/img/lobby/UI_BTN_Play.png", !paused, this.onPlay.bind(this))}
      <img className="flex-player-btn2" src="/img/lobby/UI_BTN_FR.png" onClick={() => setspeed(speedidx_prev)} />
      <img className="flex-player-speedo" src={`/img/lobby/Speedo_${String(curspeed).padStart(2, '0')}.png`} />
      <img className="flex-player-btn2" src="/img/lobby/UI_BTN_FF.png" onClick={() => setspeed(speedidx_next)} />
    </>;

    /*
      <div className='flexleft-time-btngroup'>
        <Button disabled={!paused} className='flexleft-time-btn' onClick={this.onPlay.bind(this)}>play</Button>
        <Button disabled={paused} className='flexleft-time-btn' onClick={this.onPause.bind(this)}>pause</Button>
      </div>
      <div className='flexleft-time-btngroup'>
        {[1, 3, 10, 20, 100].map((speed) => {
          const tps = speed * TPS_BASE;
          let disabled = tps === simtps;
          return <Button disabled={disabled} key={speed} className='flexleft-time-btn'
            onClick={() => { this.setState({ game, simtps: tps }); }}>x{speed}</Button>;
        })}
      </div>
      <span onClick={this.onTogglePermadeath.bind(this)}>permadeath={game.permadeath ? 'on' : 'off'}</span>
      */

  }

  onAgentAcquirePerk(agent, perk) {
    if (!perk || agent.perks.list.find((p) => p === perk)) {
      return;
    }
    const { game } = this.state;
    game.agentAcquirePerk(agent, perk);
    this.setState({ game });
  }

  onMissionSelect(mission, force) {
    const { game } = this.state;
    game.missionSelect(mission, force);
    this.setState({ game });
  }

  onSpawnChange(agent) {
    const { game } = this.state;
    game.agentSpawnChange(agent);
    this.setState({ game });
  }

  onResetMissionConfig() {
    const { game } = this.state;
    game.resetMissionConfig();
    this.setState({ game });
  }

  onAgentEquipAvail(agent, equip_ty, index) {
    const { game } = this.state;
    const { inventories } = game;

    let cur = null;
    let reason = null;
    if (equip_ty === 'firearm') {
      cur = <FirearmLabel firearm={agent.firearm} />;
      const firearms = _.uniqBy(inventories.filter((e) => e.ty === 'firearm'), (e) => e.firearm.firearm_name + (e.firearm.options ?? []));
      if (firearms.length === 0) {
        reason = L('loc_ui_longtext_agent_firearm_disabled');
      }
    } else if (equip_ty === 'equipment') {
      cur = <GearLabel equipment={agent.equipment} />;
      const equipments = _.uniqBy(inventories.filter((e) => {
        if (e.ty !== 'equipment') {
          return false;
        }
        if (e.equipment.vest_name === agent.equipment.vest_name && (e.equipment.options ?? []).join('') === (agent.equipment.options ?? []).join('')) {
          return false;
        }
        if (e.equipment.vest_rate * 4 > agent.stats2.bravery) {
          return false;
        }
        return true;
      }), (e) => e.equipment.vest_name + (e.equipment.options ?? []));
      if (equipments.length === 0) {
        reason = L('loc_ui_longtext_agent_equipment_disabled');
      }
    } else if (equip_ty === 'throwable') {
      cur = <ThrowableLabel throwable={agent.throwables[index]} />;
      const throwables = _.uniqBy(inventories.filter((e) => e.ty === 'throwable'), (e) => e.throwable.throwable_name + (e.throwable.options ?? []));
      if (index === 0 && !agentHasThrowableSlot1(agent)) {
        reason = L('loc_ui_longtext_agent_throwable_disabled_perk');
      } else if (index === 1 && !agentHasThrowableSlot2(agent)) {
        reason = L('loc_ui_longtext_agent_throwable_disabled_perk_2');
      }
      if (reason === null && throwables.length === 0) {
        reason = L('loc_ui_longtext_agent_throwable_disabled_no_item');
      }
    } else {
      throw new Error(`unknown equip_ty ${equip_ty}`);
    }

    if (agent.state) {
      return {
        cur,
        avail: false,
        reason: agent.state,
      };
    }

    return {
      cur,
      avail: reason === null,
      reason,
    };
  }

  onAgentEquipPopup(agent, equip_ty, equip_idx) {
    this.overlayPush({ ty: 'EQUIP', equip_ty, agent, equip_idx });
  }

  onItemEquipPopup(item) {
    this.overlayPush({ ty: 'ITEM_EQUIP', item });
  }

  onAgentEquip(agent, item, num) {
    const { game } = this.state;
    game.agentEquip(agent, item, num);
    this.setState({ game });
  }

  onAgentDisarm(agent) {
    const { game } = this.state;
    game.agentDisarm(agent);
    this.setState({ game });
  }

  onAgentGoDispatch(dispatch, agent, areaNum, trainingCat, recurr) {
    const { game } = this.state;
    game.agentGoDispatch(dispatch, agent, areaNum, trainingCat, recurr);
    this.setState({ game });
  }

  onAgentCancelDispatchRecurrence(dispatch) {
    const { game } = this.state;
    game.agentCancelDispatchRecurrence(dispatch);
    this.setState({ game });
  }

  onFinish(res) {
    // TODO: 원인 찾아서 고쳐야 함
    if (!this.simRef.current) {
      return;
    }
    const sim = this.simRef.current.state.sim;

    const { game } = this.state;
    game.onFinish(sim, res);
    this.setState({ game });
  }

  onInventorySell(item) {
    const { game } = this.state;
    game.inventorySell(item);
    this.setState({ game });
  }

  onMarketListingPurchase(item, cost) {
    const { game, tabs_badge } = this.state;
    if (game.marketListingPurchase(item, cost)) {
      tabs_badge['WAREHOUSE'].new_badge = true;
    }
    this.setState({ game });
  }

  onAreaUnlock(center, cost) {
    const { game } = this.state;
    game.areaUnlock(center, cost);
    this.setState({ game });
  }

  onAreaBase(center, cost) {
    const { game } = this.state;
    game.areaBase(center, cost);
    this.setState({ game });
  }

  onBarrackLevelUp() {
    const { game } = this.state;
    game.barrackLevelUp();
    this.setState({ game });
  }

  onAgentTerminate(agent, cost) {
    const { game } = this.state;
    game.agentTerminate(agent, cost);
    this.setState({ game });
  }

  onBaseSelect(idx) {
    const { game } = this.state;
    game.baseSelect(idx);
    this.setState({ game, agents_selected: [] });
  }

  renderAgentTab(agent, agent1, tab) {
    if (!agent1) {
      return null;
    }

    const { game } = this.state;
    const { turn, barrack_vacancy } = game;

    const { contract } = agent1;
    const contracted = contract.term > 0;

    let body = null;
    if (!agent1) {
      return null;
    }

    if (tab === 'OVERVIEW') {
      if (contracted) {
        body = <MercenariesAgentDetailOverview
          agent={agent1}
          tooltipHandlers={this.tooltipHandlers}
          onAgentAvail={this.onAgentAvail.bind(this)}
          onAgentEquipPopup={(ty, index) => {
            return this.onAgentEquipPopup(agent, ty, index);
          }}
          firearmShape={
            <div
              style={{ position: 'absolute', top: -21, left: -11 }}
            >
              <FirearmShape
                className="firearm-shape-instance"
                ty={`ty${agent.firearm?.firearm_ty?.toLowerCase()}-1`}
              />
            </div>
          }
        />;
      } else {
        body = <AgentRecruitOverview
          agent={agent}
          game={game}
          avail={barrack_vacancy > 0}
          onDone={() => this.setState({ game })}
          tooltipHandlers={this.tooltipHandlers}
        />;
      }
    } else {
      let inner = null;
      if (tab === 'STAT') {
        inner = <AgentStatGuages agent={agent1} slots={game.slots} tick={game.turn} global_effects={game.effects} />;
      }
      // ToDo: nakwon 머지전에 지워야함
      if (tab === 'PERK') {
        inner = <AgentPerkWrapper agent={agent1}
          onAgentAcquirePerk={(a, perk) => this.onAgentAcquirePerk(a, perk)}
        />;
      } else if (tab === 'CONTRACT') {
        inner = <AgentContractWrapper
          agent={agent}
          turn={turn}
          onAgentTerminate={() => {
            this.onAgentsAgentDetailContractCancel(agent);
            this.overlayPopMaybe('PREVIEW');
          }}
        />;
      }

      body = <MercenariesAgentDetailPlaceholder body={inner} />;
    }
    return body;
  }

  renderAgents() {
    const { game, agent_agentsDetail: agent, subtab_agentsDetail: tab } = this.state;
    const { agents, turn } = game;
    const { contract } = agent ?? {};

    return <Window
      title={L('loc_ui_title_menu_agent')}
      showBackButton={false}
      showCloseButton={true}
      onClickButtonClose={this.onKeyEscape.bind(this)}
    >
      <Panel title={L('loc_ui_string_agent_list')} className="fh1-panel-left">
        <Well style={{ width: '100%', ...styleHeight }}>
          {agents.map((a) =>
            <AgentItem key={a.name} agent={a} turn={turn}
              onAgentSelect={this.onAgentsAgentDetail.bind(this)} selected={agent?.idx === a.idx} />
          )}
        </Well>
      </Panel>
      <Panel
        title={agent?.name}
        subtitle={contractExpiresInStr(contract, turn)}
        style={{ width: 800 }}>
        <AgentDetail
          agent={agent}
          game={game}
          tab_selected={tab}
          onTabSelect={this.onAgentsAgentDetailSubTabSelect.bind(this)}

          onAgentEquipPopup={this.onAgentEquipPopup.bind(this)}
          onAgentEquipAvail={this.onAgentEquipAvail.bind(this)}

          onDone={() => this.setState({ game })}

          onAgentTerminate={() => {
            this.onAgentsAgentDetailContractCancel(agent);
            this.overlayPopMaybe('PREVIEW');
            this.setState({ agent_agentsDetail: null });
          }}

          onAgentAcquirePerk={this.onAgentAcquirePerk.bind(this)}
        />
      </Panel>
    </Window>;
  }

  renderResearch() {
    const { game } = this.state;
    const { progress } = game;

    return <div className="popupcard flex-card-long">
      <p className="flex-title">research</p>
      <ResearchEffects progress={progress} onEffectClaim={(effect) => {
        game.onResearchEffectClaim(effect);
        this.setState({ game });
      }} />
      <div className="flex-research-body">
        <ResearchView progress={progress} onStateTransit={(research) => {
          progress.onStateTransit(research);
          this.setState({ game });
        }} table={true} />
      </div>
    </div>;
  }

  onAgentPreview(agent) {
    this.overlayPush({ ty: 'PREVIEW', agent });
  }

  onAgentsAgentDetail(agent) {
    // 선택해제는 임시로 막아둠 용병상세 화면 분리할때까지
    // if (agent_agentsDetail && agent_agentsDetail.name === agent?.name) {
    //   this.setState({ agent_agentsDetail: null });
    //   return;
    // }

    this.setState({ agent_agentsDetail: agent });
  }

  onAgentsAgentDetailSubTabSelect(k) {
    const tab = SUBTABS_AGENT.find(({ key }) => key === k).key;
    this.setState({ subtab_agentsDetail: tab });
  }

  onAgentsAgentDetailContractCancel(agent) {
    const { game, agent_agentsDetail } = this.state;
    const { agents, turn } = game;
    const agent0 = agents.find((a) => a.name === agent.name);
    if (!agent0) {
      return;
    }
    const { contract } = agent0;
    const contractRemainMonth = Math.floor((contract.term + contract.start - turn + TICK_PER_MONTH - 1) / TICK_PER_MONTH);
    const terminationCost = contractRemainMonth * contract.turnCost / 2;
    this.onAgentTerminate(agent0, terminationCost);
    if (agent0 === agent_agentsDetail) {
      this.setState({ agent_agentsDetail: null });
    }
  }

  overlayPush(overlay) {
    let { overlays } = this.state;
    overlays.push(overlay);
    this.setState({ overlays });
  }

  overlayUpdate(obj) {
    const overlays = this.state.overlays.slice();
    const last = this.overlay_cur;
    overlays[overlays.length - 1] = { ...last, ...obj };
    this.setState({ overlays });
  }

  overlayPop() {
    const overlays = this.state.overlays.slice();
    overlays.pop();
    this.setState({ overlays });
  }

  overlayPopMaybe(ty) {
    if (this.overlay_cur?.ty === ty) {
      this.overlayPop();
    }
  }

  renderCompany() {
    const { game } = this.state;
    const { departmentRoot } = game;
    return <Window
      title={L('loc_ui_title_menu_organization')}
      showBackButton={false}
      showCloseButton={true}
      onClickButtonClose={this.onKeyEscape.bind(this)}
    >
      <DepartmentView departmentRoot={departmentRoot} game={game} onGameUpdated={() => this.setState({ game })} ref={this.departmentRef} />
    </Window>;
  }

  onContractRenewal(ty, agent, option) {
    const { game } = this.state;

    game.contractRenew(ty, agent, option);

    if (game.agentRenewalCount() === 0) {
      this.overlayPopMaybe('RENEWAL');
    }
    this.setState({ game });
  }

  onMissionQueue(props, force) {
    const { game, tabs_badge } = this.state;

    if (!force && game.pauseconfig.mission_warning) {
      if (props.expectation?.resp?.class < 2) {
        this.overlayPush({ ty: 'MISSION_WARNING', props });
        return;
      }
    }

    game.missionQueue(props);
    if (props.mission.milestone) {
      tabs_badge['CONTRACTS'].badge = null;
    }
    this.setState({ game, agents_selected: [], tabs_badge });
  }

  renderAgentRenewal() {
    const { game } = this.state;
    const { agents, resources, global_modifier, turn } = game;

    //용병기간
    return agents.map((agent) => {
      agent = { ...agent };
      const { contract: { start, term } } = agent;
      if (start + term <= turn) {
        // 재계약은 항상 가능합니다
        return <AgentContract
          key={agent.name}
          agent={agent}
          game={game}
          resources={resources}
          turn={turn}
          avail={true}
          onAgentPreview={this.onAgentPreview.bind(this)}
          onContractRenewal={this.onContractRenewal.bind(this)} global_modifier={global_modifier} />;
      }
      return null;
    })
  }

  renderAgentRenewalV2() {
    const { game } = this.state;
    const { turn, agents } = game;
    const agents1 = agents.filter(({ contract: { start, term } }) => start + term <= turn);

    return <AgentsContract
      game={game}
      agents={agents1}
      onContractRenewal={(ty, agent1, option) => {
        const agent = agents.find((a) => a.name === agent1?.name);
        if (agent === null) {
          return;
        }
        this.onContractRenewal(ty, agent, option);
      }}
      onDone={() => this.setState({ game })}
      tooltipHandlers={this.tooltipHandlers}
    />;
  }

  onMissionComplete() {
    const { game } = this.state;
    const { res, mission } = game.mission_state;
    if (mission?.milestone_last && res === 0) {
      this.setState({ cutscene: game.mission_state.mission.mission_level });
    }
    game.missionComplete();
    this.setState({ game }, () => this.serializeState(0));
  }

  renderMissionResult() {
    const { game } = this.state;
    const { state, turn, areas, mission_state, resources } = game;

    let content = null;
    if (state === 'reward') {
      content = <>
        <span className="mission-result-subtitle">{L('loc_ui_string_result_reward_select')}</span>
        {this.renderRewardSelect()}
      </>;
    } else if (state === 'event') {
      content = <>
        <span className="mission-result-subtitle">{L('loc_ui_string_result_event')}</span>
        {this.renderEventSelect()}
      </>;
    } else if (state === 'result') {//적용하는 단계
      content = <>
        <Button onClick={this.onMissionComplete.bind(this)}>{L('loc_ui_button_common_continue')}</Button>
      </>;
    }

    const { res, mission } = mission_state;
    const result = (res === 0 ? L('loc_ui_string_common_success') : (res === 1 ? L('loc_ui_string_common_failure') : L('loc_ui_string_common_pass')));

    const onAgentRevive = (idx) => {

      const agent = game.agents.find((a) => a.idx === idx);
      if (!agent) {
        return;
      }

      const deadAgentIndex = mission_state.reward.findIndex((r) => r.ty === 'deadAgent' && r.args.agent.idx === idx);
      if (deadAgentIndex >= 0) {
        mission_state.reward.splice(deadAgentIndex, 1);
      }

      const renownIndex = mission_state.reward.findIndex((r) => r.ty === 'renown' && r.args.reason === 'deadAgent' && r.args.idx === idx);
      if (renownIndex >= 0) {
        mission_state.reward.splice(renownIndex, 1);
      }

      const agentReward = mission_state.reward.find((r) => r.ty === 'agent' && r.args.agent.idx === idx);
      if (agentReward) {
        agentReward.args.life = Math.floor(agent.life_max * 0.1);
      }

      const { turnCost, shareRatio } = agent.contract;
      const reviveCost = Math.round(turnCost + shareRatio * 5000);
      mission_state.reward.push({ ty: 'resource', args: { ty: 'revive', resource: -reviveCost, idx: agent.idx } });

      this.setState(game);
    }

    return <>
      <p className="mission-result-title">{L('loc_ui_title_result') + result}</p>

      <div className="mission-result">
        {mission ? <>
          <span className="mission-result-subtitle">{L('loc_ui_string_result_contract_information')}</span>
          <MissionItem turn={turn} mission={mission_state.mission} areas={areas} readonly global_effects={game.effects} />
        </> : null}
        <ReportView game={game} onRevive={(idx) => onAgentRevive(idx)} />
        <ClipboardView label="디버그: 게임 복사" content={this.state.game.serialized} />
      </div>

      <hr className="flex-sep" />
      {content}
    </>;

    /*
      <span className="mission-result-subtitle">결산 기록</span>

      {<div className='whitespace-pre-wrap'>
        {renderReward(mission_state.reward, game.areas).map((r, i) => <p key={i}>{r}</p>)}
        </div>}
      */
  }

  onClickReward(ty, val) {
    const { game, tabs_badge } = this.state;
    game.missionSelectReward(ty, val);
    if (ty === 'agent') {
      tabs_badge['MERCENARIES'].new_badge = true;
    } else if (ty !== 'resource' && ty) {
      tabs_badge['WAREHOUSE'].new_badge = true;
    }
    this.setState({ game, tabs_badge });
  }

  renderRewardSelect() {
    const { game } = this.state;
    const { mission_state: { reward_choices } } = game;

    return <div className="box mission-result-reward-select">
      {reward_choices.map((r) => {
        const { ty, disabled } = r;
        const value = r[ty];

        return this.renderRewardSelectButton(ty, value, disabled);
      })}
    </div>;
  }

  renderRewardSelectButton(ty, value, disabled) {

    const descr = {
      agent: '[디버그] 이 텍스트가 보이면 안 됩니다.',
      equipment: 'loc_ui_longtext_result_reward_equipment',
      throwable: 'loc_ui_longtext_result_reward_throwable',
      firearm: 'loc_ui_longtext_result_reward_firearm',
      resource: 'loc_ui_longtext_result_reward_resource',
    };

    switch (ty) {
      case 'firearm':
        return <FirearmBox selectable_reward={true} value={value} title={L(descr['firearm'])}
          onClick={() => this.onClickReward(ty, value)} />;
        break;
      case 'equipment':
        return <GearBox selectable_reward={true} value={value} title={L(descr['equipment'])}
          onClick={() => this.onClickReward(ty, value)} />;
        break;
      case 'throwable':
        return <ThrowableBox selectable_reward={true} value={value} title={L(descr['throwable'])}
          onClick={() => this.onClickReward(ty, value)} />;
        break;
      case 'resource':
        return <CashBox selectable_reward={true} value={value} title={L(descr['resource'])}
          onClick={() => this.onClickReward(ty, value)} />;
        break;
      case 'none':
        return <NoneBox selectable_reward={true} value={null} title={null}
          onClick={() => this.onClickReward(null, null)} />;
      default:
        return <Button disabled={disabled} key={ty} title={L(descr[ty])}
          onClick={() => this.onClickReward(ty, value)}>{ty}:{value}</Button>;
        break;
    }
  }

  onClickEvent(ev) {
    const { game } = this.state;
    game.missionSelectEvent(ev);
    this.setState({ game });
  }

  renderEventSelect() {
    const { game } = this.state;
    const { rng, resources, mission_state, global_modifier, agents } = game;
    const event = mission_state.mission.event;
    const num = event.num;
    const events0 = events.filter((event) => event.num === num);
    let selectModifier = [];
    let selectAgentModifier = [];
    let cnt = 0;
    if (num === 18) {
      while (selectModifier.length < Math.min(3, global_modifier.length) && cnt < 100) {
        cnt++;
        const modifier = rng.choice(global_modifier).key;
        if (selectModifier.includes(modifier)) {
          continue;
        }
        selectModifier.push(modifier);
      }
    } else if (num === 26) {
      const agents0 = agents.filter((a) => a.modifier.length > 0);
      while (selectAgentModifier.length < Math.min(3, agents0.length) && cnt < 100) {
        cnt++;
        const agent0 = rng.choice(agents0);
        if (selectAgentModifier.filter(({ agent }) => agent0.idx === agent.idx).length > 0) {
          continue;
        }
        const modifier = rng.choice(agent0.modifier);
        selectAgentModifier.push({ agent: agent0, modifier: modifier.key });
      }
    }

    const resource = resources.resource + mission_state.reward.reduce((sum, r) => {
      if (r.ty === 'resource') {
        return sum + r.args;
      } else {
        return sum;
      }
    }, 0)

    const buttons = events0.map((e, i) => {
      const event = { ...e };
      let disabled = false;
      let desc = event.title;
      if (resource < event.cost) {
        disabled = true;
      }
      if (num === 18) {
        if (i >= selectModifier.length) {
          return null;
        }
        event.modifier = [selectModifier[i]];
        desc = L('loc_ui_button_result_event_remove_modifier') + L(modifiers[selectModifier[i]].name)
      }
      if (num === 26) {
        if (i >= selectAgentModifier.length) {
          return null;
        }
        const { agent, modifier } = selectAgentModifier[i];
        event.modifier = [modifier];
        event.agent = agent;
        desc = L('loc_ui_button_result_event_remove_modifier') + L(modifiers[modifier].name) + '(' + agent.name + ')';
      }
      if (num === 2 || num === 22) {
        const gcapData = gcap.sample(rng, mission_state.mission.area.num);
        const { spawn_power_min, spawn_power_max } = gcapData;
        const power = rng.range(spawn_power_min, spawn_power_max);

        desc = desc.replace('X', power.toFixed(1));
        event.amount = event.amount ?? power;
      }

      let title = '';
      let className;
      if (event.modifier) {
        for (const m of event.modifier) {
          title += L(modifiers[m].desc) + '\n';
        }
        className = 'tooltip'
      }

      return <p key={i}>
        {event.action}
        <Button className={className} title={title} disabled={disabled} onClick={() => this.onClickEvent(event)}>{L(desc)}</Button>
      </p>;
    }).filter((b) => b !== null);

    if (buttons.length === 0) {
      return <div key={num} className='box'>
        <Button key="pass" onClick={() => {
          this.onClickEvent(null);
        }}>{L('loc_ui_button_longtext_result_event_none')}</Button>
      </div>;
    }

    return <div key={num} className='box'>
      <p>[{L(event.title)}]{L(event.desc)}</p>
      {buttons}
    </div>;
  }

  renderPendings() {
    const { game } = this.state;
    const { turn, pendings, notifications } = game;

    const pendings0 = pendings.filter((p) => p.ty !== "mission");

    const dismissNotification = (p) => {
      game.dismissNotification(p);
      this.setState({ game });
    };

    return <div className="flexright-upcomings">
      {notifications.map((p, i) => {
        const { mentComplete: ment, tab } = NOTIFICATION_TABLE[p.ty];
        return <div className='flexright-upcoming-complete row' key={i}>
          <div>
            <div>{L(ment)}</div>
            <div onClick={() => {
              dismissNotification(p);
              this.setState({ tab_selected: tab });
            }}>{L('loc_ui_button_drawer_notification_fixnow')}</div>
          </div>
          <Button className='minusbtn' onClick={() => dismissNotification(p)}>-</Button>
        </div>;
      })}
      {pendings0.map((p, i) => {
        if (p.ty === 'renown') {
          return null;
        }
        let count = game.newCount(p.ty);
        if (count === 0) {
          return null;
        }

        const { mentUpcoming: ment } = NOTIFICATION_TABLE[p.ty];
        return <div className='flexright-upcoming row' key={i}>
          <div>
            <div>{L(ment)}: {count}</div>
            <div>{dayjs.duration(p.turn - turn, 'hour').humanize()}</div>
          </div>
        </div>;
      })}
    </div >;
  }

  renderAgentFullLifeList() {
    const { game, showAgentFullLifeList } = this.state;
    const { agents } = game;

    const agents0 = agents.filter((a) => a.life === a.life_max && a.state === null);

    let body;
    if (showAgentFullLifeList) {
      body = <div className='flexright-body'>
        {agents0.map((agent) => {
          const inTraining = game.slots.find((slot) => slot.training?.agentIdx === agent.idx);

          return <div className='flexright-body-item' key={agent.name}>
            <div className='flexright-body-item-name' onClick={() => this.onAgentPreview(agent)}>{agent.name}{inTraining ? "(In training)" : null}</div>
          </div>
        })}
      </div>;
    }

    return <WellSegment title={L('loc_ui_title_drawer_agents_available')}>
      <div className="fh1-content-inner">
        {body}
      </div>
    </WellSegment>;

    /*
    <div className='flexright-title'>
      <div className='flexright-title-left' onClick={() => {
        this.setState({ showAgentFullLifeList: !showAgentFullLifeList })
      }}>{!showAgentFullLifeList ? '▶︎' : '▼'}  임무 수행 가능</div>
    </div>
    */
  }

  renderAgentPointList() {
    const { game, showAgentPointList } = this.state;
    const { agents } = game;

    const agents0 = agents.filter((a) => a.perks.point > 0);
    if (agents0.length === 0) {
      return null;
    }

    let body;
    if (showAgentPointList) {
      body = <div className='flexright-body'>
        {agents0.map((agent) => {
          return <div className='flexright-body-item' key={agent.name}>
            <div className='flexright-body-item-name' onClick={() => this.onAgentPreview(agent)}>{agent.name}</div>
            <div className='flexright-body-item-middle'></div>
            <div className='flexright-body-item-time'>{agent.perks.point}</div>
          </div>
        })}
      </div>;
    }

    return <div className='flexright-trainings'>
      <div className='flexright-title'>
        <Button className='flexright-btn' onClick={() => {
          this.setState({ showAgentPointList: !showAgentPointList })
        }}>{!showAgentPointList ? '▶︎' : '▼'}</Button>
        <div className='flexright-title-left'> 퍽 습득 가능:{agents0.length}</div>
      </div>
      {body}
    </div>
  }

  renderMissionList() {
    const { game, showMissionList } = this.state;
    const { pendings } = game;

    const missions = pendings.filter((p) => p.ty === 'mission');
    if (missions.length === 0) {
      return null;
    }

    let body;
    if (showMissionList) {
      body = <div className='flexright-body'>
        {missions.map(({ mission_state, turn }) => {
          return <div className='flexright-body-item' key={mission_state.mission.title}>
            <div className='flexright-body-item-name'>{mission_state.mission.title}</div>
            <div className='flexright-body-item-middle'></div>
            <div className='flexright-body-item-time'>{dayjs.duration(turn - game.turn, 'hour').humanize()}</div>
          </div>
        })}
      </div>;
    }

    return <div className='flexright-missions'>
      <div className='flexright-title'>
        <Button className='flexright-btn' onClick={() => {
          this.setState({ showMissionList: !showMissionList })
        }}>{!showMissionList ? '▶︎' : '▼'}</Button>
        <div className='flexright-title-left'>임무:{missions.length}</div>
      </div>
      {body}
    </div>
  }

  onWorldMouseMove(p) {
    this.setState({ worldcursor: p });
  }

  onWorldSelect(p) {
    const { game } = this.state;
    const { world, missions, milestone_missions } = game;

    // 임무 선택
    const mission = [
      ...missions,
      ...milestone_missions,
    ].find((m) => qrEq(m.p, p) && game.missionAvailable(m));
    if (mission) {
      this.setState({ tab_selected: 'CONTRACT' });
      game.missionSelect(mission, true);
      return;
    }

    const idx = world.idxqr(p);
    const s = world.storage[idx];
    if (s.centerstate) {
      this.onWorldSelectRegion('BASE', p);
    } else if (s.nearests.length > 0) {
      this.onWorldSelectRegion('REGION', world.qridx(s.nearests[0].idx));
    } else {
      this.setState({ tab_selected: null });
    }
  }

  onWorldSelectRegion(ty, p) {
    const { game } = this.state;
    const { world } = game;

    const idx = world.idxqr(p);
    if (world.storage[idx].centerstate.office) {
      game.centerSelect(idx);
    }

    this.setState({ tab_selected: ty, worldselect: p, game });
  }

  renderWorldCursor() {
    const { worldcursor, game } = this.state;
    const { world, resources, turn } = game;
    if (!worldcursor) {
      return null;
    }

    const idx = world.idxqr(worldcursor);
    const s = world.storage[idx];
    if (!s) {
      return null;
    }

    let area = L('loc_ui_string_worldmap_cursor_base_none');
    if (s.nearests.length > 0) {
      const nearest = world.storage[s.nearests[0].idx].centerstate;
      area = L('loc_ui_string_worldmap_cursor_base') + nearest.name;
    }

    const mission = world.missions.find((m) => m.idx === idx);
    let body = null;
    if (mission) {
      body = <MissionItem key={mission.seed} turn={turn} mission={mission}
        readonly={true} global_effects={game.effects} />
    }

    return <div className="box">
      <p>cursor: {JSON.stringify(worldcursor)}<br />
        ty={TYNAMES[s.ty]}<br />
        {area}<br />
      </p>
      {body}
    </div>;
  }

  renderWorldFacilities() {
    const { game } = this.state;
    const { world } = game;

    const offices = [];
    for (const { idx } of world.centers) {
      if (!world.centerEnabled(idx)) {
        continue;
      }

      offices.push(<div key={idx}>
        {this.renderWorldCenter(idx, true)}
      </div>);
    }

    return <Well style={styleHeight}>
      {offices}
    </Well>;
    // <FacilityTableView data={types} />
  }

  renderWorldBase(readonly) {
    const { worldselect, game } = this.state;
    const { world } = game;

    if (!worldselect) {
      return null;
    }

    const idx = world.idxqr(worldselect);
    if (idx === -1) {
      return null;
    }

    const { centerstate } = world.storage[idx];

    if (!centerstate) {
      return null;
    }

    const bonuses = world.facilityBonuses(idx);

    let rows = [];
    rows.push(<FacilityTableView key='bonuses' data={bonuses} />);
    if (!readonly) {
      rows.push(<div key="areabtn">
        {this.renderAreaButton({ idx })}
      </div>);
    }

    const mode = world.centerMode(idx);

    for (let i = 0; i < centerstate.facilities.length; i++) {
      const f = centerstate.facilities[i];
      if (!data_facilities.facilityIsBase(f.key)) {
        continue;
      }

      let onUpgrade = (center, i, info) => {
        game.facilityUpdate(center, i, info);
        this.setState({ game });
      };

      let onReset = null;
      const data = data_facilities.facilityByKey(f.key);
      if (data.ty0 === 'base_sub' && data.ty1 !== 'empty') {
        onReset = (center, i) => {
          game.facilityReset(center, i);
          this.setState({ game });
        };
      }

      rows.push(<WorldFacilityView key={f.fid} i={i} idx={idx} facility={f} game={game} readonly={readonly} mode={mode}
        state={centerstate}
        onFacilityUpgrade={onUpgrade}
        onFacilityReset={onReset}
      />);
    }

    let cls = 'flex-worldcenter';
    if (readonly) {
      cls += '-readonly';
    }

    return <WellSegment className={cls} onClick={(ev) => {
      ev.preventDefault();
      this.setState({ worldselect: world.qridx(idx) });
    }}
      title={L('loc_ui_string_worldmap_cursor_base') + `${centerstate.name}`}
    >
      <Button onClick={() => {
        this.setState({ tab_selected: 'REGION' });
      }}>{L('loc_ui_button_region_region')}</Button>
      {this.renderFacilityBase(idx)}
      {rows}
    </WellSegment>;
  }

  renderWorldCenter(idx, readonly) {
    const { game } = this.state;
    const { world } = game;
    const { centerstate } = world.storage[idx];

    if (!centerstate) {
      return null;
    }

    const bonuses = world.facilityBonuses(idx);

    let rows = [];
    rows.push(<FacilityTableView key='bonuses' data={bonuses} />);
    if (!readonly) {
      rows.push(<div key="areabtn">
        {this.renderAreaButton({ idx })}
      </div>);
    }

    const mode = world.centerMode(idx);
    const facility_bonus = world.facilityMaxBonusByKey(idx, 'market_adjacent');

    const is_base = data_facilities.facilityIsBase;
    for (let i = 0; i < centerstate.facilities.length; i++) {
      const f = centerstate.facilities[i];
      if (is_base(f.key)) {
        continue;
      }

      let onUpgrade = (center, i, info) => {
        game.facilityUpdate(center, i, info);
        this.setState({ game });
      };

      rows.push(<WorldFacilityView key={f.fid} i={i} idx={idx} facility={f} game={game} readonly={readonly} mode={mode}
        state={centerstate}
        bonus={facility_bonus}
        onFacilityUpgrade={onUpgrade}
      />);
    }

    let cls = 'flex-worldcenter';
    if (readonly) {
      cls += '-readonly';
    }
    return <WellSegment className={cls} onClick={(ev) => {
      ev.preventDefault();
      this.setState({ worldselect: world.qridx(idx) });
    }}
      title={centerstate.name}
    >
      {centerstate.office ? <Button onClick={() => {
        this.setState({ tab_selected: 'BASE' });
      }}>{L('loc_ui_button_region_base')}</Button> : null}
      {this.renderFacilityForces(idx)}
      {rows}
    </WellSegment>;
  }

  renderWorldSelect() {
    const { worldselect, game } = this.state;
    const { world } = game;
    if (!worldselect) {
      return null;
    }

    const idx = world.idxqr(worldselect);
    if (idx === -1) {
      return null;
    }
    return this.renderWorldCenter(idx);
  }

  renderAreaButton(props) {
    const { idx } = props;
    const { game } = this.state;

    const areastate = game.worldAreaState(idx);
    if (areastate === null) {
      return null;
    }

    const { action, conditions, disabled, cost } = areastate;

    let unlockButton = <></>;
    if (action === 'safehouse') {
      unlockButton = <Button disabled={disabled} onClick={() => this.onAreaUnlock({ idx }, -cost)} title={L('loc_ui_longtext_region_secure_safehouse')} >{L('loc_dynamic_button_region_secure_safehouse', { value: action })}</Button>
    }
    else {
      unlockButton = <Button disabled={disabled} onClick={() => this.onAreaBase({ idx }, -cost)}>{L('loc_dynamic_button_region_secure_safehouse', { value: action })}</Button>
    }

    return <>
      <Checklist conditions={conditions} />
      {unlockButton}
    </>;
  }

  renderBase() {
    return <Window
      title="Base"
      showBackButton={false}
      showCloseButton={true}
      onClickButtonClose={this.onKeyEscape.bind(this)}
    >
      <Panel style={{ width: 400 }}>
        {this.renderWorldBase()}
      </Panel>;
    </Window>;
  }

  renderArea() {
    return <Window
      title="Region"
      showBackButton={false}
      showCloseButton={true}
      onClickButtonClose={this.onKeyEscape.bind(this)}
    >
      <Panel style={{ width: 400 }}>
        {this.renderWorldSelect()}
      </Panel>;
    </Window>;
  }

  renderAreas() {
    const area = this.renderWorldSelect();

    return <Window
      title={L('loc_ui_title_menu_region')}
      showBackButton={false}
      showCloseButton={true}
      onClickButtonClose={this.onKeyEscape.bind(this)}
    >
      <Panel title={L('loc_ui_string_region_list')} className="fh1-panel-left">
        {this.renderWorldFacilities()}
      </Panel>
      {area ?
        <Panel title={L('loc_ui_string_region_detail')} style={{ width: 600 }}>
          {area}
        </Panel>
        : null
      }
    </Window>;
    // {this.renderWorldCursor()}
  }

  renderJournals() {
    const { journals } = this.state.game;
    return <div className="popupcard flex-card-short">
      <p className="flex-title">journals</p>
      {journals.slice().reverse().map(({ turn: t, ty, msg }, i) => {
        if (ty === 'fail' || ty === 'success' || ty === 'pass') {
          const title = msg.join('\n');
          return <p key={i} title={title}>{tickToDateStr(t)}: {ty}</p>
        } else {
          return <p key={i}>{tickToDateStr(t)}: {msg}</p>
        }
      })}
    </div>;
  }

  renderNotifications() {
    const { game } = this.state;
    const { rng, tokens, world, departmentRoot, resources, questTracker, effects: global_effects } = game;

    const list = [];

    const { renown } = resources;
    const renown_data = RENOWN[renown.level];
    const milestone_mission = game.milestone_missions.find((m) => m.area.num === renown_data.milestoneNum);
    if (!renown.max) {
      list.push({
        ty: 'milestone_renown',
        level: 'info',
        title: L('loc_dynamic_string_drawer_notification_milestone_renown', { value: renown_data.max - renown.point }),
        msg: L('loc_ui_longtext_drawer_notification_milestone_renown'),
      });
    } else if (milestone_mission) {
      list.push({
        ty: 'milestone_available',
        level: 'info',
        title: L('loc_ui_string_drawer_notification_milestone_available'),
        msg: L('loc_ui_longtext_drawer_notification_milestone_available'),
        action: () => {
          game.missionSelect(milestone_mission);
          this.setState({
            tab_selected: 'CONTRACTS',
            game,
          });
        },
      });
    }

    // const idle_agents = game.agents.filter((a) => a.state === null && a.world_idx && a.life === a.life_max);
    // if (idle_agents.length > 0) {
    //   list.push({
    //     ty: 'agents_idle',
    //     level: 'info',
    //     title: '용병 준비됨',
    //     msg: '임무 수행을 위해 준비된 용병이 있습니다: 용병을 임무에 배치하세요',
    //     action: () => {
    //       const { world_idx } = rng.choice(idle_agents);
    //       const agents_selected = game.agents.filter((a) => a.world_idx === world_idx);
    //       const mission = rng.choice(game.missions);

    //       game.baseSelect(world_idx);
    //       game.missionSelect(mission);
    //       this.setState({
    //         tab_selected: 'CONTRACTS',
    //         game,
    //         agents_selected,
    //       });
    //     },
    //   });
    // }

    const unlocated_agents = game.agents.filter((a) => !a.world_idx);
    if (unlocated_agents.length > 0) {
      list.push({
        ty: 'agents_notlocated',
        level: 'warn',
        title: L('loc_ui_string_drawer_notification_agents_notlocated'),
        msg: L('loc_ui_longtext_drawer_notification_agents_notlocated'),
        action: () => {
          const center = rng.choice(world.centers.filter((c) => world.storage[c.idx].centerstate.office));
          this.onWorldSelectRegion('BASE', world.qridx(center.idx));
        },
      });
    }

    {
      const areastates = game.world.centers.map((c) => game.worldAreaState(c.idx)).filter((a) => a !== null && !a.disabled);
      const unstableArea = game.world.centers.find((c) => game.world.storage[c.idx].centerstate?.safehouse && game.world.centerMode(c.idx) > 0);
      if (areastates.find((a) => a.action === 'safehouse') && !unstableArea) {
        list.push({
          ty: 'world_area_safehouse',
          level: 'info',
          title: L('loc_ui_string_drawer_notification_world_area_safehouse'),
          msg: L('loc_ui_longtext_drawer_notification_world_area_safehouse'),
          action: () => {
            const candidate = rng.choice(areastates.filter((a) => a.action === 'safehouse'));
            this.onWorldSelectRegion('REGION', world.qridx(candidate.idx));
          },
        });
      }
      if (areastates.find((a) => a.action === 'office')) {
        list.push({
          ty: 'world_area_office',
          level: 'info',
          title: L('loc_ui_string_drawer_notification_world_area_office'),
          msg: L('loc_ui_longtext_drawer_notification_world_area_office'),
          action: () => {
            const candidate = rng.choice(areastates.filter((a) => a.action === 'office'));
            this.onWorldSelectRegion('REGION', world.qridx(candidate.idx));
          },
        });
      }
    }

    // 시설
    {
      let avails = [];
      for (const { idx } of world.centers) {
        const mode = world.centerMode(idx);
        const { effects, facilities } = world.storage[idx].centerstate;
        for (const f of facilities) {
          const ty = data_facilities.facilityIsBase(f.key) ? 'BASE' : 'REGION';
          const cur = data_facilities.facilities.find(({ key }) => f.key === key);
          let nexts = facilityUpgrades({ cur, facilities, effects, global_effects, resources, tokens, mode });
          if (nexts.find(({ avail }) => avail)) {
            avails.push({ idx, ty });
          }
        }
      }

      if (avails.length > 0) {
        const { idx, ty } = rng.choice(avails);
        list.push({
          ty: 'facility_upgrade_avail',
          level: 'info',
          title: L('loc_ui_string_drawer_notification_facility_upgrade_avail'),
          msg: L('loc_ui_longtext_drawer_notification_facility_upgrade_avail'),
          action: () => {
            this.onWorldSelectRegion(ty, world.qridx(idx));
          },
        });
      }
    }

    // if (game.slots.length === 0) {
    //   list.push({
    //     ty: 'slots_notexist',
    //     level: 'warn',
    //     title: '훈련 슬롯 없음',
    //     msg: '훈련 슬롯이 없습니다: 용병의 기량이 서서히 감소합니다. 훈련 슬롯을 확보하고 용병을 훈련시키세요',
    //     action: () => {
    //       const center = rng.choice(world.centers.filter((c) => world.storage[c.idx].centerstate.office));
    //       this.onWorldSelectRegion('BASE', world.qridx(center.idx));
    //     },
    //   });
    // } else if (game.slots.length <= game.agents.length && game.slots.find((s) => s.training === null)) {
    //   list.push({
    //     ty: 'slots_idle',
    //     level: 'warn',
    //     title: '할당되지 않은 훈련 슬롯',
    //     msg: '용병이 할당되지 않은 훈련 슬롯이 있습니다: 훈련 슬롯에 용병을 할당하세요',
    //     action: () => this.setState({ tab_selected: 'TRAINING' }),
    //   });
    // } else if (game.slots.length < game.agents.length) {
    //   list.push({
    //     ty: 'slots_insufficient',
    //     level: 'notice',
    //     title: '훈련 슬롯 부족',
    //     msg: '훈련 슬롯 수가 용병 수보다 적습니다. 훈련을 받지 못하는 용병은 시간에 따라 기량이 감소합니다. 훈련 슬롯을 확보하세요.',
    //     action: () => this.setState({ tab_selected: 'TRAINING' }),
    //   });
    // }
    // if (game.agents.find(agent => (isAgentTrainable(agent) && !game.slots.find((s) => s.training?.agentIdx === agent.idx)))) {
    //   list.push({
    //     ty: 'slots_idle',
    //     level: 'warn',
    //     title: L('loc_ui_string_drawer_notification_slots_idle'),
    //     msg: L('loc_ui_longtext_drawer_notification_slots_idle'),
    //     action: () => {
    //       if (game.slots.find((s) => s.training === null)) {
    //         this.setState({ tab_selected: 'TRAINING' });
    //       }
    //       else {
    //         const center = rng.choice(world.centers.filter((c) => world.storage[c.idx].centerstate.office));
    //         this.onWorldSelectRegion('BASE', world.qridx(center.idx));
    //       }
    //     },
    //   });
    // }

    const slots_lowavail = game.slots.filter((s) => s.availability < 50);
    if (slots_lowavail.length > 0) {
      list.push({
        ty: 'slots_insufficient',
        level: 'notice',
        title: L('loc_ui_string_drawer_notification_slots_insufficient'),
        msg: L('loc_ui_longtext_drawer_notification_slots_insufficient'),
        action: () => this.setState({ tab_selected: 'TRAINING' }),
      });
    }
    // else if (slots_noavail.length > 0) {
    //   list.push({
    //     ty: 'slots_insufficient',
    //     level: 'warn',
    //     title: '훈련장 방치됨',
    //     msg: '훈련장의 관리가 제대로 되지 않고 있습니다: 훈련 효율이 크게 감소합니다. 훈련장을 관리해주세요',
    //     action: () => this.setState({ tab_selected: 'TRAINING' }),
    //   });

    // }

    const { staffs } = departmentRoot;
    const departments = departmentRoot.departments.filter(({ key }) => key !== 'temp');
    // if (departments.find((d) => d.head_id === -1)) {
    //   list.push({
    //     ty: 'department_empty',
    //     level: 'warn',
    //     title: '빈 부서',
    //     msg: '직원이 배정되지 않은 부서가 있습니다: 부서에 직원을 배정해주세요',
    //     action: () => this.setState({ tab_selected: 'COMPANY' }),
    //   });
    // }
    if (departments.find((d) => d.head_id === -1 || d.members_id.length < d.members_max)) {
      list.push({
        ty: 'department_empty',
        level: 'warn',
        title: L('loc_ui_string_drawer_notification_department_empty'),
        msg: L('loc_ui_longtext_drawer_notification_department_empty'),
        action: () => this.setState({ tab_selected: 'COMPANY' }),
      });
    }
    if (departments.find((d) => !d.pendings.find((p) => p.work_type === 'main'))) {
      list.push({
        ty: 'department_idle_staff',
        level: 'warn',
        title: L('loc_ui_string_drawer_notification_department_idle_staff'),
        msg: L('loc_ui_longtext_drawer_notification_department_idle_staff'),
        action: () => this.setState({ tab_selected: 'COMPANY' }),
      });
    }
    if (staffs.find((s) => s.department_fid === -1)) {
      list.push({
        ty: 'department_idle_task',
        level: 'notice',
        title: L('loc_ui_string_drawer_notification_department_idle_task'),
        msg: L('loc_ui_longtext_drawer_notification_department_idle_task'),
        action: () => this.setState({ tab_selected: 'COMPANY' }),
      });
    }

    if (!departments.find((d) => d.pendings.find((p) => p.work_key.startsWith("search_agent")))) {
      list.push({
        ty: 'department_notask_agent',
        level: 'notice',
        title: L('loc_ui_string_drawer_notification_department_notask_agent'),
        msg: L('loc_ui_longtext_drawer_notification_department_notask_agent'),
        action: () => this.setState({ tab_selected: 'COMPANY' }),
      });
    }
    if (!departments.find((d) => d.pendings.find((p) => p.work_key.startsWith("source_")))) {
      list.push({
        ty: 'department_notask_gear',
        level: 'notice',
        title: L('loc_ui_string_drawer_notification_department_notask_gear'),
        msg: L('loc_ui_longtext_drawer_notification_department_notask_gear'),
        action: () => this.setState({ tab_selected: 'COMPANY' }),
      });
    }

    //Quest
    if (questTracker.onProgress.filter((q) => !q.objectives.find((o) => !o.completed))) {
      list.push({
        ty: 'quest_notclaim',
        level: 'info',
        title: L('loc_ui_string_drawer_notification_quest_notclaim'),
        msg: L('loc_ui_longtext_drawer_notification_quest_notclaim'),
      });
    }

    const levelToTier = (level) => {
      switch (level) {
        case 'warn':
          return 3;
        case 'info':
          return 2;
        case 'notice':
          return 1;
        default:
          return 0;
      }
    }

    list.sort((a, b) => {
      const tier_a = levelToTier(a.level);
      const tier_b = levelToTier(b.level);
      return tier_b - tier_a;
    });

    const title = <>{L('loc_ui_title_drawer_notification')}<ButtonInline onClick={() => this.setState({ tab_selected: 'SYSTEM' })}>{L('loc_ui_button_drawer_notification_setting')}</ButtonInline></>;
    const list1 = list.filter(({ ty }) => game.notificationconfig[ty] ?? true);
    let body = list1.map(({ ty, title, msg, level, action }, i) => {
      let btn = null;
      if (action) {
        btn = <ButtonInline className="flex-info-row-btn" onClick={action}>{L('loc_ui_button_drawer_notification_fixnow')}</ButtonInline>;
      }
      return <p key={i} className={`flex-info-row flex-info-row-${level}`}>
        <span className={`flex-info-row flex-info-row-${level}-title`} title={msg}>{title}</span> <span className="flex-info-row-push" /> {btn} <ButtonInline className="flex-info-row-btn" onClick={() => {
          game.notificationconfig[ty] = false;
          this.setState({ game });
        }}>{L('loc_ui_button_drawer_notification_dismiss')}</ButtonInline>
      </p>;
    });

    return <div className="flex-notification-root" >
      <WellSegment title={title}>
        <div className="flex-notification-body fh1-content-inner">
          {body}
        </div>
      </WellSegment>
    </div>;
  }

  renderSimpleJournals() {
    const { game } = this.state;
    const { journals } = game;

    const items = journals.slice(-100).reverse().map(({ turn: t, ty, msg, paused }, i) => {
      let cls = 'journal-item';
      let extra = null;
      if (paused) {
        cls += ' journal-item-paused';
        extra = `(Pause 됨)`;
      }

      if (ty === 'fail' || ty === 'success' || ty === 'pass') {
        const title = msg.join('\n');
        return <p key={i} title={title} className={cls}>{tickToDateStr(t)}: {ty}{extra}</p>;
      }

      if (paused) {
        extra = <>
          (Pause 됨)
          <Button onClick={() => {
            game.pauseSet(ty, false);
            for (const item of journals) {
              if (item.ty === ty) {
                item.paused = false;
              }
            }
            this.setState({ game });
          }}>멈추지 않기</Button>
        </>;
      }
      return <p key={i} className={cls}>{tickToDateStr(t)}: {msg}{extra}</p>;
    });

    return <div className="flex-info-root" >
      <WellSegment title={L('loc_ui_title_drawer_journal')}>
        <div className="flex-notification-body fh1-content-inner">
          {items}
        </div>
      </WellSegment>
    </div>;
  }

  renderAgentPreview(agent, overlay) {
    const { game } = this.state;
    const { turn } = game;
    const { contract } = agent;
    const contracted = contract.term > 0;

    let tabs = contracted ? SUBTABS_AGENT : SUBTABS_RECRUIT;
    tabs = tabsWrap(agent, tabs);

    const tab = overlay.tab ?? tabs[0].key;

    return <div className='overlay-agentpreview' onClick={() => this.overlayPopMaybe('PREVIEW')}>
      <span onClick={(e) => { e.stopPropagation(); }}>
        <Window
          title='요원 미리보기'
          showCloseButton={true}
          onClickButtonClose={this.onKeyEscape.bind(this)}
          style={{ minHeight: 920, maxHeight: 920 }}
        >
          <Panel
            title={agent.name}
            subtitle={contracted ? contractExpiresInStr(contract, turn) : null}
            style={{ width: 800 }}
          >
            <AgentDetail
              agent={agent}
              game={game}
              tabs={tabs}
              tab_selected={tab}
              onTabSelect={(tabkey) => {
                const tab = tabs.find(({ key }) => key === tabkey).key;
                this.overlayUpdate({ tab });
              }}

              onAgentEquipPopup={this.onAgentEquipPopup.bind(this)}
              onAgentEquipAvail={this.onAgentEquipAvail.bind(this)}

              onDone={() => this.setState({ game })}

              onAgentTerminate={() => {
                this.onAgentsAgentDetailContractCancel(agent);
                this.overlayPopMaybe('PREVIEW');
                this.setState({ agent_agentsDetail: null });
              }}

              onAgentAcquirePerk={this.onAgentAcquirePerk.bind(this)}
            />
          </Panel>
        </Window>
      </span>
    </div >;
  }

  renderAgentPreviewForHire(overlay) {
    const { agent } = overlay;
    const { game } = this.state;
    const { barrack_vacancy } = game;

    const tabkeys = ['OVERVIEW', 'STAT', 'PERK'];
    const tab = tabkeys[0];

    const set_option = (option) => { agent.contract.option = option; };

    const body = <AgentRecruitOverview
      agent={agent}
      option={agent.contract.option}
      game={game}
      avail={barrack_vacancy > 0}
      negotiated
      onContract={(ty, _agent1, option) => {
        this.onContractRecruit(ty, agent, option);
        game.popDepartmentOverlay(overlay);
      }}
      onDone={() => this.setState({ game })}
      tooltipHandlers={this.tooltipHandlers}
      onChangeOption={(option) => {
        set_option(option);
        this.setState({ game });
      }}
    />;

    return <div className='overlay-agentpreview'>
      <span onClick={(e) => { e.stopPropagation(); }}>
        <Window
          title='영입 협상 완료 후 계약할지 말지'
          showBackButton={false}
          showCloseButton={false}
          style={{ marginTop: 'var(--card-top)' }}
        >
          <Panel title={agent?.name} style={{ width: 800, ...styleHeight }}>
            {body}
          </Panel>
        </Window>
      </span>
    </div >;
  }

  renderAgentPreviewOverview(agent) {
    const { game } = this.state;
    const { turn, inventories } = game;

    const readonly = !game.agents.find((a) => a === agent);
    const { power, growthcap, physical, physicalcap, mission_stats, contract, state } = agent;

    const { amount, label } = agentPowerModifiers(agent, turn);
    const gcapData = gcap.find(growthcap);
    const { wins, loses, damage_done, damage_taken } = mission_stats;

    let damage_done_avg = 0;
    let damage_taken_avg = 0;
    if (damage_done.length > 0) {
      const samples = damage_done.slice(-5);
      damage_done_avg = _.sum(samples) / samples.length;
    }
    if (damage_taken.length > 0) {
      const samples = damage_taken.slice(-5);
      damage_taken_avg = _.sum(samples) / samples.length;
    }

    const traitView = (data) => {
      return data.map(([title, dataView]) => <div className='agent-trait' key={title}>
        <div className='agent-preview1-title'>{title}</div>
        {dataView}
      </div>);
    }

    const contribution = game.agentContribution(agent, TICK_PER_MONTH);

    const leftViews = [
      ['nationality', <div className='agent-preview1-num'>{agent.nationality.nation}</div>],
      ['language', <div className='agent-preview1-num'>{agent.language}</div>],
      ['background', <div className='agent-preview1-num'>
        <span title={agentBackgroundTitle(agent.background)}>{agent.background.name}</span>
      </div>],

      ['traits', <div className='agent-preview1-num'>
        <span className="seplist2">
          {agent.traits.map((t) => <span key={t.key} title={t.descr}>{t.name}</span>)}
        </span>
      </div>],

      ['modifiers', <div className='agent-preview1-num row'>{agent.modifier.map(({ key }) => {
        const name = modifiers[key].name;
        const title = modifiers[key].desc;
        return <span key={key} title={title}>[{name}]</span>;
      })}</div>],
    ];

    const expData = exps.exps.find((e) => e.level === agent.level.cur);

    const centerViews = [
      ['level/exp', <div className='agent-preview1-num row'>
        {agent.level.cur} | {agent.level.exp.toFixed(1)}/{expData.exp}
      </div>],
      ['life', <div className='agent-preview1-num row'><AgentLife agent={agent} /></div>],
      ['overall', <div className='agent-preview1-num row'>
        <div>{power.toFixed(1)}</div>
        <div className="agent-power-extra" title={label}>{(amount >= 0 ? '+' : '') + amount.toFixed(1)}</div>
        <div>/{gcapData.power_cap}</div>
      </div>],
      ['physical', <div className='agent-preview1-num row'>
        <div>{physical.toFixed(1)}</div>
        <div className="agent-power-extra" title={label}>+0.0</div>
        <div>/{pscap.find(physicalcap).physical_cap}</div>
      </div>],
      ['perks', <div className='agent-preview1-num row'>{agent.perks.list.map((p) => {
        const data = perks2ByKey(p);
        return <span key={p} title={data.descr}>{data.name}, </span>;
      })}</div>],
    ];

    const rightViews = [
      ['missions', <div className='agent-preview1-num row'>Sucess {wins}<Sep /><div className='red'>Fail {loses}</div></div>],
      ['damage', <div className='agent-preview1-num row'>Do {damage_done_avg.toFixed(0)}<Sep /><div className='red'>Take {damage_taken_avg.toFixed(0)}</div></div>],
      ['contribution', <div className='agent-preview1-num row yellow'>{contribution < 0 ? '-' : ''}${Math.abs(contribution)}</div>],
    ];

    let termination = null;
    if (state === null && !readonly) {
      const { terminationCost, terminationLabel } = terminations(contract, turn);
      termination = <Button className='agent-termination-btn' title={terminationLabel} onClick={(ev) => {
        ev.stopPropagation();
        this.onAgentTerminate(agent, terminationCost);
        this.overlayPopMaybe('PREVIEW');
      }}>Cancel (${terminationCost})</Button>;
    }

    return <div>
      <div className='agent-preview1-top'>
        <div className="agent-preview1-col">
          <p>Profile</p>
          {traitView(leftViews)}

        </div>
        <div className="agent-preview1-col">
          <p>Combat & Equips</p>
          <AgentNatureHorizontalList agent={agent} turn={turn} />
          {traitView(centerViews)}
          <AgentItemGears agent={agent} onAgentEquip={this.onAgentEquip.bind(this)} onAgentDisarm={this.onAgentDisarm.bind(this)} inventories={inventories} readonly={readonly} />
        </div>
        <div className="agent-preview1-col">
          <p>Contract & Records</p>
          <div className="agent-preview1-box">
            <div className="row">
              <div className='agent-preview1-title'>Contract</div>
              {termination}
            </div>
            <br />
            <DashedListView data={contractDetails(agent.contract, turn)} />
          </div>
          {traitView(rightViews)}
        </div>
      </div>
    </div>;
  }

  renderAgentPreviewStat(agent) {
    const { game } = this.state;
    return (
      <div className="agent-preview1-top">
        <div className="agent-preview1-col">
          <AgentStatGuages agent={agent} slots={game.slots} tick={game.tick} global_effects={game.effects} />
        </div>
      </div>
    )
  }

  renderSlots(idx = null) {
    const { game } = this.state;
    const { turn: tick, agents, instructors, rng, slots } = game;

    return Object.entries(_.groupBy(slots, (s) => Math.floor(s.idx / 10)))
      .filter(([cid, _]) => (idx === null) ? true : +idx === +cid)
      .map(([cid, slots_group]) => {
        const name = game.world.storage[cid]?.centerstate?.name ?? `(Error ${cid})`;
        return <div className='fh1-flatwell' key={cid}>
          {idx === null ? <p className='flex-title'>{name}</p> : null}

          {slots_group.map((s, i) => (
            <TrainSlot key={`${idx}-${i}`} rng={rng} agents={agents} slots={slots} slot={s} tick={tick} instructors={instructors}
              onToggleAvail={this.onSlotAvail.bind(this)}
              onAgentGoTraining={this.onAgentGoTraining.bind(this)}
              onAgentCancelTrainingRecurrence={this.onAgentCancelTrainingRecurrence.bind(this)}
              onAgentCancelTraining={this.onAgentCancelTraining.bind(this)}
              onSetSlotAutomation={this.onSetSlotAutomation.bind(this)}
              onPause={() => this.setPaused(true)}
            />
          ))}
        </div>
      });
  }

  renderTrainingPreview() {
    const { game } = this.state;

    const summary = game.popup_msg;

    return <div className='mission-result'>
      <p className='flex-title'>훈련 보고서</p>
      <div className=''>
        {
          summary.length === 0 ?
            (<div>
              <h1>이번 분기에는 완료된 훈련이 없습니다.</h1>
            </div>) :
            <AgentsTrainingResult summary={summary} trainedOn />
        }

      </div>

      <Button className='new-btn' onClick={(ev) => {
        ev.stopPropagation();
        // TODO: 팝업 사라지게 하는 함수? 만들어야함
        game.state = null;
        this.setState({ game });
      }}>CONFIRM</Button>
    </div >;
  }

  renderItemDestroyReport() {
    const { game } = this.state;

    const msg = game.popup_msg;

    return <div className='mission-result'>
      <p className='flex-title'>아이템 망가짐</p>
      <div className=''>
        {msg.map(({ agent, ty, item }) => {
          switch (ty) {
            case 'firearm':
              return <div>{agent.name} - <FirearmLabel firearm={item} /></div>;
            case 'equipment':
              return <div>{agent.name} - <GearLabel equipment={item} /></div>;
            case 'throwable':
              return <div>{agent.name} - <ThrowableLabel throwable={item} /></div>;
            default:
              return null;
          }
        })}
      </div>

      <Button className='new-btn' onClick={(ev) => {
        ev.stopPropagation();
        // TODO: 팝업 사라지게 하는 함수? 만들어야함
        game.state = null;
        this.setState({ game });
      }}>CONFIRM</Button>
    </div >;
  }

  renderMonthlyReport() {
    const { game } = this.state;
    const { turn, cashbook } = game;

    const {
      summary_agents,
      summary_staffs,
      summary_facilities,
      summary_items,
      summary_recruits,
      summary_renown,
    } = game.popup_msg;
    const month = tickToMonth(turn) - 1;

    const styleSplit = { display: 'flex', width: '50%', flexDirection: 'column' };

    let objective = questObjective(game);

    return <div className='mission-result'>
      <p className='flex-title'>월간 보고서</p>
      {objective}

      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div className="box" style={styleSplit}>
          <p className='flex-title'>재무</p>
          <CashbookView turn={month} turn_start={month} cashbook={cashbook} />
        </div>

        <div className="box" style={styleSplit}>
          <p className='flex-title'>용병</p>
          <table>
            <thead>
              <tr>
                <th>이름</th>
                <th>임무 수행</th>
                <th>급여</th>
                <th>회복</th>
                <th>보상</th>
                <th>수익 기여</th>
              </tr>
            </thead>
            <tbody>
              {summary_agents.map(({ agent, mission_stats, mission_stats_last, recoverCost, turnCost, compensationCost }) => {
                return <tr key={agent.idx}>
                  <td>{agent.name}</td>
                  <td>{mission_stats.count - mission_stats_last.count}회</td>
                  <td><ValueSpan value={-turnCost} /></td>
                  <td><ValueSpan value={-recoverCost} /></td>
                  <td><ValueSpan value={-compensationCost} /></td>
                  <td><ValueSpan value={mission_stats.contributions - mission_stats_last.contributions} /></td>
                </tr>;
                /*
                return <div className="box" key={agent.idx}>
                  이름: {agent.name}<br/>
                  임무 수행: {mission_stats.count - mission_stats_last.count}회<br/>
                  비용: 월간 ${turnCost}, 회복 ${recoverCost}, 보상 ${compensationCost}
                </div>
                  */
              })}
            </tbody>

          </table>
        </div>
      </div>

      <p className='flex-title'>자원</p>
      <div className="box">
        <p>시간이 지남에 따라, 명성이 {summary_renown.delta}만큼 감소했습니다.</p>
        <p>{summary_recruits.length}명의 용병 후보를 탐색했습니다</p>
        {summary_recruits.map((item, i) => {
          return <MarketListingView key={i} item={item} game={game} />;
        })}
        <p>{summary_items.length}개의 신규 장비가 상점에 추가되었습니다</p>
        {summary_items.map((item, i) => {
          return <MarketListingView key={i} item={item} game={game} />;
        })}
      </div>

      <Button className='new-btn' onClick={(ev) => {
        ev.stopPropagation();
        // TODO: 팝업 사라지게 하는 함수? 만들어야함
        game.state = null;
        this.setState({ game });
      }}>CONFIRM</Button>
    </div >;
  }

  onAgentSelect(agent) {
    let { agents_selected } = this.state;
    if (agents_selected.find((a) => a === agent)) {
      agents_selected = agents_selected.filter((a) => a !== agent);
    } else {
      agents_selected.push(agent);
    }
    this.setState({ agents_selected });
  }

  onRenownUp() {
    const { game } = this.state;
    game.renownUp();
    this.setState({ game });
  }

  onResourceUp() {
    const { game } = this.state;
    game.resourceUp();
    this.setState({ game });
  }

  onReset() {
    this.setState(RoguelikeView.initialState(names3));
  }

  onResetNames2() {
    this.setState(RoguelikeView.initialState(names2));
  }

  onContractRecruit(ty, agent, option) {
    const { game, tabs_badge } = this.state;
    const { turn, global_modifier } = game;

    this.overlayPopMaybe('RECRUIT');
    if (ty === null) {
      return;
    }

    agent.contract = contractRenew(turn, agent, option, global_modifier);
    game.agentListingPurchase(agent);
    tabs_badge['MERCENARIES'].new_badge = true;
    this.setState({ tabs_badge });
  }

  renderState() {
    const { game, rng } = this.state;
    const { state } = game;

    const wrap = (body) => <div className="overlay-root"> <div className="overlay"> {body} </div> </div>;

    let playtip = ''
    if (rng) {
      playtip = rng.choice(data_playtip).message;
    }

    if (state === 'mission_start') {
      const { turn, resources, mission_state, pauseconfig } = game;

      return <MissionStartView turn={turn} resources={resources} mission_state={mission_state}
        onProceed={() => {
          game.state = 'mission';
          this.setState({ game });
        }}
        hide={!pauseconfig.mission_start}
        onToggleHide={() => {
          game.pauseconfig.mission_start = !game.pauseconfig.mission_start;
          this.setState({ game });
        }}
        global_effects={game.effects}
        playtip={playtip}
      />;
    } else if (state === 'training_preview') {
      return wrap(this.renderTrainingPreview());
    } else if (state === 'monthly_report') {
      return wrap(this.renderMonthlyReport());
    }
    else if (state === 'items_destory') {
      return wrap(this.renderItemDestroyReport());
    }

    if (['reward', 'event', 'result'].includes(state)) {
      return wrap(this.renderMissionResult());
    }
    return null;
  }

  /// training
  onAgentState(agent, state) {
    const { agents } = this.state;
    agent.state = state;

    this.setState({ agents });
  }

  onAgentHp(agent, life) {
    const { agents } = this.state;
    agent.life = life;
    this.setState({ agents });
  }

  onSlotAvail(slot, cat) {
    const { slots } = this.state;
    if (slot.avail.includes(cat)) {
      slot.avail = slot.avail.filter((c) => c !== cat);
    }
    else {
      slot.avail = [...slot.avail, cat];
    }
    this.setState({ slots });
  }

  onAgentPerkPoint(agent, point) {
    const { agents } = this.state;
    agent.perks.point = point;
    this.setState({ agents });
  }

  onAgentGoTraining(slot, agent, instructor, ty, option, recurr, forced = false) {
    const { game } = this.state;
    const { turn, slots } = game;

    // 이미 다른 곳에서 훈련 중
    const prevSlot = slots.find((slot) => slot.training?.agentIdx === agent.idx);
    if (prevSlot !== undefined) {
      if (forced) {
        slotCancel(prevSlot);
        game.pendingTraining = null;
      } else {
        const pendingTraining = { slot, agent, instructor, ty, option, recurr };
        this.setPaused(true);
        this.setState({ pendingTraining });
        return;
      }
    }

    const { training: next, reason } = trainStart(turn, slot.level, agent, instructor, ty, option, slot.effects);
    if (reason) {
      alert(`훈련을 시작할 수 없습니다. 이유: ${reason}`);
    }
    if (recurr) {
      // set recurr data
      next.recurr = true;
      next.recurrCancelled = false;
    }
    slot.training = next;

    game.triggerQuest('train', ty);

    this.setState({ game });
  }

  onAgentCancelTraining(slot) {
    const { game } = this.state;
    game.onAgentCancelTraining(slot);
    this.setState({ game });
  }

  onSetSlotAutomation(slot, instructor, isEnable, option) {
    const { game } = this.state;
    const { turn, agents, instructors, slots } = game;
    slotAutomationUpdate(slot, turn, agents, instructor, slots, instructors, isEnable, option);
    this.setState({ game });
  }

  onAgentCancelTrainingRecurrence(slot) {
    const { game } = this.state;
    slot.training.recurrCancelled = true;
    this.setState({ game });
  }

  renderMissionWarning(props) {
    const { game } = this.state;
    let btn2cls = "mission-warning-overlay-btn2";
    if (!game.pauseconfig.mission_warning) {
      btn2cls += " mission-warning-overlay-btn2-selected";
    }

    return <div className="overlay-root overlay-root-shrink">
      <div className="overlay-flex">
        <p className="mission-warning-overlay-title">{L('loc_ui_title_notification_play_tip')}</p>
        <p className="mission-warning-overlay-body-important">{L('loc_ui_longtext_notification_play_tip_high_difficulty')}</p>
        <div className="overlay-flex-btngroup">
          <Button className="mission-warning-overlay-btn" onClick={() => {
            this.onMissionQueue(props, true);
            this.overlayPopMaybe('MISSION_WARNING');
          }}>{L('loc_ui_button_common_proceed_anyway')}</Button>
          <Button className="mission-warning-overlay-btn" onClick={() => {
            this.overlayPopMaybe('MISSION_WARNING');
          }}>{L('loc_ui_button_common_reconsider')}</Button>
        </div>
        <Button className={btn2cls} onClick={() => {
          game.pauseconfig.mission_warning = !game.pauseconfig.mission_warning;
          this.setState({ game });
        }}>{L('loc_ui_button_notification_do_not_show_again')}</Button>
      </div>
    </div>;
  }

  renderAgentEquip(overlay) {
    const { game } = this.state;
    const { inventories } = game;
    const { agent, equip_ty, equip_idx } = overlay;

    let equip_cur = null;
    let equip_list = null;
    if (equip_ty === 'firearm') {
      equip_cur = <FirearmLabel firearm={agent.firearm} />;
      equip_list = _.uniqBy(inventories.filter((e) => e.ty === 'firearm'), (e) => e.firearm.firearm_name + (e.firearm.options ?? []))
        .map((e, i) => {
          return <div key={i} className="box item-equip-candidate">
            <FirearmLabel firearm={e.firearm} /> <ButtonInline className="agent-equip-button" onClick={() => {
              this.onAgentEquip(agent, e);
              this.overlayPopMaybe('EQUIP');
            }}>{L('loc_ui_button_common_select')}</ButtonInline>
          </div>;
        });
    } else if (equip_ty === 'equipment') {
      equip_cur = <GearLabel equipment={agent.equipment} />;
      equip_list = _.uniqBy(inventories.filter((e) => e.ty === 'equipment'
        && e.equipment.vest_rate * 4 <= agent.stats2.bravery
        && (e.equipment.vest_rate !== agent.equipment.vest_rate || ((e.equipment.options ?? []).join('') !== (agent.equipment.options ?? []).join(''))))
        , (e) => e.equipment.vest_name + (e.equipment.options ?? []))
        .map((e, i) => {
          return <div key={i} className="box item-equip-candidate">
            <GearLabel equipment={e.equipment} /> <ButtonInline className="agent-equip-button" onClick={() => {
              this.onAgentEquip(agent, e);
              this.overlayPopMaybe('EQUIP');
            }}>{L('loc_ui_button_common_select')}</ButtonInline>
          </div>;
        });
    } else if (equip_ty === 'throwable') {
      equip_cur = <ThrowableLabel throwable={agent.throwables[equip_idx]} />;
      equip_list = _.uniqBy(inventories.filter((e) => e.ty === 'throwable'), (e) => e.throwable.throwable_name + (e.throwable.options ?? []))
        .map((e, i) => {
          return <div key={i} className="box item-equip-candidate">
            <ThrowableLabel throwable={e.throwable} /> <ButtonInline className="agent-equip-button" onClick={() => {
              this.onAgentEquip(agent, e, equip_idx);
              this.overlayPopMaybe('EQUIP');
            }}>{L('loc_ui_button_common_select')}</ButtonInline>
          </div>;
        });
    }

    let label = equip_list.length === 0 ? L('loc_ui_string_popup_agent_equipment_no_alternatives')
      : L('loc_ui_string_popup_agent_equipment_alternative_exists');

    return <div className="overlay-root overlay-root-shrink">
      <div className="overlay-flex">
        <p className="mission-warning-overlay-title">{L('loc_dynamic_title_popup_agent_equipable_change_' + equip_ty, { name: agent.name })}</p>

        <p className="mission-warning-overlay-body-important">{L('loc_ui_string_popup_agent_equipment_current')}</p>
        <div className="box item-equip-candidate">
          {equip_cur}
        </div>

        <p className="mission-warning-overlay-body-important">{label}</p>
        {equip_list}

        <div className="overlay-flex-btngroup">
          <Button className="mission-warning-overlay-btn" onClick={() => {
            this.overlayPopMaybe('EQUIP');
          }}>{L('loc_ui_button_popup_common_close')}</Button>
        </div>
      </div>
    </div>;
  }

  renderItemEquip(overlay) {
    const { game } = this.state;
    const { agents } = game;
    const { item } = overlay;

    let equip_cur = null;
    let equip_list = null;

    const getCompareText = (diff) => {
      if (diff > 0) {
        return <>(<font className='item-arrow-higher'>▲</font>)</>;
      }
      else if (diff < 0) {
        return <>(<font className='item-arrow-lower'>▼</font>)</>
      }
      else {
        return <>(-)</>;
      }
    }

    if (item.ty === 'firearm') {
      const { firearm } = item;
      equip_cur = <FirearmLabel firearm={firearm} />;
      equip_list = agents.map((a, i) => {
        return <div key={i} className="box agent-equip-candidate">
          {a.name}
          (<FirearmLabel firearm={a.firearm} /> 장착중)
          {getCompareText(firearm.firearm_rate - a.firearm.firearm_rate)}
          <Button className="agent-equip-button" onClick={() => {
            this.onAgentEquip(a, item);
            this.overlayPopMaybe('ITEM_EQUIP');
          }}>{L('loc_ui_button_common_select')}</Button>
        </div>;
      });
    }
    else if (item.ty === 'equipment') {
      const { equipment } = item;
      equip_cur = <GearLabel equipment={equipment} />;
      equip_list = agents.filter((a) => equipment.vest_rate * 4 <= a.stats2.bravery)
        .map((a, i) => {
          return <div key={i} className="box agent-equip-candidate">
            {a.name}
            (<GearLabel equipment={a.equipment} /> 장착중)
            {getCompareText(equipment.vest_rate - a.equipment.vest_rate)}
            <Button className="agent-equip-button" onClick={() => {
              this.onAgentEquip(a, item);
              this.overlayPopMaybe('ITEM_EQUIP');
            }}>{L('loc_ui_button_common_select')}</Button>
          </div>;
        });
    }
    // else if (item.ty === 'throwable') {
    //   const { throwable } = item;
    //   equip_cur = <ThrowableLabel throwable={throwable} />;
    //   equip_list = agents.filter((a) => agentHasThrowableSlot1(a))
    //     .map((a, i) => {
    //       return <div key={i} className="box">
    //         {a.name}
    //         <ThrowableLabel throwable={a.throwables[0]} />
    //         <Button className="agent-equip-button" onClick={() => {
    //           this.onAgentEquip(a, item, 0);
    //           this.overlayPopMaybe('ITEM_EQUIP');
    //         }}>{L('loc_ui_button_common_select')}</Button>
    //       </div>;
    //     });
    // }

    let label = equip_list.length === 0 ? L('loc_ui_string_warehouse_no_alternatives') : L('loc_ui_string_warehouse_select_agent_to_equip');

    return <div className="overlay-root overlay-root-shrink">
      <div className="overlay-flex">
        <p className="mission-warning-overlay-title">{L('loc_ui_string_warehouse_select_equipment_agent')}</p>

        <p className="mission-warning-overlay-body-important">{L('loc_ui_string_warehouse_select_equipment')}</p>
        <div className="box agent-equip-candidate">
          {equip_cur}
        </div>

        <p className="mission-warning-overlay-body-important">{label}</p>
        {equip_list}

        <div className="overlay-flex-btngroup">
          <Button className="mission-warning-overlay-btn" onClick={() => {
            this.overlayPopMaybe('ITEM_EQUIP');
          }}>{L('loc_ui_button_popup_common_close')}</Button>
        </div>
      </div>
    </div>;
  }

  renderDepartment(overlay) {
    const { game } = this.state;
    const { pauseconfig } = game;

    const optkey = `department_${overlay.event_ty}`;
    const pause = pauseconfig[optkey] ?? true;

    let btn2cls = "mission-warning-overlay-btn2";
    if (!pause) {
      btn2cls += " mission-warning-overlay-btn2-selected";
    }

    return <div className="overlay-root overlay-root-shrink">
      <div className="overlay-flex">
        <p className="mission-warning-overlay-title">{L('loc_ui_title_notification_task_complete')}</p>
        <DepartmentResultView {...overlay} />
        <div className="overlay-flex-btngroup">
          <Button className="mission-warning-overlay-btn" onClick={() => {
            this.overlayPopMaybe('DEPARTMENT');
            game.popDepartmentOverlay(overlay);
          }}>{L('loc_ui_button_common_confirm')}</Button>
        </div>
        <Button className={btn2cls} onClick={() => {
          pauseconfig[optkey] = !pause;
          this.setState({ game });
        }}>{L('loc_ui_button_notification_do_not_show_again')}</Button>
      </div>
    </div>;
  }

  renderJournalOverlay(overlay) {
    const { game } = this.state;
    const { pauseconfig } = game;
    const { journal } = overlay;
    const { ty, msg } = journal;

    const pause = pauseconfig[ty] ?? true;

    let btn2cls = "mission-warning-overlay-btn2";
    if (!pause) {
      btn2cls += " mission-warning-overlay-btn2-selected";
    }

    return <div className="overlay-root overlay-root-shrink">
      <div className="overlay-flex">
        <p className="mission-warning-overlay-title">{L('loc_ui_title_popup_pause_' + ty)}</p>
        <p className="mission-warning-overlay-subtitle">{msg}</p>
        <div className="overlay-flex-btngroup">
          <Button className="mission-warning-overlay-btn" onClick={() => {
            this.overlayPopMaybe('JOURNAL');
          }}>{L('loc_ui_button_common_confirm')}</Button>
        </div>
        <CheckBox checked={!pause} onChange={() => {
          pauseconfig[ty] = !pause;
          this.setState({ game });
        }}>
          {L('loc_ui_button_notification_do_not_show_again')}
        </CheckBox>
      </div>
    </div>;
  }

  renderOverlay() {
    const { overlays, game } = this.state;

    const gameover = game.gameover();
    if (gameover) {
      return <div className="overlay-root overlay-root-shrink">
        <div className="overlay-flex">
          <p className="mission-warning-overlay-title">게임오버</p>
          <p className="mission-warning-overlay-body-important">{gameover}</p>
          <br />
          <Button className="mission-warning-overlay-btn2" onClick={() => {
            this.onReset();
          }}>다시 하기</Button>
        </div>
      </div>;
    }

    let body = this.renderState();
    if (body) {
      return body;
    }

    if (overlays.length === 0) {
      return null;
    }

    let overlay = overlays[overlays.length - 1];

    let content = null;
    if (overlay.ty === 'PREVIEW') {
      content = this.renderAgentPreview(overlay.agent, overlay);
    } else if (overlay.ty === 'RECRUIT') {
      content = this.renderAgentPreviewForHire(overlay);
    } else if (overlay.ty === 'MISSION_WARNING') {
      content = this.renderMissionWarning(overlay.props);
    } else if (overlay.ty === 'RENEWAL') {
      content = this.renderAgentRenewalV2();
    } else if (overlay.ty === 'EQUIP') {
      content = this.renderAgentEquip(overlay);
    } else if (overlay.ty === 'ITEM_EQUIP') {
      content = this.renderItemEquip(overlay);
    } else if (overlay.ty === 'DEPARTMENT') {
      content = this.renderDepartment(overlay);
    } else if (overlay.ty === 'JOURNAL') {
      content = this.renderJournalOverlay(overlay);
    } else if (overlay.ty === 'QUEST') {
      content = this.renderOverlayQuest(overlay.quest);
    } else {
      throw new Error(`unknown overlay type: ${overlay.ty}`);
    }

    return <div className="overlay-root">
      {content}
    </div>;
  }

  renderPlan(showMission) {
    const { game } = this.state;
    const {
      turn,
      seed,
      global_modifier,
      mission_selected,
      areas,
      inventories,
    } = game;


    return <PlanView seed={seed}
      game={game}
      turn={turn} mission={mission_selected} agents={game.curAgents()}
      onMissionQueue={this.onMissionQueue.bind(this)}
      onBaseSelect={this.onBaseSelect.bind(this)}
      onAgentDetail={this.onAgentPreview.bind(this)}
      onReset={this.onResetMissionConfig.bind(this)}
      onAgentSelect={this.onAgentSelect.bind(this)}
      onAgentEquipAvail={this.onAgentEquipAvail.bind(this)}
      onAgentEquipPopup={this.onAgentEquipPopup.bind(this)}
      onAgentDisarm={this.onAgentDisarm.bind(this)}
      global_modifier={global_modifier}
      areas={areas}
      agents_selected={this.state.agents_selected}
      showMission={showMission}
    />;
  }

  renderFacilityBase(idx) {
    const { game } = this.state;
    const { world } = game;
    if (!world.storage[idx].centerstate.office) {
      return null;
    }

    let agents_relocate = game.agents.filter((a) => {
      if (a.world_idx === idx) {
        return false;
      }
      if (a.state) {
        return false;
      }
      return true;
    });

    let agents_cur = game.agents.filter((a) => {
      return a.world_idx === idx;
    });
    const agents_limit = game.baseAgentsCount(idx);

    const renderRelocateButton = (a) => {
      if (agents_cur.length >= agents_limit) {
        return <Button disabled>배치 수 제한 초과</Button>;
      }

      return <Button onClick={() => {
        //고용된 용병을 처음 배치할 경우에는 재배치 시간 없음
        if (a.world_idx) {
          a.relocate_at = game.turn + TICK_PER_WEEK;
          a.state = 'relocate';
        }
        a.world_idx = idx;
        game.triggerQuest('relocate_agent');
        this.setState({ game });
      }}>재배치</Button>;
    };

    const renderAgent = (a) => {
      let state = null;
      if (a.state === 'relocate') {
        let remain_days = Math.ceil((a.relocate_at - game.turn) / TICK_PER_DAY);
        state = <span> (재배치중: {remain_days}일 후 완료)</span>;
      } else if (a.state) {
        state = <span> ({a.state})</span>;
      }
      return <span>{L(firearm_ty_name[a.firearm.firearm_ty])} {L('loc_dynamic_string_common_item_tier')}{a.firearm.firearm_rate} | <NameView agent={a} onAgentDetail={this.onAgentPreview.bind(this)} additional={null} /> {state}</span>;
    };

    return <div className="box">
      <p>용병 목록: 현재 거점 {agents_cur.length}/{agents_limit}</p>
      {agents_cur.map((a) => {
        return <div className="box" key={a.idx}>{renderAgent(a)}</div>;
      })}
      {agents_relocate.length > 0 ? <p>용병 목록: 재배치 가능</p> : null}
      {agents_relocate.map((a) => {
        let pos = '배치 예정';
        if (a.world_idx) {
          pos = world.storage[a.world_idx].centerstate.name;
        }
        return <div className="box" key={a.idx}>
          {renderAgent(a)} [{pos}] {renderRelocateButton(a)}
        </div>;
      })}
    </div>;
  }

  renderFacilityForces(idx) {
    const { game } = this.state;
    const { world } = game;

    const { centerstate } = world.storage[idx];

    if (!centerstate.office) {
      return null;
    }
    else {
      const center = world.centers.find((c) => c.idx === idx);

      const { renown } = centerstate;
      const step = renownStep(renown);

      const base = step.cur.renown;
      const max = step.next.renown;
      const title = `${renown}/${max}`;
      const full = max === renown;

      return <div key="forces" className="box">
        <p>{L('loc_dynamic_string_regional_renown_total', { value: renown })}</p>
        <p>{L('loc_dynamic_string_regional_influence_level', { value: centerstate.unlocks, total: center.subdivisions.length })}</p>
        {(!full) ? <>{L('loc_ui_string_regional_renown_next_influence')}
          <ProgressBar title={title} cur={renown - base} max={max - base} width={100} />
        </> : null}
      </div>;
    }
  }

  onRender() {
    const { game } = this.state;
    const { world } = game;

    const canvas = this.canvasRef.current;
    if (!canvas) {
      return;
    }

    const elems = document.getElementsByClassName('flexoverlay-item');
    for (const elem of elems) {
      const idx = elem.getAttribute('idx');
      const p = world.qridx(idx);
      const { x, y } = canvas.screenPos(p);
      elem.style = `top: ${y}px; left: ${x}px;`;
    }
  }

  renderWorldOverlay() {
    const { overlayoptions, worldcursor } = this.state;

    const { game } = this.state;
    const { tokens, world, resources, slots } = game;
    const canvas = this.canvasRef.current;
    if (!canvas) {
      return;
    }

    const renderWorldAgentIcon = (function(agent) {
      const { name } = agent;

      const fatigueData = getFatigueData(agent.stamina);

      const stamina_recover_mult = game.effects.stamina_recover_mult ?? 1;
      const stamina_regen_per_day = agent.stamina_regen_per_day * stamina_recover_mult;

      const props = {
        ptAgentHpCap: `/${agent.life_max}`,
        ptAgentHpVal: `${agent.life.toFixed(0)}`,
        ptAgentStaminaCap: `/${agent.stamina_max.toFixed(1)}`,
        ptAgentStaminaVal: `${L(fatigueData.name)}(${agent.stamina.toFixed(1)})`,
        ptAgentStaminaRegen: `${(stamina_regen_per_day * 28).toFixed(1)}`,
        ptAgentStaminaIdx: `${fatigueData.idx}`,
        ptAgentName: `${name}`,
        ptFirearmTy: `${agent.firearm.firearm_ty}`,
        ptPerkEnable: agent.perks.point > 0 ? '▲' : '',
        ptTraining: slots.find(s => s.training?.agentIdx === agent.idx) ? 'Training' : '',
        override: <PortraitWrapper className="flexoverlay-agenticon-portrait" agent={agent} preset={PORTRAIT_PRESET_ZOOM}
          onClick={(ev) => {
            ev.preventDefault();
            ev.stopPropagation();
            this.onAgentPreview(agent);
          }} />,
      };
      return <CipoCell key={agent.idx} className="CIPO-cell-instance" {...props} />;
    }).bind(this);

    const items = [];
    function addItem(p, item, cls) {
      const idx = world.idxqr(p);
      let className = "flexoverlay-item";
      if (cls) {
        className += ` ${cls}`;
      }

      items.push(<div key={idx} className={className} idx={idx}>
        {item}
      </div>);
    }

    const { market_listings, recruit_listings, effects: global_effects } = game;
    for (const { idx, subdivisions } of world.centers) {
      const { centerstate } = world.storage[idx];
      const subitems = [];

      let renderbase = false;
      let cls = null;
      if (this.state.tab_selected === 'BASE' && game.selected_world_idx === idx) {
        renderbase = true;
      }
      if (worldcursor && idx === world.idxqr(worldcursor)) {
        renderbase = true;
        cls = 'flexoverlay-item-readonly';
      }
      if (centerstate.office && overlayoptions.base) {
        renderbase = true;
        cls = 'flexoverlay-item-office';
      }

      // 지역 명성
      if (centerstate.office) {
        const { renown } = centerstate;
        const step = renownStep(renown);

        const base = step.cur.renown;
        const max = step.next.renown;
        const title = `${renown}/${max}`;
        const full = max === renown;

        subitems.push(<div key="forces" className="flexoverlay-forces">
          <div className="label">{L('loc_ui_string_regional_renown_total')}</div>
          <div className="content value important">{renown}</div>
          <div className="label">{L('loc_ui_string_regional_influence_level')}</div>
          <div className="content"><span className="value">{centerstate.unlocks}</span>/{subdivisions.length}</div>
          {(!full) ? <>
            <div className="label">{L('loc_ui_string_regional_renown_next_influence')}</div>
            <ProgressBar title={title} classname="content overlay-progress" cur={renown - base} max={max - base} width='95%' />
          </> : null}
          {DEBUG ? <button onClick={() => {
            centerstate.renown += 100;
            // centerstate.unlocks = unlocksByRenown(centerstate.renown);
            this.setState({ game });
          }}>DEBUG: 명성 획득</button> : null}
        </div>);
      }

      // 세력
      if (overlayoptions.center) {
        subitems.push(this.renderFacilityForces(idx));
      }

      if (renderbase) {
        const icons = game.agents.filter(({ state, world_idx }) => state !== 'mission' && world_idx === idx).map(renderWorldAgentIcon);
        if (icons.length > 0) {
          subitems.push(<div className="flexoverlay-agent" key="agents-root">
            {L('loc_ui_string_worldmap_base_agents')}
            <div className="flexoverlay-agenticon-root" key="agents">
              {icons}
            </div>
          </div>);
        }

        const { effects, facilities } = centerstate;

        if (world.centerEnabled(idx)) {
          const mode = world.centerMode(idx);
          let upgrade_avail = false;
          const subitem_facilities = [];
          for (let i = 0; i < facilities.length; i++) {
            const f = facilities[i];
            const cur = data_facilities.facilities.find(({ key }) => f.key === key);
            let nexts = facilityUpgrades({ cur, facilities, effects, global_effects, resources, tokens, mode });
            if (nexts.find(({ avail }) => avail)) {
              upgrade_avail = true;
            }

            if (f.state === 'upgrading') {
              subitem_facilities.push(<WorldFacilityUpgradeView facility={f} turn={game.turn} effects={effects} global_effects={global_effects} key={`upgrade-${i}`} flexoverlay={true} />);
            }
          }

          if (upgrade_avail) {
            subitem_facilities.push(<div className="flexoverlay-upgradable-desc" key="upgrade" onClick={() => {
              const p = world.qridx(idx);
              this.setState({ tab_selected: 'REGION', worldselect: p });
            }}>
              {L('loc_ui_longtext_region_facility_exist_upgradable')}
            </div>);
          }

          if (subitem_facilities.length > 0) {
            subitems.push(<div className="flexoverlay-facility">
              {subitem_facilities}
            </div>);
          }
        }

        const subitem_markets = [];

        for (const item of market_listings.filter(({ idx: idx0 }) => idx0 === idx)) {
          subitem_markets.push(<MarketListingView key={subitems.length} item={item} game={game}
            onMarketListingPurchase={this.onMarketListingPurchase.bind(this)}
          />)
        }

        if (subitem_markets.length > 0) {
          subitems.push(<div className="flexoverlay-market-desc">{L('loc_ui_string_market_item_list')}</div>);
          subitems.push(<div className="flexoverlay-market">
            {subitem_markets}
          </div>);
        }

        const subitem_recruits = [];

        for (const { agent } of recruit_listings.filter(({ idx: idx0 }) => idx0 === idx)) {
          subitem_recruits.push(<div className="flexoverlay-recruit">
            <span className="name">{agent.name}</span>
            <Button onClick={() => {
              this.overlayPush({ ty: 'PREVIEW', agent });
            }}>{L('loc_ui_common_detail')}</Button>
            <span className="power desc">{L('loc_ui_string_agent_stat_overall')}</span>
            <span className="power value">{agent.power.toFixed(1)}</span>
            <AgentPotential agent={agent} />
            <AgentGrowthCap agent={agent} />
            <AgentPhysicalCap agent={agent} />
          </div>);
        }

        if (subitem_recruits.length > 0) {
          subitems.push(<div className="flexoverlay-recruit-desc">{L('loc_ui_string_recruit_list')}</div>);
          subitems.push(<div className="flexoverlay-listcontainer">
            {subitem_recruits}
          </div>);
        }

        const { departmentRoot } = game;
        const subitem_works = [];
        for (const department of departmentRoot.departments.filter(({ fid }) => Math.floor(fid / 10) === idx)) {
          for (const pending of department.pendings) {
            subitem_works.push(<WorldDepartmentWorkView department={department} pending={pending} />)
          }
        }
        if (subitem_works.length > 0) {
          subitems.push(<div className="flexoverlay-work-desc">{L('loc_ui_string_organization_work_in_progress')}</div>);
          subitems.push(<>
            {subitem_works}
          </>);
        }
      }

      if (subitems.length > 0) {
        addItem(world.qridx(idx), <>
          <div className='title'>{L('loc_ui_string_worldmap_cursor_base') + centerstate.name}</div>
          {subitems}
        </>, cls);
      }
    }

    // subdivision unlocks
    for (const { idx, subdivisions } of world.centers) {
      const { centerstate } = world.storage[idx];
      const { renown, unlocks } = centerstate;
      const unlocks_max = unlocksByRenown(renown);
      for (let i = 1; i < subdivisions.length; i++) {
        if (i < unlocks || i >= unlocks_max) {
          continue;
        }
        const { idx: subidx } = subdivisions[i];

        addItem(world.qridx(subidx), <>
          <Button className="flexoverlay-btn-expand" onClick={() => {
            centerstate.unlocks += 1;
            game.syncWorldmap();
            this.setState({ game, world });
          }}>영역 확장</Button>
        </>, 'flexoverlay-top');
        break;
      }
    }

    const { turn, pendings } = game;
    for (const mission of game.missions) {
      const { p } = mission;
      if (!(qrEq(mission.p, worldcursor) || mission === game.mission_selected)) {
        continue;
      }
      if (!game.missionAvailable(mission)) {
        continue;
      }

      addItem(p, <MissionItem key={mission.seed} turn={turn} mission={mission} mission_selected={null} global_effects={game.effects}
        onMissionSelect={() => {
          this.onMissionSelect(mission, true);
          this.setState({ tab_selected: 'CONTRACT' });
        }} readonly={true} />, 'flexoverlay-item-readonly'
      );
    }

    for (const { mission_state: { mission, agents }, turn: turn_mission } of pendings.filter(({ ty }) => ty === 'mission')) {
      if (!overlayoptions.mission_ongoing) {
        continue;
      }
      const { p } = mission;
      if (!p) {
        continue;
      }

      const remain = dayjs.duration(turn_mission - turn, 'hour').humanize();
      const icons = agents.map(renderWorldAgentIcon);
      addItem(p, <>
        <MissionItem key={mission.seed} turn={turn} mission={mission} mission_selected={null} global_effects={game.effects}
          readonly={true} reason={`in progress: ${remain}`} />
        <div className="flexoverlay-agenticon-root" key="agents">
          {icons}
        </div>
      </>, 'flexoverlay-item-nostyle');
    }

    return <div className="flexoverlay">
      {items}
    </div>;
  }

  renderWorldOverlayOptions() {
    const { overlayoptions } = this.state;
    const names = {
      base: '거점 표시',
      center: '세력 표시',
      mission: '임무 표시',
      mission_ongoing: '진행중인 임무 표시',
    };

    const renderOption = (key) => {
      const on = overlayoptions[key];
      const name = names[key] ?? key;
      const label = `${name}: ${on ? 'ON' : 'OFF'}`;
      return <Button key={key} onClick={() => {
        this.setState({
          overlayoptions: {
            ...overlayoptions,
            [key]: !on,
          },
        });
      }}>{label}</Button>
    };

    return <div className="flexmapoptions">
      {Object.keys(names).map((key) => renderOption(key))}
    </div>;
  }

  tabStatus(tab) {
    const { game } = this.state;
    const buildret = (l) => { return { avail: l.length > 0, count: l.length }; };
    if (tab === 'CONTRACTS') {
      return {
        avail: true,
        count: game.missions.filter((m) => game.missionAvailable(m)).length,
      };
    } else if (tab === 'RECRUIT') {
      return buildret(game.recruit_listings);
    } else if (tab === 'MARKET') {
      return buildret(game.market_listings);
    } else if (tab === 'TRAINING') {
      return {
        avail: game.slots.length > 0,
        count: game.slots.filter((s) => s.training === null).length,
      };
    } else if (tab === 'WAREHOUSE') {
      return buildret(game.inventories.filter((i) => i.sell_cost > 0));
    }
    else if (tab === 'COMPANY') {
      const { departmentRoot } = game;
      return {
        avail: departmentRoot?.departments.length > 0,
        count: departmentRoot?.recruit_listings.length,
      }
    }
    return { avail: true, count: 0 };
  }

  questClaim(quest) {
    const { game } = this.state;
    game.claimQuestReward(quest.key);
    this.setState(game);
  }

  renderQuests() {
    const { game } = this.state;
    const { turn, questTracker } = game;
    const { onProgress } = questTracker;

    const needClaim = onProgress.filter((quest) => questClaimable(quest));
    const remains = onProgress.filter((quest) => !questClaimable(quest));

    return <WellSegment title={L('loc_ui_title_drawer_quest')}>
      <div className="fh1-content-inner">
        {needClaim.map((quest, i) => <QuestElement key={i} tick={turn} quest={quest} onClaim={() => this.questClaim(quest)} />)}
        {remains.map((quest, i) => <QuestElement key={i} tick={turn} quest={quest} />)}
      </div>
    </WellSegment>;

    /*
    return <div className="flexright-missions">
      <div className='flexright-title'>
        <div className='flexright-title-left'> 퀘스트</div>
      </div>
    </div >;
    */
  }

  renderOverlayQuest(quest) {
    const { turn } = this.state.game;
    const claimable = questClaimable(quest);

    const rewardString = questRewardString(quest);
    const conds = quest.objectives.map((o, i) => ({ done: o.completed, label: L(quest.instruction).replace(/\r/g, '').split('\n')[i] }));

    let header = (quest.type === 'milestone') ? L('loc_ui_title_modal_quest_new') : L('loc_ui_title_modal_tutorial_new');
    let title = L(quest.title);
    let btn = null;
    if (rewardString) {
      L('loc_dynamic_string_quest_reward', { rewardString });
    }

    let expire = null;
    if (claimable) {
      header = (quest.type === 'milestone') ? L('loc_ui_title_modal_quest_finish') : L('loc_ui_title_modal_tutorial_finish');

      const onClick = () => {
        this.questClaim(quest);
        this.overlayPopMaybe('QUEST');
      };
      if (rewardString) {
        btn = <Button onClick={onClick}>{L('loc_dynamic_button_quest_claim_reward', { rewardString })}</Button>;
      } else {
        btn = <Button onClick={onClick}>완료</Button>;
      }
    } else {
      btn = <Button onClick={() => this.overlayPopMaybe('QUEST')}>{L('loc_ui_button_popup_common_close')}</Button>;
      expire = questExpire(turn, quest);
    }

    return <div className="overlay-root overlay-root-shrink">
      <div className={"overlay-flex-quest" + (quest.type === 'milestone' ? ' special' : '')}>
        <div className="overlay-quest-header">{header}</div>
        <div className="mission-warning-overlay-title">{title}</div>
        {(quest.type === 'milestone' ? expire : <hr />)}
        <div className='overlay-quest-body'>{L(quest.description)}</div>
        <Checklist conditions={conds} />
        {btn}
      </div>
    </div>;
  }

  render() {
    const { simtps, view, game, cutscene, play_bgm, tooltip } = this.state;
    const {
      turn,
      resources,
      missions,
      mission_selected,
      market_listings,
      inventories,
      recruit_listings,
      pendings,
      state,
      mission_state,
      world,
    } = game;

    if (cutscene >= 0) {
      return <CutSceneView idx={cutscene} onDone={() => {
        this.serializeState(0);
        this.setState({ cutscene: -1 });
      }} />;
    }

    if (state === 'mission') {
      const { seed: missionSeed } = mission_state.mission;
      const nocontrol = mission_state.agents.find((a) => a.modifier.find(({ key }) => key === 'mod_agent_5_disable_prompt'));

      if (window.gameface) {
        const dummyFinish = (res) => {
          // TODO: 버그많음
          const { game } = this.state;
          game.state = (res === 1) ? 'reward' : 'result';
          game.mission_state.reward = [];
          game.mission_state.reward_choices = [];
          this.setState({ game });
        };
        return (<div className='flex-simview'>
          <h1 onClick={() => dummyFinish(0)}>WIN</h1>
          <h1>/</h1>
          <h1 onClick={() => dummyFinish(1)}>LOSE</h1>
        </div>);
      }
      if (window.ue) {
        return <div className="flex-simview">
          <SimView ref={this.simRef}
            m={this.props.m}
            seed={missionSeed}
            debug={false}
            nocontrol={nocontrol}
            noobs={true}
            onFinish={(res) => this.onFinish(res)}
            viewWidth={512}
            viewHeight={512}
            autoscroll={true}
            embed={true}
            {...mission_state.simstate}
          />
        </div>
      }

      return <div className="flex-simview flex-simview-canvas">
        <SimView ref={this.simRef}
          m={this.props.m}
          seed={missionSeed}
          debug={false}
          nocontrol={nocontrol}
          onFinish={(res) => this.onFinish(res)}
          autoscroll={true}
          embed={false}
          {...mission_state.simstate}
        />
      </div>;
    }


    const can_progress = game.canProgress();

    const { tab_selected } = this.state;
    let body = null;

    if (tab_selected === 'REGIONS') {
      body = this.renderAreas();
    } else if (tab_selected === 'BASE') {
      body = this.renderBase();
    } else if (tab_selected === 'REGION') {
      body = this.renderArea();
    } else if (tab_selected === 'CONTRACT') {
      // 월드맵에서 임무만 눌렀을 때
      body = <Window
        title='의뢰'
        showBackButton={false}
        showCloseButton={true}
        onClickButtonClose={this.onKeyEscape.bind(this)}
      >
        {this.renderPlan(true)}
      </Window>;
    } else if (tab_selected === 'CONTRACTS') {
      let milestone_missions = game.milestone_missions;
      if (pendings.find((p) => p.ty === 'mission' && p.mission_state.mission.milestone)) {
        milestone_missions = [];
      }

      const missions0 = missions.filter((m) => game.missionAvailable(m));

      body = <>
        <Missions missions={missions0} mission_selected={mission_selected}
          milestone_missions={milestone_missions}
          onMissionSelect={this.onMissionSelect.bind(this)}
          turn={turn} can_progress={can_progress} resources={resources} global_effects={game.effects}
          onClickClose={this.onKeyEscape.bind(this)}
          renderedPlan={mission_selected ? this.renderPlan(false) : null}
        />
      </>;
    } else if (tab_selected === 'TRAINING') {
      // TODO
      const tabs = game.baseList().map(({ idx }) => {
        const { centerstate } = game.world.storage[idx];
        return { idx, key: idx, label: centerstate.name };
      });
      body = <Window
        title={L('loc_ui_title_menu_training')}
        showBackButton={false}
        showCloseButton={true}
        onClickButtonClose={this.onKeyEscape.bind(this)}
      >
        <Panel style={{ width: 960 }}>
          {this.renderSlots()}
        </Panel>
      </Window>;
    } else if (tab_selected === 'RECRUIT') {
      body = <>
        <AgentsRecruit
          agents={recruit_listings.map((r) => r.agent)}
          game={game}
          onContractRecruit={() => { }}
          onDone={() => { this.setState({ game }) }}
          onClose={this.onKeyEscape.bind(this)}
        />
      </>;
    } else if (tab_selected === 'MARKET') {
      body = <Market
        world={world}
        game={game}
        market_listings={market_listings}
        onMarketListingPurchase={this.onMarketListingPurchase.bind(this)}
        onClickClose={this.onKeyEscape.bind(this)}
      />
    } else if (tab_selected === 'WAREHOUSE') {
      body = <Inventory
        world={world}
        game={game}
        inventories={inventories}
        onInventorySell={this.onInventorySell.bind(this)}
        onItemEquip={this.onItemEquipPopup.bind(this)}
        onClickClose={this.onKeyEscape.bind(this)}
        readonly={!state ? false : true}
      />
    } else if (tab_selected === 'MERCENARIES') {
      body = this.renderAgents();
    } else if (tab_selected === 'RESEARCH') {
      body = this.renderResearch();
    } else if (tab_selected === 'JOURNAL') {
      body = this.renderJournals();
    } else if (tab_selected === 'SYSTEM') {
      body = this.renderSystem();
    } else if (tab_selected === 'COMPANY') {
      body = this.renderCompany();
    }

    let cls = "flexroot";
    if (window.ue) {
      cls += " flexroot-embed";
    }

    const date = tickToDate(turn);
    const ms_per_tick = 1000 / this.state.simtps;
    const minutes = 0 | Math.max(0, Math.min(59, (this.now - this.last_ms) * 60 / ms_per_tick));

    const str_date = dayjs(date).format("YYYY.MM.DD");
    const str_time = dayjs(date).format("HH:") + String(minutes).padStart(2, '0') + ":00";

    const hour = date.getHours();
    const days = turn / 24 + minutes / (60 * 60);

    const tier = game.getRenownTier();

    const tabs = TABS.map((tab) => {
      const { avail, count } = this.tabStatus(tab);
      return { tab, avail, count };
    });

    return <div className={cls}>
      <div className="flex-worldmap">
        <WorldCanvas world={world} ref={this.canvasRef}
          onMouseMove={this.onWorldMouseMove.bind(this)}
          onSelect={this.onWorldSelect.bind(this)}
          selected_base_idx={game.selected_world_idx}
          selected_mission_idx={game.mission_selected?.idx ?? null}
          view={view}
          hidemissions={!this.state.overlayoptions.mission}
          simtps={simtps}
          days={days}
          onRender={this.onRender.bind(this)}
        />
      </div>

      <GlobalModifiersView game={game} />

      <img alt="" className='flexbg-lb' src='/img/UI_Corner_Tone_L_B.png' />
      <img alt="" className='flexbg-lt' src='/img/UI_Corner_Tone_L_T.png' />
      <img alt="" className='flexbg-rb' src='/img/UI_Corner_Tone_R_B.png' />
      <img alt="" className='flexbg-rt' src='/img/UI_Corner_Tone_R_T.png' />

      <div className="flex-player">
        <img alt="" className='flex-player-bg' src='/img/lobby/Player_BGI.png' />
        <Spinner paused={this.state.paused} simtps={this.state.simtps} onClick={() => {
          this.setState({ tab_selected: 'SYSTEM' });
        }} />

        <span className='flex-player-datetime'>
          <span className="flex-player-date">{str_date}</span>
          <br />
          <span className="flex-player-time">{str_time}</span>
        </span>

        <div className='flex-player-btns'>
          {this.renderTime()}
        </div>
      </div>

      {this.renderWorldOverlay()}
      {this.renderWorldOverlayOptions()}

      <OutgameHeader {...({
        resource: game.resources.resource,
        level: game.resources.renown.level + 1,
        level_tooltip: `현재 명성: ${game.resources.renown.point}\n다음 명성 단계까지: ${game.resources.renown.point}/${tier.max + 1}`,
        agents: game.agents.length,
        agents_max: game.maxAgentsCount(),
        training: game.agents.filter((a) => a.state === 'training').length,
        training_max: game.slots.length,
        injured: game.agents.filter((a) => a.life < a.life_max).length,
      })} />

      <RenderLeft tabs={tabs} selected={tab_selected} onSelect={(tab) => {
        this.onTabSelect(tab);
      }} />

      <div className="flexcenter">
        {body}
      </div>

      <div className="flexright">
        {this.renderQuests()}
        {/*
        <TrainingListWidget game={game} onAgentPreview={this.onAgentPreview.bind(this)} />
        {this.renderMissionList()}
        {this.renderAgentPointList()}
        {this.renderAgentFullLifeList()}
        */}
      </div>

      {/*
      <div className="flexright1">
        {this.renderNotifications()}
      </div>
      {this.renderSimpleJournals()}
       */}

      {DEBUG ? <div className="flexdebug">
        <Button onClick={() => this.onRenownUp()}>명성</Button>
        <Button onClick={() => this.onResourceUp()}>현금</Button>
        <Button onClick={() => this.onResetNames2()}>미소녀</Button>
      </div> : null}

      {this.renderOverlay()}

      <GlobalTooltip {...tooltip} />
      {
        play_bgm ?
          <audio autoPlay loop>
            <source src="/audio/bgm00.mp3" type="audio/mpeg" />
          </audio> : null
      }
    </div>;
  }
}

function Spinner(props) {
  const { paused, simtps, onClick } = props;
  const times = Math.round(simtps / TPS_BASE);
  const className = `flex-player-reel spinner-x${times}` + (paused ? ' spinner-paused' : '')
  return <img alt="" className={className} src='/img/ReelTape.png' onClick={onClick} />;
}

function GlobalTooltip(props) {
  const { show, x, y, title, content } = props;

  if (!show) {
    return null;
  }

  const OFFSET_TOOLTIP = { x: 32, y: 16 };
  const style = {
    top: `${y + OFFSET_TOOLTIP.y}px`,
    left: `${x + OFFSET_TOOLTIP.x}px`,
  };

  return <div className='tooltip-floater' style={style}>
    <h2>{title}</h2>
    <p>{content}</p>
  </div>
}


function lookup_tooltip(ty, options = {}) {
  if (ty === 'trait') {
    const { key } = options;
    const found = data_traits.find(key);
    const { name: title, descr: content } = found;
    return { title, content };
  } else if (ty === 'modifier') {
    const { key } = options;
    const found = data_modifiers[key];
    const { name: title, desc: content } = found;
    return { title, content };
  } else if (ty === 'background') {
    const { key } = options;
    const found = data_backgrounds.find((d) => d.key === key);
    const { name: title, descr: content } = found;
    return { title, content };
  }

  return { title: '', content: '' };
}

function tooltipHandlers() {
  return {
    onTooltipShow: ({ title, content }) => this.setState((prevState) => ({ tooltip: { ...prevState.tooltip, show: true, title, content } })),
    onTooltipHide: () => this.setState({ tooltip: { show: false } }),
    onTooltipMove: (e) => this.setState((prevState) => ({ tooltip: { ...prevState.tooltip, x: e.clientX, y: e.clientY } })),
    lookup: lookup_tooltip,
  };
}

function AgentDetailContract(props) {
  const { agent, game, onAgentTerminate } = props;
  const { contract } = agent;
  const { turn } = game;
  const details = contractDetails(contract, turn);
  const details_raw = contractDetails0(contract, turn);
  const has_any_options = details_raw.options.filter(({ value }) => !!value).length > 0;
  const { terminationCost } = terminations(contract, turn);
  const sign = terminationCost < 0 ? '' : '-';
  const abs = Math.abs(terminationCost);

  let terminateButtonText = 'loc_ui_button_agent_contract_cancellation';
  let canTerminate = true;

  if (agent.state === 'mission') {
    canTerminate = false;
    terminateButtonText = 'loc_ui_string_common_agent_state_mission';
  }
  else if (!game.agentAvail(agent)) {
    canTerminate = false;
    terminateButtonText = 'loc_ui_string_common_agent_state_recovery';
  }

  return <Panel>
    <Panel title={L('loc_ui_string_agent_contract_information')}>
      {details.map(([k, v]) => <KVP title={k} key={k}>{v}</KVP>)}
    </Panel>
    {has_any_options &&
      <Panel title={L('loc_ui_string_agent_contract_option')}>
        {details_raw.options.map((o) => o.value && <PanelSmall key={o.name} title={L(o.name)}>
          {L(o.desc)}
        </PanelSmall>)}
      </Panel>
    }
    <Panel title={L('loc_ui_button_agent_contract_cancellation')}>
      <KVP title={L('loc_ui_string_agent_contract_cancellation_fee')}>{sign}${abs.toLocaleString('en-US')}</KVP>
      <ButtonPrimary onClick={onAgentTerminate} disabled={!canTerminate} className="agent-contract-cancellation">{L(terminateButtonText)}</ButtonPrimary>
    </Panel >
  </Panel >;
}

function AgentDetailOverview(props) {
  const { agent, game } = props;
  const {
    onAgentEquipPopup,
    onAgentEquipAvail,
  } = props;
  const a = figmaConvertAgent({ agent, game });

  function equipButton(ty, index) {
    return <AgentEquipButton readonly={false} agent={agent}
      onAgentEquipAvail={onAgentEquipAvail}
      onAgentEquipPopup={onAgentEquipPopup}
      ty={ty} index={index} />;
  }

  return <Panel>
    <PanelSmall className='fh1-row' style={{ minHeight: 300 }}>
      <PanelSmall style={{ flex: '2 1 0', minWidth: 118 }}>
        <PortraitWrapper agent={agent} />
      </PanelSmall>
      <PanelSmall style={{ flex: '3 1 0' }}>
        <PanelSmall title={L('loc_ui_string_agent_nationality')}>
          {L(a.nationality.nation)}
        </PanelSmall>
        <PanelSmall title={L('loc_ui_string_agent_language')}>
          {L(a.language)}
        </PanelSmall>
        <PanelSmall title={L('loc_ui_string_agent_background')}>
          {L(a.background.name)}
        </PanelSmall>
        <PanelSmall title={L('loc_ui_string_agent_age')}>
          {a.age}
        </PanelSmall>
      </PanelSmall>
      <PanelSmall style={{ flex: '6 1 0' }}>
        <PanelSmall className='fh1-row' style={{ flex: '4 1 0' }}>
          <Grade title={L('loc_ui_string_agent_level')}>{a.level.cur}</Grade>
          <KVP title={L('loc_ui_string_agent_experience')}>{a.level.exp.toFixed(0)}/{a.exp_cap}</KVP>
        </PanelSmall>
        <PanelSmall className='fh1-row' style={{ flex: '6 1 0' }}>
          <Grade title={L('loc_ui_string_agent_grade')}>{a.grade}</Grade>
          <PanelSmall>
            <KVP title={L('loc_ui_string_agent_life')}>{a.life.toFixed()}/{a.life_max.toFixed()}</KVP>
            <KVP title={L('loc_ui_string_agent_stamina')}>{a.stamina.toFixed(1)}/{a.stamina_max.toFixed(1)}</KVP>
            <KVP title={L('loc_ui_string_agent_overall')}>{a.overall.toFixed(1)}/{a.overall_cap.toFixed(1)}</KVP>
            <KVP title={L('loc_ui_string_agent_weapon_qualification')}>{a.vocation.map((v) => v.toUpperCase()).join(', ')}</KVP>
          </PanelSmall>
        </PanelSmall>
        <PanelSmall className='fh1-row' style={{ flex: '5 1 0' }}>
          <Grade title={L('loc_ui_string_agent_growth_cap')} style={{ flex: '1 1 0' }}>{a.physical_grade}</Grade>
          <Grade title={L('loc_ui_string_agent_potential')} style={{ flex: '1 1 0' }}>{a.growth_grade}</Grade>
        </PanelSmall>
      </PanelSmall>
    </PanelSmall>
    <PanelSmall title={L('loc_ui_string_common_equipables')} className='fh1-row'>
      <PanelSmall>
        {L('loc_ui_string_common_firearm')}
        {equipButton('firearm', 0)}
      </PanelSmall>
      <PanelSmall style={{ height: 200 }}>
        <PanelSmall>
          {L('loc_ui_string_common_equipment')}
          {equipButton('equipment', 0)}
        </PanelSmall>
        <PanelSmall>
          {L('loc_ui_string_common_throwable')} #1
          {equipButton('throwable', 0)}
        </PanelSmall>
        <PanelSmall>
          {L('loc_ui_string_common_throwable')} #2
          {equipButton('throwable', 1)}
        </PanelSmall>
      </PanelSmall>
    </PanelSmall>
    <PanelSmall className='fh1-row'>
      <PanelSmall title={L('loc_ui_string_agent_trait')}>
        <ul>
          {a.traits.map((item, index) => <li key={index}>{L(item.name)}</li>)}
        </ul>
      </PanelSmall>
      <PanelSmall title={L('loc_ui_string_agent_status')}>
        <ul>
          {a.modifier.map((item, index) => <li key={index}>{L(item.name)}</li>)}
        </ul>
      </PanelSmall>
    </PanelSmall>
    <PanelSmall title={L('loc_ui_string_agent_statistics')}>
      <PanelSmall>
        {L('loc_ui_string_agent_statistics_mission')} TODO
      </PanelSmall>
      <PanelSmall>
        {L('loc_ui_string_agent_statistics_damage')} TODO
      </PanelSmall>
    </PanelSmall>
    <PanelSmall title={L('loc_ui_string_agent_contribution')}>
      ${a.contribution}
    </PanelSmall>
  </Panel>;
}


function AgentRecruitOverview(props) {
  const { game, agent, negotiated, avail, onDone, onContract,
    option: option0, onChangeOption,
    depart_idx = 0, onChangeDepartIdx,
    state,
  } = props;
  const { world, turn, global_modifier, departmentRoot } = game;

  const options_readonly = !negotiated;

  const contract = agent?.contract;
  const agenda = contract?.agenda;
  const option1 = option0 ?? contract?.option ?? {};

  const disabled = {};
  const option = {};
  for (const opt of CONTRACT_OPTIONS) {
    disabled[opt.key] = false;
    option[opt.key] = option1[opt.key] ?? false;
  }

  if (agenda === 'mod_agent_16_agenda_frequent') {
    disabled['long'] = true;
    option['long'] = false;
  }
  if (agenda === 'mod_agent_18_agenda_wellbeing') {
    disabled['advanced'] = true;
    option['advanced'] = true;
  }
  if (agenda === 'mod_agent_17_agenda_safety') {
    disabled['danger'] = true;
    option['danger'] = true;
  }

  if (!_.isEqual(option, option0)) {
    onChangeOption && onChangeOption(option);
  }

  if (!agent) {
    return null;
  }

  // DepartmentView에서 적당히 훔쳐옴
  // TODO: 적당히 합치기
  const work_key = 'recruit_agent';
  const departments = departmentRoot.availDepartments(work_key);

  const set_depart_idx_next = () => {
    const incr = depart_idx + 1;
    const next = (incr >= departments.length) ? 0 : incr;
    onChangeDepartIdx && onChangeDepartIdx(next);
  };

  const department = departments[depart_idx];
  const department_working = departmentRoot.departments.find((d) => d.pendings.find((p) => p.work_key === work_key && p.var1.idx === agent.idx));
  const pending_plan = departmentRoot.createPending(work_key, department, agent, null);
  const pending_working_idx = department_working?.pendings?.findIndex((p) => p.var1?.idx === agent.idx);
  const pending_working = department_working?.pendings[pending_working_idx];
  const remain_days = Math.ceil((pending_working?.ticks ?? 0) / TICK_PER_DAY);
  const success_rate_text = getSuccessRateText(work_key);
  const percentage_text = getPercentageText(pending_plan);
  const onAddPending = () => departmentRoot.addPending(department, pending_plan, game);
  const onRemovePending = negotiated ?
    null :
    () => {
      departmentRoot.removePending(department, pending_working_idx, game);
      onDone && onDone();
    };

  const { start, term } = contract;
  const contracted = term > 0;
  const renewal_needed = contracted && (start + term <= turn);
  const allow_change_depart = !negotiated && avail && !pending_working && !contracted;

  const a = figmaConvertAgent({ agent, game });
  const preview = contractRenew(turn, agent, option, global_modifier);
  const details = renewal_needed ? contractDetails(preview, turn, contract) : contractDetails(preview, turn);

  const dangerpay = option.danger ? 250 : 0;
  // TODO: 상수
  const injury = option.advanced ? 10 : 5;
  // TODO: 상수

  function calculate_final_fee(game, work_key, department, pending, fee) {
    const props = game.getDepartmentPendingSuccessProps(work_key, department, pending);
    const { discount } = props;
    return fee * (1.0 - discount);
  }
  const final_fee = calculate_final_fee(game, work_key, department, pending_plan, contract.turnCost);

  let departmentview = null;
  if (department.key !== TEMPORAL_DEPARTMENT_KEY) {
    departmentview = <KVP title='협상 진행 부서'>
      {departmentName(department, world)}
      {allow_change_depart && <>
        -
        <Button label='변경' onClick={set_depart_idx_next} />
      </>
      }
    </KVP>;
  }

  return <Panel style={{ minHeight: 777 }}>
    <PanelSmall className='fh1-row' style={{ minHeight: 180, maxHeight: 184 }}>
      <PanelSmall style={{ flex: '1 1 0', minWidth: 118 }}>
        <PortraitWrapper agent={agent} />
      </PanelSmall>
      <PanelSmall style={{ flex: '3 1 0' }}>
        <PanelSmall className='fh1-row' style={{ flex: '2 1 0' }}>
          <PanelSmall title={L('loc_ui_string_agent_age')} style={{ flex: '2 1 0' }}>
            {a.age}
          </PanelSmall>
          <PanelSmall title={L('loc_ui_string_agent_nationality')} style={{ flex: '3 1 0' }}>
            {L(a.nationality.nation)}
          </PanelSmall>
        </PanelSmall>
        <PanelSmall className='fh1-row' style={{ flex: '3 1 0' }}>
          <Grade title={L('loc_ui_string_agent_grade')}>{a.grade}</Grade>
          <PanelSmall>
            <KVP title={L('loc_ui_string_agent_life')}>{a.life.toFixed()}/{a.life_max.toFixed()}</KVP>
            <KVP title={L('loc_ui_string_agent_stamina')}>{a.stamina.toFixed(1)}/{a.stamina_max.toFixed(1)}</KVP>
            <KVP title={L('loc_ui_string_agent_overall')}>{a.overall.toFixed(1)}/{a.overall_cap.toFixed(1)}</KVP>
            <KVP title={L('loc_ui_string_agent_weapon_qualification')}>{a.vocation.map((v) => v.toUpperCase()).join(', ')}</KVP>
          </PanelSmall>
        </PanelSmall>
      </PanelSmall>
      <PanelSmall style={{ flex: '4 1 0' }}>
        <PanelSmall className='fh1-row' style={{ flex: '2 1 0' }}>
          <PanelSmall title={L('loc_ui_string_agent_language')}>
            {L(a.language)}
          </PanelSmall>
          <PanelSmall title={L('loc_ui_string_agent_background')}>
            {L(a.background.name)}
          </PanelSmall>
        </PanelSmall>
        <PanelSmall className='fh1-row' style={{ flex: '3 1 0' }}>
          <Grade title={L('loc_ui_string_agent_growth_cap')} style={{ flex: '1 1 0' }}>{a.physical_grade}</Grade>
          <Grade title={L('loc_ui_string_agent_potential')} style={{ flex: '1 1 0' }}>{a.growth_grade}</Grade>
        </PanelSmall>
      </PanelSmall>
    </PanelSmall>
    <PanelSmall className='fh1-row' style={{ maxHeight: 160 }}>
      <PanelSmall>
        <PanelSmall title={L('loc_ui_string_common_equipables')}>
          TODO:
          {L('loc_ui_string_common_firearm_' + firearm_ty_full[a.firearm.firearm_ty] + '_short')}
          /
          {a.firearm.firearm_rate}
          /
          {L(a.firearm.firearm_name)}<br />
          TODO:
          {a.equipment?.vest_armor}
          /
          {a.equipment?.vest_rate}
          /
          {L(a.equipment?.vest_name)}
        </PanelSmall>
      </PanelSmall>
      <PanelSmall>
        <PanelSmall title={L('loc_ui_string_agent_trait')}>
          <ul>
            {a.traits.map((item, index) => <li key={index}>{L(item.name)}</li>)}
          </ul>
        </PanelSmall>
        <PanelSmall title={L('loc_ui_string_agent_status')}>
          <ul>
            {a.modifier.map((item, index) => <li key={index}>{L(item.name)}</li>)}
          </ul>
        </PanelSmall>
      </PanelSmall>
    </PanelSmall>
    <PanelSmall title={L('loc_ui_string_agent_contract_information')}>
      <PanelSmall className='fh1-row' style={{ flex: '4 1 0' }}>
        <PanelSmall>
          {details.map(([k, v]) => <KVP title={k} key={k}>{v}</KVP>)}
        </PanelSmall>
        <PanelSmall>
          <KVP title={L('loc_ui_string_agent_contract_medical_expense')}>{L('loc_dynamic_string_agent_contract_daily_pay', { value: injury })}</KVP>
          <KVP title={L('loc_ui_string_agent_contract_danger_pay')}>${dangerpay}</KVP>
          <div style={{ width: 18, height: 18 }} />
          {departmentview}
          <KVP title={success_rate_text}>{percentage_text}</KVP>
          <KVP title={L('loc_ui_string_recruit_base_pay_when_favorable')}>{L('loc_dynamic_string_agent_contract_monthly_pay', { value: final_fee.toFixed(1) })}</KVP>
        </PanelSmall>
      </PanelSmall>
      {
        options_readonly ? null :
          <PanelSmall title={L('loc_ui_string_agent_contract_option')} className='fh1-row' style={{ flex: '2 1 0', maxHeight: 61 }}>
            <CheckBox
              label={L('loc_ui_string_agent_contract_option_long_term')}
              checked={option['long']}
              disabled={(options_readonly || disabled['long'])}
              onChange={() => options_readonly || disabled['long'] || onChangeOption({ ...option, long: !option['long'] })}
              style={{ flex: '1 1 0' }}
            />
            <CheckBox
              label={L('loc_ui_string_agent_contract_option_medical_priority')}
              checked={option['advanced']}
              disabled={(options_readonly || disabled['advanced'])}
              onChange={() => options_readonly || disabled['advanced'] || onChangeOption({ ...option, advanced: !option['advanced'] })}
              style={{ flex: '1 1 0' }}
            />
            <CheckBox
              label={L('loc_ui_string_agent_contract_option_hazard_pay')}
              checked={option['danger']}
              disabled={(options_readonly || disabled['danger'])}
              onChange={() => options_readonly || disabled['danger'] || onChangeOption({ ...option, danger: !option['danger'] })}
              style={{ flex: '1 1 0' }}
            />
          </PanelSmall>
      }
      <PanelSmall className='fh1-row' style={{ flex: '1 1 0' }}>
        <ButtonPrimary
          style={{ flex: '3 1 0' }}
          label={
            negotiated ?
              avail ?
                renewal_needed ?
                  L('loc_ui_button_agent_contract_renew') :
                  L('loc_ui_button_recruit_recruit_immediately') :
                state ?? L('loc_ui_string_recruit_cannot_recruit_no_vacancy') :
              department_working ?
                L('loc_ui_button_recruit_ongoing_recruit_negotiation', { count: remain_days }) :
                avail ?
                  L('loc_ui_button_recruit_start_recruit_negotiation') :
                  state ?? L('loc_ui_string_recruit_cannot_recruit_no_vacancy')
          }
          onClick={
            negotiated ?
              avail ?
                renewal_needed ?
                  () => {
                    onContract && onContract(agent.contract.ty, agent, option);
                    onDone && onDone();
                  } :
                  () => {
                    onContract && onContract(agent.contract.ty, agent, option);
                    onDone && onDone();
                  } :
                null :
              department_working ?
                null :
                () => {
                  onAddPending();
                  onDone && onDone();
                }
          }
          disabled={
            negotiated ?
              avail ?
                false :
                true :
              department_working ?
                true :
                avail ?
                  false :
                  true
          }
        />
        {
          (!negotiated && !department_working) ?
            null
            : <ButtonPrimary
              style={{ flex: '2 1 0' }}
              label={
                negotiated ?
                  avail ?
                    renewal_needed ?
                      agent.state ?
                        L('loc_ui_string_agent_contract_must_renew') :
                        L('loc_ui_button_agent_contract_not_renew') :
                      L('loc_ui_button_recruit_not_recruit') :
                    contracted ?
                      L('loc_ui_string_agent_contract_cannot_renew_no_vacancy') :
                      state ?? L('loc_ui_string_recruit_cannot_recruit_no_vacancy') :
                  L('loc_ui_button_recruit_cancel_recruit_negotiation')
              }
              onClick={
                negotiated ?
                  avail ?
                    renewal_needed ?
                      () => {
                        !agent.state && onContract && onContract(null, agent, option);
                        onDone && onDone();
                      } :
                      () => {
                        onContract && onContract(null, agent, option);
                        onDone && onDone();
                      } :
                    () => {
                      onContract && onContract(null, agent, option);
                      onDone && onDone();
                    } :
                  onRemovePending
              }
              disabled={agent.state ? true : false}
            />
        }
      </PanelSmall>
    </PanelSmall>
  </Panel>;
}

function AgentDetailPerk(props) {
  const { agent, onAgentAcquirePerk } = props;
  return <Panel>
    <PerkView
      agent={agent}
      onAgentAcquirePerk={onAgentAcquirePerk}
    />
  </Panel>;
}
