// downloaded data!
export const data = [
  {
    "perk_group": "common",
    "source": "default",
    "source_group": "",
    "weight": "",
    "perk_group_name": "공통",
    "description": "특기할 사항은 없습니다."
  },
  {
    "perk_group": "decision",
    "source": "trait",
    "source_group": "decision",
    "weight": "1",
    "perk_group_name": "%%decision%%",
    "description": "사격시 조준을 향상시키는 데 관심이 있습니다."
  },
  {
    "perk_group": "bravery",
    "source": "trait",
    "source_group": "bravery",
    "weight": "1",
    "perk_group_name": "%%bravery%%",
    "description": "타고난 용기를 전장에서 가다듬을 기회가 있습니다."
  },
  {
    "perk_group": "focus",
    "source": "trait",
    "source_group": "focus",
    "weight": "1",
    "perk_group_name": "%%focus%%",
    "description": "어떠한 상황에서도 적을 명중시키는 데 관심이 있습니다."
  },
  {
    "perk_group": "reaction",
    "source": "trait",
    "source_group": "reaction",
    "weight": "1",
    "perk_group_name": "%%reaction%%",
    "description": "비현실적인 생존본능을 발달시킬 수 있습니다."
  },
  {
    "perk_group": "toughness",
    "source": "trait",
    "source_group": "toughness",
    "weight": "1",
    "perk_group_name": "%%toughness%%",
    "description": "강인한 정신과 육체로 활약하고자 합니다."
  },
  {
    "perk_group": "precision",
    "source": "trait",
    "source_group": "precision",
    "weight": "1",
    "perk_group_name": "%%precision%%",
    "description": "일격필살의 정밀함을 갈고닦으려 합니다."
  },
  {
    "perk_group": "social",
    "source": "trait",
    "source_group": "social",
    "weight": "1",
    "perk_group_name": "사교적",
    "description": "좋은 동료들과 일하는 데 관심이 있습니다."
  },
  {
    "perk_group": "antisocial",
    "source": "trait",
    "source_group": "antisocial",
    "weight": "1",
    "perk_group_name": "외톨이",
    "description": "혼자서 일하는 것을 선호합니다."
  },
  {
    "perk_group": "negative",
    "source": "trait",
    "source_group": "negative",
    "weight": "1",
    "perk_group_name": "부정적",
    "description": "부정적인 사고가 눈에 띕니다."
  },
  {
    "perk_group": "desmar_offensive",
    "source": "background",
    "source_group": "thug",
    "weight": "3",
    "perk_group_name": "지정사수(뒷골목)",
    "description": "공격받지 않는 거리에서 적을 하나씩 제거하기를 즐깁니다."
  },
  {
    "perk_group": "commed_unlicensed",
    "source": "background",
    "source_group": "thug",
    "weight": "3",
    "perk_group_name": "전투의무병(뒷골목)",
    "description": "급조한 붕대와 데낄라로 총에 맞은 사람을 살리곤 합니다."
  },
  {
    "perk_group": "common",
    "source": "background",
    "source_group": "thug",
    "weight": "4",
    "perk_group_name": "공통",
    "description": "뒷골목에서 세월을 보냈습니다."
  },
  {
    "perk_group": "grenadier_regular",
    "source": "background",
    "source_group": "soldier",
    "weight": "2",
    "perk_group_name": "척탄병(정규)",
    "description": "수류탄 투척 훈련을 우수하게 통과했습니다."
  },
  {
    "perk_group": "desmar_defensive",
    "source": "background",
    "source_group": "soldier",
    "weight": "2",
    "perk_group_name": "지정사수(정규)",
    "description": "지정사수로 복무한 경험이 있습니다."
  },
  {
    "perk_group": "commed_regular",
    "source": "background",
    "source_group": "soldier",
    "weight": "2",
    "perk_group_name": "전투의무병(정규)",
    "description": "의무병 과정을 수료했습니다."
  },
  {
    "perk_group": "antisocial",
    "source": "background",
    "source_group": "soldier",
    "weight": "2",
    "perk_group_name": "외톨이",
    "description": "전멸한 부대의 유일한 생존자입니다."
  },
  {
    "perk_group": "common",
    "source": "background",
    "source_group": "soldier",
    "weight": "2",
    "perk_group_name": "공통",
    "description": "비교적 평범한 군인이었습니다."
  },
  {
    "perk_group": "commed_unlicensed",
    "source": "background",
    "source_group": "commoner",
    "weight": "2",
    "perk_group_name": "전투의무병(뒷골목)",
    "description": "삶의 굴곡이 죽음으로부터 도망치는 법을 가르쳤습니다."
  },
  {
    "perk_group": "common",
    "source": "background",
    "source_group": "commoner",
    "weight": "8",
    "perk_group_name": "공통",
    "description": "굴곡진 삶을 살았습니다."
  },
  {
    "perk_group": "common",
    "source": "background",
    "source_group": "bourgeois",
    "weight": "2",
    "perk_group_name": "공통",
    "description": "거친 일에 익숙하지 않습니다."
  },
  {
    "perk_group": "social",
    "source": "background",
    "source_group": "agitator",
    "weight": "8",
    "perk_group_name": "사교적",
    "description": "사람들의 감정을 조절하는 천부적인 재능이 있습니다."
  },
  {
    "perk_group": "common",
    "source": "background",
    "source_group": "agitator",
    "weight": "2",
    "perk_group_name": "공통",
    "description": "어떻게 사람들을 이끄는 자리에 있었는지 이해할 수 없게 평범합니다."
  },
  {
    "perk_group": "grenadier_regular",
    "source": "background",
    "source_group": "bluecollar",
    "weight": "3",
    "perk_group_name": "척탄병(정규)",
    "description": "노동으로 단련된 강인한 어깨를 지녔습니다."
  },
  {
    "perk_group": "toughness",
    "source": "background",
    "source_group": "bluecollar",
    "weight": "3",
    "perk_group_name": "%%toughness%%",
    "description": "보이는 것보다 노동으로 단련된 육체가 탄탄합니다."
  },
  {
    "perk_group": "common",
    "source": "background",
    "source_group": "bluecollar",
    "weight": "4",
    "perk_group_name": "공통",
    "description": "오래된 노동을 지긋지긋해하고 있습니다."
  },
  {
    "perk_group": "grenadier_chemical",
    "source": "background",
    "source_group": "whitecollar",
    "weight": "3",
    "perk_group_name": "척탄병(특수탄)",
    "description": "화학물질을 안전하게 운용할 수 있습니다."
  },
  {
    "perk_group": "commed_regular",
    "source": "background",
    "source_group": "whitecollar",
    "weight": "3",
    "perk_group_name": "전투의무병(정규)",
    "description": "의무 훈련을 받을 기초지식이 있습니다."
  },
  {
    "perk_group": "common",
    "source": "background",
    "source_group": "whitecollar",
    "weight": "4",
    "perk_group_name": "공통",
    "description": "책상물림입니다."
  }
];