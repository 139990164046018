/* eslint-disable */
import { opts } from './opts.mjs';
import { v2 } from './v2.mjs';
import { STATS_TMPL, STATS_TMPL_MAX, team0_STATS_TMPL, team1_STATS_TMPL, entityFromStat, stats_const } from './stat.mjs';
import { entityFromStat2, STATS2_TMPL, stats2_const, entityFromStat2WithBonus } from './stats2.mjs';
import { agents } from './agents.mjs';
import * as presets_mission from './presets_mission.mjs';
import { preset_maze } from './randomPreset2.mjs'
import { throwable_fragment, throwable_stun } from './presets_throwables.mjs';

import {
  team0_tmpl_agent_dmr_mid,
  team0_tmpl_indoor_ar_high,
  team0_tmpl_agent_sg_high,
  team0_tmpl_agent_ar_low,
  team0_tmpl_agent_ar_mid,
  tmpl_bulletproof_low,
  team0_tmpl_agent_ar_high,

  enemy_tmpl_agent_ar_low,
} from './presets.mjs';

import * as presets_firearm from './presets_firearm.mjs';
const {
  tmpl_firearm_ar_low,
  tmpl_firearm_ar_mid,
  tmpl_firearm_ar_high,
  tmpl_firearm_sg_low,
  tmpl_firearm_sg_mid,
  tmpl_firearm_sg_high,
  tmpl_firearm_dmr_mid,
  tmpl_firearm_dmr_high,
  tmpl_firearm_sr_high,
  tmpl_firearm_sr_mid,
  tmpl_firearm_smg_mid,
  tmpl_firearm_smg_low
} = presets_firearm;

import {
  gear_vest_bulletproof_mid,
  gear_nvd_t1,
  gear_nvd_t2,
  gear_nvd_t3,
  gear_nvd_t4,
  gear_vest_bulletproof_highend,
} from './presets_gear.mjs';
/* eslint-enable */

function presetInvul(tb) {
  return {
    ...tb,
    preset: () => {
      const preset = tb.preset();
      for (const entity of preset.entities) {
        if (entity.team === 0) {
          entity.invulnerable = true;
        }
      }
      return preset;
    },
  };
}


function preset_stat_judgement() {
  const poor = {
    preset: () => {
      const preset = presets_mission.presets['outdoor'](1000);
      preset.entities = preset.entities.filter((e) => e.team !== 0);
      const entity0 = entityFromStat(stats_const(15), tmpl_firearm_sr_high);
      preset.entities.push(entity0);
      return preset;
    }
  };
  const good = {
    preset: () => {
      const preset = poor.preset();
      const entity = preset.entities.find((e) => e.team === 0);

      entity.retarget_interval_range = [3, 5];
      entity.retarget_accurate_prob = 0;

      return preset;
    }
  };

  return {
    poor,
    good,
  };
}

function preset_stat_cover() {
  const poor = {
    preset: () => {
      const preset = presets_mission.presets['outdoor'](800);
      preset.entities = preset.entities.filter((e) => e.team !== 0);
      const entity0 = entityFromStat(stats_const(15), tmpl_firearm_ar_high);
      preset.entities.push(entity0);
      return preset;
    },
  };
  const good = {
    ...poor,
    preset: () => {
      const preset = poor.preset();
      const entity = preset.entities.find((e) => e.team === 0);
      entity.hit_prob_covered = 0.2;

      return preset;
    },
  };

  return {
    poor,
    good,
  };
}

function preset_mobility() {
  const m1 = {
    preset: () => {
      return presets_mission.presets['movetest_indoor']();
    },
    onsim: (sim) => {
      sim.controlSet('mobility', 0, 1);
    },
  };
  const m2 = {
    ...m1,
    onsim: (sim) => {
      sim.controlSet('mobility', 0, 2);
    },
  };
  const m3 = {
    ...m1,
    onsim: (sim) => {
      sim.controlSet('mobility', 0, 3);
    },
  };

  return { m1, m2, m3 };
}

function override_entity(preset, override) {
  preset.entities = preset.entities.map((e) => {
    if (e.team !== 0) {
      return e;
    }
    return {
      ...e,
      ...override,
    };
  });
}

function preset_level() {
  const variants = {};

  for (let i = 5; i <= 20; i += 5) {
    const variant = {
      preset: () => {
        const preset = presets_mission.presets['movetest_indoor']();
        override_entity(preset, entityFromStat(stats_const(i), tmpl_firearm_smg_mid));
        return preset;
      },
    };
    variants[`level_${i}`] = variant;
  }

  return variants;
}

function preset_crit() {
  return {
    'nocrit': {
      preset: () => {
        const preset = presets_mission.presets['aimvar']();
        override_entity(preset, entityFromStat(stats_const(10), tmpl_firearm_ar_mid));
        preset.entities[0].crit_prob = 0;
        return preset;
      },
    },
    'crit': {
      preset: () => {
        const preset = presets_mission.presets['aimvar']();
        override_entity(preset, entityFromStat(stats_const(10), tmpl_firearm_ar_mid));
        preset.entities[0].crit_prob = 1;
        return preset;
      },
    },
  };
}

function preset_firearm_ty() {
  const types = ['ar_mid', 'ar_high', 'smg_mid', 'smg_high', 'sg_mid', 'sg_high'];
  const variants = {};

  for (const ty of types) {
    const variant = {
      preset: () => {
        const preset = presets_mission.presets['movetest_indoor']();
        const firearm = presets_firearm[`tmpl_firearm_${ty}`];
        override_entity(preset, entityFromStat(stats_const(15), firearm));
        return preset;
      },
    };
    variants[ty] = variant;
  }

  return variants;
}

function preset_stable_damage_preset(preset) {
  const on = {
    preset: () => {
      return presets_mission.presets[preset]();
    },
    onsim: (_sim) => {
      opts.STABLE_DAMAGE = true;
      opts.STABLE_DAMAGE_FAKE = false;
    },
  };

  const off = {
    ...on,
    onsim: (_sim) => {
      opts.STABLE_DAMAGE = false;
      opts.STABLE_DAMAGE_FAKE = false;
    },
  };

  const fake = {
    ...on,
    onsim: (_sim) => {
      opts.STABLE_DAMAGE = true;
      opts.STABLE_DAMAGE_FAKE = true;
    },
  };

  return { on, off, fake };
}


function preset_stat_throwable() {
  const poor = {
    preset: () => {
      const preset = presets_mission.presets['outdoor']({ height: 800 });
      preset.entities = preset.entities.filter((e) => e.team !== 0);

      const entity0 = entityFromStat(stats_const(10), tmpl_firearm_ar_high);
      entity0.throwables = [throwable_fragment];

      preset.entities.push(entity0);
      return preset;
    },
    onsim: (sim) => {
      sim.controlSet('throwable', 0, true);
    },
  };
  const good = {
    ...poor,
    preset: () => {
      const preset = poor.preset();
      const entity = preset.entities.find((e) => e.team === 0);
      entity.throwables = [throwable_fragment];

      entity.throwable_max_dist = 400;

      return preset;
    },
  };

  return {
    poor,
    good,
  };
}

function preset_riskrank() {
  const use_mixed = false;
  const f = presets_mission.preset_outdoor_riskrank;
  return {
    leaderrank: { preset: () => f({ use_riskrank: true, use_leader: true, use_mixed }) },
    def: { preset: () => f({ use_riskrank: false, use_leader: false, use_mixed }) },
    leader: { preset: () => f({ use_riskrank: false, use_leader: true, use_mixed }) },
    rank: { preset: () => f({ use_riskrank: true, use_leader: false, use_mixed }) },
  };
}

function preset_metro() {
  const nogroup = {
    preset: () => {
      const preset = presets_mission.presets['metro']();
      preset.entities = preset.entities.map((e) => ({ ...e }));
      return preset;
    },
  };

  const group = {
    preset: () => {
      const preset = nogroup.preset();
      preset.entities[1].leader = 0;
      preset.entities[2].leader = 0;
      preset.entities[3].leader = 0;

      return preset;
    },
  };

  const twogroup = {
    preset: () => {
      const preset = nogroup.preset();
      preset.entities[1].leader = 0;
      preset.entities[3].leader = 2;

      preset.entities[2].spawnarea = 1;
      preset.entities[3].spawnarea = 1;

      return preset;
    },
  };

  return {
    group,
    nogroup,
    twogroup,

    group_invul: presetInvul(group),
    nogroup_invul: presetInvul(nogroup),
    twogroup_invul: presetInvul(twogroup),
  };
}

function preset_outdoor_throw() {
  const base = {
    preset: () => {
      const preset = presets_mission.presets['outdoor']({ height: 800 });
      preset.entities = preset.entities.filter((e) => e.team !== 0);

      const entity0 = entityFromStat(stats_const(10), presets_firearm.tmpl_firearm_smg_high);
      entity0.throwables = [throwable_fragment];
      const entity1 = entityFromStat(stats_const(10), presets_firearm.tmpl_firearm_smg_high);

      preset.entities.push(entity0);
      preset.entities.push(entity1);

      return preset;
    },
    onsim: (sim) => {
      sim.controlSet('throwable', 0, true);
    },
  };
  const throwrange = {
    ...base,
    preset: () => {
      const preset = base.preset();
      preset.entities.find((e) => e.throwables && e.throwables.length > 0).perk_grenadier_throw_range = true;

      return preset;
    },
  };
  const effectrange = {
    ...base,
    preset: () => {
      const preset = base.preset();
      preset.entities.find((e) => e.throwables && e.throwables.length > 0).perk_grenadier_effect_range = true;

      return preset;
    },
  };
  const effectamount = {
    ...base,
    preset: () => {
      const preset = base.preset();
      preset.entities.find((e) => e.throwables && e.throwables.length > 0).perk_grenadier_effect_amount = true;

      return preset;
    },
  };
  const shorterblast = {
    ...base,
    preset: () => {
      const preset = base.preset();
      preset.entities.find((e) => e.throwables && e.throwables.length > 0).perk_grenadier_shorter_blast = true;

      return preset;
    }
  };

  return {
    base,
    throwrange,
    effectrange,
    effectamount,
    shorterblast,
  };
}

function preset_indoor2_breach() {
  const base = {
    preset: () => {
      const preset = presets_mission.presets['indoor2']();
      preset.entities = preset.entities.filter((e) => e.team !== 0);

      const entity0 = entityFromStat(stats_const(10), presets_firearm.tmpl_firearm_smg_high);
      entity0.throwables = [throwable_fragment];
      const entity1 = entityFromStat(stats_const(10), presets_firearm.tmpl_firearm_smg_high);

      preset.entities.push(entity0);
      preset.entities.push(entity1);

      return preset;
    },
    onsim: (sim) => {
      sim.controlSet('throwable', 0, true);
    },
  };
  const throwrange = {
    ...base,
    preset: () => {
      const preset = base.preset();
      preset.entities.find((e) => e.throwables && e.throwables.length > 0).perk_grenadier_throw_range = true;

      return preset;
    },
  };
  const effectrange = {
    ...base,
    preset: () => {
      const preset = base.preset();
      preset.entities.find((e) => e.throwables && e.throwables.length > 0).perk_grenadier_effect_range = true;

      return preset;
    },
  };
  const effectamount = {
    ...base,
    preset: () => {
      const preset = base.preset();
      preset.entities.find((e) => e.throwables && e.throwables.length > 0).perk_grenadier_effect_amount = true;

      return preset;
    },
  };
  const shorterblast = {
    ...base,
    preset: () => {
      const preset = base.preset();
      preset.entities.find((e) => e.throwables && e.throwables.length > 0).perk_grenadier_shorter_blast = true;

      return preset;
    }
  };

  return {
    base,
    throwrange,
    effectrange,
    effectamount,
    shorterblast,
  };
}

function preset_aimvar() {
  const variants = {};

  for (const firearm of presets_firearm.firearms_tier1) {
    variants[firearm.firearm_ty] = {
      preset: () => {
        const preset = presets_mission.preset_aimvar({ dist: firearm.firearm_range * 2 / 3 });
        preset.entities = preset.entities.filter((e) => e.team !== 0);
        const entity0 = entityFromStat(stats_const(15), firearm);
        preset.entities.push(entity0);
        return preset;
      },
    };
  }

  return variants;
}

function preset_desmar_test() {
  const defaultpreset = () => {
    const preset = presets_mission.presets['border_checkpoint_short']();

    const team0_ar = {
      ...entityFromStat(stats_const(10), presets_firearm.tmpl_firearm_ar_low),
      ...gear_vest_bulletproof_highend,
      spawnarea: 0,
    };

    const team1_tmpl_ar = {
      ...entityFromStat(stats_const(2), tmpl_firearm_ar_low),
      default_rule: { ty: 'idle', alert: false },
      team: 1,
      spawnarea: 2,
    };

    preset.entities = [
      { ...team0_ar },
      { ...team0_ar, leader: 0 },
      { ...team0_ar, leader: 0 },

      { ...team1_tmpl_ar, spawnarea: 3 },
      { ...team1_tmpl_ar, spawnarea: 4 },
      { ...team1_tmpl_ar, spawnarea: 5 },
      { ...team1_tmpl_ar, spawnarea: 6 },

      ...preset.entities.filter((e) => e.team !== 0),
    ];


    preset.spawnareas = [
      ...preset.spawnareas,
      { pos: new v2(218, 922), extent: new v2(18, 18), heading: 0, spawn: true, },
    ];

    return preset;
  };
  const noperk = {
    preset: () => {
      const preset = defaultpreset();

      const team0_dmr = {
        ...entityFromStat(stats_const(10), presets_firearm.tmpl_firearm_dmr_low),
        ...gear_vest_bulletproof_mid,
        spawnarea: 0,
      };

      preset.entities.push({ ...team0_dmr, leader: 0 });
      return preset;
    },
  };

  const baseperk = {
    preset: () => {
      const preset = defaultpreset();

      const team0_dmr = {
        ...entityFromStat(stats_const(10), presets_firearm.tmpl_firearm_dmr_low),
        ...gear_vest_bulletproof_mid,
        spawnarea: 7,
      };

      preset.entities.push(team0_dmr);
      return preset;
    },
  };

  const distraction = {
    preset: () => {
      const preset = defaultpreset();

      const team0_dmr = {
        ...entityFromStat(stats_const(10), presets_firearm.tmpl_firearm_dmr_low),
        ...gear_vest_bulletproof_mid,
        perk_desmar_distraction: true,
        spawnarea: 7,
      };

      preset.entities.push(team0_dmr);
      return preset;
    },
  }

  const distraction_range = {
    preset: () => {
      const preset = defaultpreset();

      const team0_dmr = {
        ...entityFromStat(stats_const(10), presets_firearm.tmpl_firearm_dmr_low),
        ...gear_vest_bulletproof_mid,
        perk_desmar_distraction: true,
        perk_desmar_distraction_range: true,
        spawnarea: 7,
      };

      preset.entities.push(team0_dmr);
      return preset;
    },
  }

  const damage = {
    preset: () => {
      const preset = defaultpreset();

      const team0_dmr = {
        ...entityFromStat(stats_const(10), presets_firearm.tmpl_firearm_dmr_low),
        ...gear_vest_bulletproof_mid,
        perk_desmar_damage: true,
        spawnarea: 7,
      };

      preset.entities.push(team0_dmr);
      return preset;
    },
  }

  const aimspeed = {
    preset: () => {
      const preset = defaultpreset();

      const team0_dmr = {
        ...entityFromStat(stats_const(10), presets_firearm.tmpl_firearm_dmr_low),
        ...gear_vest_bulletproof_mid,
        perk_desmar_aimspeed: true,
        spawnarea: 7,
      };

      preset.entities.push(team0_dmr);
      return preset;
    },
  }

  const priority_defensive = {
    preset: () => {
      const preset = defaultpreset();

      const team0_dmr = {
        ...entityFromStat(stats_const(10), presets_firearm.tmpl_firearm_dmr_low),
        ...gear_vest_bulletproof_mid,
        perk_desmar_priority_defensive: true,
        spawnarea: 7,
      };

      preset.entities.push(team0_dmr);
      return preset;
    },
  }

  const priority_offensive = {
    preset: () => {
      const preset = defaultpreset();

      const team0_dmr = {
        ...entityFromStat(stats_const(10), presets_firearm.tmpl_firearm_dmr_low),
        ...gear_vest_bulletproof_mid,
        perk_desmar_priority_offensive: true,
        spawnarea: 7,
      };

      preset.entities.push(team0_dmr);
      return preset;
    },
  }

  return {
    noperk,
    baseperk,
    distraction,
    distraction_range,
    damage,
    aimspeed,
    priority_defensive,
    priority_offensive,
  };
}

function preset_commed_test() {
  let basepreset = presets_mission.presets['indoor2']();
  basepreset.entities[0].throwables = [];
  basepreset.entities[1].throwables = [];
  const noperk = {
    preset: () => {
      return basepreset;
    },
  };

  const baseperk = {
    preset: () => {
      const preset = basepreset;

      let e0 = preset.entities.find((e) => e.team === 0);
      e0.heals = [
        {
          heal_amount: 25,
          heal_duration: 3,
        },
      ];

      return preset;
    },
  };

  const workspeed = {
    preset: () => {
      const preset = basepreset;

      let e0 = preset.entities.find((e) => e.team === 0);
      e0.heals = [
        {
          heal_amount: 25,
          heal_duration: 3,
        },
      ];
      e0.perk_commed_workspeed = true;

      return preset;
    },
  };

  const count = {
    preset: () => {
      const preset = basepreset;

      let e0 = preset.entities.find((e) => e.team === 0);
      e0.heals = [
        {
          heal_amount: 25,
          heal_duration: 3,
        },
        {
          heal_amount: 25,
          heal_duration: 3,
        },
      ];

      return preset;
    },
  };

  const amount = {
    preset: () => {
      const preset = basepreset;

      let e0 = preset.entities.find((e) => e.team === 0);
      e0.heals = [
        {
          heal_amount: 25,
          heal_duration: 3,
        },
      ];
      e0.perk_commed_amount = true;

      return preset;
    },
  };

  const target_self = {
    preset: () => {
      const preset = basepreset;

      let e0 = preset.entities.find((e) => e.team === 0);
      e0.heals = [
        {
          heal_amount: 25,
          heal_duration: 3,
        },
      ];
      e0.perk_commed_target_self = true;

      return preset;
    },
  };

  const risk = {
    preset: () => {
      const preset = basepreset;

      let e0 = preset.entities.find((e) => e.team === 0);
      e0.heals = [
        {
          heal_amount: 25,
          heal_duration: 3,
        },
      ];
      e0.perk_commed_risk = true;

      return preset;
    },
  };

  const buff = {
    preset: () => {
      const preset = basepreset;

      let e0 = preset.entities.find((e) => e.team === 0);
      e0.heals = [
        {
          heal_amount: 25,
          heal_duration: 3,
        },
      ];
      e0.perk_commed_buff = true;

      return preset;
    },
  };

  return {
    noperk,
    baseperk,
    workspeed,
    count,
    amount,
    target_self,
    risk,
    buff,
  };
}

function preset_indoor_rescue() {
  const basecase = {
    preset: () => {
      let basepreset = presets_mission.presets['indoor_rescue']();
      return basepreset;
    },
  };

  const fragment = {
    preset: () => {
      const preset = basecase.preset();
      const entity0 = preset.entities.find((e) => e.team === 0);
      entity0.throwables = [throwable_fragment];
      return preset;
    }
  };

  const stun = {
    preset: () => {
      const preset = basecase.preset();
      const entity0 = preset.entities.find((e) => e.team === 0);
      entity0.throwables = [throwable_stun];
      return preset;
    }
  }

  return {
    basecase,
    fragment,
    stun,
  };
}

function preset_desmar_priority() {
  let e0 = {
    ...entityFromStat(stats_const(15), tmpl_firearm_dmr_high),

    firearm_projectile_damage: 10,
  };

  let e1 = {
    ...entityFromStat(stats_const(1), tmpl_firearm_smg_low),
    waypoint_policy: 'defensive',

    team: 1,
    spawnarea: 1,
  };

  const _entities = () => {
    return [
      { ...e0 },
      { ...e1, life: 30 },
      { ...e1, life: 50 },
      { ...e1, firearm_range: 500, },
      { ...e1, _stat: { _stat: 10 } },
    ];
  };

  const basecase = {
    preset: () => {
      let preset = presets_mission.presets['outdoor']();
      preset.entities = _entities();
      return preset;
    },
  };

  const defensive = {
    preset: () => {
      let preset = basecase.preset();
      preset.entities[0].perk_desmar_priority_defensive = true;
      return preset;
    },
  };

  const offensive = {
    preset: () => {
      let preset = basecase.preset();
      preset.entities[0].perk_desmar_priority_offensive = true;
      return preset;
    },
  };

  return {
    basecase,
    defensive,
    offensive,
  };
}

function preset_perks_outdoor() {
  const outdoor = {
    preset: () => {
      const preset = presets_mission.presets['outdoor']({ height: 800 });
      preset.entities = preset.entities.filter((e) => e.team !== 0);

      const entity0 = entityFromStat(stats_const(8), presets_firearm.tmpl_firearm_ar_high);
      const entity1 = entityFromStat(stats_const(8), presets_firearm.tmpl_firearm_ar_high);

      preset.entities.push(entity0);
      preset.entities.push(entity1);

      return preset;
    },
  }

  const aim_together = {
    preset: () => {
      const preset = presets_mission.presets['outdoor']({ height: 800 });
      preset.entities = preset.entities.filter((e) => e.team !== 0);

      const entity0 = entityFromStat(stats_const(8), presets_firearm.tmpl_firearm_dmr_high);
      entity0['perk_aim_together'] = true;
      const entity1 = entityFromStat(stats_const(8), presets_firearm.tmpl_firearm_ar_high);

      preset.entities.push(entity0);
      preset.entities.push(entity1);

      preset.entities.map((e) => e.life = 1000);

      return preset;
    },
  }

  const aim_execute = {
    preset: () => {
      const preset = presets_mission.presets['outdoor']({ height: 800 });
      preset.entities = preset.entities.filter((e) => e.team !== 0);

      const entity0 = entityFromStat(stats_const(8), presets_firearm.tmpl_firearm_dmr_high);
      entity0['perk_aim_execute'] = true;
      const entity1 = entityFromStat(stats_const(8), presets_firearm.tmpl_firearm_ar_high);

      preset.entities.push(entity0);
      preset.entities.push(entity1);

      preset.entities.map((e) => e.life = 1000);

      return preset;
    },
  }

  return {
    outdoor,
    aim_together,
    aim_execute,
  }
}

function preset_perks_compare() {

  const hit_antiarmor = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_low;
      const preset = presets_mission.presets['preset_aimvar_compare']({ dist: firearm.firearm_range * 2 / 3 });

      preset.entities[0] = {
        ...preset.entities[0],
        perk_hit_antiarmor: true,
      }

      preset.entities.map((e) => e.armor = 1000);

      preset.entities.filter((e) => e.team !== 0).map((e) => e.default_rule = 'dummy');

      return preset;
    },
  }

  const reduce_cover_effect = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_low;
      const preset = presets_mission.presets['preset_aimvar_compare']({ dist: firearm.firearm_range * 2 / 3 });

      preset.entities[0] = {
        ...preset.entities[0],
        perk_reduce_cover_effect: true,
      }

      return preset;
    },
  }

  const piercing_bullet = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_low;
      const preset = presets_mission.presets['preset_aimvar_compare']({ dist: firearm.firearm_range * 2 / 3 });

      preset.entities[0] = {
        ...preset.entities[0],
        perk_piercing_bullet: true,
      }
      return preset;
    },
  }

  const pierce_moving_enemy = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_dmr_mid;
      const dist = firearm.firearm_range * 2 / 3;
      const height = Math.ceil(firearm.firearm_range / 100) * 100;
      const preset = presets_mission.presets['preset_aimvar_compare']({ dist: dist });

      const team0_tmpl_base = entityFromStat(stats_const(8), firearm);
      preset.entities[0] = {
        ...team0_tmpl_base,
        perk_pierce_moving_enemy: true,
      }

      preset.entities[1] = {
        ...team0_tmpl_base,
        spawnarea: 1,
      }

      const team1_tmpl_base = entityFromStat(STATS_TMPL, tmpl_firearm_sg_low);
      const team1_tmpl = {
        ...team1_tmpl_base,
        team: 1,
        spawnarea: 2,

        life: 1000,
        default_rule: 'explore',
      };

      preset.entities[2] = team1_tmpl;
      preset.entities[3] = {
        ...team1_tmpl,
        spawnarea: 3,
      }

      preset.obstacle_specs = [
        { pos: new v2(-100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-80, -height / 2 + 15 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(120, -height / 2 + 15 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-120, -height / 2 - 25 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(80, -height / 2 - 25 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-80, -height / 2 - 65 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(120, -height / 2 - 65 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-120, -height / 2 - 105 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(80, -height / 2 - 105 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },

        { pos: new v2(0, 0), extent: new v2(10, height / 2), heading: 0, ty: "full" },
      ]
      return preset;
    },
  }

  const damage_standing = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_mid;
      const dist = firearm.firearm_range * 2 / 3;
      const height = Math.ceil(firearm.firearm_range / 100) * 100;
      const preset = presets_mission.presets['preset_aimvar_compare']({ dist: dist });

      preset.entities[0] = {
        ...preset.entities[0],
        perk_damage_standing: true,
      }

      preset.entities.filter((e) => e.team !== 0).map((e) => e.default_rule = 'dummy');

      preset.obstacle_specs = [
        { pos: new v2(-100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(0, 0), extent: new v2(10, height / 2), heading: 0, ty: "full" },
      ];

      return preset;
    },
  }

  const damage_move_crawl = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_dmr_mid;
      const dist = firearm.firearm_range * 2 / 3;
      const height = Math.ceil(firearm.firearm_range / 100) * 100;
      const preset = presets_mission.presets['preset_aimvar_compare']({ dist: dist });

      const team0_tmpl_base = entityFromStat(stats_const(8), firearm);
      preset.entities[0] = {
        ...team0_tmpl_base,
        life: 1000,
        perk_damage_move_crawl: true,
      }

      preset.entities[1] = {
        ...team0_tmpl_base,
        life: 1000,
        spawnarea: 1,
      }

      const team1_tmpl_base = entityFromStat(STATS_TMPL, tmpl_firearm_sg_low);
      const team1_tmpl = {
        ...team1_tmpl_base,
        team: 1,
        spawnarea: 2,

        life: 1000,
        default_rule: 'explore',
      };

      preset.entities[2] = team1_tmpl;
      preset.entities[3] = {
        ...team1_tmpl,
        spawnarea: 3,
      }
      preset.obstacle_specs = [
        { pos: new v2(-100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-80, -height / 2 + 15 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(120, -height / 2 + 15 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-120, -height / 2 - 25 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(80, -height / 2 - 25 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-80, -height / 2 - 65 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(120, -height / 2 - 65 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-120, -height / 2 - 105 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(80, -height / 2 - 105 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },

        { pos: new v2(0, 0), extent: new v2(10, height / 2), heading: 0, ty: "full" },
      ];

      return preset;
    },
  }

  const lastshoot = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_mid;
      const preset = presets_mission.presets['preset_aimvar_compare']({ dist: firearm.firearm_range * 2 / 3 });

      const team0_tmpl_base = entityFromStat(stats_const(8), firearm);
      preset.entities[0] = {
        ...team0_tmpl_base,
        life: 1000,
        perk_lastshoot: true,
      }

      preset.entities[1] = {
        ...team0_tmpl_base,
        life: 1000,
        spawnarea: 1,
      }
      return preset;
    },
  }

  const cover_steady = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_mid;
      const preset = presets_mission.presets['preset_aimvar_compare']({ dist: firearm.firearm_range * 2 / 3 });

      const team0_tmpl_base = entityFromStat(stats_const(8), firearm);
      preset.entities[0] = {
        ...team0_tmpl_base,
        life: 1000,
        perk_cover_reload: true,
      }

      preset.entities[1] = {
        ...team0_tmpl_base,
        life: 1000,
        spawnarea: 1,
      }
      return preset;
    },
  }

  const hit_incr_aimvar = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_mid;
      const preset = presets_mission.presets['preset_aimvar_compare']({ dist: firearm.firearm_range * 2 / 3 });

      const team0_tmpl_base = entityFromStat(stats_const(8), firearm);
      preset.entities[0] = {
        ...team0_tmpl_base,
        life: 1000,
        perk_cover_reload: true,
      }

      preset.entities[1] = {
        ...team0_tmpl_base,
        life: 1000,
        spawnarea: 1,
      }
      return preset;
    },
  }

  const cover_reload = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_mid;
      const preset = presets_mission.presets['preset_aimvar_compare']({ dist: firearm.firearm_range * 2 / 3 });

      const team0_tmpl_base = entityFromStat(stats_const(8), firearm);
      preset.entities[0] = {
        ...team0_tmpl_base,
        life: 1000,
        perk_cover_reload: true,
      }

      preset.entities[1] = {
        ...team0_tmpl_base,
        life: 1000,
        spawnarea: 1,
      }
      return preset;
    },
  }

  const damp_aimvar_incr = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_dmr_low;
      const preset = presets_mission.presets['preset_aimvar_compare']({ dist: firearm.firearm_range * 2 / 3 });

      const team0_tmpl_base = entityFromStat(stats_const(8), firearm);
      preset.entities[0] = {
        ...team0_tmpl_base,
        life: 1000,
        perk_damp_aimvar_incr: true,
      };

      preset.entities[1] = {
        ...team0_tmpl_base,
        life: 1000,
        spawnarea: 1,
      }

      const team1_tmpl_base = entityFromStat(STATS_TMPL, firearm);
      const team1_tmpl = {
        ...team1_tmpl_base,
        life: 1000,
        team: 1,
        spawnarea: 2,
      };

      preset.entities[2] = team1_tmpl;
      preset.entities[3] = {
        ...team1_tmpl,
        spawnarea: 3,
      }

      return preset;
    },
  }

  const engage_dash = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_dmr_mid;
      const dist = firearm.firearm_range * 2 / 3;
      const height = Math.ceil(firearm.firearm_range / 100) * 100;
      const preset = presets_mission.presets['preset_aimvar_compare']({ dist: dist });

      const team0_tmpl_base = entityFromStat(stats_const(8), firearm);
      preset.entities[0] = {
        ...team0_tmpl_base,
      };

      preset.entities[1] = {
        ...team0_tmpl_base,
        spawnarea: 1,
      };

      const team1_tmpl_base = entityFromStat(STATS_TMPL, tmpl_firearm_sg_low);
      const team1_tmpl = {
        ...team1_tmpl_base,
        team: 1,
        spawnarea: 2,

        life: 1000,
        default_rule: 'explore',
      };

      preset.entities[2] = {
        ...team1_tmpl,
        perk_engage_dash: true,
        spawnarea: 2,
      };
      preset.entities[3] = {
        ...team1_tmpl,
        spawnarea: 3,
      };
      preset.obstacle_specs = [
        { pos: new v2(-100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-80, -height / 2 + 15 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(120, -height / 2 + 15 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-120, -height / 2 - 25 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(80, -height / 2 - 25 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-80, -height / 2 - 65 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(120, -height / 2 - 65 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-120, -height / 2 - 105 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(80, -height / 2 - 105 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },

        { pos: new v2(0, 0), extent: new v2(10, height / 2), heading: 0, ty: "full" },
      ];

      return preset;
    },
  }

  const cover_effect = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_mid;
      const preset = presets_mission.presets['preset_aimvar_compare']({ dist: firearm.firearm_range * 2 / 3 });

      const team0_tmpl_base = entityFromStat(stats_const(8), firearm);
      preset.entities[0] = {
        ...team0_tmpl_base,
        life: 1000,
      }

      preset.entities[1] = {
        ...team0_tmpl_base,
        life: 1000,
        spawnarea: 1,
      }

      preset.entities[2] = {
        ...preset.entities[2],
        perk_cover_effect: true,
      }
      return preset;
    },
  };

  const cover_dash = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_dmr_mid;
      const dist = firearm.firearm_range * 2 / 3;
      const height = Math.ceil(firearm.firearm_range / 100) * 100;
      const preset = presets_mission.presets['preset_aimvar_compare']({ dist: dist });

      const team0_tmpl_base = entityFromStat(stats_const(8), firearm);
      preset.entities[0] = {
        ...team0_tmpl_base,
      };

      preset.entities[1] = {
        ...team0_tmpl_base,
        spawnarea: 1,
      };

      const team1_tmpl_base = entityFromStat(STATS_TMPL, tmpl_firearm_ar_mid);
      const team1_tmpl = {
        ...team1_tmpl_base,
        team: 1,
        spawnarea: 2,

        life: 1000,
        default_rule: 'explore',
      };

      preset.entities[2] = {
        ...team1_tmpl,
        perk_cover_dash: true,
        spawnarea: 2,
      };
      preset.entities[3] = {
        ...team1_tmpl,
        spawnarea: 3,
      };
      preset.obstacle_specs = [
        { pos: new v2(-100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-80, -height / 2 + 15 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(120, -height / 2 + 15 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-120, -height / 2 - 25 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(80, -height / 2 - 25 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-80, -height / 2 - 65 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(120, -height / 2 - 65 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-120, -height / 2 - 105 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(80, -height / 2 - 105 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },

        { pos: new v2(0, 0), extent: new v2(10, height / 2), heading: 0, ty: "full" },
      ];

      return preset;
    },
  };

  const armor_effect = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_high;
      const preset = presets_mission.presets['preset_aimvar_compare']({ dist: firearm.firearm_range * 2 / 3 });

      const team0_tmpl_base = entityFromStat(STATS_TMPL, firearm);
      preset.entities[0] = {
        ...team0_tmpl_base,
        life: 1000,
        armor: 1000,
      };

      preset.entities[1] = {
        ...team0_tmpl_base,
        life: 1000,
        armor: 1000,
        spawnarea: 1,
      }

      const team1_tmpl_base = entityFromStat(STATS_TMPL, firearm);
      const team1_tmpl = {
        ...team1_tmpl_base,
        life: 1000,
        armor: 1000,
        team: 1,
        spawnarea: 2,
      };

      preset.entities[2] = {
        ...team1_tmpl,
        perk_armor_effect: true,
      };
      preset.entities[3] = {
        ...team1_tmpl,
        spawnarea: 3,
      }

      return preset;
    },
  };

  const armor_first = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_high;
      const preset = presets_mission.presets['preset_aimvar_compare']({ dist: firearm.firearm_range * 2 / 3 });

      const team0_tmpl_base = entityFromStat(STATS_TMPL, firearm);
      preset.entities[0] = {
        ...team0_tmpl_base,
        life: 1000,
        armor: 1000,
      };

      preset.entities[1] = {
        ...team0_tmpl_base,
        life: 1000,
        armor: 1000,
        spawnarea: 1,
      }

      const team1_tmpl_base = entityFromStat(STATS_TMPL, firearm);
      const team1_tmpl = {
        ...team1_tmpl_base,
        life: 1000,
        armor: 1000,
        team: 1,
        spawnarea: 2,
      };

      preset.entities[2] = {
        ...team1_tmpl,
        perk_armor_first: true,
      };
      preset.entities[3] = {
        ...team1_tmpl,
        spawnarea: 3,
      }

      return preset;
    },
  };

  const standing_evade = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_mid;
      const dist = firearm.firearm_range * 2 / 3;
      const height = Math.ceil(firearm.firearm_range / 100) * 100;
      const preset = presets_mission.presets['preset_aimvar_compare']({ dist: dist });

      preset.entities.filter((e) => e.team !== 0).map((e) => e.default_rule = 'dummy');
      preset.entities[2] = {
        ...preset.entities[2],
        perk_standing_evade: true,
      }
      preset.obstacle_specs = [
        { pos: new v2(-100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(0, 0), extent: new v2(10, height / 2), heading: 0, ty: "full" },
      ];

      return preset;
    },
  };

  const move_cover = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_dmr_mid;
      const dist = firearm.firearm_range * 2 / 3;
      const height = Math.ceil(firearm.firearm_range / 100) * 100;
      const preset = presets_mission.presets['preset_aimvar_compare']({ dist: dist });

      const team0_tmpl_base = entityFromStat(stats_const(8), firearm);
      preset.entities[0] = {
        ...team0_tmpl_base,
      };

      preset.entities[1] = {
        ...team0_tmpl_base,
        spawnarea: 1,
      };

      const team1_tmpl_base = entityFromStat(STATS_TMPL, tmpl_firearm_sg_low);
      const team1_tmpl = {
        ...team1_tmpl_base,
        team: 1,
        spawnarea: 2,

        life: 1000,
        default_rule: 'explore',
      };

      preset.entities[2] = {
        ...team1_tmpl,
        perk_move_cover: true,
        spawnarea: 2,
      };
      preset.entities[3] = {
        ...team1_tmpl,
        spawnarea: 3,
      };
      preset.obstacle_specs = [
        { pos: new v2(-100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-80, -height / 2 + 15 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(120, -height / 2 + 15 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-120, -height / 2 - 25 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(80, -height / 2 - 25 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-80, -height / 2 - 65 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(120, -height / 2 - 65 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-120, -height / 2 - 105 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(80, -height / 2 - 105 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },

        { pos: new v2(0, 0), extent: new v2(10, height / 2), heading: 0, ty: "full" },
      ];

      return preset;
    },
  };

  const sg_projectile = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_sg_low;
      const preset = presets_mission.presets['preset_aimvar_compare']({ dist: 70 });

      const team0_tmpl_base = entityFromStat(STATS_TMPL, firearm);
      preset.entities[0] = {
        ...team0_tmpl_base,
        perk_sg_projectile: true,
      }
      preset.entities[1] = {
        ...team0_tmpl_base,
        spawnarea: 1,
      }

      preset.entities.filter((e) => e.team !== 0).map((e) => e.default_rule = 'dummy');

      return preset;
    },
  }

  return {
    hit_antiarmor,
    reduce_cover_effect,
    piercing_bullet,
    pierce_moving_enemy,
    damage_standing,
    damage_move_crawl,
    lastshoot,
    cover_steady,
    hit_incr_aimvar,
    cover_reload,
    damp_aimvar_incr,
    engage_dash,
    cover_effect,
    cover_dash,
    armor_effect,
    armor_first,
    standing_evade,
    move_cover,
    sg_projectile,
  }
}

function preset_stats_compare() {
  function preset_base(args) {
    const dist = args?.dist ?? 300;
    const height = Math.ceil(dist * 3 / 2 / 100) * 100;
    const world = {
      width: 400,
      height: height,
      simover_rule: 'eliminate',
    };

    const controlStat =
    {
      ...STATS2_TMPL,
      decision: args?.decision ? 1 : STATS2_TMPL.decision,
      bravery: args?.bravery ? 1 : STATS2_TMPL.bravery,
      focus: args?.focus ? 1 : STATS2_TMPL.focus,
      reaction: args?.reaction ? 1 : STATS2_TMPL.reaction,
      toughness: args?.toughness ? 1 : STATS2_TMPL.toughness,
      precision: args?.precision ? 1 : STATS2_TMPL.precision,
    };

    const testStat =
    {
      ...STATS2_TMPL,
      decision: args?.decision ? 20 : STATS2_TMPL.decision,
      bravery: args?.bravery ? 20 : STATS2_TMPL.bravery,
      focus: args?.focus ? 20 : STATS2_TMPL.focus,
      reaction: args?.reaction ? 20 : STATS2_TMPL.reaction,
      toughness: args?.toughness ? 20 : STATS2_TMPL.toughness,
      precision: args?.precision ? 20 : STATS2_TMPL.precision,
    };

    const buildEntity = args?.bonus ? entityFromStat2WithBonus : entityFromStat2;

    const team0_tmpl = {
      ...buildEntity(controlStat, tmpl_firearm_ar_low),
      spawnarea: 0,
      life: 1000,
    };

    const team0_test = {
      ...buildEntity(testStat, tmpl_firearm_ar_low),
      spawnarea: 1,
      life: 1000,
    }

    const team1_tmpl_base = buildEntity(STATS2_TMPL, tmpl_firearm_ar_low);
    const team1_tmpl = {
      ...team1_tmpl_base,
      team: 1,
      spawnarea: 2,

      life: 1000,
      default_rule: 'cover',
    };

    return {
      world,

      spawnareas: [
        { pos: new v2(-100, -height / 2 + 20), extent: new v2(10, 10), heading: 0 },
        { pos: new v2(100, -height / 2 + 20), extent: new v2(10, 10), heading: 0 },
        { pos: new v2(-100, -height / 2 + 35 + dist), extent: new v2(10, 10), heading: 0 },
        { pos: new v2(100, -height / 2 + 35 + dist), extent: new v2(10, 10), heading: 0 },
      ],
      obstacle_specs: [
        { pos: new v2(-100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-100, -height / 2 + 15 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(100, -height / 2 + 15 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(0, 0), extent: new v2(10, height / 2), heading: 0, ty: "full" },
      ],

      entities: [
        team0_tmpl,
        { ...team0_test, spawnarea: 1 },
        team1_tmpl,
        { ...team1_tmpl, spawnarea: 3 },
      ],

      goals: [],
      mission_rules: [{ ty: 'explore' }],
    };
  };

  const decision = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_low;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          decision: true,
        });

      preset.entities.map((e) => e.armor = 1000);

      preset.entities.filter((e) => e.team !== 0).map((e) => e.default_rule = 'dummy');

      return preset;
    },
  };

  const bravery = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_low;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          bravery: true,
        });

      preset.entities.map((e) => e.armor = 1000);

      return preset;
    },
  };

  const focus = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_low;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          focus: true,
        });

      preset.entities.map((e) => e.armor = 1000);

      preset.entities.filter((e) => e.team !== 0).map((e) => e.default_rule = 'dummy');

      return preset;
    },
  };

  const reaction = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_low;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          reaction: true,
        });

      preset.entities.map((e) => e.armor = 1000);

      return preset;
    },
  };

  const toughness = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_low;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          toughness: true,
        });

      preset.entities.map((e) => e.armor = 1000);

      return preset;
    },
  };

  const precision = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_low;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          precision: true,
        });

      preset.entities.map((e) => e.armor = 1000);

      preset.entities.filter((e) => e.team !== 0).map((e) => e.default_rule = 'dummy');

      return preset;
    },
  };

  const decisionWithBonus = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_low;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          decision: true,
          bonus: true,
        });

      preset.entities.map((e) => e.armor = 1000);

      preset.entities.filter((e) => e.team !== 0).map((e) => e.default_rule = 'dummy');

      return preset;
    },
  };

  const braveryWithBonus = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_low;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          bravery: true,
          bonus: true,
        });

      preset.entities.map((e) => e.armor = 1000);

      return preset;
    },
  };

  const focusWithBonus = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_low;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          focus: true,
          bonus: true,
        });

      preset.entities.map((e) => e.armor = 1000);

      preset.entities.filter((e) => e.team !== 0).map((e) => e.default_rule = 'dummy');

      return preset;
    },
  };

  const reactionWithBonus = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_low;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          reaction: true,
          bonus: true,
        });

      preset.entities.map((e) => e.armor = 1000);

      return preset;
    },
  };

  const toughnessWithBonus = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_low;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          toughness: true,
          bonus: true,
        });

      preset.entities.map((e) => e.armor = 1000);

      return preset;
    },
  };

  const precisionWithBonus = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_low;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          precision: true,
          bonus: true,
        });

      preset.entities.map((e) => e.armor = 1000);

      preset.entities.filter((e) => e.team !== 0).map((e) => e.default_rule = 'dummy');

      return preset;
    },
  };

  return {
    decision,
    bravery,
    focus,
    reaction,
    toughness,
    precision,
    decisionWithBonus,
    braveryWithBonus,
    focusWithBonus,
    reactionWithBonus,
    toughnessWithBonus,
    precisionWithBonus,
  };
}

function preset_stat_bonus_compare() {

  function preset_base(args) {
    const dist = args?.dist ?? 300;
    const height = Math.ceil(dist * 3 / 2 / 100) * 100;
    const world = {
      width: 400,
      height: height,
      simover_rule: 'eliminate',
    };

    const controlStat =
    {
      ...STATS2_TMPL,
      decision: args?.decision ? args.level - 1 : STATS2_TMPL.decision,
      bravery: args?.bravery ? args.level - 1 : STATS2_TMPL.bravery,
      focus: args?.focus ? args.level - 1 : STATS2_TMPL.focus,
      reaction: args?.reaction ? args.level - 1 : STATS2_TMPL.reaction,
      toughness: args?.toughness ? args.level - 1 : STATS2_TMPL.toughness,
      precision: args?.precision ? args.level - 1 : STATS2_TMPL.precision,
    };

    const testStat =
    {
      ...STATS2_TMPL,
      decision: args?.decision ? args.level : STATS2_TMPL.decision,
      bravery: args?.bravery ? args.level : STATS2_TMPL.bravery,
      focus: args?.focus ? args.level : STATS2_TMPL.focus,
      reaction: args?.reaction ? args.level : STATS2_TMPL.reaction,
      toughness: args?.toughness ? args.level : STATS2_TMPL.toughness,
      precision: args?.precision ? args.level : STATS2_TMPL.precision,
    };

    const team0_tmpl = {
      ...entityFromStat2WithBonus(controlStat, tmpl_firearm_ar_low),
      spawnarea: 0,
      life: 1000,
    };

    const team0_test = {
      ...entityFromStat2WithBonus(testStat, tmpl_firearm_ar_low),
      spawnarea: 1,
      life: 1000,
    }

    const team1_tmpl_base = entityFromStat2(STATS2_TMPL, tmpl_firearm_ar_low);
    const team1_tmpl = {
      ...team1_tmpl_base,
      team: 1,
      spawnarea: 2,

      life: 1000,
      default_rule: 'cover',
    };

    return {
      world,

      spawnareas: [
        { pos: new v2(-100, -height / 2 + 20), extent: new v2(10, 10), heading: 0 },
        { pos: new v2(100, -height / 2 + 20), extent: new v2(10, 10), heading: 0 },
        { pos: new v2(-100, -height / 2 + 35 + dist), extent: new v2(10, 10), heading: 0 },
        { pos: new v2(100, -height / 2 + 35 + dist), extent: new v2(10, 10), heading: 0 },
      ],
      obstacle_specs: [
        { pos: new v2(-100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-100, -height / 2 + 15 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(100, -height / 2 + 15 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(0, 0), extent: new v2(10, height / 2), heading: 0, ty: "full" },
      ],

      entities: [
        team0_tmpl,
        { ...team0_test, spawnarea: 1 },
        team1_tmpl,
        { ...team1_tmpl, spawnarea: 3 },
      ],

      goals: [],
      mission_rules: [{ ty: 'explore' }],
    };
  };

  function preset_enemy(args) {
    const dist = args?.dist ?? 300;
    const height = Math.ceil(dist * 3 / 2 / 100) * 100;
    const world = {
      width: 400,
      height: height,
      simover_rule: 'eliminate',
    };

    const controlStat =
    {
      ...STATS2_TMPL,
      decision: args?.decision ? args.level - 1 : STATS2_TMPL.decision,
      bravery: args?.bravery ? args.level - 1 : STATS2_TMPL.bravery,
      focus: args?.focus ? args.level - 1 : STATS2_TMPL.focus,
      reaction: args?.reaction ? args.level - 1 : STATS2_TMPL.reaction,
      toughness: args?.toughness ? args.level - 1 : STATS2_TMPL.toughness,
      precision: args?.precision ? args.level - 1 : STATS2_TMPL.precision,
    };

    const testStat =
    {
      ...STATS2_TMPL,
      decision: args?.decision ? args.level : STATS2_TMPL.decision,
      bravery: args?.bravery ? args.level : STATS2_TMPL.bravery,
      focus: args?.focus ? args.level : STATS2_TMPL.focus,
      reaction: args?.reaction ? args.level : STATS2_TMPL.reaction,
      toughness: args?.toughness ? args.level : STATS2_TMPL.toughness,
      precision: args?.precision ? args.level : STATS2_TMPL.precision,
    };

    const team0_tmpl = {
      ...entityFromStat2WithBonus(STATS2_TMPL, presets_firearm.tmpl_firearm_dmr_mid),
      spawnarea: 0,
      life: 1000,
    };

    const team1_tmpl = {
      ...entityFromStat2(controlStat, tmpl_firearm_ar_mid),
      team: 1,
      spawnarea: 2,
    };
    const team1_test = {
      ...entityFromStat2(testStat, tmpl_firearm_ar_mid),
      team: 1,
      spawnarea: 3,

      life: 1000,
    };

    return {
      world,

      spawnareas: [
        { pos: new v2(-100, -height / 2 + 20), extent: new v2(10, 10), heading: 0 },
        { pos: new v2(100, -height / 2 + 20), extent: new v2(10, 10), heading: 0 },
        { pos: new v2(-100, -height / 2 + 35 + dist), extent: new v2(10, 10), heading: 0 },
        { pos: new v2(100, -height / 2 + 35 + dist), extent: new v2(10, 10), heading: 0 },
      ],
      obstacle_specs: [
        { pos: new v2(-100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-80, -height / 2 + 15 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(120, -height / 2 + 15 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-120, -height / 2 - 25 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(80, -height / 2 - 25 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-80, -height / 2 - 65 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(120, -height / 2 - 65 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(-120, -height / 2 - 105 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
        { pos: new v2(80, -height / 2 - 105 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },

        { pos: new v2(0, 0), extent: new v2(10, height / 2), heading: 0, ty: "full" },
      ],

      entities: [
        team0_tmpl,
        { ...team0_tmpl, spawnarea: 1 },
        team1_tmpl,
        team1_test,
      ],

      goals: [],
      mission_rules: [{ ty: 'explore' }],
    };
  };

  const decision_4 = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_low;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          decision: true,
          level: 4,
        });

      preset.entities.map((e) => e.armor = 1000);

      preset.entities.filter((e) => e.team !== 0).map((e) => e.default_rule = 'dummy');

      return preset;
    },
  };

  const decision_8 = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_low;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          decision: true,
          level: 8,
        });

      preset.entities.map((e) => e.armor = 1000);

      preset.entities.filter((e) => e.team !== 0).map((e) => e.default_rule = 'dummy');

      return preset;
    },
  };

  const decision_12 = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_low;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          decision: true,
          level: 12,
        });

      preset.entities.map((e) => e.armor = 1000);

      preset.entities.filter((e) => e.team !== 0).map((e) => e.default_rule = 'dummy');

      return preset;
    },
  };

  const decision_16 = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_low;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          decision: true,
          level: 16,
        });

      preset.entities.map((e) => e.armor = 1000);

      preset.entities.filter((e) => e.team !== 0).map((e) => e.default_rule = 'dummy');

      return preset;
    },
  };

  const bravery_4 = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_low;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          bravery: true,
          level: 4,
        });

      preset.entities.map((e) => e.armor = 1000);

      return preset;
    },
  };

  const bravery_8 = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_low;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          bravery: true,
          level: 8,
        });

      preset.entities.map((e) => e.armor = 1000);

      return preset;
    },
  };

  const bravery_12 = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_low;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          bravery: true,
          level: 12,
        });

      preset.entities.map((e) => e.armor = 1000);

      return preset;
    },
  };

  const bravery_16 = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_low;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          bravery: true,
          level: 16,
        });

      preset.entities.map((e) => e.armor = 1000);

      return preset;
    },
  };

  const focus_8 = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_low;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          focus: true,
          level: 8,
        });

      preset.entities.map((e) => e.armor = 1000);

      preset.entities.filter((e) => e.team !== 0).map((e) => e.default_rule = 'dummy');

      return preset;
    },
  };

  const reaction_12 = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_low;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          reaction: true,
          level: 12,
        });

      preset.entities.map((e) => e.armor = 1000);

      return preset;
    },
  };

  const toughness_4 = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_low;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          toughness: true,
          level: 4,
        });

      preset.entities.map((e) => e.armor = 1000);

      return preset;
    },
  };

  const toughness_8 = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_low;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          toughness: true,
          level: 8,
        });

      preset.entities.map((e) => e.armor = 1000);

      return preset;
    },
  };

  const toughness_12 = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_low;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          toughness: true,
          level: 12,
        });

      preset.entities.map((e) => e.armor = 1000);

      return preset;
    },
  };

  const toughness_20 = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_low;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          toughness: true,
          level: 20,
        });

      preset.entities.map((e) => e.armor = 1000);

      return preset;
    },
  };

  const precision_4 = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_ar_low;
      const preset = preset_base(
        {
          dist: firearm.firearm_range * 2 / 3,
          precision: true,
          level: 4,
        });

      preset.entities.map((e) => e.armor = 1000);

      preset.entities.filter((e) => e.team !== 0).map((e) => e.default_rule = 'dummy');

      return preset;
    },
  };

  const reaction_12_enemy = {
    preset: () => {
      const firearm = presets_firearm.tmpl_firearm_dmr_mid;
      const preset = preset_enemy(
        {
          dist: firearm.firearm_range * 2 / 3,
          bravery: true,
          level: 4,
        });

      preset.entities.map((e) => e.armor = 1000);

      return preset;
    },
  };

  return {
    'decision_4': decision_4,
    'decision_8': decision_8,
    'decision_12': decision_12,
    'decision_16': decision_16,
    'bravery_4': bravery_4,
    'bravery_8': bravery_8,
    'bravery_12': bravery_12,
    'bravery_16': bravery_16,
    'focus_8': focus_8,
    'reaction_12': reaction_12,
    'toughness_4': toughness_4,
    'toughness_8': toughness_8,
    'toughness_12': toughness_12,
    'toughness_20': toughness_20,
    'precision_4': precision_4,
    'reaction_12_enemy': reaction_12_enemy,
  }
}

export const presets = {
  'stat_judgement': preset_stat_judgement,
  'stat_cover': preset_stat_cover,
  'stat_throwable': preset_stat_throwable,
  'mobility': preset_mobility,
  'level': preset_level,
  'crit': preset_crit,
  'firearm_ty': preset_firearm_ty,
  'stable_damage_indoor2_trigger': () => preset_stable_damage_preset('indoor2_trigger'),
  'stable_damage_ship': () => preset_stable_damage_preset('ship'),
  'stable_damage_bazaar': () => preset_stable_damage_preset('bazaar'),
  'riskrank': preset_riskrank,
  'metro': preset_metro,
  'outdoor_throw': preset_outdoor_throw,
  'indoor2_breach': preset_indoor2_breach,
  'aimvar': preset_aimvar,
  'desmar_test': preset_desmar_test,
  'commed_test': preset_commed_test,
  'indoor_rescue': preset_indoor_rescue,

  'desmar_priority': preset_desmar_priority,
  'perks_outdoor': preset_perks_outdoor,
  'perks_compare': preset_perks_compare,
  'stats_compare': preset_stats_compare,
  'stat_bonus_compare': preset_stat_bonus_compare,
};

export const preset_default = 'desmar_priority';
