import { data as source } from '../downloaded/presets.mjs';


export const presets = [];
for (const row of source) {
  let { name,
    nationality, language, background, ambition, traits,
    vocation, ty, power, physical, potential_label, growthcap, perkpointcap, physicalcap,
    decision,
    bravery,
    focus,
    reaction,
    toughness,
    precision,
  } = row;

  presets.push({
    name,

    nationality,
    language,
    background,
    ambition,
    traits: traits.split(','),

    vocation,
    contract_ty: ty,
    power: +power,
    physical: +physical,
    potential_label,
    growthcap,
    perkpointcap,
    physicalcap,

    stats2: {
      decision: +decision,
      bravery: + bravery,
      focus: +focus,
      reaction: +reaction,
      toughness: + toughness,
      precision: +precision,
    },
  });
}
