import { data as source } from '../downloaded/perks2.mjs';

export const perks2 = [];

for (const row of source) {
  const { key, dep, level, groups, name, msg, descr, cost, term, available_firearm_ty } = row;

  perks2.push({
    key,
    dep,
    level: 0 | level,
    groups: groups === '' ? [] : groups.split(',').map((s) => s.trim()),
    name,
    msg,
    descr,
    cost,
    point: 0 | term,
    available_firearm_ty: available_firearm_ty === 'all' ? [] : available_firearm_ty.split(','),
  });
}

export function perks2ByKey(key0) {
  return perks2.find(({ key }) => key === key0);
}

const firearm_level_prefix = 'perk_stat_firearm_level_';

export function perk_apply_stats(stats, perk_keys) {
  stats = JSON.parse(JSON.stringify(stats));
  for (const perk_key of perk_keys) {
    const perk = perks2ByKey(perk_key);
    if (perk.key.startsWith(firearm_level_prefix)) {
      const key = 0 | perk.key.slice(firearm_level_prefix.length);
      stats.stat_firearm_level[key] = Math.min(20, stats.stat_firearm_level[key] + 5);
    }
  }
  return stats;
}

export function perk_apply_entity(entity, perk_keys) {
  for (const perk_key of perk_keys) {
    const perk = perks2ByKey(perk_key);
    if (perk.key.startsWith(firearm_level_prefix)) {
      continue;
    }
    if (perk.available_firearm_ty.length > 0 && perk.available_firearm_ty.indexOf(entity.firearm_ty) === -1) {
      console.log('unavailable');
      continue;
    }

    entity[perk.key] = true;
  }
}
