import React, { useState } from 'react';
import _ from 'lodash';

import './SimView.css';

import { opts } from './opts';
import { v2 } from './v2';
import { Rng } from './rand';
import { Simulator, } from './sim';
import { COLORS, COLORS_ALT, renderCanvas } from './simrender';
import { checkcover, routePathfind, routePathfindAll, hostilityNetwork, coverEdges, stats_populate } from './geom';
import { perks } from './perks';
import { StatView } from './StatView';
import { ProgressBar } from './ProgressBar';
import { PortraitWrapper } from './CharacterView';
import { ordersDescr } from './data/google/processor/data_ordersDescr.mjs';
import { serializeState } from './extobj.mjs';
import { SimOverlay } from './SimOverlay.mjs';

const DEBUG_SERIALIZE = false;
const SHOW_OVERLAY = false;

const sw = function(msg, f) {
  const start = Date.now();
  const ret = f();
  console.log(`${msg} took ${Date.now() - start}ms`);
  return ret;
}

const clamp = function(x, min, max) {
  return Math.min(Math.max(x, min), max);
}

function formatCoord(coord) {
  return `[${coord.x.toFixed(0)},${coord.y.toFixed(0)}]`;
}

const EntityOverContext = React.createContext({
  entityOver: null,
  onEntityOver: () => { },
});

function EntityName(props) {
  const { entity } = props;
  if (!entity) {
    return '<none>';
  }
  let { name, team } = entity;
  let cls = `entityname-${team}`;
  if (props.className) {
    cls = `${props.className} ${cls}`;
  }

  if (name.indexOf('"') >= 0) {
    name = name.split('"')[1];
  }

  return <EntityOverContext.Consumer>
    {({ entityOver, onEntityOver }) => {
      if (entity === entityOver) {
        cls += ` entityname-over`;
      }
      return <span className={cls}
        onMouseOver={() => onEntityOver(entity)}
        onMouseLeave={() => onEntityOver(null)}
      >{name}</span>;
    }}
  </EntityOverContext.Consumer>;
}

function EntityNameList(props) {
  const { entities } = props;
  if (!entities || entities.length === 0) {
    return '<empty>';
  }
  return <span>{entities.map((e) => EntityName({ entity: e })).reduce((prev, curr) => [...prev, ', ', curr], [])}</span>;
}

function JournalText(props) {
  const { item } = props;

  if (item.ty === 'aim') {
    return <span><EntityName entity={item.entity} />이(가) 조준합니다: <EntityName entity={item.target} /></span>;
  } else if (item.ty === 'unaim') {
    return <span><EntityName entity={item.entity} />이(가) 조준을 해제합니다.</span>;
  } else if (item.ty === 'fire') {
    const descr = item.kill ? '사살' : '사격';
    return <span><EntityName entity={item.entity} />이(가) {descr}합니다: <EntityName entity={item.target} /></span>;
  } else if (item.ty === 'discover') {
    return <span><EntityName entity={item.entity} />이(가) 발견합니다: <EntityName entity={item.target} /></span>;
  } else if (item.ty === 'perk') {
    const perkinfo = perks[item.perk];
    return <span><EntityName entity={item.entity} />의 특성이 발동합니다: {perkinfo?.msg ?? item.perk}<br />{item.targets && <EntityNameList entities={item.targets} />}</span>;
  } else if (item.ty === 'throw_door') {
    return <span><EntityName entity={item.entity} />이(가) 실내에 진입하며 {item.throwable.throwable_name} 수류탄을 던집니다.</span>;
  } else if (item.ty === 'throw_general') {
    return <span><EntityName entity={item.entity} />이(가) {item.targets.map((t) => t.name).join(',')}을(를) 노리고 {item.throwable.throwable_name} 수류탄을 던집니다.</span>;
  } else if (item.ty === 'throw_kill') {
    return <span><EntityName entity={item.entity} />이(가) 던진 {item.throwable.throwable_name} 수류탄이 <EntityName entity={item.target} />을(를) 무력화합니다.</span>;
  } else if (item.ty === 'throw_effect') {
    return <span><EntityName entity={item.entity} />이(가) 던진 {item.throwable.throwable_name} 수류탄이 <EntityName entity={item.target} />을(를) {item.effect_ty} 상태에 빠뜨립니다.</span>;
  } else if (item.ty === 'heal_start') {
    return <span><EntityName entity={item.entity} />이(가) <EntityName entity={item.target} />을(를) 치료합니다.</span>;
  } else if (item.ty === 'heal_self') {
    return <span><EntityName entity={item.entity} />이(가) 자기 자신을 치료합니다.</span>;
  } else if (item.ty === 'heal_risk') {
    return <span><EntityName entity={item.entity} />이(가) <EntityName entity={item.target} />을(를) 철저하게 치료하기 위해 일단 상처를 벌립니다.</span>;
  } else {
    console.error('unknown journal ty', item.ty);
  }
}

function JournalItem(props) {
  const { tick, item } = props;

  const seconds = Math.floor((tick - item.tick) / opts.tps);
  return <div>{seconds}초 전: <JournalText item={item} />{item.reps ? 'x' + item.reps : ''}</div>;
}

const ActionIndicatorIconUri = {
  low: '/img/overlay/Merc_Status_Caution.png',
  walk: '/img/overlay/Merc_Status_Normal.png',
  run: '/img/overlay/Merc_Status_Rapid.png',
};

function EntityView(props) {
  return <EntityOverContext.Consumer>
    {({ entityOver, onEntityOver }) => {
      const { entity } = props;

      let cls = 'sim-overlay-entity box';
      if (entityOver === entity) {
        cls += ' entity-over';
      }
      if (entity.state === 'dead') {
        cls += ' entity-dead';
      }

      let extra = '';
      if (entity.leader) {
        extra += ` leader=${entity.leader.name}`;
      }
      if (!isNaN(entity.risk_rank)) {
        extra += ` rr=${entity.risk_rank}`;
      }

      return <div className={cls}
        onMouseOver={() => onEntityOver(entity)}
        onMouseLeave={() => onEntityOver(null)}>
        <div className="sim-overlay-entity-img">
          <img alt="" className="sim-overlay-entity-img-background" src="/img/overlay/Merc_Idle.png" />
          {entityOver === entity ? (
            <div className="sim-overlay-entity-img-hover">
              <img alt="" src="/img/overlay/Merc_Selected.png" />
            </div>
          ) : null}
          <PortraitWrapper agent={{ name: entity.name }} className="list-portrait sim-overlay-entity-img-icon" />,
        </div>
        <div className="sim-overlay-entity-detail">
          <EntityName entity={entity} /> {entity.throwables && entity.throwables.length > 0 && <span>Throwables: {entity.throwables.length}</span>}
          <br />
          <ProgressBar cur={entity.life} max={entity.life_max} bgcolor='#ff4646' fgcolor='black' width={100} />
          <ProgressBar cur={entity.armor} max={entity.armor_max} bgcolor='#99a837' fgcolor='black' width={50} />
          <ProgressBar cur={entity.ammo} max={entity.firearm_ammo_max} bgcolor='#ffe400' fgcolor='black' width={50} />
          <br />
          {entity.firearm_ty} 사격대상=<EntityName entity={entity.aimtarget} />
          {extra}
        </div>
      </div>;
      // {entity.state}/{entity.movestate}/{entity.waypoint_rule.ty}
    }}
  </EntityOverContext.Consumer>;
}

function EntityDebugView(props) {
  const { sim, entity, debugRng } = props;
  const { onDebugCover, onDebugRoute, onDebugScore, onDebugDist, onDebugMST, onDebugEdge, onDebugTurn, onDebugReroute } = props;
  let dir = Math.floor(entity.dir * 180 / Math.PI).toString();

  let waypoint_dist = 0;
  let at_waypoint = false;
  if (entity.waypoint) {
    at_waypoint = entity.pos.eq(entity.waypoint.pos);
    waypoint_dist = entity.pos.dist(entity.waypoint.pos) / 10;
  }

  const printrules = entity.rules.map((r) => {
    const obj = { ty: r.ty };

    for (const key of ['area', 'target', 'initiator', 'goal', 'object']) {
      if (r[key]) {
        obj[key] = r[key].name;
      }
    }
    return obj;
  });
  printrules.reverse();

  return <EntityOverContext.Consumer>
    {({ entityOver, onEntityOver }) => {
      let cls = 'box';
      if (entityOver === entity) {
        cls += ' entity-over';
      }

      return <div className={cls} onMouseOver={() => onEntityOver(entity)}>
        <EntityName entity={entity} /> {entity.team} {entity.waypoint_rule.ty}/{entity.state} {formatCoord(entity.pos)} dir={dir}
        <br />
        life={entity.life} ammo={entity.ammo} speed={entity.movespeed.toFixed(2)} aim={entity.aimmult.toFixed(3)}
        /{entity.aimvar.toFixed(3)}
        /{entity.aimtarget?.name ?? 'none'} aimvar={entity.aimvar_hold.toFixed(5)}
        /{entity.aimvar_hold_max.toFixed(5)} fire={entity.allow_fire_control ? 'allowed' : 'not allowed'}
        /{sim.entityFireControl(entity) ? 'controlled' : 'not controlled'}
        /{sim.entityFireControlReady(entity, debugRng) ? 'ready' : 'not ready'}
        <br />
        perks={Object.keys(entity).filter((k) => k.startsWith('perk_')).filter((k) => entity[k] === true).map((k) => k.slice(5)).join(',')}
        <br />
        {printrules.map((r, i) => <p key={i}>{JSON.stringify(r)}</p>)}
        <div>
          debug {`waypoint=${waypoint_dist.toFixed(1)}m/${at_waypoint}`}
          <button onClick={() => onDebugCover(entity)}>cover</button>
          <button onClick={() => onDebugRoute(entity)}>route</button>
          <button onClick={() => onDebugScore(entity)}>score</button>
          <button onClick={() => onDebugDist(entity)}>dist</button>
          <button onClick={() => onDebugMST(entity)}>mst</button>
          <button onClick={() => onDebugEdge(entity)}>edge</button>
          <button onClick={() => onDebugTurn(entity)}>turn</button>
          <button onClick={() => onDebugReroute(entity)}>reroute</button>
        </div>
      </div>;
    }}
  </EntityOverContext.Consumer>;
}

function GoalView(props) {
  const { goal } = props;
  return <div className="box">
    {goal.name}: {JSON.stringify(goal.goalstate)}
  </div>;
}

const SCALES = [4, 2, 1];

// direct access to sim object
export class DebugSimView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      debugRng: new Rng(),

      debugScore: [],
      debugDist: [],
      debugScoreProp: 'score',
      debugScoreRule: 'covergoal',
    };
  }

  onDebugCover(entity) {
    if (entity.aimtarget === null) {
      return;
    }
    const { sim } = this.props;

    const cover = checkcover(entity.aimtarget.pos, entity.pos, sim.routes);
    console.log('cover', cover, entity);
  }

  onDebugRoute(entity) {
    if (entity.aimtarget === null) {
      return;
    }
    const { routes } = this.state;

    let cp = null;
    if (entity.waypoint_rule.ty === 'fire') {
      cp = this.choosefirepoint(entity.aimtarget);
    } else {
      cp = this.choosecoverpoint(entity);
    }

    if (cp === null) {
      console.log('onDebugRoute: cp === null');
      return;
    }

    const atcp = cp?.pos.eq(entity.pos);

    const path = routePathfind(routes, entity.pos, cp.pos, null, true);
    console.log('onDebugRoute', entity, cp, path, `atcp=${atcp}`);
  }

  onDebugScore(entity) {
    const { sim } = this.props;
    const { debugScoreRule } = this.state;
    // const items = this.choosefirepoint(entity.aimtarget, this.debugchoosepoint.bind(this));
    let items = [];
    if (!debugScoreRule) {
      console.error(`debugScoreRule not specified`);
      return;
    }

    const f = sim.debugchoosepoint.bind(sim);
    if (debugScoreRule === 'cover') {
      items = sim.choosecoverpoint(entity, 1000000, f);
    } else if (debugScoreRule === 'capture') {
      items = sim.choosecapturepoint(entity, null, f);
    } else if (debugScoreRule === 'covergoal') {
      items = sim.choosecovergoalpoint(entity, null, f);
    } else {
      const fname = `choose${debugScoreRule}point`;
      if (sim[fname]) {
        items = (sim[fname].bind(sim))(entity, f);
        console.log(items);
      } else {
        console.error(`unknown function: ${fname}`);
      }
    }

    this.setState({
      debugScore: items,
    });
  }

  onDebugDist(entity) {
    const { sim } = this.props;
    const dist = routePathfindAll(sim.routes, entity.pos, true);

    this.setState({ debugDist: dist });
  }

  onDebugMST(entity) {
    const { sim } = this.props;

    const out = sw('hostilityNetwork', () => hostilityNetwork(sim.routes, entity.pos));
    this.setState({ debugMST: out });
  }

  onDebugEdge(entity) {
    const { sim } = this.props;

    const out = sw('edge', () => coverEdges(sim.routes, entity.pos));
    this.setState({ debugMST: out });
  }

  onDebugTurn(entity) {
    const { sim } = this.props;
    sim.convertEntity(entity, 0);
  }

  onDebugReroute(entity) {
    const { sim } = this.props;
    sim.entityNextWaypoint0(entity);
  }

  renderDebugScoreRule() {
    const rules = ['fire', 'cover', 'explore', 'capture', 'covergoal'];

    const onDebugScoreRule = (r) => { this.setState({ debugScoreRule: r }); }
    return <>
      <p>debugrule</p>
      {rules.map((p, i) => <button key={i} onClick={() => onDebugScoreRule(p)}>{p}</button>)}
    </>;
  }

  renderDebugScoreProps() {
    const { debugScore } = this.state;
    if (!debugScore) {
      return null;
    }

    let props = [];
    for (const item of debugScore) {
      if (item.query) {
        props = Object.keys(item.query);
        break;
      }
    }

    const onDebugScoreProp = (p) => {
      this.setState({
        debugScoreProp: p,
      });
    }

    return <>
      <p>debugprops</p>
      {props.map((p, i) => <button key={i} onClick={() => onDebugScoreProp(p)}>{p}</button>)}
    </>;
  }

  renderJournal() {
    const { sim } = this.props;
    const { debugOptJournal } = this.state;
    const { journal } = sim;
    if (!debugOptJournal) {
      return null;
    }

    return <div>
      <p>journal</p>
      {journal.map((s, i) => <JournalItem key={i} tick={sim.tick} item={s} />)}

      <p style={{ wordWrap: "break-word" }}>
        stats={JSON.stringify(this.state.stats)}
      </p>
    </div>;
  }

  render() {
    const { sim } = this.props;
    const { entityOver, debugRng } = this.state;
    const { entities, goals } = sim;

    return <>
      <div>
        <p>debug</p>
        {this.renderDebugScoreRule()}
        {this.renderDebugScoreProps()}
      </div>
      <div>
        <p>info</p>
        <div className="sim-overlay-info-entities">
          {entities.map((e, i) =>
            <EntityDebugView key={i} entity={e} sim={sim}
              debugRng={debugRng}
              onDebugCover={this.onDebugCover.bind(this)}
              onDebugRoute={this.onDebugRoute.bind(this)}
              onDebugScore={this.onDebugScore.bind(this)}
              onDebugDist={this.onDebugDist.bind(this)}
              onDebugMST={this.onDebugMST.bind(this)}
              onDebugEdge={this.onDebugEdge.bind(this)}
              onDebugTurn={this.onDebugTurn.bind(this)}
              onDebugReroute={this.onDebugReroute.bind(this)}
              entityOver={entityOver}
            />)}
        </div>
        {goals.map((item, i) => <GoalView key={i} idx={i} goal={item} />)}
      </div>
      {this.renderJournal()}
    </>;
  }
}

function SideSelectView0(props) {
  const options = [
    { key: 'opt0', label: '옵션 0', },
    { key: 'opt1', label: '옵션 1', },
    { key: 'opt2', label: '옵션 2', },
    { key: 'opt3', label: '옵션 3', },
  ];
  const [selected, onSelect] = useState(options[0]);

  return <SideSelectView selected={selected.key} onSelect={onSelect} options={options} />;
}

function SideSelectView(props) {
  const { options, selected, onSelect, title } = props;
  const width = props.width ?? '100px';

  const [expand, setExpand] = useState(false);
  const curopt = _.find(options, (o) => o.key === selected);

  const buttons = [];
  {
    let style = {};
    if (curopt.color) {
      style.color = curopt.color;
    }
    buttons.push(<div key='cur' className='side-btn side-btn-selected' style={style} onClick={() => {
      setExpand(!expand);
    }}>{curopt.label}</div>);
  }

  if (expand) {
    let i = 0;
    for (let opt of options) {
      i += 1;
      let cls = `side-btn side-btn-${i}`;
      if (opt.key === selected) {
        cls += ' side-btn-selected';
      }
      let style = {};
      if (opt.color) {
        style.color = opt.color;
      }

      buttons.push(<div key={opt.key} className={cls} style={style} onClick={() => {
        onSelect(opt);
        setExpand(false);
      }}>{opt.label}</div>);
    }
  }

  const style = {
    '--side-select-width': width,
  };

  return <div className='side-select-root'>
    <div className='side-select' style={style}>
      {buttons}
    </div>
    {title && <span className='side-select-title'>{title}</span>}
  </div>;
}

function MeterView(props) {
  const { entities } = props;

  const trails = props.trails.filter((t) => entities.includes(t.source));
  const stat_rows = entities.map((entity, i) => {
    const trails1 = trails.filter((t) => t.source === entity);
    const kills = trails1.filter((t) => t.kill).length;
    const dealt = _.sum(trails1.map((t) => t.damage));

    return {
      entity,
      kills,
      dealt,
      i,
    };
  });
  stat_rows.sort((a, b) => {
    if (a.dealt !== b.dealt) {
      return b.dealt - a.dealt;
    }
    if (a.kills !== b.kills) {
      return b.kills - a.kills;
    }
    return a.i - b.i;
  });
  const dealt_max = stat_rows[0].dealt;

  return <div>
    {stat_rows.map(({ entity, kills, dealt }, i) => {
      const text = `kills=${kills} dealt=${dealt.toFixed(0)}`;
      return <div key={i} className="sim-overlay-stat-row">
        <EntityName className="sim-overlay-stat-row-name" entity={entity} />
        <ProgressBar cur={dealt} max={dealt_max} width={200} text={text} />
      </div>;
    })}
  </div>;
}

export class SimView extends React.Component {
  static defaultProps = {
    canvasScale: 1.0,
  }

  constructor(props) {
    super(props);
    const { debug } = props;

    this.canvasRef = React.createRef();
    this.winCounterRef = React.createRef();
    this.timer = null;

    this.img_blood = document.createElement('img');
    this.img_blood.src = '/sim/blood.png';

    this.img_tile = document.createElement('img');
    this.img_tile.src = '/sim/Textures-16.png';

    this.img_icons = document.createElement('img');
    this.img_icons.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAA8FJREFUeF7tW+1ywzAIW97/obtLb+4xVSDhOLt07f4t8QcIELKzbV9v/rO9uf9fTwDcbrfbDsq2bS1w2LzZtf4yKCkAXRCGs3Eee1Y5F8dn46rAzMwvAeiAgJvvhrJnCgDloHqvsgfnSwBcEBz01Vr7GspB9f4UAJTh+3sXgGqtSwOgQOgAkK31AeCnA1Vp/O9LoFvDcbyThZcnwcsB4AqiFW1QkaljS5UFbP6lhNCIPnPCcX5m/mWlcFdFYum481t6X9XnK77/APCKUVtp82UzoHuQmgUlBeAoE88alN0hVHcLWetzOgcFgGnyv7jcUGeBoRMcOew4fz+TsPaRTT4ThOi8amEZUDP2/QJgRQSOpP64RIkByLIRgxSd75SETYJncgJGXwGApeDwQ5bVrQxQqZmpMVWPqwCYuUd8AOA655QJavIOANmBiKW8S4ZVSdwBOCu9XVKqQM0C48xhAKFNrRLotKFuOjoOjas0lYVZxIdNEZgnAI5cWlbHUedOsZPSqqxcPrKPwwr16jiqnI/AKce6dsQSty5EVnKCywERvGzOzFpORto6YFbgzM7DOlaZMbvPZQGYdag77wNAF7FXGe9qm19K0K0zl4lXEpdjW6f/PzQBMvDYKNPnHefZWm4GdUnQtQv3T+8DGACdTaoDjgPCCgCc8016IxTFSzeVGVAd8JgWUSqRHaERAGYDlcKomWejEYFTAKj6fUrd8DdMau0q45a3QUx9zCR13eaUB0rrbE+WATj3AwDT4bMlgKnIuCNL10uVABrukuARAFacJvHkN1UCw4luG+wQkTO2S7x48hs3zDGbWSDTG6Gsj1cpXF1VZ4IrI71ZAFwSfVKCTlQiys7VdWWM2u8IADGLMQMwSEu6QJfAkGS7UXPHIweweUsAcA264rgSAJf9r+iYa5P9edw5jiITV/LVNfDsceWtMGp5lJHMOMUHHSCr9WcldUmCR4WMElCdkmJdQJGa6ixDKNEPI87krA0yEYKqjP3eibBynjmXrW8DkEWsEkNOzVbpi/qCti74dx5VdplN+16Pj6PV11fmcPYsbhbXZ8/jM1Z+TM6ySB8CIFNclRR2nImEqWrfcR4JGO2rMiUD6J4BleRkhrsS1R3nRHTYmcnvysHIPawtlyVQRTqLgGpzGZlV62UZsqIN0+8CDqKqD7snQ+QBRXoV9yjwWbc67cNIpwTQMCa4Kh45RIKKiSvyqVpeFwAsjc78ZQCgWImoKybPxJB67r7vgO1okRHYJYehjNgUSbk16zo0M+7tj8Pf2XhojC5uzXEAAAAASUVORK5CYII=';
    this.img_icons.sheet = {
      'walk': [0, 0],
      'run': [1, 0],
      'obstruct': [2, 0],
      'cover': [3, 0],
      'reload': [0, 1],
      'crawl': [1, 1],
      'explore': [2, 1],
      'idle': [3, 1],
      'gather': [0, 2],
      'alert': [1, 2],
      'heal': [2, 2],
      'granade': [3, 2],
      'aim': [0, 3],
      'skull': [1, 3],
      'blind': [2, 3],
      'briefcase': [3, 3],
    };

    // 현재 webview가 UE5에 embed되어 있는지 확인합니다.
    var embed = false;
    var paused = false;
    if (!props.noembed && window.ue) {
      embed = true;
      paused = true;
    }

    this.state = {
      sim: null,
      res: -1,
      seed: 0,
      simtps: opts.SIMTPS,

      paused,
      embed,
      ext: null,

      debugOptObstacles: true,
      debugOptStructs: false,
      debugOptPoints: false,
      debugOptNet: false,
      debugOptRisk: false,
      debugOptRiskDry: false,
      debugOptVisArc: false,
      debugOptFog: !debug,
      debugOptWasmVis: false,
      debugOptWasmNav: false,
      debugOptWasmThreat: false,
      debugOptWaypoints: true,
      debugOptPerception: false,
      debugOptKnowledge: false,
      debugOptThrow: false,
      debugOptEdges: false,
      debugOptAutoscroll: false,
      debugOptBubble: !embed,
      debugOptJournal: false,

      debugOptOverlay: true,

      entityOver: null,

      scale: 1,

      stats: stats_populate(),

      showStats: false,

      debugMST: [],
      debugGrid: null,

      // in-game player controls
      controlWin: false,
      controlWithdraw: false,

      canvasOffset: new v2(0, 0),

      colors: COLORS_ALT,

      currentRenderGroup: 0,
    };

    this.keyDown = this.onKeyDown.bind(this);
    this.keyUp = this.onKeyUp.bind(this);
    this.resize = this.onResize.bind(this);
    this.mouseDown = this.onMouseDown.bind(this, "click");
    this.mouseMove = this.onMouseMove.bind(this, "click");
    this.mouseUp = this.onMouseUp.bind(this, "click");
    this.touchStart = this.onMouseDown.bind(this, "touch");
    this.touchMove = this.onMouseMove.bind(this, "touch");
    this.touchEnd = this.onMouseUp.bind(this, "touch");

    this.last_ms = Date.now();

    this.onTimer = () => {
      let { last_ms } = this;
      let { simtps, stats } = this.state;

      if (simtps > 0) {
        const now = Date.now();
        const dt_max = 1000 / simtps * 3;
        if (now - last_ms > dt_max) {
          last_ms = now - dt_max;
        }

        let idx = 0;
        while (last_ms < now) {
          last_ms += 1000 / simtps;
          if (this.onTick() >= 0) {
            break;
          }
          stats = stats_populate();
          idx += 1;
        }
      } else {
        let start_ms = Date.now();
        while (start_ms + 100 > Date.now()) {
          if (this.onTick() >= 0) {
            break;
          }
          stats = stats_populate();
        }
      }

      this.startTimer();
      this.last_ms = last_ms;
      this.onAutoscroll();
      this.setState({ stats });
    };


    // Unreal Object is Exposed in window.ue.{name}

    // All Name's Unreal Object, Function is been lowercased. Be careful about this

    // window.simrestart = () => {
    //   console.log("Sim Restart");
    //   const { sim } = this.state;
    //   this.setState(this.newSimState(this.props, this.props.seed), () => {
    //     sim.free();
    //   });
    //   window.ue.connection.startcombat("ASDFASDF");
    // };
  }

  componentDidMount() {
    const { paused, embed } = this.state;
    if (!paused) {
      this.startTimer();
    }

    document.addEventListener('keydown', this.keyDown);
    document.addEventListener('keyup', this.keyUp);
    window.addEventListener('resize', this.resize);

    document.addEventListener('mousedown', this.mouseDown);
    document.addEventListener('mousemove', this.mouseMove);
    document.addEventListener('mouseup', this.mouseUp);
    document.addEventListener("touchstart", this.touchStart, { passive: false });
    document.addEventListener("touchmove", this.touchMove, { passive: false });
    document.addEventListener("touchend", this.touchEnd, { passive: false });

    if (this.state.sim === null) {
      // TODO: bypass react 18 remounting
      const s = this.state;
      const { seed, sim } = this.newSimState(this.props, this.props.seed);
      s.seed = seed;
      s.sim = sim;
      this.setState({ seed, sim }, () => {
        this.renderCanvas();
      });
    }

    if (embed) {
      window.simview = this;
      this.onTick();
    }
  }

  componentWillUnmount() {
    this.stopTimer();
    document.removeEventListener('keydown', this.keyDown);
    document.removeEventListener('keyup', this.keyUp);
    window.removeEventListener('resize', this.resize);

    document.removeEventListener('mousedown', this.mouseDown);
    document.removeEventListener('mousemove', this.mouseMove);
    document.removeEventListener('mouseup', this.mouseUp);
    document.removeEventListener("touchstart", this.touchStart);
    document.removeEventListener("touchmove", this.touchMove);
    document.removeEventListener("touchend", this.touchEnd);

    if (window.simview === this) {
      window.simview = null;
    }
  }

  newSimState(props, seed) {
    const { embed } = this.state;
    if (isNaN(seed)) {
      seed = Rng.randomseed();
    }
    const simprops = { ...props, seed };

    if (embed) {
      window.ue.connection.onsimprops?.(JSON.stringify(simprops));
    }

    if (DEBUG_SERIALIZE) {
      simprops = JSON.parse(JSON.stringify(simprops));
    }

    const sim = Simulator.create({ ...simprops, m: this.props.m });
    return {
      seed,
      sim,
      simprops,
      res: -1,
      entityOver: null,
      mousepos: null,
    };
  }

  startTimer() {
    // do not start timer on embed
    if (this.state.embed) {
      return;
    }

    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.last_ms = Date.now();
    this.timer = setTimeout(this.onTimer, 0);
  }

  stopTimer() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  componentDidUpdate() {
    this.renderCanvas();
  }

  // canvas-related shortcuts
  onKeyDownCanvas(ev) {
    const { paused, scale, simtps } = this.state;

    if (!ev.ctrlKey) {
      for (let i = 0; i < opts.SIMTPS_OPTS.length; i++) {
        if (ev.key === (i + 1).toString()) {
          this.startTimer();
          this.setState({ simtps: opts.SIMTPS_OPTS[i], paused: false });
        }
      }
    }

    // embed인 경우 simulation tick은 ue에서 호출합니다.
    if (ev.key === ' ') {
      ev.preventDefault();
      if (paused) {
        this.startTimer();
      } else {
        this.stopTimer();
      }
      this.setState({ paused: !paused });
    }

    if (ev.key === 'z') {
      const idx = SCALES.indexOf(scale);
      this.setState({
        scale: SCALES[(idx + 1) % SCALES.length],
      });
      // TODO
    } else if (ev.key === 's') {
      // single step
      ev.preventDefault();
      this.onTick();
    } else if (ev.key === 'Tab') {
      ev.preventDefault();
      if (this.state.embed) {
        return;
      }

      let idx = opts.SIMTPS_OPTS.indexOf(simtps);
      this.setState({
        simtps: opts.SIMTPS_OPTS[(idx + 1) % (opts.SIMTPS_OPTS.length)],
      });
    }
  }

  onKeyUp(ev) {
    const { embed } = this.state;
    if (ev.which === 9) {
      if (!embed) {
        this.setState({ showStats: false });
      }
      ev.preventDefault();
      return;
    }
  }

  onResize() {
    this.setState({});
  }

  onKeyDown(ev) {
    const { sim, paused, embed } = this.state;
    const { nocontrol, onReload } = this.props;

    if (!embed) {
      this.onKeyDownCanvas(ev);
    }

    if (ev.key === '0') {
      this.stopTimer();
      this.setState({ paused: true });
    }

    if (ev.which === 9) {
      if (!embed) {
        this.setState({ showStats: true });
      }
      ev.preventDefault();
      return;
    }

    if (!sim || !this.props.debug) {
      return;
    }

    if (ev.key === 'Escape') {
      this.setState({ entityOver: null });
      return;
    }

    if (ev.key === 't') {
      const { sim } = this.state;
      const key = 'throwable';
      const allyEntities = sim.entities.filter((e) => e.team === 0);
      const spawnareas = _.uniq(allyEntities.map((e) => e.spawnarea));
      for (const spawnarea of spawnareas) {
        let cur = sim.controlGet(spawnarea, key);
        sim.controlSet(spawnarea, key, !cur);
      }
      this.setState({ sim });
    }

    if (onReload) {
      let seed = null;
      if (ev.key === 'r' && !(ev.metaKey || ev.ctrlKey)) {
        // restart with new seed
        seed = Rng.randomseed();
      } else if (ev.key === 'R') {
        // restart with same old seed
        seed = this.state.seed;
      }

      if (seed !== null) {
        ev.preventDefault();
        if (paused) {
          this.startTimer();
        }

        const nextProps = onReload(seed);

        this.setState({
          ...this.newSimState(nextProps, seed),
          rng: new Rng(seed),
        }, () => {
          sim.free();
        });
      }
    }
  }

  viewSize() {
    const { viewWidth, viewHeight } = this.viewSize0();
    const renderScale = this.renderScale();
    return {
      viewWidth: viewWidth * renderScale,
      viewHeight: viewHeight * renderScale,
    };
  }

  viewSize0() {
    if (this.state.embed) {
      return {
        viewWidth: 360,
        viewHeight: 360,
      };
    }

    const { sim } = this.state;
    const DEFAULTSIZE = 2048;
    let viewWidth = this.props.viewWidth ?? DEFAULTSIZE;
    let viewHeight = this.props.viewHeight ?? DEFAULTSIZE;

    if (sim) {
      const { width, height } = sim.world;
      viewWidth = Math.min(viewWidth, width);
      viewHeight = Math.min(viewHeight, height);
    }

    return {
      viewWidth,
      viewHeight,
    };
  }

  canvasCursor(type, e) {
    const canvas = this.canvasRef.current;
    if (!canvas) {
      return;
    }
    const rect = canvas.getBoundingClientRect();
    if (type === "touch") {
      e = e.touches[0];
    }

    let x = e.clientX - rect.left;
    let y = e.clientY - rect.top;

    const { viewWidth, viewHeight } = this.viewSize();

    if (x < 0 || y < 0 || x > viewWidth || y > viewHeight) {
      return null;
    }
    x -= viewWidth / 2;
    y -= viewHeight / 2;

    return new v2(x, y);
  }

  onMouseDown(type, e) {
    const cursor = this.canvasCursor(type, e);
    if (!cursor) {
      return;
    }
    e.preventDefault();
    const { canvasOffset } = this.state;
    this.setState({
      click: cursor.add(canvasOffset),
    })
  }

  renderScale() {
    let renderScale = 1;
    if (typeof window !== 'undefined') {
      renderScale = window.devicePixelRatio;
    }
    return renderScale;
  }

  clampCanvasOffset(offset) {
    const { sim, scale } = this.state;

    const { width, height } = sim.world;
    const { viewWidth, viewHeight } = this.viewSize0();
    const maxX = width - viewWidth / scale;
    const maxY = height - viewHeight / scale;
    return new v2(
      clamp(offset.x, 0, maxX),
      clamp(offset.y, 0, maxY),
    );
  }

  onMouseMove(type, e) {
    const { click } = this.state;
    if (!click) {
      return
    }

    const cursor = this.canvasCursor(type, e);
    if (!cursor) {
      return;
    }

    const nextOffset = click.sub(cursor);
    this.setState({
      canvasOffset: this.clampCanvasOffset(nextOffset),
    })
  }

  onMouseUp(_e) {
    this.setState({
      click: null
    })
  }

  renderCanvas() {
    const canvas = this.canvasRef.current;
    if (!canvas) {
      return;
    }

    const ctx = canvas.getContext('2d');

    const renderScale = this.renderScale();
    const resources = {
      blood: this.img_blood,
      icons: this.img_icons,
    };
    renderCanvas(ctx, { ...this.state, renderScale }, { ...this.props, ...this.viewSize0() }, resources);
  }

  onEntityOver(e) {
    this.setState({ entityOver: e });
  }

  projectEvent(e) {
    const c = this.canvasRef.current;
    const rect = c.getBoundingClientRect();
    const x = Math.floor(e.clientX - rect.left);
    const y = Math.floor(e.clientY - rect.top);
    return new v2(x, y);
  }

  canvasMouseMove(e) {
    const pos = this.projectEvent(e);

    this.setState({ mousepos: pos });
  }

  restart(cb) {
    this.setState(this.newSimState(this.props, this.props.seed), cb);
  }

  onTick() {
    const { sim, controlWin, controlWithdraw, embed, entityOver, scale } = this.state;
    let { res } = this.state;
    if (res !== -1) {
      return;
    }

    let first = false;
    if (!this.started) {
      first = true;
      this.started = true;
    }

    res = sim.onTick();
    if (controlWin) {
      res = 0;
    }
    if (controlWithdraw) {
      res = 1;
    }

    if (true) {
      let pos = new v2(0, 0);
      let count = 0;
      for (const entity of sim.entities) {
        if (entity.team !== 0 || entity.state === 'dead') {
          continue;
        }
        pos = pos.add(entity.pos);
        count += 1;
      }
      pos = pos.mul(1 / count);

      // pos가 (-width/2, -height/2)일 때 offset이 0이어야 함
      const { width, height } = sim.world;
      const { viewWidth, viewHeight } = this.viewSize0();
      const canvasOffset = this.clampCanvasOffset(new v2(
        Math.round(pos.x + width / 2 - viewWidth / scale / 2),
        Math.round(pos.y + height / 2 - viewHeight / scale / 2)
      ));

      this.setState({
        canvasOffset,
      });
    }

    const ext = serializeState(sim, first, res, entityOver);
    ext.paused = this.state.paused;
    ext.mute_bgm = this.props.mute_bgm ?? false;
    const noobs = this.props.noobs ?? false;
    if (noobs) {
      for (const obstacle of ext.obstacles) {
        obstacle.imported = true;
      }
    }

    if (embed) {
      // 페이지가 UE5에 embed되어 있는 경우, 시뮬레이션 상태를 UE5에 보냅니다
      window.ue.connection.onsimulationdata(JSON.stringify(ext));
      // window.ue.connection.onrenderdata(JSON.stringify(serializeState(sim, first, res, entityOver)));
    }
    this.setState({ ext });

    if (res >= 0) {
      if (!embed) {
        this.onFinish(res);
      } else {
        // 이전 빌드 hang하지 않도록 조치
        setTimeout(() => {
          this.onFinish(res)
        }, 3000);
      }

      // TODO: ue fix
      this.state.res = res;
      this.stopTimer();
    }

    this.setState({ sim, res });
    return res;
  }

  onFinish(res) {
    if (res === undefined) {
      res = this.state.res;
    }
    this.props.onFinish(res);
  }

  onAutoscroll() {
    const { sim, debugOptAutoscroll } = this.state;
    if (!debugOptAutoscroll) {
      return;
    }

    let pos = new v2(0, 0);
    let count = 0;
    for (const entity of sim.entities) {
      if (entity.team !== 0 || entity.state === 'dead') {
        continue;
      }
      pos = pos.add(entity.pos);
      count += 1;
    }
    pos = pos.mul(1 / count);

    const c = this.canvasRef.current;
    const scroll = c.offsetTop + sim.world.height / 2 + pos.y - document.documentElement.clientHeight / 2;
    window.scrollTo(0, scroll);
  }

  renderControls() {
    const { debug } = this.props;
    const controlButtons = [];
    const debugKeys = [];

    for (const key of Object.keys(this.state)) {
      const prefix = 'control';
      if (key.indexOf(prefix) !== 0) {
        continue;
      }
      if (!debug && debugKeys.includes(key)) {
        continue;
      }

      let cls = '';
      if (this.state[key]) {
        cls = 'selected';
      }
      controlButtons.push(<button className={cls} key={key}
        onClick={() => this.setState({ [key]: !this.state[key] })}>debug: {key.substring(prefix.length).toLowerCase()}</button>);
    }

    controlButtons.push(<button key="retreat" onClick={() => {
      const { sim } = this.state;
      for (const entity of sim.entities) {
        if (entity.team !== 0) {
          continue;
        }
        entity.push_rule(sim.tick, { ty: 'capture' });
      }
    }}>retreat</button>);

    return <div className='sim-overlay-debug-controls'>{controlButtons}</div>;
  }

  controlSet(key, value) {
    const { sim } = this.state;
    sim.controlSet(0, key, value);
    this.setState({ sim });
  }

  renderPlayerStats() {
    const { sim } = this.state;
    const { morale, uncover } = sim.playerstats;

    const levels_morale = [
      { value: 81, label: '천하무적', color: '#ffd700' },
      { value: 61, label: '자신만만', color: '#1f4d90' },
      { value: 41, label: '안정', color: '#6cace4' },
      { value: 21, label: '불안', color: '#fcd67a' },
      { value: 1, label: '흔들림', color: '#726e6d' },
      { value: 0, label: '패주', color: '#575757' },
    ];
    const level_morale = levels_morale.find((e) => morale >= e.value);

    const levels_uncover = [
      { value: 100, label: '전면전', color: '#8b0000' },
      { value: 75, label: '비상', color: '#ff0000' },
      { value: 50, label: '소란', color: '#ff7f50' },
      { value: 25, label: '경계', color: '#8c7293' },
      { value: 0, label: '은밀', color: '#4b3d5c' },
    ];
    const level_uncover = levels_uncover.find((e) => uncover >= e.value);
    const style = {
      display: 'inline-block',
    };

    return <div>
      <div className="sim-overlay-stat-row">
        <span className="sim-overlay-stat-row-name">사기: <span style={{ ...style, color: level_morale.color }}>{level_morale.label}</span></span>
        <ProgressBar width={200} cur={morale} max={100} bgcolor={level_morale.color} />
      </div>
      {/* <div className="sim-overlay-stat-row">
        <span className="sim-overlay-stat-row-name">발각: <span style={{ ...style, color: level_uncover.color }}>{level_uncover.label}</span></span>
        <ProgressBar width={200} cur={uncover} max={100} bgcolor={level_uncover.color} />
      </div> */}
    </div>;
  }

  renderAreaControls(spawnarea) {
    const { sim } = this.state;
    const { nocontrol } = this.props;
    const t = this;

    function renderButton(key, value, title) {
      let cur = sim.controlGet(spawnarea, key);
      if (value === undefined) {
        value = !cur;
      }

      return <DivBtn key={title ?? key} name={key} disabled={nocontrol} title={title}
        onClick={() => { sim.controlSet(spawnarea, key, value); t.setState({ sim }); }} />
    }

    const renderControlButton = (key) => {
      const prefix = 'control';
      return <DivBtn key={key} name={key} title={`dbg: ${key.substring(prefix.length).toLowerCase()}`}
        onClick={() => t.setState({ [key]: !this.state[key] })} />;
    }

    // state mgmt
    const entities = sim.entities.filter((e) => e.spawnarea === spawnarea);

    let state = sim.areaState(spawnarea);

    const actionbtns = [];
    const clearbtn = renderButton('reorg', false, '탐색 재시작');

    if (['engage', 'reorg0', 'explore'].includes(state)) {
      // actionbtns.push(renderButton('reorg', true));
    } else if (state === 'reorg0') {
      actionbtns.push(clearbtn);
    }
    actionbtns.push(renderButton('withdraw'));
    actionbtns.push(renderControlButton('controlWin'));

    const renderBoolButton = (key, label, opts) => {
      const opt = opts.find((o) => o.value === sim.controlGet(spawnarea, key));

      return <SideSelectView title={label} options={opts}
        selected={opt.key}
        onSelect={(opt) => {
          sim.controlSet(spawnarea, key, opt.value);
          t.setState({ sim });
        }} />;
    };

    const coveropts = [
      { key: 'engage', value: false, label: '공격적', color: 'red' },
      { key: 'cover', value: true, label: '방어적', color: 'blue' },
    ];
    actionbtns.push(renderBoolButton('cover', '교전 정책', coveropts));


    // const searchopts = [
    //   { key: 'search', value: true, label: '수색', color: 'red' },
    //   { key: 'nosearch', value: false, label: '속행', color: 'blue' },
    // ];
    // actionbtns.push(renderBoolButton('search', '수색 정책', searchopts));


    const healopts = [
      { key: 'forbid', value: -1, label: '금지', color: 'red' },
      { key: 'conservative', value: 0.5, label: '보수적', color: 'green' },
      { key: 'aggressive', value: 1, label: '적극적', color: 'blue' },
    ];
    const healopt = healopts.find((e) => e.value === sim.controlGet(spawnarea, 'heal'));

    actionbtns.push(<SideSelectView title='회복 정책' options={healopts}
      selected={healopt.key}
      onSelect={(opt) => {
        sim.controlSet(spawnarea, 'heal', opt.value);
        t.setState({ sim });
      }} />);


    const throwopts = [
      { key: 'forbid', value: false, label: '금지', color: 'red' },
      { key: 'allow', value: true, label: '허용', color: 'green' },
    ];
    actionbtns.push(renderBoolButton('throwable', '투적물 정책', throwopts));


    const statename = {
      'engage': '교전중',
      'explore': '탐색중',
      'reorg0': '엄폐중',
      'reorg': '재정비중',
    }[state];

    return <>
      <p className={`sim-overlay-state sim-overlay-state-${state}`}>상태: {statename}</p>
      <div className='sim-overlay-controls'>
        {actionbtns}
      </div>
      <hr />
      {this.renderPlayerStats()}
      <hr />
      <MeterView entities={entities} trails={sim.trails} />
      <hr />
      <div>
        {/* <span>전리품 현금=${sim.playerstats.loot.resource}</span> */}
        <div>
          {sim.playerstats.loot.inventory.map((i) => {
            if (i.ty === 'firearm') {
              const { firearm_ty, firearm_rate, firearm_name } = i.firearm;
              return <span key={i}>{firearm_ty} 🟊{firearm_rate}: {firearm_name}</span>;
            }
            return null;
          })}
        </div>
      </div>
      <hr />
    </>;
  }

  renderUI() {
    const { sim, colors } = this.state;
    const { debug } = this.props;

    const debugButtons = [];

    if (debug) {
      let cls = '';
      let next = COLORS_ALT;
      if (colors !== COLORS) {
        cls = 'selected';
        next = COLORS;
      }
      debugButtons.push(<button key='color' className={cls} onClick={(ev) => {
        ev.preventDefault();
        this.setState({ colors: next });
      }}>altcolor</button>);
    }

    for (const key of Object.keys(this.state)) {
      const prefix = 'debugOpt';
      if (key.indexOf(prefix) !== 0) {
        continue;
      }
      if (!debug) {
        continue;
      }

      let cls = '';
      if (this.state[key]) {
        cls = 'selected';
      }
      debugButtons.push(<button className={cls} key={key}
        onClick={() => this.setState({ [key]: !this.state[key] })}>{key.substring(prefix.length).toLowerCase()}</button>);
    }

    let controls = null;
    let extras = null;
    if (debug) {
      controls = <div>
        debug
        <button onClick={this.onTick.bind(this)}>step</button>
        {debugButtons}
        <button onClick={() => this.setState({ entityOver: null })}>unselect</button>
      </div>;
      extras = <DebugSimView sim={sim} />;
    } else {
      controls = <div>{debugButtons}</div>;
    }

    return { controls, extras };
  }

  renderAction(action) {
    const { sim } = this.state;
    const { tick } = sim;
    const { queue_at, execute_at, trigger } = action;

    const max = (execute_at - queue_at) / sim.tps;
    const cur = (execute_at - tick) / sim.tps;

    let body = `알려지지 않은 트리거: ${JSON.stringify(trigger)}`;

    if (trigger.action === 'push_rule' && trigger.actionrules[0].ty === 'idle') {
      body = `교전을 인지합니다`;
    }
    if (trigger.action === 'push_rule' && trigger.actionrules[0].ty === 'explore') {
      body = `침입에 대응합니다`;
    }
    if (trigger.action === 'spawn_entity') {
      body = `증원이 도착합니다`;
    }

    return <div key={body} className="box">
      <ProgressBar cur={cur} max={max} bgcolor='white' fgcolor='black' width={100} />
      {body}
    </div>;
  }

  renderPrompt(prompts) {
    const { nocontrol } = this.props;
    const { sim } = this.state;
    const { tick } = sim;
    const { prompt_options, queue_at, expire_at } = prompts;

    const max = (expire_at - queue_at) / sim.tps;
    const cur = (expire_at - tick) / sim.tps;

    return <div className="box" key={queue_at}>
      <ProgressBar cur={cur} max={max} bgcolor='white' fgcolor='black' width={100} />

      {prompt_options.map((p, i) => {
        let { title, default: d } = p;
        if (d) {
          title += " (default)";
        }
        return <button key={i} disabled={nocontrol} onClick={() => {
          sim.controlSelectPrompt(prompts, i);
        }}>{title}</button>;
      })}
    </div>
  }

  getSpawnAreas() {
    const { sim } = this.state;
    const { entities } = sim;
    const allyEntities = entities.filter((e) => e.team === 0);
    return _.uniq(allyEntities.map((e) => e.spawnarea));
  }

  onChangeRenderGroup(i) {
    this.setState({ currentRenderGroup: i });

  }

  embedWebview() {
    return this.state.embed || this.props.embed;
  }

  renderOverlayGroup(areas, i) {
    const { sim, entityOver, currentRenderGroup } = this.state;
    const { entities } = sim;
    const area = areas[i];

    const prompts = sim.prompts.filter((p) => p.area === area);

    const { viewWidth, viewHeight } = this.viewSize();
    const groupEntities = entities.filter((e) => e.team === 0 && e.spawnarea === area);
    let canvas = null;
    if (this.embedWebview()) {
      canvas = <canvas className="sim-overlay-canvas-container"
        width={viewWidth}
        height={viewHeight}
        ref={this.canvasRef}
        onMouseMove={this.canvasMouseMove.bind(this)}
      ></canvas>;
    }

    const journal = this.getJournal();

    const spawnareas = this.getSpawnAreas();

    return <div key={area} className="sim-overlay-group">
      <div className="sim-overlay-radio-base">
        {canvas}
        {this.renderAreaControls(area)}
      </div>

      <div className="sim-overlay-group-tabs">
        {spawnareas.map((area, i) => {
          let cls = 'sim-overlay-group-tab';
          if (i === this.state.currentRenderGroup) {
            cls += ' selected';
          }
          return <div key={i} className={cls} onClick={this.onChangeRenderGroup.bind(this, i)}>group #{area}</div>;
        })}
      </div>
      {prompts.map(this.renderPrompt.bind(this))}
      <div className="sim-overlay-entities-container">
        {groupEntities.map((e, i) =>
          <EntityView key={i} entity={e} sim={sim}
            entityOver={entityOver}
          />)}
      </div>

      <div className="sim-overlay-journals">
        {journal.map((s, i) => <JournalItem key={i} tick={sim.tick} item={s} />)}
      </div>

    </div>;
    /*
      <div>{this.renderControls()}</div>
      */
  }

  getJournal() {
    const { sim } = this.state;
    const logs = sim.journal.filter((e) => {
      if (e.ty === 'fire' && e.kill) {
        return true;
      }
      if (e.ty === 'perk') {
        return true;
      }
      if (e.ty.includes('throw') || e.ty.includes('heal')) {
        return true;
      }
      return false;
    });
    const journal = logs.slice(Math.max(0, logs.length - 10)).reverse();
    return journal;
  }

  render() {
    const { sim, entityOver, debugOptOverlay, showStats, embed, res, currentRenderGroup, ext } = this.state;
    if (!sim) {
      return;
    }

    if (embed) {
      if (res >= 0) {
        return;
      }
    }

    const { controls, extras } = this.renderUI();

    const { viewWidth: viewWidth0, viewHeight: viewHeight0 } = this.viewSize0();
    const { viewWidth, viewHeight } = this.viewSize();

    let statOverlay = null;
    if (showStats) {
      statOverlay = <div className="overlay">
        <StatView sim={sim} />
      </div>;
    }

    let overlay = null;
    if (debugOptOverlay) {
      let overlayCls = 'sim-overlay'
      if (embed) {
        overlayCls = 'sim-overlay-embed';
      }

      overlay = <>
        {/* <div className={`${overlayCls}-debug`}>
          <div>
            {journal.map((s, i) => <JournalItem key={i} tick={sim.tick} item={s} />)}
          </div>
          <div>{this.renderControls()}</div>
        </div> */}
        <div className={overlayCls}>

          {sim.actions.map(this.renderAction.bind(this))}
          {this.renderOverlayGroup(this.getSpawnAreas(), currentRenderGroup)}
        </div>
      </>;
    }

    let canvas = null;
    if (!this.embedWebview()) {
      canvas = <canvas
        width={viewWidth}
        height={viewHeight}
        style={{ width: viewWidth0, height: viewHeight0 }}
        ref={this.canvasRef}
        onMouseMove={this.canvasMouseMove.bind(this)}
      ></canvas>;
    }

    let simoverlay = null;
    if (!embed && SHOW_OVERLAY) {
      simoverlay = <SimOverlay data={ext} />;
    }

    return <>
      <EntityOverContext.Provider value={{ entityOver, onEntityOver: this.onEntityOver.bind(this) }}>
        {controls}
        {overlay}
        {canvas}
        {extras}
      </EntityOverContext.Provider>
      {simoverlay}
      {statOverlay}
    </>;
  }
}

function DivBtn(props) {
  const { name, onClick, disabled } = props;
  let { title } = props;
  if (!title) {
    const data_descr = ordersDescr.find(d => d.key === name);
    title = data_descr.title;
  }
  return <button disabled={disabled} onClick={() => {
    if (!disabled) {
      onClick();
    }
  }}>{title}</button>;
}
