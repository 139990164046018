import './ProgressBar.css';
import { L } from './localization.mjs';

export function round(v, digits) {
  if (v === (0 | v)) {
    return v;
  }
  return v.toFixed(digits);
}
export function roundsign(v, digits) {
  v = round(v, digits);
  if (v > 0) {
    return `+${v}`;
  }
  return v;
}

export function ProgressBar(props) {
  let { cur, max, width, label, date, text, title, classname } = props;

  const cap = props.cap ?? max;
  const bonus = props.bonus ?? 0;
  let growth = props.growth ?? 0;

  const height = props.height ?? 20;

  const digits = props.digits ?? 1;

  const lines = props.lines ?? [];

  let _text = '';
  if (text || text === '') {
    _text = text;
  } else if (date) {
    _text = L('loc_dynamic_string_worldmap_work_progress', { value: round(cur / 24, digits), max: round(cap / 24, digits) });
  } else if (bonus) {
    _text = `${round(cur, digits)}${roundsign(bonus, digits)}/${round(cap, digits)}`;
  } else if (growth) {
    _text = `${round(cur, digits)} -> ${round(cur + growth, digits)}/${round(cap, digits)}`;
  } else {
    _text = `${round(cur, digits)}/${round(cap, digits)}`;
  }

  // swap growth and cur
  if (growth < 0) {
    const growth_prev = growth;

    cur = cur + growth;
    growth = -growth_prev;
  }
  else {
    growth = Math.min(growth, max - cur);
  }


  let ratio = cur * 100 / max;
  if (max === 0) {
    ratio = 0;
  }
  const fillwidth = `${(ratio).toFixed(2)}%`;

  let bonusbar = null;
  if (bonus) {
    const fillwidthbonus = `${Math.floor(100 * (cur + bonus) / max)}%`;
    bonusbar = <div className="bonus" style={{ position: 'absolute', width: fillwidthbonus, height: Math.floor(height / 2)}} />
  }

  let growthbar = null;
  if (growth) {
    const fillwidthgrowth = `${(100 * growth / max).toFixed(2)}%`;
    growthbar = <div className="growth" style={{ position: 'absolute', left: fillwidth, width: fillwidthgrowth, height }} />;
  }

  let capbar = null;
  if (cap) {
    const fillwidthcap = `${Math.floor(100 * cap / max)}%`;
    capbar = <div className="cap" style={{ position: 'absolute', width: fillwidthcap, height }} />;
  }

  const linebars = lines.map((line, i) => {
    const { value, color } = line;
    const fillwidthline = `${(100 * value / max).toFixed(2)}%`;
    return <div key={i} style={{ position: 'absolute', left: fillwidthline, width: '1px', height }} />;
  });

  return <div className={(classname ? ('progress ' + classname) : 'progress')} title={title} style={{ position: 'relative', width: width ?? '100%', height}}>

    {capbar}
    {bonusbar}
    {growthbar}

    <div className="value" style={{ position: 'absolute', width: fillwidth, height }} />
    {linebars}
    <span className='progress-text' style={{ height }} title={label ?? null}>{_text}</span>
  </div>;
}
