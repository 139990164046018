// downloaded data!
export const data = [
  {
    "key": "nation_1_col",
    "nation": "loc_data_string_nationalities_nation_nation_1_col",
    "_nation_eng": "Colombia",
    "languages": "loc_data_string_nationalities_language_spanish",
    "background": "history_11_drugdealer",
    "weight": "30"
  },
  {
    "key": "nation_2_mex",
    "nation": "loc_data_string_nationalities_nation_nation_2_mex",
    "_nation_eng": "Mexico",
    "languages": "loc_data_string_nationalities_language_spanish",
    "background": "",
    "weight": "5"
  },
  {
    "key": "nation_3_usa",
    "nation": "loc_data_string_nationalities_nation_nation_3_usa",
    "_nation_eng": "United States of America",
    "languages": "loc_data_string_nationalities_language_english",
    "background": "",
    "weight": "5"
  },
  {
    "key": "nation_4_fr",
    "nation": "loc_data_string_nationalities_nation_nation_4_fr",
    "_nation_eng": "France",
    "languages": "loc_data_string_nationalities_language_french",
    "background": "",
    "weight": "4"
  },
  {
    "key": "nation_5_viet",
    "nation": "loc_data_string_nationalities_nation_nation_5_viet",
    "_nation_eng": "Vietnam",
    "languages": "loc_data_string_nationalities_language_vietnamese",
    "background": "history_19_refuge",
    "weight": "3"
  },
  {
    "key": "nation_6_spa",
    "nation": "loc_data_string_nationalities_nation_nation_6_spa",
    "_nation_eng": "Spain",
    "languages": "loc_data_string_nationalities_language_spanish",
    "background": "",
    "weight": "3"
  },
  {
    "key": "nation_7_uk",
    "nation": "loc_data_string_nationalities_nation_nation_7_uk",
    "_nation_eng": "United Kingdom",
    "languages": "loc_data_string_nationalities_language_english",
    "background": "history_1_sas",
    "weight": "3"
  },
  {
    "key": "nation_8_ira",
    "nation": "loc_data_string_nationalities_nation_nation_8_ira",
    "_nation_eng": "Iran",
    "languages": "loc_data_string_nationalities_language_persian",
    "background": "history_7_revolution",
    "weight": "3"
  },
  {
    "key": "nation_9_cub",
    "nation": "loc_data_string_nationalities_nation_nation_9_cub",
    "_nation_eng": "Cuba",
    "languages": "loc_data_string_nationalities_language_spanish",
    "background": "",
    "weight": "3"
  },
  {
    "key": "nation_10_ven",
    "nation": "loc_data_string_nationalities_nation_nation_10_ven",
    "_nation_eng": "Venezuela",
    "languages": "loc_data_string_nationalities_language_spanish",
    "background": "",
    "weight": "2"
  },
  {
    "key": "nation_11_bol",
    "nation": "loc_data_string_nationalities_nation_nation_11_bol",
    "_nation_eng": "Bolivia",
    "languages": "loc_data_string_nationalities_language_spanish",
    "background": "",
    "weight": "2"
  },
  {
    "key": "nation_12_ecu",
    "nation": "loc_data_string_nationalities_nation_nation_12_ecu",
    "_nation_eng": "Ecuador",
    "languages": "loc_data_string_nationalities_language_spanish",
    "background": "",
    "weight": "2"
  },
  {
    "key": "nation_13_hon",
    "nation": "loc_data_string_nationalities_nation_nation_13_hon",
    "_nation_eng": "Honduras",
    "languages": "loc_data_string_nationalities_language_spanish",
    "background": "",
    "weight": "2"
  },
  {
    "key": "nation_14_uru",
    "nation": "loc_data_string_nationalities_nation_nation_14_uru",
    "_nation_eng": "Uruguay",
    "languages": "loc_data_string_nationalities_language_spanish",
    "background": "",
    "weight": "2"
  },
  {
    "key": "nation_15_isr",
    "nation": "loc_data_string_nationalities_nation_nation_15_isr",
    "_nation_eng": "Israel",
    "languages": "loc_data_string_nationalities_language_hebrew",
    "background": "",
    "weight": "2"
  },
  {
    "key": "nation_16_pan",
    "nation": "loc_data_string_nationalities_nation_nation_16_pan",
    "_nation_eng": "Panama",
    "languages": "loc_data_string_nationalities_language_spanish",
    "background": "",
    "weight": "2"
  },
  {
    "key": "nation_17_par",
    "nation": "loc_data_string_nationalities_nation_nation_17_par",
    "_nation_eng": "Paraguay",
    "languages": "loc_data_string_nationalities_language_spanish",
    "background": "",
    "weight": "2"
  },
  {
    "key": "nation_18_peru",
    "nation": "loc_data_string_nationalities_nation_nation_18_peru",
    "_nation_eng": "Peru",
    "languages": "loc_data_string_nationalities_language_spanish",
    "background": "",
    "weight": "2"
  },
  {
    "key": "nation_19_gua",
    "nation": "loc_data_string_nationalities_nation_nation_19_gua",
    "_nation_eng": "Guatemala",
    "languages": "loc_data_string_nationalities_language_spanish",
    "background": "",
    "weight": "1"
  },
  {
    "key": "nation_20_nep",
    "nation": "loc_data_string_nationalities_nation_nation_20_nep",
    "_nation_eng": "Nepal",
    "languages": "loc_data_string_nationalities_language_nepali",
    "background": "history_4_gurkha",
    "weight": "1"
  },
  {
    "key": "nation_21_nic",
    "nation": "loc_data_string_nationalities_nation_nation_21_nic",
    "_nation_eng": "Nicaragua",
    "languages": "loc_data_string_nationalities_language_spanish",
    "background": "history_19_refuge",
    "weight": "1"
  },
  {
    "key": "nation_22_rok",
    "nation": "loc_data_string_nationalities_nation_nation_22_rok",
    "_nation_eng": "Republic of Korea",
    "languages": "loc_data_string_nationalities_language_korean",
    "background": "history_31_developer",
    "weight": "1"
  },
  {
    "key": "nation_23_ger",
    "nation": "loc_data_string_nationalities_nation_nation_23_ger",
    "_nation_eng": "Germany",
    "languages": "loc_data_string_nationalities_language_german",
    "background": "",
    "weight": "1"
  },
  {
    "key": "nation_24_rus",
    "nation": "loc_data_string_nationalities_nation_nation_24_rus",
    "_nation_eng": "Russia",
    "languages": "loc_data_string_nationalities_language_russian",
    "background": "",
    "weight": "1"
  },
  {
    "key": "nation_25_rwa",
    "nation": "loc_data_string_nationalities_nation_nation_25_rwa",
    "_nation_eng": "Rwanda",
    "languages": "loc_data_string_nationalities_language_kinyarwanda",
    "background": "history_19_refuge",
    "weight": "1"
  },
  {
    "key": "nation_26_lib",
    "nation": "loc_data_string_nationalities_nation_nation_26_lib",
    "_nation_eng": "Libya",
    "languages": "loc_data_string_nationalities_language_arabic",
    "background": "history_7_revolution",
    "weight": "1"
  },
  {
    "key": "nation_27_bra",
    "nation": "loc_data_string_nationalities_nation_nation_27_bra",
    "_nation_eng": "Brazil",
    "languages": "loc_data_string_nationalities_language_portuguese",
    "background": "",
    "weight": "1"
  },
  {
    "key": "nation_28_sud",
    "nation": "loc_data_string_nationalities_nation_nation_28_sud",
    "_nation_eng": "Sudan",
    "languages": "loc_data_string_nationalities_language_arabic",
    "background": "history_19_refuge",
    "weight": "1"
  },
  {
    "key": "nation_29_sur",
    "nation": "loc_data_string_nationalities_nation_nation_29_sur",
    "_nation_eng": "Suriname",
    "languages": "loc_data_string_nationalities_language_dutch",
    "background": "",
    "weight": "1"
  },
  {
    "key": "nation_30_swe",
    "nation": "loc_data_string_nationalities_nation_nation_30_swe",
    "_nation_eng": "Sweden",
    "languages": "loc_data_string_nationalities_language_swedish",
    "background": "",
    "weight": "1"
  },
  {
    "key": "nation_31_swi",
    "nation": "loc_data_string_nationalities_nation_nation_31_swi",
    "_nation_eng": "Switzerland",
    "languages": "loc_data_string_nationalities_language_german",
    "background": "history_2_swiss",
    "weight": "1"
  },
  {
    "key": "nation_32_arg",
    "nation": "loc_data_string_nationalities_nation_nation_32_arg",
    "_nation_eng": "Argentina",
    "languages": "loc_data_string_nationalities_language_spanish",
    "background": "history_7_revolution",
    "weight": "1"
  },
  {
    "key": "nation_33_hai",
    "nation": "loc_data_string_nationalities_nation_nation_33_hai",
    "_nation_eng": "Haiti",
    "languages": "loc_data_string_nationalities_language_french",
    "background": "",
    "weight": "1"
  },
  {
    "key": "nation_34_eth",
    "nation": "loc_data_string_nationalities_nation_nation_34_eth",
    "_nation_eng": "Ethiopia",
    "languages": "loc_data_string_nationalities_language_amharic",
    "background": "",
    "weight": "1"
  },
  {
    "key": "nation_35_yug",
    "nation": "loc_data_string_nationalities_nation_nation_35_yug",
    "_nation_eng": "Yugoslavia",
    "languages": "loc_data_string_nationalities_language_servokroatische, loc_data_string_nationalities_language_slovenian, loc_data_string_nationalities_language_macedonian",
    "background": "history_7_revolution",
    "weight": "1"
  },
  {
    "key": "nation_36_egy",
    "nation": "loc_data_string_nationalities_nation_nation_36_egy",
    "_nation_eng": "Egypt",
    "languages": "loc_data_string_nationalities_language_arabic",
    "background": "",
    "weight": "1"
  },
  {
    "key": "nation_37_ind",
    "nation": "loc_data_string_nationalities_nation_nation_37_ind",
    "_nation_eng": "India",
    "languages": "loc_data_string_nationalities_language_hindi",
    "background": "history_4_gurkha",
    "weight": "1"
  },
  {
    "key": "nation_38_jp",
    "nation": "loc_data_string_nationalities_nation_nation_38_jp",
    "_nation_eng": "Japan",
    "languages": "loc_data_string_nationalities_language_japanese",
    "background": "",
    "weight": "1"
  },
  {
    "key": "nation_39_chi",
    "nation": "loc_data_string_nationalities_nation_nation_39_chi",
    "_nation_eng": "China",
    "languages": "loc_data_string_nationalities_language_chinese",
    "background": "",
    "weight": "1"
  },
  {
    "key": "nation_40_chil",
    "nation": "loc_data_string_nationalities_nation_nation_40_chil",
    "_nation_eng": "Chile",
    "languages": "loc_data_string_nationalities_language_spanish",
    "background": "",
    "weight": "1"
  },
  {
    "key": "nation_41_can",
    "nation": "loc_data_string_nationalities_nation_nation_41_can",
    "_nation_eng": "Canada",
    "languages": "loc_data_string_nationalities_language_english, loc_data_string_nationalities_language_french",
    "background": "",
    "weight": "1"
  }
];