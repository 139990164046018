// downloaded data!
export const data = [
  {
    "name": "자모라",
    "loc_key": "loc_data_string_region_0",
    "tier": "2",
    "force_0_weight": "0",
    "force_1_ty": "갱단",
    "force_1_weight": "20",
    "force_2_ty": "카르텔",
    "force_2_weight": "80"
  },
  {
    "name": "산베니토",
    "loc_key": "loc_data_string_region_1",
    "tier": "2",
    "force_0_weight": "0",
    "force_1_ty": "갱단",
    "force_1_weight": "20",
    "force_2_ty": "카르텔",
    "force_2_weight": "80"
  },
  {
    "name": "엔비가도",
    "loc_key": "loc_data_string_region_2",
    "tier": "2",
    "force_0_weight": "0",
    "force_1_ty": "갱단",
    "force_1_weight": "50",
    "force_2_ty": "민병대",
    "force_2_weight": "25",
    "force_3_ty": "카르텔",
    "force_3_weight": "25"
  },
  {
    "name": "벨렌",
    "loc_key": "loc_data_string_region_3",
    "tier": "2",
    "force_0_weight": "0",
    "force_1_ty": "갱단",
    "force_1_weight": "50",
    "force_2_ty": "민병대",
    "force_2_weight": "25",
    "force_3_ty": "카르텔",
    "force_3_weight": "25"
  },
  {
    "name": "코라존데헤수스",
    "loc_key": "loc_data_string_region_4",
    "tier": "2",
    "force_0_weight": "0",
    "force_1_ty": "갱단",
    "force_1_weight": "50",
    "force_2_ty": "민병대",
    "force_2_weight": "25",
    "force_3_ty": "카르텔",
    "force_3_weight": "25"
  },
  {
    "name": "망가",
    "loc_key": "loc_data_string_region_5",
    "tier": "2",
    "force_0_weight": "0",
    "force_1_ty": "갱단",
    "force_1_weight": "20",
    "force_2_ty": "민병대",
    "force_2_weight": "80"
  },
  {
    "name": "엘팔마",
    "loc_key": "loc_data_string_region_6",
    "tier": "2",
    "force_0_weight": "0",
    "force_1_ty": "갱단",
    "force_1_weight": "20",
    "force_2_ty": "카르텔",
    "force_2_weight": "80"
  },
  {
    "name": "산루이스",
    "loc_key": "loc_data_string_region_7",
    "tier": "2",
    "force_0_weight": "0",
    "force_1_ty": "갱단",
    "force_1_weight": "20",
    "force_2_ty": "민병대",
    "force_2_weight": "80"
  },
  {
    "name": "라스모라스",
    "loc_key": "loc_data_string_region_8",
    "tier": "2",
    "force_0_weight": "0",
    "force_1_ty": "갱단",
    "force_1_weight": "50",
    "force_2_ty": "민병대",
    "force_2_weight": "25",
    "force_3_ty": "카르텔",
    "force_3_weight": "25"
  },
  {
    "name": "산타모니카",
    "loc_key": "loc_data_string_region_9",
    "tier": "2",
    "force_0_weight": "0",
    "force_1_ty": "갱단",
    "force_1_weight": "20",
    "force_2_ty": "민병대",
    "force_2_weight": "80"
  },
  {
    "name": "마벨라",
    "loc_key": "loc_data_string_region_10",
    "tier": "2",
    "force_0_weight": "25",
    "force_1_ty": "잡범",
    "force_1_weight": "75"
  },
  {
    "name": "라플로리다",
    "loc_key": "loc_data_string_region_11",
    "tier": "2",
    "force_0_weight": "25",
    "force_1_ty": "잡범",
    "force_1_weight": "75"
  },
  {
    "name": "에스페란차",
    "loc_key": "loc_data_string_region_12",
    "tier": "2",
    "force_0_weight": "25",
    "force_1_ty": "잡범",
    "force_1_weight": "75"
  },
  {
    "name": "보카그란데",
    "loc_key": "loc_data_string_region_13",
    "tier": "2",
    "force_0_weight": "25",
    "force_1_ty": "잡범",
    "force_1_weight": "75"
  },
  {
    "name": "아르메니아",
    "loc_key": "loc_data_string_region_14",
    "tier": "2",
    "force_0_weight": "25",
    "force_1_ty": "잡범",
    "force_1_weight": "75"
  }
];