import * as React from 'react';
import './FigmaUpcomingView.css';

function Upcoming() {
  return <>
    <div className="upcoming">
      Upcoming
      <UpcomingMilestone />
      <UpcomingMilestone collapsed />
      <UpcomingMilestone locked />
      <UpcomingMilestone collapsed locked />
      <UpcomingItem />
      <UpcomingItem checked />
      <UpcomingItem arrived />
      <UpcomingItem checked arrived />
      <UpcomingListPanel kind="training" countItem={1} countEmpty={2} />
      <UpcomingListPanel kind="training" countItem={2} countEmpty={3} collapsed />
      <UpcomingListPanel kind="mission" countItem={3} />
      <UpcomingListPanel kind="mission" countItem={4} collapsed />
    </div>
  </>;
}

function UpcomingMilestone(props) {
  const { locked, collapsed } = props;
  if (locked) {
    return UpcomingMilestoneLocked({ collapsed });
  } else {
    return UpcomingMilestoneUnlocked({ collapsed });
  }
}
function UpcomingMilestoneLocked(props) {
  return <>
    <div className="upcoming-milestone locked">
      <div className="upcoming-milestone-inner">
        <div className="upcoming-milestone-well">
          <div className="flexc">
            <div className="flexr">
              <div className="upcoming-milestone-icon-map"></div>
              <div className="flexc grow1">
                <h1>
                  <i className="upcoming-milestone-icon-locked"></i>
                  <a href="#!">[마일스톤] 첫 걸음 ABCDEFGHIJK</a>
                </h1>
                <div className="flexr">
                  <div className="upcoming-milestone-well-detail-left">
                    <h2>남은 기간</h2>
                    <time>7개월 26일 13시간</time>
                  </div>
                  <div className="upcoming-milestone-well-detail-divider">
                  </div>
                  <div className="upcoming-milestone-well-detail-reward">
                    <h2>보상</h2>
                    <p className="reward-amount">$30000</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {UpcomingMilestoneProgress({ value: 100, max: 150 })}
        </div>
        {UpcomingMilestoneSummary(props)}
      </div>
    </div>
  </>;
}

function UpcomingMilestoneProgress(props) {
  const { value, max } = props;
  const percent = Math.min(100, (value / max) * 100);
  const gradientStart = Math.max(0, percent - 20);
  const barStyle = {
    background: `linear-gradient(90deg,
      #00BBB0 0%,
      #00BBB0 ${gradientStart}%,
      #1BFFF1FF ${percent}%,
      #1BFFF100 ${percent}%,
      #1BFFF100 100%)`};
  return <>
    <div className="upcoming-milestone-well-progress">
      <h2>잠금 해제에 명성 {max} 필요</h2>
      <div className="upcoming-milestone-well-progress-bar" style={barStyle}>
        {value}/{max}
      </div>
    </div>
  </>;
}

function UpcomingMilestoneSummary(props) {
  const [collapsed, setCollapsed] = React.useState(props.collapsed);
  if (collapsed) {
    return <>
      <p className="upcoming-milestone-description">
        바랑키야가 칼리 마약 운반 루트 정보를 얻었으며,
        대량의 마약을 멕시코로 보내고 있다는 것을 알았다.
        하지만 배의 출항 경로는 아직 알 수 없다.
        바랑키야는 칼리의 배를 습격하여
        끝없는 전쟁을 막기 위해
        더 강력한 일격을 준비해야 한다.
      </p>
      <a
        href="#!"
        className="upcoming-milestone-summary-toggle"
        onClick={() => { setCollapsed(!collapsed) }}
      >
        <i className="upcoming-icon-up"></i>
        마일스톤 개요 숨기기
      </a>
    </>;
  } else {
    return <>
      <a
        href="#!"
        className="upcoming-milestone-summary-toggle"
        onClick={() => { setCollapsed(!collapsed) }}
      >
        <i className="upcoming-icon-right"></i>
        마일스톤 개요 펼쳐보기
      </a>
    </>;
  }

}

function UpcomingMilestoneUnlocked(props) {
  return <>
    <div className="upcoming-milestone unlocked">
      <div className='upcoming-milestone-warning-container'>
        <div className='upcoming-milestone-warning'>
        </div>
      </div>
      <p className="warning">미션을 기한내에 완수하지 않으면 게임이 종료됩니다!</p>
      <div className="upcoming-milestone-inner">
        <div className="upcoming-milestone-well">
          <div className="flexc">
            <div className="flexr">
              <div className="upcoming-milestone-icon-map"></div>
              <div className="flexc grow1">
                <h1>
                  <i className="upcoming-milestone-icon-unlocked"></i>
                  <a href="#!">[마일스톤] <strong>첫 걸음 ABCDEFGHIJK</strong></a>
                </h1>
                <div className="flexr grow1">
                  <div className="upcoming-milestone-well-detail-left">
                    <h2>남은 기간</h2>
                    <time>13시간 45분 17초</time>
                  </div>
                  <div className="upcoming-milestone-well-detail-divider">
                  </div>
                  <div className="upcoming-milestone-well-detail-reward">
                    <h2>보상</h2>
                    <p className="reward-amount">$30000</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {UpcomingMilestoneSummary(props)}
      </div>
    </div>
  </>;
}

function UpcomingItem(props) {
  const { checked, arrived } = props;
  const iconClass = 'upcoming-icon-item-' + (checked ? 'checked' : 'unchecked');
  const arrivedClass = 'upcoming-item-' + (arrived ? 'arrived' : 'coming');
  return <>
    <div className="upcoming-item">
      <div className="upcoming-item-inner">
        <div className="flexr">
          <i className={iconClass}></i>
          <p className={"upcoming-item-content " + arrivedClass}>
            영입 목록이 갱신되기까지
            <br />
            <a href="#!">7일 남음</a>
          </p>
          <a href="#!"><i className="upcoming-icon-delete"></i></a>
        </div>
      </div>
    </div>
  </>;
}

function getListPanelTitle(kind) {
  if (kind === "training") {
    return "훈련";
  } else if (kind === "mission") {
    return "임무";
  } else {
    return "목록";
  }
}

function getListPanelClassName(kind) {
  if (kind === "training") {
    return "upcoming-training";
  } else {
    return "upcoming-mission";
  }
}

function UpcomingListPanel(props) {
  const { kind } = props;
  const title = getListPanelTitle(kind);
  const className = getListPanelClassName(kind);
  const [collapsed, setCollapsed] = React.useState(props.collapsed);
  const onTitleClick = () => {
    setCollapsed(!collapsed);
    console.log(collapsed);
  };
  return <>
    <div className={className}>
      {UpcomingCollapsibleTitle({ ...props, collapsed, title, onClick: onTitleClick })}
      {UpcomingList({ ...props, collapsed })}
    </div>
  </>;
}

function UpcomingCollapsibleTitle(props) {
  const { title, collapsed, onClick } = props;
  const { countItem, countEmpty } = props;

  const className = collapsed ?
    "upcoming-title flexr collapsed" :
    "upcoming-title flexr";
  const icon = collapsed ?
    <i className="upcoming-icon-right"></i> :
    <i className="upcoming-icon-down"></i>;
  const emptySlot = countEmpty ?
    <p><a href="#!">빈 슬롯: <strong>{countEmpty}</strong></a></p> :
    <></>;

  return <>
    <div className={className}>
      <h1 className="grow1">
        <a
          href="#!"
          onClick={onClick}
        >
          {icon}
          {title} : {countItem}
        </a>
      </h1>
      <h2>
        {emptySlot}
      </h2>
    </div>
  </>;
}

function UpcomingList(props) {
  const { collapsed } = props;
  if (collapsed) {
    return <></>;
  }

  return <>
    <div className="upcoming-list-container">
      <ul className="upcoming-list">
        <li className="flexr">
          <p className="grow1">
            <a href="#!">
              <i className="upcoming-icon-mission"></i>
              카르텔 정보원 추출
            </a>
          </p>
          <i className="upcoming-icon-check"></i>
        </li>
        <li className="flexr">
          <p className="grow1">
            <a href="#!">
              <i className="upcoming-icon-mission"></i>
              무기 운송 금지
            </a>
          </p>
          <time>
            12일
          </time>
        </li>
        <li className="flexr">
          <p className="grow1">
            <a href="#!">
              <i className="upcoming-icon-agent"></i>
              Mauricio “Spider” Rey
            </a>
          </p>
          <i className="upcoming-icon-check"></i>
        </li>
        <li className="flexr">
          <p className="grow1">
            <a href="#!">
              <i className="upcoming-icon-agent"></i>
              Mauricio “Spider” Rey
            </a>
          </p>
          <time>
            a month
          </time>
        </li>
      </ul>
    </div>
  </>;
}

export class FigmaUpcomingView extends React.Component {
  render() {
    return <>
      <div className="root">
        <h1>HELLO</h1>
        {Upcoming()}
      </div>
    </>
  }
}
