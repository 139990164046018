// downloaded data!
export const data = [
  {
    "work_key": "search_agent_tier",
    "work_type": "main",
    "work_name": "loc_data_string_work_work_name_search_agent_tier",
    "work_desc": "loc_data_longtext_work_work_desc_search_agent_tier",
    "department": "recruit",
    "ability_ty": "search",
    "var1": "1, 2, 3, 4",
    "var2": "",
    "ability_diff_fixed": "",
    "ability_diff_calc": "var1 * 10",
    "work_effect_ty": "on_finish",
    "on_finish_success": "new_recruit, 2 + var1 * 4",
    "finish_success_rate_desc": "loc_data_string_workdetails_finish_success_rate_desc_success",
    "finish_success_effect_desc": "loc_data_string_workdetails_finish_success_effect_desc_overall_agent",
    "on_finish_fail": "do_nothing",
    "on_finish_repeat": "always",
    "on_finish_stop_timer": "always",
    "duration_base_days": "7 + var1 * 3.5",
    "duration_minimum_days": "3.5 + var1 * 1.75"
  },
  {
    "work_key": "search_agent_possible",
    "work_type": "main",
    "work_name": "loc_data_string_work_work_name_search_agent_possible",
    "work_desc": "loc_data_longtext_work_work_desc_search_agent_possible",
    "department": "recruit",
    "ability_ty": "search",
    "var1": "",
    "var2": "",
    "ability_diff_fixed": "",
    "ability_diff_calc": "ability와 동일",
    "work_effect_ty": "on_finish",
    "on_finish_success": "new_recruit, 2 + difficulty * 0.4",
    "finish_success_rate_desc": "loc_data_string_workdetails_finish_success_rate_desc_success",
    "finish_success_effect_desc": "loc_data_string_workdetails_finish_success_effect_desc_overall_agent",
    "on_finish_fail": "do_nothing",
    "on_finish_repeat": "always",
    "on_finish_stop_timer": "always",
    "duration_base_days": "7 * (1 + difficulty * 0.1)",
    "duration_minimum_days": "3.5 * (1 + difficulty * 0.1)"
  },
  {
    "work_key": "recruit_agent",
    "work_type": "sub",
    "work_name": "loc_data_string_work_work_name_recruit_agent",
    "work_desc": "loc_data_longtext_work_work_desc_recruit_agent",
    "department": "recruit",
    "ability_ty": "negotiate",
    "var1": "*recruit_agents",
    "var2": "",
    "ability_diff_fixed": "20",
    "ability_diff_calc": "",
    "work_effect_ty": "on_finish",
    "on_finish_success": "recruit_with_discount, min(0.01 * ability, 0.2)",
    "finish_success_rate_desc": "loc_data_string_workdetails_finish_success_rate_desc_favorable_negotiation",
    "finish_success_effect_desc": "loc_data_string_workdetails_finish_success_effect_desc_discount_hire",
    "on_finish_fail": "recruit_with_discount, 0",
    "on_finish_repeat": "never",
    "on_finish_stop_timer": "always",
    "duration_base_days": "5",
    "duration_minimum_days": "5"
  },
  {
    "work_key": "discount_renewal_agent",
    "work_type": "main",
    "work_name": "loc_data_string_work_work_name_discount_renewal_agent",
    "work_desc": "loc_data_longtext_work_work_desc_discount_renewal_agent",
    "department": "recruit",
    "ability_ty": "negotiate",
    "var1": "*agents",
    "var2": "",
    "ability_diff_fixed": "30",
    "ability_diff_calc": "",
    "work_effect_ty": "on_finish",
    "on_finish_success": "discount_agent_value, min(0.005 * ability, 0.15)",
    "finish_success_rate_desc": "loc_data_string_workdetails_finish_success_rate_desc_success",
    "finish_success_effect_desc": "loc_data_string_workdetails_finish_success_effect_desc_discount_extend",
    "on_finish_fail": "do_nothing",
    "on_finish_repeat": "always",
    "on_finish_stop_timer": "always",
    "duration_base_days": "28",
    "duration_minimum_days": "14"
  },
  {
    "work_key": "search_staff",
    "work_type": "main",
    "work_name": "loc_data_string_work_work_name_search_staff",
    "work_desc": "loc_data_longtext_work_work_desc_search_staff",
    "department": "recruit",
    "ability_ty": "search",
    "var1": "",
    "var2": "",
    "ability_diff_fixed": "",
    "ability_diff_calc": "ability와 동일",
    "work_effect_ty": "on_finish",
    "on_finish_success": "new_staff, ability / 2",
    "finish_success_rate_desc": "loc_data_string_workdetails_finish_success_rate_desc_success",
    "finish_success_effect_desc": "loc_data_string_workdetails_finish_success_effect_desc_overall_staff",
    "on_finish_fail": "do_nothing",
    "on_finish_repeat": "always",
    "on_finish_stop_timer": "always",
    "duration_base_days": "28",
    "duration_minimum_days": "7"
  },
  {
    "work_key": "recruit_staff",
    "work_type": "sub",
    "work_name": "loc_data_string_work_work_name_recruit_staff",
    "work_desc": "loc_data_longtext_work_work_desc_recruit_staff",
    "department": "recruit",
    "ability_ty": "negotiate",
    "var1": "*recruit_staffs",
    "var2": "",
    "ability_diff_fixed": "",
    "ability_diff_calc": "2 * (후보의 평균 ability)",
    "work_effect_ty": "on_finish",
    "on_finish_success": "recruit_staff",
    "finish_success_rate_desc": "loc_data_string_workdetails_finish_success_rate_desc_success",
    "finish_success_effect_desc": "",
    "on_finish_fail": "do_nothing",
    "on_finish_repeat": "never",
    "on_finish_stop_timer": "always",
    "duration_base_days": "5",
    "duration_minimum_days": "5"
  },
  {
    "work_key": "source_gun_general",
    "work_type": "main",
    "work_name": "loc_data_string_work_work_name_source_gun_general",
    "work_desc": "loc_data_longtext_work_work_desc_source_gun_general",
    "department": "market",
    "ability_ty": "search",
    "var1": "",
    "var2": "",
    "ability_diff_fixed": "10",
    "ability_diff_calc": "",
    "work_effect_ty": "on_finish",
    "on_finish_success": "new_gun, random, [1, max(1, ability / 10)]",
    "finish_success_rate_desc": "loc_data_string_workdetails_finish_success_rate_desc_success",
    "finish_success_effect_desc": "",
    "on_finish_fail": "do_nothing",
    "on_finish_repeat": "always",
    "on_finish_stop_timer": "always",
    "duration_base_days": "10",
    "duration_minimum_days": "5"
  },
  {
    "work_key": "source_gun_ty",
    "work_type": "main",
    "work_name": "loc_data_string_work_work_name_source_gun_ty",
    "work_desc": "loc_data_longtext_work_work_desc_source_gun_ty",
    "department": "market",
    "ability_ty": "search",
    "var1": "sg, smg, ar, dmr, sr",
    "var2": "",
    "ability_diff_fixed": "15",
    "ability_diff_calc": "",
    "work_effect_ty": "on_finish",
    "on_finish_success": "new_gun, var1, [1, max(1, ability / 10)]",
    "finish_success_rate_desc": "loc_data_string_workdetails_finish_success_rate_desc_success",
    "finish_success_effect_desc": "",
    "on_finish_fail": "do_nothing",
    "on_finish_repeat": "always",
    "on_finish_stop_timer": "always",
    "duration_base_days": "14",
    "duration_minimum_days": "7"
  },
  {
    "work_key": "source_gun_tier",
    "work_type": "main",
    "work_name": "loc_data_string_work_work_name_source_gun_tier",
    "work_desc": "loc_data_longtext_work_work_desc_source_gun_tier",
    "department": "market",
    "ability_ty": "search",
    "var1": "1, 2, 3, 4, 5",
    "var2": "",
    "ability_diff_fixed": "",
    "ability_diff_calc": "var1 * 9",
    "work_effect_ty": "on_finish",
    "on_finish_success": "new_gun, random, [var1, var1]",
    "finish_success_rate_desc": "loc_data_string_workdetails_finish_success_rate_desc_success",
    "finish_success_effect_desc": "",
    "on_finish_fail": "do_nothing",
    "on_finish_repeat": "always",
    "on_finish_stop_timer": "always",
    "duration_base_days": "var1 * 7",
    "duration_minimum_days": "var1 * 3.5"
  },
  {
    "work_key": "source_gun_ty_tier",
    "work_type": "main",
    "work_name": "loc_data_string_work_work_name_source_gun_ty_tier",
    "work_desc": "loc_data_longtext_work_work_desc_source_gun_ty_tier",
    "department": "market",
    "ability_ty": "search",
    "var1": "sg, smg, ar, dmr, sr",
    "var2": "1, 2, 3, 4, 5",
    "ability_diff_fixed": "",
    "ability_diff_calc": "var2 * 12",
    "work_effect_ty": "on_finish",
    "on_finish_success": "new_gun, var1, [var2, var2]",
    "finish_success_rate_desc": "loc_data_string_workdetails_finish_success_rate_desc_success",
    "finish_success_effect_desc": "",
    "on_finish_fail": "do_nothing",
    "on_finish_repeat": "always",
    "on_finish_stop_timer": "always",
    "duration_base_days": "var2 * 10",
    "duration_minimum_days": "var2 * 5"
  },
  {
    "work_key": "source_bulletproof_general",
    "work_type": "main",
    "work_name": "loc_data_string_work_work_name_source_bulletproof_general",
    "work_desc": "loc_data_longtext_work_work_desc_source_bulletproof_general",
    "department": "market",
    "ability_ty": "search",
    "var1": "",
    "var2": "",
    "ability_diff_fixed": "12",
    "ability_diff_calc": "",
    "work_effect_ty": "on_finish",
    "on_finish_success": "new_bulletproof, [1, max(1, ability / 10)]",
    "finish_success_rate_desc": "loc_data_string_workdetails_finish_success_rate_desc_success",
    "finish_success_effect_desc": "",
    "on_finish_fail": "do_nothing",
    "on_finish_repeat": "always",
    "on_finish_stop_timer": "always",
    "duration_base_days": "14",
    "duration_minimum_days": "7"
  },
  {
    "work_key": "source_bulletproof_tier",
    "work_type": "main",
    "work_name": "loc_data_string_work_work_name_source_bulletproof_tier",
    "work_desc": "loc_data_longtext_work_work_desc_source_bulletproof_tier",
    "department": "market",
    "ability_ty": "search",
    "var1": "1, 2, 3, 4",
    "var2": "",
    "ability_diff_fixed": "",
    "ability_diff_calc": "var1 * 10",
    "work_effect_ty": "on_finish",
    "on_finish_success": "new_bulletproof, [var1, var1]",
    "finish_success_rate_desc": "loc_data_string_workdetails_finish_success_rate_desc_success",
    "finish_success_effect_desc": "",
    "on_finish_fail": "do_nothing",
    "on_finish_repeat": "always",
    "on_finish_stop_timer": "always",
    "duration_base_days": "21",
    "duration_minimum_days": "10"
  },
  {
    "work_key": "source_granade_tier",
    "work_type": "main",
    "work_name": "loc_data_string_work_work_name_source_granade_tier",
    "work_desc": "loc_data_longtext_work_work_desc_source_granade_tier",
    "department": "market",
    "ability_ty": "search",
    "var1": "1, 2, 3",
    "var2": "",
    "ability_diff_fixed": "20",
    "ability_diff_calc": "",
    "work_effect_ty": "on_finish",
    "on_finish_success": "new_granade, var1",
    "finish_success_rate_desc": "loc_data_string_workdetails_finish_success_rate_desc_success",
    "finish_success_effect_desc": "",
    "on_finish_fail": "do_nothing",
    "on_finish_repeat": "always",
    "on_finish_stop_timer": "always",
    "duration_base_days": "10",
    "duration_minimum_days": "5"
  },
  {
    "work_key": "buy_item",
    "work_type": "sub",
    "work_name": "loc_data_string_work_work_name_buy_item",
    "work_desc": "loc_data_longtext_work_work_desc_buy_item",
    "department": "market",
    "ability_ty": "bargain",
    "var1": "*market_items",
    "var2": "",
    "ability_diff_fixed": "20",
    "ability_diff_calc": "",
    "work_effect_ty": "on_finish",
    "on_finish_success": "buy_with_discount, min(0.01 * ability, 0.2)",
    "finish_success_rate_desc": "loc_data_string_workdetails_finish_success_rate_desc_discount_buy",
    "finish_success_effect_desc": "loc_data_string_workdetails_finish_success_effect_desc_discount_buy",
    "on_finish_fail": "buy_with_discount, 0",
    "on_finish_repeat": "never",
    "on_finish_stop_timer": "always",
    "duration_base_days": "1",
    "duration_minimum_days": "1"
  },
  {
    "work_key": "sell_item",
    "work_type": "sub",
    "work_name": "loc_data_string_work_work_name_sell_item",
    "work_desc": "loc_data_longtext_work_work_desc_sell_item",
    "department": "market",
    "ability_ty": "bargain",
    "var1": "*warehouse_items",
    "var2": "",
    "ability_diff_fixed": "15",
    "ability_diff_calc": "",
    "work_effect_ty": "on_finish",
    "on_finish_success": "sell_with_bonus, min(0.01 * ability, 0.15)",
    "finish_success_rate_desc": "loc_data_string_workdetails_finish_success_rate_desc_increase_sell",
    "finish_success_effect_desc": "loc_data_string_workdetails_finish_success_effect_desc_increase_sell",
    "on_finish_fail": "sell_with_bonus, 0",
    "on_finish_repeat": "never",
    "on_finish_stop_timer": "always",
    "duration_base_days": "1",
    "duration_minimum_days": "1"
  },
  {
    "work_key": "maintain_training",
    "work_type": "main",
    "work_name": "loc_data_string_work_work_name_maintain_training",
    "work_desc": "loc_data_longtext_work_work_desc_maintain_training",
    "department": "training",
    "ability_ty": "train",
    "var1": "",
    "var2": "",
    "ability_diff_fixed": "",
    "ability_diff_calc": "ability와 동일",
    "work_effect_ty": "everyday",
    "on_finish_success": "do_nothing",
    "finish_success_rate_desc": "loc_data_string_workdetails_finish_success_rate_desc_success",
    "finish_success_effect_desc": "loc_data_string_workdetails_finish_success_effect_desc_daily_maintain",
    "on_finish_fail": "do_nothing",
    "on_finish_repeat": "always",
    "on_finish_stop_timer": "never",
    "duration_base_days": "28",
    "duration_minimum_days": "28"
  },
  {
    "work_key": "search_contract",
    "work_type": "main",
    "work_name": "loc_data_string_work_work_name_search_contract",
    "work_desc": "loc_data_longtext_work_work_desc_search_contract",
    "department": "office",
    "ability_ty": "search",
    "var1": "1, 2, 3, 4, 5",
    "var2": "",
    "ability_diff_fixed": "",
    "ability_diff_calc": "5 + 5 * var1",
    "work_effect_ty": "on_finish",
    "on_finish_success": "new_contract, [var1, var1], min(0.01 * negotiate, 0.2)",
    "finish_success_rate_desc": "loc_data_string_workdetails_finish_success_rate_desc_more_rewards",
    "finish_success_effect_desc": "loc_data_string_workdetails_finish_success_effect_desc_more_rewards",
    "on_finish_fail": "new_contract, [max(1, var1 - 1), min(var1 + 1, 5)], 0",
    "on_finish_repeat": "always",
    "on_finish_stop_timer": "always",
    "duration_base_days": "28",
    "duration_minimum_days": "14"
  },
  {
    "work_key": "",
    "work_type": "",
    "work_name": "",
    "work_desc": "",
    "department": "",
    "ability_ty": "",
    "var1": "",
    "var2": "",
    "ability_diff_fixed": "",
    "ability_diff_calc": "",
    "work_effect_ty": "",
    "on_finish_success": "",
    "finish_success_rate_desc": "",
    "finish_success_effect_desc": "",
    "on_finish_fail": "",
    "on_finish_repeat": "",
    "on_finish_stop_timer": "",
    "duration_base_days": "",
    "duration_minimum_days": ""
  }
];