// downloaded data!
export const data = [
  {
    "name": "도시",
    "ty": "urban",
    "weight": "1.5",
    "slots_office": "1",
    "slots_production": "2",
    "slots_support": "2",
    "traits": "slum, metro, drug"
  },
  {
    "name": "사막",
    "ty": "desert",
    "weight": "1",
    "slots_office": "1",
    "slots_production": "1",
    "slots_support": "1",
    "traits": "mine, military"
  },
  {
    "name": "평야",
    "ty": "plain",
    "weight": "1",
    "slots_office": "1",
    "slots_production": "1",
    "slots_support": "1",
    "traits": "farm"
  },
  {
    "name": "밀림",
    "ty": "forest",
    "weight": "1",
    "slots_office": "1",
    "slots_production": "1",
    "slots_support": "1",
    "traits": "drug, park"
  },
  {
    "name": "산악",
    "ty": "alpine",
    "weight": "1",
    "slots_office": "1",
    "slots_production": "1",
    "slots_support": "1",
    "traits": "mine, park"
  }
];