import dayjs from 'dayjs';

export const TICK_PER_DAY = 24;
export const TICK_PER_WEEK = TICK_PER_DAY * 7;
export const TICK_PER_MONTH = TICK_PER_WEEK * 4;

export function tickToDate(tick) {
  return new Date(1970, 0, 1, tick);
}

export function tickToMonth(tick) {
  return Math.floor(tick / TICK_PER_MONTH);
}

export function tickToDateStr(tick) {
  return dayjs(tickToDate(tick)).format("YYYY-MM-DD HH:00");
}
