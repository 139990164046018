// downloaded data!
export const data = [
  {
    "id": "firearm_option_rusty",
    "name": "녹슨",
    "prob_generate": "0.5",
    "item_type": "firearm",
    "item_group": "sg, smg, ar, dmr, sr",
    "ty": "condition",
    "modifier_0": "price",
    "value_0": "0.6",
    "modifier_1": "aimvar_hold_max",
    "value_1": "1.3",
    "modifier_2": "firearm_range",
    "value_2": "0.8"
  },
  {
    "id": "vest_option_reinforced",
    "name": "보강한",
    "prob_generate": "0.2",
    "item_type": "equipment",
    "item_group": "vest",
    "ty": "condition",
    "modifier_0": "price",
    "value_0": "1.3",
    "modifier_1": "vest_armor",
    "value_1": "1.3"
  },
  {},
  {},
  {},
  {
    "id": "",
    "name": "",
    "prob_generate": "",
    "item_type": "",
    "item_group": "",
    "ty": "",
    "modifier_0": "",
    "value_0": "",
    "modifier_1": "                       "
  }
];