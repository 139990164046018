import { ENTITY_CONFIG_TMPL } from './opts.mjs';
import { names3 } from './names.mjs';

const lerp = function (a, b, t) {
  return a + (b - a) * t;
}

// level: [1, 20]
const project = function (min, max, lvl) {
  return lerp(min, max, (lvl - 1) / 19);
}

export const STATS2_DESCR = {
  decision: '판단력',
  bravery: '용감함',
  focus: '집중력',
  reaction: '반사신경',
  toughness: '강인함',
  precision: '정교함',
};

export const STATS2_TMPL = {
  name: null,

  stat_size: 2,
  stat_firearm_level: [11, 11, 11, 11, 11],

  decision: 10,
  bravery: 10,
  focus: 10,
  reaction: 10,
  toughness: 10,
  precision: 10,
};

export const STATS2_BONUS = {
  decision: [
    { level: 4, perk: 'perk_aimvar_max_5' },
    { level: 8, perk: 'perk_aimvar_5' },
    { level: 12, perk: 'perk_aimvar_max_10' },
    { level: 16, perk: 'perk_aimvar_10' },
    // {level: 20, perk: 'perk_predict_aim'},
  ],
  bravery: [
    { level: 4, perk: 'perk_aimvar_incr_m5' },
    { level: 8, perk: 'perk_suppress_m5' },
    { level: 12, perk: 'perk_aimvar_incr_m10' },
    { level: 16, perk: 'perk_suppress_m10' },
    // {level: 20, perk: 'perk_counterattack'},
  ],
  focus: [
    // {level: 4, perk: 'perk_moving_reload'},
    { level: 8, perk: 'perk_stationary_aimspeed' },
    { level: 12, perk: 'perk_aim_together' },
    { level: 16, perk: 'perk_cover_steady' },
    { level: 20, perk: 'perk_aim_execute' },
  ],
  reaction: [
    { level: 4, perk: 'perk_cover_dash' },
    // {level: 8, perk: 'perk_reflex_evade'},
    { level: 12, perk: 'perk_move_evade' },
    { level: 16, perk: 'perk_move_cover' },
    { level: 20, perk: 'perk_engage_dash' },
  ],
  toughness: [
    { level: 4, perk: 'perk_healed_amount_5' },
    { level: 8, perk: 'perk_glancing_blow' },
    { level: 12, perk: 'perk_healed_amount_15' },
    { level: 16, perk: 'perk_recover_life' },
    { level: 20, perk: 'perk_reduce_penalty' },
  ],
  precision: [
    { level: 4, perk: 'perk_critical_add' },
    { level: 8, perk: 'perk_damage_standing' },
    { level: 12, perk: 'perk_pierce_moving_enemy' },
    { level: 16, perk: 'perk_damage_move_crawl' },
    { level: 20, perk: 'perk_lastshoot' },
  ],
};

export function stats2_const(val, firearmVal) {
  return {
    stat_size: 2,
    stat_firearm_level: STATS2_TMPL.stat_firearm_level.map((_v) => firearmVal ?? 11),

    decision: val,
    bravery: val,
    focus: val,
    reaction: val,
    toughness: val,
    precision: val,

    _stat: val,
  };
}

export function stats2_rand(rng, name) {
  if (!name) {
    name = rng.choice(names3);
  }
  return {
    name,
    stat_size: rng.integer(1, 3),
    stat_firearm_level: [11, 11, 11, 11, 11],

    decision: rng.range(1, 20),
    bravery: rng.range(1, 20),
    focus: rng.range(1, 20),
    reaction: rng.range(1, 20),
    toughness: rng.range(1, 20),
    precision: rng.range(1, 20),
  };
}

export function stats2_avg(stats) {
  let sum = 0;
  let count = 0;

  for (const key of Object.keys(STATS2_DESCR)) {
    sum += stats[key];
    count += 1;
  }
  return (sum / count).toFixed(1);
}

export const STATS2_TMPL_MAX = stats2_const(20, 20);

export function updateEntityStat2(entity, stat, firearm_stat) {
  if (stat.stat_size <= 0 || stat.stat_size > 3) {
    throw new Error(`invalid stat_size: ${stat.stat_size}`);
  }

  if (firearm_stat) {
    // 총기 template 복사해서 넣기
    for (const key in firearm_stat) {
      entity[key] = firearm_stat[key];
    }
  }

  // 기타 능력치 적용하기
  entity.speed = 20;
  entity.waypoint_dash_dist = 100;
  entity.aimvar_hold *= Math.exp(project(0.2, -0.6, stat.precision));
  entity.aimvar_hold_max *= Math.exp(project(-0.2, -0.8, stat.precision));
  entity.aimvar_decay_per_tick = project(0.9, 0.76, stat.focus);
  entity.aimvar_incr_per_hit = project(0.2, 0.1, stat.focus);
  entity.crit_prob = project(0.004, 0.08, stat.precision);

  // reaction
  entity.aware_mult = project(0.05, 0.95, stat.reaction);
  entity.response_time = project(0.8, 0.1, stat.reaction);

  // TODO: 얼마나 눈에 띄지 않는가. 현재 연결되지 않음.
  entity.vis_thres = 0.5;

  entity.firearm_reload_idle_visibility_duration = 2.5;
  entity.firearm_reload_idle_duration = 10;

  // TODO: multiplier
  entity.aimvar_incr_per_shoot *= project(1.4, 0.6, stat.focus);

  // 용병 스탯 강화 이터레이션
  entity.hit_prob_covered = project(0.5, 0.3, stat.decision);
  entity.hit_prob_stand = project(0.985, 0.7, stat.reaction);
  entity.hit_prob_obstructed = entity.hit_prob_stand * 0.5;

  entity.firearm_reload_duration = project(2.5, 1.835, stat.focus);

  if (stat.name) {
    entity.name = stat.name;
  }
  entity._stat = stat;
  entity._firearm_stat = firearm_stat;

  return entity;
}

export function entityFromStat2(stat, firearm_stat) {
  const base = { ...ENTITY_CONFIG_TMPL };
  return updateEntityStat2(base, stat, firearm_stat);
}

export function entityFromStat2WithBonus(stat, firearm_stat) {
  const base = { ...ENTITY_CONFIG_TMPL };
  const entity = updateEntityStat2(base, stat, firearm_stat);
  const bonus = STATS2_BONUS;
  for (const key in bonus) {
    for (let i = 0; i < bonus[key].length; i++) {
      if (stat[key] >= bonus[key][i].level) {
        entity[bonus[key][i].perk] = true;
      }
    }
  }
  return entity;
}
