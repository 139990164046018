import { data as source } from '../downloaded/contractoverall.mjs';

export const contractoverall = [];
for (const row of source) {
  const { overall, mult_min, mult_max } = row;
  contractoverall.push({
    overall: 0 | overall,
    mult_min: +mult_min,
    mult_max: +mult_max,
  });
}
