let wasm;

const heap = new Array(128).fill(undefined);

heap.push(undefined, null, true, false);

function getObject(idx) { return heap[idx]; }

let heap_next = heap.length;

function dropObject(idx) {
    if (idx < 132) return;
    heap[idx] = heap_next;
    heap_next = idx;
}

function takeObject(idx) {
    const ret = getObject(idx);
    dropObject(idx);
    return ret;
}

const cachedTextDecoder = (typeof TextDecoder !== 'undefined' ? new TextDecoder('utf-8', { ignoreBOM: true, fatal: true }) : { decode: () => { throw Error('TextDecoder not available') } } );

if (typeof TextDecoder !== 'undefined') { cachedTextDecoder.decode(); };

let cachedUint8Memory0 = null;

function getUint8Memory0() {
    if (cachedUint8Memory0 === null || cachedUint8Memory0.byteLength === 0) {
        cachedUint8Memory0 = new Uint8Array(wasm.memory.buffer);
    }
    return cachedUint8Memory0;
}

function getStringFromWasm0(ptr, len) {
    ptr = ptr >>> 0;
    return cachedTextDecoder.decode(getUint8Memory0().subarray(ptr, ptr + len));
}

function addHeapObject(obj) {
    if (heap_next === heap.length) heap.push(heap.length + 1);
    const idx = heap_next;
    heap_next = heap[idx];

    heap[idx] = obj;
    return idx;
}

function _assertClass(instance, klass) {
    if (!(instance instanceof klass)) {
        throw new Error(`expected instance of ${klass.name}`);
    }
    return instance.ptr;
}

let cachedFloat64Memory0 = null;

function getFloat64Memory0() {
    if (cachedFloat64Memory0 === null || cachedFloat64Memory0.byteLength === 0) {
        cachedFloat64Memory0 = new Float64Array(wasm.memory.buffer);
    }
    return cachedFloat64Memory0;
}

let WASM_VECTOR_LEN = 0;

function passArrayF64ToWasm0(arg, malloc) {
    const ptr = malloc(arg.length * 8, 8) >>> 0;
    getFloat64Memory0().set(arg, ptr / 8);
    WASM_VECTOR_LEN = arg.length;
    return ptr;
}
/**
*/
export class Connectivity {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Connectivity.prototype);
        obj.__wbg_ptr = ptr;

        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_connectivity_free(ptr);
    }
    /**
    * @param {Triangulated} t
    * @param {Float64Array} coords
    * @returns {Connectivity}
    */
    static from(t, coords) {
        _assertClass(t, Triangulated);
        const ptr0 = passArrayF64ToWasm0(coords, wasm.__wbindgen_malloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.connectivity_from(t.__wbg_ptr, ptr0, len0);
        return Connectivity.__wrap(ret);
    }
    /**
    * @returns {Uint16Array}
    */
    connectivity() {
        const ret = wasm.connectivity_connectivity(this.__wbg_ptr);
        return takeObject(ret);
    }
    /**
    * @param {Triangulated} t
    * @param {number} x
    * @param {number} y
    * @returns {Uint16Array}
    */
    reachables(t, x, y) {
        _assertClass(t, Triangulated);
        const ret = wasm.connectivity_reachables(this.__wbg_ptr, t.__wbg_ptr, x, y);
        return takeObject(ret);
    }
}
/**
*/
export class Delaunay {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Delaunay.prototype);
        obj.__wbg_ptr = ptr;

        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_delaunay_free(ptr);
    }
    /**
    * @param {Float64Array} coords
    * @returns {Delaunay}
    */
    static from(coords) {
        const ptr0 = passArrayF64ToWasm0(coords, wasm.__wbindgen_malloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.delaunay_from(ptr0, len0);
        return Delaunay.__wrap(ret);
    }
    /**
    * @returns {Uint16Array}
    */
    neighbors() {
        const ret = wasm.delaunay_neighbors(this.__wbg_ptr);
        return takeObject(ret);
    }
}
/**
*/
export class Simplical {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Simplical.prototype);
        obj.__wbg_ptr = ptr;

        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_simplical_free(ptr);
    }
    /**
    * @returns {Simplical}
    */
    static new() {
        const ret = wasm.simplical_new();
        return Simplical.__wrap(ret);
    }
    /**
    * @returns {Simplical}
    */
    dup() {
        const ret = wasm.simplical_dup(this.__wbg_ptr);
        return Simplical.__wrap(ret);
    }
    /**
    * @param {number} x
    * @param {number} y
    * @param {number} extent_x
    * @param {number} extent_y
    * @param {number} rot
    * @param {number} subdivide
    * @returns {Simplical}
    */
    static from_rect(x, y, extent_x, extent_y, rot, subdivide) {
        const ret = wasm.simplical_from_rect(x, y, extent_x, extent_y, rot, subdivide);
        return Simplical.__wrap(ret);
    }
    /**
    * @param {number} x
    * @param {number} y
    * @param {number} extent_x
    * @param {number} extent_y
    * @param {number} rot
    * @param {number} seglen
    * @returns {Simplical}
    */
    static from_rect_seglen(x, y, extent_x, extent_y, rot, seglen) {
        const ret = wasm.simplical_from_rect_seglen(x, y, extent_x, extent_y, rot, seglen);
        return Simplical.__wrap(ret);
    }
    /**
    * @returns {Float32Array}
    */
    simplices() {
        const ret = wasm.simplical_simplices(this.__wbg_ptr);
        return takeObject(ret);
    }
    /**
    * @param {Simplical} other
    * @returns {Simplical}
    */
    union(other) {
        _assertClass(other, Simplical);
        const ret = wasm.simplical_union(this.__wbg_ptr, other.__wbg_ptr);
        return Simplical.__wrap(ret);
    }
    /**
    * @param {Simplical} other
    * @returns {Simplical}
    */
    intersect(other) {
        _assertClass(other, Simplical);
        const ret = wasm.simplical_intersect(this.__wbg_ptr, other.__wbg_ptr);
        return Simplical.__wrap(ret);
    }
    /**
    * @param {Simplical} other
    * @returns {Simplical}
    */
    subtract(other) {
        _assertClass(other, Simplical);
        const ret = wasm.simplical_subtract(this.__wbg_ptr, other.__wbg_ptr);
        return Simplical.__wrap(ret);
    }
}
/**
*/
export class Triangulated {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Triangulated.prototype);
        obj.__wbg_ptr = ptr;

        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_triangulated_free(ptr);
    }
    /**
    * @param {Simplical} sim
    * @returns {Triangulated}
    */
    static from(sim) {
        _assertClass(sim, Simplical);
        const ret = wasm.triangulated_from(sim.__wbg_ptr);
        return Triangulated.__wrap(ret);
    }
    /**
    * @param {number} x
    * @param {number} y
    * @param {boolean} out_to_in
    * @returns {Visibility}
    */
    visibility(x, y, out_to_in) {
        const ret = wasm.triangulated_visibility(this.__wbg_ptr, x, y, out_to_in);
        return Visibility.__wrap(ret);
    }
    /**
    * @param {Float64Array} coords
    * @returns {Uint16Array}
    */
    connectivity(coords) {
        const ptr0 = passArrayF64ToWasm0(coords, wasm.__wbindgen_malloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.triangulated_connectivity(this.__wbg_ptr, ptr0, len0);
        return takeObject(ret);
    }
}
/**
*/
export class Visibility {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Visibility.prototype);
        obj.__wbg_ptr = ptr;

        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_visibility_free(ptr);
    }
    /**
    * @param {number} limit
    */
    limit(limit) {
        wasm.visibility_limit(this.__wbg_ptr, limit);
    }
    /**
    * @param {Float64Array} points
    */
    clip(points) {
        const ptr0 = passArrayF64ToWasm0(points, wasm.__wbindgen_malloc);
        const len0 = WASM_VECTOR_LEN;
        wasm.visibility_clip(this.__wbg_ptr, ptr0, len0);
    }
    /**
    * @returns {Float32Array}
    */
    serialize() {
        const ret = wasm.visibility_serialize(this.__wbg_ptr);
        return takeObject(ret);
    }
    /**
    * @param {Float64Array} gridinfo
    * @param {Uint8Array} grid
    * @returns {VisibilityResult}
    */
    fill(gridinfo, grid) {
        const ptr0 = passArrayF64ToWasm0(gridinfo, wasm.__wbindgen_malloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.visibility_fill(this.__wbg_ptr, ptr0, len0, addHeapObject(grid));
        return VisibilityResult.__wrap(ret);
    }
    /**
    * @param {Float64Array} coords
    * @returns {Uint8Array}
    */
    visible(coords) {
        const ptr0 = passArrayF64ToWasm0(coords, wasm.__wbindgen_malloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.visibility_visible(this.__wbg_ptr, ptr0, len0);
        return takeObject(ret);
    }
    /**
    * @param {Float64Array} coords
    * @returns {Float32Array}
    */
    raycast(coords) {
        const ptr0 = passArrayF64ToWasm0(coords, wasm.__wbindgen_malloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.visibility_raycast(this.__wbg_ptr, ptr0, len0);
        return takeObject(ret);
    }
}
/**
*/
export class VisibilityResult {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(VisibilityResult.prototype);
        obj.__wbg_ptr = ptr;

        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_visibilityresult_free(ptr);
    }
    /**
    * @returns {number}
    */
    get min_x() {
        const ret = wasm.__wbg_get_visibilityresult_min_x(this.__wbg_ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set min_x(arg0) {
        wasm.__wbg_set_visibilityresult_min_x(this.__wbg_ptr, arg0);
    }
    /**
    * @returns {number}
    */
    get min_y() {
        const ret = wasm.__wbg_get_visibilityresult_min_y(this.__wbg_ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set min_y(arg0) {
        wasm.__wbg_set_visibilityresult_min_y(this.__wbg_ptr, arg0);
    }
    /**
    * @returns {number}
    */
    get max_x() {
        const ret = wasm.__wbg_get_visibilityresult_max_x(this.__wbg_ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set max_x(arg0) {
        wasm.__wbg_set_visibilityresult_max_x(this.__wbg_ptr, arg0);
    }
    /**
    * @returns {number}
    */
    get max_y() {
        const ret = wasm.__wbg_get_visibilityresult_max_y(this.__wbg_ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set max_y(arg0) {
        wasm.__wbg_set_visibilityresult_max_y(this.__wbg_ptr, arg0);
    }
    /**
    * @returns {number}
    */
    get count() {
        const ret = wasm.__wbg_get_visibilityresult_count(this.__wbg_ptr);
        return ret >>> 0;
    }
    /**
    * @param {number} arg0
    */
    set count(arg0) {
        wasm.__wbg_set_visibilityresult_count(this.__wbg_ptr, arg0);
    }
}

async function __wbg_load(module, imports) {
    if (typeof Response === 'function' && module instanceof Response) {
        if (typeof WebAssembly.instantiateStreaming === 'function') {
            try {
                return await WebAssembly.instantiateStreaming(module, imports);

            } catch (e) {
                if (module.headers.get('Content-Type') != 'application/wasm') {
                    console.warn("`WebAssembly.instantiateStreaming` failed because your server does not serve wasm with `application/wasm` MIME type. Falling back to `WebAssembly.instantiate` which is slower. Original error:\n", e);

                } else {
                    throw e;
                }
            }
        }

        const bytes = await module.arrayBuffer();
        return await WebAssembly.instantiate(bytes, imports);

    } else {
        const instance = await WebAssembly.instantiate(module, imports);

        if (instance instanceof WebAssembly.Instance) {
            return { instance, module };

        } else {
            return instance;
        }
    }
}

function __wbg_get_imports() {
    const imports = {};
    imports.wbg = {};
    imports.wbg.__wbindgen_object_drop_ref = function(arg0) {
        takeObject(arg0);
    };
    imports.wbg.__wbg_buffer_085ec1f694018c4f = function(arg0) {
        const ret = getObject(arg0).buffer;
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_newwithbyteoffsetandlength_6da8e527659b86aa = function(arg0, arg1, arg2) {
        const ret = new Uint8Array(getObject(arg0), arg1 >>> 0, arg2 >>> 0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_new_8125e318e6245eed = function(arg0) {
        const ret = new Uint8Array(getObject(arg0));
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_newwithbyteoffsetandlength_31ff1024ef0c63c7 = function(arg0, arg1, arg2) {
        const ret = new Uint16Array(getObject(arg0), arg1 >>> 0, arg2 >>> 0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_new_5000fd84960f2d1e = function(arg0) {
        const ret = new Uint16Array(getObject(arg0));
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_newwithbyteoffsetandlength_69193e31c844b792 = function(arg0, arg1, arg2) {
        const ret = new Float32Array(getObject(arg0), arg1 >>> 0, arg2 >>> 0);
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_new_d086a66d1c264b3f = function(arg0) {
        const ret = new Float32Array(getObject(arg0));
        return addHeapObject(ret);
    };
    imports.wbg.__wbg_setindex_24de8908d99d47eb = function(arg0, arg1, arg2) {
        getObject(arg0)[arg1 >>> 0] = arg2;
    };
    imports.wbg.__wbindgen_throw = function(arg0, arg1) {
        throw new Error(getStringFromWasm0(arg0, arg1));
    };
    imports.wbg.__wbindgen_memory = function() {
        const ret = wasm.memory;
        return addHeapObject(ret);
    };

    return imports;
}

function __wbg_init_memory(imports, maybe_memory) {

}

function __wbg_finalize_init(instance, module) {
    wasm = instance.exports;
    __wbg_init.__wbindgen_wasm_module = module;
    cachedFloat64Memory0 = null;
    cachedUint8Memory0 = null;


    return wasm;
}

function initSync(module) {
    if (wasm !== undefined) return wasm;

    const imports = __wbg_get_imports();

    __wbg_init_memory(imports);

    if (!(module instanceof WebAssembly.Module)) {
        module = new WebAssembly.Module(module);
    }

    const instance = new WebAssembly.Instance(module, imports);

    return __wbg_finalize_init(instance, module);
}

async function __wbg_init(input) {
    if (wasm !== undefined) return wasm;

    if (typeof input === 'undefined') {
        input = new URL('asm_bg.wasm', import.meta.url);
    }
    const imports = __wbg_get_imports();

    if (typeof input === 'string' || (typeof Request === 'function' && input instanceof Request) || (typeof URL === 'function' && input instanceof URL)) {
        input = fetch(input);
    }

    __wbg_init_memory(imports);

    const { instance, module } = await __wbg_load(await input, imports);

    return __wbg_finalize_init(instance, module);
}

export { initSync }
export default __wbg_init;
