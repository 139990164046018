// downloaded data!
export const data = [
  {
    "key": "firearm_unarmed",
    "buy_cost": "0",
    "sell_cost": "0",
    "durability": "-1"
  },
  {
    "key": "firearm_ar_t1",
    "buy_cost": "4000",
    "sell_cost": "800",
    "durability": "-1"
  },
  {
    "key": "firearm_ar_t2",
    "buy_cost": "8000",
    "sell_cost": "1600",
    "durability": "-1"
  },
  {
    "key": "firearm_ar_t3",
    "buy_cost": "12000",
    "sell_cost": "2400",
    "durability": "-1"
  },
  {
    "key": "firearm_ar_t4",
    "buy_cost": "20000",
    "sell_cost": "4000",
    "durability": "-1"
  },
  {
    "key": "firearm_ar_t5",
    "buy_cost": "32000",
    "sell_cost": "6400",
    "durability": "-1"
  },
  {
    "key": "firearm_sg_t1",
    "buy_cost": "3600",
    "sell_cost": "720",
    "durability": "-1"
  },
  {
    "key": "firearm_sg_t2",
    "buy_cost": "7200",
    "sell_cost": "1440",
    "durability": "-1"
  },
  {
    "key": "firearm_sg_t3",
    "buy_cost": "10800",
    "sell_cost": "2160",
    "durability": "-1"
  },
  {
    "key": "firearm_sg_t4",
    "buy_cost": "18000",
    "sell_cost": "3600",
    "durability": "-1"
  },
  {
    "key": "firearm_sg_t5",
    "buy_cost": "28800",
    "sell_cost": "5760",
    "durability": "-1"
  },
  {
    "key": "firearm_dmr_t1",
    "buy_cost": "3000",
    "sell_cost": "600",
    "durability": "-1"
  },
  {
    "key": "firearm_dmr_t2",
    "buy_cost": "6000",
    "sell_cost": "1200",
    "durability": "-1"
  },
  {
    "key": "firearm_dmr_t3",
    "buy_cost": "9000",
    "sell_cost": "1800",
    "durability": "-1"
  },
  {
    "key": "firearm_dmr_t4",
    "buy_cost": "15000",
    "sell_cost": "3000",
    "durability": "-1"
  },
  {
    "key": "firearm_dmr_t5",
    "buy_cost": "24000",
    "sell_cost": "4800",
    "durability": "-1"
  },
  {
    "key": "firearm_smg_t1",
    "buy_cost": "3600",
    "sell_cost": "720",
    "durability": "-1"
  },
  {
    "key": "firearm_smg_t2",
    "buy_cost": "7200",
    "sell_cost": "1440",
    "durability": "-1"
  },
  {
    "key": "firearm_smg_t3",
    "buy_cost": "10800",
    "sell_cost": "2160",
    "durability": "-1"
  },
  {
    "key": "firearm_smg_t4",
    "buy_cost": "18000",
    "sell_cost": "3600",
    "durability": "-1"
  },
  {
    "key": "firearm_smg_t5",
    "buy_cost": "28800",
    "sell_cost": "5760",
    "durability": "-1"
  },
  {
    "key": "firearm_sr_t1",
    "buy_cost": "3600",
    "sell_cost": "720",
    "durability": "-1"
  },
  {
    "key": "firearm_sr_t2",
    "buy_cost": "7200",
    "sell_cost": "1440",
    "durability": "-1"
  },
  {
    "key": "firearm_sr_t3",
    "buy_cost": "10800",
    "sell_cost": "2160",
    "durability": "-1"
  },
  {
    "key": "firearm_sr_t4",
    "buy_cost": "18000",
    "sell_cost": "3600",
    "durability": "-1"
  },
  {
    "key": "firearm_sr_t5",
    "buy_cost": "28800",
    "sell_cost": "5760",
    "durability": "-1"
  },
  {
    "key": "firearm_hg_t1",
    "buy_cost": "0",
    "sell_cost": "0",
    "durability": "-1"
  },
  {
    "key": "firearm_hg_t2",
    "buy_cost": "1000",
    "sell_cost": "200",
    "durability": "-1"
  },
  {
    "key": "firearm_hg_t3",
    "buy_cost": "2000",
    "sell_cost": "400",
    "durability": "-1"
  },
  {
    "key": "firearm_hg_t4",
    "buy_cost": "3000",
    "sell_cost": "600",
    "durability": "-1"
  },
  {
    "key": "vest_bulletproof_nothing",
    "buy_cost": "0",
    "sell_cost": "0",
    "durability": "-1"
  },
  {
    "key": "vest_bulletproof_low",
    "buy_cost": "2000",
    "sell_cost": "400",
    "durability": "-1"
  },
  {
    "key": "vest_bulletproof_mid",
    "buy_cost": "4000",
    "sell_cost": "800",
    "durability": "-1"
  },
  {
    "key": "vest_bulletproof_high",
    "buy_cost": "6000",
    "sell_cost": "1200",
    "durability": "-1"
  },
  {
    "key": "vest_bulletproof_highend",
    "buy_cost": "10000",
    "sell_cost": "2000",
    "durability": "-1"
  },
  {
    "key": "throwable_none",
    "buy_cost": "0",
    "sell_cost": "0",
    "durability": "-1"
  },
  {
    "key": "throwable_fragment",
    "buy_cost": "3000",
    "sell_cost": "600",
    "durability": "-1"
  },
  {
    "key": "throwable_frag_mk1",
    "buy_cost": "3000",
    "sell_cost": "600",
    "durability": "-1"
  },
  {
    "key": "throwable_frag_mk2",
    "buy_cost": "5000",
    "sell_cost": "1000",
    "durability": "-1"
  },
  {
    "key": "throwable_frag_mk3",
    "buy_cost": "7000",
    "sell_cost": "1400",
    "durability": "-1"
  },
  {
    "key": "throwable_frag_demo",
    "buy_cost": "7000",
    "sell_cost": "1400",
    "durability": "-1"
  },
  {
    "key": "throwable_impact_frag_mk1",
    "buy_cost": "3000",
    "sell_cost": "600",
    "durability": "-1"
  },
  {
    "key": "throwable_impact_frag_mk2",
    "buy_cost": "5000",
    "sell_cost": "1000",
    "durability": "-1"
  },
  {
    "key": "throwable_he_mk1",
    "buy_cost": "3000",
    "sell_cost": "600",
    "durability": "-1"
  },
  {
    "key": "throwable_he_mk2",
    "buy_cost": "5000",
    "sell_cost": "1000",
    "durability": "-1"
  },
  {
    "key": "throwable_stun",
    "buy_cost": "1000",
    "sell_cost": "200",
    "durability": "-1"
  },
  {
    "key": "throwable_stun_mk1",
    "buy_cost": "2000",
    "sell_cost": "400",
    "durability": "-1"
  },
  {
    "key": "throwable_stun_mk2",
    "buy_cost": "3000",
    "sell_cost": "600",
    "durability": "-1"
  },
  {
    "key": "throwable_stun_mk3",
    "buy_cost": "4000",
    "sell_cost": "800",
    "durability": "-1"
  },
  {
    "key": "throwable_visibility",
    "buy_cost": "1000",
    "sell_cost": "200",
    "durability": "-1"
  },
  {
    "key": "throwable_smoke",
    "buy_cost": "2000",
    "sell_cost": "400",
    "durability": "-1"
  }
];