export const raw = `perk_grenadier_base	기초 척탄 훈련	%% NOMSG %%	수류탄을 소지하고 투척할 수 있게 됩니다.	1	1	all
perk_grenadier_throw_range	투척거리 강화	수류탄이 더 멀리 날아갑니다.	수류탄의 투척 거리가 20% 증가합니다.	1	1	all
perk_grenadier_amount	투척물 개수 관리	%% NOMSG %%	수류탄의 소지 가능 개수가 1개 증가합니다.	1	1	all
perk_grenadier_shorter_blast	쿠킹	수류탄이 이른 타이밍에 터집니다.	수류탄이 폭발할 때까지 걸리는 시간이 1초 감소합니다.	1	1	all
perk_grenadier_special_1	특수탄 1	%% NOMSG %%	섬광탄을 소지하고 투척할 수 있게 됩니다.	1	1	all
perk_grenadier_effect_amount	투척물 효과 극대화	수류탄의 효과가 증가합니다.	수류탄의 피해량, 상태이상의 지속시간이 25% 증가합니다.	1	1	all
perk_grenadier_special_2	특수탄 2	%% NOMSG %%	연막탄을 소지하고 투척할 수 있게 됩니다.	1	1	all
perk_desmar_base	사수 지정	지정사수가 거리를 두고 행동합니다.	DMR 장비 시 분대와 거리를 두고 행동하게 됩니다.	1	1	dmr
perk_desmar_priority_defensive	방어적 우선순위	위협적인 적을 우선합니다.	사격목표 전환시 아군에게 위협적인 적을 우선합니다.	1	1	all
perk_desmar_distraction	견제 사격	견제 사격으로 이동을 저지합니다.	DMR 사격 시 [장거리 제압] 상태를 유발해 접근하는 적이 오래 포복하게 합니다.	1	1	dmr
perk_desmar_distraction_range	광역 견제	견제 사격으로 이동을 저지합니다.	DMR 사격 시 [장거리 제압]의 효과 범위가 사격 대상 중심 10m가 됩니다.	1	1	dmr
perk_desmar_priority_offensive	공격적 우선순위	피해를 많이 주기를 우선합니다.	사격목표 전환시 가장 많은 피해를 줄 수 있는 적을 우선합니다.	1	1	all
perk_desmar_damage	치명적 사격	피해량이 증가합니다.	DMR 장비 시 명중한 사격의 피해량이 20% 증가합니다.	1	1	dmr
perk_desmar_aimspeed	신선한 사냥감	피해를 입지 않은 적을 빠르게 조준합니다.	DMR 장비 시 생명력 피해를 입지 않은 적을 조준하는 속도가 50% 빨라집니다.	1	1	dmr
perk_commed_base	기초 의무 훈련	%% NOMSG %%	임무당 한 번 다른 용병을 치료해 생명력을 회복할 수 있습니다.	1	1	all
perk_commed_workspeed	의무 숙달 훈련	더 빠르게 치료합니다.	치료 속도가 20% 증가합니다.	1	1	all
perk_commed_count	의무 역량 증대	%% NOMSG %%	임무 당 치료 회수가 1회 증가합니다.	1	1	all
perk_commed_amount	복합 치료	치료양이 증가합니다.	치료로 회복되는 생명력이 20% 증가합니다.	1	1	all
perk_commed_target_self	자가 치료	%% NOMSG %%	임무당 한 번 자기 자신을 치료할 수 있습니다.	1	1	all
perk_commed_risk	과감한 조치	과감하게 상처를 벌린 뒤 치료합니다.	용병의 체력이 충분할 때, 치료양의 50%만큼 피해를 준 다음 200%만큼 회복합니다.	1	1	all
perk_commed_buff	미심쩍은 약물	일시적으로 능력을 상승시킵니다.	치료받은 용병이 다음 피해를 입기 전까지 전투력 2 보너스를 얻습니다.	1	1	all
perk_strike_base	기초 타격 훈련	%% NOMSG %%	정확하게 적을 타격하는 훈련을 받았습니다.	1	1	all
perk_aim_together	견제 활용	아군이 공격하는 적을 함께 공격합니다.	다른 용병이 공격중인 적을 조준하는 속도가 50% 빨라집니다.	1	1	all
perk_retarget_fast	빠른 목표 전환	목표를 빠르게 전환합니다.	대상이 사망한 경우, 1초간 조준 이동 속도가 100% 증가합니다.	1	1	all
perk_aim_execute	마무리	피해를 입은 적을 빠르게 조준합니다.	이미 피해를 입은 적을 조준하는 속도가 50% 빨라집니다.	1	1	all
perk_hit_antiarmor	방탄복 파괴	방탄적에 부수적 피해를 입힙니다.	사격으로 생명력 피해를 입히면 방탄복에도 같은 피해를 줍니다.	1	1	all
perk_hit_disruptive	중심 무너뜨리기	자세를 무너뜨려 엄폐를 해제합니다.	엄폐중인 적에게 명중시 20% 확률로 적이 엄폐에서 해제됩니다.	1	1	all
perk_recoil_steady	흔들리지 않는 손길	탄퍼짐이 감소합니다.	탄퍼짐이 50% 감소합니다.	1	1	all
perk_reduce_cover_effect	노출 부위 탐색	엄폐중인 적의 노출된 부분을 찾습니다.	내 사격이 엄폐중인 적에게 명중할 확률이 50% 증가합니다.	1	1	all
perk_pierce_moving_enemy	움직임 포착	적의 이동을 파악해 사격합니다.	이동중인 적을 사격할 때 장애물을 무시합니다.	1	1	all
perk_piercing_bullet	관통 탄자 운용	관통합니다.	적을 사격할 때 엄폐, 장애물을 무시합니다.	1	1	all
perk_damage_standing	쉬운 목표	적의 급소를 노립니다.	엄폐 없이 서 있는 상태의 적에게 주는 피해량이 15% 증가합니다.	1	1	all
perk_damage_move_crawl	움직이는 목표	적의 급소를 노립니다.	이동 중이거나 포복중인 상태의 적에게 주는 피해량이 15% 증가합니다.	1	1	all
perk_lastshoot	마지막 한 발	탄창의 마지막 탄환을 쏩니다.	재장전 전 마지막 탄환이 엄폐, 장애물을 무시하고 명중하고, 100% 증가한 피해를 줍니다.	1	1	all
perk_supress_base	기초 제압 훈련	%% NOMSG %%	교전 상황에서 화력을 활용해 제압하는 훈련을 받았습니다.	1	1	all
perk_miss_supress	기초 제압	제압합니다.	명중하지 않은 사격이 10% 확률로 조준을 흐트러트립니다.	1	1	all
perk_cover_steady	반동 제어	반동을 제어합니다.	엄폐 중 사격시 반동으로 인한 조준 흔들림이 20% 감소합니다.	1	1	all
perk_hit_incr_aimvar	위협 전문가	조준이 더 크게 흐트러집니다.	유효사격 시 표적의 조준이 100% 더 크게 흐트러집니다.	1	1	all
perk_moving_reload	사전 준비	다음 재장전을 미리 준비해둡니다.	이동 중에 재장전하는 경우, 다음 재장전의 속도가 50% 증가합니다.	1	1	all
perk_crawl_reload	상황 타개	포복 중에 재장전합니다.	포복 중에 재장전한 경우 다음 첫 탄환이 엄폐, 장애물을 무시하고 명중합니다.	1	1	all
perk_cover_reload	안정적 화력	엄폐 중에 재장전합니다.	엄폐중일 때 재장전 속도가 100% 증가합니다.	1	1	all
perk_vision_distance	매의 눈	%% NOMSG %%	시야 거리가 20% 증가합니다.	1	1	all
perk_unidir_sense	직감	시야 밖 상대를 감지합니다.	바라보지 않는 방향의 적을 감지할 수 있습니다.	1	1	all
perk_breach_seethrough	기척 감지	문 건너편의 상대를 파악합니다.	브리칭 시 문에서 가장 가까운 적의 위치가 표시됩니다.	1	1	all
perk_cover_effect	밀착 엄폐	철저한 엄폐로 총알을 피합니다.	엄폐중일 때 피해를 받을 확률이 50% 감소합니다.	1	1	all
perk_cover_dash	신속 엄폐	엄폐 위치까지 질주합니다.	적에게 공격받을 때 엄폐물까지 100% 빠르게 달려갈 수 있습니다.	1	1	all
perk_after_cover	심리전	허를 찔러 이동합니다.	엄폐를 해제하고 이동할 때 1초간 조준 대상이 되지 않습니다.	1	1	all
perk_aggro_base	기초 돌격 훈련	%% NOMSG %%	적의 공격이 무의미하게 소모되도록 유도하는 훈련을 받았습니다.	1	1	all
perk_armor_effect	효과적인 방탄복	방탄복에 요령껏 맞습니다.	방탄복에 맞는 공격의 피해량이 50% 감소합니다.	1	1	all
perk_armor_first	거북이	방탄복에 맞습니다.	방탄복이 유효한 경우, 항상 방탄복에 먼저 명중합니다.	1	1	all
perk_damp_aimvar_incr	아드레날린 분출	조준이 덜 흐트러집니다.	적에게 사격당했을 때 조준이 90% 덜 흐트러지게 됩니다.	1	1	all
perk_engage_dash	돌진본능	돌진합니다.	교전 중 50% 빠르게 움직입니다. 엎드리지 않습니다.	1	1	all
perk_move_cover	현란한 이동	적의 눈을 속이며 이동합니다.	이동 중 공격받을 때 엄폐 효과를 받습니다.	1	1	all
perk_targetpref_high	존재감	적이 우선적으로 공격합니다.	항상 우선적으로 공격받습니다.	1	1	all
perk_recover_armor	방탄복 수리	방탄복을 응급조치합니다.	교전 종료시, 해당 교전에서 방탄복이 받은 피해 중 0~50%를 회복합니다.	1	1	all
perk_recover_life	숨 고르기	숨을 고릅니다.	생명력 피해를 받고 5초간 피해를 더 받지 않으면, 해당 피해의 10%를 회복합니다.	1	1	all
perk_glancing_blow	비껴맞기	덜 아픈 쪽으로 맞습니다.	적에게 사격당해 생명력 피해를 입을 때 50% 확률로 절반의 피해만 입습니다.	1	1	all
perk_standing_evade	총알 탄 사나이	기묘한 자세로 사격을 피합니다.	엄폐 없이 서 있는 동안 50% 확률로 사격이 빗나갑니다.	1	1	all
perk_moving_recoil	떨리는 손	적에게 위압감을 심어주며 이동합니다.	이동 중일 때 자신을 향한 사격의 탄퍼짐이 50% 증가합니다.	1	1	all
perk_cover_reload_evade	은폐 재장전	재장전 중 피격당하지 않습니다.	엄폐하며 재장전중인 동안 피격당하지 않습니다.	1	1	all`;

export const perk_groups = [
  {
    name: 'DMR 전문가',
    keys: "perk_stat_firearm_level_1 perk_lastshoot_hit perk_targetpref_low perk_suppress perk_aimtarget_incr_aimvar".split(' '),
  },
  {
    name: 'AR 전문가',
    keys: "perk_stat_firearm_level_2 perk_kill_recover perk_damp_aimvar_incr perk_instant_reload perk_cover_dash".split(' '),
  },
  {
    name: 'SMG 전문가',
    keys: "perk_stat_firearm_level_3 perk_smg_fast_move perk_instant_reload perk_hit_incr_aimvar perk_unidir_sense".split(' '),
  },
  {
    name: 'SG 전문가',
    keys: "perk_stat_firearm_level_4 perk_armor_recover perk_engage_dash perk_reload_one_more perk_targetpref_high".split(' '),
  },
  {
    name: '특급 사수',
    keys: "perk_firstshoot_hit perk_lastshoot_hit perk_shoot_ignore_obstructed perk_damp_aimvar_incr perk_suppress".split(' '),
  },
  {
    name: '생존 전문가',
    keys: "perk_targetpref_low perk_unidir_sense perk_armor_first perk_cover_reload_evade perk_armor_recover".split(' '),
  },
  {
    name: '도라이',
    keys: "perk_firstshoot_amp perk_lastshoot_amp perk_kill_recover perk_targetpref_high perk_hit_incr_aimvar".split(' '),
  },
  {
    name: '날파리',
    keys: "perk_cover_dash perk_engage_dash perk_smg_fast_move perk_targetpref_low perk_aimtarget_incr_aimvar".split(' '),
  },
];

export const perks = {};

for (const row of raw.split('\n')) {
  const columns = row.split('\t');
  if (columns.length === 0) {
    continue;
  }
  const [key, name, msg, descr, cost, term, available_firearm_ty] = columns;
  perks[key] = {
    key,
    name,
    msg,
    descr,
    cost: 0 | cost,
    term: 0 | term,
    // 모든 화기에 적용 가능한 경우: []
    // 특정 화기에만 적용 가능한 경우: ['sg', 'sr']
    // TODO:
    //  - UI에 관련 정보가 노출되면 좋겠습니다.
    //  - perk이 현재 화기에서 적용 안 되는 경우, 해당 정보가 노출되면 좋겠습니다.
    available_firearm_ty: available_firearm_ty === 'all' ? [] : available_firearm_ty.split(','),
  };
}

const firearm_level_prefix = 'perk_stat_firearm_level_';

export function perk_apply_stats(stats, perk_keys) {
  stats = JSON.parse(JSON.stringify(stats));
  for (const perk_key of perk_keys) {
    const perk = perks[perk_key];
    if (perk.key.startsWith(firearm_level_prefix)) {
      const key = 0 | perk.key.slice(firearm_level_prefix.length);
      stats.stat_firearm_level[key] = stats.stat_firearm_level[key] + 5;
    }
  }
  return stats;
}

export function perk_apply_entity(entity, perk_keys) {
  for (const perk_key of perk_keys) {
    const perk = perks[perk_key];
    if (perk.key.startsWith(firearm_level_prefix)) {
      continue;
    }
    if (perk.available_firearm_ty.length > 0 && perk.available_firearm_ty.indexOf(entity.firearm_ty) === -1) {
      console.log('unavailable');
      continue;
    }

    entity[perk.key] = true;
  }
}
