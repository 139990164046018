import '../style.css';

export function FH1KVP(props) {
  const { 'title': _, ...props0 } = props;
  return <div {...props0} className={`fh1-kvp ${props.className ?? ''}`}>
    <div className='fh1-title'>{props.title}</div>
    <div className='fh1-dash'></div>
    <div className='fh1-content'>
      {props.children}
    </div>
  </div>;
}
