import { items } from './data/google/processor/data_items.mjs'

const tmpl_firearm_base = {
  firearm_projectile_per_shoot: 1,
  aimvar_incr_per_shoot: 1,
  aimvar_incr_per_hit: 1,

  // firearm 종류별, UI 표기에 사용하는 최대 RPM
  firearm_ty_max_rpm: 1200,
  firearm_ty_max_damage: 200,

  /*
  firearm_projectile_damage_prob: [
    { weight: 1, multiplier: 1 },
    { weight: 1, multiplier: 0.3 },
  ],
  */

  movespeed_rules: [
    { reldir: Math.PI * 2, multiplier: 0.5 },
    { reldir: Math.PI / 2, multiplier: 0.8 },
    { reldir: Math.PI / 6, multiplier: 1 },
  ]
}

const tmpl_firearm_ar_base = {
  ...tmpl_firearm_base,

  firearm_ammo_max: 30,
  firearm_range: 320,

  firearm_reload_idle_duration: 10,
  aim_samples_fire_thres: 0.25,

  // 총기별 기본 반동 계수
  aimvar_incr_per_shoot_mult: 0.6,
};

// AR 화기 (5티어 구조)
export const tmpl_firearm_ar_t1 = {
  key: 'firearm_ar_t1',

  firearm_ty: 'ar',
  firearm_name: 'loc_ui_string_common_firearm_assult_rifle_tier_1',
  firearm_rate: 1,
  firearm_cost: 0,

  ...tmpl_firearm_ar_base,

  firearm_aimvar_incr_rot_cap: 0.15,
  firearm_aimvar_incr_move_cap: 0.05,

  firearm_aimvar_mult: 1,

  firearm_shoot_pattern: [60 / 300, 60 / 300],
  firearm_shoot_pattern_interval_sec: 0.75,
  firearm_projectile_damage: 19,

  firearm_projectile_aimvar: Math.PI / 800,
  firearm_reload_duration: 2.8,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 223,
  aimvar_incr_per_shoot: 0.15,
  aimvar_incr_per_hit: 0.25,

  firearm_additional_hit_prob: 0,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.1 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],
};

export const tmpl_firearm_ar_t2 = {
  key: 'firearm_ar_t2',

  firearm_ty: 'ar',
  firearm_name: 'loc_ui_string_common_firearm_assult_rifle_tier_2',
  firearm_rate: 2,
  firearm_cost: 400,

  ...tmpl_firearm_ar_base,

  firearm_aimvar_incr_rot_cap: 0.12,
  firearm_aimvar_incr_move_cap: 0.045,

  firearm_aimvar_mult: 1,

  firearm_shoot_pattern: [60 / 400, 60 / 400],
  firearm_shoot_pattern_interval_sec: 0.65,
  firearm_projectile_damage: 21,

  firearm_projectile_aimvar: Math.PI / 800,
  firearm_reload_duration: 2.7,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 235,
  aimvar_incr_per_shoot: 0.13,
  aimvar_incr_per_hit: 0.25,

  firearm_additional_hit_prob: 0,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.1 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],
};

export const tmpl_firearm_ar_t3 = {
  key: 'firearm_ar_t3',

  firearm_ty: 'ar',
  firearm_name: 'loc_ui_string_common_firearm_assult_rifle_tier_3',
  firearm_rate: 3,
  firearm_cost: 1000,

  ...tmpl_firearm_ar_base,

  firearm_aimvar_incr_rot_cap: 0.09,
  firearm_aimvar_incr_move_cap: 0.04,

  firearm_aimvar_mult: 1,

  firearm_shoot_pattern: [60 / 450, 60 / 450],
  firearm_shoot_pattern_interval_sec: 0.6,
  firearm_projectile_damage: 24,

  firearm_projectile_aimvar: Math.PI / 800,
  firearm_reload_duration: 2.6,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 253,
  aimvar_incr_per_shoot: 0.09,
  aimvar_incr_per_hit: 0.25,

  firearm_additional_hit_prob: 0,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.1 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],
};

export const tmpl_firearm_ar_t4 = {
  key: 'firearm_ar_t4',

  firearm_ty: 'ar',
  firearm_name: 'loc_ui_string_common_firearm_assult_rifle_tier_4',
  firearm_rate: 4,
  firearm_cost: 2000,

  ...tmpl_firearm_ar_base,

  firearm_aimvar_incr_rot_cap: 0.07,
  firearm_aimvar_incr_move_cap: 0.03,

  firearm_aimvar_mult: 1,

  firearm_shoot_pattern: [60 / 500, 60 / 500],
  firearm_shoot_pattern_interval_sec: 0.4,
  firearm_projectile_damage: 28,

  firearm_projectile_aimvar: Math.PI / 800,
  firearm_reload_duration: 2.5,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 278,
  aimvar_incr_per_shoot: 0.07,
  aimvar_incr_per_hit: 0.25,

  firearm_additional_hit_prob: 0,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.11 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],
};

export const tmpl_firearm_ar_t5 = {
  key: 'firearm_ar_t5',

  firearm_ty: 'ar',
  firearm_name: 'loc_ui_string_common_firearm_assult_rifle_tier_5',
  firearm_rate: 5,
  firearm_cost: 3200,

  ...tmpl_firearm_ar_base,

  firearm_aimvar_incr_rot_cap: 0.05,
  firearm_aimvar_incr_move_cap: 0.02,

  firearm_aimvar_mult: 1,

  firearm_shoot_pattern: [60 / 600],
  firearm_shoot_pattern_interval_sec: 0.3,
  firearm_projectile_damage: 31,

  firearm_projectile_aimvar: Math.PI / 820,
  firearm_reload_duration: 2.5,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 310,
  aimvar_incr_per_shoot: 0.06,
  aimvar_incr_per_hit: 0.2,

  firearm_additional_hit_prob: 0,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.12 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],
};

export const tmpl_firearm_ar_cheat = {
  firearm_ty: 'ar',
  firearm_name: 'C7A1',
  firearm_rate: 5,
  firearm_cost: 3200,

  ...tmpl_firearm_ar_base,

  firearm_projectile_damage: 50,

  firearm_aimvar_incr_rot_cap: 0.05,
  firearm_aimvar_incr_move_cap: 0.025,

  firearm_aimvar_mult: 1,

  firearm_shoot_pattern: [0.1, 0.1],
  firearm_shoot_pattern_interval_sec: 0.2,

  firearm_projectile_aimvar: Math.PI / 800,
  firearm_reload_duration: 2.5,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 310,
  aimvar_incr_per_shoot: 0.06,
  aimvar_incr_per_hit: 0.2,

  firearm_additional_hit_prob: 0,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.12 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],
};

// 아군 에이전트 화기 (AR)
// 낮은 스펙의 AR. 레퍼런스는 GAU-5입니다.
export const tmpl_firearm_ar_low = {
  firearm_ty: 'ar',
  firearm_name: 'GAU-5',
  firearm_rate: 1,
  firearm_cost: 0,

  ...tmpl_firearm_ar_base,

  // 피카티니 레일이 없음. 광학장비를 달수 없으니 rot_cap 값을 높여둡니다.
  firearm_aimvar_incr_rot_cap: 0.15,
  firearm_aimvar_incr_move_cap: 0.05,

  firearm_aimvar_mult: 1.15,

  firearm_shoot_pattern: [0.25, 0.25],
  firearm_shoot_pattern_interval_sec: 0.75,

  firearm_projectile_aimvar: Math.PI / 512,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 256,
  aimvar_incr_per_shoot: 0.15,
  aimvar_incr_per_hit: 0.25,

  firearm_additional_hit_prob: 0,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.1 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],
};


// 중간 스펙의 AR 레퍼런스는 M16A4 MWS 입니다.
export const tmpl_firearm_ar_mid = {
  firearm_ty: 'ar',
  firearm_name: 'M16A4 MWS',
  firearm_rate: 2,
  firearm_cost: 400,

  ...tmpl_firearm_ar_base,

  firearm_aimvar_incr_rot_cap: 0.08,
  firearm_aimvar_incr_move_cap: 0.04,

  firearm_aimvar_mult: 1,

  firearm_shoot_pattern: [0.15, 0.15],
  firearm_shoot_pattern_interval_sec: 0.6,

  firearm_projectile_aimvar: 0,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 256,
  aimvar_incr_per_shoot: 0.08,
  aimvar_incr_per_hit: 0.25,

  firearm_additional_hit_prob: 0.1,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.1 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],
};


// 높은 스펙의 AR 레퍼런스는 HK416 입니다.
export const tmpl_firearm_ar_high = {
  firearm_ty: 'ar',
  firearm_name: 'HK416',
  firearm_rate: 3,
  firearm_cost: 800,

  ...tmpl_firearm_ar_base,

  firearm_aimvar_incr_rot_cap: 0.05,
  firearm_aimvar_incr_move_cap: 0.025,

  firearm_aimvar_mult: 0.8,

  firearm_shoot_pattern: [0.1],
  firearm_shoot_pattern_interval_sec: 0.25,

  firearm_projectile_aimvar: 0,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 256,
  aimvar_incr_per_shoot: 0.06,
  aimvar_incr_per_hit: 0.2,

  firearm_additional_hit_prob: 0.15,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.12 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],
};

const tmpl_firearm_sg_base = {
  ...tmpl_firearm_base,

  firearm_reload_idle_duration: 10,

  firearm_projectile_per_shoot: 12,
  aim_samples_fire_thres: 0.7,

  // 총기별 기본 반동 계수
  aimvar_incr_per_shoot_mult: 2,

  // firearm 종류별, UI 표기에 사용하는 최대 RPM
  firearm_ty_max_rpm: 40,
  firearm_ty_max_damage: 40,
};

export const tmpl_firearm_sg_t1 = {
  key: 'firearm_sg_t1',

  firearm_ty: 'sg',
  firearm_name: 'loc_ui_string_common_firearm_shotgun_tier_1',
  firearm_rate: 1,
  firearm_cost: 0,

  ...tmpl_firearm_sg_base,

  firearm_aimvar_incr_rot_cap: 0.05,
  firearm_aimvar_incr_move_cap: 0.05,

  firearm_ammo_max: 4,
  firearm_range: 160,
  firearm_aimvar_mult: 1,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 2.5,
  firearm_projectile_damage: 13,
  firearm_reload_duration: 4.5,

  // 산탄 초크 같은걸 달면 펠럿이 퍼지는 범위를 줄일 수 있기 때문에 나중에 부착물이나 퍽으로 제어하는게 이상하진 않을 것으로 예상
  firearm_projectile_aimvar: Math.PI / 130,

  aimvar_hold: Math.PI / 90,
  aimvar_hold_max: Math.PI / 250,
  aimvar_incr_per_shoot: 1.5,
  aimvar_incr_per_hit: 0.15,

  firearm_additional_hit_prob: 0.35,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.11 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],
};

export const tmpl_firearm_sg_t2 = {
  key: 'firearm_sg_t2',

  firearm_ty: 'sg',
  firearm_name: 'loc_ui_string_common_firearm_shotgun_tier_2',
  firearm_rate: 2,
  firearm_cost: 200,

  ...tmpl_firearm_sg_base,

  firearm_aimvar_incr_rot_cap: 0.05,
  firearm_aimvar_incr_move_cap: 0.05,

  firearm_ammo_max: 5,
  firearm_range: 165,
  firearm_aimvar_mult: 1,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 2.2,
  firearm_projectile_damage: 15,
  firearm_reload_duration: 4.5,

  // 산탄 초크 같은걸 달면 펠럿이 퍼지는 범위를 줄일 수 있기 때문에 나중에 부착물이나 퍽으로 제어하는게 이상하진 않을 것으로 예상
  firearm_projectile_aimvar: Math.PI / 130,

  aimvar_hold: Math.PI / 90,
  aimvar_hold_max: Math.PI / 250,
  aimvar_incr_per_shoot: 1.35,
  aimvar_incr_per_hit: 0.15,

  firearm_additional_hit_prob: 0.35,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.11 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],
};

export const tmpl_firearm_sg_t3 = {
  key: 'firearm_sg_t3',

  firearm_ty: 'sg',
  firearm_name: 'loc_ui_string_common_firearm_shotgun_tier_3',
  firearm_rate: 3,
  firearm_cost: 800,

  ...tmpl_firearm_sg_base,

  firearm_aimvar_incr_rot_cap: 0.05,
  firearm_aimvar_incr_move_cap: 0.05,

  firearm_ammo_max: 7,
  firearm_range: 165,
  firearm_aimvar_mult: 1,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 2,
  firearm_projectile_damage: 18,
  firearm_reload_duration: 4.5,

  // 산탄 초크 같은걸 달면 펠럿이 퍼지는 범위를 줄일 수 있기 때문에 나중에 부착물이나 퍽으로 제어하는게 이상하진 않을 것으로 예상
  firearm_projectile_aimvar: Math.PI / 130,

  aimvar_hold: Math.PI / 90,
  aimvar_hold_max: Math.PI / 250,
  aimvar_incr_per_shoot: 1.3,
  aimvar_incr_per_hit: 0.15,

  firearm_additional_hit_prob: 0.35,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.115 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],
};

export const tmpl_firearm_sg_t4 = {
  key: 'firearm_sg_t4',

  firearm_ty: 'sg',
  firearm_name: 'loc_ui_string_common_firearm_shotgun_tier_4',
  firearm_rate: 4,
  firearm_cost: 1600,

  ...tmpl_firearm_sg_base,

  firearm_aimvar_incr_rot_cap: 0.05,
  firearm_aimvar_incr_move_cap: 0.05,

  firearm_ammo_max: 7,
  firearm_range: 165,
  firearm_aimvar_mult: 1,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 1.85,
  firearm_projectile_damage: 21,
  firearm_reload_duration: 4,

  // 산탄 초크 같은걸 달면 펠럿이 퍼지는 범위를 줄일 수 있기 때문에 나중에 부착물이나 퍽으로 제어하는게 이상하진 않을 것으로 예상
  firearm_projectile_aimvar: Math.PI / 130,

  aimvar_hold: Math.PI / 90,
  aimvar_hold_max: Math.PI / 250,
  aimvar_incr_per_shoot: 1.25,
  aimvar_incr_per_hit: 0.15,

  firearm_additional_hit_prob: 0.35,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.115 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],
};

export const tmpl_firearm_sg_t5 = {
  key: 'firearm_sg_t5',

  firearm_ty: 'sg',
  firearm_name: 'loc_ui_string_common_firearm_shotgun_tier_5',
  firearm_rate: 5,
  firearm_cost: 2600,

  ...tmpl_firearm_sg_base,

  firearm_aimvar_incr_rot_cap: 0.035,
  firearm_aimvar_incr_move_cap: 0.035,

  firearm_ammo_max: 8,
  firearm_range: 165,
  firearm_aimvar_mult: 1,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 1.7,
  firearm_projectile_damage: 25,
  firearm_reload_duration: 4,

  // 산탄 초크 같은걸 달면 펠럿이 퍼지는 범위를 줄일 수 있기 때문에 나중에 부착물이나 퍽으로 제어하는게 이상하진 않을 것으로 예상
  firearm_projectile_aimvar: Math.PI / 145,

  aimvar_hold: Math.PI / 100,
  aimvar_hold_max: Math.PI / 260,
  aimvar_incr_per_shoot: 1.15,
  aimvar_incr_per_hit: 0.15,

  firearm_additional_hit_prob: 0.35,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.12 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],
};

// 아군 에이전트 화기 (SG)
// 낮은 스펙의 SG. 레퍼런스는 M870입니다.
export const tmpl_firearm_sg_low = {
  firearm_ty: 'sg',
  firearm_name: 'M870',
  firearm_rate: 1,
  firearm_cost: 0,

  ...tmpl_firearm_sg_base,

  firearm_aimvar_incr_rot_cap: 0.1,
  firearm_aimvar_incr_move_cap: 0.03,

  firearm_ammo_max: 5,
  firearm_range: 100,
  firearm_aimvar_mult: 1,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 2,

  // 산탄 초크 같은걸 달면 펠럿이 퍼지는 범위를 줄일 수 있기 때문에 나중에 부착물이나 퍽으로 제어하는게 이상하진 않을 것으로 예상
  firearm_projectile_aimvar: Math.PI / 52,

  aimvar_hold: Math.PI / 75,
  aimvar_hold_max: Math.PI / 90,
  aimvar_incr_per_shoot: 1.5,
  aimvar_incr_per_hit: 0.2,

  firearm_additional_hit_prob: 0.35,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.11 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],
};


// 중간 스펙의 SG. 레퍼런스는 M1014입니다.
export const tmpl_firearm_sg_mid = {
  firearm_ty: 'sg',
  firearm_name: 'M1014',
  firearm_rate: 2,
  firearm_cost: 400,

  ...tmpl_firearm_sg_base,

  firearm_aimvar_incr_rot_cap: 0.08,
  firearm_aimvar_incr_move_cap: 0.02,

  firearm_ammo_max: 7,
  firearm_range: 115,
  firearm_aimvar_mult: 1,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 1.85,

  // 산탄 초크 같은걸 달면 펠럿이 퍼지는 범위를 줄일 수 있기 때문에 나중에 부착물이나 퍽으로 제어하는게 이상하진 않을 것으로 예상
  firearm_projectile_aimvar: Math.PI / 56,

  firearm_reload_duration: 4.5,

  aimvar_hold: Math.PI / 80,
  aimvar_hold_max: Math.PI / 100,
  aimvar_incr_per_shoot: 1.25,
  aimvar_incr_per_hit: 0.2,

  firearm_additional_hit_prob: 0.35,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.12 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],
};

// 높은 스펙의 SG. 레퍼런스는 AA-12입니다.
export const tmpl_firearm_sg_high = {
  firearm_ty: 'sg',
  firearm_name: 'AA-12',
  firearm_rate: 3,
  firearm_cost: 800,

  ...tmpl_firearm_sg_base,

  firearm_aimvar_incr_rot_cap: 0.06,
  firearm_aimvar_incr_move_cap: 0.015,

  firearm_ammo_max: 10,
  firearm_range: 130,
  firearm_aimvar_mult: 1,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 1.7,

  // 산탄 초크 같은걸 달면 펠럿이 퍼지는 범위를 줄일 수 있기 때문에 나중에 부착물이나 퍽으로 제어하는게 이상하진 않을 것으로 예상
  firearm_projectile_aimvar: Math.PI / 60,

  firearm_reload_duration: 4,

  aimvar_hold: Math.PI / 85,
  aimvar_hold_max: Math.PI / 105,
  aimvar_incr_per_shoot: 1.15,
  aimvar_incr_per_hit: 0.2,

  firearm_additional_hit_prob: 0.35,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.12 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],
};

const tmpl_firearm_dmr_base = {
  ...tmpl_firearm_base,

  firearm_aimvar_mult: 1,
  firearm_reload_idle_duration: 10,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 820,

  // 총기별 기본 반동 계수
  aimvar_incr_per_shoot_mult: 5,

  // firearm 종류별, UI 표기에 사용하는 최대 RPM
  firearm_ty_max_rpm: 30,
};

export const tmpl_firearm_dmr_t1 = {
  key: 'firearm_dmr_t1',

  firearm_ty: 'dmr',
  firearm_name: 'loc_ui_string_common_firearm_designated_marksman_rifle_tier_1',
  firearm_rate: 1,
  firearm_cost: 0,

  ...tmpl_firearm_dmr_base,

  firearm_ammo_max: 10,
  firearm_range: 600,

  firearm_aimvar_incr_rot_cap: 0.5,
  firearm_aimvar_incr_move_cap: 0.4,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 3.5,

  firearm_projectile_damage: 44,
  firearm_projectile_aimvar: Math.PI / 920,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 820,

  aimvar_incr_per_shoot: 1.5,
  aimvar_incr_per_hit: 1,

  firearm_reload_duration: 5.5,

  aim_samples_fire_thres: 0.8,
  firearm_additional_hit_prob: 0.15,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.06 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],

  vis_range: 600,
};

export const tmpl_firearm_dmr_t2 = {
  key: 'firearm_dmr_t2',

  firearm_ty: 'dmr',
  firearm_name: 'loc_ui_string_common_firearm_designated_marksman_rifle_tier_2',
  firearm_rate: 2,
  firearm_cost: 0,

  ...tmpl_firearm_dmr_base,

  firearm_ammo_max: 10,
  firearm_range: 610,

  firearm_aimvar_incr_rot_cap: 0.475,
  firearm_aimvar_incr_move_cap: 0.35,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 3.25,

  firearm_projectile_damage: 49,
  firearm_projectile_aimvar: Math.PI / 920,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 863,

  aimvar_incr_per_shoot: 1.48,
  aimvar_incr_per_hit: 1,

  firearm_reload_duration: 5.4,

  aim_samples_fire_thres: 0.8,
  firearm_additional_hit_prob: 0.15,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.06 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],

  vis_range: 610,
};

export const tmpl_firearm_dmr_t3 = {
  key: 'firearm_dmr_t3',

  firearm_ty: 'dmr',
  firearm_name: 'loc_ui_string_common_firearm_designated_marksman_rifle_tier_3',
  firearm_rate: 3,
  firearm_cost: 0,

  ...tmpl_firearm_dmr_base,

  firearm_ammo_max: 10,
  firearm_range: 625,

  firearm_aimvar_incr_rot_cap: 0.45,
  firearm_aimvar_incr_move_cap: 0.3,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 3,

  firearm_projectile_damage: 55,
  firearm_projectile_aimvar: Math.PI / 920,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 911,

  aimvar_incr_per_shoot: 1.42,
  aimvar_incr_per_hit: 1,

  firearm_reload_duration: 5.3,

  aim_samples_fire_thres: 0.8,
  firearm_additional_hit_prob: 0.15,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.065 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],

  vis_range: 625,
};

export const tmpl_firearm_dmr_t4 = {
  key: 'firearm_dmr_t4',

  firearm_ty: 'dmr',
  firearm_name: 'loc_ui_string_common_firearm_designated_marksman_rifle_tier_4',
  firearm_rate: 4,
  firearm_cost: 0,

  ...tmpl_firearm_dmr_base,

  firearm_ammo_max: 20,
  firearm_range: 635,

  firearm_aimvar_incr_rot_cap: 0.425,
  firearm_aimvar_incr_move_cap: 0.2,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 3,

  firearm_projectile_damage: 65,
  firearm_projectile_aimvar: Math.PI / 920,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 965,

  aimvar_incr_per_shoot: 1.4,
  aimvar_incr_per_hit: 1,

  firearm_reload_duration: 5.1,

  aim_samples_fire_thres: 0.8,
  firearm_additional_hit_prob: 0.15,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.07 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],

  vis_range: 635,
};

export const tmpl_firearm_dmr_t5 = {
  key: 'firearm_dmr_t5',

  firearm_ty: 'dmr',
  firearm_name: 'loc_ui_string_common_firearm_designated_marksman_rifle_tier_5',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_dmr_base,

  firearm_ammo_max: 20,
  firearm_range: 650,

  firearm_aimvar_incr_rot_cap: 0.4,
  firearm_aimvar_incr_move_cap: 0.18,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 2.75,

  firearm_projectile_damage: 74,
  firearm_projectile_aimvar: Math.PI / 920,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 1000,

  aimvar_incr_per_shoot: 1.3,
  aimvar_incr_per_hit: 1,

  firearm_reload_duration: 5,

  aim_samples_fire_thres: 0.8,
  firearm_additional_hit_prob: 0.15,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.08 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],

  vis_range: 650,
};

// SVD 레퍼런스. 비싼 매치 그레이드 탄을 사용하지 않는다는 설정이라 성능이 다소 낮습니다.
// 7.62×51mm 10발 들이 박스탄창 사용하는 모델
// 방탄복을 사용하지 않는 적을 2발 명중으로 사살할 수 있습니다.
export const tmpl_firearm_dmr_low = {
  firearm_ty: 'dmr',
  firearm_name: 'SVD',
  firearm_rate: 1,
  firearm_cost: 0,

  ...tmpl_firearm_dmr_base,

  firearm_ammo_max: 10,
  firearm_range: 600,

  firearm_aimvar_mult: 0.75,
  firearm_aimvar_incr_rot_cap: 0.5,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 3.25,

  firearm_projectile_damage: 66,
  firearm_projectile_aimvar: Math.PI / 900,

  firearm_reload_duration: 5.5,

  aim_samples_fire_thres: 0.8,

  firearm_additional_hit_prob: 0.15,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.08 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],

  vis_range: 600,
};

// SR-25 E2 레퍼런스. 7.62탄 10발들이 박스탄창을 사용합니다.
// 33짜리 방탄복을 사용하는 적을 2발 안에 제압합니다.
export const tmpl_firearm_dmr_mid = {
  firearm_ty: 'dmr',
  firearm_name: 'SR-25 E2',
  firearm_rate: 2,
  firearm_cost: 450,

  ...tmpl_firearm_dmr_base,

  firearm_ammo_max: 10,
  firearm_range: 620,

  firearm_aimvar_mult: 0.7,
  firearm_aimvar_incr_rot_cap: 0.45,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 3,

  firearm_projectile_damage: 67,
  firearm_projectile_aimvar: Math.PI / 940,

  firearm_reload_duration: 5,

  aim_samples_fire_thres: 0.8,

  firearm_additional_hit_prob: 0.15,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.08 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],

  vis_range: 620,
};

// SCAR-H 레퍼런스. 7.62탄 20발들이 박스탄창을 사용합니다.
// 33짜리 방탄복을 사용하는 적을 2발 안에 제압합니다.
// SR-25 보다 신뢰성이 높고 사거리가 더 깁니다.
export const tmpl_firearm_dmr_high = {
  firearm_ty: 'dmr',
  firearm_name: 'SCAR',
  firearm_rate: 3,
  firearm_cost: 900,

  ...tmpl_firearm_dmr_base,

  firearm_ammo_max: 20,
  firearm_range: 650,

  firearm_aimvar_mult: 0.65,
  firearm_aimvar_incr_rot_cap: 0.4,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 2.75,

  firearm_projectile_damage: 67,
  firearm_projectile_aimvar: Math.PI / 1000,

  firearm_reload_duration: 5,

  aim_samples_fire_thres: 0.85,

  firearm_additional_hit_prob: 0.2,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.08 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],

  vis_range: 650,
};

const tmpl_firearm_smg_base = {
  ...tmpl_firearm_base,

  firearm_reload_idle_duration: 10,

  aim_samples_fire_thres: 0.2,

  movespeed_rules: [
    // 기타: 옆뒤 혹은 뒤로 움직일 때, 10%로 이동합니다.
    { reldir: Math.PI * 2, multiplier: 0.1 },
    // 진행방향 좌/우 90도 안쪽을 바라볼 때 (옆걸음), 30%로 이동합니다.
    { reldir: Math.PI / 2, multiplier: 0.3 },
    // 진행방향 좌/우 30도 안쪽을 바라볼 때, 정상 속도로 이동합니다.
    { reldir: Math.PI / 6, multiplier: 1 },
  ],

  // 총기별 기본 반동 계수
  aimvar_incr_per_shoot_mult: 0.2,
};

export const tmpl_firearm_smg_t1 = {
  key: 'firearm_smg_t1',

  firearm_ty: 'smg',
  firearm_name: 'loc_ui_string_common_firearm_submachine_gun_tier_1',
  firearm_rate: 1,
  firearm_cost: 0,

  ...tmpl_firearm_smg_base,

  firearm_aimvar_incr_rot_cap: 0.15,
  firearm_aimvar_incr_move_cap: 0.03,

  firearm_ammo_max: 32,
  firearm_range: 200,
  firearm_aimvar_mult: 1,
  firearm_reload_duration: 2.2,

  firearm_shoot_pattern: [60 / 500, 60 / 500, 60 / 500, 60 / 500, 60 / 500, 60 / 500, 60 / 500],
  firearm_shoot_pattern_interval_sec: 0.25,

  firearm_projectile_damage: 13,
  firearm_projectile_aimvar: Math.PI / 400,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 204,
  aimvar_incr_per_shoot: 0.15,
  aimvar_incr_per_hit: 0.15,

  firearm_additional_hit_prob: 0,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.18 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],
};

export const tmpl_firearm_smg_t2 = {
  key: 'firearm_smg_t2',

  firearm_ty: 'smg',
  firearm_name: 'loc_ui_string_common_firearm_submachine_gun_tier_2',
  firearm_rate: 2,
  firearm_cost: 0,

  ...tmpl_firearm_smg_base,

  firearm_aimvar_incr_rot_cap: 0.135,
  firearm_aimvar_incr_move_cap: 0.025,

  firearm_ammo_max: 34,
  firearm_range: 220,
  firearm_aimvar_mult: 1,
  firearm_reload_duration: 2,

  firearm_shoot_pattern: [60 / 550, 60 / 550, 60 / 550,],
  firearm_shoot_pattern_interval_sec: 0.22,

  firearm_projectile_damage: 15,
  firearm_projectile_aimvar: Math.PI / 450,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 226,
  aimvar_incr_per_shoot: 0.1,
  aimvar_incr_per_hit: 0.15,

  firearm_additional_hit_prob: 0,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.18 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],
};

export const tmpl_firearm_smg_t3 = {
  key: 'firearm_smg_t3',

  firearm_ty: 'smg',
  firearm_name: 'loc_ui_string_common_firearm_submachine_gun_tier_3',
  firearm_rate: 3,
  firearm_cost: 0,

  ...tmpl_firearm_smg_base,

  firearm_aimvar_incr_rot_cap: 0.12,
  firearm_aimvar_incr_move_cap: 0.02,

  firearm_ammo_max: 40,
  firearm_range: 250,
  firearm_aimvar_mult: 1,
  firearm_reload_duration: 1.8,

  firearm_shoot_pattern: [60 / 550, 60 / 550, 60 / 550, 60 / 550,],
  firearm_shoot_pattern_interval_sec: 0.18,

  firearm_projectile_damage: 17,
  firearm_projectile_aimvar: Math.PI / 450,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 248,
  aimvar_incr_per_shoot: 0.08,
  aimvar_incr_per_hit: 0.15,

  firearm_additional_hit_prob: 0,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.19 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],
};

export const tmpl_firearm_smg_t4 = {
  key: 'firearm_smg_t4',

  firearm_ty: 'smg',
  firearm_name: 'loc_ui_string_common_firearm_submachine_gun_tier_4',
  firearm_rate: 4,
  firearm_cost: 0,

  ...tmpl_firearm_smg_base,

  firearm_aimvar_incr_rot_cap: 0.11,
  firearm_aimvar_incr_move_cap: 0.015,

  firearm_ammo_max: 32,
  firearm_range: 250,
  firearm_aimvar_mult: 1,
  firearm_reload_duration: 1.7,

  firearm_shoot_pattern: [60 / 550, 60 / 550, 60 / 550, 60 / 550,],
  firearm_shoot_pattern_interval_sec: 0.15,

  firearm_projectile_damage: 19,
  firearm_projectile_aimvar: Math.PI / 450,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 261,
  aimvar_incr_per_shoot: 0.06,
  aimvar_incr_per_hit: 0.15,

  firearm_additional_hit_prob: 0,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.195 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],
};

export const tmpl_firearm_smg_t5 = {
  key: 'firearm_smg_t5',

  firearm_ty: 'smg',
  firearm_name: 'loc_ui_string_common_firearm_submachine_gun_tier_5',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_smg_base,

  firearm_aimvar_incr_rot_cap: 0.1,
  firearm_aimvar_incr_move_cap: 0.01,
  firearm_reload_duration: 1.6,

  firearm_ammo_max: 40,
  firearm_range: 260,
  firearm_aimvar_mult: 1,

  firearm_shoot_pattern: [60 / 800, 60 / 800, 60 / 800, 60 / 800,],
  firearm_shoot_pattern_interval_sec: 0.15,

  firearm_projectile_damage: 22,
  firearm_projectile_aimvar: Math.PI / 450,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 283,
  aimvar_incr_per_shoot: 0.05,
  aimvar_incr_per_hit: 0.15,

  firearm_additional_hit_prob: 0,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.2 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],
};

// MAC-10, 9x19 파라벨럼 32발들이 탄창 사용하는 SMG
// 개머리판이 매우 부실한데, RPM까지 높아서 때문에 반동제어가 어렵습니다.
export const tmpl_firearm_smg_low = {
  firearm_ty: 'smg',
  firearm_name: 'MAC-10',
  firearm_rate: 1,
  firearm_cost: 0,

  ...tmpl_firearm_smg_base,

  firearm_aimvar_incr_rot_cap: 0.15,

  firearm_ammo_max: 32,
  firearm_range: 200,
  firearm_aimvar_mult: 1.2,

  firearm_shoot_pattern: [0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05],
  firearm_shoot_pattern_interval_sec: 0.25,

  firearm_projectile_damage: 24,
  firearm_projectile_aimvar: Math.PI / 256,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 256,
  aimvar_incr_per_shoot: 0.1,
  aimvar_incr_per_hit: 0.15,

  firearm_additional_hit_prob: 0,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.18 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],
};

// MP5A5, 9x19 파라벨럼 40발들이 바나나 탄창 사용하는 SMG
// 적당한 수준의 RPM, 낮은 반동. 무난하게 사용하기 좋은 SMG입니다.
export const tmpl_firearm_smg_mid = {
  firearm_ty: 'smg',
  firearm_name: 'MP5A5',
  firearm_rate: 2,
  firearm_cost: 300,

  ...tmpl_firearm_smg_base,

  firearm_aimvar_incr_rot_cap: 0.12,

  firearm_ammo_max: 40,
  firearm_range: 250,
  firearm_aimvar_mult: 1,

  firearm_shoot_pattern: [0.1, 0.1, 0.1, 0.1],
  firearm_shoot_pattern_interval_sec: 0.15,

  firearm_projectile_damage: 25,
  firearm_projectile_aimvar: Math.PI / 448,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 256,
  aimvar_incr_per_shoot: 0.07,
  aimvar_incr_per_hit: 0.15,

  firearm_additional_hit_prob: 0,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.18 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],
};

// MPX, 9x19 파라벨럼 35발들이 바나나 탄창 사용하는 SMG
// MP5A5 대비 반동제어가 더 쉬워졌기 때문에 RPM이 더 높아져도 사용성을 해치지 않도록 디자인 되었습니다.
export const tmpl_firearm_smg_high = {
  firearm_ty: 'smg',
  firearm_name: 'MPX',
  firearm_rate: 3,
  firearm_cost: 650,

  ...tmpl_firearm_smg_base,

  firearm_aimvar_incr_rot_cap: 0.1,

  firearm_ammo_max: 35,
  firearm_range: 280,
  firearm_aimvar_mult: 0.9,

  firearm_shoot_pattern: [0.075, 0.075, 0.075, 0.075],
  firearm_shoot_pattern_interval_sec: 0.1,

  firearm_projectile_damage: 25,
  firearm_projectile_aimvar: Math.PI / 448,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 256,
  aimvar_incr_per_shoot: 0.04,
  aimvar_incr_per_hit: 0.15,

  firearm_additional_hit_prob: 0.1,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.19 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],
};

const tmpl_firearm_sr_base = {
  ...tmpl_firearm_base,

  firearm_range: 1000,

  firearm_shoot_pattern: [],
  firearm_projectile_aimvar: 0,

  firearm_reload_idle_duration: 10,

  aimvar_hold: Math.PI / 32,
  aimvar_incr_per_shoot: 100,
  aimvar_incr_per_hit: 10,

  aim_samples_fire_thres: 0.9,

  // 총기별 기본 반동 계수
  aimvar_incr_per_shoot_mult: 120,

  // firearm 종류별, UI 표기에 사용하는 최대 RPM
  firearm_ty_max_rpm: 10,
};

export const tmpl_firearm_sr_t1 = {
  key: 'firearm_sr_t1',

  firearm_ty: 'sr',
  firearm_name: 'loc_ui_string_common_firearm_sniper_rifle_tier_1',
  firearm_rate: 1,
  firearm_cost: 0,

  ...tmpl_firearm_sr_base,

  firearm_ammo_max: 3,
  firearm_range: 900,

  firearm_aimvar_incr_rot_cap: 0.65,
  firearm_aimvar_incr_move_cap: 1,

  firearm_projectile_damage: 100,
  firearm_reload_duration: 9,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 9,

  aimvar_hold_max: Math.PI / 900,

  aimvar_incr_per_shoot: 100,
  aimvar_incr_per_hit: 10,

  firearm_additional_hit_prob: 0.3,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.007 },
    { aimvar: Math.PI * 2, aimspeed: 0.07 },
  ],

  // TODO
  vis_range: 900,
};

export const tmpl_firearm_sr_t2 = {
  key: 'firearm_sr_t2',

  firearm_ty: 'sr',
  firearm_name: 'loc_ui_string_common_firearm_sniper_rifle_tier_2',
  firearm_rate: 2,
  firearm_cost: 0,

  ...tmpl_firearm_sr_base,

  firearm_ammo_max: 5,
  firearm_range: 910,

  firearm_aimvar_incr_rot_cap: 0.6,
  firearm_aimvar_incr_move_cap: 1,

  firearm_projectile_damage: 100,
  firearm_reload_duration: 9,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 8.75,

  aimvar_hold_max: Math.PI / 978,

  aimvar_incr_per_shoot: 100,
  aimvar_incr_per_hit: 10,

  firearm_additional_hit_prob: 0.3,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.0075 },
    { aimvar: Math.PI * 2, aimspeed: 0.07 },
  ],

  // TODO
  vis_range: 910,
};

export const tmpl_firearm_sr_t3 = {
  key: 'firearm_sr_t3',

  firearm_ty: 'sr',
  firearm_name: 'loc_ui_string_common_firearm_sniper_rifle_tier_3',
  firearm_rate: 3,
  firearm_cost: 0,

  ...tmpl_firearm_sr_base,

  firearm_ammo_max: 5,
  firearm_range: 910,

  firearm_aimvar_incr_rot_cap: 0.55,
  firearm_aimvar_incr_move_cap: 1,

  firearm_projectile_damage: 130,
  firearm_reload_duration: 8,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 8.5,

  aimvar_hold_max: Math.PI / 1058,

  aimvar_incr_per_shoot: 100,
  aimvar_incr_per_hit: 10,

  firearm_additional_hit_prob: 0.3,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.008 },
    { aimvar: Math.PI * 2, aimspeed: 0.07 },
  ],

  // TODO
  vis_range: 910,
};

export const tmpl_firearm_sr_t4 = {
  key: 'firearm_sr_t4',

  firearm_ty: 'sr',
  firearm_name: 'loc_ui_string_common_firearm_sniper_rifle_tier_4',
  firearm_rate: 4,
  firearm_cost: 0,

  ...tmpl_firearm_sr_base,

  firearm_ammo_max: 5,
  firearm_range: 920,

  firearm_aimvar_incr_rot_cap: 0.5,
  firearm_aimvar_incr_move_cap: 1,

  firearm_projectile_damage: 130,
  firearm_reload_duration: 8,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 8.25,

  aimvar_hold_max: Math.PI / 1125,

  aimvar_incr_per_shoot: 100,
  aimvar_incr_per_hit: 10,

  firearm_additional_hit_prob: 0.3,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.008 },
    { aimvar: Math.PI * 2, aimspeed: 0.07 },
  ],

  // TODO
  vis_range: 920,
};

export const tmpl_firearm_sr_t5 = {
  key: 'firearm_sr_t5',

  firearm_ty: 'sr',
  firearm_name: 'loc_ui_string_common_firearm_sniper_rifle_tier_5',
  firearm_rate: 5,
  firearm_cost: 0,

  ...tmpl_firearm_sr_base,

  firearm_ammo_max: 10,
  firearm_range: 930,

  firearm_aimvar_incr_rot_cap: 0.45,
  firearm_aimvar_incr_move_cap: 1,

  firearm_projectile_damage: 133,
  firearm_reload_duration: 7,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 7.5,

  aimvar_hold_max: Math.PI / 1200,

  aimvar_incr_per_shoot: 100,
  aimvar_incr_per_hit: 10,

  firearm_additional_hit_prob: 0.3,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.0085 },
    { aimvar: Math.PI * 2, aimspeed: 0.07 },
  ],

  // TODO
  vis_range: 930,
};

// 윈체스터 Model 70 모델. 신뢰성이 낮은 1964년도 이후 모델 .308 윈체스터 탄 5발 들이 박스탄창 사용
// 방탄복을 입고있지 않은 적의 경우 1발 명중으로 확실하게 사살 할 수 있습니다.
export const tmpl_firearm_sr_low = {
  firearm_ty: 'sr',
  firearm_name: 'Model 70',
  firearm_rate: 1,
  firearm_cost: 0,

  ...tmpl_firearm_sr_base,

  firearm_ammo_max: 3,
  firearm_aimvar_mult: 1,

  firearm_aimvar_incr_rot_cap: 0.65,
  firearm_aimvar_incr_move_cap: 1,

  firearm_projectile_damage: 100,
  firearm_reload_duration: 10,
  firearm_shoot_pattern_interval_sec: 9,

  aimvar_hold_max: Math.PI / 900,

  firearm_additional_hit_prob: 0.3,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.008 },
    { aimvar: Math.PI * 2, aimspeed: 0.07 },
  ],

  // TODO
  vis_range: 900,
};

// 동구권 볼트 액션 소총, 7.62×54mmR탄 10발들이 박스탄창 사용.
// 33짜리 방탄복을 입고 있는 경우 방탄복 명중 여부와 관련 없이 1발 명중으로 확실하게 적을 제압할 수 있습니다.
export const tmpl_firearm_sr_mid = {
  firearm_ty: 'sr',
  firearm_name: 'SV-98',
  firearm_rate: 2,
  firearm_cost: 550,

  ...tmpl_firearm_sr_base,

  firearm_ammo_max: 5,
  firearm_aimvar_mult: 0.8,

  firearm_aimvar_incr_rot_cap: 0.55,
  firearm_aimvar_incr_move_cap: 1,

  firearm_projectile_damage: 133,
  firearm_reload_duration: 10,
  firearm_shoot_pattern_interval_sec: 8.5,

  aimvar_hold_max: Math.PI / 1000,

  firearm_additional_hit_prob: 0.3,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.008 },
    { aimvar: Math.PI * 2, aimspeed: 0.07 },
  ],

  // TODO
  vis_range: 900,
};

// M87 기반의 볼트액션 소총, 최장거리 저격기록 보유한 총기.
// 50구경을 사용하고 있어서 방탄복 여부와 관련 없이 적을 한 발로 사살 할 수 있습니다. 대신 RPM이 다소 낮습니다.
// .50구경 5발들이 박스탄창 사용
export const tmpl_firearm_sr_high = {
  firearm_ty: 'sr',
  firearm_name: 'TAC-50',
  firearm_rate: 3,
  firearm_cost: 1000,

  ...tmpl_firearm_sr_base,

  firearm_ammo_max: 5,
  firearm_aimvar_mult: 0.65,

  firearm_shoot_pattern_interval_sec: 8,

  firearm_aimvar_incr_rot_cap: 0.45,
  firearm_aimvar_incr_move_cap: 1,

  firearm_projectile_damage: 200,

  firearm_reload_duration: 9,

  aimvar_hold_max: Math.PI / 1100,

  firearm_additional_hit_prob: 0.4,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.008 },
    { aimvar: Math.PI * 2, aimspeed: 0.07 },
  ],

  // TODO
  vis_range: 900,
};

// 경기관총 비스므리한거
export const tmpl_firearm_smg_lmg = {
  firearm_ty: 'smg',
  firearm_name: 'RPK-16',
  firearm_rate: 4,
  firearm_cost: 3000,

  firearm_aimvar_incr_rot_cap: 1,

  firearm_ammo_max: 100,
  firearm_range: 900,
  firearm_aimvar_mult: 0.65,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 60 / 700,

  firearm_projectile_damage: 100,
  firearm_projectile_aimvar: Math.PI / 688,

  firearm_reload_duration: 5.5,
  firearm_reload_idle_duration: 10,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 344,
  aimvar_incr_per_shoot: 0.1,
  aimvar_incr_per_hit: 0.75,

  aim_samples_fire_thres: 0.85,

  firearm_additional_hit_prob: 0.25,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.15 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],

  // TODO
  vis_range: 700,
};



// 여기서 부터는 적 에이전트에 대한 화기 설정입니다.
// 낮은 스펙의 AR 사용하는 적. 레퍼런스는 GAU-5입니다.
export const enemy_tmpl_firearm_ar_low = {

  // 피카티니 레일이 없음. 광학장비를 달수 없으니 rot_cap 값을 높여둡니다.
  firearm_aimvar_incr_rot_cap: 0.35,

  firearm_ammo_max: 30,
  firearm_range: 320,
  firearm_aimvar_mult: 1.7,

  firearm_shoot_pattern: [0.2, 0.2],
  firearm_shoot_pattern_interval_sec: 1.5,

  firearm_projectile_damage: 33,
  firearm_projectile_aimvar: Math.PI / 198,

  firearm_reload_duration: 3,
  firearm_reload_idle_duration: 10,

  aimvar_hold: Math.PI / 28,
  aimvar_hold_max: Math.PI / 224,
  aimvar_incr_per_shoot: 0.4,
  aimvar_incr_per_hit: 0.35,

  aim_samples_fire_thres: 0.4,

  firearm_additional_hit_prob: -0.1,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.075 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],

  movespeed_rules: [
    // 기타: 옆뒤 혹은 뒤로 움직일 때, 20%로 이동합니다.
    { reldir: Math.PI * 2, multiplier: 0.2 },
    // 진행방향 좌/우 90도 안쪽을 바라볼 때 (옆걸음), 50%로 이동합니다.
    { reldir: Math.PI / 2, multiplier: 0.5 },
    // 진행방향 좌/우 30도 안쪽을 바라볼 때, 정상 속도로 이동합니다.
    { reldir: Math.PI / 6, multiplier: 1 },
  ]
};

const tmpl_firearm_hg_base = {
  ...tmpl_firearm_base,
  firearm_reload_idle_duration: 8,

  aim_samples_fire_thres: 0.1,
  movespeed_rules: [
    // 기타: 옆뒤 혹은 뒤로 움직일 때, 10%로 이동합니다.
    { reldir: Math.PI * 2, multiplier: 0.1 },
    // 진행방향 좌/우 90도 안쪽을 바라볼 때 (옆걸음), 30%로 이동합니다.
    { reldir: Math.PI / 2, multiplier: 0.3 },
    // 진행방향 좌/우 30도 안쪽을 바라볼 때, 정상 속도로 이동합니다.
    { reldir: Math.PI / 6, multiplier: 1 },
  ],

  // 총기별 기본 반동 계수
  aimvar_incr_per_shoot_mult: 0.5,

  // firearm 종류별, UI 표기에 사용하는 최대 RPM
  firearm_ty_max_rpm: 60,
};

export const tmpl_firearm_hg_t1 = {
  key: 'firearm_hg_t1',

  firearm_ty: 'hg',
  firearm_name: 'loc_ui_string_common_firearm_handgun_tier_1',
  firearm_rate: 1,
  firearm_cost: 0,

  ...tmpl_firearm_hg_base,

  firearm_aimvar_incr_rot_cap: 0.125,
  firearm_aimvar_incr_move_cap: 0.02,

  firearm_ammo_max: 8,
  firearm_range: 150,
  firearm_aimvar_mult: 1,
  firearm_reload_duration: 2,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 1.5,

  firearm_projectile_damage: 15,
  firearm_projectile_aimvar: Math.PI / 350,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 260,
  aimvar_incr_per_shoot: 0.4,
  aimvar_incr_per_hit: 0.4,

  firearm_additional_hit_prob: 0,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.24 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],
};

export const tmpl_firearm_hg_t2 = {
  key: 'firearm_hg_t2',

  firearm_ty: 'hg',
  firearm_name: 'loc_ui_string_common_firearm_handgun_tier_2',
  firearm_rate: 2,
  firearm_cost: 250,

  ...tmpl_firearm_hg_base,

  firearm_aimvar_incr_rot_cap: 0.125,
  firearm_aimvar_incr_move_cap: 0.02,

  firearm_ammo_max: 8,
  firearm_range: 150,
  firearm_aimvar_mult: 1,
  firearm_reload_duration: 2,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 1.45,

  firearm_projectile_damage: 17,
  firearm_projectile_aimvar: Math.PI / 350,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 272,
  aimvar_incr_per_shoot: 0.4,
  aimvar_incr_per_hit: 0.4,

  firearm_additional_hit_prob: 0,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.24 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],
};

export const tmpl_firearm_hg_t3 = {
  key: 'firearm_hg_t3',

  firearm_ty: 'hg',
  firearm_name: 'loc_ui_string_common_firearm_handgun_tier_3',
  firearm_rate: 3,
  firearm_cost: 400,

  ...tmpl_firearm_hg_base,

  firearm_aimvar_incr_rot_cap: 0.125,
  firearm_aimvar_incr_move_cap: 0.02,

  firearm_ammo_max: 12,
  firearm_range: 150,
  firearm_aimvar_mult: 1,
  firearm_reload_duration: 2,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 1.4,

  firearm_projectile_damage: 20,
  firearm_projectile_aimvar: Math.PI / 350,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 284,
  aimvar_incr_per_shoot: 0.4,
  aimvar_incr_per_hit: 0.4,

  firearm_additional_hit_prob: 0,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.24 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],
};

export const tmpl_firearm_hg_t4 = {
  key: 'firearm_hg_t4',

  firearm_ty: 'hg',
  firearm_name: 'loc_ui_string_common_firearm_handgun_tier_4',
  firearm_rate: 4,
  firearm_cost: 600,

  ...tmpl_firearm_hg_base,

  firearm_aimvar_incr_rot_cap: 0.125,
  firearm_aimvar_incr_move_cap: 0.02,

  firearm_ammo_max: 13,
  firearm_range: 160,
  firearm_aimvar_mult: 1,
  firearm_reload_duration: 2,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 1.3,

  firearm_projectile_damage: 21,
  firearm_projectile_aimvar: Math.PI / 350,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 296,
  aimvar_incr_per_shoot: 0.25,
  aimvar_incr_per_hit: 0.25,

  firearm_additional_hit_prob: 0,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.24 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],
};

export const tmpl_firearm_hostage = {
  ...tmpl_firearm_smg_low,

  firearm_range: 5,
  firearm_aimvar_mult: 30,
};

export const tmpl_firearm_unarmed = {
  key: 'firearm_unarmed',

  firearm_ty: 'unarmed',
  firearm_name: 'unarmed1',
  firearm_rate: 1,
  firearm_cost: 1,

  ...tmpl_firearm_hg_base,

  firearm_aimvar_incr_rot_cap: 0.125,
  firearm_aimvar_incr_move_cap: 0.02,

  firearm_ammo_max: 10,
  firearm_range: 25,
  firearm_aimvar_mult: 1,
  firearm_reload_duration: 2,

  firearm_shoot_pattern: [],
  firearm_shoot_pattern_interval_sec: 1.3,

  firearm_projectile_damage: 1,
  firearm_projectile_aimvar: Math.PI / 350,

  aimvar_hold: Math.PI / 32,
  aimvar_hold_max: Math.PI / 296,
  aimvar_incr_per_shoot: 0.25,
  aimvar_incr_per_hit: 0.25,

  firearm_additional_hit_prob: 0,

  aim_rot_rules: [
    { aimvar: Math.PI / 2, aimspeed: 0.24 },
    { aimvar: Math.PI * 2, aimspeed: 0.1 },
  ],
  vis_range: 25
};

export const firearm_tys = {
  // sr: [
  //   /*
  //   tmpl_firearm_sr_low,
  //   tmpl_firearm_sr_mid,
  //   tmpl_firearm_sr_high,
  //   */

  //   tmpl_firearm_sr_t1,
  //   tmpl_firearm_sr_t2,
  //   tmpl_firearm_sr_t3,
  //   tmpl_firearm_sr_t4,
  //   tmpl_firearm_sr_t5,
  // ],
  // dmr: [
  //   /*
  //   tmpl_firearm_dmr_low,
  //   tmpl_firearm_dmr_mid,
  //   tmpl_firearm_dmr_high,
  //   */

  //   tmpl_firearm_dmr_t1,
  //   tmpl_firearm_dmr_t2,
  //   tmpl_firearm_dmr_t3,
  //   tmpl_firearm_dmr_t4,
  //   tmpl_firearm_dmr_t5,
  // ],
  ar: [
    /*
    tmpl_firearm_ar_low,
    tmpl_firearm_ar_mid,
    tmpl_firearm_ar_high,
    */

    tmpl_firearm_ar_t1,
    tmpl_firearm_ar_t2,
    tmpl_firearm_ar_t3,
    tmpl_firearm_ar_t4,
    tmpl_firearm_ar_t5,
  ],
  smg: [
    /*
    tmpl_firearm_smg_low,
    tmpl_firearm_smg_mid,
    tmpl_firearm_smg_high,
    */

    tmpl_firearm_smg_t1,
    tmpl_firearm_smg_t2,
    tmpl_firearm_smg_t3,
    tmpl_firearm_smg_t4,
    tmpl_firearm_smg_t5,
  ],
  sg: [
    /*
    tmpl_firearm_sg_low,
    tmpl_firearm_sg_mid,
    tmpl_firearm_sg_high,
    */

    tmpl_firearm_sg_t1,
    tmpl_firearm_sg_t2,
    tmpl_firearm_sg_t3,
    tmpl_firearm_sg_t4,
    tmpl_firearm_sg_t5,
  ],
  hg: [
    tmpl_firearm_hg_t1,
    tmpl_firearm_hg_t2,
    tmpl_firearm_hg_t3,
    tmpl_firearm_hg_t4,

    tmpl_firearm_unarmed,
  ],
};

function update_cost(target) {
  const item = items.find(i => i.key === target.key);
  if (item) {
    target.buy_cost = item.buy_cost;
    target.sell_cost = item.sell_cost;
    target.durability = item.durability;
  }
}

export const firearms = [];
export const firearms_tier1 = [];
for (const l of Object.values(firearm_tys)) {
  const firearm_tier1 = l[0];
  update_cost(firearm_tier1);
  firearms_tier1.push(firearm_tier1);
  for (const firearm of l) {
    update_cost(firearm);
    firearms.push(firearm);
  }
}

export const firearm_ty_name = {
  'sr': 'loc_ui_string_common_firearm_sniper_rifle',
  'dmr': 'loc_ui_string_common_firearm_designated_marksman_rifle',
  'ar': 'loc_ui_string_common_firearm_assult_rifle',
  'smg': 'loc_ui_string_common_firearm_submachine_gun',
  'sg': 'loc_ui_string_common_firearm_shotgun',
  'hg': 'loc_ui_string_common_firearm_handgun'
}

export const firearm_ty_name_short = {
  'sr': 'loc_ui_string_common_firearm_sniper_rifle_short',
  'dmr': 'loc_ui_string_common_firearm_designated_marksman_rifle_short',
  'ar': 'loc_ui_string_common_firearm_assult_rifle_short',
  'smg': 'loc_ui_string_common_firearm_submachine_gun_short',
  'sg': 'loc_ui_string_common_firearm_shotgun_short',
  'hg': 'loc_ui_string_common_firearm_handgun_short'
}

export const firearm_ty_full = {
  'sr': 'sniper_rifle',
  'dmr': 'designated_marksman_rifle',
  'ar': 'assult_rifle',
  'smg': 'submachine_gun',
  'sg': 'shotgun',
  'hg': 'handgun'
}