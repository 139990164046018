import React from "react";

export class Estimation extends React.Component {
    // Props properties
    constructor(props) {
        super(props);

        this.getWeaponGrade = this.getWeaponGrade.bind(this);
        this.getPopulation = this.getPopulation.bind(this);
        this.getAllyLevel = this.getAllyLevel.bind(this);
        this.haveProperRole = this.haveProperRole.bind(this);
        this.isMapEasy = this.isMapEasy.bind(this);
        this.getEstimation = this.getEstimation.bind(this);

        this.state = {
            preprocessedTable: null
        }

        this.csv = `무기 등급,아군의 수,아군의 수준,역할,판정
        - 1등급 ~ 1.5 등급,- 적의 인원수가 아군과 비교하여 2배수 미만,- 상대적으로 더 높은 스탯 (+2 이상),- 적합한 역할 포함,대체로 우세
        - 1등급 ~ 1.5 등급,- 적의 인원수가 아군과 비교하여 2배수 미만,- 상대적으로 더 높은 스탯 (+2 이상),- 적합한 역할 포함 X,근소한 우세
        - 1등급 ~ 1.5 등급,- 적의 인원수가 아군과 비교하여 2배수 미만,- 비슷한 수준의 스탯 (±2 미만 차이),- 적합한 역할 포함,근소한 우세
        - 1등급 ~ 1.5 등급,- 적의 인원수가 아군과 비교하여 2배수 미만,- 비슷한 수준의 스탯 (±2 미만 차이),- 적합한 역할 포함 X,알 수 없음
        - 1등급 ~ 1.5 등급,- 적의 인원수가 아군과 비교하여 2배수 미만,- 상대적으로 더 열세인 스탯 (-2 이상),- 적합한 역할 포함,알 수 없음
        - 1등급 ~ 1.5 등급,- 적의 인원수가 아군과 비교하여 2배수 미만,- 상대적으로 더 열세인 스탯 (-2 이상),- 적합한 역할 포함 X,근소한 약세
        - 1등급 ~ 1.5 등급,- 적의 인원수가 아군과 비교하여 2배수 이상 3배수 미만,- 상대적으로 더 높은 스탯 (+2 이상),- 적합한 역할 포함,근소한 우세
        - 1등급 ~ 1.5 등급,- 적의 인원수가 아군과 비교하여 2배수 이상 3배수 미만,- 상대적으로 더 높은 스탯 (+2 이상),- 적합한 역할 포함 X,알 수 없음
        - 1등급 ~ 1.5 등급,- 적의 인원수가 아군과 비교하여 2배수 이상 3배수 미만,- 비슷한 수준의 스탯 (±2 미만 차이),- 적합한 역할 포함,알 수 없음
        - 1등급 ~ 1.5 등급,- 적의 인원수가 아군과 비교하여 2배수 이상 3배수 미만,- 비슷한 수준의 스탯 (±2 미만 차이),- 적합한 역할 포함 X,근소한 약세
        - 1등급 ~ 1.5 등급,- 적의 인원수가 아군과 비교하여 2배수 이상 3배수 미만,- 상대적으로 더 열세인 스탯 (-2 이상),- 적합한 역할 포함,근소한 약세
        - 1등급 ~ 1.5 등급,- 적의 인원수가 아군과 비교하여 2배수 이상 3배수 미만,- 상대적으로 더 열세인 스탯 (-2 이상),- 적합한 역할 포함 X,대체로 약세
        - 1등급 ~ 1.5 등급,- 적의 인원수가 아군과 비교하여 3배수 이상,- 상대적으로 더 높은 스탯 (+2 이상),- 적합한 역할 포함,알 수 없음
        - 1등급 ~ 1.5 등급,- 적의 인원수가 아군과 비교하여 3배수 이상,- 상대적으로 더 높은 스탯 (+2 이상),- 적합한 역할 포함 X,근소한 약세
        - 1등급 ~ 1.5 등급,- 적의 인원수가 아군과 비교하여 3배수 이상,- 비슷한 수준의 스탯 (±2 미만 차이),- 적합한 역할 포함,근소한 약세
        - 1등급 ~ 1.5 등급,- 적의 인원수가 아군과 비교하여 3배수 이상,- 비슷한 수준의 스탯 (±2 미만 차이),- 적합한 역할 포함 X,대체로 약세
        - 1등급 ~ 1.5 등급,- 적의 인원수가 아군과 비교하여 3배수 이상,- 상대적으로 더 열세인 스탯 (-2 이상),- 적합한 역할 포함,대체로 약세
        - 1등급 ~ 1.5 등급,- 적의 인원수가 아군과 비교하여 3배수 이상,- 상대적으로 더 열세인 스탯 (-2 이상),- 적합한 역할 포함 X,확실한 약세
        - 1.5등급 ~ 2.5등급,- 적의 인원수가 아군과 비교하여 2배수 미만,- 상대적으로 더 높은 스탯 (+2 이상),- 적합한 역할 포함,확실한 우세
        - 1.5등급 ~ 2.5등급,- 적의 인원수가 아군과 비교하여 2배수 미만,- 상대적으로 더 높은 스탯 (+2 이상),- 적합한 역할 포함 X,대체로 우세
        - 1.5등급 ~ 2.5등급,- 적의 인원수가 아군과 비교하여 2배수 미만,- 비슷한 수준의 스탯 (±2 미만 차이),- 적합한 역할 포함,대체로 우세
        - 1.5등급 ~ 2.5등급,- 적의 인원수가 아군과 비교하여 2배수 미만,- 비슷한 수준의 스탯 (±2 미만 차이),- 적합한 역할 포함 X,근소한 우세
        - 1.5등급 ~ 2.5등급,- 적의 인원수가 아군과 비교하여 2배수 미만,- 상대적으로 더 열세인 스탯 (-2 이상),- 적합한 역할 포함,근소한 우세
        - 1.5등급 ~ 2.5등급,- 적의 인원수가 아군과 비교하여 2배수 미만,- 상대적으로 더 열세인 스탯 (-2 이상),- 적합한 역할 포함 X,알 수 없음
        - 1.5등급 ~ 2.5등급,- 적의 인원수가 아군과 비교하여 2배수 이상 3배수 미만,- 상대적으로 더 높은 스탯 (+2 이상),- 적합한 역할 포함,대체로 우세
        - 1.5등급 ~ 2.5등급,- 적의 인원수가 아군과 비교하여 2배수 이상 3배수 미만,- 상대적으로 더 높은 스탯 (+2 이상),- 적합한 역할 포함 X,근소한 우세
        - 1.5등급 ~ 2.5등급,- 적의 인원수가 아군과 비교하여 2배수 이상 3배수 미만,- 비슷한 수준의 스탯 (±2 미만 차이),- 적합한 역할 포함,근소한 우세
        - 1.5등급 ~ 2.5등급,- 적의 인원수가 아군과 비교하여 2배수 이상 3배수 미만,- 비슷한 수준의 스탯 (±2 미만 차이),- 적합한 역할 포함 X,알 수 없음
        - 1.5등급 ~ 2.5등급,- 적의 인원수가 아군과 비교하여 2배수 이상 3배수 미만,- 상대적으로 더 열세인 스탯 (-2 이상),- 적합한 역할 포함,알 수 없음
        - 1.5등급 ~ 2.5등급,- 적의 인원수가 아군과 비교하여 2배수 이상 3배수 미만,- 상대적으로 더 열세인 스탯 (-2 이상),- 적합한 역할 포함 X,근소한 약세
        - 1.5등급 ~ 2.5등급,- 적의 인원수가 아군과 비교하여 3배수 이상,- 상대적으로 더 높은 스탯 (+2 이상),- 적합한 역할 포함,근소한 우세
        - 1.5등급 ~ 2.5등급,- 적의 인원수가 아군과 비교하여 3배수 이상,- 상대적으로 더 높은 스탯 (+2 이상),- 적합한 역할 포함 X,알 수 없음
        - 1.5등급 ~ 2.5등급,- 적의 인원수가 아군과 비교하여 3배수 이상,- 비슷한 수준의 스탯 (±2 미만 차이),- 적합한 역할 포함,알 수 없음
        - 1.5등급 ~ 2.5등급,- 적의 인원수가 아군과 비교하여 3배수 이상,- 비슷한 수준의 스탯 (±2 미만 차이),- 적합한 역할 포함 X,근소한 약세
        - 1.5등급 ~ 2.5등급,- 적의 인원수가 아군과 비교하여 3배수 이상,- 상대적으로 더 열세인 스탯 (-2 이상),- 적합한 역할 포함,근소한 약세
        - 1.5등급 ~ 2.5등급,- 적의 인원수가 아군과 비교하여 3배수 이상,- 상대적으로 더 열세인 스탯 (-2 이상),- 적합한 역할 포함 X,대체로 약세
        - 2.5등급 ~ 3등급,- 적의 인원수가 아군과 비교하여 2배수 미만,- 상대적으로 더 높은 스탯 (+2 이상),- 적합한 역할 포함,확실한 우세
        - 2.5등급 ~ 3등급,- 적의 인원수가 아군과 비교하여 2배수 미만,- 상대적으로 더 높은 스탯 (+2 이상),- 적합한 역할 포함 X,확실한 우세
        - 2.5등급 ~ 3등급,- 적의 인원수가 아군과 비교하여 2배수 미만,- 비슷한 수준의 스탯 (±2 미만 차이),- 적합한 역할 포함,확실한 우세
        - 2.5등급 ~ 3등급,- 적의 인원수가 아군과 비교하여 2배수 미만,- 비슷한 수준의 스탯 (±2 미만 차이),- 적합한 역할 포함 X,대체로 우세
        - 2.5등급 ~ 3등급,- 적의 인원수가 아군과 비교하여 2배수 미만,- 상대적으로 더 열세인 스탯 (-2 이상),- 적합한 역할 포함,대체로 우세
        - 2.5등급 ~ 3등급,- 적의 인원수가 아군과 비교하여 2배수 미만,- 상대적으로 더 열세인 스탯 (-2 이상),- 적합한 역할 포함 X,대체로 우세
        - 2.5등급 ~ 3등급,- 적의 인원수가 아군과 비교하여 2배수 이상 3배수 미만,- 상대적으로 더 높은 스탯 (+2 이상),- 적합한 역할 포함,대체로 우세
        - 2.5등급 ~ 3등급,- 적의 인원수가 아군과 비교하여 2배수 이상 3배수 미만,- 상대적으로 더 높은 스탯 (+2 이상),- 적합한 역할 포함 X,대체로 우세
        - 2.5등급 ~ 3등급,- 적의 인원수가 아군과 비교하여 2배수 이상 3배수 미만,- 비슷한 수준의 스탯 (±2 미만 차이),- 적합한 역할 포함,대체로 우세
        - 2.5등급 ~ 3등급,- 적의 인원수가 아군과 비교하여 2배수 이상 3배수 미만,- 비슷한 수준의 스탯 (±2 미만 차이),- 적합한 역할 포함 X,대체로 우세
        - 2.5등급 ~ 3등급,- 적의 인원수가 아군과 비교하여 2배수 이상 3배수 미만,- 상대적으로 더 열세인 스탯 (-2 이상),- 적합한 역할 포함,근소한 우세
        - 2.5등급 ~ 3등급,- 적의 인원수가 아군과 비교하여 2배수 이상 3배수 미만,- 상대적으로 더 열세인 스탯 (-2 이상),- 적합한 역할 포함 X,근소한 우세
        - 2.5등급 ~ 3등급,- 적의 인원수가 아군과 비교하여 3배수 이상,- 상대적으로 더 높은 스탯 (+2 이상),- 적합한 역할 포함,대체로 우세
        - 2.5등급 ~ 3등급,- 적의 인원수가 아군과 비교하여 3배수 이상,- 상대적으로 더 높은 스탯 (+2 이상),- 적합한 역할 포함 X,대체로 우세
        - 2.5등급 ~ 3등급,- 적의 인원수가 아군과 비교하여 3배수 이상,- 비슷한 수준의 스탯 (±2 미만 차이),- 적합한 역할 포함,근소한 우세
        - 2.5등급 ~ 3등급,- 적의 인원수가 아군과 비교하여 3배수 이상,- 비슷한 수준의 스탯 (±2 미만 차이),- 적합한 역할 포함 X,근소한 우세
        - 2.5등급 ~ 3등급,- 적의 인원수가 아군과 비교하여 3배수 이상,- 상대적으로 더 열세인 스탯 (-2 이상),- 적합한 역할 포함,알 수 없음
        - 2.5등급 ~ 3등급,- 적의 인원수가 아군과 비교하여 3배수 이상,- 상대적으로 더 열세인 스탯 (-2 이상),- 적합한 역할 포함 X,알 수 없음`;
    }

    get CSV() { return this.csv; }

    preprocessing(csv) {
        let data = csv.split('\n');
        let properties = data[0].split(',');
        data = data.slice(1);

        data = data.map((element) => {
            let temp = {};

            let tempArr = element.split(',');
            tempArr.forEach((value, i) => {
                temp[properties[i]] = value;
            })

            return temp;
        });

        const getGrade = function (range) {
            // range: str
            let first = range.split('~')[0];
            first = first.replace(/\s/g, '').replace('-', '');

            return first;
        }

        const getRatio = function (range) {
            // range: str
            let digit = range.match(/\d+/g).map(x => Number(x).toString());

            if (digit.length !== 2) {
                if (range.includes('미만')) digit.unshift('_');
                else digit.push('_');
            }

            return digit.join(',');
        }

        const getRelativeStat = function (range) {
            if (range.includes('높은')) return '+ 이상';
            else if (range.includes('열세')) return '- 이상';
            else return '미만';
        }

        const haveProperRole = function (range) {
            if (range.includes('X')) return 'X';
            else return 'O';
        }

        let replaced = data.map((element) => {
            let dict = {};
            for (let key in element) {
                if (key === '무기 등급') {
                    dict[key] = getGrade(element[key]);
                }
                else if (key === '아군의 수') dict[key] = getRatio(element[key]);
                else if (key === '아군의 수준') dict[key] = getRelativeStat(element[key]);
                else if (key === '역할') dict[key] = haveProperRole(element[key]);
                else if (key === '판정') dict[key] = element[key];
                else continue;
            }

            return dict;
        })

        return replaced;
    }


    csvMappingToCriterion() {
        if (this.state.preprocessedTable !== null) return this.state.preprocessedTable;

        const criterion0 = {
            "1등급": 0,
            "1.5등급": 1,
            "2.5등급": 2
        };

        const criterion1 = {
            "_,2": 0,
            "2,3": 1,
            "3,_": 2
        };

        const criterion2 = {
            "+ 이상": 1,
            "- 이상": -1,
            "미만": 0
        };

        const criterion3 = {
            "O": 1,
            "X": 0
        };

        const result = {
            "확실한 우세": 1,
            "대체로 우세": 2,
            "근소한 우세": 3,
            "알 수 없음": 4,
            "근소한 약세": 5,
            "대체로 약세": 6,
            "확실한 약세": 7,
        }

        let criterionArr = this.preprocessing.bind(this)(this.CSV);
        let replaced = null;

        replaced = criterionArr.map((element) => {
            let dict = {};
            for (let key in element) {
                if (key === '무기 등급') {
                    dict[key] = criterion0[element[key]];
                }
                else if (key === '아군의 수') dict[key] = criterion1[element[key]];
                else if (key === '아군의 수준') dict[key] = criterion2[element[key]];
                else if (key === '역할') dict[key] = criterion3[element[key]];
                else if (key === '판정') dict[key] = result[element[key]];
                else continue;
            }

            return dict;
        })

        this.setState({ preprocessedTable: replaced });
        return replaced;
    }

    getWeaponGrade(data) {
        let rate = data.agents_equip_rate;

        if (rate >= 1.0 && rate < 1.5) return 0;
        else if (rate >= 1.5 && rate < 2.5) return 1;
        else if (rate >= 2.5 && rate <= 3) return 2;
        else return null;
    }

    getPopulation(data) {
        let popEnemy = data.threats;
        let popAlly = data.agents_count;

        let rate = Math.round(popEnemy * 100.0 / popAlly) / 100;

        if (rate < 2.0) return 0;
        else if (rate >= 2.0 && rate < 3.0) return 1;
        else return 2;
    }

    getAllyLevel(data) {
        const EnemytoAlly = {
            1: 4,
            3: 8,
            5: 12
        };

        let enemyLevel = EnemytoAlly[data.training];
        let allyLevel = data.agents_overall

        if (allyLevel >= enemyLevel + 2) return 1;
        else if (enemyLevel >= allyLevel + 2) return -1;
        else return 0;
    }

    haveProperRole(data) {
        let map = data.yt

        if (map === 'outdoor' && data.agents_count_dmr === 1) return 1;
        if (map === 'indoor' && data.agents_count_sg === 1) return 1;

        return 0;
    }


    isMapEasy(data) {
        let map = data.ty

        if (map === 'embassy_short') return 1;
        else if (map === 'outdoor') return -1;
        else return 0;

    }

    getEstimation(res) {
        // Handle invalid input
        if (res === undefined) return null;

        for (const [, value] of Object.entries(res)) {
            if (value === "" || value === null) return null;
        }

        let criterion = {};
        criterion['무기 등급'] = this.getWeaponGrade(res);
        criterion['아군의 수'] = this.getPopulation(res);
        criterion['아군의 수준'] = this.getAllyLevel(res);
        criterion['역할'] = this.haveProperRole(res);

        let bias = this.isMapEasy(res);

        // criterion[0] : 무기 등급
        // criterion[1] : 아군의 수
        // criterion[2] : 아군의 수준
        // criterion[3] : 적합한 역할 포함
        // bias : 보정값

        let result = -1;

        // Get the result based on criterion
        let criterionTable = this.csvMappingToCriterion.bind(this)();

        for (let e in criterionTable) {
            let element = criterionTable[e];
            let isMatched = true;

            for (let key in criterion) {
                if (criterion[key] !== element[key]) {
                    isMatched = false;
                    break;
                }
            }

            if (isMatched === true) {
                result = element['판정'];
                break;
            }
        }
        result = result + bias;

        if (result > 7) return 7;
        else if (result < 1) return 1;
        else return result;
    }

    render() {
        const judgeTable = {
            1: '확실한 우세',
            2: '대체로 우세',
            3: '근소한 우세',
            4: '알 수 없음',
            5: '근소한 약세',
            6: '대체로 약세',
            7: '확실한 약세'
        }


        let value = this.getEstimation(this.props.data);
        if (value === null) return <>undefined</>;

        value = judgeTable[value];

        return (
            <>
                {value}
            </>
        );
    }
}