export const EFFECTS_DEFAULT = {
  base_tier: 0,
  agents_limit: 0,

  wage_mult: 1.0,
  agent_wage_mult: 1.0,
  train_mult: 1.0,
  travel_speed_mult: 1.0,
  cost_safehouse_mult: 1.0,

  recover_mult: 0,
  mission_recover_mult: 0,

  cost_facility_mult: 1.0,
  duration_facility_mult: 1.0,

  staff_spawn_stat_mult: 1.0,
  mission_time_mult: 1.0,
  stamina_recover_mult: 1.0,

  staff_stats: {
  },

  recruit_backgrounds: {
  },

  interval_items: {

  },

  interval_agents: {

  },
};
