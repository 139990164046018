/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const PgbTooltipContent = ({ ptDesc = "TOOLTIP_DESC", ptTitle = "TOOLTIP_TITLE", className }) => {
  return (
    <div className={`PGB-tooltip-content ${className}`}>
      <div className="pt-title">{ptTitle}</div>
      <div className="pt-desc">{ptDesc}</div>
    </div>
  );
};

PgbTooltipContent.propTypes = {
  ptDesc: PropTypes.string,
  ptTitle: PropTypes.string,
};
