import _ from 'lodash';
import React, { useState } from 'react';
import { opts } from './opts';
import { StatValue, AgentRecoverDays } from './CharacterView.js'
import * as exps from './data/google/processor/data_exp.mjs';
import { modifiers } from './data/google/processor/data_modifiers.mjs';
import { perks2ByKey } from './data/google/processor/data_perks2.mjs';
import { ProgressBar } from './ProgressBar';
import { life_max_change } from './character.mjs';
import { firearm_ty_name } from './presets_firearm.mjs';
import { FirearmBox, GearBox, ThrowableBox } from './GearView.js';
import { L } from './localization.mjs';

export function ReportView(props) {
  const { game, onRevive } = props;
  const { turn, mission_state, resources } = game;
  const { mission, sim } = mission_state;
  const [sortOpt, setSortOpt] = useState({ index: 0, isDesc: true });
  const [focusedAgent, setFocusedAgent] = useState(null);
  const [tab, setTab] = useState(0);

  if (!sim) {
    return;
  }


  const { entities, trails, heals } = sim;
  const allies = entities.filter((e) => e.team === 0 && e.ty !== 'vip' && entityToAgent(e));
  const header_missionItem = [
    'loc_ui_string_contract_terrain',
    'loc_ui_string_result_contract_goal',
    'loc_ui_string_result_contract_inputs',
    'loc_ui_string_result_contract_damaged',
    'loc_ui_string_result_contract_mission_duration',
    'loc_ui_string_result_contract_enemy_threat',
    'loc_ui_string_result_contract_enemy_count',
  ];

  const header_agent = [
    'loc_ui_string_agent_name',
    'loc_ui_string_common_firearm',
    'loc_ui_string_agent_overall',
    'loc_ui_string_agent_life',
    'loc_ui_string_agent_experience',
    'loc_ui_string_result_statistics_status_gained',
    'loc_ui_string_result_statistics_kill_count',
  ];

  const header_statistics = [
    'loc_ui_string_agent_name',
    'loc_ui_string_agent_level',
    'loc_ui_string_agent_overall',
    'loc_ui_string_common_firearm',
    'loc_ui_string_common_equipment',
    'loc_ui_string_common_throwable',
    'loc_ui_string_result_statistics_kill_count',
    'loc_ui_string_agent_statistics_damage_given',
    'loc_ui_string_result_statistics_shoots',
    'loc_ui_string_agent_statistics_damage_taken',
    'loc_ui_string_result_amount_healed',
    'loc_ui_string_result_kills_throwable',
  ]

  const header_reward = [
    'loc_ui_string_contract_reward_renown',
    'loc_ui_string_result_contract_reward_cash',
    'loc_ui_string_result_contract_reward_medical_expense',
    'loc_ui_string_result_contract_reward_incentive_payment',
    'loc_ui_string_result_contract_reward_net_profit',
  ]

  function entityToAgent(entity) {
    return game.agents.find((a) => a.idx === entity.idx);
  }

  function agentToFirearmName(agent) {
    return `${L(agent.firearm.firearm_name)}(${L(firearm_ty_name[agent.firearm.firearm_ty])}${L('loc_dynamic_string_common_item_tier').repeat(agent.firearm.firearm_rate)})`;
  }

  function secondToHHMM(sec) {
    return `${Math.floor(sec / 60)}:${("0" + sec % 60).slice(-2)}`
  }

  function getRank(win) {
    const filters = [
      { rank: 'A+', score: 300 },
      { rank: 'A', score: 270 },
      { rank: 'B', score: 220 },
      { rank: 'C', score: 150 },
      { rank: 'D', score: 60 },
      { rank: 'F', score: 0 },
    ]

    if (!win) {
      return filters[filters.length - 1].rank;
    }

    const injured = allies.filter(a => !game.agentAvail(a)).length;
    const manpower_loss = injured / mission_state.agents.length;
    const hp_prev = _.sum(game.agents.map((a) => a.life));
    const hp_curr = _.sum(allies.map((a) => a.life));
    const hp_loss = (hp_prev - hp_curr) / hp_prev;
    const time = Math.floor(sim.tick / opts.tps);
    const size = mission.intel.threats;

    const score = (3 - manpower_loss - hp_loss) * 100 - time + size * 6;

    for (const filter of filters) {
      if (filter.score <= score) {
        return filter.rank;
      }
    }

    return filters[filters.length - 1].rank;
  }

  function FocusedAgentView(props) {
    const { agent } = props;
    if (!agent) {
      return;
    }

    const entity = entities.find((e) => e.idx === agent.idx);

    const header_upper = [
      L('loc_ui_string_common_firearm'),
      L('loc_ui_string_agent_overall'),
      L('loc_ui_string_agent_stat_decision'),
      L('loc_ui_string_agent_stat_focus'),
      L('loc_ui_string_agent_stat_toughness'),
      L('loc_ui_string_agent_life'),
      L('loc_ui_string_agent_status'),
    ]

    const header_lower = [
      '',
      '',
      L('loc_ui_string_agent_stat_bravery'),
      L('loc_ui_string_agent_stat_reaction'),
      L('loc_ui_string_agent_stat_precision'),
      L('loc_ui_string_result_statistics_agent_perks'),
      '',
    ]

    const perkEntries = [];

    for (const key of agent.perks.list) {
      perkEntries.push(perks2ByKey(key));
    }


    const body_upper = [
      agentToFirearmName(agent),
      agent.power.toFixed(2),
      StatValue(agent, 'decision').toFixed(2),
      StatValue(agent, 'focus').toFixed(2),
      StatValue(agent, 'toughness').toFixed(2),
      <ProgressBar width={100} cur={entity.life} cap={entity.life_max} max={entity.life_max} />,
      agent.modifier.map((m, i) => <span key={i} title={modifiers[m.key].desc}>{`[${L(modifiers[m.key].name)}]`}</span>),
    ];
    const body_lower = [
      '',
      '',
      StatValue(agent, 'bravery').toFixed(2),
      StatValue(agent, 'reaction').toFixed(2),
      StatValue(agent, 'precision').toFixed(2),
      perkEntries.map((perk, i) =>
        <>
          <span key={i} title={perk.descr}>{L(perk.name)}</span>
          {i < perkEntries.length - 1 ? ' | ' : ''}
        </>),
    ];

    return <>
      <span className="mission-result-subtitle">{L('loc_dynamic_string_result_statistics_agents', { value: agent.name })}</span>
      <table className='focusedAgentView'>
        <thead>
          <tr>{header_upper.map((name, i) => <th key={i}>{name}</th>)}</tr>
        </thead>
        <tbody>
          <tr>{body_upper.map((row, i) => <td key={i}>{row}</td>)}</tr>
        </tbody>
        <thead>
          <tr>{header_lower.map((name, i) => <th key={i}>{name}</th>)}</tr>
        </thead>
        <tbody>
          <tr>{body_lower.map((row, i) => <td className='mission-result-agent-td' key={i}>{row}</td>)}</tr>
        </tbody>
      </table >
    </>;
  }

  function buildMissionItem() {
    let extraThreats = mission.intel.extraThreats ?? 0;
    if (resources.renown && mission.modifier.find((m) => m === 'mod_mission_7_resent')) {
      extraThreats += 2
    }
    if (mission.modifier.find((m) => m === 'mod_mission_8_weaken')) {
      extraThreats -= 2
    }

    const injured = allies.filter(a => !game.agentAvail(a)).length;

    const time = Math.floor(sim.tick / opts.tps);

    return [
      mission.ty,
      sim.world.simover_rule,
      mission_state.agents.length,
      injured,
      secondToHHMM(time),
      mission.intel.training,
      `${mission.intel.threats}${extraThreats ? `(${extraThreats})` : ``}`
    ]
  }

  function buildTabRow(entity) {
    const killCount = trails.filter((t) => t.source === entity && t.kill).length;
    const vocations = [];
    const mods = [];
    const agent = entityToAgent(entity);
    let life = entity.life;
    let exp = 0;
    let power = agent.power;
    let life_max = entity.life_max;


    for (const { ty, args } of mission_state.reward) {
      if (ty === 'agent' && args.agent.idx === agent.idx) {
        const { vocation, modifier, agenda } = args;

        if (vocation) {
          vocations.push(vocation);
        }
        if (modifier) {
          for (const mod of modifier) {
            mods.push({ key: mod, start: turn, term: args.term });
          }
        }
        if (agenda) {
          mods.push({ key: agenda });
        }

        if (!isNaN(args.life)) {
          life = Math.max(1, Math.min(life_max, args.life));
        }

        if (!isNaN(args.exp)) {
          exp += args.exp;
        }

        power = power + (args.power ?? 0);
        if (args.stats2?.toughness) {
          const res = life_max_change(agent, args.stats2?.toughness);
          life_max = res.life_max;
          life = res.life;
        }
      }
    }
    const recoverDays = AgentRecoverDays(agent, life, life_max);

    const expData = exps.exps.find((e) => e.level === agent.level.cur);
    exp = Math.floor(exp);
    const expText = exp + agent.level.exp >= expData.exp ? `+${exp}(level up)` : ` +${exp}`;

    const { turnCost, shareRatio } = agent.contract;
    const reviveCost = Math.round(turnCost + shareRatio * 5000);

    return [
      entity.name,
      agentToFirearmName(agent),
      agent.power.toFixed(2),
      (() => {
        let extra = null;
        if (entity.life > 0) {
          if (Math.ceil(recoverDays) > 0) {
            extra = <span>{L('loc_dynamic_string_result_statistics_days_recovery', { count: Math.ceil(recoverDays) })}</span>;
          }
        } else {
          extra = <span>
            <font className='agent-critical'>
              {L('loc_ui_string_result_agent_dead')}
              <button className="inventory-item-notice" onClick={() => { entity.life = 1; onRevive(entity.idx); }}>
                {L('loc_dynamic_button_result_agent_dead', { value: reviveCost })}
              </button>
            </font>
          </span>;
        }
        return <div>
          <ProgressBar width={100} cur={entity.life} max={entity.life_max} />
          {extra ? <><br />{extra}</> : null}
        </div>;
      })(),
      <div>
        <ProgressBar width={200} cur={agent.level.exp} growth={exp} max={expData.exp} />
        {expText}
      </div>,
      mods.map((m, i) => <span key={i} title={L(modifiers[m.key].desc)}>{`[${L(modifiers[m.key].name)}]`}</span>),
      killCount,
    ];
  }

  function buildRewardInfo() {
    const resources = [];
    let renown = 0;
    let sales = 0;
    let total_share = 0;
    let total_recover = 0;
    let net_profit = 0;
    const shares = {};
    const recovers = {};

    for (const ally of allies) {
      const agent = entityToAgent(ally);

      const missionCost = -agent.contract.missionCost;
      shares[ally.idx] = missionCost;
      total_share += missionCost;
      net_profit += missionCost;

      const recoverDays = AgentRecoverDays(agent, ally.life);
      const recoverCost = Math.ceil(-recoverDays * agent.contract.turnCost / 28);

      recovers[ally.idx] = recoverCost;
      total_recover += recoverCost;
      net_profit += recoverCost;
    }

    const renown_details = [];
    for (const { ty, args } of mission_state.reward) {
      if (ty === 'resource') {
        const { resource, ty } = args;

        resources.push(args);
        net_profit += resource;

        switch (ty) {
          case 'share':
          case 'contract':
          case 'extra':
            shares[args.agents[0].idx] += resource;
            total_share += resource;
            break;
          case 'revive':
            recovers[args.idx] += resource;
            total_recover += resource;
            break;
          default:
            sales += resource;
            break;
        }
      }
      else if (ty === 'renown') {
        renown += args.point;
        let reason = ''
        switch (args.reason) {
          case 'mission_clear':
            reason = L('loc_ui_title_result') + L('loc_ui_string_common_success');
            break;
          case 'mission_fail':
            reason = L('loc_ui_title_result') + L('loc_ui_string_common_failure');
            break;
          case 'deadAgent':
            const agent = game.agents.find((a) => a.idx === args.idx);
            reason = L('loc_dynamic_string_result_reason_agent_dead', { value: agent.name });
            break;
          default:
            break;
        }

        renown_details.push({ point: args.point, reason });
      }
    }

    return [
      <span className='tooltip' title={renown_details.map(({ point, reason }) => `${reason}: ${point}`).join('\n')}>{renown >= 0 ? `+${renown}` : `${renown} `}</span>,
      `$${sales} `,
      <span className='tooptip' title={allies.map(({ name, idx }) => `${name}: $${recovers[idx]} `).join('\n')}>{`$${total_recover} `}</span>,
      <span className='tooptip' title={allies.map(({ name, idx }) => `${name}: $${shares[idx]} `).join('\n')}>{`$${total_share} `}</span>,
      net_profit >= 0 ? `$${net_profit} ` : `-$${-net_profit}`,
    ];
  }

  function buildInventoryRewardInfo() {
    const rewards = [];
    for (const { ty, args } of mission_state.reward) {
      if (ty === 'inventory') {
        const { ty } = args;

        switch (ty) {
          case 'firearm':
            rewards.push(<FirearmBox firearm={args.firearm} />);
            break;
          case 'equipment':
            rewards.push(<GearBox equipment={args.equipment} />);
            break;
          case 'throwable':
            rewards.push(<ThrowableBox throwable={args.throwable} />);
            break;
          default:
            break;
        }
      }
    }
    if (rewards.length > 0) {
      return <>
        <span className='mission-result-subtitle'>{L('loc_ui_string_result_contract_reward_item')}</span>
        {rewards}
      </>
    }
    else {
      return null;
    }
  }

  function buildStatistics(entity) {
    const agent = entityToAgent(entity);
    const damage_done = _.sum(trails.filter((t) => t.source === entity && t.hit).map((t) => t.damage))
    const damage_all = _.sum(trails.filter((t) => t.source === entity).map((t) => t.damage));
    const damage_taken = _.sum(trails.filter((t) => t.target === entity).map((t) => t.damage));

    const throwKills = [];
    for (const j of sim.journal) {
      if (j.ty === 'throw_kill' && j.entity === entity && j.target.deadTick >= j.tick && !throwKills.includes(j.target)) {
        throwKills.push(j.target);
      }
    }
    const killCount = trails.filter((t) => t.source === entity && t.kill && t.target.deadTick >= t.tick).length
      + throwKills.length;
    const heal = _.sum(heals.filter((h) => h.source.idx === entity.idx).map((h) => h.heal_amount));

    const entity_throwables = sim.throwables.filter((t) => t.entity.idx === entity.idx);
    let throwable_suppression = 0;
    for (const throwable of entity_throwables) {
      const suppressed = [];
      for (const blastarea of throwable.blastareas) {
        for (const e of blastarea.entities) {
          if ((e.state !== 'dead' || e.deadTick >= blastarea.tick) && !suppressed.includes(e)) {
            suppressed.push(e);
          }
        }
      }
      throwable_suppression += suppressed.length;
    }

    // const desmar_suppression = _.sum(sim.journal.filter((j) =>
    //   j.entity.idx === entity.idx && (j.perk === 'perk_desmar_distraction' || j.perk === 'perk_desmar_distraction_range')
    // ).map((j) => j.targets.length));

    const throwables_text = [];
    const throwables_all = agent.throwables.map(t => t.throwable_name);
    const throwables_used = sim.throwables.filter(t => t.entity.idx === agent.idx).map(t => t.throwable.throwable_name);

    for (const throwable of throwables_used) {
      const idx = throwables_all.indexOf(throwable);
      if (idx > -1) throwables_all.splice(idx, 1);
      throwables_text.push(L(throwable) + L('loc_ui_string_result_grenade_used'));
    }

    for (const throwable of throwables_all) {
      if (throwable !== 'None') {
        throwables_text.push(L(throwable) + L('loc_ui_string_result_grenade_unused'));
      }
    }

    return [
      entity.name,
      agent.level.cur,
      agent.power.toFixed(2),
      agentToFirearmName(agent),
      L(agent.equipment.vest_name),
      throwables_text.join('\n'),
      killCount,
      damage_done,
      damage_all,
      damage_taken,
      heal,
      throwable_suppression,
    ]
  }

  const rows = [];
  const statistics = [];
  for (const entity of allies) {
    rows.push(buildTabRow(entity));
    statistics.push(buildStatistics(entity));
  }

  const missionItem = buildMissionItem();

  const costInfo = buildRewardInfo();

  statistics.sort((a, b) => {
    const va = a[sortOpt.index];
    const vb = b[sortOpt.index];

    const mul = sortOpt.isDesc ? 1 : -1;

    if (vb > va) {
      return mul;
    }
    else if (va > vb) {
      return -mul;
    }
    else {
      return 0;
    }
  });

  let tab_stat = [
    <table className="stat-small">
      <thead>
        <tr>{header_agent.map((name, i) => <th key={i}>{L(name)}</th>)}</tr>
      </thead>
      <tbody>
        {rows.map((row, i) => <tr key={i}>
          {row.map((cell, j) => <td key={j}>{cell}</td>)}
        </tr>)}
      </tbody>
    </table>,
  ];

  const tab_stats = [
    <table className='statistics-small'>
      <thead>
        <tr>{header_statistics.map((name, i) => <th key={i} onClick={(_ev) => {
          setSortOpt({ index: i, isDesc: sortOpt.index === i ? !sortOpt.isDesc : true });
        }}>{L(name)}</th>)}</tr>
      </thead>
      <tbody>
        {statistics.map((statistic, i) => <tr key={i} onClick={() => {
          const selected = game.agents.find((a) => a.name === statistics[i][0]);
          const next = selected === focusedAgent ? null : selected;
          setFocusedAgent(next);
        }}>
          {statistic.map((cell, j) => <td key={j}>{cell}</td>)}
        </tr>)}
      </tbody>
    </table >,
    <FocusedAgentView agent={focusedAgent} />,
  ];

  const tabview = tab === 0 ? tab_stat : tab_stats;


  return <>
    <span className="mission-result-subtitle">{L('loc_ui_string_result_mission_evaluation') + getRank(mission_state.res === 0)}</span>
    <table className='missionItem-small'>
      <thead>
        <tr>{header_missionItem.map((name, i) => <th key={i}>{L(name)}</th>)}</tr>
      </thead>
      <tbody>
        <tr>
          {missionItem.map((row, i) => <td key={i}>{row}</td>)}
        </tr>
      </tbody>
    </table>

    <span className="mission-result-subtitle">{L('loc_ui_string_result_statistics_mission')}</span>
    <div>
      {["loc_ui_string_result_statistics_summary", "loc_ui_string_result_statistics_combat"].map((name, i) => {
        let cls = "mission-result-tab";
        if (i === tab) {
          cls += " mission-result-tab-selected";
        }
        return <button key={i} onClick={() => setTab(i)} className={cls}> {L(name)}</button>;
      })}
    </div>
    {tabview}

    <span className="mission-result-subtitle">{L('loc_ui_string_result_contract_reward')}</span>
    <table className="reward-small">
      <thead>
        <tr>{header_reward.map((name, i) => <th key={i}>{L(name)}</th>)}</tr>
      </thead>
      <tbody>
        <tr>
          {costInfo.map((row, i) => <td key={i}>{row}</td>)}
        </tr>
      </tbody>
    </table>
    {buildInventoryRewardInfo()}
  </>;
}
