// downloaded data!
export const data = [
  {
    "key": "focus_1_1",
    "seq": "1",
    "subseq": "1",
    "narrative": "1",
    "col": "0",
    "name": "시작",
    "prerequisite_cond": "",
    "prerequisities": "",
    "complete_cond": "",
    "complete_value": "",
    "duration": "1",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "renown_once",
    "effect_0_value": "10",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "임무 브로커, 무기상, 병원 등 우리의 활동에 필요한 사람들과 커넥션을 만들자",
    "effect_text": "명성 +10",
    "effect_comment": "자 이제 시작이야."
  },
  {
    "key": "focus_1_4",
    "seq": "1",
    "subseq": "4",
    "narrative": "1",
    "col": "0",
    "name": "복수의 시작",
    "prerequisite_cond": "",
    "prerequisities": "focus_1_1",
    "complete_cond": "renown",
    "complete_value": "100",
    "duration": "0",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "milestone_open",
    "effect_0_value": "1",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "칼리 카르텔의 마약 제조실을 공격하려면 우리 힘만으로는 부족하다. 뒤를 봐줄 누군가 필요해.",
    "effect_text": "마일스톤1 활성화",
    "effect_comment": "DEA 요원이 접근했다. 정체를 감춰주겠다며 마약제조실 공격을 요청했다. 우리야 고맙지."
  },
  {
    "key": "focus_1_7",
    "seq": "1",
    "subseq": "7",
    "narrative": "0",
    "col": "10",
    "name": "교관 영입",
    "prerequisite_cond": "",
    "prerequisities": "focus_1_1",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "train_slot",
    "effect_0_value": "1",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "용병을 훈련시킬 교관이 필요하다. 옛 친구 중 괜찮은 녀석을 알지.",
    "effect_text": "훈련슬롯 1개 획득",
    "effect_comment": "환영하네. 이제 오합지졸을 전투병기로 만들 시간이야."
  },
  {
    "key": "focus_1_8",
    "seq": "1",
    "subseq": "8",
    "narrative": "0",
    "col": "20",
    "name": "훈련시설 개선: 바랑키야",
    "prerequisite_cond": "",
    "prerequisities": "focus_1_1",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "train_efficiency_1",
    "effect_0_value": "10",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "바랑키야의 훈련장은 낡고 허름하지만 좀 수리하면 쓸만해질 것이다.",
    "effect_text": "바랑키야 훈련 시 훈련효율 +10%",
    "effect_comment": "이 정도면 나쁘지 않아. 화장실도 있고."
  },
  {
    "key": "focus_1_9",
    "seq": "1",
    "subseq": "9",
    "narrative": "0",
    "col": "30",
    "name": "응급처치 교육",
    "prerequisite_cond": "",
    "prerequisities": "focus_1_1",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "hp_after",
    "effect_0_value": "10",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "모든 용병이 기본적인 응급처치라도 할 줄 안다면 피해를 줄일 수 있다.",
    "effect_text": "임무 종료 후 체력 회복 +10%p",
    "effect_comment": "자, 이건 지혈대라는 거야. 근처에서 나무 막대기 같은 걸 구해서..."
  },
  {
    "key": "focus_1_10",
    "seq": "1",
    "subseq": "10",
    "narrative": "0",
    "col": "40",
    "name": "기술자 탐색",
    "prerequisite_cond": "",
    "prerequisities": "focus_1_1",
    "complete_cond": "",
    "complete_value": "",
    "duration": "1",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "",
    "effect_0_value": "",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "전투장비를 봐줄 전문가를 구해야 한다.",
    "effect_text": "",
    "effect_comment": "창인가 방패인가."
  },
  {
    "key": "focus_1_11",
    "seq": "1",
    "subseq": "11",
    "narrative": "0",
    "col": "40",
    "name": "총기 전문가 영입",
    "prerequisite_cond": "",
    "prerequisities": "focus_1_10",
    "complete_cond": "",
    "complete_value": "",
    "duration": "2",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "focus_1_12",
    "effect_0_key": "update_trade_day",
    "effect_0_value": "-7",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "최선의 방어는 공격이다. 총기에 미친 사람을 하나 아는데, 그가 적임자다.",
    "effect_text": "상점 업데이트 주기 -7일",
    "effect_comment": "전문성은 무기다. 우리가 갖춘 전문성에 놀라, 무기상은 더 빠르게 매물 정보를 알려오기로 했다."
  },
  {
    "key": "focus_1_12",
    "seq": "1",
    "subseq": "12",
    "narrative": "0",
    "col": "41",
    "name": "방탄 전문가 영입",
    "prerequisite_cond": "",
    "prerequisities": "focus_1_10",
    "complete_cond": "",
    "complete_value": "",
    "duration": "2",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "focus_1_11",
    "effect_0_key": "update_trade_day",
    "effect_0_value": "-7",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "안전하다고 느끼면 더 적극적으로 싸울 수 있다. 방탄복 전문가를 하나 알고 있지.",
    "effect_text": "상점 업데이트 주기 -7일",
    "effect_comment": "전문성은 방탄복이다. 우리가 갖춘 전문성에 감탄한 무기상은 더 빠르게 매물 정보를 알려오기로 했다."
  },
  {
    "key": "focus_1_13",
    "seq": "1",
    "subseq": "13",
    "narrative": "0",
    "col": "50",
    "name": "이미지 작업",
    "prerequisite_cond": "",
    "prerequisities": "focus_1_1",
    "complete_cond": "",
    "complete_value": "",
    "duration": "1",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "renown_monthly",
    "effect_0_value": "4",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "외부에서 우리 회사를 어떻게 기억하게 될지 결정해야 한다.",
    "effect_text": "매달 명성 +4",
    "effect_comment": "우아한 신사들이냐, 추잡한 불량배냐."
  },
  {
    "key": "focus_1_14",
    "seq": "1",
    "subseq": "14",
    "narrative": "0",
    "col": "50",
    "name": "무장한 신사들",
    "prerequisite_cond": "",
    "prerequisities": "focus_1_13",
    "complete_cond": "",
    "complete_value": "",
    "duration": "2",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "focus_1_15",
    "effect_0_key": "reward_renown_pos",
    "effect_0_value": "10",
    "effect_1_key": "reward_renown_neg",
    "effect_1_value": "20",
    "descr": "평판을 관리하자. 우리 용병들은 신사답게 행동할 것이다.",
    "effect_text": "임무 성공으로 명성이 상승하면 상승치 +10%, 하락하면 하락치 +20%",
    "effect_comment": "우리 회사의 용병들은 적이든 아군이든 신사답게 대한다. 사람들은 우리를 존경하겠지만, 작은 실수에도 크게 실망할 것이다."
  },
  {
    "key": "focus_1_15",
    "seq": "1",
    "subseq": "15",
    "narrative": "0",
    "col": "51",
    "name": "깡패 집단",
    "prerequisite_cond": "",
    "prerequisities": "focus_1_13",
    "complete_cond": "",
    "complete_value": "",
    "duration": "2",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "focus_1_14",
    "effect_0_key": "reward_renown_pos",
    "effect_0_value": "-10",
    "effect_1_key": "reward_renown_neg",
    "effect_1_value": "-20",
    "descr": "대놓고 불량하면 나쁜 짓 좀 해도 아무도 뭐라 안 한다.",
    "effect_text": "임무 성공으로 명성이 상승하면 상승치 -10%, 하락하면 하락치 -20%",
    "effect_comment": "우리 회사의 용병들이 더럽게 싸우고 명예도 모른다는 인식이 퍼졌다. 이런 우리가 민간인 차에 구멍 좀 낸다고 놀라는 사람은 없을 거다."
  },
  {
    "key": "focus_2_1",
    "seq": "2",
    "subseq": "1",
    "narrative": "1",
    "col": "0",
    "name": "전쟁의 불씨",
    "prerequisite_cond": "",
    "prerequisities": "focus_1_4",
    "complete_cond": "milestone_clear",
    "complete_value": "1",
    "duration": "0",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "branch_open",
    "effect_0_value": "2",
    "effect_1_key": "mod_global_boom",
    "effect_1_value": "3",
    "descr": "DEA의 공작이 먹혔다. 칼리 카르텔은 마약제조실 파괴의 범인으로 메데인 카르텔을 의심하고 있다. 놈들은 전쟁에 대비해 용병을 모으고 있다.",
    "effect_text": "칼리 지부 해금, [호경기] 모디파이어 획득(3개월)",
    "effect_comment": "이때 자연스럽게 칼리로 진출하면 오히려 의심을 덜 받겠지."
  },
  {
    "key": "focus_2_2",
    "seq": "2",
    "subseq": "2",
    "narrative": "1",
    "col": "0",
    "name": "사업 확장",
    "prerequisite_cond": "",
    "prerequisities": "focus_2_1",
    "complete_cond": "branch_expand",
    "complete_value": "2",
    "duration": "0",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "renown_once",
    "effect_0_value": "40",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "칼리로 진출해 사업을 키우고 칼리를 더 물먹일 기회를 찾자.",
    "effect_text": "명성 +40",
    "effect_comment": "이제 이곳에 적응할 시간이다. 의뢰를 받아 수익을 올리고 용병을 육성하자."
  },
  {
    "key": "focus_2_4",
    "seq": "2",
    "subseq": "4",
    "narrative": "1",
    "col": "0",
    "name": "백경",
    "prerequisite_cond": "",
    "prerequisities": "focus_2_2",
    "complete_cond": "renown",
    "complete_value": "500",
    "duration": "0",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "milestone_open",
    "effect_0_value": "2",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "배에 접근할 방법을 고민하는 중 지난번의 DEA 요원이 접근했다. 유조선 건을 알려주며 협업을 제안하자, 상부 지원을 받아 도와주기로 했다. 다만 워낙 큰 일이라 우리의 실력과 신용을 입증할 것을 요구했다.",
    "effect_text": "마일스톤2 활성화",
    "effect_comment": "우리의 명성이 미 대사관까지 전해지면서, DEA 요원이 상부의 허가를 받았다고 알려왔다."
  },
  {
    "key": "focus_2_7",
    "seq": "2",
    "subseq": "7",
    "narrative": "0",
    "col": "10",
    "name": "훈련 커리큘럼 구상",
    "prerequisite_cond": "and",
    "prerequisities": "focus_1_7, focus_2_2",
    "complete_cond": "",
    "complete_value": "",
    "duration": "1",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "",
    "effect_0_value": "",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "훈련시설과 교관만 있다고 되는 게 아니다. 옛 기억을 떠올려 용병을 가르칠 체계와 내용을 구상한다.",
    "effect_text": "",
    "effect_comment": "군 시절 받았던 정석적인 훈련이 나을지, 실전을 통한 교육이 나을지."
  },
  {
    "key": "focus_2_8",
    "seq": "2",
    "subseq": "8",
    "narrative": "0",
    "col": "10",
    "name": "커리큘럼: 훈련의 정석",
    "prerequisite_cond": "",
    "prerequisities": "focus_2_7",
    "complete_cond": "",
    "complete_value": "",
    "duration": "2",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "focus_2_9",
    "effect_0_key": "train_efficiency_all",
    "effect_0_value": "25",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "군 특수부대처럼 철저하고 정석적인 훈련으로 효과를 높인다.",
    "effect_text": "훈련 효율 +25%",
    "effect_comment": "군 시절을 떠올려 본다. 교본과 훈련 프로그램을 정교하게 구성하자. 훈련을 마치고 나면, 모두 훌륭한 전사로 재탄생할 것이다."
  },
  {
    "key": "focus_2_9",
    "seq": "2",
    "subseq": "9",
    "narrative": "0",
    "col": "11",
    "name": "커리큘럼: 실전 중시",
    "prerequisite_cond": "",
    "prerequisities": "focus_2_7",
    "complete_cond": "",
    "complete_value": "",
    "duration": "2",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "focus_2_8",
    "effect_0_key": "train_duration_all",
    "effect_0_value": "-20",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "훈련과 교육은 속성으로 끝내고, 실전에서 스스로 배워야 한다.",
    "effect_text": "훈련 기간 -20%",
    "effect_comment": "용병 시절을 떠올려 본다. 이론은 기초일 뿐이다. 꼭 알아야 하는 요점만 숙지시킨다."
  },
  {
    "key": "focus_2_10",
    "seq": "2",
    "subseq": "10",
    "narrative": "0",
    "col": "20",
    "name": "훈련시설 개선: 칼리",
    "prerequisite_cond": "and",
    "prerequisities": "focus_1_8, focus_2_2",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "train_efficiency_2",
    "effect_0_value": "10",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "칼리의 훈련장은 너무 좁다. 확장해서 쾌적한 환경을 만들자.",
    "effect_text": "칼리에서 훈련 시 훈련효율 +10%",
    "effect_comment": "벌레에 물릴 걱정 없이 훈련한다는 게 얼마나 감사한 일인가."
  },
  {
    "key": "focus_2_11",
    "seq": "2",
    "subseq": "11",
    "narrative": "0",
    "col": "30",
    "name": "구급차 마련",
    "prerequisite_cond": "and",
    "prerequisities": "focus_1_9, focus_2_2",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "hp_unable_recover",
    "effect_0_value": "20",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "다친 용병을 빠르게 가까운 병원으로 후송할 시스템을 만들자.",
    "effect_text": "임무 불가 상태에서의 회복속도 +20%",
    "effect_comment": "부상에 빠르게 대처해야 회복도 빠르다."
  },
  {
    "key": "focus_2_12",
    "seq": "2",
    "subseq": "12",
    "narrative": "0",
    "col": "40",
    "name": "노획 규칙: 총기",
    "prerequisite_cond": "and",
    "prerequisities": "focus_1_11, focus_2_2",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "focus_2_13",
    "effect_0_key": "reward_firearm_prob",
    "effect_0_value": "50",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "임무에 나가면 총기 위주로 노획하도록 한다.",
    "effect_text": "임무 선택보상에 무기가 나올 확률 +50%",
    "effect_comment": "좋은 무기를 모아 전투력을 강화하자."
  },
  {
    "key": "focus_2_13",
    "seq": "2",
    "subseq": "13",
    "narrative": "0",
    "col": "41",
    "name": "노획 규칙: 방탄",
    "prerequisite_cond": "and",
    "prerequisities": "focus_1_12, focus_2_2",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "focus_2_12",
    "effect_0_key": "reward_equip_prob",
    "effect_0_value": "50",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "임무에 나가면 장비 위주로 노획하도록 한다.",
    "effect_text": "임무 선택보상에 장비가 나올 확률 +50%",
    "effect_comment": "좋은 장비를 모아 전투력을 강화하자."
  },
  {
    "key": "focus_2_14",
    "seq": "2",
    "subseq": "14",
    "narrative": "0",
    "col": "50",
    "name": "매너가 사람을 만든다",
    "prerequisite_cond": "and",
    "prerequisities": "focus_1_14, focus_2_2",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "focus_2_15",
    "effect_0_key": "reward_merc_overall",
    "effect_0_value": "2",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "우릴 동경하는 용병이 늘어나고 있다. 포로를 잘 회유하면 좋은 전력이 된다.",
    "effect_text": "선택보상으로 나오는 용병의 overall +2",
    "effect_comment": "작전 중 잡힌 포로에 대한 처우를 개선하자 실력자들도 우리에게 넘어오기 시작한다."
  },
  {
    "key": "focus_2_15",
    "seq": "2",
    "subseq": "15",
    "narrative": "0",
    "col": "51",
    "name": "약탈 장려",
    "prerequisite_cond": "and",
    "prerequisities": "focus_1_15, focus_2_2",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "focus_2_14",
    "effect_0_key": "reward_cash_value",
    "effect_0_value": "30",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "임무 현장에서 습득하는 귀중품은 좋은 부수입이 된다.",
    "effect_text": "현금 선택보상의 금액 +30%",
    "effect_comment": "어차피 죽은 사람은 집세 안 내도 된다고."
  },
  {
    "key": "focus_2_16",
    "seq": "2",
    "subseq": "16",
    "narrative": "0",
    "col": "60",
    "name": "스카우터 영입",
    "prerequisite_cond": "",
    "prerequisities": "focus_2_2",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "recruit_refresh_cost",
    "effect_0_value": "-50",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "용병 채용 전문가를 영입한다.",
    "effect_text": "고용창 새로고침 비용 -50%",
    "effect_comment": "채용하면 좋을 유능한 용병에 대한 정보를 더 쉽게 구할 수 있다."
  },
  {
    "key": "focus_3_1",
    "seq": "3",
    "subseq": "1",
    "narrative": "1",
    "col": "0",
    "name": "유령선 전쟁",
    "prerequisite_cond": "",
    "prerequisities": "focus_2_4",
    "complete_cond": "milestone_clear",
    "complete_value": "2",
    "duration": "0",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "branch_open",
    "effect_0_value": "3",
    "effect_1_key": "update_mission_day",
    "effect_1_value": "-1",
    "descr": "유조선은 케이멘 제도에서 발견됐다. 선원, 호위병 그리고 코카인 모두 사라져 있었다. 칼리 카르텔은 메데인 카르텔의 소행을 확신하고 전면전을 선언했다.",
    "effect_text": "메데인 지부 해금, 임무 업데이트 주기 -1일",
    "effect_comment": "이제 양대 카르텔 사이의 전쟁이 벌어질 것이고, 더 큰 세력을 갖춘 메데인이 승리할 것이다. 하지만 우리가 그걸 막을 것이다. 우리는 이 싸움을 패자뿐인 지옥으로 만들 것이다."
  },
  {
    "key": "focus_3_2",
    "seq": "3",
    "subseq": "2",
    "narrative": "1",
    "col": "0",
    "name": "메데인의 목마",
    "prerequisite_cond": "",
    "prerequisities": "focus_3_1",
    "complete_cond": "branch_expand",
    "complete_value": "3",
    "duration": "0",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "mission_extra_pos",
    "effect_0_value": "25",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "DEA 지부장이 직접 연락해 메데인 활동을 권유했다. 갑자기 벌어진 전쟁 때문에 우리의 의도를 의심할 수는 없을 거라고 한다.",
    "effect_text": "임무가 생성될 때 25% 확률로 명성 상승 임무가 추가로 생성",
    "effect_comment": "이제부터 DEA가 직접 나설 수 없는 부분은 우리에게 맡기기로 했다. 큰 수익이 나지는 않아도 시민을 지키고 카르텔과 싸우는 임무가 될 것이다."
  },
  {
    "key": "focus_3_6",
    "seq": "3",
    "subseq": "6",
    "narrative": "1",
    "col": "0",
    "name": "역사적 의미",
    "prerequisite_cond": "",
    "prerequisities": "focus_3_2",
    "complete_cond": "renown",
    "complete_value": "2000",
    "duration": "0",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "milestone_open",
    "effect_0_value": "3",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "이번 전쟁에서 메데인의 선봉에 있는 건 돈 베르나의 전투부대. 돈 베르나를 제거하면 메데인에 큰 타격을 줄 수 있다. 카르텔 조직원 및 주민들에게 돈을 뿌리며 수색을 진행하면 위치를 알아낼 수 있을 것이다.",
    "effect_text": "마일스톤3 활성화",
    "effect_comment": "놈의 소재를 파악했다. 거기 숨어 있었군..."
  },
  {
    "key": "focus_3_11",
    "seq": "3",
    "subseq": "11",
    "narrative": "0",
    "col": "10",
    "name": "커리큘럼: 훈련교본",
    "prerequisite_cond": "and",
    "prerequisities": "focus_2_8, focus_3_2",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "train_cost_all",
    "effect_0_value": "-25",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "교육내용을 하나의 통일된 교본으로 정리하면 훈련 중의 혼란을 줄일 수 있다.",
    "effect_text": "훈련 비용 -25%",
    "effect_comment": "FM대로 해, FM대로!"
  },
  {
    "key": "focus_3_12",
    "seq": "3",
    "subseq": "12",
    "narrative": "0",
    "col": "11",
    "name": "커리큘럼: 모두 너의 스승이다",
    "prerequisite_cond": "and",
    "prerequisities": "focus_2_9, focus_3_2",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "aptitude_prob",
    "effect_0_value": "20",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "실전에서 만난 적과 동료로부터 배우는 자세와 관찰력이 중요하다.",
    "effect_text": "무기 적성 획득 확률 +20%",
    "effect_comment": "자존심 부리지 말고 받아들여야 살아남는다고."
  },
  {
    "key": "focus_3_13",
    "seq": "3",
    "subseq": "13",
    "narrative": "0",
    "col": "20",
    "name": "훈련시설 개선: 메데인",
    "prerequisite_cond": "and",
    "prerequisities": "focus_2_10, focus_3_2",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "train_efficiency_3",
    "effect_0_value": "10",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "메데인 훈련장은 너무 구식이라 훈련 의지마저 떨어뜨린다. 신식으로 개조하자.",
    "effect_text": "메데인에서 훈련 시 훈련효율 +10%",
    "effect_comment": "이게 그 일본에서 사온 비데라는 것인데..."
  },
  {
    "key": "focus_3_14",
    "seq": "3",
    "subseq": "14",
    "narrative": "0",
    "col": "21",
    "name": "체력단련장 설립",
    "prerequisite_cond": "and",
    "prerequisities": "focus_2_10, focus_3_2",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "train_efficiency_physical",
    "effect_0_value": "20",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "강인한 육체에 강인한 용병이 깃든다. 운동을 통해 신체를 단련할 수 있는 장소를 마련한다.",
    "effect_text": "Physical 훈련 효율 +20%",
    "effect_comment": "아놀드 슈왈제네거라는 보디빌더가 요즘 유명하다지? 포스터를 하나 구해서 붙여놓을까."
  },
  {
    "key": "focus_3_15",
    "seq": "3",
    "subseq": "15",
    "narrative": "0",
    "col": "30",
    "name": "조기퇴원",
    "prerequisite_cond": "and",
    "prerequisities": "focus_2_11, focus_3_2",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "focus_3_16",
    "effect_0_key": "hp_able_threshold",
    "effect_0_value": "-20",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "용병들을 빨리 퇴원시키도록 의사를 압박하자.",
    "effect_text": "해고, 훈련, 임무 투입을 위한 최소 체력 각각 -20%p",
    "effect_comment": "한 명 더 있느냐 없느냐가 성패를 가른다. 총만 잡을 수 있으면 임무에도 나갈 수 있는 거다."
  },
  {
    "key": "focus_3_16",
    "seq": "3",
    "subseq": "16",
    "narrative": "0",
    "col": "31",
    "name": "팀닥터 채용",
    "prerequisite_cond": "and",
    "prerequisities": "focus_2_11, focus_3_2",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "focus_3_15",
    "effect_0_key": "hp_cost",
    "effect_0_value": "-50",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "용병들의 건강과 치료에 몰두할 의사를 고용한다.",
    "effect_text": "회복 비용 -50%",
    "effect_comment": "전문의 대기 중."
  },
  {
    "key": "focus_3_17",
    "seq": "3",
    "subseq": "17",
    "narrative": "0",
    "col": "40",
    "name": "무기 사냥",
    "prerequisite_cond": "and",
    "prerequisities": "focus_2_12, focus_3_2",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "mission_firearm",
    "effect_0_value": "15",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "좋은 무기를 얻을 수 있는 기회를 찾아낸다.",
    "effect_text": "임무가 생성될 때 15% 확률로 [무기고] 모디파이어 부여",
    "effect_comment": "무기란 원래 적에게서 취하는 법이다."
  },
  {
    "key": "focus_3_18",
    "seq": "3",
    "subseq": "18",
    "narrative": "0",
    "col": "41",
    "name": "장비 사냥",
    "prerequisite_cond": "and",
    "prerequisities": "focus_2_13, focus_3_2",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "mission_equipment",
    "effect_0_value": "15",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "좋은 장비를 얻을 수 있는 기회를 찾아낸다.",
    "effect_text": "임무가 생성될 때 15% 확률로 [방탄장비 보관소] 모디파이어 부여",
    "effect_comment": "몸통 말고 얼굴을 쏘란 말이야. 그래야 방탄복 안 상하지."
  },
  {
    "key": "focus_3_19",
    "seq": "3",
    "subseq": "19",
    "narrative": "0",
    "col": "50",
    "name": "연합전선",
    "prerequisite_cond": "and",
    "prerequisities": "focus_2_14, focus_3_2",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "mission_militia",
    "effect_0_value": "20",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "좋은 평판 덕분에 우릴 돕고자 하는 사람이 많다.",
    "effect_text": "임무가 생성될 때 20% 확률로 [현지 증원] 모디파이어 부여",
    "effect_comment": "도움을 마다할 이유가 없다."
  },
  {
    "key": "focus_3_20",
    "seq": "3",
    "subseq": "20",
    "narrative": "0",
    "col": "51",
    "name": "하이에나의 방식",
    "prerequisite_cond": "and",
    "prerequisities": "focus_2_15, focus_3_2",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "mission_hyena",
    "effect_0_value": "20",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "적이 다른 집단과 싸우고 지쳐있을 때 공격하는 것이 상책이다.",
    "effect_text": "임무가 생성될 때 20% 확률로 [후속 타격 의뢰] 모디파이어 부여",
    "effect_comment": "기회를 마다할 이유가 없다."
  },
  {
    "key": "focus_3_21",
    "seq": "3",
    "subseq": "21",
    "narrative": "0",
    "col": "60",
    "name": "채용 방침: 양",
    "prerequisite_cond": "and",
    "prerequisities": "focus_2_16, focus_3_2",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "focus_3_22",
    "effect_0_key": "recruit_size",
    "effect_0_value": "1",
    "effect_1_key": "recruit_overall",
    "effect_1_value": "-1",
    "descr": "완벽할 필요 없으니 최대한 많은 채용 후보를 보고하도록.",
    "effect_text": "용병 채용 목록 크기 +1, overall -1",
    "effect_comment": "질보다 양."
  },
  {
    "key": "focus_3_22",
    "seq": "3",
    "subseq": "22",
    "narrative": "0",
    "col": "61",
    "name": "채용 방침: 질",
    "prerequisite_cond": "and",
    "prerequisities": "focus_2_16, focus_3_2",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "focus_3_21",
    "effect_0_key": "recruit_size",
    "effect_0_value": "-1",
    "effect_1_key": "recruit_overall",
    "effect_1_value": "1",
    "descr": "꼼꼼한 검증을 거친 능력자만 보고하도록.",
    "effect_text": "용병 채용 목록 크기 -1, overall +1",
    "effect_comment": "양보다 질."
  },
  {
    "key": "focus_3_23",
    "seq": "3",
    "subseq": "23",
    "narrative": "0",
    "col": "62",
    "name": "총무팀 구성",
    "prerequisite_cond": "and",
    "prerequisities": "focus_2_16, focus_3_2",
    "complete_cond": "",
    "complete_value": "",
    "duration": "1",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "",
    "effect_0_value": "",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "용병들의 작전을 뒤에서 지원할 부서를 조직하자.",
    "effect_text": "",
    "effect_comment": "각자 자기 일에 집중할 수 있어야 좋은 회사다."
  },
  {
    "key": "focus_4_1",
    "seq": "4",
    "subseq": "1",
    "narrative": "1",
    "col": "0",
    "name": "소강상태",
    "prerequisite_cond": "or",
    "prerequisities": "focus_3_6",
    "complete_cond": "milestone_clear",
    "complete_value": "3",
    "duration": "0",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "branch_open",
    "effect_0_value": "4",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "돈 베르나의 갑작스러운 죽음으로 메데인의 공세가 꺾였고, 칼리의 반격이 성공했다. 메데인과 칼리 모두 막대한 피해를 입었고, 전쟁은 소강상태에 들어갔다.",
    "effect_text": "보고타 지부 해금, 임무 업데이트 주기 +1일",
    "effect_comment": "놈들을 완전히 끝장내려면 더 거대한 힘이 필요하다."
  },
  {
    "key": "focus_4_2",
    "seq": "4",
    "subseq": "2",
    "narrative": "1",
    "col": "0",
    "name": "상경",
    "prerequisite_cond": "",
    "prerequisities": "focus_4_1",
    "complete_cond": "branch_expand",
    "complete_value": "4",
    "duration": "0",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "renown_once",
    "effect_0_value": "320",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "수도 보고타에 입성하면 공권력의 힘을 빌릴 수 있을지도 모른다.",
    "effect_text": "명성 +320",
    "effect_comment": "그 힘을 누구에게서 빌릴지... 정치판은 다음 대통령직을 두고 경쟁이 한창이다. 네? 보좌관이요?"
  },
  {
    "key": "focus_4_6",
    "seq": "4",
    "subseq": "6",
    "narrative": "1",
    "col": "0",
    "name": "역사의 전환점",
    "prerequisite_cond": "",
    "prerequisities": "focus_4_2",
    "complete_cond": "renown",
    "complete_value": "8000",
    "duration": "0",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "milestone_open",
    "effect_0_value": "4",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "투표일이 다가오고 있다. 우리의 후보가 당선되었을 때, 카르텔 소탕 작전을 제시하고 또 우리가 그 선봉에 서려면 명성을 더 높일 필요가 있다. 다른 누구도 아니고 대통령이니까.",
    "effect_text": "마일스톤4 활성화",
    "effect_comment": "우리 후보가 당선됐다! 하지만 우리를 대통령과 연결해줄 보좌관이 에스코바르의 시카리오들에게 납치당하고 말았다. 놈들을 소탕하고 보좌관을 구해야 한다."
  },
  {
    "key": "focus_4_11",
    "seq": "4",
    "subseq": "11",
    "narrative": "0",
    "col": "10",
    "name": "커리큘럼: 반복숙달",
    "prerequisite_cond": "and",
    "prerequisities": "focus_3_11, focus_4_2",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "train_efficiency_stats",
    "effect_0_value": "20",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "이해할 때까지 반복하라. 이해했으면 완벽할 때까지 반복하라.",
    "effect_text": "스탯 훈련 효율 +20%",
    "effect_comment": "각자의 특기를 완벽히 숙달하면 최강의 부대가 탄생한다."
  },
  {
    "key": "focus_4_12",
    "seq": "4",
    "subseq": "12",
    "narrative": "0",
    "col": "11",
    "name": "커리큘럼: 다재다능",
    "prerequisite_cond": "and",
    "prerequisities": "focus_3_12, focus_4_2",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "train_efficiency_overall",
    "effect_0_value": "20",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "살아남기 위해서라면 모든 능력을 고루 길러야 한다.",
    "effect_text": "overall 훈련 효율 +20%",
    "effect_comment": "언제 어떤 상황이 벌어질지 모르는 게 용병의 삶."
  },
  {
    "key": "focus_4_13",
    "seq": "4",
    "subseq": "13",
    "narrative": "0",
    "col": "20",
    "name": "훈련시설 개선: 보고타",
    "prerequisite_cond": "and",
    "prerequisities": "focus_3_13, focus_4_2",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "train_efficiency_4",
    "effect_0_value": "10",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "국내 최고 수준의 훈련장을 만들자.",
    "effect_text": "보고타에서 훈련 시 훈련효율 +10%",
    "effect_comment": "아마 미국 놈들보다도 좋은 곳에서 훈련하는 거다."
  },
  {
    "key": "focus_4_14",
    "seq": "4",
    "subseq": "14",
    "narrative": "0",
    "col": "21",
    "name": "트레이너 영입",
    "prerequisite_cond": "and",
    "prerequisities": "focus_3_14, focus_4_2",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "train_efficiency_physical",
    "effect_0_value": "10",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "전문가의 지도 아래 운동해야 부상의 위험을 줄일 수 있다.",
    "effect_text": "Physical 훈련 효율 +10%",
    "effect_comment": "그 유명한 조셉 필라테스의 직계 제자라고 하니 다들 말 잘 듣도록."
  },
  {
    "key": "focus_4_15",
    "seq": "4",
    "subseq": "15",
    "narrative": "0",
    "col": "30",
    "name": "진통제 지급",
    "prerequisite_cond": "and",
    "prerequisities": "focus_3_15, focus_4_2",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "hp_penalty",
    "effect_0_value": "-50",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "통증을 억제한다면 인간은 엄청난 힘을 낼 수 있다.",
    "effect_text": "부족한 체력에 의한 전투력 페널티 -50%",
    "effect_comment": "너무 많이 먹지는 말도록. 그럼 중독될 수... 아 뭐, 몸에 나쁜 건 아니니까 불안해 하지 말고."
  },
  {
    "key": "focus_4_16",
    "seq": "4",
    "subseq": "16",
    "narrative": "0",
    "col": "31",
    "name": "재활치료시설",
    "prerequisite_cond": "and",
    "prerequisities": "focus_3_16, focus_4_2",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "hp_able_recover",
    "effect_0_value": "20",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "용병들이 빠르게 최고의 상태로 복귀할 수 있게 돕는다.",
    "effect_text": "임무에 투입될 수 있는 상태에서 회복속도 +20%",
    "effect_comment": "옛말에 이르기를, 명장은 싸우기 전에 이긴다. 만반의 준비를 하고 싸우는 게 당연한 거다."
  },
  {
    "key": "focus_4_17",
    "seq": "4",
    "subseq": "17",
    "narrative": "0",
    "col": "40",
    "name": "세계적 정보망",
    "prerequisite_cond": "and",
    "prerequisities": "focus_3_17, focus_4_2",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "update_trade_day",
    "effect_0_value": "-2",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "전세계의 무기상들과의 커넥션을 통해 시장에 나온 무기를 더 빠르게 확보한다.",
    "effect_text": "상점 업데이트 주기 -2일",
    "effect_comment": "내가 입찰한 돌격소총 상회입찰 하지마라."
  },
  {
    "key": "focus_4_18",
    "seq": "4",
    "subseq": "18",
    "narrative": "0",
    "col": "41",
    "name": "철저한 장비 검사",
    "prerequisite_cond": "and",
    "prerequisities": "focus_3_18, focus_4_2",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "trade_equipment_cost",
    "effect_0_value": "-30",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "장비의 작은 결함들을 발견해 트집 잡아서 가격을 깎는다.",
    "effect_text": "장비 구매가 -30%",
    "effect_comment": "벨크로가 반대로 달려있긴 하지만 성능은 문제 없어. 단추 달아줄게."
  },
  {
    "key": "focus_4_19",
    "seq": "4",
    "subseq": "19",
    "narrative": "0",
    "col": "60",
    "name": "임무 리뷰",
    "prerequisite_cond": "and",
    "prerequisities": "focus_3_23, focus_4_2",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "personal_milestone_veteran",
    "effect_0_value": "-10",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "임무에서 얻은 경험과 지식을 정리할 자리가 필요하다.",
    "effect_text": "베테랑 용병 마일스톤의 목표치 10% 감소",
    "effect_comment": "발표 시키면 싫어하겠지."
  },
  {
    "key": "focus_4_20",
    "seq": "4",
    "subseq": "20",
    "narrative": "0",
    "col": "61",
    "name": "훈련일지 작성",
    "prerequisite_cond": "and",
    "prerequisities": "focus_3_23, focus_4_2",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "personal_milestone_fm",
    "effect_0_value": "-10",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "매일 훈련내용을 기록하면 훈련내용을 더 잘 이해할 수 있다.",
    "effect_text": "FM 전문가 마일스톤의 목표치 10% 감소",
    "effect_comment": "300자 이상은 써라. 막내한테 시키지 말고."
  },
  {
    "key": "focus_4_21",
    "seq": "4",
    "subseq": "21",
    "narrative": "0",
    "col": "62",
    "name": "사살 리더보드",
    "prerequisite_cond": "and",
    "prerequisities": "focus_3_23, focus_4_2",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "personal_milestone_ace",
    "effect_0_value": "-10",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "임무를 경쟁으로, 사살을 점수로 생각하게 되면 더 큰 성취감을 가질 것",
    "effect_text": "에이스 마일스톤의 목표치 10% 감소",
    "effect_comment": "덩치랑 상관없이 한 명당 똑같이 1점이야. 싸우지 마라."
  },
  {
    "key": "focus_4_22",
    "seq": "4",
    "subseq": "22",
    "narrative": "0",
    "col": "63",
    "name": "표창장 수여",
    "prerequisite_cond": "and",
    "prerequisities": "focus_3_23, focus_4_2",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "personal_milestone_unbreakable",
    "effect_0_value": "-10",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "동료 대신 총알에 맞는 건 숭고한 영웅의 행동이지. 자랑할 수 있게 표창장을 주자.",
    "effect_text": "불굴의 요원 마일스톤의 목표치 10% 감소",
    "effect_comment": "이걸로 고통을 덜 수 있으면 좋을 텐데."
  },
  {
    "key": "focus_4_23",
    "seq": "4",
    "subseq": "23",
    "narrative": "0",
    "col": "64",
    "name": "복무신조 제창",
    "prerequisite_cond": "and",
    "prerequisities": "focus_3_23, focus_4_2",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "personal_milestone_social",
    "effect_0_value": "-10",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "아침저녁으로 복무신조를 제창하면 소속감을 키울 수 있다.",
    "effect_text": "사회인 마일스톤의 목표치 10% 감소",
    "effect_comment": "우리 회사의 용병으로서의 정체성을 확립하도록."
  },
  {
    "key": "focus_4_24",
    "seq": "4",
    "subseq": "24",
    "narrative": "0",
    "col": "65",
    "name": "우수사원 발표",
    "prerequisite_cond": "and",
    "prerequisities": "focus_3_23, focus_4_2",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "personal_milestone_cashcow",
    "effect_0_value": "-10",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "회사에 크게 기여한 직원이 있으면 모두에게 알려야지.",
    "effect_text": "캐시 카우 마일스톤의 목표치 10% 감소",
    "effect_comment": "우리는 영리단체니까."
  },
  {
    "key": "focus_4_25",
    "seq": "4",
    "subseq": "25",
    "narrative": "0",
    "col": "66",
    "name": "임무 정보 공개",
    "prerequisite_cond": "and",
    "prerequisities": "focus_3_23, focus_4_2",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "personal_milestone_honor",
    "effect_0_value": "-10",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "왜, 누굴 위해 싸우는지 명확히 알면 더 뿌듯해할 거다.",
    "effect_text": "명예 훈장 마일스톤의 목표치 10% 감소",
    "effect_comment": "다들 보람을 느꼈으면 좋겠다."
  },
  {
    "key": "focus_4_26",
    "seq": "4",
    "subseq": "26",
    "narrative": "0",
    "col": "67",
    "name": "월간 심리상담",
    "prerequisite_cond": "and",
    "prerequisities": "focus_3_23, focus_4_2",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "personal_milestone_reaper",
    "effect_0_value": "-10",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "전장은 트라우마가 생기기 좋은 환경이지만 친절한 말 몇마디면 강력한 동기로 바꿀 수 있다.",
    "effect_text": "사신 마일스톤의 목표치 10% 감소",
    "effect_comment": "죽음의 공포를 극복하면 한 단계 성장한다."
  },
  {
    "key": "focus_5_1",
    "seq": "5",
    "subseq": "1",
    "narrative": "1",
    "col": "0",
    "name": "서치 블락",
    "prerequisite_cond": "",
    "prerequisities": "focus_4_6",
    "complete_cond": "milestone_clear",
    "complete_value": "4",
    "duration": "0",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "",
    "effect_0_value": "",
    "effect_1_key": "mod_personal_morale_all",
    "effect_1_value": "3",
    "descr": "대통령은 카르텔 전담 경찰특수부대 서치 블락을 창설했다. 보좌관의 도움으로, 우리는 서치 블락과 협력할 수 있게 되었다.",
    "effect_text": "모든 용병에게 [사기충천] 모디파이어 부여(3개월)",
    "effect_comment": "이제 최종장이다."
  },
  {
    "key": "focus_5_2",
    "seq": "5",
    "subseq": "2",
    "narrative": "1",
    "col": "0",
    "name": "공조체계 구축",
    "prerequisite_cond": "",
    "prerequisities": "focus_5_1",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "mission_extra_pos",
    "effect_0_value": "50",
    "effect_1_key": "update_mission_day",
    "effect_1_value": "1",
    "descr": "서치 블락의 지휘관과 함께 카르텔을 어떻게 소탕할지 계획을 짜고 체계를 구축한다.",
    "effect_text": "임무가 생성될 때 50% 확률로 명성 상승 임무가 추가로 생성, 임무 업데이트 주기 +1일",
    "effect_comment": "준비는 끝났다."
  },
  {
    "key": "focus_5_3",
    "seq": "5",
    "subseq": "3",
    "narrative": "1",
    "col": "0",
    "name": "마약과의 전쟁",
    "prerequisite_cond": "",
    "prerequisities": "focus_5_2",
    "complete_cond": "renown",
    "complete_value": "12000",
    "duration": "0",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "hp_unable_recover",
    "effect_0_value": "30",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "이제 전면전이다.",
    "effect_text": "임무 불가 상태에서의 회복속도 +20%",
    "effect_comment": "계속된 승리에 고무된 용병들이 몸을 불살라가며 싸움에 임하고 있다."
  },
  {
    "key": "focus_5_5",
    "seq": "5",
    "subseq": "5",
    "narrative": "1",
    "col": "0",
    "name": "궁지에 몰린 쥐",
    "prerequisite_cond": "",
    "prerequisities": "focus_5_3",
    "complete_cond": "renown",
    "complete_value": "15000",
    "duration": "0",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "milestone_open",
    "effect_0_value": "5",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "양 카르텔의 수뇌부가 협정을 맺기 위해 모이는 장소를 기습할 수 있다면 적을 완전히 무너뜨릴 수 있다. 놈들을 몰아붙여야 한다.",
    "effect_text": "마일스톤5 활성화",
    "effect_comment": "서치 블락에서 메데인의 에스코바르와 칼리의 로드리게스 형제가 회담을 가지는 장소와 시간을 알아냈다. 지휘관은 내게 영광을 넘긴다며, 기습을 이끌 것을 제안했다. 평생 기다리던 순간이 찾아왔다..."
  },
  {
    "key": "focus_5_11",
    "seq": "5",
    "subseq": "11",
    "narrative": "1",
    "col": "0",
    "name": "다섯 발의 총성",
    "prerequisite_cond": "",
    "prerequisities": "focus_5_5",
    "complete_cond": "milestone_clear",
    "complete_value": "5",
    "duration": "0",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "train_efficiency_all",
    "effect_0_value": "20",
    "effect_1_key": "update_mission_day",
    "effect_1_value": "-1",
    "descr": "복수는 성공했지만 세상은 아직 죽음의 공포로 가득하다. 두 거대 카르텔은 붕괴됐지만 군소 조직들이 들어와 그 자리를 채웠다.",
    "effect_text": "훈련 효율 +20%, 임무 업데이트 주기 -1일",
    "effect_comment": "은과 납의 흐름은 끝나지 않는다. 우리의 일도 계속된다."
  },
  {
    "key": "focus_5_12",
    "seq": "5",
    "subseq": "12",
    "narrative": "0",
    "col": "10",
    "name": "실사격 훈련 프로그램",
    "prerequisite_cond": "or",
    "prerequisities": "focus_4_11, focus_4_12",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "train_efficiency_decision",
    "effect_0_value": "10",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "조준과 사격은 기본기다.",
    "effect_text": "훈련 시 Decision 성장치  +10%",
    "effect_comment": "반동과 소리를 느끼며 실탄을 발사해야 감각을 제대로 익힐 수 있다."
  },
  {
    "key": "focus_5_13",
    "seq": "5",
    "subseq": "13",
    "narrative": "0",
    "col": "11",
    "name": "정신력 함양 프로그램",
    "prerequisite_cond": "or",
    "prerequisities": "focus_4_11, focus_4_12",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "train_efficiency_bravery",
    "effect_0_value": "10",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "위기 상황에서도 이성을 유지하는 법을 가르친다.",
    "effect_text": "훈련 시 Bravery 성장치  +10%",
    "effect_comment": "결정적인 순간에는 정신력이 생사를 가른다."
  },
  {
    "key": "focus_5_14",
    "seq": "5",
    "subseq": "14",
    "narrative": "0",
    "col": "12",
    "name": "모의 전투 프로그램",
    "prerequisite_cond": "or",
    "prerequisities": "focus_4_11, focus_4_12",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "train_efficiency_focus",
    "effect_0_value": "10",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "전장에서 끝까지 집중하고 살아남는 법을 가르친다.",
    "effect_text": "훈련 시 Focus 성장치  +10%",
    "effect_comment": "실전 감각이 무뎌지지 않게 한다."
  },
  {
    "key": "focus_5_15",
    "seq": "5",
    "subseq": "15",
    "narrative": "0",
    "col": "13",
    "name": "시가전 훈련 프로그램",
    "prerequisite_cond": "or",
    "prerequisities": "focus_4_11, focus_4_12",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "train_efficiency_reaction",
    "effect_0_value": "10",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "긴장을 날카롭게 유지하는 법을 가르친다.",
    "effect_text": "훈련 시 Reaction 성장치  +10%",
    "effect_comment": "시가전에서는 그 어떤 상황도 일어날 수 있으니까."
  },
  {
    "key": "focus_5_16",
    "seq": "5",
    "subseq": "16",
    "narrative": "0",
    "col": "14",
    "name": "극기 훈련 프로그램",
    "prerequisite_cond": "or",
    "prerequisities": "focus_4_11, focus_4_12",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "train_efficiency_toughness",
    "effect_0_value": "10",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "죽지 않으면 강해진다.",
    "effect_text": "훈련 시 Toughness 성장치  +10%",
    "effect_comment": "본 조교는 천사가 될 수도 악마가 될 수도 있다."
  },
  {
    "key": "focus_5_17",
    "seq": "5",
    "subseq": "17",
    "narrative": "0",
    "col": "15",
    "name": "사격술 교육 프로그램",
    "prerequisite_cond": "or",
    "prerequisities": "focus_4_11, focus_4_12",
    "complete_cond": "",
    "complete_value": "",
    "duration": "3",
    "expire_cond": "",
    "expire_value": "",
    "exclusives": "",
    "effect_0_key": "train_efficiency_precision",
    "effect_0_value": "10",
    "effect_1_key": "",
    "effect_1_value": "",
    "descr": "여러 상황에서 필요한 최신 사격술을 가르친다.",
    "effect_text": "훈련 시 Precision 성장치 +10%",
    "effect_comment": "각종 사격 자세와 탄도학까지 숙지해야 최고의 사수가 된다"
  }
];