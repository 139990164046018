import { facilities as data_facilities } from './data/google/processor/data_facilities.mjs';
import { work as data_work } from './data/google/processor/data_work.mjs';
import { presetsStaff as data_presetsStaff } from './data/google/processor/data_presetsStaff.mjs';
import { staffAbilities as data_staffAbilities } from './data/google/processor/data_staffAbility.mjs';
import { Rng } from './rand.mjs';
import { sampleAgentStats } from './character.mjs';
import { TICK_PER_DAY } from './tick.mjs';

const STAFF_STAT_MAX = 20;
const WAGE_MULTIPLIER_MIN = 1;
const WAGE_MULTIPLIER_MAX = 1.9;
const MANAGE_DECAY = 1;
const MANAGE_DECAY_MAX = 3;
const DEPARTMENT_ITEM_EXPIRE_DAYS = 28;

const ABILITY_KEYS = [];
for (const staffAbility of data_staffAbilities) {
    ABILITY_KEYS.push(staffAbility.key);
}

//props: {ability, var1, var2}
const DIFFICULTY_FUNCTIONS = {
    'search_agent_tier': (props) => {
        const { var1 } = props;
        return Number(var1) * 10;
    },
    'search_agent_possible': (props) => {
        const { ability } = props;
        return ability;
    },
    'search_staff': (props) => {
        const { ability } = props;
        return ability;
    },
    'recruit_staff': (props) => {
        const { var1 } = props;
        let ability_sum = 0;
        if (var1) {
            for (const ability_key of ABILITY_KEYS) {
                ability_sum += staffAbilityValue(var1, ability_key);
            }
        }
        return 2 * ability_sum / ABILITY_KEYS.length;
    },
    'source_gun_tier': (props) => {
        const { var1 } = props;
        return Number(var1) * 12;
    },
    'source_gun_ty_tier': (props) => {
        const { var2 } = props;
        return Number(var2) * 18;
    },
    'source_bulletproof_tier': (props) => {
        const { var1 } = props;
        return Number(var1) * 10;
    },
    'do_training': (props) => {
        return 10;
    },
    'maintain_training': (props) => {
        const { ability } = props;
        return ability;
    },
    'search_contract': (props) => {
        const { var1 } = props;
        return 5 + 5 * var1;
    },
};

//props: {difficulty, var1, var2}
const DURATION_BASE_DAYS_FUNCTIONS = {
    'search_agent_tier': (props) => {
        const { var1 } = props;
        return 7 + Number(var1) * 3.5;
    },
    'search_agent_possible': (props) => {
        const { difficulty } = props;
        return 7 * (1 + difficulty * 0.1);
    },
    'source_gun_tier': (props) => {
        const { var1 } = props;
        return Number(var1) * 10;
    },
    'source_gun_ty_tier': (props) => {
        const { var2 } = props;
        return Number(var2) * 14;
    },
};

const DURATION_MINIMUM_DAYS_FUNCTIONS = {
    'search_agent_tier': (props) => {
        const { var1 } = props;
        return 3.5 + Number(var1) * 1.75;
    },
    'search_agent_possible': (props) => {
        const { difficulty } = props;
        return 3.5 * (1 + difficulty * 0.1);
    },
    'source_gun_tier': (props) => {
        const { var1 } = props;
        return Number(var1) * 5;
    },
    'source_gun_ty_tier': (props) => {
        const { var2 } = props;
        return Number(var2) * 7;
    },
};

const GROWTH_PRESETS = [
    [1, 1, 1, 1, 1.2],
    [0.8, 1, 1, 1, 1.4],
    [0.9, 0.9, 1, 1.2, 1.2],
];

const TEMPORAL_DEPARTMENT_KEY = 'temp';
const TEMPORAL_DEPARTMENT_STAT = 3;

export {
    STAFF_STAT_MAX,
    ABILITY_KEYS,
    TICK_PER_DAY,
    DURATION_BASE_DAYS_FUNCTIONS,
    DEPARTMENT_ITEM_EXPIRE_DAYS,
    TEMPORAL_DEPARTMENT_KEY,
};

const temporal_department_template = {
    key: TEMPORAL_DEPARTMENT_KEY,
    name: '임시 부서',
    core_abilities: ABILITY_KEYS.slice(),
    fid: 0,
    head_id: -1,
    members_id: [],
    members_max: 0,
    pendings: []
};

export function newStaff(rng, id, power, name, turn) {
    const stat_deviation = 2;
    const stats = sampleAgentStats(rng, Math.min(power, STAFF_STAT_MAX), ABILITY_KEYS.length, stat_deviation, STAFF_STAT_MAX);

    const totalStat = stats.reduce((a, b) => a + b, 0);

    const wage_multiplier = rng.range(WAGE_MULTIPLIER_MIN, WAGE_MULTIPLIER_MAX);
    const wage = Math.floor(totalStat * wage_multiplier);

    const abilities = {};

    const growths = {};
    const growth_nums = rng.shuffle(rng.choice(GROWTH_PRESETS).slice());

    const caps = {};

    for (let i = 0; i < ABILITY_KEYS.length; i++) {
        abilities[ABILITY_KEYS[i]] = stats[i];
        growths[ABILITY_KEYS[i]] = growth_nums[i];

        const min_cap = Math.min(Math.max(stats[i] + 2, 6), STAFF_STAT_MAX);
        const max_cap = Math.max(Math.min(stats[i] * growth_nums[i], STAFF_STAT_MAX), min_cap);

        caps[ABILITY_KEYS[i]] = Math.floor(rng.range(min_cap, max_cap) * 10) / 10;
    }

    for (let i = 0; i < ABILITY_KEYS.length; i++) {
        growths[ABILITY_KEYS[i]] = growth_nums[i];
    }

    const nameParts = name.split('"');
    const displayName = nameParts.length < 3 ? name : `${nameParts[0]} ${nameParts[2]}`;

    return {
        id,
        name,
        displayName,
        abilities,
        caps,
        growths,
        wage,
        department_fid: -1,
        expires_at: turn + DEPARTMENT_ITEM_EXPIRE_DAYS * TICK_PER_DAY,
    };
}

export class DepartmentRoot {
    constructor(props) {
        if (props) {
            Object.assign(this, props);
            this.rng = new Rng(this.seed);
        }
        else {
            this.initialize();
        }
    }

    initialize() {
        const seed = Rng.randomseed();
        const rng = new Rng(seed);

        this.nextStaffId = 0;

        const staffs = this.createPresetsStaff();
        const departments = [{ ...temporal_department_template }];
        const logs = [];

        this.staffs = staffs;
        this.seed = seed;
        this.rng = rng;
        this.departments = departments;
        this.logs = logs;
        this.finishedPendings = [];
        this.recruit_listings = [];
    }

    initWorldFacilities(world) {
        for (const center of world.centers) {
            const { centerstate } = world.storage[center.idx];

            if (centerstate.office) {
                const { facilities } = centerstate;

                for (let i = 0; i < facilities.length; i++) {
                    const f = facilities[i];
                    const facilityData = data_facilities.find((d) => d.key === f.key);
                    const department_needed = facilityData.staff_heads + facilityData.staffs > 0;
                    if (department_needed) {
                        const department = createDepartmentFromFacility(f.key, f.fid);
                        this.departments.push(department);
                    }
                }
            }
        }
    }

    onTick(game) {
        const { departments } = this;

        for (const department of departments) {
            this.onTickDepartment(department, game);
        }
    }

    onTickDepartment(department, game) {
        const { pendings } = department;
        for (let i = 0; i < pendings.length; i++) {
            const pending = pendings[i];
            pending.ticks--;
            if (pending.ticks <= 0) {
                pending.department = department;
                this.finishedPendings.push(pending);
                this.removePending(department, i, game);
            }
        }
    }

    createPresetsStaff() {
        let { nextStaffId } = this;

        const staffs = [];
        for (const data of data_presetsStaff) {
            const staff = { ...data, id: nextStaffId, department_fid: -1, displayName: data.name };
            nextStaffId++;
            staffs.push(staff);
        }

        this.nextStaffId = nextStaffId;

        return staffs;
    }

    addStaffToMarket(power, name, turn) {
        const { recruit_listings, rng } = this;
        const id = this.nextStaffId++;

        const staff = newStaff(rng, id, power, name, turn);
        recruit_listings.push(staff);

        return staff;
    }

    checkRecruitListings(turn) {
        this.recruit_listings = this.recruit_listings.filter((r) => r.expires_at > turn);
    }

    recruitStaff(staff) {
        const { recruit_listings, staffs } = this;
        const index = recruit_listings.findIndex((s) => s.id === staff.id);
        if (index >= 0) {
            recruit_listings.splice(index, 1);
            delete staff.expires_at;
            staffs.push(staff);
        }
    }

    fireStaff(staff_id) {
        const { staffs } = this;
        const staff_index = staffs.findIndex((s) => s.id === staff_id);
        if (staff_index < 0) {
            return;
        }
        const staff = staffs[staff_index];
        if (staff.department_fid >= 0) {
            this.removeStaffFromDepartment(staff_id, staff.department_fid);
        }

        staffs.splice(staff_index, 1);
    }

    breakDepartment(department) {
        const { departments, staffs } = this;
        const head = staffs.find((s) => s.id === department.head_id);
        if (head) {
            head.department_fid = -1;
        }
        for (const member_id of department.members_id) {
            const member = staffs.find((s) => s.id === member_id);
            if (member) {
                member.department_fid = -1;
            }
        }

        const idx = departments.findIndex((d) => d.fid === department.fid);
        if (idx >= 0) {
            departments.splice(idx, 1);
        }
    }

    upgradeDepartment(department, facilityData) {
        const { staffs } = this;

        department.key = facilityData.key;
        department.name = facilityData.name;

        const members_length = department.members_id.length;
        if (members_length > facilityData.staffs) {
            for (let i = facilityData.staffs; i < members_length; i++) {
                const member = staffs.find((s) => s.id === department.members_id[i]);
                if (member) {
                    member.department_fid = -1;
                }
            }
            department.members_id.splice(facilityData.staffs, members_length - facilityData.staffs);
        }
        department.members_max = facilityData.staffs;
    }

    removeStaffFromDepartment(staff_id, department_fid) {
        const { staffs, departments } = this;
        const staff = staffs.find((s) => s.id === staff_id);
        const department = departments.find((d) => d.fid === department_fid);

        if (!staff || !department || staff.department_fid !== department_fid) {
            return;
        }

        if (department.head_id === staff.id) {
            department.head_id = -1;
        }
        else {
            const index = department.members_id.indexOf(staff.id);
            if (index >= 0) {
                department.members_id.splice(index, 1);
            }
        }

        staff.department_fid = -1;
    }

    setStaffToDepartmentMember(staff_id, department_fid) {
        const { staffs, departments } = this;
        const staff = staffs.find((s) => s.id === staff_id);
        const department = departments.find((d) => d.fid === department_fid);

        if (!staff || !department || (staff.department_fid === department.fid && department.head_id !== staff.id) || department.members_id.length >= department.members_max) {
            return;
        }

        this.removeStaffFromDepartment(staff_id, staff.department_fid);

        staff.department_fid = department_fid;
        department.members_id.push(staff_id);

        department.members_id.sort((a, b) => {
            const staff_a = staffs.find((s) => s.id === a);
            const staff_b = staffs.find((s) => s.id === b);
            let totalStat_a = 0;
            let totalStat_b = 0;
            for (const key of department.core_abilities) {
                totalStat_a += departmentStaffAbilityValue(this, department, staff_a, key).toFixed(1);
                totalStat_b += departmentStaffAbilityValue(this, department, staff_b, key).toFixed(1);
            }
            if (totalStat_a < totalStat_b) return 1;
            else if (totalStat_a > totalStat_b) return -1;
            return 0;
        });
    }

    setStaffToDepartmentHead(staff_id, department_fid) {
        const { staffs, departments } = this;
        const staff = staffs.find((s) => s.id === staff_id);
        const department = departments.find((d) => d.fid === department_fid);
        if (!staff || !department || department.head_id === staff_id) {
            return;
        }

        this.removeStaffFromDepartment(staff_id, staff.department_fid);

        const head = staffs.find((s) => s.id === department.head_id)
        if (head) {
            this.removeStaffFromDepartment(head.id, department_fid);
        }

        staff.department_fid = department_fid;
        department.head_id = staff_id;
    }

    availDepartments(work_key) {
        const departments = this.departments.filter((d) => data_facilities.find((f) => f.key === d.key)?.tasks.find((t) => t.work_key === work_key))
            .filter((d) => !(d.head_id < 0));

        if (departments.length === 0) {
            const temporary_department = this.departments.find((d) => d.key === TEMPORAL_DEPARTMENT_KEY);
            if (temporary_department) {
                departments.push(temporary_department);
            }
        }
        return departments;
    }

    defaultDepartment(work_key) {
        return this.availDepartments(work_key)[0];
    }

    createPending(work_key, department, var1, var2) {
        const data = data_work.find(w => w.work_key === work_key);

        const ability_ty = data.ability_ty;
        const ability = this.departmentTotalStat(department, ability_ty);

        const difficulty_func = DIFFICULTY_FUNCTIONS[work_key];
        const difficulty = difficulty_func ?
            difficulty_func({
                ability,
                var1,
                var2,
            }) : data.ability_diff_fixed;

        const { days } = getWorkDuration(work_key, ability, difficulty, var1, var2);
        const ticks = Math.floor(days * TICK_PER_DAY);

        return {
            work_key,
            work_type: data.work_type,
            head_id: department.head_id,
            members_id: department.members_id.slice(),
            ability,
            var1,
            var2,
            difficulty,
            repeatOption: data.on_finish_repeat,
            stopTimerOption: data.on_finish_stop_timer,
            ticks,
            work_effect_ty: data.work_effect_ty,
            success_count: 0,
            total_count: 0,
        };
    }

    addPending(department, pending, game) {
        department.pendings.push(pending);
        switch (pending.work_key) {
            case 'recruit_staff':
                {
                    const staff = pending.var1;
                    staff.blockExpire = true;
                    break;
                }
            case 'buy_item':
                {
                    const item = pending.var1;
                    item.blockExpire = true;

                    if (item.ty === 'firearm') {
                        game.triggerQuest('buy_weapon');
                    }
                    game.triggerQuest('buy_item');
                    game.pushCashbook({ ty: 'market', resource: -item.buy_cost });
                    break;
                }
            case 'recruit_agent':
                {
                    const agent = pending.var1;
                    const item = game.recruit_listings.find((i) => i.agent.idx === agent.idx);

                    if (item) {
                        item.blockExpire = true;
                    }
                    break;
                }
            default:
                break;
        }

        game.triggerQuest('department_work', pending.work_key);
    }

    removePending(department, pending_idx, game) {
        const pending = department.pendings[pending_idx];
        department.pendings.splice(pending_idx, 1);
        switch (pending.work_key) {
            case 'recruit_staff':
                {
                    const staff_id = pending.var1.id;
                    const staff = this.recruit_listings.find((r) => r.id === staff_id);
                    if (staff) {
                        staff.blockExpire = false;
                    }
                    break;
                }
            case 'buy_item':
                {
                    const item_id = pending.var1.id;
                    const item = game.market_listings.find((m) => m.id === item_id);
                    if (item) {
                        item.blockExpire = false;
                    }
                    break;
                }
            case 'recruit_agent':
                {
                    const agent = pending.var1;
                    const item = game.recruit_listings.find((i) => i.agent.idx === agent.idx);
                    if (item) {
                        item.blockExpire = false;
                    }
                    break;
                }
            default:
                break;
        }

    }

    departmentTotalStat(department, key) {
        const { staffs } = this;
        const { members_id } = department;

        if (department.key === TEMPORAL_DEPARTMENT_KEY) {
            return TEMPORAL_DEPARTMENT_STAT;
        }

        members_id.sort((a, b) => {
            const staff_a = staffs.find((s) => s.id === a);
            const staff_b = staffs.find((s) => s.id === b);
            if (staff_a.abilities[key] < staff_b.abilities[key]) return 1;
            else if (staff_a.abilities[key] > staff_b.abilities[key]) return -1;
            return 0;
        });

        let totalStat = 0;
        let departmentManage = 0;

        const head = staffs.find((s) => s.id === department.head_id);

        if (head) {
            const statValue = staffAbilityValue(head, key);
            departmentManage = staffAbilityValue(head, 'manage');
            totalStat += statValue;
        }

        for (let i = 0; i < members_id.length; i++) {
            const staff = staffs.find((s) => s.id === members_id[i]);
            if (staff) {
                const statValue = getStaffAbility(staff, key, departmentManage, i);
                totalStat += statValue;
            }
        }

        return totalStat;
    }

    growStaff(staff_id, ability_ty, days, base_days, success_rate) {
        if (staff_id < 0) {
            return null;
        }

        const { staffs } = this;

        const staff = staffs.find((s) => s.id === staff_id);
        if (staff) {
            const growth_amount = staff.growths[ability_ty] * 0.1 * Math.min(days, base_days) * success_rate / 28;

            const stat_prev = staff.abilities[ability_ty];
            const stat_new = Math.min(stat_prev + growth_amount, staff.caps[ability_ty]);
            staff.abilities[ability_ty] = stat_new;

            return { staff_id, staff_name: staff.displayName, ability_ty, stat_prev, stat_new };
        }

        return null;
    }

    growStaffs(pending) {
        const growths = [];
        const { work_key, ability, var1, var2, difficulty, head_id, members_id, success_count, total_count } = pending;

        const work = data_work.find(w => w.work_key === work_key);

        if (work) {
            const { base_days, days } = getWorkDuration(work_key, ability, difficulty, var1, var2);
            const success_rate = total_count > 0 ? success_count / total_count : 0;
            const grow_result_head = this.growStaff(head_id, work.ability_ty, days, base_days, success_rate);
            if (grow_result_head) {
                growths.push(grow_result_head);
            }
            for (const member_id of members_id) {
                const grow_result_member = this.growStaff(member_id, work.ability_ty, days, base_days, success_rate);
                if (grow_result_member) {
                    growths.push(grow_result_member);
                }
            }
        }

        return growths;
    }
}

export function getWorkDuration(work_key, ability, difficulty, var1, var2) {
    const work = data_work.find(w => w.work_key === work_key);

    if (work) {
        const base_days_func = DURATION_BASE_DAYS_FUNCTIONS[work_key];
        const base_days = base_days_func ?
            base_days_func({
                difficulty,
                var1,
                var2,
            }) : Number(work.duration_base_days);

        const minimum_days_func = DURATION_MINIMUM_DAYS_FUNCTIONS[work_key];
        const minimum_days = minimum_days_func ?
            minimum_days_func({
                difficulty,
                var1,
                var2,
            }) : Number(work.duration_minimum_days);

        let days = Math.max(base_days * difficulty / ability, minimum_days);
        if (work_key === 'recruit_agent') {
            days = Math.min(days, 14);
        }

        return { base_days, minimum_days, days };
    }

    return { base_days: -1, minimum_days: -1, days: -1 };
}

export function createDepartmentFromFacility(key, fid) {
    const data_facility = data_facilities.find(f => f.key === key);
    const { ty1, staff_abilities } = data_facility;

    const department = {
        key,
        name: data_facility.name,
        ty1,
        core_abilities: staff_abilities,
        fid,
        head_id: -1,
        members_id: [],
        members_max: data_facility.staffs,
        pendings: []
    };
    return department;
}

export function staffAbilityValue(staff, ability_key) {
    return Math.floor(staff.abilities[ability_key] * 10) / 10;
}

export function departmentStaffAbilityValue(departmentRoot, department, staff, ability_key) {
    const { staffs } = departmentRoot;

    if (department.head_id < 0) {
        return 0;
    }
    else {
        const head = staffs.find((s) => s.id === department.head_id);


        const members_id = department.members_id.slice();

        members_id.sort((a, b) => {
            const staff_a = staffs.find((s) => s.id === a);
            const staff_b = staffs.find((s) => s.id === b);
            if (staff_a.abilities[ability_key] < staff_b.abilities[ability_key]) return 1;
            else if (staff_a.abilities[ability_key] > staff_b.abilities[ability_key]) return -1;
            return 0;
        });

        const staff_index = members_id.findIndex((id) => id === staff.id);
        const departmentManage = staffAbilityValue(head, 'manage');

        if (departmentManage > 0) {
            return getStaffAbility(staff, ability_key, departmentManage, staff_index);
        }
        else {
            return 0;
        }
    }
}

function getStaffAbility(staff, ability_key, departmentManage, staff_index) {
    if (departmentManage > 0) {
        return staffAbilityValue(staff, ability_key) * Math.pow(departmentManage / STAFF_STAT_MAX, (2 + Math.min(MANAGE_DECAY * staff_index, MANAGE_DECAY_MAX)) / 3);
    }
    else {
        return 0;
    }
}
