// downloaded data!
export const data = [
  {
    "stability_level": "온보딩",
    "mission_level": "0",
    "difficulty": "1",
    "maps": "hatchery_020",
    "training": "1",
    "threats_min": "2",
    "threats_max": "2",
    "agents_max": "4",
    "reward_resource_min": "710",
    "reward_resource_max": "1090",
    "weight": "1",
    "reward_firearm_tier_min": "0",
    "reward_firearm_tier_max": "0",
    "reward_equipment_tier_min": "0",
    "reward_equipment_tier_max": "0",
    "reward_agent_power_min": "0",
    "reward_agent_power_max": "0",
    "reward_throwable_type": "none",
    "reward_resource_add_amount": "0",
    "total_contribution_rate": "0.2",
    "event_group": "initial",
    "renown_gain": "3",
    "newbie": "",
    "drop_item_tier": "1",
    "drop_item_prob": "1",
    "drop_item_ty": "sg",
    "exp": "90"
  },
  {
    "stability_level": "온보딩",
    "mission_level": "0",
    "difficulty": "1",
    "maps": "construction_010",
    "training": "1",
    "threats_min": "4",
    "threats_max": "4",
    "agents_max": "4",
    "reward_resource_min": "890",
    "reward_resource_max": "1310",
    "weight": "1",
    "reward_firearm_tier_min": "0",
    "reward_firearm_tier_max": "0",
    "reward_equipment_tier_min": "0",
    "reward_equipment_tier_max": "0",
    "reward_agent_power_min": "0",
    "reward_agent_power_max": "0",
    "reward_throwable_type": "none",
    "reward_resource_add_amount": "0",
    "total_contribution_rate": "0.2",
    "event_group": "neutral",
    "renown_gain": "3",
    "newbie": "",
    "drop_item_tier": "1",
    "drop_item_prob": "1",
    "drop_item_ty": "ar",
    "exp": "90"
  },
  {
    "stability_level": "온보딩",
    "mission_level": "0",
    "difficulty": "1",
    "maps": "embassy_short",
    "training": "1",
    "threats_min": "5",
    "threats_max": "5",
    "agents_max": "4",
    "reward_resource_min": "1160",
    "reward_resource_max": "1640",
    "weight": "1",
    "reward_firearm_tier_min": "1",
    "reward_firearm_tier_max": "2",
    "reward_equipment_tier_min": "1",
    "reward_equipment_tier_max": "2",
    "reward_agent_power_min": "7",
    "reward_agent_power_max": "8",
    "reward_throwable_type": "stun",
    "reward_resource_add_amount": "200",
    "total_contribution_rate": "0.2",
    "event_group": "minor",
    "renown_gain": "5",
    "newbie": "",
    "drop_item_tier": "1",
    "drop_item_prob": "1",
    "drop_item_ty": "equipment",
    "exp": "120"
  },
  {
    "stability_level": "온보딩",
    "mission_level": "0",
    "difficulty": "2",
    "maps": "farm_011",
    "training": "2",
    "threats_min": "5",
    "threats_max": "5",
    "agents_max": "4",
    "reward_resource_min": "1520",
    "reward_resource_max": "2080",
    "weight": "1",
    "reward_firearm_tier_min": "2",
    "reward_firearm_tier_max": "2",
    "reward_equipment_tier_min": "2",
    "reward_equipment_tier_max": "2",
    "reward_agent_power_min": "8",
    "reward_agent_power_max": "11",
    "reward_throwable_type": "stun",
    "reward_resource_add_amount": "400",
    "total_contribution_rate": "0.2",
    "event_group": "major",
    "renown_gain": "5",
    "newbie": "",
    "drop_item_tier": "1",
    "drop_item_prob": "1",
    "drop_item_ty": "equipment",
    "exp": "120"
  },
  {
    "stability_level": "시작",
    "mission_level": "0",
    "difficulty": "1",
    "maps": "",
    "training": "1",
    "threats_min": "6",
    "threats_max": "6",
    "agents_max": "4",
    "reward_resource_min": "1000",
    "reward_resource_max": "1000",
    "weight": "1",
    "reward_firearm_tier_min": "2",
    "reward_firearm_tier_max": "2",
    "reward_equipment_tier_min": "2",
    "reward_equipment_tier_max": "2",
    "reward_agent_power_min": "0",
    "reward_agent_power_max": "0",
    "reward_throwable_type": "none",
    "reward_resource_add_amount": "200",
    "total_contribution_rate": "0.2",
    "event_group": "initial",
    "renown_gain": "0",
    "newbie": "",
    "drop_item_tier": "1",
    "drop_item_prob": "1",
    "drop_item_ty": "",
    "exp": "120"
  },
  {
    "stability_level": "마일스톤",
    "mission_level": "1",
    "difficulty": "4",
    "maps": "",
    "training": "2",
    "threats_min": "15",
    "threats_max": "15",
    "agents_max": "6",
    "reward_resource_min": "10000",
    "reward_resource_max": "10000",
    "weight": "1",
    "reward_firearm_tier_min": "2",
    "reward_firearm_tier_max": "2",
    "reward_equipment_tier_min": "3",
    "reward_equipment_tier_max": "3",
    "reward_agent_power_min": "11",
    "reward_agent_power_max": "11",
    "reward_throwable_type": "stun",
    "reward_resource_add_amount": "500",
    "total_contribution_rate": "0.2",
    "event_group": "milestone1",
    "renown_gain": "0",
    "newbie": "",
    "drop_item_tier": "2",
    "drop_item_prob": "1",
    "drop_item_ty": "",
    "exp": "150"
  },
  {
    "stability_level": "마일스톤",
    "mission_level": "2",
    "difficulty": "6",
    "maps": "",
    "training": "3",
    "threats_min": "20",
    "threats_max": "20",
    "agents_max": "7",
    "reward_resource_min": "10000",
    "reward_resource_max": "10000",
    "weight": "1",
    "reward_firearm_tier_min": "3",
    "reward_firearm_tier_max": "3",
    "reward_equipment_tier_min": "3",
    "reward_equipment_tier_max": "3",
    "reward_agent_power_min": "14",
    "reward_agent_power_max": "14",
    "reward_throwable_type": "stun",
    "reward_resource_add_amount": "1500",
    "total_contribution_rate": "0.2",
    "event_group": "milestone2",
    "renown_gain": "0",
    "newbie": "",
    "drop_item_tier": "3",
    "drop_item_prob": "1",
    "drop_item_ty": "",
    "exp": "180"
  },
  {
    "stability_level": "마일스톤",
    "mission_level": "3",
    "difficulty": "8",
    "maps": "",
    "training": "4",
    "threats_min": "30",
    "threats_max": "30",
    "agents_max": "8",
    "reward_resource_min": "10000",
    "reward_resource_max": "10000",
    "weight": "1",
    "reward_firearm_tier_min": "4",
    "reward_firearm_tier_max": "4",
    "reward_equipment_tier_min": "4",
    "reward_equipment_tier_max": "4",
    "reward_agent_power_min": "17",
    "reward_agent_power_max": "17",
    "reward_throwable_type": "fragment",
    "reward_resource_add_amount": "2500",
    "total_contribution_rate": "0.2",
    "event_group": "milestone3",
    "renown_gain": "0",
    "newbie": "",
    "drop_item_tier": "4",
    "drop_item_prob": "1",
    "drop_item_ty": "",
    "exp": "210"
  },
  {
    "stability_level": "마일스톤",
    "mission_level": "4",
    "difficulty": "10",
    "maps": "",
    "training": "5",
    "threats_min": "35",
    "threats_max": "35",
    "agents_max": "9",
    "reward_resource_min": "25000",
    "reward_resource_max": "25000",
    "weight": "1",
    "reward_firearm_tier_min": "5",
    "reward_firearm_tier_max": "5",
    "reward_equipment_tier_min": "4",
    "reward_equipment_tier_max": "4",
    "reward_agent_power_min": "20",
    "reward_agent_power_max": "20",
    "reward_throwable_type": "fragment",
    "reward_resource_add_amount": "3500",
    "total_contribution_rate": "0.2",
    "event_group": "milestone4",
    "renown_gain": "0",
    "newbie": "",
    "drop_item_tier": "5",
    "drop_item_prob": "1",
    "drop_item_ty": "",
    "exp": "240"
  },
  {
    "stability_level": "마일스톤",
    "mission_level": "5",
    "difficulty": "12",
    "maps": "",
    "training": "6",
    "threats_min": "40",
    "threats_max": "40",
    "agents_max": "10",
    "reward_resource_min": "30000",
    "reward_resource_max": "30000",
    "weight": "1",
    "reward_firearm_tier_min": "5",
    "reward_firearm_tier_max": "5",
    "reward_equipment_tier_min": "4",
    "reward_equipment_tier_max": "4",
    "reward_agent_power_min": "25",
    "reward_agent_power_max": "25",
    "reward_throwable_type": "fragment",
    "reward_resource_add_amount": "5000",
    "total_contribution_rate": "0.2",
    "event_group": "milestone5",
    "renown_gain": "0",
    "newbie": "",
    "drop_item_tier": "5",
    "drop_item_prob": "1",
    "drop_item_ty": "",
    "exp": "300"
  },
  {
    "stability_level": "Peaceful",
    "mission_level": "1",
    "difficulty": "1",
    "maps": "hatchery_010, hatchery_020, hatchery_030, farm_010, construction_010, construction_030",
    "training": "1",
    "threats_min": "4",
    "threats_max": "4",
    "agents_max": "4",
    "reward_resource_min": "890",
    "reward_resource_max": "1310",
    "weight": "2",
    "reward_firearm_tier_min": "0",
    "reward_firearm_tier_max": "0",
    "reward_equipment_tier_min": "0",
    "reward_equipment_tier_max": "0",
    "reward_agent_power_min": "0",
    "reward_agent_power_max": "0",
    "reward_throwable_type": "none",
    "reward_resource_add_amount": "0",
    "total_contribution_rate": "0.2",
    "event_group": "neutral",
    "renown_gain": "5",
    "newbie": "",
    "drop_item_tier": "1",
    "drop_item_prob": "0.1",
    "drop_item_ty": "",
    "exp": "90"
  },
  {
    "stability_level": "Peaceful",
    "mission_level": "1",
    "difficulty": "1",
    "maps": "embassy_short, farm_011, farm_020, farm_021, farm_031, construction_020",
    "training": "1",
    "threats_min": "5",
    "threats_max": "5",
    "agents_max": "4",
    "reward_resource_min": "890",
    "reward_resource_max": "1310",
    "weight": "2",
    "reward_firearm_tier_min": "0",
    "reward_firearm_tier_max": "0",
    "reward_equipment_tier_min": "0",
    "reward_equipment_tier_max": "0",
    "reward_agent_power_min": "0",
    "reward_agent_power_max": "0",
    "reward_throwable_type": "none",
    "reward_resource_add_amount": "0",
    "total_contribution_rate": "0.2",
    "event_group": "neutral",
    "renown_gain": "5",
    "newbie": "",
    "drop_item_tier": "1",
    "drop_item_prob": "0.1",
    "drop_item_ty": "",
    "exp": "90"
  },
  {
    "stability_level": "Peaceful",
    "mission_level": "1",
    "difficulty": "2",
    "maps": "hatchery_010, hatchery_020, hatchery_030, farm_010, construction_010, construction_030",
    "training": "2",
    "threats_min": "5",
    "threats_max": "6",
    "agents_max": "4",
    "reward_resource_min": "1250",
    "reward_resource_max": "1750",
    "weight": "2",
    "reward_firearm_tier_min": "0",
    "reward_firearm_tier_max": "0",
    "reward_equipment_tier_min": "0",
    "reward_equipment_tier_max": "0",
    "reward_agent_power_min": "0",
    "reward_agent_power_max": "0",
    "reward_throwable_type": "none",
    "reward_resource_add_amount": "0",
    "total_contribution_rate": "0.2",
    "event_group": "minor",
    "renown_gain": "15",
    "newbie": "",
    "drop_item_tier": "1",
    "drop_item_prob": "0.15",
    "drop_item_ty": "",
    "exp": "120"
  },
  {
    "stability_level": "Peaceful",
    "mission_level": "1",
    "difficulty": "2",
    "maps": "embassy_short, farm_011, farm_020, farm_021, farm_031, construction_020",
    "training": "1",
    "threats_min": "10",
    "threats_max": "11",
    "agents_max": "5",
    "reward_resource_min": "1250",
    "reward_resource_max": "1750",
    "weight": "2",
    "reward_firearm_tier_min": "0",
    "reward_firearm_tier_max": "0",
    "reward_equipment_tier_min": "0",
    "reward_equipment_tier_max": "0",
    "reward_agent_power_min": "0",
    "reward_agent_power_max": "0",
    "reward_throwable_type": "none",
    "reward_resource_add_amount": "0",
    "total_contribution_rate": "0.2",
    "event_group": "minor",
    "renown_gain": "15",
    "newbie": "",
    "drop_item_tier": "1",
    "drop_item_prob": "0.15",
    "drop_item_ty": "",
    "exp": "120"
  },
  {
    "stability_level": "Peaceful",
    "mission_level": "1",
    "difficulty": "3",
    "maps": "hatchery_010, hatchery_020, hatchery_030, farm_010, construction_010, construction_030",
    "training": "2",
    "threats_min": "7",
    "threats_max": "8",
    "agents_max": "5",
    "reward_resource_min": "1520",
    "reward_resource_max": "2080",
    "weight": "1",
    "reward_firearm_tier_min": "1",
    "reward_firearm_tier_max": "1",
    "reward_equipment_tier_min": "1",
    "reward_equipment_tier_max": "1",
    "reward_agent_power_min": "8",
    "reward_agent_power_max": "11",
    "reward_throwable_type": "stun",
    "reward_resource_add_amount": "400",
    "total_contribution_rate": "0.2",
    "event_group": "major",
    "renown_gain": "25",
    "newbie": "",
    "drop_item_tier": "1",
    "drop_item_prob": "0.2",
    "drop_item_ty": "",
    "exp": "150"
  },
  {
    "stability_level": "Peaceful",
    "mission_level": "1",
    "difficulty": "3",
    "maps": "embassy_short, farm_011, farm_020, farm_021, farm_031, construction_020",
    "training": "2",
    "threats_min": "10",
    "threats_max": "11",
    "agents_max": "5",
    "reward_resource_min": "1520",
    "reward_resource_max": "2080",
    "weight": "1",
    "reward_firearm_tier_min": "1",
    "reward_firearm_tier_max": "1",
    "reward_equipment_tier_min": "1",
    "reward_equipment_tier_max": "1",
    "reward_agent_power_min": "8",
    "reward_agent_power_max": "11",
    "reward_throwable_type": "stun",
    "reward_resource_add_amount": "400",
    "total_contribution_rate": "0.2",
    "event_group": "major",
    "renown_gain": "25",
    "newbie": "",
    "drop_item_tier": "1",
    "drop_item_prob": "0.2",
    "drop_item_ty": "",
    "exp": "150"
  },
  {
    "stability_level": "Tense",
    "mission_level": "2",
    "difficulty": "3",
    "maps": "hatchery_010, hatchery_030, hatchery_031, farm_030, farm_032, construction_011, construction_021",
    "training": "2",
    "threats_min": "7",
    "threats_max": "8",
    "agents_max": "4",
    "reward_resource_min": "2060",
    "reward_resource_max": "2740",
    "weight": "2",
    "reward_firearm_tier_min": "0",
    "reward_firearm_tier_max": "0",
    "reward_equipment_tier_min": "0",
    "reward_equipment_tier_max": "0",
    "reward_agent_power_min": "0",
    "reward_agent_power_max": "0",
    "reward_throwable_type": "none",
    "reward_resource_add_amount": "0",
    "total_contribution_rate": "0.2",
    "event_group": "neutral",
    "renown_gain": "15",
    "newbie": "",
    "drop_item_tier": "2",
    "drop_item_prob": "0.1",
    "drop_item_ty": "",
    "exp": "90"
  },
  {
    "stability_level": "Tense",
    "mission_level": "2",
    "difficulty": "3",
    "maps": "embassy_short, construction_031, farm_022, farm_110, farm_120, farm_130, farm_012",
    "training": "2",
    "threats_min": "10",
    "threats_max": "11",
    "agents_max": "4",
    "reward_resource_min": "2060",
    "reward_resource_max": "2740",
    "weight": "2",
    "reward_firearm_tier_min": "0",
    "reward_firearm_tier_max": "0",
    "reward_equipment_tier_min": "0",
    "reward_equipment_tier_max": "0",
    "reward_agent_power_min": "0",
    "reward_agent_power_max": "0",
    "reward_throwable_type": "none",
    "reward_resource_add_amount": "0",
    "total_contribution_rate": "0.2",
    "event_group": "neutral",
    "renown_gain": "15",
    "newbie": "",
    "drop_item_tier": "2",
    "drop_item_prob": "0.1",
    "drop_item_ty": "",
    "exp": "90"
  },
  {
    "stability_level": "Tense",
    "mission_level": "2",
    "difficulty": "4",
    "maps": "hatchery_010, hatchery_030, hatchery_031, farm_030, farm_032, construction_011, construction_021",
    "training": "3",
    "threats_min": "8",
    "threats_max": "9",
    "agents_max": "4",
    "reward_resource_min": "2780",
    "reward_resource_max": "3620",
    "weight": "2",
    "reward_firearm_tier_min": "0",
    "reward_firearm_tier_max": "0",
    "reward_equipment_tier_min": "0",
    "reward_equipment_tier_max": "0",
    "reward_agent_power_min": "0",
    "reward_agent_power_max": "0",
    "reward_throwable_type": "none",
    "reward_resource_add_amount": "0",
    "total_contribution_rate": "0.2",
    "event_group": "minor",
    "renown_gain": "45",
    "newbie": "",
    "drop_item_tier": "2",
    "drop_item_prob": "0.15",
    "drop_item_ty": "",
    "exp": "120"
  },
  {
    "stability_level": "Tense",
    "mission_level": "2",
    "difficulty": "4",
    "maps": "hatchery_130, hatchery_210, hatchery_220, hatchery_230, construction_031, farm_022, farm_110, farm_120, farm_130, farm_012",
    "training": "2",
    "threats_min": "15",
    "threats_max": "16",
    "agents_max": "5",
    "reward_resource_min": "2780",
    "reward_resource_max": "3620",
    "weight": "2",
    "reward_firearm_tier_min": "0",
    "reward_firearm_tier_max": "0",
    "reward_equipment_tier_min": "0",
    "reward_equipment_tier_max": "0",
    "reward_agent_power_min": "0",
    "reward_agent_power_max": "0",
    "reward_throwable_type": "none",
    "reward_resource_add_amount": "0",
    "total_contribution_rate": "0.2",
    "event_group": "minor",
    "renown_gain": "45",
    "newbie": "",
    "drop_item_tier": "2",
    "drop_item_prob": "0.15",
    "drop_item_ty": "",
    "exp": "120"
  },
  {
    "stability_level": "Tense",
    "mission_level": "2",
    "difficulty": "5",
    "maps": "hatchery_031, hatchery_120, farm_030, farm_032, construction_011, construction_021",
    "training": "3",
    "threats_min": "10",
    "threats_max": "11",
    "agents_max": "5",
    "reward_resource_min": "3320",
    "reward_resource_max": "4280",
    "weight": "1",
    "reward_firearm_tier_min": "1",
    "reward_firearm_tier_max": "2",
    "reward_equipment_tier_min": "1",
    "reward_equipment_tier_max": "2",
    "reward_agent_power_min": "12",
    "reward_agent_power_max": "15",
    "reward_throwable_type": "stun",
    "reward_resource_add_amount": "700",
    "total_contribution_rate": "0.2",
    "event_group": "major",
    "renown_gain": "75",
    "newbie": "",
    "drop_item_tier": "2",
    "drop_item_prob": "0.2",
    "drop_item_ty": "",
    "exp": "150"
  },
  {
    "stability_level": "Tense",
    "mission_level": "2",
    "difficulty": "5",
    "maps": "hatchery_130, hatchery_210, hatchery_220, hatchery_230, construction_031, construction_210, construction_220, construction_230, farm_022, farm_110, farm_120, farm_130, farm_012, farm_230, farm_210, farm_220",
    "training": "3",
    "threats_min": "15",
    "threats_max": "16",
    "agents_max": "5",
    "reward_resource_min": "3320",
    "reward_resource_max": "4280",
    "weight": "1",
    "reward_firearm_tier_min": "2",
    "reward_firearm_tier_max": "2",
    "reward_equipment_tier_min": "2",
    "reward_equipment_tier_max": "2",
    "reward_agent_power_min": "12",
    "reward_agent_power_max": "15",
    "reward_throwable_type": "stun",
    "reward_resource_add_amount": "700",
    "total_contribution_rate": "0.2",
    "event_group": "major",
    "renown_gain": "75",
    "newbie": "",
    "drop_item_tier": "2",
    "drop_item_prob": "0.2",
    "drop_item_ty": "",
    "exp": "150"
  },
  {
    "stability_level": "Hostile",
    "mission_level": "3",
    "difficulty": "5",
    "maps": "",
    "training": "3",
    "threats_min": "9",
    "threats_max": "12",
    "agents_max": "5",
    "reward_resource_min": "3500",
    "reward_resource_max": "4500",
    "weight": "1",
    "reward_firearm_tier_min": "0",
    "reward_firearm_tier_max": "0",
    "reward_equipment_tier_min": "0",
    "reward_equipment_tier_max": "0",
    "reward_agent_power_min": "0",
    "reward_agent_power_max": "0",
    "reward_throwable_type": "none",
    "reward_resource_add_amount": "0",
    "total_contribution_rate": "0.2",
    "event_group": "neutral",
    "renown_gain": "45",
    "newbie": "",
    "drop_item_tier": "3",
    "drop_item_prob": "0.1",
    "drop_item_ty": "",
    "exp": "90"
  },
  {
    "stability_level": "Hostile",
    "mission_level": "3",
    "difficulty": "5",
    "maps": "",
    "training": "3",
    "threats_min": "13",
    "threats_max": "16",
    "agents_max": "5",
    "reward_resource_min": "3950",
    "reward_resource_max": "5050",
    "weight": "1",
    "reward_firearm_tier_min": "0",
    "reward_firearm_tier_max": "0",
    "reward_equipment_tier_min": "0",
    "reward_equipment_tier_max": "0",
    "reward_agent_power_min": "0",
    "reward_agent_power_max": "0",
    "reward_throwable_type": "none",
    "reward_resource_add_amount": "0",
    "total_contribution_rate": "0.2",
    "event_group": "minor",
    "renown_gain": "45",
    "newbie": "",
    "drop_item_tier": "3",
    "drop_item_prob": "0.1",
    "drop_item_ty": "",
    "exp": "90"
  },
  {
    "stability_level": "Hostile",
    "mission_level": "3",
    "difficulty": "6",
    "maps": "",
    "training": "4",
    "threats_min": "12",
    "threats_max": "14",
    "agents_max": "5",
    "reward_resource_min": "4625",
    "reward_resource_max": "5875",
    "weight": "1",
    "reward_firearm_tier_min": "0",
    "reward_firearm_tier_max": "0",
    "reward_equipment_tier_min": "0",
    "reward_equipment_tier_max": "0",
    "reward_agent_power_min": "0",
    "reward_agent_power_max": "0",
    "reward_throwable_type": "none",
    "reward_resource_add_amount": "0",
    "total_contribution_rate": "0.2",
    "event_group": "minor",
    "renown_gain": "135",
    "newbie": "",
    "drop_item_tier": "3",
    "drop_item_prob": "0.15",
    "drop_item_ty": "",
    "exp": "120"
  },
  {
    "stability_level": "Hostile",
    "mission_level": "3",
    "difficulty": "6",
    "maps": "",
    "training": "4",
    "threats_min": "15",
    "threats_max": "16",
    "agents_max": "6",
    "reward_resource_min": "5300",
    "reward_resource_max": "6700",
    "weight": "1",
    "reward_firearm_tier_min": "2",
    "reward_firearm_tier_max": "3",
    "reward_equipment_tier_min": "2",
    "reward_equipment_tier_max": "3",
    "reward_agent_power_min": "15",
    "reward_agent_power_max": "18",
    "reward_throwable_type": "fragment",
    "reward_resource_add_amount": "1000",
    "total_contribution_rate": "0.2",
    "event_group": "major",
    "renown_gain": "135",
    "newbie": "",
    "drop_item_tier": "3",
    "drop_item_prob": "0.15",
    "drop_item_ty": "",
    "exp": "120"
  },
  {
    "stability_level": "Hostile",
    "mission_level": "3",
    "difficulty": "7",
    "maps": "",
    "training": "4",
    "threats_min": "17",
    "threats_max": "18",
    "agents_max": "6",
    "reward_resource_min": "5975",
    "reward_resource_max": "7525",
    "weight": "1",
    "reward_firearm_tier_min": "3",
    "reward_firearm_tier_max": "3",
    "reward_equipment_tier_min": "3",
    "reward_equipment_tier_max": "3",
    "reward_agent_power_min": "15",
    "reward_agent_power_max": "18",
    "reward_throwable_type": "fragment",
    "reward_resource_add_amount": "1000",
    "total_contribution_rate": "0.2",
    "event_group": "major",
    "renown_gain": "225",
    "newbie": "",
    "drop_item_tier": "3",
    "drop_item_prob": "0.2",
    "drop_item_ty": "",
    "exp": "150"
  },
  {
    "stability_level": "Warzone",
    "mission_level": "4",
    "difficulty": "7",
    "maps": "",
    "training": "4",
    "threats_min": "13",
    "threats_max": "16",
    "agents_max": "6",
    "reward_resource_min": "5660",
    "reward_resource_max": "7140",
    "weight": "1",
    "reward_firearm_tier_min": "0",
    "reward_firearm_tier_max": "0",
    "reward_equipment_tier_min": "0",
    "reward_equipment_tier_max": "0",
    "reward_agent_power_min": "13",
    "reward_agent_power_max": "14",
    "reward_throwable_type": "fragment",
    "reward_resource_add_amount": "500",
    "total_contribution_rate": "0.2",
    "event_group": "neutral",
    "renown_gain": "320",
    "newbie": "",
    "drop_item_tier": "4",
    "drop_item_prob": "0.2",
    "drop_item_ty": "",
    "exp": "90"
  },
  {
    "stability_level": "Warzone",
    "mission_level": "4",
    "difficulty": "7",
    "maps": "",
    "training": "4",
    "threats_min": "17",
    "threats_max": "20",
    "agents_max": "6",
    "reward_resource_min": "6200",
    "reward_resource_max": "7800",
    "weight": "1",
    "reward_firearm_tier_min": "0",
    "reward_firearm_tier_max": "0",
    "reward_equipment_tier_min": "0",
    "reward_equipment_tier_max": "0",
    "reward_agent_power_min": "14",
    "reward_agent_power_max": "15",
    "reward_throwable_type": "fragment",
    "reward_resource_add_amount": "700",
    "total_contribution_rate": "0.2",
    "event_group": "neutral",
    "renown_gain": "640",
    "newbie": "",
    "drop_item_tier": "4",
    "drop_item_prob": "0.2",
    "drop_item_ty": "",
    "exp": "90"
  },
  {
    "stability_level": "Warzone",
    "mission_level": "4",
    "difficulty": "8",
    "maps": "",
    "training": "5",
    "threats_min": "14",
    "threats_max": "16",
    "agents_max": "7",
    "reward_resource_min": "7010",
    "reward_resource_max": "8790",
    "weight": "1",
    "reward_firearm_tier_min": "0",
    "reward_firearm_tier_max": "0",
    "reward_equipment_tier_min": "0",
    "reward_equipment_tier_max": "0",
    "reward_agent_power_min": "15",
    "reward_agent_power_max": "16",
    "reward_throwable_type": "fragment",
    "reward_resource_add_amount": "900",
    "total_contribution_rate": "0.2",
    "event_group": "minor",
    "renown_gain": "960",
    "newbie": "",
    "drop_item_tier": "4",
    "drop_item_prob": "0.3",
    "drop_item_ty": "",
    "exp": "120"
  },
  {
    "stability_level": "Warzone",
    "mission_level": "4",
    "difficulty": "8",
    "maps": "",
    "training": "5",
    "threats_min": "17",
    "threats_max": "19",
    "agents_max": "7",
    "reward_resource_min": "7820",
    "reward_resource_max": "9780",
    "weight": "1",
    "reward_firearm_tier_min": "3",
    "reward_firearm_tier_max": "4",
    "reward_equipment_tier_min": "3",
    "reward_equipment_tier_max": "4",
    "reward_agent_power_min": "16",
    "reward_agent_power_max": "17",
    "reward_throwable_type": "fragment",
    "reward_resource_add_amount": "1100",
    "total_contribution_rate": "0.2",
    "event_group": "minor",
    "renown_gain": "1280",
    "newbie": "",
    "drop_item_tier": "4",
    "drop_item_prob": "0.4",
    "drop_item_ty": "",
    "exp": "120"
  },
  {
    "stability_level": "Warzone",
    "mission_level": "4",
    "difficulty": "9",
    "maps": "",
    "training": "5",
    "threats_min": "20",
    "threats_max": "22",
    "agents_max": "7",
    "reward_resource_min": "8630",
    "reward_resource_max": "10770",
    "weight": "1",
    "reward_firearm_tier_min": "4",
    "reward_firearm_tier_max": "4",
    "reward_equipment_tier_min": "4",
    "reward_equipment_tier_max": "4",
    "reward_agent_power_min": "17",
    "reward_agent_power_max": "18",
    "reward_throwable_type": "fragment",
    "reward_resource_add_amount": "1300",
    "total_contribution_rate": "0.2",
    "event_group": "major",
    "renown_gain": "1600",
    "newbie": "",
    "drop_item_tier": "4",
    "drop_item_prob": "0.5",
    "drop_item_ty": "",
    "exp": "150"
  },
  {
    "stability_level": "정부임무",
    "mission_level": "0",
    "difficulty": "9",
    "maps": "",
    "training": "",
    "threats_min": "",
    "threats_max": "",
    "agents_max": "",
    "reward_resource_min": "",
    "reward_resource_max": "",
    "weight": "1"
  },
  {
    "stability_level": "특수임무",
    "mission_level": "0",
    "difficulty": "9",
    "maps": "",
    "training": "",
    "threats_min": "",
    "threats_max": "",
    "agents_max": "",
    "reward_resource_min": "",
    "reward_resource_max": "",
    "weight": "1"
  },
  {
    "stability_level": "특수임무",
    "mission_level": "0",
    "difficulty": "9",
    "maps": "",
    "training": "",
    "threats_min": "",
    "threats_max": "",
    "agents_max": "",
    "reward_resource_min": "",
    "reward_resource_max": "",
    "weight": "1"
  },
  {
    "stability_level": "온보딩",
    "mission_level": "0",
    "difficulty": "1",
    "maps": "hatchery_020",
    "training": "1",
    "threats_min": "2",
    "threats_max": "2",
    "agents_max": "4",
    "reward_resource_min": "710",
    "reward_resource_max": "1090",
    "weight": "1",
    "reward_firearm_tier_min": "0",
    "reward_firearm_tier_max": "0",
    "reward_equipment_tier_min": "0",
    "reward_equipment_tier_max": "0",
    "reward_agent_power_min": "0",
    "reward_agent_power_max": "0",
    "reward_throwable_type": "none",
    "reward_resource_add_amount": "0",
    "total_contribution_rate": "0.2",
    "event_group": "initial",
    "renown_gain": "3",
    "newbie": "1",
    "drop_item_tier": "1",
    "drop_item_prob": "1",
    "drop_item_ty": "sg",
    "exp": "90"
  },
  {
    "stability_level": "온보딩",
    "mission_level": "0",
    "difficulty": "1",
    "maps": "construction_010",
    "training": "1",
    "threats_min": "4",
    "threats_max": "4",
    "agents_max": "4",
    "reward_resource_min": "890",
    "reward_resource_max": "1310",
    "weight": "1",
    "reward_firearm_tier_min": "0",
    "reward_firearm_tier_max": "0",
    "reward_equipment_tier_min": "0",
    "reward_equipment_tier_max": "0",
    "reward_agent_power_min": "0",
    "reward_agent_power_max": "0",
    "reward_throwable_type": "none",
    "reward_resource_add_amount": "0",
    "total_contribution_rate": "0.2",
    "event_group": "neutral",
    "renown_gain": "3",
    "newbie": "1",
    "drop_item_tier": "1",
    "drop_item_prob": "1",
    "drop_item_ty": "equipment",
    "exp": "90"
  },
  {
    "stability_level": "온보딩",
    "mission_level": "0",
    "difficulty": "1",
    "maps": "embassy_short",
    "training": "1",
    "threats_min": "5",
    "threats_max": "5",
    "agents_max": "4",
    "reward_resource_min": "1160",
    "reward_resource_max": "1640",
    "weight": "1",
    "reward_firearm_tier_min": "0",
    "reward_firearm_tier_max": "0",
    "reward_equipment_tier_min": "0",
    "reward_equipment_tier_max": "0",
    "reward_agent_power_min": "0",
    "reward_agent_power_max": "0",
    "reward_throwable_type": "none",
    "reward_resource_add_amount": "0",
    "total_contribution_rate": "0.2",
    "event_group": "minor",
    "renown_gain": "5",
    "newbie": "1",
    "drop_item_tier": "1",
    "drop_item_prob": "1",
    "drop_item_ty": "ar",
    "exp": "90"
  },
  {
    "stability_level": "온보딩",
    "mission_level": "0",
    "difficulty": "2",
    "maps": "farm_011",
    "training": "2",
    "threats_min": "5",
    "threats_max": "5",
    "agents_max": "4",
    "reward_resource_min": "1520",
    "reward_resource_max": "2080",
    "weight": "1",
    "reward_firearm_tier_min": "1",
    "reward_firearm_tier_max": "1",
    "reward_equipment_tier_min": "1",
    "reward_equipment_tier_max": "1",
    "reward_agent_power_min": "0",
    "reward_agent_power_max": "0",
    "reward_throwable_type": "stun",
    "reward_resource_add_amount": "400",
    "total_contribution_rate": "0.2",
    "event_group": "major",
    "renown_gain": "5",
    "newbie": "1",
    "drop_item_tier": "1",
    "drop_item_prob": "1",
    "drop_item_ty": "equipment",
    "exp": "120"
  },
  {
    "stability_level": "시작",
    "mission_level": "0",
    "difficulty": "1",
    "maps": "",
    "training": "1",
    "threats_min": "6",
    "threats_max": "6",
    "agents_max": "4",
    "reward_resource_min": "1000",
    "reward_resource_max": "1000",
    "weight": "1",
    "reward_firearm_tier_min": "2",
    "reward_firearm_tier_max": "2",
    "reward_equipment_tier_min": "2",
    "reward_equipment_tier_max": "2",
    "reward_agent_power_min": "0",
    "reward_agent_power_max": "0",
    "reward_throwable_type": "none",
    "reward_resource_add_amount": "200",
    "total_contribution_rate": "0.2",
    "event_group": "initial",
    "renown_gain": "0",
    "newbie": "1",
    "drop_item_tier": "1",
    "drop_item_prob": "1",
    "drop_item_ty": "",
    "exp": "120"
  },
  {
    "stability_level": "마일스톤",
    "mission_level": "1",
    "difficulty": "4",
    "maps": "",
    "training": "2",
    "threats_min": "15",
    "threats_max": "15",
    "agents_max": "6",
    "reward_resource_min": "5000",
    "reward_resource_max": "5000",
    "weight": "1",
    "reward_firearm_tier_min": "2",
    "reward_firearm_tier_max": "2",
    "reward_equipment_tier_min": "2",
    "reward_equipment_tier_max": "2",
    "reward_agent_power_min": "10",
    "reward_agent_power_max": "10",
    "reward_throwable_type": "stun",
    "reward_resource_add_amount": "3000",
    "total_contribution_rate": "0.2",
    "event_group": "milestone1",
    "renown_gain": "0",
    "newbie": "1",
    "drop_item_tier": "2",
    "drop_item_prob": "1",
    "drop_item_ty": "",
    "exp": "150"
  },
  {
    "stability_level": "마일스톤",
    "mission_level": "2",
    "difficulty": "5",
    "maps": "",
    "training": "3",
    "threats_min": "20",
    "threats_max": "20",
    "agents_max": "7",
    "reward_resource_min": "10000",
    "reward_resource_max": "10000",
    "weight": "1",
    "reward_firearm_tier_min": "3",
    "reward_firearm_tier_max": "3",
    "reward_equipment_tier_min": "3",
    "reward_equipment_tier_max": "3",
    "reward_agent_power_min": "14",
    "reward_agent_power_max": "14",
    "reward_throwable_type": "stun",
    "reward_resource_add_amount": "1500",
    "total_contribution_rate": "0.2",
    "event_group": "milestone2",
    "renown_gain": "0",
    "newbie": "1",
    "drop_item_tier": "3",
    "drop_item_prob": "1",
    "drop_item_ty": "",
    "exp": "180"
  },
  {
    "stability_level": "마일스톤",
    "mission_level": "3",
    "difficulty": "6",
    "maps": "",
    "training": "4",
    "threats_min": "30",
    "threats_max": "30",
    "agents_max": "8",
    "reward_resource_min": "15000",
    "reward_resource_max": "15000",
    "weight": "1",
    "reward_firearm_tier_min": "4",
    "reward_firearm_tier_max": "4",
    "reward_equipment_tier_min": "4",
    "reward_equipment_tier_max": "4",
    "reward_agent_power_min": "17",
    "reward_agent_power_max": "17",
    "reward_throwable_type": "fragment",
    "reward_resource_add_amount": "2500",
    "total_contribution_rate": "0.2",
    "event_group": "milestone3",
    "renown_gain": "0",
    "newbie": "1",
    "drop_item_tier": "4",
    "drop_item_prob": "1",
    "drop_item_ty": "",
    "exp": "210"
  },
  {
    "stability_level": "마일스톤",
    "mission_level": "4",
    "difficulty": "7",
    "maps": "",
    "training": "5",
    "threats_min": "35",
    "threats_max": "35",
    "agents_max": "9",
    "reward_resource_min": "25000",
    "reward_resource_max": "25000",
    "weight": "1",
    "reward_firearm_tier_min": "5",
    "reward_firearm_tier_max": "5",
    "reward_equipment_tier_min": "4",
    "reward_equipment_tier_max": "4",
    "reward_agent_power_min": "20",
    "reward_agent_power_max": "20",
    "reward_throwable_type": "fragment",
    "reward_resource_add_amount": "3500",
    "total_contribution_rate": "0.2",
    "event_group": "milestone4",
    "renown_gain": "0",
    "newbie": "1",
    "drop_item_tier": "5",
    "drop_item_prob": "1",
    "drop_item_ty": "",
    "exp": "240"
  },
  {
    "stability_level": "마일스톤",
    "mission_level": "5",
    "difficulty": "8",
    "maps": "",
    "training": "6",
    "threats_min": "40",
    "threats_max": "40",
    "agents_max": "10",
    "reward_resource_min": "30000",
    "reward_resource_max": "30000",
    "weight": "1",
    "reward_firearm_tier_min": "5",
    "reward_firearm_tier_max": "5",
    "reward_equipment_tier_min": "4",
    "reward_equipment_tier_max": "4",
    "reward_agent_power_min": "25",
    "reward_agent_power_max": "25",
    "reward_throwable_type": "fragment",
    "reward_resource_add_amount": "5000",
    "total_contribution_rate": "0.2",
    "event_group": "milestone5",
    "renown_gain": "0",
    "newbie": "1",
    "drop_item_tier": "5",
    "drop_item_prob": "1",
    "drop_item_ty": "",
    "exp": "300"
  },
  {
    "stability_level": "Peaceful",
    "mission_level": "1",
    "difficulty": "1",
    "maps": "hatchery_010, hatchery_020, hatchery_030, farm_010, construction_010, construction_030",
    "training": "1",
    "threats_min": "4",
    "threats_max": "4",
    "agents_max": "4",
    "reward_resource_min": "890",
    "reward_resource_max": "1310",
    "weight": "2",
    "reward_firearm_tier_min": "0",
    "reward_firearm_tier_max": "0",
    "reward_equipment_tier_min": "0",
    "reward_equipment_tier_max": "0",
    "reward_agent_power_min": "0",
    "reward_agent_power_max": "0",
    "reward_throwable_type": "none",
    "reward_resource_add_amount": "0",
    "total_contribution_rate": "0.2",
    "event_group": "neutral",
    "renown_gain": "5",
    "newbie": "1",
    "drop_item_tier": "1",
    "drop_item_prob": "0.1",
    "drop_item_ty": "",
    "exp": "90"
  },
  {
    "stability_level": "Peaceful",
    "mission_level": "1",
    "difficulty": "1",
    "maps": "embassy_short, farm_011, farm_020, farm_021, farm_031, construction_020",
    "training": "1",
    "threats_min": "5",
    "threats_max": "5",
    "agents_max": "4",
    "reward_resource_min": "890",
    "reward_resource_max": "1310",
    "weight": "2",
    "reward_firearm_tier_min": "0",
    "reward_firearm_tier_max": "0",
    "reward_equipment_tier_min": "0",
    "reward_equipment_tier_max": "0",
    "reward_agent_power_min": "0",
    "reward_agent_power_max": "0",
    "reward_throwable_type": "none",
    "reward_resource_add_amount": "0",
    "total_contribution_rate": "0.2",
    "event_group": "neutral",
    "renown_gain": "5",
    "newbie": "1",
    "drop_item_tier": "1",
    "drop_item_prob": "0.1",
    "drop_item_ty": "",
    "exp": "90"
  },
  {
    "stability_level": "Peaceful",
    "mission_level": "1",
    "difficulty": "2",
    "maps": "hatchery_010, hatchery_020, hatchery_030, farm_010, construction_010, construction_030",
    "training": "2",
    "threats_min": "5",
    "threats_max": "6",
    "agents_max": "4",
    "reward_resource_min": "1250",
    "reward_resource_max": "1750",
    "weight": "2",
    "reward_firearm_tier_min": "0",
    "reward_firearm_tier_max": "0",
    "reward_equipment_tier_min": "0",
    "reward_equipment_tier_max": "0",
    "reward_agent_power_min": "0",
    "reward_agent_power_max": "0",
    "reward_throwable_type": "none",
    "reward_resource_add_amount": "0",
    "total_contribution_rate": "0.2",
    "event_group": "minor",
    "renown_gain": "15",
    "newbie": "1",
    "drop_item_tier": "1",
    "drop_item_prob": "0.15",
    "drop_item_ty": "",
    "exp": "120"
  },
  {
    "stability_level": "Peaceful",
    "mission_level": "1",
    "difficulty": "2",
    "maps": "embassy_short, farm_011, farm_020, farm_021, farm_031, construction_020",
    "training": "1",
    "threats_min": "10",
    "threats_max": "11",
    "agents_max": "5",
    "reward_resource_min": "1250",
    "reward_resource_max": "1750",
    "weight": "2",
    "reward_firearm_tier_min": "0",
    "reward_firearm_tier_max": "0",
    "reward_equipment_tier_min": "0",
    "reward_equipment_tier_max": "0",
    "reward_agent_power_min": "0",
    "reward_agent_power_max": "0",
    "reward_throwable_type": "none",
    "reward_resource_add_amount": "0",
    "total_contribution_rate": "0.2",
    "event_group": "minor",
    "renown_gain": "15",
    "newbie": "1",
    "drop_item_tier": "1",
    "drop_item_prob": "0.15",
    "drop_item_ty": "",
    "exp": "120"
  },
  {
    "stability_level": "Peaceful",
    "mission_level": "1",
    "difficulty": "3",
    "maps": "hatchery_010, hatchery_020, hatchery_030, farm_010, construction_010, construction_030",
    "training": "2",
    "threats_min": "7",
    "threats_max": "8",
    "agents_max": "5",
    "reward_resource_min": "1520",
    "reward_resource_max": "2080",
    "weight": "1",
    "reward_firearm_tier_min": "1",
    "reward_firearm_tier_max": "1",
    "reward_equipment_tier_min": "1",
    "reward_equipment_tier_max": "1",
    "reward_agent_power_min": "8",
    "reward_agent_power_max": "11",
    "reward_throwable_type": "stun",
    "reward_resource_add_amount": "400",
    "total_contribution_rate": "0.2",
    "event_group": "major",
    "renown_gain": "25",
    "newbie": "1",
    "drop_item_tier": "1",
    "drop_item_prob": "0.2",
    "drop_item_ty": "",
    "exp": "150"
  },
  {
    "stability_level": "Peaceful",
    "mission_level": "1",
    "difficulty": "3",
    "maps": "embassy_short, farm_011, farm_020, farm_021, farm_031, construction_020",
    "training": "2",
    "threats_min": "10",
    "threats_max": "11",
    "agents_max": "5",
    "reward_resource_min": "1520",
    "reward_resource_max": "2080",
    "weight": "1",
    "reward_firearm_tier_min": "1",
    "reward_firearm_tier_max": "1",
    "reward_equipment_tier_min": "1",
    "reward_equipment_tier_max": "1",
    "reward_agent_power_min": "8",
    "reward_agent_power_max": "11",
    "reward_throwable_type": "stun",
    "reward_resource_add_amount": "400",
    "total_contribution_rate": "0.2",
    "event_group": "major",
    "renown_gain": "25",
    "newbie": "1",
    "drop_item_tier": "1",
    "drop_item_prob": "0.2",
    "drop_item_ty": "",
    "exp": "150"
  },
  {
    "stability_level": "Tense",
    "mission_level": "2",
    "difficulty": "3",
    "maps": "hatchery_010, hatchery_030, hatchery_031, farm_030, farm_032, construction_011, construction_021",
    "training": "2",
    "threats_min": "7",
    "threats_max": "8",
    "agents_max": "4",
    "reward_resource_min": "2060",
    "reward_resource_max": "2740",
    "weight": "2",
    "reward_firearm_tier_min": "0",
    "reward_firearm_tier_max": "0",
    "reward_equipment_tier_min": "0",
    "reward_equipment_tier_max": "0",
    "reward_agent_power_min": "0",
    "reward_agent_power_max": "0",
    "reward_throwable_type": "none",
    "reward_resource_add_amount": "0",
    "total_contribution_rate": "0.2",
    "event_group": "neutral",
    "renown_gain": "15",
    "newbie": "1",
    "drop_item_tier": "2",
    "drop_item_prob": "0.1",
    "drop_item_ty": "",
    "exp": "90"
  },
  {
    "stability_level": "Tense",
    "mission_level": "2",
    "difficulty": "3",
    "maps": "embassy_short, construction_031, farm_022, farm_110, farm_120, farm_130, farm_012",
    "training": "2",
    "threats_min": "10",
    "threats_max": "11",
    "agents_max": "4",
    "reward_resource_min": "2060",
    "reward_resource_max": "2740",
    "weight": "2",
    "reward_firearm_tier_min": "0",
    "reward_firearm_tier_max": "0",
    "reward_equipment_tier_min": "0",
    "reward_equipment_tier_max": "0",
    "reward_agent_power_min": "0",
    "reward_agent_power_max": "0",
    "reward_throwable_type": "none",
    "reward_resource_add_amount": "0",
    "total_contribution_rate": "0.2",
    "event_group": "neutral",
    "renown_gain": "15",
    "newbie": "1",
    "drop_item_tier": "2",
    "drop_item_prob": "0.1",
    "drop_item_ty": "",
    "exp": "90"
  },
  {
    "stability_level": "Tense",
    "mission_level": "2",
    "difficulty": "4",
    "maps": "hatchery_010, hatchery_030, hatchery_031, farm_030, farm_032, construction_011, construction_021",
    "training": "3",
    "threats_min": "8",
    "threats_max": "9",
    "agents_max": "4",
    "reward_resource_min": "2780",
    "reward_resource_max": "3620",
    "weight": "2",
    "reward_firearm_tier_min": "0",
    "reward_firearm_tier_max": "0",
    "reward_equipment_tier_min": "0",
    "reward_equipment_tier_max": "0",
    "reward_agent_power_min": "0",
    "reward_agent_power_max": "0",
    "reward_throwable_type": "none",
    "reward_resource_add_amount": "0",
    "total_contribution_rate": "0.2",
    "event_group": "minor",
    "renown_gain": "45",
    "newbie": "1",
    "drop_item_tier": "2",
    "drop_item_prob": "0.15",
    "drop_item_ty": "",
    "exp": "120"
  },
  {
    "stability_level": "Tense",
    "mission_level": "2",
    "difficulty": "4",
    "maps": "hatchery_130, hatchery_210, hatchery_220, hatchery_230, construction_031, farm_022, farm_110, farm_120, farm_130, farm_012",
    "training": "2",
    "threats_min": "15",
    "threats_max": "16",
    "agents_max": "5",
    "reward_resource_min": "2780",
    "reward_resource_max": "3620",
    "weight": "2",
    "reward_firearm_tier_min": "0",
    "reward_firearm_tier_max": "0",
    "reward_equipment_tier_min": "0",
    "reward_equipment_tier_max": "0",
    "reward_agent_power_min": "0",
    "reward_agent_power_max": "0",
    "reward_throwable_type": "none",
    "reward_resource_add_amount": "0",
    "total_contribution_rate": "0.2",
    "event_group": "minor",
    "renown_gain": "45",
    "newbie": "1",
    "drop_item_tier": "2",
    "drop_item_prob": "0.15",
    "drop_item_ty": "",
    "exp": "120"
  },
  {
    "stability_level": "Tense",
    "mission_level": "2",
    "difficulty": "5",
    "maps": "hatchery_031, hatchery_120, farm_030, farm_032, construction_011, construction_021",
    "training": "3",
    "threats_min": "10",
    "threats_max": "11",
    "agents_max": "5",
    "reward_resource_min": "3320",
    "reward_resource_max": "4280",
    "weight": "1",
    "reward_firearm_tier_min": "1",
    "reward_firearm_tier_max": "2",
    "reward_equipment_tier_min": "1",
    "reward_equipment_tier_max": "2",
    "reward_agent_power_min": "12",
    "reward_agent_power_max": "15",
    "reward_throwable_type": "stun",
    "reward_resource_add_amount": "700",
    "total_contribution_rate": "0.2",
    "event_group": "major",
    "renown_gain": "75",
    "newbie": "1",
    "drop_item_tier": "2",
    "drop_item_prob": "0.2",
    "drop_item_ty": "",
    "exp": "150"
  },
  {
    "stability_level": "Tense",
    "mission_level": "2",
    "difficulty": "5",
    "maps": "hatchery_130, hatchery_210, hatchery_220, hatchery_230, construction_031, construction_210, construction_220, construction_230, farm_022, farm_110, farm_120, farm_130, farm_012, farm_230, farm_210, farm_220",
    "training": "3",
    "threats_min": "15",
    "threats_max": "16",
    "agents_max": "5",
    "reward_resource_min": "3320",
    "reward_resource_max": "4280",
    "weight": "1",
    "reward_firearm_tier_min": "2",
    "reward_firearm_tier_max": "2",
    "reward_equipment_tier_min": "2",
    "reward_equipment_tier_max": "2",
    "reward_agent_power_min": "12",
    "reward_agent_power_max": "15",
    "reward_throwable_type": "stun",
    "reward_resource_add_amount": "700",
    "total_contribution_rate": "0.2",
    "event_group": "major",
    "renown_gain": "75",
    "newbie": "1",
    "drop_item_tier": "2",
    "drop_item_prob": "0.2",
    "drop_item_ty": "",
    "exp": "150"
  },
  {
    "stability_level": "Hostile",
    "mission_level": "3",
    "difficulty": "5",
    "maps": "",
    "training": "3",
    "threats_min": "9",
    "threats_max": "12",
    "agents_max": "5",
    "reward_resource_min": "3500",
    "reward_resource_max": "4500",
    "weight": "1",
    "reward_firearm_tier_min": "0",
    "reward_firearm_tier_max": "0",
    "reward_equipment_tier_min": "0",
    "reward_equipment_tier_max": "0",
    "reward_agent_power_min": "0",
    "reward_agent_power_max": "0",
    "reward_throwable_type": "none",
    "reward_resource_add_amount": "0",
    "total_contribution_rate": "0.2",
    "event_group": "neutral",
    "renown_gain": "45",
    "newbie": "1",
    "drop_item_tier": "3",
    "drop_item_prob": "0.1",
    "drop_item_ty": "",
    "exp": "90"
  },
  {
    "stability_level": "Hostile",
    "mission_level": "3",
    "difficulty": "5",
    "maps": "",
    "training": "3",
    "threats_min": "13",
    "threats_max": "16",
    "agents_max": "5",
    "reward_resource_min": "3950",
    "reward_resource_max": "5050",
    "weight": "1",
    "reward_firearm_tier_min": "0",
    "reward_firearm_tier_max": "0",
    "reward_equipment_tier_min": "0",
    "reward_equipment_tier_max": "0",
    "reward_agent_power_min": "0",
    "reward_agent_power_max": "0",
    "reward_throwable_type": "none",
    "reward_resource_add_amount": "0",
    "total_contribution_rate": "0.2",
    "event_group": "minor",
    "renown_gain": "45",
    "newbie": "1",
    "drop_item_tier": "3",
    "drop_item_prob": "0.1",
    "drop_item_ty": "",
    "exp": "90"
  },
  {
    "stability_level": "Hostile",
    "mission_level": "3",
    "difficulty": "6",
    "maps": "",
    "training": "4",
    "threats_min": "12",
    "threats_max": "14",
    "agents_max": "5",
    "reward_resource_min": "4625",
    "reward_resource_max": "5875",
    "weight": "1",
    "reward_firearm_tier_min": "0",
    "reward_firearm_tier_max": "0",
    "reward_equipment_tier_min": "0",
    "reward_equipment_tier_max": "0",
    "reward_agent_power_min": "0",
    "reward_agent_power_max": "0",
    "reward_throwable_type": "none",
    "reward_resource_add_amount": "0",
    "total_contribution_rate": "0.2",
    "event_group": "minor",
    "renown_gain": "135",
    "newbie": "1",
    "drop_item_tier": "3",
    "drop_item_prob": "0.15",
    "drop_item_ty": "",
    "exp": "120"
  },
  {
    "stability_level": "Hostile",
    "mission_level": "3",
    "difficulty": "6",
    "maps": "",
    "training": "4",
    "threats_min": "15",
    "threats_max": "16",
    "agents_max": "6",
    "reward_resource_min": "5300",
    "reward_resource_max": "6700",
    "weight": "1",
    "reward_firearm_tier_min": "2",
    "reward_firearm_tier_max": "3",
    "reward_equipment_tier_min": "2",
    "reward_equipment_tier_max": "3",
    "reward_agent_power_min": "15",
    "reward_agent_power_max": "18",
    "reward_throwable_type": "fragment",
    "reward_resource_add_amount": "1000",
    "total_contribution_rate": "0.2",
    "event_group": "major",
    "renown_gain": "135",
    "newbie": "1",
    "drop_item_tier": "3",
    "drop_item_prob": "0.15",
    "drop_item_ty": "",
    "exp": "120"
  },
  {
    "stability_level": "Hostile",
    "mission_level": "3",
    "difficulty": "7",
    "maps": "",
    "training": "4",
    "threats_min": "17",
    "threats_max": "18",
    "agents_max": "6",
    "reward_resource_min": "5975",
    "reward_resource_max": "7525",
    "weight": "1",
    "reward_firearm_tier_min": "3",
    "reward_firearm_tier_max": "3",
    "reward_equipment_tier_min": "3",
    "reward_equipment_tier_max": "3",
    "reward_agent_power_min": "15",
    "reward_agent_power_max": "18",
    "reward_throwable_type": "fragment",
    "reward_resource_add_amount": "1000",
    "total_contribution_rate": "0.2",
    "event_group": "major",
    "renown_gain": "225",
    "newbie": "1",
    "drop_item_tier": "3",
    "drop_item_prob": "0.2",
    "drop_item_ty": "",
    "exp": "150"
  },
  {
    "stability_level": "Warzone",
    "mission_level": "4",
    "difficulty": "7",
    "maps": "",
    "training": "4",
    "threats_min": "13",
    "threats_max": "16",
    "agents_max": "6",
    "reward_resource_min": "5660",
    "reward_resource_max": "7140",
    "weight": "1",
    "reward_firearm_tier_min": "0",
    "reward_firearm_tier_max": "0",
    "reward_equipment_tier_min": "0",
    "reward_equipment_tier_max": "0",
    "reward_agent_power_min": "13",
    "reward_agent_power_max": "14",
    "reward_throwable_type": "fragment",
    "reward_resource_add_amount": "500",
    "total_contribution_rate": "0.2",
    "event_group": "neutral",
    "renown_gain": "320",
    "newbie": "1",
    "drop_item_tier": "4",
    "drop_item_prob": "0.2",
    "drop_item_ty": "",
    "exp": "90"
  },
  {
    "stability_level": "Warzone",
    "mission_level": "4",
    "difficulty": "7",
    "maps": "",
    "training": "4",
    "threats_min": "17",
    "threats_max": "20",
    "agents_max": "6",
    "reward_resource_min": "6200",
    "reward_resource_max": "7800",
    "weight": "1",
    "reward_firearm_tier_min": "0",
    "reward_firearm_tier_max": "0",
    "reward_equipment_tier_min": "0",
    "reward_equipment_tier_max": "0",
    "reward_agent_power_min": "14",
    "reward_agent_power_max": "15",
    "reward_throwable_type": "fragment",
    "reward_resource_add_amount": "700",
    "total_contribution_rate": "0.2",
    "event_group": "neutral",
    "renown_gain": "640",
    "newbie": "1",
    "drop_item_tier": "4",
    "drop_item_prob": "0.2",
    "drop_item_ty": "",
    "exp": "90"
  },
  {
    "stability_level": "Warzone",
    "mission_level": "4",
    "difficulty": "8",
    "maps": "",
    "training": "5",
    "threats_min": "14",
    "threats_max": "16",
    "agents_max": "7",
    "reward_resource_min": "7010",
    "reward_resource_max": "8790",
    "weight": "1",
    "reward_firearm_tier_min": "0",
    "reward_firearm_tier_max": "0",
    "reward_equipment_tier_min": "0",
    "reward_equipment_tier_max": "0",
    "reward_agent_power_min": "15",
    "reward_agent_power_max": "16",
    "reward_throwable_type": "fragment",
    "reward_resource_add_amount": "900",
    "total_contribution_rate": "0.2",
    "event_group": "minor",
    "renown_gain": "960",
    "newbie": "1",
    "drop_item_tier": "4",
    "drop_item_prob": "0.3",
    "drop_item_ty": "",
    "exp": "120"
  },
  {
    "stability_level": "Warzone",
    "mission_level": "4",
    "difficulty": "8",
    "maps": "",
    "training": "5",
    "threats_min": "17",
    "threats_max": "19",
    "agents_max": "7",
    "reward_resource_min": "7820",
    "reward_resource_max": "9780",
    "weight": "1",
    "reward_firearm_tier_min": "3",
    "reward_firearm_tier_max": "4",
    "reward_equipment_tier_min": "3",
    "reward_equipment_tier_max": "4",
    "reward_agent_power_min": "16",
    "reward_agent_power_max": "17",
    "reward_throwable_type": "fragment",
    "reward_resource_add_amount": "1100",
    "total_contribution_rate": "0.2",
    "event_group": "minor",
    "renown_gain": "1280",
    "newbie": "1",
    "drop_item_tier": "4",
    "drop_item_prob": "0.4",
    "drop_item_ty": "",
    "exp": "120"
  },
  {
    "stability_level": "Warzone",
    "mission_level": "4",
    "difficulty": "9",
    "maps": "",
    "training": "5",
    "threats_min": "20",
    "threats_max": "22",
    "agents_max": "7",
    "reward_resource_min": "8630",
    "reward_resource_max": "10770",
    "weight": "1",
    "reward_firearm_tier_min": "4",
    "reward_firearm_tier_max": "4",
    "reward_equipment_tier_min": "4",
    "reward_equipment_tier_max": "4",
    "reward_agent_power_min": "17",
    "reward_agent_power_max": "18",
    "reward_throwable_type": "fragment",
    "reward_resource_add_amount": "1300",
    "total_contribution_rate": "0.2",
    "event_group": "major",
    "renown_gain": "1600",
    "newbie": "1",
    "drop_item_tier": "4",
    "drop_item_prob": "0.5",
    "drop_item_ty": "",
    "exp": "150"
  },
  {
    "stability_level": "정부임무",
    "mission_level": "0",
    "difficulty": "9",
    "maps": "",
    "training": "",
    "threats_min": "",
    "threats_max": "",
    "agents_max": "",
    "reward_resource_min": "",
    "reward_resource_max": "",
    "weight": "1",
    "reward_firearm_tier_min": "",
    "reward_firearm_tier_max": "",
    "reward_equipment_tier_min": "",
    "reward_equipment_tier_max": "",
    "reward_agent_power_min": "",
    "reward_agent_power_max": "",
    "reward_throwable_type": "",
    "reward_resource_add_amount": "",
    "total_contribution_rate": "",
    "event_group": "",
    "renown_gain": "",
    "newbie": "1"
  },
  {
    "stability_level": "특수임무",
    "mission_level": "0",
    "difficulty": "9",
    "maps": "",
    "training": "",
    "threats_min": "",
    "threats_max": "",
    "agents_max": "",
    "reward_resource_min": "",
    "reward_resource_max": "",
    "weight": "1",
    "reward_firearm_tier_min": "",
    "reward_firearm_tier_max": "",
    "reward_equipment_tier_min": "",
    "reward_equipment_tier_max": "",
    "reward_agent_power_min": "",
    "reward_agent_power_max": "",
    "reward_throwable_type": "",
    "reward_resource_add_amount": "",
    "total_contribution_rate": "",
    "event_group": "",
    "renown_gain": "",
    "newbie": "1"
  },
  {
    "stability_level": "특수임무",
    "mission_level": "0",
    "difficulty": "9",
    "maps": "",
    "training": "",
    "threats_min": "",
    "threats_max": "",
    "agents_max": "",
    "reward_resource_min": "",
    "reward_resource_max": "",
    "weight": "1",
    "reward_firearm_tier_min": "",
    "reward_firearm_tier_max": "",
    "reward_equipment_tier_min": "",
    "reward_equipment_tier_max": "",
    "reward_agent_power_min": "",
    "reward_agent_power_max": "",
    "reward_throwable_type": "",
    "reward_resource_add_amount": "",
    "total_contribution_rate": "",
    "event_group": "",
    "renown_gain": "",
    "newbie": "1"
  }
];