// downloaded data!
export const data = [
  {
    "threat_level": "1",
    "life_min": "34",
    "life_max": "44",
    "weight": "1",
    "power": "4",
    "firearm_rate": "1",
    "gear_vest_rate": "0",
    "color": "#F7F9F9",
    "firearm_types": "hg"
  },
  {
    "threat_level": "2",
    "life_min": "45",
    "life_max": "54",
    "weight": "2",
    "power": "6",
    "firearm_rate": "1",
    "gear_vest_rate": "0",
    "color": "#F9E79F",
    "firearm_types": "hg, smg"
  },
  {
    "threat_level": "3",
    "life_min": "55",
    "life_max": "65",
    "weight": "3",
    "power": "8",
    "firearm_rate": "1",
    "gear_vest_rate": "1",
    "color": "#EDBB99",
    "firearm_types": "sg, smg, ar"
  },
  {
    "threat_level": "4",
    "life_min": "66",
    "life_max": "82",
    "weight": "4",
    "power": "10",
    "firearm_rate": "2",
    "gear_vest_rate": "2",
    "color": "#F5B7B1",
    "firearm_types": "sg, smg, ar"
  },
  {
    "threat_level": "5",
    "life_min": "83",
    "life_max": "99",
    "weight": "5",
    "power": "12",
    "firearm_rate": "3",
    "gear_vest_rate": "3",
    "color": "#D7BDE2",
    "firearm_types": "sg, smg, ar"
  },
  {
    "threat_level": "6",
    "life_min": "100",
    "life_max": "100",
    "weight": "6",
    "power": "14",
    "firearm_rate": "4",
    "gear_vest_rate": "4",
    "color": "#A2D9CE",
    "firearm_types": "sg, smg, ar"
  }
];