import React from 'react';
import './CutSceneView.css';
import { items } from './data/google/processor/data_cutscenes.mjs';
import { L } from './localization.mjs';

export class CutSceneView extends React.Component {
  constructor(props) {
    super(props);

    const idx = props.idx || 0;

    this.keyDown = this.onKeyDown.bind(this);
    this.tick = this.onTick.bind(this);

    this.timer = null;
    this.ticks = 0;
    this.state = {
      text: L(items[idx]),
      progress: items[idx].length,
      text_full: L(items[idx]),
    };
  }

  onKeyDown(ev) {
    if (ev.keyCode === 27 || ev.key === "Escape" || ev.key === ' ') {
      this.onSkip();
      ev.stopPropagation();
    }
  }

  onSkip() {
    const { text, text_full } = this.state;
    if (text.length === text_full.length) {
      this.props.onDone();
    } else {
      this.setState({ progress: text_full.length, text: text_full });
      if (this.timer) {
        window.cancelAnimationFrame(this.timer);
      }
    }
  }

  componentDidMount() {
    this.timer = window.requestAnimationFrame(this.tick);

    document.addEventListener('keydown', this.keyDown);
  }

  componentWillUnmount() {
    if (this.timer) {
      window.cancelAnimationFrame(this.timer);
    }
    this.timer = null;

    document.removeEventListener('keydown', this.keyDown);
  }

  onTick() {
    const { text_full } = this.state;
    let progress = this.state.progress;

    this.ticks += 1;
    if (this.ticks % 2 === 0) {
      this.ticks = 0;

      progress += 1;
      this.setState({
        progress,
        text: text_full.slice(0, progress),
      });
    }

    if (progress < text_full.length) {
      this.timer = window.requestAnimationFrame(this.tick);
    }
  }

  render() {
    const { text } = this.state;
    const lines = text.split('\n');

    let labelcls = 'typing-effect-skip';
    let label = L('loc_ui_string_cutscene_skip');
    if (text.length === this.state.text_full.length) {
      labelcls += ' typing-effect-skip-done';
      label = L('loc_ui_string_cutscene_continue');
    }

    return <>
      <div className="typing-effect" onClick={() => this.onSkip()}>
        {lines.map((text, i) => {
          if (i === lines.length - 1) {
            let t0 = text.slice(0, text.length - 1);
            let t1 = text.slice(text.length - 1);
            return <p key={i}>{t0}<span className="typing-effect-inv">{t1}</span></p>;
          }
          return <p key={i}>{text}</p>;
        })}
      </div>
      <div className={labelcls}>{label}</div>
    </>;
  }
}
