// downloaded data!
export const data = [
  {
    "label": "E",
    "potential_min": "0.7",
    "potential_max": "0.8",
    "weight": "50",
    "overall_min": "0.3",
    "overall_max": "0.5",
    "stat_min": "0.15",
    "stat_max": "0.25",
    "physical_min": "0.3",
    "physical_max": "0.6",
    "prob_vocation": "0.01",
    "power_mult": "0.8",
    "exp_mult": "0.6"
  },
  {
    "label": "D",
    "potential_min": "0.8",
    "potential_max": "0.9",
    "weight": "34",
    "overall_min": "0.35",
    "overall_max": "0.55",
    "stat_min": "0.175",
    "stat_max": "0.275",
    "physical_min": "0.38",
    "physical_max": "0.68",
    "prob_vocation": "0.05",
    "power_mult": "0.9",
    "exp_mult": "0.8"
  },
  {
    "label": "C",
    "potential_min": "0.9",
    "potential_max": "1",
    "weight": "10",
    "overall_min": "0.4",
    "overall_max": "0.6",
    "stat_min": "0.2",
    "stat_max": "0.3",
    "physical_min": "0.46",
    "physical_max": "0.76",
    "prob_vocation": "0.08",
    "power_mult": "1",
    "exp_mult": "1"
  },
  {
    "label": "B",
    "potential_min": "1",
    "potential_max": "1.1",
    "weight": "5",
    "overall_min": "0.45",
    "overall_max": "0.65",
    "stat_min": "0.225",
    "stat_max": "0.325",
    "physical_min": "0.54",
    "physical_max": "0.84",
    "prob_vocation": "0.1",
    "power_mult": "1.2",
    "exp_mult": "1.2"
  },
  {
    "label": "A",
    "potential_min": "1.1",
    "potential_max": "1.2",
    "weight": "0.9",
    "overall_min": "0.5",
    "overall_max": "0.7",
    "stat_min": "0.25",
    "stat_max": "0.35",
    "physical_min": "0.62",
    "physical_max": "0.92",
    "prob_vocation": "0.2",
    "power_mult": "1.4",
    "exp_mult": "1.4"
  },
  {
    "label": "S",
    "potential_min": "1.2",
    "potential_max": "1.3",
    "weight": "0.1",
    "overall_min": "0.55",
    "overall_max": "0.75",
    "stat_min": "0.275",
    "stat_max": "0.375",
    "physical_min": "0.7",
    "physical_max": "1",
    "prob_vocation": "0.4",
    "power_mult": "1.8",
    "exp_mult": "1.6"
  }
];