// downloaded data!
export const data = [
  {
    "name": "Max Orellana",
    "search": "6",
    "negotiate": "9",
    "bargain": "5",
    "train": "8",
    "manage": "6",
    "wage": "50",
    "cap_search": "12",
    "cap_negotiate": "18",
    "cap_bargain": "9",
    "cap_train": "12",
    "cap_manage": "16",
    "growth_search": "1",
    "growth_negotiate": "1.4",
    "growth_bargain": "0.8",
    "growth_train": "1",
    "growth_manage": "1.2"
  },
  {
    "name": "Esteban Del Lana",
    "search": "9",
    "negotiate": "8",
    "bargain": "7",
    "train": "3",
    "manage": "8",
    "wage": "50",
    "cap_search": "16",
    "cap_negotiate": "11",
    "cap_bargain": "16",
    "cap_train": "7",
    "cap_manage": "16",
    "growth_search": "1.2",
    "growth_negotiate": "1.2",
    "growth_bargain": "1.1",
    "growth_train": "1",
    "growth_manage": "1.2"
  },
  {
    "name": "Xavier Díaz",
    "search": "5",
    "negotiate": "6",
    "bargain": "9",
    "train": "7",
    "manage": "8",
    "wage": "70",
    "cap_search": "8",
    "cap_negotiate": "12",
    "cap_bargain": "14",
    "cap_train": "16",
    "cap_manage": "18",
    "growth_search": "0.8",
    "growth_negotiate": "1",
    "growth_bargain": "1.1",
    "growth_train": "1.3",
    "growth_manage": "1.2"
  },
  {
    "name": "Ricardo Morales",
    "search": "8",
    "negotiate": "6",
    "bargain": "8",
    "train": "6",
    "manage": "15",
    "wage": "60",
    "cap_search": "10",
    "cap_negotiate": "10",
    "cap_bargain": "11",
    "cap_train": "8",
    "cap_manage": "18",
    "growth_search": "0.4",
    "growth_negotiate": "0.4",
    "growth_bargain": "0.4",
    "growth_train": "0.4",
    "growth_manage": "0.4"
  }
];