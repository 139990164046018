// downloaded data!
export const data = [
  {
    "title": "loc_data_string_missionmilestonetitles_title_1",
    "desc": "loc_data_longtext_missionmilestonetitles_desc_1"
  },
  {
    "title": "loc_data_string_missionmilestonetitles_title_2",
    "desc": "loc_data_longtext_missionmilestonetitles_desc_2"
  },
  {
    "title": "loc_data_string_missionmilestonetitles_title_3",
    "desc": "loc_data_longtext_missionmilestonetitles_desc_3"
  },
  {
    "title": "loc_data_string_missionmilestonetitles_title_4",
    "desc": "loc_data_longtext_missionmilestonetitles_desc_4"
  },
  {
    "title": "loc_data_string_missionmilestonetitles_title_5",
    "desc": "loc_data_longtext_missionmilestonetitles_desc_5"
  }
];