// downloaded data!
export const data = [
  {
    "stability_name": "Peaceful",
    "stability_level": "1",
    "ty": "agent",
    "min": "6",
    "max": "8",
    "perk_len": "0"
  },
  {
    "stability_name": "Peaceful",
    "stability_level": "1",
    "ty": "firearm",
    "min": "1",
    "max": "1"
  },
  {
    "stability_name": "Peaceful",
    "stability_level": "1",
    "ty": "equipment",
    "min": "1",
    "max": "1"
  },
  {
    "stability_name": "Peaceful",
    "stability_level": "1",
    "ty": "throwable",
    "min": "",
    "max": "",
    "perk_len": "",
    "perk_list": "",
    "throwable": "visibility"
  },
  {
    "stability_name": "Tense",
    "stability_level": "2",
    "ty": "agent",
    "min": "9",
    "max": "11",
    "perk_len": "1",
    "perk_list": "perk_engage_dash, perk_targetpref_low, perk_targetpref_high, perk_unidir_sense, perk_cover_dash, perk_hit_incr_aimvar"
  },
  {
    "stability_name": "Tense",
    "stability_level": "2",
    "ty": "firearm",
    "min": "1",
    "max": "2"
  },
  {
    "stability_name": "Tense",
    "stability_level": "2",
    "ty": "equipment",
    "min": "2",
    "max": "2"
  },
  {
    "stability_name": "Tense",
    "stability_level": "2",
    "ty": "throwable",
    "min": "",
    "max": "",
    "perk_len": "",
    "perk_list": "",
    "throwable": "stun"
  },
  {
    "stability_name": "Hostile",
    "stability_level": "3",
    "ty": "agent",
    "min": "12",
    "max": "14",
    "perk_len": "1",
    "perk_list": "perk_engage_dash, perk_targetpref_low, perk_targetpref_high, perk_unidir_sense, perk_cover_dash, perk_hit_incr_aimvar, perk_instant_reload, perk_armor_first, perk_suppress, perk_shoot_ignore_obstructed"
  },
  {
    "stability_name": "Hostile",
    "stability_level": "3",
    "ty": "firearm",
    "min": "2",
    "max": "2"
  },
  {
    "stability_name": "Hostile",
    "stability_level": "3",
    "ty": "equipment",
    "min": "2",
    "max": "3"
  },
  {
    "stability_name": "Hostile",
    "stability_level": "3",
    "ty": "throwable",
    "min": "",
    "max": "",
    "perk_len": "",
    "perk_list": "",
    "throwable": "fragment"
  },
  {
    "stability_name": "Warzone",
    "stability_level": "4",
    "ty": "agent",
    "min": "14",
    "max": "16",
    "perk_len": "2",
    "perk_list": "perk_engage_dash, perk_targetpref_low, perk_targetpref_high, perk_unidir_sense, perk_cover_dash, perk_hit_incr_aimvar, perk_instant_reload, perk_armor_first, perk_suppress, perk_shoot_ignore_obstructed"
  },
  {
    "stability_name": "Warzone",
    "stability_level": "4",
    "ty": "firearm",
    "min": "2",
    "max": "3"
  },
  {
    "stability_name": "Warzone",
    "stability_level": "4",
    "ty": "equipment",
    "min": "3",
    "max": "3"
  },
  {
    "stability_name": "Warzone",
    "stability_level": "4",
    "ty": "throwable",
    "min": "",
    "max": "",
    "perk_len": "",
    "perk_list": "",
    "throwable": "fragment"
  }
];