import { data as source } from '../downloaded/facilityresult.mjs';

function maybeNumber(x) {
  if (isNaN(x)) {
    return x;
  }
  return Number(x);
}

export const results = [];
for (const row of source) {
  let { result_key } = row;
  const effects = [];
  if (row.effect_0) {
    effects.push({
      effect: row.effect_0,
      value0: maybeNumber(row.value_0_0),
      value1: maybeNumber(row.value_0_1),
    });
  }

  if (row.effect_1) {
    effects.push({
      effect: row.effect_1,
      value0: maybeNumber(row.value_1_0),
      value1: maybeNumber(row.value_1_1),
    });
  }

  results.push({
    result_key,
    effects,
  });
}
