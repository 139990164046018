/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { CipoCell } from "../CipoCell";
import { SwapDummy } from "../SwapDummy";
import "./style.css";

export const ContractInProgress = ({
  ptMoney = "12,345",
  ptRenown = "777",
  ptEnemyCount = "88",
  ptMapName = "MAP_NAME",
  pbShowDimmer = true,
  ptCustomer = "CUSTOMER",
  ptThreatLevel = "9",
  ptRemainUnit = "years",
  ptRemainNumber = "99",
  override = (
    <CipoCell
      className="CIPO-cell-instance"
      override={
        <SwapDummy className="swap-dummy-3" dummyClassName="swap-dummy-5" overlapGroupClassName="swap-dummy-4" />
      }
      ptAgentHpCap="/777"
      ptAgentHpVal="333"
      ptAgentStaminaCap="/777"
      ptAgentStaminaVal="333"
      ptAgentStaminaRegen="11.11"
      ptAgentStaminaIdx="3"
      ptAgentName="NAME"
      ptFirearmTy="BFG"
    />
  ),
  icon = "/anima/img/icon.svg",
  img = "/anima/img/icon-1.svg",
}) => {
  return (
    <div className="contract-in-progress">
      <div className="squad-info">{override}</div>
      <div className="contract-info">
        <div className="div">
          <img className="img" alt="Bg" src="/anima/img/bg-14.svg" />
          <div className="reward">
            <div className="reputation">
              <img className="icon" alt="Icon" src={icon} />
              <div className="pt-renown">{ptRenown}</div>
            </div>
            <div className="money">
              <img className="icon-2" alt="Icon" src={img} />
              <div className="pt-money">{ptMoney}</div>
            </div>
          </div>
          <div className="map-enemy">
            <div className="map">
              <img className="frame" alt="Frame" src="/anima/img/frame-405-1.svg" />
              <div className="pt-map-name">{ptMapName}</div>
            </div>
            <img className="vector" alt="Vector" src="/anima/img/vector-178-1.svg" />
            <div className="frame-2">
              <div className="overlap-group-wrapper">
                <div className="pt-threat-level-wrapper">
                  <div className="pt-threat-level">{ptThreatLevel}</div>
                </div>
              </div>
              <div className="frame-3">
                <div className="x">X</div>
                <div className="pt-enemy-count">{ptEnemyCount}</div>
              </div>
            </div>
          </div>
          <div className="customer">
            <div className="pt-customer">{ptCustomer}</div>
          </div>
          {pbShowDimmer && <img className="img" alt="Dimed" src="/anima/img/dimed.svg" />}

          <div className="in-progress">
            <div className="in-progress-due">
              <div className="pt-remain-number">{ptRemainNumber}</div>
              <div className="pt-remain-unit">{ptRemainUnit}</div>
            </div>
            <div className="in-progress-2">임무까지 남은 기간</div>
          </div>
        </div>
      </div>
    </div>
  );
};

ContractInProgress.propTypes = {
  ptMoney: PropTypes.string,
  ptRenown: PropTypes.string,
  ptEnemyCount: PropTypes.string,
  ptMapName: PropTypes.string,
  pbShowDimmer: PropTypes.bool,
  ptCustomer: PropTypes.string,
  ptThreatLevel: PropTypes.string,
  ptRemainUnit: PropTypes.string,
  ptRemainNumber: PropTypes.string,
  icon: PropTypes.string,
  img: PropTypes.string,
};
