import { v2 } from './v2.mjs';
import { opts } from './opts.mjs';

export class World {
  constructor(params) {
    const { width, height } = params;

    this.preset = params.preset ?? 'unknown';

    this.width = width;
    this.height = height;

    this.grid_count_x = width / opts.GRID_SIZE;
    this.grid_count_y = height / opts.GRID_SIZE;
    this.grid_count = this.grid_count_x * this.grid_count_y;

    this.offset = params.offset ?? new v2(0, 0);

    // TODO: mission, goal, eliminate
    this.simover_rule = params.simover_rule ?? 'mission';

    // render
    this.tile_floor = params.tile_floor ?? null;

    // misc
    // 맵 주변부 시야를 시작할 때 밝힙니다. 실내 탐색 디버깅용
    this.exp_prepopulate_grid = params.exp_prepopulate_grid ?? false;

    // 색적. 공격을 감지했지만 시야에 닿지 않는 경우 탐색합니다.
    this.exp_search = params.exp_search ?? false;

    // 실내 데모용 플래그
    // 주기적으로 agent의 시야 정보를 공유합니다. 실내 문 진입 환경 문제 우회용
    this.exp_transfer_knowledge = params.exp_transfer_knowledge ?? true;

    // 9월 데모 빌드용 레거시. 레벨 하나만 불러옴
    this.level_name_base = params.level_name_base;
    this.level_names = params.level_names;
  }

  idx(p) {
    return p.y * this.grid_count_x + p.x;
  }

  valid(p) {
    const { x, y } = p;
    return x >= 0 && x < this.grid_count_x && y >= 0 && y < this.grid_count_y;
  }

  valid_world(p) {
    const { x, y } = p;
    return x >= -this.width / 2 && x <= this.width / 2 && y >= -this.height / 2 && y <= this.height / 2;
  }

  gridIdxToWorld(idx) {
    let x = idx % this.grid_count_x;
    let y = Math.floor(idx / this.grid_count_x);
    return this.gridToWorld(new v2(x, y));
  }

  gridToWorld(p) {
    const { x, y } = p;
    return new v2(
      (x + 0.5) * opts.GRID_SIZE - this.width / 2,
      (y + 0.5) * opts.GRID_SIZE - this.height / 2,
    );
  }

  worldToGrid(p) {
    return new v2(
      Math.floor((p.x + this.width / 2) / opts.GRID_SIZE),
      Math.floor((p.y + this.height / 2) / opts.GRID_SIZE),
    );
  }
}
