/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const PerktooltipSi = ({
  ptDesc = "TOOLTIP_DESC",
  ptTitle = "TOOLTIP_TITLE",
  style = "D",
  perkimgSample = "/anima/img/perkimg-sample-4.png",
}) => {
  return (
    <div className="perktooltip-SI" style={typeof style === "object" ? style : null}>
      <div className="overlap-13">
        <div className="div-4">
          <img className="subtract-4" alt="Subtract" src="/anima/img/subtract.svg" />
        </div>
        <div className="div-4">
          <div className="overlap-group-12">
            <img className="rectangle-5" alt="Rectangle" src="/anima/img/rectangle-362-1.svg" />
            <img className="rectangle-6" alt="Rectangle" src="/anima/img/rectangle-367-1.svg" />
          </div>
        </div>
        <div className="div-4">
          <img className="subtract-5" alt="Subtract" src="/anima/img/subtract-2.svg" />
        </div>
        <div className="tooltipcontent">
          <div className="frame-36">
            <div className="perkimg-sample-wrapper">
              <img className="perkimg-sample" alt="Perkimg sample" src={perkimgSample} />
            </div>
          </div>
          <div className="tooltip-text">
            <div className="pt-desc-2">{ptDesc}</div>
            <div className="pt-title-2">{ptTitle}</div>
          </div>
        </div>
        <div className="style-dummy-2"></div>
      </div>
    </div>
  );
};

PerktooltipSi.propTypes = {
  ptDesc: PropTypes.string,
  ptTitle: PropTypes.string,
  style: PropTypes.any,
  perkimgSample: PropTypes.string,
};
