
import MersenneTwister from 'mersenne-twister';

const SEED_MAX = 100000;

const cyrb53 = (str, seed = 0) => {
  let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
  for(let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1  = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
  h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2  = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
  h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

export class Rng {
  static randomseed() {
    return Math.floor(Math.random() * SEED_MAX);
  }

  constructor(seed) {
    if (typeof seed === 'string') {
      seed = cyrb53(seed);
    }
    this.rng = new MersenneTwister(seed);
  }

  next() {
    return this.rng.random_excl();
  }

  range(start, end) {
    const interval = end - start;
    return this.next() * interval + start;
  }

  integer(start, end) {
    return Math.floor(this.range(start, end + 1));
  }

  next_seed() {
    return this.integer(0, SEED_MAX);
  }

  choice(items) {
    return items[Math.floor(this.range(0, items.length))];
  }

  shuffle(items) {
    for (let i = 0; i < items.length - 1; i++) {
      const j = this.integer(i, items.length - 1);
      const tmp = items[i];
      items[i] = items[j];
      items[j] = tmp;
    }
    return items;
  }

  weighted(weights) {
    const sum = weights.reduce((a, b) => a + b);
    const r = this.range(0, sum);
    let acc = 0;
    for (let i = 0; i < weights.length; i++) {
      acc += weights[i];
      if (r <= acc) {
        return i;
      }
    }
    return weights.length - 1;
  }

  weighted_key(items, key) {
    const weights = items.map((item) => item[key]);
    const selected = this.weighted(weights);
    return items[selected];
  }

  angle() {
    return this.range(0, Math.PI * 2);
  }
}
