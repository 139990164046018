export class TickTimer {
  constructor(start, duration) {
    this.start = start;
    this.duration = duration;
    this.end = start + duration;
  }

  expired(tick) {
    return tick >= this.end;
  }

  expired_exact(tick) {
    return tick === this.end;
  }

  progress(tick) {
    return (tick - this.start) / this.duration;
  }

  triggered(tick) {
    return this.start === tick - 1;
  }

  remain(tick) {
    return Math.max(-1, this.end - tick);
  }
}
