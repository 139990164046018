// downloaded data!
export const data = [
  {
    "num": "1",
    "group": "neutral",
    "title": "겁먹은 은행가",
    "desc": "문을 열고 들어간 방에는 은행가가 주가를 조작하고 있었다. 가진 돈을 다 줄 테니 살려달라고 하는데…"
  },
  {
    "num": "2",
    "group": "neutral",
    "title": "용병 과잉",
    "desc": "업계에 용병이 너무 많다! 이 상황에서 어떻게 이득을 취할 수 있을까?"
  },
  {
    "num": "3",
    "group": "neutral",
    "title": "인터뷰",
    "desc": "용병업계에 대한 특집기사를 쓰는 미국인 기자가 인터뷰를 요청했다. 꾸며내도 좋으니 낭만적인 모험담을 풀면 수고비를 주겠다고 한다."
  },
  {
    "num": "4",
    "group": "neutral",
    "title": "보급지 정보",
    "desc": "무기상의 밀수 거점을 알아냈다. 털면 유용한 물자가 나올 것 같다. 어차피 신고도 못할 거 아냐?"
  },
  {
    "num": "5",
    "group": "neutral",
    "title": "임무 정보",
    "desc": "괜찮은 의뢰가 들어왔다며, 브로커가 내게 취향을 묻는다."
  },
  {
    "num": "6",
    "group": "neutral",
    "title": "회의감 확산",
    "desc": "교전 중 민간인이 휘말렸다. 일부 직원이 죄책감을 느끼는지 우울해보인다. 손도 떨고."
  },
  {
    "num": "7",
    "group": "neutral",
    "title": "편향된 전리품",
    "desc": "용병들이 \"성공적인 임무 수행을 위해서는 뭐가 제일 중요하냐\"로 싸움이 났다. 셋 중 하나의 편을 들어줘야하긴 하는데…"
  },
  {
    "num": "8",
    "group": "minor",
    "title": "벌집을 건드림",
    "desc": "이번에 상대한 놈들은 아무래도 뒤끝이 있어보인다."
  },
  {
    "num": "10",
    "group": "minor",
    "title": "예상치 못한 도움",
    "desc": "임무에서 도움을 받은 의뢰주가 감사를 표하며, 다가올 임무에서 도움을 줄 수 있다고 말한다."
  },
  {
    "num": "11",
    "group": "minor",
    "title": "성과를 인정해",
    "desc": "용병들의 최근 임무 평가에 대해서 불만을 가지고 있다."
  },
  {
    "num": "12",
    "group": "neutral",
    "title": "인근국 내전 완화",
    "desc": "니카라과 혁명군이 정부와 협상에 들어갔다는 뉴스다. 다량의 무기가 이쪽으로 넘어올 것이다. 결국 또 싸우기 시작하겠지만."
  },
  {
    "num": "13",
    "group": "neutral",
    "title": "사상은 방탄이다",
    "desc": "공산주의 게릴라가 또 하나 창설됐는데, 총만 잔뜩 사서 방탄장비가 남아 돈다고 한다."
  },
  {
    "num": "14",
    "group": "neutral",
    "title": "엎어진 채광사업",
    "desc": "대규모 채광사업이 취소돼서 대량의 폭약이 시장에 풀렸다."
  },
  {
    "num": "15",
    "group": "minor",
    "title": "사소한 실수",
    "desc": "용병 중 한 명이 실수를 해서 비전투손실이 발생했다. 이 점을 파고들까, 아니면 결속을 강화하는 기회로 삼을까?"
  },
  {
    "num": "16",
    "group": "major",
    "title": "예상외의 수입",
    "desc": "퇴직한 직원의 방을 청소하다가 지폐뭉치를 발견했다. 잘 쓰겠습니다."
  },
  {
    "num": "18",
    "group": "neutral",
    "title": "시대의 끝",
    "desc": "좋은 일도, 나쁜 일도 결국은 지나가기 마련이다."
  },
  {
    "num": "19",
    "group": "minor",
    "title": "지시 불만",
    "desc": "용병들이 최근 원격 지시에 불만을 느끼고 있다. 강제로 지시하는 방법도 있겠지만…"
  },
  {
    "num": "20",
    "group": "minor",
    "title": "용병 성장",
    "desc": "용병 중 한 명이 갑자기 왕성환 활동을 보이고 있다. 포텐셜이 터진 걸까."
  },
  {
    "num": "24",
    "group": "major",
    "title": "예상외의 지출",
    "desc": "예산 다 편상하고 난 다음에 지출증빙 들고 나오지 말라고!!!"
  },
  {
    "num": "26",
    "group": "neutral",
    "title": "용병 면담",
    "desc": "용병을 관리하기 위해서 노동자대표와 면담을 진행한다."
  },
  {
    "num": "28",
    "group": "major",
    "title": "이런 날도 있어야지",
    "desc": "평화롭다. 가끔은 이런 날도 있어야지."
  },
  {
    "num": "29",
    "group": "major",
    "title": "단체 결근",
    "desc": "어제 임무 성공 기념으로 회식을 했다가 오늘 단체로 결근했다. 벌금통이 꽉 차겠군."
  },
  {
    "num": "30",
    "group": "major",
    "title": "경쟁사의 흉계",
    "desc": "신생 회사 하나가 비정상적으로 높은 연봉으로 우리 용병을 유혹하고 있다. 보너스로 달래는 수밖에."
  },
  {
    "num": "33",
    "group": "major",
    "title": "유망한 업계",
    "desc": "업계 선배 하나가 정치에 뛰어들었다가 용병 경력을 폭로당했다. 그 이후로 용병일을 하겠다는 사람이 늘었다."
  },
  {
    "num": "34",
    "group": "major",
    "title": "바다 건너의 기회",
    "desc": "로디지아의 내전이 격해지면서 그쪽으로 넘어가는 용병들이 많아졌다."
  },
  {
    "num": "39",
    "group": "minor",
    "title": "무기 상점 강화",
    "desc": "미국과 소련의 화해 분위기에 각국 국방예산이 동결됐다는 뉴스다. 각국 방산업체는 재고 걱정에 휩싸였겠지."
  },
  {
    "num": "40",
    "group": "minor",
    "title": "장비 상점 강화",
    "desc": "업계에 방탄복 무용론이 퍼지고 있다. 목숨이 더 싸다나 뭐라나..."
  },
  {
    "num": "41",
    "group": "minor",
    "title": "투척물 상점 강화",
    "desc": "야구가 인기다. 용병들 사이에서도 투척이 유행이다. 흥, 1947 월드시리즈도 못 본 핏덩이들이 뭘 알겠어? 요기 베라 정말 대단했지..."
  },
  {
    "num": "42",
    "group": "milestone1",
    "title": "마약 가공 시설 파괴",
    "desc": "마약 가공 시설을 일소하고 카르텔의 운영에 타격을 주었다. "
  },
  {
    "num": "43",
    "group": "milestone2",
    "title": "선상 파티 참가",
    "desc": "우리는 어디까지나 이번 파티에 참가하지 못한 거다."
  },
  {
    "num": "44",
    "group": "milestone3",
    "title": "지역 사회 보호",
    "desc": "어쩐지 수상하다 했는데 정치인이 연루되어 있었다."
  },
  {
    "num": "45",
    "group": "milestone4",
    "title": "대사관 진입 저지",
    "desc": "녀석이 미국에 넘기려 했던 정보는 무사히 확보했다."
  },
  {
    "num": "46",
    "group": "milestone5",
    "title": "메세지",
    "desc": "할 수 있는 일은 했다."
  },
  {
    "num": "9",
    "group": "neutral",
    "title": "예상치 못한 군축",
    "desc": "정치인들의 사정으로 군축이 진행된다고 한다. 인력을 좀 뽑아올 기회 같은데."
  },
  {
    "num": "17",
    "group": "major",
    "title": "복무관리실태 감사",
    "desc": "\"용병들이 불안한 환경에서 근무하도록 방치하신다면 저희 용병권익협회에서는 국제노동기구 개입을 요청할 수밖에 없습니다.\""
  },
  {
    "num": "21",
    "group": "minor",
    "title": "강적",
    "desc": "다음 목표 임무가 쉽지 않을 거라는 예상은 하고 있었다. 하지만 예상보다 더 어려울 거라는 정보를 입수했다."
  },
  {
    "num": "22",
    "group": "minor",
    "title": "유망한 친구",
    "desc": "분쟁지역을 갓 졸업하고 귀국한 친구들과 계약할 기회를 얻었다. 어떤 친구가 좋을까."
  },
  {
    "num": "23",
    "group": "major",
    "title": "방랑 의사",
    "desc": "자신을 의사라고 주장하는 수상한 남자가 나타났다."
  },
  {
    "num": "25",
    "group": "major",
    "title": "노동자대표 면담",
    "desc": "노동자대표가 용병의 처우 상승을 요청하고 있다. 다행히 이 공산주의자들은 '타협의 여지'가 있다…."
  },
  {
    "num": "27",
    "group": "major",
    "title": "암시장 활성화",
    "desc": "대단한 건 없는 시장이지만, 가끔 의욕있는 사람들이 나타나곤 한다."
  },
  {
    "num": "31",
    "group": "major",
    "title": "호경기",
    "desc": "갑작스레 용병 업계에 돈이 돌기 시작했다."
  },
  {
    "num": "32",
    "group": "major",
    "title": "불경기",
    "desc": "갑자기 용병 업계가 팍팍해졌다."
  },
  {
    "num": "35",
    "group": "major",
    "title": "고용안정성 증가",
    "desc": "노동법 제정이 용병업계에도 영향을 주고 있다. 이번 법은 얼마나 갈까."
  },
  {
    "num": "36",
    "group": "major",
    "title": "부당 계약",
    "desc": "어리버리한 신인들을 등쳐먹는 게 유행이다…"
  },
  {
    "num": "37",
    "group": "minor",
    "title": "고급 인력",
    "desc": "군축이 진행되는데 장성들은 멀쩡하고 장교들만 옷 벗고 있다 한다. 안타까운 일이지만, 우리한텐 아니지."
  },
  {
    "num": "38",
    "group": "minor",
    "title": "사병 출신 인력",
    "desc": "전역자들이 대규모로 발생한다고 한다. 일자리는 한정되어 있고. 우리 업계가 좀 완충 역할을 할 수 있을 것 같은데."
  },
  {
    "num": "49",
    "group": "major",
    "title": "첫발을 내딛다",
    "desc": "첫 의뢰를 성공적으로 끝냈다. 앞으로 더 어려운 의뢰와 강력한 적을 만나게 될 것이다."
  }
];