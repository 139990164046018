// downloaded data!
export const data = [
  {
    "result_key": "base_main_1",
    "effect_0": "base_enable_facility_tier",
    "value_0_0": "1",
    "value_0_1": "",
    "effect_1": "base_give_slot",
    "value_1_0": "base_sub",
    "value_1_1": "6"
  },
  {
    "result_key": "base_main_2",
    "effect_0": "base_enable_facility_tier",
    "value_0_0": "2",
    "value_0_1": "",
    "effect_1": "base_give_slot",
    "value_1_0": "base_sub",
    "value_1_1": "7"
  },
  {
    "result_key": "base_main_3",
    "effect_0": "base_enable_facility_tier",
    "value_0_0": "3",
    "value_0_1": "",
    "effect_1": "base_give_slot",
    "value_1_0": "base_sub",
    "value_1_1": "8"
  },
  {
    "result_key": "base_main_4",
    "effect_0": "base_enable_facility_tier",
    "value_0_0": "4",
    "value_0_1": "",
    "effect_1": "base_give_slot",
    "value_1_0": "base_sub",
    "value_1_1": "9"
  },
  {
    "result_key": "department",
    "effect_0": "department"
  },
  {
    "result_key": "market_1",
    "effect_0": "generate_item",
    "value_0_0": "1",
    "value_0_1": "1"
  },
  {
    "result_key": "market_2",
    "effect_0": "generate_item",
    "value_0_0": "2",
    "value_0_1": "1"
  },
  {
    "result_key": "market_3",
    "effect_0": "generate_item",
    "value_0_0": "3",
    "value_0_1": "1"
  },
  {
    "result_key": "market_4",
    "effect_0": "generate_item",
    "value_0_0": "4",
    "value_0_1": "1"
  },
  {
    "result_key": "recruit_1",
    "effect_0": "generate_mercenary",
    "value_0_0": "1",
    "value_0_1": "1"
  },
  {
    "result_key": "recruit_2",
    "effect_0": "generate_mercenary",
    "value_0_0": "2",
    "value_0_1": "1"
  },
  {
    "result_key": "recruit_3",
    "effect_0": "generate_mercenary",
    "value_0_0": "3",
    "value_0_1": "1"
  },
  {
    "result_key": "recruit_4",
    "effect_0": "generate_mercenary",
    "value_0_0": "4",
    "value_0_1": "1"
  },
  {
    "result_key": "train_1",
    "effect_0": "generate_trainslot",
    "value_0_0": "1",
    "value_0_1": "4"
  },
  {
    "result_key": "train_2",
    "effect_0": "generate_trainslot",
    "value_0_0": "2",
    "value_0_1": "5"
  },
  {
    "result_key": "train_3",
    "effect_0": "generate_trainslot",
    "value_0_0": "3",
    "value_0_1": "6"
  },
  {
    "result_key": "train_4",
    "effect_0": "generate_trainslot",
    "value_0_0": "4",
    "value_0_1": "7"
  },
  {
    "result_key": "command_1",
    "effect_0": "base_increase_mercenary_limit",
    "value_0_0": "2"
  },
  {
    "result_key": "command_2",
    "effect_0": "base_increase_mercenary_limit",
    "value_0_0": "4"
  },
  {
    "result_key": "command_3",
    "effect_0": "base_increase_mercenary_limit",
    "value_0_0": "6"
  },
  {
    "result_key": "command_4",
    "effect_0": "base_increase_mercenary_limit",
    "value_0_0": "8"
  },
  {
    "result_key": "trauma_1",
    "effect_0": "base_increase_mercenary_heal",
    "value_0_0": "10"
  },
  {
    "result_key": "trauma_2",
    "effect_0": "base_increase_mercenary_heal",
    "value_0_0": "20"
  },
  {
    "result_key": "trauma_3",
    "effect_0": "base_increase_mercenary_heal",
    "value_0_0": "30"
  },
  {
    "result_key": "trauma_4",
    "effect_0": "base_increase_mercenary_heal",
    "value_0_0": "40"
  },
  {
    "result_key": "manage_1",
    "effect_0": "base_decrease_total_wage",
    "value_0_0": "10"
  },
  {
    "result_key": "manage_2",
    "effect_0": "base_decrease_total_wage",
    "value_0_0": "20"
  },
  {
    "result_key": "manage_3",
    "effect_0": "base_decrease_total_wage",
    "value_0_0": "30"
  },
  {
    "result_key": "manage_4",
    "effect_0": "base_decrease_total_wage",
    "value_0_0": "40"
  },
  {
    "result_key": "search_bonus_1",
    "effect_0": "base_increase_staff_stat",
    "value_0_0": "search",
    "value_0_1": "2"
  },
  {
    "result_key": "search_bonus_2",
    "effect_0": "base_increase_staff_stat",
    "value_0_0": "search",
    "value_0_1": "5"
  },
  {
    "result_key": "search_bonus_3",
    "effect_0": "base_increase_staff_stat",
    "value_0_0": "search",
    "value_0_1": "9"
  },
  {
    "result_key": "train_bonus_1",
    "effect_0": "base_increase_staff_stat",
    "value_0_0": "train",
    "value_0_1": "2"
  },
  {
    "result_key": "train_bonus_2",
    "effect_0": "base_increase_staff_stat",
    "value_0_0": "train",
    "value_0_1": "5"
  },
  {
    "result_key": "train_bonus_3",
    "effect_0": "base_increase_staff_stat",
    "value_0_0": "train",
    "value_0_1": "9"
  },
  {
    "result_key": "newtown_1",
    "effect_0": "base_search_mercenary_background",
    "value_0_0": "30",
    "value_0_1": "history_1_sas"
  },
  {
    "result_key": "sangam_1",
    "effect_0": "base_increase_train_efficiency",
    "value_0_0": "50"
  },
  {
    "result_key": "hq_stat_bonus",
    "effect_0": "base_increase_staff_stat_all",
    "value_0_0": "3"
  },
  {
    "result_key": "safehouse_1",
    "effect_0": "district_enable_operation"
  },
  {
    "result_key": "safehouse_2",
    "effect_0": "district_enable_operation",
    "value_0_0": "",
    "value_0_1": "",
    "effect_1": "district_first_aid",
    "value_1_0": "5"
  },
  {
    "result_key": "safehouse_3",
    "effect_0": "district_enable_operation",
    "value_0_0": "",
    "value_0_1": "",
    "effect_1": "district_first_aid",
    "value_1_0": "10"
  },
  {
    "result_key": "safehouse_4",
    "effect_0": "district_enable_operation",
    "value_0_0": "",
    "value_0_1": "",
    "effect_1": "district_first_aid",
    "value_1_0": "15"
  },
  {
    "result_key": "pharmacy",
    "effect_0": "increase_mercenary_heal",
    "value_0_0": "5"
  },
  {
    "result_key": "bar",
    "effect_0": "decrease_mercenary_wage",
    "value_0_0": "5"
  },
  {
    "result_key": "estate",
    "effect_0": "decrease_safehouse_cost",
    "value_0_0": "5"
  },
  {
    "result_key": "architect",
    "effect_0": "decrease_base_facility_cost",
    "value_0_0": "5",
    "value_0_1": "",
    "effect_1": "decrease_base_facility_time",
    "value_1_0": "5"
  },
  {
    "result_key": "mechanic",
    "effect_0": "increase_operation_travel_speed",
    "value_0_0": "5"
  },
  {
    "result_key": "pier",
    "effect_0": "district_increase_staff_stat",
    "value_0_0": "search",
    "value_0_1": "5"
  },
  {
    "result_key": "gym",
    "effect_0": "district_increase_train_efficiency",
    "value_0_0": "30"
  },
  {
    "result_key": "college",
    "effect_0": "district_increase_search_staff",
    "value_0_0": "30"
  },
  {
    "result_key": "redevelop",
    "effect_0": "district_decrease_base_facility_cost",
    "value_0_0": "20",
    "value_0_1": "",
    "effect_1": "district_decrease_base_facility_time",
    "value_1_0": "20"
  },
  {
    "result_key": "mechanic_1",
    "effect_0": "base_decrease_mission_time",
    "value_0_0": "10"
  },
  {
    "result_key": "mechanic_2",
    "effect_0": "base_decrease_mission_time",
    "value_0_0": "20"
  },
  {
    "result_key": "mechanic_3",
    "effect_0": "base_decrease_mission_time",
    "value_0_0": "30"
  },
  {
    "result_key": "mechanic_4",
    "effect_0": "base_decrease_mission_time",
    "value_0_0": "40"
  },
  {
    "result_key": "remedy_1",
    "effect_0": "base_increase_stamina_recover",
    "value_0_0": "10"
  },
  {
    "result_key": "remedy_2",
    "effect_0": "base_increase_stamina_recover",
    "value_0_0": "20"
  },
  {
    "result_key": "remedy_3",
    "effect_0": "base_increase_stamina_recover",
    "value_0_0": "30"
  },
  {
    "result_key": "remedy_4",
    "effect_0": "base_increase_stamina_recover",
    "value_0_0": "40"
  },
  {
    "result_key": "hideout_1",
    "effect_0": "district_first_aid",
    "value_0_0": "5"
  },
  {
    "result_key": "hideout_2",
    "effect_0": "district_first_aid",
    "value_0_0": "10"
  },
  {
    "result_key": "hideout_3",
    "effect_0": "district_first_aid",
    "value_0_0": "15"
  },
  {
    "result_key": "hideout_4",
    "effect_0": "district_first_aid",
    "value_0_0": "20"
  }
];