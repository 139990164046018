// downloaded data!
export const data = [
  {
    "key": "decision",
    "descr": "loc_data_longtext_statsdescr_descr_decision",
    "descr_effect": "loc_data_longtext_statsdescr_descr_effect_decision"
  },
  {
    "key": "bravery",
    "descr": "loc_data_longtext_statsdescr_descr_bravery",
    "descr_effect": "loc_data_longtext_statsdescr_descr_effect_bravery"
  },
  {
    "key": "focus",
    "descr": "loc_data_longtext_statsdescr_descr_focus",
    "descr_effect": "loc_data_longtext_statsdescr_descr_effect_focus"
  },
  {
    "key": "reaction",
    "descr": "loc_data_longtext_statsdescr_descr_reaction",
    "descr_effect": "loc_data_longtext_statsdescr_descr_effect_reaction"
  },
  {
    "key": "toughness",
    "descr": "loc_data_longtext_statsdescr_descr_toughness",
    "descr_effect": "loc_data_longtext_statsdescr_descr_effect_toughness"
  },
  {
    "key": "precision",
    "descr": "loc_data_longtext_statsdescr_descr_precision",
    "descr_effect": "loc_data_longtext_statsdescr_descr_effect_precision"
  }
];
