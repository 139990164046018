import _ from 'lodash';
import dayjs from 'dayjs';
import { Fragment } from "react";
import { tickToDate, TICK_PER_MONTH } from './tick';

import './CashbookView.css';

export const CATS_INCOME = [
  ['Basic Mission Reward', 'basic', true],
  ['Additional Mission Reward', 'select'],
  ['Items Sold', 'market'],
  ['Events Income', 'event'],
  ['Branch Shutdown', 'area'],
];

export const CATS_SPENDING = [
  ['Mission Cost', 'basic', true],
  ['Profit Share', 'share', true],
  ['Compensation', 'compensation'],
  ['Extra Recovery', 'extra'],
  ['Item Purchase', 'market'],
  ['Mercenary Contract', 'contract'],
  ['Events Expenditure', 'event'],
  ['Branch Maintenance', 'area'],
  ['Staff Salary', 'staff'],
];

export function turnBalance(turn, cashbook) {
  return cashbook.filter((c) => c.turn === turn).reduce((sum, c) => sum + c.resource, 0);
}

export function CashbookSummary(props) {
  const { turn, cashbook } = props;

  let amount = 0;
  let summary = '';
  for (const [name, ty] of CATS_INCOME) {
    let sum = cashbook.filter((c) => c.turn === turn && c.ty === ty && c.resource > 0)
      .reduce((sum, c) => { return sum + c.resource }, 0);
    if (sum !== 0) {
      amount += sum;
      summary += `${name}: $${sum.toFixed(0)}\n`;
    }
  }
  for (const [name, ty] of CATS_SPENDING) {
    let sum = cashbook.filter((c) => c.turn === turn && c.ty === ty && c.resource < 0)
      .reduce((sum, c) => { return sum + c.resource }, 0);
    if (sum !== 0) {
      amount += sum;
      summary += `${name}: -$${-sum.toFixed(0)}\n`;
    }
  }
  return <span title={summary}>${amount.toFixed(0)}</span>;
}

function color(n) {
  if (n === 0) {
    return 'cashbook-zero';
  } else if (n > 0) {
    return 'cashbook-pos';
  } else {
    return 'cashbook-neg';
  }
}

export function ValueSpan(props) {
  const { value } = props;
  const cls = `${props.className ?? ''} ${color(value)}`;
  const v = value.toFixed(0);

  if (v === '0') {
    return <span className={cls}>-</span>;
  }
  return <span className={cls}>${value}</span>;

}

function ValueRow(props) {
  return <td><ValueSpan {...props}/></td>;
}

export function CashbookView(props) {
  const { turn, cashbook } = props;
  const turn_start = props.turn_start ?? 0;
  const show_total = turn !== turn_start;
  const col = [];
  for (let i = turn_start; i < turn + 1; i++) {
    col.push(i);
  }

  const cashbook1 = cashbook;

  const income = cashbook1.filter((c) => c.turn >= 0 && c.resource > 0);
  const spending = cashbook1.filter((c) => c.turn >= 0 && c.resource < 0);

  const cats_income = CATS_INCOME.filter(([_, ty, show]) => show || income.find((c) => c.ty === ty));
  const cats_spending = CATS_SPENDING.filter(([_, ty, show]) => show || spending.find((c) => c.ty === ty));

  function tableRow(l, title, ty) {
    let sum = _.sum(l.map(({ resource }) => resource));
    return <tr key={ty}>
      {title === 'Basic Mission Reward' ? <th className="cashbook-row0" rowSpan={cats_income.length + 1}>Income</th> : ``}
      {title === 'Mission Cost' ? <th className="cashbook-row0" rowSpan={cats_spending.length + 1}>Expenses</th> : ``}
      <th className="cashbook-row1">{title}</th>
      {
        col.map((i) => {
          let sum = _.sum(l.filter((c) => c.turn === i && c.ty === ty).map(({ resource }) => resource));
          return <ValueRow key={i} className='cashbook-row' value={sum} />;
        })
      }
      {show_total ? <ValueRow className='cashbook-row' value={sum} /> : null}
    </tr>
  }

  const sumAll = cashbook1.filter((c) => c.turn >= 0).reduce((sum, c) => { return sum + c.resource }, 0);

  const renderSubtable = (l, cats) => {
    return <>
      {cats.map(([label, key]) => {
        const l1 = l.filter((c) => c.ty === key);
        return tableRow(l1, label, key);
      })}
      <tr>
        <th>Subtotal</th>
        {col.map((i) => {
          const sum0 = _.sum(l.filter((c) => c.turn === i).map(({ resource }) => resource));
          return <ValueRow key={i} value={sum0} />;
        })}
        {show_total ? <ValueRow value={_.sum(l.map(({ resource }) => resource))} /> : null}
      </tr>
    </>;
  }

  return <table className="cashbook-root">
    <thead>
      <tr align="center" >
        <th></th>
        <th></th>
        {col.map((i) => {
          const tick = i * TICK_PER_MONTH;
          const month = dayjs(tickToDate(tick)).format("YYYY-MM");
          let m_minus = `M-${turn - i}`;
          if (i === turn) {
            m_minus = '이번달';
          }

          return <th key={i}>
            {month}
            <br />
            {m_minus}
          </th>;
        })}
        {show_total ? <th>Total</th> : null}
      </tr>
    </thead>
    <tbody>
      {renderSubtable(income, cats_income)}
      {renderSubtable(spending, cats_spending)}

      <tr style={{ fontWeight: 'bold' }}>
        <th colSpan="2">Total</th>
        {col.map((i) => {
          const sum0 = cashbook1.filter((c) => c.turn === i)
            .reduce((sum, c) => { return sum + c.resource }, 0);
          return <ValueRow key={i} value={sum0} />;
        })}
        {show_total ? <ValueRow value={sumAll} /> : null}
      </tr>
      <tr>
        <th></th>
        <th >Carryover</th>
        {col.map((i) => {
          const sum0 = cashbook1.filter((c) => c.turn < i)
            .reduce((sum, c) => { return sum + c.resource }, 0);
          return <ValueRow key={i} value={sum0} />;
        })}
      </tr>
      <tr>
        <th></th>
        <th>Balance</th>
        {col.map((i) => {
          const sum0 = cashbook1.filter((c) => c.turn <= i)
            .reduce((sum, c) => { return sum + c.resource }, 0);
          return <ValueRow key={i} value={sum0} />;
        })}
        {show_total ? <ValueRow value={cashbook1.reduce((sum, c) => { return sum + c.resource }, 0)} /> : null}
      </tr>
    </tbody>
  </table >;
}

export function AgentCashbookView(props) {
  const { agents, agentbook, turn } = props;

  const cashbook = [];
  for (const c of props.cashbook) {
    for (const agent of c.agents) {
      cashbook.push({ ...c, agent, resource: c.resource / c.agents.length })
    }
  }

  let agentAll = [];
  for (const c of cashbook) {
    agentAll.push(c.agent.name);
  }
  agentAll = [...new Set(agentAll)];

  function color(n) {
    if (n >= 0) {
      return 'black';
    } else {
      return 'red';
    }
  }

  function tableRowSumAver(name, col, turn) {
    const nowAgent = agents.find((a) => a.name === name);
    let sum = 0;
    let aver;
    const tys0 = ['contract', 'extra', 'basic'];
    const tys1 = ['estimated', 'estimatedKill'];
    if (nowAgent) {
      for (const ty of tys0) {
        sum += cashbook.filter((c) => c.agent.name === name && c.ty === ty && c.turn >= nowAgent.contract.start && c.resource < 0)
          .reduce((sum, c) => { return sum + c.resource }, 0);
      }
      for (const ty of tys1) {
        sum += nowAgent.contract[ty];
      }
      const term = turn - nowAgent.contract.start;
      aver = sum / (term === 0 ? 1 : term);
    }

    const data = col.map((i) => {
      const agent = agentbook.find((a) => a.name === name && a.contract.cnt === i)
      if (agent) {
        let sum = 0;
        let aver;
        for (const ty of tys0) {
          sum += cashbook.filter((c) => c.agent.name === name && c.ty === ty && c.resource < 0
            && c.turn >= agent.contract.start && c.turn < agent.contract.start + agent.contract.term)
            .reduce((sum, c) => { return sum + c.resource }, 0);
        }
        for (const ty of tys1) {
          sum += agent.contract[ty];
        }
        const term = agent.contract.term;
        aver = sum / (term === 0 ? 1 : term);
        return { sum, aver };
      } else {
        return null;
      }
    })

    return <Fragment key={name + turn}>
      <tr>
        <th rowSpan="2">종합</th>
        <th>합계</th>
        {nowAgent ? <td style={{ color: color(sum), fontWeight: 'bold', borderRight: '2px double' }}>{sum.toFixed()}</td> : <td style={{ borderRight: '2px double' }}>.</td>}
        {col.map((i) => <td key={name + turn + i + 'sum'} style={{ color: color(data[i - 1].sum), fontWeight: 'bold' }}>{data[i - 1].sum.toFixed()}</td>)}
      </tr>
      <tr>
        <th>평균</th>
        {nowAgent ? <td style={{ color: color(sum), fontWeight: 'bold', borderRight: '2px double' }}>{aver.toFixed()}</td> : <td style={{ borderRight: '2px double' }}>.</td>}
        {col.map((i) => <td key={name + turn + i + 'aver'} style={{ color: color(data[i - 1].aver), fontWeight: 'bold' }}>{data[i - 1].aver.toFixed()}</td>)}
      </tr>
    </Fragment>
      ;
  }

  function tableRow(name, tys, col, turn, aver) {
    const nowAgent = agents.find((a) => a.name === name);
    let data = 0;
    if (nowAgent) {
      for (const ty of tys) {
        data += cashbook.filter((c) => c.agent.name === name && c.turn >= nowAgent.contract.start
          && c.ty === ty && c.resource < 0)
          .reduce((sum, c) => { return sum + c.resource }, 0);
      }
      if (aver) {
        const term = turn - nowAgent.contract.start;
        data /= term === 0 ? 1 : term;
      }
    }

    const key = name + tys.toString() + turn + aver ? 3 : 4;

    return <Fragment key={key}>
      {nowAgent ? <td style={{ color: color(data), borderRight: '2px double' }}>{data.toFixed()}</td> : <td style={{ borderRight: '2px double' }}>.</td>}
      {col.map((i) => {
        const agent = agentbook.find((a) => a.name === name && a.contract.cnt === i)
        if (agent) {
          let data = 0;
          for (const ty of tys) {
            data += cashbook.filter((c) => c.agent.name === name && c.turn >= agent.contract.start
              && c.turn < agent.contract.start + agent.contract.term && c.ty === ty && c.resource < 0)
              .reduce((sum, c) => { return sum + c.resource }, 0);
          }
          if (aver) {
            data /= agent.contract.term;
          }
          return <td key={key + i} className={color(data)}>{data.toFixed()}</td>;
        } else {
          return null;
        }
      })}
    </Fragment>
  }

  function tableRow0(name, tys, col, turn, aver) {
    const nowAgent = agents.find((a) => a.name === name);
    let data = 0;
    if (nowAgent) {
      for (const ty of tys) {
        data += nowAgent.contract[ty];
      }
      if (aver) {
        const term = turn - nowAgent.contract.start;
        data /= term === 0 ? 1 : term;
      }
    }
    const key = name + tys.toString() + turn + aver ? 1 : 2;
    return <Fragment key={key}>
      {nowAgent ? <td style={{ color: color(data), borderRight: '2px double' }}>{data.toFixed()}</td> : <td>.</td>}
      {col.map((i) => {
        const agent = agentbook.find((a) => a.name === name && a.contract.cnt === i)
        if (agent) {
          let data = 0;
          for (const ty of tys) {
            data += agent.contract[ty];
          }
          if (aver) {
            data /= agent.contract.term;
          }
          return <td key={key + i} className={color(data)}>{data.toFixed()}</td>;
        } else {
          return null;
        }
      })}
    </Fragment>
  }

  const cntMax = cashbook.reduce((max, c) => {
    return Math.max(max, c.agent.contract.cnt);
  }, 0);
  const col = Array.from({ length: cntMax - 1 }, (_v, i) => i + 1);

  return <table
    border="1"
    width="50%">
    <thead>
      <tr align="center" >
        <th>용병</th>
        <th></th>
        <th></th>
        <th style={{ borderRight: '2px double' }}>now</th>
        {col.map((i) => <th key={i}>{i}</th>)}
      </tr>
    </thead>
    <tbody>
      {agentAll.map((name => {
        const nowAgent = agents.find((a) => a.name === name);
        const cntMax = agentbook.filter((c) => c.name === name).length;
        const col = Array.from({ length: cntMax }, (_v, i) => i + 1);

        return <Fragment key={name}>
          <tr>
            <th rowSpan="20">{name}</th>
            <th rowSpan="5">수입</th>
            <th>임무기여(참여)</th>
            {tableRow0(name, ['estimated'], col, turn)}
          </tr>
          <tr>
            <th>임무기여(킬)</th>
            {tableRow0(name, ['estimatedKill'], col, turn)}
          </tr>
          <tr>
            <th>파견</th>
            {tableRow0(name, ['estimatedDispatch'], col, turn)}
          </tr>
          <tr>
            <th>소계</th>
            {tableRow0(name, ['estimated', 'estimatedKill', 'estimatedDispatch'], col, turn)}
          </tr>
          <tr>
            <th>평균</th>
            {tableRow0(name, ['estimated', 'estimatedKill', 'estimatedDispatch'], col, turn, true)}
          </tr>
          <tr>
            <th rowSpan="5">비용</th>
            <th>계약금</th>
            {tableRow(name, ['contract'], col, turn)}
          </tr>
          <tr>
            <th>투입비용</th>
            {tableRow(name, ['basic'], col, turn)}
          </tr>
          <tr>
            <th>치료비</th>
            {tableRow(name, ['extra'], col, turn)}
          </tr>
          <tr>
            <th>소계</th>
            {tableRow(name, ['contract', 'extra', 'basic'], col, turn)}
          </tr>
          <tr>
            <th>평균</th>
            {tableRow(name, ['contract', 'extra', 'basic'], col, turn, true)}
          </tr>
          {tableRowSumAver(name, col, turn)}
          <tr>
            <th rowSpan="8">정보</th>
            <th>계약기간</th>
            {nowAgent ? <td style={{ borderRight: '2px double' }}>{nowAgent.contract.start}~{nowAgent.contract.start + nowAgent.contract.term - 1}({turn})</td> : <td style={{ borderRight: '2px double' }}>.</td>}
            {col.map((i) => {
              const agent = agentbook.find((a) => a.name === name && a.contract.cnt === i)
              const { start, term } = agent.contract;
              return <td key={name + i + 'term'}>{start}~{start + term - 1}</td>;
            })}
          </tr>
          <tr>
            <th>계약형태</th>
            {nowAgent ? <td style={{ borderRight: '2px double' }}>{nowAgent.contract.ty}</td> : <td style={{ borderRight: '2px double' }}>.</td>}
            {col.map((i) => {
              const agent = agentbook.find((a) => a.name === name && a.contract.cnt === i)
              return <td key={name + i + 'ty'}>{agent.contract.ty}</td>;
            })}
          </tr>
          <tr>
            <th>옵션</th>
            {nowAgent ? <td style={{ borderRight: '2px double' }}>
              {nowAgent.contract.option.long ? '장기계약, ' : ''}
              {nowAgent.contract.option.advanced ? '고급케어, ' : ''}
              {nowAgent.contract.option.danger ? '위험수당, ' : ''}
              {nowAgent.contract.option.convenience ? '임무수당편의' : ''}
            </td> : <td style={{ borderRight: '2px double' }}>.</td>}
            {col.map((i) => {
              const agent = agentbook.find((a) => a.name === name && a.contract.cnt === i)
              return <td key={name + i + 'option'}>
                {agent.contract.option.long ? '장기계약, ' : ''}
                {agent.contract.option.advanced ? '고급케어, ' : ''}
                {agent.contract.option.danger ? '위험수당, ' : ''}
                {agent.contract.option.convenience ? '임무수당편의' : ''}
              </td>;
            })}
          </tr>
          <tr>
            <th>임무턴수</th>
            {nowAgent ? <td style={{ borderRight: '2px double' }}>{nowAgent.contract.mission}</td> : <td style={{ borderRight: '2px double' }}>.</td>}
            {col.map((i) => {
              const agent = agentbook.find((a) => a.name === name && a.contract.cnt === i)
              return <td key={name + i + 'mission'}>{agent.contract.mission}</td>;
            })}
          </tr>
          <tr>
            <th>치료턴수</th>
            {nowAgent ? <td style={{ borderRight: '2px double' }}>{nowAgent.contract.rest}</td> : <td style={{ borderRight: '2px double' }}>.</td>}
            {col.map((i) => {
              const agent = agentbook.find((a) => a.name === name && a.contract.cnt === i)
              return <td key={name + i + 'rest'}>{agent.contract.rest}</td>;
            })}
          </tr>
          <tr>
            <th>파견턴수</th>
            {nowAgent ? <td style={{ borderRight: '2px double' }}>{nowAgent.contract.dispatch}</td> : <td style={{ borderRight: '2px double' }}>.</td>}
            {col.map((i) => {
              const agent = agentbook.find((a) => a.name === name && a.contract.cnt === i)
              return <td key={name + i + 'dispatch'}>{agent.contract.dispatch}</td>;
            })}
          </tr>
          <tr>
            <th>논턴수</th>
            {nowAgent ? <td style={{ borderRight: '2px double' }}>{turn - nowAgent.contract.start - nowAgent.contract.rest - nowAgent.contract.mission - nowAgent.contract.dispatch}</td> : <td style={{ borderRight: '2px double' }}>.</td>}
            {col.map((i) => {
              const agent = agentbook.find((a) => a.name === name && a.contract.cnt === i)
              const { term, rest, mission, dispatch } = agent.contract;
              return <td key={name + i + 'play'}>{term - rest - mission - dispatch}</td>;
            })}
          </tr>
          <tr>
            <th>총턴수</th>
            {nowAgent ? <td style={{ borderRight: '2px double' }}>{turn - nowAgent.contract.start}</td> : <td style={{ borderRight: '2px double' }}>.</td>}
            {col.map((i) => {
              const agent = agentbook.find((a) => a.name === name && a.contract.cnt === i)
              return <td key={name + i + 'all'}>{agent.contract.term}</td>;
            })}
          </tr>
        </Fragment>;
      }))}
    </tbody>
  </table >;
}
