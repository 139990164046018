import React, { useState } from 'react';
import {
  STAFF_STAT_MAX,
  ABILITY_KEYS,
  DURATION_BASE_DAYS_FUNCTIONS,
  DepartmentRoot,
  staffAbilityValue,
  departmentStaffAbilityValue,
  getWorkDuration,
  TEMPORAL_DEPARTMENT_KEY,
} from './department.mjs';
import { Rng } from './rand';

import {
  FH1ButtonFoldable as ButtonFoldable,
  FH1ButtonTiny as Button,
  FH1ButtonInline as ButtonInline,
  FH1Panel as Panel,
  FH1ProgressBar as ProgressBar,
  FH1Tabs as Tabs,
} from './component/figma/fh1';

import './DepartmentView.css';

import { facilities as data_facilities } from './data/google/processor/data_facilities.mjs';
import { work as data_work } from './data/google/processor/data_work.mjs';
import { staffAbilities as data_staffAbilities } from './data/google/processor/data_staffAbility.mjs';
import { TICK_PER_DAY, tickToDateStr, tickToMonth } from './tick.mjs';
import { firearm_ty_name } from './presets_firearm.mjs';
import { L } from './localization.mjs';
import { questObjective } from './quest';

import { CashbookSummary, CashbookView } from './CashbookView.js';


const DEPARTMANT_STAT_MAX = Math.ceil(2.5 * STAFF_STAT_MAX);

function getPendingVar1Text(work_key, var1) {
  switch (work_key) {
    case 'do_training':
      //TODO: 선택한 훈련 이름 return
      return var1;
    case 'recruit_agent':
    case 'discount_renewal_agent':
      return var1.name;
    case 'recruit_staff':
      return var1.displayName;
    case 'buy_item':
    case 'sell_item':
      let name = '';
      switch (var1.ty) {
        case 'equipment':
          const { equipment } = var1;
          name = `${L(equipment.vest_name)} / ${equipment.vest_armor}`;
          break;
        case 'firearm':
          const { firearm } = var1;
          name = `${L(firearm_ty_name[firearm.firearm_ty])} / ${L(firearm.firearm_name)}`;
          break;
        case 'throwable':
          const { throwable } = var1;
          name = `${L(throwable.throwable_name)}`;
          break;
        default:
          break;
      }
      return name;
    case 'source_gun_ty':
      return L(firearm_ty_name[var1]);
    case 'source_gun_tier':
    case 'source_bulletproof_tier':
    case 'source_granade_tier':
      return L('loc_dynamic_string_common_item_tier') + var1;
    default:
      return var1;
  }
}

function getPendingVar2Text(work_key, var2) {
  if (work_key === 'do_training') {
    //TODO: 선택한 용병 이름 return
    return var2;
  }
  return var2;
}

function getPendingText(work_key, var1, var2) {
  const data = data_work.find(d => d.work_key === work_key);

  const var1Text = (var1 && var1 !== '') ? ` / ${getPendingVar1Text(work_key, var1)}` : '';
  const var2Text = (var2 && var2 !== '') ? ` / ${getPendingVar2Text(work_key, var2)}` : '';

  return `${L(data.work_name)}${var1Text}${var2Text}`;
}

export function getSuccessRateText(work_key) {
  const work = data_work.find((d) => d.work_key === work_key);
  return work ? L(work.finish_success_rate_desc) : L('loc_data_string_workdetails_finish_success_rate_desc_success');
}

export function getPercentageText(pending) {
  return (Math.min(pending.ability / pending.difficulty, 1) * 100).toFixed(1) + '%';
}

function staffStatBar(value) {
  return <ProgressBar width={70} cur={value} cap={value} max={STAFF_STAT_MAX} text={`${value}`} classname='staffstat' />;
}

function departmentStaffStatBar(cur, cap, text) {
  return <ProgressBar width={70} cur={cur} cap={cap} max={STAFF_STAT_MAX} text={text} classname='departmentstaffstat' />;
}

function departmentStatBar(value) {
  return <ProgressBar width={70} cur={value} cap={value} max={DEPARTMANT_STAT_MAX} text={`${value}`} classname='departmentstat' />;
}


function staffRankText(departmentRoot, staff, world) {
  const { department_fid } = staff;

  if (department_fid === -1) {
    return L('loc_ui_string_organization_department_reserve');
  }
  else {
    const department = departmentRoot.departments.find((d) => d.fid === department_fid);
    if (department.head_id === staff.id) {
      return departmentName(department, world) + ' ' + L('loc_ui_string_organization_department_head');
    }
    else {
      return departmentName(department, world) + ' ' + L('loc_ui_string_organization_department_staff');
    }
  }
}

function VariableSelectView(game, var_data, index, onSelectedIndexChanged) {
  let selectView = <></>;
  let selectedVar;

  if (var_data.length > 1) {
    selectView = <>
      선택: <select onChange={(option) => { onSelectedIndexChanged(option.target.selectedIndex) }}>
        {var_data.map((element, i) => {
          return <option key={i} value={element}>{element}</option>
        })}
      </select>
    </>;

    selectedVar = var_data[index];
  }
  else if (var_data[0][0] === '*') {
    switch (var_data[0]) {
      case '*recruit_agents':
        {
          const recruit_listings = game.recruit_listings;
          selectedVar = recruit_listings.length > index ? recruit_listings[index].agent : null;

          selectView = <select onChange={(option) => { onSelectedIndexChanged(option.target.selectedIndex) }}>
            {recruit_listings.map((element, i) => {
              return <option key={i} value={element.agent.name}>{element.agent.name}</option>
            })}
          </select >
        }
        break;
      case '*agents':
        {
          const agents = game.agents;
          selectedVar = agents.length > index ? agents[index] : null;

          selectView = <select onChange={(option) => { onSelectedIndexChanged(option.target.selectedIndex) }}>
            {agents.map((element, i) => {
              return <option key={i} value={element.name}>{element.name}</option>
            })}
          </select >
        }
        break;
      case '*recruit_staffs':
        {
          const recruit_listings = game.departmentRoot.recruit_listings;
          selectedVar = recruit_listings.length > index ? recruit_listings[index] : null;

          selectView = <select onChange={(option) => { onSelectedIndexChanged(option.target.selectedIndex) }}>
            {recruit_listings.map((element, i) => {
              return <option key={i} value={element.displayName}>{element.displayName}</option>
            })}
          </select >
        }
        break;
      case '*market_items':
        {
          const market_listings = game.market_listings;
          selectedVar = market_listings.length > index ? market_listings[index] : null;

          selectView = <select onChange={(option) => { onSelectedIndexChanged(option.target.selectedIndex) }}>
            {market_listings.map((element, i) => {
              let name = '';
              switch (element.ty) {
                case 'equipment':
                  const { equipment } = element;
                  name = `${L(equipment.vest_name)} / ${equipment.vest_armor}`;
                  break;
                case 'firearm':
                  const { firearm } = element;
                  name = `${L(firearm_ty_name[firearm.firearm_ty])} / ${L(firearm.firearm_name)}`;
                  break;
                case 'throwable':
                  const { throwable } = element;
                  name = `${L(throwable.throwable_name)}`;
                  break;
                default:
                  break;
              }
              return <option key={i} value={name}>{name}</option>
            })}
          </select >
        }
        break;
      case '*warehouse_items':
        {
          const inventories = game.inventories;
          selectedVar = inventories.length > index ? inventories[index] : null;

          selectView = <select onChange={(option) => { onSelectedIndexChanged(option.target.selectedIndex) }}>
            {inventories.map((element, i) => {
              let name = '';
              switch (element.ty) {
                case 'equipment':
                  const { equipment } = element;
                  name = `${L(equipment.vest_name)} / ${equipment.vest_armor}`;
                  break;
                case 'firearm':
                  const { firearm } = element;
                  name = `${L(firearm_ty_name[firearm.firearm_ty])} / ${L(firearm.firearm_name)}`;
                  break;
                case 'throwable':
                  const { throwable } = element;
                  name = `${L(throwable.throwable_name)}`;
                  break;
                default:
                  break;
              }
              return <option key={i} value={name}>{name}</option>
            })}
          </select >
        }
        break;
      case '*train_slots':
        {
          const slots = game.slots;
          selectedVar = slots.length > index ? slots[index] : null;

          selectView = <select onChange={(option) => { onSelectedIndexChanged(option.target.selectedIndex) }}>
            {slots.map((element, i) => {
              return <option key={i} value={element.idx}>{`training-${element.idx}`}</option>
            })}
          </select >
        }
        break;
      case '*trainings':
        break;
      default:
        break;
    }


  }
  else {
    selectView = <span>단일 선택지: {var_data[0]}</span>;
    selectedVar = var_data[0];
  }

  return { selectView, selectedVar };
}

export function departmentName(department, world) {
  const department_idx = department.fid % 10;
  const world_idx = (department.fid - department_idx) / 10;
  const centerstate = world.storage[world_idx].centerstate;
  return centerstate ? `${L(centerstate.name)} - ${L(department.name)} #${department_idx}` : `${L(department.name)} #${department_idx}`
}

function WorkEffectView(props) {
  const { game, department, pending } = props;
  const { work_key } = pending;
  const work = data_work.find((d) => d.work_key === work_key);
  const success_effect_desc = work ? L(work.finish_success_effect_desc) : '';

  const success_props = game.getDepartmentPendingSuccessProps(work_key, department, pending);

  switch (work_key) {
    case 'search_agent_tier':
    case 'search_agent_possible':
    case 'search_staff':
      return <div>{`${success_effect_desc}: ${success_props.power.toFixed(1)}`}</div>;
    case 'recruit_agent':
    case 'discount_renewal_agent':
    case 'buy_item':
      return <div>{`${success_effect_desc}: ${(success_props.discount * 100).toFixed(2)}%`}</div>;
    case 'sell_item':
    case 'search_contract':
      return <div>{`${success_effect_desc}: ${(success_props.bonus * 100).toFixed(2)}%`}</div>;
    default:
      return <></>;
  }
}

function SelectWorkView(props) {
  const { game, department, button_disabled, selectBody, firstOption, secondOption, work, pending, onAccept, onCancel } = props;

  const days = Math.floor(pending.ticks / TICK_PER_DAY);
  const hours = pending.ticks - days * TICK_PER_DAY;
  const days_text = days > 0 ? L('loc_dynamic_string_organization_task_duration_days_text', { count: days }) : '';
  const hours_text = hours > 0 ? L('loc_dynamic_string_organization_task_duration_hours_text', { count: hours }) : '';

  const base_days_func = DURATION_BASE_DAYS_FUNCTIONS[work.work_key];
  const base_days = base_days_func ?
    base_days_func(pending) : Number(work.duration_base_days);
  const base_days_diff_percent = Math.round((base_days - pending.ticks / TICK_PER_DAY) / base_days * 100);
  let base_days_diff_percent_format = <></>;
  if (base_days_diff_percent > 0) {
    base_days_diff_percent_format = <span color='#55AA55'>{L('loc_dynamic_string_organization_task_duration_shortened', { value: base_days_diff_percent })}</span>
  }
  else if (base_days_diff_percent < 0) {
    base_days_diff_percent_format = <span color='#AA5555'>{L('loc_dynamic_string_organization_task_duration_elongated', { value: -base_days_diff_percent })}</span>
  }

  const data_staffAbility = data_staffAbilities.find((d) => d.key === work.ability_ty);

  return <>
    {selectBody}
    {firstOption}
    {secondOption}
    <div>{L('loc_ui_string_organization_task_required_ability_type') + L('loc_data_string_staffability_name_' + data_staffAbility.key)}</div>
    <div>{L('loc_ui_string_organization_task_department_required_ability') + (Math.round(pending.ability * 10) / 10)}</div>
    <div>{L('loc_ui_string_organization_task_difficulty') + (Math.round(pending.difficulty * 10) / 10)}</div>
    <div>{`${getSuccessRateText(work.work_key)}: ${(Math.min(pending.ability / pending.difficulty, 1) * 100).toFixed(2)}%`}</div>
    <div>{L('loc_ui_string_organization_task_duration') + days_text + hours_text}{base_days_diff_percent_format}</div>
    <WorkEffectView game={game} department={department} pending={pending} />

    <Button disabled={button_disabled} onClick={onAccept}>{L('loc_ui_button_organization_perform_task')}</Button>
    <Button onClick={onCancel}>{L('loc_ui_button_common_cancel')}</Button>
  </>;
}

function DepartmentWorkView(props) {
  const { departmentRoot, game, department, onDepartmentUpdated, onCancel } = props;
  const [work_key, setWork] = useState(null);
  const [var1_index, setVar1] = useState(0);
  const [var2_index, setVar2] = useState(0);

  if (!department) {
    return <ButtonInline onClick={onCancel}>취소</ButtonInline>;
  }
  else {
    const data_facility = data_facilities.find(f => f.key === department.key);
    const tasks = data_facility.tasks;
    const works = data_work.filter((w) => w.work_type === 'main' && tasks.find((t) => t.work_key === w.work_key));

    const data = tasks.find((t) => t.work_key === work_key);
    let firstOption = <></>;
    let secondOption = <></>;

    const work_options = [<option key={0} value={null}>{L('loc_ui_string_common_not_applicable')}</option>];
    for (let i = 0; i < works.length; i++) {
      const work = works[i];
      work_options.push(<option key={2 * i + 1} value={work.work_key} selected={work.work_key === work_key}>{L(work.work_name)}</option>);
      work_options.push(<option key={2 * i + 2} disabled={true} value={work.work_key}>&nbsp;&nbsp;&nbsp;&nbsp;{L(work.work_desc)}</option>);
    }

    let body = <>
      <p className='flex-department-popup-subtitle'>{L('loc_ui_longtext_organization_select_task')}</p>
      <select onChange={(option) => { setWork(option.target.value); }}> {work_options} </select>
      {firstOption}
      {secondOption}
      <div>
        <ButtonInline onClick={onCancel}>{L('loc_ui_button_common_cancel')}</ButtonInline>
      </div>
    </>;

    if (data) {
      let var1, var2;

      if (data.var1) {
        const { selectView, selectedVar } = VariableSelectView(game, data.var1, var1_index, setVar1);
        firstOption = selectView;
        var1 = selectedVar;
      }

      if (data.var2) {
        const { selectView, selectedVar } = VariableSelectView(game, data.var2, var2_index, setVar2);
        secondOption = selectView;
        var2 = selectedVar;
      }

      const pending = departmentRoot.createPending(work_key, department, var1, var2);
      const onAccept = () => {
        departmentRoot.addPending(department, pending, game);
        onDepartmentUpdated();
      }

      body = <SelectWorkView
        game={game}
        department={department}
        button_disabled={false}
        selectBody={<select onChange={(option) => { setWork(option.target.value); }}> {work_options} </select>}
        firstOption={firstOption}
        secondOption={secondOption}
        work={works.find((w) => w.work_key === work_key)}
        pending={pending}
        onAccept={onAccept}
        onCancel={onCancel}
      />
    }

    return <>
      <p className='flex-department-popup-title'>{L('loc_ui_title_organization_select_task')}</p>
      {body}
    </>;
  }
}

export function DepartmentSelectView(props) {
  const { game, work_key, var1, var2, onPending, onCancel } = props;
  const { departmentRoot, world } = game;
  const [department_index, setDepartment] = useState(0);
  const [var1_index, setVar1] = useState(0);
  const [var2_index, setVar2] = useState(0);

  if (work_key === '') {
    return <></>;
  }

  const departments = departmentRoot.departments.filter((d) => data_facilities.find((f) => f.key === d.key)?.tasks.find((t) => t.work_key === work_key))
    .filter((d) => !(d.head_id < 0));
  //부서장이 없는 부서는 업무 불가능

  let firstOption = <></>;
  let secondOption = <></>;

  if (departments.length === 0) {
    const temporary_department = departmentRoot.departments.find((d) => d.key === TEMPORAL_DEPARTMENT_KEY);
    if (temporary_department) {
      departments.push(temporary_department);
    }
  }

  if (departments.length > 0) {
    let var1_selected, var2_selected;

    if (departments.length <= department_index) {
      setDepartment(0);
    }
    const department = departments[department_index];
    const facility = data_facilities.find((f) => f.key === department.key);
    const task = facility?.tasks.find((t) => t.work_key === work_key);

    if (var1) {
      firstOption = getPendingVar1Text(work_key, var1);
      var1_selected = var1;
    }
    else if (task?.var1) {
      const { selectView, selectedVar } = VariableSelectView(game, task.var1, var1_index, setVar1);
      firstOption = selectView;
      var1_selected = selectedVar;
    }

    if (var2) {
      secondOption = getPendingVar1Text(work_key, var2);
      var2_selected = var2;
    }
    else if (task?.var2) {
      const { selectView, selectedVar } = VariableSelectView(game, task.var2, var2_index, setVar2);
      secondOption = selectView;
      var2_selected = selectedVar;
    }

    const pending = departmentRoot.createPending(work_key, department, var1_selected, var2_selected);
    const onAccept = () => {
      departmentRoot.addPending(department, pending, game);
      onPending();
    }

    return <SelectWorkView
      game={game}
      department={department}
      button_disabled={department ? false : true}
      selectBody={<select onChange={(option) => { setDepartment(option.target.selectedIndex); }}>
        {departments.map((d, i) => {
          return <option key={i} value={d}>{departmentName(d, world)}</option>
        })}
      </select>}
      firstOption={firstOption}
      secondOption={secondOption}
      work={data_work.find((w) => w.work_key === work_key)}
      pending={pending}
      onAccept={onAccept}
      onCancel={onCancel}
    />

  }
  else {
    return <div>수행 가능한 부서 없음</div>;
  }
}

function DepartmentLogView(props) {
  const { log, departmentRoot } = props;
  const { turn, pending, department_name, success, growths } = log;
  const { ability, work_key, difficulty, var1, var2, work_effect_ty, success_count, total_count } = pending;

  let growthTexts = [];
  for (const growth of growths) {
    const { staff_id, ability_ty, stat_prev, stat_new, staff_name } = growth;

    const stat_prev_visible = Math.floor(stat_prev * 10) / 10;
    const stat_new_visible = Math.floor(stat_new * 10) / 10;

    if (stat_prev_visible < stat_new_visible) {
      const staff = departmentRoot.staffs.find((s) => s.id === staff_id);
      const name = staff_name ?? (staff?.displayName);

      if (name) {
        const data_staffAbility = data_staffAbilities.find((d) => d.key === ability_ty);

        growthTexts.push(`${name} - ${data_staffAbility.name} ${stat_prev_visible} => ${stat_new_visible}`);
      }
    }
  }

  let resultText = '';
  if (work_effect_ty === 'on_finish') {
    resultText = success ? '성공' : '실패';
  }
  else {
    const success_rate = total_count > 0 ? success_count / total_count : 0;
    resultText = `완료(성공률:${(success_rate * 100).toFixed(2)}%)`;
  }

  const growthText = growthTexts.length > 0 ? ` / ${growthTexts.join(' / ')}` : '';

  const pendingText = getPendingText(work_key, var1, var2);
  return <div>{`${tickToDateStr(turn)} - ${department_name} / ${pendingText} / 역량:${Math.round(ability * 100) / 100} / 난이도:${Math.round(difficulty * 100) / 100} / ${getSuccessRateText(work_key)}:${(Math.min(ability / difficulty, 1) * 100).toFixed(2)}% - ${resultText}${growthText}`}</div>;
}

function createDepartmentStatCompareView(departmentRoot, game, prevDepartment, newDepartment, world) {
  const head = [
    <td key={'name'}>{departmentName(prevDepartment, world)}</td>,
  ];
  const prevStat_body = [
    <td key={'empty'}></td>,
  ];
  const newStat_body = [
    <td key={'empty'}></td >,
  ]

  for (const ability of prevDepartment.core_abilities) {
    const data_staffAbility = data_staffAbilities.find((d) => d.key === ability);

    head.push(<td key={ability} title={L('loc_data_string_staffability_desc_' + ability)}>{L('loc_data_string_staffability_name_' + ability)}</td>);

    const prevStat = Math.round(departmentRoot.departmentTotalStat(prevDepartment, ability) * 10) / 10;
    const newStat = Math.round(departmentRoot.departmentTotalStat(newDepartment, ability) * 10) / 10;
    const diff = newStat - prevStat;
    prevStat_body.push(<td key={ability}>{prevStat}</td>);
    if (diff > 0) {
      newStat_body.push(<td key={ability}>{`${newStat} `}<span color='#55FF55'>{`(+${diff.toFixed(1)})`}</span></td>);
    }
    else if (diff < 0) {
      newStat_body.push(<td key={ability}>{`${newStat} `}<span color='#FF5555'>{`(${diff.toFixed(1)})`}</span></td>);
    }
    else {
      newStat_body.push(<td key={ability}>{newStat}</td>)
    }
  }

  const facility = data_facilities.find(f => f.key === prevDepartment.key);

  const work_diffs = [];
  let main_work_diff_body = <></>;

  const workDiffText = (task, prev_pending, new_pending, work_name_suffix) => {
    const work_data = data_work.find((d) => d.work_key === task.work_key);
    const work_title = `${L(work_data.work_name)}${work_name_suffix}`;

    let success_rate_body = <></>;
    {
      let prev_success_rate = (Math.min(prev_pending.ability / prev_pending.difficulty, 1) * 100).toFixed(2);
      let new_success_rate = (Math.min(new_pending.ability / new_pending.difficulty, 1) * 100).toFixed(2);
      if (isNaN(prev_success_rate)) {
        prev_success_rate = 0;
      }
      if (isNaN(new_success_rate)) {
        new_success_rate = 0;
      }

      let success_rate_diff = <></>;
      const diff = (new_success_rate - prev_success_rate).toFixed(2);
      if (diff > 0) {
        success_rate_diff = <span color='#55AA55'>{L('loc_dynamic_string_organizatiom_rate_increase', { value: diff })}</span>
      }
      else if (diff < 0) {
        success_rate_diff = <span color='#AA5555'>{L('loc_dynamic_string_organizatiom_rate_decrease', { value: -diff })}</span>
      }

      success_rate_body = <div>{` - ${getSuccessRateText(task.work_key)} ${prev_success_rate}% => ${new_success_rate}%`}{success_rate_diff}</div>
    }

    const success_effect_desc = work_data ? L(work_data.finish_success_effect_desc) : '';

    let success_effect_body = <></>

    if (success_effect_desc !== '') {
      const prev_success_props = game.getDepartmentPendingSuccessProps(task.work_key, prevDepartment, prev_pending);
      const new_success_props = game.getDepartmentPendingSuccessProps(task.work_key, newDepartment, new_pending);

      let effect_compare = '';
      let effect_diff = <></>;

      switch (task.work_key) {
        case 'search_agent_tier':
        case 'search_agent_possible':
        case 'search_staff':
          {
            const prev_power = prev_success_props.power.toFixed(1);
            const new_power = new_success_props.power.toFixed(1);
            effect_compare = `${prev_power} => ${new_power}`;
            const diff = (new_power - prev_power).toFixed(1);
            if (diff > 0) {
              effect_diff = <span color='#55AA55'>{L('loc_dynamic_string_organizatiom_amount_increase', { value: diff })}</span>
            }
            else if (diff < 0) {
              effect_diff = <span color='#AA5555'>{L('loc_dynamic_string_organizatiom_amount_decrease', { value: -diff })}</span>
            }
          }
          break;
        case 'maintain_training':
          {
            const prev_amount = prev_success_props.amount.toFixed(2);
            const new_amount = new_success_props.amount.toFixed(2);
            effect_compare = `${prev_amount} => ${new_amount}`;
            const diff = (new_amount - prev_amount).toFixed(2);
            if (diff > 0) {
              effect_diff = <span color='#55AA55'>{L('loc_dynamic_string_organizatiom_amount_increase', { value: diff })}</span>
            }
            else if (diff < 0) {
              effect_diff = <span color='#AA5555'>{L('loc_dynamic_string_organizatiom_amount_decrease', { value: -diff })}</span>
            }
          }
          break;
        case 'recruit_agent':
        case 'discount_renewal_agent':
        case 'buy_item':
          {
            const prev_discount_percent = (prev_success_props.discount * 100).toFixed(2);
            const new_discount_percent = (new_success_props.discount * 100).toFixed(2);
            effect_compare = `${prev_discount_percent}% => ${new_discount_percent}% `;
            const diff = (new_discount_percent - prev_discount_percent).toFixed(2);
            if (diff > 0) {
              effect_diff = <span color='#55AA55'>{L('loc_dynamic_string_organizatiom_rate_increase', { value: diff })}</span>
            }
            else if (diff < 0) {
              effect_diff = <span color='#AA5555'>{L('loc_dynamic_string_organizatiom_rate_decrease', { value: -diff })}</span>
            }
          }
          break;
        case 'sell_item':
        case 'search_contract':
          {
            const prev_bonus_percent = (prev_success_props.bonus * 100).toFixed(2);
            const new_bonus_percent = (new_success_props.bonus * 100).toFixed(2);
            effect_compare = `${prev_bonus_percent}% => ${new_bonus_percent}% `;
            const diff = (new_bonus_percent - prev_bonus_percent).toFixed(2);
            if (diff > 0) {
              effect_diff = <span color='#55AA55'>{L('loc_dynamic_string_organizatiom_rate_increase', { value: diff })}</span>
            }
            else if (diff < 0) {
              effect_diff = <span color='#AA5555'>{L('loc_dynamic_string_organizatiom_rate_decrease', { value: -diff })}</span>
            }
          }
          break;
        default:
          return <></>;
      }
      success_effect_body = <div>{` - ${L(success_effect_desc)} ${effect_compare}`}{effect_diff}</div>
    }

    return <div>
      <div>{work_title}</div>
      {success_rate_body}
      {success_effect_body}
    </div>
  }

  for (const task of facility.tasks) {
    if (task.work_key === 'recruit_staff' || task.work_key === 'do_training') {
      continue;
    }

    const var1 = task.var1 && task.var1[0][0] !== '*' ? task.var1[task.var1.length - 1] : null;
    const var2 = task.var2 && task.var1[0][0] !== '*' ? task.var2[task.var2.length - 1] : null;

    const prev_pending = departmentRoot.createPending(task.work_key, prevDepartment, var1, var2);
    const new_pending = departmentRoot.createPending(task.work_key, newDepartment, var1, var2);

    switch (task.work_key) {
      case 'recruit_staff':
      case 'do_training':
        continue;
      case 'search_staff':
      case 'search_agent_possible':
      case 'source_gun_ty':
      case 'maintain_training':
        work_diffs.push(workDiffText(task, prev_pending, new_pending, ''));
        break;
      case 'source_gun_ty_tier':
        work_diffs.push(workDiffText(task, prev_pending, new_pending, var2 ? L('loc_dynamic_string_organization_work_item_tier', { value: var2 }) : ''));
        break;
      case 'source_granade_tier':
        work_diffs.push(workDiffText(task, prev_pending, new_pending, var2 ? L('loc_dynamic_string_organization_work_item_type', { value: var2 }) : ''));
        break;
      default:
        work_diffs.push(workDiffText(task, prev_pending, new_pending, var1 ? L('loc_dynamic_string_organization_work_item_tier', { value: var1 }) : ''));
        break;
    }
  }

  if (work_diffs.length > 0) {
    main_work_diff_body = <>
      <p className='flex-department-title'>{L('loc_ui_string_organization_task_efficiency_change')}</p>
      <div>{work_diffs}</div>
    </>
  }

  return <>
    <p className='flex-department-title'>{L('loc_ui_string_organization_department_ability_change')}</p>
    <div className='flex-department-preview'>
      <div className='flex-department-left'>
        <table className='flex-department-compare'>
          <tbody>
            <tr>{head}</tr>
            <tr>{prevStat_body}</tr>
          </tbody>
        </table>
      </div>
      <div className='flex-department-middle'> {`=>`}</div>
      <div className='flex-department-right'>
        <table className='flex-department-compare'>
          <tbody>
            <tr>{head}</tr>
            <tr>{newStat_body}</tr>
          </tbody>
        </table>
      </div>
    </div>
    {main_work_diff_body}
  </>;
}

export function WorldDepartmentWorkView(props) {
  const { department, pending } = props;

  const { work_key, ability, difficulty, var1, var2, ticks } = pending;

  const work = data_work.find(((d) => d.work_key === pending.work_key));

  const { days } = getWorkDuration(work_key, ability, difficulty, var1, var2);

  const dur = (days * TICK_PER_DAY).toFixed();
  const elapsed = dur - ticks;

  return <div className='flexoverlay-work'>
    <div className='flexoverlay-work-item-desc'>{L(department.name)}: {L(work.work_name)}</div>
    <ProgressBar width='95%' cur={elapsed} cap={dur} max={dur} date={true} classname='overlay-progress' />
  </div>;
}

const RESULT_DESCR = {
  'market_staff': 'loc_ui_longtext_notification_task_complete_market_staff',
  'new_staff': 'loc_ui_longtext_notification_task_complete_new_staff',
  'market': 'loc_ui_longtext_notification_task_complete_market',
  'department_market': 'loc_ui_longtext_notification_task_complete_market',
  'department_recruit': 'loc_ui_longtext_notification_task_complete_recruit',
  'intel': 'loc_ui_longtext_notification_task_complete_intel',
};

export const DepartmentResultView = (props) => {
  const { event_ty: ty } = props;
  let body = null;
  if (ty === 'market_staff' || ty === 'new_staff') {
    const { staff } = props;
    body = <>
      <p className="mission-warning-overlay-body">이름: {staff.name}</p>
      {ABILITY_KEYS.map((key) => {
        const stat = staffAbilityValue(staff, key);
        return <p className="mission-warning-overlay-body" key={key}>{data_staffAbilities.find((d) => d.key === key).name}: {staffStatBar(stat)}</p>;
      })}
    </>;
  } else if (ty === 'market' || ty === 'deaprtment_market') {
  } else if (ty === 'recruit_agent') {
    console.log(props);
  } else if (ty === 'intel') {
  }

  return <>
    <p className="mission-warning-overlay-body-important">{L('loc_ui_longtext_notification_task_complete') + ty}</p>
    <br />
    <p className="mission-warning-overlay-body">{L(RESULT_DESCR[ty])}</p>
    <br />
    {body}
  </>;
  ;
};


export class DepartmentView extends React.Component {
  constructor(props) {
    super(props);
    const rng = new Rng();

    const departmentRoot = props.departmentRoot ?? new DepartmentRoot(null);
    const game = props.game ?? null;

    const onGameUpdated = props.onGameUpdated ?? (() => { return null; });

    const showDepartmemberFullList = [];

    this.state = {
      rng,
      departmentRoot,
      game,
      onGameUpdated,
      dragStaff: null,
      prevStaff: null,
      targetStaff: null,
      targetDepartment: null,
      targetFunction: null,
      selectedDepartment: null,
      work_key: '',
      recruit_index: 0,
      showDepartmemberFullList,
      showStaffFullList: true,
      overlayType: null,
      staffFilter: null,

      tab_selected: 'summary',
    };
  }

  showDepartment(i) {
    const { showDepartmemberFullList } = this.state;
    while (i >= showDepartmemberFullList.length) {
      showDepartmemberFullList.push(true);
    }
    return showDepartmemberFullList[i];
  }

  toggleDepartment(i) {
    const { showDepartmemberFullList } = this.state;
    showDepartmemberFullList[i] = !this.showDepartment(i);

    this.setState({ showDepartmemberFullList });
  }

  buildStaffRow(staff, isBoss, isHighlighted, additionalButton, department, showSource) {
    const { displayName, wage, department_fid } = staff;
    const { departmentRoot, targetFunction } = this.state;
    const row = [];

    const departmentCur = departmentRoot.departments.find((d) => d.fid === department_fid);

    if (department === 'undefined') {
      department = departmentCur;
    }

    const highlightRow = (key) => {
      if (!department) {
        return true;
      }

      if (department.core_abilities.find((ability) => ability === key)) {
        return true;
      }
      if (targetFunction === 'setBoss' && key === 'manage') {
        return true;
      }
      if (isBoss && key === 'manage') {
        return true;
      }
      return false;
    };

    let nameCls = isHighlighted ? 'department-staffrow-highlighted' : '';
    let name = displayName;
    if (isBoss) {
      name += ' ★';
    }

    if (showSource) {
      const { world } = this.state.game;
      row.push(<td key='name' className='dt-long'>
        <span className={nameCls}>{name}</span>
        {(() => {
          if (staff.department_fid === -1) {
            return null;
          }
          return <>
            <br />
            <span className="flex-department-diff-warn">{staffRankText(departmentRoot, staff, world)}</span>
          </>;
        })()}
      </td>);
    } else {
      row.push(<td key='name' className='dt-long'><span className={nameCls}>{name}</span></td>);
    }

    for (const key of ABILITY_KEYS) {
      const stat = staffAbilityValue(staff, key);
      if (department) {
        let highlight = highlightRow(key);
        let stat0 = 0;
        let label = `${stat}`;

        if (highlight) {
          if (isBoss) {
            stat0 = stat;
          } else {
            stat0 = departmentStaffAbilityValue(departmentRoot, department, staff, key).toFixed(1);
            label = `${stat0}/${stat}`;
          }
        }

        row.push(<td key={key} className='dt-short'>{departmentStaffStatBar(stat0, stat, label)}</td>);
      }
      else {
        row.push(<td key={key} className='dt-short'>{staffStatBar(stat)}</td>);
      }
    }
    row.push(<td key='wage' className='dt-short'>{`$${wage}`}</td>);
    if (additionalButton) {
      row.push(<td key='setButton' className='dt-short'>{additionalButton}</td>);
    }

    return row;
  }

  buildRecruitStaffRow(staff, i, turn) {
    const { recruit_index, work_key, departmentRoot } = this.state;
    const { departments, recruit_listings } = departmentRoot;
    const { displayName, wage, expires_at } = staff;

    const row = [];

    row.push(<td key='name' className='dt-long'>{displayName}</td>);
    for (const key of ABILITY_KEYS) {
      row.push(<td key={key} className='dt-short'>{staffStatBar(staffAbilityValue(staff, key))}</td>);
    }
    row.push(<td key='wage' className='dt-short'>{`$${wage}`}</td>);

    const disabled = departments.map((d) => {
      return d.pendings.find((p) => p.work_key === 'recruit_staff' && p.var1 && p.var1.id === recruit_listings[i].id);
    }).find((p) => !!p);

    if (disabled) {
      row.push(<td key='recruit' className='dt-long'>{L('loc_ui_string_organization_staff_hiring') + L('loc_dynamic_string_common_remaining_days', { count: Math.ceil(Math.max(expires_at - turn, 0) / TICK_PER_DAY) })}</td>);
    } else {
      row.push(<td key='recruit' className='dt-long'>
        <span>{expires_at ? L('loc_dynamic_string_common_remaining_days', { count: Math.ceil(Math.max(expires_at - turn, 0) / TICK_PER_DAY) }) : ''}</span>
        <ButtonInline disabled={disabled}
          onClick={() => {
            if (i === recruit_index && work_key === 'recruit_staff') {
              this.setState({ work_key: '', recruit_index: 0, overlayType: null });
            }
            else {
              this.setState({ work_key: 'recruit_staff', recruit_index: i, overlayType: 'select_department' });
            }
          }}>{L('loc_ui_button_organization_staff_hire')}</ButtonInline></td>);
    }

    return row;
  }

  departmentActionView(department) {
    const { departmentRoot, game } = this.state;
    const { key } = department;

    const data_facility = data_facilities.find(f => f.key === key);

    const works_row = [];
    if (department.head_id < 0) {
      if (key === 'temp') {
        works_row.push(<span color='#FF5555'>{L('loc_ui_longtext_organization_info_temporary_department')}</span>);
      }
      else {
        works_row.push(<span color='#FF5555'>{L('loc_ui_longtext_organization_info_task_not_possible')}</span>);
      }
    }
    else {
      const main_pending_index = department.pendings.findIndex((p) => p.work_type === 'main');
      if (main_pending_index >= 0) {
        const main_pending = department.pendings[main_pending_index];
        const pendingText = getPendingText(main_pending.work_key, main_pending.var1, main_pending.var2);
        works_row.push(<>
          {L('loc_dynamic_string_organization_ongoing_task') + pendingText + L('loc_dynamic_string_organization_work_remaining_days', { count: Math.ceil(main_pending.ticks / TICK_PER_DAY) })}
          <ButtonInline onClick={() => {
            departmentRoot.removePending(department, main_pending_index, game);
            this.setState({ selectedDepartment: null });
          }}>{L('loc_ui_button_organization_abort_task')}</ButtonInline>
        </>);
      }
      else {
        works_row.push(<>
          <span className="flex-department-warn">{L('loc_ui_string_organization_info_no_task_ongoing')}</span>
          <ButtonInline onClick={() => this.setState({ selectedDepartment: department, overlayType: 'select_work' })}>{L('loc_ui_button_organization_assign_task')}</ButtonInline>
        </>);
      }
    }
    for (let i = 0; i < department.pendings.length; i++) {
      const pending = department.pendings[i];
      if (pending.work_type === 'main') {
        continue;
      }
      const pendingText = getPendingText(pending.work_key, pending.var1, pending.var2);
      works_row.push(<>
        {L('loc_dynamic_string_organization_ongoing_task_sub') + pendingText + L('loc_dynamic_string_organization_work_remaining_days', { count: Math.ceil(pending.ticks / TICK_PER_DAY) })}
        <ButtonInline onClick={() => {
          departmentRoot.removePending(department, i, game);
          this.setState({ selectedDepartment: null });
        }}>{L('loc_ui_button_organization_abort_task')}</ButtonInline>
      </>);
    }
    if (key !== 'temp') {
      works_row.push();
    }

    return <>
      {works_row.map((r, i) => <div key={i}>{r}</div>)}
    </>;
  }

  buildDepartmentRow(departmentRoot, department) {
    const { head_id, members_id, core_abilities } = department;

    const head = departmentRoot.staffs.find((s) => s.id === head_id);

    const row = [];

    row.push(<td key='name' className='dt-long'>부서 역량</td>);
    for (const key of ABILITY_KEYS) {
      if (core_abilities.includes(key)) {
        row.push(<td key={key} className='dt-short'>{departmentStatBar(departmentRoot.departmentTotalStat(department, key).toFixed(1))}</td>);
      }
      else {
        row.push(<td key={key} className='dt-short'>{''}</td>);
      }
    }

    let totalWage = 0;
    if (head) {
      totalWage += head.wage;
    }
    for (const id of members_id) {
      const staff = departmentRoot.staffs.find((s) => s.id === id);
      if (staff) {
        totalWage += staff.wage;
      }
    }
    row.push(<td key='wage' className='dt-short'>{`$${totalWage}`}</td>);

    return row;
  }

  buildEmptyStaffRow(text) {
    const row = [];
    row.push(<td key='text' width="100%" colSpan="7"><span className='department-emptyslot'>{text}</span></td>);

    return row;
  }

  buildDepartmentView(department) {
    const { departmentRoot, game, dragStaff } = this.state;
    const { staffs, departments } = departmentRoot;
    const i = departments.findIndex((d) => d === department);

    const rows = [];
    const setBoss = (department, prevStaff, staffFilter) => { this.setState({ targetStaff: dragStaff, prevStaff, targetDepartment: department ?? null, targetFunction: 'setBoss', overlayType: 'stat_diff', staffFilter }) };
    const setMember = (department, prevStaff, staffFilter) => { this.setState({ targetStaff: dragStaff, prevStaff, targetDepartment: department ?? null, targetFunction: 'setMember', overlayType: 'stat_diff', staffFilter }) };

    const isWorking = department && department.pendings.length > 0;
    const checkWorking = (staff) => {
      const department = departments.find((d) => d.fid === staff.department_fid);
      if (!department) {
        return true;
      }
      return department.pendings.length <= 0;
    }

    rows.push(<tr key={`department-${i}`}>{this.buildDepartmentRow(departmentRoot, department)}</tr>);

    if (department.key === TEMPORAL_DEPARTMENT_KEY) {
      if (department.pendings.length === 0) {
        return;
      }
    }
    else if (isWorking) {
      if (this.showDepartment(i)) {
        for (const row of this.departmentRowsWorking(department, i)) {
          rows.push(row);
        }
      }
    }
    else {

      if (this.showDepartment(i)) {
        if (department.head_id >= 0) {
          const staff = staffs.find((s) => s.id === department.head_id);
          const btn = <ButtonInline onClick={() => setBoss(department, staff, (s) => s.id !== staff.id && checkWorking(s))}>{L('loc_ui_button_organization_department_head_change')}</ButtonInline>;
          rows.push(<tr key={`head-${i}`}>{this.buildStaffRow(staff, true, false, btn, department)}</tr>);
        }
        else {
          rows.push(<tr key={'head-empty'}><td width="100%" colSpan="7">
            <span>
              {L('loc_ui_string_organization_info_no_department_head')}
              <ButtonInline onClick={() => setBoss(department, null, (s) => checkWorking(s))}>{L('loc_ui_button_organization_department_head_place')}</ButtonInline>
            </span>
          </td></tr>);
        }

        for (let j = 0; j < department.members_max; j++) {
          if (j < department.members_id.length) {
            const id = department.members_id[j];
            const staff = staffs.find((s) => s.id === id);
            const btn = <ButtonInline onClick={() => setMember(department, staff, (s) => s.id !== staff.id && checkWorking(s))}>{L('loc_ui_button_organization_staff_change')}</ButtonInline>;
            rows.push(<tr key={`member-${i}-${id}`}>{this.buildStaffRow(staff, false, false, btn, department)}</tr>);
          }
          else {
            rows.push(<tr key={`members-${i}-empty`}><td width="100%" colSpan="7">
              <span>
                {L('loc_ui_button_organization_staff_change', { value: j + 1 })}
                <ButtonInline onClick={() => setMember(department, null, (s) => checkWorking(s))}>{L('loc_ui_button_organization_staff_place')}</ButtonInline>
              </span>
            </td></tr>);
          }
        }
      }
    }

    const departmentStaffButton = (staff, isHead) => {
      if (staff) {
        if (isHead) {
          return <ButtonInline onClick={() => setBoss(department, staff, (s) => s.id !== staff.id && checkWorking(s))}>{L('loc_ui_button_organization_department_head_change')}</ButtonInline>;
        }
        else {
          return <ButtonInline onClick={() => setMember(department, staff, (s) => s.id !== staff.id && checkWorking(s))}>{L('loc_ui_button_organization_staff_change')}</ButtonInline>;
        }
      }
      else {
        if (isHead) {
          return <ButtonInline onClick={() => setBoss(department, null, (s) => checkWorking(s))}>{L('loc_ui_button_organization_department_head_place')}</ButtonInline>;
        }
        else {
          return <ButtonInline onClick={() => setMember(department, null, (s) => checkWorking(s))}>{L('loc_ui_button_organization_staff_place')}</ButtonInline>;
        }
      }
    }

    const { key } = department;
    let taskview = null;
    if (key !== 'temp') {
      const data_facility = data_facilities.find(f => f.key === key);
      taskview = <span>{L('loc_ui_string_organization_tasks')}{data_facility.tasks.map(({ work_key }, i) => {
        const { work_desc, work_name } = data_work.find(d => d.work_key === work_key);
        const comma = (i === 0) ? '' : ', ';
        return <span key={work_key} title={L(work_desc)}>{comma}{L(work_name)}</span>;
      })}</span>;
    }

    const actionview = this.departmentActionView(department, i);

    let body = <>
      {actionview}
      {taskview}
      {this.buildDepartmentTable(department, i, departmentStaffButton, (_) => false)}
    </>;

    return <>
      <Panel title='부서 인원'>
        {body}
      </Panel>
    </>;
  }

  departmentRowsWorking(department, i) {
    const { departmentRoot } = this.state;
    const { staffs } = departmentRoot;

    const rows = [];
    if (department.head_id >= 0) {
      const staff = staffs.find((s) => s.id === department.head_id);
      rows.push(<tr key={`head-${i}`}>{this.buildStaffRow(staff, true, false, null, department)}</tr>);
    }
    else {
      rows.push(<tr key='head-empty'>
        <td>{this.buildEmptyStaffRow(L('loc_ui_string_organization_info_no_department_head'))}</td>
      </tr>);
    }
    for (let j = 0; j < department.members_max; j++) {
      if (j < department.members_id.length) {
        const id = department.members_id[j];
        const staff = staffs.find((s) => s.id === id);
        rows.push(<tr key={`member-${j}-${id}`}>{this.buildStaffRow(staff, false, false, null, department)}</tr>);
      }
      else {
        rows.push(<tr key={`members-${j}-empty`}>{this.buildEmptyStaffRow(L('loc_dynamic_longtext_organization_info_cannot_place_staff', { value: j + 1 }))}</tr>)
      }
    }
    return rows;
  }

  buildDepartmentTable(department, i, staffButton, highlightStaff) {
    const { departmentRoot } = this.state;
    const { staffs } = departmentRoot;

    const rows = [];
    const isWorking = department && department.pendings.length > 0;

    if (department.key === TEMPORAL_DEPARTMENT_KEY) {
      if (department.pendings.length === 0) {
        return;
      }
    }
    else if (isWorking) {
      if (this.showDepartment(i)) {
        for (const row of this.departmentRowsWorking(department, i)) {
          rows.push(row);
        }
      }
    }
    else {
      if (this.showDepartment(i)) {
        if (department.head_id >= 0) {
          const staff = staffs.find((s) => s.id === department.head_id);
          rows.push(<tr key={`head-${i}`}>{this.buildStaffRow(staff, true, highlightStaff(staff), staffButton(staff, true), department)}</tr>);
        }
        else {
          rows.push(<tr key={'head-empty'}><td width="100%" colSpan="8">
            <span>
              {L('loc_ui_string_organization_info_no_department_head')}
              {staffButton(null, true)}
            </span>
          </td></tr>);
        }

        for (let j = 0; j < department.members_max; j++) {
          if (j < department.members_id.length) {
            const id = department.members_id[j];
            const staff = staffs.find((s) => s.id === id);
            rows.push(<tr key={`member-${j}-${id}`}>{this.buildStaffRow(staff, false, highlightStaff(staff), staffButton(staff, false), department)}</tr>);
          }
          else {
            rows.push(<tr key={`members-${j}-empty`}><td width="100%" colSpan="8">
              <span>
                {L('loc_dynamic_string_organization_info_no_staff', { value: j + 1 })}
                {staffButton(null, false)}
              </span>
            </td></tr>);
          }
        }
      }
    }

    return <table>
      <tbody>
        <tr>
          <td className='dt-long'>{L('loc_ui_string_organization_staff_name')}</td>
          {data_staffAbilities.map(({ key }, i) => {
            let className = 'dt-short disabled';
            if (department.core_abilities.find((ability) => ability === key) || key === 'manage') {
              className = 'dt-short enabled';
            }

            return <td key={i} className={className} title={L('loc_data_longtext_staffability_desc_' + key)}
              onClick={() => this.sortMembers(staffs, key)}>
              {L('loc_data_string_staffability_name_' + key)}</td>;
          })}
          <td className='dt-short'>{L('loc_ui_string_organization_staff_wage')}</td>
          <td className='dt-short'></td>
        </tr>
        {rows}
      </tbody>
    </table>
  }

  buildStaffsView(department, staffFilter, additionalButtonFunction, showSource) {
    const { departmentRoot, showStaffFullList, targetFunction } = this.state;
    const { staffs } = departmentRoot;

    const rows = [];

    const remainStaffs = staffs.filter(staffFilter);
    if (showStaffFullList) {
      for (const staff of remainStaffs) {
        const isBoss = targetFunction === 'setBoss';
        rows.push(<tr key={`member-none-${staff.id}`}>
          {this.buildStaffRow(staff, isBoss, false, additionalButtonFunction(staff), department, showSource)}
        </tr>);
      }
    }

    return <table>
      <tbody>
        <tr>
          <td className='dt-long'>{L('loc_ui_string_organization_staff_name')}</td>
          {data_staffAbilities.map((data, i) =>
            <td key={i} className='dt-short' title={L('loc_data_longtext_staffability_desc_' + data.key)} onClick={() => this.sortMembers(staffs, data.key)}>{L('loc_data_string_staffability_name_' + data.key)}</td>)}
          <td className='dt-short'>{L('loc_ui_string_organization_staff_wage')}</td>
          <td className='dt-short'></td>
        </tr>
        {rows}
      </tbody>
    </table>;
  }

  buildDepartmentStatDiffView() {
    const { departmentRoot, targetStaff, prevStaff, targetDepartment, targetFunction, game, onGameUpdated } = this.state;
    const { world } = game;
    const { departments } = departmentRoot;


    const departmentStaffButton = (staff, _) => {
      if (staff && staff.id === prevStaff?.id) {
        return <ButtonInline className="department-row-button" onClick={() => this.setState({ targetFunction: 'remove', targetStaff: prevStaff })}>보직 해제</ButtonInline>;
      }
      else {
        return null;
      }
    }
    const targetDepartmentTable = targetDepartment ? <>
      <p className="flex-department-subtitle">{L('loc_dynamic_title_organization_department_name', { value: departmentName(targetDepartment, world) })}</p>
      {this.buildDepartmentTable(targetDepartment, departments.findIndex((d) => d.fid === targetDepartment.fid), departmentStaffButton, (staff) => prevStaff && prevStaff.id === staff?.id)}
    </> : null;

    const checkWorking = (staff) => {
      const department = departments.find((d) => d.fid === staff.department_fid);
      if (!department) {
        return true;
      }
      return department.pendings.length <= 0;
    }
    const checkDepartment = (staff) => {
      if (targetFunction !== 'setMember' || !targetDepartment) {
        return true;
      }
      return staff?.department_fid !== targetDepartment.fid || staff?.id === targetDepartment.head_id;
    }
    const selectableStaffFilter = (staff) => staff.id !== prevStaff?.id && checkWorking(staff) && checkDepartment(staff);

    const selectStaffButton = (staff) => {
      return <ButtonInline className="department-row-button"
        onClick={() => {
          if (targetFunction === 'remove') {
            this.setState({ targetStaff: staff, targetFunction: prevStaff.id === targetDepartment.head_id ? 'setBoss' : 'setMember' });
          }
          else {
            this.setState({ targetStaff: staff });
          }
        }
        }>{L('loc_ui_button_common_select')}</ButtonInline>;
    };

    if (!targetStaff) {
      return <div className='overlay-flex-departmentStatDiff'>
        {targetDepartmentTable}
        <br />
        <div>{this.buildStaffsView(targetDepartment, selectableStaffFilter, selectStaffButton, true)}</div>
        <br />
        <div className="overlay-flex-btngroup">
          <ButtonInline onClick={() => this.setState({ overlayType: null })}>취소</ButtonInline>
        </div>
      </div>
    }

    let head = <></>;
    let onClick = null;
    let prevDepartmentView = <></>;
    let newDepartmentView = <></>;

    switch (targetFunction) {
      case 'setBoss':
        if (!targetDepartment) {
          return <></>;
        }

        if (targetStaff.department_fid !== -1 && targetStaff.department_fid !== targetDepartment.fid) {
          const prevDepartment = departmentRoot.departments.find((d) => d.fid === targetStaff.department_fid);
          const newDepartment = { ...prevDepartment };
          newDepartment.members_id = prevDepartment.members_id.slice();

          if (prevDepartment.head_id === targetStaff.id) {
            newDepartment.head_id = -1;
          }
          else {
            const index = prevDepartment.members_id.indexOf(targetStaff.id);
            if (index >= 0) {
              newDepartment.members_id.splice(index, 1);
            }
          }

          prevDepartmentView = createDepartmentStatCompareView(departmentRoot, game, prevDepartment, newDepartment, world);
        }
        {
          const newHeadText = L('loc_dynamic_string_organization_place_head_preview', { value: targetStaff.displayName }) + `(${staffRankText(departmentRoot, targetStaff, world)} => ${departmentName(targetDepartment, world)})`;
          const prevHead = departmentRoot.staffs.find((s) => s.id === targetDepartment.head_id);

          const newDepartment = { ...targetDepartment };
          newDepartment.head_id = targetStaff.id;
          newDepartment.members_id = targetDepartment.members_id.slice();

          const idx = newDepartment.members_id.findIndex((id) => id === targetStaff.id);
          if (idx >= 0) {
            newDepartment.members_id.splice(idx, 1);
          }

          if (!prevHead) {
            head = <p>{newHeadText}</p>;

            onClick = () => {
              departmentRoot.setStaffToDepartmentHead(targetStaff.id, targetDepartment.fid);
              game.triggerQuest('allocate_department_chief', targetDepartment.key);
              game.updateQuest('allocate_department_chief_persistent');
              game.updateQuest('allocate_department_staff_persistent');
              onGameUpdated();
              this.setState({ targetStaff: null, targetDepartment: null, targetFunction: null, overlayType: null });
            }
          }
          else {
            let prevHeadText;
            if (targetDepartment.members_id.length >= targetDepartment.members_max) {
              prevHeadText = L('loc_dynamic_string_organization_place_head_previous_release_preview', { value: prevHead.displayName }) + `(${staffRankText(departmentRoot, prevHead, world)} => ` + L('loc_ui_string_organization_department_reserve') + ')';

              onClick = () => {
                departmentRoot.removeStaffFromDepartment(targetDepartment.head_id, targetDepartment.fid);
                departmentRoot.setStaffToDepartmentHead(targetStaff.id, targetDepartment.fid);
                game.triggerQuest('allocate_department_chief', targetDepartment.key);
                game.updateQuest('allocate_department_chief_persistent');
                game.updateQuest('allocate_department_staff_persistent');
                onGameUpdated();
                this.setState({ targetStaff: null, targetDepartment: null, targetFunction: null, overlayType: null });
              }
            }
            else {
              newDepartment.members_id.push(prevHead.id);

              prevHeadText = L('loc_dynamic_string_organization_place_head_previous_staff_preview', { value: prevHead.displayName }) + `(${staffRankText(departmentRoot, prevHead, world)} => ${departmentName(targetDepartment, world)})`;

              onClick = () => {
                departmentRoot.setStaffToDepartmentMember(targetDepartment.head_id, targetDepartment.fid);
                departmentRoot.setStaffToDepartmentHead(targetStaff.id, targetDepartment.fid);
                game.triggerQuest('allocate_department_chief', targetDepartment.key);
                game.triggerQuest('allocate_department_staff', targetDepartment.key);
                game.updateQuest('allocate_department_chief_persistent');
                game.updateQuest('allocate_department_staff_persistent');
                onGameUpdated();
                this.setState({ targetStaff: null, targetDepartment: null, targetFunction: null, overlayType: null });
              }
            }
            head = <>
              <div>{newHeadText}</div>
              <div>{prevHeadText}</div>
            </>;
          }

          newDepartmentView = createDepartmentStatCompareView(departmentRoot, game, targetDepartment, newDepartment, world);
        }
        break;
      case 'setMember':
        if (!targetDepartment) {
          return <></>;
        }

        if (targetStaff.department_fid !== -1 && targetStaff.department_fid !== targetDepartment.fid) {
          const prevDepartment = departmentRoot.departments.find((d) => d.fid === targetStaff.department_fid);
          const newDepartment = { ...prevDepartment };
          newDepartment.members_id = prevDepartment.members_id.slice();

          if (prevDepartment.head_id === targetStaff.id) {
            newDepartment.head_id = -1;
          }
          else {
            const index = prevDepartment.members_id.indexOf(targetStaff.id);
            if (index >= 0) {
              newDepartment.members_id.splice(index, 1);
            }
          }

          prevDepartmentView = createDepartmentStatCompareView(departmentRoot, game, prevDepartment, newDepartment, world);
        }
        {
          const newMemberText = L('loc_dynamic_string_organization_place_staff_preview', { value: targetStaff.displayName }) + `(${staffRankText(departmentRoot, targetStaff, world)} => ${departmentName(targetDepartment, world)})`;
          head = <div>{newMemberText}</div>;

          const newDepartment = { ...targetDepartment };
          newDepartment.members_id = targetDepartment.members_id.slice();

          if (prevStaff) {
            const prevMemberText = L('loc_dynamic_string_organization_place_staff_previous_release_preview', { value: prevStaff.displayName }) + `${staffRankText(departmentRoot, prevStaff, world)} => ` + L('loc_ui_string_organization_department_reserve') + `)`;
            const prevMemberIndex = newDepartment.members_id.findIndex((id) => id === prevStaff.id);
            newDepartment.members_id.splice(prevMemberIndex, 1);

            onClick = () => {
              departmentRoot.removeStaffFromDepartment(prevStaff.id, targetDepartment.fid);
              departmentRoot.setStaffToDepartmentMember(targetStaff.id, targetDepartment.fid);
              game.triggerQuest('allocate_department_staff', targetDepartment.key);
              game.updateQuest('allocate_department_chief_persistent');
              game.updateQuest('allocate_department_staff_persistent');
              onGameUpdated();
              this.setState({ targetStaff: null, targetDepartment: null, targetFunction: null, overlayType: null });
            }

            head = <>
              <div>{newMemberText}</div>
              <div>{prevMemberText}</div>
            </>;
          }
          else {
            onClick = () => {
              departmentRoot.setStaffToDepartmentMember(targetStaff.id, targetDepartment.fid);
              game.triggerQuest('allocate_department_staff', targetDepartment.key);
              game.updateQuest('allocate_department_chief_persistent');
              game.updateQuest('allocate_department_staff_persistent');
              onGameUpdated();
              this.setState({ targetStaff: null, targetDepartment: null, targetFunction: null, overlayType: null });
            }

            head = <div>{newMemberText}</div>;
          }

          newDepartment.members_id.push(targetStaff.id);
          if (targetDepartment.head_id === targetStaff.id) {
            newDepartment.head_id = -1;
          }

          newDepartmentView = createDepartmentStatCompareView(departmentRoot, game, targetDepartment, newDepartment, world);
        }
        break;
      case 'remove':
        if (!targetStaff || targetStaff.department_fid === -1) {
          return <></>;
        }

        const targetMemberText = L('loc_dynamic_string_organization_release_preview', { value: targetStaff.displayName }) + `${staffRankText(departmentRoot, targetStaff, world)} => ` + L('loc_ui_string_organization_department_reserve') + `)`;

        const prevDepartment = departmentRoot.departments.find((d) => d.fid === targetStaff.department_fid);
        const newDepartment = { ...prevDepartment };
        newDepartment.members_id = prevDepartment.members_id.slice();

        if (prevDepartment.head_id === targetStaff.id) {
          newDepartment.head_id = -1;
        }
        else {
          const index = prevDepartment.members_id.indexOf(targetStaff.id);
          if (index >= 0) {
            newDepartment.members_id.splice(index, 1);
          }
        }

        newDepartmentView = createDepartmentStatCompareView(departmentRoot, game, prevDepartment, newDepartment, world);

        head = <>
          <div>{targetMemberText}</div>
        </>;
        onClick = () => {
          departmentRoot.removeStaffFromDepartment(targetStaff.id, targetStaff.department_fid);
          game.updateQuest('allocate_department_chief_persistent');
          game.updateQuest('allocate_department_staff_persistent');
          onGameUpdated();
          this.setState({ targetStaff: null, targetDepartment: null, targetFunction: null, overlayType: null });
        }
        break;
      case '':
      default:
        break;
    }

    const body = <>
      <div>{prevDepartmentView}</div>
      <br />
      <div>{newDepartmentView}</div>
    </>;

    return <div className='overlay-flex-departmentStatDiff'>
      {targetDepartmentTable}
      <br />
      <div>{this.buildStaffsView(targetDepartment, selectableStaffFilter, selectStaffButton, true)}</div>
      <br />
      <div className="flex-department-title">{head}</div>
      <div>{body}</div>
      <br />
      <div className="overlay-flex-btngroup">
        <ButtonInline onClick={onClick}>{L('loc_ui_button_organization_apply_change')}</ButtonInline>
        <ButtonInline onClick={() => this.setState({ overlayType: null })}>{L('loc_ui_button_common_cancel')}</ButtonInline>
      </div>
    </div>
  }

  buildRecruitView() {
    const { departmentRoot, game } = this.state;
    const { recruit_listings } = departmentRoot;
    const { turn } = game;

    return <div className="box">
      <p className='flex-department-subtitle'>{L('loc_ui_string_organization_staff_candidates')}</p>
      <table>
        <tbody>
          <tr>
            <td className='dt-long'>{L('loc_ui_string_organization_staff_name')}</td>
            {data_staffAbilities.map((data, i) =>
              <td key={i} className='dt-short' title={L('loc_data_longtext_staffability_desc_' + data.key)} onClick={() => this.sortMembers(recruit_listings, data.key)}>{L('loc_data_string_staffability_name_' + data.key)}</td>)}
            <td className='dt-short'>{L('loc_ui_string_organization_staff_wage')}</td>
            <td className='dt-long'>{L('loc_ui_string_organization_staff_action')}</td>
          </tr>

          {recruit_listings.map((staff, i) => <tr key={i}>
            {this.buildRecruitStaffRow(staff, i, turn)}
          </tr>)}
        </tbody>
      </table>
    </div>;
  }

  sortMembers(list, key) {
    const { departmentRoot } = this.state;

    const sortFunction = (a, b) => {
      if (a.abilities[key] < b.abilities[key]) {
        return 1;
      }
      else if (a.abilities[key] > b.abilities[key]) {
        return -1;
      }
      else {
        return 0;
      }
    }

    list.sort(sortFunction);
    this.setState(departmentRoot);
  }

  onDepartmentUpdated() {
    const { departmentRoot } = this.state;
    this.setState({ departmentRoot, selectedDepartment: null });
  }

  renderOverlay() {
    const { departmentRoot, game, work_key, recruit_index, overlayType, onGameUpdated } = this.state;
    const { recruit_listings } = departmentRoot;
    const { selectedDepartment } = this.state;

    if (overlayType === null) {
      return null;
    }

    let content = null;

    switch (overlayType) {
      case 'select_department':
        content = <DepartmentSelectView game={game} work_key={work_key} var1={recruit_listings[recruit_index]}
          onPending={() => { this.setState({ work_key: '', overlayType: null }); onGameUpdated(); }}
          onCancel={() => this.setState({ overlayType: null })} />;
        break;
      case 'select_work':
        content = <DepartmentWorkView departmentRoot={departmentRoot} game={game} department={selectedDepartment}
          onDepartmentUpdated={() => { this.onDepartmentUpdated(); this.setState({ overlayType: null }); onGameUpdated(); }}
          onCancel={() => this.setState({ overlayType: null })} />;
        break;
      case 'stat_diff':
        content = this.buildDepartmentStatDiffView();
        break;
      default:
        return null;
    }

    return <div className="department-overlay-root overlay-root overlay-root-shrink">
      <div className="overlay-flex">
        {content}
      </div>
    </div>;
  }

  buildDescripton(key) {
    const rows = [];

    switch (key) {
      case ('base'):
        rows.push(<p key="base-1">{L('loc_ui_longtext_organization_desc_base-1')}</p>);
        rows.push(<p key="base-2">{L('loc_ui_longtext_organization_desc_base-2')}</p>);
        rows.push(<p key="base-3">{L('loc_ui_longtext_organization_desc_base-3')}</p>);
        rows.push(<p key="base-4">{L('loc_ui_longtext_organization_desc_base-4')}</p>);
        break;
      case ('recruit'):
        rows.push(<p key="recruit-1">{L('loc_ui_longtext_organization_desc_recruit-1')}</p>);
        rows.push(<p key="recruit-2">{L('loc_ui_longtext_organization_desc_recruit-2')}</p>);
        rows.push(<p key="recruit-3">{L('loc_ui_longtext_organization_desc_recruit-3')}</p>);
        rows.push(<p key="recruit-4">{L('loc_ui_longtext_organization_desc_recruit-4')}</p>);
        break;
      case ('market'):
        rows.push(<p key="market-1">{L('loc_ui_longtext_organization_desc_market-1')}</p>);
        rows.push(<p key="market-2">{L('loc_ui_longtext_organization_desc_market-2')}</p>);
        rows.push(<p key="market-3">{L('loc_ui_longtext_organization_desc_market-3')}</p>);
        rows.push(<p key="market-4">{L('loc_ui_longtext_organization_desc_market-4')}</p>);
        break;
      case ('training'):
        rows.push(<p key="training-1">{L('loc_ui_longtext_organization_desc_training-1')}</p>);
        rows.push(<p key="training-2">{L('loc_ui_longtext_organization_desc_training-2')}</p>);
        rows.push(<p key="training-3">{L('loc_ui_longtext_organization_desc_training-3')}</p>);
        break;
      case ('summary'):
      case ('log'):
      default:
        return;
    }

    return <div className="box" key="descr">
      {rows}
    </div>;
  }

  render() {
    const { game, departmentRoot, tab_selected } = this.state;

    const nonDepartmentFilter = (s) => s.department_fid < 0;

    const tabs = [
      { key: 'summary', locale: 'loc_ui_title_organization_tab_summary', },
      { key: 'base', locale: 'loc_ui_title_organization_tab_base', },
      { key: 'recruit', locale: 'loc_ui_title_organization_tab_recruit', },
      { key: 'market', locale: 'loc_ui_title_organization_tab_market', },
      { key: 'training', locale: 'loc_ui_title_organization_tab_training', },
      { key: 'log', locale: 'loc_ui_string_organization_task_history', },
    ];

    let views = [];
    if (['base', 'recruit', 'market', 'training'].includes(tab_selected)) {
      views.push(this.buildDescripton(tab_selected));
    }
    const department = departmentRoot.departments.find((d) => d.ty1 === tab_selected);
    if (!department) {
      if (!['summary', 'log'].includes(tab_selected)) {
        views.push(<div className="box" key="department-empty">
          {L('loc_ui_longtext_organization_no_department')}
        </div>);
      }
    } else {
      views.push(<div className="box" key="department">
        {this.buildDepartmentView(department)}
      </div>);
    }

    //     const nonDepartmentFilter = (s) => s.department_fid < 0;
    //     const fireStaffButton = (staff) => <ButtonInline className='department-row-button' onClick={() => {
    //       departmentRoot.fireStaff(staff.id);
    //       this.setState({ departmentRoot, prevStaff: null, targetStaff: null, targetDepartment: null, targetFunction: null, overlayType: null, dragStaff: null });
    //     }}>{L('loc_ui_button_organization_staff_dismiss')}</ButtonInline>;

    //     return <>
    //       <div className='department-root fh1-panel-stack'>
    //         <Panel title={L('loc_ui_string_organization_list_department')}>
    //           {views}
    //         </Panel>
    //         <Panel title={L('loc_ui_string_organization_staffs_available')}>
    //           {this.buildStaffsView(null, nonDepartmentFilter, fireStaffButton)}
    //         </Panel>
    //         <Panel title={L('loc_ui_string_organization_staff_candidates')}>
    //           {this.buildRecruitView()}
    //         </Panel>
    //         <Panel title={L('loc_ui_string_organization_task_history')}>
    //           <div className='flex-department-logs'>{departmentRoot.logs.map((log, i) => <DepartmentLogView key={i} log={log} departmentRoot={departmentRoot} />)}</div>
    //         </Panel>
    if (tab_selected === 'summary') {
      const {
        turn,
        cashbook,
      } = game;

      const objective = questObjective(game);
      if (objective) {
        views.push(<Panel title='목표' key="objective">
          {objective}
        </Panel>);
      }

      const month = tickToMonth(turn);
      views.push(<Panel title={L('loc_ui_title_organization_cashbook')} key="cashbook">
        <div className='department-cashbook'>
          <CashbookView turn={month} cashbook={cashbook} />
        </div>
      </Panel>);
    } else if (department && tab_selected === 'base') {
      const fireStaffButton = (staff) => <ButtonInline className='department-row-button' onClick={() => {
        departmentRoot.fireStaff(staff.id);
        this.setState({ departmentRoot, prevStaff: null, targetStaff: null, targetDepartment: null, targetFunction: null, overlayType: null, dragStaff: null });
      }}>{L('loc_ui_button_organization_staff_dismiss')}</ButtonInline>;

      views.push(
        <Panel title={L('loc_ui_string_organization_staffs_available')} key="avail">
          {this.buildStaffsView(null, nonDepartmentFilter, fireStaffButton)}
        </Panel>
      );

      views.push(
        <Panel title={L('loc_ui_string_organization_staff_candidates')} key="candidates">
          {this.buildRecruitView()}
        </Panel>
      );
    } else if (tab_selected === 'recruit') {
    } else if (tab_selected === 'market') {
    } else if (tab_selected === 'training') {
    } else if (tab_selected === 'log') {
      views.push(
        <Panel title={L('loc_ui_string_organization_task_history')} key="history">
          <div key="log" className='flex-department-logs'>
            {departmentRoot.logs.map((log, i) => <DepartmentLogView key={i} log={log} departmentRoot={departmentRoot} />)}
          </div>
        </Panel>
      );
    }

    if (views.length <= 0) {
      views.push(<div key="empty" className="box">{L('loc_ui_longtext_organization_no_department')}</div>);
    }

    return <>
      <div className='department-root fh1-panel-stack'>
        <Tabs className='fh1-style2' items={tabs} selected={tab_selected} onChange={(tab_selected) => {
          this.setState({ tab_selected });
        }} />

        {views}
      </div>
      {this.renderOverlay()}
    </>;
  }
}
