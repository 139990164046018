// downloaded data!
export const data = [
  {
    "preset": "alley1_short",
    "threats_min": "16",
    "threats_max": "999",
    "resource_multiplier": "1.1",
    "conflict_tys": "suburb,remote",
    "weight": "1"
  },
  {
    "preset": "border_checkpoint_short",
    "threats_min": "13",
    "threats_max": "999",
    "resource_multiplier": "1.05",
    "conflict_tys": "border",
    "weight": "1"
  },
  {
    "preset": "construction_010",
    "threats_min": "1",
    "threats_max": "8",
    "resource_multiplier": "1",
    "conflict_tys": "center,suburb",
    "weight": "0.3"
  },
  {
    "preset": "construction_011",
    "threats_min": "1",
    "threats_max": "8",
    "resource_multiplier": "1",
    "conflict_tys": "center,suburb",
    "weight": "0.3"
  },
  {
    "preset": "construction_020",
    "threats_min": "1",
    "threats_max": "8",
    "resource_multiplier": "1",
    "conflict_tys": "center,suburb",
    "weight": "0.3"
  },
  {
    "preset": "construction_021",
    "threats_min": "8",
    "threats_max": "15",
    "resource_multiplier": "1",
    "conflict_tys": "center,suburb",
    "weight": "0.3"
  },
  {
    "preset": "construction_030",
    "threats_min": "1",
    "threats_max": "8",
    "resource_multiplier": "1",
    "conflict_tys": "center,suburb",
    "weight": "0.3"
  },
  {
    "preset": "construction_031",
    "threats_min": "8",
    "threats_max": "15",
    "resource_multiplier": "1",
    "conflict_tys": "center,suburb",
    "weight": "0.3"
  },
  {
    "preset": "construction_210",
    "threats_min": "15",
    "threats_max": "999",
    "resource_multiplier": "1",
    "conflict_tys": "center,suburb",
    "weight": "0.3"
  },
  {
    "preset": "construction_220",
    "threats_min": "15",
    "threats_max": "999",
    "resource_multiplier": "1",
    "conflict_tys": "center,suburb",
    "weight": "0.3"
  },
  {
    "preset": "construction_230",
    "threats_min": "15",
    "threats_max": "999",
    "resource_multiplier": "1",
    "conflict_tys": "center,suburb",
    "weight": "0.3"
  },
  {
    "preset": "embassy_short",
    "threats_min": "1",
    "threats_max": "11",
    "resource_multiplier": "1",
    "conflict_tys": "center",
    "weight": "1"
  },
  {
    "preset": "exhibition_hall_short",
    "threats_min": "16",
    "threats_max": "999",
    "resource_multiplier": "1.05",
    "conflict_tys": "center",
    "weight": "1"
  },
  {
    "preset": "farm_010",
    "threats_min": "1",
    "threats_max": "8",
    "resource_multiplier": "1",
    "conflict_tys": "center,suburb",
    "weight": "0.3"
  },
  {
    "preset": "farm_011",
    "threats_min": "1",
    "threats_max": "8",
    "resource_multiplier": "1",
    "conflict_tys": "center,suburb",
    "weight": "0.3"
  },
  {
    "preset": "farm_012",
    "threats_min": "1",
    "threats_max": "8",
    "resource_multiplier": "1",
    "conflict_tys": "center,suburb",
    "weight": "0.3"
  },
  {
    "preset": "farm_020",
    "threats_min": "1",
    "threats_max": "8",
    "resource_multiplier": "1",
    "conflict_tys": "center,suburb",
    "weight": "0.3"
  },
  {
    "preset": "farm_021",
    "threats_min": "1",
    "threats_max": "8",
    "resource_multiplier": "1",
    "conflict_tys": "center,suburb",
    "weight": "0.3"
  },
  {
    "preset": "farm_022",
    "threats_min": "1",
    "threats_max": "8",
    "resource_multiplier": "1",
    "conflict_tys": "center,suburb",
    "weight": "0.3"
  },
  {
    "preset": "farm_030",
    "threats_min": "1",
    "threats_max": "8",
    "resource_multiplier": "1",
    "conflict_tys": "center,suburb",
    "weight": "0.3"
  },
  {
    "preset": "farm_031",
    "threats_min": "1",
    "threats_max": "8",
    "resource_multiplier": "1",
    "conflict_tys": "center,suburb",
    "weight": "0.3"
  },
  {
    "preset": "farm_032",
    "threats_min": "1",
    "threats_max": "8",
    "resource_multiplier": "1",
    "conflict_tys": "center,suburb",
    "weight": "0.3"
  },
  {
    "preset": "farm_110",
    "threats_min": "8",
    "threats_max": "15",
    "resource_multiplier": "1",
    "conflict_tys": "center,suburb",
    "weight": "0.3"
  },
  {
    "preset": "farm_120",
    "threats_min": "8",
    "threats_max": "15",
    "resource_multiplier": "1",
    "conflict_tys": "center,suburb",
    "weight": "0.3"
  },
  {
    "preset": "farm_130",
    "threats_min": "8",
    "threats_max": "15",
    "resource_multiplier": "1",
    "conflict_tys": "center,suburb",
    "weight": "0.3"
  },
  {
    "preset": "farm_210",
    "threats_min": "15",
    "threats_max": "999",
    "resource_multiplier": "1",
    "conflict_tys": "center,suburb",
    "weight": "0.3"
  },
  {
    "preset": "farm_220",
    "threats_min": "15",
    "threats_max": "999",
    "resource_multiplier": "1",
    "conflict_tys": "center,suburb",
    "weight": "0.3"
  },
  {
    "preset": "farm_230",
    "threats_min": "15",
    "threats_max": "999",
    "resource_multiplier": "1",
    "conflict_tys": "center,suburb",
    "weight": "0.3"
  },
  {
    "preset": "hatchery_010",
    "threats_min": "1",
    "threats_max": "10",
    "resource_multiplier": "1",
    "conflict_tys": "center,suburb",
    "weight": "0.5"
  },
  {
    "preset": "hatchery_020",
    "threats_min": "1",
    "threats_max": "10",
    "resource_multiplier": "1",
    "conflict_tys": "center,suburb",
    "weight": "0.5"
  },
  {
    "preset": "hatchery_030",
    "threats_min": "1",
    "threats_max": "10",
    "resource_multiplier": "1",
    "conflict_tys": "center,suburb",
    "weight": "0.5"
  },
  {
    "preset": "hatchery_031",
    "threats_min": "8",
    "threats_max": "14",
    "resource_multiplier": "1",
    "conflict_tys": "center,suburb",
    "weight": "0.5"
  },
  {
    "preset": "hatchery_120",
    "threats_min": "10",
    "threats_max": "15",
    "resource_multiplier": "1",
    "conflict_tys": "center,suburb",
    "weight": "0.5"
  },
  {
    "preset": "hatchery_130",
    "threats_min": "12",
    "threats_max": "20",
    "resource_multiplier": "1",
    "conflict_tys": "center,suburb",
    "weight": "0.5"
  },
  {
    "preset": "hatchery_210",
    "threats_min": "12",
    "threats_max": "999",
    "resource_multiplier": "1",
    "conflict_tys": "center,suburb",
    "weight": "0.5"
  },
  {
    "preset": "hatchery_220",
    "threats_min": "12",
    "threats_max": "999",
    "resource_multiplier": "1",
    "conflict_tys": "center,suburb",
    "weight": "0.5"
  },
  {
    "preset": "hatchery_230",
    "threats_min": "15",
    "threats_max": "999",
    "resource_multiplier": "1",
    "conflict_tys": "center,suburb",
    "weight": "0.5"
  },
  {
    "preset": "indoor",
    "threats_min": "1",
    "threats_max": "7",
    "resource_multiplier": "1",
    "conflict_tys": "center,suburb",
    "weight": "1"
  },
  {
    "preset": "indoor2",
    "threats_min": "8",
    "threats_max": "12",
    "resource_multiplier": "1",
    "conflict_tys": "center,suburb",
    "weight": "1"
  },
  {
    "preset": "maze",
    "threats_min": "1",
    "threats_max": "11",
    "resource_multiplier": "1",
    "conflict_tys": "center",
    "weight": "1"
  },
  {
    "preset": "outdoor",
    "threats_min": "1",
    "threats_max": "12",
    "resource_multiplier": "1",
    "conflict_tys": "remote,border",
    "weight": "1"
  },
  {
    "preset": "shooting_house1_short",
    "threats_min": "12",
    "threats_max": "999",
    "resource_multiplier": "1.1",
    "conflict_tys": "suburb",
    "weight": "1"
  },
  {
    "preset": "shooting_house2_short",
    "threats_min": "12",
    "threats_max": "999",
    "resource_multiplier": "1.1",
    "conflict_tys": "remote,suburb",
    "weight": "1"
  },
  {
    "preset": "storehouse_short",
    "threats_min": "13",
    "threats_max": "999",
    "resource_multiplier": "1.1",
    "conflict_tys": "remote",
    "weight": "1"
  }
];