import { data as sourceResearch } from '../downloaded/research.mjs';
import { data as sourceResearchKey } from '../downloaded/researchKey.mjs';


export const research_keys = [];
for (const row of sourceResearchKey) {
  const { key, descr_tmpl, ty } = row;
  research_keys.push({ key, descr_tmpl, ty });
}

export const researches = [];
for (const row of sourceResearch) {
  const { key, seq, subseq, narrative, col, name,
    prerequisite_cond,
    prerequisities,

    complete_cond,
    complete_value,

    duration,

    expire_cond,
    expire_value,

    exclusives,

    effect_0_key,
    effect_0_value,
    effect_1_key,
    effect_1_value,

    descr,
    effect_text,
    effect_comment,
  } = row;

  const effects = [];
  if (effect_0_key) {
    effects.push({ key: effect_0_key, value: 0 | effect_0_value });
  }
  if (effect_1_key) {
    effects.push({ key: effect_1_key, value: 0 | effect_1_value });
  }

  researches.push({
    key,
    seq: 0 | seq,
    subseq: 0 | subseq,
    narrative: (0 | narrative) === 1,
    col: 0 | col,
    name,

    prerequisite_cond,
    prerequisities: prerequisities.split(',').map((item) => item.trim()).filter((item) => item.length > 0),

    complete_cond,
    complete_value: 0 | complete_value,

    duration: (0 | duration) * 28,

    expire_cond,
    expire_value: 0 | expire_value,

    exclusives: exclusives.split(',').map((item) => item.trim()).filter((item) => item.length > 0),

    effects,

    descr,
    effect_text,
    effect_comment,
  });
}
