// downloaded data!
export const data = [
  {
    "index": "0",
    "tick": "0",
    "world_idx": "4127",
    "renown": "10",
    "descr": "스타팅 거점에서 5일 떨어진 타일(#4127)"
  },
  {
    "index": "1",
    "tick": "120",
    "world_idx": "4360",
    "renown": "12",
    "descr": "스타팅 거점에서 7일 걸리는 타일(#4365)"
  },
  {
    "index": "2",
    "tick": "320",
    "world_idx": "3890",
    "renown": "15",
    "descr": "스타팅 거점에서 9일 걸리는 타일(#4518)"
  },
  {
    "index": "3",
    "tick": "680",
    "world_idx": "3891",
    "renown": "18",
    "descr": "스타팅 거점에서 9일 걸리는 타일(#4199)"
  }
];