import { data as source } from '../downloaded/weights.mjs';


export const list = [];
for (const row of source) {
  const { area, label, ty, weight } = row;
  list.push({
    area: 0 | area,
    label,
    ty,
    weight: +weight,
  });
}

export const LABELS = ['E', 'D', 'C', 'B', 'A', 'S'];
export function label_delta(label, delta) {
  let idx = LABELS.indexOf(label);
  idx = Math.max(0, Math.min(LABELS.length - 1, idx + delta));
  return LABELS[idx];
}

export function sample(rng, area, ty, delta) {
  const l = weights(area, ty);
  const w = l.map(({ weight }) => weight);
  const label = l[rng.weighted(w)].label;
  return label_delta(label, delta ?? 0);
}

export function weights(area, ty) {
  return list.filter(({ area: a, ty: t }) => a === area && t === ty);
}
