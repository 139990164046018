// downloaded data!
export const data = [
  {
    "key": "trait_1_hawkeye",
    "name": "loc_data_string_traits_name_trait_1_hawkeye",
    "conflicts": "trait_41_shortsight",
    "decision": "0",
    "bravery": "0",
    "focus": "0",
    "reaction": "0",
    "toughness": "0",
    "precision": "3",
    "ingame": "",
    "trait_perk_group": "precision",
    "descr": "loc_data_longtext_traits_descr_trait_1_hawkeye"
  },
  {
    "key": "trait_2_kind",
    "name": "loc_data_string_traits_name_trait_2_kind",
    "conflicts": "trait_16_cunning",
    "decision": "-1",
    "bravery": "1",
    "focus": "0",
    "reaction": "0",
    "toughness": "0",
    "precision": "0",
    "ingame": "",
    "trait_perk_group": "bravery",
    "descr": "loc_data_longtext_traits_descr_trait_2_kind"
  },
  {
    "key": "trait_3_rash",
    "name": "loc_data_string_traits_name_trait_3_rash",
    "conflicts": "trait_7_cautious",
    "decision": "-2",
    "bravery": "0",
    "focus": "0",
    "reaction": "1",
    "toughness": "0",
    "precision": "0",
    "ingame": "",
    "trait_perk_group": "reaction",
    "descr": "loc_data_longtext_traits_descr_trait_3_rash"
  },
  {
    "key": "trait_4_pessimist",
    "name": "loc_data_string_traits_name_trait_4_pessimist",
    "conflicts": "trait_5_optimist",
    "decision": "0",
    "bravery": "-1",
    "focus": "0",
    "reaction": "0",
    "toughness": "0",
    "precision": "1",
    "ingame": "",
    "trait_perk_group": "precision",
    "descr": "loc_data_longtext_traits_descr_trait_4_pessimist"
  },
  {
    "key": "trait_5_optimist",
    "name": "loc_data_string_traits_name_trait_5_optimist",
    "conflicts": "trait_4_pessimist",
    "decision": "0",
    "bravery": "1",
    "focus": "0",
    "reaction": "0",
    "toughness": "0",
    "precision": "-1",
    "ingame": "",
    "trait_perk_group": "bravery",
    "descr": "loc_data_longtext_traits_descr_trait_5_optimist"
  },
  {
    "key": "trait_6_loner",
    "name": "loc_data_string_traits_name_trait_6_loner",
    "conflicts": "trait_12_gregarious",
    "decision": "0",
    "bravery": "0",
    "focus": "0",
    "reaction": "0",
    "toughness": "0",
    "precision": "0",
    "ingame": "혼자 남으면 회피율 +50%",
    "trait_perk_group": "antisocial",
    "descr": "loc_data_longtext_traits_descr_trait_6_loner"
  },
  {
    "key": "trait_7_cautious",
    "name": "loc_data_string_traits_name_trait_7_cautious",
    "conflicts": "trait_3_rash",
    "decision": "2",
    "bravery": "0",
    "focus": "0",
    "reaction": "-1",
    "toughness": "0",
    "precision": "0",
    "ingame": "",
    "trait_perk_group": "decision",
    "descr": "loc_data_longtext_traits_descr_trait_7_cautious"
  },
  {
    "key": "trait_8_agile",
    "name": "loc_data_string_traits_name_trait_8_agile",
    "conflicts": "",
    "decision": "0",
    "bravery": "0",
    "focus": "0",
    "reaction": "1",
    "toughness": "1",
    "precision": "0",
    "ingame": "",
    "trait_perk_group": "reaction",
    "descr": "loc_data_longtext_traits_descr_trait_8_agile"
  },
  {
    "key": "trait_9_impulsive",
    "name": "loc_data_string_traits_name_trait_9_impulsive",
    "conflicts": "trait_19_stoic",
    "decision": "-1",
    "bravery": "0",
    "focus": "-1",
    "reaction": "0",
    "toughness": "0",
    "precision": "0",
    "ingame": "",
    "trait_perk_group": "negative",
    "descr": "loc_data_longtext_traits_descr_trait_9_impulsive"
  },
  {
    "key": "trait_10_intuitive",
    "name": "loc_data_string_traits_name_trait_10_intuitive",
    "conflicts": "",
    "decision": "0",
    "bravery": "0",
    "focus": "0",
    "reaction": "2",
    "toughness": "0",
    "precision": "0",
    "ingame": "",
    "trait_perk_group": "reaction",
    "descr": "loc_data_longtext_traits_descr_trait_10_intuitive"
  },
  {
    "key": "trait_11_resilient",
    "name": "loc_data_string_traits_name_trait_11_resilient",
    "conflicts": "",
    "decision": "0",
    "bravery": "1",
    "focus": "0",
    "reaction": "0",
    "toughness": "1",
    "precision": "0",
    "ingame": "",
    "trait_perk_group": "toughness",
    "descr": "loc_data_longtext_traits_descr_trait_11_resilient"
  },
  {
    "key": "trait_12_gregarious",
    "name": "loc_data_string_traits_name_trait_12_gregarious",
    "conflicts": "trait_6_loner",
    "decision": "0",
    "bravery": "0",
    "focus": "0",
    "reaction": "0",
    "toughness": "0",
    "precision": "0",
    "ingame": "다른 용병들 스트레스 회복 효율 +10%",
    "trait_perk_group": "social",
    "descr": "loc_data_longtext_traits_descr_trait_12_gregarious"
  },
  {
    "key": "trait_13_skeptical",
    "name": "loc_data_string_traits_name_trait_13_skeptical",
    "conflicts": "trait_21_idealist",
    "decision": "1",
    "bravery": "-1",
    "focus": "0",
    "reaction": "0",
    "toughness": "0",
    "precision": "0",
    "ingame": "",
    "trait_perk_group": "decision",
    "descr": "loc_data_longtext_traits_descr_trait_13_skeptical"
  },
  {
    "key": "trait_14_adaptive",
    "name": "loc_data_string_traits_name_trait_14_adaptive",
    "conflicts": "",
    "decision": "0",
    "bravery": "0",
    "focus": "0",
    "reaction": "1",
    "toughness": "0",
    "precision": "0",
    "ingame": "",
    "trait_perk_group": "reaction",
    "descr": "loc_data_longtext_traits_descr_trait_14_adaptive"
  },
  {
    "key": "trait_15_observing",
    "name": "loc_data_string_traits_name_trait_15_observing",
    "conflicts": "",
    "decision": "0",
    "bravery": "0",
    "focus": "1",
    "reaction": "0",
    "toughness": "0",
    "precision": "1",
    "ingame": "",
    "trait_perk_group": "focus",
    "descr": "loc_data_longtext_traits_descr_trait_15_observing"
  },
  {
    "key": "trait_16_cunning",
    "name": "loc_data_string_traits_name_trait_16_cunning",
    "conflicts": "trait_2_kind",
    "decision": "1",
    "bravery": "0",
    "focus": "0",
    "reaction": "0",
    "toughness": "0",
    "precision": "0",
    "ingame": "",
    "trait_perk_group": "decision",
    "descr": "loc_data_longtext_traits_descr_trait_16_cunning"
  },
  {
    "key": "trait_17_tactical",
    "name": "loc_data_string_traits_name_trait_17_tactical",
    "conflicts": "",
    "decision": "2",
    "bravery": "0",
    "focus": "0",
    "reaction": "0",
    "toughness": "0",
    "precision": "2",
    "ingame": "",
    "trait_perk_group": "decision",
    "descr": "loc_data_longtext_traits_descr_trait_17_tactical"
  },
  {
    "key": "trait_18_loyal",
    "name": "loc_data_string_traits_name_trait_18_loyal",
    "conflicts": "trait_20_free",
    "decision": "0",
    "bravery": "1",
    "focus": "0",
    "reaction": "0",
    "toughness": "0",
    "precision": "0",
    "ingame": "",
    "trait_perk_group": "bravery",
    "descr": "loc_data_longtext_traits_descr_trait_18_loyal"
  },
  {
    "key": "trait_19_stoic",
    "name": "loc_data_string_traits_name_trait_19_stoic",
    "conflicts": "trait_9_impulsive",
    "decision": "1",
    "bravery": "0",
    "focus": "1",
    "reaction": "0",
    "toughness": "0",
    "precision": "0",
    "ingame": "",
    "trait_perk_group": "focus",
    "descr": "loc_data_longtext_traits_descr_trait_19_stoic"
  },
  {
    "key": "trait_20_free",
    "name": "loc_data_string_traits_name_trait_20_free",
    "conflicts": "trait_18_loyal",
    "decision": "0",
    "bravery": "0",
    "focus": "0",
    "reaction": "0",
    "toughness": "0",
    "precision": "1",
    "ingame": "",
    "trait_perk_group": "precision",
    "descr": "loc_data_longtext_traits_descr_trait_20_free"
  },
  {
    "key": "trait_21_idealist",
    "name": "loc_data_string_traits_name_trait_21_idealist",
    "conflicts": "trait_13_skeptical",
    "decision": "-1",
    "bravery": "1",
    "focus": "0",
    "reaction": "0",
    "toughness": "0",
    "precision": "0",
    "ingame": "",
    "trait_perk_group": "bravery",
    "descr": "loc_data_longtext_traits_descr_trait_21_idealist"
  },
  {
    "key": "trait_22_philosophical",
    "name": "loc_data_string_traits_name_trait_22_philosophical",
    "conflicts": "",
    "decision": "0",
    "bravery": "1",
    "focus": "-1",
    "reaction": "0",
    "toughness": "0",
    "precision": "0",
    "ingame": "",
    "trait_perk_group": "bravery",
    "descr": "loc_data_longtext_traits_descr_trait_22_philosophical"
  },
  {
    "key": "trait_23_ironwill",
    "name": "loc_data_string_traits_name_trait_23_ironwill",
    "conflicts": "trait_24_depressive",
    "decision": "0",
    "bravery": "2",
    "focus": "0",
    "reaction": "0",
    "toughness": "0",
    "precision": "0",
    "ingame": "",
    "trait_perk_group": "bravery",
    "descr": "loc_data_longtext_traits_descr_trait_23_ironwill"
  },
  {
    "key": "trait_24_depressive",
    "name": "loc_data_string_traits_name_trait_24_depressive",
    "conflicts": "trait_23_ironwill",
    "decision": "0",
    "bravery": "-2",
    "focus": "0",
    "reaction": "0",
    "toughness": "0",
    "precision": "0",
    "ingame": "",
    "trait_perk_group": "negative",
    "descr": "loc_data_longtext_traits_descr_trait_24_depressive"
  },
  {
    "key": "trait_25_charismatic",
    "name": "loc_data_string_traits_name_trait_25_charismatic",
    "conflicts": "trait_45_ridiculous",
    "decision": "0",
    "bravery": "0",
    "focus": "0",
    "reaction": "0",
    "toughness": "0",
    "precision": "0",
    "ingame": "다른 용병들 재장전 속도 +10%",
    "trait_perk_group": "social",
    "descr": "loc_data_longtext_traits_descr_trait_25_charismatic"
  },
  {
    "key": "trait_26_pacifist",
    "name": "loc_data_string_traits_name_trait_26_pacifist",
    "conflicts": "trait_39_merciless",
    "decision": "0",
    "bravery": "0",
    "focus": "-2",
    "reaction": "0",
    "toughness": "1",
    "precision": "0",
    "ingame": "",
    "trait_perk_group": "toughness",
    "descr": "loc_data_longtext_traits_descr_trait_26_pacifist"
  },
  {
    "key": "trait_27_pokerface",
    "name": "loc_data_string_traits_name_trait_27_pokerface",
    "conflicts": "",
    "decision": "0",
    "bravery": "0",
    "focus": "1",
    "reaction": "1",
    "toughness": "0",
    "precision": "0",
    "ingame": "",
    "trait_perk_group": "focus",
    "descr": "loc_data_longtext_traits_descr_trait_27_pokerface"
  },
  {
    "key": "trait_28_inventive",
    "name": "loc_data_string_traits_name_trait_28_inventive",
    "conflicts": "",
    "decision": "1",
    "bravery": "0",
    "focus": "0",
    "reaction": "0",
    "toughness": "0",
    "precision": "1",
    "ingame": "",
    "trait_perk_group": "decision",
    "descr": "loc_data_longtext_traits_descr_trait_28_inventive"
  },
  {
    "key": "trait_29_strong",
    "name": "loc_data_string_traits_name_trait_29_strong",
    "conflicts": "trait_47_weak",
    "decision": "0",
    "bravery": "0",
    "focus": "0",
    "reaction": "0",
    "toughness": "3",
    "precision": "0",
    "ingame": "",
    "trait_perk_group": "toughness",
    "descr": "loc_data_longtext_traits_descr_trait_29_strong"
  },
  {
    "key": "trait_30_perfectionist",
    "name": "loc_data_string_traits_name_trait_30_perfectionist",
    "conflicts": "",
    "decision": "0",
    "bravery": "-2",
    "focus": "1",
    "reaction": "1",
    "toughness": "0",
    "precision": "0",
    "ingame": "",
    "trait_perk_group": "focus",
    "descr": "loc_data_longtext_traits_descr_trait_30_perfectionist"
  },
  {
    "key": "trait_31_improviser",
    "name": "loc_data_string_traits_name_trait_31_improviser",
    "conflicts": "",
    "decision": "0",
    "bravery": "1",
    "focus": "0",
    "reaction": "1",
    "toughness": "0",
    "precision": "0",
    "ingame": "",
    "trait_perk_group": "reaction",
    "descr": "loc_data_longtext_traits_descr_trait_31_improviser"
  },
  {
    "key": "trait_32_brave",
    "name": "loc_data_string_traits_name_trait_32_brave",
    "conflicts": "trait_38_paranoid",
    "decision": "0",
    "bravery": "2",
    "focus": "0",
    "reaction": "0",
    "toughness": "0",
    "precision": "0",
    "ingame": "",
    "trait_perk_group": "bravery",
    "descr": "loc_data_longtext_traits_descr_trait_32_brave"
  },
  {
    "key": "trait_33_ambitious",
    "name": "loc_data_string_traits_name_trait_33_ambitious",
    "conflicts": "",
    "decision": "0",
    "bravery": "0",
    "focus": "2",
    "reaction": "0",
    "toughness": "0",
    "precision": "0",
    "ingame": "",
    "trait_perk_group": "focus",
    "descr": "loc_data_longtext_traits_descr_trait_33_ambitious"
  },
  {
    "key": "trait_34_conservative",
    "name": "loc_data_string_traits_name_trait_34_conservative",
    "conflicts": "trait_44_openmind",
    "decision": "-1",
    "bravery": "1",
    "focus": "0",
    "reaction": "0",
    "toughness": "0",
    "precision": "0",
    "ingame": "",
    "trait_perk_group": "bravery",
    "descr": "loc_data_longtext_traits_descr_trait_34_conservative"
  },
  {
    "key": "trait_35_rhetoric",
    "name": "loc_data_string_traits_name_trait_35_rhetoric",
    "conflicts": "trait_46_mumbling",
    "decision": "0",
    "bravery": "0",
    "focus": "0",
    "reaction": "0",
    "toughness": "0",
    "precision": "0",
    "ingame": "다른 용병들 치명상 입힐 확률 +1%",
    "trait_perk_group": "social",
    "descr": "loc_data_longtext_traits_descr_trait_35_rhetoric"
  },
  {
    "key": "trait_36_revengeful",
    "name": "loc_data_string_traits_name_trait_36_revengeful",
    "conflicts": "",
    "decision": "0",
    "bravery": "1",
    "focus": "0",
    "reaction": "0",
    "toughness": "0",
    "precision": "-1",
    "ingame": "",
    "trait_perk_group": "bravery",
    "descr": "loc_data_longtext_traits_descr_trait_36_revengeful"
  },
  {
    "key": "trait_37_romantic",
    "name": "loc_data_string_traits_name_trait_37_romantic",
    "conflicts": "trait_43_pragmatic",
    "decision": "-1",
    "bravery": "0",
    "focus": "0",
    "reaction": "0",
    "toughness": "0",
    "precision": "0",
    "ingame": "",
    "trait_perk_group": "negative",
    "descr": "loc_data_longtext_traits_descr_trait_37_romantic"
  },
  {
    "key": "trait_38_paranoid",
    "name": "loc_data_string_traits_name_trait_38_paranoid",
    "conflicts": "trait_32_brave",
    "decision": "-1",
    "bravery": "0",
    "focus": "0",
    "reaction": "1",
    "toughness": "0",
    "precision": "0",
    "ingame": "",
    "trait_perk_group": "reaction",
    "descr": "loc_data_longtext_traits_descr_trait_38_paranoid"
  },
  {
    "key": "trait_39_merciless",
    "name": "loc_data_string_traits_name_trait_39_merciless",
    "conflicts": "trait_26_pacifist",
    "decision": "0",
    "bravery": "0",
    "focus": "1",
    "reaction": "0",
    "toughness": "0",
    "precision": "0",
    "ingame": "",
    "trait_perk_group": "focus",
    "descr": "loc_data_longtext_traits_descr_trait_39_merciless"
  },
  {
    "key": "trait_40_humble",
    "name": "loc_data_string_traits_name_trait_40_humble",
    "conflicts": "trait_42_proud",
    "decision": "0",
    "bravery": "0",
    "focus": "0",
    "reaction": "1",
    "toughness": "0",
    "precision": "1",
    "ingame": "",
    "trait_perk_group": "precision",
    "descr": "loc_data_longtext_traits_descr_trait_40_humble"
  },
  {
    "key": "trait_41_shortsight",
    "name": "loc_data_string_traits_name_trait_41_shortsight",
    "conflicts": "trait_1_hawkeye",
    "decision": "0",
    "bravery": "0",
    "focus": "0",
    "reaction": "0",
    "toughness": "0",
    "precision": "-3",
    "ingame": "",
    "trait_perk_group": "negative",
    "descr": "loc_data_longtext_traits_descr_trait_41_shortsight"
  },
  {
    "key": "trait_42_proud",
    "name": "loc_data_string_traits_name_trait_42_proud",
    "conflicts": "trait_40_humble",
    "decision": "0",
    "bravery": "1",
    "focus": "1",
    "reaction": "-1",
    "toughness": "0",
    "precision": "0",
    "ingame": "",
    "trait_perk_group": "bravery",
    "descr": "loc_data_longtext_traits_descr_trait_42_proud"
  },
  {
    "key": "trait_43_pragmatic",
    "name": "loc_data_string_traits_name_trait_43_pragmatic",
    "conflicts": "trait_37_romantic",
    "decision": "1",
    "bravery": "0",
    "focus": "0",
    "reaction": "0",
    "toughness": "0",
    "precision": "0",
    "ingame": "",
    "trait_perk_group": "decision",
    "descr": "loc_data_longtext_traits_descr_trait_43_pragmatic"
  },
  {
    "key": "trait_44_openmind",
    "name": "loc_data_string_traits_name_trait_44_openmind",
    "conflicts": "trait_34_conservative",
    "decision": "1",
    "bravery": "-1",
    "focus": "0",
    "reaction": "0",
    "toughness": "0",
    "precision": "0",
    "ingame": "",
    "trait_perk_group": "decision",
    "descr": "loc_data_longtext_traits_descr_trait_44_openmind"
  },
  {
    "key": "trait_45_ridiculous",
    "name": "loc_data_string_traits_name_trait_45_ridiculous",
    "conflicts": "trait_25_charismatic",
    "decision": "0",
    "bravery": "0",
    "focus": "0",
    "reaction": "0",
    "toughness": "0",
    "precision": "0",
    "ingame": "다른 용병들 스트레스 회복 효율 +10%",
    "trait_perk_group": "social",
    "descr": "loc_data_longtext_traits_descr_trait_45_ridiculous"
  },
  {
    "key": "trait_46_mumbling",
    "name": "loc_data_string_traits_name_trait_46_mumbling",
    "conflicts": "trait_35_rhetoric",
    "decision": "0",
    "bravery": "0",
    "focus": "0",
    "reaction": "0",
    "toughness": "0",
    "precision": "0",
    "ingame": "다른 용병들 스트레스 회복 효율 -10%",
    "trait_perk_group": "negative",
    "descr": "loc_data_longtext_traits_descr_trait_46_mumbling"
  },
  {
    "key": "trait_47_weak",
    "name": "loc_data_string_traits_name_trait_47_weak",
    "conflicts": "trait_29_strong",
    "decision": "0",
    "bravery": "0",
    "focus": "0",
    "reaction": "0",
    "toughness": "-3",
    "precision": "0",
    "ingame": "",
    "trait_perk_group": "negative",
    "descr": "loc_data_longtext_traits_descr_trait_47_weak"
  },
  {
    "key": "trait_48_unfocused",
    "name": "loc_data_string_traits_name_trait_48_unfocused",
    "conflicts": "",
    "decision": "0",
    "bravery": "0",
    "focus": "-3",
    "reaction": "0",
    "toughness": "0",
    "precision": "0",
    "ingame": "",
    "trait_perk_group": "negative",
    "descr": "loc_data_longtext_traits_descr_trait_48_unfocused"
  }
];