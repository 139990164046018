import React from 'react';
import { gears_vest_bulletproof } from '../presets_gear';
import ResourceContext from './ResourceContext';
import _ from 'lodash';

function EquipRow(props) {
  const { equipment: e, selected, onEquip, ch } = props;
  let button =
    <ResourceContext.Consumer>
      {(resource) => {
        return <button onClick={() => onEquip(ch, e)} disabled={resource + ch.equipment.vest_cost - e.vest_cost < 0}>Equip</button>
      }}
    </ResourceContext.Consumer>
  if (selected) {
    //TODO
    button = <button style={{ backgroundColor: 'red' }}>Equipped</button>;
  }

  return <tr key={e.vest_name}>
    <th>
      {e.vest_name}
    </th>
    <td align="left">{e.vest_class}</td>
    <td align="center">{e.vest_rate}★</td>
    <td align="right">{e.vest_cost}BTC</td>
    <td>
      {button}
    </td>
  </tr>;
}

export function EquipmentList(props) {
  const { ch } = props;

  return (
    <div style={{ width: '100%', overflow: 'auto', height: 400 }}>
      <table style={{ Width: 650 }}>
        <thead>
          <tr>
            <td>이름</td>
            <td align="center">종류</td>
            <td align="center">랭크</td>
            <td align="center">비용</td>
            <td align="center"></td>
          </tr>
        </thead>
        <tbody>
          {gears_vest_bulletproof.map((e, i) => {
            return <EquipRow key={i} equipment={e} selected={_.isEqual(e, ch.equipment)}
              onEquip={props.onEquip} ch={ch} />;
          })}
        </tbody>
      </table>
    </div>
  );


}
