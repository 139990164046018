/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { PerkSlot } from "../PerkSlot";
import "./style.css";

export const PerkTreeColumn = ({
  perkSlotFrame,
  perkSlotPerkimg,
  perkSlotState = "completed",
  perkSlotOverlapGroupClassName,
  perkSlotVb,
  perkSlotVector,
  override = (
    <PerkSlot
      className="perk-slot-instance"
      frame={perkSlotFrame}
      frameEc="/anima/img/frame8ec93630-2.svg"
      img="/anima/img/perkimg-sample-2.png"
      label="PERK"
      onClick="D"
      onMouseMove="D"
      onMouseOut="D"
      onMouseOver="D"
      overlapGroupClassName={perkSlotOverlapGroupClassName}
      perkimgSample={perkSlotPerkimg}
      state={perkSlotState}
      v="/anima/img/v1fb2a71d-2.svg"
      v1="/anima/img/v90633387-2.svg"
      vE="/anima/img/v48146e38-2.svg"
      vFceEc="/anima/img/v5fce79ec-2.svg"
      vb={perkSlotVb}
      vector={perkSlotVector}
      vf="/anima/img/vf6ee8613-2.svg"
    />
  ),
}) => {
  return <div className="perk-tree-column">{override}</div>;
};

PerkTreeColumn.propTypes = {
  perkSlotFrame: PropTypes.string,
  perkSlotPerkimg: PropTypes.string,
  perkSlotState: PropTypes.string,
  perkSlotVb: PropTypes.string,
  perkSlotVector: PropTypes.string,
};
