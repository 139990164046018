// downloaded data!
export const data = [
  {
    "name": "미야모토 프레데리카",
    "nationality": "nation_1_col",
    "language": "Spanish",
    "background": "history_23_police",
    "ambition": "ambition_1_honor",
    "traits": "trait_17_tactical, trait_42_proud",
    "vocation": "sg",
    "ty": "Standard",
    "power": "8",
    "physical": "5",
    "potential_label": "E",
    "growthcap": "B",
    "perkpointcap": "D",
    "physicalcap": "B",
    "decision": "6",
    "bravery": "10",
    "focus": "6",
    "reaction": "9",
    "toughness": "11",
    "precision": "6"
  },
  {
    "name": "타카가키 카에데",
    "nationality": "nation_1_col",
    "language": "Spanish",
    "background": "history_11_drugdealer",
    "ambition": "ambition_3_success",
    "traits": "trait_39_merciless, trait_3_rash",
    "vocation": "smg",
    "ty": "Standard",
    "power": "7",
    "physical": "7",
    "potential_label": "B",
    "growthcap": "D",
    "perkpointcap": "A",
    "physicalcap": "C",
    "decision": "6",
    "bravery": "9",
    "focus": "6",
    "reaction": "11",
    "toughness": "5",
    "precision": "5"
  },
  {
    "name": "미요시 사나",
    "nationality": "nation_1_col",
    "language": "Spanish",
    "background": "history_17_farmer",
    "ambition": "ambition_5_onboard",
    "traits": "trait_7_cautious, trait_18_loyal",
    "vocation": "ar",
    "ty": "Incentive",
    "power": "6",
    "physical": "10",
    "potential_label": "C",
    "growthcap": "C",
    "perkpointcap": "B",
    "physicalcap": "A",
    "decision": "9",
    "bravery": "7",
    "focus": "8",
    "reaction": "4",
    "toughness": "4",
    "precision": "4"
  }
];
