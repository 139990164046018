import './App.css';
import React from 'react';

import { presets, preset_default } from './presets_testbed';
import { Simulator } from './sim';
import { Rng } from './rand';
import { SimView } from './SimView';

export class WinCounter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      wins: [0, 0],
    };
  };

  addWin(team) {
    const { wins } = this.state;
    wins[team] += 1;
    this.setState({ wins });
  };

  team0Winrate() {
    const { wins } = this.state;
    return (wins[0] === 0) ? 0 : wins[0] / (wins[0] + wins[1]);
  }

  render() {
    const { wins } = this.state;
    return <div>
      <p>Wins</p>
      <p>team0: {wins[0]}</p>
      <p>team1: {wins[1]}</p>
      <p>team0 winrate: {this.team0Winrate()}</p>
    </div>;
  }
}

export class Testbed extends React.Component {
  constructor(props) {
    super(props);

    const seed = Rng.randomseed();

    this.serializeRef = React.createRef();
    this.simRef = React.createRef();
    this.winCounterRef = React.createRef();
    this.restartTimer = null;

    this.keyBind = this.keyDown.bind(this);

    const variants = presets[preset_default]();
    const variant_selected = Object.keys(variants)[0];

    this.state = {
      presets,
      preset_selected: preset_default,
      variants,
      variant_selected,
      simstate: variants[variant_selected].preset(),

      seed,
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.keyBind);
    this.onSimstateReset();
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyBind);
  }

  onChangeConfig(entities) {
    this.setState({ entities });
  }

  onExport() {
    const state = { ...this.state.simstate };
    state.seed = this.simRef.current.state.seed;

    const text = JSON.stringify(state);
    this.serializeRef.current.value = text;
  }

  onImport() {
    const state = JSON.parse(this.serializeRef.current.value);
    this.setState({ simstate: state });
    this.simRef.current.restart();
  }

  onSeed(seed) {
    this.setState({
      seed,
    }, () => {
      this.simRef.current.restart();
    });
  }

  onPreset(p) {
    const { presets } = this.state;

    const variants = presets[p]();
    const variant_selected = Object.keys(variants)[0];

    this.setState({
      presets,
      preset_selected: p,
      variants,
      variant_selected,
      simstate: variants[variant_selected].preset(),
    }, this.onSimstateReset.bind(this));
  }

  onVariant(variant_selected) {
    const { variants } = this.state;
    this.setState({
      variant_selected,
      simstate: variants[variant_selected].preset(),
    }, this.onSimstateReset.bind(this));
  }

  onSimstateReset() {
    const { variants, variant_selected } = this.state;
    const simview = this.simRef.current;
    const { onsim } = variants[variant_selected];
    simview.restart(() => {
      if (onsim) {
        onsim(simview.state.sim);
      }
    });
  }

  onFinish(res) {
    const view = this.simRef.current;

    if (this.restartTimer !== null) {
      return;
    }

    if (res >= 0) {
      this.winCounterRef.current?.addWin(res);
    }

    view.stopTimer();
    this.restartTimer = setTimeout(() => {
      this.restartTimer = null;
      this.onSimstateReset();
      view.startTimer();
    }, 1000);
  }

  onOfflineSim() {
    // TODO: change seed
    // const seed = this.state.seed;
    const seed = Rng.randomseed();
    const view = this.simRef.current;

    const sim = Simulator.create({ ...view.props, seed, m: this.props.m });
    // reset runtime seed
    const seed2 = Rng.randomseed();
    sim.rng = new Rng(seed2);

    // console.log('before', sim);
    const start = Date.now();
    while (sim.onTick() === -1 && sim.tick < 10000) { }
    const res = sim.onTick();
    if (res >= 0) {
      this.winCounterRef.current.addWin(res);
    }
    sim.free();
    const dt = Date.now() - start;
    console.log(`took ${dt}ms, ${(sim.tick * 1000 / dt).toFixed(1)}tps`, res, sim);

    return res;
  }

  onOfflineSimStat(samples) {
    for (let i = 0; i < samples; i++) {
      this.onOfflineSim();
    }
  }

  keyDown(ev) {
    if (ev.key === 'b') {
      this.onOfflineSim();
    } else if (ev.key === 'B') {
      this.onOfflineSimStat(100);
    }
  }

  render() {
    const { preset_selected, presets, variant_selected, variants, simstate, seed } = this.state;

    return <>
      <p>shortcuts: (r) restart / (R) restart with same map / (z) zoom / (Tab) change speed / ( ) pause/resume / (s) single-step</p>
      <div>
        presets
        {Object.keys(presets).map((p) => {
          const cls = (p === preset_selected) ? 'selected' : '';
          return <button className={cls} onClick={() => this.onPreset(p)} key={p}>{p}</button>;
        })}
      </div>
      <div>
        variants
        {Object.keys(variants).map((p) => {
          const cls = (p === variant_selected) ? 'selected' : '';
          return <button className={cls} onClick={() => this.onVariant(p)} key={p}>{p}</button>;
        })}
      </div>

      <div>
        save&load
        <button onClick={this.onExport.bind(this)}>export</button>
        <button onClick={this.onImport.bind(this)}>import</button>
        <input ref={this.serializeRef} type="text"></input>
      </div>
      <button onClick={() => this.onSeed(seed)}>load seed</button>
      <input type="text" placeholder="seed" value={seed} onChange={(e) => this.setState({ seed: parseInt(e.target.value, 10) || 0 })}></input>
      <button onClick={() => { this.onSeed(Rng.randomseed()) }}> randomize </button>
      <button onClick={() => { this.onSeed(seed + 1) }}>&nbsp;+&nbsp;</button>
      <button onClick={() => { this.onSeed(seed - 1) }}>&nbsp;-&nbsp;</button>
      <SimView ref={this.simRef}
        seed={seed}
        debug={true}
        onFinish={(res) => this.onFinish(res)}
        {...simstate}
      />
      <WinCounter ref={this.winCounterRef} />
    </>;
  }
}
