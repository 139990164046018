// downloaded data!
export const data = [
  {
    "overall": "8",
    "mult_min": "1",
    "mult_max": "1.3"
  },
  {
    "overall": "9",
    "mult_min": "1.2",
    "mult_max": "1.5"
  },
  {
    "overall": "10",
    "mult_min": "1.4",
    "mult_max": "1.7"
  },
  {
    "overall": "11",
    "mult_min": "1.6",
    "mult_max": "1.9"
  },
  {
    "overall": "12",
    "mult_min": "1.8",
    "mult_max": "2.1"
  },
  {
    "overall": "13",
    "mult_min": "2",
    "mult_max": "2.3"
  },
  {
    "overall": "14",
    "mult_min": "2.2",
    "mult_max": "2.5"
  },
  {
    "overall": "15",
    "mult_min": "2.4",
    "mult_max": "2.7"
  },
  {
    "overall": "16",
    "mult_min": "2.6",
    "mult_max": "2.9"
  }
];