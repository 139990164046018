import { ENTITY_CONFIG_TMPL } from './opts.mjs';
import { v2 } from './v2.mjs';
import { Rng } from './rand.mjs';
import { STATS_TMPL, STATS_TMPL_MAX, team0_STATS_TMPL, team1_STATS_TMPL, entityFromStat } from './stat.mjs';
import { entityFromStat2, stats2_const, STATS2_TMPL } from './stats2.mjs';
import { agents } from './agents.mjs';
import { preset_maze } from './randomPreset2.mjs'
import { World } from './world.mjs';

import PRESET_HACHERY from './map_hatchery_wood1_variation.json' assert { type: 'json' };
import PRESET_CONSTRUCTION from './map_construction_site1_variation.json' assert { type: 'json' };
import PRESET_BAZAAR from './map_bazaar_variation.json' assert { type: 'json' };
import PRESET_ALLEY_SHORT from './map_alley_short_variation.json' assert { type: 'json' };
import PRESET_EMBASSY_SHORT from './map_embassy_short.json' assert { type: 'json' };
import OUTSKIRTS_FARM from './map_outskirts_farm1.json' assert { type: 'json' };
import TRAILER_FIELD from './map_trailer_field.json' assert { type: 'json' };
import TRAILER_FIELD_2 from './map_trailer_field_2.json' assert { type: 'json' };


/* eslint-disable */
import {
  throwable_fragment,
  throwable_frag_mk1,
  throwable_frag_mk2,
  throwable_frag_mk3,
  throwable_impact_frag_mk1,
  throwable_impact_frag_mk2,
  throwable_he_mk1,
  throwable_he_mk2,
  throwable_stun_mk1,
  throwable_stun_mk2,
  throwable_stun_mk3,
  throwable_smoke,
  throwable_stun,

  throwable_frag_demo,
} from './presets_throwables.mjs';
import {
  team0_tmpl_agent_dmr_mid,
  team0_tmpl_indoor_ar_high,
  team0_tmpl_agent_sg_high,
  team0_tmpl_agent_ar_low,
  team0_tmpl_agent_ar_mid,
  tmpl_bulletproof_low,
  team0_tmpl_agent_ar_high,

  enemy_tmpl_agent_ar_low,
} from './presets.mjs';
import {
  tmpl_firearm_ar_low,
  tmpl_firearm_ar_mid,
  tmpl_firearm_ar_high,
  tmpl_firearm_sg_low,
  tmpl_firearm_sg_mid,
  tmpl_firearm_sg_high,
  tmpl_firearm_dmr_low,
  tmpl_firearm_dmr_mid,
  tmpl_firearm_dmr_high,
  tmpl_firearm_sr_high,
  tmpl_firearm_sr_mid,
  tmpl_firearm_smg_high,
  tmpl_firearm_smg_mid,
  tmpl_firearm_smg_low,
  tmpl_firearm_sr_low,

  tmpl_firearm_hg_t1,
  tmpl_firearm_hg_t2,
  tmpl_firearm_hg_t3,
  tmpl_firearm_hg_t4,

  tmpl_firearm_unarmed,

  enemy_tmpl_firearm_ar_low,
  tmpl_firearm_sg_t1,

  tmpl_firearm_sr_t5,
  tmpl_firearm_smg_t5,
  tmpl_firearm_ar_t5,
  tmpl_firearm_ar_t3,
  tmpl_firearm_ar_t1,

  tmpl_firearm_ar_cheat,
  tmpl_firearm_smg_t3,
  tmpl_firearm_dmr_t3,
  tmpl_firearm_sg_t3,
  tmpl_firearm_sg_t2,
  tmpl_firearm_smg_t2,
  tmpl_firearm_smg_t1,
  tmpl_firearm_smg_t4,
  tmpl_firearm_ar_t2,
  tmpl_firearm_sg_t5,
} from './presets_firearm.mjs';

import {
  gear_vest_bulletproof_mid,
  gear_vest_bulletproof_highend,
  gear_nvd_t1,
  gear_nvd_t2,
  gear_nvd_t3,
  gear_nvd_t4,
} from './presets_gear.mjs';
/* eslint-enable */

const VIP_TMPL = {
  ...ENTITY_CONFIG_TMPL,
  ty: 'vip',
  team: 2,
  default_rule: 'idle',
  firearm_range: 5,
  firearm_aimvar_mult: 30,

  speed: 20,
};

export function preset_rescue() {
  const world = { width: 800, height: 1600 };
  const size = world.height / 2 - 100;

  const spawnSize = new v2(100, 20);

  // 적 에이전트 스펙 정의
  const team1_tmpl = {
    ...ENTITY_CONFIG_TMPL, team: 1, spawnarea: 1,
    armor: 1,
    default_rule: 'idle', allow_crawl: false,
  };

  const team1_tmpl_cover_t = {
    ...team1_tmpl, spawnarea: 3,
    default_rule: 'cover',
  };
  const team1_tmpl_cover_d = {
    ...team1_tmpl_cover_t, spawnarea: 4, group: 0,
    default_rule: 'dummy'
  };

  const room_size = new v2(world.width - 100, 500).mul(0.5);
  const buf = 200;

  const buf2 = 100;

  return {
    world,

    obstacle_specs: [{
      pos: new v2(0, 0),
      extent: new v2(
        (world.width / 2) - 100,
        (world.height / 2) - 100,
      ),
      ty: 'random',
      random: { ty: 'mixed', count: 30 }
    }],

    entities: [
      team0_tmpl_agent_dmr_mid,
      team0_tmpl_agent_dmr_mid,
      team0_tmpl_agent_dmr_mid,
      team0_tmpl_agent_dmr_mid,
      team0_tmpl_agent_dmr_mid,
      team0_tmpl_agent_dmr_mid,

      { ...VIP_TMPL, spawnarea: 1 },

      team1_tmpl,
      team1_tmpl,
      team1_tmpl,
      team1_tmpl,
      team1_tmpl,

      team1_tmpl_cover_t,
      team1_tmpl_cover_t,
      team1_tmpl_cover_t,
      team1_tmpl_cover_t,

      team1_tmpl_cover_d,
      team1_tmpl_cover_d,
      team1_tmpl_cover_d,
    ],

    spawnareas: [
      { pos: new v2(0, -size), extent: spawnSize, heading: 0, spawn: true },
      {
        pos: new v2(0, 0), extent: room_size, heading: 0, vacate: true,
        structureopts: {
          count: 3,
        }
      },
      { pos: new v2(0, +size), extent: spawnSize, heading: 0 },


      {
        pos: new v2(0, -(world.height / 4 + room_size.y) + buf / 2),
        extent: new v2(world.width - buf, world.height / 2 - room_size.y / 2 - buf).mul(0.5), heading: 0
      },
      {
        pos: new v2(0, +(world.height / 4 + room_size.y) + buf / 2),
        extent: new v2(world.width - buf, 100).mul(0.5), heading: 0
      },

      // trigger area
      {
        pos: new v2(0, +(world.height / 4 + room_size.y) - buf2 / 2),
        extent: new v2(world.width - buf2, world.height / 2 - room_size.y / 2 - buf2).mul(0.5),
        heading: 0,

        // TODO
        triggers: [
          {
            condition: 'enter',
            conditiontarget: {
              ty: 'entity',
              team: 0,
            },

            action: 'push_rule',
            actiontarget: {
              ty: 'entity',
              group: 0,
            },
            actionrules: [{ ty: 'cover' }],
          }
        ],
      },
    ],

    goals: [
      { name: 'escape area', area: 2 },
    ],

    mission_rules: [
      { ty: 'capture' },
      { ty: 'explore' },
    ],
  };
}

export function preset_rescue2() {
  const world = { width: 1200, height: 2000 };

  const team0_0 = entityFromStat({
    name: 'hello',

    // [0, 2]
    stat_size: 1,

    // [1, 20]
    // sr, dmr, ar, smg, sg
    stat_firearm_level: [11, 11, 11, 11, 11],

    // [18, 22]
    stat_movespeed: 20,
    // [50, 150]
    stat_dashdist: 100,
    // [1, 20]
    stat_aimvar_hold: 11,
    stat_aimvar_decay_per_tick: 11,
    stat_aimvar_hold_max: 11,
    stat_aimvar_incr_per_shoot: 11,
    stat_aimvar_incr_per_hit: 11,
    // [0, 2]
    stat_aim_fire_thres: 1,
    stat_aim_reload: 1,
  }, tmpl_firearm_dmr_mid);

  const team0_1 = entityFromStat(agents[1], tmpl_firearm_dmr_mid);
  const team0_2 = entityFromStat(agents[2], tmpl_firearm_ar_high);

  // 적 에이전트 스펙 정의
  const team1_tmpl = {
    ...ENTITY_CONFIG_TMPL, team: 1, spawnarea: 1,
    armor: 1,
    default_rule: 'idle', allow_crawl: false,
    vis_range: 300,

    aim_rot_rules: [
      { aimvar: Math.PI * 2, aimspeed: 0.1 },
    ],
  };

  return {
    world,

    entities: [
      team0_0,
      team0_1,
      team0_2,

      { ...VIP_TMPL, spawnarea: 4 },

      { ...team1_tmpl, spawnarea: 1 },
      { ...team1_tmpl, spawnarea: 1 },
      { ...team1_tmpl, spawnarea: 1 },

      { ...team1_tmpl, spawnarea: 2 },
      { ...team1_tmpl, spawnarea: 2 },
      { ...team1_tmpl, spawnarea: 2 },

      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },

      { ...team1_tmpl, spawnarea: 4 },
      { ...team1_tmpl, spawnarea: 4 },
      { ...team1_tmpl, spawnarea: 4 },
      { ...team1_tmpl, spawnarea: 4 },
      { ...team1_tmpl, spawnarea: 4 },

      { ...team1_tmpl, spawnarea: 5 },
      { ...team1_tmpl, spawnarea: 5 },

      { ...team1_tmpl, spawnarea: 6 },
    ],

    spawnareas: [
      { pos: new v2(-430, -926), extent: new v2(126, 49), heading: 0, spawn: true },
      { pos: new v2(360, -599), extent: new v2(82, 50), heading: 0 },
      { pos: new v2(-363, -381), extent: new v2(67, 52), heading: 0 },
      { pos: new v2(382, -175), extent: new v2(83, 49), heading: 0 },
      {
        pos: new v2(-214, 171), extent: new v2(356, 275), heading: 0,
        structureopts: { count: 1 },
        triggers: [
          {
            condition: 'enter',
            conditiontarget: {
              ty: 'entity',
              team: 0,
            },

            action: 'push_rule',
            actiontarget: {
              ty: 'entity',
              team: 0,
            },
            actionrules: [{ ty: 'explore', area: 4 }],
          }
        ],
      },
      {
        pos: new v2(-89, 893), extent: new v2(79, 265), heading: 0, triggers: [
          {
            condition: 'enter',
            conditiontarget: { ty: 'entity', team: 0 },
            action: 'push_rule',
            actiontarget: { ty: 'entity', team: 0 },
            actionrules: [{ ty: 'capture' }],
          }
        ]
      },
      { pos: new v2(496, 555), extent: new v2(70, 64), heading: 0 },
    ],
    obstacle_specs: [
      { pos: new v2(114, -867), extent: new v2(74, 16), heading: 0.6610339355468751, ty: "half" },
      { pos: new v2(-23, -711), extent: new v2(75, 22), heading: -2.230751342773438, ty: "half" },
      { pos: new v2(-358, -536), extent: new v2(293, 19), heading: 0.35367431640625, ty: "full" },
      { pos: new v2(-22, -548), extent: new v2(75, 16), heading: -0.669625244140625, ty: "half" },
      { pos: new v2(23, -417), extent: new v2(9, 31), heading: 0.0660748291015625, ty: "half" },
      { pos: new v2(439, -493), extent: new v2(179, 12), heading: 0.4049395751953125, ty: "full" },
      { pos: new v2(379, -737), extent: new v2(72, 13), heading: -0.21957763671874997, ty: "full" },
      { pos: new v2(158, -389), extent: new v2(84, 14), heading: 0.7438415527343748, ty: "half" },
      { pos: new v2(132, -143), extent: new v2(379, 16), heading: -0.4499334716796875, ty: "full" },
      { pos: new v2(276, 450), extent: new v2(529, 15), heading: 0.3973382568359375, ty: "full" },
      { pos: new v2(-329, 693), extent: new v2(75, 16), heading: 0.873121337890625, ty: "half" },
      { pos: new v2(278, 101), extent: new v2(10, 63), heading: 0.17880859375, ty: "half" },
      { pos: new v2(-434, -216), extent: new v2(85, 11), heading: 0.1760205078125, ty: "half" },
      { pos: new v2(-283, -435), extent: new v2(9, 62), heading: 0.36402404785156256, ty: "half" },
      { pos: new v2(-343, 888), extent: new v2(16, 71), heading: 0.398150634765625, ty: "half" },
    ],

    goals: [
      { name: 'escape area', area: 6 },
    ],

    mission_rules: [
      { ty: 'explore' },
    ],
  };
}

export function preset_indoor() {
  const world = {
    width: 600,
    height: 600,
    simover_rule: 'eliminate',
    exp_prepopulate_grid: true,
  };
  const size = world.height / 2 - 100;

  const spawnSize = new v2(100, 20);
  const room_size = new v2(150, 150);

  /*
  const team0_tmpl = {
    ...entityFromStat(stats2_const(10), tmpl_firearm_sg_low),
    allow_cover_edge: true,
  };
  */

  const enemyStat =
  {
    ...STATS2_TMPL,
    decision: 10,
    bravery: 1,
    focus: 10,
    reaction: 5,
    toughness: 1,
    precision: 1,
  };

  const testStat =
  {
    ...STATS2_TMPL,
    decision: 10,
    bravery: 10,
    focus: 10,
    reaction: 20,
    toughness: 2,
    precision: 1,
  };

  const team0_tmpl = {
    ...entityFromStat2(testStat, tmpl_firearm_ar_high),

    // 현재 소유하고 있는, 던질 수 있는 물건의 목록
    throwables: [
      throwable_fragment,
      throwable_stun,
    ],
  };

  const team1_tmpl = {
    ...entityFromStat2(enemyStat, tmpl_firearm_sg_mid), team: 1, spawnarea: 1,
    life: 100,
    armor: 0,
    armor_hit_prob: 0.4,
    default_rule: 'idle',
    allow_crawl: false,
    allow_door_wait: false,
  };

  /*
  const team1_tmpl = {
    ...ENTITY_CONFIG_TMPL, team: 1, spawnarea: 1,
    life: 100,
    armor: 0,
    armor_hit_prob: 0.4,
    default_rule: { ty: 'idle', alert: false },
    allow_crawl: false,
  };
  */

  return {
    world,

    obstacle_specs: [{
      pos: new v2(0, 0),
      extent: new v2(
        (world.width / 2) - 100,
        (world.height / 2) - 100,
      ),
      ty: 'random',
      random: { ty: 'mixed', count: 0 }
    }],

    entities: [
      { ...team0_tmpl },
      { ...team0_tmpl },

      ...new Array(7).fill(team1_tmpl),
    ],

    spawnareas: [
      { pos: new v2(0, -200), extent: spawnSize, heading: 0, spawn: true },
      {
        pos: new v2(0, 0), extent: room_size, heading: 0, vacate: true, spawn1: true,
        structureopts: {
          count: 1,
          enterance: 0,
        },
        triggers: [
          {
            condition: 'leave',
            conditiontarget: { ty: 'entity', team: 0 },
            action: 'push_rule',
            actiondelay: 0,
            actiontarget: { ty: 'entity', team: 1 },
            actionrules: [{ ty: 'explore' }],
          },
          {
            condition: 'blast',
            conditiontarget: { ty: 'entity', team: 0 },
            action: 'push_rule',
            actiondelay: 30 * 10,
            actiontarget: { ty: 'entity', team: 1 },
            actionrules: [{ ty: 'explore' }],
          }
        ],
      },
      { pos: new v2(0, +size), extent: spawnSize, heading: 0 },
    ],

    goals: [
    ],

    mission_rules: [
      { ty: 'capture' },
      { ty: 'explore' },
    ],
  };
}

export function preset_indoor_response_team0() {
  const preset = preset_indoor();
  for (const entity of preset.entities) {
    if (entity.team === 0) {
      entity.vis_thres = 0.1;
      entity.aware_mult = 0.01;
      entity.response_time = 1;
    }
  }
  return preset;
}

export function preset_indoor_response_team1() {
  const preset = preset_indoor();
  for (const entity of preset.entities) {
    if (entity.team === 0) {
      entity.vis_thres = 0.1;
      entity.aware_mult = 0.01;
      entity.response_time = 1;
    }
  }
  return preset;
}

export function preset_indoor_custom() {
  const team0_tmpl = {
    ...entityFromStat2(stats2_const(7), tmpl_firearm_ar_low),
    life: 34,

    // tier-1 armor
    armor: 33,
    armor_hit_prob: 0.8,
  };

  const team1_tmpl = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_ar_low),
    life: 34,
    team: 1, spawnarea: 1,
    default_rule: { ty: 'idle', alert: false },
    allow_crawl: false,
  };

  const preset = preset_watchwood();
  preset.entities = [
    { ...team0_tmpl },
    { ...team0_tmpl },
    { ...team0_tmpl },

    { ...team1_tmpl },
    { ...team1_tmpl },
    { ...team1_tmpl },
    { ...team1_tmpl },


  ];
  return preset;
}

export function preset_indoor_reloadtest() {
  const preset = preset_indoor();
  for (const entity of preset.entities) {
    entity.firearm_ammo_max = 1;
  }
  return preset;
}

export function preset_indoor_rescue(seed = 256) {
  const preset = preset_indoor();
  preset.world.simover_rule = 'goal';

  for (const e of preset.entities) {
    if (e.team === 0) {
      e.default_rule = 'mission';
    }
  }
  preset.entities.push({ ...VIP_TMPL, spawnarea: 1 });

  const rng = new Rng(seed);
  preset.entities = rng.shuffle(preset.entities);
  preset.goals = [
    { name: 'escape area', area: 2 },
  ];

  return preset;
}

export function preset_indoor_heal() {
  const preset = preset_indoor();
  preset.world.simover_rule = 'goal';

  let [e0, e1] = preset.entities.filter((e) => e.team === 0);
  e1.life = 50;
  e1.life_max = 100;
  e0.spawnarea = 0;

  e1.perk_commed_target_self = true;
  e1.perk_commed_buff = true;
  e1.perk_commed_risk = true;
  e1.spawnarea = 2;
  e1.heals = [
    {
      heal_amount: 10,
      heal_duration: 3,
    },
  ];

  return preset;
}

export function preset_indoor2() {
  const world = {
    width: 600,
    height: 600,
    simover_rule: 'eliminate',
    exp_prepopulate_grid: true,
  };

  const room_size = new v2(260, 260);

  const enemyStat =
  {
    ...STATS2_TMPL,
    decision: 5,
    bravery: 5,
    focus: 5,
    reaction: 5,
    toughness: 5,
    precision: 5,
  };

  const testStat =
  {
    ...STATS2_TMPL,
    decision: 10,
    bravery: 10,
    focus: 10,
    reaction: 20,
    toughness: 5,
    precision: 20,
  };

  const team0_tmpl = {
    ...entityFromStat2(testStat, tmpl_firearm_hg_t4),

    // 현재 소유하고 있는, 던질 수 있는 물건의 목록
    throwables: [
      throwable_impact_frag_mk2,
    ],
  };

  const team1_tmpl = {
    ...entityFromStat2(enemyStat, enemy_tmpl_firearm_ar_low), team: 1, spawnarea: 1,
    life: 40,
    armor: 0,
    armor_hit_prob: 0.4,
    default_rule: 'idle',
    allow_crawl: false,
    allow_door_wait: false,
  };

  return {
    world,

    obstacle_specs: [],

    entities: [
      team0_tmpl,
      team0_tmpl,

      ...new Array(9).fill(team1_tmpl),
    ],

    spawnareas: [
      { pos: new v2(-290, -290), extent: new v2(10, 10), heading: 0, spawn: true },
      {
        pos: new v2(0, 0), extent: room_size, heading: 0, vacate: true, spawn1: true,
        structureopts: {
          count: 1,
          obstacle_count: 30,
          enterance: 0,
        },
        triggers: [
          {
            condition: 'leave',
            conditiontarget: { ty: 'entity', team: 0 },
            action: 'push_rule',
            actiondelay: 0,
            actiontarget: { ty: 'entity', team: 1 },
            actionrules: [{ ty: 'explore' }],
          },
          {
            condition: 'blast',
            conditiontarget: { ty: 'entity', team: 0 },
            action: 'push_rule',
            actiondelay: 30 * 10,
            actiontarget: { ty: 'entity', team: 1 },
            actionrules: [{ ty: 'explore' }],
          }
        ],
      },
      { pos: new v2(250, 250), extent: new v2(10, 10), heading: 0 },
    ],

    goals: [],
    mission_rules: [
      { ty: 'capture' },
      { ty: 'explore' },
    ],
  };
}

export function preset_indoor2_trigger() {
  const preset = preset_indoor2();
  preset.spawnareas[1].triggers = [
    {
      condition: 'fire',
      conditiontarget: { ty: 'entity', team: 0 },
      action: 'push_rule',
      actiondelay: 30 * 15,
      actiontarget: { ty: 'entity', team: 1 },
      actionrules: [{ ty: 'explore' }],
    }
  ];

  return preset;
}

export function preset_indoor2_rescue(seed = 512) {
  const preset = preset_indoor2();
  preset.world.simover_rule = 'goal';

  for (const e of preset.entities) {
    if (e.team === 0) {
      e.default_rule = 'mission';
    }
  }
  preset.entities.push({ ...VIP_TMPL, spawnarea: 1 });
  preset.entities.push({ ...VIP_TMPL, spawnarea: 1 });

  const rng = new Rng(seed);
  preset.entities = rng.shuffle(preset.entities);
  preset.goals = [
    { name: 'escape area', area: 2 },
  ];

  return preset;
}

export function preset_indoor2_heal() {
  const preset = preset_indoor2();
  preset.world.simover_rule = 'goal';

  let e0 = preset.entities.find((e) => e.team === 0);
  e0.heals = [
    {
      heal_amount: 50,
      heal_duration: 3,
    },
  ];

  preset.entities = [
    ...preset.entities,

    ...new Array(3).fill(preset.entities[3]),
  ]

  return preset;
}

export function preset_outdoor_desmar_distrange() {
  const preset = preset_outdoor();

  for (const e of preset.entities) {
    if (e.team === 0) {
      e.perk_desmar_distraction_range = true;
      e.perk_desmar_aimspeed = true;
    }
  }

  return preset;

}

export function preset_outdoor_maze() {
  const world = {
    width: 800,
    height: 800,
    simover_rule: 'eliminate',
  };
  const size = world.height / 2 - 100;

  const spawnSize = new v2(100, 20);

  const team0_tmpl = {
    ...team0_tmpl_agent_ar_mid,
  };

  const team1_tmpl = {
    ...ENTITY_CONFIG_TMPL,
    ...gear_vest_bulletproof_mid,
    ...tmpl_firearm_ar_mid,

    team: 1, spawnarea: 1,
    default_rule: 'idle',
    use_riskdir: true,
  };

  return {
    world,

    obstacle_specs: [
      {
        pos: new v2(0, 0),
        extent: new v2(
          (world.width / 2) - 100,
          (world.height / 2) - 100,
        ),
        ty: 'random',
        random: { ty: 'full', count: 30 },
      },
    ],

    entities: [
      team0_tmpl,
      team0_tmpl,

      team1_tmpl,
      team1_tmpl,
      team1_tmpl,
      team1_tmpl,
    ],

    spawnareas: [
      { pos: new v2(0, -size), extent: spawnSize, heading: 0, spawn: true },
      { pos: new v2(0, +size), extent: spawnSize, heading: 0 },
    ],

    goals: [],
    mission_rules: [{ ty: 'explore' }],
  };
}

export function preset_outdoor_maze_limvis() {
  // maze preset으로부터 시작합니다.
  const preset = preset_outdoor_maze();
  preset.world.exp_search = true;

  // team0 entity에 nvd_t4를 장착합니다.
  for (let i = 0; i < preset.entities.length; i++) {
    let entity = preset.entities[i];
    if (entity.team !== 0) {
      continue;
    }
    preset.entities[i] = {
      ...entity,
      ...gear_nvd_t2,
    };
  }

  // 월드 전체를 덮는 effect area를 추가합니다.
  preset.spawnareas.push({
    pos: v2.unit(0),
    extent: new v2(preset.world.width / 2, preset.world.height / 2),
    heading: 0,
    // 시야가 제한된 구역
    effect_ty: 'limvis',
  });
  return preset;
}

export function preset_outdoor_maze_swamp() {
  // maze preset으로부터 시작합니다.
  const preset = preset_outdoor_maze();

  for (let i = 0; i < preset.entities.length; i++) {
    preset.entities[i].allow_crawl = true;
  }

  // 월드 전체를 덮는 effect area를 추가합니다.
  preset.spawnareas.push({
    pos: v2.unit(0),
    extent: new v2(preset.world.width / 2, preset.world.height / 2),
    heading: 0,
    // 늪지: https://5minlab.slack.com/archives/C03HFUCRMFD/p1668077832947099?thread_ts=1668042144.317669&cid=C03HFUCRMFD
    effect_ty: 'swamp',
  });
  return preset;
}

export function preset_outdoor(opts) {
  const height = opts?.height ?? 1200;
  const enemy_count = opts?.enemy_count ?? 4;
  const world = {
    width: 600,
    height,
    simover_rule: 'eliminate',
  };
  const size = world.height / 2 - 100;

  const spawnSize = new v2(100, 20);

  const team1_tmpl = {
    ...ENTITY_CONFIG_TMPL,
    ...tmpl_firearm_ar_low,

    team: 1, spawnarea: 1,
    default_rule: 'idle',
  };

  return {
    world,

    obstacle_specs: [
      {
        pos: new v2(0, 0),
        extent: new v2(
          (world.width / 2) - 100,
          (world.height / 2) - 100,
        ),
        ty: 'random',
        random: { ty: 'half', count: 30 },
      },
      {
        pos: new v2(0, 0),
        extent: new v2(
          (world.width / 2) - 100,
          (world.height / 2) - 100,
        ),
        ty: 'random',
        random: { ty: 'full', count: 10 },
      },
    ],

    entities: [
      entityFromStat2(stats2_const(15), tmpl_firearm_ar_high),
      {
        ...entityFromStat2(stats2_const(15), tmpl_firearm_sr_high),
        waypoint_policy: 'defensive',
      },

      ...new Array(enemy_count).fill(team1_tmpl),
    ],

    spawnareas: [
      { pos: new v2(0, -size), extent: spawnSize, heading: 0, spawn: true },
      { pos: new v2(0, +size), extent: spawnSize, heading: 0, spawn1: true },
      { pos: new v2(100, +size / 3), extent: spawnSize, heading: 0, spawn1: true },
      { pos: new v2(-100, -size / 3), extent: spawnSize, heading: 0, spawn1: true },
    ],

    goals: [],
    mission_rules: [
      { ty: 'capture' },
      { ty: 'explore' },
    ],
  };
}

export function preset_outdoor_throw() {
  const preset = preset_outdoor({
    height: 800,
  });
  for (const entity of preset.entities) {
    if (entity.team === 0) {
      entity.firearm_range = 100;
      entity.throwable_max_dist = 300;
      entity.throwables = [throwable_fragment, throwable_fragment, throwable_fragment];
    }
  }
  return preset;
}

export function preset_outdoor_smoke0(opts) {
  const preset = preset_outdoor(opts);

  const team0_tmpl = {
    ...entityFromStat2(stats2_const(15), tmpl_firearm_ar_high),
  };

  const team1_tmpl = {
    ...ENTITY_CONFIG_TMPL,
    ...tmpl_firearm_sr_low,

    team: 1, spawnarea: 1,
    dir: 0,
    default_rule: 'idle',
  };

  preset.entities = [
    { ...team0_tmpl },
    { ...team0_tmpl },

    { ...team1_tmpl },
    { ...team1_tmpl },
    { ...team1_tmpl },
    { ...team1_tmpl },
    { ...team1_tmpl },
  ];

  /*
  preset.spawnareas.push({
    pos: v2.unit(0),
    extent: new v2(preset.world.width / 3, preset.world.height / 4),
    heading: 0,
    // 시야가 제한된 구역
    effect_ty: 'smoke',
  });
  */

  preset.blastareas = [];
  for (const y of [-150, 0, 150]) {
    preset.blastareas.push({
      pos: new v2(0, y),
      blast_radius: 200,
      blast_expose_ty: 'full',
      effect_ty: 'smoke',
    });
  }

  return preset;
}

export function preset_outdoor_smoke1(opts) {
  const preset = preset_outdoor_smoke0(opts);

  for (const entity of preset.entities) {
    entity.throwables = [throwable_smoke];
  }
  preset.blastareas = [];

  return preset;
}



export function markLeader(preset) {
  preset.entities = preset.entities.map((e) => ({ ...e }));
  const entities = preset.entities.filter((e) => e.team === 0);
  const leader = entities[0];
  const leaderIdx = preset.entities.indexOf(leader);

  for (const follower of entities) {
    if (leader === follower) {
      continue;
    }
    follower.leader = leaderIdx;
  }

  return preset;
}

export function preset_outdoor_riskrank({ use_riskrank, use_leader, use_mixed }) {
  const preset = preset_outdoor({ enemy_count: 8, height: 1000 });

  let entities = [];
  if (use_mixed) {
    entities = [
      entityFromStat2(stats2_const(15), tmpl_firearm_ar_mid),
      entityFromStat2(stats2_const(15), tmpl_firearm_sg_mid),
      entityFromStat2(stats2_const(15), tmpl_firearm_smg_mid),
      entityFromStat2(stats2_const(15), tmpl_firearm_dmr_mid),
    ];
  } else {
    entities = [
      entityFromStat2(stats2_const(15), tmpl_firearm_ar_mid),
      entityFromStat2(stats2_const(15), tmpl_firearm_ar_mid),
      entityFromStat2(stats2_const(15), tmpl_firearm_ar_mid),
      entityFromStat2(stats2_const(15), tmpl_firearm_ar_mid),
    ];
  }

  if (use_riskrank) {
    entities[0].risk_rank = 2;
    entities[1].risk_rank = 0;
    entities[2].risk_rank = 1;
    entities[3].risk_rank = 3;
  }
  if (use_leader) {
    entities[1].leader = 0;
    entities[2].leader = 0;
    entities[3].leader = 0;
  }

  preset.entities = [
    ...entities,
    ...preset.entities.filter((e) => e.team !== 0),
  ];

  return preset;
}

export function preset_outdoor_rescue() {
  const preset = preset_outdoor();
  preset.world.simover_rule = 'mission';

  for (const e of preset.entities) {
    if (e.team === 0) {
      e.default_rule = 'mission';
    }
  }
  preset.entities.push({ ...VIP_TMPL, spawnarea: 1 });
  preset.goals = [
    { area: 1, name: 'escape point', waypoint: false },
  ];
  return preset;
}

export function preset_outdoor_obs() {
  const preset = preset_outdoor();
  for (const spec of preset.obstacle_specs) {
    spec.random.presets = true;
  }
  return preset;
}

export function preset_forest() {
  const world = {
    width: 600,
    height: 1200,
    simover_rule: 'eliminate',
    // tile_floor: new v2(0, 18),
  };
  const size = world.height / 2 - 100;

  const spawnSize = new v2(100, 20);

  const temp0_tmpl = {
    ...entityFromStat2(stats2_const(15), tmpl_firearm_ar_high),
    allow_cover_edge: true,
  };

  const team1_tmpl = {
    ...ENTITY_CONFIG_TMPL,
    ...tmpl_firearm_ar_low,

    team: 1, spawnarea: 1,
    default_rule: 'idle',
  };

  return {
    world,

    obstacle_specs: [
      {
        pos: new v2(0, 0),
        extent: new v2(250, 250),
        ty: 'random',
        random: { ty: 'full', count: 150, extent: new v2(8, 8) },
      },
      {
        pos: new v2(0, 0),
        extent: new v2(300, 600),
        ty: 'random',
        random: { ty: 'full', count: 10, },
      },
    ],

    entities: [
      temp0_tmpl,
      temp0_tmpl,

      team1_tmpl,
      team1_tmpl,
      team1_tmpl,
      team1_tmpl,
    ],

    spawnareas: [
      { pos: new v2(0, -size), extent: spawnSize, heading: 0, spawn: true },
      { pos: new v2(0, +size), extent: spawnSize, heading: 0 },
    ],

    goals: [],
    mission_rules: [{ ty: 'explore' }],
  };
}

export function preset_forest_sr() {
  const world = {
    width: 600,
    height: 1200,
    simover_rule: 'eliminate',
    exp_search: true,
  };
  const size = world.height / 2 - 100;

  const spawnSize = new v2(100, 20);

  const temp0_tmpl = {
    ...entityFromStat2(stats2_const(15), tmpl_firearm_sr_high),
    allow_cover_edge: true,

    perk_desmar_distraction: true,
    perk_desmar_distraction_range: true,
  };

  const team1_tmpl = {
    ...ENTITY_CONFIG_TMPL,
    ...tmpl_firearm_ar_low,

    team: 1, spawnarea: 1,
    allow_crawl: false,
    default_rule: 'idle',
  };

  return {
    world,

    obstacle_specs: [
      {
        pos: new v2(0, 0),
        extent: new v2(250, 250),
        ty: 'random',
        random: { ty: 'half', count: 150, extent: new v2(8, 8) },
      },
      {
        pos: new v2(0, 0),
        extent: new v2(300, 600),
        ty: 'random',
        random: { ty: 'mixed', count: 20, },
      },
    ],

    entities: [
      temp0_tmpl,

      team1_tmpl,
      team1_tmpl,
      team1_tmpl,
      team1_tmpl,
    ],

    spawnareas: [
      { pos: new v2(0, -size), extent: spawnSize, heading: 0, spawn: true },
      { pos: new v2(0, +size), extent: spawnSize, heading: 0 },
    ],

    goals: [],
    mission_rules: [{ ty: 'explore' }],
  };
}


export function preset_firecontrol() {
  const world = {
    width: 800,
    height: 800,
    simover_rule: 'eliminate',
  };

  const team0_tmpl = {
    ...team0_tmpl_agent_ar_mid,
    speed: 50,
    vis_range: 800,
    firearm_range: 800,
    firearm_aimvar_mult: 0.1,
    allow_fire_control: true,
    allow_coordinated_fire: true,
  };

  const team1_tmpl_base = entityFromStat(STATS_TMPL, tmpl_firearm_ar_low);
  const team1_tmpl = {
    ...team1_tmpl_base,
    // TODO
    ...gear_vest_bulletproof_mid,

    // TODO: 시나리오별 추가 파라미터. 나중에는 스텟 기반으로 다 바꾸어야 함
    life: 50,
    speed: 35,
    vis_range: 300,

    team: 1, spawnarea: 1,
    default_rule: 'idle',
    use_riskdir: true,
  };

  return {
    world,

    spawnareas: [
      { pos: new v2(-66, -346), extent: new v2(115, 17), heading: 0, spawn: true },
      { pos: new v2(-55, 279), extent: new v2(181, 79), heading: 0 },
    ],
    obstacle_specs: [
      { pos: new v2(-39, -263), extent: new v2(309, 12), heading: 0, ty: "half" },
    ],

    entities: [
      team0_tmpl,
      team0_tmpl,
      team0_tmpl,
      team0_tmpl,

      team1_tmpl,
      team1_tmpl,
      team1_tmpl,
      team1_tmpl,
      team1_tmpl,
      team1_tmpl,
      team1_tmpl,
      team1_tmpl,
      team1_tmpl,
      team1_tmpl,
      team1_tmpl,
      team1_tmpl,
      team1_tmpl,
      team1_tmpl,
      team1_tmpl,
      team1_tmpl,
    ],

    goals: [],
    mission_rules: [{ ty: 'explore' }],
  };
}

export function preset_aimvar(args) {
  const dist = args?.dist ?? 300;
  const height = Math.ceil(dist * 3 / 2 / 100) * 100;
  const world = {
    width: 400,
    height: height,
    simover_rule: 'eliminate',
  };

  const team0_tmpl = entityFromStat2(stats2_const(8), tmpl_firearm_ar_low);

  const team1_tmpl_base = entityFromStat(STATS_TMPL, tmpl_firearm_ar_low);
  const team1_tmpl = {
    ...team1_tmpl_base,
    team: 1,
    spawnarea: 1,

    life: 100000,
    default_rule: 'dummy',
  };

  return {
    world,

    spawnareas: [
      { pos: new v2(0, -height / 2 + 20), extent: new v2(10, 10), heading: 0, spawn: true },
      { pos: new v2(0, -height / 2 + 35 + dist), extent: new v2(10, 10), heading: 0 },
    ],
    obstacle_specs: [
      { pos: new v2(0, -height / 2 + 50), extent: new v2(100, 10), heading: 0, ty: "half" },
    ],

    entities: [
      team0_tmpl,

      team1_tmpl,
    ],

    goals: [],
    mission_rules: [{ ty: 'explore' }],
  };
}

export function preset_aimvar_armorstop() {
  const preset = preset_aimvar();

  preset.entities[0] = {
    ...entityFromStat2(stats2_const(8), tmpl_firearm_dmr_high),

    firearm_armor_stop: true,
    firearm_projectile_damage: 1000,
    firearm_projectile_per_shoot: 10,
  }

  preset.entities[1] = {
    ...preset.entities[1],

    life: 100,
    armor: 1,
    armor_hit_prob: 1,
  };

  return preset;
}


export function preset_aimvar_compare(args) {
  const dist = args?.dist ?? 300;
  const height = Math.ceil(dist * 3 / 2 / 100) * 100;
  const world = {
    width: 400,
    height: height,
    simover_rule: 'eliminate',
  };

  const team0_tmpl = {
    ...team0_tmpl_agent_ar_mid,
    spawnarea: 0,
    life: 1000,
  };

  const team1_tmpl_base = entityFromStat(STATS_TMPL, tmpl_firearm_ar_low);
  const team1_tmpl = {
    ...team1_tmpl_base,
    team: 1,
    spawnarea: 2,

    life: 1000,
    default_rule: 'cover',
  };

  return {
    world,

    spawnareas: [
      { pos: new v2(-100, -height / 2 + 20), extent: new v2(10, 10), heading: 0 },
      { pos: new v2(100, -height / 2 + 20), extent: new v2(10, 10), heading: 0 },
      { pos: new v2(-100, -height / 2 + 35 + dist), extent: new v2(10, 10), heading: 0 },
      { pos: new v2(100, -height / 2 + 35 + dist), extent: new v2(10, 10), heading: 0 },
    ],
    obstacle_specs: [
      { pos: new v2(-100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
      { pos: new v2(100, -height / 2 + 50), extent: new v2(80, 10), heading: 0, ty: "half" },
      { pos: new v2(-100, -height / 2 + 15 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
      { pos: new v2(100, -height / 2 + 15 + dist), extent: new v2(80, 10), heading: 0, ty: "half" },
      { pos: new v2(0, 0), extent: new v2(10, height / 2), heading: 0, ty: "full" },
    ],

    entities: [
      team0_tmpl,
      { ...team0_tmpl, spawnarea: 1 },
      team1_tmpl,
      { ...team1_tmpl, spawnarea: 3 },
    ],

    goals: [],
    mission_rules: [{ ty: 'explore' }],
  };
}

export function preset_testedge() {
  const world = {
    width: 800,
    height: 800,
    simover_rule: 'eliminate',
  };

  const team0_tmpl = {
    ...team0_tmpl_agent_ar_mid,
    speed: 50,
    vis_range: 800,
    firearm_range: 800,
    firearm_aimvar_mult: 0.1,
    allow_fire_control: true,
    allow_cover_edge: true,

    firearm_projectile_damage: 0,
  };

  const team1_tmpl_base = entityFromStat(STATS_TMPL, tmpl_firearm_ar_low);
  const team1_tmpl = {
    ...team1_tmpl_base,
    // TODO
    ...gear_vest_bulletproof_mid,

    // TODO: 시나리오별 추가 파라미터. 나중에는 스텟 기반으로 다 바꾸어야 함
    life: 50,
    speed: 35,
    vis_range: 300,

    team: 1, spawnarea: 1,
    default_rule: 'patrol',
    use_riskdir: true,
    allow_crawl: false,

    waypoints: [
      new v2(-100, 179),
      new v2(-200, 179),
    ],
  };

  return {
    world,

    spawnareas: [
      { pos: new v2(-66, -146), extent: new v2(1, 1), heading: 0, spawn: true },
      { pos: new v2(-100, 179), extent: new v2(1, 1), heading: 0 },
    ],
    obstacle_specs: [
      { pos: new v2(-39, -100), extent: new v2(100, 12), extent_margin: new v2(20, 20), heading: 0, ty: "full" },
    ],

    entities: [
      team0_tmpl,
      team1_tmpl,
    ],

    goals: [],
    mission_rules: [{ ty: 'explore' }],
  };
}


export function preset_range() {
  const world = {
    width: 800,
    height: 800,
    simover_rule: 'eliminate',
  };

  const team1_vis = 200;
  const team1_tmpl_spawn2 = {
    ...ENTITY_CONFIG_TMPL,
    ...gear_vest_bulletproof_mid,
    ...tmpl_firearm_ar_low,
    life: 50,
    speed: 25,
    vis_range: team1_vis,

    team: 1, spawnarea: 2,
    default_rule: 'idle',
    use_riskdir: true,
  };

  const team1_tmpl_spawn3 = {
    ...ENTITY_CONFIG_TMPL,
    ...gear_vest_bulletproof_mid,
    ...tmpl_firearm_ar_low,
    life: 50,
    speed: 25,
    vis_range: team1_vis,

    team: 1, spawnarea: 3,
    default_rule: 'idle',
    use_riskdir: true,
  };

  const team1_tmpl_spawn4 = {
    ...ENTITY_CONFIG_TMPL,
    ...gear_vest_bulletproof_mid,
    ...tmpl_firearm_ar_low,
    life: 50,
    speed: 35,
    vis_range: team1_vis,

    team: 1, spawnarea: 4,
    default_rule: 'idle',
    use_riskdir: true,
  };

  const team1_tmpl_spawn5 = {
    ...gear_vest_bulletproof_mid,
    ...tmpl_firearm_ar_low,
    life: 50,
    speed: 35,
    vis_range: team1_vis,

    team: 1, spawnarea: 5,
    default_rule: 'idle',
    use_riskdir: true,
  };

  const team0_tmpl = {
    team: 0,
    allow_crawl: false,
    allow_fire_control: true,
  };
  const team0_tmpl_spawn0 = {
    ...team0_tmpl_agent_ar_high,
    ...team0_tmpl,

    spawnarea: 0,
  };

  const team0_tmpl_spawn1 = {
    ...team0_tmpl_agent_ar_high,
    ...team0_tmpl,

    spawnarea: 1,
  };

  return {
    world,

    spawnareas: [
      { pos: new v2(-204, -358), extent: new v2(117, 15), heading: 0, spawn: true },
      { pos: new v2(207, -358), extent: new v2(117, 22), heading: 0, spawn: true },
      { pos: new v2(-145, 367), extent: new v2(95, 16), heading: 0 },
      { pos: new v2(162, 365), extent: new v2(69, 20), heading: 0 },
      { pos: new v2(-221, 32), extent: new v2(32, 27), heading: 0 },
      { pos: new v2(285, 34), extent: new v2(32, 23), heading: 0 },
    ],
    obstacle_specs: [
      { pos: new v2(-307, -264), extent: new v2(28, 9), heading: -2.7755575615628914e-17, ty: "half" },
      { pos: new v2(-231, -189), extent: new v2(25, 8), heading: -0.2, ty: "half" },
      { pos: new v2(-322, -149), extent: new v2(27, 8), heading: 0, ty: "half" },
      { pos: new v2(-156, -253), extent: new v2(25, 9), heading: 0.5, ty: "half" },
      { pos: new v2(-148, -126), extent: new v2(36, 9), heading: 0.7, ty: "half" },
      { pos: new v2(-229, -46), extent: new v2(80, 13), heading: -0.4, ty: "half" },
      { pos: new v2(-305, 41), extent: new v2(27, 11), heading: 0.1, ty: "half" },
      { pos: new v2(-314, 145), extent: new v2(33, 14), heading: 0.2, ty: "half" },
      { pos: new v2(-175, 168), extent: new v2(53, 10), heading: 0.2, ty: "half" },
      { pos: new v2(-169, 301), extent: new v2(122, 12), heading: 0.2, ty: "half" },
      { pos: new v2(176, 286), extent: new v2(103, 12), heading: -0.4, ty: "half" },
      { pos: new v2(309, -71), extent: new v2(31, 14), heading: 0.4, ty: "half" },
      { pos: new v2(154, -182), extent: new v2(38, 11), heading: -0.30000000000000004, ty: "half" },
      { pos: new v2(222, 65), extent: new v2(29, 14), heading: -1.4000000000000001, ty: "half" },
      { pos: new v2(186, -4), extent: new v2(16, 30), heading: 0.30000000000000004, ty: "half" },
      { pos: new v2(-118, 54), extent: new v2(15, 64), heading: -0.6, ty: "half" },
      { pos: new v2(141, 168), extent: new v2(22, 10), heading: 0, ty: "half" },
      { pos: new v2(229, -281), extent: new v2(23, 9), heading: 0, ty: "half" },
      { pos: new v2(305, -219), extent: new v2(19, 6), heading: -0.5, ty: "half" },
    ],

    entities: [
      team0_tmpl_spawn0,
      team0_tmpl_spawn1,

      team1_tmpl_spawn2,
      team1_tmpl_spawn2,
      team1_tmpl_spawn3,
      team1_tmpl_spawn3,
      team1_tmpl_spawn4,
      team1_tmpl_spawn4,
      team1_tmpl_spawn5,
      team1_tmpl_spawn5,
    ],

    goals: [],
    mission_rules: [{ ty: 'explore' }],
  };
}

export function preset_rescue_metro1() {
  const world = { width: 600, height: 2700, simover_rule: 'goal' };

  const team0_tmpl_ar = {
    ...team0_tmpl_agent_ar_high,

    allow_fire_control: true,
    firearm_range: 500,
  };

  // 적 에이전트 스펙 정의
  const team1_tmpl = {
    ...ENTITY_CONFIG_TMPL, team: 1, spawnarea: 1,
    armor: 1,
    default_rule: 'idle', allow_crawl: false,
    vis_range: 300,

    aim_rot_rules: [
      { aimvar: Math.PI * 2, aimspeed: 0.1 },
    ],
  };

  return {
    world,

    entities: [
      { ...team0_tmpl_ar, risk_rank: 2 },
      { ...team0_tmpl_ar, leader: 0, ...tmpl_firearm_dmr_high, risk_rank: 3 },
      { ...team0_tmpl_ar, leader: 0, ...tmpl_firearm_smg_high, risk_rank: 1 },
      { ...team0_tmpl_ar, leader: 0, ...tmpl_firearm_sg_high, risk_rank: 0 },

      { ...VIP_TMPL, spawnarea: 9 },

      { ...team1_tmpl, spawnarea: 2 },
      { ...team1_tmpl, spawnarea: 2 },

      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },

      { ...team1_tmpl, spawnarea: 4 },

      { ...team1_tmpl, spawnarea: 5 },
      { ...team1_tmpl, spawnarea: 5 },
      { ...team1_tmpl, spawnarea: 5 },

      { ...team1_tmpl, spawnarea: 6 },
      { ...team1_tmpl, spawnarea: 6 },

      { ...team1_tmpl, spawnarea: 7 },
      { ...team1_tmpl, spawnarea: 7 },
      { ...team1_tmpl, spawnarea: 7 },

      { ...team1_tmpl, spawnarea: 8 },
      { ...team1_tmpl, spawnarea: 8 },
      { ...team1_tmpl, spawnarea: 8 },
      { ...team1_tmpl, spawnarea: 8 },

      { ...team1_tmpl, spawnarea: 9 },
      { ...team1_tmpl, spawnarea: 9 },
      { ...team1_tmpl, spawnarea: 9 },

      { ...team1_tmpl, spawnarea: 10 },
      { ...team1_tmpl, spawnarea: 10 },

      { ...team1_tmpl, spawnarea: 11 },
      { ...team1_tmpl, spawnarea: 11 },
      { ...team1_tmpl, spawnarea: 11 },

      { ...team1_tmpl, spawnarea: 12 },
      { ...team1_tmpl, spawnarea: 12 },

      { ...team1_tmpl, spawnarea: 13 },
    ],




    spawnareas: [
      { pos: new v2(166, -1205.400001525879), extent: new v2(34, 20), heading: 0, spawn: true },
      { pos: new v2(-204, -1199.400001525879), extent: new v2(32, 20), heading: 0 },
      { pos: new v2(-179, -764.3999977111816), extent: new v2(22, 16), heading: 0 },
      { pos: new v2(4, -673.3999977111816), extent: new v2(62, 25), heading: 0 },
      { pos: new v2(271, -409.39996337890625), extent: new v2(16, 13), heading: 0 },
      { pos: new v2(174, 25.60003662109375), extent: new v2(24, 19), heading: 0 },
      { pos: new v2(265, 96.4000244140625), extent: new v2(20, 20), heading: 0 },
      { pos: new v2(130, 510.4000244140625), extent: new v2(23, 16), heading: 0 },
      { pos: new v2(135, 959.4000244140625), extent: new v2(35, 21), heading: 0 },
      {
        pos: new v2(-43, 1106.4000244140625), extent: new v2(30, 17), heading: 0, triggers: [
          {
            condition: 'enter',
            conditiontarget: { ty: 'entity', team: 0 },
            action: 'push_rule',
            actiontarget: { ty: 'entity', team: 0 },
            actionrules: [{ ty: 'capture' }],
          }
        ]
      },
      { pos: new v2(-197, 1198.4000244140625), extent: new v2(24, 18), heading: 0 },
      { pos: new v2(-24, 1325.4000244140625), extent: new v2(70, 15), heading: 0 },
      { pos: new v2(181, -749.5999994277954), extent: new v2(21, 16), heading: 0 },
      { pos: new v2(136, 216.4000244140625), extent: new v2(17, 13), heading: 0 },

      {
        pos: new v2(150, -300), extent: new v2(100, 19), heading: 0,
        /*
        triggers: [
          {
            condition: 'enter',
            conditiontarget: { ty: 'entity', team: 0 },
            action: 'spawn_entity',
            actionentities: [
              { ...team1_tmpl, speed: 70, spawnarea: 0, default_rule: 'explore' },
              { ...team1_tmpl, speed: 70, spawnarea: 0, default_rule: 'explore' },
              { ...team1_tmpl, speed: 70, spawnarea: 1, default_rule: 'explore' },
              { ...team1_tmpl, speed: 70, spawnarea: 1, default_rule: 'explore' },
            ],
          }
        ]
        */
      },

    ],
    obstacle_specs: [
      { pos: new v2(171, -1160), extent: new v2(149, 17), heading: 0, ty: "full" },
      { pos: new v2(-40, -1033), extent: new v2(50, 7), heading: 0, ty: "full" },
      { pos: new v2(68, -1030), extent: new v2(22, 8), heading: 0, ty: "full" },
      { pos: new v2(-100, -1006), extent: new v2(10, 34), heading: 0, ty: "full" },
      { pos: new v2(99, -1004), extent: new v2(10, 33), heading: 0, ty: "full" },
      { pos: new v2(0, -962), extent: new v2(110, 8), heading: 0, ty: "half" },
      { pos: new v2(-86, -846), extent: new v2(18, 17), heading: 0, ty: "full" },
      { pos: new v2(87, -845), extent: new v2(18, 18), heading: 0, ty: "full" },
      { pos: new v2(-85, -744), extent: new v2(19, 16), heading: 0, ty: "full" },
      { pos: new v2(87, -743), extent: new v2(19, 17), heading: 0, ty: "full" },
      { pos: new v2(-112, -843), extent: new v2(4, 12), heading: 0, ty: "half" },
      { pos: new v2(-60, -844), extent: new v2(4, 13), heading: 0, ty: "half" },
      { pos: new v2(-85, -819), extent: new v2(13, 4), heading: 0, ty: "half" },
      { pos: new v2(-86, -872), extent: new v2(13, 5), heading: 0, ty: "half" },
      { pos: new v2(87, -871), extent: new v2(15, 3), heading: 0, ty: "half" },
      { pos: new v2(88, -820), extent: new v2(14, 3), heading: 0, ty: "half" },
      { pos: new v2(116, -843), extent: new v2(4, 14), heading: 0, ty: "half" },
      { pos: new v2(58, -844), extent: new v2(5, 15), heading: 0, ty: "half" },
      { pos: new v2(87, -768), extent: new v2(14, 4), heading: 0, ty: "half" },
      { pos: new v2(116, -743), extent: new v2(4, 11), heading: 0, ty: "half" },
      { pos: new v2(89, -718), extent: new v2(15, 3), heading: 0, ty: "half" },
      { pos: new v2(59, -743), extent: new v2(4, 14), heading: 0, ty: "half" },
      { pos: new v2(-86, -769), extent: new v2(16, 5), heading: 0, ty: "half" },
      { pos: new v2(-56, -743), extent: new v2(5, 12), heading: 0, ty: "half" },
      { pos: new v2(-84, -719), extent: new v2(14, 4), heading: 0, ty: "half" },
      { pos: new v2(-113, -744), extent: new v2(3, 15), heading: 0, ty: "half" },
      { pos: new v2(-226, -845), extent: new v2(8, 41), heading: 0, ty: "half" },
      { pos: new v2(-224, -722), extent: new v2(9, 39), heading: 0, ty: "half" },
      { pos: new v2(226, -728), extent: new v2(8, 42), heading: 0, ty: "half" },
      { pos: new v2(226, -845), extent: new v2(8, 34), heading: 0, ty: "half" },
      { pos: new v2(172, -793), extent: new v2(6, 18), heading: -0.6, ty: "half" },
      { pos: new v2(153, -712), extent: new v2(18, 5), heading: 0.4, ty: "half" },
      { pos: new v2(34, -811), extent: new v2(21, 5), heading: 0.30000000000000004, ty: "half" },
      { pos: new v2(-39, -796), extent: new v2(23, 6), heading: -0.30000000000000004, ty: "half" },
      { pos: new v2(-7, -880), extent: new v2(10, 7), heading: 0, ty: "half" },
      { pos: new v2(69, -927), extent: new v2(6, 5), heading: 0, ty: "half" },
      { pos: new v2(-48, -921), extent: new v2(17, 4), heading: -0.1, ty: "half" },
      { pos: new v2(-136, -797), extent: new v2(4, 11), heading: -0.2, ty: "half" },
      { pos: new v2(-141, -689), extent: new v2(14, 7), heading: -0.30000000000000004, ty: "half" },
      { pos: new v2(227, -791), extent: new v2(10, 11), heading: 0, ty: "full" },
      { pos: new v2(-226, -784), extent: new v2(11, 11), heading: 0, ty: "full" },
      { pos: new v2(-158, -437), extent: new v2(26, 23), heading: 0, ty: "full" },
      { pos: new v2(-106, -430), extent: new v2(10, 12), heading: 0, ty: "full" },
      { pos: new v2(-118, -450), extent: new v2(17, 16), heading: 0, ty: "full" },
      { pos: new v2(-172, -1157.400001525879), extent: new v2(105, 15), heading: 0, ty: "full" },
      { pos: new v2(-241, -1077.400001525879), extent: new v2(22, 21), heading: 0, ty: "full" },
      { pos: new v2(-241, -1009.4000015258789), extent: new v2(23, 23), heading: 0, ty: "full" },
      { pos: new v2(-206, -1009.4000015258789), extent: new v2(8, 22), heading: 0, ty: "half" },
      { pos: new v2(-207, -1079.400001525879), extent: new v2(7, 24), heading: 0, ty: "half" },
      { pos: new v2(-240, -943.4000015258789), extent: new v2(25, 22), heading: 0, ty: "full" },
      { pos: new v2(-203, -943.4000015258789), extent: new v2(7, 22), heading: 0, ty: "half" },
      { pos: new v2(244, -1076.400001525879), extent: new v2(22, 20), heading: 0, ty: "full" },
      { pos: new v2(211, -1076.400001525879), extent: new v2(7, 18), heading: 0, ty: "half" },
      { pos: new v2(245, -1010.4000015258789), extent: new v2(23, 21), heading: 0, ty: "full" },
      { pos: new v2(212, -1009.4000015258789), extent: new v2(7, 21), heading: 0, ty: "half" },
      { pos: new v2(246, -941.4000015258789), extent: new v2(23, 22), heading: 0, ty: "full" },
      { pos: new v2(211, -940.4000015258789), extent: new v2(7, 22), heading: 0, ty: "half" },
      { pos: new v2(140, -1056.400001525879), extent: new v2(23, 7), heading: -0.25, ty: "half" },
      { pos: new v2(7, -1073.400001525879), extent: new v2(16, 6), heading: 0, ty: "half" },
      { pos: new v2(-120, -1089.400001525879), extent: new v2(10, 9), heading: 0, ty: "half" },
      { pos: new v2(9, -526.3999977111816), extent: new v2(110, 99), heading: 0, ty: "full" },
      { pos: new v2(-241, -530.3999977111816), extent: new v2(71, 94), heading: 0, ty: "full" },
      { pos: new v2(314, -525.3999977111816), extent: new v2(123, 99), heading: 0, ty: "full" },
      { pos: new v2(156, -525.3999977111816), extent: new v2(8, 99), heading: 0, ty: "half" },
      { pos: new v2(156, -404.39999771118164), extent: new v2(21, 4), heading: 0, ty: "half" },
      { pos: new v2(272, -145.39999389648438), extent: new v2(39, 201), heading: 0, ty: "full" },
      { pos: new v2(221, -314.3999938964844), extent: new v2(6, 15), heading: 0, ty: "half" },
      { pos: new v2(224, 226.60003662109375), extent: new v2(5, 18), heading: 0, ty: "half" },
      { pos: new v2(224, 268.4000244140625), extent: new v2(5, 18), heading: 0, ty: "half" },
      { pos: new v2(-10, 786.4000091552734), extent: new v2(112, 93), heading: 0, ty: "full" },
      { pos: new v2(261, 786.4000244140625), extent: new v2(95, 93), heading: 0, ty: "full" },
      { pos: new v2(-283, 788.5999755859375), extent: new v2(96, 93), heading: 0, ty: "full" },
      { pos: new v2(-181, 881.4000244140625), extent: new v2(28, 21), heading: 0, ty: "full" },
      { pos: new v2(-135, 867.4000244140625), extent: new v2(19, 22), heading: 0, ty: "full" },
      { pos: new v2(-138, 893.4000244140625), extent: new v2(15, 13), heading: 0, ty: "full" },
      { pos: new v2(134, 786.4000244140625), extent: new v2(8, 93), heading: 0, ty: "half" },
      { pos: new v2(135, 677.4000244140625), extent: new v2(24, 3), heading: 0, ty: "half" },
      { pos: new v2(6, -636.4000015258789), extent: new v2(78, 6), heading: 0, ty: "half" },
      { pos: new v2(-22, 1066.5999755859375), extent: new v2(98, 11), heading: 0, ty: "half" },
      { pos: new v2(-112, 1111.5999755859375), extent: new v2(9, 33), heading: 0, ty: "full" },
      { pos: new v2(64, 1109.5999755859375), extent: new v2(11, 32), heading: 0, ty: "full" },
      { pos: new v2(-1, 1148.5999755859375), extent: new v2(76, 11), heading: 0, ty: "full" },
      { pos: new v2(284, 393.5999755859375), extent: new v2(49, 232), heading: 0, ty: "full" },
      { pos: new v2(127, 49.60003662109375), extent: new v2(12, 11), heading: 0, ty: "half" },
      { pos: new v2(198, 179.5999755859375), extent: new v2(28, 15), heading: -0.375, ty: "half" },
      { pos: new v2(221, -274.39996337890625), extent: new v2(4, 17), heading: 0, ty: "half" },
      { pos: new v2(222, -234.39996337890625), extent: new v2(5, 16), heading: 0, ty: "half" },
      { pos: new v2(219, -68.39996337890625), extent: new v2(6, 35), heading: 0, ty: "half" },
      { pos: new v2(223, 12.60003662109375), extent: new v2(6, 6), heading: 0, ty: "half" },
      { pos: new v2(223, 31.60003662109375), extent: new v2(6, 6), heading: 0, ty: "half" },
      { pos: new v2(222, 391.60003662109375), extent: new v2(8, 39), heading: 0, ty: "half" },
      { pos: new v2(198, 480.5999755859375), extent: new v2(26, 12), heading: -0.375, ty: "half" },
      { pos: new v2(201, 521.5999755859375), extent: new v2(22, 11), heading: 0.125, ty: "half" },
      { pos: new v2(222, 552.5999755859375), extent: new v2(6, 15), heading: 0, ty: "half" },
      { pos: new v2(92, -77.39996337890625), extent: new v2(9, 9), heading: 0, ty: "half" },
      { pos: new v2(166, -15.39996337890625), extent: new v2(23, 6), heading: 0.5, ty: "half" },
      { pos: new v2(107, 295.60003662109375), extent: new v2(24, 7), heading: 0.25, ty: "half" },
      { pos: new v2(139, 389.60003662109375), extent: new v2(18, 5), heading: 0.75, ty: "half" },
      { pos: new v2(227, 89.60003662109375), extent: new v2(4, 28), heading: -0.25, ty: "half" },
      { pos: new v2(234, 137.60003662109375), extent: new v2(4, 11), heading: 0.375, ty: "half" },
      { pos: new v2(-168, 159.4000244140625), extent: new v2(235, 610), heading: 0, ty: "full" },
      { pos: new v2(-103, -481.6000061035156), extent: new v2(20, 20), heading: 0, ty: "full" },
      { pos: new v2(-157, 809.4000244140625), extent: new v2(52, 56), heading: 0, ty: "full" },
      { pos: new v2(127, 904.4000244140625), extent: new v2(6, 7), heading: -0.25, ty: "half" },
      { pos: new v2(159, 906.4000244140625), extent: new v2(9, 4), heading: -0.375, ty: "half" },
      { pos: new v2(107, 915.4000244140625), extent: new v2(8, 6), heading: 0, ty: "half" },
      { pos: new v2(153, -94.60000610351562), extent: new v2(22, 4), heading: -0.125, ty: "half" },
      { pos: new v2(129, -165.5999755859375), extent: new v2(13, 4), heading: 0.25, ty: "half" },
      { pos: new v2(170, -151.5999755859375), extent: new v2(12, 4), heading: -0.5, ty: "half" },
      { pos: new v2(185, 80.4000244140625), extent: new v2(7, 33), heading: -0.875, ty: "full" },
      { pos: new v2(114, 193.4000244140625), extent: new v2(36, 4), heading: 0.25, ty: "half" },
      { pos: new v2(92, 90.4000244140625), extent: new v2(11, 6), heading: 0.25, ty: "half" },
      { pos: new v2(124, 130.4000244140625), extent: new v2(6, 7), heading: 0, ty: "half" },
      { pos: new v2(114, -332.3999938964844), extent: new v2(23, 4), heading: 0.375, ty: "half" },
      { pos: new v2(154, -234.39999389648438), extent: new v2(21, 18), heading: 0, ty: "half" },
      { pos: new v2(240, -365.3999938964844), extent: new v2(7, 24), heading: 0, ty: "full" },
      { pos: new v2(109, 481.4000244140625), extent: new v2(9, 5), heading: 0.875, ty: "half" },
      { pos: new v2(246, 941.4000244140625), extent: new v2(23, 21), heading: 0, ty: "full" },
      { pos: new v2(-82, -1273.400001525879), extent: new v2(15, 108), heading: 0, ty: "full" },
      { pos: new v2(258, -1300.400001525879), extent: new v2(238, 52), heading: 0, ty: "full" },
      { pos: new v2(247, 1021.4000244140625), extent: new v2(24, 22), heading: 0, ty: "full" },
      { pos: new v2(246, 1101.4000244140625), extent: new v2(25, 23), heading: 0, ty: "full" },
      { pos: new v2(-250, 946.4000244140625), extent: new v2(24, 23), heading: 0, ty: "full" },
      { pos: new v2(-246, 1020.4000244140625), extent: new v2(24, 24), heading: 0, ty: "full" },
      { pos: new v2(-247, 1100.4000244140625), extent: new v2(26, 23), heading: 0, ty: "full" },
      { pos: new v2(214, 942.4000244140625), extent: new v2(6, 20), heading: 0, ty: "half" },
      { pos: new v2(214, 1021.4000244140625), extent: new v2(6, 20), heading: 0, ty: "half" },
      { pos: new v2(211, 1100.4000244140625), extent: new v2(7, 20), heading: 0, ty: "half" },
      { pos: new v2(-208, 1100.4000244140625), extent: new v2(8, 22), heading: 0, ty: "half" },
      { pos: new v2(-210, 1020.4000244140625), extent: new v2(8, 24), heading: 0, ty: "half" },
      { pos: new v2(-216, 947.4000244140625), extent: new v2(6, 25), heading: 0, ty: "half" },
      { pos: new v2(255, 1184.4000244140625), extent: new v2(8, 45), heading: 0, ty: "half" },
      { pos: new v2(254, 1248.4000244140625), extent: new v2(9, 11), heading: 0, ty: "full" },
      { pos: new v2(254, 1299.4000244140625), extent: new v2(10, 31), heading: 0, ty: "half" },
      { pos: new v2(-104, 1227.4000244140625), extent: new v2(22, 22), heading: 0, ty: "full" },
      { pos: new v2(69, 1226.4000244140625), extent: new v2(23, 22), heading: 0, ty: "full" },
      { pos: new v2(103, 1226.4000244140625), extent: new v2(7, 20), heading: 0, ty: "half" },
      { pos: new v2(69, 1257.4000244140625), extent: new v2(21, 7), heading: 0, ty: "half" },
      { pos: new v2(37, 1226.4000244140625), extent: new v2(6, 18), heading: 0, ty: "half" },
      { pos: new v2(69, 1196.4000244140625), extent: new v2(23, 6), heading: 0, ty: "half" },
      { pos: new v2(-71, 1227.4000244140625), extent: new v2(7, 19), heading: 0, ty: "half" },
      { pos: new v2(-104, 1198.4000244140625), extent: new v2(23, 6), heading: 0, ty: "half" },
      { pos: new v2(-137, 1227.4000244140625), extent: new v2(8, 19), heading: 0, ty: "half" },
      { pos: new v2(-104, 1257.4000244140625), extent: new v2(21, 6), heading: 0, ty: "half" },
      { pos: new v2(-35, 950.4000244140625), extent: new v2(79, 7), heading: 0.25, ty: "full" },
      { pos: new v2(125, 1034.4000244140625), extent: new v2(6, 35), heading: 0.75, ty: "full" },
      { pos: new v2(73, 933.4000244140625), extent: new v2(17, 3), heading: 0.375, ty: "half" },
      { pos: new v2(-139, 1006.4000244140625), extent: new v2(7, 14), heading: -0.75, ty: "half" },
      { pos: new v2(-161, 1058.4000244140625), extent: new v2(6, 16), heading: 0, ty: "half" },
      { pos: new v2(157, 1094.4000244140625), extent: new v2(3, 28), heading: 0.25, ty: "half" },
      { pos: new v2(137, 1294.4000244140625), extent: new v2(24, 4), heading: -0.875, ty: "half" },
      { pos: new v2(-16, 1203.4000244140625), extent: new v2(12, 4), heading: -0.25, ty: "half" },
      { pos: new v2(-191, 1283.4000244140625), extent: new v2(8, 21), heading: -0.375, ty: "half" },
      { pos: new v2(-183, 1234.4000244140625), extent: new v2(7, 8), heading: -0.125, ty: "half" },
      { pos: new v2(-251, 1240.4000244140625), extent: new v2(12, 13), heading: 0, ty: "full" },
      { pos: new v2(-251, 1199.4000244140625), extent: new v2(10, 23), heading: -0.125, ty: "half" },
      { pos: new v2(-219, 1163.4000244140625), extent: new v2(6, 20), heading: -0.75, ty: "half" },
      { pos: new v2(-243, 1166.4000244140625), extent: new v2(5, 6), heading: 0.25, ty: "half" },
      { pos: new v2(-251, 1292.4000244140625), extent: new v2(11, 32), heading: 0, ty: "half" },
      { pos: new v2(-154, 1322.4000244140625), extent: new v2(13, 4), heading: -0.875, ty: "half" },
      { pos: new v2(170, 1181.4000244140625), extent: new v2(23, 4), heading: -0.5, ty: "half" },
      { pos: new v2(190, 1247.4000244140625), extent: new v2(16, 10), heading: -0.25, ty: "half" },
      { pos: new v2(-22, -1148.400001525879), extent: new v2(24, 6), heading: 0, ty: "half" },
      { pos: new v2(-158, -901.4000015258789), extent: new v2(20, 4), heading: 0.375, ty: "half" },
      { pos: new v2(-120, -907.4000015258789), extent: new v2(11, 5), heading: -0.25, ty: "half" },
      { pos: new v2(-172, 1159.4000244140625), extent: new v2(27, 7), heading: -0.625, ty: "half" },
      { pos: new v2(126, 553.4000244140625), extent: new v2(33, 5), heading: 0.25, ty: "half" },
      { pos: new v2(156, 627.4000244140625), extent: new v2(16, 13), heading: 0.125, ty: "half" },
      { pos: new v2(191, 629.4000244140625), extent: new v2(12, 13), heading: -0.375, ty: "half" },
    ],
    goals: [
      { name: 'escape area', area: 11 },
    ],

    mission_rules: [
      { ty: 'explore' },
    ],

    editor: true
  };
}

export function preset_rescue_metro_sr() {
  const base = preset_rescue_metro1();

  const team0_tmpl_sr = entityFromStat(STATS_TMPL_MAX, tmpl_firearm_sr_high);

  const team0_tmpl_ar = {
    ...team0_tmpl_agent_ar_high,
    speed: 20,

    allow_fire_control: true,
    firearm_range: 500,
  };

  return {
    ...base,
    entities: [
      {
        ...team0_tmpl_sr,
        spawnarea: 1,
        allow_fire_control: true,
        firearm_projectile_damage: 101,
        vis_range: 700,
        perk_targetpref_low: true,
        perk_shoot_ignore_obstructed: true,
      },
      team0_tmpl_ar,
      ...base.entities.filter((e) => e.team > 0)
    ],
    editor: false,
  };
}

export function preset_rescue_metro_test() {
  const world = { width: 500, height: 2500 };

  const team0_tmpl = {
    ...team0_tmpl_agent_ar_high,
    speed: 20,
    allow_fire_control: true,
    firearm_range: 500,
  };

  // 적 에이전트 스펙 정의
  const team1_tmpl = {
    ...ENTITY_CONFIG_TMPL, team: 1, spawnarea: 1,
    speed: 20,
    armor: 1,
    default_rule: 'idle', allow_crawl: false,
    vis_range: 300,

    aim_rot_rules: [
      { aimvar: Math.PI * 2, aimspeed: 0.1 },
    ],
  };

  return {
    world,

    entities: [
      team0_tmpl,
      team0_tmpl,
      team0_tmpl,

      { ...VIP_TMPL, spawnarea: 4 },

      { ...team1_tmpl, spawnarea: 1 },
      { ...team1_tmpl, spawnarea: 1 },
      { ...team1_tmpl, spawnarea: 1 },

      { ...team1_tmpl, spawnarea: 2 },
      { ...team1_tmpl, spawnarea: 2 },
      { ...team1_tmpl, spawnarea: 2 },

      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },

      { ...team1_tmpl, spawnarea: 4 },
      { ...team1_tmpl, spawnarea: 4 },
      { ...team1_tmpl, spawnarea: 4 },
      { ...team1_tmpl, spawnarea: 4 },
      { ...team1_tmpl, spawnarea: 4 },

      { ...team1_tmpl, spawnarea: 5 },
      { ...team1_tmpl, spawnarea: 5 },

      { ...team1_tmpl, spawnarea: 6 },
    ],



    spawnareas: [
      { pos: new v2(12, -1128), extent: new v2(70, 21), heading: 0, spawn: true },
      { pos: new v2(-134, -903), extent: new v2(67, 20), heading: 0 },
      { pos: new v2(67, -797), extent: new v2(88, 54), heading: 0.1 },
      { pos: new v2(0, -253), extent: new v2(97, 62), heading: 0 },
      { pos: new v2(-11, 454), extent: new v2(103, 71), heading: -2.7755575615628914e-17 },
      { pos: new v2(72, 765), extent: new v2(63, 32), heading: 0 },
      { pos: new v2(-138, 1035), extent: new v2(88, 54), heading: 0 },
    ],
    obstacle_specs: [
      { pos: new v2(16, -1077), extent: new v2(128, 16), heading: 0, ty: "half" },
      { pos: new v2(-99, -1129), extent: new v2(16, 33), heading: 0, ty: "full" },
      { pos: new v2(-23, -1176), extent: new v2(92, 13), heading: 0, ty: "full" },
      { pos: new v2(134, -1127), extent: new v2(11, 33), heading: 0, ty: "full" },
      { pos: new v2(125, -1172), extent: new v2(20, 11), heading: 0, ty: "full" },
    ],

    goals: [
      { name: 'escape area', area: 6 },
    ],

    mission_rules: [
      { ty: 'explore' },
    ],
  };
}

export function preset_rescue_metro_heal() {
  const preset = preset_rescue_metro1();

  let e0 = preset.entities.find((e) => e.team === 0);
  e0.heals = [
    {
      heal_amount: 50,
      heal_duration: 3,
    },
  ];

  return preset;
}

export function preset_duel() {
  const world = {
    width: 600,
    height: 600,
    simover_rule: 'eliminate',
    exp_prepopulate_grid: false,
  };

  const spawnSize = new v2(20, 100);

  const team0_tmpl_base = entityFromStat(team0_STATS_TMPL, tmpl_firearm_ar_mid);
  const team1_tmpl_base = entityFromStat(team1_STATS_TMPL, tmpl_firearm_ar_mid);

  const team0_tmpl = {
    ...team0_tmpl_base,
    // TODO
    ...gear_vest_bulletproof_mid,

    // TODO: 시나리오별 추가 파라미터. 나중에는 스텟 기반으로 다 바꾸어야 함
    life: 100,
    speed: 20,
    vis_range: 300,

    team: 0, spawnarea: 0,
    default_rule: 'explore',
  };

  const team1_tmpl = {
    ...team1_tmpl_base,
    // TODO
    ...gear_vest_bulletproof_mid,

    // TODO: 시나리오별 추가 파라미터. 나중에는 스텟 기반으로 다 바꾸어야 함
    life: 100,
    speed: 20,
    vis_range: 300,

    team: 1, spawnarea: 1,
    default_rule: 'explore',
  };

  return {
    world,

    entities: [
      team0_tmpl,

      team1_tmpl,
    ],

    spawnareas: [
      { pos: new v2(-200, 0), extent: spawnSize, heading: 0, spawn: true },
      { pos: new v2(200, 0), extent: spawnSize, heading: 0 },
    ],

    obstacle_specs: [
      { pos: new v2(0, 0), extent: spawnSize, heading: 0, ty: 'full' },
    ],

    goals: [
    ],

    mission_rules: [
      { ty: 'explore' },
    ],
  };
}

export function preset_duel_slope() {
  const preset = preset_duel();

  // 월드 전체를 덮는 effect area를 추가합니다.
  preset.spawnareas.push({
    pos: v2.unit(0),
    extent: new v2(preset.world.width / 2, preset.world.height / 2),
    heading: 0,
    // 시야가 제한된 구역
    effect_ty: 'slope',
    effect_slope_vec: new v2(1, 0),
  });
  return preset;
}

export function preset_indoor_sgtest() {
  const world = {
    width: 600,
    height: 600,
    simover_rule: 'eliminate',
    exp_prepopulate_grid: true,
  };

  const size = world.height / 2 - 100;
  const spawnSize = new v2(20, 100);

  const room_size = new v2(150, 150);

  const team0_tmpl_base = entityFromStat(team0_STATS_TMPL, tmpl_firearm_sg_mid);
  const team1_tmpl_base = entityFromStat(team1_STATS_TMPL, tmpl_firearm_smg_mid);

  const team0_tmpl = {
    ...team0_tmpl_base,
    // TODO
    ...gear_vest_bulletproof_mid,

    // TODO: 시나리오별 추가 파라미터. 나중에는 스텟 기반으로 다 바꾸어야 함
    life: 100,
    speed: 20,
    vis_range: 300,

    team: 0, spawnarea: 0,
  };

  const team1_tmpl = {
    ...team1_tmpl_base,
    // TODO
    ...gear_vest_bulletproof_mid,

    // TODO: 시나리오별 추가 파라미터. 나중에는 스텟 기반으로 다 바꾸어야 함
    life: 100,
    speed: 20,
    vis_range: 300,

    team: 1, spawnarea: 1,
    default_rule: 'idle',
  };

  return {
    world,

    obstacle_specs: [{
      pos: new v2(0, 0),
      extent: new v2(
        (world.width / 2) - 100,
        (world.height / 2) - 100,
      ),
      ty: 'random',
      random: { ty: 'mixed', count: 0, },
    }],

    entities: [
      team0_tmpl,
      team0_tmpl,

      team1_tmpl,
      team1_tmpl,
    ],

    spawnareas: [
      { pos: new v2(0, -size), extent: spawnSize, heading: 1.57, spawn: true },
      {
        pos: new v2(0, 0), extent: room_size, heading: 0, vacate: true,
        structureopts: {
          count: 1,
          obstacle_count: 10,
        }
      },
      { pos: new v2(0, +size), extent: spawnSize, heading: 1.57 },
    ],

    goals: [
    ],

    mission_rules: [
      { ty: 'explore' },
    ],
  };
}

export function preset_rescue_embassy() {
  const world = { width: 700, height: 2700 };

  const team0_tmpl_ar = {
    ...team0_tmpl_agent_ar_high,

    allow_fire_control: true,
    firearm_range: 500,
  };

  // 적 에이전트 스펙 정의
  const team1_tmpl = {
    ...ENTITY_CONFIG_TMPL, team: 1, spawnarea: 1,
    armor: 1,
    default_rule: 'idle', allow_crawl: false,
    vis_range: 300,

    aim_rot_rules: [
      { aimvar: Math.PI * 2, aimspeed: 0.1 },
    ],
  };

  const team0_area0 = { ...team0_tmpl_ar, spawnarea: 0, vis_group: 1 };
  const team0_area1 = { ...team0_tmpl_ar, spawnarea: 1, vis_group: 2 };

  return {
    world,

    entities: [
      team0_area0,
      team0_area0,
      team0_area1,
      team0_area1,

      { ...VIP_TMPL, spawnarea: 12 },

      { ...team1_tmpl, spawnarea: 2 },
      { ...team1_tmpl, spawnarea: 2 },

      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },

      { ...team1_tmpl, spawnarea: 4 },
      { ...team1_tmpl, spawnarea: 4 },

      { ...team1_tmpl, spawnarea: 5 },
      { ...team1_tmpl, spawnarea: 5 },

      { ...team1_tmpl, spawnarea: 6 },
      { ...team1_tmpl, spawnarea: 6 },

      { ...team1_tmpl, spawnarea: 7 },
      { ...team1_tmpl, spawnarea: 7 },
      { ...team1_tmpl, spawnarea: 7 },

      { ...team1_tmpl, spawnarea: 8 },
      { ...team1_tmpl, spawnarea: 8 },

      { ...team1_tmpl, spawnarea: 9 },

      { ...team1_tmpl, spawnarea: 10 },
      { ...team1_tmpl, spawnarea: 10 },

      { ...team1_tmpl, spawnarea: 11 },
      { ...team1_tmpl, spawnarea: 11 },

      { ...team1_tmpl, spawnarea: 12 },
      { ...team1_tmpl, spawnarea: 12 },
      { ...team1_tmpl, spawnarea: 12 },
      { ...team1_tmpl, spawnarea: 12 },
      { ...team1_tmpl, spawnarea: 12 },

      { ...team1_tmpl, spawnarea: 13 },

      { ...team1_tmpl, spawnarea: 14 },
      { ...team1_tmpl, spawnarea: 14 },
      { ...team1_tmpl, spawnarea: 14 },

      { ...team1_tmpl, spawnarea: 15 },
      { ...team1_tmpl, spawnarea: 15 },
    ],

    spawnareas: [
      { pos: new v2(-291, -1298), extent: new v2(35, 29), heading: 0, spawn: true },
      { pos: new v2(221, -1299), extent: new v2(28, 21), heading: 0, spawn: true },
      { pos: new v2(-322, -946), extent: new v2(14, 20), heading: 0 },
      { pos: new v2(-49, -1062), extent: new v2(29, 20), heading: 0 },
      { pos: new v2(285, -1043), extent: new v2(26, 20), heading: 0 },
      { pos: new v2(-89, -657), extent: new v2(41, 15), heading: 0 },
      { pos: new v2(-79, -249), extent: new v2(27, 20), heading: 0 },
      { pos: new v2(269, -94), extent: new v2(43, 21), heading: 0 },
      { pos: new v2(70, 32), extent: new v2(35, 12), heading: 0 },
      { pos: new v2(-97, 10), extent: new v2(20, 16), heading: 0 },
      { pos: new v2(-189, 230), extent: new v2(15, 28), heading: 0 },
      { pos: new v2(76, 442), extent: new v2(28, 10), heading: 0 },
      {
        pos: new v2(34, 237), extent: new v2(194, 187), heading: 0,
        structureopts: { count: 1 },
        triggers: [
          {
            condition: 'enter',
            conditiontarget: {
              ty: 'entity',
              team: 0,
            },

            action: 'push_rule',
            actiontarget: {
              ty: 'entity',
              team: 0,
            },
            actionrules: [{ ty: 'explore', area: 12 }],
          }
        ],
      },
      { pos: new v2(-200, 585), extent: new v2(24, 22), heading: 0.2 },
      { pos: new v2(-54, 1125), extent: new v2(20, 37), heading: 0 },
      { pos: new v2(105, 1123), extent: new v2(20, 39), heading: 0 },
      { pos: new v2(24, 1319), extent: new v2(21, 17), heading: 0 },
      {
        pos: new v2(9, 661), extent: new v2(465, 21), heading: 0,
        triggers: [
          {
            condition: 'enter',
            conditiontarget: { ty: 'entity', team: 0 },
            action: 'push_rule',
            actiontarget: { ty: 'entity', team: 0 },
            actionrules: [{ ty: 'capture' }],
          }
        ]
      },
    ],
    obstacle_specs: [
      { pos: new v2(-288, -1105), extent: new v2(68, 91), heading: 0, ty: "full" },
      { pos: new v2(151, -1273), extent: new v2(20, 85), heading: 0, ty: "full" },
      { pos: new v2(316, -1288), extent: new v2(41, 98), heading: 0, ty: "full" },
      { pos: new v2(161, -1175), extent: new v2(30, 16), heading: 0, ty: "full" },
      { pos: new v2(256, -1095), extent: new v2(125, 17), heading: 0, ty: "full" },
      { pos: new v2(149, -1006), extent: new v2(18, 76), heading: 0, ty: "full" },
      { pos: new v2(339, -920), extent: new v2(21, 88), heading: 0, ty: "full" },
      { pos: new v2(360, -902), extent: new v2(16, 109), heading: -0.7999999999999999, ty: "full" },
      { pos: new v2(-255, -832), extent: new v2(117, 21), heading: 0, ty: "full" },
      { pos: new v2(-162, -1182), extent: new v2(22, 287), heading: 0, ty: "full" },
      { pos: new v2(31, -944), extent: new v2(26, 6), heading: 0.4, ty: "half" },
      { pos: new v2(8, -980), extent: new v2(50, 6), heading: 0.4, ty: "half" },
      { pos: new v2(-229, -953), extent: new v2(5, 17), heading: 0.2, ty: "half" },
      { pos: new v2(-252, -886), extent: new v2(4, 19), heading: 0.1, ty: "half" },
      { pos: new v2(219, -1043), extent: new v2(5, 34), heading: 0, ty: "half" },
      { pos: new v2(239, -983), extent: new v2(18, 5), heading: 0, ty: "half" },
      { pos: new v2(244, -939), extent: new v2(4, 28), heading: 0.4, ty: "half" },
      { pos: new v2(228, -876), extent: new v2(8, 7), heading: -0.2, ty: "half" },
      { pos: new v2(-201, -1068), extent: new v2(12, 6), heading: -0.4, ty: "half" },
      { pos: new v2(-274, -943), extent: new v2(7, 40), heading: 0, ty: "half" },
      { pos: new v2(-333, -978), extent: new v2(26, 7), heading: 0, ty: "half" },
      { pos: new v2(-340, -910), extent: new v2(58, 7), heading: 0, ty: "half" },
      { pos: new v2(-117, -905), extent: new v2(13, 4), heading: -0.4, ty: "half" },
      { pos: new v2(-94, -889), extent: new v2(11, 4), heading: -0.8999999999999999, ty: "half" },
      { pos: new v2(-98, -1057), extent: new v2(13, 40), heading: 0, ty: "full" },
      { pos: new v2(42, -1039), extent: new v2(4, 31), heading: 0.4, ty: "half" },
      { pos: new v2(43, -1091), extent: new v2(5, 21), heading: -0.5, ty: "half" },
      { pos: new v2(81, -1146), extent: new v2(5, 14), heading: -4.100000000000001, ty: "half" },
      { pos: new v2(-4, -1045), extent: new v2(6, 18), heading: 0, ty: "half" },
      { pos: new v2(-294, -603), extent: new v2(162, 21), heading: 0, ty: "full" },
      { pos: new v2(-144, -551), extent: new v2(12, 31), heading: 0, ty: "full" },
      { pos: new v2(-295, -421), extent: new v2(25, 189), heading: -0.8999999999999999, ty: "full" },
      { pos: new v2(-280, -548), extent: new v2(125, 34), heading: 0, ty: "full" },
      { pos: new v2(-324, -477), extent: new v2(33, 75), heading: 0, ty: "full" },
      { pos: new v2(-263, -502), extent: new v2(59, 62), heading: -0.7999999999999999, ty: "full" },
      { pos: new v2(64, -575), extent: new v2(21, 16), heading: 0.2, ty: "full" },
      { pos: new v2(37, -605), extent: new v2(15, 16), heading: -0.7999999999999999, ty: "full" },
      { pos: new v2(37, -585), extent: new v2(12, 10), heading: 0.30000000000000004, ty: "full" },
      { pos: new v2(509, -593), extent: new v2(392, 32), heading: 0, ty: "full" },
      { pos: new v2(269, -390), extent: new v2(29, 227), heading: 0.6, ty: "full" },
      { pos: new v2(-117, -41), extent: new v2(150, 12), heading: 0, ty: "full" },
      { pos: new v2(189, -42), extent: new v2(88, 12), heading: 0, ty: "full" },
      { pos: new v2(-255, 79), extent: new v2(12, 108), heading: 0, ty: "full" },
      { pos: new v2(-256, 397), extent: new v2(13, 114), heading: 0, ty: "full" },
      { pos: new v2(-101, 498), extent: new v2(143, 13), heading: 0, ty: "full" },
      { pos: new v2(291, 132), extent: new v2(16, 186), heading: 0, ty: "full" },
      { pos: new v2(291, 398), extent: new v2(16, 81), heading: 0, ty: "full" },
      { pos: new v2(59, 513), extent: new v2(15, 5), heading: -0.5, ty: "half" },
      { pos: new v2(101, 519), extent: new v2(5, 16), heading: -0.6, ty: "half" },
      { pos: new v2(50, -63), extent: new v2(4, 20), heading: -0.7999999999999999, ty: "half" },
      { pos: new v2(-256, 268), extent: new v2(13, 15), heading: 0, ty: "full" },
      { pos: new v2(-261, 204), extent: new v2(6, 16), heading: 0, ty: "half" },
      { pos: new v2(-277, 240), extent: new v2(14, 6), heading: -0.6, ty: "half" },
      { pos: new v2(-223, 262), extent: new v2(8, 7), heading: -0.1, ty: "half" },
      { pos: new v2(-199, 281), extent: new v2(5, 9), heading: 0.4, ty: "half" },
      { pos: new v2(-202, 181), extent: new v2(17, 4), heading: -0.5, ty: "half" },
      { pos: new v2(-222, 215), extent: new v2(5, 5), heading: -0.1, ty: "half" },
      { pos: new v2(119, 14), extent: new v2(5, 18), heading: 0.2, ty: "half" },
      { pos: new v2(22, 24), extent: new v2(4, 15), heading: -0.2, ty: "half" },
      { pos: new v2(23, -3), extent: new v2(5, 8), heading: 0.30000000000000004, ty: "half" },
      { pos: new v2(76, -8), extent: new v2(10, 9), heading: -0.1, ty: "half" },
      { pos: new v2(-141, 31), extent: new v2(10, 10), heading: 0, ty: "half" },
      { pos: new v2(-187, -6), extent: new v2(5, 21), heading: 0, ty: "half" },
      { pos: new v2(-221, 27), extent: new v2(20, 5), heading: 0, ty: "half" },
      { pos: new v2(-223, -10), extent: new v2(11, 10), heading: 0, ty: "half" },
      { pos: new v2(216, -8), extent: new v2(5, 21), heading: 0, ty: "half" },
      { pos: new v2(251, 29), extent: new v2(23, 4), heading: 0, ty: "half" },
      { pos: new v2(211, 494), extent: new v2(96, 15), heading: 0, ty: "full" },
      { pos: new v2(94, -69), extent: new v2(15, 5), heading: -1.5000000000000002, ty: "half" },
      { pos: new v2(-134, -499), extent: new v2(8, 9), heading: -0.8999999999999999, ty: "half" },
      { pos: new v2(57, -597), extent: new v2(15, 11), heading: -0.2, ty: "full" },
      { pos: new v2(-190, -664), extent: new v2(47, 13), heading: 0, ty: "full" },
      { pos: new v2(277, -828), extent: new v2(82, 15), heading: 0, ty: "full" },
      { pos: new v2(-88, -697), extent: new v2(50, 13), heading: 0, ty: "full" },
      { pos: new v2(116, -784), extent: new v2(25, 11), heading: 2.7755575615628914e-17, ty: "half" },
      { pos: new v2(17, -732), extent: new v2(25, 11), heading: -0.4, ty: "half" },
      { pos: new v2(56, -701), extent: new v2(23, 12), heading: -0.20000000000000004, ty: "half" },
      { pos: new v2(187, -751), extent: new v2(52, 11), heading: 0, ty: "full" },
      { pos: new v2(-64, -783), extent: new v2(24, 11), heading: 0, ty: "half" },
      { pos: new v2(207, -701), extent: new v2(24, 11), heading: 0, ty: "half" },
      { pos: new v2(188, -664), extent: new v2(25, 11), heading: 0, ty: "half" },
      { pos: new v2(-281, -770), extent: new v2(6, 22), heading: 0, ty: "half" },
      { pos: new v2(-281, -714), extent: new v2(6, 21), heading: 0, ty: "half" },
      { pos: new v2(-282, -660), extent: new v2(5, 21), heading: 0, ty: "half" },
      { pos: new v2(304, -773), extent: new v2(6, 25), heading: 0, ty: "half" },
      { pos: new v2(304, -717), extent: new v2(7, 24), heading: 0, ty: "half" },
      { pos: new v2(304, -663), extent: new v2(6, 22), heading: 0, ty: "half" },
      { pos: new v2(-64, -963), extent: new v2(19, 7), heading: -0.09999999999999998, ty: "half" },
      { pos: new v2(-321, -794), extent: new v2(8, 8), heading: 0, ty: "half" },
      { pos: new v2(-176, -792), extent: new v2(8, 8), heading: 0, ty: "half" },
      { pos: new v2(-120, -829), extent: new v2(7, 8), heading: 0, ty: "half" },
      { pos: new v2(-123, -938), extent: new v2(8, 9), heading: 0, ty: "half" },
      { pos: new v2(-126, -1050), extent: new v2(7, 7), heading: 0, ty: "half" },
      { pos: new v2(-123, -1175), extent: new v2(7, 8), heading: 0, ty: "half" },
      { pos: new v2(-120, -1296), extent: new v2(9, 9), heading: 0, ty: "half" },
      { pos: new v2(106, -1292), extent: new v2(7, 7), heading: 0, ty: "half" },
      { pos: new v2(107, -1174), extent: new v2(8, 8), heading: 0, ty: "half" },
      { pos: new v2(113, -1051), extent: new v2(9, 9), heading: 0, ty: "half" },
      { pos: new v2(113, -940), extent: new v2(10, 8), heading: 0, ty: "half" },
      { pos: new v2(202, -797), extent: new v2(7, 8), heading: 0, ty: "half" },
      { pos: new v2(333, -797), extent: new v2(7, 6), heading: 0, ty: "half" },
      { pos: new v2(-322, -638), extent: new v2(8, 7), heading: 0, ty: "half" },
      { pos: new v2(-171, -636), extent: new v2(6, 6), heading: 0, ty: "half" },
      { pos: new v2(129, -638), extent: new v2(7, 7), heading: 0, ty: "half" },
      { pos: new v2(239, -638), extent: new v2(7, 7), heading: 0, ty: "half" },
      { pos: new v2(334, -637), extent: new v2(8, 8), heading: 0, ty: "half" },
      { pos: new v2(-242, -416), extent: new v2(8, 7), heading: 0.7, ty: "half" },
      { pos: new v2(255, -10), extent: new v2(11, 11), heading: 0, ty: "half" },
      { pos: new v2(76, 471), extent: new v2(24, 3), heading: 0, ty: "half" },
      { pos: new v2(118, 464), extent: new v2(8, 3), heading: 0.30000000000000004, ty: "half" },
      { pos: new v2(27, 458), extent: new v2(10, 7), heading: -0.4, ty: "half" },
      { pos: new v2(253, 435), extent: new v2(21, 5), heading: 0, ty: "half" },
      { pos: new v2(230, 469), extent: new v2(5, 15), heading: 0, ty: "half" },
      { pos: new v2(258, 460), extent: new v2(9, 9), heading: 0, ty: "half" },
      { pos: new v2(-220, 435), extent: new v2(24, 4), heading: 0, ty: "half" },
      { pos: new v2(-190, 474), extent: new v2(5, 18), heading: 0, ty: "half" },
      { pos: new v2(-224, 467), extent: new v2(10, 9), heading: 0, ty: "half" },
      { pos: new v2(25, 1000), extent: new v2(49, 44), heading: 2.7755575615628914e-17, ty: "half" },
      { pos: new v2(26, 999), extent: new v2(37, 38), heading: 1.6000000000000003, ty: "full" },
      { pos: new v2(-61, 616), extent: new v2(38, 7), heading: 0.10000000000000003, ty: "full" },
      { pos: new v2(25, 607), extent: new v2(38, 7), heading: 0.10000000000000003, ty: "half" },
      { pos: new v2(-349, 618), extent: new v2(10, 50), heading: 0.2, ty: "full" },
      { pos: new v2(-347, 728), extent: new v2(18, 64), heading: 0, ty: "full" },
      { pos: new v2(-328, 886), extent: new v2(26, 95), heading: 0, ty: "full" },
      { pos: new v2(-334, 1127), extent: new v2(52, 146), heading: 0, ty: "full" },
      { pos: new v2(-344, 1329), extent: new v2(78, 59), heading: 0, ty: "full" },
      { pos: new v2(-329, 790), extent: new v2(19, 17), heading: 0, ty: "full" },
      { pos: new v2(-309, 978), extent: new v2(20, 16), heading: 0, ty: "full" },
      { pos: new v2(-286, 1267), extent: new v2(16, 15), heading: 0, ty: "full" },
      { pos: new v2(190, 959), extent: new v2(6, 68), heading: 0, ty: "half" },
      { pos: new v2(193, 1151), extent: new v2(7, 74), heading: 0, ty: "half" },
      { pos: new v2(148, 1235), extent: new v2(36, 35), heading: 0, ty: "full" },
      { pos: new v2(-95, 1231), extent: new v2(36, 32), heading: 0, ty: "full" },
      { pos: new v2(-75, 1340), extent: new v2(56, 17), heading: 0, ty: "full" },
      { pos: new v2(-106, 1297), extent: new v2(25, 47), heading: 0, ty: "full" },
      { pos: new v2(155, 1285), extent: new v2(29, 60), heading: 0, ty: "full" },
      { pos: new v2(-50, 1320), extent: new v2(32, 57), heading: 0, ty: "full" },
      { pos: new v2(101, 1317), extent: new v2(34, 55), heading: 0, ty: "full" },
      { pos: new v2(-6, 1248), extent: new v2(20, 5), heading: 1.2, ty: "half" },
      { pos: new v2(51, 1252), extent: new v2(21, 4), heading: -0.7999999999999999, ty: "half" },
      { pos: new v2(144, 818), extent: new v2(83, 5), heading: -0.8999999999999999, ty: "full" },
      { pos: new v2(20, 740), extent: new v2(60, 5), heading: 0, ty: "full" },
      { pos: new v2(-106, 830), extent: new v2(9, 82), heading: -0.5, ty: "full" },
      { pos: new v2(-139, 1137), extent: new v2(7, 85), heading: 0, ty: "half" },
      { pos: new v2(269, 581), extent: new v2(87, 7), heading: 0.1, ty: "half" },
      { pos: new v2(-181, 630), extent: new v2(44, 6), heading: 0.1, ty: "half" },
      { pos: new v2(-220, 734), extent: new v2(6, 52), heading: 0.1, ty: "half" },
      { pos: new v2(-206, 857), extent: new v2(8, 57), heading: 0.1, ty: "half" },
      { pos: new v2(-201, 1150), extent: new v2(7, 73), heading: 0, ty: "half" },
      { pos: new v2(-199, 940), extent: new v2(7, 26), heading: 0.1, ty: "full" },
      { pos: new v2(-201, 1058), extent: new v2(9, 18), heading: 0, ty: "full" },
      { pos: new v2(-237, 618), extent: new v2(5, 16), heading: 0.6, ty: "half" },
      { pos: new v2(-402, -180), extent: new v2(18, 209), heading: -0.8999999999999999, ty: "full" },
      { pos: new v2(-101, -197), extent: new v2(250, 16), heading: 0, ty: "full" },
      { pos: new v2(196, -93), extent: new v2(5, 39), heading: 0, ty: "half" },
      { pos: new v2(300, -139), extent: new v2(48, 5), heading: 0, ty: "half" },
      { pos: new v2(332, -47), extent: new v2(26, 7), heading: 0, ty: "full" },
      { pos: new v2(329, 505), extent: new v2(22, 4), heading: 0, ty: "full" },
      { pos: new v2(337, 227), extent: new v2(35, 278), heading: 0, ty: "full" },
      { pos: new v2(-334, -342), extent: new v2(7, 6), heading: 0.7, ty: "half" },
      { pos: new v2(122, -529), extent: new v2(9, 8), heading: -0.8999999999999999, ty: "half" },
      { pos: new v2(201, -413), extent: new v2(8, 7), heading: -0.9999999999999999, ty: "half" },
      { pos: new v2(279, -296), extent: new v2(10, 8), heading: -0.9999999999999999, ty: "half" },
      { pos: new v2(341, -365), extent: new v2(51, 49), heading: 0, ty: "full" },
      { pos: new v2(301, -453), extent: new v2(55, 76), heading: 0, ty: "full" },
      { pos: new v2(275, -515), extent: new v2(80, 42), heading: 0.20000000000000004, ty: "full" },
      { pos: new v2(260, -555), extent: new v2(90, 15), heading: 0.1, ty: "full" },
      { pos: new v2(253, 220), extent: new v2(6, 24), heading: 0.30000000000000004, ty: "half" },
      { pos: new v2(247, 260), extent: new v2(4, 4), heading: 0, ty: "half" },
      { pos: new v2(254, 348), extent: new v2(4, 22), heading: -0.2, ty: "half" },
      { pos: new v2(-112, -407), extent: new v2(20, 108), heading: -0.8999999999999999, ty: "full" },
      { pos: new v2(46, -378), extent: new v2(18, 140), heading: 0.6, ty: "full" },
      { pos: new v2(145, -222), extent: new v2(13, 24), heading: 3.700000000000002, ty: "full" },
      { pos: new v2(152, -195), extent: new v2(17, 14), heading: 0, ty: "full" },
      { pos: new v2(-193, -290), extent: new v2(7, 28), heading: -0.8999999999999999, ty: "full" },
      { pos: new v2(-218, -248), extent: new v2(9, 40), heading: -1.3, ty: "full" },
      { pos: new v2(105, -574), extent: new v2(26, 7), heading: -0.4, ty: "full" },
      { pos: new v2(-134, -295), extent: new v2(9, 58), heading: -0.8999999999999999, ty: "full" },
      { pos: new v2(9, -242), extent: new v2(12, 4), heading: 1.2, ty: "full" },
      { pos: new v2(1, -258), extent: new v2(14, 4), heading: -0.7999999999999999, ty: "full" },
      { pos: new v2(40, -330), extent: new v2(13, 5), heading: 0.6, ty: "half" },
      { pos: new v2(-175, -226), extent: new v2(5, 7), heading: -0.2, ty: "half" },
      { pos: new v2(-130, -256), extent: new v2(4, 12), heading: -0.4, ty: "half" },
      { pos: new v2(-153, -235), extent: new v2(8, 3), heading: 0.5, ty: "half" },
      { pos: new v2(223, -143), extent: new v2(10, 6), heading: 0.7, ty: "half" },
      { pos: new v2(216, -193), extent: new v2(19, 3), heading: -0.6, ty: "half" },
      { pos: new v2(190, -213), extent: new v2(17, 4), heading: -1.2, ty: "half" },
      { pos: new v2(98, -359), extent: new v2(8, 7), heading: 0.6, ty: "half" },
      { pos: new v2(164, -243), extent: new v2(10, 6), heading: 0.6, ty: "half" },
      { pos: new v2(-311, -290), extent: new v2(8, 8), heading: 0.7, ty: "half" },
      { pos: new v2(-209, -370), extent: new v2(8, 6), heading: 0.6, ty: "half" },
      { pos: new v2(-101, -456), extent: new v2(8, 8), heading: 0.7, ty: "half" },
      { pos: new v2(-254, -66), extent: new v2(9, 5), heading: 0, ty: "half" },
      { pos: new v2(-113, -69), extent: new v2(9, 7), heading: 0, ty: "half" },
      { pos: new v2(167, -68), extent: new v2(10, 10), heading: 0, ty: "half" },
      { pos: new v2(-291, 47), extent: new v2(9, 11), heading: 0, ty: "half" },
      { pos: new v2(-297, 361), extent: new v2(9, 9), heading: 0, ty: "half" },
      { pos: new v2(-200, 529), extent: new v2(9, 8), heading: 0, ty: "half" },
      { pos: new v2(-53, 532), extent: new v2(9, 9), heading: 0, ty: "half" },
      { pos: new v2(209, 529), extent: new v2(12, 10), heading: 0, ty: "half" },
      { pos: new v2(308, 530), extent: new v2(9, 10), heading: 0, ty: "half" },
      { pos: new v2(126, 597), extent: new v2(25, 6), heading: 0.10000000000000003, ty: "full" },
      { pos: new v2(-52, 752), extent: new v2(15, 4), heading: 0.7999999999999999, ty: "full" },
      { pos: new v2(87, 748), extent: new v2(10, 4), heading: -0.6, ty: "full" },
      { pos: new v2(-142, 958), extent: new v2(6, 52), heading: 0, ty: "half" },
      { pos: new v2(106, 968), extent: new v2(9, 21), heading: 0, ty: "half" },
      { pos: new v2(106, 1032), extent: new v2(8, 15), heading: 0, ty: "half" },
      { pos: new v2(-49, 964), extent: new v2(7, 17), heading: 0, ty: "half" },
      { pos: new v2(-49, 1029), extent: new v2(9, 19), heading: 0, ty: "half" },
      { pos: new v2(-106, 1088), extent: new v2(5, 13), heading: 0, ty: "half" },
      { pos: new v2(-106, 1132), extent: new v2(6, 12), heading: 0, ty: "half" },
      { pos: new v2(-106, 1172), extent: new v2(6, 12), heading: 0, ty: "half" },
      { pos: new v2(27, 1096), extent: new v2(14, 11), heading: 0, ty: "half" },
      { pos: new v2(27, 1096), extent: new v2(10, 7), heading: 0, ty: "full" },
      { pos: new v2(28, 904), extent: new v2(16, 13), heading: 0, ty: "half" },
      { pos: new v2(28, 903), extent: new v2(10, 9), heading: 0, ty: "full" },
      { pos: new v2(163, 1093), extent: new v2(5, 18), heading: 0, ty: "half" },
      { pos: new v2(163, 1139), extent: new v2(6, 13), heading: 0, ty: "half" },
      { pos: new v2(164, 1179), extent: new v2(7, 13), heading: 0, ty: "half" },
      { pos: new v2(161, 922), extent: new v2(8, 16), heading: 0, ty: "half" },
      { pos: new v2(161, 970), extent: new v2(6, 13), heading: 0, ty: "half" },
      { pos: new v2(161, 1011), extent: new v2(5, 13), heading: 0, ty: "half" },
      { pos: new v2(-115, 923), extent: new v2(6, 16), heading: 0, ty: "half" },
      { pos: new v2(-114, 965), extent: new v2(6, 14), heading: 0, ty: "half" },
      { pos: new v2(-113, 1004), extent: new v2(6, 15), heading: 0, ty: "half" },
      { pos: new v2(28, 1147), extent: new v2(11, 10), heading: 0, ty: "full" },
      { pos: new v2(28, 1148), extent: new v2(18, 17), heading: 0, ty: "half" },
      { pos: new v2(31, 827), extent: new v2(19, 18), heading: 0, ty: "half" },
      { pos: new v2(31, 827), extent: new v2(12, 11), heading: 0, ty: "full" },
      { pos: new v2(354, 682), extent: new v2(11, 49), heading: -0.20000000000000004, ty: "full" },
      { pos: new v2(351, 792), extent: new v2(17, 65), heading: 0, ty: "full" },
      { pos: new v2(351, 918), extent: new v2(41, 63), heading: 0, ty: "full" },
      { pos: new v2(329, 1066), extent: new v2(29, 97), heading: 2.7755575615628914e-17, ty: "full" },
      { pos: new v2(344, 1219), extent: new v2(56, 93), heading: 0, ty: "full" },
      { pos: new v2(315, 1334), extent: new v2(60, 51), heading: 0, ty: "full" },
      { pos: new v2(286, 1281), extent: new v2(13, 9), heading: 0, ty: "full" },
      { pos: new v2(305, 1140), extent: new v2(13, 31), heading: 0, ty: "full" },
      { pos: new v2(337, 866), extent: new v2(20, 29), heading: 0, ty: "full" },
      { pos: new v2(-253, 592), extent: new v2(5, 14), heading: 0.4, ty: "half" },
      { pos: new v2(164, 783), extent: new v2(10, 9), heading: 0.6, ty: "half" },
      { pos: new v2(215, 903), extent: new v2(8, 9), heading: 0, ty: "half" },
      { pos: new v2(219, 1090), extent: new v2(8, 8), heading: 0, ty: "half" },
      { pos: new v2(-163, 1066), extent: new v2(10, 11), heading: 0, ty: "half" },
      { pos: new v2(-167, 920), extent: new v2(10, 10), heading: 0, ty: "half" },
      { pos: new v2(-126, 790), extent: new v2(9, 8), heading: 0.9999999999999999, ty: "half" },
      { pos: new v2(-68, 817), extent: new v2(13, 4), heading: 1.0999999999999999, ty: "half" },
      { pos: new v2(-86, 846), extent: new v2(4, 12), heading: -0.5, ty: "half" },
      { pos: new v2(107, 800), extent: new v2(4, 15), heading: 0.5, ty: "half" },
      { pos: new v2(129, 838), extent: new v2(6, 16), heading: 0.5, ty: "half" },
      { pos: new v2(4, -222), extent: new v2(6, 10), heading: 0, ty: "full" },
      { pos: new v2(151, 1341), extent: new v2(33, 29), heading: 0, ty: "full" },
      { pos: new v2(64, -438), extent: new v2(42, 9), heading: 0.30000000000000004, ty: "full" },
      { pos: new v2(103, -452), extent: new v2(21, 15), heading: 0.1, ty: "full" },
      { pos: new v2(123, -466), extent: new v2(9, 8), heading: 0, ty: "full" },
      { pos: new v2(124, -447), extent: new v2(26, 13), heading: 3.0000000000000013, ty: "full" },
      { pos: new v2(147, -460), extent: new v2(19, 7), heading: 0.2, ty: "full" },
      { pos: new v2(174, -469), extent: new v2(21, 5), heading: 0, ty: "full" },
      { pos: new v2(68, -449), extent: new v2(14, 9), heading: -0.30000000000000004, ty: "full" },
    ],

    goals: [
      { name: 'escape area', area: 16 },
    ],

    mission_rules: [
      { ty: 'explore' },
    ],

    editor: true,
  };
}

function preset_from(preset) {
  function conv(obs) {
    return {
      ...obs,
      pos: v2.from(obs.pos),
      extent: v2.from(obs.extent),
    };
  }
  return {
    ...preset,
    spawnareas: preset.spawnareas.map(conv),
    obstacle_specs: preset.obstacle_specs.map(conv),
  };
}

export function preset_embassy_short_layer(layer) {
  const preset = preset_from(PRESET_EMBASSY_SHORT);

  const level_name_base = 'L_ShooterGym_embassy_short';
  const level_names = [
    'L_ShooterGym_embassy_short',
    'L_ShooterGym_embassy_Base',
    'L_ShooterGym_embassy_Light',
    'L_ShooterGym_embassy_Sound',
  ];

  return {
    world: {
      ...preset.world,
      simover_rule: 'eliminate',

      level_name_base,
      level_names,
    },

    layers: preset.layers,

    spawnareas: preset.spawnareas.filter((s) => {
      if (!layer) {
        return true;
      }
      return s.layer === undefined || s.layer === layer;
    }).map((s, i) => {
      if (i === 0) {
        return { ...s, spawn: true };
      }
      return { ...s, spawn1: true };
    }),

    obstacle_specs: preset.obstacle_specs.filter((s) => {
      if (!layer) {
        return true;
      }
      return s.layer === undefined || s.layer === layer;
    }),

    mission_rules: [
      { ty: 'explore' },
    ],

    goals: [],

    editor: true,
  };
}

export function preset_embassy_short_layer_base() {
  const preset = preset_embassy_short_layer('base');

  const team0_smg = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_hg_t1),
    ...team0_opts,
    spawnarea: 0,
    life: 65,
  };

  const team0_ar = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_ar_t1),
    ...team0_opts,
    spawnarea: 0,
    life: 65,
  };

  // 적 에이전트 스펙 정의
  const team1_tmpl_smg = {
    ...entityFromStat2(stats2_const(4), tmpl_firearm_smg_t1),
    default_rule: { ty: 'idle', alert: false },
    team: 1,
    spawnarea: 2,
    life: 42,
  };

    const team1_tmpl_ar = {
    ...entityFromStat2(stats2_const(4), tmpl_firearm_ar_t1),
    default_rule: { ty: 'idle', alert: false },
    team: 1,
    spawnarea: 2,
    life: 42,
  };

  const team1_tmpl_hg = {
    ...entityFromStat2(stats2_const(4), tmpl_firearm_hg_t1),
    default_rule: { ty: 'idle', alert: false },
    team: 1,
    spawnarea: 2,
    life: 42,
  };

  return {
    ...preset,

    goals: [
      { name: 'escape area', ty: 'escape', area: 0 },
    ],

    entities: [
      ...new Array(2).fill(team0_smg),
      ...new Array(1).fill(team0_ar),
      // {
      //   ...team0_smg,
      // },
      // {
      //   ...team0_ar, leader: 0,
      //   heals: [{ heal_amount: 50, heal_duration: 3, }],
      // },
      { ...team1_tmpl_hg, spawnarea: 1 },
      { ...team1_tmpl_hg, spawnarea: 2 },

      { ...team1_tmpl_hg, spawnarea: 3 },
      { ...team1_tmpl_smg, spawnarea: 4 },

      { ...team1_tmpl_ar, spawnarea: 5 },
      { ...team1_tmpl_hg, spawnarea: 6 },
    ],
  };
}

export function preset_embassy_short() {
  const world = {
    width: 700,
    height: 500,
    simover_rule: 'eliminate',

    level_name: 'embassy_short',
    level_names: ['embassy_short'],
  };

  const team0_tmpl_ar = {
    ...team0_tmpl_agent_ar_high,

    allow_fire_control: true,
    firearm_range: 500,
  };

  // 적 에이전트 스펙 정의
  const team1_tmpl = {
    ...ENTITY_CONFIG_TMPL, team: 1, spawnarea: 1,
    armor: 1,
    default_rule: 'idle', allow_crawl: false,
    vis_range: 300,

    aim_rot_rules: [
      { aimvar: Math.PI * 2, aimspeed: 0.1 },
    ],
  };

  const team0_area0 = { ...team0_tmpl_ar, spawnarea: 0, vis_group: 1 };
  const team0_area1 = { ...team0_tmpl_ar, spawnarea: 1, vis_group: 2 };

  return {
    world,

    entities: [
      team0_area0,
      team0_area0,
      team0_area1,
      team0_area1,

      { ...team1_tmpl, spawnarea: 2 },
      { ...team1_tmpl, spawnarea: 2 },

      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },

      { ...team1_tmpl, spawnarea: 4 },
      { ...team1_tmpl, spawnarea: 4 },
    ],


    spawnareas: [
      { pos: new v2(221, -199), extent: new v2(28, 21), heading: 0, spawn: true, },
      { pos: new v2(-291, -198), extent: new v2(35, 29), heading: 0, },
      { pos: new v2(-322, 154), extent: new v2(14, 20), heading: 0, spawn1: true, },
      { pos: new v2(-49, 38), extent: new v2(29, 20), heading: 0, spawn1: true, },
      { pos: new v2(285, 57), extent: new v2(26, 20), heading: 0, spawn1: true, },
      { pos: new v2(200, 200), extent: new v2(25, 25), heading: 0, },
    ],

    obstacle_specs: [
      { pos: new v2(-288, -5), extent: new v2(68, 91), heading: 0, ty: "full", },
      { pos: new v2(151, -173), extent: new v2(20, 85), heading: 0, ty: "full", },
      { pos: new v2(316, -188), extent: new v2(41, 98), heading: 0, ty: "full", },
      { pos: new v2(161, -75), extent: new v2(30, 16), heading: 0, ty: "full", },
      { pos: new v2(256, 5), extent: new v2(125, 17), heading: 0, ty: "full", },
      { pos: new v2(149, 94), extent: new v2(18, 76), heading: 0, ty: "full", },
      { pos: new v2(339, 180), extent: new v2(21, 88), heading: 0, ty: "full", },
      { pos: new v2(360, 198), extent: new v2(16, 109), heading: -0.7999999999999999, ty: "full", },
      { pos: new v2(-255, 268), extent: new v2(117, 21), heading: 0, ty: "full", },
      { pos: new v2(-162, -82), extent: new v2(22, 287), heading: 0, ty: "full", },
      { pos: new v2(31, 156), extent: new v2(26, 6), heading: 0.4, ty: "half", },
      { pos: new v2(8, 120), extent: new v2(50, 6), heading: 0.4, ty: "half", },
      { pos: new v2(-229, 147), extent: new v2(5, 17), heading: 0.2, ty: "half", },
      { pos: new v2(-252, 214), extent: new v2(4, 19), heading: 0.1, ty: "half", },
      { pos: new v2(219, 57), extent: new v2(5, 34), heading: 0, ty: "half", },
      { pos: new v2(239, 117), extent: new v2(18, 5), heading: 0, ty: "half", },
      { pos: new v2(244, 161), extent: new v2(4, 28), heading: 0.4, ty: "half", },
      { pos: new v2(228, 224), extent: new v2(8, 7), heading: -0.2, ty: "half", },
      { pos: new v2(-201, 32), extent: new v2(12, 6), heading: -0.4, ty: "half", },
      { pos: new v2(-274, 157), extent: new v2(7, 40), heading: 0, ty: "half", },
      { pos: new v2(-333, 122), extent: new v2(26, 7), heading: 0, ty: "half", },
      { pos: new v2(-340, 190), extent: new v2(58, 7), heading: 0, ty: "half", },
      { pos: new v2(-117, 195), extent: new v2(13, 4), heading: -0.4, ty: "half", },
      { pos: new v2(-94, 211), extent: new v2(11, 4), heading: -0.8999999999999999, ty: "half", },
      { pos: new v2(-98, 43), extent: new v2(13, 40), heading: 0, ty: "full", },
      { pos: new v2(42, 61), extent: new v2(4, 31), heading: 0.4, ty: "half", },
      { pos: new v2(43, 9), extent: new v2(5, 21), heading: -0.5, ty: "half", },
      { pos: new v2(81, -46), extent: new v2(5, 14), heading: -4.100000000000001, ty: "half", },
      { pos: new v2(-4, 55), extent: new v2(6, 18), heading: 0, ty: "half", },
      { pos: new v2(-64, 137), extent: new v2(19, 7), heading: -0.09999999999999998, ty: "half", },
      { pos: new v2(-123, 162), extent: new v2(8, 9), heading: 0, ty: "half", },
      { pos: new v2(-126, 50), extent: new v2(7, 7), heading: 0, ty: "half", },
      { pos: new v2(-123, -75), extent: new v2(7, 8), heading: 0, ty: "half", },
      { pos: new v2(-120, -196), extent: new v2(9, 9), heading: 0, ty: "half", },
      { pos: new v2(106, -192), extent: new v2(7, 7), heading: 0, ty: "half", },
      { pos: new v2(107, -74), extent: new v2(8, 8), heading: 0, ty: "half", },
      { pos: new v2(113, 49), extent: new v2(9, 9), heading: 0, ty: "half", },
      { pos: new v2(113, 160), extent: new v2(10, 8), heading: 0, ty: "half", },
    ],

    goals: [
      { name: 'escape area', area: 5 },
    ],

    mission_rules: [
      { ty: 'explore' },
    ],

    editor: true,
  };
}

export function preset_embassy_short0() {
  const preset = preset_embassy_short();
  for (const entity of preset.entities) {
    if (entity.team === 0) {
      entity.spawnarea = 0;
      delete (entity.visgroup);
    }
  }
  return preset;
}

export function preset_hammerhead() {
  const world = {
    width: 1000,
    height: 2700,
    exp_transfer_knowledge: false,
  };

  const team0_tmpl_ar = {
    ...team0_tmpl_agent_ar_high,

    allow_fire_control: true,
    firearm_range: 500,
  };

  // 적 에이전트 스펙 정의
  const team1_tmpl = {
    ...ENTITY_CONFIG_TMPL, team: 1,
    armor: 1,
    default_rule: 'capture', allow_crawl: false,
    vis_range: 500,

    aim_rot_rules: [
      { aimvar: Math.PI * 2, aimspeed: 0.1 },
    ],
  };

  const ally_tmpl = {
    ...ENTITY_CONFIG_TMPL, team: 0,
    armor: 33,
    default_rule: 'cover',
    vis_range: 500,

    speed: 50,
    spawnarea: 1,
  };

  const ally_area0 = { ...team0_tmpl_ar, vis_group: 1 };
  const ally_area1 = { ...ally_tmpl, spawnarea: 1 };
  const ally_area2 = { ...ally_tmpl, spawnarea: 2 };

  return {
    world,

    entities: [
      ally_area0,
      ally_area0,
      ally_area0,
      ally_area0,
      ally_area0,
      ally_area0,

      ally_area2,
      ally_area2,
      ally_area2,

      ally_area1,
      ally_area1,
      ally_area1,
      ally_area1,
      ally_area1,
      ally_area1,
      ally_area1,
      ally_area1,
      ally_area1,
      ally_area1,
      ally_area1,
      ally_area1,
      ally_area1,
      ally_area1,
      ally_area1,
      ally_area1,
      ally_area1,
      ally_area1,
      ally_area1,



      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },

      { ...team1_tmpl, spawnarea: 4 },
      { ...team1_tmpl, spawnarea: 4 },
      { ...team1_tmpl, spawnarea: 4 },
      { ...team1_tmpl, spawnarea: 4 },
    ],



    spawnareas: [
      { pos: new v2(408, -1051.400001525879), extent: new v2(66, 53), heading: 0, spawn: true },
      { pos: new v2(-181, -1047.400001525879), extent: new v2(152, 45), heading: 0, spawn: true },
      { pos: new v2(-156, -889.4000015258789), extent: new v2(70, 12), heading: 0, spawn: true },
      { pos: new v2(-144, -560.3999938964844), extent: new v2(139, 102), heading: 0 },
      { pos: new v2(390, -206.39999389648438), extent: new v2(67, 84), heading: 0 },
      { pos: new v2(64, 402.5999755859375), extent: new v2(112, 73), heading: 0 },
    ],
    obstacle_specs: [
      { pos: new v2(242, -871.3999633789062), extent: new v2(36, 528), heading: 0, ty: "full" },
      { pos: new v2(98, -583.3999938964844), extent: new v2(9, 53), heading: -2.5, ty: "half" },
      { pos: new v2(233, 67.60003662109375), extent: new v2(27, 489), heading: 0, ty: "full" },
      { pos: new v2(18, 534.5999755859375), extent: new v2(189, 22), heading: -0.125, ty: "full" },
      { pos: new v2(-102, -171.39999389648438), extent: new v2(95, 8), heading: 0.125, ty: "full" },
      { pos: new v2(-374, -179.39999389648438), extent: new v2(99, 6), heading: -0.25, ty: "full" },
      { pos: new v2(-47, -326.6000061035156), extent: new v2(127, 6), heading: 0.125, ty: "half" },
      { pos: new v2(-61, -959.4000015258789), extent: new v2(27, 4), heading: 0, ty: "half" },
      { pos: new v2(-10, -961.4000015258789), extent: new v2(28, 11), heading: 0, ty: "full" },
      { pos: new v2(-343, -960.4000015258789), extent: new v2(22, 4), heading: 0, ty: "half" },
      { pos: new v2(-384, -960.4000015258789), extent: new v2(20, 11), heading: 0, ty: "full" },
      { pos: new v2(-324, -790.3999977111816), extent: new v2(153, 5), heading: 0, ty: "full" },
      { pos: new v2(60, -788.3999977111816), extent: new v2(77, 5), heading: 0, ty: "full" },
      { pos: new v2(-415, -1008.3999977111816), extent: new v2(12, 59), heading: 0, ty: "full" },
      { pos: new v2(-362, -1130.3999977111816), extent: new v2(66, 10), heading: 0, ty: "full" },
      { pos: new v2(-287, -1129.3999977111816), extent: new v2(33, 5), heading: 0, ty: "half" },
      { pos: new v2(-183, -1130.3999977111816), extent: new v2(74, 12), heading: 0, ty: "full" },
      { pos: new v2(-7, -1131.3999977111816), extent: new v2(59, 14), heading: 0, ty: "full" },
      { pos: new v2(31, -966.3999977111816), extent: new v2(47, 12), heading: 0, ty: "full" },
      { pos: new v2(-227, -959.3999977111816), extent: new v2(18, 12), heading: 0, ty: "half" },
      { pos: new v2(-284, -960.3999977111816), extent: new v2(39, 13), heading: 0, ty: "full" },
      { pos: new v2(-148, -959.3999977111816), extent: new v2(61, 12), heading: 0, ty: "full" },
      { pos: new v2(72, -1079.3999977111816), extent: new v2(5, 23), heading: 0, ty: "half" },
      { pos: new v2(67, -1120.3999977111816), extent: new v2(16, 18), heading: 0, ty: "full" },
      { pos: new v2(71, -1014.3999977111816), extent: new v2(15, 42), heading: 0, ty: "full" },
      { pos: new v2(146, -931.4000015258789), extent: new v2(9, 148), heading: 0, ty: "full" },
      { pos: new v2(145, -1206.400001525879), extent: new v2(10, 94), heading: 0, ty: "full" },
      { pos: new v2(-23, -1291.400001525879), extent: new v2(160, 9), heading: 0, ty: "full" },
      { pos: new v2(-314, -1292.400001525879), extent: new v2(92, 8), heading: 0, ty: "full" },
      { pos: new v2(-472, -1154.400001525879), extent: new v2(9, 144), heading: 0, ty: "full" },
      { pos: new v2(-472, -863.4000015258789), extent: new v2(9, 79), heading: 0, ty: "full" },
      { pos: new v2(-455, -1291.400001525879), extent: new v2(20, 7), heading: 0, ty: "full" },
      { pos: new v2(118, -1082.400001525879), extent: new v2(4, 16), heading: 1, ty: "half" },
      { pos: new v2(-181, -1262.400001525879), extent: new v2(6, 19), heading: -0.125, ty: "half" },
      { pos: new v2(-433, -1267.400001525879), extent: new v2(4, 16), heading: 0.25, ty: "half" },
      { pos: new v2(-497, -996.4000015258789), extent: new v2(6, 33), heading: -1.125, ty: "half" },
      { pos: new v2(-122, -848.4000015258789), extent: new v2(80, 7), heading: 0, ty: "half" },
      { pos: new v2(-28, -867.4000015258789), extent: new v2(7, 22), heading: -0.5, ty: "half" },
      { pos: new v2(-239, -855.4000015258789), extent: new v2(34, 4), heading: -0.125, ty: "half" },
      { pos: new v2(-292, -878.4000015258789), extent: new v2(6, 24), heading: 0.625, ty: "half" },
      { pos: new v2(-212, -704.3999938964844), extent: new v2(67, 12), heading: 0.125, ty: "half" },
      { pos: new v2(-81, -683.3999938964844), extent: new v2(7, 43), heading: 1.125, ty: "half" },
      { pos: new v2(-21, -1008.4000015258789), extent: new v2(8, 21), heading: -1, ty: "half" },
      { pos: new v2(-153, -1011.4000015258789), extent: new v2(20, 8), heading: 0, ty: "half" },
      { pos: new v2(-286, -995.4000015258789), extent: new v2(10, 20), heading: 1.375, ty: "half" },
      { pos: new v2(-365, -1003.4000015258789), extent: new v2(19, 6), heading: 0, ty: "half" },
      { pos: new v2(-226, -1022.4000015258789), extent: new v2(12, 9), heading: 0, ty: "half" },
      { pos: new v2(11, -1039.400001525879), extent: new v2(28, 5), heading: 1.5, ty: "half" },
      { pos: new v2(-112, -991.4000015258789), extent: new v2(23, 3), heading: -0.125, ty: "half" },
      { pos: new v2(-177, -1099.400001525879), extent: new v2(28, 8), heading: 0, ty: "half" },
      { pos: new v2(-343, -1081.400001525879), extent: new v2(15, 13), heading: 0, ty: "half" },
      { pos: new v2(-91, -1129.400001525879), extent: new v2(25, 6), heading: 0, ty: "half" },
      { pos: new v2(320, -27.39996337890625), extent: new v2(63, 6), heading: 0, ty: "half" },
      { pos: new v2(448, 49.60003662109375), extent: new v2(36, 10), heading: 0, ty: "half" },
      { pos: new v2(351, 160.60003662109375), extent: new v2(21, 7), heading: 0, ty: "half" },
      { pos: new v2(365, -418.39996337890625), extent: new v2(36, 11), heading: -0.125, ty: "half" },
      { pos: new v2(442, -470.39996337890625), extent: new v2(17, 14), heading: 0, ty: "half" },
      { pos: new v2(455, -387.3999938964844), extent: new v2(47, 3), heading: -0.375, ty: "half" },
      { pos: new v2(340, -1254.400001525879), extent: new v2(44, 5), heading: -0.625, ty: "full" },
      { pos: new v2(411, -1200.400001525879), extent: new v2(44, 6), heading: -0.625, ty: "full" },
      { pos: new v2(412, -1265.400001525879), extent: new v2(46, 5), heading: 0.875, ty: "full" },
      { pos: new v2(346, -1186.400001525879), extent: new v2(45, 5), heading: -2.25, ty: "full" },
      { pos: new v2(378, -1223.400001525879), extent: new v2(37, 19), heading: 0.125, ty: "half" },
      { pos: new v2(378, -1224.400001525879), extent: new v2(11, 10), heading: 0, ty: "full" },
      { pos: new v2(440, -1232.400001525879), extent: new v2(27, 5), heading: 0.125, ty: "half" },
      { pos: new v2(477, -1241.400001525879), extent: new v2(16, 3), heading: 0.25, ty: "half" },
      { pos: new v2(476, -1231.400001525879), extent: new v2(14, 3), heading: 0, ty: "half" },
      { pos: new v2(332, -1216.400001525879), extent: new v2(9, 20), heading: 0.125, ty: "half" },
      { pos: new v2(316, -1213.400001525879), extent: new v2(4, 10), heading: 0.125, ty: "half" },
      { pos: new v2(-347, -884.5999994277954), extent: new v2(39, 8), heading: 0.125, ty: "half" },
      { pos: new v2(-421, -891.5999994277954), extent: new v2(5, 12), heading: 1.25, ty: "half" },
      { pos: new v2(-365, -1052.400001525879), extent: new v2(3, 19), heading: 0, ty: "half" },
      { pos: new v2(-63, -989.4000015258789), extent: new v2(10, 5), heading: 0, ty: "half" },
      { pos: new v2(-31, -804.4000015258789), extent: new v2(7, 26), heading: 0.625, ty: "half" },
      { pos: new v2(-428, -1109.400001525879), extent: new v2(5, 17), heading: -0.375, ty: "half" },
    ],
    goals: [
      { name: 'escape area', area: 1 },
    ],

    mission_rules: [
      { ty: 'explore' },
    ],

    editor: true,
  };
}

export function preset_rescue_ship1() {
  const world = { width: 500, height: 1500 };

  const team0_tmpl = {
    ...team0_tmpl_agent_ar_low,
    ...ENTITY_CONFIG_TMPL,
    speed: 20,
    allow_fire_control: true,
    firearm_range: 500,
  };

  const team0_tmpl_mid = {
    ...team0_tmpl_agent_ar_mid,
    speed: 20,
    allow_fire_control: true,
    firearm_range: 500,
  };

  // 적 에이전트 스펙 정의
  const team1_tmpl = {
    ...enemy_tmpl_agent_ar_low,
    team: 1,
    spawnarea: 1,

    life: 99,
    speed: 20,
    armor: 1,
    armor_hit_prob: 0.2,
    default_rule: 'idle', allow_crawl: false,
    vis_range: 300,

    aim_rot_rules: [
      { aimvar: Math.PI * 2, aimspeed: 0.075 },
    ],
  };

  return {
    world,

    entities: [
      team0_tmpl,
      team0_tmpl_mid,
      team0_tmpl,
      team0_tmpl,

      { ...VIP_TMPL, spawnarea: 8 },

      { ...team1_tmpl, spawnarea: 1 },

      { ...team1_tmpl, spawnarea: 2 },

      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },

      { ...team1_tmpl, spawnarea: 4 },

      { ...team1_tmpl, spawnarea: 5 },

      { ...team1_tmpl, spawnarea: 6 },

      { ...team1_tmpl, spawnarea: 7 },

      { ...team1_tmpl, spawnarea: 8 },
      { ...team1_tmpl, spawnarea: 8 },
    ],

    spawnareas: [
      { pos: new v2(-11, -629), extent: new v2(48, 34), heading: 0, spawn: true },
      { pos: new v2(-126, -254), extent: new v2(18, 21), heading: 0 },
      { pos: new v2(-66, -158), extent: new v2(10, 8), heading: 0 },
      { pos: new v2(-15, 0), extent: new v2(15, 25), heading: 0 },
      { pos: new v2(-156, -43), extent: new v2(12, 23), heading: 0 },
      { pos: new v2(141, -41), extent: new v2(11, 25), heading: 0 },
      { pos: new v2(39, 89), extent: new v2(13, 11), heading: 0 },
      { pos: new v2(-16, 102), extent: new v2(14, 12), heading: 0 },
      { pos: new v2(56, 357), extent: new v2(29, 14), heading: 0 },
      { pos: new v2(63, 467), extent: new v2(15, 14), heading: 0 },
      {
        pos: new v2(-10, 395), extent: new v2(172, 11), heading: 0,
        triggers: [
          {
            condition: 'enter',
            conditiontarget: { ty: 'entity', team: 0 },
            action: 'push_rule',
            actiontarget: { ty: 'entity', team: 0 },
            actionrules: [{ ty: 'capture' }],
          }
        ]
      },
    ],
    obstacle_specs: [
      { pos: new v2(-169, 154), extent: new v2(17, 165), heading: 0, ty: "full" },
      { pos: new v2(134, 343), extent: new v2(15, 37), heading: -0.4, ty: "full" },
      { pos: new v2(-122, 426), extent: new v2(11, 53), heading: 0.2, ty: "full" },
      { pos: new v2(109, 424), extent: new v2(13, 54), heading: -0.30000000000000004, ty: "full" },
      { pos: new v2(-2, 601), extent: new v2(25, 13), heading: 0, ty: "full" },
      { pos: new v2(-71, 542), extent: new v2(13, 80), heading: 0.6, ty: "full" },
      { pos: new v2(62, 541), extent: new v2(12, 82), heading: -0.5, ty: "full" },
      { pos: new v2(-170, -284), extent: new v2(18, 136), heading: 0, ty: "full" },
      { pos: new v2(149, -281), extent: new v2(19, 138), heading: 0, ty: "full" },
      { pos: new v2(-122, -572), extent: new v2(18, 88), heading: 0, ty: "full" },
      { pos: new v2(-215, -78), extent: new v2(17, 47), heading: 0, ty: "full" },
      { pos: new v2(-189, -20), extent: new v2(9, 26), heading: 0.7999999999999999, ty: "full" },
      { pos: new v2(-194, -138), extent: new v2(11, 23), heading: -0.6, ty: "full" },
      { pos: new v2(-151, 345), extent: new v2(16, 38), heading: 0.4, ty: "full" },
      { pos: new v2(150, 152), extent: new v2(20, 163), heading: 0, ty: "full" },
      { pos: new v2(198, -81), extent: new v2(12, 46), heading: 2.7755575615628914e-17, ty: "full" },
      { pos: new v2(178, -143), extent: new v2(9, 34), heading: 0.7, ty: "full" },
      { pos: new v2(179, -30), extent: new v2(8, 30), heading: -0.6, ty: "full" },
      { pos: new v2(96, -571), extent: new v2(18, 89), heading: 0, ty: "full" },
      { pos: new v2(-150, -446), extent: new v2(11, 53), heading: -0.4, ty: "full" },
      { pos: new v2(127, -451), extent: new v2(11, 59), heading: 0.4, ty: "full" },
      { pos: new v2(-73, -685), extent: new v2(53, 10), heading: 0.7999999999999999, ty: "full" },
      { pos: new v2(46, -687), extent: new v2(54, 10), heading: 2.400000000000001, ty: "full" },
      { pos: new v2(-101, -409), extent: new v2(12, 50), heading: 0, ty: "full" },
      { pos: new v2(71, -407), extent: new v2(12, 53), heading: 0, ty: "full" },
      { pos: new v2(-13, -413), extent: new v2(7, 39), heading: 0, ty: "half" },
      { pos: new v2(-84, 49), extent: new v2(9, 58), heading: 0, ty: "full" },
      { pos: new v2(65, 50), extent: new v2(8, 62), heading: 0, ty: "full" },
      { pos: new v2(-86, -178), extent: new v2(7, 34), heading: 0, ty: "full" },
      { pos: new v2(62, -178), extent: new v2(7, 34), heading: 0, ty: "full" },
      { pos: new v2(-60, -328), extent: new v2(11, 7), heading: 0, ty: "half" },
      { pos: new v2(26, -326), extent: new v2(6, 22), heading: 1.9000000000000006, ty: "half" },
      { pos: new v2(-32, -305), extent: new v2(7, 4), heading: 0, ty: "half" },
      { pos: new v2(-7, 454), extent: new v2(31, 30), heading: 0, ty: "full" },
      { pos: new v2(54, 403), extent: new v2(33, 6), heading: 0, ty: "half" },
      { pos: new v2(-68, 404), extent: new v2(33, 5), heading: 0, ty: "half" },
      { pos: new v2(88, 323), extent: new v2(16, 16), heading: 0, ty: "full" },
      { pos: new v2(-97, 321), extent: new v2(18, 17), heading: 0, ty: "full" },
      { pos: new v2(-10, 191), extent: new v2(16, 33), heading: 0, ty: "full" },
      { pos: new v2(-105, 188), extent: new v2(7, 33), heading: 0, ty: "full" },
      { pos: new v2(81, 189), extent: new v2(7, 34), heading: 0, ty: "full" },
      { pos: new v2(-43, 217), extent: new v2(15, 7), heading: 0, ty: "half" },
      { pos: new v2(22, 215), extent: new v2(15, 7), heading: 0, ty: "half" },
      { pos: new v2(-66, 113), extent: new v2(27, 6), heading: 0, ty: "full" },
      { pos: new v2(44, 112), extent: new v2(29, 6), heading: 0, ty: "full" },
      { pos: new v2(-95, -5), extent: new v2(11, 9), heading: 0, ty: "full" },
      { pos: new v2(73, -11), extent: new v2(12, 8), heading: 0, ty: "full" },
      { pos: new v2(-93, -145), extent: new v2(11, 8), heading: 0, ty: "full" },
      { pos: new v2(72, -145), extent: new v2(11, 8), heading: 0, ty: "full" },
      { pos: new v2(-43, 131), extent: new v2(4, 12), heading: 0, ty: "full" },
      { pos: new v2(19, 130), extent: new v2(4, 14), heading: 0, ty: "full" },
      { pos: new v2(-79, -267), extent: new v2(17, 5), heading: -0.7, ty: "full" },
      { pos: new v2(-75, -488), extent: new v2(29, 4), heading: 0, ty: "half" },
      { pos: new v2(51, -488), extent: new v2(34, 5), heading: 0, ty: "half" },
      { pos: new v2(-30, 343), extent: new v2(9, 6), heading: -0.1, ty: "half" },
      { pos: new v2(17, 332), extent: new v2(5, 14), heading: -1.0999999999999999, ty: "half" },
      { pos: new v2(-63, 365), extent: new v2(21, 3), heading: -0.2, ty: "half" },
      { pos: new v2(-85, 246), extent: new v2(4, 14), heading: 0.8999999999999999, ty: "half" },
      { pos: new v2(58, 245), extent: new v2(4, 15), heading: -0.7, ty: "half" },
      { pos: new v2(-43, -448), extent: new v2(16, 6), heading: 0, ty: "half" },
      { pos: new v2(18, -448), extent: new v2(17, 7), heading: 0, ty: "half" },
      { pos: new v2(-15, -723), extent: new v2(24, 10), heading: 0, ty: "full" },
      { pos: new v2(-75, -356), extent: new v2(39, 4), heading: 0, ty: "full" },
      { pos: new v2(44, -356), extent: new v2(39, 5), heading: 0, ty: "full" },
      { pos: new v2(98, -188), extent: new v2(39, 6), heading: -0.5, ty: "full" },
      { pos: new v2(-125, -186), extent: new v2(7, 43), heading: -1.0999999999999999, ty: "full" },
      { pos: new v2(-30, -231), extent: new v2(14, 3), heading: 0.30000000000000004, ty: "half" },
      { pos: new v2(8, -227), extent: new v2(14, 3), heading: -0.4, ty: "half" },
      { pos: new v2(-68, -288), extent: new v2(88, 4), heading: 0, ty: "full" },
      { pos: new v2(95, -287), extent: new v2(39, 5), heading: 0, ty: "full" },
      { pos: new v2(46, -265), extent: new v2(24, 4), heading: 0.8999999999999999, ty: "full" },
      { pos: new v2(9, -274), extent: new v2(15, 3), heading: -2.2000000000000006, ty: "full" },
      { pos: new v2(4, -206), extent: new v2(52, 6), heading: 0, ty: "full" },
      { pos: new v2(70, -142), extent: new v2(21, 5), heading: 0, ty: "full" },
      { pos: new v2(86, 289), extent: new v2(76, 6), heading: 0, ty: "full" },
      { pos: new v2(-93, 289), extent: new v2(64, 6), heading: 0, ty: "full" },
      { pos: new v2(-33, 272), extent: new v2(4, 13), heading: 0, ty: "full" },
      { pos: new v2(15, 271), extent: new v2(5, 14), heading: 0, ty: "full" },
      { pos: new v2(-36, 301), extent: new v2(7, 7), heading: 0, ty: "half" },
      { pos: new v2(17, 301), extent: new v2(7, 7), heading: 0, ty: "half" },
      { pos: new v2(-79, -117), extent: new v2(8, 9), heading: 0, ty: "half" },
      { pos: new v2(59, -119), extent: new v2(7, 7), heading: 0, ty: "half" },
      { pos: new v2(-81, -67), extent: new v2(3, 22), heading: 0, ty: "half" },
      { pos: new v2(61, -70), extent: new v2(4, 21), heading: 0, ty: "half" },
      { pos: new v2(23, -122), extent: new v2(10, 4), heading: -0.8999999999999999, ty: "half" },
      { pos: new v2(2, -112), extent: new v2(3, 6), heading: -1.1999999999999997, ty: "half" },
      { pos: new v2(-17, -109), extent: new v2(11, 3), heading: 0, ty: "half" },
      { pos: new v2(25, -77), extent: new v2(11, 9), heading: 0, ty: "full" },
      { pos: new v2(25, -32), extent: new v2(11, 13), heading: 0, ty: "full" },
      { pos: new v2(24, 15), extent: new v2(11, 11), heading: 0, ty: "full" },
      { pos: new v2(-53, -76), extent: new v2(11, 11), heading: 0, ty: "full" },
      { pos: new v2(-53, -29), extent: new v2(10, 13), heading: 0, ty: "full" },
      { pos: new v2(-54, 17), extent: new v2(11, 11), heading: 0, ty: "full" },
      { pos: new v2(-13, 57), extent: new v2(28, 8), heading: 0, ty: "half" },
      { pos: new v2(-42, 73), extent: new v2(4, 8), heading: 0, ty: "half" },
      { pos: new v2(17, 72), extent: new v2(4, 8), heading: 0, ty: "half" },
      { pos: new v2(6, 355), extent: new v2(12, 4), heading: 1.2, ty: "half" },
      { pos: new v2(-10, 282), extent: new v2(3, 13), heading: 0, ty: "half" },
      { pos: new v2(-31, -141), extent: new v2(57, 4), heading: 0, ty: "full" },
      { pos: new v2(-104, -29), extent: new v2(5, 20), heading: 0, ty: "half" },
      { pos: new v2(-104, -95), extent: new v2(6, 48), heading: 0, ty: "full" },
      { pos: new v2(85, -32), extent: new v2(5, 18), heading: 0, ty: "half" },
      { pos: new v2(85, -95), extent: new v2(6, 48), heading: 0, ty: "full" },
      { pos: new v2(44, -107), extent: new v2(9, 3), heading: 0.1, ty: "half" },
      { pos: new v2(-15, -72), extent: new v2(12, 4), heading: 0, ty: "half" },
      { pos: new v2(-56, -105), extent: new v2(11, 3), heading: -0.2, ty: "half" },
      { pos: new v2(-48, -49), extent: new v2(5, 6), heading: 0, ty: "half" },
      { pos: new v2(3, -40), extent: new v2(10, 4), heading: 0, ty: "half" },
      { pos: new v2(-51, 32), extent: new v2(8, 4), heading: 0, ty: "half" },
      { pos: new v2(21, 29), extent: new v2(8, 3), heading: 0, ty: "half" },
      { pos: new v2(-28, -33), extent: new v2(8, 4), heading: 0.30000000000000004, ty: "half" },
    ],

    goals: [
      { name: 'escape area', area: 9 },
    ],

    mission_rules: [
      { ty: 'explore' },
    ],

    editor: true,
  };
}

export function preset_bazaar1() {
  const world = { width: 800, height: 900, simover_rule: 'eliminate', };

  const team0_tmpl = {
    ...team0_tmpl_agent_ar_low,
    ...ENTITY_CONFIG_TMPL,
    speed: 20,
    allow_fire_control: true,
    firearm_range: 500,
  };

  const team0_tmpl_mid = {
    ...team0_tmpl_agent_ar_mid,
    speed: 20,
    allow_fire_control: true,
    firearm_range: 500,
  };

  // 적 에이전트 스펙 정의
  const team1_tmpl = {
    ...enemy_tmpl_agent_ar_low,
    team: 1,
    spawnarea: 1,

    life: 100,
    speed: 20,
    armor: 33,
    armor_hit_prob: 0.2,
    default_rule: 'idle', allow_crawl: false,
    vis_range: 300,

    aim_rot_rules: [
      { aimvar: Math.PI * 2, aimspeed: 0.075 },
    ],
  };

  return {
    world,

    entities: [
      team0_tmpl,
      team0_tmpl_mid,
      team0_tmpl_mid,
      team0_tmpl,

      { ...team1_tmpl, spawnarea: 1 },

      { ...team1_tmpl, spawnarea: 2 },
      { ...team1_tmpl, spawnarea: 2 },

      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },

      { ...team1_tmpl, spawnarea: 4 },
      { ...team1_tmpl, spawnarea: 4 },

      { ...team1_tmpl, spawnarea: 5 },

      { ...team1_tmpl, spawnarea: 6 },

      { ...team1_tmpl, spawnarea: 7 },

      { ...team1_tmpl, spawnarea: 8 },
      { ...team1_tmpl, spawnarea: 8 },

      { ...team1_tmpl, spawnarea: 9 },

      { ...team1_tmpl, spawnarea: 10 },

      { ...team1_tmpl, spawnarea: 11 },

      { ...team1_tmpl, spawnarea: 12 },

      { ...team1_tmpl, spawnarea: 13 },
      { ...team1_tmpl, spawnarea: 13 },

      { ...team1_tmpl, spawnarea: 14 },

      { ...team1_tmpl, spawnarea: 15 },
      { ...team1_tmpl, spawnarea: 15 },

      { ...team1_tmpl, spawnarea: 16 },
      { ...team1_tmpl, spawnarea: 16 },

      { ...team1_tmpl, spawnarea: 17 },

      { ...team1_tmpl, spawnarea: 18 },
      { ...team1_tmpl, spawnarea: 18 },

      { ...team1_tmpl, spawnarea: 19 },

      { ...team1_tmpl, spawnarea: 20 },
      { ...team1_tmpl, spawnarea: 20 },
    ],


    spawnareas: [
      { pos: new v2(-322, -343.85000228881836), extent: new v2(24, 39), heading: 0, spawn: true },
      { pos: new v2(342, -74.45000076293945), extent: new v2(13, 12), heading: 0 },
      { pos: new v2(314, -28.450000762939453), extent: new v2(12, 9), heading: 0 },
      { pos: new v2(356, 348.54999923706055), extent: new v2(8, 9), heading: 0 },
      { pos: new v2(328, 405.54999923706055), extent: new v2(11, 7), heading: 0 },
      { pos: new v2(305, 277.54999923706055), extent: new v2(10, 11), heading: 0 },
      { pos: new v2(42, 379.54999923706055), extent: new v2(9, 8), heading: 0 },
      { pos: new v2(-11, 410.54999923706055), extent: new v2(8, 8), heading: 0 },
      { pos: new v2(-100, 391.54999923706055), extent: new v2(10, 8), heading: 0 },
      { pos: new v2(-143, 241.54999923706055), extent: new v2(11, 9), heading: 0 },
      { pos: new v2(-310, 117.54999923706055), extent: new v2(7, 8), heading: 0 },
      { pos: new v2(-212, -159.45000076293945), extent: new v2(11, 9), heading: 0 },
      { pos: new v2(-313, -137.45000076293945), extent: new v2(9, 8), heading: 0 },
      { pos: new v2(198, 217.54999923706055), extent: new v2(7, 6), heading: 0 },
      { pos: new v2(234, 247.54999923706055), extent: new v2(6, 8), heading: 0.125 },
      { pos: new v2(-100, -231.45000076293945), extent: new v2(8, 6), heading: 0.125 },
      { pos: new v2(-114, -207.45000076293945), extent: new v2(7, 6), heading: 0 },
      { pos: new v2(26, 54.54999923706055), extent: new v2(7, 7), heading: 0 },
      { pos: new v2(104, 87.54999923706055), extent: new v2(8, 8), heading: 0.125 },
      { pos: new v2(115, 154.54999923706055), extent: new v2(8, 7), heading: 0.125 },
      { pos: new v2(35, 164.54999923706055), extent: new v2(8, 8), heading: 0 },
      { pos: new v2(238, -37.45000076293945), extent: new v2(7, 7), heading: 0 },
    ],
    obstacle_specs: [
      { pos: new v2(-160, -41.85000228881836), extent: new v2(11, 115), heading: 0.125, ty: "full" },
      { pos: new v2(110, -272.85000228881836), extent: new v2(86, 8), heading: 0, ty: "full" },
      { pos: new v2(229, -274.85000228881836), extent: new v2(35, 6), heading: 0, ty: "full" },
      { pos: new v2(273, -189.85000228881836), extent: new v2(12, 91), heading: 0, ty: "full" },
      { pos: new v2(273, 6.149997711181641), extent: new v2(12, 108), heading: 0, ty: "full" },
      { pos: new v2(273, 212.14999771118164), extent: new v2(12, 101), heading: 0, ty: "full" },
      { pos: new v2(152, 337.14999771118164), extent: new v2(135, 14), heading: 0.125, ty: "full" },
      { pos: new v2(-34, 357.14999771118164), extent: new v2(56, 11), heading: 0.125, ty: "full" },
      { pos: new v2(-88, 444.14999771118164), extent: new v2(63, 7), heading: 0, ty: "full" },
      { pos: new v2(45, 435.14999771118164), extent: new v2(29, 22), heading: 0, ty: "full" },
      { pos: new v2(142, 430.14999771118164), extent: new v2(29, 26), heading: 0, ty: "full" },
      { pos: new v2(199, 428.14999771118164), extent: new v2(10, 25), heading: 0, ty: "full" },
      { pos: new v2(300, 426.14999771118164), extent: new v2(8, 29), heading: 0, ty: "full" },
      { pos: new v2(252, 446.14999771118164), extent: new v2(46, 7), heading: 0, ty: "full" },
      { pos: new v2(388, 310.14999771118164), extent: new v2(13, 85), heading: 0, ty: "full" },
      { pos: new v2(388, 69.14999771118164), extent: new v2(16, 66), heading: 0, ty: "full" },
      { pos: new v2(393, -193.85000228881836), extent: new v2(12, 51), heading: 0, ty: "full" },
      { pos: new v2(329, -442.85000228881836), extent: new v2(105, 8), heading: 0, ty: "full" },
      { pos: new v2(83, -422.85000228881836), extent: new v2(90, 36), heading: 0, ty: "full" },
      { pos: new v2(-56, -442.85000228881836), extent: new v2(34, 12), heading: 0, ty: "full" },
      { pos: new v2(-182, -421.85000228881836), extent: new v2(79, 30), heading: 0, ty: "full" },
      { pos: new v2(-385, -431.85000228881836), extent: new v2(21, 31), heading: 0, ty: "full" },
      { pos: new v2(-391, -198.85000228881836), extent: new v2(32, 33), heading: 0, ty: "full" },
      { pos: new v2(-391, -236.85000228881836), extent: new v2(15, 10), heading: 0, ty: "full" },
      { pos: new v2(-365, 60.14999771118164), extent: new v2(53, 43), heading: 0, ty: "full" },
      { pos: new v2(-386, 155.14999771118164), extent: new v2(26, 55), heading: 0, ty: "full" },
      { pos: new v2(-389, 301.14999771118164), extent: new v2(23, 55), heading: 0, ty: "full" },
      { pos: new v2(-286, 430.14999771118164), extent: new v2(20, 28), heading: 0, ty: "full" },
      { pos: new v2(-169, 421.14999771118164), extent: new v2(22, 33), heading: 0, ty: "full" },
      { pos: new v2(-232, 441.14999771118164), extent: new v2(47, 10), heading: 0, ty: "full" },
      { pos: new v2(251, 418.1499996185303), extent: new v2(23, 9), heading: 0, ty: "half" },
      { pos: new v2(-56, 417.1499996185303), extent: new v2(13, 24), heading: 0, ty: "full" },
      { pos: new v2(-341, -68.85000038146973), extent: new v2(31, 12), heading: 0, ty: "full" },
      { pos: new v2(-317, -20.850000381469727), extent: new v2(7, 41), heading: 0, ty: "half" },
      { pos: new v2(-361, -105.85000038146973), extent: new v2(26, 13), heading: 1.625, ty: "full" },
      { pos: new v2(-357, -15.85000228881836), extent: new v2(7, 19), heading: 0, ty: "full" },
      { pos: new v2(393, -420.85000228881836), extent: new v2(32, 29), heading: 0, ty: "full" },
      { pos: new v2(370, -340.85000228881836), extent: new v2(35, 12), heading: 0, ty: "full" },
      { pos: new v2(276, -404.85000228881836), extent: new v2(52, 8), heading: 0, ty: "half" },
      { pos: new v2(196, -381.85000228881836), extent: new v2(6, 9), heading: 2, ty: "half" },
      { pos: new v2(-180, 149.14999771118164), extent: new v2(15, 55), heading: 0.125, ty: "full" },
      { pos: new v2(-168, 84.14999771118164), extent: new v2(35, 12), heading: 0.125, ty: "full" },
      { pos: new v2(240, -173.85000228881836), extent: new v2(29, 61), heading: 0, ty: "full" },
      { pos: new v2(-57, -398.85000228881836), extent: new v2(23, 8), heading: 0, ty: "half" },
      { pos: new v2(-311, -433.85000228881836), extent: new v2(26, 10), heading: 0, ty: "full" },
      { pos: new v2(190, 277.14999771118164), extent: new v2(22, 8), heading: 0.125, ty: "half" },
      { pos: new v2(152, 297.54999923706055), extent: new v2(9, 30), heading: 0.125, ty: "full" },
      { pos: new v2(74, 308.54999923706055), extent: new v2(11, 28), heading: 0.125, ty: "full" },
      { pos: new v2(-1, 317.54999923706055), extent: new v2(11, 27), heading: 0.125, ty: "full" },
      { pos: new v2(111, 286.54999923706055), extent: new v2(18, 7), heading: 0.125, ty: "half" },
      { pos: new v2(-10, 138.54999923706055), extent: new v2(10, 39), heading: 0.125, ty: "full" },
      { pos: new v2(-22, 38.54999923706055), extent: new v2(11, 67), heading: 0.125, ty: "full" },
      { pos: new v2(-41, -102.45000076293945), extent: new v2(10, 82), heading: 0.125, ty: "full" },
      { pos: new v2(30, 219.54999923706055), extent: new v2(54, 7), heading: 0.125, ty: "full" },
      { pos: new v2(150, 204.54999923706055), extent: new v2(69, 7), heading: 0.125, ty: "full" },
      { pos: new v2(240, 193.54999923706055), extent: new v2(25, 7), heading: 0.125, ty: "full" },
      { pos: new v2(-90, 258.54999923706055), extent: new v2(12, 50), heading: 0.125, ty: "full" },
      { pos: new v2(-136, 207.54999923706055), extent: new v2(51, 12), heading: 0.125, ty: "full" },
      { pos: new v2(-15, 199.54999923706055), extent: new v2(12, 26), heading: 0.125, ty: "full" },
      { pos: new v2(-10, 175.54999923706055), extent: new v2(11, 11), heading: 0, ty: "full" },
      { pos: new v2(128, 222.54999923706055), extent: new v2(6, 11), heading: 0.125, ty: "half" },
      { pos: new v2(176, 217.54999923706055), extent: new v2(10, 13), heading: 0.125, ty: "half" },
      { pos: new v2(71, 283.54999923706055), extent: new v2(6, 17), heading: 0.125, ty: "half" },
      { pos: new v2(29, 231.54999923706055), extent: new v2(32, 8), heading: 0.125, ty: "half" },
      { pos: new v2(253, 209.54999923706055), extent: new v2(11, 17), heading: 0.125, ty: "half" },
      { pos: new v2(100, 249.54999923706055), extent: new v2(4, 10), heading: 0.25, ty: "half" },
      { pos: new v2(209, 238.54999923706055), extent: new v2(14, 6), heading: 0.75, ty: "half" },
      { pos: new v2(34, 297.54999923706055), extent: new v2(15, 6), heading: 0.125, ty: "half" },
      { pos: new v2(394, -110.45000076293945), extent: new v2(11, 15), heading: 0, ty: "half" },
      { pos: new v2(392, -38.45000076293945), extent: new v2(8, 30), heading: 0, ty: "half" },
      { pos: new v2(343, 263.54999923706055), extent: new v2(13, 38), heading: 0.125, ty: "half" },
      { pos: new v2(311, 320.54999923706055), extent: new v2(18, 8), heading: 0.125, ty: "half" },
      { pos: new v2(356, 320.54999923706055), extent: new v2(13, 8), heading: -0.125, ty: "half" },
      { pos: new v2(387, 380.54999923706055), extent: new v2(17, 10), heading: 0.25, ty: "full" },
      { pos: new v2(322, 370.54999923706055), extent: new v2(22, 5), heading: 0.25, ty: "half" },
      { pos: new v2(-131, 302.14999771118164), extent: new v2(47, 11), heading: 0.125, ty: "full" },
      { pos: new v2(-172, 257.14999771118164), extent: new v2(8, 52), heading: 0.125, ty: "half" },
      { pos: new v2(-360, 290.14999771118164), extent: new v2(8, 43), heading: 0, ty: "half" },
      { pos: new v2(-377, 403.14999771118164), extent: new v2(25, 53), heading: 0, ty: "full" },
      { pos: new v2(-329, 442.14999771118164), extent: new v2(29, 10), heading: 0, ty: "full" },
      { pos: new v2(176, 446.14999771118164), extent: new v2(16, 11), heading: 0, ty: "full" },
      { pos: new v2(-179, -207.85000228881836), extent: new v2(9, 58), heading: 0.125, ty: "full" },
      { pos: new v2(-30, -273.85000228881836), extent: new v2(60, 7), heading: 0, ty: "full" },
      { pos: new v2(-140, -268.85000228881836), extent: new v2(56, 9), heading: 0.125, ty: "full" },
      { pos: new v2(-95, 359.14999771118164), extent: new v2(39, 6), heading: 0.125, ty: "full" },
      { pos: new v2(-137, 129.54999923706055), extent: new v2(5, 24), heading: -0.75, ty: "half" },
      { pos: new v2(-132, 163.54999923706055), extent: new v2(6, 7), heading: 0, ty: "half" },
      { pos: new v2(-110, 145.54999923706055), extent: new v2(13, 8), heading: -0.125, ty: "half" },
      { pos: new v2(-28, 340.54999923706055), extent: new v2(7, 22), heading: 1.75, ty: "half" },
      { pos: new v2(-70, 274.54999923706055), extent: new v2(17, 7), heading: -1.5, ty: "half" },
      { pos: new v2(-38, 194.54999923706055), extent: new v2(16, 5), heading: 0.125, ty: "half" },
      { pos: new v2(-57, 230.54999923706055), extent: new v2(9, 5), heading: -0.25, ty: "half" },
      { pos: new v2(-17, 264.54999923706055), extent: new v2(7, 12), heading: 0.875, ty: "half" },
      { pos: new v2(-18, -176.45000076293945), extent: new v2(10, 29), heading: -1.5, ty: "full" },
      { pos: new v2(194, -200.45000076293945), extent: new v2(80, 12), heading: 0.125, ty: "full" },
      { pos: new v2(230, -250.45000076293945), extent: new v2(45, 22), heading: 0, ty: "full" },
      { pos: new v2(199, -217.45000076293945), extent: new v2(14, 21), heading: 0, ty: "full" },
      { pos: new v2(188, -86.45000076293945), extent: new v2(78, 27), heading: 0.125, ty: "full" },
      { pos: new v2(173, 19.549999237060547), extent: new v2(54, 37), heading: 0.125, ty: "full" },
      { pos: new v2(53, -23.450000762939453), extent: new v2(19, 42), heading: 0.125, ty: "full" },
      { pos: new v2(10, 126.54999923706055), extent: new v2(12, 6), heading: 0.125, ty: "full" },
      { pos: new v2(56, 119.54999923706055), extent: new v2(15, 7), heading: 0.125, ty: "full" },
      { pos: new v2(107, 113.54999923706055), extent: new v2(12, 7), heading: 0.125, ty: "full" },
      { pos: new v2(155, 108.54999923706055), extent: new v2(14, 7), heading: 0.125, ty: "full" },
      { pos: new v2(32, 123.54999923706055), extent: new v2(17, 4), heading: 0.125, ty: "half" },
      { pos: new v2(81, 116.54999923706055), extent: new v2(14, 5), heading: 0.125, ty: "half" },
      { pos: new v2(136, 110.54999923706055), extent: new v2(24, 5), heading: 0.125, ty: "half" },
      { pos: new v2(253, 95.54999923706055), extent: new v2(26, 8), heading: 0.125, ty: "full" },
      { pos: new v2(222, 99.54999923706055), extent: new v2(18, 4), heading: 0.125, ty: "half" },
      { pos: new v2(205, 120.54999923706055), extent: new v2(3, 6), heading: 0.875, ty: "half" },
      { pos: new v2(198, 76.54999923706055), extent: new v2(10, 3), heading: -1.875, ty: "half" },
      { pos: new v2(7, 157.54999923706055), extent: new v2(8, 9), heading: 0.125, ty: "half" },
      { pos: new v2(44, 203.54999923706055), extent: new v2(9, 15), heading: 1.75, ty: "half" },
      { pos: new v2(235, 121.54999923706055), extent: new v2(7, 22), heading: 0, ty: "full" },
      { pos: new v2(234, 146.54999923706055), extent: new v2(6, 13), heading: 0, ty: "full" },
      { pos: new v2(252, 130.54999923706055), extent: new v2(22, 32), heading: 0, ty: "full" },
      { pos: new v2(254, 172.54999923706055), extent: new v2(24, 24), heading: 0.125, ty: "full" },
      { pos: new v2(-140, -40.85000228881836), extent: new v2(11, 33), heading: 0.125, ty: "half" },
      { pos: new v2(-127, 42.14999771118164), extent: new v2(12, 31), heading: 0.125, ty: "half" },
      { pos: new v2(-61, -107.85000228881836), extent: new v2(11, 35), heading: 0.125, ty: "half" },
      { pos: new v2(-53, -38.85000228881836), extent: new v2(10, 21), heading: 0.125, ty: "half" },
      { pos: new v2(-43, 23.14999771118164), extent: new v2(12, 24), heading: 0.125, ty: "half" },
      { pos: new v2(-156, -163.85000228881836), extent: new v2(14, 18), heading: 0.125, ty: "half" },
      { pos: new v2(-154, -107.85000228881836), extent: new v2(13, 15), heading: 0.125, ty: "half" },
      { pos: new v2(-24, 118.14999771118164), extent: new v2(8, 16), heading: 0.125, ty: "half" },
      { pos: new v2(-162, -244.85000228881836), extent: new v2(22, 15), heading: 0.125, ty: "half" },
      { pos: new v2(-119, -253.85000228881836), extent: new v2(6, 15), heading: 0.125, ty: "full" },
      { pos: new v2(-73, -256.85000228881836), extent: new v2(28, 12), heading: 0, ty: "half" },
      { pos: new v2(36, -257.85000228881836), extent: new v2(35, 10), heading: 0, ty: "half" },
      { pos: new v2(127, -256.85000228881836), extent: new v2(40, 18), heading: 0, ty: "half" },
      { pos: new v2(135, -207.85000228881836), extent: new v2(21, 12), heading: 0.125, ty: "half" },
      { pos: new v2(-9, -190.85000228881836), extent: new v2(16, 11), heading: 0, ty: "half" },
      { pos: new v2(-49, -190.85000228881836), extent: new v2(12, 17), heading: 0.125, ty: "half" },
      { pos: new v2(-105, -142.85000228881836), extent: new v2(12, 8), heading: -0.5, ty: "half" },
      { pos: new v2(-92, 2.1499977111816406), extent: new v2(6, 10), heading: 2.375, ty: "half" },
      { pos: new v2(-85, -26.85000228881836), extent: new v2(6, 13), heading: 0.125, ty: "half" },
      { pos: new v2(-112, -91.85000228881836), extent: new v2(12, 4), heading: -0.125, ty: "half" },
      { pos: new v2(-62, -155.85000228881836), extent: new v2(8, 5), heading: 0.125, ty: "half" },
      { pos: new v2(32, -217.85000228881836), extent: new v2(14, 4), heading: -0.75, ty: "half" },
      { pos: new v2(177, -160.85000228881836), extent: new v2(12, 14), heading: 0.125, ty: "half" },
      { pos: new v2(187, -130.85000228881836), extent: new v2(18, 8), heading: 0.125, ty: "half" },
      { pos: new v2(-24, -125.85000228881836), extent: new v2(13, 23), heading: 0.125, ty: "half" },
      { pos: new v2(-13, -32.85000228881836), extent: new v2(9, 32), heading: 0.125, ty: "half" },
      { pos: new v2(47, -72.85000228881836), extent: new v2(12, 12), heading: 0.125, ty: "half" },
      { pos: new v2(60, 25.14999771118164), extent: new v2(14, 13), heading: 0.125, ty: "half" },
      { pos: new v2(78, -25.85000228881836), extent: new v2(7, 20), heading: 0.125, ty: "half" },
      { pos: new v2(-2, 77.14999771118164), extent: new v2(11, 31), heading: 0.125, ty: "half" },
      { pos: new v2(72, -167.85000228881836), extent: new v2(8, 14), heading: 1.25, ty: "half" },
      { pos: new v2(86, -128.85000228881836), extent: new v2(5, 18), heading: -0.75, ty: "half" },
      { pos: new v2(38, -134.85000228881836), extent: new v2(7, 23), heading: 0.5, ty: "half" },
      { pos: new v2(55, 107.14999771118164), extent: new v2(9, 6), heading: 0.125, ty: "half" },
      { pos: new v2(81, 70.14999771118164), extent: new v2(6, 12), heading: -0.25, ty: "half" },
      { pos: new v2(143, -46.85000228881836), extent: new v2(22, 8), heading: 0.125, ty: "half" },
      { pos: new v2(227, -58.85000228881836), extent: new v2(37, 7), heading: 0.125, ty: "half" },
      { pos: new v2(255, 5.149997711181641), extent: new v2(8, 15), heading: 0, ty: "half" },
      { pos: new v2(252, 80.14999771118164), extent: new v2(11, 14), heading: 0.125, ty: "half" },
      { pos: new v2(131, 195.14999771118164), extent: new v2(8, 21), heading: 1.625, ty: "half" },
      { pos: new v2(88, 139.54999923706055), extent: new v2(43, 5), heading: 0.125, ty: "half" },
      { pos: new v2(220, 181.54999923706055), extent: new v2(12, 9), heading: 0.125, ty: "half" },
      { pos: new v2(177, 138.54999923706055), extent: new v2(19, 4), heading: 0, ty: "half" },
      { pos: new v2(175, 164.54999923706055), extent: new v2(5, 11), heading: 4.75, ty: "half" },
      { pos: new v2(225, 141.54999923706055), extent: new v2(6, 8), heading: 0, ty: "half" },
      { pos: new v2(87, 173.54999923706055), extent: new v2(4, 11), heading: -0.25, ty: "half" },
      { pos: new v2(-109, 317.54999923706055), extent: new v2(28, 8), heading: 0.125, ty: "half" },
      { pos: new v2(244, 287.54999923706055), extent: new v2(20, 29), heading: 0.125, ty: "full" },
      { pos: new v2(-123, -185.45000076293945), extent: new v2(5, 18), heading: 1, ty: "half" },
      { pos: new v2(-82, -219.45000076293945), extent: new v2(4, 10), heading: 0, ty: "half" },
      { pos: new v2(-257, -281.45000076293945), extent: new v2(7, 36), heading: 0, ty: "full" },
      { pos: new v2(-224, -234.45000076293945), extent: new v2(38, 5), heading: 0, ty: "full" },
      { pos: new v2(-325, -238.45000076293945), extent: new v2(77, 9), heading: 0, ty: "full" },
      { pos: new v2(-376, -374.45000076293945), extent: new v2(14, 10), heading: 0, ty: "half" },
      { pos: new v2(-376, -345.45000076293945), extent: new v2(15, 9), heading: 0, ty: "half" },
      { pos: new v2(-369, -260.45000076293945), extent: new v2(18, 6), heading: -0.125, ty: "half" },
      { pos: new v2(-322, -256.45000076293945), extent: new v2(18, 4), heading: 0, ty: "half" },
      { pos: new v2(-281, -262.45000076293945), extent: new v2(6, 10), heading: -0.875, ty: "half" },
      { pos: new v2(-341, -404.45000076293945), extent: new v2(15, 6), heading: 0, ty: "half" },
      { pos: new v2(-315, -398.45000076293945), extent: new v2(3, 10), heading: 0.375, ty: "half" },
      { pos: new v2(-292, -404.45000076293945), extent: new v2(14, 6), heading: 0, ty: "half" },
      { pos: new v2(-375, -289.45000076293945), extent: new v2(19, 11), heading: 0, ty: "half" },
      { pos: new v2(-271, -326.45000076293945), extent: new v2(5, 18), heading: -0.75, ty: "half" },
      { pos: new v2(-264, -377.45000076293945), extent: new v2(5, 18), heading: 0.5, ty: "half" },
      { pos: new v2(-187, -299.85000228881836), extent: new v2(8, 21), heading: 0.125, ty: "half" },
      { pos: new v2(-179, -358.85000228881836), extent: new v2(9, 27), heading: -0.125, ty: "half" },
      { pos: new v2(-105, -308.85000228881836), extent: new v2(39, 14), heading: 0, ty: "half" },
      { pos: new v2(-29, -308.85000228881836), extent: new v2(21, 11), heading: 0, ty: "half" },
      { pos: new v2(8, -358.85000228881836), extent: new v2(27, 11), heading: -0.125, ty: "half" },
      { pos: new v2(74, -371.85000228881836), extent: new v2(20, 8), heading: 0, ty: "half" },
      { pos: new v2(123, -371.85000228881836), extent: new v2(17, 8), heading: 0, ty: "half" },
      { pos: new v2(128, -325.85000228881836), extent: new v2(6, 5), heading: -0.25, ty: "half" },
      { pos: new v2(156, -322.85000228881836), extent: new v2(14, 9), heading: 0.125, ty: "half" },
      { pos: new v2(386, -316.85000228881836), extent: new v2(18, 19), heading: 0, ty: "half" },
      { pos: new v2(293, 19.14999771118164), extent: new v2(10, 32), heading: 0, ty: "half" },
      { pos: new v2(296, -112.85000228881836), extent: new v2(13, 26), heading: 0, ty: "half" },
      { pos: new v2(296, -55.85000228881836), extent: new v2(15, 6), heading: 0, ty: "full" },
      { pos: new v2(358, 66.14999771118164), extent: new v2(8, 15), heading: 0, ty: "half" },
      { pos: new v2(360, 25.14999771118164), extent: new v2(6, 16), heading: 0, ty: "half" },
      { pos: new v2(358, 105.14999771118164), extent: new v2(9, 17), heading: 0, ty: "half" },
      { pos: new v2(392, 180.14999771118164), extent: new v2(10, 27), heading: 0, ty: "half" },
      { pos: new v2(287, 91.14999771118164), extent: new v2(8, 18), heading: 0, ty: "half" },
      { pos: new v2(291, 155.14999771118164), extent: new v2(17, 19), heading: 0, ty: "half" },
      { pos: new v2(292, 224.14999771118164), extent: new v2(8, 12), heading: 0, ty: "half" },
      { pos: new v2(171, 357.54999923706055), extent: new v2(46, 8), heading: 0.125, ty: "half" },
      { pos: new v2(266, 358.54999923706055), extent: new v2(12, 5), heading: -0.5, ty: "half" },
      { pos: new v2(0, 378.54999923706055), extent: new v2(6, 17), heading: 0.125, ty: "half" },
      { pos: new v2(68, 402.54999923706055), extent: new v2(6, 15), heading: 0, ty: "half" },
      { pos: new v2(102, 384.54999923706055), extent: new v2(6, 10), heading: 0.125, ty: "half" },
      { pos: new v2(-135, 428.54999923706055), extent: new v2(13, 10), heading: 0, ty: "half" },
      { pos: new v2(-86, 429.54999923706055), extent: new v2(19, 10), heading: 0, ty: "half" },
      { pos: new v2(-249, 277.54999923706055), extent: new v2(34, 8), heading: -0.5, ty: "half" },
      { pos: new v2(-296, 313.54999923706055), extent: new v2(10, 32), heading: 0.125, ty: "half" },
      { pos: new v2(-348, 125.54999923706055), extent: new v2(17, 26), heading: 0, ty: "half" },
      { pos: new v2(-349, 186.54999923706055), extent: new v2(16, 13), heading: 0, ty: "half" },
      { pos: new v2(-244, 336.54999923706055), extent: new v2(30, 7), heading: 0.625, ty: "half" },
      { pos: new v2(-229, 423.54999923706055), extent: new v2(17, 10), heading: 0, ty: "half" },
      { pos: new v2(-338, 378.54999923706055), extent: new v2(14, 17), heading: 0, ty: "half" },
      { pos: new v2(-208, 148.54999923706055), extent: new v2(16, 33), heading: 0.125, ty: "half" },
      { pos: new v2(-283, 159.54999923706055), extent: new v2(30, 8), heading: 0.25, ty: "half" },
      { pos: new v2(-207, -21.450000762939453), extent: new v2(7, 55), heading: 1.5, ty: "half" },
      { pos: new v2(-309, 75.54999923706055), extent: new v2(18, 10), heading: 0, ty: "half" },
      { pos: new v2(-171, 27.549999237060547), extent: new v2(14, 10), heading: 0.125, ty: "half" },
      { pos: new v2(-337, -96.45000076293945), extent: new v2(15, 17), heading: 0.125, ty: "half" },
      { pos: new v2(-354, -191.45000076293945), extent: new v2(13, 20), heading: 0, ty: "half" },
      { pos: new v2(-265, -229.45000076293945), extent: new v2(29, 9), heading: 0, ty: "full" },
      { pos: new v2(-219, -232.45000076293945), extent: new v2(32, 11), heading: 0, ty: "full" },
      { pos: new v2(-279, -167.45000076293945), extent: new v2(40, 7), heading: -0.25, ty: "half" },
      { pos: new v2(-215, -119.45000076293945), extent: new v2(10, 16), heading: 0.75, ty: "half" },
      { pos: new v2(245, -284.85000228881836), extent: new v2(30, 10), heading: 0, ty: "half" },
      { pos: new v2(79, -284.85000228881836), extent: new v2(37, 10), heading: 0, ty: "half" },
      { pos: new v2(167, -287.85000228881836), extent: new v2(13, 8), heading: 0, ty: "half" },
      { pos: new v2(292, -226.85000228881836), extent: new v2(11, 24), heading: 0, ty: "half" },
      { pos: new v2(329, -279.85000228881836), extent: new v2(19, 6), heading: -0.5, ty: "half" },
      { pos: new v2(374, -223.85000228881836), extent: new v2(10, 17), heading: 0, ty: "half" },
      { pos: new v2(374, -169.85000228881836), extent: new v2(8, 18), heading: 0, ty: "half" },
      { pos: new v2(-147, 353.5500011444092), extent: new v2(29, 6), heading: -0.25, ty: "full" },
    ],

    goals: [],
    mission_rules: [
      { ty: 'explore' },
    ],

    editor: true
  };
}

export function preset_bazaar_heal() {
  const preset = preset_bazaar1();

  let e0 = preset.entities.find((e) => e.team === 0);
  e0.heals = [
    {
      heal_amount: 50,
      heal_duration: 3,
    },
  ];

  return preset;
}

export function preset_bazaar_dash() {
  const preset = preset_bazaar1();
  for (const entity of preset.entities) {
    entity.perk_cover_dash = true;
  }
  return preset;
}

export function preset_swamp_trigger() {
  const world = {
    width: 800,
    height: 2000,
    simover_rule: 'eliminate',
  };

  const team1_tmpl_exp = {
    ...ENTITY_CONFIG_TMPL,
    ...tmpl_firearm_ar_mid,

    team: 1, spawnarea: 1,
    default_rule: 'explore',
  };

  const team1_tmpl_idle = {
    ...ENTITY_CONFIG_TMPL,
    ...tmpl_firearm_ar_mid,

    team: 1, spawnarea: 2,
    default_rule: 'idle',
  };

  return {
    world,

    entities: [
      entityFromStat2(stats2_const(15), tmpl_firearm_dmr_mid),
      entityFromStat2(stats2_const(15), tmpl_firearm_dmr_high),

      team1_tmpl_exp,
      team1_tmpl_exp,
      team1_tmpl_exp,
      team1_tmpl_exp,
      team1_tmpl_exp,

      team1_tmpl_idle,
      team1_tmpl_idle,
      team1_tmpl_idle,
      team1_tmpl_idle,
      team1_tmpl_idle,
      team1_tmpl_idle,
      team1_tmpl_idle,
      team1_tmpl_idle,
      team1_tmpl_idle,
      team1_tmpl_idle,
      team1_tmpl_idle,
    ],


    spawnareas: [
      { pos: new v2(-289, -945.8500022888184), extent: new v2(104, 40), heading: 0, spawn: true },
      { pos: new v2(-313, 790.5499877929688), extent: new v2(72, 34), heading: 0 },
      { pos: new v2(296, 693.5499877929688), extent: new v2(81, 50), heading: 0 },
      { pos: new v2(-17, -162.85000610351562), extent: new v2(427, 604), heading: 0, effect_ty: 'swamp' },
    ],

    obstacle_specs: [
      { pos: new v2(233, 168.14999389648438), extent: new v2(66, 8), heading: 0, ty: "half" },
      { pos: new v2(-231, 234.14999389648438), extent: new v2(62, 7), heading: 0, ty: "half" },
      { pos: new v2(310, 613.550048828125), extent: new v2(256, 15), heading: 0, ty: "full" },
      { pos: new v2(9, 323.1500244140625), extent: new v2(92, 8), heading: 0, ty: "half" },
      { pos: new v2(-112, 273.1500244140625), extent: new v2(48, 10), heading: 0, ty: "half" },
      { pos: new v2(-148, 81.14999389648438), extent: new v2(52, 12), heading: -0.25, ty: "full" },
      { pos: new v2(98, 34.149993896484375), extent: new v2(68, 10), heading: -0.125, ty: "full" },
      { pos: new v2(-55, -45.850006103515625), extent: new v2(33, 9), heading: 0.125, ty: "full" },
      { pos: new v2(-199, -96.84999084472656), extent: new v2(48, 9), heading: -0.125, ty: "half" },
      { pos: new v2(161, -155.84999084472656), extent: new v2(84, 8), heading: 0.125, ty: "half" },
      { pos: new v2(-44, -245.8499984741211), extent: new v2(32, 9), heading: 0, ty: "half" },
      { pos: new v2(-226, -784.8500022888184), extent: new v2(145, 11), heading: 0, ty: "half" },
      { pos: new v2(149, -781.8500022888184), extent: new v2(127, 16), heading: 0, ty: "half" },
      { pos: new v2(-260, -251.85000228881836), extent: new v2(57, 11), heading: 0, ty: "half" },
      { pos: new v2(284, -16.85000228881836), extent: new v2(109, 14), heading: -0.375, ty: "half" },
      { pos: new v2(-368, 4.34375), extent: new v2(109, 12), heading: 0, ty: "full" },
    ],

    goals: [],
    mission_rules: [{ ty: 'explore' }],
  };
}


export function preset_mansion() {
  const world = {
    width: 800,
    height: 2500,
    simover_rule: 'eliminate',
  };

  const team1_tmpl_idle = {
    ...ENTITY_CONFIG_TMPL,
    ...tmpl_firearm_ar_mid,

    team: 1, spawnarea: 2,
    default_rule: 'idle',
  };

  const team0_tmpl_ar = {
    ...ENTITY_CONFIG_TMPL,
    ...tmpl_firearm_ar_high,

    team: 0,
    default_rule: 'explore',
  };


  return {
    world,

    entities: [
      entityFromStat2(stats2_const(15), tmpl_firearm_ar_mid),
      entityFromStat2(stats2_const(15), tmpl_firearm_ar_high),

      { ...team0_tmpl_ar, spawnarea: 1, allow_coordinated_fire: true },
      { ...team0_tmpl_ar, spawnarea: 1, allow_coordinated_fire: true },

      { ...team1_tmpl_idle, spawnarea: 2, vis_group: 1 },
      { ...team1_tmpl_idle, spawnarea: 2, vis_group: 1 },
      { ...team1_tmpl_idle, spawnarea: 2, vis_group: 1 },


      { ...team1_tmpl_idle, spawnarea: 3 },
      { ...team1_tmpl_idle, spawnarea: 3 },
      { ...team1_tmpl_idle, spawnarea: 3 },
      { ...team1_tmpl_idle, spawnarea: 3 },
      { ...team1_tmpl_idle, spawnarea: 3 },

      { ...team1_tmpl_idle, spawnarea: 4, vis_group: 3 },
      { ...team1_tmpl_idle, spawnarea: 4, vis_group: 3 },
      { ...team1_tmpl_idle, spawnarea: 5, vis_group: 3 },
      { ...team1_tmpl_idle, spawnarea: 5, vis_group: 3 },
    ],


    spawnareas: [
      { pos: new v2(-280, -1164.8500022888184), extent: new v2(144, 49), heading: 0, spawn: true },
      { pos: new v2(-23, 677.1500244140625), extent: new v2(253, 35), heading: 0, spawn: true },
      { pos: new v2(50, -812.8500022888184), extent: new v2(106, 32), heading: 0 },
      {
        pos: new v2(-6, -368.8500061035156), extent: new v2(290, 283), heading: 0,
        structureopts: { count: 1 },
        triggers: [
          {
            condition: 'enter',
            conditiontarget: {
              ty: 'entity',
              team: 0,
            },

            action: 'push_rule',
            actiontarget: {
              ty: 'entity',
              team: 0,
            },
            actionrules: [{ ty: 'explore', area: 3 }],
          }
        ],
      },
      { pos: new v2(-222, 112.1500244140625), extent: new v2(79, 27), heading: 0 },
      { pos: new v2(178, 120.1500244140625), extent: new v2(75, 28), heading: 0 },
    ],
    obstacle_specs: [
      { pos: new v2(358, -254.85000610351562), extent: new v2(11, 269), heading: 0, ty: "full" },
      { pos: new v2(46, 2.5500030517578125), extent: new v2(307, 11), heading: 0, ty: "full" },
      { pos: new v2(-8, 226.75), extent: new v2(89, 9), heading: 0, ty: "full" },
      { pos: new v2(344, 241.75), extent: new v2(54, 13), heading: 0, ty: "full" },
      { pos: new v2(-394, 205.75), extent: new v2(57, 13), heading: 0, ty: "full" },
      { pos: new v2(181, 211.75), extent: new v2(73, 10), heading: 0, ty: "half" },
      { pos: new v2(-220, 197.75), extent: new v2(73, 13), heading: 0, ty: "half" },
      { pos: new v2(-270, -1001.8500022888184), extent: new v2(224, 9), heading: 0, ty: "full" },
      { pos: new v2(296, -1001.8500022888184), extent: new v2(181, 12), heading: 0, ty: "full" },
      { pos: new v2(-36, -1001.8500022888184), extent: new v2(11, 17), heading: 0, ty: "full" },
      { pos: new v2(105, -1001.8500022888184), extent: new v2(12, 18), heading: 0, ty: "full" },
      { pos: new v2(357, -631.8499908447266), extent: new v2(13, 120), heading: 0, ty: "full" },
      { pos: new v2(142, -740.8499908447266), extent: new v2(219, 10), heading: 0, ty: "full" },
      { pos: new v2(-238, -737.8499908447266), extent: new v2(89, 10), heading: 0, ty: "full" },
      { pos: new v2(-390, -543.8499908447266), extent: new v2(13, 203), heading: 0, ty: "full" },
      { pos: new v2(-389, -169.8499755859375), extent: new v2(12, 177), heading: 0, ty: "full" },
      { pos: new v2(-24, -943.8500022888184), extent: new v2(50, 7), heading: 0, ty: "half" },
      { pos: new v2(106, -944.8500022888184), extent: new v2(58, 8), heading: 0, ty: "half" },
      { pos: new v2(-313, 338.54998779296875), extent: new v2(100, 8), heading: 0.25, ty: "half" },
      { pos: new v2(-142, 347.54998779296875), extent: new v2(64, 9), heading: 0, ty: "half" },
      { pos: new v2(103, 333.54998779296875), extent: new v2(72, 9), heading: 0, ty: "half" },
      { pos: new v2(259, 348.54998779296875), extent: new v2(75, 7), heading: -0.25, ty: "half" },
    ],

    goals: [],
    mission_rules: [{ ty: 'explore' }],
  };
}

export function preset_hub() {
  const team0_tmpl_sr = entityFromStat2(stats2_const(15), tmpl_firearm_sr_high);
  const world = {
    width: 1500,
    height: 2500,
    simover_rule: 'eliminate',
  };

  const team1_tmpl_idle = {
    ...ENTITY_CONFIG_TMPL,
    ...tmpl_firearm_ar_mid,

    team: 1, spawnarea: 2,
    default_rule: 'idle',
  };

  const team1_tmpl_idle_nv = {
    ...ENTITY_CONFIG_TMPL,
    ...tmpl_firearm_ar_mid,
    ...gear_nvd_t2,

    team: 1, spawnarea: 2,
    default_rule: 'idle',
  };

  /*
  const team0_tmpl_ar = {
    ...ENTITY_CONFIG_TMPL,
    ...tmpl_firearm_ar_high,

    team: 0,
    default_rule: 'explore',
  };
  */


  return {
    world,

    entities: [
      entityFromStat2(stats2_const(15), tmpl_firearm_ar_mid),
      entityFromStat2(stats2_const(15), tmpl_firearm_ar_mid),

      { ...team0_tmpl_sr, spawnarea: 1, allow_coordinated_fire: true },
      { ...team0_tmpl_sr, spawnarea: 2, allow_coordinated_fire: true },

      { ...team1_tmpl_idle, spawnarea: 3, vis_group: 1 },
      { ...team1_tmpl_idle, spawnarea: 3, vis_group: 1 },

      { ...team1_tmpl_idle, spawnarea: 4, vis_group: 2 },

      { ...team1_tmpl_idle, spawnarea: 5, vis_group: 4 },
      { ...team1_tmpl_idle, spawnarea: 6, vis_group: 4 },

      { ...team1_tmpl_idle_nv, spawnarea: 7, vis_group: 5, },

      { ...team1_tmpl_idle, spawnarea: 8, vis_group: 6 },
      { ...team1_tmpl_idle, spawnarea: 8, vis_group: 6 },
      { ...team1_tmpl_idle, spawnarea: 30, vis_group: 6 },

      { ...team1_tmpl_idle, spawnarea: 9, vis_group: 7 },
      { ...team1_tmpl_idle, spawnarea: 10, vis_group: 7 },
      { ...team1_tmpl_idle, spawnarea: 10, vis_group: 7 },
      { ...team1_tmpl_idle, spawnarea: 11, vis_group: 7 },

      { ...team1_tmpl_idle, spawnarea: 12, vis_group: 8 },
      { ...team1_tmpl_idle, spawnarea: 13, vis_group: 8 },
      { ...team1_tmpl_idle, spawnarea: 13, vis_group: 8 },

      { ...team1_tmpl_idle, spawnarea: 14, vis_group: 9 },
      { ...team1_tmpl_idle, spawnarea: 14, vis_group: 9 },

      { ...team1_tmpl_idle, spawnarea: 15, vis_group: 10 },

      { ...team1_tmpl_idle, spawnarea: 16, vis_group: 11 },
      { ...team1_tmpl_idle, spawnarea: 16, vis_group: 11 },

      { ...team1_tmpl_idle, spawnarea: 17, vis_group: 12 },
      { ...team1_tmpl_idle, spawnarea: 17, vis_group: 12 },
      { ...team1_tmpl_idle, spawnarea: 18, vis_group: 12 },
      { ...team1_tmpl_idle, spawnarea: 19, vis_group: 12 },
      { ...team1_tmpl_idle, spawnarea: 20, vis_group: 12 },
      { ...team1_tmpl_idle, spawnarea: 23, vis_group: 12 },
      { ...team1_tmpl_idle, spawnarea: 23, vis_group: 12 },
      { ...team1_tmpl_idle, spawnarea: 24, vis_group: 12 },

      { ...team1_tmpl_idle, spawnarea: 21, vis_group: 13 },

      { ...team1_tmpl_idle, spawnarea: 22, vis_group: 14 },

      { ...team1_tmpl_idle, spawnarea: 25, vis_group: 101 },
      { ...team1_tmpl_idle, spawnarea: 25, vis_group: 101 },
      { ...team1_tmpl_idle, spawnarea: 25, vis_group: 101 },
      { ...team1_tmpl_idle, spawnarea: 25, vis_group: 101 },
      { ...team1_tmpl_idle, spawnarea: 25, vis_group: 101 },
      { ...team1_tmpl_idle, spawnarea: 25, vis_group: 101 },
      { ...team1_tmpl_idle, spawnarea: 25, vis_group: 101 },
      { ...team1_tmpl_idle, spawnarea: 25, vis_group: 101 },
      { ...team1_tmpl_idle, spawnarea: 25, vis_group: 101 },
      { ...team1_tmpl_idle, spawnarea: 25, vis_group: 101 },
      { ...team1_tmpl_idle, spawnarea: 25, vis_group: 101 },
      { ...team1_tmpl_idle, spawnarea: 25, vis_group: 101 },
      { ...team1_tmpl_idle, spawnarea: 25, vis_group: 101 },
      { ...team1_tmpl_idle, spawnarea: 25, vis_group: 101 },

      { ...team1_tmpl_idle, spawnarea: 26, vis_group: 15 },
      { ...team1_tmpl_idle, spawnarea: 27, vis_group: 16 },

      { ...team1_tmpl_idle, spawnarea: 28, vis_group: 102 },
      { ...team1_tmpl_idle, spawnarea: 28, vis_group: 102 },
      { ...team1_tmpl_idle, spawnarea: 28, vis_group: 102 },
      { ...team1_tmpl_idle, spawnarea: 28, vis_group: 102 },
      { ...team1_tmpl_idle, spawnarea: 28, vis_group: 102 },
      { ...team1_tmpl_idle, spawnarea: 28, vis_group: 102 },
      { ...team1_tmpl_idle, spawnarea: 28, vis_group: 102 },
      { ...team1_tmpl_idle, spawnarea: 28, vis_group: 102 },
      { ...team1_tmpl_idle, spawnarea: 28, vis_group: 102 },
      { ...team1_tmpl_idle, spawnarea: 28, vis_group: 102 },

      { ...team1_tmpl_idle, spawnarea: 29, vis_group: 17 },
    ],














    spawnareas: [
      { pos: new v2(671, -1195.650001525879), extent: new v2(38, 31), heading: 0, spawn: true },
      { pos: new v2(-736, -1220.650001525879), extent: new v2(13, 16), heading: 0, spawn: true },
      { pos: new v2(-81, 1219.550048828125), extent: new v2(25, 16), heading: 0, spawn: true },
      { pos: new v2(-174, -1127.650001525879), extent: new v2(70, 50), heading: 0 },
      { pos: new v2(-223, -788.6500015258789), extent: new v2(35, 23), heading: 0 },
      { pos: new v2(-168, -520.6500015258789), extent: new v2(31, 14), heading: 0 },
      { pos: new v2(-316, -635.6500015258789), extent: new v2(17, 9), heading: 0 },
      { pos: new v2(-177, -446.6499938964844), extent: new v2(15, 13.999992370605469), heading: 0 },
      { pos: new v2(-442, -359.6499938964844), extent: new v2(14, 8), heading: 0 },
      { pos: new v2(-417, 135.35003662109375), extent: new v2(9, 7), heading: 0 },
      { pos: new v2(-502, 197.35003662109375), extent: new v2(70, 8), heading: 0 },
      { pos: new v2(-314, 197.35003662109375), extent: new v2(31, 7), heading: 0 },
      { pos: new v2(-269, 382.35003662109375), extent: new v2(14, 7), heading: 0 },
      { pos: new v2(-620, 385.35003662109375), extent: new v2(9, 19), heading: 0 },
      { pos: new v2(-489, 553.3500366210938), extent: new v2(9, 35), heading: 0 },
      { pos: new v2(-387, 621.3500366210938), extent: new v2(45, 9), heading: 0 },
      { pos: new v2(-375, 864.3499755859375), extent: new v2(54, 27), heading: 0 },
      { pos: new v2(211, 499.550048828125), extent: new v2(34, 20), heading: 0 },
      { pos: new v2(378, 506.550048828125), extent: new v2(39, 20), heading: 0 },
      { pos: new v2(416, 594.550048828125), extent: new v2(37, 20), heading: 0 },
      { pos: new v2(172, 591.550048828125), extent: new v2(41, 20), heading: 0 },
      { pos: new v2(-62, 668.550048828125), extent: new v2(31, 14), heading: 0 },
      { pos: new v2(653, 784.550048828125), extent: new v2(26, 16), heading: 0 },
      { pos: new v2(258, 698.550048828125), extent: new v2(101, 15), heading: 0 },
      { pos: new v2(438, 708.550048828125), extent: new v2(21, 52), heading: 0 },
      {
        pos: new v2(318, -4.45001220703125), extent: new v2(361, 310), heading: 0,
        structureopts: { count: 2 },
        triggers: [
          {
            condition: 'enter',
            conditiontarget: {
              ty: 'entity',
              team: 0,
            },

            action: 'push_rule',
            actiontarget: {
              ty: 'entity',
              team: 0,
            },
            actionrules: [{ ty: 'explore', area: 25 }],
          }
        ],
      },
      { pos: new v2(146, -491.4499969482422), extent: new v2(19, 30), heading: 0 },
      { pos: new v2(622, -478.4499969482422), extent: new v2(22, 12), heading: 0 },
      {
        pos: new v2(384, -756.4499969482422), extent: new v2(236, 171), heading: 0,
        structureopts: { count: 1 },
        triggers: [
          {
            condition: 'enter',
            conditiontarget: {
              ty: 'entity',
              team: 0,
            },

            action: 'push_rule',
            actiontarget: {
              ty: 'entity',
              team: 0,
            },
            actionrules: [{ ty: 'explore', area: 28 }],
          }
        ],
      },
      { pos: new v2(-173, 1018.550048828125), extent: new v2(15, 9), heading: 0 },
      { pos: new v2(-198, -186.45001220703125), extent: new v2(18, 11), heading: 0 },
    ],
    obstacle_specs: [
      { pos: new v2(1, -698.8500022888184), extent: new v2(16, 321), heading: 0, ty: "full" },
      { pos: new v2(239, -1008.8500022888184), extent: new v2(249, 12), heading: 0, ty: "full" },
      { pos: new v2(532, -1008.8500022888184), extent: new v2(81, 11), heading: 0, ty: "full" },
      { pos: new v2(-108, -1009.8500022888184), extent: new v2(51, 8), heading: -0.25, ty: "half" },
      { pos: new v2(-230, -1011.8500022888184), extent: new v2(32, 6), heading: 0.125, ty: "half" },
      { pos: new v2(-323, -990.8500022888184), extent: new v2(52, 7), heading: 0.25, ty: "half" },
      { pos: new v2(-278, -857.8499984741211), extent: new v2(66, 6), heading: 0, ty: "full" },
      { pos: new v2(-113, -856.8499984741211), extent: new v2(52, 7), heading: 0, ty: "full" },
      { pos: new v2(-158, -716.8499908447266), extent: new v2(94, 9), heading: 0, ty: "full" },
      { pos: new v2(-296, -716.8499908447266), extent: new v2(51, 9), heading: 0, ty: "full" },
      { pos: new v2(-58, -785.8500022888184), extent: new v2(10, 78), heading: 0, ty: "full" },
      { pos: new v2(-333, -786.8500022888184), extent: new v2(8, 45), heading: 0, ty: "half" },
      { pos: new v2(-225, -663.8499908447266), extent: new v2(212, 10), heading: 0, ty: "full" },
      { pos: new v2(-326, -577.8499908447266), extent: new v2(29, 35), heading: 0, ty: "full" },
      { pos: new v2(-206, -563.8499908447266), extent: new v2(72, 22), heading: 0, ty: "full" },
      { pos: new v2(-76, -581.8499908447266), extent: new v2(44, 35), heading: 0, ty: "full" },
      { pos: new v2(-234, -487.84999084472656), extent: new v2(120, 12), heading: 0, ty: "full" },
      { pos: new v2(-390, -616.8499908447266), extent: new v2(10, 26), heading: 0, ty: "half" },
      { pos: new v2(-412, -557.8499908447266), extent: new v2(32, 8), heading: 0, ty: "half" },
      { pos: new v2(-399, -517.8499908447266), extent: new v2(22, 11), heading: 0, ty: "half" },
      { pos: new v2(-476, -661.8499908447266), extent: new v2(41, 8), heading: 0, ty: "full" },
      { pos: new v2(-411, -483.84999084472656), extent: new v2(108, 8), heading: 0, ty: "full" },
      { pos: new v2(-206, -633.8499908447266), extent: new v2(64, 7), heading: 0, ty: "full" },
      { pos: new v2(708, -1008.8500022888184), extent: new v2(121, 11), heading: 0, ty: "full" },
      { pos: new v2(-65, -370.8500061035156), extent: new v2(82, 13), heading: 0, ty: "full" },
      { pos: new v2(-151, -250.85000610351562), extent: new v2(11, 133), heading: 0, ty: "full" },
      { pos: new v2(114, -1168.8500022888184), extent: new v2(8, 46), heading: -0.125, ty: "half" },
      { pos: new v2(135, -1063.8500022888184), extent: new v2(10, 26), heading: 0.25, ty: "half" },
      { pos: new v2(-173, 125.1500244140625), extent: new v2(15, 292), heading: 0, ty: "full" },
      { pos: new v2(-192, -858.8500022888184), extent: new v2(31, 5), heading: 0, ty: "full" },
      { pos: new v2(-249, 1180.550048828125), extent: new v2(568, 10), heading: 0, ty: "half" },
      { pos: new v2(-548, -175.45001220703125), extent: new v2(31, 10), heading: 0, ty: "full" },
      { pos: new v2(-268, -174.85000610351562), extent: new v2(25, 13), heading: 0, ty: "full" },
      { pos: new v2(-176, -208.85000610351562), extent: new v2(23, 5), heading: 0, ty: "full" },
      { pos: new v2(-790, -417.8499984741211), extent: new v2(79, 12), heading: 0, ty: "half" },
      { pos: new v2(-700, -884.8500022888184), extent: new v2(12, 479.0000114440918), heading: 0, ty: "half" },
      { pos: new v2(-678, -174.8499755859375), extent: new v2(101, 6), heading: 0, ty: "full" },
      { pos: new v2(-419, -214.8499755859375), extent: new v2(117, 5), heading: 0.375, ty: "full" },
      { pos: new v2(-266, -192.8499755859375), extent: new v2(27, 4), heading: 0, ty: "half" },
      { pos: new v2(-262, -157.8499755859375), extent: new v2(31, 5), heading: 0, ty: "half" },
      { pos: new v2(-547, -161.8499755859375), extent: new v2(32, 6), heading: 0, ty: "half" },
      { pos: new v2(-547, -188.8499755859375), extent: new v2(30, 5), heading: 0, ty: "half" },
      { pos: new v2(-682, -56.8499755859375), extent: new v2(9, 14), heading: 0, ty: "half" },
      { pos: new v2(-679, 0.1500244140625), extent: new v2(9, 12), heading: 0, ty: "half" },
      { pos: new v2(-507, 230.1500244140625), extent: new v2(79, 19), heading: 0, ty: "full" },
      { pos: new v2(-676, 50.1500244140625), extent: new v2(6, 10), heading: 0, ty: "half" },
      { pos: new v2(-513, 347.1500244140625), extent: new v2(82, 23), heading: 0, ty: "full" },
      { pos: new v2(-648, 286.1500244140625), extent: new v2(6, 22), heading: 0, ty: "full" },
      { pos: new v2(-658, 286.1500244140625), extent: new v2(6, 22), heading: 0, ty: "full" },
      { pos: new v2(-240, 285.1500244140625), extent: new v2(6, 22), heading: 0, ty: "full" },
      { pos: new v2(-799, -174.8499755859375), extent: new v2(27, 9), heading: 0, ty: "full" },
      { pos: new v2(-444, 285.1500244140625), extent: new v2(199, 12), heading: 0, ty: "half" },
      { pos: new v2(574, 1179.550048828125), extent: new v2(258, 9), heading: 0, ty: "half" },
      { pos: new v2(-546, 522.550048828125), extent: new v2(44, 19), heading: 0, ty: "full" },
      { pos: new v2(-434, 523.550048828125), extent: new v2(43, 20), heading: 0, ty: "full" },
      { pos: new v2(-318, 523.550048828125), extent: new v2(49, 21), heading: 0, ty: "full" },
      { pos: new v2(-546, 579.550048828125), extent: new v2(44, 19), heading: 0, ty: "full" },
      { pos: new v2(-435, 579.550048828125), extent: new v2(43, 20), heading: 0, ty: "full" },
      { pos: new v2(-318, 579.550048828125), extent: new v2(49, 21), heading: 0, ty: "full" },
      { pos: new v2(-656, 470.1500244140625), extent: new v2(6, 22), heading: 0, ty: "full" },
      { pos: new v2(-450, 471.1500244140625), extent: new v2(199, 12), heading: 0, ty: "half" },
      { pos: new v2(-246, 471.1500244140625), extent: new v2(6, 22), heading: 0, ty: "full" },
      { pos: new v2(-269, 743.550048828125), extent: new v2(15, 10), heading: 0, ty: "half" },
      { pos: new v2(-341, 743.550048828125), extent: new v2(15, 10), heading: 0, ty: "half" },
      { pos: new v2(-412, 744.550048828125), extent: new v2(15, 10), heading: 0, ty: "half" },
      { pos: new v2(-625, 748.550048828125), extent: new v2(15, 10), heading: 0, ty: "half" },
      { pos: new v2(-701, 747.550048828125), extent: new v2(15, 10), heading: 0, ty: "half" },
      { pos: new v2(-173, 700.1500244140625), extent: new v2(15, 292), heading: 0, ty: "full" },
      { pos: new v2(569, 365.54998779296875), extent: new v2(197, 10), heading: 0, ty: "full" },
      { pos: new v2(34, 367.54998779296875), extent: new v2(197, 10), heading: 0, ty: "full" },
      { pos: new v2(-626, 696.550048828125), extent: new v2(21, 43), heading: 0, ty: "full" },
      { pos: new v2(-701, 695.550048828125), extent: new v2(21, 43), heading: 0, ty: "full" },
      { pos: new v2(-412, 693.550048828125), extent: new v2(21, 43), heading: 0, ty: "full" },
      { pos: new v2(-341, 692.550048828125), extent: new v2(21, 43), heading: 0, ty: "full" },
      { pos: new v2(-269, 692.550048828125), extent: new v2(21, 43), heading: 0, ty: "full" },
      { pos: new v2(-631, 50.1500244140625), extent: new v2(38, 15), heading: 0, ty: "full" },
      { pos: new v2(-632, 0.1500244140625), extent: new v2(39, 17), heading: 0, ty: "full" },
      { pos: new v2(-634, -56.8499755859375), extent: new v2(40, 18), heading: 0, ty: "full" },
      { pos: new v2(-291, -11.8499755859375), extent: new v2(30, 14), heading: 0, ty: "half" },
      { pos: new v2(-372, -13.45001220703125), extent: new v2(32, 15), heading: 0, ty: "half" },
      { pos: new v2(-370, 34.54998779296875), extent: new v2(33, 14), heading: 0, ty: "half" },
      { pos: new v2(-288, 36.1500244140625), extent: new v2(28, 13), heading: 0, ty: "half" },
      { pos: new v2(-294, -59.8499755859375), extent: new v2(31, 14), heading: 0, ty: "half" },
      { pos: new v2(-373, -58.45001220703125), extent: new v2(32, 12), heading: 0, ty: "half" },
      { pos: new v2(-508, 102.1500244140625), extent: new v2(79, 19), heading: 0, ty: "full" },
      { pos: new v2(-328, 101.1500244140625), extent: new v2(79, 19), heading: 0, ty: "full" },
      { pos: new v2(-508, 165.1500244140625), extent: new v2(79, 19), heading: 0, ty: "full" },
      { pos: new v2(-326, 165.1500244140625), extent: new v2(79, 19), heading: 0, ty: "full" },
      { pos: new v2(-327, 228.1500244140625), extent: new v2(79, 19), heading: 0, ty: "full" },
      { pos: new v2(-325, 346.1500244140625), extent: new v2(82, 23), heading: 0, ty: "full" },
      { pos: new v2(-513, 417.1500244140625), extent: new v2(82, 23), heading: 0, ty: "full" },
      { pos: new v2(-326, 417.1500244140625), extent: new v2(82, 23), heading: 0, ty: "full" },
      { pos: new v2(-366, 816.550048828125), extent: new v2(65, 6), heading: 0, ty: "half" },
      { pos: new v2(-257, 816.550048828125), extent: new v2(32, 7), heading: 0, ty: "half" },
      { pos: new v2(-633, 816.550048828125), extent: new v2(32, 7), heading: 0, ty: "half" },
      { pos: new v2(-707, 816.550048828125), extent: new v2(32, 7), heading: 0, ty: "half" },
      { pos: new v2(-672, 886.550048828125), extent: new v2(8, 54), heading: 0, ty: "half" },
      { pos: new v2(-295, 886.550048828125), extent: new v2(8, 54), heading: 0, ty: "half" },
      { pos: new v2(-295, 952.550048828125), extent: new v2(14, 14), heading: 0, ty: "full" },
      { pos: new v2(-671, 950.550048828125), extent: new v2(14, 14), heading: 0, ty: "full" },
      { pos: new v2(-120, 677.550048828125), extent: new v2(11, 261), heading: 0, ty: "half" },
      { pos: new v2(708, 676.550048828125), extent: new v2(11, 261), heading: 0, ty: "half" },
      { pos: new v2(52, 447.550048828125), extent: new v2(49, 21), heading: 0, ty: "full" },
      { pos: new v2(109, 447.550048828125), extent: new v2(8, 14), heading: 0, ty: "half" },
      { pos: new v2(-68, 436.550048828125), extent: new v2(21, 10), heading: 0.125, ty: "half" },
      { pos: new v2(-66, 464.550048828125), extent: new v2(16, 14), heading: 0.25, ty: "half" },
      { pos: new v2(-35, 454.550048828125), extent: new v2(11, 15), heading: -0.125, ty: "half" },
      { pos: new v2(-75, 558.550048828125), extent: new v2(8, 13), heading: -0.125, ty: "half" },
      { pos: new v2(-50, 566.550048828125), extent: new v2(16, 8), heading: -0.25, ty: "half" },
      { pos: new v2(-50, 546.550048828125), extent: new v2(15, 9), heading: -0.125, ty: "half" },
      { pos: new v2(-22, 556.550048828125), extent: new v2(13, 14), heading: 0.125, ty: "half" },
      { pos: new v2(-79, 515.550048828125), extent: new v2(9, 8), heading: 0.125, ty: "half" },
      { pos: new v2(-37, 625.550048828125), extent: new v2(12, 15), heading: -0.25, ty: "half" },
      { pos: new v2(-63, 619.550048828125), extent: new v2(15, 9), heading: -0.125, ty: "half" },
      { pos: new v2(-74, 707.550048828125), extent: new v2(12, 14), heading: 0.125, ty: "half" },
      { pos: new v2(-42, 700.550048828125), extent: new v2(11, 6), heading: 0.125, ty: "half" },
      { pos: new v2(-47, 742.550048828125), extent: new v2(6, 5), heading: 0.125, ty: "half" },
      { pos: new v2(-75, 813.550048828125), extent: new v2(18, 15), heading: 0.125, ty: "half" },
      { pos: new v2(-69, 866.550048828125), extent: new v2(13, 18), heading: -0.25, ty: "half" },
      { pos: new v2(53, 550.550048828125), extent: new v2(49, 21), heading: 0, ty: "full" },
      { pos: new v2(110, 550.550048828125), extent: new v2(8, 14), heading: 0, ty: "half" },
      { pos: new v2(54, 642.550048828125), extent: new v2(49, 21), heading: 0, ty: "full" },
      { pos: new v2(111, 643.550048828125), extent: new v2(8, 14), heading: 0, ty: "half" },
      { pos: new v2(51, 733.550048828125), extent: new v2(49, 21), heading: 0, ty: "full" },
      { pos: new v2(107, 735.550048828125), extent: new v2(8, 14), heading: 0, ty: "half" },
      { pos: new v2(48, 827.550048828125), extent: new v2(49, 21), heading: 0, ty: "full" },
      { pos: new v2(104, 829.550048828125), extent: new v2(8, 14), heading: 0, ty: "half" },
      { pos: new v2(-23, 861.550048828125), extent: new v2(15, 11), heading: 2.125, ty: "half" },
      { pos: new v2(-174, 994.550048828125), extent: new v2(44, 6), heading: 0, ty: "full" },
      { pos: new v2(-174, 1044.550048828125), extent: new v2(44, 6), heading: 0, ty: "full" },
      { pos: new v2(-214, 1020.550048828125), extent: new v2(5, 22), heading: 0, ty: "half" },
      { pos: new v2(-133, 1033.550048828125), extent: new v2(5, 16), heading: 0, ty: "full" },
      { pos: new v2(-239, -186.45001220703125), extent: new v2(8, 25), heading: 0, ty: "full" },
      { pos: new v2(-210, -157.45001220703125), extent: new v2(23, 4), heading: 0, ty: "half" },
      { pos: new v2(-174, 1056.550048828125), extent: new v2(15, 8), heading: 0, ty: "full" },
      { pos: new v2(-116, 1094.550048828125), extent: new v2(4, 60), heading: 1, ty: "full" },
      { pos: new v2(-192, 1054.550048828125), extent: new v2(5, 10), heading: 0, ty: "half" },
      { pos: new v2(-154, 1053.550048828125), extent: new v2(5, 10), heading: 0, ty: "half" },
      { pos: new v2(639, 467.550048828125), extent: new v2(16, 14), heading: 0.375, ty: "half" },
      { pos: new v2(606, 456.550048828125), extent: new v2(11, 15), heading: -0.125, ty: "half" },
      { pos: new v2(634, 436.550048828125), extent: new v2(13, 6), heading: 0.625, ty: "half" },
      { pos: new v2(661, 438.550048828125), extent: new v2(7, 8), heading: -0.5, ty: "half" },
      { pos: new v2(671, 507.550048828125), extent: new v2(14, 13), heading: -0.125, ty: "half" },
      { pos: new v2(655, 560.550048828125), extent: new v2(28, 5), heading: 2.25, ty: "half" },
      { pos: new v2(629, 561.550048828125), extent: new v2(14, 6), heading: -0.375, ty: "half" },
      { pos: new v2(638, 582.550048828125), extent: new v2(17, 9), heading: 0.375, ty: "half" },
      { pos: new v2(659, 639.550048828125), extent: new v2(17, 16), heading: -0.25, ty: "half" },
      { pos: new v2(620, 635.550048828125), extent: new v2(15, 10), heading: -0.25, ty: "half" },
      { pos: new v2(633, 663.550048828125), extent: new v2(11, 6), heading: -0.25, ty: "half" },
      { pos: new v2(664, 711.550048828125), extent: new v2(18, 12), heading: -0.875, ty: "half" },
      { pos: new v2(637, 722.550048828125), extent: new v2(19, 10), heading: -1, ty: "half" },
      { pos: new v2(660, 748.550048828125), extent: new v2(12, 8), heading: 0.5, ty: "half" },
      { pos: new v2(673, 820.550048828125), extent: new v2(9, 12), heading: 0.375, ty: "half" },
      { pos: new v2(667, 857.550048828125), extent: new v2(14, 12), heading: -0.5, ty: "half" },
      { pos: new v2(645, 829.550048828125), extent: new v2(11, 7), heading: -0.375, ty: "half" },
      { pos: new v2(570, 826.550048828125), extent: new v2(49, 21), heading: 0, ty: "full" },
      { pos: new v2(514, 826.550048828125), extent: new v2(8, 14), heading: 0, ty: "half" },
      { pos: new v2(562, 740.550048828125), extent: new v2(49, 21), heading: 0, ty: "full" },
      { pos: new v2(507, 740.550048828125), extent: new v2(8, 14), heading: 0, ty: "half" },
      { pos: new v2(541, 639.550048828125), extent: new v2(49, 21), heading: 0, ty: "full" },
      { pos: new v2(487, 641.550048828125), extent: new v2(8, 14), heading: 0, ty: "half" },
      { pos: new v2(538, 550.550048828125), extent: new v2(49, 21), heading: 0, ty: "full" },
      { pos: new v2(485, 552.550048828125), extent: new v2(8, 14), heading: 0, ty: "half" },
      { pos: new v2(530, 447.550048828125), extent: new v2(49, 21), heading: 0, ty: "full" },
      { pos: new v2(477, 448.550048828125), extent: new v2(8, 14), heading: 0, ty: "half" },
      { pos: new v2(305, 840.550048828125), extent: new v2(11, 11), heading: 0, ty: "full" },
      { pos: new v2(302, 739.550048828125), extent: new v2(11, 11), heading: 0, ty: "full" },
      { pos: new v2(301, 644.550048828125), extent: new v2(11, 11), heading: 0, ty: "full" },
      { pos: new v2(298, 555.550048828125), extent: new v2(11, 11), heading: 0, ty: "full" },
      { pos: new v2(296, 451.550048828125), extent: new v2(11, 11), heading: 0, ty: "full" },
      { pos: new v2(306, 854.550048828125), extent: new v2(11, 5), heading: 0, ty: "half" },
      { pos: new v2(302, 754.550048828125), extent: new v2(11, 5), heading: 0, ty: "half" },
      { pos: new v2(302, 659.550048828125), extent: new v2(11, 5), heading: 0, ty: "half" },
      { pos: new v2(299, 570.550048828125), extent: new v2(11, 5), heading: 0, ty: "half" },
      { pos: new v2(296, 465.550048828125), extent: new v2(11, 5), heading: 0, ty: "half" },
      { pos: new v2(197, -443.6500015258789), extent: new v2(188, 11), heading: 0, ty: "full" },
      { pos: new v2(661, -443.6500015258789), extent: new v2(96, 12), heading: 0, ty: "full" },
      { pos: new v2(474, -445.6500015258789), extent: new v2(50, 7), heading: 0, ty: "full" },
      { pos: new v2(355, -485.6500015258789), extent: new v2(4, 13), heading: 0, ty: "half" },
      { pos: new v2(575, -478.6500015258789), extent: new v2(5, 14), heading: 0, ty: "half" },
      { pos: new v2(289, -492.6500015258789), extent: new v2(19, 12), heading: -0.75, ty: "half" },
      { pos: new v2(227, -510.6500015258789), extent: new v2(31, 8), heading: -0.25, ty: "half" },
      { pos: new v2(618, -514.6500015258789), extent: new v2(14, 7), heading: 0, ty: "half" },
      { pos: new v2(51, -485.6500015258789), extent: new v2(21, 17), heading: 0, ty: "half" },
      { pos: new v2(45, -523.6500015258789), extent: new v2(33, 4), heading: 0, ty: "half" },
      { pos: new v2(98, -470.6500015258789), extent: new v2(4, 30), heading: 0, ty: "half" },
      { pos: new v2(714, -485.6500015258789), extent: new v2(21, 17), heading: 0, ty: "half" },
      { pos: new v2(666, -471.6500015258789), extent: new v2(4, 30), heading: 0, ty: "half" },
      { pos: new v2(719, -521.6500015258789), extent: new v2(33, 4), heading: 0, ty: "half" },
      { pos: new v2(-646, -281.6499938964844), extent: new v2(24, 7), heading: -2.125, ty: "half" },
      { pos: new v2(-674, -246.64999389648438), extent: new v2(15, 10), heading: -0.25, ty: "half" },
      { pos: new v2(-693, -216.64999389648438), extent: new v2(26, 6), heading: -2.75, ty: "half" },
      { pos: new v2(-533, -371.6499938964844), extent: new v2(16, 16), heading: 1.5, ty: "full" },
      { pos: new v2(-483, -374.6499938964844), extent: new v2(13, 7), heading: -2.25, ty: "full" },
      { pos: new v2(-383, -1155.650001525879), extent: new v2(8, 62), heading: 0.5, ty: "half" },
      { pos: new v2(-377, -1042.650001525879), extent: new v2(13, 31), heading: 0.5, ty: "half" },
      { pos: new v2(-522, -1204.650001525879), extent: new v2(14, 13), heading: 0, ty: "half" },
      { pos: new v2(-521, -917.6500015258789), extent: new v2(14, 13), heading: 0, ty: "half" },
      { pos: new v2(-523, -1062.650001525879), extent: new v2(14, 13), heading: 0, ty: "full" },
      { pos: new v2(-520, -763.6500015258789), extent: new v2(14, 13), heading: 0, ty: "full" },
      { pos: new v2(-84, 1166.550048828125), extent: new v2(62, 4), heading: 0, ty: "full" },
      { pos: new v2(-682, -1218.650001525879), extent: new v2(6, 31), heading: 0, ty: "full" },
      { pos: new v2(-473, -529.4499969482422), extent: new v2(24, 8), heading: -0.625, ty: "half" },
      { pos: new v2(-508, -648.4499969482422), extent: new v2(10, 20), heading: 0, ty: "full" },
      { pos: new v2(-545, -485.4499816894531), extent: new v2(34, 7), heading: 0, ty: "full" },
      { pos: new v2(-446, -386.4499816894531), extent: new v2(21, 6), heading: 0.125, ty: "full" },
      { pos: new v2(-319, -386.45001220703125), extent: new v2(36, 8), heading: -1, ty: "half" },
      { pos: new v2(-283, -308.45001220703125), extent: new v2(11, 9), heading: 0, ty: "half" },
      { pos: new v2(-340, -300.45001220703125), extent: new v2(27, 5), heading: 0.25, ty: "half" },
      { pos: new v2(-625, -485.6500015258789), extent: new v2(63, 6), heading: 0, ty: "full" },
      { pos: new v2(-508, -608.6500015258789), extent: new v2(10, 32), heading: 0, ty: "full" },
      { pos: new v2(-353, -636.6500015258789), extent: new v2(7, 8), heading: 0, ty: "full" },
      { pos: new v2(-408, -383.4499816894531), extent: new v2(12, 6), heading: -0.75, ty: "full" },
      { pos: new v2(-401, -348.6500015258789), extent: new v2(6, 21), heading: 0, ty: "full" },
      { pos: new v2(-422, -322.6499938964844), extent: new v2(25, 5), heading: 0, ty: "half" },
    ],

    goals: [],
    mission_rules: [{ ty: 'explore' }],

    editor: true,
  };
}





export function preset_night_ops_test() {
  // preset_mansion 으로부터 시작합니다.
  const preset = preset_mansion();
  preset.world.exp_search = true;

  // team0 entity에 nvd_t2를 장착합니다.
  for (let i = 0; i < preset.entities.length; i++) {
    let entity = preset.entities[i];
    if (entity.team !== 0) {
      continue;
    }
    preset.entities[i] = {
      ...entity,
      ...gear_nvd_t2,
    };
  }

  // 월드 전체를 덮는 effect area를 추가합니다.
  preset.spawnareas.push({
    pos: v2.unit(0),
    extent: new v2(preset.world.width / 2, preset.world.height / 2),
    heading: 0,
    // 시야가 제한된 구역
    effect_ty: 'limvis',
  });
  return preset;
}


export function preset_night_hub() {
  // preset_mansion 으로부터 시작합니다.
  const preset = preset_hub();
  preset.world.exp_search = true;

  // team0 entity에 nvd_t2를 장착합니다.
  for (let i = 0; i < preset.entities.length; i++) {
    let entity = preset.entities[i];
    if (entity.team !== 0) {
      continue;
    }
    preset.entities[i] = {
      ...entity,
      ...gear_nvd_t3,
    };
  }

  // 월드 전체를 덮는 effect area를 추가합니다.
  preset.spawnareas.push({
    pos: v2.unit(0),
    extent: new v2(preset.world.width / 2, preset.world.height / 2),
    heading: 0,
    // 시야가 제한된 구역
    effect_ty: 'limvis',
  });
  return preset;
}


export function preset_mimictest() {
  const team0_tmpl = {
    ...entityFromStat2(stats2_const(15), tmpl_firearm_smg_mid),
    speed: 100,
  };

  return {
    world: { width: 800, height: 1600, simover_rule: 'eliminate' },

    entities: [
      team0_tmpl,
      team0_tmpl,

      {
        ...entityFromStat2(stats2_const(15), tmpl_firearm_smg_mid),
        default_rule: 'idle',
        team: 1,
        spawnarea: 7,
      },
    ],

    spawnareas: [
      { pos: new v2(-375, -773.65625), extent: new v2(17, 16), heading: 0, spawn: true, },
      { pos: new v2(-2, -579.65625), extent: new v2(394, 194), heading: 0 },
      { pos: new v2(280, -410), extent: new v2(40, 40), heading: 0, },
      { pos: new v2(-3, -142.65625), extent: new v2(394, 194), heading: 0 },
      { pos: new v2(-300, 30), extent: new v2(40, 40), heading: 0, },
      { pos: new v2(-3, 287.34375), extent: new v2(394, 194), heading: 0 },
      { pos: new v2(280, 450), extent: new v2(40, 40), heading: 0, },
      { pos: new v2(210, 702.34375), extent: new v2(48, 41), heading: 0, },
    ],

    obstacle_specs: [
      { pos: new v2(-50, -579.65625), extent: new v2(300, 194), heading: 0, ty: 'random', random: { count: 1, }, },
      { pos: new v2(50, -142.65625), extent: new v2(300, 194), heading: 0, ty: 'random', random: { count: 30, }, },
      { pos: new v2(-50, 287.34375), extent: new v2(300, 194), heading: 0, ty: 'random', random: { count: 30, }, },

      { pos: new v2(-101, -353.65625), extent: new v2(414, 11), heading: 0, ty: "full" },
      { pos: new v2(67, 77.34375), extent: new v2(414, 11), heading: 0, ty: "full" },
      { pos: new v2(-106, 501.34375), extent: new v2(414, 11), heading: 0, ty: "full" },
      { pos: new v2(294, 639.34375), extent: new v2(14, 134), heading: 0, ty: "full" },
    ],

    goals: [
      { area: 2, name: 'checkpoint #1', waypoint: true },
      { area: 4, name: 'checkpoint #2', waypoint: true },
      { area: 6, name: 'checkpoint #3', waypoint: true },
    ],

    mission_rules: [
      { ty: 'cover' },
      {
        ty: 'capture-goal', goal: 0, group: true, prompt_duration: 5, prompt_options: [
          {
            title: 'proceed',
            default: true,
            actions: [
              {
                action: 'push_rule',
                actiontarget: { ty: 'entity', team: 0 },
                actionrules: [
                  {
                    ty: 'capture-goal', goal: 1, group: true, prompt_duration: 5, prompt_options: [
                      {
                        title: 'proceed',
                        default: true,
                        actions: [
                          {
                            action: 'push_rule',
                            actiontarget: { ty: 'entity', team: 0 },
                            actionrules: [
                              {
                                ty: 'capture-goal', goal: 2, group: true, prompt_duration: 5, prompt_options: [
                                  {
                                    title: 'proceed',
                                    default: true,
                                    actions: [
                                      {
                                        action: 'push_rule',
                                        actiontarget: { ty: 'entity', team: 0 },
                                        actionrules: [
                                          { ty: 'explore', group: true },
                                        ],
                                      }
                                    ],
                                  }
                                ]
                              },
                              { ty: 'explore', area: 5, group: true },
                            ],
                          }
                        ],
                      }
                    ]
                  },
                  { ty: 'explore', area: 3, group: true },
                ],
              }
            ],
          }
        ],
      },
      { ty: 'explore', area: 1, group: true }
    ],
  };
}

export function preset_prompt00() {

  const team0_tmpl = {
    ...entityFromStat2(stats2_const(15), tmpl_firearm_sg_mid),
    spawnarea: 0,
  };

  const team1_tmpl = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_dmr_mid),
    default_rule: 'cover',
    team: 1,
    spawnarea: 3,
  };

  return {
    world: { width: 800, height: 800, simover_rule: 'eliminate' },

    entities: [
      ...new Array(4).fill(team0_tmpl),
      ...new Array(2).fill(team1_tmpl),
    ],

    spawnareas: [
      {
        pos: new v2(180, 352), extent: new v2(28, 27), heading: 0, triggers: [
          {
            condition: 'enter',
            conditiontarget: {
              ty: 'entity',
              team: 0,
            },

            action: 'push_prompt',
            actionprompts: {
              prompt_duration: 5,
              prompt_options: [
                {
                  title: 'go checkpoint #2',
                  default: true,
                  actions: [
                    {
                      action: 'push_rule',
                      actiontarget: { ty: 'entity', team: 0 },
                      actionrules: [
                        { ty: 'explore', area: 3, group: true },
                        { ty: 'capture-goal', goal: 1, group: true },
                      ],
                    }
                  ],
                },
                {
                  title: 'go checkpoint #1',
                  default: false,
                  actions: [
                    {
                      action: 'push_rule',
                      actiontarget: { ty: 'entity', team: 0 },
                      actionrules: [
                        { ty: 'explore', area: 3, group: true },
                        { ty: 'capture-goal', goal: 0, group: true },
                      ],
                    }
                  ],
                },
              ]
            }
          }
        ]
      },
      { pos: new v2(173, -275), extent: new v2(28, 22), heading: 0, },
      { pos: new v2(-127, 357), extent: new v2(24, 23), heading: 0, },
      { pos: new v2(-86, -171), extent: new v2(174, 74), heading: 0, },
    ],

    obstacle_specs: [
      { pos: new v2(128, 0), extent: new v2(8, 306), heading: 0, ty: "full" },
      { pos: new v2(-8, 307), extent: new v2(144, 9), heading: 0, ty: "full" },
      {
        pos: new v2(-65, -239), extent: new v2(107, 44), heading: 0,
        ty: 'random',
        random: { ty: 'half', count: 20 }
      },
    ],

    goals: [
      { area: 1, name: 'checkpoint #1', waypoint: true },
      { area: 2, name: 'checkpoint #2', waypoint: true },
    ],

    mission_rules: [
      { ty: 'idle' },
    ],
  };
}

export function preset_prompt01() {
  const team0_tmpl = {
    ...entityFromStat2(stats2_const(15), tmpl_firearm_ar_high),
    spawnarea: 0,
    throwables: [throwable_fragment, throwable_stun,],
  };

  const team1_tmpl = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_smg_low),
    default_rule: { ty: 'idle', alert: false },
    team: 1,
    spawnarea: 3,
  };

  const team1_tmpl_reinforce = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_smg_low),
    default_rule: { ty: 'explore', area: 3 },
    team: 1,
    spawnarea: 1,
  };

  return {
    world: { width: 1600, height: 800, simover_rule: 'goal' },

    entities: [
      ...new Array(2).fill(team0_tmpl),
      ...new Array(5).fill(team1_tmpl),
      { ...VIP_TMPL, spawnarea: 3 },
    ],

    spawnareas: [
      { pos: new v2(-758, -254), extent: new v2(29, 25), heading: 0, },
      { pos: new v2(47, 293), extent: new v2(25, 30), heading: 0, },
      { pos: new v2(391, -117), extent: new v2(49, 49), heading: 0, },
      {
        pos: new v2(-483, 100), extent: new v2(200, 200), heading: 0, ty: "half",
        structureopts: {
          count: 1,
          heading: 0,
          enterance: 0,
          obstacle_count: 20,
        },
        triggers: [
          {
            condition: 'fire',
            conditiontarget: { ty: 'entity', team: 0 },
            action: 'push_rule',
            actiondelay: 30 * 5,
            actiontarget: { ty: 'entity', team: 1 },
            actionrules: [{ ty: 'idle' }],
          },
          {
            condition: 'enter',
            conditiontarget: { ty: 'entity', team: 0 },
            action: 'spawn_entity',
            actiondelay: 30 * 90,
            actionentities: new Array(4).fill(team1_tmpl_reinforce),
          },
          {
            condition: 'leave',
            conditiontarget: { ty: 'entity', team: 0 },
            action: 'push_rule',
            actiondelay: 0,
            actiontarget: { ty: 'entity', team: 1 },
            actionrules: [{ ty: 'explore' }],
          },
          {
            condition: 'blast',
            conditiontarget: { ty: 'entity', team: 0 },
            action: 'push_rule',
            actiondelay: 30 * 10,
            actiontarget: { ty: 'entity', team: 1 },
            actionrules: [{ ty: 'explore' }],
          }
        ],
      },
    ],

    obstacle_specs: [
      {
        pos: new v2(-21, -310), extent: new v2(769, 81), heading: 0,
        ty: 'random',
        random: { ty: 'mixed', count: 30 }
      },
      { pos: new v2(151, -206), extent: new v2(169, 14), heading: 0, ty: "full" },
      { pos: new v2(307, 147), extent: new v2(12, 348), heading: 0, ty: "full" },
      {
        pos: new v2(75, 15), extent: new v2(203, 195), heading: 0,
        ty: 'random',
        random: { ty: "mixed", count: 20, extent: new v2(20, 5) }
      },
    ],

    goals: [
      { area: 2, name: 'checkpoint #0', waypoint: false },
    ],

    mission_rules: [
      { ty: 'capture-goal', goal: 0 },
      { ty: 'explore', area: 3 },
    ],

  };
}



export function preset_prompt02() {
  const team0_tmpl = {
    ...entityFromStat2(stats2_const(15), tmpl_firearm_smg_mid),
    spawnarea: 0,
    throwables: [throwable_fragment, throwable_stun,],
  };
  const team0_tmpl_dm = {
    ...entityFromStat2(stats2_const(15), tmpl_firearm_sg_mid),
    spawnarea: 0,
    throwables: [throwable_fragment, throwable_stun,],
  };

  const team1_tmpl = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_smg_low),
    default_rule: { ty: 'idle', alert: false },
    team: 1,
    spawnarea: 3,
  };

  const team1_tmpl_alley_0 = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_sg_mid),
    default_rule: { ty: 'idle', alert: false },
    team: 1,
    spawnarea: 6,
  };

  const team1_tmpl_alley_1 = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_smg_mid),
    default_rule: { ty: 'cover', alert: false },
    team: 1,
    spawnarea: 7,
  };
  const team1_tmpl_alley_2 = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_smg_mid),
    default_rule: { ty: 'idle', alert: false },
    team: 1,
    spawnarea: 5,
    vis_range: 200,
  };

  const team1_tmpl_guard_ar = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_ar_low),
    default_rule: { ty: 'cover', area: 4 },
    team: 1,
    spawnarea: 4,
  }

  const team1_tmpl_guard_smg = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_smg_mid),
    default_rule: { ty: 'cover', area: 4 },
    team: 1,
    spawnarea: 4,
  }

  const team1_tmpl_reinforce = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_smg_mid),
    default_rule: { ty: 'explore', area: 3 },
    team: 1,
    spawnarea: 1,
  };

  return {
    world: { width: 1600, height: 800, simover_rule: 'goal' },

    entities: [
      ...new Array(3).fill(team0_tmpl),
      ...new Array(1).fill(team0_tmpl_dm),
      ...new Array(5).fill(team1_tmpl),
      ...new Array(4).fill(team1_tmpl_guard_ar),
      ...new Array(2).fill(team1_tmpl_guard_smg),
      ...new Array(2).fill(team1_tmpl_alley_0),
      ...new Array(2).fill(team1_tmpl_alley_1),
      ...new Array(2).fill(team1_tmpl_alley_2),
    ],

    spawnareas: [
      {
        pos: new v2(711, 123), extent: new v2(44, 36), heading: 0, triggers: [
          {
            condition: 'enter',
            conditiontarget: {
              ty: 'entity',
              team: 0,
            },

            action: 'push_prompt',
            actionprompts: {
              prompt_duration: 10,
              prompt_options: [
                {
                  title: 'head-on breakthrough',
                  default: true,
                  actions: [
                    {
                      action: 'push_rule',
                      actiontarget: { ty: 'entity', team: 0 },
                      actionrules: [
                        { ty: 'capture-goal', goal: 2, group: true },
                        { ty: 'explore', area: 3, group: true },
                        { ty: 'capture-goal', goal: 0, group: true },
                      ],
                    }
                  ],
                },
                {
                  title: 'stealthy detour',
                  default: false,
                  actions: [
                    {
                      action: 'push_rule',
                      actiontarget: { ty: 'entity', team: 0 },
                      actionrules: [
                        { ty: 'capture-goal', goal: 2, group: true },
                        { ty: 'explore', area: 3, group: true },
                        { ty: 'capture-goal', goal: 1, group: true },
                      ],
                    }
                  ],
                },
              ]
            }
          }
        ]
      },
      { pos: new v2(-713, -280), extent: new v2(64, 59), heading: 0, },
      { pos: new v2(-719, 331), extent: new v2(48, 42), heading: 0, },
      {
        pos: new v2(-70, -229), extent: new v2(280, 139), heading: 0, ty: "half",
        structureopts: {
          count: 1,
          heading: 0,
          enterance: 2,
          obstacle_count: 20,
        },
        triggers: [
          {
            condition: 'fire',
            conditiontarget: { ty: 'entity', team: 0 },
            action: 'push_rule',
            actiondelay: 30 * 5,
            actiontarget: { ty: 'entity', team: 1 },
            actionrules: [{ ty: 'idle' }],
          },
          {
            condition: 'enter',
            conditiontarget: { ty: 'entity', team: 0 },
            action: 'spawn_entity',
            actiondelay: 30 * 150,
            actionentities: new Array(8).fill(team1_tmpl_reinforce),
          },
          {
            condition: 'blast',
            conditiontarget: { ty: 'entity', team: 0 },
            action: 'push_rule',
            actiondelay: 30 * 10,
            actiontarget: { ty: 'entity', team: 1 },
            actionrules: [{ ty: 'explore' }],
          }
        ],
      },
      { pos: new v2(-110, 136), extent: new v2(50, 137), heading: 0, },
      { pos: new v2(603, -98), extent: new v2(20, 28), heading: 0, },
      { pos: new v2(367, -108), extent: new v2(37, 37), heading: 0, },
      { pos: new v2(297, -286), extent: new v2(31, 28), heading: 0, },
    ],

    obstacle_specs: [
      { pos: new v2(90, 315), extent: new v2(733, 21), heading: 0.01, ty: "full" },
      { pos: new v2(347, -30), extent: new v2(330, 18), heading: 0, ty: "full" },
      { pos: new v2(36, 20), extent: new v2(10, 42), heading: 0, ty: "half" },
      { pos: new v2(36, 236), extent: new v2(10, 35), heading: 0, ty: "half" },
      { pos: new v2(35, 139), extent: new v2(10, 33), heading: 0, ty: "half" },
      { pos: new v2(-5, 80), extent: new v2(10, 33), heading: 0, ty: "half" },
      { pos: new v2(-5, 170), extent: new v2(10, 33), heading: 0, ty: "half" },
      { pos: new v2(220, 59), extent: new v2(60, 32), heading: 0, ty: "half" },
      { pos: new v2(270, 236), extent: new v2(60, 32), heading: 0, ty: "half" },
      { pos: new v2(530, 159), extent: new v2(18, 24), heading: 0, ty: "half" },
      { pos: new v2(-411, -31), extent: new v2(266, 17), heading: 0, ty: "full" },
      { pos: new v2(657, -145), extent: new v2(20, 98), heading: 0, ty: "full" },
      { pos: new v2(546, -276), extent: new v2(25, 134), heading: 0, ty: "full" },
      { pos: new v2(531, -82), extent: new v2(40, 23), heading: 0, ty: "half" },
      { pos: new v2(284, -136), extent: new v2(20, 88), heading: 0, ty: "full" },
      { area_pos: new v2(420, -254), area_extent: new v2(81, 74), heading: 0, ty: "mixed", count: 20 },
      { area_pos: new v2(237, -356), area_extent: new v2(31, 26), heading: 0, ty: "mixed", count: 10 },
      { pos: new v2(-646, -193), extent: new v2(157, 16), heading: 0, ty: "full" },
      { pos: new v2(-505, -156), extent: new v2(16, 32), heading: 0, ty: "full" },

    ],

    goals: [
      { area: 4, name: 'checkpoint #0', waypoint: false },
      { area: 5, name: 'checkpoint #1', waypoint: false },
      { area: 2, name: 'checkpoint #2', waypoint: false },
    ],

    mission_rules: [
      { ty: 'idle' },
    ],

  };
}




export function preset_movetest00() {
  const team0_tmpl = {
    ...entityFromStat2(stats2_const(15), tmpl_firearm_smg_mid),
    spawnarea: 0,
    throwables: [throwable_fragment, throwable_stun,],
  };

  const team1_tmpl_0 = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_smg_low),
    default_rule: { ty: 'idle', alert: true },
    team: 1,
    spawnarea: 2,
  };
  const team1_tmpl_1 = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_smg_low),
    default_rule: { ty: 'cover', alert: true },
    team: 1,
    spawnarea: 3,
  };
  const team1_tmpl_2 = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_smg_low),
    default_rule: { ty: 'idle', alert: true },
    team: 1,
    spawnarea: 4,
  };
  const team1_tmpl_3 = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_smg_low),
    default_rule: { ty: 'idle', alert: true },
    team: 1,
    spawnarea: 5,
  };
  const team1_tmpl_4 = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_smg_low),
    default_rule: { ty: 'idle', alert: true },
    team: 1,
    spawnarea: 6,
    aim_samples_fire_thres: 0.5,
  };
  const team1_tmpl_5 = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_smg_low),
    default_rule: { ty: 'cover', alert: true },
    team: 1,
    spawnarea: 7,
  };

  return {
    world: { width: 800, height: 800, simover_rule: 'goal' },

    entities: [
      ...new Array(4).fill(team0_tmpl),
      ...new Array(2).fill(team1_tmpl_0),
      ...new Array(1).fill(team1_tmpl_1),
      ...new Array(2).fill(team1_tmpl_2),
      ...new Array(0).fill(team1_tmpl_3),
      ...new Array(4).fill(team1_tmpl_4),
      ...new Array(2).fill(team1_tmpl_5),
    ],

    spawnareas: [
      { pos: new v2(-339, -329), extent: new v2(35, 33), heading: 0, },
      { pos: new v2(168, -273), extent: new v2(51, 40), heading: 0, },
      { pos: new v2(-382, -220), extent: new v2(17, 19), heading: 0, },
      { pos: new v2(-222, -29), extent: new v2(16, 17), heading: 0, },
      { pos: new v2(-390, 336), extent: new v2(1, 1), heading: 0, },
      { pos: new v2(-290, 255), extent: new v2(40, 59), heading: 0, },
      { pos: new v2(332, 366), extent: new v2(56, 24), heading: 0, },
      { pos: new v2(120, 275), extent: new v2(20, 19), heading: 0, },
    ],

    obstacle_specs: [

      { pos: new v2(-224, -338), extent: new v2(16, 65), heading: 0, ty: "full" },
      { pos: new v2(-245, -261), extent: new v2(37, 14), heading: 0, ty: "full" },
      { pos: new v2(-397, -259), extent: new v2(57, 16), heading: 0, ty: "full" },
      { pos: new v2(-263, -165), extent: new v2(21, 110), heading: 0, ty: "full" },
      { pos: new v2(-222, -72), extent: new v2(46, 17), heading: 0, ty: "full" },
      { pos: new v2(-177, 115), extent: new v2(24, 204), heading: 0, ty: "full" },
      { pos: new v2(-375, 221), extent: new v2(27, 103), heading: 0, ty: "full" },
      { pos: new v2(-49, 301), extent: new v2(114, 19), heading: 0, ty: "full" },
      { pos: new v2(70, 65), extent: new v2(22, 256), heading: 0, ty: "full" },
      { pos: new v2(331, 67), extent: new v2(70, 264), heading: 0, ty: "full" },
      { pos: new v2(-342, -235), extent: new v2(15, 40), heading: 0, ty: "full" },
    ],


    goals: [
      { area: 1, name: 'checkpoint #0', waypoint: false },
    ],

    mission_rules: [
      { ty: 'explore', goal: 0 },
    ],

  };
}

export function preset_movetest01() {
  const team0_tmpl = {
    ...entityFromStat2(stats2_const(15), tmpl_firearm_smg_mid),
    spawnarea: 0,
    throwables: [throwable_fragment, throwable_stun],
  };

  const team1_tmpl_0 = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_smg_low),
    default_rule: { ty: 'idle', alert: false },
    team: 1,
    spawnarea: 1,
  };
  const team1_tmpl_1 = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_sg_low),
    default_rule: { ty: 'idle', alert: false },
    team: 1,
    spawnarea: 1,
  };

  return {
    world: { width: 800, height: 800, simover_rule: 'eliminate' },

    entities: [
      ...new Array(4).fill(team0_tmpl),
      ...new Array(6).fill(team1_tmpl_0),
      ...new Array(5).fill(team1_tmpl_1),
    ],

    spawnareas: [
      { pos: new v2(-325, -323), extent: new v2(44, 45), heading: 0, },
      {
        pos: new v2(-16, -45), extent: new v2(300, 250), heading: 0, ty: "half",
        structureopts: {
          count: 1,
          heading: 0.1,
          enterance: 0,
          obstacle_count: 20,
        },
        triggers: [
          {
            condition: 'fire',
            conditiontarget: { ty: 'entity', team: 0 },
            action: 'push_rule',
            actiondelay: 30 * 10,
            actiontarget: { ty: 'entity', team: 1 },
            actionrules: [{ ty: 'explore' }],
          }
        ],
      },
    ],

    obstacle_specs: [

    ],


    goals: [
    ],

    mission_rules: [
      { ty: 'explore', area: 1 },
    ],

  };
}

export function preset_movetest02() {
  const team0_tmpl = {
    ...entityFromStat2(stats2_const(15), tmpl_firearm_ar_low),
    spawnarea: 0,
    throwables: [throwable_fragment, throwable_stun,],
  };

  const team1_tmpl_0 = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_ar_low),
    default_rule: { ty: 'cover', alert: false },
    team: 1,
    spawnarea: 2,
    vis_range: 1200,
  };
  const team1_tmpl_1 = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_dmr_mid),
    default_rule: { ty: 'cover', alert: false },
    team: 1,
    spawnarea: 3,
    vis_range: 1200,
  };

  return {
    world: { width: 1000, height: 600, simover_rule: 'goal' },

    entities: [
      ...new Array(4).fill(team0_tmpl),
      ...new Array(3).fill(team1_tmpl_0),
      ...new Array(1).fill(team1_tmpl_1),
    ],

    spawnareas: [

      { pos: new v2(-453, 70), extent: new v2(39, 36), heading: 0.02, },
      { pos: new v2(446, 8), extent: new v2(41, 48), heading: 0, },
      { pos: new v2(211, 11), extent: new v2(38, 60), heading: 0, },
      { pos: new v2(304, 8), extent: new v2(36, 52), heading: 0, },
    ],

    obstacle_specs: [


      { pos: new v2(-413, 219), extent: new v2(90, 82), heading: 0, ty: "full" },
      { pos: new v2(-411, -149), extent: new v2(93, 163), heading: 0, ty: "full" },
      { pos: new v2(368, -184), extent: new v2(25, 120), heading: 0, ty: "full" },
      { pos: new v2(371, 190), extent: new v2(25, 120), heading: 0, ty: "full" },
      { pos: new v2(-95, -38), extent: new v2(12, 41), heading: 0, ty: "half" },
      { pos: new v2(-100, 98), extent: new v2(14, 39), heading: 0, ty: "half" },
      { pos: new v2(258, -57), extent: new v2(13, 24), heading: 0, ty: "half" },
      { pos: new v2(141, 78), extent: new v2(10, 27), heading: 0, ty: "half" },
      { pos: new v2(143, -79), extent: new v2(14, 31), heading: 0, ty: "half" },
      { pos: new v2(141, 0), extent: new v2(9, 23), heading: 0, ty: "half" },
      { pos: new v2(-38, -78), extent: new v2(13, 43), heading: 0, ty: "half" },
      { pos: new v2(-42, 131), extent: new v2(12, 42), heading: 0, ty: "half" },
    ],


    goals: [
      { area: 1, name: 'checkpoint #0', waypoint: false },
    ],

    mission_rules: [
      { ty: 'capture-goal', goal: 0 },
    ],

  };
}

export function preset_movetest03() {
  const team0_tmpl = {
    ...entityFromStat2(stats2_const(15), tmpl_firearm_smg_mid),
    spawnarea: 0,
    throwables: [throwable_fragment, throwable_stun,],
  };

  const team1_tmpl_0 = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_ar_low),
    default_rule: { ty: 'cover', alert: false },
    team: 1,
    spawnarea: 2,
  };
  const team1_tmpl_2 = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_ar_mid),
    default_rule: { ty: 'explore', alert: false },
    team: 1,
    spawnarea: 5,
  };

  return {
    world: { width: 1000, height: 600, simover_rule: 'goal' },

    entities: [
      ...new Array(4).fill(team0_tmpl),
      ...new Array(3).fill(team1_tmpl_0),
    ],

    spawnareas: [

      { pos: new v2(-453, 70), extent: new v2(39, 36), heading: 0.02, },
      { pos: new v2(446, 8), extent: new v2(41, 48), heading: 0, },
      { pos: new v2(211, 11), extent: new v2(38, 60), heading: 0, },
      { pos: new v2(304, 8), extent: new v2(36, 52), heading: 0, },
      {
        pos: new v2(211, 3), extent: new v2(103, 137), heading: 0,
        triggers: [
          {
            condition: 'fire',
            conditiontarget: { ty: 'entity', team: 1 },
            action: 'spawn_entity',
            actiondelay: 30 * 24,
            actionentities: new Array(12).fill(team1_tmpl_2),
          }
        ],
      },
      { pos: new v2(-71, -233), extent: new v2(64, 43), heading: 0, },
    ],

    obstacle_specs: [


      { pos: new v2(-413, 219), extent: new v2(90, 82), heading: 0, ty: "full" },
      { pos: new v2(-411, -149), extent: new v2(93, 163), heading: 0, ty: "full" },
      { pos: new v2(368, -184), extent: new v2(25, 120), heading: 0, ty: "full" },
      { pos: new v2(371, 190), extent: new v2(25, 120), heading: 0, ty: "full" },
      { pos: new v2(141, 78), extent: new v2(10, 27), heading: 0, ty: "half" },
      { pos: new v2(143, -79), extent: new v2(14, 31), heading: 0, ty: "half" },
      { pos: new v2(141, 0), extent: new v2(9, 23), heading: 0, ty: "half" },
    ],


    goals: [
      { area: 1, name: 'checkpoint #0', waypoint: false },
    ],

    mission_rules: [
      { ty: 'capture-goal', goal: 0 },
    ],

  };
}

export function preset_movetest04() {
  const team0_tmpl = {
    ...entityFromStat2(stats2_const(15), tmpl_firearm_smg_mid),
    spawnarea: 0,
    throwables: [throwable_fragment, throwable_stun,],
  };

  const team1_tmpl_0 = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_ar_low),
    default_rule: { ty: 'cover', alert: false },
    team: 1,
    spawnarea: 2,
  };
  const team1_tmpl_2 = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_ar_mid),
    default_rule: { ty: 'explore', alert: false },
    team: 1,
    spawnarea: 5,
  };

  return {
    world: { width: 1000, height: 600, simover_rule: 'goal' },

    entities: [
      ...new Array(4).fill(team0_tmpl),
      ...new Array(1).fill(team1_tmpl_0),
    ],

    spawnareas: [

      { pos: new v2(-453, 70), extent: new v2(39, 36), heading: 0.02, },
      { pos: new v2(446, 8), extent: new v2(41, 48), heading: 0, },
      { pos: new v2(211, 11), extent: new v2(38, 60), heading: 0, },
      { pos: new v2(304, 8), extent: new v2(36, 52), heading: 0, },
      {
        pos: new v2(211, 3), extent: new v2(103, 137), heading: 0,
        triggers: [
          {
            condition: 'fire',
            conditiontarget: { ty: 'entity', team: 1 },
            action: 'spawn_entity',
            actiondelay: 30 * 14,
            actionentities: new Array(12).fill(team1_tmpl_2),
          }
        ],
      },
      { pos: new v2(-240, -235), extent: new v2(50, 43), heading: 0, },
    ],

    obstacle_specs: [


      { pos: new v2(-413, 219), extent: new v2(90, 82), heading: 0, ty: "full" },
      { pos: new v2(-411, -149), extent: new v2(93, 163), heading: 0, ty: "full" },
      { pos: new v2(368, -184), extent: new v2(25, 120), heading: 0, ty: "full" },
      { pos: new v2(371, 190), extent: new v2(25, 120), heading: 0, ty: "full" },
      { pos: new v2(141, 0), extent: new v2(9, 23), heading: 0, ty: "half" },
    ],


    goals: [
      { area: 1, name: 'checkpoint #0', waypoint: false },
    ],

    mission_rules: [
      { ty: 'capture-goal', goal: 0 },
    ],

  };
}

export function preset_movetest05() {
  const team0_tmpl = {
    ...entityFromStat2(stats2_const(15), tmpl_firearm_smg_mid),
    spawnarea: 0,
    throwables: [throwable_fragment, throwable_stun,],
  };

  const team1_tmpl_0 = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_smg_low),
    default_rule: { ty: 'idle', alert: false },
    team: 1,
    spawnarea: 2,
  };
  const team1_tmpl_1 = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_smg_low),
    default_rule: { ty: 'explore', area: 2, alert: false },
    team: 1,
    spawnarea: 1,
  };

  return {
    world: { width: 1000, height: 600, simover_rule: 'goal' },

    entities: [
      ...new Array(4).fill(team0_tmpl),
      ...new Array(10).fill(team1_tmpl_0),
    ],

    spawnareas: [

      { pos: new v2(-422, -13), extent: new v2(44, 58), heading: 0, },
      { pos: new v2(427, -13), extent: new v2(54, 67), heading: 0, },
      {
        pos: new v2(92, -9), extent: new v2(208, 200), heading: 0,
        triggers: [
          {
            condition: 'fire',
            conditiontarget: { ty: 'entity', team: 1 },
            action: 'spawn_entity',
            actiondelay: 30 * 14,
            actionentities: new Array(6).fill(team1_tmpl_1),
          }
        ],
      },
    ],

    obstacle_specs: [


      { pos: new v2(-435, -221), extent: new v2(72, 92), heading: 0, ty: "full" },
      { pos: new v2(-441, 215), extent: new v2(72, 92), heading: 0, ty: "full" },
      { area_pos: new v2(15, 2), area_extent: new v2(325, 248), heading: 0, ty: "mixed", count: 50 },
    ],


    goals: [
      { area: 1, name: 'checkpoint #0', waypoint: false },
    ],

    mission_rules: [
      { ty: 'capture-goal', goal: 0 },
    ],

  };
}

export function preset_debug_fire() {
  const team0_sniper = {
    ...entityFromStat2(stats2_const(15), tmpl_firearm_sr_high),
    spawnarea: 0,

    allow_cover_edge: true,
    aimvar_hold_max: Math.PI / 32,
    firearm_range: 2000,
    vis_range: 2000,
  };

  const team1_tmpl = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_ar_low),
    default_rule: { ty: 'idle', alert: false },
    team: 1,
    spawnarea: 1,
  };


  return {
    world: { width: 400, height: 1600, simover_rule: 'eliminate' },

    entities: [
      team0_sniper,
      team1_tmpl,
    ],

    spawnareas: [
      { pos: new v2(53, -746), extent: new v2(32, 27), heading: 0, },
      { pos: new v2(100, 342), extent: new v2(30, 30), heading: 0, },
    ],
    obstacle_specs: [
      { pos: new v2(-32, -656), extent: new v2(31, 10), heading: 0, ty: "full" },
      { pos: new v2(-62, -606), extent: new v2(51, 10), heading: 0, ty: "full" },
    ],

    goals: [],
    mission_rules: [
      { ty: 'cover-fire' },
    ],
  };
}

export function preset_stonehenge(seed = 123) {
  const width = 800;
  const height = 600;

  const team0_sniper = {
    ...entityFromStat2(stats2_const(15), tmpl_firearm_sr_high),
    spawnarea: 0,
  };
  const team0_tanker = {
    ...entityFromStat2(stats2_const(15), tmpl_firearm_ar_low),
    ...gear_vest_bulletproof_highend,
    spawnarea: 1,
  };

  const team1_tmpl = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_ar_low),
    default_rule: { ty: 'cover-fire', alert: false },
    team: 1,
    spawnarea: 2,
  };

  const henge = (count, radius, origin, extent, ty) => {
    const obstacles = [];
    for (let i = 0; i < count; i++) {
      const { x: x_init, y: y_init } = origin;

      const angle = 360.0 / count * i;
      const rad = angle * (Math.PI / 180);
      const x_delta = radius * Math.cos(rad);
      const y_delta = radius * Math.sin(rad);

      const x = x_init + x_delta;
      const y = y_init + y_delta;

      const pos = new v2(x, y);

      obstacles.push(
        { pos, extent, heading: -rad, ty }
      );
    }
    return obstacles;
  }

  const rng = new Rng(seed);
  const origin = new v2(rng.integer(-30, 30), rng.integer(-30, 30));
  const countInner = rng.integer(4, 12);
  const countOuter = countInner * 2;
  const radiusInner = rng.integer(height / 7.0, height / 5.0);
  const radiusOuter = rng.integer(height / 3.0, height / 2.0 - 30);
  const extentInner = new v2(rng.integer(3, 10), rng.integer(3, 10));
  const extentOuter = new v2(rng.integer(5, 20), rng.integer(5, 20));

  const hengeInner = henge(countInner, radiusInner, origin, extentInner, 'full');
  const hengeOuter = henge(countOuter, radiusOuter, origin, extentOuter, 'half');

  // 사거리 짧은 에이전트들만 있을 경우 징검다리가 없으면 교전이 시작되지 않기도 합니다
  const stepstones = [
    { pos: new v2(-width / 2.7, height / 2.7), extent: new v2(5, 5), heading: 0, ty: 'half' },
    { pos: new v2(width / 2.7, -height / 2.7), extent: new v2(5, 5), heading: 0, ty: 'half' },
  ];

  const obstacle_specs = []
    .concat(hengeInner)
    .concat(hengeOuter)
    .concat(stepstones)
    ;

  return {
    world: { width, height, simover_rule: 'eliminate' },

    entities: [
      ...new Array(5).fill(team0_sniper),
      ...new Array(3).fill(team0_tanker),
      ...new Array(15).fill(team1_tmpl),
    ],

    spawnareas: [
      // TODO: spawn, spawnteam 데이터 구조 정리하기, LoopView, RoguelikeView 양측+에서 잘돌게
      { spawn: true, spawnteam: 0, pos: new v2(-width / 2 + 20, height / 2 - 20), extent: new v2(20, 20), heading: 0, },
      { spawn: true, spawnteam: 0, pos: new v2(-width / 3.3, height / 3.3), extent: new v2(20, 20), heading: 0, },
      { spawn: false, spawnteam: 1, pos: new v2(width / 2 - 20, -height / 2 + 20), extent: new v2(20, 20), heading: 0, },
    ],

    obstacle_specs,

    goals: [
    ],

    mission_rules: [
      { ty: 'explore' },
    ],
  };
}

export function preset_watchwood(seed = 123, density = 40) {
  const width = 800;
  const height = 600;

  const team0_sniper_1 = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_dmr_high),
    spawnarea: 1,
  };
  const team0_sniper_2 = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_dmr_low),
    spawnarea: 2,
  };
  const team0_tanker = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_ar_low),
    ...gear_vest_bulletproof_highend,
    spawnarea: 0,
  };

  const team1_tmpl = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_ar_low),
    default_rule: { ty: 'cover-fire', alert: false },
    team: 1,
    spawnarea: 3,
  };

  /// doors: north, east, west, south에 문 뚫기 (=half벽 생략)
  const building = (pos, extent, doors = ['n', 'e', 'w', 's']) => {
    const obstacles = [];

    const { x, y } = pos;
    const { x: width, y: height } = extent;
    const window_wall_ratio = 4;
    const thickness = 3;

    const wall_width = width / window_wall_ratio;
    const wall_height = height / window_wall_ratio;

    if (!doors.includes('n')) {
      obstacles.push({ pos: new v2(x, y - height), extent: new v2(width + thickness, thickness), heading: 0, ty: 'half' });
    }
    obstacles.push({ pos: new v2(x - width + wall_width, y - height + thickness), extent: new v2(wall_width, thickness), heading: 0, ty: 'full' });
    obstacles.push({ pos: new v2(x + width - wall_width, y - height + thickness), extent: new v2(wall_width, thickness), heading: 0, ty: 'full' });

    if (!doors.includes('s')) {
      obstacles.push({ pos: new v2(x, y + height), extent: new v2(width + thickness, thickness), heading: 0, ty: 'half' });
    }
    obstacles.push({ pos: new v2(x - width + wall_width, y + height - thickness), extent: new v2(wall_width, thickness), heading: 0, ty: 'full' });
    obstacles.push({ pos: new v2(x + width - wall_width, y + height - thickness), extent: new v2(wall_width, thickness), heading: 0, ty: 'full' });

    if (!doors.includes('w')) {
      obstacles.push({ pos: new v2(x - width, y), extent: new v2(thickness, height + thickness), heading: 0, ty: 'half' });
    }
    obstacles.push({ pos: new v2(x + width - thickness, y - height + wall_height), extent: new v2(thickness, wall_height), heading: 0, ty: 'full' });
    obstacles.push({ pos: new v2(x + width - thickness, y + height - wall_height), extent: new v2(thickness, wall_height), heading: 0, ty: 'full' });

    if (!doors.includes('e')) {
      obstacles.push({ pos: new v2(x + width, y), extent: new v2(thickness, height + thickness), heading: 0, ty: 'half' });
    }
    obstacles.push({ pos: new v2(x - width + thickness, y - height + wall_height), extent: new v2(thickness, wall_height), heading: 0, ty: 'full' });
    obstacles.push({ pos: new v2(x - width + thickness, y + height - wall_height), extent: new v2(thickness, wall_height), heading: 0, ty: 'full' });

    return obstacles;
  }

  let obstacle_specs = [];
  let spawn_pos_sniper_1 = {};
  let spawn_pos_sniper_2 = {};
  let spawn_extent_sniper_1 = {};
  let spawn_extent_sniper_2 = {};
  {
    const rng = new Rng(seed);
    const w = rng.integer(20, 30);
    const h = rng.integer(20, 30);
    const rand = 10;

    const extent_1 = new v2(w + rng.integer(0, rand), h + rng.integer(0, rand));
    const extent_2 = new v2(w + rng.integer(0, rand), h + rng.integer(0, rand));
    const extent_3 = new v2(w + rng.integer(0, rand), h + rng.integer(0, rand));
    const extent_4 = new v2(w + rng.integer(0, rand), h + rng.integer(0, rand));

    const pos_factor = 2.6;
    const pos_1 = new v2(-width / pos_factor, -height / pos_factor);
    const pos_2 = new v2(+width / pos_factor, -height / pos_factor);
    const pos_3 = new v2(-width / pos_factor, +height / pos_factor);
    const pos_4 = new v2(+width / pos_factor, +height / pos_factor);

    const building_1 = building(pos_1, extent_1, ['w']);
    const building_2 = building(pos_2, extent_2, ['e']);
    const building_3 = building(pos_3, extent_3, ['w']);
    const building_4 = building(pos_4, extent_4, ['e']);
    obstacle_specs = []
      .concat(building_1)
      .concat(building_2)
      .concat(building_3)
      .concat(building_4)
      ;

    spawn_pos_sniper_1 = pos_1;
    spawn_pos_sniper_2 = pos_3;
    spawn_extent_sniper_1 = extent_1;
    spawn_extent_sniper_2 = extent_3;
  }


  const forest_density = density;
  const ty = 'half';
  obstacle_specs.push({
    ty,
    area_pos: new v2(0, 0),
    area_extent: new v2(width / 5, height / 5),
    extent: new v2(6, 6),
    count: forest_density,
  });
  obstacle_specs.push({
    ty,
    area_pos: new v2(0, 0),
    area_extent: new v2(width / 4, height / 4),
    extent: new v2(5, 5),
    count: forest_density,
  });

  obstacle_specs.push({
    ty,
    area_pos: new v2(0, 0),
    area_extent: new v2(width / 3, height / 3),
    extent: new v2(4, 4),
    count: 10,
  });
  obstacle_specs.push({
    ty,
    area_pos: new v2(0, 0),
    area_extent: new v2(width / 2, height / 2),
    extent: new v2(3, 3),
    count: 10,
  });

  return {
    world: { width, height, simover_rule: 'eliminate' },

    entities: [
      ...new Array(1).fill(team0_sniper_1),
      ...new Array(1).fill(team0_sniper_2),
      ...new Array(1).fill(team0_tanker),
      ...new Array(7).fill(team1_tmpl),
    ],

    spawnareas: [
      // TODO: spawn, spawnteam 데이터 구조 정리하기, LoopView, RoguelikeView 양측+에서 잘돌게
      { spawn: true, spawnteam: 0, pos: new v2(-120, 0), extent: new v2(30, 30), heading: 0, },
      { spawn: true, spawnteam: 0, pos: spawn_pos_sniper_1, extent: spawn_extent_sniper_1, heading: 0, },
      { spawn: true, spawnteam: 0, pos: spawn_pos_sniper_2, extent: spawn_extent_sniper_2, heading: 0, },
      { spawn: false, spawnteam: 1, pos: new v2(355, 0), extent: new v2(30, 30), heading: 0, },
    ],

    obstacle_specs,

    goals: [
    ],

    mission_rules: [
      { ty: 'explore' },
    ],
  };
}

export function preset_exhibition_hall() {
  const world = { width: 800, height: 900, simover_rule: 'eliminate', };

  const team0_sr = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_sr_mid),
    ...gear_vest_bulletproof_highend,
    spawnarea: 0,
  };

  // 적 에이전트 스펙 정의
  const team1_tmpl = {
    ...enemy_tmpl_agent_ar_low,
    team: 1,
    spawnarea: 1,

    life: 100,
    speed: 20,
    armor: 33,
    armor_hit_prob: 0.2,
    default_rule: 'idle', allow_crawl: false,
    vis_range: 300,

    aim_rot_rules: [
      { aimvar: Math.PI * 2, aimspeed: 0.075 },
    ],
  };

  const team1_tmpl_dmr = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_dmr_mid),
    default_rule: { ty: 'idle', alert: false },
    team: 1,
    spawnarea: 2,
  };

  return {
    world,

    entities: [
      ...new Array(4).fill(team0_sr),

      { ...team1_tmpl, spawnarea: 1 },

      { ...team1_tmpl_dmr, spawnarea: 2 },
      { ...team1_tmpl_dmr, spawnarea: 2 },

      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },

      { ...team1_tmpl, spawnarea: 4 },
      { ...team1_tmpl, spawnarea: 4 },

      { ...team1_tmpl, spawnarea: 5 },
      { ...team1_tmpl, spawnarea: 5 },

      { ...team1_tmpl, spawnarea: 6 },

      { ...team1_tmpl, spawnarea: 7 },
      { ...team1_tmpl, spawnarea: 7 },

      { ...team1_tmpl, spawnarea: 8 },
    ],


    spawnareas: [
      { pos: new v2(328, 20), extent: new v2(52, 25), heading: 0, spawn: true, },
      { pos: new v2(-33, -339), extent: new v2(29, 19), heading: 0, spawn1: true, },
      { pos: new v2(-136, 376), extent: new v2(29, 19), heading: 0, spawn1: true, },
      { pos: new v2(-310, 114), extent: new v2(29, 19), heading: 0, spawn1: true, },
      { pos: new v2(-299, 191), extent: new v2(29, 19), heading: 0, spawn1: true, },
      { pos: new v2(59, -19), extent: new v2(29, 19), heading: 0, spawn1: true, },
      { pos: new v2(154, 91), extent: new v2(25, 14), heading: 0, spawn1: true, },
      { pos: new v2(84, -228), extent: new v2(25, 14), heading: 0, spawn1: true, },
      { pos: new v2(6, -260), extent: new v2(15, 10), heading: 0, spawn1: true, },
      { pos: new v2(-356, -115), extent: new v2(31, 16), heading: 0, },
    ],
    obstacle_specs: [
      { pos: new v2(368, 93), extent: new v2(77, 40), heading: 0, ty: "full" },
      { pos: new v2(329, 218), extent: new v2(128, 105), heading: 0, ty: "full" },
      { pos: new v2(280, 59), extent: new v2(80, 6), heading: 0.01, ty: "full" },
      { pos: new v2(78, -305), extent: new v2(140, 8), heading: 0, ty: "full" },
      { pos: new v2(-225, -314), extent: new v2(62, 21), heading: 0, ty: "full" },
      { pos: new v2(-387, -315), extent: new v2(24, 17), heading: 0, ty: "full" },
      { pos: new v2(-388, -265), extent: new v2(24, 17), heading: 0, ty: "full" },
      { pos: new v2(-304, -297), extent: new v2(20, 4), heading: 0, ty: "half" },
      { pos: new v2(-143, -297), extent: new v2(20, 4), heading: 0, ty: "half" },
      { pos: new v2(-54, -158), extent: new v2(8, 153), heading: 0, ty: "full" },
      { pos: new v2(209, -125), extent: new v2(10, 188), heading: 0, ty: "full" },
      { pos: new v2(-76, -296), extent: new v2(22, 5), heading: 0, ty: "half" },
      { pos: new v2(-349, -254), extent: new v2(25, 5), heading: 0, ty: "half" },
      { pos: new v2(-115, -255), extent: new v2(28, 6), heading: 0, ty: "half" },
      { pos: new v2(-240, -199), extent: new v2(52, 7), heading: 0, ty: "full" },
      { pos: new v2(-289, -184), extent: new v2(13, 18), heading: -0.48, ty: "full" },
      { pos: new v2(-243, -184), extent: new v2(38, 13), heading: 0, ty: "full" },
      { pos: new v2(-302, -141), extent: new v2(6, 33), heading: 0, ty: "half" },
      { pos: new v2(-188, -129), extent: new v2(5, 22), heading: 0, ty: "full" },
      { pos: new v2(-277, -112), extent: new v2(19, 5), heading: 0, ty: "half" },
      { pos: new v2(-209, -112), extent: new v2(19, 5), heading: 0, ty: "half" },
      { pos: new v2(-332, -72), extent: new v2(21, 11), heading: 2.08, ty: "half" },
      { pos: new v2(-364, -31), extent: new v2(11, 12), heading: -0.24, ty: "half" },
      { pos: new v2(-109, -11), extent: new v2(5, 34), heading: 0, ty: "half" },
      { pos: new v2(-151, 27), extent: new v2(46, 5), heading: 0, ty: "half" },
      { pos: new v2(-160, -19), extent: new v2(4, 32), heading: -0.72, ty: "half" },
      { pos: new v2(-54, 144), extent: new v2(8, 153), heading: 0, ty: "full" },
      { pos: new v2(74, 318), extent: new v2(10, 11), heading: 0, ty: "full" },
      { pos: new v2(-54, 392), extent: new v2(9, 24), heading: 0, ty: "full" },
      { pos: new v2(-44, 368), extent: new v2(7, 10), heading: -0.83, ty: "full" },
      { pos: new v2(-24, 352), extent: new v2(4, 20), heading: -0.83, ty: "half" },
      { pos: new v2(101, 182), extent: new v2(115, 7), heading: 0, ty: "full" },
      { pos: new v2(77, -198), extent: new v2(48, 5), heading: 0, ty: "half" },
      { pos: new v2(143, -150), extent: new v2(9, 8), heading: 0, ty: "full" },
      { pos: new v2(144, -199), extent: new v2(9, 8), heading: 0, ty: "full" },
      { pos: new v2(8, -198), extent: new v2(9, 8), heading: 0, ty: "full" },
      { pos: new v2(9, -150), extent: new v2(9, 8), heading: 0, ty: "full" },
      { pos: new v2(75, -287), extent: new v2(23, 14), heading: 0, ty: "full" },
      { pos: new v2(189, -252), extent: new v2(10, 19), heading: 0, ty: "full" },
      { pos: new v2(-35, -247), extent: new v2(15, 20), heading: 0, ty: "full" },
      { pos: new v2(239, -358), extent: new v2(6, 20), heading: 0.39, ty: "half" },
      { pos: new v2(34, -354), extent: new v2(22, 3), heading: -0.24, ty: "half" },
      { pos: new v2(-228, -338), extent: new v2(30, 4), heading: 0, ty: "half" },
      { pos: new v2(-241, 270), extent: new v2(22, 4), heading: 0, ty: "half" },
      { pos: new v2(-324, 54), extent: new v2(22, 24), heading: -0.36, ty: "half" },
      { pos: new v2(-136, 170), extent: new v2(26, 10), heading: -0.36, ty: "half" },
      { pos: new v2(-362, 97), extent: new v2(9, 7), heading: -0.12, ty: "half" },
      { pos: new v2(-393, 28), extent: new v2(28, 16), heading: 0, ty: "full" },
      { pos: new v2(-399, 73), extent: new v2(10, 32), heading: 0, ty: "full" },
      { pos: new v2(-398, -197), extent: new v2(11, 58), heading: 0, ty: "full" },
      { pos: new v2(-398, -34), extent: new v2(11, 58), heading: 0, ty: "full" },
      { pos: new v2(-391, 219), extent: new v2(25, 30), heading: 0, ty: "full" },
      { pos: new v2(-400, 182), extent: new v2(10, 32), heading: 0, ty: "full" },
      { pos: new v2(50, -52), extent: new v2(101, 8), heading: 0, ty: "full" },
      { pos: new v2(55, 17), extent: new v2(27, 9), heading: -0.24, ty: "half" },
      { pos: new v2(133, 40), extent: new v2(22, 4), heading: 0, ty: "half" },
      { pos: new v2(212, 86), extent: new v2(8, 8), heading: 0, ty: "half" },
      { pos: new v2(261, 90), extent: new v2(17, 5), heading: -0.48, ty: "half" },
      { pos: new v2(32, 118), extent: new v2(18, 4), heading: -0.84, ty: "half" },
      { pos: new v2(75, 59), extent: new v2(11, 11), heading: 0, ty: "full" },
      { pos: new v2(7, 57), extent: new v2(9, 6), heading: 2.22, ty: "half" },
      { pos: new v2(77, -149), extent: new v2(11, 34), heading: 0, ty: "half" },
      { pos: new v2(49, -168), extent: new v2(6, 12), heading: 0, ty: "half" },
      { pos: new v2(48, -129), extent: new v2(6, 12), heading: 0, ty: "half" },
      { pos: new v2(103, -128), extent: new v2(6, 12), heading: 0, ty: "half" },
      { pos: new v2(103, -169), extent: new v2(6, 12), heading: 0, ty: "half" },
      { pos: new v2(14, -92), extent: new v2(23, 4), heading: 0.14, ty: "half" },
      { pos: new v2(148, -73), extent: new v2(4, 18), heading: 0, ty: "half" },
      { pos: new v2(187, -128), extent: new v2(10, 5), heading: -1.41, ty: "half" },
      { pos: new v2(184, -158), extent: new v2(7, 13), heading: -0.34, ty: "half" },
      { pos: new v2(108, -86), extent: new v2(12, 4), heading: -0.24, ty: "half" },
      { pos: new v2(34, -244), extent: new v2(12, 6), heading: -0.24, ty: "half" },
      { pos: new v2(132, -247), extent: new v2(5, 16), heading: 0.78, ty: "half" },
      { pos: new v2(2, -283), extent: new v2(23, 6), heading: 0, ty: "half" },
      { pos: new v2(148, -283), extent: new v2(23, 6), heading: 0, ty: "half" },
      { pos: new v2(61, 236), extent: new v2(6, 16), heading: 0.26, ty: "half" },
      { pos: new v2(119, 282), extent: new v2(26, 5), heading: -0.48, ty: "half" },
      { pos: new v2(66, 276), extent: new v2(7, 7), heading: 0.26, ty: "half" },
      { pos: new v2(-36, 299), extent: new v2(26, 7), heading: 0, ty: "full" },
      { pos: new v2(3, 303), extent: new v2(13, 5), heading: -0.24, ty: "half" },
      { pos: new v2(42, 313), extent: new v2(10, 4), heading: -1.08, ty: "half" },
      { pos: new v2(-16, 109), extent: new v2(15, 2), heading: -0.36, ty: "half" },
      { pos: new v2(49, 165), extent: new v2(16, 4), heading: -0.12, ty: "half" },
      { pos: new v2(92, 164), extent: new v2(14, 4), heading: 0.13, ty: "half" },
      { pos: new v2(139, -18), extent: new v2(11, 11), heading: 0, ty: "full" },
      { pos: new v2(3, -16), extent: new v2(11, 11), heading: 0, ty: "full" },
      { pos: new v2(139, 138), extent: new v2(11, 11), heading: 0, ty: "full" },
      { pos: new v2(6, 139), extent: new v2(11, 11), heading: 0, ty: "full" },
      { pos: new v2(-329, -357), extent: new v2(32, 6), heading: -0.36, ty: "half" },
      { pos: new v2(-268, -369), extent: new v2(21, 4), heading: 0.13, ty: "half" },
      { pos: new v2(-88, -359), extent: new v2(16, 7), heading: 0.66, ty: "half" },
      { pos: new v2(344, -320), extent: new v2(8, 7), heading: -0.24, ty: "half" },
      { pos: new v2(293, -323), extent: new v2(22, 7), heading: 1.17, ty: "half" },
      { pos: new v2(329, -365), extent: new v2(8, 9), heading: 0.13, ty: "half" },
      { pos: new v2(238, -308), extent: new v2(27, 5), heading: 0, ty: "full" },
      { pos: new v2(283, 417), extent: new v2(219, 95), heading: 0, ty: "full" },
      { pos: new v2(-369, 368), extent: new v2(108, 121), heading: 0, ty: "full" },
      { pos: new v2(-265, 441), extent: new v2(46, 139), heading: 0, ty: "full" },
      { pos: new v2(-225, 160), extent: new v2(7, 106), heading: 0, ty: "full" },
      { pos: new v2(-250, 61), extent: new v2(32, 7), heading: 0, ty: "full" },
      { pos: new v2(-163, -172), extent: new v2(4, 27), heading: 0, ty: "full" },
      { pos: new v2(136, -359), extent: new v2(31, 4), heading: 0.55, ty: "half" },
      { pos: new v2(-151, 337), extent: new v2(82, 6), heading: 0, ty: "half" },
    ],
    goals: [],
    mission_rules: [
      { ty: 'explore' },
    ],

    editor: true
  };
}

export function preset_shooting_house1() {
  const world = { width: 500, height: 500, simover_rule: 'eliminate' };

  const team0_smg = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_ar_low),
    ...gear_vest_bulletproof_highend,
    spawnarea: 0,
  };

  // 적 에이전트 스펙 정의
  const team1_tmpl = {
    ...enemy_tmpl_agent_ar_low,
    team: 1,
    spawnarea: 1,

    life: 99,
    speed: 20,
    armor: 1,
    armor_hit_prob: 0.2,
    default_rule: 'idle', allow_crawl: false,
    vis_range: 300,

    aim_rot_rules: [
      { aimvar: Math.PI * 2, aimspeed: 0.075 },
    ],
  };

  return {
    world,

    entities: [
      ...new Array(1).fill(team0_smg),
      ...new Array(1).fill(team0_smg),
      ...new Array(1).fill(team0_smg),

      { ...team1_tmpl, spawnarea: 1 },
      { ...team1_tmpl, spawnarea: 1 },

      { ...team1_tmpl, spawnarea: 2 },

      { ...team1_tmpl, spawnarea: 3 },
      { ...team1_tmpl, spawnarea: 3 },

      { ...team1_tmpl, spawnarea: 4 },
      { ...team1_tmpl, spawnarea: 4 },

      { ...team1_tmpl, spawnarea: 5 },

      { ...team1_tmpl, spawnarea: 6 },

      { ...team1_tmpl, spawnarea: 7 },
    ],


    spawnareas: [
      { pos: new v2(12, 226), extent: new v2(36, 16), heading: 0, spawn: true },
      { pos: new v2(-15, 130), extent: new v2(16, 13), heading: 0, spawn1: true, },
      { pos: new v2(-199, 58), extent: new v2(11, 9), heading: 0, spawn1: true, },
      { pos: new v2(-116, -7), extent: new v2(13, 10), heading: 0, spawn1: true, },
      { pos: new v2(-178, -62), extent: new v2(11, 10), heading: 0, spawn1: true, },
      { pos: new v2(59, -35), extent: new v2(11, 9), heading: 0, spawn1: true, },
      { pos: new v2(130, -3), extent: new v2(9, 9), heading: 0, spawn1: true, },
      { pos: new v2(181, 23), extent: new v2(11, 11), heading: 0, spawn1: true, },
      { pos: new v2(157, -118), extent: new v2(11, 7), heading: 0, },
      { pos: new v2(-112, -211), extent: new v2(11, 14), heading: 0, },
      {
        pos: new v2(209, 2), extent: new v2(39, 46), heading: 0,
        triggers: [
          {
            condition: 'enter',
            conditiontarget: { ty: 'entity', team: 0 },
            action: 'push_rule',
            actiontarget: { ty: 'entity', team: 0 },
            actionrules: [{ ty: 'capture' }],
          }
        ],
      },
    ],
    obstacle_specs: [
      { pos: new v2(-124, 174), extent: new v2(282, 23), heading: 0, ty: "full" },
      { pos: new v2(241, 173), extent: new v2(46, 23), heading: 0, ty: "full" },
      { pos: new v2(15, 118), extent: new v2(7, 8), heading: 0.13, ty: "half" },
      { pos: new v2(87, 139), extent: new v2(7, 6), heading: -0.12, ty: "half" },
      { pos: new v2(-51, 129), extent: new v2(6, 4), heading: -1.56, ty: "half" },
      { pos: new v2(62, 92), extent: new v2(171, 6), heading: 0, ty: "full" },
      { pos: new v2(-184, 93), extent: new v2(35, 5), heading: 0, ty: "full" },
      { pos: new v2(-155, 56), extent: new v2(6, 33), heading: 0, ty: "full" },
      { pos: new v2(-218, 29), extent: new v2(34, 5), heading: 0, ty: "full" },
      { pos: new v2(-231, 56), extent: new v2(6, 9), heading: 0.39, ty: "half" },
      { pos: new v2(-195, 76), extent: new v2(6, 4), heading: -0.24, ty: "half" },
      { pos: new v2(-197, 37), extent: new v2(12, 3), heading: 0, ty: "half" },
      { pos: new v2(-249, 93), extent: new v2(10, 5), heading: 0, ty: "full" },
      { pos: new v2(156, 51), extent: new v2(8, 40), heading: 0, ty: "full" },
      { pos: new v2(228, 38), extent: new v2(14, 3), heading: -0.12, ty: "half" },
      { pos: new v2(214, 65), extent: new v2(6, 5), heading: -0.48, ty: "half" },
      { pos: new v2(180, -81), extent: new v2(75, 6), heading: 0, ty: "full" },
      { pos: new v2(-39, -119), extent: new v2(92, 45), heading: 0, ty: "full" },
      { pos: new v2(112, -159), extent: new v2(84, 5), heading: 0, ty: "full" },
      { pos: new v2(200, -13), extent: new v2(6, 16), heading: 0.13, ty: "half" },
      { pos: new v2(229, -61), extent: new v2(10, 6), heading: -0.12, ty: "half" },
      { pos: new v2(230, -37), extent: new v2(6, 7), heading: -0.96, ty: "half" },
      { pos: new v2(48, 53), extent: new v2(103, 42), heading: 0, ty: "full" },
      { pos: new v2(-22, -63), extent: new v2(75, 14), heading: 0, ty: "full" },
      { pos: new v2(-196, -158), extent: new v2(67, 6), heading: 0, ty: "full" },
      { pos: new v2(-268, -52), extent: new v2(29, 4), heading: 0, ty: "full" },
      { pos: new v2(-171, -122), extent: new v2(20, 4), heading: -0.24, ty: "half" },
      { pos: new v2(-230, -123), extent: new v2(12, 8), heading: 0.26, ty: "half" },
      { pos: new v2(-206, -84), extent: new v2(4, 12), heading: -0.72, ty: "half" },
      { pos: new v2(-194, -20), extent: new v2(8, 13), heading: 0.26, ty: "half" },
      { pos: new v2(-153, -2), extent: new v2(11, 7), heading: -0.36, ty: "half" },
      { pos: new v2(-227, -3), extent: new v2(11, 6), heading: -0.6, ty: "half" },
      { pos: new v2(6, -28), extent: new v2(4, 12), heading: 0.26, ty: "half" },
      { pos: new v2(30, -10), extent: new v2(6, 13), heading: -0.24, ty: "half" },
      { pos: new v2(-50, -31), extent: new v2(4, 21), heading: 0, ty: "full" },
      { pos: new v2(-94, 26), extent: new v2(14, 14), heading: 0, ty: "full" },
      { pos: new v2(-113, -32), extent: new v2(16, 5), heading: -0.24, ty: "half" },
      { pos: new v2(-83, -18), extent: new v2(11, 5), heading: -0.94, ty: "half" },
      { pos: new v2(65, -54), extent: new v2(17, 5), heading: 0, ty: "full" },
      { pos: new v2(213, -96), extent: new v2(84, 11), heading: 0, ty: "full" },
      { pos: new v2(240, -130), extent: new v2(20, 34), heading: 0, ty: "full" },
      { pos: new v2(79, -99), extent: new v2(15, 6), heading: 0.13, ty: "half" },
      { pos: new v2(102, -134), extent: new v2(19, 2), heading: 2.34, ty: "half" },
      { pos: new v2(109, -41), extent: new v2(8, 3), heading: 0.39, ty: "half" },
      { pos: new v2(85, -19), extent: new v2(5, 4), heading: 0.78, ty: "half" },
      { pos: new v2(79, -74), extent: new v2(4, 6), heading: -0.24, ty: "half" },
      { pos: new v2(146, 118), extent: new v2(6, 7), heading: 0.26, ty: "half" },
      { pos: new v2(140, 138), extent: new v2(6, 7), heading: -0.59, ty: "half" },
      { pos: new v2(-127, 93), extent: new v2(29, 5), heading: 0, ty: "full" },
      { pos: new v2(242, 92), extent: new v2(12, 6), heading: 0, ty: "full" },
      { pos: new v2(134, -122), extent: new v2(5, 17), heading: 0, ty: "full" },
      { pos: new v2(-127, -53), extent: new v2(37, 4), heading: 0, ty: "full" },
      { pos: new v2(158, -8), extent: new v2(6, 25), heading: 0, ty: "full" },
    ],

    goals: [
      { name: 'escape area', area: 9 },
    ],

    mission_rules: [
      { ty: 'explore' },
    ],

    editor: true,
  };
}

export function preset_shooting_house2() {
  const world = { width: 600, height: 500, simover_rule: 'eliminate' };

  const team0_ar = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_ar_low),
    ...gear_vest_bulletproof_highend,
    spawnarea: 0,
  };

  const team0_dmr = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_dmr_low),
    ...gear_vest_bulletproof_highend,
    spawnarea: 0,
  };

  // 적 에이전트 스펙 정의
  const team1_tmpl_ar = {
    ...entityFromStat2(stats2_const(3), tmpl_firearm_ar_low),
    default_rule: { ty: 'idle', alert: false },
    team: 1,
    spawnarea: 2,
  };

  const team1_tmpl_ar_ace1 = {
    ...entityFromStat2(stats2_const(4), tmpl_firearm_ar_low),
    default_rule: { ty: 'cover-fire', alert: false },
    team: 1,
    spawnarea: 5
  };

  const team1_tmpl_ar_ace3 = {
    ...entityFromStat2(stats2_const(4), tmpl_firearm_ar_low),
    default_rule: { ty: 'cover-fire', alert: false },
    team: 1,
    spawnarea: 6
  };

  return {
    world,

    entities: [
      ...new Array(1).fill(team0_dmr),
      ...new Array(1).fill(team0_ar),
      ...new Array(1).fill(team0_ar),

      { ...team1_tmpl_ar, spawnarea: 1 },
      { ...team1_tmpl_ar, spawnarea: 2 },

      { ...team1_tmpl_ar, spawnarea: 3 },

      { ...team1_tmpl_ar, spawnarea: 5 },
      { ...team1_tmpl_ar, spawnarea: 6 },

    ],


    spawnareas: [
      { pos: new v2(-2, 224), extent: new v2(53, 18), heading: 0, spawn: true },
      { pos: new v2(-174, 126), extent: new v2(25, 17), heading: 0, spawn1: true, },
      { pos: new v2(-240, -158), extent: new v2(25, 18), heading: 0, spawn1: true, },
      { pos: new v2(-264, -98), extent: new v2(16, 13), heading: 0, spawn1: true, },
      { pos: new v2(243, -29), extent: new v2(12, 13), heading: 0, },
      { pos: new v2(242, 4), extent: new v2(16, 13), heading: 0, spawn1: true, },
      { pos: new v2(-95, -102), extent: new v2(16, 13), heading: 0, spawn1: true, },
      { pos: new v2(230, -224), extent: new v2(18, 20), heading: 0, },
      {
        pos: new v2(-41, -181), extent: new v2(78, 33), heading: 0,
        triggers: [
          {
            condition: 'enter',
            conditiontarget: { ty: 'entity', team: 0 },
            action: 'push_rule',
            actiontarget: { ty: 'entity', team: 0 },
            actionrules: [{ ty: 'capture' }],
          }
        ],
      },
      {
        pos: new v2(-130, 43), extent: new v2(17, 24), heading: 0,
        triggers: [
          {
            condition: 'enter',
            conditiontarget: { ty: 'entity', team: 0 },
            action: 'spawn_entity',
            actiondelay: 0,
            actionentities: new Array(2).fill(team1_tmpl_ar_ace1),
          }
        ],
      },
      {
        pos: new v2(267, -116), extent: new v2(25, 32), heading: 0,
        triggers: [
          {
            condition: 'enter',
            conditiontarget: { ty: 'entity', team: 0 },
            action: 'spawn_entity',
            actiondelay: 0,
            actionentities: new Array(2).fill(team1_tmpl_ar_ace3),
          }
        ],
      },
    ],
    obstacle_specs: [
      { pos: new v2(-124, 174), extent: new v2(282, 23), heading: 0, ty: "full" },
      { pos: new v2(15, 114), extent: new v2(7, 8), heading: 0.13, ty: "half" },
      { pos: new v2(-51, 129), extent: new v2(6, 4), heading: -1.92, ty: "half" },
      { pos: new v2(-184, 93), extent: new v2(35, 5), heading: 0, ty: "full" },
      { pos: new v2(-231, 56), extent: new v2(6, 9), heading: 0.39, ty: "half" },
      { pos: new v2(-195, 76), extent: new v2(6, 4), heading: -0.24, ty: "half" },
      { pos: new v2(-197, 37), extent: new v2(12, 3), heading: 0, ty: "half" },
      { pos: new v2(112, -159), extent: new v2(84, 5), heading: 0, ty: "full" },
      { pos: new v2(-22, -63), extent: new v2(75, 14), heading: 0, ty: "full" },
      { pos: new v2(-206, -84), extent: new v2(4, 12), heading: -0.72, ty: "half" },
      { pos: new v2(-194, -20), extent: new v2(8, 13), heading: 0.26, ty: "half" },
      { pos: new v2(-227, -3), extent: new v2(11, 6), heading: -0.6, ty: "half" },
      { pos: new v2(65, -54), extent: new v2(17, 5), heading: 0, ty: "full" },
      { pos: new v2(63, 100), extent: new v2(6, 7), heading: 0.26, ty: "half" },
      { pos: new v2(87, 130), extent: new v2(6, 7), heading: -0.59, ty: "half" },
      { pos: new v2(-127, 93), extent: new v2(29, 5), heading: 0, ty: "full" },
      { pos: new v2(-127, -53), extent: new v2(37, 4), heading: 0, ty: "full" },
      { pos: new v2(-294, 94), extent: new v2(41, 5), heading: 0, ty: "full" },
      { pos: new v2(-77, 82), extent: new v2(23, 16), heading: 0, ty: "full" },
      { pos: new v2(153, 79), extent: new v2(5, 12), heading: 0, ty: "full" },
      { pos: new v2(189, 140), extent: new v2(18, 4), heading: -0.12, ty: "half" },
      { pos: new v2(277, -159), extent: new v2(84, 5), heading: 0, ty: "full" },
      { pos: new v2(33, 71), extent: new v2(125, 5), heading: 0, ty: "full" },
      { pos: new v2(-127, 119), extent: new v2(14, 3), heading: 0.79, ty: "half" },
      { pos: new v2(-32, 100), extent: new v2(8, 6), heading: -0.96, ty: "half" },
      { pos: new v2(8, 137), extent: new v2(16, 3), heading: 0.31, ty: "half" },
      { pos: new v2(81, -67), extent: new v2(6, 18), heading: 0, ty: "full" },
      { pos: new v2(14, -141), extent: new v2(33, 8), heading: 0, ty: "full" },
      { pos: new v2(37, -152), extent: new v2(10, 12), heading: 0, ty: "full" },
      { pos: new v2(178, 172), extent: new v2(11, 3), heading: -0.36, ty: "half" },
      { pos: new v2(203, 190), extent: new v2(6, 5), heading: -1.39, ty: "half" },
      { pos: new v2(-232, -122), extent: new v2(18, 3), heading: -0.12, ty: "half" },
      { pos: new v2(-192, -148), extent: new v2(8, 11), heading: -0.48, ty: "half" },
      { pos: new v2(-273, -28), extent: new v2(3, 6), heading: 0.26, ty: "half" },
      { pos: new v2(-110, -31), extent: new v2(4, 5), heading: -0.48, ty: "half" },
      { pos: new v2(-130, 54), extent: new v2(7, 4), heading: -3, ty: "half" },
      { pos: new v2(133, 1), extent: new v2(4, 9), heading: 0.55, ty: "half" },
      { pos: new v2(130, 42), extent: new v2(6, 4), heading: 0.72, ty: "half" },
      { pos: new v2(6, -117), extent: new v2(4, 26), heading: 0, ty: "half" },
      { pos: new v2(48, -107), extent: new v2(9, 11), heading: 0.39, ty: "half" },
      { pos: new v2(63, -127), extent: new v2(6, 6), heading: -0.48, ty: "half" },
      { pos: new v2(275, -95), extent: new v2(11, 3), heading: -0.6, ty: "half" },
      { pos: new v2(260, -127), extent: new v2(6, 9), heading: -0.9, ty: "half" },
      { pos: new v2(-279, -52), extent: new v2(26, 4), heading: 0, ty: "half" },
      { pos: new v2(161, -79), extent: new v2(75, 6), heading: 0, ty: "full" },
      { pos: new v2(-126, -173), extent: new v2(6, 39), heading: 0, ty: "full" },
      { pos: new v2(-243, -198), extent: new v2(117, 14), heading: 0, ty: "full" },
      { pos: new v2(-292, -161), extent: new v2(17, 24), heading: 0, ty: "full" },
      { pos: new v2(-297, -108), extent: new v2(7, 60), heading: 0, ty: "full" },
      { pos: new v2(-209, -52), extent: new v2(26, 4), heading: 0, ty: "half" },
      { pos: new v2(268, 174), extent: new v2(48, 23), heading: 0, ty: "full" },
      { pos: new v2(298, 80), extent: new v2(5, 31), heading: 0, ty: "full" },
      { pos: new v2(-70, -17), extent: new v2(5, 44), heading: 0, ty: "half" },
      { pos: new v2(-45, 24), extent: new v2(6, 45), heading: 0, ty: "half" },
      { pos: new v2(-14, -5), extent: new v2(6, 45), heading: 0, ty: "half" },
      { pos: new v2(50, -6), extent: new v2(6, 45), heading: 0, ty: "half" },
      { pos: new v2(-93, 26), extent: new v2(5, 44), heading: 0, ty: "half" },
      { pos: new v2(112, 37), extent: new v2(4, 9), heading: 0.55, ty: "half" },
      { pos: new v2(0, 53), extent: new v2(5, 6), heading: -0.6, ty: "half" },
      { pos: new v2(-89, 102), extent: new v2(7, 9), heading: 0, ty: "full" },
      { pos: new v2(-115, 148), extent: new v2(5, 14), heading: 0, ty: "full" },
      { pos: new v2(155, -19), extent: new v2(5, 56), heading: 0, ty: "half" },
      { pos: new v2(226, 87), extent: new v2(71, 4), heading: 0, ty: "full" },
      { pos: new v2(223, -66), extent: new v2(13, 11), heading: 0, ty: "full" },
      { pos: new v2(231, -107), extent: new v2(4, 23), heading: 0, ty: "half" },
      { pos: new v2(202, -136), extent: new v2(4, 23), heading: 0, ty: "half" },
      { pos: new v2(168, -107), extent: new v2(4, 23), heading: 0, ty: "half" },
      { pos: new v2(96, -105), extent: new v2(4, 23), heading: 0, ty: "half" },
      { pos: new v2(78, -99), extent: new v2(5, 5), heading: -0.36, ty: "half" },
      { pos: new v2(132, -138), extent: new v2(4, 23), heading: 0, ty: "half" },
      { pos: new v2(-160, 6), extent: new v2(5, 63), heading: 0, ty: "full" },
      { pos: new v2(-119, 14), extent: new v2(3, 17), heading: 0.32, ty: "half" },
      { pos: new v2(-133, -142), extent: new v2(33, 8), heading: 0, ty: "full" },
      { pos: new v2(-158, -95), extent: new v2(8, 41), heading: 0, ty: "full" },
      { pos: new v2(80, 29), extent: new v2(6, 45), heading: 0, ty: "half" },
      { pos: new v2(22, 27), extent: new v2(6, 45), heading: 0, ty: "half" },
      { pos: new v2(-20, 55), extent: new v2(5, 6), heading: -0.84, ty: "half" },
      { pos: new v2(-38, -35), extent: new v2(9, 4), heading: -0.24, ty: "half" },
      { pos: new v2(52, 55), extent: new v2(9, 3), heading: -0.24, ty: "half" },
      { pos: new v2(111, -31), extent: new v2(6, 13), heading: -0.58, ty: "half" },
      { pos: new v2(215, -15), extent: new v2(5, 40), heading: 0, ty: "half" },
      { pos: new v2(238, 29), extent: new v2(28, 5), heading: 0, ty: "half" },
      { pos: new v2(271, -14), extent: new v2(5, 49), heading: 0, ty: "half" },
      { pos: new v2(249, -60), extent: new v2(26, 4), heading: 0, ty: "half" },
      { pos: new v2(-68, -101), extent: new v2(4, 26), heading: 0, ty: "half" },
      { pos: new v2(-123, -97), extent: new v2(5, 42), heading: 0, ty: "half" },
      { pos: new v2(-96, -125), extent: new v2(33, 4), heading: 0, ty: "half" },
    ],

    goals: [
      { name: 'escape area', area: 7 },
    ],

    mission_rules: [
      { ty: 'explore' },
    ],

    editor: true,
  };
}

export function preset_alley1() {
  const world = {
    width: 600,
    height: 600,
    simover_rule: 'goal',
  };

  const team0_smg = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_smg_low),
    ...gear_vest_bulletproof_highend,
    spawnarea: 0,
  };

  // 적 에이전트 스펙 정의
  const team1_tmpl_ar = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_ar_low),
    default_rule: { ty: 'idle', alert: false },
    team: 1,
    spawnarea: 2,
  };

  const team1_tmpl_ar_area4 = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_ar_low),
    default_rule: { ty: 'explore', alert: false },
    team: 1,
    spawnarea: 4
  };

  const team1_tmpl_ar_area5 = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_ar_low),
    default_rule: { ty: 'explore', alert: false },
    team: 1,
    spawnarea: 5
  };

  const team1_tmpl_ar_area10 = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_ar_low),
    default_rule: { ty: 'explore', alert: false },
    team: 1,
    spawnarea: 10
  };

  const team1_tmpl_ar_area11 = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_ar_low),
    default_rule: { ty: 'explore', alert: false },
    team: 1,
    spawnarea: 11
  };

  return {
    world,

    entities: [
      ...new Array(1).fill(team0_smg),
      ...new Array(1).fill(team0_smg),
      ...new Array(1).fill(team0_smg),
      ...new Array(1).fill(team0_smg),

      { ...team1_tmpl_ar, spawnarea: 1 },

      { ...team1_tmpl_ar, spawnarea: 2 },

      { ...team1_tmpl_ar, spawnarea: 3 },

      { ...team1_tmpl_ar, spawnarea: 7 },

      { ...team1_tmpl_ar, spawnarea: 9 },
      { ...team1_tmpl_ar, spawnarea: 9 },

      { ...team1_tmpl_ar, spawnarea: 13 },
      { ...team1_tmpl_ar, spawnarea: 13 },

      { ...team1_tmpl_ar, spawnarea: 14 },
      { ...team1_tmpl_ar, spawnarea: 14 },

      { ...team1_tmpl_ar, spawnarea: 16 },
      { ...team1_tmpl_ar, spawnarea: 16 },

    ],


    spawnareas: [
      { pos: new v2(-259, -87), extent: new v2(33, 16), heading: 0, spawn: true },
      { pos: new v2(-119, -71), extent: new v2(14, 12), heading: 0, spawn1: true },
      { pos: new v2(-57, -235), extent: new v2(23, 19), heading: 0, spawn1: true },
      { pos: new v2(137, -209), extent: new v2(19, 22), heading: 0, spawn1: true },
      { pos: new v2(233, -78), extent: new v2(8, 21), heading: 0, },
      { pos: new v2(45, -52), extent: new v2(11, 26), heading: 0, },
      { pos: new v2(-179, 21), extent: new v2(23, 17), heading: 0, },
      { pos: new v2(0, 157), extent: new v2(17, 15), heading: 0, spawn1: true },
      { pos: new v2(146, 177), extent: new v2(31, 22), heading: 0, },
      { pos: new v2(256, -15), extent: new v2(27, 17), heading: 0, spawn1: true },
      { pos: new v2(26, 274), extent: new v2(25, 9), heading: 0, },
      { pos: new v2(-180, 180), extent: new v2(6, 11), heading: 0, },
      { pos: new v2(-279, -26), extent: new v2(17, 15), heading: 0, },
      {
        pos: new v2(138, -119), extent: new v2(62, 65), heading: 0.01, spawn1: true,
        triggers: [
          {
            condition: 'enter',
            conditiontarget: { ty: 'entity', team: 0 },
            action: 'spawn_entity',
            actiondelay: 0,
            actionentities: new Array(2).fill(team1_tmpl_ar_area4),
          },
          {
            condition: 'enter',
            conditiontarget: { ty: 'entity', team: 0 },
            action: 'spawn_entity',
            actiondelay: 0,
            actionentities: new Array(2).fill(team1_tmpl_ar_area5),
          }
        ],
      },
      {
        pos: new v2(252, 233), extent: new v2(44, 13), heading: 0, spawn1: true,
        triggers: [
          {
            condition: 'enter',
            conditiontarget: { ty: 'entity', team: 0 },
            action: 'spawn_entity',
            actiondelay: 0,
            actionentities: new Array(2).fill(team1_tmpl_ar_area10),
          }
        ],
      },
      {
        pos: new v2(46, 183), extent: new v2(39, 26), heading: 0,
        triggers: [
          {
            condition: 'enter',
            conditiontarget: { ty: 'entity', team: 0 },
            action: 'spawn_entity',
            actiondelay: 0,
            actionentities: new Array(2).fill(team1_tmpl_ar_area11),
          }
        ],
      },
      {
        pos: new v2(-263, 88), extent: new v2(31, 14), heading: 0, spawn1: true,
        triggers: [
          {
            condition: 'enter',
            conditiontarget: { ty: 'entity', team: 0 },
            action: 'push_rule',
            actiontarget: { ty: 'entity', team: 0 },
            actionrules: [{ ty: 'capture' }],
          }
        ],
      },
    ],
    obstacle_specs: [
      { pos: new v2(-201, -251), extent: new v2(109, 60), heading: 0, ty: "full" },
      { pos: new v2(187, -271), extent: new v2(128, 34), heading: 0, ty: "full" },
      { pos: new v2(265, 284), extent: new v2(64, 36), heading: 0, ty: "full" },
      { pos: new v2(180, 294), extent: new v2(50, 25), heading: 0, ty: "full" },
      { pos: new v2(-259, 227), extent: new v2(68, 95), heading: 0, ty: "full" },
      { pos: new v2(-181, -102), extent: new v2(30, 58), heading: 0, ty: "full" },
      { pos: new v2(-150, -124), extent: new v2(19, 36), heading: 0, ty: "full" },
      { pos: new v2(-86, -89), extent: new v2(12, 39), heading: 0, ty: "full" },
      { pos: new v2(-46, -166), extent: new v2(39, 4), heading: 0, ty: "full" },
      { pos: new v2(-65, -292), extent: new v2(58, 28), heading: 0, ty: "full" },
      { pos: new v2(-13, -204), extent: new v2(7, 42), heading: 0, ty: "full" },
      { pos: new v2(-95, -49), extent: new v2(21, 6), heading: 0, ty: "full" },
      { pos: new v2(256, -203), extent: new v2(48, 46), heading: 0, ty: "full" },
      { pos: new v2(274, -95), extent: new v2(29, 45), heading: 0, ty: "full" },
      { pos: new v2(278, -47), extent: new v2(72, 6), heading: 0, ty: "full" },
      { pos: new v2(213, -81), extent: new v2(7, 39), heading: 0, ty: "full" },
      { pos: new v2(209, 18), extent: new v2(3, 60), heading: 0, ty: "full" },
      { pos: new v2(210, 171), extent: new v2(11, 28), heading: 0, ty: "full" },
      { pos: new v2(226, 207), extent: new v2(27, 11), heading: 0, ty: "full" },
      { pos: new v2(152, 213), extent: new v2(55, 4), heading: 0, ty: "full" },
      { pos: new v2(93, 199), extent: new v2(5, 36), heading: 0, ty: "full" },
      { pos: new v2(20, 235), extent: new v2(49, 25), heading: 0, ty: "full" },
      { pos: new v2(65, 273), extent: new v2(4, 14), heading: 0, ty: "full" },
      { pos: new v2(86, 149), extent: new v2(12, 15), heading: 0, ty: "full" },
      { pos: new v2(65, 101), extent: new v2(33, 36), heading: 0, ty: "full" },
      { pos: new v2(15, 71), extent: new v2(19, 5), heading: 0, ty: "half" },
      { pos: new v2(14, 130), extent: new v2(19, 5), heading: 0, ty: "half" },
      { pos: new v2(-24, 227), extent: new v2(33, 31), heading: 0, ty: "full" },
      { pos: new v2(-46, 96), extent: new v2(43, 40), heading: 0, ty: "full" },
      { pos: new v2(-155, 153), extent: new v2(12, 39), heading: 0, ty: "full" },
      { pos: new v2(-133, 180), extent: new v2(31, 12), heading: 0, ty: "full" },
      { pos: new v2(-187, 139), extent: new v2(22, 7), heading: 0, ty: "full" },
      { pos: new v2(-160, 62), extent: new v2(93, 6), heading: 0, ty: "full" },
      { pos: new v2(-269, 125), extent: new v2(5, 15), heading: 0, ty: "full" },
      { pos: new v2(-244, 31), extent: new v2(9, 31), heading: 0, ty: "full" },
      { pos: new v2(-233, -3), extent: new v2(20, 16), heading: 0, ty: "full" },
      { pos: new v2(-211, -29), extent: new v2(18, 23), heading: 0, ty: "full" },
      { pos: new v2(-267, -54), extent: new v2(65, 5), heading: 0, ty: "full" },
      { pos: new v2(-3, -80), extent: new v2(30, 58), heading: 0, ty: "full" },
      { pos: new v2(-45, 1), extent: new v2(72, 26), heading: 0, ty: "full" },
      { pos: new v2(44, 5), extent: new v2(29, 22), heading: 0, ty: "full" },
      { pos: new v2(177, 3), extent: new v2(32, 17), heading: 0, ty: "full" },
      { pos: new v2(68, 22), extent: new v2(22, 5), heading: 0, ty: "full" },
      { pos: new v2(145, 22), extent: new v2(22, 5), heading: 0, ty: "full" },
      { pos: new v2(68, -63), extent: new v2(5, 76), heading: 0, ty: "full" },
      { pos: new v2(-165, -19), extent: new v2(9, 8), heading: 0, ty: "full" },
      { pos: new v2(-76, -144), extent: new v2(2, 19), heading: 0, ty: "full" },
      { pos: new v2(85, -228), extent: new v2(26, 14), heading: 0, ty: "full" },
      { pos: new v2(-85, -195), extent: new v2(29, 3), heading: 0, ty: "half" },
      { pos: new v2(-116, -136), extent: new v2(7, 6), heading: 0.13, ty: "half" },
      { pos: new v2(-100, -143), extent: new v2(4, 5), heading: -0.95, ty: "half" },
      { pos: new v2(-141, -183), extent: new v2(4, 4), heading: 0.13, ty: "half" },
      { pos: new v2(-171, -171), extent: new v2(12, 3), heading: -0.12, ty: "half" },
      { pos: new v2(-283, -130), extent: new v2(6, 4), heading: 0.13, ty: "half" },
      { pos: new v2(-248, -131), extent: new v2(15, 5), heading: -0.6, ty: "half" },
      { pos: new v2(-279, -166), extent: new v2(4, 11), heading: 0.26, ty: "half" },
      { pos: new v2(-244, -166), extent: new v2(7, 2), heading: -1.08, ty: "half" },
      { pos: new v2(45, -163), extent: new v2(7, 6), heading: 0.66, ty: "half" },
      { pos: new v2(68, -203), extent: new v2(5, 17), heading: 0, ty: "full" },
      { pos: new v2(17, -169), extent: new v2(6, 7), heading: 0.39, ty: "half" },
      { pos: new v2(38, -226), extent: new v2(4, 17), heading: -0.24, ty: "half" },
      { pos: new v2(119, -157), extent: new v2(19, 6), heading: -0.36, ty: "half" },
      { pos: new v2(169, -166), extent: new v2(16, 5), heading: 0.39, ty: "half" },
      { pos: new v2(170, -136), extent: new v2(14, 3), heading: 1.17, ty: "half" },
      { pos: new v2(103, -83), extent: new v2(17, 2), heading: 0.13, ty: "half" },
      { pos: new v2(160, -83), extent: new v2(8, 6), heading: 0, ty: "half" },
      { pos: new v2(160, -60), extent: new v2(8, 8), heading: 0, ty: "half" },
      { pos: new v2(186, -60), extent: new v2(8, 8), heading: 0, ty: "half" },
      { pos: new v2(186, -84), extent: new v2(8, 6), heading: 0, ty: "half" },
      { pos: new v2(90, -44), extent: new v2(8, 8), heading: 0, ty: "half" },
      { pos: new v2(90, -21), extent: new v2(8, 8), heading: 0, ty: "half" },
      { pos: new v2(116, -21), extent: new v2(8, 8), heading: 0, ty: "half" },
      { pos: new v2(116, -46), extent: new v2(8, 8), heading: 0, ty: "half" },
      { pos: new v2(90, -130), extent: new v2(8, 8), heading: 0, ty: "half" },
      { pos: new v2(90, -107), extent: new v2(8, 8), heading: 0, ty: "half" },
      { pos: new v2(110, -119), extent: new v2(5, 18), heading: 0, ty: "half" },
      { pos: new v2(214, -139), extent: new v2(4, 8), heading: 0, ty: "half" },
      { pos: new v2(-68, -119), extent: new v2(5, 9), heading: 0, ty: "half" },
      { pos: new v2(-38, -89), extent: new v2(4, 10), heading: 0, ty: "half" },
      { pos: new v2(-68, -52), extent: new v2(5, 9), heading: 0, ty: "half" },
      { pos: new v2(-48, -36), extent: new v2(4, 4), heading: -0.48, ty: "half" },
      { pos: new v2(-54, -79), extent: new v2(2, 5), heading: 0.78, ty: "half" },
      { pos: new v2(-144, -6), extent: new v2(4, 4), heading: -0.48, ty: "half" },
      { pos: new v2(-128, -8), extent: new v2(7, 2), heading: 0, ty: "half" },
      { pos: new v2(-56, 30), extent: new v2(11, 5), heading: 0, ty: "half" },
      { pos: new v2(-12, 55), extent: new v2(9, 4), heading: 0, ty: "half" },
      { pos: new v2(61, 45), extent: new v2(7, 6), heading: 0.39, ty: "half" },
      { pos: new v2(80, 53), extent: new v2(5, 5), heading: -0.12, ty: "half" },
      { pos: new v2(113, 84), extent: new v2(8, 8), heading: 0, ty: "half" },
      { pos: new v2(113, 107), extent: new v2(8, 8), heading: 0, ty: "half" },
      { pos: new v2(134, 95), extent: new v2(5, 18), heading: 0, ty: "half" },
      { pos: new v2(185, 121), extent: new v2(8, 8), heading: 0.14, ty: "half" },
      { pos: new v2(180, 99), extent: new v2(8, 8), heading: 0.39, ty: "half" },
      { pos: new v2(166, 114), extent: new v2(4, 13), heading: 0.28, ty: "half" },
      { pos: new v2(122, 145), extent: new v2(17, 5), heading: 0, ty: "half" },
      { pos: new v2(163, 145), extent: new v2(17, 5), heading: 0, ty: "half" },
      { pos: new v2(163, 68), extent: new v2(5, 5), heading: -0.72, ty: "half" },
      { pos: new v2(178, 66), extent: new v2(5, 5), heading: 0.4, ty: "half" },
      { pos: new v2(241, 143), extent: new v2(5, 14), heading: 0.13, ty: "half" },
      { pos: new v2(240, 78), extent: new v2(9, 4), heading: -0.24, ty: "half" },
      { pos: new v2(236, 177), extent: new v2(7, 11), heading: 0, ty: "half" },
      { pos: new v2(257, 177), extent: new v2(7, 11), heading: 0, ty: "half" },
      { pos: new v2(286, 142), extent: new v2(7, 11), heading: 0, ty: "half" },
      { pos: new v2(286, 112), extent: new v2(7, 11), heading: 0, ty: "half" },
      { pos: new v2(286, 83), extent: new v2(7, 11), heading: 0, ty: "half" },
      { pos: new v2(286, 55), extent: new v2(7, 11), heading: 0, ty: "half" },
      { pos: new v2(227, 21), extent: new v2(12, 5), heading: -0.1, ty: "half" },
      { pos: new v2(227, 37), extent: new v2(12, 5), heading: 0.01, ty: "half" },
      { pos: new v2(229, 53), extent: new v2(12, 5), heading: -0.11, ty: "half" },
      { pos: new v2(183, 256), extent: new v2(8, 8), heading: -0.12, ty: "half" },
      { pos: new v2(155, 256), extent: new v2(8, 8), heading: 0.13, ty: "half" },
      { pos: new v2(127, 254), extent: new v2(5, 5), heading: 0.4, ty: "half" },
      { pos: new v2(93, 239), extent: new v2(12, 4), heading: 0, ty: "half" },
      { pos: new v2(99, 277), extent: new v2(3, 13), heading: 0.52, ty: "half" },
      { pos: new v2(41, 187), extent: new v2(4, 8), heading: -0.2, ty: "half" },
      { pos: new v2(58, 175), extent: new v2(7, 7), heading: 0.13, ty: "half" },
      { pos: new v2(60, 193), extent: new v2(7, 7), heading: -0.11, ty: "half" },
      { pos: new v2(-80, 202), extent: new v2(14, 4), heading: -0.72, ty: "half" },
      { pos: new v2(-235, 121), extent: new v2(13, 7), heading: 0, ty: "half" },
      { pos: new v2(-190, 120), extent: new v2(13, 7), heading: 0, ty: "half" },
      { pos: new v2(-267, 59), extent: new v2(8, 8), heading: -0.12, ty: "half" },
      { pos: new v2(-267, 33), extent: new v2(8, 8), heading: 0.13, ty: "half" },
      { pos: new v2(-289, 115), extent: new v2(7, 11), heading: 0, ty: "half" },
      { pos: new v2(-287, 50), extent: new v2(4, 15), heading: 0.13, ty: "half" },
      { pos: new v2(-217, 88), extent: new v2(5, 4), heading: -0.24, ty: "half" },
      { pos: new v2(-132, 287), extent: new v2(104, 52), heading: 0, ty: "full" },
      { pos: new v2(-135, -49), extent: new v2(21, 4), heading: 0, ty: "full" },
      { pos: new v2(108, 23), extent: new v2(27, 4), heading: 0, ty: "full" },
      { pos: new v2(-63, 175), extent: new v2(6, 7), heading: 0.26, ty: "half" },
      { pos: new v2(-19, 185), extent: new v2(19, 4), heading: 0, ty: "half" },
      { pos: new v2(-47, 152), extent: new v2(2, 8), heading: 0.65, ty: "half" },
      { pos: new v2(-151, 79), extent: new v2(7, 4), heading: 0.13, ty: "half" },
      { pos: new v2(-171, 96), extent: new v2(4, 13), heading: 0.66, ty: "half" },
      { pos: new v2(-104, 202), extent: new v2(2, 18), heading: 0, ty: "full" },
      { pos: new v2(-29, 163), extent: new v2(3, 10), heading: 0.26, ty: "half" },
      { pos: new v2(-130, 124), extent: new v2(4, 16), heading: -0.22, ty: "half" },
      { pos: new v2(-106, 81), extent: new v2(6, 7), heading: 0.26, ty: "half" },
      { pos: new v2(-138, 94), extent: new v2(5, 3), heading: -2.04, ty: "half" },
    ],

    goals: [
      { name: 'escape area', area: 12 },
    ],

    mission_rules: [
      { ty: 'explore' },
    ],

    editor: true,
  };
}


export function preset_shipment_mode1() {
  const world = { width: 1200, height: 900, simover_rule: 'goal' };

  const team0_smg = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_smg_low),
    ...gear_vest_bulletproof_highend,
    spawnarea: 0,
  };

  // 적 에이전트 스펙 정의
  const team1_tmpl_ar = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_ar_low),
    default_rule: { ty: 'idle', alert: false },
    team: 1,
    spawnarea: 2,
  };

  /*
  const team1_tmpl_ar_area4 = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_ar_low),
    default_rule: { ty: 'explore', alert: false },
    team: 1,
    spawnarea: 4
  };

  const team1_tmpl_ar_area5 = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_ar_low),
    default_rule: { ty: 'explore', alert: false },
    team: 1,
    spawnarea: 5
  };

  const team1_tmpl_ar_area10 = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_ar_low),
    default_rule: { ty: 'explore', alert: false },
    team: 1,
    spawnarea: 10
  };

  const team1_tmpl_ar_area11 = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_ar_low),
    default_rule: { ty: 'explore', alert: false },
    team: 1,
    spawnarea: 11
  };
  */

  return {
    world,

    entities: [
      ...new Array(1).fill(team0_smg),
      ...new Array(1).fill(team0_smg),
      ...new Array(1).fill(team0_smg),
      ...new Array(1).fill(team0_smg),

      { ...team1_tmpl_ar, spawnarea: 4 },


    ],



    spawnareas: [
      { pos: new v2(518, 261), extent: new v2(36, 27), heading: 0, },
      { pos: new v2(257, 39), extent: new v2(20, 20), heading: 0, },
      { pos: new v2(420, -98), extent: new v2(35, 15), heading: 0, },
      { pos: new v2(319, -205), extent: new v2(30, 16), heading: 0, },
      { pos: new v2(265, -307), extent: new v2(19, 17), heading: 0, },
      { pos: new v2(530, -417), extent: new v2(19, 17), heading: 0, },
    ],
    obstacle_specs: [
      { pos: new v2(-478, 258), extent: new v2(12, 94), heading: 0, ty: "full", },
      { pos: new v2(90, 339), extent: new v2(580, 13), heading: 0, ty: "full", },
      { pos: new v2(-329, 109), extent: new v2(13, 79), heading: 0, ty: "full", },
      { pos: new v2(-86, -321), extent: new v2(13, 435), heading: -0.6, ty: "full", },
      { pos: new v2(-160, -8), extent: new v2(92, 31), heading: 0, ty: "full", },
      { pos: new v2(-179, 69), extent: new v2(111, 34), heading: 0, ty: "full", },
      { pos: new v2(-136, -84), extent: new v2(67, 30), heading: 0, ty: "full", },
      { pos: new v2(-180, 154), extent: new v2(111, 34), heading: 0, ty: "full", },
      { pos: new v2(-416, 174), extent: new v2(74, 10), heading: 0, ty: "full", },
      { pos: new v2(98, 154), extent: new v2(111, 34), heading: 0, ty: "full", },
      { pos: new v2(99, 72), extent: new v2(111, 34), heading: 0, ty: "full", },
      { pos: new v2(99, -8), extent: new v2(111, 34), heading: 0, ty: "full", },
      { pos: new v2(98, -87), extent: new v2(111, 34), heading: 0, ty: "full", },
      { pos: new v2(198, 303), extent: new v2(10, 23), heading: 0, ty: "full", },
      { pos: new v2(-8, 290), extent: new v2(10, 23), heading: 0, ty: "full", },
      { pos: new v2(-188, 291), extent: new v2(10, 23), heading: 0, ty: "full", },
      { pos: new v2(-326, 283), extent: new v2(10, 23), heading: 0, ty: "full", },
      { pos: new v2(-402, 250), extent: new v2(10, 23), heading: 0, ty: "full", },
      { pos: new v2(181, 401), extent: new v2(29, 70), heading: -0.2, ty: "full", },
      { pos: new v2(423, 501), extent: new v2(28, 165), heading: -0.2, ty: "full", },
      { pos: new v2(356, 167), extent: new v2(71, 17), heading: 0, ty: "full", },
      { pos: new v2(394, 130), extent: new v2(31, 11), heading: 0, ty: "full", },
      { pos: new v2(444, 137), extent: new v2(8, 18), heading: 0, ty: "full", },
      { pos: new v2(469, 139), extent: new v2(7, 21), heading: 0, ty: "full", },
      { pos: new v2(554, 33), extent: new v2(107, 61), heading: 0, ty: "full", },
      { pos: new v2(386, 216), extent: new v2(101, 4), heading: 0, ty: "full", },
      { pos: new v2(626, 215), extent: new v2(101, 4), heading: 0, ty: "full", },
      { pos: new v2(381, -2), extent: new v2(44, 30), heading: 0, ty: "full", },
      { pos: new v2(391, 77), extent: new v2(32, 22), heading: 0, ty: "full", },
      { pos: new v2(476, -271), extent: new v2(26, 56), heading: -0.8, ty: "full", },
      { pos: new v2(461, -335), extent: new v2(21, 24), heading: -0.8, ty: "full", },
      { pos: new v2(413, -290), extent: new v2(21, 24), heading: -0.8, ty: "full", },
      { pos: new v2(384, -326), extent: new v2(11, 28), heading: -0.8, ty: "full", },
      { pos: new v2(429, -369), extent: new v2(11, 28), heading: -0.8, ty: "full", },
      { pos: new v2(332, -419), extent: new v2(26, 56), heading: -0.8, ty: "full", },
      { pos: new v2(98, -189), extent: new v2(111, 34), heading: 0, ty: "full", },
      { pos: new v2(298, -5), extent: new v2(12, 26), heading: 0, ty: "full", },
      { pos: new v2(299, 73), extent: new v2(12, 26), heading: 0, ty: "full", },
      { pos: new v2(338, 92), extent: new v2(39, 3), heading: 0, ty: "half", },
      { pos: new v2(222, -26), extent: new v2(4, 249), heading: 0, ty: "full", },
      { pos: new v2(345, -165), extent: new v2(82, 15), heading: 0, ty: "full", },
      { pos: new v2(284, -470), extent: new v2(26, 56), heading: -0.8, ty: "full", },
      { pos: new v2(532, -154), extent: new v2(87, 5), heading: 0, ty: "full", },
      { pos: new v2(107, -256), extent: new v2(102, 17), heading: 0, ty: "full", },
      { pos: new v2(58, -358), extent: new v2(26, 56), heading: -0.6, ty: "full", },
      { pos: new v2(114, -342), extent: new v2(20, 39), heading: -0.6, ty: "full", },
      { pos: new v2(-330, 317), extent: new v2(4, 15), heading: 0, ty: "half", },
      { pos: new v2(-77, 110), extent: new v2(6, 18), heading: 0, ty: "half", },
      { pos: new v2(-77, 28), extent: new v2(4, 13), heading: 0, ty: "half", },
      { pos: new v2(-77, -51), extent: new v2(5, 20), heading: 0, ty: "half", },
      { pos: new v2(-90, -166), extent: new v2(27, 11), heading: -0.2, ty: "full", },
      { pos: new v2(-99, -203), extent: new v2(14, 15), heading: 0.1, ty: "half", },
      { pos: new v2(-68, -190), extent: new v2(10, 9), heading: -0.2, ty: "half", },
      { pos: new v2(-131, -173), extent: new v2(10, 14), heading: -0.2, ty: "half", },
      { pos: new v2(-70, -214), extent: new v2(8, 7), heading: 0, ty: "half", },
      { pos: new v2(103, 202), extent: new v2(118, 7), heading: 0, ty: "full", },
      { pos: new v2(-187, 204), extent: new v2(118, 7), heading: 0, ty: "full", },
      { pos: new v2(-315, 196), extent: new v2(13, 15), heading: 0, ty: "full", },
      { pos: new v2(-322, 242), extent: new v2(5, 34), heading: 0, ty: "half", },
      { pos: new v2(-66, -141), extent: new v2(14, 5), heading: -0.1, ty: "half", },
      { pos: new v2(-60, 132), extent: new v2(5, 5), heading: 0.1, ty: "half", },
      { pos: new v2(-60, 145), extent: new v2(5, 5), heading: -0.1, ty: "half", },
      { pos: new v2(-57, -317), extent: new v2(13, 3), heading: 0.5, ty: "half", },
      { pos: new v2(-38, -311), extent: new v2(10, 3), heading: 0, ty: "half", },
      { pos: new v2(135, -297), extent: new v2(9, 7), heading: -0.6, ty: "half", },
      { pos: new v2(112, -286), extent: new v2(8, 6), heading: 0, ty: "half", },
      { pos: new v2(267, -249), extent: new v2(4, 25), heading: 0, ty: "full", },
      { pos: new v2(292, -269), extent: new v2(26, 5), heading: 0, ty: "full", },
      { pos: new v2(300, -244), extent: new v2(18, 11), heading: 0, ty: "half", },
      { pos: new v2(334, -244), extent: new v2(9, 11), heading: 0, ty: "half", },
      { pos: new v2(268, -191), extent: new v2(5, 13), heading: 0, ty: "full", },
      { pos: new v2(390, -195), extent: new v2(8, 7), heading: 0, ty: "half", },
      { pos: new v2(246, -156), extent: new v2(22, 3), heading: 0, ty: "half", },
      { pos: new v2(467, -46), extent: new v2(17, 10), heading: 0.1, ty: "half", },
      { pos: new v2(477, -131), extent: new v2(12, 9), heading: 0.1, ty: "half", },
      { pos: new v2(407, -136), extent: new v2(6, 14), heading: 1.5, ty: "half", },
      { pos: new v2(452, -66), extent: new v2(4, 9), heading: 1.6, ty: "half", },
      { pos: new v2(291, -42), extent: new v2(4, 5), heading: 0, ty: "half", },
      { pos: new v2(304, -45), extent: new v2(6, 9), heading: 0, ty: "half", },
      { pos: new v2(350, -43), extent: new v2(12, 5), heading: 0, ty: "half", },
      { pos: new v2(378, -45), extent: new v2(11, 7), heading: 0, ty: "half", },
      { pos: new v2(564, -118), extent: new v2(22, 17), heading: 0, ty: "full", },
      { pos: new v2(571, -64), extent: new v2(16, 26), heading: 0, ty: "full", },
      { pos: new v2(527, -63), extent: new v2(16, 26), heading: 0, ty: "full", },
      { pos: new v2(496, -140), extent: new v2(3, 14), heading: 0, ty: "full", },
      { pos: new v2(496, -39), extent: new v2(4, 15), heading: 0, ty: "full", },
      { pos: new v2(272, -412), extent: new v2(6, 27), heading: 0.7, ty: "half", },
      { pos: new v2(381, -297), extent: new v2(5, 13), heading: 0.7, ty: "half", },
      { pos: new v2(420, -257), extent: new v2(5, 13), heading: 0.7, ty: "half", },
      { pos: new v2(408, -350), extent: new v2(7, 12), heading: -0.8, ty: "full", },
      { pos: new v2(0, -139), extent: new v2(5, 24), heading: 0, ty: "half", },
      { pos: new v2(0, -49), extent: new v2(5, 24), heading: 0, ty: "half", },
      { pos: new v2(-2, 34), extent: new v2(5, 24), heading: 0, ty: "half", },
      { pos: new v2(-3, 122), extent: new v2(5, 24), heading: 0, ty: "half", },
      { pos: new v2(-7, 185), extent: new v2(5, 24), heading: 0, ty: "half", },
      { pos: new v2(-76, 185), extent: new v2(5, 24), heading: 0, ty: "half", },
      { pos: new v2(-196, -124), extent: new v2(5, 28), heading: 0, ty: "half", },
      { pos: new v2(200, 244), extent: new v2(5, 37), heading: 0, ty: "half", },
      { pos: new v2(220, -415), extent: new v2(5, 74), heading: 0, ty: "full", },
      { pos: new v2(222, -309), extent: new v2(3, 41), heading: 0, ty: "half", },
      { pos: new v2(507, 216), extent: new v2(21, 3), heading: 0, ty: "half", },
      { pos: new v2(506, 207), extent: new v2(19, 4), heading: 0, ty: "full", },
      { pos: new v2(295, 194), extent: new v2(9, 5), heading: 0, ty: "half", },
      { pos: new v2(279, 191), extent: new v2(4, 5), heading: -0.1, ty: "half", },
      { pos: new v2(229, 94), extent: new v2(5, 4), heading: 0, ty: "full", },
      { pos: new v2(267, 94), extent: new v2(37, 4), heading: 0, ty: "half", },
      { pos: new v2(451, 96), extent: new v2(12, 4), heading: 0, ty: "half", },
      { pos: new v2(435, -23), extent: new v2(13, 3), heading: 0, ty: "half", },
      { pos: new v2(321, -25), extent: new v2(21, 3), heading: 0, ty: "half", },
      { pos: new v2(286, -25), extent: new v2(23, 4), heading: 0, ty: "half", },
      { pos: new v2(231, -25), extent: new v2(5, 4), heading: 0, ty: "full", },
      { pos: new v2(410, -209), extent: new v2(8, 7), heading: 0.1, ty: "half", },
      { pos: new v2(408, -192), extent: new v2(7, 4), heading: -0.4, ty: "half", },
      { pos: new v2(536, -269), extent: new v2(10, 10), heading: 0.6, ty: "half", },
      { pos: new v2(558, -250), extent: new v2(10, 10), heading: 0.6, ty: "half", },
      { pos: new v2(565, -275), extent: new v2(12, 7), heading: 0.1, ty: "full", },
      { pos: new v2(553, -293), extent: new v2(6, 6), heading: 0.1, ty: "half", },
    ],

    goals: [
      { name: 'escape area', area: 5 },
    ],

    mission_rules: [
      { ty: 'explore' },
    ],

    editor: true,
  };
}

export function preset_shipment_mode2() {
  const world = { width: 1200, height: 900, simover_rule: 'goal' };

  const team0_smg = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_smg_low),
    ...gear_vest_bulletproof_highend,
    spawnarea: 0,
  };

  // 적 에이전트 스펙 정의
  const team1_tmpl_ar = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_ar_low),
    default_rule: { ty: 'idle', alert: false },
    team: 1,
    spawnarea: 2,
  };

  /*
  const team1_tmpl_ar_area4 = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_ar_low),
    default_rule: { ty: 'explore', alert: false },
    team: 1,
    spawnarea: 4
  };

  const team1_tmpl_ar_area5 = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_ar_low),
    default_rule: { ty: 'explore', alert: false },
    team: 1,
    spawnarea: 5
  };

  const team1_tmpl_ar_area10 = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_ar_low),
    default_rule: { ty: 'explore', alert: false },
    team: 1,
    spawnarea: 10
  };

  const team1_tmpl_ar_area11 = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_ar_low),
    default_rule: { ty: 'explore', alert: false },
    team: 1,
    spawnarea: 11
  };
  */

  return {
    world,

    entities: [
      ...new Array(1).fill(team0_smg),
      ...new Array(1).fill(team0_smg),
      ...new Array(1).fill(team0_smg),
      ...new Array(1).fill(team0_smg),

      { ...team1_tmpl_ar, spawnarea: 4 },


    ],

    spawnareas: [
      { pos: new v2(530, 136), extent: new v2(36, 27), heading: 0, },
      { pos: new v2(-37, 77), extent: new v2(29, 32), heading: -0.2, },
      { pos: new v2(-59, -237), extent: new v2(40, 51), heading: -0.4, },
      { pos: new v2(269, -316), extent: new v2(26, 25), heading: 0, },
      { pos: new v2(392, -203), extent: new v2(31, 10), heading: 0, },
      { pos: new v2(525, -366), extent: new v2(23, 19), heading: 0, },
    ],
    obstacle_specs: [
      { pos: new v2(-478, 258), extent: new v2(12, 94), heading: 0, ty: "full", },
      { pos: new v2(90, 339), extent: new v2(580, 13), heading: 0, ty: "full", },
      { pos: new v2(-329, 109), extent: new v2(13, 79), heading: 0, ty: "full", },
      { pos: new v2(-86, -321), extent: new v2(13, 435), heading: -0.6, ty: "full", },
      { pos: new v2(-84, 0), extent: new v2(92, 31), heading: -0.2, ty: "full", },
      { pos: new v2(-183, 80), extent: new v2(111, 34), heading: -0.2, ty: "full", },
      { pos: new v2(-111, -66), extent: new v2(67, 30), heading: -0.3, ty: "full", },
      { pos: new v2(-195, 174), extent: new v2(111, 34), heading: 0.3, ty: "full", },
      { pos: new v2(-416, 174), extent: new v2(74, 10), heading: 0, ty: "full", },
      { pos: new v2(98, 154), extent: new v2(111, 34), heading: -0.2, ty: "full", },
      { pos: new v2(99, 72), extent: new v2(111, 34), heading: -0.1, ty: "full", },
      { pos: new v2(99, -8), extent: new v2(111, 34), heading: 0.1, ty: "full", },
      { pos: new v2(100, -71), extent: new v2(111, 34), heading: -0.1, ty: "full", },
      { pos: new v2(198, 303), extent: new v2(10, 23), heading: 0, ty: "full", },
      { pos: new v2(-336, 312), extent: new v2(10, 23), heading: -0.5, ty: "full", },
      { pos: new v2(-402, 250), extent: new v2(10, 23), heading: 0, ty: "full", },
      { pos: new v2(181, 401), extent: new v2(29, 70), heading: -0.2, ty: "full", },
      { pos: new v2(423, 501), extent: new v2(28, 165), heading: -0.2, ty: "full", },
      { pos: new v2(356, 167), extent: new v2(71, 17), heading: 0, ty: "full", },
      { pos: new v2(394, 130), extent: new v2(31, 11), heading: 0, ty: "full", },
      { pos: new v2(444, 137), extent: new v2(8, 18), heading: 0, ty: "full", },
      { pos: new v2(469, 139), extent: new v2(7, 21), heading: 0, ty: "full", },
      { pos: new v2(554, 33), extent: new v2(107, 61), heading: 0, ty: "full", },
      { pos: new v2(386, 216), extent: new v2(101, 4), heading: 0, ty: "full", },
      { pos: new v2(626, 215), extent: new v2(101, 4), heading: 0, ty: "full", },
      { pos: new v2(381, -2), extent: new v2(44, 30), heading: 0, ty: "full", },
      { pos: new v2(391, 77), extent: new v2(32, 22), heading: 0, ty: "full", },
      { pos: new v2(437, 85), extent: new v2(29, 4), heading: 0, ty: "half", },
      { pos: new v2(476, -271), extent: new v2(26, 56), heading: -0.8, ty: "full", },
      { pos: new v2(461, -335), extent: new v2(21, 24), heading: -0.8, ty: "full", },
      { pos: new v2(413, -290), extent: new v2(21, 24), heading: -0.8, ty: "full", },
      { pos: new v2(384, -326), extent: new v2(11, 28), heading: -0.8, ty: "full", },
      { pos: new v2(429, -369), extent: new v2(11, 28), heading: -0.8, ty: "full", },
      { pos: new v2(332, -419), extent: new v2(26, 56), heading: -0.8, ty: "full", },
      { pos: new v2(106, -176), extent: new v2(111, 34), heading: -0.1, ty: "full", },
      { pos: new v2(298, -5), extent: new v2(12, 26), heading: 0, ty: "full", },
      { pos: new v2(299, 73), extent: new v2(12, 26), heading: 0, ty: "full", },
      { pos: new v2(338, 92), extent: new v2(39, 3), heading: 0, ty: "half", },
      { pos: new v2(222, -26), extent: new v2(4, 249), heading: 0, ty: "full", },
      { pos: new v2(345, -165), extent: new v2(82, 15), heading: 0, ty: "full", },
      { pos: new v2(284, -470), extent: new v2(26, 56), heading: -0.8, ty: "full", },
      { pos: new v2(532, -154), extent: new v2(87, 5), heading: 0, ty: "full", },
      { pos: new v2(257, 93), extent: new v2(33, 5), heading: 0, ty: "half", },
      { pos: new v2(99, -252), extent: new v2(102, 17), heading: 0, ty: "full", },
      { pos: new v2(58, -358), extent: new v2(26, 56), heading: -0.6, ty: "full", },
      { pos: new v2(114, -342), extent: new v2(20, 39), heading: -0.6, ty: "full", },
      { pos: new v2(181, -320), extent: new v2(20, 39), heading: 1, ty: "full", },
      { pos: new v2(-90, -166), extent: new v2(27, 11), heading: -0.2, ty: "full", },
      { pos: new v2(-99, -203), extent: new v2(14, 15), heading: 0.1, ty: "full", },
      { pos: new v2(-68, -190), extent: new v2(10, 9), heading: -0.2, ty: "half", },
      { pos: new v2(-131, -173), extent: new v2(10, 14), heading: -0.2, ty: "half", },
      { pos: new v2(-70, -214), extent: new v2(8, 7), heading: 0, ty: "half", },
      { pos: new v2(-315, 196), extent: new v2(13, 15), heading: 0, ty: "full", },
      { pos: new v2(-322, 242), extent: new v2(5, 34), heading: 0, ty: "half", },
      { pos: new v2(-64, -137), extent: new v2(14, 5), heading: 3.2, ty: "full", },
      { pos: new v2(-20, 63), extent: new v2(4, 11), heading: 0.1, ty: "half", },
      { pos: new v2(-32, -48), extent: new v2(5, 5), heading: 0.2, ty: "half", },
      { pos: new v2(-57, -317), extent: new v2(13, 3), heading: 0.5, ty: "half", },
      { pos: new v2(-38, -311), extent: new v2(10, 3), heading: 0, ty: "half", },
      { pos: new v2(135, -297), extent: new v2(9, 7), heading: -0.6, ty: "half", },
      { pos: new v2(112, -286), extent: new v2(8, 6), heading: 0, ty: "half", },
      { pos: new v2(267, -249), extent: new v2(4, 25), heading: 0, ty: "full", },
      { pos: new v2(292, -269), extent: new v2(26, 5), heading: 0, ty: "full", },
      { pos: new v2(300, -244), extent: new v2(18, 11), heading: 0, ty: "half", },
      { pos: new v2(333, -207), extent: new v2(11, 15), heading: 0, ty: "half", },
      { pos: new v2(334, -244), extent: new v2(9, 11), heading: 0, ty: "half", },
      { pos: new v2(268, -191), extent: new v2(5, 13), heading: 0, ty: "full", },
      { pos: new v2(304, -218), extent: new v2(8, 7), heading: 0, ty: "half", },
      { pos: new v2(437, -154), extent: new v2(16, 2), heading: 0, ty: "half", },
      { pos: new v2(246, -156), extent: new v2(22, 3), heading: 0, ty: "half", },
      { pos: new v2(467, -46), extent: new v2(17, 10), heading: 0.1, ty: "half", },
      { pos: new v2(477, -131), extent: new v2(12, 9), heading: 0.1, ty: "half", },
      { pos: new v2(407, -136), extent: new v2(6, 14), heading: 1.5, ty: "half", },
      { pos: new v2(452, -66), extent: new v2(4, 9), heading: 1.6, ty: "half", },
      { pos: new v2(291, -42), extent: new v2(4, 5), heading: 0, ty: "half", },
      { pos: new v2(304, -45), extent: new v2(6, 9), heading: 0, ty: "half", },
      { pos: new v2(350, -43), extent: new v2(12, 5), heading: 0, ty: "half", },
      { pos: new v2(378, -45), extent: new v2(11, 7), heading: 0, ty: "half", },
      { pos: new v2(564, -118), extent: new v2(22, 17), heading: 0, ty: "full", },
      { pos: new v2(571, -64), extent: new v2(16, 26), heading: 0, ty: "full", },
      { pos: new v2(527, -63), extent: new v2(16, 26), heading: 0, ty: "full", },
      { pos: new v2(496, -140), extent: new v2(3, 14), heading: 0, ty: "full", },
      { pos: new v2(496, -39), extent: new v2(4, 15), heading: 0, ty: "full", },
      { pos: new v2(272, -412), extent: new v2(6, 27), heading: 0.7, ty: "half", },
      { pos: new v2(381, -297), extent: new v2(5, 13), heading: 0.7, ty: "half", },
      { pos: new v2(420, -257), extent: new v2(5, 13), heading: 0.7, ty: "half", },
      { pos: new v2(408, -350), extent: new v2(7, 12), heading: -0.8, ty: "full", },
      { pos: new v2(97, 268), extent: new v2(111, 34), heading: -0.3, ty: "full", },
      { pos: new v2(-152, 265), extent: new v2(111, 34), heading: 1, ty: "full", },
      { pos: new v2(-231, 244), extent: new v2(111, 34), heading: 0.6, ty: "full", },
      { pos: new v2(-267, 123), extent: new v2(111, 34), heading: -0.5, ty: "full", },
      { pos: new v2(-156, -36), extent: new v2(67, 30), heading: 0.1, ty: "full", },
      { pos: new v2(72, 110), extent: new v2(70, 18), heading: -0.1, ty: "full", },
      { pos: new v2(158, 121), extent: new v2(38, 25), heading: 0, ty: "full", },
      { pos: new v2(65, 298), extent: new v2(111, 34), heading: 0, ty: "full", },
      { pos: new v2(-67, 159), extent: new v2(15, 5), heading: -0.8, ty: "full", },
      { pos: new v2(-26, 209), extent: new v2(3, 12), heading: 0.1, ty: "half", },
      { pos: new v2(-44, 226), extent: new v2(6, 11), heading: 0.2, ty: "half", },
      { pos: new v2(-43, 196), extent: new v2(10, 13), heading: -0.1, ty: "half", },
      { pos: new v2(-68, 228), extent: new v2(15, 6), heading: 0.7, ty: "half", },
      { pos: new v2(-94, 279), extent: new v2(7, 5), heading: 0.2, ty: "half", },
      { pos: new v2(-78, 304), extent: new v2(6, 7), heading: -0.1, ty: "half", },
      { pos: new v2(-32, 111), extent: new v2(19, 4), heading: 0.1, ty: "full", },
      { pos: new v2(-23, 118), extent: new v2(8, 8), heading: 1.3, ty: "full", },
      { pos: new v2(-33, 119), extent: new v2(6, 6), heading: 0.2, ty: "full", },
      { pos: new v2(-67, 129), extent: new v2(6, 4), heading: 0.3, ty: "half", },
      { pos: new v2(-59, 81), extent: new v2(4, 6), heading: 0.7, ty: "half", },
      { pos: new v2(91, 220), extent: new v2(10, 5), heading: -0.3, ty: "half", },
      { pos: new v2(197, 219), extent: new v2(15, 3), heading: 0.6, ty: "half", },
      { pos: new v2(176, 215), extent: new v2(6, 3), heading: -0.1, ty: "half", },
      { pos: new v2(69, 210), extent: new v2(7, 4), heading: -0.2, ty: "half", },
      { pos: new v2(52, 207), extent: new v2(4, 6), heading: 0.1, ty: "half", },
      { pos: new v2(-38, 145), extent: new v2(7, 4), heading: 0.2, ty: "full", },
      { pos: new v2(-193, 10), extent: new v2(6, 5), heading: 0.2, ty: "half", },
      { pos: new v2(-248, -7), extent: new v2(4, 10), heading: 0.1, ty: "half", },
      { pos: new v2(-239, 12), extent: new v2(4, 5), heading: 0.3, ty: "half", },
      { pos: new v2(-303, 49), extent: new v2(7, 5), heading: 0.2, ty: "full", },
      { pos: new v2(-299, 38), extent: new v2(7, 9), heading: -0.3, ty: "full", },
      { pos: new v2(-7, -123), extent: new v2(10, 12), heading: 0.1, ty: "full", },
      { pos: new v2(23, -143), extent: new v2(10, 14), heading: -0.2, ty: "full", },
      { pos: new v2(10, -142), extent: new v2(16, 8), heading: 0.6, ty: "full", },
      { pos: new v2(8, -121), extent: new v2(10, 9), heading: 0.8, ty: "full", },
      { pos: new v2(-23, -71), extent: new v2(5, 10), heading: 0.2, ty: "half", },
      { pos: new v2(-98, -130), extent: new v2(14, 8), heading: -1.1, ty: "half", },
      { pos: new v2(-14, -344), extent: new v2(5, 42), heading: 1, ty: "full", },
      { pos: new v2(-56, -299), extent: new v2(11, 5), heading: -0.2, ty: "half", },
      { pos: new v2(-25, -293), extent: new v2(11, 11), heading: 0.1, ty: "half", },
      { pos: new v2(-51, -249), extent: new v2(10, 9), heading: -0.2, ty: "half", },
      { pos: new v2(-30, -236), extent: new v2(7, 7), heading: 0.2, ty: "half", },
      { pos: new v2(-44, -217), extent: new v2(4, 16), heading: 0.2, ty: "half", },
      { pos: new v2(112, -382), extent: new v2(5, 10), heading: 1, ty: "half", },
      { pos: new v2(153, -356), extent: new v2(5, 10), heading: 1, ty: "half", },
      { pos: new v2(15, -274), extent: new v2(17, 10), heading: 0.5, ty: "full", },
      { pos: new v2(25, -299), extent: new v2(13, 22), heading: -0.2, ty: "full", },
      { pos: new v2(47, -295), extent: new v2(12, 5), heading: 2.7, ty: "full", },
      { pos: new v2(175, -226), extent: new v2(13, 4), heading: 0, ty: "half", },
      { pos: new v2(208, -208), extent: new v2(6, 5), heading: -0.1, ty: "half", },
      { pos: new v2(192, -208), extent: new v2(6, 4), heading: -0.1, ty: "half", },
    ],

    goals: [
      { name: 'escape area', area: 5 },
    ],

    mission_rules: [
      { ty: 'explore' },
    ],

    editor: true,
  };
}


export function preset_shipment_basic() {
  const world = { width: 1200, height: 900, simover_rule: 'goal' };

  const team0_smg = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_smg_low),
    ...gear_vest_bulletproof_highend,
    spawnarea: 0,
  };

  // 적 에이전트 스펙 정의
  const team1_tmpl_ar = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_ar_low),
    default_rule: { ty: 'idle', alert: false },
    team: 1,
    spawnarea: 2,
  };

  /*
  const team1_tmpl_ar_area4 = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_ar_low),
    default_rule: { ty: 'explore', alert: false },
    team: 1,
    spawnarea: 4
  };

  const team1_tmpl_ar_area5 = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_ar_low),
    default_rule: { ty: 'explore', alert: false },
    team: 1,
    spawnarea: 5
  };

  const team1_tmpl_ar_area10 = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_ar_low),
    default_rule: { ty: 'explore', alert: false },
    team: 1,
    spawnarea: 10
  };

  const team1_tmpl_ar_area11 = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_ar_low),
    default_rule: { ty: 'explore', alert: false },
    team: 1,
    spawnarea: 11
  };
  */

  return {
    world,

    entities: [
      ...new Array(1).fill(team0_smg),
      ...new Array(1).fill(team0_smg),
      ...new Array(1).fill(team0_smg),
      ...new Array(1).fill(team0_smg),

      { ...team1_tmpl_ar, spawnarea: 4 },


    ],


    spawnareas: [
      { pos: new v2(530, 136), extent: new v2(36, 27), heading: 0, },
      { pos: new v2(-104, 267), extent: new v2(204, 52), heading: 0, },
      { pos: new v2(-64, -251), extent: new v2(29, 19), heading: 0, },
      { pos: new v2(281, -315), extent: new v2(29, 19), heading: 0, },
      { pos: new v2(532, -418), extent: new v2(29, 19), heading: 0, },
    ],
    obstacle_specs: [
      { pos: new v2(-478, 258), extent: new v2(12, 94), heading: 0, ty: "full", },
      { pos: new v2(90, 339), extent: new v2(580, 13), heading: 0, ty: "full", },
      { pos: new v2(-329, 109), extent: new v2(13, 79), heading: 0, ty: "full", },
      { pos: new v2(-86, -321), extent: new v2(13, 435), heading: -0.6, ty: "full", },
      { pos: new v2(-160, -8), extent: new v2(92, 31), heading: 0, ty: "full", },
      { pos: new v2(-179, 69), extent: new v2(111, 34), heading: 0, ty: "full", },
      { pos: new v2(-136, -84), extent: new v2(67, 30), heading: 0, ty: "full", },
      { pos: new v2(-180, 154), extent: new v2(111, 34), heading: 0, ty: "full", },
      { pos: new v2(-416, 174), extent: new v2(74, 10), heading: 0, ty: "full", },
      { pos: new v2(98, 154), extent: new v2(111, 34), heading: 0, ty: "full", },
      { pos: new v2(99, 72), extent: new v2(111, 34), heading: 0, ty: "full", },
      { pos: new v2(99, -8), extent: new v2(111, 34), heading: 0, ty: "full", },
      { pos: new v2(98, -87), extent: new v2(111, 34), heading: 0, ty: "full", },
      { pos: new v2(198, 303), extent: new v2(10, 23), heading: 0, ty: "full", },
      { pos: new v2(-8, 290), extent: new v2(10, 23), heading: 0, ty: "full", },
      { pos: new v2(-188, 291), extent: new v2(10, 23), heading: 0, ty: "full", },
      { pos: new v2(-326, 283), extent: new v2(10, 23), heading: 0, ty: "full", },
      { pos: new v2(-402, 250), extent: new v2(10, 23), heading: 0, ty: "full", },
      { pos: new v2(181, 401), extent: new v2(29, 70), heading: -0.2, ty: "full", },
      { pos: new v2(423, 501), extent: new v2(28, 165), heading: -0.2, ty: "full", },
      { pos: new v2(356, 167), extent: new v2(71, 17), heading: 0, ty: "full", },
      { pos: new v2(394, 130), extent: new v2(31, 11), heading: 0, ty: "full", },
      { pos: new v2(444, 137), extent: new v2(8, 18), heading: 0, ty: "full", },
      { pos: new v2(469, 139), extent: new v2(7, 21), heading: 0, ty: "full", },
      { pos: new v2(554, 33), extent: new v2(107, 61), heading: 0, ty: "full", },
      { pos: new v2(386, 216), extent: new v2(101, 4), heading: 0, ty: "full", },
      { pos: new v2(626, 215), extent: new v2(101, 4), heading: 0, ty: "full", },
      { pos: new v2(381, -2), extent: new v2(44, 30), heading: 0, ty: "full", },
      { pos: new v2(391, 77), extent: new v2(32, 22), heading: 0, ty: "full", },
      { pos: new v2(437, 85), extent: new v2(29, 4), heading: 0, ty: "half", },
      { pos: new v2(476, -271), extent: new v2(26, 56), heading: -0.8, ty: "full", },
      { pos: new v2(461, -335), extent: new v2(21, 24), heading: -0.8, ty: "full", },
      { pos: new v2(413, -290), extent: new v2(21, 24), heading: -0.8, ty: "full", },
      { pos: new v2(384, -326), extent: new v2(11, 28), heading: -0.8, ty: "full", },
      { pos: new v2(429, -369), extent: new v2(11, 28), heading: -0.8, ty: "full", },
      { pos: new v2(332, -419), extent: new v2(26, 56), heading: -0.8, ty: "full", },
      { pos: new v2(98, -189), extent: new v2(111, 34), heading: 0, ty: "full", },
      { pos: new v2(298, -5), extent: new v2(12, 26), heading: 0, ty: "full", },
      { pos: new v2(299, 73), extent: new v2(12, 26), heading: 0, ty: "full", },
      { pos: new v2(338, 92), extent: new v2(39, 3), heading: 0, ty: "half", },
      { pos: new v2(222, -26), extent: new v2(4, 249), heading: 0, ty: "full", },
      { pos: new v2(345, -165), extent: new v2(82, 15), heading: 0, ty: "full", },
      { pos: new v2(284, -470), extent: new v2(26, 56), heading: -0.8, ty: "full", },
      { pos: new v2(532, -154), extent: new v2(87, 5), heading: 0, ty: "full", },
      { pos: new v2(257, 93), extent: new v2(33, 5), heading: 0, ty: "half", },
      { pos: new v2(107, -256), extent: new v2(102, 17), heading: 0, ty: "full", },
      { pos: new v2(58, -358), extent: new v2(26, 56), heading: -0.6, ty: "full", },
      { pos: new v2(114, -342), extent: new v2(20, 39), heading: -0.6, ty: "full", },
      { pos: new v2(181, -320), extent: new v2(20, 39), heading: 1, ty: "full", },
      { pos: new v2(-330, 317), extent: new v2(4, 15), heading: 0, ty: "half", },
      { pos: new v2(-77, 110), extent: new v2(6, 18), heading: 0, ty: "half", },
      { pos: new v2(-77, 28), extent: new v2(4, 13), heading: 0, ty: "half", },
      { pos: new v2(-77, -51), extent: new v2(5, 20), heading: 0, ty: "half", },
      { pos: new v2(-90, -166), extent: new v2(27, 11), heading: -0.2, ty: "full", },
      { pos: new v2(-99, -203), extent: new v2(14, 15), heading: 0.1, ty: "half", },
      { pos: new v2(-68, -190), extent: new v2(10, 9), heading: -0.2, ty: "half", },
      { pos: new v2(-131, -173), extent: new v2(10, 14), heading: -0.2, ty: "half", },
      { pos: new v2(-70, -214), extent: new v2(8, 7), heading: 0, ty: "half", },
      { pos: new v2(46, 297), extent: new v2(16, 4), heading: 0, ty: "half", },
      { pos: new v2(52, 279), extent: new v2(24, 6), heading: 0, ty: "half", },
      { pos: new v2(-100, 262), extent: new v2(13, 11), heading: -0.1, ty: "half", },
      { pos: new v2(-70, 259), extent: new v2(13, 11), heading: -1.5, ty: "half", },
      { pos: new v2(-273, 231), extent: new v2(13, 11), heading: -0.1, ty: "half", },
      { pos: new v2(-239, 236), extent: new v2(13, 11), heading: -0.3, ty: "half", },
      { pos: new v2(103, 202), extent: new v2(118, 7), heading: 0, ty: "full", },
      { pos: new v2(-187, 204), extent: new v2(118, 7), heading: 0, ty: "full", },
      { pos: new v2(-315, 196), extent: new v2(13, 15), heading: 0, ty: "full", },
      { pos: new v2(-322, 242), extent: new v2(5, 34), heading: 0, ty: "half", },
      { pos: new v2(-66, -141), extent: new v2(14, 5), heading: -0.1, ty: "half", },
      { pos: new v2(-20, 63), extent: new v2(4, 11), heading: 0.1, ty: "half", },
      { pos: new v2(-61, -7), extent: new v2(4, 7), heading: -0.1, ty: "half", },
      { pos: new v2(-22, -61), extent: new v2(5, 5), heading: 0.1, ty: "half", },
      { pos: new v2(-60, 132), extent: new v2(5, 5), heading: 0.1, ty: "half", },
      { pos: new v2(-60, 145), extent: new v2(5, 5), heading: -0.1, ty: "half", },
      { pos: new v2(-21, 127), extent: new v2(5, 5), heading: 0, ty: "half", },
      { pos: new v2(-57, -317), extent: new v2(13, 3), heading: 0.5, ty: "half", },
      { pos: new v2(-38, -311), extent: new v2(10, 3), heading: 0, ty: "half", },
      { pos: new v2(135, -297), extent: new v2(9, 7), heading: -0.6, ty: "half", },
      { pos: new v2(112, -286), extent: new v2(8, 6), heading: 0, ty: "half", },
      { pos: new v2(267, -249), extent: new v2(4, 25), heading: 0, ty: "full", },
      { pos: new v2(292, -269), extent: new v2(26, 5), heading: 0, ty: "full", },
      { pos: new v2(300, -244), extent: new v2(18, 11), heading: 0, ty: "half", },
      { pos: new v2(333, -207), extent: new v2(11, 15), heading: 0, ty: "half", },
      { pos: new v2(334, -244), extent: new v2(9, 11), heading: 0, ty: "half", },
      { pos: new v2(268, -191), extent: new v2(5, 13), heading: 0, ty: "full", },
      { pos: new v2(304, -218), extent: new v2(8, 7), heading: 0, ty: "half", },
      { pos: new v2(437, -154), extent: new v2(16, 2), heading: 0, ty: "half", },
      { pos: new v2(246, -156), extent: new v2(22, 3), heading: 0, ty: "half", },
      { pos: new v2(467, -46), extent: new v2(17, 10), heading: 0.1, ty: "half", },
      { pos: new v2(477, -131), extent: new v2(12, 9), heading: 0.1, ty: "half", },
      { pos: new v2(407, -136), extent: new v2(6, 14), heading: 1.5, ty: "half", },
      { pos: new v2(452, -66), extent: new v2(4, 9), heading: 1.6, ty: "half", },
      { pos: new v2(291, -42), extent: new v2(4, 5), heading: 0, ty: "half", },
      { pos: new v2(304, -45), extent: new v2(6, 9), heading: 0, ty: "half", },
      { pos: new v2(350, -43), extent: new v2(12, 5), heading: 0, ty: "half", },
      { pos: new v2(378, -45), extent: new v2(11, 7), heading: 0, ty: "half", },
      { pos: new v2(564, -118), extent: new v2(22, 17), heading: 0, ty: "full", },
      { pos: new v2(571, -64), extent: new v2(16, 26), heading: 0, ty: "full", },
      { pos: new v2(527, -63), extent: new v2(16, 26), heading: 0, ty: "full", },
      { pos: new v2(496, -140), extent: new v2(3, 14), heading: 0, ty: "full", },
      { pos: new v2(496, -39), extent: new v2(4, 15), heading: 0, ty: "full", },
      { pos: new v2(272, -412), extent: new v2(6, 27), heading: 0.7, ty: "half", },
      { pos: new v2(381, -297), extent: new v2(5, 13), heading: 0.7, ty: "half", },
      { pos: new v2(420, -257), extent: new v2(5, 13), heading: 0.7, ty: "half", },
      { pos: new v2(408, -350), extent: new v2(7, 12), heading: -0.8, ty: "full", },
      { pos: new v2(0, -139), extent: new v2(5, 24), heading: 0, ty: "half", },
      { pos: new v2(0, -49), extent: new v2(5, 24), heading: 0, ty: "half", },
      { pos: new v2(-2, 34), extent: new v2(5, 24), heading: 0, ty: "half", },
      { pos: new v2(-3, 122), extent: new v2(5, 24), heading: 0, ty: "half", },
      { pos: new v2(-7, 185), extent: new v2(5, 24), heading: 0, ty: "half", },
      { pos: new v2(-76, 185), extent: new v2(5, 24), heading: 0, ty: "half", },
      { pos: new v2(-196, -124), extent: new v2(5, 28), heading: 0, ty: "half", },
    ],

    goals: [
      { name: 'escape area', area: 4 },
    ],

    mission_rules: [
      { ty: 'explore' },
    ],

    editor: true,
  };
}



export function preset_storehouse() {
  const world = { width: 1000, height: 600, simover_rule: 'eliminate' };

  const team0_smg = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_smg_low),
    ...gear_vest_bulletproof_highend,
    spawnarea: 0,
  };

  // 적 에이전트 스펙 정의
  const team1_tmpl_ar = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_ar_low),
    default_rule: { ty: 'idle', alert: false },
    team: 1,
    spawnarea: 2,
  };

  const team1_tmpl_ar_area2 = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_ar_low),
    default_rule: { ty: 'capture', alert: false },
    team: 1,
    spawnarea: 2
  };

  return {
    world,

    entities: [
      ...new Array(3).fill(team0_smg),

      { ...team1_tmpl_ar, spawnarea: 1 },
      { ...team1_tmpl_ar, spawnarea: 1 },

      { ...team1_tmpl_ar, spawnarea: 2 },
      { ...team1_tmpl_ar, spawnarea: 2 },
      { ...team1_tmpl_ar, spawnarea: 2 },

      { ...team1_tmpl_ar, spawnarea: 3 },

      { ...team1_tmpl_ar, spawnarea: 6 },
      { ...team1_tmpl_ar, spawnarea: 6 },
      { ...team1_tmpl_ar, spawnarea: 6 },
      { ...team1_tmpl_ar, spawnarea: 6 },

    ],


    spawnareas: [
      { pos: new v2(-450, 169), extent: new v2(29, 25), heading: 0, spawn: true },
      { pos: new v2(465, 172), extent: new v2(18, 47), heading: 0, spawn1: true },
      { pos: new v2(204, -76), extent: new v2(33, 68), heading: 0, spawn1: true },
      { pos: new v2(410, 202), extent: new v2(31, 27), heading: 0, spawn1: true },
      {
        pos: new v2(-129, 222), extent: new v2(56, 104), heading: 0,
        triggers: [
          {
            condition: 'enter',
            conditiontarget: { ty: 'entity', team: 0 },
            action: 'spawn_entity',
            actiondelay: 0,
            actionentities: new Array(3).fill(team1_tmpl_ar_area2),
          }
        ],
      },
      {
        pos: new v2(426, 91), extent: new v2(93, 23), heading: 0,
        triggers: [
          {
            condition: 'enter',
            conditiontarget: { ty: 'entity', team: 0 },
            action: 'spawn_entity',
            actiondelay: 0,
            actionentities: new Array(3).fill(team1_tmpl_ar_area2),
          }
        ],
      },
      {
        pos: new v2(-166, -106), extent: new v2(312, 184), heading: 0, spawn1: true,
        structureopts: { count: 1 },
        triggers: [
          {
            condition: 'enter',
            conditiontarget: {
              ty: 'entity',
              team: 0,
            },

            action: 'push_rule',
            actiontarget: {
              ty: 'entity',
              team: 0,
            },
            actionrules: [{ ty: 'explore', area: 4 }],
          }
        ],
      },
    ],
    obstacle_specs: [
      { pos: new v2(-90, 105), extent: new v2(326, 15), heading: 0, ty: "full" },
      { pos: new v2(277, 105), extent: new v2(47, 15), heading: 0, ty: "full" },
      { pos: new v2(334, 70), extent: new v2(15, 51), heading: 0, ty: "full" },
      { pos: new v2(277, -253), extent: new v2(47, 15), heading: 0, ty: "full" },
      { pos: new v2(334, -217), extent: new v2(15, 51), heading: 0, ty: "full" },
      { pos: new v2(-461, 104), extent: new v2(48, 14), heading: 0, ty: "full" },
      { pos: new v2(-214, 160), extent: new v2(7, 67), heading: 0, ty: "full" },
      { pos: new v2(-213, 285), extent: new v2(6, 25), heading: 0, ty: "full" },
      { pos: new v2(36, 194), extent: new v2(10, 10), heading: -0.24, ty: "half" },
      { pos: new v2(362, 193), extent: new v2(4, 25), heading: 0.13, ty: "half" },
      { pos: new v2(376, 251), extent: new v2(12, 10), heading: -0.24, ty: "half" },
      { pos: new v2(37, 256), extent: new v2(5, 17), heading: -0.12, ty: "half" },
      { pos: new v2(9, 281), extent: new v2(5, 11), heading: -0.24, ty: "half" },
      { pos: new v2(295, 244), extent: new v2(4, 11), heading: 0.13, ty: "half" },
      { pos: new v2(272, 250), extent: new v2(7, 8), heading: -0.12, ty: "half" },
      { pos: new v2(216, 214), extent: new v2(3, 11), heading: 0.81, ty: "half" },
      { pos: new v2(328, 221), extent: new v2(5, 13), heading: 0, ty: "half" },
      { pos: new v2(327, 275), extent: new v2(6, 24), heading: 0, ty: "full" },
      { pos: new v2(282, 201), extent: new v2(6, 5), heading: -0.12, ty: "half" },
      { pos: new v2(404, 257), extent: new v2(11, 10), heading: -0.24, ty: "half" },
      { pos: new v2(428, 264), extent: new v2(8, 10), heading: -0.23, ty: "half" },
      { pos: new v2(13, 139), extent: new v2(20, 9), heading: -0.12, ty: "half" },
      { pos: new v2(61, 139), extent: new v2(20, 9), heading: -0.12, ty: "half" },
      { pos: new v2(-36, 139), extent: new v2(20, 9), heading: 0.13, ty: "half" },
      { pos: new v2(179, 68), extent: new v2(15, 14), heading: -0.24, ty: "full" },
      { pos: new v2(185, -214), extent: new v2(15, 14), heading: -0.12, ty: "full" },
      { pos: new v2(356, 143), extent: new v2(4, 5), heading: -0.12, ty: "half" },
      { pos: new v2(81, 282), extent: new v2(17, 23), heading: 0, ty: "full" },
      { pos: new v2(125, 282), extent: new v2(17, 23), heading: 0, ty: "full" },
      { pos: new v2(81, 229), extent: new v2(17, 23), heading: 0, ty: "full" },
      { pos: new v2(125, 229), extent: new v2(17, 23), heading: 0, ty: "full" },
      { pos: new v2(141, 143), extent: new v2(13, 13), heading: 0.13, ty: "half" },
      { pos: new v2(175, 143), extent: new v2(13, 13), heading: 0.13, ty: "half" },
      { pos: new v2(175, 173), extent: new v2(13, 13), heading: 0.01, ty: "half" },
      { pos: new v2(209, 142), extent: new v2(13, 13), heading: 0.01, ty: "half" },
      { pos: new v2(210, 173), extent: new v2(13, 13), heading: 0.13, ty: "half" },
      { pos: new v2(178, 257), extent: new v2(19, 26), heading: 0.13, ty: "full" },
      { pos: new v2(233, 258), extent: new v2(19, 26), heading: -0.12, ty: "full" },
      { pos: new v2(242, 164), extent: new v2(8, 21), heading: 0.13, ty: "full" },
      { pos: new v2(270, 164), extent: new v2(8, 21), heading: 0, ty: "full" },
      { pos: new v2(228, -215), extent: new v2(15, 14), heading: -0.12, ty: "full" },
      { pos: new v2(227, -177), extent: new v2(15, 14), heading: -0.12, ty: "full" },
      { pos: new v2(269, -177), extent: new v2(15, 14), heading: 0.13, ty: "full" },
      { pos: new v2(270, -215), extent: new v2(15, 14), heading: 0.13, ty: "full" },
      { pos: new v2(185, -176), extent: new v2(15, 14), heading: 0.13, ty: "full" },
      { pos: new v2(224, 67), extent: new v2(15, 14), heading: 0.13, ty: "full" },
      { pos: new v2(270, 67), extent: new v2(15, 14), heading: -0.12, ty: "full" },
      { pos: new v2(269, 29), extent: new v2(15, 14), heading: 0.13, ty: "full" },
      { pos: new v2(224, 27), extent: new v2(15, 14), heading: -0.12, ty: "full" },
      { pos: new v2(183, 29), extent: new v2(15, 14), heading: -0.11, ty: "full" },
      { pos: new v2(402, -249), extent: new v2(33, 35), heading: 0.01, ty: "full" },
      { pos: new v2(464, -239), extent: new v2(16, 30), heading: -0.12, ty: "full" },
      { pos: new v2(92, 175), extent: new v2(10, 11), heading: -0.24, ty: "half" },
      { pos: new v2(122, 182), extent: new v2(6, 13), heading: 0.13, ty: "half" },
      { pos: new v2(369, 274), extent: new v2(12, 7), heading: -0.24, ty: "half" },
      { pos: new v2(407, 283), extent: new v2(18, 7), heading: -0.24, ty: "half" },
      { pos: new v2(374, 143), extent: new v2(4, 5), heading: -0.12, ty: "half" },
      { pos: new v2(389, 152), extent: new v2(4, 5), heading: -0.12, ty: "half" },
      { pos: new v2(408, 160), extent: new v2(4, 5), heading: -0.12, ty: "half" },
      { pos: new v2(385, 123), extent: new v2(4, 5), heading: -0.12, ty: "half" },
      { pos: new v2(409, 135), extent: new v2(4, 5), heading: -0.12, ty: "half" },
      { pos: new v2(452, -32), extent: new v2(18, 21), heading: 0.14, ty: "half" },
      { pos: new v2(391, -84), extent: new v2(18, 21), heading: -0.24, ty: "half" },
      { pos: new v2(454, 246), extent: new v2(9, 12), heading: -0.24, ty: "half" },
      { pos: new v2(477, 246), extent: new v2(9, 12), heading: -0.12, ty: "half" },
      { pos: new v2(260, -140), extent: new v2(4, 5), heading: -0.12, ty: "half" },
      { pos: new v2(282, -140), extent: new v2(4, 5), heading: -0.12, ty: "half" },
      { pos: new v2(271, -118), extent: new v2(4, 12), heading: 0, ty: "half" },
      { pos: new v2(301, -135), extent: new v2(4, 12), heading: 0, ty: "half" },
      { pos: new v2(287, -116), extent: new v2(4, 5), heading: -0.12, ty: "half" },
      { pos: new v2(283, -20), extent: new v2(4, 12), heading: 0, ty: "half" },
      { pos: new v2(366, -21), extent: new v2(4, 12), heading: -0.71, ty: "half" },
      { pos: new v2(293, -51), extent: new v2(4, 5), heading: -0.12, ty: "half" },
      { pos: new v2(306, -17), extent: new v2(4, 5), heading: -0.12, ty: "half" },
      { pos: new v2(269, -42), extent: new v2(4, 5), heading: -0.12, ty: "half" },
      { pos: new v2(302, -34), extent: new v2(4, 5), heading: -0.12, ty: "half" },
      { pos: new v2(262, -11), extent: new v2(4, 12), heading: 0, ty: "half" },
      { pos: new v2(327, 145), extent: new v2(7, 40), heading: 0, ty: "half" },
      { pos: new v2(443, -121), extent: new v2(11, 4), heading: 0.65, ty: "half" },
      { pos: new v2(441, -81), extent: new v2(11, 18), heading: -0.24, ty: "half" },
      { pos: new v2(412, -133), extent: new v2(9, 12), heading: -0.24, ty: "half" },
      { pos: new v2(333, 19), extent: new v2(6, 60), heading: 0, ty: "full" },
      { pos: new v2(333, -45), extent: new v2(8, 5), heading: 0, ty: "full" },
      { pos: new v2(372, -127), extent: new v2(6, 13), heading: -1.32, ty: "half" },
      { pos: new v2(372, 26), extent: new v2(37, 7), heading: 0, ty: "full" },
      { pos: new v2(364, -45), extent: new v2(4, 12), heading: -0.71, ty: "half" },
      { pos: new v2(363, 6), extent: new v2(4, 12), heading: -0.83, ty: "half" },
      { pos: new v2(394, -41), extent: new v2(6, 7), heading: 0, ty: "half" },
      { pos: new v2(403, -19), extent: new v2(5, 4), heading: -0.48, ty: "half" },
      { pos: new v2(415, -50), extent: new v2(4, 3), heading: 0, ty: "half" },
      { pos: new v2(249, -279), extent: new v2(19, 24), heading: 0, ty: "full" },
    ],
    goals: [
      { name: 'Hostile R-Point', area: 3 },
    ],

    mission_rules: [
      { ty: 'explore' },
    ],

    editor: true,
  };
}

export function preset_border_checkpoint() {
  const world = { width: 600, height: 2400, simover_rule: 'eliminate' };

  const team0_ar = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_ar_low),
    ...gear_vest_bulletproof_highend,
    spawnarea: 0,
  };

  const team0_dmr = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_dmr_low),
    ...gear_vest_bulletproof_highend,
    spawnarea: 0,
  };

  // 적 에이전트 스펙 정의
  const team1_tmpl_ar = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_ar_low),
    default_rule: { ty: 'idle', alert: false },
    team: 1,
    spawnarea: 2,
  };

  const team1_tmpl_ar_area3 = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_ar_low),
    default_rule: { ty: 'capture', alert: false },
    team: 1,
    spawnarea: 3
  };

  const team1_tmpl_ar_area4 = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_ar_low),
    default_rule: { ty: 'capture', alert: false },
    team: 1,
    spawnarea: 4
  };

  const team1_tmpl_ar_area5 = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_ar_low),
    default_rule: { ty: 'explore', alert: false },
    team: 1,
    spawnarea: 5
  };

  return {
    world,

    entities: [
      ...new Array(2).fill(team0_ar),
      ...new Array(2).fill(team0_dmr),

      { ...team1_tmpl_ar, spawnarea: 1 },
      { ...team1_tmpl_ar, spawnarea: 1 },

      { ...team1_tmpl_ar, spawnarea: 2 },
      { ...team1_tmpl_ar, spawnarea: 2 },

      { ...team1_tmpl_ar, spawnarea: 3 },
      { ...team1_tmpl_ar, spawnarea: 3 },
      { ...team1_tmpl_ar, spawnarea: 3 },

      { ...team1_tmpl_ar, spawnarea: 4 },
      { ...team1_tmpl_ar, spawnarea: 4 },
      { ...team1_tmpl_ar, spawnarea: 4 },

      { ...team1_tmpl_ar, spawnarea: 5 },

      { ...team1_tmpl_ar, spawnarea: 6 },

      { ...team1_tmpl_ar, spawnarea: 8 },


    ],

    spawnareas: [
      { pos: new v2(215, 1033), extent: new v2(39, 35), heading: 0, spawn: true, },
      { pos: new v2(-267, 632), extent: new v2(23, 43), heading: 0, spawn1: true },
      { pos: new v2(-14, 399), extent: new v2(30, 16), heading: 0, spawn1: true },
      { pos: new v2(-15, -291), extent: new v2(60, 26), heading: 0, spawn1: true },
      { pos: new v2(213, -1066), extent: new v2(29, 20), heading: 0.02, spawn1: true },
      { pos: new v2(-176, -627), extent: new v2(49, 41), heading: 0, spawn1: true },
      { pos: new v2(-199, -458), extent: new v2(28, 23), heading: 0, },
      {
        pos: new v2(-79, 354), extent: new v2(57, 238), heading: 1.62, spawn1: true,
        triggers: [
          {
            condition: 'enter',
            conditiontarget: { ty: 'entity', team: 0 },
            action: 'spawn_entity',
            actiondelay: 0,
            actionentities: new Array(5).fill(team1_tmpl_ar_area3),
          }
        ],
      },
      {
        pos: new v2(178, -391), extent: new v2(17, 67), heading: 0.31,
        triggers: [
          {
            condition: 'enter',
            conditiontarget: { ty: 'entity', team: 0 },
            action: 'spawn_entity',
            actiondelay: 0,
            actionentities: new Array(5).fill(team1_tmpl_ar_area4),
          }
        ],
      },
      {
        pos: new v2(76, -1101), extent: new v2(31, 64), heading: 0.57, spawn1: true,
        triggers: [
          {
            condition: 'enter',
            conditiontarget: { ty: 'entity', team: 0 },
            action: 'spawn_entity',
            actiondelay: 0,
            actionentities: new Array(5).fill(team1_tmpl_ar_area5),
          }
        ],
      },
      { pos: new v2(56, 166), extent: new v2(34, 31), heading: -0.6, },
    ],
    obstacle_specs: [
      { pos: new v2(-253, 1075), extent: new v2(148, 136), heading: 0, ty: "full" },
      { pos: new v2(-203, 892), extent: new v2(6, 68), heading: 0, ty: "full" },
      { pos: new v2(-137, 830), extent: new v2(33, 6), heading: 0, ty: "full" },
      { pos: new v2(-110, 871), extent: new v2(7, 46), heading: 0, ty: "full" },
      { pos: new v2(-354, 430), extent: new v2(63, 238), heading: -0.24, ty: "full" },
      { pos: new v2(-286, 87), extent: new v2(75, 126), heading: -0.12, ty: "full" },
      { pos: new v2(286, 223), extent: new v2(156, 175), heading: 0.02, ty: "full" },
      { pos: new v2(251, -151), extent: new v2(101, 221), heading: -0.11, ty: "full" },
      { pos: new v2(-342, -195), extent: new v2(135, 229), heading: -0.12, ty: "full" },
      { pos: new v2(-102, -396), extent: new v2(66, 22), heading: -0.72, ty: "full" },
      { pos: new v2(-160, -389), extent: new v2(26, 17), heading: 0.01, ty: "full" },
      { pos: new v2(-9, -354), extent: new v2(45, 11), heading: -0.12, ty: "half" },
      { pos: new v2(-24, -412), extent: new v2(52, 16), heading: -0.33, ty: "half" },
      { pos: new v2(52, -368), extent: new v2(29, 41), heading: 0, ty: "full" },
      { pos: new v2(94, -389), extent: new v2(15, 35), heading: -0.24, ty: "full" },
      { pos: new v2(114, -436), extent: new v2(52, 12), heading: 0.26, ty: "full" },
      { pos: new v2(171, -687), extent: new v2(9, 258), heading: 0, ty: "full" },
      { pos: new v2(160, -1014), extent: new v2(9, 88), heading: 0.14, ty: "full" },
      { pos: new v2(326, -1073), extent: new v2(44, 197), heading: 0.14, ty: "full" },
      { pos: new v2(-255, -864), extent: new v2(48, 179), heading: 0, ty: "full" },
      { pos: new v2(-278, -562), extent: new v2(38, 180), heading: 0, ty: "full" },
      { pos: new v2(122, -1255), extent: new v2(9, 88), heading: 0.14, ty: "full" },
      { pos: new v2(-298, -1142), extent: new v2(76, 112), heading: 0.14, ty: "full" },
      { pos: new v2(-267, 733), extent: new v2(7, 13), heading: 0, ty: "half" },
      { pos: new v2(-216, 639), extent: new v2(9, 40), heading: 0.01, ty: "half" },
      { pos: new v2(-268, 511), extent: new v2(51, 3), heading: 0, ty: "full" },
      { pos: new v2(-287, 568), extent: new v2(39, 4), heading: 0, ty: "full" },
      { pos: new v2(-294, 485), extent: new v2(19, 27), heading: 0, ty: "full" },
      { pos: new v2(-303, 444), extent: new v2(20, 45), heading: 0, ty: "full" },
      { pos: new v2(-223, 557), extent: new v2(4, 16), heading: 0, ty: "full" },
      { pos: new v2(-223, 528), extent: new v2(3, 8), heading: 0, ty: "half" },
      { pos: new v2(-290, 532), extent: new v2(7, 6), heading: 0, ty: "half" },
      { pos: new v2(-276, 557), extent: new v2(22, 4), heading: 0, ty: "half" },
      { pos: new v2(-274, 865), extent: new v2(10, 16), heading: 0.13, ty: "half" },
      { pos: new v2(-241, 812), extent: new v2(4, 15), heading: -0.47, ty: "half" },
      { pos: new v2(-265, 757), extent: new v2(19, 4), heading: 0, ty: "half" },
      { pos: new v2(-271, 708), extent: new v2(11, 3), heading: 0, ty: "half" },
      { pos: new v2(-183, 531), extent: new v2(8, 18), heading: 0, ty: "half" },
      { pos: new v2(-82, 638), extent: new v2(16, 46), heading: 0, ty: "full" },
      { pos: new v2(-9, 532), extent: new v2(16, 46), heading: -0.12, ty: "full" },
      { pos: new v2(-87, 529), extent: new v2(16, 46), heading: 0.66, ty: "full" },
      { pos: new v2(-45, 497), extent: new v2(11, 20), heading: 0, ty: "half" },
      { pos: new v2(-73, 454), extent: new v2(11, 20), heading: 0.13, ty: "half" },
      { pos: new v2(-27, 449), extent: new v2(11, 20), heading: 0.13, ty: "half" },
      { pos: new v2(19, 457), extent: new v2(11, 20), heading: 0.66, ty: "half" },
      { pos: new v2(-158, 477), extent: new v2(11, 20), heading: -0.11, ty: "half" },
      { pos: new v2(-118, 456), extent: new v2(11, 20), heading: 0.27, ty: "half" },
      { pos: new v2(-129, 347), extent: new v2(11, 20), heading: 0.04, ty: "half" },
      { pos: new v2(-96, 394), extent: new v2(11, 20), heading: 0.04, ty: "half" },
      { pos: new v2(-90, 330), extent: new v2(11, 20), heading: 0.04, ty: "half" },
      { pos: new v2(-44, 353), extent: new v2(11, 20), heading: 0.04, ty: "half" },
      { pos: new v2(11, 336), extent: new v2(11, 20), heading: -0.92, ty: "half" },
      { pos: new v2(3, 275), extent: new v2(11, 20), heading: -0.79, ty: "half" },
      { pos: new v2(-215, 447), extent: new v2(16, 46), heading: -0.55, ty: "full" },
      { pos: new v2(-160, 394), extent: new v2(11, 20), heading: 0.43, ty: "half" },
      { pos: new v2(-27, 219), extent: new v2(11, 20), heading: -0.67, ty: "half" },
      { pos: new v2(-87, 264), extent: new v2(11, 20), heading: 0.04, ty: "half" },
      { pos: new v2(-184, 202), extent: new v2(16, 46), heading: 1.12, ty: "full" },
      { pos: new v2(-114, 172), extent: new v2(16, 46), heading: -0.9, ty: "full" },
      { pos: new v2(-58, -2), extent: new v2(16, 46), heading: -0.17, ty: "full" },
      { pos: new v2(-119, 1), extent: new v2(11, 20), heading: 0.21, ty: "half" },
      { pos: new v2(-43, -102), extent: new v2(11, 20), heading: 0.08, ty: "half" },
      { pos: new v2(-104, -84), extent: new v2(16, 46), heading: 0.22, ty: "full" },
      { pos: new v2(-74, -177), extent: new v2(16, 46), heading: -0.14, ty: "full" },
      { pos: new v2(26, -165), extent: new v2(11, 20), heading: -0.16, ty: "half" },
      { pos: new v2(73, -213), extent: new v2(11, 20), heading: -0.29, ty: "half" },
      { pos: new v2(70, -269), extent: new v2(11, 20), heading: -0.29, ty: "half" },
      { pos: new v2(-129, -207), extent: new v2(11, 20), heading: -0.04, ty: "half" },
      { pos: new v2(-180, -204), extent: new v2(16, 46), heading: -0.14, ty: "full" },
      { pos: new v2(-173, -118), extent: new v2(11, 20), heading: 0.22, ty: "half" },
      { pos: new v2(-137, -52), extent: new v2(11, 20), heading: 0.22, ty: "half" },
      { pos: new v2(-153, 25), extent: new v2(11, 20), heading: 0.74, ty: "half" },
      { pos: new v2(-173, -30), extent: new v2(11, 20), heading: 0.74, ty: "half" },
      { pos: new v2(-97, 52), extent: new v2(11, 20), heading: 1.13, ty: "half" },
      { pos: new v2(0, -587), extent: new v2(11, 20), heading: 0.47, ty: "half" },
      { pos: new v2(48, -600), extent: new v2(11, 20), heading: -0.16, ty: "half" },
      { pos: new v2(25, -690), extent: new v2(11, 20), heading: -0.16, ty: "half" },
      { pos: new v2(-161, -1015), extent: new v2(11, 20), heading: 0.47, ty: "half" },
      { pos: new v2(32, -441), extent: new v2(11, 20), heading: -0.41, ty: "half" },
      { pos: new v2(25, -486), extent: new v2(11, 20), heading: 0.47, ty: "half" },
      { pos: new v2(54, -507), extent: new v2(11, 20), heading: -0.26, ty: "half" },
      { pos: new v2(-16, -447), extent: new v2(11, 20), heading: -0.02, ty: "half" },
      { pos: new v2(-28, -490), extent: new v2(11, 20), heading: 0.11, ty: "half" },
      { pos: new v2(-19, -534), extent: new v2(11, 20), heading: -0.49, ty: "half" },
      { pos: new v2(-80, -554), extent: new v2(16, 46), heading: 1.15, ty: "full" },
      { pos: new v2(-47, -581), extent: new v2(11, 20), heading: 0.47, ty: "half" },
      { pos: new v2(-78, -506), extent: new v2(11, 20), heading: -1.58, ty: "half" },
      { pos: new v2(30, -547), extent: new v2(11, 20), heading: -0.45, ty: "half" },
      { pos: new v2(-64, -710), extent: new v2(16, 46), heading: 0.18, ty: "full" },
      { pos: new v2(-96, -1092), extent: new v2(11, 20), heading: 0.21, ty: "half" },
      { pos: new v2(1, -1106), extent: new v2(11, 20), heading: 0.21, ty: "half" },
      { pos: new v2(110, -793), extent: new v2(16, 46), heading: -0.19, ty: "full" },
      { pos: new v2(135, -733), extent: new v2(11, 20), heading: -0.14, ty: "half" },
      { pos: new v2(96, -567), extent: new v2(11, 20), heading: -0.02, ty: "half" },
      { pos: new v2(120, -668), extent: new v2(11, 20), heading: -0.29, ty: "half" },
      { pos: new v2(107, -929), extent: new v2(11, 20), heading: -0.41, ty: "half" },
      { pos: new v2(120, -996), extent: new v2(11, 20), heading: -0.29, ty: "half" },
      { pos: new v2(56, -1002), extent: new v2(11, 20), heading: 0.48, ty: "half" },
      { pos: new v2(-134, -707), extent: new v2(11, 20), heading: 0.27, ty: "half" },
      { pos: new v2(-102, -641), extent: new v2(11, 20), heading: 0.27, ty: "half" },
      { pos: new v2(-64, -625), extent: new v2(11, 20), heading: 1.31, ty: "half" },
      { pos: new v2(6, -661), extent: new v2(11, 20), heading: 0.95, ty: "full" },
      { pos: new v2(-156, -780), extent: new v2(11, 20), heading: 0.85, ty: "half" },
      { pos: new v2(-121, -319), extent: new v2(11, 20), heading: -0.29, ty: "half" },
      { pos: new v2(-96, -246), extent: new v2(11, 20), heading: 0.22, ty: "half" },
      { pos: new v2(126, -315), extent: new v2(11, 20), heading: 1.13, ty: "half" },
      { pos: new v2(81, -100), extent: new v2(11, 20), heading: 0.87, ty: "half" },
      { pos: new v2(107, 206), extent: new v2(11, 20), heading: -0.43, ty: "half" },
      { pos: new v2(-213, 321), extent: new v2(11, 20), heading: 0.43, ty: "half" },
      { pos: new v2(-179, 137), extent: new v2(11, 20), heading: 0.43, ty: "half" },
      { pos: new v2(-153, 77), extent: new v2(11, 20), heading: 0.74, ty: "half" },
      { pos: new v2(-209, 254), extent: new v2(11, 20), heading: 0.07, ty: "half" },
      { pos: new v2(-170, 333), extent: new v2(11, 20), heading: 0.2, ty: "half" },
      { pos: new v2(270, -418), extent: new v2(13, 11), heading: -0.24, ty: "half" },
      { pos: new v2(220, -473), extent: new v2(13, 11), heading: -0.11, ty: "half" },
      { pos: new v2(202, -517), extent: new v2(13, 11), heading: -0.11, ty: "half" },
      { pos: new v2(187, -882), extent: new v2(12, 15), heading: 0, ty: "full" },
      { pos: new v2(292, -884), extent: new v2(25, 11), heading: 0, ty: "full" },
      { pos: new v2(147, -1099), extent: new v2(21, 4), heading: 0.13, ty: "full" },
      { pos: new v2(134, -1171), extent: new v2(21, 4), heading: 0.13, ty: "full" },
      { pos: new v2(181, -1174), extent: new v2(12, 13), heading: 0.13, ty: "half" },
      { pos: new v2(194, -1007), extent: new v2(12, 13), heading: 0.13, ty: "half" },
      { pos: new v2(265, -941), extent: new v2(13, 12), heading: -0.24, ty: "half" },
      { pos: new v2(273, -989), extent: new v2(7, 26), heading: 0.39, ty: "half" },
      { pos: new v2(254, -1127), extent: new v2(7, 26), heading: 0.14, ty: "half" },
      { pos: new v2(255, -1029), extent: new v2(8, 8), heading: -0.6, ty: "half" },
      { pos: new v2(212, -966), extent: new v2(15, 4), heading: -0.12, ty: "half" },
      { pos: new v2(278, -841), extent: new v2(15, 18), heading: 0, ty: "half" },
      { pos: new v2(204, -839), extent: new v2(15, 18), heading: 0, ty: "half" },
      { pos: new v2(269, -556), extent: new v2(13, 11), heading: -0.47, ty: "half" },
      { pos: new v2(236, -547), extent: new v2(8, 8), heading: -0.47, ty: "half" },
      { pos: new v2(255, -535), extent: new v2(8, 8), heading: -0.95, ty: "half" },
      { pos: new v2(142, 855), extent: new v2(13, 30), heading: 0, ty: "full" },
      { pos: new v2(60, 854), extent: new v2(13, 30), heading: 0, ty: "full" },
      { pos: new v2(-23, 856), extent: new v2(13, 30), heading: 0, ty: "full" },
      { pos: new v2(298, 861), extent: new v2(88, 43), heading: 0, ty: "full" },
      { pos: new v2(114, 854), extent: new v2(224, 3), heading: 0, ty: "half" },
      { pos: new v2(-23, 889), extent: new v2(15, 6), heading: 0, ty: "full" },
      { pos: new v2(60, 890), extent: new v2(15, 6), heading: 0, ty: "full" },
      { pos: new v2(142, 890), extent: new v2(15, 6), heading: 0, ty: "full" },
      { pos: new v2(142, 821), extent: new v2(15, 6), heading: 0, ty: "full" },
      { pos: new v2(60, 820), extent: new v2(15, 6), heading: 0, ty: "full" },
      { pos: new v2(-23, 821), extent: new v2(15, 6), heading: 0, ty: "full" },
      { pos: new v2(35, 1060), extent: new v2(16, 46), heading: 0, ty: "full" },
      { pos: new v2(-47, 1139), extent: new v2(16, 46), heading: 0, ty: "full" },
      { pos: new v2(77, 1153), extent: new v2(16, 46), heading: -0.6, ty: "full" },
      { pos: new v2(121, 1015), extent: new v2(16, 46), heading: 0.01, ty: "full" },
      { pos: new v2(-48, 1045), extent: new v2(11, 20), heading: 0.01, ty: "half" },
      { pos: new v2(-51, 969), extent: new v2(11, 20), heading: 0.01, ty: "half" },
      { pos: new v2(26, 974), extent: new v2(11, 20), heading: 0.01, ty: "half" },
      { pos: new v2(114, 1174), extent: new v2(11, 20), heading: -0.2, ty: "half" },
      { pos: new v2(78, 1078), extent: new v2(11, 20), heading: 0.01, ty: "half" },
      { pos: new v2(22, 1167), extent: new v2(11, 20), heading: 0.14, ty: "half" },
      { pos: new v2(146, 1137), extent: new v2(11, 20), heading: -0.58, ty: "half" },
      { pos: new v2(44, 543), extent: new v2(11, 20), heading: 0.07, ty: "half" },
      { pos: new v2(92, 643), extent: new v2(11, 20), heading: 0.07, ty: "half" },
      { pos: new v2(183, 818), extent: new v2(11, 20), heading: 0.07, ty: "half" },
      { pos: new v2(172, 661), extent: new v2(11, 20), heading: 0.07, ty: "half" },
      { pos: new v2(135, 552), extent: new v2(11, 20), heading: 0.1, ty: "half" },
      { pos: new v2(53, 404), extent: new v2(11, 20), heading: 0.72, ty: "half" },
      { pos: new v2(91, 770), extent: new v2(16, 46), heading: -0.6, ty: "full" },
      { pos: new v2(260, 591), extent: new v2(16, 46), heading: 0.93, ty: "full" },
      { pos: new v2(25, 740), extent: new v2(11, 20), heading: 0.1, ty: "half" },
      { pos: new v2(22, 652), extent: new v2(11, 20), heading: 0.1, ty: "half" },
      { pos: new v2(-72, 778), extent: new v2(11, 20), heading: 0.75, ty: "half" },
      { pos: new v2(56, 705), extent: new v2(11, 20), heading: -0.38, ty: "half" },
      { pos: new v2(43, 602), extent: new v2(11, 20), heading: 0.1, ty: "half" },
      { pos: new v2(-52, 724), extent: new v2(11, 20), heading: -0.26, ty: "half" },
      { pos: new v2(-63, 830), extent: new v2(11, 20), heading: 0.1, ty: "half" },
      { pos: new v2(4, 801), extent: new v2(11, 20), heading: -0.26, ty: "half" },
      { pos: new v2(182, 744), extent: new v2(11, 20), heading: -0.3, ty: "half" },
      { pos: new v2(174, 704), extent: new v2(11, 20), heading: -0.78, ty: "half" },
      { pos: new v2(169, 607), extent: new v2(11, 20), heading: -0.38, ty: "half" },
      { pos: new v2(169, 512), extent: new v2(11, 20), heading: 0.32, ty: "half" },
      { pos: new v2(111, 434), extent: new v2(11, 20), heading: 1.25, ty: "half" },
      { pos: new v2(178, 455), extent: new v2(11, 20), heading: 0.4, ty: "half" },
      { pos: new v2(-131, 736), extent: new v2(11, 20), heading: -0.26, ty: "half" },
      { pos: new v2(-212, 752), extent: new v2(9, 40), heading: 0.01, ty: "half" },
      { pos: new v2(-141, 800), extent: new v2(11, 20), heading: -0.26, ty: "half" },
      { pos: new v2(-120, 817), extent: new v2(6, 3), heading: 0.13, ty: "half" },
      { pos: new v2(-112, 805), extent: new v2(4, 4), heading: -0.6, ty: "half" },
      { pos: new v2(-167, 815), extent: new v2(4, 4), heading: -0.6, ty: "half" },
      { pos: new v2(-162, 803), extent: new v2(4, 4), heading: -0.72, ty: "half" },
      { pos: new v2(-175, 806), extent: new v2(4, 4), heading: -0.96, ty: "half" },
      { pos: new v2(-258, 838), extent: new v2(4, 4), heading: -0.96, ty: "half" },
      { pos: new v2(-166, 743), extent: new v2(4, 4), heading: -1.92, ty: "half" },
      { pos: new v2(-167, 679), extent: new v2(4, 4), heading: -0.31, ty: "half" },
      { pos: new v2(-141, 665), extent: new v2(4, 4), heading: -1.44, ty: "half" },
      { pos: new v2(-164, 602), extent: new v2(4, 4), heading: -1.2, ty: "half" },
      { pos: new v2(-174, 628), extent: new v2(4, 4), heading: -0.31, ty: "half" },
      { pos: new v2(-195, 575), extent: new v2(4, 4), heading: -0.83, ty: "half" },
      { pos: new v2(-33, -231), extent: new v2(11, 20), heading: 0.48, ty: "half" },
      { pos: new v2(-75, 192), extent: new v2(11, 20), heading: -0.66, ty: "half" },
      { pos: new v2(-98, 103), extent: new v2(11, 20), heading: 0.52, ty: "half" },
      { pos: new v2(-121, 236), extent: new v2(11, 20), heading: 2.11, ty: "half" },
      { pos: new v2(-151, 272), extent: new v2(11, 20), heading: 1.07, ty: "half" },
      { pos: new v2(60, 241), extent: new v2(11, 20), heading: -2, ty: "half" },
      { pos: new v2(87, 303), extent: new v2(11, 20), heading: -2.93, ty: "half" },
      { pos: new v2(72, 355), extent: new v2(11, 20), heading: 0.24, ty: "half" },
      { pos: new v2(17, -1188), extent: new v2(16, 46), heading: 1.25, ty: "full" },
      { pos: new v2(-148, -1166), extent: new v2(11, 20), heading: 0.6, ty: "half" },
      { pos: new v2(90, -1130), extent: new v2(11, 20), heading: 0.5, ty: "half" },
      { pos: new v2(92, -1044), extent: new v2(11, 20), heading: -0.01, ty: "half" },
      { pos: new v2(205, -442), extent: new v2(20, 6), heading: -0.11, ty: "half" },
      { pos: new v2(275, -502), extent: new v2(20, 6), heading: -0.11, ty: "half" },
      { pos: new v2(42, -1101), extent: new v2(5, 17), heading: 0.39, ty: "half" },
      { pos: new v2(68, -1086), extent: new v2(6, 5), heading: -0.24, ty: "half" },
      { pos: new v2(105, 17), extent: new v2(16, 46), heading: -0.17, ty: "full" },
      { pos: new v2(-64, 84), extent: new v2(11, 20), heading: -1.26, ty: "half" },
      { pos: new v2(-52, -912), extent: new v2(11, 20), heading: 0.1, ty: "half" },
      { pos: new v2(-94, -779), extent: new v2(11, 20), heading: -0.14, ty: "half" },
      { pos: new v2(14, 57), extent: new v2(11, 20), heading: -0.5, ty: "half" },
      { pos: new v2(92, -1178), extent: new v2(9, 15), heading: 0.39, ty: "half" },
      { pos: new v2(-6, -1154), extent: new v2(11, 20), heading: 1.12, ty: "half" },
      { pos: new v2(44, -1054), extent: new v2(11, 20), heading: 0.74, ty: "half" },
      { pos: new v2(-168, -824), extent: new v2(11, 20), heading: 0.4, ty: "half" },
      { pos: new v2(-16, -761), extent: new v2(11, 20), heading: 0.36, ty: "half" },
      { pos: new v2(107, -511), extent: new v2(11, 20), heading: -0.77, ty: "half" },
    ],
    goals: [
      { name: 'Hostile R-Point', area: 10 },
    ],

    mission_rules: [
      { ty: 'explore' },
    ],

    editor: true,
  };
}

export function preset_shiping_dock() {
  const world = { width: 700, height: 500, simover_rule: 'eliminate' };

  const team0_smg = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_smg_low),
    ...gear_vest_bulletproof_highend,
    spawnarea: 0,
  };

  // 적 에이전트 스펙 정의
  const team1_tmpl_ar = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_ar_low),
    default_rule: { ty: 'explore', alert: false },
    team: 1,
    spawnarea: 2,
  };

  return {
    world,

    entities: [
      ...new Array(4).fill(team0_smg),

      { ...team1_tmpl_ar, spawnarea: 1 },
      { ...team1_tmpl_ar, spawnarea: 1 },
      { ...team1_tmpl_ar, spawnarea: 1 },

      { ...team1_tmpl_ar, spawnarea: 2 },
      { ...team1_tmpl_ar, spawnarea: 2 },
      { ...team1_tmpl_ar, spawnarea: 2 },

      { ...team1_tmpl_ar, spawnarea: 3 },
      { ...team1_tmpl_ar, spawnarea: 4 },
    ],

    spawnareas: [
      { pos: new v2(-309, -209), extent: new v2(30, 27), heading: 0, spawn: true, },
      { pos: new v2(274, -184), extent: new v2(36, 27), heading: 0, },
      { pos: new v2(305, 133), extent: new v2(35, 26), heading: 0, },
      { pos: new v2(77, 187), extent: new v2(29, 21), heading: 0, },
      { pos: new v2(-22, -68), extent: new v2(14, 16), heading: 0, },
    ],
    obstacle_specs: [
      { pos: new v2(-284, -28), extent: new v2(18, 42), heading: -0.12, ty: "full" },
      { pos: new v2(-329, -28), extent: new v2(18, 42), heading: 0.14, ty: "full" },
      { pos: new v2(-237, 1), extent: new v2(11, 11), heading: -0.24, ty: "half" },
      { pos: new v2(-239, -26), extent: new v2(11, 11), heading: -0.12, ty: "half" },
      { pos: new v2(-242, -53), extent: new v2(8, 7), heading: 0.52, ty: "half" },
      { pos: new v2(-221, -46), extent: new v2(4, 5), heading: -0.84, ty: "half" },
      { pos: new v2(-216, -78), extent: new v2(18, 15), heading: 0.52, ty: "half" },
      { pos: new v2(-252, -76), extent: new v2(8, 6), heading: -0.48, ty: "half" },
      { pos: new v2(-266, 121), extent: new v2(9, 11), heading: -0.11, ty: "half" },
      { pos: new v2(-240, 119), extent: new v2(9, 11), heading: 0.22, ty: "half" },
      { pos: new v2(-240, 146), extent: new v2(9, 11), heading: 0.27, ty: "half" },
      { pos: new v2(-266, 148), extent: new v2(9, 11), heading: 0.14, ty: "half" },
      { pos: new v2(-229, -177), extent: new v2(11, 14), heading: 0.13, ty: "full" },
      { pos: new v2(-159, -146), extent: new v2(5, 19), heading: -0.12, ty: "half" },
      { pos: new v2(-210, -134), extent: new v2(6, 18), heading: -0.48, ty: "half" },
      { pos: new v2(-27, -234), extent: new v2(59, 19), heading: 0, ty: "full" },
      { pos: new v2(-45, -173), extent: new v2(59, 19), heading: 0.78, ty: "full" },
      { pos: new v2(-19, 233), extent: new v2(59, 19), heading: 0, ty: "full" },
      { pos: new v2(-35, 197), extent: new v2(59, 19), heading: 0, ty: "full" },
      { pos: new v2(-78, 67), extent: new v2(20, 45), heading: -0.12, ty: "full" },
      { pos: new v2(-122, 65), extent: new v2(20, 45), heading: 0, ty: "full" },
      { pos: new v2(-123, -61), extent: new v2(20, 45), heading: 0, ty: "full" },
      { pos: new v2(-74, -61), extent: new v2(20, 45), heading: 0.27, ty: "full" },
      { pos: new v2(32, -65), extent: new v2(20, 45), heading: 0.01, ty: "full" },
      { pos: new v2(82, -67), extent: new v2(20, 45), heading: 0.02, ty: "full" },
      { pos: new v2(35, 67), extent: new v2(20, 45), heading: -0.09, ty: "full" },
      { pos: new v2(79, 69), extent: new v2(20, 45), heading: 0.01, ty: "full" },
      { pos: new v2(148, -57), extent: new v2(20, 45), heading: -0.17, ty: "full" },
      { pos: new v2(147, 72), extent: new v2(20, 45), heading: 0.02, ty: "full" },
      { pos: new v2(176, 231), extent: new v2(59, 19), heading: 0, ty: "full" },
      { pos: new v2(329, -26), extent: new v2(18, 42), heading: 0.14, ty: "full" },
      { pos: new v2(289, -23), extent: new v2(18, 42), heading: 0.02, ty: "full" },
      { pos: new v2(296, -240), extent: new v2(18, 42), heading: 1.58, ty: "full" },
      { pos: new v2(241, 114), extent: new v2(8, 12), heading: -0.45, ty: "half" },
      { pos: new v2(246, 159), extent: new v2(8, 12), heading: -0.12, ty: "half" },
      { pos: new v2(-155, -16), extent: new v2(7, 7), heading: -0.12, ty: "half" },
      { pos: new v2(-155, 20), extent: new v2(7, 7), heading: -0.12, ty: "half" },
      { pos: new v2(-135, -4), extent: new v2(11, 4), heading: -0.48, ty: "half" },
      { pos: new v2(-166, 36), extent: new v2(6, 7), heading: -0.6, ty: "half" },
      { pos: new v2(-205, -168), extent: new v2(6, 7), heading: -0.12, ty: "half" },
      { pos: new v2(-230, -209), extent: new v2(6, 8), heading: 0.26, ty: "half" },
      { pos: new v2(-244, -199), extent: new v2(6, 4), heading: -0.12, ty: "half" },
      { pos: new v2(-292, -129), extent: new v2(9, 4), heading: -0.48, ty: "half" },
      { pos: new v2(-276, -137), extent: new v2(5, 5), heading: -0.12, ty: "half" },
      { pos: new v2(-296, -156), extent: new v2(5, 5), heading: 1.18, ty: "half" },
      { pos: new v2(1, -26), extent: new v2(5, 5), heading: -0.12, ty: "half" },
      { pos: new v2(9, 23), extent: new v2(5, 5), heading: 0.14, ty: "half" },
      { pos: new v2(105, 11), extent: new v2(8, 2), heading: -0.6, ty: "half" },
      { pos: new v2(170, -6), extent: new v2(4, 11), heading: -0.24, ty: "half" },
      { pos: new v2(184, -25), extent: new v2(9, 7), heading: -0.36, ty: "half" },
      { pos: new v2(174, 27), extent: new v2(5, 6), heading: 0.4, ty: "half" },
      { pos: new v2(177, 117), extent: new v2(5, 6), heading: -0.32, ty: "half" },
      { pos: new v2(186, 104), extent: new v2(5, 4), heading: 0, ty: "half" },
      { pos: new v2(187, -100), extent: new v2(7, 7), heading: 0.13, ty: "half" },
      { pos: new v2(177, -115), extent: new v2(4, 4), heading: 0.39, ty: "half" },
      { pos: new v2(100, -166), extent: new v2(7, 8), heading: 0.4, ty: "half" },
      { pos: new v2(187, -190), extent: new v2(17, 16), heading: -0.12, ty: "half" },
      { pos: new v2(323, -147), extent: new v2(8, 12), heading: 0, ty: "half" },
      { pos: new v2(294, -139), extent: new v2(14, 10), heading: 0.13, ty: "half" },
      { pos: new v2(299, 71), extent: new v2(39, 10), heading: 0, ty: "half" },
      { pos: new v2(-296, 235), extent: new v2(59, 19), heading: 0, ty: "full" },
      { pos: new v2(-302, 201), extent: new v2(59, 19), heading: 0, ty: "full" },
      { pos: new v2(-277, 91), extent: new v2(26, 5), heading: -0.6, ty: "half" },
      { pos: new v2(-166, -196), extent: new v2(8, 6), heading: -0.72, ty: "half" },
      { pos: new v2(-16, -130), extent: new v2(59, 19), heading: 0.31, ty: "full" },
      { pos: new v2(47, -163), extent: new v2(9, 16), heading: -0.1, ty: "full" },
      { pos: new v2(64, -189), extent: new v2(8, 8), heading: 0.39, ty: "full" },
      { pos: new v2(178, 166), extent: new v2(59, 19), heading: -0.84, ty: "full" },
      { pos: new v2(116, 134), extent: new v2(8, 5), heading: 0.13, ty: "full" },
      { pos: new v2(195, 139), extent: new v2(9, 5), heading: -0.84, ty: "full" },
      { pos: new v2(125, 113), extent: new v2(11, 7), heading: -0.24, ty: "full" },
      { pos: new v2(-10, 122), extent: new v2(11, 4), heading: -0.36, ty: "half" },
      { pos: new v2(-26, 74), extent: new v2(3, 9), heading: 0.26, ty: "half" },
      { pos: new v2(-193, 46), extent: new v2(10, 3), heading: -0.08, ty: "half" },
      { pos: new v2(-100, 10), extent: new v2(6, 4), heading: -1.2, ty: "half" },
      { pos: new v2(-26, -2), extent: new v2(7, 7), heading: 0.01, ty: "half" },
      { pos: new v2(-37, 15), extent: new v2(7, 7), heading: -0.84, ty: "half" },
      { pos: new v2(-156, 113), extent: new v2(7, 7), heading: -0.12, ty: "full" },
      { pos: new v2(-155, 173), extent: new v2(5, 8), heading: 0, ty: "full" },
      { pos: new v2(-206, 186), extent: new v2(27, 10), heading: -0.36, ty: "half" },
      { pos: new v2(-91, -11), extent: new v2(4, 5), heading: 0.39, ty: "half" },
      { pos: new v2(-172, 146), extent: new v2(25, 4), heading: 1.04, ty: "half" },
    ],
    goals: [],

    mission_rules: [
      { ty: 'explore' },
    ],

    editor: true,
  };
}



export function preset_border_checkpoint_short() {
  const world = { width: 600, height: 1900, simover_rule: 'eliminate' };

  const team0_ar = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_ar_low),
    ...gear_vest_bulletproof_highend,
    spawnarea: 0,
  };

  const team0_dmr = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_dmr_low),
    ...gear_vest_bulletproof_highend,
    spawnarea: 0,
  };

  // 적 에이전트 스펙 정의
  const team1_tmpl_ar = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_ar_low),
    default_rule: { ty: 'idle', alert: false },
    team: 1,
    spawnarea: 2,
  };

  return {
    world,

    entities: [
      ...new Array(2).fill(team0_ar),
      ...new Array(2).fill(team0_dmr),

      { ...team1_tmpl_ar, spawnarea: 1 },
      { ...team1_tmpl_ar, spawnarea: 1 },

      { ...team1_tmpl_ar, spawnarea: 2 },
      { ...team1_tmpl_ar, spawnarea: 2 },

      { ...team1_tmpl_ar, spawnarea: 3 },
      { ...team1_tmpl_ar, spawnarea: 3 },

      { ...team1_tmpl_ar, spawnarea: 4 },
      { ...team1_tmpl_ar, spawnarea: 4 },

      { ...team1_tmpl_ar, spawnarea: 5 },
      { ...team1_tmpl_ar, spawnarea: 5 },

      { ...team1_tmpl_ar, spawnarea: 6 },
      { ...team1_tmpl_ar, spawnarea: 6 },

    ],

    spawnareas: [
      { pos: new v2(102, 922), extent: new v2(98, 18), heading: 0, spawn: true, },
      { pos: new v2(-267, 632), extent: new v2(23, 43), heading: 0, spawn1: true, },
      { pos: new v2(-41, 379), extent: new v2(94, 36), heading: 0, spawn1: true, },
      { pos: new v2(1, 75), extent: new v2(76, 28), heading: -0.1, spawn1: true, },
      { pos: new v2(-35, -185), extent: new v2(42, 66), heading: -0.24, spawn1: true, },
      { pos: new v2(23, -454), extent: new v2(29, 51), heading: 1.51, spawn1: true, },
      { pos: new v2(-69, -576), extent: new v2(57, 26), heading: -0.04, spawn1: true, },
    ],
    obstacle_specs: [
      { pos: new v2(-214, 917), extent: new v2(8, 86), heading: 0, ty: "full" },
      { pos: new v2(-125, 828), extent: new v2(22, 7), heading: 0, ty: "full" },
      { pos: new v2(-111, 852), extent: new v2(7, 17), heading: 0, ty: "full" },
      { pos: new v2(-354, 430), extent: new v2(63, 238), heading: -0.24, ty: "full" },
      { pos: new v2(-286, 87), extent: new v2(75, 126), heading: -0.12, ty: "full" },
      { pos: new v2(291, 229), extent: new v2(156, 175), heading: -0.05, ty: "full" },
      { pos: new v2(251, -151), extent: new v2(101, 221), heading: -0.06, ty: "full" },
      { pos: new v2(-342, -195), extent: new v2(135, 229), heading: -0.12, ty: "full" },
      { pos: new v2(-89, -403), extent: new v2(55, 19), heading: 0.46, ty: "full" },
      { pos: new v2(-160, -389), extent: new v2(26, 17), heading: 0.01, ty: "full" },
      { pos: new v2(-55, -352), extent: new v2(61, 8), heading: -0.34, ty: "half" },
      { pos: new v2(-3, -406), extent: new v2(43, 11), heading: -0.06, ty: "half" },
      { pos: new v2(52, -368), extent: new v2(29, 41), heading: 0, ty: "full" },
      { pos: new v2(-278, -562), extent: new v2(38, 180), heading: 0, ty: "full" },
      { pos: new v2(-267, 733), extent: new v2(7, 13), heading: 0, ty: "half" },
      { pos: new v2(-216, 639), extent: new v2(9, 40), heading: 0.01, ty: "half" },
      { pos: new v2(-268, 511), extent: new v2(51, 3), heading: 0, ty: "full" },
      { pos: new v2(-287, 568), extent: new v2(39, 4), heading: 0, ty: "full" },
      { pos: new v2(-294, 485), extent: new v2(19, 27), heading: 0, ty: "full" },
      { pos: new v2(-303, 444), extent: new v2(20, 45), heading: 0, ty: "full" },
      { pos: new v2(-223, 557), extent: new v2(4, 16), heading: 0, ty: "full" },
      { pos: new v2(-223, 528), extent: new v2(3, 8), heading: 0, ty: "half" },
      { pos: new v2(-290, 532), extent: new v2(7, 6), heading: 0, ty: "half" },
      { pos: new v2(-274, 865), extent: new v2(10, 16), heading: 0.13, ty: "half" },
      { pos: new v2(-241, 812), extent: new v2(4, 15), heading: -0.47, ty: "half" },
      { pos: new v2(-265, 757), extent: new v2(19, 4), heading: 0, ty: "half" },
      { pos: new v2(-271, 708), extent: new v2(11, 3), heading: 0, ty: "half" },
      { pos: new v2(-183, 531), extent: new v2(8, 18), heading: 0, ty: "half" },
      { pos: new v2(-88, 640), extent: new v2(16, 46), heading: 0, ty: "full" },
      { pos: new v2(-9, 532), extent: new v2(16, 46), heading: -0.12, ty: "full" },
      { pos: new v2(-96, 529), extent: new v2(16, 46), heading: 0.66, ty: "full" },
      { pos: new v2(67, 462), extent: new v2(11, 20), heading: 0, ty: "half" },
      { pos: new v2(-61, 464), extent: new v2(11, 20), heading: 0.27, ty: "half" },
      { pos: new v2(-94, 315), extent: new v2(11, 20), heading: -0.17, ty: "half" },
      { pos: new v2(1, 456), extent: new v2(11, 20), heading: -0.92, ty: "half" },
      { pos: new v2(61, 238), extent: new v2(11, 20), heading: -0.79, ty: "half" },
      { pos: new v2(-215, 447), extent: new v2(16, 46), heading: -0.55, ty: "full" },
      { pos: new v2(-123, 449), extent: new v2(11, 20), heading: 0.43, ty: "half" },
      { pos: new v2(-33, 311), extent: new v2(11, 20), heading: -0.66, ty: "half" },
      { pos: new v2(-184, 202), extent: new v2(16, 46), heading: 1.12, ty: "full" },
      { pos: new v2(-114, 172), extent: new v2(16, 46), heading: -0.9, ty: "full" },
      { pos: new v2(43, -161), extent: new v2(16, 46), heading: -0.17, ty: "full" },
      { pos: new v2(-69, -94), extent: new v2(11, 20), heading: 0.08, ty: "half" },
      { pos: new v2(75, -30), extent: new v2(16, 46), heading: 0.22, ty: "full" },
      { pos: new v2(-117, -98), extent: new v2(16, 46), heading: 0.02, ty: "full" },
      { pos: new v2(70, -269), extent: new v2(11, 20), heading: -0.29, ty: "half" },
      { pos: new v2(-129, -207), extent: new v2(11, 20), heading: -0.04, ty: "half" },
      { pos: new v2(-180, -204), extent: new v2(16, 46), heading: -0.14, ty: "full" },
      { pos: new v2(-173, -118), extent: new v2(11, 20), heading: 0.22, ty: "half" },
      { pos: new v2(-35, 128), extent: new v2(11, 20), heading: 1.69, ty: "half" },
      { pos: new v2(-73, 8), extent: new v2(11, 20), heading: 0.74, ty: "half" },
      { pos: new v2(-173, -30), extent: new v2(11, 20), heading: 0.74, ty: "half" },
      { pos: new v2(-72, -633), extent: new v2(11, 20), heading: -0.06, ty: "half" },
      { pos: new v2(28, -518), extent: new v2(11, 20), heading: 0.22, ty: "half" },
      { pos: new v2(104, -542), extent: new v2(11, 20), heading: -0.26, ty: "half" },
      { pos: new v2(25, -603), extent: new v2(11, 20), heading: -0.02, ty: "half" },
      { pos: new v2(-93, -521), extent: new v2(11, 20), heading: -0.57, ty: "half" },
      { pos: new v2(-2, -696), extent: new v2(16, 265), heading: 1.53, ty: "full" },
      { pos: new v2(-159, -599), extent: new v2(11, 20), heading: 0.02, ty: "half" },
      { pos: new v2(-189, -496), extent: new v2(11, 20), heading: -2.88, ty: "half" },
      { pos: new v2(157, -613), extent: new v2(11, 20), heading: -0.02, ty: "half" },
      { pos: new v2(-94, -287), extent: new v2(11, 20), heading: -0.29, ty: "half" },
      { pos: new v2(123, -353), extent: new v2(11, 20), heading: 1.38, ty: "half" },
      { pos: new v2(101, 128), extent: new v2(11, 20), heading: -0.43, ty: "half" },
      { pos: new v2(-209, 331), extent: new v2(11, 20), heading: 0.43, ty: "half" },
      { pos: new v2(-179, 137), extent: new v2(11, 20), heading: 0.43, ty: "half" },
      { pos: new v2(-209, 254), extent: new v2(11, 20), heading: 0.07, ty: "half" },
      { pos: new v2(270, -418), extent: new v2(13, 11), heading: -0.24, ty: "half" },
      { pos: new v2(202, -517), extent: new v2(13, 11), heading: -0.11, ty: "half" },
      { pos: new v2(269, -556), extent: new v2(13, 11), heading: -0.47, ty: "half" },
      { pos: new v2(142, 855), extent: new v2(13, 30), heading: 0, ty: "full" },
      { pos: new v2(60, 854), extent: new v2(13, 30), heading: 0, ty: "full" },
      { pos: new v2(-23, 856), extent: new v2(13, 30), heading: 0, ty: "full" },
      { pos: new v2(298, 861), extent: new v2(88, 43), heading: 0, ty: "full" },
      { pos: new v2(114, 854), extent: new v2(224, 3), heading: 0, ty: "half" },
      { pos: new v2(-23, 889), extent: new v2(15, 6), heading: 0, ty: "full" },
      { pos: new v2(60, 890), extent: new v2(15, 6), heading: 0, ty: "full" },
      { pos: new v2(142, 890), extent: new v2(15, 6), heading: 0, ty: "full" },
      { pos: new v2(142, 821), extent: new v2(15, 6), heading: 0, ty: "full" },
      { pos: new v2(60, 820), extent: new v2(15, 6), heading: 0, ty: "full" },
      { pos: new v2(-23, 821), extent: new v2(15, 6), heading: 0, ty: "full" },
      { pos: new v2(25, -71), extent: new v2(11, 20), heading: -0.01, ty: "half" },
      { pos: new v2(105, 742), extent: new v2(11, 20), heading: -0.42, ty: "half" },
      { pos: new v2(183, 818), extent: new v2(11, 20), heading: 0.07, ty: "half" },
      { pos: new v2(173, 637), extent: new v2(11, 20), heading: 0.07, ty: "half" },
      { pos: new v2(119, 540), extent: new v2(11, 20), heading: -0.26, ty: "half" },
      { pos: new v2(92, 412), extent: new v2(11, 20), heading: 0.72, ty: "half" },
      { pos: new v2(106, 630), extent: new v2(16, 46), heading: -0.6, ty: "full" },
      { pos: new v2(218, 586), extent: new v2(16, 46), heading: 0.93, ty: "full" },
      { pos: new v2(42, 729), extent: new v2(11, 20), heading: 0.1, ty: "half" },
      { pos: new v2(8, 640), extent: new v2(11, 20), heading: 0.1, ty: "half" },
      { pos: new v2(-52, 724), extent: new v2(11, 20), heading: -0.26, ty: "half" },
      { pos: new v2(-66, 820), extent: new v2(11, 20), heading: 0.1, ty: "half" },
      { pos: new v2(6, 790), extent: new v2(11, 20), heading: -0.26, ty: "half" },
      { pos: new v2(177, 723), extent: new v2(11, 20), heading: -0.78, ty: "half" },
      { pos: new v2(34, 144), extent: new v2(11, 20), heading: 1.25, ty: "half" },
      { pos: new v2(203, 472), extent: new v2(11, 20), heading: 0.4, ty: "half" },
      { pos: new v2(-124, 747), extent: new v2(11, 20), heading: -0.26, ty: "half" },
      { pos: new v2(-212, 752), extent: new v2(9, 40), heading: 0.01, ty: "half" },
      { pos: new v2(-171, 893), extent: new v2(12, 20), heading: 1.12, ty: "half" },
      { pos: new v2(-166, 743), extent: new v2(4, 4), heading: -1.92, ty: "half" },
      { pos: new v2(-167, 679), extent: new v2(4, 4), heading: -0.31, ty: "half" },
      { pos: new v2(-141, 665), extent: new v2(4, 4), heading: -1.44, ty: "half" },
      { pos: new v2(-164, 602), extent: new v2(4, 4), heading: -1.2, ty: "half" },
      { pos: new v2(-174, 628), extent: new v2(4, 4), heading: -0.31, ty: "half" },
      { pos: new v2(-195, 575), extent: new v2(4, 4), heading: -0.83, ty: "half" },
      { pos: new v2(-24, -69), extent: new v2(11, 20), heading: 0.48, ty: "half" },
      { pos: new v2(-6, 195), extent: new v2(11, 20), heading: -0.66, ty: "half" },
      { pos: new v2(-13, -272), extent: new v2(11, 20), heading: 1.07, ty: "half" },
      { pos: new v2(37, 309), extent: new v2(11, 20), heading: -2.65, ty: "half" },
      { pos: new v2(225, -456), extent: new v2(20, 6), heading: -0.11, ty: "half" },
      { pos: new v2(139, -247), extent: new v2(16, 46), heading: -0.17, ty: "full" },
      { pos: new v2(-135, 71), extent: new v2(11, 20), heading: -1.26, ty: "half" },
      { pos: new v2(11, 3), extent: new v2(11, 20), heading: -0.5, ty: "half" },
      { pos: new v2(98, -436), extent: new v2(11, 20), heading: 0.27, ty: "half" },
    ],
    goals: [
    ],

    mission_rules: [
      { ty: 'explore' },
    ],

    editor: true,
  };
}


export function preset_storehouse_short() {
  const world = { width: 1000, height: 600, simover_rule: 'eliminate' };

  const team0_smg = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_smg_low),
    ...gear_vest_bulletproof_highend,
    spawnarea: 0,
  };

  // 적 에이전트 스펙 정의
  const team1_tmpl_ar = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_ar_low),
    default_rule: { ty: 'idle', alert: false },
    team: 1,
    spawnarea: 2,
  };

  return {
    world,

    entities: [
      ...new Array(5).fill(team0_smg),

      { ...team1_tmpl_ar, spawnarea: 1 },
      { ...team1_tmpl_ar, spawnarea: 1 },

      { ...team1_tmpl_ar, spawnarea: 2 },
      { ...team1_tmpl_ar, spawnarea: 2 },

      { ...team1_tmpl_ar, spawnarea: 3 },
      { ...team1_tmpl_ar, spawnarea: 3 },

      { ...team1_tmpl_ar, spawnarea: 4 },
      { ...team1_tmpl_ar, spawnarea: 4 },

      { ...team1_tmpl_ar, spawnarea: 5 },
      { ...team1_tmpl_ar, spawnarea: 5 },

      { ...team1_tmpl_ar, spawnarea: 6 },
      { ...team1_tmpl_ar, spawnarea: 6 },
    ],


    spawnareas: [
      { pos: new v2(-450, 169), extent: new v2(29, 25), heading: 0, spawn: true, },
      { pos: new v2(459, 177), extent: new v2(39, 45), heading: 0, spawn1: true },
      { pos: new v2(204, -76), extent: new v2(33, 68), heading: 0, spawn1: true },
      { pos: new v2(204, 199), extent: new v2(53, 33), heading: 0, spawn1: true },
      {
        pos: new v2(-173, -108), extent: new v2(204, 143), heading: 0, spawn1: true,
        structureopts: { count: 1 },
      },
      { pos: new v2(-98, 230), extent: new v2(56, 47), heading: 0, spawn1: true },
      { pos: new v2(423, -154), extent: new v2(50, 44), heading: 0, spawn1: true },
    ],
    obstacle_specs: [
      { pos: new v2(-90, 105), extent: new v2(326, 15), heading: 0, ty: "full" },
      { pos: new v2(277, 105), extent: new v2(47, 15), heading: 0, ty: "full" },
      { pos: new v2(334, 70), extent: new v2(15, 51), heading: 0, ty: "full" },
      { pos: new v2(277, -253), extent: new v2(47, 15), heading: 0, ty: "full" },
      { pos: new v2(334, -217), extent: new v2(15, 51), heading: 0, ty: "full" },
      { pos: new v2(-461, 104), extent: new v2(48, 14), heading: 0, ty: "full" },
      { pos: new v2(-213, 145), extent: new v2(7, 30), heading: 0, ty: "full" },
      { pos: new v2(-213, 285), extent: new v2(6, 25), heading: 0, ty: "full" },
      { pos: new v2(-177, 254), extent: new v2(10, 10), heading: -0.24, ty: "half" },
      { pos: new v2(362, 193), extent: new v2(4, 25), heading: 0.13, ty: "half" },
      { pos: new v2(376, 251), extent: new v2(12, 10), heading: -0.24, ty: "half" },
      { pos: new v2(-87, 161), extent: new v2(5, 17), heading: -0.12, ty: "half" },
      { pos: new v2(40, 204), extent: new v2(5, 11), heading: -0.24, ty: "half" },
      { pos: new v2(295, 244), extent: new v2(4, 11), heading: 0.13, ty: "half" },
      { pos: new v2(272, 250), extent: new v2(7, 8), heading: -0.12, ty: "half" },
      { pos: new v2(86, 153), extent: new v2(8, 16), heading: -0.88, ty: "half" },
      { pos: new v2(328, 221), extent: new v2(5, 13), heading: 0, ty: "half" },
      { pos: new v2(327, 275), extent: new v2(6, 24), heading: 0, ty: "full" },
      { pos: new v2(282, 201), extent: new v2(6, 5), heading: -0.12, ty: "half" },
      { pos: new v2(404, 257), extent: new v2(11, 10), heading: -0.24, ty: "half" },
      { pos: new v2(428, 264), extent: new v2(8, 10), heading: -0.23, ty: "half" },
      { pos: new v2(-163, 159), extent: new v2(20, 9), heading: -0.12, ty: "half" },
      { pos: new v2(20, 142), extent: new v2(20, 9), heading: -0.12, ty: "half" },
      { pos: new v2(15, 250), extent: new v2(20, 9), heading: 0.13, ty: "half" },
      { pos: new v2(179, 68), extent: new v2(15, 14), heading: -0.24, ty: "full" },
      { pos: new v2(185, -214), extent: new v2(15, 14), heading: -0.12, ty: "full" },
      { pos: new v2(356, 143), extent: new v2(4, 5), heading: -0.12, ty: "half" },
      { pos: new v2(81, 282), extent: new v2(17, 23), heading: 0, ty: "full" },
      { pos: new v2(125, 282), extent: new v2(17, 23), heading: 0, ty: "full" },
      { pos: new v2(81, 229), extent: new v2(17, 23), heading: 0, ty: "full" },
      { pos: new v2(125, 229), extent: new v2(17, 23), heading: 0, ty: "full" },
      { pos: new v2(141, 143), extent: new v2(13, 13), heading: 0.13, ty: "half" },
      { pos: new v2(175, 143), extent: new v2(13, 13), heading: 0.13, ty: "half" },
      { pos: new v2(209, 142), extent: new v2(13, 13), heading: 0.01, ty: "half" },
      { pos: new v2(173, 269), extent: new v2(19, 26), heading: 0.13, ty: "full" },
      { pos: new v2(233, 268), extent: new v2(19, 26), heading: -0.12, ty: "full" },
      { pos: new v2(268, 151), extent: new v2(8, 21), heading: 0.13, ty: "full" },
      { pos: new v2(301, 152), extent: new v2(8, 21), heading: 0, ty: "full" },
      { pos: new v2(228, -215), extent: new v2(15, 14), heading: -0.12, ty: "full" },
      { pos: new v2(227, -177), extent: new v2(15, 14), heading: -0.12, ty: "full" },
      { pos: new v2(269, -177), extent: new v2(15, 14), heading: 0.13, ty: "full" },
      { pos: new v2(270, -215), extent: new v2(15, 14), heading: 0.13, ty: "full" },
      { pos: new v2(185, -176), extent: new v2(15, 14), heading: 0.13, ty: "full" },
      { pos: new v2(224, 67), extent: new v2(15, 14), heading: 0.13, ty: "full" },
      { pos: new v2(270, 67), extent: new v2(15, 14), heading: -0.12, ty: "full" },
      { pos: new v2(269, 29), extent: new v2(15, 14), heading: 0.13, ty: "full" },
      { pos: new v2(224, 27), extent: new v2(15, 14), heading: -0.12, ty: "full" },
      { pos: new v2(183, 29), extent: new v2(15, 14), heading: -0.11, ty: "full" },
      { pos: new v2(402, -249), extent: new v2(33, 35), heading: 0.01, ty: "full" },
      { pos: new v2(464, -239), extent: new v2(16, 30), heading: -0.12, ty: "full" },
      { pos: new v2(-227, 192), extent: new v2(6, 13), heading: 0.13, ty: "half" },
      { pos: new v2(369, 274), extent: new v2(12, 7), heading: -0.24, ty: "half" },
      { pos: new v2(407, 283), extent: new v2(18, 7), heading: -0.24, ty: "half" },
      { pos: new v2(374, 143), extent: new v2(4, 5), heading: -0.12, ty: "half" },
      { pos: new v2(389, 152), extent: new v2(4, 5), heading: -0.12, ty: "half" },
      { pos: new v2(408, 160), extent: new v2(4, 5), heading: -0.12, ty: "half" },
      { pos: new v2(385, 123), extent: new v2(4, 5), heading: -0.12, ty: "half" },
      { pos: new v2(409, 135), extent: new v2(4, 5), heading: -0.12, ty: "half" },
      { pos: new v2(465, 78), extent: new v2(18, 21), heading: 0.14, ty: "half" },
      { pos: new v2(462, -47), extent: new v2(18, 21), heading: -0.24, ty: "half" },
      { pos: new v2(454, 246), extent: new v2(9, 12), heading: -0.24, ty: "half" },
      { pos: new v2(477, 246), extent: new v2(9, 12), heading: -0.12, ty: "half" },
      { pos: new v2(260, -140), extent: new v2(4, 5), heading: -0.12, ty: "half" },
      { pos: new v2(282, -140), extent: new v2(4, 5), heading: -0.12, ty: "half" },
      { pos: new v2(271, -118), extent: new v2(4, 12), heading: 0, ty: "half" },
      { pos: new v2(301, -135), extent: new v2(4, 12), heading: 0, ty: "half" },
      { pos: new v2(287, -116), extent: new v2(4, 5), heading: -0.12, ty: "half" },
      { pos: new v2(283, -20), extent: new v2(4, 12), heading: 0, ty: "half" },
      { pos: new v2(366, -21), extent: new v2(4, 12), heading: -0.71, ty: "half" },
      { pos: new v2(293, -51), extent: new v2(4, 5), heading: -0.12, ty: "half" },
      { pos: new v2(306, -17), extent: new v2(4, 5), heading: -0.12, ty: "half" },
      { pos: new v2(269, -42), extent: new v2(4, 5), heading: -0.12, ty: "half" },
      { pos: new v2(302, -34), extent: new v2(4, 5), heading: -0.12, ty: "half" },
      { pos: new v2(262, -11), extent: new v2(4, 12), heading: 0, ty: "half" },
      { pos: new v2(327, 145), extent: new v2(7, 40), heading: 0, ty: "half" },
      { pos: new v2(484, -89), extent: new v2(11, 4), heading: 0.65, ty: "half" },
      { pos: new v2(406, 60), extent: new v2(11, 18), heading: -0.24, ty: "half" },
      { pos: new v2(408, -78), extent: new v2(9, 12), heading: -0.24, ty: "half" },
      { pos: new v2(333, 19), extent: new v2(6, 60), heading: 0, ty: "full" },
      { pos: new v2(333, -45), extent: new v2(8, 5), heading: 0, ty: "full" },
      { pos: new v2(356, -78), extent: new v2(6, 13), heading: -1.32, ty: "half" },
      { pos: new v2(372, 26), extent: new v2(37, 7), heading: 0, ty: "full" },
      { pos: new v2(364, -45), extent: new v2(4, 12), heading: -0.71, ty: "half" },
      { pos: new v2(363, 6), extent: new v2(4, 12), heading: -0.83, ty: "half" },
      { pos: new v2(394, -41), extent: new v2(6, 7), heading: 0, ty: "half" },
      { pos: new v2(435, 84), extent: new v2(5, 4), heading: -0.48, ty: "half" },
      { pos: new v2(415, -50), extent: new v2(4, 3), heading: 0, ty: "half" },
      { pos: new v2(249, -279), extent: new v2(19, 24), heading: 0, ty: "full" },
      { pos: new v2(-256, 259), extent: new v2(16, 27), heading: 0, ty: "half" },
      { pos: new v2(-302, 171), extent: new v2(16, 27), heading: -0.22, ty: "half" },
      { pos: new v2(-38, 157), extent: new v2(8, 16), heading: 0.14, ty: "half" },
      { pos: new v2(-105, 221), extent: new v2(8, 16), heading: -0.2, ty: "half" },
      { pos: new v2(-94, 276), extent: new v2(8, 16), heading: 0.31, ty: "half" },
    ],
    goals: [
    ],

    mission_rules: [
      { ty: 'explore' },
    ],

    editor: true,
  };
}

export function preset_limvis_debug() {
  const world = { width: 800, height: 900, simover_rule: 'eliminate', };

  const team0_ar = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_ar_t5),
    spawnarea: 0,
    life: 10000,
  };

  const team1_tmpl_ar = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_ar_t5),
    default_rule: { ty: 'idle', alert: false },
    team: 1,
    life: 10000,
    spawnarea: 2,
  };

  return {
    world,

    entities: [
      ...new Array(2).fill(team0_ar),

      { ...team1_tmpl_ar, spawnarea: 1 },
    ],

    spawnareas: [
      { pos: new v2(-255, -307), extent: new v2(52, 25), heading: 0, spawn: true, },
      { pos: new v2(-194, 410), extent: new v2(23, 17), heading: 0, },
      { pos: new v2(-12, -13), extent: new v2(388, 380), heading: 0, effect_ty: 'limvis', },
    ],
    obstacle_specs: [
      { pos: new v2(-87, 368), extent: new v2(315, 8), heading: 0, ty: "half", },
      { pos: new v2(135, 379), extent: new v2(9, 72), heading: 0, ty: "half", },
      { pos: new v2(-296, 391), extent: new v2(8, 74), heading: 0, ty: "half", },
    ],
    goals: [],
    mission_rules: [
      { ty: 'explore' },
    ],

    editor: true
  };
}

export function preset_alley1_short_layer(layer) {
  const preset = PRESET_ALLEY_SHORT;

  for (const obs of preset.spawnareas) {
    obs.pos = v2.from(obs.pos);
    obs.extent = v2.from(obs.extent);
  }
  for (const obs of preset.obstacle_specs) {
    obs.pos = v2.from(obs.pos);
    obs.extent = v2.from(obs.extent);
  }

  const level_names = [
    'Alley1_Short',
    'Alley1_Light',
  ];

  return {
    world: {
      ...preset.world,
      simover_rule: 'eliminate',
      level_names,
    },

    layers: preset.layers,

    spawnareas: preset.spawnareas.filter((s) => {
      if (!layer) {
        return true;
      }
      return s.layer === undefined || s.layer === layer;
    }).map((s, i) => {
      if (i === 0) {
        return { ...s, spawn: true };
      }
      return { ...s, spawn1: true };
    }),

    obstacle_specs: preset.obstacle_specs.filter((s) => {
      if (!layer) {
        return true;
      }
      return s.layer === undefined || s.layer === layer;
    }),

    mission_rules: [
      { ty: 'explore' },
    ],

    goals: [],

    editor: true,
  };
}

export function preset_alley1_short_layer_base() {
  const preset = preset_alley1_short_layer('base');

  const team0_smg = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_smg_low),
    ...gear_vest_bulletproof_highend,
    spawnarea: 0,
  };

  // 적 에이전트 스펙 정의
  const team1_tmpl_ar = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_ar_low),
    default_rule: { ty: 'idle', alert: false },
    team: 1,
    spawnarea: 2,
  };

  return {
    ...preset,

    entities: [
      ...new Array(1).fill(team0_smg),
      ...new Array(1).fill(team0_smg),
      ...new Array(1).fill(team0_smg),
      ...new Array(1).fill(team0_smg),

      { ...team1_tmpl_ar, spawnarea: 1 },
      { ...team1_tmpl_ar, spawnarea: 1 },

      { ...team1_tmpl_ar, spawnarea: 2 },
      { ...team1_tmpl_ar, spawnarea: 2 },

      { ...team1_tmpl_ar, spawnarea: 3 },
      { ...team1_tmpl_ar, spawnarea: 3 },

      { ...team1_tmpl_ar, spawnarea: 4 },
      { ...team1_tmpl_ar, spawnarea: 4 },

      { ...team1_tmpl_ar, spawnarea: 5 },
      { ...team1_tmpl_ar, spawnarea: 5 },

      { ...team1_tmpl_ar, spawnarea: 6 },
      { ...team1_tmpl_ar, spawnarea: 6 },

      { ...team1_tmpl_ar, spawnarea: 7 },
      { ...team1_tmpl_ar, spawnarea: 7 },
    ],
  };
}

export function preset_alley1_short() {
  const world = {
    width: 600,
    height: 500,
    simover_rule: 'eliminate',
  };

  const team0_smg = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_smg_low),
    ...gear_vest_bulletproof_highend,
    spawnarea: 0,
  };

  // 적 에이전트 스펙 정의
  const team1_tmpl_ar = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_ar_low),
    default_rule: { ty: 'idle', alert: false },
    team: 1,
    spawnarea: 2,
  };

  return {
    world,

    entities: [
      ...new Array(1).fill(team0_smg),
      ...new Array(1).fill(team0_smg),
      ...new Array(1).fill(team0_smg),
      ...new Array(1).fill(team0_smg),

      { ...team1_tmpl_ar, spawnarea: 1 },
      { ...team1_tmpl_ar, spawnarea: 1 },

      { ...team1_tmpl_ar, spawnarea: 2 },
      { ...team1_tmpl_ar, spawnarea: 2 },

      { ...team1_tmpl_ar, spawnarea: 3 },
      { ...team1_tmpl_ar, spawnarea: 3 },

      { ...team1_tmpl_ar, spawnarea: 4 },
      { ...team1_tmpl_ar, spawnarea: 4 },

      { ...team1_tmpl_ar, spawnarea: 5 },
      { ...team1_tmpl_ar, spawnarea: 5 },

      { ...team1_tmpl_ar, spawnarea: 6 },
      { ...team1_tmpl_ar, spawnarea: 6 },

      { ...team1_tmpl_ar, spawnarea: 7 },
      { ...team1_tmpl_ar, spawnarea: 7 },
    ],

    spawnareas: [
      { pos: new v2(-249, -100), extent: new v2(36, 27), heading: 0, spawn: true, },
      { pos: new v2(-119, -71), extent: new v2(14, 12), heading: 0, spawn1: true },
      { pos: new v2(-153, 28), extent: new v2(23, 17), heading: 0, spawn1: true },
      { pos: new v2(0, 157), extent: new v2(17, 15), heading: 0, spawn1: true },
      { pos: new v2(-283, -23), extent: new v2(17, 15), heading: 0, spawn1: true },
      { pos: new v2(138, -90), extent: new v2(62, 65), heading: 0.01, spawn1: true },
      { pos: new v2(-263, 88), extent: new v2(31, 14), heading: 0, spawn1: true },
      { pos: new v2(271, 114), extent: new v2(24, 36), heading: 0, spawn1: true },
    ],
    obstacle_specs: [
      { pos: new v2(-174, -93), extent: new v2(28, 50), heading: 0, ty: "full" },
      { pos: new v2(-153, -107), extent: new v2(19, 36), heading: 0, ty: "full" },
      { pos: new v2(-86, -89), extent: new v2(12, 39), heading: 0, ty: "full" },
      { pos: new v2(-55, -157), extent: new v2(23, 8), heading: 0, ty: "full" },
      { pos: new v2(-95, -49), extent: new v2(21, 6), heading: 0, ty: "full" },
      { pos: new v2(260, -140), extent: new v2(42, 54), heading: 0, ty: "full" },
      { pos: new v2(213, -81), extent: new v2(7, 39), heading: 0, ty: "full" },
      { pos: new v2(214, 3), extent: new v2(8, 52), heading: 0, ty: "full" },
      { pos: new v2(86, 149), extent: new v2(12, 15), heading: 0, ty: "full" },
      { pos: new v2(65, 101), extent: new v2(33, 36), heading: 0, ty: "full" },
      { pos: new v2(15, 71), extent: new v2(19, 5), heading: 0, ty: "half" },
      { pos: new v2(14, 130), extent: new v2(19, 5), heading: 0, ty: "half" },
      { pos: new v2(-46, 96), extent: new v2(43, 40), heading: 0, ty: "full" },
      { pos: new v2(-160, 62), extent: new v2(93, 6), heading: 0, ty: "full" },
      { pos: new v2(-269, 125), extent: new v2(5, 15), heading: 0, ty: "full" },
      { pos: new v2(-244, 31), extent: new v2(9, 31), heading: 0, ty: "full" },
      { pos: new v2(-233, -3), extent: new v2(20, 16), heading: 0, ty: "full" },
      { pos: new v2(-211, -29), extent: new v2(18, 23), heading: 0, ty: "full" },
      { pos: new v2(-267, -54), extent: new v2(65, 5), heading: 0, ty: "full" },
      { pos: new v2(-45, 1), extent: new v2(72, 26), heading: 0, ty: "full" },
      { pos: new v2(44, 5), extent: new v2(29, 22), heading: 0, ty: "full" },
      { pos: new v2(177, 3), extent: new v2(32, 17), heading: 0, ty: "full" },
      { pos: new v2(68, 22), extent: new v2(22, 5), heading: 0, ty: "full" },
      { pos: new v2(145, 22), extent: new v2(22, 5), heading: 0, ty: "full" },
      { pos: new v2(-165, -19), extent: new v2(9, 8), heading: 0, ty: "full" },
      { pos: new v2(-77, -144), extent: new v2(4, 21), heading: 0, ty: "full" },
      { pos: new v2(119, -157), extent: new v2(19, 6), heading: -0.36, ty: "half" },
      { pos: new v2(169, -166), extent: new v2(16, 5), heading: 0.39, ty: "half" },
      { pos: new v2(170, -136), extent: new v2(14, 3), heading: 1.17, ty: "half" },
      { pos: new v2(103, -83), extent: new v2(17, 2), heading: 0.13, ty: "half" },
      { pos: new v2(160, -83), extent: new v2(8, 6), heading: 0, ty: "half" },
      { pos: new v2(160, -60), extent: new v2(8, 8), heading: 0, ty: "half" },
      { pos: new v2(186, -60), extent: new v2(8, 8), heading: 0, ty: "half" },
      { pos: new v2(186, -84), extent: new v2(8, 6), heading: 0, ty: "half" },
      { pos: new v2(90, -44), extent: new v2(8, 8), heading: 0, ty: "half" },
      { pos: new v2(90, -21), extent: new v2(8, 8), heading: 0, ty: "half" },
      { pos: new v2(116, -21), extent: new v2(8, 8), heading: 0, ty: "half" },
      { pos: new v2(116, -46), extent: new v2(8, 8), heading: 0, ty: "half" },
      { pos: new v2(90, -130), extent: new v2(8, 8), heading: 0, ty: "half" },
      { pos: new v2(90, -107), extent: new v2(8, 8), heading: 0, ty: "half" },
      { pos: new v2(110, -119), extent: new v2(5, 18), heading: 0, ty: "half" },
      { pos: new v2(-66, -117), extent: new v2(5, 9), heading: 0, ty: "half" },
      { pos: new v2(-38, -89), extent: new v2(4, 10), heading: 0, ty: "half" },
      { pos: new v2(-68, -52), extent: new v2(5, 9), heading: 0, ty: "half" },
      { pos: new v2(-54, -79), extent: new v2(2, 5), heading: 0.78, ty: "half" },
      { pos: new v2(-144, -6), extent: new v2(4, 4), heading: -0.48, ty: "half" },
      { pos: new v2(-128, -8), extent: new v2(7, 2), heading: 0, ty: "half" },
      { pos: new v2(-56, 30), extent: new v2(11, 5), heading: 0, ty: "half" },
      { pos: new v2(-12, 55), extent: new v2(9, 4), heading: 0, ty: "half" },
      { pos: new v2(61, 45), extent: new v2(7, 6), heading: 0.39, ty: "half" },
      { pos: new v2(80, 53), extent: new v2(5, 5), heading: -0.12, ty: "half" },
      { pos: new v2(113, 84), extent: new v2(8, 8), heading: 0, ty: "half" },
      { pos: new v2(113, 107), extent: new v2(8, 8), heading: 0, ty: "half" },
      { pos: new v2(134, 95), extent: new v2(5, 18), heading: 0, ty: "half" },
      { pos: new v2(197, 98), extent: new v2(4, 13), heading: 0.28, ty: "half" },
      { pos: new v2(169, 172), extent: new v2(13, 7), heading: 0, ty: "half" },
      { pos: new v2(163, 68), extent: new v2(5, 5), heading: -0.72, ty: "half" },
      { pos: new v2(233, 93), extent: new v2(5, 14), heading: 0.13, ty: "half" },
      { pos: new v2(235, 135), extent: new v2(7, 11), heading: 0, ty: "half" },
      { pos: new v2(41, 187), extent: new v2(4, 8), heading: -0.2, ty: "half" },
      { pos: new v2(58, 175), extent: new v2(7, 7), heading: 0.13, ty: "half" },
      { pos: new v2(60, 193), extent: new v2(7, 7), heading: -0.11, ty: "half" },
      { pos: new v2(-235, 121), extent: new v2(13, 7), heading: 0, ty: "half" },
      { pos: new v2(-190, 120), extent: new v2(13, 7), heading: 0, ty: "half" },
      { pos: new v2(-267, 59), extent: new v2(8, 8), heading: -0.12, ty: "half" },
      { pos: new v2(-267, 33), extent: new v2(8, 8), heading: 0.13, ty: "half" },
      { pos: new v2(-289, 115), extent: new v2(7, 11), heading: 0, ty: "half" },
      { pos: new v2(-287, 50), extent: new v2(4, 15), heading: 0.13, ty: "half" },
      { pos: new v2(-217, 88), extent: new v2(5, 4), heading: -0.24, ty: "half" },
      { pos: new v2(-135, -49), extent: new v2(21, 4), heading: 0, ty: "full" },
      { pos: new v2(108, 23), extent: new v2(27, 4), heading: 0, ty: "full" },
      { pos: new v2(-63, 175), extent: new v2(6, 7), heading: 0.26, ty: "half" },
      { pos: new v2(-19, 185), extent: new v2(19, 4), heading: 0, ty: "half" },
      { pos: new v2(-151, 79), extent: new v2(7, 4), heading: 0.13, ty: "half" },
      { pos: new v2(-171, 96), extent: new v2(4, 13), heading: 0.66, ty: "half" },
      { pos: new v2(-29, 163), extent: new v2(3, 10), heading: 0.26, ty: "half" },
      { pos: new v2(-109, 159), extent: new v2(4, 16), heading: -0.22, ty: "half" },
      { pos: new v2(-106, 81), extent: new v2(6, 7), heading: 0.26, ty: "half" },
      { pos: new v2(-138, 94), extent: new v2(5, 3), heading: -2.04, ty: "half" },
      { pos: new v2(-18, -228), extent: new v2(325, 36), heading: 0, ty: "full" },
      { pos: new v2(-116, -102), extent: new v2(11, 9), heading: 0, ty: "half" },
      { pos: new v2(122, 145), extent: new v2(26, 7), heading: 0, ty: "full" },
      { pos: new v2(-108, -134), extent: new v2(5, 12), heading: 0, ty: "half" },
      { pos: new v2(24, -77), extent: new v2(47, 64), heading: 0, ty: "full" },
      { pos: new v2(240, 17), extent: new v2(9, 16), heading: 0, ty: "half" },
      { pos: new v2(269, -42), extent: new v2(16, 10), heading: 0, ty: "half" },
      { pos: new v2(263, 176), extent: new v2(14, 9), heading: 0, ty: "half" },
      { pos: new v2(-225, 179), extent: new v2(85, 26), heading: 0, ty: "full" },
      { pos: new v2(211, 169), extent: new v2(8, 33), heading: 0, ty: "full" },
      { pos: new v2(8, 238), extent: new v2(325, 36), heading: 0, ty: "full" },
    ],
    goals: [
    ],

    mission_rules: [
      { ty: 'explore' },
    ],

    editor: true,
  };
}


export function preset_shooting_house2_short() {
  const world = {
    width: 600, height: 500, simover_rule: 'eliminate',
    level_names: [
      'L_ShooterGym_shootinghouse_short',
    ],
  };

  const team0_ar = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_ar_low),
    ...gear_vest_bulletproof_highend,
    spawnarea: 0,
  };

  const team0_dmr = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_dmr_low),
    ...gear_vest_bulletproof_highend,
    spawnarea: 0,
  };

  const team0_smg = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_smg_low),
    ...gear_vest_bulletproof_highend,
    spawnarea: 0,
  };

  // 적 에이전트 스펙 정의
  const team1_tmpl_ar = {
    ...entityFromStat2(stats2_const(3), tmpl_firearm_ar_low),
    default_rule: { ty: 'idle', alert: false },
    team: 1,
    spawnarea: 2,
  };

  return {
    world,

    entities: [
      ...new Array(1).fill(team0_smg),
      ...new Array(1).fill(team0_smg),
      ...new Array(1).fill(team0_ar),
      ...new Array(1).fill(team0_dmr),
      ...new Array(1).fill(team0_dmr),

      { ...team1_tmpl_ar, spawnarea: 1 },
      { ...team1_tmpl_ar, spawnarea: 1 },

      { ...team1_tmpl_ar, spawnarea: 2 },
      { ...team1_tmpl_ar, spawnarea: 2 },

      { ...team1_tmpl_ar, spawnarea: 3 },
      { ...team1_tmpl_ar, spawnarea: 3 },

      { ...team1_tmpl_ar, spawnarea: 4 },
      { ...team1_tmpl_ar, spawnarea: 4 },

      { ...team1_tmpl_ar, spawnarea: 5 },
      { ...team1_tmpl_ar, spawnarea: 5 },

    ],


    spawnareas: [
      { pos: new v2(-2, 224), extent: new v2(53, 18), heading: 0, spawn: true, },
      { pos: new v2(-174, 126), extent: new v2(25, 17), heading: 0, spawn1: true, },
      { pos: new v2(-226, -120), extent: new v2(40, 51), heading: 0, spawn1: true, },
      { pos: new v2(242, -18), extent: new v2(17, 31), heading: 0, spawn1: true, },
      { pos: new v2(-95, -102), extent: new v2(16, 13), heading: 0, spawn1: true, },
      { pos: new v2(246, -205), extent: new v2(36, 21), heading: 0, spawn1: true, },
    ],

    obstacle_specs: [
      { pos: new v2(-124, 174), extent: new v2(282, 23), heading: 0, ty: "full" },
      { pos: new v2(91, 93), extent: new v2(7, 8), heading: 0.13, ty: "half" },
      { pos: new v2(-43, 138), extent: new v2(6, 4), heading: -1.92, ty: "half" },
      { pos: new v2(-184, 93), extent: new v2(35, 5), heading: 0, ty: "full" },
      { pos: new v2(-268, 67), extent: new v2(6, 9), heading: 2.08, ty: "half" },
      { pos: new v2(-195, 76), extent: new v2(6, 4), heading: -0.24, ty: "half" },
      { pos: new v2(-292, 40), extent: new v2(12, 3), heading: 0, ty: "half" },
      { pos: new v2(112, -159), extent: new v2(84, 5), heading: 0, ty: "full" },
      { pos: new v2(-22, -63), extent: new v2(75, 14), heading: 0, ty: "full" },
      { pos: new v2(-206, -84), extent: new v2(4, 12), heading: -0.72, ty: "half" },
      { pos: new v2(-194, -20), extent: new v2(8, 13), heading: 0.26, ty: "half" },
      { pos: new v2(-230, 57), extent: new v2(11, 6), heading: -0.06, ty: "half" },
      { pos: new v2(65, -54), extent: new v2(17, 5), heading: 0, ty: "full" },
      { pos: new v2(128, 97), extent: new v2(6, 7), heading: 0.26, ty: "half" },
      { pos: new v2(139, 135), extent: new v2(6, 7), heading: -0.59, ty: "half" },
      { pos: new v2(-127, 93), extent: new v2(29, 5), heading: 0, ty: "full" },
      { pos: new v2(-127, -53), extent: new v2(37, 4), heading: 0, ty: "full" },
      { pos: new v2(-294, 94), extent: new v2(41, 5), heading: 0, ty: "full" },
      { pos: new v2(-77, 82), extent: new v2(23, 16), heading: 0, ty: "full" },
      { pos: new v2(153, 79), extent: new v2(5, 12), heading: 0, ty: "full" },
      { pos: new v2(194, 113), extent: new v2(18, 4), heading: 0.68, ty: "half" },
      { pos: new v2(277, -159), extent: new v2(84, 5), heading: 0, ty: "full" },
      { pos: new v2(33, 71), extent: new v2(125, 5), heading: 0, ty: "full" },
      { pos: new v2(-127, 119), extent: new v2(14, 3), heading: 0.79, ty: "half" },
      { pos: new v2(-20, 104), extent: new v2(8, 6), heading: -0.96, ty: "half" },
      { pos: new v2(61, 133), extent: new v2(16, 3), heading: 0.31, ty: "half" },
      { pos: new v2(81, -67), extent: new v2(6, 18), heading: 0, ty: "full" },
      { pos: new v2(14, -141), extent: new v2(33, 8), heading: 0, ty: "full" },
      { pos: new v2(37, -152), extent: new v2(10, 12), heading: 0, ty: "full" },
      { pos: new v2(178, 172), extent: new v2(11, 3), heading: -0.36, ty: "half" },
      { pos: new v2(203, 190), extent: new v2(6, 5), heading: -1.39, ty: "half" },
      { pos: new v2(-232, -122), extent: new v2(18, 3), heading: -0.12, ty: "half" },
      { pos: new v2(-192, -148), extent: new v2(8, 11), heading: -0.48, ty: "half" },
      { pos: new v2(-75, -27), extent: new v2(4, 5), heading: -0.48, ty: "half" },
      { pos: new v2(-130, 54), extent: new v2(7, 4), heading: -3, ty: "half" },
      { pos: new v2(173, 39), extent: new v2(6, 4), heading: 0.72, ty: "half" },
      { pos: new v2(-39, -120), extent: new v2(6, 6), heading: -0.48, ty: "half" },
      { pos: new v2(275, -95), extent: new v2(11, 3), heading: -0.6, ty: "half" },
      { pos: new v2(260, -127), extent: new v2(6, 9), heading: -0.9, ty: "half" },
      { pos: new v2(-279, -52), extent: new v2(26, 4), heading: 0, ty: "half" },
      { pos: new v2(161, -79), extent: new v2(75, 6), heading: 0, ty: "full" },
      { pos: new v2(-126, -173), extent: new v2(6, 39), heading: 0, ty: "full" },
      { pos: new v2(-243, -198), extent: new v2(117, 14), heading: 0, ty: "full" },
      { pos: new v2(-292, -161), extent: new v2(17, 24), heading: 0, ty: "full" },
      { pos: new v2(-297, -108), extent: new v2(7, 60), heading: 0, ty: "full" },
      { pos: new v2(-209, -52), extent: new v2(26, 4), heading: 0, ty: "half" },
      { pos: new v2(268, 174), extent: new v2(48, 23), heading: 0, ty: "full" },
      { pos: new v2(298, 80), extent: new v2(5, 31), heading: 0, ty: "full" },
      { pos: new v2(1, 9), extent: new v2(5, 44), heading: 0, ty: "half" },
      { pos: new v2(26, 23), extent: new v2(6, 45), heading: 0, ty: "half" },
      { pos: new v2(-46, 10), extent: new v2(5, 44), heading: 0, ty: "half" },
      { pos: new v2(147, 5), extent: new v2(4, 9), heading: 0.55, ty: "half" },
      { pos: new v2(60, -31), extent: new v2(5, 6), heading: -0.6, ty: "half" },
      { pos: new v2(-89, 102), extent: new v2(7, 9), heading: 0, ty: "full" },
      { pos: new v2(-88, 149), extent: new v2(5, 14), heading: 0, ty: "full" },
      { pos: new v2(226, 87), extent: new v2(71, 4), heading: 0, ty: "full" },
      { pos: new v2(223, -66), extent: new v2(13, 11), heading: 0, ty: "full" },
      { pos: new v2(186, -118), extent: new v2(4, 23), heading: 0, ty: "half" },
      { pos: new v2(168, -107), extent: new v2(4, 23), heading: 0, ty: "half" },
      { pos: new v2(96, -105), extent: new v2(4, 23), heading: 0, ty: "half" },
      { pos: new v2(-39, -92), extent: new v2(5, 5), heading: -0.36, ty: "half" },
      { pos: new v2(149, -121), extent: new v2(4, 23), heading: 0, ty: "half" },
      { pos: new v2(-160, 6), extent: new v2(5, 63), heading: 0, ty: "full" },
      { pos: new v2(-102, -8), extent: new v2(3, 17), heading: 0.32, ty: "half" },
      { pos: new v2(-133, -142), extent: new v2(33, 8), heading: 0, ty: "full" },
      { pos: new v2(-158, -95), extent: new v2(8, 41), heading: 0, ty: "full" },
      { pos: new v2(-23, 52), extent: new v2(5, 6), heading: -0.84, ty: "half" },
      { pos: new v2(52, 55), extent: new v2(9, 3), heading: -0.24, ty: "half" },
      { pos: new v2(167, -39), extent: new v2(6, 13), heading: -0.58, ty: "half" },
      { pos: new v2(215, -15), extent: new v2(5, 40), heading: 0, ty: "half" },
      { pos: new v2(238, 29), extent: new v2(28, 5), heading: 0, ty: "half" },
      { pos: new v2(271, -14), extent: new v2(5, 49), heading: 0, ty: "half" },
      { pos: new v2(249, -60), extent: new v2(26, 4), heading: 0, ty: "half" },
      { pos: new v2(-68, -101), extent: new v2(4, 26), heading: 0, ty: "half" },
      { pos: new v2(-123, -97), extent: new v2(5, 42), heading: 0, ty: "half" },
      { pos: new v2(-96, -125), extent: new v2(33, 4), heading: 0, ty: "half" },
      { pos: new v2(-1, -226), extent: new v2(18, 5), heading: 2.19, ty: "half" },
      { pos: new v2(4, -172), extent: new v2(14, 6), heading: 1.82, ty: "half" },
      { pos: new v2(179, -230), extent: new v2(4, 13), heading: -0.22, ty: "half" },
      { pos: new v2(145, -187), extent: new v2(11, 14), heading: 0.14, ty: "half" },
    ],

    goals: [
    ],

    mission_rules: [
      { ty: 'explore' },
    ],

    editor: true,
  };
}


export function preset_shooting_house1_short() {
  const world = { width: 500, height: 500, simover_rule: 'eliminate' };

  const team0_smg = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_ar_low),
    ...gear_vest_bulletproof_highend,
    spawnarea: 0,
  };

  // 적 에이전트 스펙 정의
  const team1_tmpl = {
    ...enemy_tmpl_agent_ar_low,
    team: 1,
    spawnarea: 1,

    life: 99,
    speed: 20,
    armor: 1,
    armor_hit_prob: 0.2,
    default_rule: 'idle', allow_crawl: false,
    vis_range: 300,

    aim_rot_rules: [
      { aimvar: Math.PI * 2, aimspeed: 0.075 },
    ],
  };

  return {
    world,

    entities: [
      ...new Array(1).fill(team0_smg),
      ...new Array(1).fill(team0_smg),
      ...new Array(1).fill(team0_smg),

      { ...team1_tmpl, spawnarea: 1 },
      { ...team1_tmpl, spawnarea: 1 },

      { ...team1_tmpl, spawnarea: 2 },

      { ...team1_tmpl, spawnarea: 3 },

      { ...team1_tmpl, spawnarea: 4 },

      { ...team1_tmpl, spawnarea: 5 },
      { ...team1_tmpl, spawnarea: 5 },

      { ...team1_tmpl, spawnarea: 6 },
      { ...team1_tmpl, spawnarea: 6 },

      { ...team1_tmpl, spawnarea: 8 },
      { ...team1_tmpl, spawnarea: 8 },
    ],


    spawnareas: [
      { pos: new v2(12, 226), extent: new v2(36, 16), heading: 0, spawn: true, },
      { pos: new v2(-15, 130), extent: new v2(16, 13), heading: 0, spawn1: true, },
      { pos: new v2(-202, 53), extent: new v2(17, 19), heading: 0, spawn1: true, },
      { pos: new v2(-92, -16), extent: new v2(27, 21), heading: 0, spawn1: true, },
      { pos: new v2(-119, -88), extent: new v2(24, 15), heading: 0, spawn1: true, },
      { pos: new v2(54, -23), extent: new v2(14, 15), heading: 0, spawn1: true, },
      { pos: new v2(182, -129), extent: new v2(17, 14), heading: 0, spawn1: true, },
      { pos: new v2(-112, -211), extent: new v2(11, 14), heading: 0, },
      { pos: new v2(206, 9), extent: new v2(34, 37), heading: 0, spawn1: true, },
    ],
    obstacle_specs: [
      { pos: new v2(-124, 174), extent: new v2(282, 23), heading: 0, ty: "full" },
      { pos: new v2(241, 173), extent: new v2(46, 23), heading: 0, ty: "full" },
      { pos: new v2(15, 118), extent: new v2(7, 8), heading: 0.13, ty: "half" },
      { pos: new v2(87, 139), extent: new v2(7, 6), heading: -0.12, ty: "half" },
      { pos: new v2(-51, 129), extent: new v2(6, 4), heading: -1.56, ty: "half" },
      { pos: new v2(62, 92), extent: new v2(171, 6), heading: 0, ty: "full" },
      { pos: new v2(-143, 67), extent: new v2(9, 25), heading: 0, ty: "full" },
      { pos: new v2(-219, 0), extent: new v2(34, 5), heading: 0, ty: "full" },
      { pos: new v2(-239, 61), extent: new v2(6, 9), heading: 0.78, ty: "half" },
      { pos: new v2(-206, 79), extent: new v2(6, 4), heading: -0.24, ty: "half" },
      { pos: new v2(-249, 93), extent: new v2(10, 5), heading: 0, ty: "full" },
      { pos: new v2(156, 51), extent: new v2(8, 40), heading: 0, ty: "full" },
      { pos: new v2(228, 38), extent: new v2(14, 3), heading: -0.12, ty: "half" },
      { pos: new v2(214, 65), extent: new v2(6, 5), heading: -0.48, ty: "half" },
      { pos: new v2(180, -81), extent: new v2(75, 6), heading: 0, ty: "full" },
      { pos: new v2(-19, -88), extent: new v2(71, 39), heading: 0, ty: "full" },
      { pos: new v2(112, -159), extent: new v2(84, 5), heading: 0, ty: "full" },
      { pos: new v2(196, -13), extent: new v2(6, 16), heading: 0.13, ty: "half" },
      { pos: new v2(229, -61), extent: new v2(10, 6), heading: -0.12, ty: "half" },
      { pos: new v2(230, -37), extent: new v2(6, 7), heading: -0.96, ty: "half" },
      { pos: new v2(-196, -158), extent: new v2(67, 6), heading: 0, ty: "full" },
      { pos: new v2(-175, -122), extent: new v2(20, 4), heading: -1.78, ty: "half" },
      { pos: new v2(-230, -123), extent: new v2(12, 8), heading: 0.26, ty: "half" },
      { pos: new v2(-178, -74), extent: new v2(4, 12), heading: 0.56, ty: "half" },
      { pos: new v2(-189, -34), extent: new v2(8, 13), heading: 0.78, ty: "half" },
      { pos: new v2(-153, -2), extent: new v2(11, 7), heading: -0.36, ty: "half" },
      { pos: new v2(-234, -48), extent: new v2(11, 6), heading: -0.6, ty: "half" },
      { pos: new v2(6, -28), extent: new v2(4, 12), heading: 0.26, ty: "half" },
      { pos: new v2(30, -10), extent: new v2(6, 13), heading: -0.24, ty: "half" },
      { pos: new v2(-51, -7), extent: new v2(4, 21), heading: 0, ty: "full" },
      { pos: new v2(-81, 26), extent: new v2(16, 5), heading: -0.24, ty: "half" },
      { pos: new v2(-131, 24), extent: new v2(11, 5), heading: -0.94, ty: "half" },
      { pos: new v2(65, -54), extent: new v2(17, 5), heading: 0, ty: "full" },
      { pos: new v2(213, -96), extent: new v2(84, 11), heading: 0, ty: "full" },
      { pos: new v2(240, -130), extent: new v2(20, 34), heading: 0, ty: "full" },
      { pos: new v2(79, -99), extent: new v2(15, 6), heading: 0.13, ty: "half" },
      { pos: new v2(102, -134), extent: new v2(19, 2), heading: 2.34, ty: "half" },
      { pos: new v2(109, -41), extent: new v2(8, 3), heading: 0.39, ty: "half" },
      { pos: new v2(85, -19), extent: new v2(5, 4), heading: 0.78, ty: "half" },
      { pos: new v2(79, -74), extent: new v2(4, 6), heading: -0.24, ty: "half" },
      { pos: new v2(146, 118), extent: new v2(6, 7), heading: 0.26, ty: "half" },
      { pos: new v2(140, 138), extent: new v2(6, 7), heading: -0.59, ty: "half" },
      { pos: new v2(-153, 93), extent: new v2(53, 5), heading: 0, ty: "full" },
      { pos: new v2(242, 92), extent: new v2(12, 6), heading: 0, ty: "full" },
      { pos: new v2(-42, -140), extent: new v2(99, 25), heading: 0, ty: "full" },
      { pos: new v2(-187, 23), extent: new v2(11, 8), heading: -0.44, ty: "half" },
      { pos: new v2(-167, 51), extent: new v2(7, 7), heading: -1.04, ty: "half" },
      { pos: new v2(157, -11), extent: new v2(6, 25), heading: 0, ty: "half" },
      { pos: new v2(138, -122), extent: new v2(7, 18), heading: 0, ty: "half" },
      { pos: new v2(-122, -54), extent: new v2(37, 4), heading: 0, ty: "half" },
      { pos: new v2(48, 53), extent: new v2(103, 42), heading: 0, ty: "full" },
    ],

    goals: [
      { name: 'escape area', area: 7 },
    ],

    mission_rules: [
      { ty: 'explore' },
    ],

    editor: true,
  };
}

export function preset_shooting_house1_short_invis() {
  return preset_shooting_house1_short();
}

export function preset_exhibition_hall_short() {
  const world = { width: 700, height: 700, simover_rule: 'eliminate', };

  const team0_ar = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_ar_low),
    ...gear_vest_bulletproof_highend,
    spawnarea: 0,
  };

  const team1_tmpl_ar = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_ar_low),
    default_rule: { ty: 'idle', alert: false },
    team: 1,
    spawnarea: 2,
  };

  return {
    world,

    entities: [
      ...new Array(4).fill(team0_ar),

      { ...team1_tmpl_ar, spawnarea: 1 },
      { ...team1_tmpl_ar, spawnarea: 1 },

      { ...team1_tmpl_ar, spawnarea: 2 },
      { ...team1_tmpl_ar, spawnarea: 2 },

      { ...team1_tmpl_ar, spawnarea: 3 },
      { ...team1_tmpl_ar, spawnarea: 3 },

      { ...team1_tmpl_ar, spawnarea: 4 },
      { ...team1_tmpl_ar, spawnarea: 4 },

      { ...team1_tmpl_ar, spawnarea: 5 },
      { ...team1_tmpl_ar, spawnarea: 5 },

      { ...team1_tmpl_ar, spawnarea: 6 },
      { ...team1_tmpl_ar, spawnarea: 6 },

    ],


    spawnareas: [
      { pos: new v2(270, -23), extent: new v2(35, 56), heading: 0, spawn: true, },
      { pos: new v2(283, -301), extent: new v2(29, 19), heading: 0, spawn1: true },
      { pos: new v2(83, -241), extent: new v2(29, 19), heading: 0, spawn1: true },
      { pos: new v2(84, -87), extent: new v2(29, 19), heading: 0, spawn1: true },
      { pos: new v2(81, 124), extent: new v2(25, 14), heading: 0, spawn1: true },
      { pos: new v2(149, 249), extent: new v2(34, 24), heading: 0, spawn1: true },
      { pos: new v2(-160, -85), extent: new v2(42, 32), heading: 0, spawn1: true },
    ],
    obstacle_specs: [
      { pos: new v2(368, 93), extent: new v2(77, 40), heading: 0.02, ty: "full" },
      { pos: new v2(280, 59), extent: new v2(80, 6), heading: 0.01, ty: "full" },
      { pos: new v2(209, -81), extent: new v2(9, 135), heading: 0, ty: "full" },
      { pos: new v2(-234, -70), extent: new v2(21, 11), heading: 2.08, ty: "half" },
      { pos: new v2(-237, -25), extent: new v2(11, 12), heading: -0.24, ty: "half" },
      { pos: new v2(-109, -11), extent: new v2(5, 34), heading: 0, ty: "half" },
      { pos: new v2(-151, 27), extent: new v2(46, 5), heading: 0, ty: "half" },
      { pos: new v2(-160, -19), extent: new v2(4, 32), heading: -0.72, ty: "half" },
      { pos: new v2(101, 182), extent: new v2(115, 7), heading: 0, ty: "full" },
      { pos: new v2(77, -198), extent: new v2(48, 5), heading: 0, ty: "half" },
      { pos: new v2(-35, -247), extent: new v2(15, 20), heading: 0, ty: "full" },
      { pos: new v2(-90, 230), extent: new v2(22, 4), heading: 0, ty: "half" },
      { pos: new v2(-247, 53), extent: new v2(22, 24), heading: -0.36, ty: "half" },
      { pos: new v2(-234, 117), extent: new v2(9, 7), heading: -0.12, ty: "half" },
      { pos: new v2(48, -55), extent: new v2(103, 8), heading: 0, ty: "full" },
      { pos: new v2(55, 17), extent: new v2(27, 9), heading: -0.24, ty: "half" },
      { pos: new v2(133, 40), extent: new v2(22, 4), heading: 0, ty: "half" },
      { pos: new v2(212, 86), extent: new v2(8, 8), heading: 0, ty: "half" },
      { pos: new v2(261, 90), extent: new v2(17, 5), heading: -0.48, ty: "half" },
      { pos: new v2(32, 118), extent: new v2(18, 4), heading: -0.84, ty: "half" },
      { pos: new v2(75, 59), extent: new v2(11, 11), heading: 0, ty: "full" },
      { pos: new v2(7, 57), extent: new v2(9, 6), heading: 2.22, ty: "half" },
      { pos: new v2(77, -149), extent: new v2(11, 34), heading: 0, ty: "half" },
      { pos: new v2(49, -168), extent: new v2(6, 12), heading: 0, ty: "half" },
      { pos: new v2(48, -129), extent: new v2(6, 12), heading: 0, ty: "half" },
      { pos: new v2(103, -128), extent: new v2(6, 12), heading: 0, ty: "half" },
      { pos: new v2(103, -169), extent: new v2(6, 12), heading: 0, ty: "half" },
      { pos: new v2(14, -92), extent: new v2(23, 4), heading: 0.14, ty: "half" },
      { pos: new v2(147, -79), extent: new v2(4, 18), heading: 0, ty: "half" },
      { pos: new v2(187, -128), extent: new v2(10, 5), heading: -1.41, ty: "half" },
      { pos: new v2(184, -158), extent: new v2(7, 13), heading: -0.34, ty: "half" },
      { pos: new v2(183, -218), extent: new v2(12, 4), heading: -0.24, ty: "half" },
      { pos: new v2(27, -246), extent: new v2(12, 6), heading: -0.24, ty: "half" },
      { pos: new v2(143, -250), extent: new v2(5, 16), heading: 0.78, ty: "half" },
      { pos: new v2(1, 224), extent: new v2(6, 16), heading: -0.31, ty: "half" },
      { pos: new v2(94, 273), extent: new v2(26, 5), heading: -1.78, ty: "half" },
      { pos: new v2(-20, 262), extent: new v2(7, 7), heading: 0.26, ty: "half" },
      { pos: new v2(-16, 109), extent: new v2(15, 2), heading: -0.36, ty: "half" },
      { pos: new v2(72, 89), extent: new v2(16, 4), heading: 0.14, ty: "half" },
      { pos: new v2(121, 91), extent: new v2(14, 4), heading: 0.13, ty: "half" },
      { pos: new v2(139, 138), extent: new v2(11, 11), heading: 0, ty: "full" },
      { pos: new v2(6, 139), extent: new v2(11, 11), heading: 0, ty: "full" },
      { pos: new v2(-61, 102), extent: new v2(8, 132), heading: 0, ty: "full" },
      { pos: new v2(-61, -144), extent: new v2(8, 159), heading: 0, ty: "full" },
      { pos: new v2(76, -298), extent: new v2(146, 12), heading: 0, ty: "full" },
      { pos: new v2(251, -261), extent: new v2(17, 11), heading: 0, ty: "half" },
      { pos: new v2(314, -262), extent: new v2(26, 8), heading: 0.01, ty: "half" },
      { pos: new v2(-249, 229), extent: new v2(25, 5), heading: 0, ty: "half" },
      { pos: new v2(-170, 229), extent: new v2(28, 6), heading: 0, ty: "full" },
      { pos: new v2(-130, 85), extent: new v2(28, 7), heading: 0.14, ty: "half" },
      { pos: new v2(-167, 157), extent: new v2(28, 7), heading: -0.22, ty: "half" },
      { pos: new v2(-317, 283), extent: new v2(45, 72), heading: 0, ty: "full" },
      { pos: new v2(-57, -325), extent: new v2(14, 26), heading: 0, ty: "full" },
      { pos: new v2(209, -324), extent: new v2(14, 26), heading: 0, ty: "full" },
      { pos: new v2(147, -16), extent: new v2(9, 6), heading: 2.63, ty: "half" },
      { pos: new v2(181, 0), extent: new v2(9, 6), heading: 3.16, ty: "half" },
      { pos: new v2(323, 239), extent: new v2(109, 114), heading: 0, ty: "full" },
      { pos: new v2(321, -96), extent: new v2(23, 6), heading: -0.1, ty: "half" },
      { pos: new v2(248, -97), extent: new v2(23, 6), heading: 0.18, ty: "half" },
      { pos: new v2(238, -210), extent: new v2(20, 6), heading: 3.84, ty: "half" },
      { pos: new v2(8, -198), extent: new v2(9, 8), heading: 0, ty: "full" },
      { pos: new v2(144, -199), extent: new v2(9, 8), heading: 0, ty: "full" },
      { pos: new v2(143, -150), extent: new v2(9, 8), heading: 0, ty: "full" },
      { pos: new v2(9, -150), extent: new v2(9, 8), heading: 0, ty: "full" },
      { pos: new v2(-226, 273), extent: new v2(25, 6), heading: 0.17, ty: "half" },
      { pos: new v2(-118, 265), extent: new v2(25, 6), heading: -0.07, ty: "half" },
      { pos: new v2(98, 221), extent: new v2(7, 7), heading: 1.04, ty: "half" },
      { pos: new v2(256, -137), extent: new v2(23, 9), heading: 0, ty: "half" },
      { pos: new v2(309, -182), extent: new v2(34, 18), heading: -0.35, ty: "half" },
      { pos: new v2(184, -255), extent: new v2(6, 15), heading: 0.78, ty: "half" },
      { pos: new v2(106, -20), extent: new v2(9, 6), heading: 2.22, ty: "half" },
    ],
    goals: [],
    mission_rules: [
      { ty: 'explore' },
    ],

    editor: true
  };
}

export function preset_watchwood_random_density(seed = 123) {
  const rng = new Rng(seed);
  return rng.choice([
    preset_watchwood_sparse,
    preset_watchwood_dense,
  ])(seed);
}

export function preset_watchwood_dense(seed = 123) {
  const rng = new Rng(seed);
  const density = rng.integer(55, 90);
  return preset_watchwood(seed, density);
}

export function preset_watchwood_sparse(seed = 123) {
  const rng = new Rng(seed);
  const density = rng.integer(0, 35);
  return preset_watchwood(seed, density);
}

export function preset_shootergym2() {
  const world = {
    width: 1000,
    height: 1000,
    simover_rule: 'eliminate',
    exp_prepopulate_grid: true,
  };
  const size = world.height / 2 - 100;

  const spawnSize = new v2(100, 20);

  const team0_tmpl = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_ar_high),
    allow_cover_edge: true,
  };

  const team1_tmpl = {
    ...ENTITY_CONFIG_TMPL, team: 1, spawnarea: 1,
    life: 100,
    armor: 0,
    armor_hit_prob: 0.4,
    default_rule: { ty: 'idle', alert: false },
    allow_crawl: false,
  };

  return {
    world,

    obstacle_specs: [

      { pos: new v2(-103.30193353300803, 558.2456332008895), extent: new v2(2.8761875152587892, 2.8981109619140626), heading: 2.9887415880891317, ty: "half", name: "SM_Barrel_5" },
      { pos: new v2(44.69042113219737, 685.6323443533889), extent: new v2(13.02308349609375, 36.904486083984374), heading: -1.2217315416604668, ty: "full", name: "Ural_4320_SM_297" },
      { pos: new v2(-116.12192058172671, 424.03647232052117), extent: new v2(20.000015258789062, 11.000039672851564), heading: -0.000005908888668613308, ty: "full", name: "SM_Kyosk_01_387" },
      { pos: new v2(81.67626571655273, -179.22010803222656), extent: new v2(20.000015258789062, 11.000039672851564), heading: -1.570796326794897, ty: "full", name: "SM_Kyosk_2" },
      { pos: new v2(-368.8237685650556, -255.92951909685974), extent: new v2(8.753229950468995, 4.542104539963475), heading: -0.0000025307273832075786, ty: "half", name: "Mesh_woodencrate_59" },
      { pos: new v2(-374.28628058295925, -265.3566009402354), extent: new v2(4.547786943587698, 4.542104539963475), heading: 1.57079672626906, ty: "half", name: "Mesh_woodencrate_60" },
      { pos: new v2(-354.5999723428497, -260.81957335378974), extent: new v2(8.753229950468995, 4.542104539963475), heading: -1.542791590036059, ty: "half", name: "Mesh_woodencrate_61" },
      { pos: new v2(-384.3862798220504, -261.1231582134961), extent: new v2(8.753229950468995, 4.542104539963475), heading: -1.5707957941626787, ty: "half", name: "Mesh_woodencrate_62" },
      { pos: new v2(87.34349558004283, -122.57240539578663), extent: new v2(2.7589267730712894, 3.0529123306274415), heading: 0.5869413297565341, ty: "half", name: "SM_trashbag_01_b4" },
      { pos: new v2(81.56366635745132, -124.33497454718287), extent: new v2(2.4695903778076174, 2.567541885375977), heading: 1.5504444497445111, ty: "half", name: "SM_trashbag_02_c4" },
      { pos: new v2(-300.2000274149256, 534.9970123410158), extent: new v2(4.547786943587698, 4.542104539963475), heading: 1.5707972589012784, ty: "half", name: "Mesh_woodencrate_66" },
      { pos: new v2(-347.0139999896289, 533.7020024783004), extent: new v2(8.753229950468995, 4.542104539963475), heading: 3.141592120957575, ty: "half", name: "Mesh_woodencrate_67" },
      { pos: new v2(-347.4194671511691, 534.0019094412118), extent: new v2(4.547786943587698, 4.542104539963475), heading: -3.141591055693139, ty: "half", name: "Mesh_woodencrate_68" },
      { pos: new v2(-233.2618366411234, 368.235997778254), extent: new v2(4.547786943587698, 4.542104539963475), heading: 1.6757664190570247, ty: "half", name: "Mesh_woodencrate_69" },
      { pos: new v2(-261.9550888568164, 381.5388921267379), extent: new v2(8.753229950468995, 4.542104539963475), heading: 3.141592120957575, ty: "half", name: "Mesh_woodencrate_70" },
      { pos: new v2(-94.82141712176298, 616.5342967513578), extent: new v2(1.1741650381400746, 4.375536119332719), heading: 0, ty: "half", name: "SM_tire_13" },
      { pos: new v2(-98.33164304362722, 617.5566128794276), extent: new v2(1.1741650381400746, 4.375536119332719), heading: 0.24497866312686412, ty: "half", name: "SM_tire_14" },
      { pos: new v2(-94.82141712176298, 616.5342967513578), extent: new v2(1.1741650381400746, 4.375536119332719), heading: 0, ty: "half", name: "SM_tire_15" },
      { pos: new v2(-100.05712268816923, 622.4026561263578), extent: new v2(1.1741650381400746, 4.375536119332719), heading: 0, ty: "half", name: "SM_tire_16" },
      { pos: new v2(-94.82141712176298, 616.5342967513578), extent: new v2(1.1741650381400746, 4.375536119332719), heading: 0, ty: "half", name: "SM_tire_17" },
      { pos: new v2(-94.92729480730986, 607.5403026107327), extent: new v2(1.1741650381400746, 4.375536119332719), heading: 0, ty: "half", name: "SM_tire_18" },
      { pos: new v2(-98.60214832293485, 608.3053905013578), extent: new v2(1.1741650381400746, 4.375536119332719), heading: 0, ty: "half", name: "SM_tire_19" },
      { pos: new v2(-94.92729480730986, 607.5403026107327), extent: new v2(1.1741650381400746, 4.375536119332719), heading: 0, ty: "half", name: "SM_tire_20" },
      { pos: new v2(-98.49529309239222, 613.9958006666849), extent: new v2(1.1741650381400746, 4.375536119332719), heading: 1.5707963267948966, ty: "half", name: "SM_tire_21" },
      { pos: new v2(-94.89353832653066, 629.1373918864632), extent: new v2(1.1741650381400746, 4.375536119332719), heading: -1.3089862863513875, ty: "half", name: "SM_tire_22" },
      { pos: new v2(-42.15485808347056, 188.89110910166488), extent: new v2(3.529176712036133, 3.2534049987792972), heading: -1.8326174193569307, ty: "half", name: "Refrigerator10" },
      { pos: new v2(-73.17406005859375, 583.791015625), extent: new v2(8.494712066650392, 19.475633239746095), heading: 0.698131700797732, ty: "half", name: "Container4_2" },
      { pos: new v2(-160.2489461256372, -441.09684630122706), extent: new v2(0.5258372306823731, 4.980535963605007), heading: 3.14159078937703, ty: "full", name: "SM_plywood_06_7" },
      { pos: new v2(-151.36797281616813, -402.85113938832717), extent: new v2(0.5258372306823731, 4.73965393319927), heading: 2.133267134296196, ty: "full", name: "SM_plywood_24" },
      { pos: new v2(349, -397.5), extent: new v2(20, 2.5), heading: 5.326322180158476e-7, ty: "full", name: "SM_UbranWall_A_4m19" },
      { pos: new v2(389, -397.5), extent: new v2(20, 2.5), heading: -3.1415915883253573, ty: "full", name: "SM_UbranWall_A_4m20" },
      { pos: new v2(429, -397.5), extent: new v2(20, 2.5), heading: 5.326322180158476e-7, ty: "full", name: "SM_UbranWall_A_4m21" },
      { pos: new v2(462, -363), extent: new v2(20, 2.5), heading: 1.570796326794897, ty: "full", name: "SM_UbranWall_A_4m23" },
      { pos: new v2(462, 157), extent: new v2(20, 2.5), heading: 1.570796326794897, ty: "full", name: "SM_UbranWall_A_4m34" },
      { pos: new v2(462, 117), extent: new v2(20, 2.5), heading: 1.570796326794897, ty: "full", name: "SM_UbranWall_A_4m35" },
      { pos: new v2(462, 77), extent: new v2(20, 2.5), heading: -1.5707964599529514, ty: "full", name: "SM_UbranWall_A_4m36" },
      { pos: new v2(462, 37), extent: new v2(20, 2.5), heading: 1.570796326794897, ty: "full", name: "SM_UbranWall_A_4m37" },
      { pos: new v2(462, -3), extent: new v2(20, 2.5), heading: -1.5707964599529514, ty: "full", name: "SM_UbranWall_A_4m38" },
      { pos: new v2(462, -43), extent: new v2(20, 2.5), heading: 1.570796326794897, ty: "full", name: "SM_UbranWall_A_4m39" },
      { pos: new v2(462, -83), extent: new v2(20, 2.5), heading: -1.5707964599529514, ty: "full", name: "SM_UbranWall_A_4m40" },
      { pos: new v2(462, -123), extent: new v2(20, 2.5), heading: 1.570796326794897, ty: "full", name: "SM_UbranWall_A_4m41" },
      { pos: new v2(462, -163), extent: new v2(20, 2.5), heading: -1.5707964599529514, ty: "full", name: "SM_UbranWall_A_4m42" },
      { pos: new v2(462, -203), extent: new v2(20, 2.5), heading: 1.570796326794897, ty: "full", name: "SM_UbranWall_A_4m43" },
      { pos: new v2(462, -243), extent: new v2(20, 2.5), heading: -1.5707964599529514, ty: "full", name: "SM_UbranWall_A_4m44" },
      { pos: new v2(462, -283), extent: new v2(20, 2.5), heading: 1.570796326794897, ty: "full", name: "SM_UbranWall_A_4m45" },
      { pos: new v2(462, -323), extent: new v2(20, 2.5), heading: -1.5707964599529514, ty: "full", name: "SM_UbranWall_A_4m46" },
      { pos: new v2(462, -473), extent: new v2(20, 2.5), heading: -1.5707964599529514, ty: "full", name: "SM_UbranWall_A_4m49" },
      { pos: new v2(462, -433), extent: new v2(20, 2.5), heading: 1.570796326794897, ty: "full", name: "SM_UbranWall_A_4m50" },
      { pos: new v2(462, -513), extent: new v2(20, 2.5), heading: 1.570796326794897, ty: "full", name: "SM_UbranWall_A_4m51" },
      { pos: new v2(-416.5, -120.5), extent: new v2(20, 2.5), heading: 1.570796326794897, ty: "full", name: "SM_UbranWall_A_4m106" },
      { pos: new v2(-416.5, -160.5), extent: new v2(20, 2.5), heading: -1.5707964599529514, ty: "full", name: "SM_UbranWall_A_4m108" },
      { pos: new v2(-416.5, -200.5), extent: new v2(20, 2.5), heading: 1.570796326794897, ty: "full", name: "SM_UbranWall_A_4m109" },
      { pos: new v2(-416.5, -240.5), extent: new v2(20, 2.5), heading: 1.570796326794897, ty: "full", name: "SM_UbranWall_A_4m110" },
      { pos: new v2(-447.5, -360.5), extent: new v2(20, 2.5), heading: 1.570796326794897, ty: "full", name: "SM_UbranWall_A_4m111" },
      { pos: new v2(-447.5, -420.5), extent: new v2(20, 2.5), heading: -1.5707964599529514, ty: "full", name: "SM_UbranWall_A_4m112" },
      { pos: new v2(-447.5, -553.5), extent: new v2(20, 2.5), heading: 1.570796326794897, ty: "full", name: "SM_UbranWall_A_4m114" },
      { pos: new v2(-447.5, -593.5), extent: new v2(20, 2.5), heading: -1.5707964599529514, ty: "full", name: "SM_UbranWall_A_4m115" },
      { pos: new v2(-416.5, -80.5), extent: new v2(20, 2.5), heading: -1.5707964599529514, ty: "full", name: "SM_UbranWall_A_4m141" },
      { pos: new v2(-393.5, -57.5), extent: new v2(20, 2.5), heading: -0.0000015978966540475428, ty: "full", name: "SM_UbranWall_A_4m142" },
      { pos: new v2(-412, 535), extent: new v2(20, 2.5), heading: 1.570795794162679, ty: "full", name: "SM_UbranWall_A_4m143" },
      { pos: new v2(-412, 495), extent: new v2(20, 2.5), heading: 1.570796326794897, ty: "full", name: "SM_UbranWall_A_4m144" },
      { pos: new v2(462.0245519638062, 197.51200580596924), extent: new v2(3.0479290008544924, 3.020808029174805), heading: 0, ty: "full", name: "SM_UbranPillar_A_10" },
      { pos: new v2(462.0245519638062, -397.48799419403076), extent: new v2(3.0479290008544924, 3.020808029174805), heading: 0, ty: "full", name: "SM_UbranPillar_A_9" },
      { pos: new v2(462, -398), extent: new v2(15, 2.5), heading: -1.570796326794897, ty: "full", name: "SM_UbranWall_A_4m47_300" },
      { pos: new v2(-416.4754480361939, -242.48799419403076), extent: new v2(3.0479290008544924, 3.020808029174805), heading: 0, ty: "full", name: "SM_Wall_OldPillar3_317" },
      { pos: new v2(-432.5, -337.5), extent: new v2(15, 2.5), heading: -3.141592653589793, ty: "full", name: "SM_UbranWall_A_4m124_319" },
      { pos: new v2(-416.5245519638062, -337.51200580596924), extent: new v2(3.0479290008544924, 3.020808029174805), heading: -3.141592653589793, ty: "full", name: "SM_UbranPillar_A_32" },
      { pos: new v2(-447.4754480361939, -337.48799419403076), extent: new v2(3.0479290008544924, 3.020808029174805), heading: 0, ty: "full", name: "SM_UbranPillar_A_33" },
      { pos: new v2(-447.4754480361939, -439.48799419403076), extent: new v2(3.0479290008544924, 3.020808029174805), heading: 0, ty: "full", name: "SM_UbranPillar_A_34" },
      { pos: new v2(-447.5245519638062, -530.5120058059692), extent: new v2(3.0479290008544924, 3.020808029174805), heading: -3.141592653589793, ty: "full", name: "SM_UbranPillar_A_35" },
      { pos: new v2(-447.51200580596924, -596.9754480361938), extent: new v2(3.0479290008544924, 3.020808029174805), heading: -1.570796326794897, ty: "full", name: "SM_UbranPillar_A_329" },
      { pos: new v2(462, 187), extent: new v2(10, 2.5), heading: 1.570796326794897, ty: "full", name: "SM_Wall_OldWall_2m_332" },
      { pos: new v2(456.5, -397.5), extent: new v2(7.5, 2.5), heading: 0, ty: "full", name: "SM_Wall_OldWall_2m2_335" },
      { pos: new v2(-447.5, -390.5), extent: new v2(10, 2.5), heading: -1.5707964599529514, ty: "full", name: "SM_Wall_OldWall_2m7_358" },
      { pos: new v2(-416.4754480361939, -57.48799419403076), extent: new v2(3.0479290008544924, 3.020808029174805), heading: 0, ty: "full", name: "SM_UbranPillar_A_30" },
      { pos: new v2(-317.4612727258484, -625.4674920101238), extent: new v2(7.992852331371615, 4.499238342694298), heading: -1.5686636673939616, ty: "half", name: "Mesh_woodencrate_72" },
      { pos: new v2(-308.85637882519023, -605.2427555211242), extent: new v2(8.753229950468995, 4.542104539963475), heading: -0.016176040461141298, ty: "half", name: "Mesh_woodencrate_73" },
      { pos: new v2(-307.11984028927094, -623.5689172738425), extent: new v2(8.670621177707835, 4.499238342694298), heading: 1.4932376190007368, ty: "half", name: "Mesh_woodencrate_74" },
      { pos: new v2(-313.9760633814499, -610.8013980470649), extent: new v2(8.753229950468995, 4.542104539963475), heading: 1.6793734044374278, ty: "half", name: "Mesh_woodencrate_75" },
      { pos: new v2(-303.5874001898456, -612.723516649933), extent: new v2(7.053557612511759, 4.950370152677351), heading: -1.6545751136310463, ty: "half", name: "Mesh_woodencrate_76" },
      { pos: new v2(-294.6387797613992, -623.283950724448), extent: new v2(8.753229950468995, 4.542104539963475), heading: 1.1175859640718273, ty: "half", name: "Mesh_woodencrate_77" },
      { pos: new v2(57.72160416943029, 553.3605204016459), extent: new v2(16.452128601074218, 40.96189575195313), heading: 0.4247030208875064, ty: "full", name: "SM_VH_ZIL_01_27" },
      { pos: new v2(48.63741924713537, 362.2785630947958), extent: new v2(9.801497650146485, 20.453799438476565), heading: 0.8350229745177669, ty: "full", name: "SM_VH_UAZ_01_36" },
      { pos: new v2(-489.5796735169724, 440.4977642076402), extent: new v2(16.452128601074218, 40.96189575195313), heading: -0.06967258846373066, ty: "full", name: "SM_VH_ZIL_2" },
      { pos: new v2(-439.94413899204875, 512.3639113085276), extent: new v2(12.872396850585938, 6.6795654296875), heading: -1.495159622359447, ty: "half", name: "Mesh_woodencrate_4" },
      { pos: new v2(-437.2703781580973, 541.6472840541797), extent: new v2(12.872396850585938, 6.6795654296875), heading: 1.584086565898881, ty: "half", name: "Mesh_woodencrate_15" },
      { pos: new v2(-425.4261147181271, 501.5), extent: new v2(8.01493410858866, 8.004919547398458), heading: 0, ty: "full", name: "Mesh_woodencrate_05_17" },
      { pos: new v2(-423.2824614307899, 524.4319312195313), extent: new v2(12.872396850585938, 6.6795654296875), heading: 1.6056629644184324, ty: "half", name: "Mesh_woodencrate_16" },
      { pos: new v2(-425.06599756518784, 484.4594851660645), extent: new v2(6.6879219055175785, 6.6795654296875), heading: -1.8267492770529956, ty: "half", name: "Mesh_woodencrate_17" },
      { pos: new v2(-450.091943359375, 525.8158691406251), extent: new v2(10.52456283569336, 3.4233238220214846), heading: 1.6042028863507967, ty: "half", name: "Mesh_woodencrate_22" },
      { pos: new v2(-439.10009765625, 486.26328125000003), extent: new v2(10.52456283569336, 3.4233238220214846), heading: -1.636129527288939, ty: "half", name: "Mesh_woodencrate_04_29" },
      { pos: new v2(-453.6217420828091, 485.82097428915006), extent: new v2(13.859649658203125, 12.366928100585938), heading: 2.9174106825041215, ty: "half", name: "Mesh_tarp_03_35" },
      { pos: new v2(-468.6700667264238, 435.43559099602754), extent: new v2(12.872396850585938, 6.6795654296875), heading: -1.495159622359447, ty: "half", name: "Mesh_woodencrate_21" },
      { pos: new v2(-464.91943359375, 453.241455078125), extent: new v2(10.52456283569336, 3.4233238220214846), heading: -2.8839621781610534, ty: "half", name: "Mesh_woodencrate_38" },
      { pos: new v2(-424.39982337951665, 473.195414352417), extent: new v2(4.079704284667969, 4.079694747924805), heading: 0, ty: "half", name: "Mesh_oildrum_01_41" },
      { pos: new v2(-431.64688780555554, 472.1924173922198), extent: new v2(4.079704284667969, 4.079694747924805), heading: -1.0595087457047885, ty: "half", name: "Mesh_oildrum_4" },
      { pos: new v2(-114.30886230468751, 373.21945667266846), extent: new v2(3.196453475952149, 2.5624307632446293), heading: 0, ty: "half", name: "SM_trashbag_02_a_15" },
      { pos: new v2(-112.19551277160645, 367.2842281341553), extent: new v2(2.8643299102783204, 2.983437728881836), heading: 0, ty: "half", name: "SM_trashbag_01_a_18" },
      { pos: new v2(-119.74929195256715, 372.35074138407595), extent: new v2(3.8063289756841643, 4.211923555823364), heading: -0.3090567485789284, ty: "half", name: "SM_trashbag_01_b_21" },
      { pos: new v2(-109.84588102682471, 374.5665635333471), extent: new v2(4.435199958756676, 3.3640939327065555), heading: -0.2780498636127586, ty: "half", name: "SM_trashbag_01_b5" },
      { pos: new v2(-142.52785667346453, 359.0700546358016), extent: new v2(3.5880637995603593, 3.8703788759174813), heading: 2.074520330652098, ty: "half", name: "SM_trashbag_01_c2" },
      { pos: new v2(-133.82277220021086, 364.62509156471793), extent: new v2(4.479773478226434, 4.832249817059392), heading: 2.2783753252435757, ty: "half", name: "SM_trashbag_01_c3" },
      { pos: new v2(242.8654213523094, -468.11740369367), extent: new v2(2.7589267730712894, 3.0529123306274415), heading: -0.7332232423753237, ty: "half", name: "SM_trashbag_01_b10" },
      { pos: new v2(462, -553), extent: new v2(20, 2.5), heading: 1.570796326794897, ty: "full", name: "SM_UbranWall_A_4m52" },
      { pos: new v2(462, -593), extent: new v2(20, 2.5), heading: 1.570796326794897, ty: "full", name: "SM_UbranWall_A_4m53" },
      { pos: new v2(462, -633), extent: new v2(20, 2.5), heading: 1.570796326794897, ty: "full", name: "SM_UbranWall_A_4m54" },
      { pos: new v2(462, -673), extent: new v2(20, 2.5), heading: 1.570796326794897, ty: "full", name: "SM_UbranWall_A_4m55" },
      { pos: new v2(462, -713), extent: new v2(20, 2.5), heading: 1.570796326794897, ty: "full", name: "SM_UbranWall_A_4m56" },
      { pos: new v2(130.82752113342286, 160.5554973602295), extent: new v2(3.2805042266845703, 2.6357059478759766), heading: 0, ty: "half", name: "SM_trashbag_01_d_22" },
      { pos: new v2(137.54406211671198, 161.55313917959597), extent: new v2(3.2805042266845703, 2.6357059478759766), heading: -1.2810685018021408, ty: "half", name: "SM_trashbag_01_d2" },
      { pos: new v2(143.73776886234086, 162.8352869292583), extent: new v2(2.30233154296875, 2.9886129379272464), heading: 2.0394929712567755, ty: "half", name: "SM_trashbag_02_b8_26" },
      { pos: new v2(138.77869617690428, 166.03860041622815), extent: new v2(2.30233154296875, 2.9886129379272464), heading: -1.2856605904697636, ty: "half", name: "SM_trashbag_02_b9" },
      { pos: new v2(133.80903546989484, 164.7625657649243), extent: new v2(3.2805042266845703, 2.6357059478759766), heading: 2.7427951993108968, ty: "half", name: "SM_trashbag_01_d3" },
      { pos: new v2(128.15787512865919, 166.0404619362571), extent: new v2(3.2805042266845703, 2.6357059478759766), heading: -2.7222989789294294, ty: "half", name: "SM_trashbag_01_d4" },
      { pos: new v2(131.38503250754408, 165.88557080947533), extent: new v2(2.7589267730712894, 3.0529123306274415), heading: 1.3882840383373678, ty: "half", name: "SM_trashbag_01_b14_56" },
      { pos: new v2(-72.49279155731202, 426.1517749786377), extent: new v2(0.8900775909423828, 3.4219993591308597), heading: -3.141592653589793, ty: "half", name: "SM_VH_Lada_Wrecked_wheel_A_50" },
      { pos: new v2(-45.42647523880005, 608.5703054428101), extent: new v2(0.8386893272399902, 3.125382995605469), heading: 0, ty: "half", name: "SM_tire_01_53" },
      { pos: new v2(-45.06724568254034, 593.2509806175249), extent: new v2(0.8386893272399902, 3.125382995605469), heading: -3.141588925164267, ty: "half", name: "SM_tire_2" },
      { pos: new v2(-45.42656679153443, 578.5137624740601), extent: new v2(0.8386893272399902, 3.125382995605469), heading: 0, ty: "half", name: "SM_tire_23" },
      { pos: new v2(-45.067361649337215, 563.1944376487749), extent: new v2(0.8386893272399902, 3.125382995605469), heading: -3.141588925164267, ty: "half", name: "SM_tire_24" },
      { pos: new v2(-60.29051152322489, 551.4553020637459), extent: new v2(0.8386893272399902, 3.125382995605469), heading: -1.6009369187479776, ty: "half", name: "SM_tire_25" },
      { pos: new v2(-75.38673163454588, 540.8633961735227), extent: new v2(0.8386893272399902, 3.125382995605469), heading: 1.4193583345686305, ty: "half", name: "SM_tire_26" },
      { pos: new v2(-93.07015903863686, 539.6842256091645), extent: new v2(0.8386893272399902, 3.125382995605469), heading: -1.6081061484024708, ty: "half", name: "SM_tire_27" },
      { pos: new v2(-114.9222106137248, 540.1540068857515), extent: new v2(0.8386893272399902, 3.125382995605469), heading: 1.6148002701184943, ty: "half", name: "SM_tire_28" },
      { pos: new v2(-94.64239770869779, 639.92962563314), extent: new v2(13.494155883789062, 5.908631134033204), heading: -1.573766417200608, ty: "half", name: "Cut_Logs_Covered_01_69" },
      { pos: new v2(-94.86045532226564, 571.5277832031251), extent: new v2(24.012637329101565, 22.735566711425783), heading: 0.8469999423091533, ty: "half", name: "Debris01_75" },
      { pos: new v2(-94.83980998992921, 642.671336555481), extent: new v2(3.0013858795166017, 1.0702152252197266), heading: 0, ty: "half", name: "Can1_84" },
      { pos: new v2(-196.90831506171344, 540.0511195184439), extent: new v2(7.8317422729242026, 1.500005626678467), heading: 3.141588925164267, ty: "full", name: "SM_WindowFence_143" },
      { pos: new v2(-242.42751672187535, 540.051217174694), extent: new v2(7.831741200038778, 1.500005626678467), heading: 3.141588925164267, ty: "full", name: "SM_WindowFence_6" },
      { pos: new v2(-286.78640344062535, 540.0507777215689), extent: new v2(7.831741200038778, 1.500005626678467), heading: 3.141588925164267, ty: "full", name: "SM_WindowFence_7" },
      { pos: new v2(-113.31413192749024, 370.25959091186525), extent: new v2(9.607099914550782, 5.93352279663086), heading: 0, ty: "half", name: "SM_trash_box_02_386" },
      { pos: new v2(-66.07562041408407, 535.729676671854), extent: new v2(1.9411300659179689, 4.735214614868164), heading: 3.0943524369095314, ty: "half", name: "pipes_pipe_B2" },
      { pos: new v2(-66.04115827862829, 535.7085060745393), extent: new v2(1.9411300659179689, 4.735214614868164), heading: 3.1378663585925546, ty: "half", name: "pipes_pipe_B12" },
      { pos: new v2(-66.0484454363174, 567.4680082007144), extent: new v2(1.9411300659179689, 4.735214614868164), heading: -0.003783819276784582, ty: "half", name: "pipes_pipe_B14" },
      { pos: new v2(-60.689930534178465, 436.1624678084992), extent: new v2(2.387151336669922, 7.2), heading: -1.1507826665338292, ty: "half", name: "SM_brokenwall_concrete_449" },
      { pos: new v2(-64.32619743347168, 492.1917922973633), extent: new v2(1.7839538574218752, 17.309730529785156), heading: 0, ty: "half", name: "sm_en_MetalSupport_01_04_470" },
      { pos: new v2(-55.90369283407472, 492.68531532965727), extent: new v2(0.5258372306823731, 4.157370376586914), heading: 0.6605193440903259, ty: "half", name: "SM_plywood_476" },
      { pos: new v2(-67.24408747709964, 509.19643228116365), extent: new v2(0.5258372306823731, 4.157370376586914), heading: 1.6159333120042672, ty: "half", name: "SM_plywood_35" },
      { pos: new v2(-92.29160144806666, 462.45328370029847), extent: new v2(0.8386893272399902, 3.125382995605469), heading: 1.503593587215619, ty: "half", name: "SM_tire_486" },
      { pos: new v2(-108.22890003205103, 461.38052979404847), extent: new v2(0.8386893272399902, 3.125382995605469), heading: 1.503593587215619, ty: "half", name: "SM_tire_30" },
      { pos: new v2(-126.4095038990093, 458.47960182888147), extent: new v2(0.8386893272399902, 3.125382995605469), heading: -2.31893846443879, ty: "half", name: "SM_tire_31" },
      { pos: new v2(-133.9399256268108, 370.83578155726076), extent: new v2(4.0054443359375, 9.00790786743164), heading: -0.7140733830829464, ty: "half", name: "SM_Bath01_558" },
      { pos: new v2(-273.4322401975187, 381.2125404888915), extent: new v2(3.0013858795166017, 1.0702152252197266), heading: 1.7455186027478347, ty: "half", name: "Can3_638" },
      { pos: new v2(-91.41170152029147, 314.8473558316539), extent: new v2(10.605496215820313, 17.249517822265627), heading: -0.08362756922050266, ty: "half", name: "Planks1_641" },
      { pos: new v2(-243.69963150024415, 430.6328720092774), extent: new v2(9.995286560058595, 9.312271118164062), heading: 0, ty: "half", name: "Stump_Desktop_683" },
      { pos: new v2(-127.74885273038672, 353.20832683607927), extent: new v2(24.739328892833875, 1.2289083813874186), heading: 0, ty: "full", name: "sm_wall_precast_8m_E2_721" },
      { pos: new v2(-102.46103187957377, 330.0576392241633), extent: new v2(24.739326493010413, 1.228908262177913), heading: 1.549723798353536, ty: "full", name: "sm_wall_precast_8m_E3" },
      { pos: new v2(-100.43466708028258, 280.92992310982265), extent: new v2(24.75337708035695, 2.096803108903896), heading: 1.549723798353536, ty: "full", name: "sm_wall_precast_8m_E4" },
      { pos: new v2(-100.32987953582376, 231.62890387260083), extent: new v2(24.739326493010413, 1.228908262177913), heading: 1.549723798353536, ty: "full", name: "sm_wall_precast_8m_E5" },
      { pos: new v2(-99.27729774871439, 183.41467047416333), extent: new v2(24.739326493010413, 1.228908262177913), heading: 1.549723798353536, ty: "full", name: "sm_wall_precast_8m_E6_735" },
      { pos: new v2(-98.52636391082376, 82.26429205619458), extent: new v2(24.739326493010413, 1.228908262177913), heading: 1.549723798353536, ty: "full", name: "sm_wall_precast_8m_E7" },
      { pos: new v2(-121.47951437606758, 57.499220761510564), extent: new v2(24.739326493010413, 1.228908262177913), heading: 3.1392072602014096, ty: "full", name: "sm_wall_precast_8m_E8" },
      { pos: new v2(-63.07496632635894, 463.2024342094299), extent: new v2(7.046244812011719, 2.998703956604004), heading: 1.5272400608505414, ty: "half", name: "SM_rusty_pipe_753" },
      { pos: new v2(-62.89735462972515, 459.0469537508074), extent: new v2(3, 2.9544254302978517), heading: 1.5272400608505414, ty: "half", name: "SM_rusty_pipe_00_755" },
      { pos: new v2(-63.08198650885079, 454.89181708450315), extent: new v2(7.046244812011719, 2.998703956604004), heading: -1.5272357997927974, ty: "half", name: "SM_rusty_pipe_759" },
      { pos: new v2(-63.245567220711976, 447.9794188384328), extent: new v2(3.0024177551269533, 3.0012832641601563), heading: -0.011405071632345708, ty: "full", name: "SM_rusty_pipe_01_764" },
      { pos: new v2(-62.82433766356117, 422.33688666630155), extent: new v2(3.0024177551269533, 3.0012832641601563), heading: -3.1301242829875853, ty: "full", name: "SM_rusty_pipe_2" },
      { pos: new v2(-62.2901964287137, 379.48818893434634), extent: new v2(3.5886470794677736, 7.077127075195313), heading: -3.141590523060921, ty: "half", name: "SM_rusty_pipe_4" },
      { pos: new v2(-65.56606900848874, 466.9434000477995), extent: new v2(2.9640198418627506, 2.6629706946618175), heading: 3.1360255816470914, ty: "half", name: "SM_rug_780" },
      { pos: new v2(79.36013355255128, 441.8484058380127), extent: new v2(2.8761875152587892, 2.8981109619140626), heading: 0, ty: "half", name: "SM_Barrel_6" },
      { pos: new v2(-71.56603686196762, -6.880799994922518), extent: new v2(3.0024177551269533, 3.0012832641601563), heading: 1.5101138045124607, ty: "full", name: "SM_rusty_pipe_5" },
      { pos: new v2(-106.83338035419541, -7.05084637122699), extent: new v2(5.355934039724843, 5.278686115656228), heading: 1.5364051965839947, ty: "half", name: "SM_rusty_pipe_6" },
      { pos: new v2(-114.53929336105188, -7.7638964597794775), extent: new v2(3.5886470794677736, 7.077127075195313), heading: -1.6051792012064194, ty: "half", name: "SM_rusty_pipe_7" },
      { pos: new v2(-59.23637976260997, -115.43320861545013), extent: new v2(3.0024177551269533, 3.0012832641601563), heading: -0.02449522307433088, ty: "full", name: "SM_rusty_pipe_15" },
      { pos: new v2(-60.9314053121752, -123.30460203123708), extent: new v2(2.964019240001676, 2.6629709505737993), heading: 3.0815130709781506, ty: "half", name: "SM_rug_3" },
      { pos: new v2(-58.20380529971934, -166.53278136935637), extent: new v2(3.0024177551269533, 3.0012832641601563), heading: -0.02449522307433088, ty: "full", name: "SM_rusty_pipe_17" },
      { pos: new v2(-58.72221368777593, -140.87794279852935), extent: new v2(3.0024177551269533, 3.0012832641601563), heading: -0.0298426341613714, ty: "full", name: "SM_rusty_pipe_18" },
      { pos: new v2(-53.542085274881856, -192.31972805287998), extent: new v2(7.046244812011719, 2.998703956604004), heading: -3.064422234682568, ty: "half", name: "SM_rusty_pipe_19" },
      { pos: new v2(-55.87704821568945, -202.7362438947622), extent: new v2(7.49191307695769, 7.403076104577576), heading: -1.5867184348460992, ty: "half", name: "SM_rusty_pipe_20" },
      { pos: new v2(-72.79659272692572, 340.90191886886663), extent: new v2(0.5258372306823731, 4.157370376586914), heading: 0.8630154607355864, ty: "half", name: "SM_plywood_10" },
      { pos: new v2(-63.76384333818581, 387.3967519895198), extent: new v2(5.355934039724843, 5.278686115656228), heading: 0, ty: "half", name: "SM_rusty_pipe_9" },
      { pos: new v2(-55.504133748833524, 333.22108963141534), extent: new v2(0.5258372306823731, 4.157370376586914), heading: 1.7736434480718475, ty: "half", name: "SM_plywood_37" },
      { pos: new v2(-72.06973505725321, 389.90453771213134), extent: new v2(2.7092456817626953, 2.9224138259887695), heading: 1.062457464242751, ty: "half", name: "SM_trashbag_01_c7" },
      { pos: new v2(-57.85703471349001, 343.4188281681898), extent: new v2(0.8386893272399902, 3.125382995605469), heading: 3.07861928013767, ty: "half", name: "SM_tire_32" },
      { pos: new v2(103.04207199529628, 697.761888877719), extent: new v2(20.05698699951172, 1.2065362930297852), heading: -1.5953273693858894, ty: "full", name: "SM_fenceSquare_big_13" },
      { pos: new v2(101.66417842121989, 738.7105224420488), extent: new v2(20.05698699951172, 1.2065362930297852), heading: -1.595326170963399, ty: "full", name: "SM_fenceSquare_big2" },
      { pos: new v2(100.65585074401315, 779.2660118079459), extent: new v2(20.05698699951172, 1.2065362930297852), heading: -1.5774431774015707, ty: "full", name: "SM_fenceSquare_18" },
      { pos: new v2(120.27031080383736, 797.5952952283865), extent: new v2(19.054137410438216, 1.2065362930297852), heading: -0.0962432212979386, ty: "full", name: "SM_fenceSquare_big3" },
      { pos: new v2(155.9168036698175, 797.2531968061643), extent: new v2(20.05698699951172, 1.2065362930297852), heading: -0.008979063997040721, ty: "full", name: "SM_fenceSquare_big4" },
      { pos: new v2(196.78709232997517, 797.2531606634977), extent: new v2(20.05698699951172, 1.2065362930297852), heading: -0.008977252839440007, ty: "full", name: "SM_fenceSquare_big5_32" },
      { pos: new v2(238.53200513239335, 797.2239561875781), extent: new v2(20.05698699951172, 1.2065362930297852), heading: -0.007513793805576427, ty: "full", name: "SM_fenceSquare_big6" },
      { pos: new v2(277.2247023604638, 797.2531501383213), extent: new v2(20.05698699951172, 1.2065362930297852), heading: -0.008976725408708497, ty: "full", name: "SM_fenceSquare_big7" },
      { pos: new v2(318.02541028252966, 797.2531555358477), extent: new v2(20.05698699951172, 1.2065362930297852), heading: -0.00897699588600671, ty: "full", name: "SM_fenceSquare_big8" },
      { pos: new v2(357.85296202873366, 797.253893066094), extent: new v2(20.05698699951172, 1.2065362930297852), heading: -0.009013954528212659, ty: "full", name: "SM_fenceSquare_big9" },
      { pos: new v2(102.10125258300413, 186.6644146791615), extent: new v2(12.555992126464844, 1.1543828964233398), heading: -1.5707979246915509, ty: "full", name: "SM_fenceRound_light_55" },
      { pos: new v2(135.73931842751577, 163.74488451210186), extent: new v2(9.607099914550782, 5.93352279663086), heading: 2.9816927333159096, ty: "half", name: "SM_trash_box_65" },
      { pos: new v2(-98.90494359187981, 158.27856445521934), extent: new v2(2.3813400494879717, 2.682099784450475), heading: -1.578984215566345, ty: "full", name: "sm_wall_precast_pilar_83" },
      { pos: new v2(463.99581150125033, 221.47663520781532), extent: new v2(19.044390869140624, 1.8922796249389648), heading: 1.5500273987178048, ty: "half", name: "SM_fenceRack_big_95" },
      { pos: new v2(462.8042141559693, 221.25561865260775), extent: new v2(20.05698699951172, 1.2065362930297852), heading: 1.5500273987178048, ty: "full", name: "SM_fenceSquare_big_light_97" },
      { pos: new v2(463.75601657937534, 261.6784662625028), extent: new v2(19.044390869140624, 1.8922796249389648), heading: 1.5500273987178048, ty: "half", name: "SM_fenceRack_big2" },
      { pos: new v2(462.5278469684693, 261.45744970729527), extent: new v2(20.05698699951172, 1.2065362930297852), heading: 1.5500273987178048, ty: "full", name: "SM_fenceSquare_big_light2" },
      { pos: new v2(463.44309473277303, 302.01576345085545), extent: new v2(19.044390869140624, 1.8922796249389648), heading: 1.5763576729412547, ty: "half", name: "SM_fenceRack_big3" },
      { pos: new v2(462.2461657691465, 301.87389643609595), extent: new v2(20.05698699951172, 1.2065362930297852), heading: 1.5763576729412547, ty: "full", name: "SM_fenceSquare_big_light3" },
      { pos: new v2(463.626493170273, 342.37338063835546), extent: new v2(19.044390869140624, 1.8922796249389648), heading: 1.5763576729412547, ty: "half", name: "SM_fenceRack_big4" },
      { pos: new v2(462.42956420664655, 342.23151362359596), extent: new v2(20.05698699951172, 1.2065362930297852), heading: 1.5763576729412547, ty: "full", name: "SM_fenceSquare_big_light4" },
      { pos: new v2(463.626493170273, 382.560367943043), extent: new v2(19.044390869140624, 1.8922796249389648), heading: 1.5763576729412547, ty: "half", name: "SM_fenceRack_big5" },
      { pos: new v2(462.42956420664655, 382.4185009282835), extent: new v2(20.05698699951172, 1.2065362930297852), heading: 1.5763576729412547, ty: "full", name: "SM_fenceSquare_big_light5" },
      { pos: new v2(464.0378478543978, 422.50951507234424), extent: new v2(19.044390869140624, 1.8922796249389648), heading: 1.5763563413607098, ty: "half", name: "SM_fenceRack_big6" },
      { pos: new v2(462.429590782413, 422.36762205784225), extent: new v2(20.05698699951172, 1.2065362930297852), heading: 1.5763563413607098, ty: "full", name: "SM_fenceSquare_big_light6" },
      { pos: new v2(464.48296973027846, 462.50649166014387), extent: new v2(19.044390869140624, 1.8922796249389648), heading: 1.553867943325808, ty: "half", name: "SM_fenceRack_big7" },
      { pos: new v2(462.8783709815639, 462.32854696304014), extent: new v2(20.05698699951172, 1.2065362930297852), heading: 1.553867943325808, ty: "full", name: "SM_fenceSquare_big_light7" },
      { pos: new v2(463.9611435584035, 503.14740962889385), extent: new v2(19.044390869140624, 1.8922796249389648), heading: 1.553867943325808, ty: "half", name: "SM_fenceRack_big8" },
      { pos: new v2(462.3565448096889, 502.9694649317901), extent: new v2(20.05698699951172, 1.2065362930297852), heading: 1.553867943325808, ty: "full", name: "SM_fenceSquare_big_light8" },
      { pos: new v2(463.96126553172445, 542.9412674897815), extent: new v2(19.044390869140624, 1.8922796249389648), heading: 1.553861818055301, ty: "half", name: "SM_fenceRack_big9" },
      { pos: new v2(462.3566670235889, 542.6286963614914), extent: new v2(20.05698699951172, 1.2065362930297852), heading: 1.553861818055301, ty: "full", name: "SM_fenceSquare_big_light9" },
      { pos: new v2(463.96126553172445, 583.0959061616564), extent: new v2(19.044390869140624, 1.8922796249389648), heading: 1.5538618180553005, ty: "half", name: "SM_fenceRack_big10" },
      { pos: new v2(462.3566670235889, 582.7833350333664), extent: new v2(20.05698699951172, 1.2065362930297852), heading: 1.5538618180553005, ty: "full", name: "SM_fenceSquare_big_light10" },
      { pos: new v2(463.2572616254745, 623.5899491304065), extent: new v2(19.044390869140624, 1.8922796249389648), heading: 1.5538618180553005, ty: "half", name: "SM_fenceRack_big11" },
      { pos: new v2(461.6526631173389, 623.2773291739913), extent: new v2(20.05698699951172, 1.2065362930297852), heading: 1.5538618180553005, ty: "full", name: "SM_fenceSquare_big_light11" },
      { pos: new v2(463.25753473942314, 664.0081360451304), extent: new v2(19.044390869140624, 1.8922796249389648), heading: 1.5538481027756872, ty: "half", name: "SM_fenceRack_big12" },
      { pos: new v2(461.6529394269601, 664.9036052963472), extent: new v2(20.05698699951172, 1.2065362930297852), heading: 1.553847969617633, ty: "full", name: "SM_fenceSquare_big_light12" },
      { pos: new v2(463.257383598733, 704.8452816014161), extent: new v2(19.044390869140624, 1.8922796249389648), heading: 1.5538556927847942, ty: "half", name: "SM_fenceRack_big13" },
      { pos: new v2(461.6527959585143, 705.2506233579397), extent: new v2(20.05698699951172, 1.2065362930297852), heading: 1.5538551601525759, ty: "full", name: "SM_fenceSquare_big_light13" },
      { pos: new v2(463.257383598733, 745.0325862889161), extent: new v2(19.044390869140624, 1.8922796249389648), heading: 1.5538556927847942, ty: "half", name: "SM_fenceRack_big14" },
      { pos: new v2(461.6527959585143, 745.4379280454397), extent: new v2(20.05698699951172, 1.2065362930297852), heading: 1.5538551601525759, ty: "full", name: "SM_fenceSquare_big_light14" },
      { pos: new v2(446.5799933674825, 779.316791134947), extent: new v2(20.05698699951172, 1.2065362930297852), heading: 0.7054236355994509, ty: "full", name: "SM_fenceSquare_big_light15" },
      { pos: new v2(448.0799887841703, 780.2299291706339), extent: new v2(19.044390869140624, 1.8922796249389648), heading: 0.705423635599451, ty: "half", name: "SM_fenceSquare_big_light16" },
      { pos: new v2(399.61559869494147, 796.4629820295047), extent: new v2(20.05698699951172, 1.2065362930297852), heading: 0.04954730064902963, ty: "full", name: "SM_fenceSquare_big10_139" },
      { pos: new v2(426.20891084614544, 790.6810570946592), extent: new v2(12.558152770996095, 1.0624029159545898), heading: -0.10910646413296782, ty: "full", name: "SM_fenceSquare2_154" },
      { pos: new v2(100.1962101951409, 738.6619102854409), extent: new v2(19.044390869140624, 1.8922796249389648), heading: -1.595326170963399, ty: "half", name: "SM_fenceSquare_big11_156" },
      { pos: new v2(99.54436693342215, 778.2549766916909), extent: new v2(19.044390869140624, 1.8922796249389648), heading: -1.595326170963399, ty: "half", name: "SM_fenceSquare_big12" },
      { pos: new v2(101.83627977521903, 697.4008263010659), extent: new v2(19.044390869140624, 1.8922796249389648), heading: -1.595326170963399, ty: "half", name: "SM_fenceSquare_big13" },
      { pos: new v2(75.07223336843269, 51.17821856995915), extent: new v2(16.316555786132813, 0.5920787334442139), heading: 1.572539232570299, ty: "full", name: "SM_fenceMetal_221" },
      { pos: new v2(75.01598336843269, 18.90307513734196), extent: new v2(16.316555786132813, 0.5920787334442139), heading: 1.572539232570299, ty: "full", name: "SM_fenceMetal2_226" },
      { pos: new v2(74.95981881765144, -13.324791607653157), extent: new v2(16.316555786132813, 0.5920787334442139), heading: 1.572539232570299, ty: "full", name: "SM_fenceMetal3" },
      { pos: new v2(74.90356881765143, -45.59994610289242), extent: new v2(16.316555786132813, 0.5920787334442139), heading: 1.572539232570299, ty: "full", name: "SM_fenceMetal4" },
      { pos: new v2(74.84747750905768, -77.79480999449397), extent: new v2(16.316555786132813, 0.5920787334442139), heading: 1.572539232570299, ty: "full", name: "SM_fenceMetal5" },
      { pos: new v2(74.79122750905769, -110.06995037535336), extent: new v2(16.316555786132813, 0.5920787334442139), heading: 1.572539232570299, ty: "full", name: "SM_fenceMetal6" },
      { pos: new v2(74.73490426687019, -142.4150492523065), extent: new v2(16.316555786132813, 0.5920787334442139), heading: 1.572539232570299, ty: "full", name: "SM_fenceMetal7" },
      { pos: new v2(84.31065449553871, -112.00482463843821), extent: new v2(9.607099914550782, 5.93352279663086), heading: 1.5754470050065021, ty: "half", name: "SM_trash_box_298" },
      { pos: new v2(86.140547865275, -117.68054098690708), extent: new v2(3.2805042266845703, 2.6357059478759766), heading: -1.3613533544461598, ty: "half", name: "SM_trashbag_01_d7" },
      { pos: new v2(86.56064484903642, -110.9032804983512), extent: new v2(3.2805042266845703, 2.6357059478759766), heading: -2.6424443599595118, ty: "half", name: "SM_trashbag_01_d8" },
      { pos: new v2(86.59397883249275, -104.57836331129829), extent: new v2(2.30233154296875, 2.9886129379272464), heading: 0.6780440759065098, ty: "half", name: "SM_trashbag_02_b10" },
      { pos: new v2(82.42982797373078, -108.76314212560044), extent: new v2(2.30233154296875, 2.9886129379272464), heading: -2.647031388621064, ty: "half", name: "SM_trashbag_02_b11" },
      { pos: new v2(82.64520370508097, -113.88963791866406), extent: new v2(3.2805042266845703, 2.6357059478759766), heading: 1.3813571563420721, ty: "half", name: "SM_trashbag_01_d9" },
      { pos: new v2(80.25245226071955, -119.38861046076232), extent: new v2(3.2805042266845703, 2.6357059478759766), heading: 2.0266802369362953, ty: "half", name: "SM_trashbag_01_d10" },
      { pos: new v2(92.74997035570144, 68.74695446207902), extent: new v2(16.316555786132813, 0.5920787334442139), heading: -0.013771880109450666, ty: "full", name: "SM_fenceMetal8" },
      { pos: new v2(88.61241891530769, -215.09479778746274), extent: new v2(16.316555786132813, 0.5920787334442139), heading: 1.572539232570299, ty: "full", name: "SM_fenceMetal9" },
      { pos: new v2(88.55609567312018, -247.43986614683774), extent: new v2(16.316555786132813, 0.5920787334442139), heading: 1.572539232570299, ty: "full", name: "SM_fenceMetal10" },
      { pos: new v2(88.55609567312018, -279.6171366546502), extent: new v2(16.316555786132813, 0.5920787334442139), heading: 1.572539232570299, ty: "full", name: "SM_fenceMetal11" },
      { pos: new v2(79.56575940426333, 163.40776676448263), extent: new v2(8.618569946289062, 4.231289672851562), heading: -0.6019343274824348, ty: "half", name: "Barrier_Block_el3_381" },
      { pos: new v2(44.98612029844239, 149.65242271826145), extent: new v2(18.100746154785156, 4.700096130371094), heading: -0.1475973477497217, ty: "half", name: "Barrier_Block_el2_384" },
      { pos: new v2(18.282592739022927, 312.65198913472005), extent: new v2(16.306921386718752, 3.617316055297852), heading: -0.9495821111798604, ty: "half", name: "Mesh_barrier_01_421" },
      { pos: new v2(23.872231923190352, 327.9755414751659), extent: new v2(14.519396972656251, 23.772396850585938), heading: 0.04186201279311698, ty: "half", name: "Uaz_Rope_Block_424" },
      { pos: new v2(-296.83200607299807, 378.3661880493164), extent: new v2(8.107099151611328, 3.4478225708007812), heading: 0, ty: "half", name: "SM_wooden_pallet_c_427" },
      { pos: new v2(83.2371077025631, -50.13784358062665), extent: new v2(18.47344512939453, 8.753233337402344), heading: 1.6037777126827653, ty: "half", name: "Mesh_pipe_palette_5" },
      { pos: new v2(84.88853752216596, -135.74707050920446), extent: new v2(9.607099914550782, 5.991490936279297), heading: -1.572983580998179, ty: "half", name: "SM_trash_box_8" },
      { pos: new v2(-332.5371602765629, 540.0507777215689), extent: new v2(7.831741200038778, 1.500005626678467), heading: 3.141588925164267, ty: "full", name: "SM_WindowFence_11" },
      { pos: new v2(-376.76325890937534, 540.0507777215689), extent: new v2(7.831741200038778, 1.500005626678467), heading: 3.141588925164267, ty: "full", name: "SM_WindowFence_12" },
      { pos: new v2(-107.28937039063301, 504.10064368204576), extent: new v2(0.5258372306823731, 4.157370376586914), heading: 0.6171729352398732, ty: "half", name: "SM_plywood_38" },
      { pos: new v2(88.55610177663581, -383.68749798277526), extent: new v2(16.316555786132813, 0.5920787334442139), heading: 1.572539232570299, ty: "full", name: "SM_fenceMetal13" },
      { pos: new v2(105.89875071614466, -400.60180534618144), extent: new v2(16.316555786132813, 0.5920787334442139), heading: -0.014391095231515815, ty: "full", name: "SM_fenceMetal14" },
      { pos: new v2(138.26440623372278, -400.13588737743146), extent: new v2(16.316555786132813, 0.5920787334442139), heading: -0.014391095231515815, ty: "full", name: "SM_fenceMetal15_33" },
      { pos: new v2(170.4577411946603, -399.67272819774394), extent: new v2(16.316555786132813, 0.5920787334442139), heading: -0.014391095231515815, ty: "full", name: "SM_fenceMetal16" },
      { pos: new v2(202.82337840169154, -399.20681022899396), extent: new v2(16.316555786132813, 0.5920787334442139), heading: -0.014391095231515815, ty: "full", name: "SM_fenceMetal17" },
      { pos: new v2(305.04621775716026, -397.85998405711894), extent: new v2(16.316555786132813, 0.5920787334442139), heading: -0.014391095231515815, ty: "full", name: "SM_fenceMetal20" },
      { pos: new v2(372.29680175781255, 38.61991729736329), extent: new v2(4.820896148681641, 2.492532730102539), heading: 0, ty: "full", name: "Locker2_114" },
      { pos: new v2(233.38504393857048, 43.48597257945284), extent: new v2(9.000015258789062, 1.500005626678467), heading: 0.4986118996972262, ty: "full", name: "SM_WindowFence_117" },
      { pos: new v2(198.80520172119142, 87.169287109375), extent: new v2(18.47344512939453, 8.753233337402344), heading: 0, ty: "half", name: "Mesh_pipe_palette_121" },
      { pos: new v2(120.97093468864314, 158.00927725071398), extent: new v2(5.604754257202149, 5.604753875732422), heading: 2.8952193600888183, ty: "half", name: "TrashCanBig_131" },
      { pos: new v2(78.06041296879278, -414.9528321194364), extent: new v2(5.604754257202149, 5.604753875732422), heading: 0.2332854379951322, ty: "half", name: "TrashCanBig2_134" },
      { pos: new v2(453.27571296796253, -59.41007789505259), extent: new v2(8.753229950468995, 4.542104539963475), heading: -1.530356092483989, ty: "half", name: "Mesh_woodencrate_83" },
      { pos: new v2(-78.78613455007226, -13.55062057114017), extent: new v2(12.555992126464844, 1.1543828964233398), heading: -0.0000011318434632836763, ty: "full", name: "SM_fenceRound4_31" },
      { pos: new v2(-103.70435964821866, -13.550620538496526), extent: new v2(12.555992126464844, 1.1543828964233398), heading: -0.000001134464016884697, ty: "full", name: "SM_fenceRound5" },
      { pos: new v2(-129.0376588187207, -15.737894257355672), extent: new v2(12.555992126464844, 1.1543828964233398), heading: -0.17453455638560061, ty: "full", name: "SM_fenceRound6" },
      { pos: new v2(-65.46959409825007, -101.2702171940342), extent: new v2(12.555992126464844, 1.1543828964233398), heading: 1.5607591389624973, ty: "full", name: "SM_fenceRound9" },
      { pos: new v2(-65.38374066228332, -126.39566088063337), extent: new v2(12.555992126464844, 1.1543828964233398), heading: 1.5607531468500446, ty: "full", name: "SM_fenceRound10" },
      { pos: new v2(-65.38078336901796, -151.2802119981408), extent: new v2(12.555992126464844, 1.1543828964233398), heading: 1.5265915811251074, ty: "full", name: "SM_fenceRound11" },
      { pos: new v2(-65.02079549748909, -176.52165007145751), extent: new v2(12.555992126464844, 1.1543828964233398), heading: 1.5671158381684074, ty: "full", name: "SM_fenceRound12" },
      { pos: new v2(-65.53283778471385, -201.5348596834352), extent: new v2(12.555992126464844, 1.1543828964233398), heading: 1.6012831296896877, ty: "full", name: "SM_fenceRound13" },
      { pos: new v2(-81.25715187631147, -214.24438038535087), extent: new v2(15.102986904871614, 1.1543828964233398), heading: -3.132972267457316, ty: "full", name: "SM_fenceRound14" },
      { pos: new v2(-98.5864251450177, -239.6445150083506), extent: new v2(25.495397949218752, 0.7797318935394287), heading: -1.5839507446832337, ty: "full", name: "ChainlinkFence_Tall_A3" },
      { pos: new v2(-130.64032897949218, -270.92035951614383), extent: new v2(25.495397949218752, 0.8275287628173829), heading: 0, ty: "full", name: "ChainlinkFence_Tall_B_76" },
      { pos: new v2(-64.27912938504022, -112.32404936151441), extent: new v2(19.044390869140624, 1.8922796249389648), heading: 1.5632445340498136, ty: "half", name: "SM_fenceRack_big18" },
      { pos: new v2(-63.97905614285273, -152.06812895135815), extent: new v2(19.044390869140624, 1.8922796249389648), heading: 1.5632445340498136, ty: "half", name: "SM_fenceRack_big19" },
      { pos: new v2(-64.26053809276895, -192.0979527058771), extent: new v2(19.044390869140624, 1.8922796249389648), heading: 1.5912804297934047, ty: "half", name: "SM_fenceRack_big20" },
      { pos: new v2(-81.54905716581533, -215.89469211753482), extent: new v2(11.216697692871094, 1.892277717590332), heading: -3.1347826843663515, ty: "half", name: "SM_fenceRack2_106" },
      { pos: new v2(-99.96409790218797, -392.2558864007153), extent: new v2(20.05698699951172, 1.2065362930297852), heading: 1.5697539655442405, ty: "full", name: "SM_fenceSquare_big_light17_155" },
      { pos: new v2(-99.92279844859893, -432.09851400866194), extent: new v2(20.05698699951172, 1.2065362930297852), heading: 1.5697580934439292, ty: "full", name: "SM_fenceSquare_big_light18" },
      { pos: new v2(-99.88088029181175, -472.48288896642566), extent: new v2(20.05698699951172, 1.2065362930297852), heading: 1.5697578271278205, ty: "full", name: "SM_fenceSquare_big_light19" },
      { pos: new v2(-99.79796934703643, -552.330838227412), extent: new v2(20.05698699951172, 1.2065362930297852), heading: 1.5697580934439292, ty: "full", name: "SM_fenceSquare_big_light21" },
      { pos: new v2(-99.62033077831096, -584.7279033177778), extent: new v2(12.558152770996095, 1.0624029159545898), heading: 1.5697580934439292, ty: "full", name: "SM_fenceSquare_big_light22" },
      { pos: new v2(-216.3746590062143, 354.0762264879072), extent: new v2(24.305575020694597, 2.096803108903896), heading: 0, ty: "full", name: "sm_wall_precast_8m_E9" },
      { pos: new v2(435.16025711270333, 145.08205553537874), extent: new v2(27.017910766601563, 17.368453979492188), heading: 3.141592120957575, ty: "full", name: "SovietGarage_218" },
      { pos: new v2(435.16025711270333, 111.93671862131623), extent: new v2(27.017910766601563, 17.368453979492188), heading: 3.141592120957575, ty: "full", name: "SovietGarage2_221" },
      { pos: new v2(435.1601106283283, 178.4198851252225), extent: new v2(27.017910766601563, 17.368453979492188), heading: 3.141592120957575, ty: "full", name: "SovietGarage3" },
      { pos: new v2(433.01038385088236, 85.3025693642898), extent: new v2(10.605496215820313, 17.249517822265627), heading: 1.587687825482888, ty: "half", name: "Planks30_243" },
      { pos: new v2(435.07949132228333, 87.78484576225985), extent: new v2(3.0013858795166017, 1.0702152252197266), heading: -0.5201235168233139, ty: "half", name: "Can4_246" },
      { pos: new v2(406.4701013770905, 131.0176480182654), extent: new v2(11.716199493408205, 3.0456130981445315), heading: -1.4337250233851717, ty: "half", name: "SM_Bicycle02_273" },
      { pos: new v2(124.78936064965231, 196.9869386693664), extent: new v2(20.05698699951172, 1.2065362930297852), heading: -3.13300316012596, ty: "full", name: "SM_fenceSquare_big_light23_276" },
      { pos: new v2(164.64261728163274, 196.74973293449864), extent: new v2(20.05698699951172, 1.2065362930297852), heading: 3.1399268463279486, ty: "full", name: "SM_fenceSquare_big_light24" },
      { pos: new v2(244.5983179652265, 196.88327785637364), extent: new v2(20.05698699951172, 1.2065362930297852), heading: 3.1399268463279486, ty: "full", name: "SM_fenceSquare_big_light26" },
      { pos: new v2(284.730275973039, 196.94990383293612), extent: new v2(20.05698699951172, 1.2065362930297852), heading: 3.1399268463279486, ty: "full", name: "SM_fenceSquare_big_light27" },
      { pos: new v2(324.5254664027265, 197.01620021965488), extent: new v2(20.05698699951172, 1.2065362930297852), heading: 3.1399268463279486, ty: "full", name: "SM_fenceSquare_big_light28" },
      { pos: new v2(364.8949976527265, 197.08348537590487), extent: new v2(20.05698699951172, 1.2065362930297852), heading: 3.1399268463279486, ty: "full", name: "SM_fenceSquare_big_light29" },
      { pos: new v2(405.1919703089765, 197.15052639152987), extent: new v2(20.05698699951172, 1.2065362930297852), heading: 3.1399268463279486, ty: "full", name: "SM_fenceSquare_big_light30" },
      { pos: new v2(445.1488062464765, 197.21711574699862), extent: new v2(20.05698699951172, 1.2065362930297852), heading: 3.1399268463279486, ty: "full", name: "SM_fenceSquare_big_light31" },
      { pos: new v2(175.9739181492685, 149.49639146946168), extent: new v2(0.8386893272399902, 3.125382995605469), heading: 1.6579550645285195, ty: "half", name: "SM_tire_297" },
      { pos: new v2(193.49586759006544, 148.59481872884248), extent: new v2(0.8386893272399902, 3.125382995605469), heading: -1.6045921073441112, ty: "half", name: "SM_tire_42" },
      { pos: new v2(211.0071002960102, 149.8710033396107), extent: new v2(0.8386893272399902, 3.125382995605469), heading: -1.559929564282937, ty: "half", name: "SM_tire_43" },
      { pos: new v2(103.30313711302627, 161.27920466256427), extent: new v2(12.555992126464844, 1.1543828964233398), heading: -1.474236896729403, ty: "full", name: "SM_fenceRound_light2" },
      { pos: new v2(-170.61660544052071, 57.38200884744806), extent: new v2(24.739326493010413, 1.228908262177913), heading: 3.1392072602014096, ty: "full", name: "sm_wall_precast_8m_E10" },
      { pos: new v2(-219.4606239952082, 57.265456113073064), extent: new v2(24.739326493010413, 1.228908262177913), heading: 3.1392072602014096, ty: "full", name: "sm_wall_precast_8m_E11" },
      { pos: new v2(-64.5639045715332, -17.27019691467285), extent: new v2(7.046244812011719, 2.998703956604004), heading: 0, ty: "half", name: "SM_rusty_pipe_349" },
      { pos: new v2(-66.38461411861408, -7.683003437898805), extent: new v2(2.761950756429178, 8.330450248718263), heading: -1.0555016869496279, ty: "half", name: "SM_brokenwall_concrete2_370" },
      { pos: new v2(-58.46008664359294, -114.43965016461667), extent: new v2(7.891975795135295, 4.048093761280143), heading: -0.42156987844405536, ty: "half", name: "ConcreteBlock4_373" },
      { pos: new v2(-127.52303619384793, 47.51842929998625), extent: new v2(4.820896148681641, 2.492532730102539), heading: 1.5707983241657142, ty: "full", name: "Locker3_394" },
      { pos: new v2(461.4886011410202, 169.12674924998873), extent: new v2(25.495397949218752, 0.8275287628173829), heading: -1.570798457323769, ty: "full", name: "ChainlinkFence_Tall_B2_2" },
      { pos: new v2(461.4873804378952, 119.25171262889496), extent: new v2(25.495397949218752, 0.8275287628173829), heading: -1.570798457323769, ty: "full", name: "ChainlinkFence_Tall_B12" },
      { pos: new v2(461.4874292660202, 69.46903440623872), extent: new v2(25.495397949218752, 0.8275287628173829), heading: -1.570798457323769, ty: "full", name: "ChainlinkFence_Tall_B13" },
      { pos: new v2(466.5898835468618, 19.462993641318658), extent: new v2(25.495397949218752, 5.929933547973633), heading: -1.570798457323769, ty: "full", name: "ChainlinkFence_Tall_B14" },
      { pos: new v2(409.06158473302503, 128.85102295708006), extent: new v2(3.793110420042126, 1.5186639663505161), heading: -3.1415894577964854, ty: "half", name: "sm_en_Lamp_01_18" },
      { pos: new v2(421.08951576654977, 143.36538558027505), extent: new v2(0.8386893272399902, 3.125382995605469), heading: -5.327068877928099e-7, ty: "half", name: "SM_tire_93" },
      { pos: new v2(417.9964634042267, 146.37895247804616), extent: new v2(0.8386893272399902, 3.125382995605469), heading: 0.00003585574013772394, ty: "half", name: "SM_tire_45" },
      { pos: new v2(340.1098495674474, 73.56190630293707), extent: new v2(0.8386893272399902, 3.125382995605469), heading: 1.504924102496223, ty: "half", name: "SM_tire_227" },
      { pos: new v2(355.3834922877362, 75.29089273046466), extent: new v2(0.8386893272399902, 3.125382995605469), heading: -1.5682950858990943, ty: "half", name: "SM_tire_228" },
      { pos: new v2(374.1767420491617, 72.9894416301292), extent: new v2(0.8386893272399902, 3.125382995605469), heading: 1.5511384695245858, ty: "half", name: "SM_tire_48" },
      { pos: new v2(387.6181584046904, 70.34248283882623), extent: new v2(0.8386893272399902, 3.125382995605469), heading: -0.7984870675229697, ty: "half", name: "SM_tire_49" },
      { pos: new v2(388.7265508692642, 53.56325841450842), extent: new v2(0.8386893272399902, 3.125382995605469), heading: 0.2640982284521058, ty: "half", name: "SM_tire_50" },
      { pos: new v2(452.6101322425297, -284.03768955750303), extent: new v2(9.607099914550782, 5.93352279663086), heading: 1.6039697265973598, ty: "half", name: "SM_trash_box_03_297" },
      { pos: new v2(85.01136658265946, -91.77685124139417), extent: new v2(9.607099914550782, 5.991490936279297), heading: -1.5556782270767893, ty: "half", name: "SM_trash_box_01_304" },
      { pos: new v2(133.29938939768607, -354.42726470494375), extent: new v2(4.791286468505859, 3.2866165161132814), heading: -2.039602427177579, ty: "half", name: "SM_block_437" },
      { pos: new v2(114.4977387939532, -309.0651695253041), extent: new v2(8.650191194697527, 3.0326029948689213), heading: 0.8100666251006888, ty: "half", name: "SM_Block_439" },
      { pos: new v2(-610.0006839727112, 599.9993393342356), extent: new v2(18.100746154785156, 4.700096130371094), heading: -0.17561370254881434, ty: "half", name: "Barrier_Block_el4_15" },
      { pos: new v2(-629.9999984741212, 510.00000076293946), extent: new v2(9.162496185302734, 9.162495422363282), heading: 0, ty: "half", name: "Spool6_18" },
      { pos: new v2(-630.0000007575399, 289.99999990939074), extent: new v2(26.082275390625, 11.164122009277344), heading: 1.4517519608039187, ty: "full", name: "Trash_container3_24" },
      { pos: new v2(-670.0008856954256, -40.000346181361266), extent: new v2(18.100746154785156, 4.700096130371094), heading: 0.21985215385114987, ty: "half", name: "Barrier_Block_el5_30" },
      { pos: new v2(419.9999938964844, 935), extent: new v2(25.05202331542969, 13.305975341796875), heading: 0, ty: "full", name: "FuelTank3_53" },
      { pos: new v2(-373.27481327056887, -57.48799419403076), extent: new v2(3.0479290008544924, 3.020808029174805), heading: 0, ty: "full", name: "SM_UbranPillar_A_31" },
      { pos: new v2(-459.61944111050246, -397.7747588636639), extent: new v2(9.607099914550782, 11.59992141723633), heading: -1.7453317819973655, ty: "full", name: "SM_trash_box_6" },
      { pos: new v2(-457.724836599775, -371.10269338121435), extent: new v2(9.607099914550782, 5.991490936279297), heading: -1.570798457323769, ty: "half", name: "SM_trash_box_10" },
      { pos: new v2(-460.7014644622803, -416.13115234375005), extent: new v2(5.604754257202149, 5.604753875732422), heading: 0, ty: "half", name: "TrashCanBig3_13" },
      { pos: new v2(-322.0305904388428, -280.031982421875), extent: new v2(5.604754257202149, 5.604753875732422), heading: 0, ty: "half", name: "TrashCanBig4_18" },
      { pos: new v2(-328.6663576880883, -285.8561764845916), extent: new v2(5.604754257202149, 5.604753875732422), heading: -2.3432043893432657, ty: "half", name: "TrashCanBig5" },
      { pos: new v2(-340.96403884887695, -295.51022949218753), extent: new v2(4.642416763305664, 4.8829086303710945), heading: 0, ty: "half", name: "Trash_PlasticBag_01_22" },
      { pos: new v2(77.63032302856446, -433.2220397949219), extent: new v2(2.7589267730712894, 3.0529123306274415), heading: 0, ty: "half", name: "SM_trashbag_01_b6_52" },
      { pos: new v2(84.11032078116594, -394.6764219134979), extent: new v2(3.2805042266845703, 2.6357059478759766), heading: -2.792525205294273, ty: "half", name: "SM_trashbag_01_d6_55" },
      { pos: new v2(81.3995849609375, -406.57431774139405), extent: new v2(3.196453475952149, 2.5624307632446293), heading: 0, ty: "half", name: "SM_trashbag_02_a3_58" },
      { pos: new v2(-372.35617370605473, -270.5604718208313), extent: new v2(25.495397949218752, 0.8275287628173829), heading: 0, ty: "full", name: "ChainlinkFence_Tall_B26" },
      { pos: new v2(-405.58383789062503, -264.40500488281253), extent: new v2(10, 2.5), heading: 0, ty: "full", name: "SM_Wall_OldWall_2m3_72" },
      { pos: new v2(-648.9092780947685, 227.5573429107666), extent: new v2(1.3444457054138184, 18.448204040527344), heading: 0, ty: "full", name: "SovietGarage_door16" },
      { pos: new v2(-648.9092780947685, 280.9678897857666), extent: new v2(1.3444457054138184, 18.448204040527344), heading: 0, ty: "full", name: "SovietGarage_door17" },
      { pos: new v2(142.86440620422363, -9.256196594238281), extent: new v2(2.889277267456055, 2.5003608703613285), heading: 0, ty: "half", name: "SM_cardboardbox_2" },
      { pos: new v2(139.30000057923755, 20.85000000000012), extent: new v2(12.872396850585938, 6.6795654296875), heading: -1.57079672626906, ty: "half", name: "Mesh_woodencrate_2" },
      { pos: new v2(146.309521484375, -0.7198120117187502), extent: new v2(12.872396850585938, 6.6795654296875), heading: -3.141592653589793, ty: "half", name: "Mesh_woodencrate_3" },
      { pos: new v2(-116.4000186920166, -21.69999580383301), extent: new v2(4.079704284667969, 4.079694747924805), heading: 0, ty: "half", name: "Mesh_oildrum_2" },
      { pos: new v2(-138.46369261317528, -24.82683249325642), extent: new v2(18.473443603515626, 6.0306442260742195), heading: -0.08726657079063577, ty: "half", name: "Mesh_pipe_palette_01_47" },
      { pos: new v2(-79.44612347445525, -88.4098144601287), extent: new v2(7.013053894042969, 17.823416137695315), heading: -2.879793798422862, ty: "half", name: "sm_en_MetalSupport_01_01_109" },
      { pos: new v2(-88.25515145209955, -25.758845681115318), extent: new v2(17.96138610839844, 9.354724121093751), heading: -1.7476994653645004e-7, ty: "half", name: "sm_en_RubbleContainer_2" },
      { pos: new v2(-89.03978285897699, -25.815295445648587), extent: new v2(14.130250549316408, 8.694176100249388), heading: -3.141592120957575, ty: "half", name: "sm_en_TrashBag_01_3" },
      { pos: new v2(-192.0486130755032, -20.360049334916486), extent: new v2(9.920210266113282, 4.993792343139649), heading: -3.141590523060921, ty: "full", name: "Mesh_locker_01_38" },
      { pos: new v2(-177.80000000000064, -21.899999618530273), extent: new v2(0.8283448219299316, 7.933695983886719), heading: -1.5707979246915509, ty: "half", name: "SM_gaspipes_02_145" },
      { pos: new v2(289.3514933151885, -375.94867214368327), extent: new v2(8.784309387207031, 8.500008392333985), heading: -1.919864707196798, ty: "half", name: "SM_Planter_box_01_23" },
      { pos: new v2(217.8753173828125, -364.6188987731934), extent: new v2(8.784309387207031, 8.500008392333985), heading: 0, ty: "half", name: "SM_Planter_box_26" },
      { pos: new v2(246.9507879947652, -403.33831825419935), extent: new v2(9.375679016113281, 3.2869461059570315), heading: 0.201455353291262, ty: "half", name: "SM_Block_46" },
      { pos: new v2(275.65090764562655, -405.3439273094826), extent: new v2(4.791286468505859, 3.2866165161132814), heading: -0.22343831664078018, ty: "half", name: "SM_block_49" },
      { pos: new v2(69.857151152887, 505.87318663620766), extent: new v2(5.344207382202149, 2.1396833419799806), heading: 3.1203853691972743, ty: "half", name: "sm_en_Lamp_01_52" },
      { pos: new v2(-98.84434909820557, -236.17036209106448), extent: new v2(0.5258372306823731, 4.157370376586914), heading: 0, ty: "half", name: "SM_plywood_8" },
      { pos: new v2(-103.28337326049805, -245.1483154296875), extent: new v2(3.821454620361328, 3.821454620361328), heading: 0, ty: "half", name: "SM_MetalBarrel_green2_17" },
      { pos: new v2(-109.40959396362305, -248.85078125), extent: new v2(3.821454620361328, 3.821454620361328), heading: 0, ty: "half", name: "SM_MetalBarrel_red6_20" },
      { pos: new v2(-103.63986740112306, -254.70915527343752), extent: new v2(3.821454620361328, 3.821454620361328), heading: 0, ty: "half", name: "SM_MetalBarrel_blue5_23" },
      { pos: new v2(132.5747146106642, -523.9830087986953), extent: new v2(11.35455780029297, 21.19658660888672), heading: -1.5595394111832406, ty: "full", name: "SM_Shed_C_33" },
      { pos: new v2(47.3318912021305, -179.69215069279636), extent: new v2(5.604754257202149, 5.604753875732422), heading: -0.5096547970257794, ty: "half", name: "TrashCanBig6_41" },
      { pos: new v2(-39.41251624888462, -99.73982658220768), extent: new v2(16.306921386718752, 3.617316055297852), heading: -0.057570872381220335, ty: "half", name: "Mesh_barrier_50" },
      { pos: new v2(422.25920631516897, 144.28877294525702), extent: new v2(0.8386893272399902, 3.125382995605469), heading: 1.5706562445216292, ty: "half", name: "SM_tire_51" },
      { pos: new v2(420.7232361138261, 146.1235603256791), extent: new v2(0.8386893272399902, 3.125382995605469), heading: 0.7835830859565217, ty: "half", name: "SM_tire_52" },
      { pos: new v2(-281.7034362792969, -333.60849591880367), extent: new v2(11.716199493408205, 3.0456130981445315), heading: -4.848256314364578e-8, ty: "half", name: "SM_Bicycle3_58" },
      { pos: new v2(-20.531204641930046, -84.57213283521588), extent: new v2(8.618569946289062, 4.231289672851562), heading: 1.9020324468537366, ty: "half", name: "Barrier_Block_el6_61" },
      { pos: new v2(-63.826481342315674, -227.57954807281496), extent: new v2(0.8386893272399902, 3.125382995605469), heading: 0, ty: "half", name: "SM_tire_85" },
      { pos: new v2(-63.82647523880005, -238.82908420562745), extent: new v2(0.8386893272399902, 3.125382995605469), heading: 0, ty: "half", name: "SM_tire_34" },
      { pos: new v2(-63.82647523880005, -254.81677951812745), extent: new v2(0.8386893272399902, 3.125382995605469), heading: 0, ty: "half", name: "SM_tire_35" },
      { pos: new v2(-64.63392926724146, -272.5059103594437), extent: new v2(0.8386893272399902, 3.125382995605469), heading: -3.127891755045771, ty: "half", name: "SM_tire_36" },
      { pos: new v2(-83.67300787067943, -369.2525764960722), extent: new v2(0.8386893272399902, 3.125382995605469), heading: 1.6973505407437894, ty: "half", name: "SM_tire_92" },
      { pos: new v2(-65.75284795857004, -371.3009163398222), extent: new v2(0.8386893272399902, 3.125382995605469), heading: 1.6973505407437894, ty: "half", name: "SM_tire_54" },
      { pos: new v2(-51.56021123837761, -373.7586030890823), extent: new v2(0.8386893272399902, 3.125382995605469), heading: 2.0274826473727368, ty: "half", name: "SM_tire_55" },
      { pos: new v2(448.56947221625444, -370.71514160817605), extent: new v2(11.35455780029297, 21.19658660888672), heading: -3.1415018397966215, ty: "full", name: "SM_Shed_C2_102" },
      { pos: new v2(453.2700228477154, -304.6021934413115), extent: new v2(7.11224365234375, 10.770631408691408), heading: -3.129443579012961, ty: "full", name: "SM_Shed_B_106" },
      { pos: new v2(326.885545986072, -421.4360510822421), extent: new v2(21.115380837315026, 0.8275287628173829), heading: 1.5721303041849177, ty: "full", name: "ChainlinkFence_Tall_B27_109" },
      { pos: new v2(-74.45929222106933, 562.0673858642579), extent: new v2(6.341335678100586, 18.4778564453125), heading: 0, ty: "full", name: "powerlinesB_117" },
      { pos: new v2(433.6578426361084, -389.61169013977053), extent: new v2(4.079704284667969, 4.079694747924805), heading: 0, ty: "half", name: "Mesh_oildrum_157" },
      { pos: new v2(-289.32240951005434, -595.0195055883988), extent: new v2(5.344207382202149, 2.1396833419799806), heading: -1.4844599732058905, ty: "half", name: "sm_en_Lamp_01_196" },
      { pos: new v2(-128.82376856505556, -255.92951909685974), extent: new v2(8.753229950468995, 4.542104539963475), heading: -0.0000025307273832075786, ty: "half", name: "Mesh_woodencrate_65" },
      { pos: new v2(-133.2862805829592, -265.3566009402354), extent: new v2(4.547786943587698, 4.542104539963475), heading: 1.57079672626906, ty: "half", name: "Mesh_woodencrate_71" },
      { pos: new v2(-114.5999723428497, -260.81957335378974), extent: new v2(8.753229950468995, 4.542104539963475), heading: -1.5427915900360587, ty: "half", name: "Mesh_woodencrate_78" },
      { pos: new v2(-142.78627982205037, -261.1231582134961), extent: new v2(8.753229950468995, 4.542104539963475), heading: -1.5707957941626787, ty: "half", name: "Mesh_woodencrate_79" },
      { pos: new v2(-124.2217786298342, -265.3566009402354), extent: new v2(4.547786943587698, 4.542104539963475), heading: 1.57079672626906, ty: "half", name: "Mesh_woodencrate_80" },
      { pos: new v2(-98.5864251450177, -179.1445150083506), extent: new v2(25.495397949218752, 0.7797318935394287), heading: -1.5839507446832337, ty: "full", name: "ChainlinkFence_Tall_A4" },
      { pos: new v2(-98.5864251450177, -119.64451500835058), extent: new v2(25.495397949218752, 0.7797318935394287), heading: -1.5839507446832337, ty: "full", name: "ChainlinkFence_Tall_A5" },
      { pos: new v2(384.6203750610352, -378.7202655792237), extent: new v2(19.82168121337891, 6.008333587646485), heading: 0, ty: "full", name: "ClothesLine_8" },
      { pos: new v2(455.4325551479293, -333.5605526916756), extent: new v2(19.276843261718753, 0.6734139442443848), heading: 1.5564789064585214, ty: "half", name: "ClothesLine_A_12" },
      { pos: new v2(449.75173763302666, -333.5642175810988), extent: new v2(19.276843261718753, 0.6734143257141114), heading: 1.5564789064585214, ty: "half", name: "ClothesLine_B_14" },
      { pos: new v2(85.75491837481954, -476.8558524377287), extent: new v2(2.421062469482422, 4.459359741210938), heading: 1.570798856797932, ty: "full", name: "SM_MailBoxes_30" },
      { pos: new v2(94.7119862459133, -476.8558524377287), extent: new v2(2.421062469482422, 4.459359741210938), heading: 1.570798856797932, ty: "full", name: "SM_MailBoxes2" },
      { pos: new v2(104.32635384794119, -362.5729666766066), extent: new v2(2.421062469482422, 4.459359741210938), heading: 1.570798457323769, ty: "full", name: "SM_MailBoxes3_37" },
      { pos: new v2(95.49704476590995, -362.5729666766066), extent: new v2(2.421062469482422, 4.459359741210938), heading: 1.570798457323769, ty: "full", name: "SM_MailBoxes4_39" },
      { pos: new v2(57.087618249602976, -685.7738924956533), extent: new v2(9.607099914550782, 5.991490936279297), heading: -1.745330317258766, ty: "half", name: "SM_trash_box_63" },
      { pos: new v2(470.275500869751, -409.0014657974243), extent: new v2(3.0024177551269533, 3.0012832641601563), heading: 0, ty: "full", name: "SM_rusty_pipe_77" },
      { pos: new v2(470.27914818941304, -404.80571213516157), extent: new v2(7.046244812011719, 2.998703956604004), heading: 1.570798457323769, ty: "half", name: "SM_rusty_pipe_8" },
      { pos: new v2(470.2974276351929, -397.6388750457764), extent: new v2(3.0024177551269533, 3.0012832641601563), heading: -0.6435011087932844, ty: "full", name: "SM_rusty_pipe_81" },
      { pos: new v2(470.27576865177855, -371.96938381191507), extent: new v2(3.0024177551269533, 3.0012832641601563), heading: 3.1415923872736844, ty: "full", name: "SM_rusty_pipe_84" },
      { pos: new v2(470.275768642058, -346.3337881087544), extent: new v2(3.0024177551269533, 3.0012832641601563), heading: 3.141592120957575, ty: "full", name: "SM_rusty_pipe_22" },
      { pos: new v2(474.4202303524124, -321.0017317067599), extent: new v2(7.046244812011719, 2.998703956604004), heading: -3.0584514217013514, ty: "half", name: "SM_rusty_pipe_23" },
      { pos: new v2(470.282190322876, -310.3002939224243), extent: new v2(3.0024177551269533, 3.0012832641601563), heading: 0, ty: "full", name: "SM_rusty_pipe_89" },
      { pos: new v2(470.07635845023555, -408.8738132311585), extent: new v2(7.997100203862101, 7.88175909160509), heading: 0, ty: "half", name: "SM_rusty_pipe_04_95" },
      { pos: new v2(470.0763584375393, -310.757114001533), extent: new v2(7.997095028894819, 7.881753991275673), heading: 0, ty: "half", name: "SM_rusty_pipe_25" },
      { pos: new v2(323.94710651465857, -373.45272391417734), extent: new v2(20.05698699951172, 1.2065362930297852), heading: -1.5707979246915509, ty: "full", name: "SM_fenceSquare_big_light32_2" },
      { pos: new v2(297.1545275194136, -314.3811688750641), extent: new v2(12.583653259277344, 5.952317047119141), heading: 0.740912454232656, ty: "half", name: "SM_Sofa_15" },
      { pos: new v2(-212.67629394531252, 87.4737648010254), extent: new v2(8.784309387207031, 8.500008392333985), heading: 0, ty: "half", name: "SM_Planter_box_33" },
      { pos: new v2(-211.693212890625, 162.94628677368166), extent: new v2(8.784309387207031, 8.500008392333985), heading: 0, ty: "half", name: "SM_Planter_box_36" },
      { pos: new v2(-106.2846993390959, 359.41650810111264), extent: new v2(3.3000000000000003, 2.8523178100585938), heading: 1.3127992672421072, ty: "half", name: "SM_Washer02_14" },
      { pos: new v2(-181.02441968478252, -588.7657122777367), extent: new v2(9.607099914550782, 5.991490936279297), heading: 1.483530796301562, ty: "half", name: "SM_trash_box_2" },
      { pos: new v2(-55.43333473205567, 149.4464973449707), extent: new v2(5.222981643676758, 9.27197952270508), heading: 0, ty: "half", name: "SM_tire_pile_10" },
      { pos: new v2(-75.15592384338379, -273.3393524169922), extent: new v2(3.8759918212890625, 18.4778564453125), heading: 0, ty: "full", name: "powerlinesB2_120" },
      { pos: new v2(-416.5, -330.5), extent: new v2(10, 2.5), heading: -1.5707964599529514, ty: "full", name: "SM_UbranWall_A_4m121" },
      { pos: new v2(-416.5, -270.5), extent: new v2(10, 2.5), heading: -1.5707964599529514, ty: "full", name: "SM_UbranWall_A_4m125" },
      { pos: new v2(79.46240468037848, -417.48987597081253), extent: new v2(0.5258372306823731, 4.157370376586914), heading: -0.6853550523100226, ty: "half", name: "SM_plywood_6" },
      { pos: new v2(125.21683355557478, 167.84404009478055), extent: new v2(0.5258372306823731, 4.157370376586914), heading: -0.07170721024703737, ty: "half", name: "TrashCanBig7" },
      { pos: new v2(-59.400001850342555, -87.50656242370665), extent: new v2(1.9411300659179689, 4.735214614868164), heading: 3.141591854641466, ty: "half", name: "pipes_pipe_90_B_2" },
      { pos: new v2(-59.93613862792828, -26.487643638931686), extent: new v2(5.355934039724843, 5.278686115656228), heading: -0.03439267817328536, ty: "half", name: "SM_rusty_pipe_11" },
      { pos: new v2(-59.823063342480275, -34.693550142055535), extent: new v2(3.5886470794677736, 7.077127075195313), heading: 3.1072080481235624, ty: "half", name: "SM_rusty_pipe_12" },
      { pos: new v2(-166.1024898055212, -79.13443676767187), extent: new v2(8.618569946289062, 4.231289672851562), heading: 1.48353132893378, ty: "half", name: "Barrier_Block_el7_63" },
      { pos: new v2(-270.111459325664, -81.31762430779757), extent: new v2(11.766343688964845, 13.544631958007812), heading: -0.0000014137166647513902, ty: "half", name: "Mesh_cementbag_pile_28" },
      { pos: new v2(-205.30647680309536, -30.24960393635682), extent: new v2(5.222981643676758, 9.27197952270508), heading: -0.26180018674747646, ty: "half", name: "SM_tire_pile2_66" },
      { pos: new v2(-400.97965825437944, -117.84028007727812), extent: new v2(25.495397949218752, 0.8275287628173829), heading: -1.5707965931110057, ty: "full", name: "ChainlinkFence_Tall_B7" },
      { pos: new v2(-370.2041051329185, -87.33957933064403), extent: new v2(25.495397949218752, 0.8275287628173829), heading: 3.14159078937703, ty: "full", name: "ChainlinkFence_Tall_B32" },
      { pos: new v2(-345.54324520486125, -70.08545817722128), extent: new v2(15.19219863615308, 1.1543828964233398), heading: 1.5607526142178267, ty: "full", name: "SM_fenceRound16" },
      { pos: new v2(-400.97965825437944, -236.44028007727812), extent: new v2(25.495397949218752, 0.8275287628173829), heading: -1.5707965931110057, ty: "full", name: "ChainlinkFence_Tall_B24_109" },
      { pos: new v2(-400.9396025830313, -178.55616123970395), extent: new v2(25.495397949218752, 0.8275287628173829), heading: -1.5707972589012784, ty: "full", name: "ChainlinkFence_Tall_B35" },
      { pos: new v2(-78.69677886962891, -11.563999843597413), extent: new v2(11.216697692871094, 1.892277717590332), heading: 0, ty: "half", name: "SM_fenceRack_big15_44" },
      { pos: new v2(-103.79677886962891, -11.563999843597413), extent: new v2(11.216697692871094, 1.892277717590332), heading: 0, ty: "half", name: "SM_fenceRack_big16" },
      { pos: new v2(-170.050598963762, 290.606049048983), extent: new v2(20.649995422363283, 22.350003051757813), heading: 2.5939998618343165, ty: "full", name: "SM_PlayGround_2" },
      { pos: new v2(-364.66683655977533, -265.43714978999094), extent: new v2(4.547786943587698, 4.542104539963475), heading: -0.000002613226819640252, ty: "half", name: "Mesh_woodencrate_85" },





    ],

    entities: [
      team0_tmpl,
      team0_tmpl,

      ...new Array(5).fill(team1_tmpl),
    ],

    spawnareas: [
      { pos: new v2(200, 100), extent: new v2(50, 50), heading: 0, spawn: true },
      {
        pos: new v2(0, 500), extent: new v2(100, 100), heading: 0, vacate: true, spawn1: true,
      },
      { pos: new v2(0, +size), extent: spawnSize, heading: 0 },






    ],

    goals: [
    ],

    mission_rules: [
      { ty: 'capture' },
      { ty: 'explore' },
    ],
  };
}

export function spawnareas_ally(config) {
  const team1_areas = [];
  for (let i = 0; i < config.spawnareas.length; i++) {
    if (config.spawnareas[i].spawn) {
      team1_areas.push(i);
    }
  }
  return team1_areas;
}

export function spawnareas_enemy(config) {
  const team1_areas = [];
  for (let i = 0; i < config.spawnareas.length; i++) {
    if (config.spawnareas[i].spawn1) {
      team1_areas.push(i);
    }
  }
  return team1_areas;
}

export function preset_ext() {
  const world = {
    width: 600,
    height: 600,
    simover_rule: 'eliminate',
  };

  const spawnSize = new v2(100, 20);

  const team0_tmpl = {
    ...entityFromStat2(stats2_const(15), tmpl_firearm_ar_high),
    allow_cover_edge: true,
  };

  const team1_tmpl_ar = {
    ...entityFromStat2(stats2_const(8), tmpl_firearm_ar_low), team: 1, spawnarea: 1,
    default_rule: { ty: 'idle', alert: false },
    allow_crawl: false,
  };

  const team1_tmpl_smg = {
    ...entityFromStat2(stats2_const(8), tmpl_firearm_smg_low), team: 1, spawnarea: 1,
    armor: 33,
    armor_hit_prob: 0.6,
    default_rule: { ty: 'idle', alert: false },
    allow_crawl: false,
  };

  const team1_tmpl_sg = {
    ...entityFromStat2(stats2_const(8), tmpl_firearm_sg_low), team: 1, spawnarea: 1,
    armor: 66,
    armor_hit_prob: 0.8,
    default_rule: { ty: 'idle', alert: false },
    allow_crawl: false,
  };

  return {
    world,

    obstacle_specs: [
      {
        pos: new v2(0, 0),
        extent: new v2(300, 300),
        heading: 0,
        ty: 'random',
        random: { ty: 'full', count: 10 },
      },
    ],

    entities: [
      team0_tmpl,
      team0_tmpl,


      { ...team1_tmpl_ar, spawnarea: 1 },
      { ...team1_tmpl_ar, spawnarea: 1 },
      { ...team1_tmpl_smg, spawnarea: 1 },
      { ...team1_tmpl_smg, spawnarea: 1 },

      { ...team1_tmpl_ar, spawnarea: 2 },
      { ...team1_tmpl_ar, spawnarea: 2 },
      { ...team1_tmpl_smg, spawnarea: 2 },
      { ...team1_tmpl_smg, spawnarea: 2 },

      { ...team1_tmpl_sg, spawnarea: 3 },
      { ...team1_tmpl_smg, spawnarea: 3 },
      { ...team1_tmpl_smg, spawnarea: 3 },

      { ...team1_tmpl_sg, spawnarea: 4 },
      { ...team1_tmpl_sg, spawnarea: 4 },

      { ...team1_tmpl_smg, spawnarea: 5 },
      { ...team1_tmpl_smg, spawnarea: 5 },
      { ...team1_tmpl_ar, spawnarea: 5 },
      { ...team1_tmpl_ar, spawnarea: 5 },
    ],

    spawnareas: [
      { pos: new v2(0, -200), extent: spawnSize, heading: 0, spawn: true },
      { pos: new v2(0, 200), extent: spawnSize, heading: 0, spawn1: true },
      { pos: new v2(0, 200), extent: spawnSize, heading: 0, spawn1: true },
      { pos: new v2(0, 200), extent: spawnSize, heading: 0, spawn1: true },
      { pos: new v2(0, 200), extent: spawnSize, heading: 0, spawn1: true },
      { pos: new v2(0, 200), extent: spawnSize, heading: 0, spawn1: true },
    ],

    goals: [],

    mission_rules: [
      { ty: 'explore' },
    ],
  };
}

export function preset_bazaar1_layer(layer) {
  const preset = PRESET_BAZAAR;

  for (const obs of preset.spawnareas) {
    obs.pos = v2.from(obs.pos);
    obs.extent = v2.from(obs.extent);
  }
  for (const obs of preset.obstacle_specs) {
    obs.pos = v2.from(obs.pos);
    obs.extent = v2.from(obs.extent);
  }

  const level_names = [
    'L_ShooterGym_Base',
    'L_C1M1_Lighting_Day',
  ];

  return {
    world: {
      ...preset.world,
      simover_rule: 'eliminate',
      level_names,
    },

    layers: preset.layers,

    spawnareas: preset.spawnareas.filter((s) => {
      if (!layer) {
        return true;
      }
      return s.layer === undefined || s.layer === layer;
    }).map((s, i) => {
      if (i === 0) {
        return { ...s, spawn: true };
      }
      return { ...s, spawn1: true };
    }),

    obstacle_specs: preset.obstacle_specs.filter((s) => {
      if (!layer) {
        return true;
      }
      return s.layer === undefined || s.layer === layer;
    }),

    mission_rules: [
      { ty: 'explore' },
    ],

    goals: [],

    editor: true,
  };
}

export function preset_bazaar1_layer_base() {
  const preset = preset_bazaar1_layer('base');

  const team0_smg = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_smg_mid),
    spawnarea: 0,
  };

  const team0_ar = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_ar_mid),
    spawnarea: 0,
  };

  // 적 에이전트 스펙 정의
  const team1_tmpl_ar = {
    ...entityFromStat2(stats2_const(2), enemy_tmpl_agent_ar_low),
    default_rule: { ty: 'idle', alert: false },
    team: 1,
    spawnarea: 2,
  };

  return {
    ...preset,

    entities: [
      // ...new Array(3).fill(team0_smg),
      ...new Array(3).fill(team0_ar),

      { ...team1_tmpl_ar, spawnarea: 1 },

      { ...team1_tmpl_ar, spawnarea: 2 },
      { ...team1_tmpl_ar, spawnarea: 2 },

      { ...team1_tmpl_ar, spawnarea: 3 },
      { ...team1_tmpl_ar, spawnarea: 3 },

      { ...team1_tmpl_ar, spawnarea: 4 },
      { ...team1_tmpl_ar, spawnarea: 4 },

      { ...team1_tmpl_ar, spawnarea: 5 },

      { ...team1_tmpl_ar, spawnarea: 6 },

      { ...team1_tmpl_ar, spawnarea: 7 },

      { ...team1_tmpl_ar, spawnarea: 8 },
      { ...team1_tmpl_ar, spawnarea: 8 },

      { ...team1_tmpl_ar, spawnarea: 9 },

      { ...team1_tmpl_ar, spawnarea: 10 },

      { ...team1_tmpl_ar, spawnarea: 11 },

      { ...team1_tmpl_ar, spawnarea: 12 },

      { ...team1_tmpl_ar, spawnarea: 13 },
      { ...team1_tmpl_ar, spawnarea: 13 },

      { ...team1_tmpl_ar, spawnarea: 14 },

      { ...team1_tmpl_ar, spawnarea: 15 },
      { ...team1_tmpl_ar, spawnarea: 15 },

      { ...team1_tmpl_ar, spawnarea: 16 },
      { ...team1_tmpl_ar, spawnarea: 16 },

      { ...team1_tmpl_ar, spawnarea: 17 },

      { ...team1_tmpl_ar, spawnarea: 18 },
      { ...team1_tmpl_ar, spawnarea: 18 },

      { ...team1_tmpl_ar, spawnarea: 19 },

      { ...team1_tmpl_ar, spawnarea: 20 },
      { ...team1_tmpl_ar, spawnarea: 20 },
    ],
  };
}


export function preset_hatchery_woods1(layers, night = false) {
  const preset = preset_from(PRESET_HACHERY);

  const level_name_base = 'L_Hatchery_Test';
  const level_names = [
    'L_Preset_Hatchery_Base',
    'L_Preset_Hatchery_Sound',
  ];
  if (!night) {
    level_names.push('L_PresetHatchery_Lighting_Day1');
  } else {
    level_names.push('L_PresetHatchery_Lighting_night1');
  }
  if (layers?.includes('vari1')) {
    level_names.push('L_PresetHatchery_Vari1');
  } else if (layers?.includes('vari2')) {
    level_names.push('L_PresetHatchery_Vari2');
  } else if (layers?.includes('vari3')) {
    level_names.push('L_PresetHatchery_Vari3');
  }

  const stalker = {
    ...entityFromStat2(stats2_const(4), tmpl_firearm_hg_t1),
    team: 1,
    spawnarea: 0,
    speed: 40,
  };

  return {
    world: {
      ...preset.world,
      simover_rule: 'eliminate',
      level_name_base,
      level_names,
    },

    layers: preset.layers,

    spawnareas: preset.spawnareas.filter((s) => {
      return !layers || s.layer === undefined || layers?.includes(s.layer);
    }),

    obstacle_specs: preset.obstacle_specs.filter((s) => {
      return !layers || s.layer === undefined || layers?.includes(s.layer);
    }),

    mission_rules: [
      { ty: 'explore' },
    ],

    stalkers: [
      { tick: 200, entity: stalker },
      { tick: 500, entity: stalker },
      { tick: 500, entity: stalker },
      { tick: 700, entity: stalker },
      { tick: 700, entity: stalker },
      { tick: 700, entity: stalker },
    ],

    goals: [],

    editor: true,
  };
}

function objectTrigger(preset) {
  for (let i = 0; i < preset.spawnareas.length; i++) {
    const s = preset.spawnareas[i];
    if (s.object) {
      s.triggers = [
        {
          condition: 'enter1',
          conditiontarget: { ty: 'entity', team: 0 },

          action: 'spawn_object',
          actionarea: i,
        }
      ];
    }
  }

  return preset;
}

function fillEntities(preset) {
  const team1_tmpl = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_smg_low),
    team: 1,
    life: 20,
    armor: 20,
    default_rule: { ty: 'idle', alert: false },
  };

  const entities = [];
  for (let i = 0; i < preset.spawnareas.length; i++) {
    const spawnarea = preset.spawnareas[i];
    if (spawnarea.spawn) {
      const tmpl = {
        ...team0_tmpl_agent_ar_high,
        spawnarea: i,
        perk_cover_dash: true,
        allow_cover_edge: true,
        allow_crawl: false,
        allow_fire_control: true,
      };
      entities.push({ ...tmpl });
      entities.push({ ...tmpl });
    }
    if (spawnarea.spawn1) {
      entities.push({ ...team1_tmpl, spawnarea: i });
    }
  }
  return { ...preset, entities };
}

export function preset_hatchery_woods1_vari1(night = false) {
  const preset = preset_hatchery_woods1(['vari1', 'vari1_start0', 'vari1_spawn0', 'vari1_spawn1'], night);

  const team0_smg = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_smg_t3),
    life: 60,
    spawnarea: 0,
  };

  const team0_ar = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_ar_t3),
    ...team0_opts,
    life: 60,
    spawnarea: 0,
  };

  // 적 에이전트 스펙 정의
  const team1_tmpl_ar = {
    ...entityFromStat2(stats2_const(4), tmpl_firearm_ar_t1),
    default_rule: { ty: 'idle', alert: false },
    team: 1,
    life: 60,
    spawnarea: 2,
  };

  return {
    ...preset,

    entities: [
      ...new Array(1).fill(team0_ar),
      ...new Array(2).fill(team0_smg),

      { ...team1_tmpl_ar, spawnarea: 1 },
      { ...team1_tmpl_ar, spawnarea: 2 },

      { ...team1_tmpl_ar, spawnarea: 3 },
      { ...team1_tmpl_ar, spawnarea: 4 },
      { ...team1_tmpl_ar, spawnarea: 5 },
      { ...team1_tmpl_ar, spawnarea: 6 },

      { ...team1_tmpl_ar, spawnarea: 7 },
      { ...team1_tmpl_ar, spawnarea: 8 },
      { ...team1_tmpl_ar, spawnarea: 9 },

      { ...team1_tmpl_ar, spawnarea: 10 },
      { ...team1_tmpl_ar, spawnarea: 11 },
    ],
  };
}

export function preset_hatchery_woods1_vari2(night = false) {
  const preset = preset_hatchery_woods1(['vari2', 'vari2_start0', 'vari2_spawn0', 'vari2_spawn1', 'vari2_spawn2'], night);
  const team0_smg = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_smg_mid),
    spawnarea: 0,
  };

  const team0_ar = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_ar_mid),
    spawnarea: 0,
  };

  const team0_dmr = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_dmr_mid),
    spawnarea: 0,
  };

  // 적 에이전트 스펙 정의
  const team1_tmpl_ar = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_ar_low),
    default_rule: { ty: 'idle', alert: false },
    team: 1,
    spawnarea: 2,
  };

  return {
    ...preset,

    entities: [
      ...new Array(1).fill(team0_dmr),
      // ...new Array(3).fill(team0_smg),
      ...new Array(2).fill(team0_ar),

      { ...team1_tmpl_ar, spawnarea: 1 },
      { ...team1_tmpl_ar, spawnarea: 2 },
      { ...team1_tmpl_ar, spawnarea: 3 },
      { ...team1_tmpl_ar, spawnarea: 4 },
      { ...team1_tmpl_ar, spawnarea: 5 },

      { ...team1_tmpl_ar, spawnarea: 6 },

      { ...team1_tmpl_ar, spawnarea: 7 },
      { ...team1_tmpl_ar, spawnarea: 8 },
      { ...team1_tmpl_ar, spawnarea: 9 },

      { ...team1_tmpl_ar, spawnarea: 10 },

      { ...team1_tmpl_ar, spawnarea: 11 },
      { ...team1_tmpl_ar, spawnarea: 12 },

      { ...team1_tmpl_ar, spawnarea: 13 },

      { ...team1_tmpl_ar, spawnarea: 14 },
      { ...team1_tmpl_ar, spawnarea: 15 },
    ],
  };
}

export function preset_hatchery_woods1_vari3(night = false) {
  const preset = preset_hatchery_woods1(['vari3', 'vari3_start0', 'vari3_spawn0', 'vari3_spawn1'], night);
  const team0_smg = {
    ...entityFromStat2(stats2_const(8), tmpl_firearm_hg_t4),
    spawnarea: 0,
    ...team0_opts,
    life: 60,
    vis_group: 0,
  };

  const team0_ar = {
    ...entityFromStat2(stats2_const(12), tmpl_firearm_ar_t3),
    ...team0_opts,
    spawnarea: 0,
  };

  const team0_dmr = {
    ...entityFromStat2(stats2_const(12), tmpl_firearm_dmr_t3),
    ...team0_opts,
    spawnarea: 0,
  };

  const team0_sg = {
    ...entityFromStat2(stats2_const(12), tmpl_firearm_sg_t3),
    ...team0_opts,
    spawnarea: 0,
    vis_group: 0,
  };

  // 적 에이전트 스펙 정의
  // lvl2, life 최소치
  const team1_tmpl_ar_life_min = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_hg_t1),
    default_rule: { ty: 'idle', alert: false },
    life: 34,
    team: 1,
    spawnarea: 2,
  };

  // 적 에이전트 스펙 정의
  // lvl2, life 평균값
  const team1_tmpl_ar_life_mid = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_hg_t1),
    default_rule: { ty: 'idle', alert: false },
    life: 41,
    team: 1,
    spawnarea: 2,
  };

  // 적 에이전트 스펙 정의
  //lvl2, life 최대치
  const team1_tmpl_ar_life_max = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_hg_t1),
    default_rule: { ty: 'idle', alert: false },
    life: 44,
    team: 1,
    spawnarea: 2,
  };

  return {
    ...preset,

    entities: [
      ...new Array(3).fill(team0_ar),

      { ...team1_tmpl_ar_life_min, spawnarea: 1 },
      { ...team1_tmpl_ar_life_min, spawnarea: 2 },
      { ...team1_tmpl_ar_life_max, spawnarea: 3 },
      { ...team1_tmpl_ar_life_min, spawnarea: 4 },
      { ...team1_tmpl_ar_life_mid, spawnarea: 5 },
      { ...team1_tmpl_ar_life_max, spawnarea: 6 },
      { ...team1_tmpl_ar_life_mid, spawnarea: 7 },

      { ...team1_tmpl_ar_life_min, spawnarea: 8 },
      { ...team1_tmpl_ar_life_min, spawnarea: 9 },
      { ...team1_tmpl_ar_life_max, spawnarea: 10 },
      { ...team1_tmpl_ar_life_mid, spawnarea: 11 },
      { ...team1_tmpl_ar_life_mid, spawnarea: 12 },
      { ...team1_tmpl_ar_life_max, spawnarea: 13 },
      { ...team1_tmpl_ar_life_mid, spawnarea: 14 },

    ],
  };
}

// construction_site1 전체
export function preset_construction_site1(layers) {
  const preset = preset_from(PRESET_CONSTRUCTION);

  const level_name_base = 'L_Preset_Construnction';
  const level_names = [
    'L_Preset_Construnction_Base',
    'L_Preset_Construnction_Light',
    'L_Preset_Construnction_Sound',
  ];
  if (layers?.includes('vari1')) {
    level_names.push('L_Preset_Construnction_Vari1');
  } else if (layers?.includes('vari2')) {
    level_names.push('L_Preset_Construnction_Vari2');
  } else if (layers?.includes('vari3')) {
    level_names.push('L_Preset_Construnction_Vari3');
  }

  return {
    world: {
      ...preset.world,
      simover_rule: 'eliminate',

      level_name_base,
      level_names,
    },

    layers: preset.layers,

    spawnareas: preset.spawnareas.filter((s) => {
      return !layers || s.layer === undefined || layers.includes(s.layer);
    }),

    obstacle_specs: preset.obstacle_specs.filter((s) => {
      return !layers || s.layer === undefined || layers.includes(s.layer);
    }),

    mission_rules: [
      { ty: 'explore' },
    ],

    goals: [],

    editor: true,
  };
}


export function preset_construction_site1_vari1() {
  const preset = preset_construction_site1(['vari1', 'vari1_start0', 'vari1_spawn0', 'vari1_spawn1']);

  const team0_sg = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_sg_t1),
    spawnarea: 0,
    ...team0_opts,
    life: 75,
  };

  const team0_ar = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_smg_t1),
    spawnarea: 0,
    // throwables: [throwable_impact_frag_mk2],
    ...team0_opts,
    life: 75,
  };

  // 적 에이전트 스펙 정의
  const team1_tmpl_ar = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_hg_t1),
    default_rule: { ty: 'idle', alert: false },
    team: 1,
    life: 54,
  };

  return {
    ...preset,

    entities: [
      ...new Array(1).fill(team0_sg),
      // ...new Array(3).fill(team0_ar),

      { ...team1_tmpl_ar, spawnarea: 1 },
      { ...team1_tmpl_ar, spawnarea: 2 },
      { ...team1_tmpl_ar, spawnarea: 16 },

      { ...team1_tmpl_ar, spawnarea: 3 },
      { ...team1_tmpl_ar, spawnarea: 4 },

      { ...team1_tmpl_ar, spawnarea: 5 },
      { ...team1_tmpl_ar, spawnarea: 6 },

      { ...team1_tmpl_ar, spawnarea: 7 },
      { ...team1_tmpl_ar, spawnarea: 8 },

      { ...team1_tmpl_ar, spawnarea: 9 },

      { ...team1_tmpl_ar, spawnarea: 10 },
      { ...team1_tmpl_ar, spawnarea: 11 },
      { ...team1_tmpl_ar, spawnarea: 12 },
    ],
  };
}

export function preset_construction_site1_vari2() {
  const preset = preset_construction_site1(['vari2', 'vari2_start0', 'vari2_spawn0', 'vari2_spawn1']);

  const team0_smg = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_smg_t3),
    spawnarea: 0,
    ...team0_opts,
  };

  const team0_ar = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_ar_t3),
    spawnarea: 0,
    ...team0_opts,
  };

  // 적 에이전트 스펙 정의
  const team1_tmpl_ar = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_ar_t1),
    default_rule: { ty: 'idle', alert: false },
    team: 1,
    life: 60,
  };

  return {
    ...preset,

    entities: [
      // ...new Array(3).fill(team0_smg),
      ...new Array(2).fill(team0_ar),

      { ...team1_tmpl_ar, spawnarea: 1 },
      { ...team1_tmpl_ar, spawnarea: 2 },

      { ...team1_tmpl_ar, spawnarea: 3 },
      { ...team1_tmpl_ar, spawnarea: 4 },

      { ...team1_tmpl_ar, spawnarea: 15 },
      { ...team1_tmpl_ar, spawnarea: 16 },

      { ...team1_tmpl_ar, spawnarea: 5 },
      { ...team1_tmpl_ar, spawnarea: 6 },

      { ...team1_tmpl_ar, spawnarea: 7 },
      { ...team1_tmpl_ar, spawnarea: 8 },

      { ...team1_tmpl_ar, spawnarea: 9 },

      { ...team1_tmpl_ar, spawnarea: 10 },
    ],
  };
}

export function preset_construction_site1_vari3() {
  const preset = preset_construction_site1(['vari3', 'vari3_start0', 'vari3_spawn0', 'vari3_spawn1']);

  const team0_smg = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_smg_t3),
    spawnarea: 0,
    ...team0_opts,
  };

  const team0_ar = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_ar_t3),
    spawnarea: 0,
    ...team0_opts,
  };

  // 적 에이전트 스펙 정의
  const team1_tmpl_ar = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_ar_t1),
    default_rule: { ty: 'idle', alert: false },
    team: 1,
    life: 60,
  };

  return {
    ...preset,

    entities: [
      // ...new Array(3).fill(team0_smg),
      ...new Array(3).fill(team0_ar),

      { ...team1_tmpl_ar, spawnarea: 1 },
      { ...team1_tmpl_ar, spawnarea: 2 },

      { ...team1_tmpl_ar, spawnarea: 3 },
      { ...team1_tmpl_ar, spawnarea: 4 },

      { ...team1_tmpl_ar, spawnarea: 5 },
      { ...team1_tmpl_ar, spawnarea: 6 },

      { ...team1_tmpl_ar, spawnarea: 7 },
      { ...team1_tmpl_ar, spawnarea: 8 },

      { ...team1_tmpl_ar, spawnarea: 9 },
      { ...team1_tmpl_ar, spawnarea: 10 },
      { ...team1_tmpl_ar, spawnarea: 11 },
    ],
  };
}

// outskirts_farm1 전체
export function preset_outskirts_farm1(layers) {
  const preset = preset_from(OUTSKIRTS_FARM);

  const level_name_base = 'L_Preset_Outskirts_Farm';
  const level_names = [
    'L_Preset_Outskirts_Farm_Base',
    'L_Preset_Outskirts_Farm_Light',
    'L_Preset_Outskirts_Farm_Sound',
  ];
  if (layers?.includes('vari1')) {
    level_names.push('L_Preset_Outskirts_Farm_Var1');
  } else if (layers?.includes('vari2')) {
    level_names.push('L_Preset_Outskirts_Farm_Var2');
  } else if (layers?.includes('vari3')) {
    level_names.push('L_Preset_Outskirts_Farm_Var3');
  }

  return {
    world: {
      ...preset.world,
      simover_rule: 'eliminate',
      level_name_base,
      level_names,
    },

    layers: preset.layers,

    spawnareas: preset.spawnareas.filter((s) => {
      return !layers || s.layer === undefined || layers.includes(s.layer);
    }),

    obstacle_specs: preset.obstacle_specs.filter((s) => {
      return !layers || s.layer === undefined || layers.includes(s.layer);
    }),

    mission_rules: [
      { ty: 'explore' },
    ],

    goals: [],

    editor: true,
  };
}

export function preset_outskirts_farm1_vari1() {
  const preset = preset_outskirts_farm1(['vari1', 'vari1_start0', 'vari1_spawn0', 'vari1_spawn1', 'vari1_spawn2']);

  const team0_smg = {
    ...entityFromStat2(stats2_const(12), tmpl_firearm_smg_t3),
    spawnarea: 0,
    ...team0_opts,
  };

  const team0_ar = {
    ...entityFromStat2(stats2_const(12), tmpl_firearm_ar_t3),
    spawnarea: 0,
    ...team0_opts,
  };

  // 적 에이전트 스펙 정의
  const team1_tmpl_ar = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_ar_t1),
    default_rule: { ty: 'idle', alert: false },
    team: 1,
  };

  return {
    ...preset,

    entities: [
      // ...new Array(3).fill(team0_smg),
      ...new Array(3).fill(team0_ar),

      { ...team1_tmpl_ar, spawnarea: 1 },
      { ...team1_tmpl_ar, spawnarea: 2 },

      { ...team1_tmpl_ar, spawnarea: 3 },
      { ...team1_tmpl_ar, spawnarea: 4 },

      { ...team1_tmpl_ar, spawnarea: 5 },
      { ...team1_tmpl_ar, spawnarea: 6 },
      { ...team1_tmpl_ar, spawnarea: 7 },

      { ...team1_tmpl_ar, spawnarea: 8 },
      { ...team1_tmpl_ar, spawnarea: 9 },

      { ...team1_tmpl_ar, spawnarea: 10 },

      { ...team1_tmpl_ar, spawnarea: 11 },
      { ...team1_tmpl_ar, spawnarea: 12 },

      { ...team1_tmpl_ar, spawnarea: 13 },
      { ...team1_tmpl_ar, spawnarea: 14 },

      { ...team1_tmpl_ar, spawnarea: 15 },
      { ...team1_tmpl_ar, spawnarea: 16 },
    ],
  };
}

export function preset_outskirts_farm1_vari2() {
  const preset = preset_outskirts_farm1(['vari2', 'vari2_start0', 'vari2_spawn0', 'vari2_spawn1', 'vari2_spawn2']);

  const team0_smg = {
    ...entityFromStat2(stats2_const(12), tmpl_firearm_smg_t3),
    spawnarea: 0,
    ...team0_opts,
    life: 65
  };

  const team0_ar = {
    ...entityFromStat2(stats2_const(12), tmpl_firearm_ar_t3),
    spawnarea: 0,
    ...team0_opts,
    life: 65
  };

  // 적 에이전트 스펙 정의
  const team1_tmpl_ar = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_ar_t1),
    default_rule: { ty: 'idle', alert: false },
    team: 1,
    life: 45
  };

  return {
    ...preset,

    entities: [
      // ...new Array(3).fill(team0_smg),
      ...new Array(3).fill(team0_ar),

      { ...team1_tmpl_ar, spawnarea: 1 },
      { ...team1_tmpl_ar, spawnarea: 25 },

      { ...team1_tmpl_ar, spawnarea: 2 },
      { ...team1_tmpl_ar, spawnarea: 3 },

      { ...team1_tmpl_ar, spawnarea: 4 },
      { ...team1_tmpl_ar, spawnarea: 21 },
      { ...team1_tmpl_ar, spawnarea: 23 },

      { ...team1_tmpl_ar, spawnarea: 5 },
      { ...team1_tmpl_ar, spawnarea: 6 },
      { ...team1_tmpl_ar, spawnarea: 7 },

      { ...team1_tmpl_ar, spawnarea: 8 },

      { ...team1_tmpl_ar, spawnarea: 9 },
      { ...team1_tmpl_ar, spawnarea: 10 },

      { ...team1_tmpl_ar, spawnarea: 11 },
      { ...team1_tmpl_ar, spawnarea: 12 },

      { ...team1_tmpl_ar, spawnarea: 13 },
      { ...team1_tmpl_ar, spawnarea: 14 },
    ],
  };
}

const team0_opts = {
    perk_cover_dash: true,
    allow_cover_edge: true,
    allow_crawl: false,
    allow_fire_control: true,
};

export function preset_outskirts_farm1_vari3() {
  const preset = preset_outskirts_farm1(['vari3', 'vari3_start0', 'vari3_spawn0', 'vari3_spawn1', 'vari3_spawn2']);

  const team0_smg = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_smg_t2),
    ...team0_opts,
    spawnarea: 0,
    vis_group: 0,
    life: 60,
  };

  const team0_ar = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_ar_t2),
    ...team0_opts,
    spawnarea: 0,
    life: 60,
  };

  const team0_sg = {
    ...entityFromStat2(stats2_const(10), tmpl_firearm_sg_t2),
    ...team0_opts,
    spawnarea: 0,
    life: 60,
  };

  // 적 에이전트 스펙 정의
  const team1_tmpl_ar = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_smg_t1),
    default_rule: { ty: 'idle', alert: false },
    team: 1,
    life: 45,

  };

  return {
    ...preset,

    entities: [
      // ...new Array(3).fill(team0_smg),
      ...new Array(3).fill(team0_smg),

      { ...team1_tmpl_ar, spawnarea: 1 },
      { ...team1_tmpl_ar, spawnarea: 2 },
      { ...team1_tmpl_ar, spawnarea: 24 },

      { ...team1_tmpl_ar, spawnarea: 3 },
      { ...team1_tmpl_ar, spawnarea: 4 },
      { ...team1_tmpl_ar, spawnarea: 5 },

      { ...team1_tmpl_ar, spawnarea: 6 },
      { ...team1_tmpl_ar, spawnarea: 7 },

      { ...team1_tmpl_ar, spawnarea: 8 },
      { ...team1_tmpl_ar, spawnarea: 9 },
      { ...team1_tmpl_ar, spawnarea: 22 },

      { ...team1_tmpl_ar, spawnarea: 10 },
      { ...team1_tmpl_ar, spawnarea: 11 },

      { ...team1_tmpl_ar, spawnarea: 12 },
      { ...team1_tmpl_ar, spawnarea: 13 },

      { ...team1_tmpl_ar, spawnarea: 14 },
      { ...team1_tmpl_ar, spawnarea: 15 },
    ],
  };
}

// outskirts_farm1 전체
export function preset_trailer_field(layer) {
  const preset = preset_from(TRAILER_FIELD);

  const level_name_base = 'L_Trailer';
  const level_names = [
    'L_Tariler_Base',
    'L_Tariler_Light',
  ];

  return {
    world: {
      ...preset.world,
      simover_rule: 'eliminate',
      level_name_base,
      level_names,
    },

    layers: preset.layers,

    spawnareas: preset.spawnareas.filter((s) => {
      if (!layer) {
        return true;
      }
      return s.layer === undefined || s.layer === layer;
    }).map((s, i) => {
      if (i === 0) {
        return { ...s, spawn: true };
      }
      return { ...s, spawn1: true };
    }),

    obstacle_specs: preset.obstacle_specs.filter((s) => {
      if (!layer) {
        return true;
      }
      return s.layer === undefined || s.layer === layer;
    }),

    mission_rules: [
      { ty: 'explore' },
    ],

    goals: [],

    editor: true,
  };
}

export function preset_trailer_field_vari1() {
  const preset = preset_trailer_field('vari1');

  const team0_ar_sq1 = {
    ...entityFromStat2(stats2_const(20), tmpl_firearm_ar_t5),
    throwables: [throwable_stun_mk3],
    spawnarea: 0,
  };

  // 적 에이전트 스펙 정의
  const team1_tmpl_ar = {
    ...entityFromStat2(stats2_const(2), tmpl_firearm_unarmed),
    default_rule: { ty: 'idle', alert: false },
    team: 1,
  };

  return {
    ...preset,

    entities: [
      // ...new Array(3).fill(team0_smg),
      ...new Array(3).fill(team0_ar_sq1),

      { ...team1_tmpl_ar, spawnarea: 1 },
      { ...team1_tmpl_ar, spawnarea: 2 },
      { ...team1_tmpl_ar, spawnarea: 3 },
      { ...team1_tmpl_ar, spawnarea: 4 },

      { ...team1_tmpl_ar, spawnarea: 5 },
      { ...team1_tmpl_ar, spawnarea: 6 },
    ],
  };
}

export function preset_trailer_field2(layer) {
  const preset = preset_from(TRAILER_FIELD_2);

  const level_name_base = 'L_Trailer';
  const level_names = [
    'L_Tariler_Base',
    'L_Tariler_Light',
  ];

  const team0_ar_sq1 = {
    ...entityFromStat2(stats2_const(20), {
      ...tmpl_firearm_ar_cheat,
    }),
    throwables: [{
      ...throwable_frag_demo,
    }],
    allow_door_wait: true,
    demo_trailer_throw: true,
    spawnarea: 0,
  };

  // 적 에이전트 스펙 정의
  const team1_tmpl_ar = {
    ...entityFromStat2(stats2_const(2), {
      ...tmpl_firearm_hg_t1,
      firearm_range: 10,
    }),
    default_rule: { ty: 'idle', alert: false },
    team: 1,
  };


  return {
    world: {
      ...preset.world,
      simover_rule: 'eliminate',
      level_name_base,
      level_names,
    },

    layers: preset.layers,

    entities: [
      { ...team0_ar_sq1, default_rule: { ty: 'capture-goal', goal: 0 } },
      { ...team0_ar_sq1, default_rule: { ty: 'capture-goal', goal: 0 } },
      { ...team0_ar_sq1, default_rule: { ty: 'capture-goal', goal: 1 } },
      { ...team0_ar_sq1, default_rule: { ty: 'capture-goal', goal: 1 } },

      { ...team1_tmpl_ar, spawnarea: 1 },
      { ...team1_tmpl_ar, spawnarea: 2 },
      { ...team1_tmpl_ar, spawnarea: 3 },
      { ...team1_tmpl_ar, spawnarea: 4 },

      { ...team1_tmpl_ar, spawnarea: 5 },
      { ...team1_tmpl_ar, spawnarea: 6 },
      { ...team1_tmpl_ar, spawnarea: 9 },
      { ...team1_tmpl_ar, spawnarea: 10 },


    ],

    spawnareas: preset.spawnareas.filter((s) => {
      if (!layer) {
        return true;
      }
      return s.layer === undefined || s.layer === layer;
    }).map((s, i) => {
      if (i === 0) {
        return { ...s, spawn: true };
      }
      return { ...s, spawn1: true };
    }),

    obstacle_specs: preset.obstacle_specs.filter((s) => {
      if (!layer) {
        return true;
      }
      return s.layer === undefined || s.layer === layer;
    }),

    mission_rules: [
      { ty: 'explore' },
    ],

    goals: [
      { name: 'goal0', area: 7 },
      { name: 'goal1', area: 8 },
    ],

    editor: true,
  };
}

// wrap preset
function w(preset) {
  preset = fillEntities(preset);
  preset = objectTrigger(preset);
  return preset;
}

export const presets = {
  'rescue': preset_rescue,
  'rescue2': preset_rescue2,
  'indoor': preset_indoor,
  'indoor_leader': () => markLeader(preset_indoor()),
  'indoor_custom': preset_indoor_custom,
  'indoor_reloadtest': preset_indoor_reloadtest,
  'indoor_rescue': preset_indoor_rescue,
  'indoor_heal': preset_indoor_heal,
  'indoor_response_t0': preset_indoor_response_team0,
  'indoor_response_t1': preset_indoor_response_team1,
  'indoor2': preset_indoor2,
  'indoor2_leader': () => markLeader(preset_indoor2()),
  'indoor2_rescue': preset_indoor2_rescue,
  'indoor2_trigger': preset_indoor2_trigger,
  'indoor2_heal': preset_indoor2_heal,
  'outdoor': preset_outdoor,
  'outdoor_throw': preset_outdoor_throw,
  'outdoor_riskrank': () => preset_outdoor_riskrank({ use_riskrank: true, use_leader: true }),
  'outdoor_leader': () => markLeader(preset_outdoor()),
  'outdoor_rescue': preset_outdoor_rescue,
  'outdoor_desmar_distrange': preset_outdoor_desmar_distrange,
  'outdoor_maze': preset_outdoor_maze,
  'outdoor_maze_limvis': preset_outdoor_maze_limvis,
  'limvis_debug': preset_limvis_debug,
  'outdoor_maze_swamp': preset_outdoor_maze_swamp,
  'outdoor_smoke0': preset_outdoor_smoke0,
  'outdoor_smoke1': preset_outdoor_smoke1,
  'outdoor_obs': preset_outdoor_obs,
  'firecontrol': preset_firecontrol,
  'aimvar': preset_aimvar,
  'armorstop': preset_aimvar_armorstop,
  'range': preset_range,
  'metro': preset_rescue_metro1,
  'metro_sr': preset_rescue_metro_sr,
  'metro_test': preset_rescue_metro_test,
  'metro_heal': preset_rescue_metro_heal,
  'duel': preset_duel,
  'duel_slope': preset_duel_slope,
  'embassy': preset_rescue_embassy,
  'embassy_short_old': preset_embassy_short,
  'embassy_short0': preset_embassy_short0,
  'hammer': preset_hammerhead,
  'ship': preset_rescue_ship1,
  'bazaar0': preset_bazaar1,
  'bazaar': preset_bazaar1_layer_base,
  'bazaar_heal': preset_bazaar_heal,
  'bazaar_dash': preset_bazaar_dash,
  'exhibition_hall': preset_exhibition_hall,
  'shooting_house1': preset_shooting_house1,
  'shooting_house2': preset_shooting_house2,
  'alley1': preset_alley1,
  'storehouse': preset_storehouse,
  'border_checkpoint': preset_border_checkpoint,
  'shipping_dock': preset_shiping_dock,

  'forest': preset_forest,
  'forest_sr': preset_forest_sr,

  'testedge': preset_testedge,
  'swamp_trigger': preset_swamp_trigger,
  'night_ops_test': preset_night_ops_test,
  'night_hub': preset_night_hub,

  'maze': preset_maze,
  'maze_obj': () => preset_maze({ object: true }),

  'indoor_sgtest': preset_indoor_sgtest,
  'mimictest': preset_mimictest,
  'mimictest_leader': () => markLeader(preset_mimictest()),
  'prompt00': preset_prompt00,
  'prompt01': preset_prompt01,
  'prompt02': preset_prompt02,
  'movetest_alley': preset_movetest00,
  'movetest_indoor': preset_movetest01,
  'movetest_outdoor': preset_movetest02,
  'movetest_rush': preset_movetest03,
  'movetest_sneak': preset_movetest04,
  'movetest_messy': preset_movetest05,

  'debug_fire': preset_debug_fire,

  'stonehenge': preset_stonehenge,
  'watchwood': preset_watchwood_random_density,
  'shootergym2': preset_shootergym2,

  'border_checkpoint_short': preset_border_checkpoint_short,
  'storehouse_short': preset_storehouse_short,
  'alley1_short0': preset_alley1_short,
  'alley1_short': preset_alley1_short_layer_base,
  'shooting_house2_short': preset_shooting_house2_short,
  'shooting_house1_short': preset_shooting_house1_short,
  'shooting_house1_short_invis': preset_shooting_house1_short_invis,
  'exhibition_hall_short': preset_exhibition_hall_short,
  'embassy_short': () => markLeader(preset_embassy_short_layer_base()),
  'ext': preset_ext,
  'preset_aimvar_compare': preset_aimvar_compare,

  'preset_shipment_basic': preset_shipment_basic,
  'preset_shipment_basic_mode1': preset_shipment_mode1,
  'preset_shipment_basic_mode2': preset_shipment_mode2,

  'hatchery': preset_hatchery_woods1,
  'hatchery_vari1': () => markLeader(preset_hatchery_woods1_vari1(false)),
  'hatchery_vari2': () => markLeader(preset_hatchery_woods1_vari2(false)),
  'hatchery_vari3': () => markLeader(preset_hatchery_woods1_vari3(false)),

  'hatchery_vari1_n': () => preset_hatchery_woods1_vari1(true),
  'hatchery_vari2_n': () => preset_hatchery_woods1_vari2(true),
  'hatchery_vari3_n': () => preset_hatchery_woods1_vari3(true),

  // 숫자가 ABC 있을 때
  // A: 맵 크기 (난이도, 소화 가능한 인원 수)
  // B: 베리에이션 (1, 2, 3)
  // C: 베리에이션에서의 조각 (0, 1)
  'hatchery_010': () => markLeader(w(preset_hatchery_woods1(['vari1', 'vari1_start0', 'vari1_spawn0']))),
  'hatchery_011': () => markLeader(w(preset_hatchery_woods1(['vari1', 'vari1_start1', 'vari1_spawn1']))),

  'hatchery_020': () => markLeader(w(preset_hatchery_woods1(['vari2', 'vari2_start0', 'vari2_spawn0']))),
  'hatchery_021': () => markLeader(w(preset_hatchery_woods1(['vari2', 'vari2_start1', 'vari2_spawn1']))),
  'hatchery_022': () => markLeader(w(preset_hatchery_woods1(['vari2', 'vari2_start2', 'vari2_spawn2']))),

  'hatchery_030': () => markLeader(w(preset_hatchery_woods1(['vari3', 'vari3_start0', 'vari3_spawn0']))),
  'hatchery_031': () => markLeader(w(preset_hatchery_woods1(['vari3', 'vari3_start1', 'vari3_spawn1']))),

  'hatchery_110': () => markLeader(w(preset_hatchery_woods1(['vari1', 'vari1_start0', 'vari1_spawn0', 'vari1_spawn1']))),
  'hatchery_120': () => markLeader(w(preset_hatchery_woods1(['vari2', 'vari2_start0', 'vari2_spawn0', 'vari2_spawn1']))),
  'hatchery_121': () => markLeader(w(preset_hatchery_woods1(['vari2', 'vari2_start1', 'vari2_spawn1', 'vari2_spawn2']))),
  'hatchery_130': () => markLeader(w(preset_hatchery_woods1(['vari3', 'vari1_start0', 'vari3_spawn0', 'vari3_spawn1']))),

  'hatchery_210': () => markLeader(w(preset_hatchery_woods1(['vari1', 'vari1_start0', 'vari1_spawn0', 'vari1_spawn1', 'vari1_spawn2']))),
  'hatchery_220': () => markLeader(w(preset_hatchery_woods1(['vari2', 'vari2_start0', 'vari2_spawn0', 'vari2_spawn1', 'vari2_spawn2']))),
  'hatchery_230': () => markLeader(w(preset_hatchery_woods1(['vari3', 'vari3_start0', 'vari3_spawn0', 'vari3_spawn1', 'vari3_spawn2']))),

  'trailer_field': preset_trailer_field,
  'trailer_field_vari1': preset_trailer_field_vari1,

  'trailer_field_2': () => preset_trailer_field2('vari1'),

  'construction_site': preset_construction_site1,
  'construction_site_vari1': () => markLeader(preset_construction_site1_vari1()),
  'construction_site_vari2': () => markLeader(preset_construction_site1_vari2()),
  'construction_site_vari3': () => markLeader(preset_construction_site1_vari3()),

  'construction_010': () => w(preset_construction_site1(['vari1', 'vari1_start0', 'vari1_spawn0'])),
  'construction_011': () => w(preset_construction_site1(['vari1', 'vari1_start1', 'vari1_spawn1'])),

  'construction_020': () => w(preset_construction_site1(['vari2', 'vari2_start0', 'vari2_spawn0'])),
  'construction_021': () => w(preset_construction_site1(['vari2', 'vari2_start1', 'vari2_spawn1'])),

  'construction_030': () => w(preset_construction_site1(['vari3', 'vari3_start0', 'vari3_spawn0'])),
  'construction_031': () => w(preset_construction_site1(['vari3', 'vari3_start1', 'vari3_spawn1'])),

  'construction_210': () => w(preset_construction_site1(['vari1', 'vari1_start0', 'vari1_spawn0', 'vari1_spawn1'])),
  'construction_220': () => w(preset_construction_site1(['vari2', 'vari2_start0', 'vari2_spawn0', 'vari2_spawn1'])),
  'construction_230': () => w(preset_construction_site1(['vari3', 'vari3_start0', 'vari3_spawn0', 'vari3_spawn1'])),

  'preset_outskirts_farm': () => preset_outskirts_farm1(null),
  'preset_outskirts_farm_vari1': () => markLeader(preset_outskirts_farm1_vari1()),
  'preset_outskirts_farm_vari2': () => markLeader(preset_outskirts_farm1_vari2()),
  'preset_outskirts_farm_vari3': () => markLeader(preset_outskirts_farm1_vari3()),

  'farm_010': () => w(preset_outskirts_farm1(['vari1', 'vari1_start0', 'vari1_spawn0'])),
  'farm_011': () => w(preset_outskirts_farm1(['vari1', 'vari1_start1', 'vari1_spawn1'])),
  'farm_012': () => w(preset_outskirts_farm1(['vari1', 'vari1_start2', 'vari1_spawn2'])),

  'farm_020': () => w(preset_outskirts_farm1(['vari2', 'vari2_start0', 'vari2_spawn0'])),
  'farm_021': () => w(preset_outskirts_farm1(['vari2', 'vari2_start1', 'vari2_spawn1'])),
  'farm_022': () => w(preset_outskirts_farm1(['vari2', 'vari2_start2', 'vari2_spawn2'])),

  'farm_030': () => w(preset_outskirts_farm1(['vari3', 'vari3_start0', 'vari3_spawn0'])),
  'farm_031': () => w(preset_outskirts_farm1(['vari3', 'vari3_start1', 'vari3_spawn1'])),
  'farm_032': () => w(preset_outskirts_farm1(['vari3', 'vari3_start2', 'vari3_spawn2'])),

  'farm_110': () => w(preset_outskirts_farm1(['vari1', 'vari1_start0', 'vari1_spawn0', 'vari1_spawn1'])),
  'farm_120': () => w(preset_outskirts_farm1(['vari2', 'vari2_start0', 'vari2_spawn0', 'vari2_spawn1'])),
  'farm_130': () => w(preset_outskirts_farm1(['vari3', 'vari3_start0', 'vari3_spawn0', 'vari3_spawn1'])),

  'farm_210': () => w(preset_outskirts_farm1(['vari1', 'vari1_start1', 'vari1_spawn1', 'vari1_spawn2'])),
  'farm_220': () => w(preset_outskirts_farm1(['vari2', 'vari2_start1', 'vari2_spawn1', 'vari2_spawn2'])),
  'farm_230': () => w(preset_outskirts_farm1(['vari3', 'vari3_start1', 'vari3_spawn1', 'vari3_spawn2'])),
};

export const preset_default = 'maze';
