// downloaded data!
export const data = [
  {
    "areaNum": "0",
    "train_cost": "100",
    "train_mult": "1",
    "train_power_cap": "11",
    "train_stats2_cap": "13",
    "life_ratio": "0.9"
  },
  {
    "areaNum": "1",
    "train_cost": "300",
    "train_mult": "1.2",
    "train_power_cap": "14",
    "train_stats2_cap": "16",
    "life_ratio": "0.9"
  },
  {
    "areaNum": "2",
    "train_cost": "700",
    "train_mult": "1.4",
    "train_power_cap": "17",
    "train_stats2_cap": "19",
    "life_ratio": "0.9"
  },
  {
    "areaNum": "3",
    "train_cost": "1500",
    "train_mult": "1.6",
    "train_power_cap": "20",
    "train_stats2_cap": "20",
    "life_ratio": "0.9"
  }
];