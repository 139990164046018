// downloaded data!
export const data = [
  {
    "key": "renown_once",
    "descr_tmpl": "명성 {}",
    "ty": "once"
  },
  {
    "key": "cash_once",
    "descr_tmpl": "현금 {}",
    "ty": "once"
  },
  {
    "key": "milestone_weaken",
    "descr_tmpl": "마일스톤1에 [분산된 적] 모디파이어 부여",
    "ty": "once"
  },
  {
    "key": "mod_global_boom",
    "descr_tmpl": "[호경기] 모디파이어 획득({}개월)",
    "ty": "once"
  },
  {
    "key": "mod_personal_morale_all",
    "descr_tmpl": "모든 용병에게 [사기충천] 모디파이어 부여({}개월)",
    "ty": "once"
  },
  {
    "key": "branch_open",
    "descr_tmpl": "{} 지부 해금",
    "ty": "once"
  },
  {
    "key": "milestone_open",
    "descr_tmpl": "마일스톤 {} 활성화",
    "ty": "once"
  },
  {
    "key": "train_slot",
    "descr_tmpl": "훈련 슬롯 {}개 추가",
    "ty": "once"
  },
  {
    "key": "reward_renown_pos",
    "descr_tmpl": "임무 성공으로 명성이 상승하면 상승치 {}%",
    "ty": "acc"
  },
  {
    "key": "reward_renown_neg",
    "descr_tmpl": "임무 성공으로 명성이 하락하면 하락치 {}%",
    "ty": "acc"
  },
  {
    "key": "train_efficiency_1",
    "descr_tmpl": "0단계 지역에서 훈련 효율 {}%",
    "ty": "acc"
  },
  {
    "key": "hp_after",
    "descr_tmpl": "임무 종료 후 체력 회복 {}%p",
    "ty": "acc"
  },
  {
    "key": "update_trade_day",
    "descr_tmpl": "상점 업데이트 주기 {}일",
    "ty": "acc"
  },
  {
    "key": "renown_monthly",
    "descr_tmpl": "매달 명성 {}",
    "ty": "acc"
  },
  {
    "key": "train_efficiency_all",
    "descr_tmpl": "전체 훈련 효율 {}%p",
    "ty": "acc"
  },
  {
    "key": "train_duration_all",
    "descr_tmpl": "전체 훈련 기간 {}%p",
    "ty": "acc"
  },
  {
    "key": "hp_unable_recover",
    "descr_tmpl": "임무 불가 상태에서의 회복속도 {}%",
    "ty": "acc"
  },
  {
    "key": "reward_firearm_prob",
    "descr_tmpl": "임무 선택보상에 무기가 나올 확률 {}%",
    "ty": "acc"
  },
  {
    "key": "reward_equip_prob",
    "descr_tmpl": "임무 선택보상에 장비가 나올 확률 {}%",
    "ty": "acc"
  },
  {
    "key": "reward_merc_overall",
    "descr_tmpl": "선택보상으로 나오는 용병의 overall {}",
    "ty": "acc"
  },
  {
    "key": "reward_cash_value",
    "descr_tmpl": "현금 선택보상의 금액 {}%p",
    "ty": "acc"
  },
  {
    "key": "recruit_refresh_cost",
    "descr_tmpl": "고용창 새로고침 비용 {}%",
    "ty": "acc"
  },
  {
    "key": "update_mission_day",
    "descr_tmpl": "임무 업데이트 주기 {}일",
    "ty": "acc"
  },
  {
    "key": "train_efficiency_2",
    "descr_tmpl": "1단계 지역에서 훈련 효율 {}%",
    "ty": "acc"
  },
  {
    "key": "mission_extra_pos",
    "descr_tmpl": "임무가 생성될 때 {}% 확률로 명성 상승 임무가 추가로 생성",
    "ty": "acc"
  },
  {
    "key": "train_cost_all",
    "descr_tmpl": "훈련 비용 {}%",
    "ty": "acc"
  },
  {
    "key": "aptitude_prob",
    "descr_tmpl": "무기 적성 획득 확률 {}%",
    "ty": "acc"
  },
  {
    "key": "train_efficiency_3",
    "descr_tmpl": "2단계 지역에서 훈련 효율 {}%",
    "ty": "acc"
  },
  {
    "key": "train_efficiency_physical",
    "descr_tmpl": "Physical 훈련 효율 {}%",
    "ty": "acc"
  },
  {
    "key": "hp_able_threshold",
    "descr_tmpl": "해고, 훈련, 임무 투입을 위한 최소 체력 각각 {}%p",
    "ty": "acc"
  },
  {
    "key": "hp_cost",
    "descr_tmpl": "회복 비용 {}%",
    "ty": "acc"
  },
  {
    "key": "mission_firearm",
    "descr_tmpl": "임무가 생성될 때 {}% 확률로 [무기고] 모디파이어 부여",
    "ty": "acc"
  },
  {
    "key": "mission_equipment",
    "descr_tmpl": "임무가 생성될 때 {}% 확률로 [방탄장비 보관소] 모디파이어 부여",
    "ty": "acc"
  },
  {
    "key": "mission_militia",
    "descr_tmpl": "임무가 생성될 때 {}% 확률로 [현지 증원] 모디파이어 부여",
    "ty": "acc"
  },
  {
    "key": "mission_hyena",
    "descr_tmpl": "임무가 생성될 때 {}% 확률로 [후속 타격 의뢰] 모디파이어 부여",
    "ty": "acc"
  },
  {
    "key": "recruit_size",
    "descr_tmpl": "용병 채용 목록 크기 {}",
    "ty": "acc"
  },
  {
    "key": "recruit_overall",
    "descr_tmpl": "용병 채용 목록 overall {}",
    "ty": "acc"
  },
  {
    "key": "train_efficiency_stats",
    "descr_tmpl": "스탯 훈련 효율 {}%",
    "ty": "acc"
  },
  {
    "key": "train_efficiency_overall",
    "descr_tmpl": "overall 훈련 효율 {}%",
    "ty": "acc"
  },
  {
    "key": "train_efficiency_decision",
    "descr_tmpl": "훈련 시 Decision 성장치  {}%",
    "ty": "acc"
  },
  {
    "key": "train_efficiency_bravery",
    "descr_tmpl": "훈련 시 Bravery 성장치  {}%",
    "ty": "acc"
  },
  {
    "key": "train_efficiency_focus",
    "descr_tmpl": "훈련 시 Focus 성장치  {}%",
    "ty": "acc"
  },
  {
    "key": "train_efficiency_reaction",
    "descr_tmpl": "훈련 시 Reaction 성장치  {}%",
    "ty": "acc"
  },
  {
    "key": "train_efficiency_toughness",
    "descr_tmpl": "훈련 시 Toughness 성장치  {}%",
    "ty": "acc"
  },
  {
    "key": "train_efficiency_precision",
    "descr_tmpl": "훈련 시 Precision 성장치 {}%",
    "ty": "acc"
  },
  {
    "key": "train_efficiency_4",
    "descr_tmpl": "3단계 지역에서 훈련 효율 {}%",
    "ty": "acc"
  },
  {
    "key": "hp_penalty",
    "descr_tmpl": "부족한 체력에 의한 전투력 페널티 {}%",
    "ty": "acc"
  },
  {
    "key": "hp_able_recover",
    "descr_tmpl": "임무에 투입될 수 있는 상태에서 회복속도 {}%",
    "ty": "acc"
  },
  {
    "key": "trade_equipment_cost",
    "descr_tmpl": "장비 구매가 {}%",
    "ty": "acc"
  },
  {
    "key": "personal_milestone_veteran",
    "descr_tmpl": "베테랑 용병 마일스톤의 목표치 {}%",
    "ty": "acc"
  },
  {
    "key": "personal_milestone_fm",
    "descr_tmpl": "FM 전문가 마일스톤의 목표치 {}%",
    "ty": "acc"
  },
  {
    "key": "personal_milestone_ace",
    "descr_tmpl": "에이스 마일스톤의 목표치 {}%",
    "ty": "acc"
  },
  {
    "key": "personal_milestone_unbreakable",
    "descr_tmpl": "불굴의 요원 마일스톤의 목표치 {}%",
    "ty": "acc"
  },
  {
    "key": "personal_milestone_social",
    "descr_tmpl": "사회인 마일스톤의 목표치 {}%",
    "ty": "acc"
  },
  {
    "key": "personal_milestone_cashcow",
    "descr_tmpl": "캐시 카우 마일스톤의 목표치 {}%",
    "ty": "acc"
  },
  {
    "key": "personal_milestone_honor",
    "descr_tmpl": "명예 훈장 마일스톤의 목표치 {}%",
    "ty": "acc"
  },
  {
    "key": "personal_milestone_reaper",
    "descr_tmpl": "사신 마일스톤의 목표치 {}%",
    "ty": "acc"
  }
];